var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.conference
    ? _c(
        "div",
        [
          _c("label", { staticClass: "ml-2 userName font-weight-medium" }, [
            _vm._v(_vm._s(_vm.conference.confName))
          ]),
          _vm._v(" "),
          _vm.conference.confId && _vm.canEditConference
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    scrollable: "",
                    "close-on-click": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "groupCardEllipses",
                                  attrs: { icon: "" }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "ellipsis-v"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    871106781
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _vm.canEditConference
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.editConference(_vm.conference)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.addConferenceModal.editConference"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEditConference &&
                      !_vm.amInACall &&
                      !_vm.conference.conferenceHall
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.callAllMembers(_vm.conference)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.addGroupModal.callAll")
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEditConference
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.reminderConference(_vm.conference)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.addConferenceModal.confReminder"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEditConference
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.checkSendMessage(
                                    _vm.conference.confUUIDS,
                                    _vm.conference.confId
                                  )
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.addGroupModal.sendMessageToAll"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEditConference
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.duplicateConference(
                                    _vm.conference.confId
                                  )
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.addConferenceModal.dupConfNewDate"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEditConference
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.preDeleteConference(
                                    $event,
                                    _vm.conference.confId
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-list-item-title",
                                              _vm._g(
                                                {
                                                  class: !_vm.deleteActive
                                                    ? "deleteBasketLinkNormal"
                                                    : "deleteBasketLinkActive"
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.addConferenceModal.deleteConference"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2355981979
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.confirmRemove"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }