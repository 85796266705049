var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.validData,
            callback: function($$v) {
              _vm.validData = $$v
            },
            expression: "validData"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userListToSend.length < 4,
                      expression: "userListToSend.length < 4"
                    }
                  ],
                  staticClass: "w-100 mx-0 my-0"
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "",
                          disabled: _vm.atUserLimit || _vm.userSelected,
                          rules: _vm.nameRules,
                          label: _vm.$t("components.inviteVisitor.name")
                        },
                        model: {
                          value: _vm.selectedUserName,
                          callback: function($$v) {
                            _vm.selectedUserName = $$v
                          },
                          expression: "selectedUserName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "",
                          disabled: _vm.emailDisabled,
                          label: _vm.$t(
                            "components.inviteVisitor.invitationEmail"
                          ),
                          rules:
                            _vm.phoneNumberInput.length > 0
                              ? []
                              : _vm.emailRules
                        },
                        on: { keyup: _vm.changeEmail },
                        model: {
                          value: _vm.selectedEmail,
                          callback: function($$v) {
                            _vm.selectedEmail = $$v
                          },
                          expression: "selectedEmail"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-0 my-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          required: "",
                          label: _vm.$t("components.inviteVisitor.function")
                        },
                        model: {
                          value: _vm.visFunction,
                          callback: function($$v) {
                            _vm.visFunction = $$v
                          },
                          expression: "visFunction"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isWhatsAppInviteAvailable
                    ? [
                        _c(
                          "v-col",
                          {
                            staticClass: "py-0 positionRelative",
                            attrs: { cols: "6" }
                          },
                          [
                            _c(
                              "vue-tel-input",
                              _vm._b(
                                {
                                  staticClass:
                                    "form-control text-center input-sm direscCallMasktTlfInput",
                                  class: _vm.phoneDisabled
                                    ? {}
                                    : (
                                        (!_vm.phoneInputValid &&
                                          _vm.phoneNumberInput) ||
                                        ""
                                      ).length > 0
                                    ? "borderError"
                                    : _vm.isDarkMode
                                    ? "borderDarkMode"
                                    : "borderLigthMode",
                                  attrs: {
                                    required: "",
                                    defaultCountry: "DE",
                                    enabledCountryCode: "",
                                    name: "number",
                                    id: "numDisplay",
                                    value: "",
                                    placeholder: "",
                                    autocomplete: "off",
                                    disabled: _vm.phoneDisabled
                                  },
                                  on: { onInput: _vm.onTelInput },
                                  model: {
                                    value: _vm.phoneNumberInput,
                                    callback: function($$v) {
                                      _vm.phoneNumberInput = $$v
                                    },
                                    expression: "phoneNumberInput"
                                  }
                                },
                                "vue-tel-input",
                                _vm.bindProps,
                                false
                              )
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "whatsAppIcon" },
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fab", "whatsapp"] }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-0 my-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 positionRelative",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("span", { staticClass: "font-14" }, [
                        _vm._v(_vm._s(_vm.getNoticeText))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }