var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    {
      class:
        "contentContainer  " +
        (_vm.mini
          ? "mainContentContainer"
          : "mainContentContainerFullSidebar") +
        " " +
        (_vm.hasNotSidebar ? "containerGuest" : "") +
        " " +
        (!_vm.showTimeline ? "hideTimeline" : "")
    },
    [
      _vm.showSambaCall ? _c("SambaCall") : _vm._e(),
      _vm._v(" "),
      _vm.showP2pCall ? _c("P2pCall") : _vm._e(),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _vm.state.simpleOrganisationManagerVisible
        ? _c("manage-organisation", {
            attrs: { visible: _vm.state.simpleOrganisationManagerVisible },
            on: {
              "update:visible": function($event) {
                return _vm.$set(
                  _vm.state,
                  "simpleOrganisationManagerVisible",
                  $event
                )
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state.currentContentVisile.showComponent &&
      _vm.state.currentContentVisile.component === "editUploadExcell"
        ? _c("EditExcelView")
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile && !_vm.isIpad ? _c("ModalSendMessage") : _vm._e(),
      _vm._v(" "),
      _vm.state.user &&
      (_vm.incommingCallsArray.length > 0 ||
        _vm.incomingConfCallsArray.length > 0)
        ? _c("ModalIncomingBridgeCall")
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile || _vm.isIpad ? _c("ModalSendMessageMobile") : _vm._e(),
      _vm._v(" "),
      _c("NotificationsModal"),
      _vm._v(" "),
      _c("AddTeamModal"),
      _vm._v(" "),
      _c("AddToGroupModal"),
      _vm._v(" "),
      _c("TimeLineEventModal"),
      _vm._v(" "),
      _c("PingPongModal"),
      _vm._v(" "),
      _vm.state.showModalMeetingTime ? _c("MeetingTimeModal") : _vm._e(),
      _vm._v(" "),
      _c("UserSelectModal"),
      _vm._v(" "),
      _vm.state.showModalEndMeeting ? _c("EndMeetingTimeModal") : _vm._e(),
      _vm._v(" "),
      _vm.showModal
        ? _c("FirstModal", {
            attrs: {
              showFirstModal: _vm.showFirstModal,
              toggleUserFirstModal: _vm.toggleUserFirstModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showInfoModal ? _c("InfoModal") : _vm._e(),
      _vm._v(" "),
      _c("LicenseExpiredModal"),
      _vm._v(" "),
      _c("LicenseAlmostExpiredModal"),
      _vm._v(" "),
      _vm.state.modalPopUp && _vm.state.modalPopUp.type === "rejectCall"
        ? _c("RejectCallModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showjoinConference ? _c("JoinConferenceStartedModal") : _vm._e(),
      _vm._v(" "),
      _vm.state.modalPopUp &&
      _vm.state.modalPopUp.type === "answerJoinConference"
        ? _c("JoinConferenceAnswerModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.modalPopUp && _vm.state.modalPopUp.type === "popUp"
        ? _c("InfoPopUpModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showFullBridgeCallModal ? _c("FullBridgeCallModal") : _vm._e(),
      _vm._v(" "),
      _vm.showcanJoinConferencesModal.show
        ? _c("CanJoinConferenceModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalVotingQuality ? _c("VotingModal") : _vm._e(),
      _vm._v(" "),
      _vm.showModalReminder ? _c("ReminderModal") : _vm._e(),
      _vm._v(" "),
      _vm.showDirectCallInviteModal
        ? _c("DirectCallInvitationModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showJoinBridgeCallModal.show ? _c("JoinBridgeCallModal") : _vm._e(),
      _vm._v(" "),
      _vm.showModalRequestPayment ? _c("RequestPaymentModal") : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.paymenInprogress
        ? _c("InProgressPaymentModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.showSuccessPayment
        ? _c("SuccessPaymentModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.showErrorPayment
        ? _c("ErrorPaymentModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.showCancelPayment
        ? _c("PaymentCanceled")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.requestedPayment.show ? _c("PaymentEventModal") : _vm._e(),
      _vm._v(" "),
      _vm.showQrCodeModal ? _c("QRcodeModal") : _vm._e(),
      _vm._v(" "),
      _vm.showVideoTestModal ? _c("VideoTestModal") : _vm._e(),
      _vm._v(" "),
      _vm.showExternalServicesModal ? _c("ExternalServicesModal") : _vm._e(),
      _vm._v(" "),
      _vm.showvOfficeProVersionModalStore
        ? _c("VOfficeProVersionModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showBadQualityMessageModal ? _c("BadQualityMessageModal") : _vm._e(),
      _vm._v(" "),
      _vm.showChangelogModal ? _c("ChangelogModal") : _vm._e(),
      _vm._v(" "),
      _vm.showBeforeEndingCallModal ? _c("BeforeEndingCallModal") : _vm._e(),
      _vm._v(" "),
      _vm.showNewVersionAvailableModal
        ? _c("NewVersionAvailableModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.showModalActiveUser ? _c("ActiveUserModal") : _vm._e(),
      _vm._v(" "),
      _vm.state.showModalAudioPolicy ? _c("AudioPolicyModal") : _vm._e(),
      _vm._v(" "),
      _vm.showModalNotification ? _c("NotificationsModalEffector") : _vm._e(),
      _vm._v(" "),
      _vm.showErrorInCallModal ? _c("ErrorInCallModal") : _vm._e(),
      _vm._v(" "),
      _vm.showHotkeysModal ? _c("HotkeysModal") : _vm._e(),
      _vm._v(" "),
      _vm.showUserProfilesModal ? _c("UserProfile") : _vm._e(),
      _vm._v(" "),
      _vm.showSetupDevice
        ? _c("DeviceSetupModal", {
            attrs: {
              showSetupDevice: _vm.showSetupDevice,
              toggleModalSettings: _vm.toggleModalSettings
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLegalMailFooter ? _c("LegalMailFooterModal") : _vm._e(),
      _vm._v(" "),
      _vm.showSendMessageBeforeConference.show
        ? _c("SendMessageBeforeConferenceModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showLanguageModal ? _c("SetLanguageModal") : _vm._e(),
      _vm._v(" "),
      _vm.showMobileModal ? _c("MobileCallModal") : _vm._e(),
      _vm._v(" "),
      _vm.showLogoutModal ? _c("LogoutModal") : _vm._e(),
      _vm._v(" "),
      _vm.showHistoryMessagesModal
        ? _c("HistoryMessagesModalEffector")
        : _vm._e(),
      _vm._v(" "),
      _vm.showUploadAvatarModal.showModal ? _c("UploadAvatarModal") : _vm._e(),
      _vm._v(" "),
      _vm.ShowerrorCameraPermissionsModal ? _c("CameraPermissions") : _vm._e(),
      _vm._v(" "),
      _vm.ShowDeleteVisitorPaylinkModalStore.showModal
        ? _c("DeleteVisitorPaylinkModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showCallUserInCallModalStore.showModal
        ? _c("CallUserInCallModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.ShowCallPersonConferenceHallModalStore
        ? _c("CallPersonConferenceHallModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showCallEndedInfoModalStore ? _c("CallEndedInfoModal") : _vm._e(),
      _vm._v(" "),
      _vm.showBreakStatusModalStore ? _c("BreakStatusModal") : _vm._e(),
      _vm._v(" "),
      _vm.showDailyReportModalStore ? _c("DailyReportModal") : _vm._e(),
      _vm._v(" "),
      _vm.showDailyReportSettingsModalStore.showModal
        ? _c("DailyReportSettingsModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.ShowBeaconDomainWhitelistStore
        ? _c("BeaconDomainWhitelistModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendBugModal ? _c("ModalSendBug") : _vm._e(),
      _vm._v(" "),
      _vm.showTeamsModal ? _c("showTeamsModal") : _vm._e(),
      _vm._v(" "),
      _vm.showAddEditOrganigramModalStore
        ? _c("AddEditOrganigramModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.showGpt4bot && _vm.state.namespaceSettings.showGPT4BOT
        ? _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                bottom: "0",
                right: "9px",
                "z-index": "20"
              }
            },
            [
              !_vm.loadingClosegpt4botIframe
                ? _c(
                    "v-btn",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "42px",
                        top: "106px"
                      },
                      attrs: { small: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.openCloseGpt4bot()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        style: { fontSize: "25px" },
                        attrs: { icon: ["fal", "times"] }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("iframe", {
                attrs: {
                  id: "gpt4botIframe",
                  allow: "microphone",
                  src: "https://gpt4bot-test.ds.ra-micro.de/?isIframe=true",
                  width: "500",
                  height: "840",
                  frameborder: "0"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.state.showInvitationSent
        ? _c(
            "v-alert",
            {
              staticClass: "slideInvitationSent",
              attrs: {
                transition: "slide-x-transition",
                text: "",
                color: "info",
                dismissible: ""
              }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.userListItem.yourInvitationTo")) +
                  "\n    "
              ),
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.state.sendInvitationGuestModal.invitationData.name
                  ) +
                    " (" +
                    _vm._s(
                      _vm.state.sendInvitationGuestModal.invitationData.email
                    ) +
                    ")"
                )
              ]),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("components.userListItem.hasBeenSuccessfullySent")
                  ) +
                  "\n  "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }