var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: this.state.showAddGroupModal,
            callback: function($$v) {
              _vm.$set(this.state, "showAddGroupModal", $$v)
            },
            expression: "this.state.showAddGroupModal"
          }
        },
        [
          _c("v-card", [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  {
                    staticClass:
                      "w-100 mx-0 my-0 d-flex justify-space-between align-center"
                  },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "headline" },
                          [
                            _c("v-img", {
                              staticClass: "mr-1",
                              attrs: {
                                src: "assets/icon.png",
                                "max-height": "30",
                                "max-width": "30",
                                contain: ""
                              }
                            }),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("components.addGroupModal.addNewGroup")
                                ) +
                                "\n              "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { icon: "", color: "close" },
                                          on: { click: _vm.closeModal }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "times"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.close")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-row", {
                  staticClass: "w-100 mx-0 my-0",
                  attrs: { flex: "" }
                }),
                _vm._v(" "),
                _c(
                  "v-form",
                  {
                    ref: "form",
                    staticStyle: { width: "100%", margin: "0" },
                    attrs: { "lazy-validation": "" },
                    model: {
                      value: _vm.valid,
                      callback: function($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "px-5",
                        staticStyle: { width: "100%", margin: "0" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12 px-2 py-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                autofocus: "",
                                label: _vm.$t(
                                  "components.addGroupModal.groupName"
                                ),
                                counter: "",
                                maxlength: "30",
                                required: "",
                                rules: _vm.requiredRule
                              },
                              model: {
                                value: _vm.newGroupName,
                                callback: function($$v) {
                                  _vm.newGroupName = $$v
                                },
                                expression: "newGroupName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        staticClass: "px-5",
                        style: { width: "100%", margin: 0 }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0 pl-0", attrs: { cols: "5" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "startDateMenu",
                                attrs: {
                                  "close-on-content-click": false,
                                  "return-value": _vm.dateStart,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "290px"
                                },
                                on: {
                                  "update:returnValue": function($event) {
                                    _vm.dateStart = $event
                                  },
                                  "update:return-value": function($event) {
                                    _vm.dateStart = $event
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              staticClass: "mt-3",
                                              attrs: {
                                                readonly: "",
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                label: _vm.$t(
                                                  "components.eventEntryForm.startEvent"
                                                )
                                              },
                                              model: {
                                                value: _vm.formatedStart,
                                                callback: function($$v) {
                                                  _vm.formatedStart = $$v
                                                },
                                                expression: "formatedStart"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.startDateMenu,
                                  callback: function($$v) {
                                    _vm.startDateMenu = $$v
                                  },
                                  expression: "startDateMenu"
                                }
                              },
                              [
                                _vm._v(" "),
                                _c("v-date-picker", {
                                  attrs: { "no-title": "", scrollable: "" },
                                  on: {
                                    "click:date": function($event) {
                                      return _vm.$refs.startDateMenu.save(
                                        _vm.dateStart
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.dateStart,
                                    callback: function($$v) {
                                      _vm.dateStart = $$v
                                    },
                                    expression: "dateStart"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "py-0 pl-0", attrs: { cols: "4" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "startTimeMenu",
                                attrs: {
                                  "close-on-content-click": false,
                                  "nudge-right": 40,
                                  "return-value": _vm.timeStart,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                },
                                on: {
                                  "update:returnValue": function($event) {
                                    _vm.timeStart = $event
                                  },
                                  "update:return-value": function($event) {
                                    _vm.timeStart = $event
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              staticClass: "mt-3",
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.eventEntryForm.startTime"
                                                ),
                                                readonly: "",
                                                outlined: "",
                                                dense: "",
                                                "hide-details": ""
                                              },
                                              model: {
                                                value: _vm.timeStart,
                                                callback: function($$v) {
                                                  _vm.timeStart = $$v
                                                },
                                                expression: "timeStart"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.startTimeMenu,
                                  callback: function($$v) {
                                    _vm.startTimeMenu = $$v
                                  },
                                  expression: "startTimeMenu"
                                }
                              },
                              [
                                _vm._v(" "),
                                _vm.startTimeMenu
                                  ? _c("v-time-picker", {
                                      attrs: {
                                        "full-width": "",
                                        format: "24hr"
                                      },
                                      on: {
                                        "click:minute": function($event) {
                                          return _vm.$refs.startTimeMenu.save(
                                            _vm.timeStart
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.timeStart,
                                        callback: function($$v) {
                                          _vm.timeStart = $$v
                                        },
                                        expression: "timeStart"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "py-0 pl-0", attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              staticClass: "mt-3",
                              attrs: {
                                outlined: "",
                                dense: "",
                                "max-width": "50",
                                type: "number",
                                min: "0",
                                max: "120",
                                step: "1",
                                label: _vm.$t(
                                  "components.eventEntryForm.minutes"
                                )
                              },
                              model: {
                                value: _vm.durationMin,
                                callback: function($$v) {
                                  _vm.durationMin = $$v
                                },
                                expression: "durationMin"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "px-2 py-2", attrs: { cols: "12" } },
                          [
                            _c("v-combobox", {
                              staticClass: "mx-1 label600",
                              attrs: {
                                items: _vm.dataCombobox.users,
                                select: "",
                                "search-input": _vm.searchUsers,
                                "item-text": "name",
                                label: _vm.$t("generics.users"),
                                multiple: "",
                                dense: "",
                                "auto-select-first": ""
                              },
                              on: {
                                "update:searchInput": function($event) {
                                  _vm.searchUsers = $event
                                },
                                "update:search-input": function($event) {
                                  _vm.searchUsers = $event
                                },
                                change: function($event) {
                                  _vm.searchUsers = ""
                                }
                              },
                              model: {
                                value: _vm.membersToGroup,
                                callback: function($$v) {
                                  _vm.membersToGroup = $$v
                                },
                                expression: "membersToGroup"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "px-2 py-2", attrs: { cols: "12" } },
                          [
                            _c("v-combobox", {
                              staticClass: "mx-1 label600",
                              attrs: {
                                items: _vm.dataCombobox.guests,
                                select: "",
                                "search-input": _vm.searchVisitors,
                                "item-text": "name",
                                label: _vm.$t("generics.guest"),
                                multiple: "",
                                dense: "",
                                "auto-select-first": ""
                              },
                              on: {
                                "update:searchInput": function($event) {
                                  _vm.searchVisitors = $event
                                },
                                "update:search-input": function($event) {
                                  _vm.searchVisitors = $event
                                },
                                change: function($event) {
                                  _vm.searchVisitors = ""
                                }
                              },
                              model: {
                                value: _vm.guestsToGroup,
                                callback: function($$v) {
                                  _vm.guestsToGroup = $$v
                                },
                                expression: "guestsToGroup"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.totalUserCombobox > 4
                          ? _c(
                              "v-col",
                              {
                                staticClass: "px-2 py-2 text-right",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.addGroupModal.maxUser")
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card",
                  {
                    staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                    attrs: { color: "primary" }
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "px-2 btns w-100 mx-0 my-0" },
                      [
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mr-3",
                                            attrs: {
                                              disabled:
                                                !_vm.valid ||
                                                _vm.membersToGroup.length +
                                                  _vm.guestsToGroup.length >
                                                  4,
                                              icon: "",
                                              color: "white"
                                            },
                                            on: { click: _vm.handleNewGroup }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "check"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.accept")))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { icon: "", color: "white" },
                                            on: { click: _vm.closeModal }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "times"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.close")))
                              ])
                            ]
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }