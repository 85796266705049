var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.person && _vm.person.user && _vm.uuid
    ? _c(
        "div",
        { staticClass: "frontButtons" },
        [
          _c("UserMoreActions", {
            attrs: {
              teamInfo: _vm.teamInfo,
              person: _vm.person,
              section: null,
              customStyle: false,
              isRegularGrid: true,
              isUserInCall: _vm.isUserInCall,
              hasOHold: _vm.hasOnHold,
              isBasicView: _vm.isBasicView,
              showUploadFile: _vm.showUploadFile,
              disableSendFileBtn: _vm.disableSendFileBtn,
              sendFileTo: _vm.sendFileTo,
              openDropdown: _vm.openDropdown,
              IsInList: _vm.IsInList,
              showHelpscoutButton: _vm.showHelpscoutButton,
              showVisitPage: _vm.showVisitPage,
              openHelpsCoutModal: _vm.openHelpsCoutModal,
              checkSendMessage: _vm.checkSendMessage,
              typeData: _vm.typeData,
              isOrga: _vm.isOrga,
              isEditableView: _vm.isEditableView,
              sectionData: _vm.sectionData,
              checkMove: _vm.checkMove,
              isMovingDepa: _vm.isMovingDepa,
              isMovingTeam: _vm.isMovingTeam,
              nameSectionToMove: _vm.nameSectionToMove,
              parentToMove: _vm.parentToMove,
              moveType: _vm.moveType
            }
          }),
          _vm._v(" "),
          _vm.openingDropdown
            ? [
                _c("DonwnloadsFilesModal", {
                  attrs: {
                    showModal: _vm.openingDropdown,
                    closeModal: _vm.openDropdown
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.amIGuest
            ? [
                _c("RemoveFromFavoriteModal", {
                  attrs: {
                    person: _vm.deleteFavorite.person,
                    showModal: _vm.deleteFavorite.show,
                    closeModal: _vm.closeDeleteFavoriteModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          [
            _vm.changeUserFunction.show
              ? _c("ChangeFunctionModal", {
                  attrs: {
                    modalData: _vm.changeUserFunction,
                    cancelChangeFunction: _vm.cancelChangeFunction
                  }
                })
              : _vm._e()
          ],
          _vm._v(" "),
          _vm.showInvitationModal
            ? _c("SendInvitationModal", {
                attrs: {
                  closeShowInviteInfo: _vm.closeShowInviteInfo,
                  showInviteInfo: _vm.showInvitationModal
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deleteUser
            ? [
                _c("RemoveUserModal", {
                  attrs: {
                    person: _vm.person,
                    showModal: _vm.deleteUser,
                    closeModal: _vm.closeRemoveUserModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showAddHomePage
            ? [
                _c("HomePageModal", {
                  attrs: {
                    sectionData: _vm.sectionData,
                    showModal: _vm.showAddHomePage,
                    closeModal: _vm.closeSetHomePageModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showSendMessageToAll
            ? [
                _c("SendMessageModalGrid", {
                  attrs: {
                    sectionData: _vm.sectionData,
                    showModal: _vm.showSendMessageToAll,
                    closeModal: _vm.closeSendMessageModalGrid
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showHelpscout
            ? [
                _c("InProgressModal", {
                  attrs: {
                    showModal: _vm.showHelpscout,
                    closeModal: _vm.closeHelpscoutModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.openHolidaysCalendar
            ? [
                _c("HolidaysCalendarModal", {
                  attrs: {
                    userUUID: _vm.person.uuid,
                    closeModal: _vm.toggleShowHolidaysModal,
                    showModal: _vm.openHolidaysCalendar
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.openDisplayAssistants
            ? [
                _c("DisplayAssistantsModal", {
                  attrs: {
                    closeModal: _vm.toggleDisplayAssistantsModal,
                    showModal: _vm.openDisplayAssistants
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showDirectLink
            ? [
                _vm.showDirectLink
                  ? _c("DirectLinkInvitationModal", {
                      attrs: {
                        showModalDirectLink: _vm.showDirectLink,
                        openCloseModalDirectLink: _vm.setDirectLink
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }