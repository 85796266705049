var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.canInvite && !_vm.isMobile
        ? _c(
            "v-menu",
            {
              attrs: {
                "position-x": 260,
                "position-y": 120,
                absolute: "",
                "offset-y": "",
                "max-height": "100%",
                "max-width": "100%",
                "content-class": "inviteVisitorMenu",
                "close-on-content-click": false,
                "close-on-click": false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c("div", _vm._g({}, on), [
                          _c(
                            "div",
                            { staticStyle: { position: "relative" } },
                            [
                              !_vm.mini
                                ? _c(
                                    "v-btn",
                                    {
                                      class:
                                        _vm.typeInvite == "guest"
                                          ? "btnSendGuestInvitation"
                                          : "",
                                      attrs: {
                                        color:
                                          _vm.typeInvite == "user"
                                            ? "white"
                                            : "",
                                        icon: ""
                                      },
                                      on: { click: _vm.toggleInstaLink }
                                    },
                                    [
                                      !_vm.isSidebar
                                        ? _c("font-awesome-icon", {
                                            staticClass: "primary--text",
                                            style: { fontSize: "20px" },
                                            attrs: {
                                              icon: ["fal", "user-plus"]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isSidebar
                                        ? _c("font-awesome-icon", {
                                            staticClass: "primary--text",
                                            style: { fontSize: "20px" },
                                            attrs: {
                                              icon: ["fal", "plus-circle"]
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.mini
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _vm.currentPage("/guest")
                                                  ? _c(
                                                      "font-awesome-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "primary--text cursorPointer",
                                                          staticStyle: {
                                                            outline: "none"
                                                          },
                                                          attrs: {
                                                            icon: [
                                                              "fas",
                                                              "user-friends"
                                                            ]
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.handlerClickVisitorsMini()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  : _c(
                                                      "font-awesome-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "primary--text cursorPointer",
                                                          staticStyle: {
                                                            outline: "none"
                                                          },
                                                          attrs: {
                                                            icon: [
                                                              "fal",
                                                              "user-friends"
                                                            ]
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.handlerClickVisitorsMini()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("generics.invitationNavbar")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                3828494008
              ),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _vm._v(" "),
              _vm.menu
                ? _c("InviteVisitorsMenuNew", {
                    attrs: { closeMenu: _vm.closeMenu }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }