var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      attrs: { "max-width": "320" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
      }
    },
    [
      !_vm.isGuest && !_vm.mini
        ? _c(
            "v-list-group",
            {
              attrs: {
                "active-class": "listColor",
                value: _vm.isActive,
                appendIcon:
                  _vm.sortedFavoriteUsers.length === 0 ? null : "$expand"
              },
              on: {
                click: function($event) {
                  return _vm.handlerClickFav($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "v-list-item-icon",
                          [
                            _vm.currentPage("/my-favorites")
                              ? _c("font-awesome-icon", {
                                  staticClass: "primary--text-sidepanel",
                                  attrs: { icon: ["fas", "star"] }
                                })
                              : _c("font-awesome-icon", {
                                  staticClass: "primary--text-sidepanel",
                                  attrs: { icon: ["fal", "star"] }
                                })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-list-item-title",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "primary--text-sidepanel",
                                            class:
                                              "" +
                                              (_vm.getRoute === "/my-favorites"
                                                ? "font-weight-bold"
                                                : ""),
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                _vm.setCurrentContentVisile(
                                                  "",
                                                  false,
                                                  _vm.$router
                                                )
                                                _vm.handleMyFavoriteView()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.footernav.myFavorites"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                          _vm.sortedFavoriteUsers.length > 0
                                            ? _c("span", [
                                                _vm._v("\n              ("),
                                                _c(
                                                  "span",
                                                  { staticClass: "textColor" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.connectedFavoriteUsersLen
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "/ " +
                                                    _vm._s(
                                                      _vm.totalUnfilteredUsers
                                                    ) +
                                                    ")"
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2919270540
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.footernav.myFavoritesTT")
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showCustomTT
                          ? _c("CustomTT", {
                              attrs: {
                                text:
                                  _vm.$t("components.hotkeys.controlKey") +
                                  " + F",
                                position: "bottom",
                                extraclass: "ctrf"
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                322143292
              )
            },
            [
              _vm._v(" "),
              _c("UserList", {
                attrs: {
                  persons: _vm.sortedFavoriteUsers,
                  section: "Favorites"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handlerClickFav()
                                  }
                                }
                              },
                              [
                                _vm.currentPage("/my-favorites")
                                  ? _c("font-awesome-icon", {
                                      staticClass: "primary--text-sidepanel",
                                      attrs: { icon: ["fas", "star"] }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.currentPage("/my-favorites")
                                  ? _c("font-awesome-icon", {
                                      staticClass: "primary--text-sidepanel",
                                      attrs: { icon: ["fal", "star"] }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3563081525
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.sidebar.favoritesMiniTT")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }