var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.dialUpTime,
        callback: function($$v) {
          _vm.dialUpTime = $$v
        },
        expression: "dialUpTime"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.isMobile
                ? _vm.$t("components.dialUpTime.titleMobile")
                : _vm.$t("components.dialUpTime.title"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-2" },
            [
              _c(
                "v-list-item",
                { staticClass: "py-0 switchWrapper" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "py-0 px-3 my-0" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.dialUpTimeItem,
                          "item-text": "state",
                          "item-value": "value",
                          label: _vm.$t(
                            "components.dialUpTime.incommingCallDuration"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeDialUpTime()
                          }
                        },
                        model: {
                          value: _vm.storeDialUpTime,
                          callback: function($$v) {
                            _vm.storeDialUpTime = $$v
                          },
                          expression: "storeDialUpTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-list-item",
                { staticClass: "py-0 switchWrapper" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "py-0 px-3 my-0" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.numberNotificationWaitingroomItem,
                          "item-text": "state",
                          "item-value": "value",
                          label: _vm.$t(
                            "components.dialUpTime.waitingroomNotification"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeNumberNotificationWaitingroom()
                          }
                        },
                        model: {
                          value: _vm.storeNumberNotificationWaitingroom,
                          callback: function($$v) {
                            _vm.storeNumberNotificationWaitingroom = $$v
                          },
                          expression: "storeNumberNotificationWaitingroom"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex px-3" },
            [
              _c(
                "v-list-item",
                { staticClass: "py-0 px-3 switchWrapper" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "py-0 my-0" },
                    [
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "subtitle-2 d-flex ml-0" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "components.adminSettingsModal.soundSignal"
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: !_vm.isMobile },
                          on: {
                            change: function($event) {
                              return _vm.changedingDongTimeForRepeat()
                            }
                          },
                          model: {
                            value: _vm.dingDongTimeForRepeat,
                            callback: function($$v) {
                              _vm.dingDongTimeForRepeat = $$v
                            },
                            expression: "dingDongTimeForRepeat"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.isMobile
                                ? "1 " + _vm.$t("literals.Minutes")
                                : "1",
                              value: "60000"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            class: !_vm.isMobile ? "ml-3" : "",
                            attrs: {
                              label: _vm.isMobile
                                ? "2 " + _vm.$t("literals.Minutes")
                                : "2",
                              value: "120000"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            class: !_vm.isMobile ? "ml-3" : "",
                            attrs: {
                              label: _vm.isMobile
                                ? "3 " + _vm.$t("literals.Minutes")
                                : "3",
                              value: "180000"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            class: !_vm.isMobile ? "ml-3" : "",
                            attrs: {
                              label: "5 " + _vm.$t("literals.Minutes"),
                              value: "300000"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }