<template>
  <div>
    <v-content
      :class="`px-0 py-0 contentGridWrapper`"
      v-on:scroll.native="handleScroll"
    >
      <template>
        <v-row class="mx-auto">
          <v-col
            class="pb-1 px-2"
            cols="2"
            v-if="!isMobile"
          >
            <v-card flat class="firstCard cardWidth wr cursorPointer">
              <div class="divAddBox" style="height: 180px; width: 100%">
                <div class="divAdd" @click="goToInviteGuest()">
                  <font-awesome-icon
                    :icon="['fal', 'plus']"
                    class="buttonAdd"
                  />
                  <p class="mb-0 mt-5">
                    {{ $t("generics.addGuestCard") }}
                  </p>
                  <div>
                    <v-btn
                      style="max-width: 200px;"
                      class="mt-3"
                      color="primary"
                      outlined
                      dense
                      @click.prevent.stop="gotoInviteGuestList()"
                      >{{ $t("components.inviteGuestPanel.inviteList") }}</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col
            v-for="(element, index) in paginatedData"
            :key="index"
            class="pb-1 px-2"
            cols="2"
          >
            <!-- START USER CARD -->
            <v-card
              flat
              class="cardWidth wr"
              @click="handleClicks($event, element, `flipCard-${index}`)"
              :style="getBorderByStatusFull(element)"
              v-if="element.uuid"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-img
                      :src="getAvatarByType(element.uuid)"
                      class="white--text align-end"
                      height="180px"
                    >
                      <div class="iconCardsContainer pa-3">
                        <font-awesome-icon
                          v-if="getColorPhone(element)"
                          class="faPhoneRotate"
                          :icon="['fal', 'phone']"
                          :rotation="270"
                          :color="getColorPhone(element)"
                          :style="{ fontSize: '20px' }"
                        />
                      </div>
                      <div
                        class="black--text text-center"
                        v-if="element.user.presentFrom"
                      >
                        {{ formatDate(element.user.presentFrom) }}
                      </div>
                      <div class="gradientBg">
                        <v-card-subtitle
                          style="color: #fff !important"
                          class="
                            titleCard
                            colorWhite
                            pt-1
                            pb-1
                            px-2
                            customFontSize
                            text-truncate
                          "
                        >
                          {{ titelPerson(element) }}
                          {{ namePerson(element, index) }}</v-card-subtitle
                        >
                        <ButtonsFrontCard
                          :person="element"
                          :uuid="element.uuid"
                          sectionName="members"
                          :flipCard="flipCard"
                          :index="index"
                          typeData="waitingroom"
                        />
                      </div>
                    </v-img>
                  </div>
                </template>
                <span>
                  <span
                    >{{ titelPerson(element) }}
                    {{ namePerson(element, index) }}</span
                  >
                  <br />
                  <span>{{ getUserActivity(element) }}</span>
                  <br
                    v-if="
                      element.user.visitorData.linkDateTo &&
                      element.user.visitorData.linkTimeTo &&
                      element.user.visitorData.duration
                    "
                  />
                  <span
                    v-if="
                      element.user.visitorData.linkDateTo &&
                      element.user.visitorData.linkTimeTo &&
                      element.user.visitorData.duration
                    "
                  >
                    {{ $t("components.inviteVisitor.appointmentshort") }}
                    {{ getTimeMessage(element.user.visitorData) }}
                  </span>
                  <br v-if="element.user.visitorData.noteForContact" />
                  <span v-if="element.user.visitorData.noteForContact">{{
                    element.user.visitorData.noteForContact
                  }}</span>
                  <br v-if="element.paylinkInfo" />
                  <span v-if="element.paylinkInfo">
                    {{ $t("components.adminSettingsModal.payOfficePaylink") }} -
                    {{ $t("components.paymentModal.price") }}:
                    {{ element.paylinkInfo.total }}
                    {{ element.paylinkInfo.currency }}
                    {{ $t("generics.status") }}:
                    {{
                      !element.paylinkInfo.unpaid
                        ? $t("generics.paid")
                        : $t("generics.unpaid")
                    }}
                  </span>
                </span>
              </v-tooltip>
            </v-card>
            <!-- END USER CARD -->
            <!-- START CONFERENCE CARD -->
            <v-card
              flat
              v-if="!element.uuid && element.isConference"
              class="cardWidth wr"
              height="184px"
              style="border: 2px solid white"
              @click="selectConference(element)"
            >
              <v-card-text
                class="conferenceCard px-0 py-0 text--primary footerCard"
              >
                <v-card-subtitle
                  class="
                    titleConferenceCard
                    font-weight-medium
                    align-end
                    px-3
                    mt-0
                    pt-3
                    text-truncate
                    notranslate
                  "
                  >{{ element.confTile }}</v-card-subtitle
                >
                <v-divider></v-divider>
                <v-card-subtitle
                  class="
                    dateConferenceCard
                    align-end
                    px-3
                    pt-4
                    pb-0
                    text-truncate
                    notranslate
                  "
                >
                  {{
                    moment(element.dateTimeStart).format("DD.MM.YYYY | H:mm")
                  }}
                </v-card-subtitle>
                <v-card-subtitle
                  class="
                    usersConferenceCard
                    align-end
                    px-3
                    pt-2
                    pb-0
                    text-truncate
                    notranslate
                  "
                >
                  {{ $t("components.groups.totalUsers") }}:&nbsp;
                  {{ element.totalUsers }}
                </v-card-subtitle>
                <v-row align="center" class="mx-0">
                  <CallConference :conference="getConference(element.confId)" />
                </v-row>
              </v-card-text>
            </v-card>
            <!-- END CONFERENCE CARD -->
          </v-col>
        </v-row>
      </template>
      <v-row class="paginationPosition">
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="getTotalPages"
              circle
              v-if="getTotalPages > 1"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-content>
  </div>
</template>

<script>
import moment from "../../../../sharedsrc/moment";
import store, { EventBus } from "../../../store";
import Flipper from "vue-flipper";
import UserInfoTooltip from "../userInfoTooltip.vue";
import { sendFileTo } from "../../../lib/rtcConn";
// import { v4 as uuidv4 } from "uuid";
import DefaultAvatarGrid from "../../image/defaultAvatarGrid.vue";
import UserProfle from "../../modal/userProfile.vue";
import { myConferencesStore } from "../../../effector/conference";
import { timelineEvents } from "../../../effector/timeline";
import { myConferenceDetails } from "../../../effector/groupConferences";
import SendInvitationModal from "../../modal/sendInvitationModal.vue";
import {
  callPerson,
  disableCallBtn,
  wsCallStartBridgeCall,
  // personIsInBridgeCall,
  directBridgeCall,
} from "../../../utils/calls";
import {
  getTimezone,
  getAvatarByType,
  getBorderByStatusFull,
  uid
} from "../../../utils/basicFunctions";
import CallConference from "./callConference.vue";
import ButtonsFrontCard from "../buttonsFrontCard.vue";
import ButtonsCardWrapper from "../buttonsCardWrapper.vue";
import { waitingInfoStore, /* markAsCallWaitingRoomUserEvent, markAsCallWaitingRoomForUserEvent */ } from "../../../effector/users/waitingInfo";
import { isMobile } from "../../../lib/mobileUtil";
export default {
  components: {
    Flipper,
    DefaultAvatarGrid,
    UserProfle,
    UserInfoTooltip,
    SendInvitationModal,
    ButtonsFrontCard,
    ButtonsCardWrapper,
    CallConference,
  },
  props: ["typeData", "removeGeneralWrapper", "event"],
  data() {
    return {
      state: store.state,
      clicks: 0,
      timer: null,
      deleteFavorite: {
        show: false,
        person: undefined,
      },
      changeUserFunction: {
        show: false,
        person: undefined,
      },
      ownUUID: store.state.ownUUID,
      showUserProfile: false,
      showInvitationModal: false,
      sliceIndex: 48,
      obConferences: [],
      activeGroupId: null,
      page: 1,
      usersPerPage: 17,
      moment: moment,
      selectedConference: "",
      isMobile: isMobile(),
    };
  },
  effector: {
    myConferencesStore: myConferencesStore,
    timelineEvents: timelineEvents,
    myConferenceDetailsEffect: myConferenceDetails,
    waitingUsesEffect: waitingInfoStore,
  },
  methods: {
    getConference(confId) {
      let conference = null;
      if (confId) {
        const extant = this.myConferenceDetailsEffect.find(
          (e) => e.confId === confId
        );
        if (extant && extant.confId) {
          conference = extant;
        }
      }
      return conference;
    },
    // Current waiting room user component
    getColorPhone(person) {
      if (this.userIsInCall(person)) {
        return "#FFA500";
      } else if (this.getCalledVisitor.indexOf(person.user.uuid) !== -1) {
        return "#FF0000";
      } else {
        return null;
      }
    },
    formatDate(date) {
      return this.moment(date).format("DD.MM.YYYY - H:mm");
    },
    getTimeMessage(visitor) {
      let dateTo = visitor.linkDateFrom;
      let timeTo = visitor.linkTimeFrom; // "08:55"
      return dateTo + " " + timeTo; //+ " - " + d;
    },
    getUserActivity(person) {
      if (!person.connected && person.user.activity !== "Holidays") {
        return this.$t("status.Offline");
      } else {
        const key = `status.${person.user.activity}`;
        const translation = this.$t(key) || key;
        return key === translation ? `${person.user.activity}` : translation;
      }
    },
    directBridgeCall(uuid) {
      directBridgeCall(uuid, this.$router);
    },
    selectConference(conference) {
      if (this.selectedConference == "") {
        this.selectedConference = conference.confId;
      } else {
        this.selectedConference = "";
      }
    },
    goToInviteGuest() {
      if (this.isAssistsWaitinRoom) {
        this.setCurrentContentVisile(
          `invitationform/${this.isAssistsWaitinRoom}`,
          true,
          this.$router
        );
      } else {
        this.setCurrentContentVisile("invitationform", true, this.$router);
      }
    },
    gotoInviteGuestList() {
      if (this.isAssistsWaitinRoom) {
        this.setCurrentContentVisile(
          `invitationform/${this.isAssistsWaitinRoom}`,
          true,
          this.$router
        );
      } else {
        this.setCurrentContentVisile("invitationform", true, this.$router);
      }
      setTimeout(() => {
        EventBus.$emit("openTabInviteGuestList");
      }, 200);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    getUserInfo(uuid) {
      if (this.state.group[uuid]) {
        let result = this.state.group[uuid];
        result.uuid = uuid;
        result.user.uuid = uuid;
        return result;
      } else {
        return false;
      }
    },
    getFinalPersons(persons) {
      // function to sort the userlist
      const personSort = persons.sort((a, b) => {
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return (
          (a.user || {}).presentFrom ||
          a.name ||
          a.uuid ||
          ""
        ).localeCompare((b.user || {}).presentFrom || b.name || b.uuid || "");
      });
      // do foreach to find user conference and replace for conference data
      let actualConference = null;
      personSort.forEach((person, index) => {
        const isConferencePerson =
          person.user.visitorData.conferenceUUID &&
          person.user.visitorData.conferenceUUID.length > 0
            ? true
            : false;
        const currentConference = person.user.visitorData.currentConference;
        if (actualConference !== currentConference) {
          actualConference = currentConference;
          if (isConferencePerson) {
            this.obConferences[currentConference].waitingRoomPosition =
              index + 1;
            personSort[index] = this.obConferences[currentConference];
          }
        }
      });
      return personSort;
    },
    callConference(conferenceID) {
      let confGroup = undefined;
      if (this.amIanAssistant) {
        confGroup = this.state.group[this.getUrlUserUuid].user.userGroups.find(
          (e) => e.id == conferenceID
        );
      } else {
        confGroup = this.state.user.userGroups.find(
          (e) => e.id == conferenceID
        );
      }
      if (confGroup.durationMin) {
        const duration = Math.floor(confGroup.durationMin * 60 * 1000);
        this.setdurationMeeting(duration);
      }
      if (confGroup.members) {
        const usersToCall = [];
        confGroup.members.forEach(async (user) => {
          if (user.uuid !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[user.uuid] || {}).connected || false;
            if (!this.amInACall) {
              if (
                isConnected &&
                (((store.state.group[user.uuid] || {}).user || {}).activity ||
                  false) !== "Holidays"
              ) {
                if (!this.userIsInCall(store.state.group[user.uuid])) {
                  usersToCall.push(user.uuid);
                }
              }
            } else {
              if (
                isConnected &&
                (((store.state.group[user.uuid] || {}).user || {}).activity ||
                  false) !== "Holidays"
              ) {
                if (!this.userIsInCall(store.state.group[user.uuid])) {
                  usersToCall.push(user.uuid);
                }
              }
            }
          }
        });
        const id = uid();
        const info = {
          callUUID: id,
          calling: usersToCall,
          isNormalMode: true,
          initiator:
            this.checkInitiator === ""
              ? this.state.ownUUID
              : this.checkInitiator,
          callStartTs: Date.now(),
          callDurationMs:
            Math.floor(confGroup.durationMin * 60 * 1000) || 60000 * 20,
          infiniteCall: false,
        };
        usersToCall.forEach((user, idx) => {
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode: false,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs:
              Math.floor(confGroup.durationMin * 60 * 1000) || 60000 * 20,
            infiniteCall: false,
          };
          wsCallStartBridgeCall(
            user,
            this.state.ownUUID,
            infoStartBridgeCall,
            idx > 0
          );
        });
        store.addRemoteBridgeStream(id, info);
        store.setCurrentContentVisile("", false, this.$router);
      }
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    userIsAdmin(uuid) {
      return store.getUserIsAdmin(uuid);
    },
    sendFileTo(uuid) {
      return sendFileTo(uuid);
    },
    flipCard(reference) {
      this.$refs[reference][0].flipped = !this.$refs[reference][0].flipped;
    },
    handleClicks(event, person, reference) {
      // const isWaitingFor = this.$route.params.uuid
      if (person.uuid === this.ownUUID) return;
      // this.clicks++;
      // if (this.clicks === 1) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
        //   this.clicks = 0;
          if (person.uuid !== this.ownUUID) {
            if (!this.disableCallBtn(person)) {
              this.callUser(person.uuid);
            }
          } else {
            this.setUserInModal();
          }
        }, 700);
      // } else {
      //   clearTimeout(this.timer);
      //   this.clicks = 0;
      //   if (!this.disableCallBtn(person)) {
      //     if (this.inBridgeCall) {
      //       this.callUser(person.uuid);
      //     } else {
      //       if (!this.userIsInCall(person)) {
      //         if (personIsInBridgeCall(person.uuid)) {
      //           this.callUser(person.uuid);
      //         } else {
      //           this.directBridgeCall(person.uuid);
      //         }
      //       }
      //     }
      //     if (isWaitingFor) {
      //       markAsCallWaitingRoomForUserEvent(person.uuid);
      //     }
      //     else {
      //       markAsCallWaitingRoomUserEvent(person.uuid);
      //     }
      //   }
      // }
    },
    setdurationMeeting(duration = null) {
      return store.setdurationMeeting(duration || 300000);
    },
    setUserInModal() {
      store.state.ownModalUser = true;
      store.state.userOnModal = {
        ...store.state.user,
        connected: store.state.wsOpen,
        uuid: store.state.ownUUID,
      };
      this.toggleUserProfile();
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
    userIsInCall(person) {
      if (
        (person.connected &&
          person.user &&
          person.user.rtcCallStatus &&
          person.user.rtcCallStatus.length > 5) ||
        person.user.inBridgeCall ||
        false
      ) {
        return true;
      }
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    namePerson(person, index) {
      const position = index + 1;
      if (
        person.uuid &&
        store.state.group[person.uuid] &&
        store.state.group[person.uuid].beaconCallInvite
      ) {
        const userName = person.user?.name || `${this.$t("components.userSettings.user")} - ${position}`;
        return `${userName} ${this.$t("components.waitingRoom.beaconPosition", [
          position,
        ])}`;
      }
      if (person && person.user?.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      return store.getTitelForUuid(person.uuid);
    },
  },
  computed: {
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    getCalledVisitor() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      return calledVisitors;
    },
    isAssistsWaitinRoom() {
      return this.$route.params.uuid;
    },
    showSidebar() {
      return this.state.persisted.showSidebar;
    },
    paginatedData() {
      const start = (this.page - 1) * this.usersPerPage,
        end = start + this.usersPerPage;
      return this.slicedPersons.slice(start, end);
    },
    getTotalPages() {
      const totalUsers = Object.keys(this.slicedPersons).length;
      return Math.ceil(totalUsers / this.usersPerPage);
    },
    getUrlUserUuid() {
      if (this.amIanAssistant) return this.$route.params.uuid;
    },
    amIanAssistant() {
      if (this.$route.params.uuid) {
        return true;
      } else {
        return false;
      }
    },
    checkInitiator() {
      const callUUID = this.getCallUUID;
      if (callUUID !== "") {
        return this.state.remoteBridgeStreams[callUUID].initiator;
      } else {
        return "";
      }
    },
    getCallUUID() {
      let remoteStreamsKey;
      if (Object.keys(this.state.remoteBridgeStreams).length > 0) {
        remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      } else {
        remoteStreamsKey = "";
      }
      return remoteStreamsKey;
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    userList() {
      return store.getProcessedUserList();
    },
    slicedPersons() {
      return this.sortedPersons
        .filter((element) => (element.uuid || (!element.uuid && element.isConference)))
        .slice(0, this.sliceIndex);
    },
    sortedPersons() {
      let persons = [];
      let waitingPeople = [];
      let conferences = [];
      let conferencesIds = [];
      let conferencePeople = [];
      if (this.amIanAssistant) {
        persons = store.getWaitingRoomList(this.getUrlUserUuid);
      } else {
        persons = store.getWaitingRoomList();
      }
      waitingPeople = persons.filter(
        (e) => ((e.user.visitorData || {}).conferenceUUID || []).length == 0
      );
      // code for user conferences
      conferencePeople = persons.filter(function (e) {
        if (
          typeof ((e.user.visitorData || {}).conferenceUUID || []) == "string"
        ) {
          const tempData = e.user.visitorData;
          tempData.conferenceUUID = [e.user.visitorData.conferenceUUID];
          return tempData;
        } else {
          if (((e.user.visitorData || {}).conferenceUUID || []).length > 0) {
            return e.user.visitorData;
          }
        }
      });
      if (conferencePeople.length > 0) {
        for (let index = 0; index < conferencePeople.length; index++) {
          const element = conferencePeople[index];
          const confUUIDS = element.user.visitorData.conferenceUUID;
          for (let idx = 0; idx < confUUIDS.length; idx++) {
            const confUUID = confUUIDS[idx];
            if (conferencesIds.indexOf(confUUID) === -1) {
              conferencesIds.push(confUUID);
              const conferenceData =
                this.myConferenceDetailsEffect.find(
                  (e) => e.confId === confUUID
                ) || {};
              const dateTimeStart =
                conferenceData.confStartDate && conferenceData.confStartTime
                  ? conferenceData.confStartDate +
                    "T" +
                    conferenceData.confStartTime
                      .split(":")
                      .map((e) => e.padStart(2, "0"))
                      .join(":") +
                    ":00" +
                    (conferenceData.confTimezone || getTimezone(conferenceData.confStartDate, conferenceData.confStartTime))
                  : Date.now();
              conferences.push({
                id: confUUID,
                name: conferenceData.confName || "",
                members: conferenceData.confUUIDS || [],
                dateTimeStart: new Date(dateTimeStart),
              });
            }
          }
        }
        this.obConferences = {};
        conferences.forEach((element) => {
          const totalUsers = (element.members || []).length;
          let membersObject = {};
          for (let i = 0; i < element.members.length; i++) {
            const member = element.members[i];
            membersObject[member] = this.state.group[member];
          }
          let data = {};
          data[element.id] = {
            confId: element.id,
            confTile: element.name,
            idsOnline: conferencePeople
              .filter(
                (e) =>
                  ((e.user.visitorData || {}).conferenceUUID || []).indexOf(
                    element.id
                  ) !== -1
              )
              .map((y) => y.uuid),
            totalUsers: totalUsers,
            isConference: true,
            dateTimeStart: element.dateTimeStart,
            members: membersObject,
          };
          Object.assign(this.obConferences, data);
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];
              for (const index in element.members) {
                if (Object.hasOwnProperty.call(element.members, index)) {
                  if (element.members[index]) {
                    const member = JSON.parse(
                      JSON.stringify(element.members[index])
                    );
                    if (member.connected && member.user.visitor) {
                      member.user.visitorData.currentConference =
                        element.confId;
                      waitingPeople.push(member);
                    }
                  }
                }
              }
            }
          }
        });
      }
      let finalObject = this.getFinalPersons(waitingPeople);
      if (this.selectedConference !== "") {
        // return only the conference object
        finalObject = [];
        for (const i in this.obConferences) {
          if (Object.hasOwnProperty.call(this.obConferences, i)) {
            const conference = this.obConferences[i];
            if (conference.confId === this.selectedConference) {
              finalObject.push(conference);
              // return finalObject;
            }
          }
        }
        for (let uuid in this.obConferences[this.selectedConference].members) {
          if (uuid) {
            const person = store.state.group[uuid];
            if (!person || !person.user) {
              continue;
            } else {
              finalObject.push({ ...person, uuid });
            }
          }
        }
        return finalObject;
      } else {
        return finalObject;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.iconCardsContainer {
  position: absolute;
  top: 0;
  right: 0;
  background: unset;
  height: 28px;
}
.conferenceCard {
  height: 100%;
  background-color: #f5f5f5 !important;
}
.titleConferenceCard {
  font-size: 18px;
  background-color: white !important;
}
.firstCard {
  border: 2px solid white !important;
  max-width: 235px !important;
  min-width: 220px!important
}
.cursorPointer {
  cursor: pointer;
}
.paginationPosition {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}
.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}
.divAddBox {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.divAdd {
  text-align: center;
  width: 100%;
}
.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  position: relative;
}
.contentGridWrapper {
  height: calc(100vh - 195px);
  overflow: auto;
}
.custom6cols {
  width: 16%;
  flex-basis: 16%;
}
.cardWidth.wr {
  max-width: 235px !important;
  box-shadow: none !important;
}
@media (max-width: 765px) {
  .custom6cols {
    width: 50% !important;
    min-width: 50% !important;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
.footerCard {
  background: white;
  padding: 0 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.v-card__subtitle {
  color: black !important;
}
.v-card__title {
  font-size: 15px !important;
}
</style>
