 <template>
    <div id="notes" class="notes">
      <multi-notes></multi-notes>
    </div>
 </template>

<script>
  import multiNotes from './multiNotes.vue';
  export default {
    components: {
      'multi-notes': multiNotes,
    },
    data() {
      return {};
    },
  }
</script>