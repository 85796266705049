<template>
  <div class="document-list">
    <!-- Display each document as a clickable link -->
    <v-row>
      <div @click.stop v-for="(document, index) in documents" :key="index" class="pr-4">
        <a
          :href="getDownloadUrl(document)"
           target="_blank"
          class="document-link"
        >
          {{ document.name }}
        </a>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['documents'],
  methods: {
    // Generate a URL from the Base64 string
    getDownloadUrl(document) {
      if(!document.base64 || !document.type) return
      const cleanBase64 = document.base64.includes(",") ? document.base64.split(",")[1] : document.base64; // Remover encabezado
      const blob = this.base64ToBlob(cleanBase64, document.type);
      return URL.createObjectURL(blob);
    },
    
    // Convert Base64 to Blob
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        byteArrays.push(new Uint8Array(byteNumbers));
      }

      return new Blob(byteArrays, { type: mimeType });
    }
  }
};
</script>

<style scoped>
.document-list {
  padding-left: 12px;
  padding-bottom: 16px;
}

.document-link {
  display: block;
  padding: 5px 0;
  margin-bottom: 8px;
  text-align: left;
  text-decoration: underline;
  color: #0066cc;
  transition: color 0.2s;
  cursor: pointer;
}

.document-link:hover {
  color: #004a99;
}
</style>
