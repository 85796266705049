var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAlertMessages
    ? _c("div", [
        _c("div", { staticClass: "blinkContainer" }, [
          _vm.micMute
            ? _c("div", { staticClass: "microphoneSlashOverlay mr-2" }, [
                _c("img", { attrs: { src: "/img/micro-full-red.svg" } })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.audioOutputMuted
            ? _c("div", { staticClass: "soundSlashOverlay mr-2" }, [
                _c("img", { attrs: { src: "/img/volume-slash-red-red.svg" } })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cameraOffByModerator
            ? _c("div", { staticClass: "cameraOffSlashOverlay mr-2" }, [
                _c("img", { attrs: { src: "/img/user-camera-slash-red.svg" } })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.getBadQuality
          ? _c("div", { staticClass: "badQualityText" }, [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.bridgeCallTooltips.badQuality")) +
                  "\n  "
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }