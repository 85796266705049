var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "pa-0 col-xl-2 col-lg-2 col-md-3 col-sm-3 px-2 d-flex justify-end white"
    },
    [
      _c(
        "div",
        {},
        [
          _vm.amIModerator
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-1",
                                  attrs: { color: "primary", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openConferenceDocumentsModal()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "hidden-md-and-down pl-1 mr-3"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.addpdfConference"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  style: { fontSize: "12px" },
                                  attrs: { icon: ["fal", "plus"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1538548421
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.conferenceForm.addpdfConference")
                      )
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.amIModerator
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-1",
                                  attrs: { color: "primary", "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openConferenceDocumentsModal()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "hidden-md-and-down pl-1 mr-3"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.PDFDoc"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  staticClass: "d-lg-none",
                                  style: { fontSize: "12px" },
                                  attrs: { icon: ["fal", "plus"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3658567735
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.conferenceForm.PDFDoc")))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showConferenceDocumentsModal
        ? _c("ConferenceDocuments", {
            attrs: {
              showModal: _vm.showConferenceDocumentsModal,
              amIModerator: _vm.amIModerator
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }