var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.group
    ? _c(
        "div",
        [
          _c("label", { staticClass: "ml-2 userName font-weight-medium" }, [
            _vm._v(_vm._s(_vm.group.groupName))
          ]),
          _vm._v(" "),
          _vm.group.groupId
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    scrollable: "",
                    "close-on-click": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "groupCardEllipses",
                                  attrs: { icon: "" }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "ellipsis-v"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    871106781
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.editGroup(_vm.group)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.addGroupModal.editGroup")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.createFromExistingGroup(
                                _vm.group.groupId
                              )
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.addGroupModal.setConfForGroup"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.callMembers(_vm.group.groupId)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.$t("components.addGroupModal.callAll"))
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.checkSendMessage(_vm.group.groupUUIDS)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.addGroupModal.sendMessageToAll"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.preDeleteGroup(
                                $event,
                                _vm.group.groupId
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-list-item-title",
                                          _vm._g(
                                            {
                                              class: !_vm.deleteActive
                                                ? "deleteBasketLinkNormal"
                                                : "deleteBasketLinkActive"
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.addGroupModal.deleteGroup"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3507666043
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.conferenceForm.confirmRemove"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.setFavorite(_vm.group.groupId)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.group.favorite
                                  ? _vm.$t(
                                      "components.addGroupModal.unsetFavoriteGroup"
                                    )
                                  : _vm.$t(
                                      "components.addGroupModal.setFavoriteGroup"
                                    )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }