var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "550" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t(
                "components.bridgeCallTooltips.streamSettings"
              ),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticStyle: { "padding-bottom": "0px" } },
            [
              _c("v-text-field", {
                staticClass: "body-2",
                attrs: {
                  disabled: _vm.useInternalPlayer,
                  label: _vm.$t("components.bridgeCallTooltips.streamKey"),
                  type: "password"
                },
                model: {
                  value: _vm.streamKey,
                  callback: function($$v) {
                    _vm.streamKey = $$v
                  },
                  expression: "streamKey"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "body-2",
                attrs: {
                  disabled: _vm.useInternalPlayer,
                  label: _vm.$t("components.bridgeCallTooltips.streamUrl")
                },
                model: {
                  value: _vm.streamUrl,
                  callback: function($$v) {
                    _vm.streamUrl = $$v
                  },
                  expression: "streamUrl"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "px-6 pt-0",
              staticStyle: { display: "inline-block" },
              attrs: { cols: "12" }
            },
            [
              _c("v-text-field", {
                staticClass: "body-2",
                attrs: {
                  label: "Viewing Url",
                  "hide-details": "auto",
                  prefix: _vm.permaurl,
                  rules: _vm.isDuplicated ? _vm.dupRules : _vm.ignoreRules
                },
                on: {
                  blur: function($event) {
                    return _vm.checkExistance()
                  }
                },
                model: {
                  value: _vm.urlLinkParam,
                  callback: function($$v) {
                    _vm.urlLinkParam = $$v
                  },
                  expression: "urlLinkParam"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "mx-0 w100" },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.selectedWallpaper,
                        callback: function($$v) {
                          _vm.selectedWallpaper = $$v
                        },
                        expression: "selectedWallpaper"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pRelative colImage" },
                        [
                          _c("v-radio", {
                            attrs: { value: "img/pause.png" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("v-img", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        src: "/img/pause.png",
                                        "max-height": "70",
                                        "max-width": "70",
                                        height: "70",
                                        width: "70",
                                        contain: ""
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.wallpapersList, function(path) {
                        return _c(
                          "v-col",
                          { key: path, staticClass: "pRelative colImage" },
                          [
                            _c("v-radio", {
                              attrs: { value: path },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("v-img", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            src: path,
                                            "max-height": "70",
                                            "max-width": "70",
                                            height: "70",
                                            width: "70",
                                            contain: ""
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "buttonDeleteWallpaper",
                                            attrs: {
                                              icon: "",
                                              color: "primary"
                                            }
                                          },
                                          [
                                            _c("DeleteBasket", {
                                              attrs: {
                                                indexData: path,
                                                delFunction:
                                                  _vm.deleteWallpaper,
                                                extraClasses: "",
                                                fontSize: "20",
                                                prevent: "true"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    ref: "uploadfile",
                    attrs: {
                      type: "file",
                      accept: "image/x-png,image/gif,image/jpeg"
                    },
                    on: { change: _vm.uploadWallpaper }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.wallpapersList.length > 2,
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs["uploadfile"].click()
                        }
                      }
                    },
                    [_vm._v(_vm._s("Upload Wallpaper"))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "px-0 py-0", attrs: { cols: "12" } },
            [
              _c("v-checkbox", {
                staticClass: "internalPlayerCB",
                attrs: {
                  label: _vm.$t(
                    "components.bridgeCallTooltips.useInternalPlayer"
                  )
                },
                on: {
                  change: function($event) {
                    return _vm.internalPlayerChange($event)
                  }
                },
                model: {
                  value: _vm.useInternalPlayer,
                  callback: function($$v) {
                    _vm.useInternalPlayer = $$v
                  },
                  expression: "useInternalPlayer"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal, showClose: true } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.saveStreamData()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }