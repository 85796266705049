var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "720" },
      model: {
        value: _vm.dailyReportModal,
        callback: function($$v) {
          _vm.dailyReportModal = $$v
        },
        expression: "dailyReportModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal:
                _vm.$t("components.adminSettingsModal.dailyReport") +
                " " +
                _vm.getDailyReportDate.getUTCDate() +
                "." +
                (_vm.getDailyReportDate.getUTCMonth() + 1) +
                "." +
                _vm.getDailyReportDate.getFullYear(),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "mx-auto py-4" },
            [
              _c("v-textarea", {
                attrs: {
                  disabled: _vm.disabledDailyReport,
                  outlined: "",
                  dense: "",
                  rows: "8",
                  counter: 1000,
                  maxlength: "1000",
                  label: _vm.$t(
                    "components.adminSettingsModal.addDailyReportTT"
                  ),
                  "no-resize": ""
                },
                model: {
                  value: _vm.dailyReportText,
                  callback: function($$v) {
                    _vm.dailyReportText = $$v
                  },
                  expression: "dailyReportText"
                }
              }),
              _vm._v(" "),
              _vm.disabledDailyReport
                ? _c("span", { staticClass: "red--text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("components.dailyReport.reportPerDay"))
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: {
                    color: "primary",
                    disabled: _vm.disabledSave || _vm.disabledDailyReport
                  },
                  on: { click: _vm.sendReport }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }