<template>
    <v-row justify="end" class="mt-2 pb-2">
        <v-btn @click.stop="toogleLike" variant="text" class="me-4">
            <v-icon size="20">{{ isLiked ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon>
            <span class="ms-2 capitalize-first-letter font-weight-regular">{{ $t("components.socialMedia.like") }}</span>
            <span v-if="totalLikes > 0" class="ms-2 font-weight-regular">({{ totalLikes }})</span>
        </v-btn>
        <v-btn @click.stop="toggleComments" variant="text">
            <v-icon size="20">mdi-chat-outline</v-icon>
            <span class="ms-2 text-capitalize font-weight-regular">{{ $t("components.socialMedia.comment") }}</span>
        </v-btn>
        <v-btn density="compact" @click.stop="toggleComments" variant="text" style="height: 36px;">
            <span class="ms-2 text-capitalize font-weight-regular">{{ getTotalComments }} {{ getTotalComments === 1 ? $t("components.socialMedia.singularComment") : $t("components.socialMedia.comments") }}</span>
        </v-btn>
    </v-row>
</template>

<script>
import store from "../../../../store";

export default {
    components: {},
    props: ['toggleComments', 'postData', 'toogleLike'],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
        };
    },
    methods: {
       
    },
    computed: {
        getTotalComments(){
            return this.postData?.comments?.length || 0
        },
        totalLikes() {
            return this.postData?.likes?.length || 0
        },
        isLiked() {
            // Verificar si ownUUID está dentro del array de likes
            return this.postData?.likes?.includes(this.ownUUID);
        }
    }
};
</script>
<style scoped lang="scss">
.capitalize-first-letter {
    text-transform: lowercase;
}

.capitalize-first-letter::first-letter {
    text-transform: uppercase;
}

</style>