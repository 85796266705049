var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v("Ws Connected: " + _vm._s(_vm.wsConnected))]),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        _vm._s(_vm.thisGuyName) + "'s Activity: " + _vm._s(_vm.thisGuyActivity)
      )
    ]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.index) + " " + _vm._s(_vm.thisGuysUUID))]),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        _vm._s(_vm.timelineEvents.length) +
          " " +
          _vm._s(_vm.unknownTimelineEvents.length)
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }