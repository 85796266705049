// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerModal[data-v-962d38cc] {
  overflow: visible !important;
}
.footerModalMobile[data-v-962d38cc] {
  overflow: visible !important;
  padding: 8px 4px !important;
}
.footerModalMobile[data-v-962d38cc]  .v-row {
  flex-wrap: nowrap !important;
}
.footerModalMobile[data-v-962d38cc]  .v-btn {
  padding: 0 8px !important;
}
.footerModalMobile[data-v-962d38cc]  .greyBtn {
  margin: 0 0 0 4px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/holidaysCalendarModal.vue","webpack://./holidaysCalendarModal.vue"],"names":[],"mappings":"AACA;EACE,4BAAA;ACAF;ADEA;EACE,4BAAA;EACA,2BAAA;ACCF;ADAE;EACE,4BAAA;ACEJ;ADAE;EACE,yBAAA;ACEJ;ADAE;EACE,4BAAA;ACEJ","sourcesContent":["\n.footerModal {\n  overflow: visible !important;\n}\n.footerModalMobile {\n  overflow: visible !important;\n  padding: 8px 4px !important;\n  :deep() .v-row {\n    flex-wrap: nowrap !important;\n  }\n  :deep() .v-btn {\n    padding: 0 8px !important;\n  }\n  :deep() .greyBtn {\n    margin: 0 0 0 4px !important;\n  }\n}\n",".footerModal {\n  overflow: visible !important;\n}\n\n.footerModalMobile {\n  overflow: visible !important;\n  padding: 8px 4px !important;\n}\n.footerModalMobile :deep() .v-row {\n  flex-wrap: nowrap !important;\n}\n.footerModalMobile :deep() .v-btn {\n  padding: 0 8px !important;\n}\n.footerModalMobile :deep() .greyBtn {\n  margin: 0 0 0 4px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
