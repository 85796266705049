var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-badge",
    {
      attrs: {
        bordered: "",
        color: "error",
        content: _vm.totalNotificationFile,
        value: _vm.totalNotificationFile,
        overlap: ""
      }
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "close-on-click": true,
            "max-height": 370,
            "max-width": 350
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: "",
                                            color: _vm.iconsBlack
                                              ? "black"
                                              : "white",
                                            id: "v-tour-files"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setTotalNotificationFile(
                                                0
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "16px" },
                                          attrs: {
                                            icon: ["fal", "upload"],
                                            rotation: 180
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.drawerContentCard.files")
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_vm._v(" "), _c("DownloadsDropdown")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }