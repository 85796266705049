<template>
  <v-dialog persistent v-model="state.showNote.note" max-width="600" @keydown.esc="closeModal">
    <v-col :md="12" :sm="12" class="noteDiv px-1 col-12">
      <v-card>
         <HeaderModal :titleModal="$t('components.modalNote.newInfoToUser', [selectionName]) " :closeModalFunction="closeModal" />
        <!-- <v-list-item
          style="font-weight:bold; font-size:15px; color: var(--v-primary-base) !important;"
        >
          <v-img class="mr-1 logoModal" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
            <v-col :class="selectionClass" cols="11" style="text-align:center">
              <p  style="margin:0 auto;font-weight:500;">
                {{$t('components.modalNote.newInfoToUser', [selectionName])}}
              </p>
            </v-col>
        </v-list-item> -->
      </v-card>
      <v-card v-if="editing  || this.inserting" outlined flat class="single-note add-note">
        <v-list-item three-line class="white-note">
          <v-list-item-content>
            <!-- All Users -->
            <v-checkbox
              class="mencheckbox allusers"
              v-model="selectAll"
              :label="$t('components.modalNote.allUsers')"
              @click="setSelectedArea('allUsers','allUsers')"
              readonly="readonly"
            ></v-checkbox>

            <!-- Sections selector -->
            <v-menu
              max-height="400"
              bottom
              origin="center center"
              transition="scale-transition"
              :close-on-click="closeOnClick"
              :close-on-content-click="false"
              v-model="isSection"
 
              
            >
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-on="on"
                  v-model="sectionsChecked"
                  class="mencheckbox sections cbNoteHead"
                  :label="$t('components.modalNote.section')"
                  readonly="readonly"
                ></v-checkbox>
              </template>
              <v-list style="padding-bottom:0px !important">
                <v-list-item>
                  <div style="margin: 8px;">
                    <v-list-item-title class="py-0">
                      <v-text-field
                        :label="$t('components.modalNote.findSection')"
                        style="font-weight: 600;font-size: 19px; !important"
                        v-model="selectionSearchTerm"
                      ></v-text-field>
                    </v-list-item-title>
                  </div>
                </v-list-item>
                <div class="confined">
                  <v-list-item
                    v-for="(item) in sectionData"
                    :key="item.name"
                    class="mencheckbox"
                    item-text="name"
                    :class="{ selactive: item.name === activeItem}"
                  >
                    <!-- <v-list-item-content> -->
                      <v-list-item-title
                        v-model="state.showModalMultiSelectNote.info.selectSection"
                        class="mencheckbox listOrgItem"
                        v-text="item.name"
                        @click="setSelectedArea('section',item.name)"
                      ></v-list-item-title>
                    <!-- </v-list-item-content> -->
                    <!-- <v-checkbox
                      v-model="state.showModalMultiSelectNote.info.selectSection"
                      class="mencheckbox"
                      :label="item.name"
                      :value="item.name"
                    ></v-checkbox>-->
                  </v-list-item>
                </div>
                <v-list-item class="select-box"></v-list-item>
                <!-- <v-list-item
                  v-for="(item, y) in dataCombobox.sections"
                  :key="y"
                  class="mencheckbox section"
                  item-text="name"
                >
                  <v-checkbox
                    v-model="state.showModalMultiSelectNote.info.selectSection"
                    class="mencheckbox"
                    :label="item.name"
                    :value="item.name"
                  ></v-checkbox>
                </v-list-item>-->
              </v-list>
            </v-menu>
            <!-- End Sections Selector -->
            <!-- Department selector -->
            <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
              :close-on-click="closeOnClick"
              :close-on-content-click="false"
              v-model="isDepartment"
            >
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-on="on"
                  v-model="departmentsChecked"
                  class="mencheckbox departments cbNoteHead"
                  :label="$t('components.modalNote.department')"
                  readonly="readonly"
                ></v-checkbox>
              </template>
              <v-list style="padding-bottom:0px !important">
                <v-list-item>
                  <div style="margin: 8px;">
                    <v-list-item-title class="py-0">
                      <v-text-field
                        :label="$t('components.modalNote.findDepartments')"
                        style="font-weight: 600;font-size: 19px; !important"
                        v-model="selectionSearchTerm"
                      ></v-text-field>
                    </v-list-item-title>
                  </div>
                </v-list-item>
                <div class="confined">
                  <v-list-item
                    v-for="(item) in departmentData"
                    :key="item.name"
                    class="mencheckbox"
                    item-text="name"
                    :class="{ selactive: item.name === activeItem}"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-model="state.showModalMultiSelectNote.info.selectDepartments"
                        class="mencheckbox listOrgItem"
                        v-text="item.name"
                         @click="setSelectedArea('department',item.name)"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <!-- <v-checkbox
                      v-model="state.showModalMultiSelectNote.info.selectDepartments"
                      class="mencheckbox"
                      :label="item.name"
                      :value="item.name"
                    ></v-checkbox>-->
                  </v-list-item>
                </div>
                <v-list-item class="select-box"></v-list-item>
                <!-- <v-list-item
                  v-for="(item, y) in dataCombobox.departments"
                  :key="y"
                  class="mencheckbox"
                  item-text="name"
                >
                  <v-checkbox
                    v-model="state.showModalMultiSelectNote.info.selectDepartments"
                    class="mencheckbox"
                    :label="item.name"
                    :value="item.name"
                  ></v-checkbox>
                </v-list-item>-->
              </v-list>
            </v-menu>
            <!-- End Department Selector -->
            <!-- Teams selector -->
            <v-menu
              max-height="400"
              bottom
              origin="center center"
              transition="scale-transition"
              :close-on-click="closeOnClick"
              :close-on-content-click="false"
              v-model="isTeam"
            >
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-on="on"
                  v-model="teamsChecked"
                  class="mencheckbox teams cbNoteHead"
                  :label="$t('components.modalNote.team')"
                  readonly="readonly"
                ></v-checkbox>
              </template>
              <v-list style="padding-bottom:0px !important">
                <div style="margin: 8px;">
                  <v-list-item-title class="py-0">
                    <v-text-field
                      :label="$t('components.modalNote.findTeams')"
                      style="font-weight: 600;font-size: 19px; !important"
                      v-model="selectionSearchTerm"
                    ></v-text-field>
                  </v-list-item-title>
                </div>
                <div class="confined">
                  <v-list-item
                    v-for="(item) in teamsData"
                    :key="item.name"
                    :class="{ selactive: item.name === activeItem}"
                    class="mencheckbox"
                    active-class="activeSelected"
                    item-text="name"
                  >
                  

                    <v-list-item-content>
                      <v-list-item-title
                        v-model="state.showModalMultiSelectNote.info.selectTeams"
                        class="mencheckbox listOrgItem"
                        v-text="item.name"
                        @click="setSelectedArea('team',item.name)"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <!-- <v-checkbox
                      v-model="state.showModalMultiSelectNote.info.selectTeams"
                      class="mencheckbox"
                      :label="item.name"
                      :value="item.name"
                    ></v-checkbox>-->
                  </v-list-item>
                </div>
                <v-list-item class="select-box"></v-list-item>
              </v-list>
            </v-menu>

            <!-- End Teams Selector -->

            <!-- <v-col :class="selectionClass" cols="12" style="text-align:center">
              <span  style="margin:0 auto;font-weight:500;">{{selectionName}}</span>
            </v-col> -->

            <v-list-item-title class="py-0" style="margin-left:60px">
              <v-text-field
              class="noteEntryText"
                :rules="rules"
                :counter="titleMax"
                @keydown="onTitleKeyDown"
                :label="$t('components.firstModal.title')"
                v-model="noteTitle"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-subtitle style="margin-left:60px">
              <v-textarea
               class="noteEntryText"
                style="font-size: 20px; !important"
                :rules="ruledesc"
                :counter="descMax"
                @keydown="onDescKeyDown"
                name="input-7-1"
                no-resize
                v-model="noteText"
                :class="isMessageExpanded(state.showNote.note.text) === true ? 'messageTextExpand': 'messageText'"
              ></v-textarea>
            </v-list-item-subtitle>
            <v-row :style="{width: '100%', margin: 0}" class="px-5 mx-0">
              <v-col cols="12" class="px-0" style="margin-left:39px">
                <v-menu
                  v-model="notedate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formatDateActiveUntil"
                      :label="$t('components.modalNote.postingUntil')"
                      v-on="on"
                    >
                     <!-- v-model="formatDateActiveUntil" -->
                      <font-awesome-icon
                        :icon="['fal', 'calendar-alt']"
                        :style="{ fontSize: '20px' } "
                        slot="prepend"
                      />
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :show-current="true"
                    v-model="state.showModalMultiSelectNote.activeUntil"
                    @input="notedate = false"
                    :pickTime="true"
                    :format="'YYYY-MM-DD H:mm'"
                    :locale="this.$locale.current()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="px-2 btns mx-0" v-if="1 === 2">
              <v-col lg="12" class="px-0 py-0" align="left">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      v-if="amIAdmin"
                      @click="toggleMultiSender"
                      icon
                      color="primary"
                    >
                      <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                      <!-- <font-awesome-icon
                  :icon="['fal', 'lock']"
                  :style="{ fontSize: '14px' }"
                  class="lockMessageIcon"
                /> -->
                    </v-btn>
                  </template>
                  <span>{{ $t('components.modalNote.sendTo') }} ...</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!-- <label v-if="amIAdmin" @click="toggleMultiSender">{{ $t('components.modalNote.sendTo') }} ... </label> -->
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="primary">
          <v-layout justify-start>
             <v-list-item-subtitle
              class="footerDate"
              style="text-align: left;"
            >{{moment(new Date).format("DD.MM.YYYY H:mm")}}</v-list-item-subtitle>
          </v-layout>
          <v-layout justify-end>
           
            <v-btn style="margin-left:19px; margin-right:10px"  color="primary" @click="confirmPublish()" :disabled="!isAllowToPublish">
             {{ $t('generics.publish') }} 
            </v-btn>
             <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" @click="setShowNote(false, undefined)">
                  <!-- <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" /> -->
                  {{ $t('generics.cancel') }}
                </v-btn>
              </template>
              <span>{{ $t('generics.cancel') }} </span>
            </v-tooltip>
          </v-layout>
        </v-card-actions>
        <!-- <div class="colors" :class="{openDivs: (editing === true) ?  currentID == state.showNote.note.id: currentID == newNote.id}">
          <div @click="changeColor('blue-note')" class="circle blue-note" :class="{selected: (editing === true) ? state.showNote.note.color == 'blue-note' : newNote.color == 'blue-note'}"></div>
              <div @click="changeColor('yellow-note')" class="circle yellow-note" :class="{selected: (editing === true) ? state.showNote.note.color == 'yellow-note' : newNote.color == 'yellow-note'}"></div>
              <div @click="changeColor('red-note')" class="circle red-note" :class="{selected: (editing === true) ? state.showNote.note.color =='red-note' : newNote.color == 'red-note'}"></div>
              <div @click="changeColor('purple-note')" class="circle purple-note" :class="{selected: (editing === true) ? state.showNote.note.color == 'purple-note' : newNote.color == 'purple-note'}"></div>
              <div @click="changeColor('white-note')" class="circle whiteCircle-note" :class="{selected: (editing === true) ? state.showNote.note.color == 'white-note' : newNote.color == 'white-note'}"></div>
        </div>-->
      </v-card>
      <v-card v-if="!editing && !inserting">
        <v-card-title class="headline">{{noteTitle}}</v-card-title>
        <v-divider></v-divider>

        <v-row class="px-5 mx-0">
          <vue-scroll>
            <div style="height: 300px">
              <div
                class="noteText"
                v-if="state.showNote"
                v-html="modifiedText(noteText)"
                :class="isMessageExpanded(noteText) === true ? 'messageTextExpand': 'messageText'"
              ></div>
            </div>
          </vue-scroll>
        </v-row>
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns mx-0">
            <v-col lg="12" class="px-0 py-0" align="right">
              <v-btn icon color="white">
                <font-awesome-icon
                  :icon="['fal', 'check']"
                  :style="{ fontSize: '20px' }"
                  @click="setShowNote(false, undefined)"
                />
              </v-btn>
              <!-- <v-btn color="primary" @click="setShowNote(false, undefined)">Accept</v-btn> -->
            </v-col>
          </v-row>
        </v-card>

        <v-spacer></v-spacer>
      </v-card>
    </v-col>
    <!-- Modal insert Note -->
    <v-dialog v-model="showModalInsertNote" persistent max-width="460" @keydown.esc="closeConfirmationModal">
      <v-card>
        <v-card-title class="headline">{{ $t('components.modalNote.publishNote') }}</v-card-title>
        <v-card-text v-if="inserting">{{$t('components.modalNote.sureWantPublishNote')}}</v-card-text>
        <v-card-text v-if="editing">{{$t('components.modalNote.sureWantUpdateNote')}}</v-card-text>
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" flex>
            <v-col cols="12" class="px-0 py-0" align="right">
              
              <v-btn
                color="primary"
                @click="insertNote"
              >{{(inserting === true) ? $t('components.modalNote.publishNote') : $t('components.modalNote.send') }}</v-btn>
              <v-btn color="primary" @click="closeConfirmationModal">{{ $t('generics.cancel') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <!-- End modal insert note -->
    <!-- <v-dialog persistent v-model="showModalMultiSelect" max-width="560" v-if="1 === 2">
      <v-card>
        <v-card-title class="headline">
          {{ $t('components.modalNote.sendTo') }} {{getNameForUuid(state.showModalMultiSelectNote.uuid)}}
          <v-checkbox
            style="margin-left: 210px;"
            v-model="sentToAll"
            :label="$t('components.modalNote.sendToAllUsers')"
            @change="handleSendToAll"
    ></v-checkbox>-->
    <!-- <label style="color: red; font-size: 13px; margin-left: 244px;">Functionality coming soon</label>  -->
    <!-- </v-card-title>
        <v-divider></v-divider>
        <v-row v-if="!sentToAll" :style="{width: '100%', margin: 0}" class="px-5">
          <v-col cols="6">
            <v-combobox
              v-model="state.showModalMultiSelectNote.info.selectSection"
              :items="dataCombobox.sections"
              item-text="name"
              label="Sections"
              multiple
              class="mx-1 label600"
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="state.showModalMultiSelectNote.info.selectDepartments"
              :items="dataCombobox.departments"
              item-text="name"
              label="Departments"
              multiple
              class="mx-1 label600"
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="state.showModalMultiSelectNote.info.selectTeams"
              :items="dataCombobox.teams"
              item-text="name"
              label="Teams"
              multiple
              class="mx-1 label600"
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="state.showModalMultiSelectNote.info.selectUsers"
              :items="dataCombobox.users"
              select
              item-text="name"
              label="Users"
              multiple
              class="mx-1 label600"
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns" flex :style="{width: '100%', margin: 0}">
            <v-col lg="6" class="px-0 py-0" align="right">
              <v-btn
                color="primary"
                @click="closeModalMultiSelectNote()"
              >{{ $t('generics.cancel') }}</v-btn>
              <v-btn
                color="primary"
                @click="preparedataToSendNote()"
                :disabled="false"
              >{{ $t('components.modalNote.sendTo') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card> 
    </v-dialog>-->
  </v-dialog>
</template>
<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";
import moment from "../../../sharedsrc/moment";
import { v4 as uuidv4 } from "uuid";
import { newNoteEvent } from "../../effector/notes";
import { getUsersFromOrganigramByName, getAllUsersWithPrivileges } from "../../utils/basicFunctions";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  data() {
    return {
      moment:moment,
      state: store.state,
      ownUUID: store.state.ownUUID,
      showModalInsertNote: false,
      sentToAll: true,
      titleMax: 100,
      descMax: 500,
      showUserProfile: false,
      editing: false,
      inserting: false,
      AllSelection: ["All"],
      search: null,
      rules: [
        value => !!value || this.$t('generics.required'),
        value => (value || "").length <= 100 || "Max 100 characters"
      ],
      ruledesc: [
        value => !!value || this.$t('generics.required'),
        value => (value || "").length <= 500 || "Max 500 characters",
        value => (value || "").length >= 5 || "Min 5 characters"

      ],
      isInsert: store.state.showNote.note.inserting,
      theNotes: this.todoFetch(),
      select: null,
      // The Note Props
      noteTitle: store.state.showNote.note.title || "",
      noteColor: "white-note", //store.state.showNote.note.noteColor || 'white-note',
      noteText: store.state.showNote.note.text || "",
      orgTargetUsers: [],
      // Temporary IDs
      currentID: -2,
      editId: -1,
      idToCopy: -1,
      // Transitions
      opened: false,
      shown: true,

      // Temporary IDs
      currentID: -2,
      editId: -1,
      idToCopy: -1,

      // author data
      author: store.state.ownUUID,
      authorName: store.state.user.name,
      showModalMultiSelect: false,
      selectSection:
        store.state.showModalMultiSelectNote.info.selectSection || [],
      selectDepartments:
        store.state.showModalMultiSelectNote.info.selectDepartments || [],
      selectTeams: store.state.showModalMultiSelectNote.info.selectTeams || [],
      selectUsers: store.state.showModalMultiSelectNote.info.selectUsers || [],
      closeOnClick: true,
      closeOnContent: false,
      notedate: "",
      selectionSearchTerm: "",
      effectiveTerm: "",
      selectionName: "Nothing selected",
      selectionClass:'selNormal',
      activeItem: null,
      isSection: false,
      isDepartment: false,
      isTeam: false,
      defaultDate:""
    };
  },
  components:{
    HeaderModal
  },
  beforeDestroy() {
    this.clearModal();
  },

  watch: {
    selectionSearchTerm: function(value) {
      this.effectiveTerm = value;
      // console.log("search value", value);
    },
    "state.showModalMultiSelectNote.info.selectTeams": function() {
      this.selectionSearchTerm = "";
      // this.state.showModalMultiSelectNote.info.selectTeams[0].active = true;
    },
    "state.showModalMultiSelectNote.info.selectSection": function() {
      this.selectionSearchTerm = "";
    },
    "state.showModalMultiSelectNote.info.selectDepartments": function() {
      this.selectionSearchTerm = "";
    },
  },
  methods: {
    closeModal(){
      this.setShowNote(false, undefined)
    },
    setSelectedArea(area, name) {

     this.isSection = false;
     this.isTeam = false;
     this.isDepartment = false;

     // remove selection
      if(this.activeItem === name){
        this.reset();
        area = 'allUsers';
        name = this.$t('components.modalNote.allUsers');
      }
      
      this.reset();
      // re-select Item
      switch (area) {
        case "section":
          store.state.showModalMultiSelectNote.info.selectSection = [name];
          this.selectionClass = 'selSection';
          this.activeItem = name;
          break;
        case "department":
          store.state.showModalMultiSelectNote.info.selectDepartments = [name];
           this.selectionClass = 'selDepartment';
            this.activeItem = name;
          break;
        case "team":
          store.state.showModalMultiSelectNote.info.selectTeams = [name];
           this.selectionClass = 'selTeam';
            this.activeItem = name;
          break;
        case "allUsers":
          store.state.showModalMultiSelectNote.info.selectUsers = [name];
           this.selectionClass = 'selAllUsers';
           this.activeItem = 'allUsers';
          break;

        default:
          break;
      }
      this.selectionName = name;
    },
    teamSelection() {
      this.selectionSearchTerm = "";
    },
    afterselection(item) {
      // console.log(item);
      this.$nextTick(() => {
        this.selected = null;
      });
    },
    handleSendToAll(e) {
      // console.log('33333333333333333333333333333',e)
    },
    onDescKeyDown(evt) {
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;
      if (this.noteText.length >= this.descMax) {
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 90) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105)
        ) {
          evt.preventDefault();
          return;
        }
      }
    },
    onTitleKeyDown(evt) {
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;
      if (this.noteTitle.length >= this.titleMax) {
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 90) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105)
        ) {
          evt.preventDefault();
          return;
        }
      }
    },
    reset() {
      this.state.showModalMultiSelectNote.info.selectSection = [];
      this.state.showModalMultiSelectNote.info.selectDepartments = [];
      this.state.showModalMultiSelectNote.info.selectTeams = [];
      this.state.showModalMultiSelectNote.info.selectUsers = [];
    },
    clearModal() {
      // console.log('clearing modal');
      this.state.showModalMultiSelectNote.info.selectSection = [];
      this.state.showModalMultiSelectNote.info.selectDepartments = [];
      this.state.showModalMultiSelectNote.info.selectTeams = [];
      this.state.showModalMultiSelectNote.info.selectUsers = [];
      this.state.showModalMultiSelectNote.activeFrom = "";
      this.state.showModalMultiSelectNote.activeUntil = "";
    },
    closeModalMultiSelectNote(cancel = true) {
      if (cancel == true) {
        if (this.inserting) {
          this.newNote.targetUsers = [];
        } else {
          this.state.showNote.note.targetUsers = this.orgTargetUsers;
        }
      }
      this.showModalMultiSelect = false;
      //this.clearModal();
    },
    toggleMultiSender() {
     // console.log("toggle multisender eeeeeeeeeeeeeeeeeeeeeeeee");
      if (this.showModalMultiSelect === false && this.editing) {
        this.clearModal();
        this.prepDataForCombos();
      } else if (this.inserting) {
        store.state.showModalMultiSelectNote.info.selectUsers = [];
      }
      this.showModalMultiSelect = !this.showModalMultiSelect;
    },
    containsAll(targetUsers, group) {
      if (group.length && group.length > 0) {
        return group.every(i => targetUsers.includes(i));
      }
    },
    prepDataForCombos() {
      // set checkbox for selected users
      this.state.showNote.note.targetUsers.forEach(element => {
        store.state.showModalMultiSelectNote.info.selectUsers.push(
          store.state.group[element].user.name
        );
      });
      // set checkbox for teams selected
      const iTargetUsers = this.state.showNote.note.targetUsers;
      const separated = this.separatedPeople();
      const iteams = Object.keys(separated.teams);
      const isections = Object.keys(separated.sections);
      const idepartments = Object.keys(separated.departments);

      iteams.forEach(oteam => {
        if (this.containsAll(iTargetUsers, separated.teams[oteam])) {
          if (
            this.state.showModalMultiSelectNote.info.selectTeams.indexOf(
              oteam
            ) == -1
          ) {
            this.state.showModalMultiSelectNote.info.selectTeams.push(oteam);
          }
        }
      });

      isections.forEach(osection => {
        if (this.containsAll(iTargetUsers, separated.sections[osection])) {
          if (
            this.state.showModalMultiSelectNote.info.selectSection.indexOf(
              osection
            ) == -1
          ) {
            this.state.showModalMultiSelectNote.info.selectSection.push(
              osection
            );
          }
        }
      });

      idepartments.forEach(odepartment => {
        if (
          this.containsAll(iTargetUsers, separated.departments[odepartment])
        ) {
          if (
            this.state.showModalMultiSelectNote.info.selectDepartments.indexOf(
              odepartment
            ) == -1
          ) {
            this.state.showModalMultiSelectNote.info.selectDepartments.push(
              odepartment
            );
          }
        }
      });
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    // preparedataToSendNote() {
    //   let userToSend = [];
    //   const separate = this.separatedPeople();
    //   console.log("inserting", this.inserting, " editing ", this.editing);
    //   if (this.inserting) {
    //     userToSend = this.newNote.targetUsers;
    //   } else {
    //     console.log(
    //       this.state.showModalMultiSelectNote.info.selectUsers,
    //       "users in control"
    //     );
    //     let usersIncontrol = this.state.showModalMultiSelectNote.info
    //       .selectUsers;
    //     let finalUsers = [];
    //     if (!this.sentToAll) {
    //       usersIncontrol.forEach(username => {
    //         this.state.showNote.note.targetUsers.forEach(uuid => {
    //           if (this.state.group[uuid].user.name === username) {
    //             if (finalUsers.indexOf(uuid) == -1) {
    //               finalUsers.push(uuid);
    //             }
    //           }
    //         });
    //       });
    //     }
    //     userToSend = finalUsers;
    //     console.log(this.state.showNote.note.targetUsers, "users in note");
    //   }

    //   // add user to send
    //   // // get user

    //   for (const user in this.state.showModalMultiSelectNote.info.selectUsers) {
    //     console.log(user.uuid, "user found selected");
    //     if (this.state.showModalMultiSelectNote.info.selectUsers[user].uuid) {
    //       if (userToSend.indexOf(user.uuid) == -1) {
    //         userToSend.push(
    //           this.state.showModalMultiSelectNote.info.selectUsers[user].uuid
    //         );
    //       }
    //     }
    //   }
    //   // get user teams
    //   for (const key in this.state.showModalMultiSelectNote.info.selectTeams) {
    //     const teamsSelected = this.state.showModalMultiSelectNote.info
    //       .selectTeams[key].name;
    //     for (const index in separate.teams[teamsSelected]) {
    //       const uuid = separate.teams[teamsSelected][index];
    //       if (userToSend.indexOf(uuid) == -1) {
    //         userToSend.push(uuid);
    //       }
    //     }
    //   }
    //   // get user sections
    //   for (const key in this.state.showModalMultiSelectNote.info
    //     .selectSection) {
    //     const sectionSelected = this.state.showModalMultiSelectNote.info
    //       .selectSection[key].name;
    //     for (const index in separate.sections[sectionSelected]) {
    //       const uuid = separate.sections[sectionSelected][index];
    //       if (uuid !== this.state.ownUUID && userToSend.indexOf(uuid) == -1) {
    //         userToSend.push(uuid);
    //       }
    //     }
    //   }

    //   // // get user sections
    //   for (const key in this.state.showModalMultiSelectNote.info
    //     .selectSection) {
    //     const sectionsSelected = this.state.showModalMultiSelectNote.info
    //       .selectSection[key].name;
    //     for (const index in separate.sections[sectionsSelected]) {
    //       const uuid = separate.sections[sectionsSelected][index];
    //       if (uuid !== this.state.ownUUID && userToSend.indexOf(uuid) == -1) {
    //         userToSend.push(uuid);
    //       }
    //     }
    //   }
    //   if (this.inserting) {
    //     this.newNote.targetUsers = userToSend;
    //   } else {
    //     // editing
    //     this.state.showNote.note.targetUsers = userToSend;
    //   }
    //   this.closeModalMultiSelectNote(false);
    // },
    separatedPeople: function() {
      const result = { sections: {}, departments: {}, teams: {} };

      if (!store.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      const sections =
        store.state.namespaceSettings.processedOrganisation.sections || {};
      const departments =
        store.state.namespaceSettings.processedOrganisation.departments || {};
      const teams =
        store.state.namespaceSettings.processedOrganisation.teams || {};

      if (Object.keys(sections).length > 0) {
        Object.keys(sections).forEach(orgKey => {
          if (!result.sections[orgKey]) result.sections[orgKey] = [];
          for (var i = 0; i < sections[orgKey].supervisors.length; i++) {
            if (
              this.ownUUID !== sections[orgKey].supervisors[i].uuid &&
              !result.sections[orgKey].includes(
                sections[orgKey].supervisors[i].uuid
              )
            ) {
              result.sections[orgKey].push(
                sections[orgKey].supervisors[i].uuid
              );
            }
          }

          for (var i = 0; i < sections[orgKey].departments.length; i++) {
            const departKey = sections[orgKey].departments[i];
            const department = departments[departKey];
            if (!result.departments[departKey])
              result.departments[departKey] = [];
            for (var j = 0; j < department.supervisors.length; j++) {
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.sections[orgKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.sections[orgKey].push(department.supervisors[j].uuid);
              }
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.departments[departKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.departments[departKey].push(
                  department.supervisors[j].uuid
                );
              }
            }

            for (
              var teamKey = 0;
              teamKey < department.teams.length;
              teamKey++
            ) {
              const team_key = department.teams[teamKey];
              const team = teams[team_key];
              if (!result.teams[team_key]) result.teams[team_key] = [];
              for (var j = 0; j < team.supervisors.length; j++) {
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.sections[orgKey].includes(team.supervisors[j].uuid)
                ) {
                  result.sections[orgKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.departments[departKey].includes(
                    team.supervisors[j].uuid
                  )
                ) {
                  result.departments[departKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.teams[team_key].includes(team.supervisors[j].uuid)
                ) {
                  result.teams[team_key].push(team.supervisors[j].uuid);
                }
              }

              for (var j = 0; j < team.members.length; j++) {
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.sections[orgKey].includes(team.members[j])
                ) {
                  result.sections[orgKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.departments[departKey].includes(team.members[j])
                ) {
                  result.departments[departKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.teams[team_key].includes(team.members[j])
                ) {
                  result.teams[team_key].push(team.members[j]);
                }
              }
            }
          }
        });
      }
      function sortObject(o) {
        return Object.keys(o)
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      result.sections = sortObject(result.sections);
      result.departments = sortObject(result.departments);
      result.teams = sortObject(result.teams);
      return result;
    },
    confirmPublish() {
      if (
        (this.noteTitle || "").trim() !== "" &&
        (this.noteText || "").trim() !== ""
      ) {
        
        this.showModalInsertNote = true;
      }
    },
    closeConfirmationModal() {
      this.showModalInsertNote = false;
    },
    todoFetch() {
      return store.state.namespaceSettings.notes || [];
    },
    toggleTransition(id) {
      if (id >= 0) {
        this.currentID = id;
      } else if (id == -1) {
        this.currentID = -1;
      }
      if (this.opened == false) {
        this.opened = true;
      } else {
        this.currentID = -2;
        this.opened = false;
      }
    },
    async todoSave(notes) {
     
      await setNamespaceSetting("notes", notes);
      // console.log(notes, 'merged note color')
      this.clearModal();
    },
    changeColor(color) {
      if (this.inserting) {
        this.newNote.color = color;
        this.noteColor = color;
      } else {
        this.state.showNote.note.color = color;
      }

      // this.insertNote();
      this.currentID = -2;
      this.opened = false;
    },
    async insertNote() {
      // console.log('inserting ' , this.editing);
      this.extrapolateColor();
      if (this.editing) {
        this.updateNote();
        return;
      }
      if (
        (this.noteTitle || "").trim() !== "" &&
        (this.noteText || "").trim() !== ""
      ) {
        // addRecipiants
        this.newNote.targetDepartments =
          store.state.showModalMultiSelectNote.info.selectDepartments;
        this.newNote.targetTeams =
          store.state.showModalMultiSelectNote.info.selectTeams;
        this.newNote.targetSections =
          store.state.showModalMultiSelectNote.info.selectSection;
        this.newNote.targetUsers =
          store.state.showModalMultiSelectNote.info.selectUsers;
        this.newNote.activeUntil =
          store.state.showModalMultiSelectNote.activeUntil;
        this.newNote.activeFrom = new Date().toISOString();
        //this.newNote.activeFrom = new Date().toLocaleDateString([], {
        //   day: "2-digit",
        //   month: "2-digit",
        //   year: "2-digit",
        //   hour: "2-digit",
        //   minute: "2-digit"
        // });
        this.newNote.noteColor = this.noteColor;

        // console.log(this.newNote, "this.newNote", this.newNote.id);
        // console.log('USERS TO SEND', this.newNote.targetDepartments, this.newNote.targetTeams, this.newNote.targetSections, this.newNote.targetUsers)
        let typeNote = '';
        let typeName = '';
        let usersList = [];
        if (this.newNote.targetSections.length > 0) {
          typeNote = 'section'
          typeName = this.newNote.targetSections[0];
        } else if (this.newNote.targetDepartments.length > 0) {
          typeNote = 'department'
          typeName = this.newNote.targetDepartments[0];
        } else if (this.newNote.targetTeams.length > 0) {
          typeNote = 'team';
          typeName = this.newNote.targetTeams[0];
        } else if ( this.newNote.targetUsers.length > 0 ) {
          typeNote = 'all';
          typeName = 'users';
        }
        if (typeNote === 'all') {
          usersList = getAllUsersWithPrivileges();
        } else {
          usersList = getUsersFromOrganigramByName(typeNote, typeName)
        }
        this.preparedataToSendNoteEffector(usersList, this.newNote.id)
        this.theNotes.push(this.newNote);
        // console.log(this.theNotes, "this.theNotes")
        await this.todoSave(this.theNotes);
        this.closeConfirmationModal();
        this.setShowNote(false, undefined, false);

        // Remove!
        this.noteTitle = "";
        this.noteText = "";
        // this.noteColor = "#fff";
      }
    },
    preparedataToSendNoteEffector(userList, noteUUID) {
      const userToSendMsg = {};
      userList.forEach(userUUID => {
        userToSendMsg[userUUID] = {
          userUUID,
          read: false,
          // replied: false,
          // deleted: false,
          // viewed: false,
        };
      });
      const dataMsg = {
        noteUUID,
        date: Date.now(),
        users: userToSendMsg,
        views: 0,
        creatorUUID: this.ownUUID,
      };
      // console.log('DATAMSG', dataMsg)
      newNoteEvent(dataMsg);
    },
    updateNote() {
     // console.log("updating");
      let doSave = false;
      let inote = this.state.showNote.note;
      inote.text = this.noteText;
      inote.title = this.noteTitle;
      if (inote.text.length > 106) {
        inote.long = true;
      } else {
        inote.long = false;
      }
      // UPDATE RECIPIENTS
      inote.targetDepartments =
        store.state.showModalMultiSelectNote.info.selectDepartments;
      inote.targetTeams = store.state.showModalMultiSelectNote.info.selectTeams;
      inote.targetSections =
        store.state.showModalMultiSelectNote.info.selectSection;
      inote.targetUsers = store.state.showModalMultiSelectNote.info.selectUsers;
      inote.activeUntil = store.state.showModalMultiSelectNote.activeUntil;
      inote.activeFrom = store.state.showModalMultiSelectNote.activeFrom;
      this.extrapolateColor();
      // GET INDEX OF EDITED NOTE
      for (const idx in this.theNotes) {
        if (this.theNotes[idx].id === inote.id) {
         
          inote.noteColor = this.noteColor;
         //  console.log(inote,'Before save');
          this.theNotes[idx] = inote;
          doSave = true;
          this.closeConfirmationModal();
          this.setShowNote(false, undefined, false);

          break;
        }
      }

      if (doSave) {
        this.todoSave(this.theNotes);
      }
    },
    setShowNote(showHidden, note) {
      // console.log('showing note', note)
      return store.setShowNote(showHidden, note, false);
    },
    modifiedText(text = "") {
      // Detect Links
      const detectLinks = /((https?:\/\/)(\S+))/g;
      const detectLinksWWW = /(^|[\s])(((www\.))(\S+))/g;

      if (text.match(detectLinks) || text.match(detectLinksWWW)) {
        return this.$sanitize(
          text
            .replace(
              detectLinks,
              '<a rel="noopener noreferrer" href="$1" target="_black">$1</a>'
            )
            .replace(
              detectLinksWWW,
              '$1<a rel="noopener noreferrer" href="https://$2" target="_blank">$2</a>'
            )
        );
      } else {
        return this.$sanitize(text);
      }
    },

    isMessageExpanded(message = "") {
      const lines = message.split("\n").length;
      if (message.length > 550 || lines > 3) {
        return true;
      }
      return false;
    },
    extrapolateColor() {
      // console.log('extrapolating....')
      if (
        store.state.showModalMultiSelectNote.info.selectTeams.length === 0 &&
        store.state.showModalMultiSelectNote.info.selectDepartments.length ===
          0 &&
        store.state.showModalMultiSelectNote.info.selectSection.length === 0
      ) {
        this.noteColor = "yellow-note";
      } else if (
        store.state.showModalMultiSelectNote.info.selectTeams.length > 0
      ) {
        this.noteColor = "lime-note";
      } else if (
        store.state.showModalMultiSelectNote.info.selectSection.length > 0
      ) {
        this.noteColor = "pink-note";
      } else if (
        store.state.showModalMultiSelectNote.info.selectDepartments.length > 0
      ) {
        this.noteColor = "blue-note";
      }
      // console.log(this.noteColor, 'resulting color')
    }
  },
  mounted: function() {
    
    
    this.editing = store.state.showNote.editnote;
    this.inserting = store.state.showNote.inserting;
    let targetTeams =
      ((store.state.showNote || {}).note || {}).targetTeam || [];

    // console.log('Mounted');
    // save copy of recipients before edit
    if (this.inserting) {
      this.newNote.targetUsers = [];
      this.clearModal();
      if (targetTeams.length) {
        store.state.showNote.note.targetTeams = targetTeams;
        store.state.showModalMultiSelectNote.info.selectTeams = targetTeams;
      }
    store.state.showModalMultiSelectNote.info.selectUsers = [name];
    this.selectionClass = 'selAllUsers';
    this.selectionName = this.$t('components.modalNote.allUsers');
    var udate = new Date();
    udate.setDate(udate.getDate() + 7);
    store.state.showModalMultiSelectNote.activeUntil = udate.toISOString().slice(0, 10);

    } else {
      store.state.showModalMultiSelectNote.info.selectTeams =
        store.state.showNote.note.targetTeams || [];
      store.state.showModalMultiSelectNote.info.selectDepartments =
        store.state.showNote.note.targetDepartments || [];
      store.state.showModalMultiSelectNote.info.selectSection =
        store.state.showNote.note.targetSections || [];
      store.state.showModalMultiSelectNote.activeUntil =
        store.state.showNote.note.activeUntil || "";
      store.state.showModalMultiSelectNote.activeFrom =
        store.state.showNote.note.activeFrom || "";

      this.orgTargetUsers = this.state.showNote.note.targetUsers;
      if (this.state.showNote.note.targetUsers.length > 0) {
        this.sentToAll = false;
      }

      // editing defaults
      if(store.state.showNote.note.targetTeams.length > 0){
         store.state.showModalMultiSelectNote.info.selectTeams = [store.state.showNote.note.targetTeams[0]];
           this.selectionClass = 'selTeam'
           this.selectionName = store.state.showNote.note.targetTeams[0];
       } else if(store.state.showNote.note.targetDepartments.length > 0){
          store.state.showModalMultiSelectNote.info.selectDepartments = [store.state.showNote.note.targetDepartments[0]];
           this.selectionClass = 'selDepartment';
           this.selectionName = store.state.showNote.note.targetDepartments[0];
      }else if(store.state.showNote.note.targetSections.length > 0){
          store.state.showModalMultiSelectNote.info.selectSection = [store.state.showNote.note.targetSections[0]];
          this.selectionClass = 'selSection';
          this.selectionName = store.state.showNote.note.targetSections[0];
      }  else {
           store.state.showModalMultiSelectNote.info.selectUsers = ['allUsers'];
           this.selectionClass = 'selAllUsers';
           this.selectionName = this.$t('components.modalNote.allUsers');
       }
    }
  },

  computed: {
    formatDateActiveUntil:{
      get: function(){
        if (store.state.showModalMultiSelectNote.activeUntil.length > 4){
          this.defaultDate = this.moment(new Date(store.state.showModalMultiSelectNote.activeUntil)).format("DD.MM.YYYY");
        } else {
          this.defaultDate = this.moment(new Date).add(7,'days').format("DD.MM.YYYY");
        }
        return  this.defaultDate;
      },
      set: function(value){
        this.defaultDate = value;
        // console.log('computed val ', this.defaultDate);
        store.state.showModalMultiSelectNote.activeUntil = "";

      }
    },
    isAllowToPublish(){
      if((this.noteTitle.length > this.titleMax || this.noteTitle.length < 2) || (this.noteText.length > this.descMax || this.noteText.length < 5)){
        return false;
      }
      return true;
    },
    isSelected(){

    },
    selectAll() {
      return (
        ((store.state.showModalMultiSelectNote.info.selectTeams || []).length ||
          0) === 0 &&
        store.state.showModalMultiSelectNote.info.selectDepartments.length ===
          0 &&
        store.state.showModalMultiSelectNote.info.selectSection.length === 0
      );
    },
    canSelectAll() {
      // console.log(store.state.showModalMultiSelectNote.info.selectTeams.length !== 0 ,   store.state.showModalMultiSelectNote.info.selectDepartments.length !== 0 , store.state.showModalMultiSelectNote.info.selectSection.length !== 0)

      let allowAll = "";
      if (
        ((store.state.showModalMultiSelectNote.info.selectTeams || []).length ||
          0) !== 0 ||
        store.state.showModalMultiSelectNote.info.selectDepartments.length !==
          0 ||
        store.state.showModalMultiSelectNote.info.selectSection.length !== 0
      ) {
        allowAll = "readonly";
      }

      // console.log(allowAll, 'allowall')
      return allowAll;
    },
    teamsChecked() {
      return (
        ((store.state.showModalMultiSelectNote.info.selectTeams || []).length ||
          0) > 0
      );
    },
    departmentsChecked() {
      return (
        store.state.showModalMultiSelectNote.info.selectDepartments.length > 0
      );
    },
    sectionsChecked() {
      return store.state.showModalMultiSelectNote.info.selectSection.length > 0;
    },
    nameTitle() {
      return store.getTitelForUuid(this.ownUUID);
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    showModalMultiSelectNote() {
      return this.state.showModalMultiSelectNote !== undefined;
    },
    dataCombobox: function(data) {
      const result = { sections: [], departments: [], teams: [], users: [] };
      if (!this.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      const sections =
        this.state.namespaceSettings.processedOrganisation.sections || {};
      const departments =
        this.state.namespaceSettings.processedOrganisation.departments || {};
       const users = this.state.group || {};

      for (const key in users) {
        const data = { name: users[key].user.name, uuid: key };
        result.users.push(data);
      }

      return result;
    },
    sectionData() {
      const osections =
        this.state.namespaceSettings.processedOrganisation.sections || {};
      const sections = [];
      let filteredSections = [];

      for (const key in osections) {
        const data = { name: key, active: false};
        sections.push(data);
      }

      if (this.effectiveTerm === "") {
        // console.log("returning full");
        filteredSections = sections;
      } else {
        filteredSections = sections.filter(t => {
         // console.log();
          return (
            t.name.toLowerCase().indexOf(this.effectiveTerm.toLowerCase()) !==
            -1
          );
        });
       // console.log("returning filtered", filteredSections);
      }
     // console.log(filteredSections, "Filtered filteredSections");
      return filteredSections.sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      });
    },
    departmentData() {
      const odepartments =
        this.state.namespaceSettings.processedOrganisation.departments || {};
      const departments = [];
      let filteredDepartments = [];

      for (const key in odepartments) {
        const data = { name: key, active: false};
        departments.push(data);
      }

      if (this.effectiveTerm === "") {
       // console.log("returning full");
        filteredDepartments = departments;
      } else {
        filteredDepartments = departments.filter(t => {
         // console.log();
          return (
            t.name.toLowerCase().indexOf(this.effectiveTerm.toLowerCase()) !==
            -1
          );
        });
       // console.log("returning filtered", filteredDepartments);
      }
     // console.log(filteredDepartments, "Filtered teams");
      return filteredDepartments.sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      });
    },
    teamsData() {
      const oteams =
        this.state.namespaceSettings.processedOrganisation.teams || {};
      const teams = [];
      let filteredTeams = [];

      for (const key in oteams) {
        const data = { name: key, active: false};
        teams.push(data);
      }

      if (this.effectiveTerm === "") {
       // console.log("returning full");
        filteredTeams = teams;
      } else {
        filteredTeams = teams.filter(t => {
         // console.log();
          return (
            t.name.toLowerCase().indexOf(this.effectiveTerm.toLowerCase()) !==
            -1
          );
        });
       // console.log("returning filtered", filteredTeams);
      }
     // console.log(filteredTeams, "Filtered teams");

      return filteredTeams.sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      });
    },
    // Define The Date
    todayDate() {
      const today = new Date().toISOString();
      // const today = new Date().toLocaleDateString([], {
      //   day: "2-digit",
      //   month: "2-digit",
      //   year: "2-digit",
      //   hour: "2-digit",
      //   minute: "2-digit"
      // });
      return today;
    },

    // Check if the note is longer than 106 letters
    longNote() {
      if (((this.noteText || "").length || 0) > 106) {
        return true;
      } else {
        return false;
      }
    },
    // calcID() {
    //   if (this.theNotes.length == 0) {
    //     return 1;
    //   } else {
    //     return this.theNotes[this.theNotes.length - 1].id + 1;
    //   }
    // },
    // Collect Note Data
    newNote() {
      // console.log(this.todayDate);
      return {
        id: uuidv4(),
        title: this.noteTitle,
        date: this.todayDate,
        text: this.noteText,
        color: this.noteColor,
        long: this.longNote,
        completed: false,
        author: this.author,
        authorName: this.authorName,
        targetUsers: [],
        targetDepartments: [],
        targetTeams: [],
        targetSections: [],
        activeUntil: "",
        activeFrom: ""
      };
    }
  }
};
</script>
<style scoped lang="scss">
.lockMessageIcon{
  position: absolute;
  right: -6px;
  top: -10px;
}
.logoModal{
  display: inline-block;
}
.positionClose{
  position: absolute;
  top:5px;
  right:0;
}
.fontsemi {
  font-weight: 600 !important;
}
.footerDate {
  color: white;
  line-height: 2.8em;
  text-align: right;
  margin-right: 19px;
}
.confined {
  max-height: 270px !important;
  overflow-y: auto;
}
.fixed {
  position: fixed;
}
.select-box {
  background-color: var(--v-primary-base) !important;
  min-height: 30px;
}
.noteText {
  white-space: pre-line;
  
 
}
.v-application {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.messageText {
  overflow: hidden;
  white-space: pre-line;
  user-select: text;
  word-break: break-word;
}

.messageTextExpand {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
  white-space: pre-line;
  user-select: text;
  word-break: break-word;
}
.mencheckbox {
  padding: 0px !important;
  margin: 0px;
  font-weight: 500;
}
.cbNoteHead{
  margin-right: 24px;
}
.selactive{
    background-color: #c1c1c166;
  }
.listOrgItem {
  margin-left: 10px;
  cursor: pointer;
}
.selNormal{
  background-color: white;
  color:black;
}
.selAllUsers {
  span{
    background-color: yellow;
  }
  p{
    background-color: transparent;
  }
  
  color:black;
}

.allusers :deep() label {
  background-color: yellow;
  padding: 0 5px 0 5px;
  font-weight: 600;
  color: black;
}
.selTeam{
  color:limegreen;
}
.teams :deep() label {
  padding: 0 5px 0 5px;
  color: limegreen;
}
.selDepartment{
  color:lightblue;
}
.departments :deep() label {
  color: lightblue;
  padding: 0 5px 0 5px;
  cursor: pointer;
}
.selSection {
  color:pink;
}
.sections :deep() label {
  color: pink;
  padding: 0 5px 0 5px;
}

</style>
<style lang="scss">
  .v-application .noteEntryText{
    & input, & textarea{
      font-size: 16px !important;
    }
}
 .v-application .noteEntryText{
    & input, & label{
      font-size: 16px !important;
      font-weight: 600;
    }
}
</style>