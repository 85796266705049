<template>
    <v-dialog   v-model="postPreviewData.show" width="90vw">
        <v-card>
            <v-card-title class="d-flex  flex-row-reverse">
                <v-btn variant="text" icon @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <Post v-if="postData" :postData="postData" />
                <WhatsWork v-if="whatsWorkData"  :whatsWorkData="whatsWorkData" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import store, { EventBus } from "../../../../store";
import Post from "../post/post.vue"
import WhatsWork from "../workUpdates/whatsWork.vue"
export default {
    props: ['postPreviewData'],
    components: {
        Post,
        WhatsWork
    },
    data() {
        return {
            state: store.sate,
            postData: null,
            whatsWorkData: null
        };
    },
    watch: {
        postPreviewData: {
            immediate: true,
            handler(newId) {
                if (this.postPreviewData && this.postPreviewData.postId) this.fetchPost();
            }
        }
    },
    unmounted() {
        this.postData = null;
        this.whatsWorkData = null;
    },
    methods: {
        fetchPost() {
            fetch(`/api/socialmedia/post-by-id?postId=${this.postPreviewData.postId}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            }).then(async (response) => {
                const result = await response.json();
                if (!result.error) {
                    if(result.post){
                        this.whatsWorkData = null;
                        this.postData = result.post;
                    }
                } else {
                    //TODO: Handler error
                }
            })
            fetch(`/api/socialmedia/whatswork-by-id?postId=${this.postPreviewData.postId}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            }).then(async (response) => {
                const result = await response.json();
                if (!result.error) {
                    if(result.post){
                        this.postData = null;
                        this.whatsWorkData = result.post;
                    }
                } else {
                    //TODO: Handler error
                }
            })
        },
        closeModal() {
            const dataToSend = {
                show: false,
                postId: null
            }
            EventBus.$emit('previewPost', dataToSend)
        }
    }
};
</script>

<style scoped>
.headline {
    font-size: 1.25rem;
    font-weight: 600;
}
</style>
