var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto primaryBackground",
      attrs: { width: "100%", flat: "" }
    },
    [
      _c(
        "v-list",
        { attrs: { "px-0": "" } },
        [
          _vm.hasPrivilege(_vm.ownUUID)
            ? _c("DrawerContentFavorites", {
                attrs: {
                  mini: _vm.mini,
                  active: _vm.active,
                  setList: _vm.setList
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }