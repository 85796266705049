var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visitors.length > 0
    ? _c(
        "vue-scroll",
        {
          class: {
            vueScrollVisitors: _vm.applyClass,
            vueScrollOne: _vm.applyOne,
            vueScrollTwo: _vm.applyTwo,
            vueScrollThree: _vm.applyThree
          }
        },
        _vm._l(_vm.sortedVisitors, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "person",
              attrs: { id: "visitor" + index }
            },
            [
              !item.user
                ? _c("VisitorListItem", {
                    attrs: { flat: "", visitor: item, section: _vm.section }
                  })
                : _vm._e(),
              _vm._v(" "),
              item.user
                ? _c("VisitorListItem", {
                    attrs: {
                      flat: "",
                      visitor: item.user,
                      section: _vm.section
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }