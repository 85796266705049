var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h100 justVideo" },
    [
      _vm.fullCall
        ? _c("fullCallModal", { attrs: { fullCall: _vm.fullCall } })
        : _vm._e(),
      _vm._v(" "),
      _vm.state.nerd
        ? _c("div", { staticClass: "rtcStatus" }, [
            _c("div", [
              _vm._v("\n      IceConnectionState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green:
                      _vm.state.rtc[_vm.uuid].iceConnectionState === "connected"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].iceConnectionState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n      IceGatheringState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green:
                      _vm.state.rtc[_vm.uuid].iceGatheringState === "complete"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].iceGatheringState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n      SignalingState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green: _vm.state.rtc[_vm.uuid].signalingState === "stable"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].signalingState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n      RingingState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green: _vm.state.rtc[_vm.uuid].ringingState === "connected"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].ringingState))]
              )
            ]),
            _vm._v(" "),
            _vm.activeCandidates
              ? _c("div", [
                  _vm._v("\n      ConnectionType:\n      "),
                  _c("span", { class: { green: !_vm.isTurn } }, [
                    _vm._v(
                      _vm._s(_vm.activeCandidates.local.candidateType) +
                        " / " +
                        _vm._s(_vm.activeCandidates.remote.candidateType)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            this.videoDetailsFirst && this.videoDetailsFirst.width
              ? _c("div", [
                  _vm._v(
                    "First: " +
                      _vm._s(this.videoDetailsFirst.width || 0) +
                      "x" +
                      _vm._s(this.videoDetailsFirst.height || 0) +
                      "@" +
                      _vm._s(Math.floor(this.videoDetailsFirst.frameRate || 0))
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.videoDetailsSecond && this.videoDetailsSecond.width
              ? _c("div", [
                  _vm._v(
                    "Second: " +
                      _vm._s(this.videoDetailsSecond.width || 0) +
                      "x" +
                      _vm._s(this.videoDetailsSecond.height || 0) +
                      "@" +
                      _vm._s(Math.floor(this.videoDetailsSecond.frameRate || 0))
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "videos",
          staticClass: "videos",
          attrs: { id: "videos" },
          on: {
            dblclick: function($event) {
              return _vm.fullSreen($event)
            }
          }
        },
        [
          _c("video", {
            staticClass: "remote-first",
            class: { hidden: !_vm.remote.first || !_vm.haveRemoteFirstVideo },
            attrs: { id: "remote-first", autoplay: "autoplay" },
            domProps: {
              srcObject: _vm.remote.first,
              muted: _vm.isLocalSoundMuted
            },
            on: {
              click: function($event) {
                $event.preventDefault()
              }
            }
          }),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "ringing"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c(
                    "div",
                    { staticClass: "callAvatarWrapper" },
                    [
                      _c("v-img", {
                        staticClass: "noVideoStreamAvatar",
                        attrs: {
                          contain: "",
                          "max-width": "65",
                          "max-height": "65",
                          src: _vm.getAvatarForUuid(_vm.uuid),
                          "lazy-src": "assets/default_profile_picture.png"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "callingText" }, [
                      _vm._v(
                        _vm._s(_vm.$t("components.remotePersonCall.calling")) +
                          " " +
                          _vm._s(_vm.getNameForUuid(_vm.uuid))
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(0)
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "accepted"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c("span", { staticClass: "callingText" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.remotePersonCall.callInProgress")
                      )
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "connected"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c(
                    "div",
                    { staticClass: "callAvatarWrapper" },
                    [
                      _c("v-img", {
                        staticClass: "noVideoStreamAvatar",
                        attrs: {
                          contain: "",
                          "max-width": "65",
                          "max-height": "65",
                          src: _vm.getAvatarForUuid(_vm.uuid),
                          "lazy-src": "assets/default_profile_picture.png"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "callingText" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.remotePersonCall.inCallWith")
                        ) +
                          " " +
                          _vm._s(_vm.getNameForUuid(_vm.uuid))
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "callerName" }, [
            _vm.uuid
              ? _c("span", [_vm._v(_vm._s(_vm.getDataForHeader(_vm.uuid)))])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "wave" } }, [
      _c("div", { staticClass: "dot", attrs: { color: "primary" } }),
      _vm._v(" "),
      _c("div", { staticClass: "dot", attrs: { color: "primary" } }),
      _vm._v(" "),
      _c("div", { staticClass: "dot", attrs: { color: "primary" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }