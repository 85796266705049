var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n       " +
                  _vm._s(_vm.$t("components.2faDisable.title")) +
                  " " +
                  _vm._s(_vm.tfaActiveCodeLabel) +
                  "\n      "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm.enabled === "sms" || (_vm.phoneNumber && _vm.smsRecovery)
                ? _c("div", { staticClass: "d-flex" }, [
                    _vm.phoneNumber
                      ? _c("div", { staticClass: "tex0t-truncate body-1" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.2faSms.phoneNumberLine")
                              )
                            )
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.phoneNumberCensored) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.phoneNumber
                      ? _c("div", { staticClass: "tex0t-truncate body-1" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.2faSms.noPhoneNumberLine")
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.enabled === "sms"
                ? _c(
                    "div",
                    { staticClass: "d-flex mb-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            disabled: _vm.requestSmsDone,
                            small: "",
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              return _vm.requestSms()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("components.2faSms.sendSMS")))]
                      ),
                      _vm._v(" "),
                      _vm.requestSmsDone
                        ? _c(
                            "span",
                            { staticClass: "px-2 align-center d-flex" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.2fa.sentSMSMsg"))
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errMsg && !_vm.showCodeInput
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", dismissible: true } },
                    [_vm._v(_vm._s(_vm.errMsg))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCodeInput
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          autocomplete: "off",
                          label: _vm.tfaCodeLabel,
                          "append-icon":
                            "" + (_vm.showPassword ? "mdi-eye-off" : "mdi-eye"),
                          type: "" + (_vm.showPassword ? "text" : "password"),
                          placeholder: " ",
                          outlined: "",
                          dense: "",
                          autofocus: "",
                          required: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.tfaCode && _vm.disable2fa()
                          },
                          "click:append": function($event) {
                            return _vm.showPass()
                          },
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar"
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                          keypress: _vm.onCodeInput
                        },
                        model: {
                          value: _vm.tfaCode,
                          callback: function($$v) {
                            _vm.tfaCode = $$v
                          },
                          expression: "tfaCode"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errMsg
                        ? _c(
                            "v-alert",
                            { attrs: { type: "error", dismissible: true } },
                            [_vm._v(_vm._s(_vm.errMsg))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                "hide-details": "",
                                small: "",
                                disabled: !_vm.tfaCode
                              },
                              on: {
                                click: function($event) {
                                  return _vm.disable2fa()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.2faModal.btnDisable"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal footersBackground"
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "6", align: "left" }
                    },
                    [
                      _vm.enabled !== "sms" && _vm.smsRecovery
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.handleForgotPin()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.getForgotPinText()))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "6", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "border-radius": "4px",
                            "min-width": "45px"
                          },
                          attrs: {
                            icon: "",
                            tile: "",
                            outlined: "",
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "times"] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      ),
      _vm._v(" "),
      _vm.showActivation
        ? _c("TwoFactorActivationModal", {
            attrs: {
              showActivation: _vm.showActivation,
              toggle2faActivationModal: _vm.toggle2faActivationModal,
              saveEntryNoPhone: _vm.saveEntryNoPhone
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendSMSModal
        ? _c("TwoFactorSendSmsModal", {
            attrs: {
              toggleShowSendSMS: _vm.toggleShowSendSMS,
              showSendSMSModal: _vm.showSendSMSModal,
              requestSms: _vm.requestSms
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }