var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModalSettings,
        callback: function($$v) {
          _vm.showModalSettings = $$v
        },
        expression: "showModalSettings"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.callsContent.addParticipants"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", large: "" },
                      on: {
                        click: function($event) {
                          return _vm.openUsers()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        style: { fontSize: "20px" },
                        attrs: { icon: ["fal", "user-plus"] }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("generics.user")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", large: "" },
                      on: {
                        click: function($event) {
                          _vm.handlerOpenVisitor()
                          _vm.closeModal()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        style: { fontSize: "20px" },
                        attrs: { icon: ["fal", "user-plus"] }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("generics.guest")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("components.callsContent.shareLinkText")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-text-field", {
                    ref: "linkRef",
                    staticClass: "inputDirectInviteLink",
                    attrs: {
                      id: "inputLink",
                      readonly: "",
                      dense: "",
                      outlined: "",
                      "hide-details": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "buttonCopyLink",
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.copyLink($event)
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "copy"] }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.instaLink,
                      callback: function($$v) {
                        _vm.instaLink = $$v
                      },
                      expression: "instaLink"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }