var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "px-4" },
    [
      _vm.amIAdmin && _vm.getUsers.length
        ? _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-center mb-3" },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0",
                        staticStyle: { width: "310px" },
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: _vm.$t("generics.search"),
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml-4 pointer",
                          on: {
                            click: function($event) {
                              return _vm.cleanSearch()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "times"] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-group",
                    {
                      staticClass: "listWrapper",
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.selectedUser,
                        callback: function($$v) {
                          _vm.selectedUser = $$v
                        },
                        expression: "selectedUser"
                      }
                    },
                    _vm._l(_vm.filteredUsers, function(user) {
                      return _c(
                        "v-list-item",
                        { key: user.uuid, attrs: { value: user.uuid } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(
                                    user.titel + " " + user.name
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: _vm.amIAdmin && _vm.getUsers.length ? 10 : 12 } },
        [
          _c("div", { staticClass: "pt-2 px-3  tableTitle" }, [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("components.workingTime.workingTime")) +
                  "\n        " +
                  _vm._s(
                    _vm.selectedUser
                      ? _vm.getTitelForUuid(_vm.selectedUser) +
                          " " +
                          _vm.getNameForUuid(_vm.selectedUser)
                      : ""
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.selectedUser
            ? [
                _c("WorkingTimeCalendar", {
                  attrs: { selectedUser: _vm.selectedUser }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }