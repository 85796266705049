var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.validData,
            callback: function($$v) {
              _vm.validData = $$v
            },
            expression: "validData"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
            [
              _c(
                "v-row",
                { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.userListToSend.length < 4,
                          expression: "userListToSend.length < 4"
                        }
                      ],
                      staticClass: "w-100 mx-0 my-0"
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "white",
                              "hide-details": "",
                              disabled: _vm.atUserLimit || _vm.userSelected,
                              dense: "",
                              solo: "",
                              label: _vm.$t("components.inviteVisitor.name")
                            },
                            model: {
                              value: _vm.selectedUserName,
                              callback: function($$v) {
                                _vm.selectedUserName = $$v
                              },
                              expression: "selectedUserName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "white",
                              "hide-details": "",
                              disabled: _vm.emailDisabled,
                              dense: "",
                              solo: "",
                              label: _vm.$t(
                                "components.inviteVisitor.invitationEmail"
                              ),
                              rules: _vm.phoneInputValid ? [] : _vm.emailRules
                            },
                            model: {
                              value: _vm.selectedEmail,
                              callback: function($$v) {
                                _vm.selectedEmail = $$v
                              },
                              expression: "selectedEmail"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0 my-3" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              required: "",
                              label: _vm.$t(
                                "components.inviteVisitor.function"
                              ),
                              dense: "",
                              solo: ""
                            },
                            model: {
                              value: _vm.visFunction,
                              callback: function($$v) {
                                _vm.visFunction = $$v
                              },
                              expression: "visFunction"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isWhatsAppInviteAvailable
                        ? [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 positionRelative",
                                attrs: { cols: "6" }
                              },
                              [
                                _c("vue-tel-input", {
                                  staticClass:
                                    "form-control text-center input-sm inputPhone2FA",
                                  class: _vm.phoneDisabled
                                    ? {}
                                    : { borderError: !_vm.phoneInputValid },
                                  attrs: {
                                    required: "",
                                    defaultCountry: "DE",
                                    enabledCountryCode: "",
                                    name: "number",
                                    id: "numDisplay",
                                    value: "",
                                    placeholder: "",
                                    autocomplete: "off",
                                    disabled: _vm.phoneDisabled
                                  },
                                  on: { onInput: _vm.onTelInput },
                                  model: {
                                    value: _vm.phoneNumberInput,
                                    callback: function($$v) {
                                      _vm.phoneNumberInput = $$v
                                    },
                                    expression: "phoneNumberInput"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "whatsAppIcon" },
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fab", "whatsapp"] }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0 my-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 positionRelative",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("span", { staticClass: "font-14" }, [
                            _vm._v(_vm._s(_vm.getNoticeText))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "w-100 mx-0 my-0" },
        _vm._l(_vm.userListToSend, function(data, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              class: { rowOdd: index % 2 === 0, rowEven: index % 2 !== 0 }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item", [
                    _c("strong", [_vm._v(_vm._s(data.name) + ":")]),
                    _vm._v("  " + _vm._s(data.email))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "margin-top": "-9px" },
                      attrs: { icon: "", disabled: _vm.isProcessing }
                    },
                    [
                      _c("DeleteBasket", {
                        attrs: {
                          indexData: index,
                          delFunction: _vm.removeUserList,
                          extraClasses: "",
                          fontSize: "20",
                          prevent: "false"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }