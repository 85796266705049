var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        {
          staticStyle: { margin: "0", padding: "0" },
          attrs: { width: _vm.width, "max-width": _vm.maxwidth, dense: "" }
        },
        [
          _c(
            "v-row",
            { staticStyle: { width: "100%", margin: "0" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex",
                  staticStyle: { "margin-left": "20px", width: "auto" },
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "ml-auto font-weight-bold",
                      attrs: { flat: "" }
                    },
                    [_vm._v("VideoCall Besuch")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-list-item",
            { staticStyle: { margin: "0", padding: "0" } },
            [
              _c(
                "v-list-item-title",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticStyle: { width: "100%", margin: "0" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-12 px-2 py-2" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.userNameRules,
                                  disabled: "true"
                                },
                                model: {
                                  value: _vm.uname,
                                  callback: function($$v) {
                                    _vm.uname = $$v
                                  },
                                  expression: "uname"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticStyle: { width: "100%", margin: "0" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-12 px-2 py-2" },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: { label: "Arulezeit" },
                                model: {
                                  value: _vm.uWithoutRules,
                                  callback: function($$v) {
                                    _vm.uWithoutRules = $$v
                                  },
                                  expression: "uWithoutRules"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticStyle: { width: "100%", margin: "0" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-6 px-2 py-2" },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: { label: "bis: Kalender" },
                                model: {
                                  value: _vm.uCalendar,
                                  callback: function($$v) {
                                    _vm.uCalendar = $$v
                                  },
                                  expression: "uCalendar"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "col-6 px-2 py-2" },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: { label: "Paylink" },
                                model: {
                                  value: _vm.uPayLink,
                                  callback: function($$v) {
                                    _vm.uPayLink = $$v
                                  },
                                  expression: "uPayLink"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticStyle: { width: "100%", margin: "0" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 px-2", attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                staticClass: "py-0 my-0",
                                attrs: {
                                  name: "noteinput",
                                  "no-resize": true,
                                  rows: "2",
                                  placeholder: "Interne Bemerkung",
                                  counter: "",
                                  maxlength: "80"
                                },
                                model: {
                                  value: _vm.internalnote,
                                  callback: function($$v) {
                                    _vm.internalnote = $$v
                                  },
                                  expression: "internalnote"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticStyle: { width: "100%", margin: "0" } },
                        [_c("div", { staticClass: "mx-auto" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "py-2 pl-4 mt-4 pr-2  footerModal",
          attrs: { color: "primary" }
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2 btns w-100 mx-0 my-0" },
            [
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    disabled: !_vm.valid,
                                    icon: "",
                                    color: "white"
                                  },
                                  on: { click: _vm.handleNewInstaLink }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "check"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generics.accept")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { icon: "", color: "white" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.closeMenu()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "times"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                  ]
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }