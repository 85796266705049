import store from '../store';
import Vue from 'vue';
import { v4 as uuidv4 } from "uuid";
import { showOnCallData } from "./calls";

export function totalUsersInRoom(room) {
  if (!room || !room.roomId) return 0;
  const { state: { ownUUID, user, group: users } } = store;
  const usersInRoom = Object.entries(users)
    .filter(([uuid, entry]) => {
      const { inBridgeCall = '', bridgeCallInfo = {} } = entry?.user || {};
      return uuid !== ownUUID && inBridgeCall && bridgeCallInfo.staticRoom && bridgeCallInfo.roomId === room.roomId;
    });
  const { inBridgeCall = '', bridgeCallInfo = {} } = user || {};
  const isUserInRoom = inBridgeCall && bridgeCallInfo.staticRoom && bridgeCallInfo.roomId === room.roomId;
  return (isUserInRoom ? 1 : 0) + usersInRoom.length;
}

export function usersNamesInRoom(room) {
  if (!room || !room.roomId) return '';
  const users = store.state.group;
  const usersInRoom = Object.entries(users).filter(e => e[1] && e[1].user && e[1].user.inBridgeCall && e[1].user.bridgeCallInfo && e[1].user.bridgeCallInfo.staticRoom && e[1].user.bridgeCallInfo.roomId === room.roomId);
  if (usersInRoom) {
    if (showOnCallData()) {
      const participants = usersInRoom.map((user) => {
        return store.state.group[user[0]]
          ? (store.state.group[user[0]].user || {}).name || "Anonymous"
          : ""
      })
        .join(", ");
      return participants;
    } else {
      return `${totalUsersInRoom(room)} ${Vue.prototype.$t('components.callsContent.participants')}`;
    }
  }
  return '';
}

export function usersInsideRoom(room) {
  if (!room || !room.roomId) return '';
  const users = store.state.group;
  const usersInRoom = Object.entries(users).filter(e => e[1] && e[1].user && e[1].user.inBridgeCall && e[1].user.bridgeCallInfo && e[1].user.bridgeCallInfo.staticRoom && e[1].user.bridgeCallInfo.roomId === room.roomId);
  if (usersInRoom) {
    const participants = usersInRoom.map((user) => {
      return store.state.group[user[0]].user || {};
    })
    return participants
  }
  return '';
}
function setCurrentContentVisile(...args) {
  return store.setCurrentContentVisile(...args);
}
export function joinSambaRoom(room, $router) {
  const usersInside = usersInsideRoom(room)
  let id = room.roomGuid || uuidv4();
  let callStartTs = Date.now();
  let callDurationMs = 60000 * 20;
  if (usersInside.length) {
    const dataCall = usersInside[0].bridgeCallInfo;
    id = dataCall.callUUID;
    callStartTs = dataCall.callStartTs;
    callDurationMs = dataCall.callDurationMs;
  }
  const info = {
    callUUID: id,
    calling: [],
    isNormalMode: true,
    initiator: store.state.ownUUID,
    callStartTs: callStartTs,
    callDurationMs: callDurationMs,
    infiniteCall: true,
    isAudioOnly: false,
    roomId: room.roomId,
    roomGuid: room.roomGuid,
    staticRoom: room.roomName || room.roomGuid,
  };
  store.addRemoteBridgeStream(id, info);
  if (store.state.wasInARoom) {
    store.setRoomInformation(null);
  }
  setCurrentContentVisile("", false, $router);
}