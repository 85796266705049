var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "gridWrapperNormalMode p-0 container-fluid",
        attrs: { id: "contentWrapper" }
      },
      [
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-19" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "100%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR1",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-19-1" }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-1" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "100%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR2",
                                    attrs: { id: "call-grid-1-2" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR2",
                                    attrs: { id: "call-grid-1-4" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR1",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-1-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR2",
                                    attrs: { id: "call-grid-1-3" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR2",
                                    attrs: { id: "call-grid-1-5" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-21" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "100%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-21-2" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-21-4" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-21-6" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR1",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-21-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-21-3" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-21-5" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-21-7" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-2" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "66.67%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-2-2" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-2-4" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR3_2",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-2-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-2-3" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-2-5" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-2-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-2-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-2-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-2-9" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-22" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "75%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-22-2" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-22-4" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-22-6" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR4_3",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-22-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-22-3" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-22-5" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-22-7" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-22-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-22-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-22-10" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-22-11" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-6" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "100%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-6-2" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-6-4" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-6-6" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR3_2",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-6-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-6-3" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-6-5" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR3",
                                    attrs: { id: "call-grid-6-7" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-3" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-5" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "50%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR4",
                                    attrs: { id: "call-grid-3-6" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR4",
                                    attrs: { id: "call-grid-3-8" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR4_2",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-3-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-3-7" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "50%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-3-9" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-10" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-11" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-12" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-3-13" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-7" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "70%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR4",
                                    attrs: { id: "call-grid-7-2" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR4",
                                    attrs: { id: "call-grid-7-4" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR4",
                                    attrs: { id: "call-grid-7-6" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "50%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR4_2",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-7-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-7-3" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.3%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-7-5" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "33.4%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR4",
                                    attrs: { id: "call-grid-7-7" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-7-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-7-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-7-10" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-7-11" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-4" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "16.66%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-5" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-7" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters flex-fill d-flex",
                staticStyle: { height: "66.68%" }
              },
              [
                _c("div", { staticClass: "col d-flex" }, [
                  _c("div", { staticClass: "container-fluid d-flex p-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row no-gutters",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "16.66%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR6",
                                    attrs: { id: "call-grid-4-8" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR6",
                                    attrs: { id: "call-grid-4-10" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR6",
                                    attrs: { id: "call-grid-4-12" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col bgyellow d-flex grm vidR6",
                                    attrs: { id: "call-grid-4-14" }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto d-flex",
                            staticStyle: { width: "66.68%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "bgyellow d-flex grm vidR6_4",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%"
                                  },
                                  attrs: { id: "call-grid-4-1" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-auto",
                            staticStyle: { width: "16.66%" }
                          },
                          [
                            _c("div", { staticClass: "container-fluid p-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR6",
                                    attrs: { id: "call-grid-4-9" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR6",
                                    attrs: { id: "call-grid-4-11" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR6",
                                    attrs: { id: "call-grid-4-13" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row no-gutters",
                                  staticStyle: { height: "25%" }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "col d-flex bgyellow grm vidR6",
                                    attrs: { id: "call-grid-4-15" }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "16.66%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-16" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-17" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-18" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-19" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-20" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR6",
                  attrs: { id: "call-grid-4-21" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-5" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "100%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-5-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-5-2" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-10" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-10-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-10-2" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-10-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-10-4" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-9" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-9-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-9-2" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-9-3" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-11" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-11-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-11-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-11-3" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-11-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-11-5" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-11-6" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-8" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-8-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-8-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-8-3" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-8-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-8-5" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-12" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-4" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-5" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-12-8" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-13" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-3" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-5" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-6" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.34%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-13-9" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-14" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-5" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "50%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR2",
                  attrs: { id: "call-grid-14-10" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-15" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-4" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-5" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-8" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.34%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-10" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-11" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-15-12" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-16" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-5" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.33%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-10" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "33.34%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-11" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-12" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-13" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-14" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR3",
                  attrs: { id: "call-grid-16-15" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-17" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-4" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-5" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-8" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-10" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-11" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-12" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-13" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-14" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-15" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-17-16" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid hidd call-grid",
            attrs: { id: "call-grid-18" }
          },
          [
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-1" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-2" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-3" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-4" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-5" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-6" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-7" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-8" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-9" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-10" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-11" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-12" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-13" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-14" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-15" }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row no-gutters", staticStyle: { height: "25%" } },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-16" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-17" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-18" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-19" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR4",
                  attrs: { id: "call-grid-18-20" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "container-fluid call-grid",
            attrs: { id: "call-grid-20" }
          },
          [
            _c(
              "div",
              {
                staticClass: "row no-gutters",
                staticStyle: { height: "100%" }
              },
              [
                _c("div", {
                  staticClass: "col bgyellow d-flex grm vidR1",
                  attrs: { id: "call-grid-20-1" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "hidd" }, [
              _c("div", { attrs: { id: "call-grid-20-2" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-3" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-4" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-5" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-6" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-7" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-8" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-9" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-10" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-11" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-12" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-13" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-14" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-15" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-16" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-17" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-18" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-19" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-20" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "call-grid-20-21" } })
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }