// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-btn {
  margin-left: auto;
}
.arrow-btn-black .v-btn__content .v-icon {
  color: black !important;
  font-size: 25px !important;
}
.arrow-btn-White .v-btn__content .v-icon {
  color: white !important;
  font-size: 25px !important;
}
.no-hover .v-btn__overlay {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/socialMediaContent.vue","webpack://./socialMediaContent.vue"],"names":[],"mappings":"AACA;EACI,iBAAA;ACAJ;ADGI;EACI,uBAAA;EACA,0BAAA;ACAR;ADII;EACI,uBAAA;EACA,0BAAA;ACDR;ADKA;EACI,wBAAA;ACFJ","sourcesContent":["\n.arrow-btn {\n    margin-left: auto;\n}\n.arrow-btn-black {\n    .v-btn__content .v-icon {\n        color: black !important;\n        font-size: 25px !important;\n    }\n}\n.arrow-btn-White {\n    .v-btn__content .v-icon {\n        color: white !important;\n        font-size: 25px !important;\n    } \n}\n\n.no-hover .v-btn__overlay {\n    display: none !important;\n}\n",".arrow-btn {\n  margin-left: auto;\n}\n\n.arrow-btn-black .v-btn__content .v-icon {\n  color: black !important;\n  font-size: 25px !important;\n}\n\n.arrow-btn-White .v-btn__content .v-icon {\n  color: white !important;\n  font-size: 25px !important;\n}\n\n.no-hover .v-btn__overlay {\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
