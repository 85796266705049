/* globals history, localStorage, location */
import Vue from 'vue';
import { webLicensedBaseFeatures } from "../../sharedsrc/licensedFeatures";
import store, { syncedGroupState } from '../store';
import { deleteVisitor } from '../utils/waitingRoom';
import { callTable, onMessage } from './wsMsg';
import { v4 as uuidv4 } from 'uuid';
// import { initPaymentView } from '../paymentPage';
import { initPaymentView } from '../paypalPaylinkInstance';
import { initSubdomainTaken } from '../SubdomainTakenInstance';
import { initLicenseExpired } from '../licenseExpiredInstance';
import { initAppDownload } from '../appDownloadInstance';
import { initUseOnDesktop } from '../useOnDesktopInstance';
import { initTwoFaPrompt } from '../TwoFaPromptInstance';
import { initDecryptError } from '../DecryptErrorInstance';
import { pokeRemoveSplash } from './splashAndTheme';
import { wsConnectedEvent, wsDisconnectedEvent } from '../effector/ws';
import { setOwnUserUUIDEvent } from '../effector/users';
import { isMobile } from '../lib/mobileUtil';
import URI from 'urijs';

let ws;
let reconnectTimeout;

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
function isValidUUID (uuid) {
  return uuid.match(uuidRegex) !== null;
}

function isValidMD5 (s) {
  return s.match(/^[a-fA-F0-9]{32}$/) !== null;
}

function isValid (s) {
  if (isValidUUID(s)) return s; // Accept valid UUIDs
  if (isValidMD5(s)) return s; // Accept valid MD5
  return undefined;
}

// http://localhost:8081/#/onecall?hash=fabdb52...&name=a&email=b
function getHashFromUrlFragment () {
  const urlHash = window.location.hash.split('#')[1];
  return urlHash ? URI(urlHash).search(true).hash : undefined;
}

function getHashValue () {
  try {
    const hashFromUrl = getHashFromUrlFragment() || window.location.hash.slice(1).replace('/', '');
    if (hashFromUrl) {
      const split = hashFromUrl.split('_');
      const uuid = isValid(split[0]);
      if (uuid) return { uuid, secret: split[1] };
    }
  } catch (err) {
    console.warn('getHashValue Error:', err.message);
  }
  return undefined;
}

function removeHashFromUrl () {
  try {
    if (getHashFromUrlFragment()) {
      const urlHash = window.location.hash.split('#')[1];
      history.replaceState(null, null, [urlHash[0], URI(urlHash).removeSearch('hash')].join('#'));
    } else if (getHashValue()) {
      history.replaceState(null, null, ' ');
      return ''; // For processing in websrc/utils/routerAcl.js
    }
  } catch (err) {
    console.warn('Error trying to remove hash from location:', err.message);
  }
}

export const hashValue = getHashValue();

if (hashValue) {
  removeHashFromUrl();
  // console.log('HashValue:', hashValue);
}

if ('onhashchange' in window) {
  window.onhashchange = function () {
    const hashValue = getHashValue();
    if (hashValue) {
      // alert('valid hash, reload');
      location.reload();
    }
  };
}

export function makeid (length) {
  let result = '';
  try {
    const size = Math.ceil(length / 3) * 3;
    const ascii = new Uint8Array(size);
    window.crypto.getRandomValues(ascii);
    result += window.btoa(String.fromCharCode.apply(null, ascii)).replace(/=|\+|\//g, '');
  } catch (err) {}
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = result.length; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.slice(-length);
}

function getStoreOwnUUID () {
  let uuid = store.state.ownUUID;
  if (!uuid || uuid === 'Loading...' || (!isValidUUID(uuid) && !isValidMD5(uuid))) uuid = undefined;
  return uuid;
}

export function getOwnUUID () {
  let uuid = (hashValue || {}).uuid;
  if (!uuid) uuid = getStoreOwnUUID();
  if (!uuid) uuid = localStorage.getItem('ownUUID');
  // console.log(!isValidUUID(uuid), !isValidMD5(uuid), (!isValidUUID(uuid) && !isValidMD5(uuid)));
  if (!uuid || (!isValidUUID(uuid) && !isValidMD5(uuid))) uuid = uuidv4();
  return uuid;
}

function getOwnAuth () {
  const uuid = getOwnUUID();

  const localSecretKey = 'ownSecret_' + uuid;
  let secret = (hashValue || {}).secret;
  if (!secret) secret = localStorage.getItem(localSecretKey);
  if (!secret) secret = makeid(32);

  const localTwoTokenKey = 'ownTwoToken_' + uuid;
  const twotoken = localStorage.getItem(localTwoTokenKey) || undefined;

  localStorage.setItem('ownUUID', uuid);
  localStorage.setItem(localSecretKey, secret);
  setOwnUserUUIDEvent(uuid);
  return { uuid, secret, twotoken };
}

export function getOwnSecret () {
  return getOwnAuth().secret;
}

export function getOwnTwoToken () {
  return getOwnAuth().twotoken;
}

function onOpen () {
  // const decryptError = store.state.namespaceSettings.decryptError;
  // console.log('errorDecrypt ====> ', decryptError)
  syncedGroupState(true).then(() => {
    // Prevent race-condition by checking our instance is the global instance, is ready and is still setup the same way
    if (this === ws && this.readyState === 1 /* OPEN */ && this.onopen === onOpen && this.onclose) {
      wsConnectedEvent();
      store.setWsOpen(true);
      store.setWsStatus('Connected');
    }
  });
  setSplashMsg('Connected!');
  // console.log('WS Open');
}

function setSplashMsg (msg, submsg, type) {
  try {
    // const elem = document.getElementById('splashMsg');
    // elem.innerText = msg;
    const title = document.getElementById('titleSplash');
    title.innerHTML = msg || '';
    const subTitle = document.getElementById('subTitleSplash');
    subTitle.innerHTML = submsg || '';
    // add funcionality to check new subdomain
    if (type && type === 'subdomainTaken') {
      document.getElementById('loadingIcon').classList.add('hidden');
      document.getElementById('staticIcon').classList.remove('hidden');
    }
  } catch (err) {
    console.warn('setSplashMsg Err:', msg, err.message);
  }
}
setSplashMsg('Initializing...', '', 'initializing');

function removePrimaryTabListeners () {
  document.removeEventListener('visibilitychange', onBecomePrimaryTab);
  window.removeEventListener('focus', onBecomePrimaryTab);
}

function onClose (event) {
  wsDisconnectedEvent();
  store.setWsOpen(false);
  // console.log('WS Close', event.code);
  if (event.code === 4999) {
    store.setWsStatus('Other has priority, focus to connect');
    // console.log('Got kicked!');
  } else if (event.code === 4998) {
    removePrimaryTabListeners();
    store.setWsStatus('Identity rejected');
    if (0 && isMobile() && !webLicensedBaseFeatures.isDev) { // eslint-disable-line no-constant-condition
      // initAppDownload();
      initUseOnDesktop();
    } else {
      initSubdomainTaken();
    }
    pokeRemoveSplash();
  } else if (event.code === 4997) {
    removePrimaryTabListeners();
    store.setWsStatus('Secret rejected');
    if (0 && isMobile() && !webLicensedBaseFeatures.isDev) { // eslint-disable-line no-constant-condition
      // initAppDownload();
      initUseOnDesktop();
    } else {
      initSubdomainTaken();
    }
    pokeRemoveSplash();
  } else if (event.code === 4996) {
    removePrimaryTabListeners();
    store.setWsStatus('2FA required');
    if (0 && isMobile() && !webLicensedBaseFeatures.isDev) { // eslint-disable-line no-constant-condition
      initAppDownload();
    } else {
      initTwoFaPrompt();
    }
    pokeRemoveSplash();
  } else if (event.code === 4995) {
    removePrimaryTabListeners();
    const sandboxElement = document.getElementById('sandbox');
    if (sandboxElement) sandboxElement.remove();
    initDecryptError();
    if (!sandboxElement) pokeRemoveSplash();
  } else if (event.code === 4994) {
    removePrimaryTabListeners();
    const sandboxElement = document.getElementById('sandbox');
    if (sandboxElement) sandboxElement.remove();
    // initAppDownload();
    initUseOnDesktop();
    if (!sandboxElement) pokeRemoveSplash();
  } else if (event.code === 4993) {
    removePrimaryTabListeners();
    initPaymentView();
    pokeRemoveSplash();
  } else if (event.code === 4992) {
    removePrimaryTabListeners();
    const sandboxElement = document.getElementById('sandbox');
    if (sandboxElement) sandboxElement.remove();
    initLicenseExpired();
    if (!sandboxElement) pokeRemoveSplash();
  } else if (event.code === 4500) {
    removePrimaryTabListeners();
    setSplashMsg('', Vue.prototype.$t('components.subdomainAvailable.nameNotAllowed'), 'subdomainTaken');
    store.setWsStatus('Not allowed');
  } else {
    setSplashMsg('Disconnected...', '', 'disconnected');
    store.setWsStatus('Disconnected');
    if (reconnectTimeout) clearTimeout(reconnectTimeout);
    reconnectTimeout = setTimeout(() => {
      connect();
    }, 5000);
  }
}

export function wsCall (func, ...args) {
  if (ws && ws.readyState === 1) {
    ws.send(JSON.stringify({ type: 'call', func, args }));
  }
}

export function wsSend (data) {
  if (ws && ws.readyState === 1) {
    ws.send(JSON.stringify(data));
  }
}

setInterval(() => {
  try {
    if (ws && ws.readyState === 1) {
      ws.send('');
    }
  } catch (err) {
    console.warn('Ping Err:', err.message);
  }
}, 9500);

// force logout user
(function forceLogout () {
  const now = new Date();
  if (now.getHours() === 3 && now.getMinutes() === 0) {
    if (store) store.setPresentFrom('');
    // the modal is shown on state.user.presentFrom watcher at main.vue
  }
  const delay = 60000 - (Date.now() % 60000); // exact ms to next minute interval
  setTimeout(forceLogout, delay);
})();

function connect () {
  if (ws && ws.readyState === 1) {
    // console.log('connect called while in state', ws.readyState);
    return;
  }
  if (ws) {
    ws.onclose = undefined;
    ws.close(); // Just in case
    store.setWsOpen(false);
  }
  const baseUrl = process.env.WEBPACK_DEV_SERVER ? 'ws://localhost:3000' : location.origin.replace('http', 'ws');
  // const baseUrl = 'wss://beta.voffice.pro';
  let url = baseUrl + '?uuid=' + getOwnUUID();
  const secret = getOwnSecret();
  if (secret) url += '&secret=' + secret;
  const twotoken = getOwnTwoToken();
  if (twotoken) url += '&twotoken=' + twotoken;
  if (process.env.WEBPACK_DEV_SERVER && localStorage.getItem('PowerOfGlasses_API') === 'true') url += '&api=true';
  // console.log(url);
  // ws = new WebSocket(url, twotoken || undefined);
  ws = new WebSocket(url);
  store.setWsStatus('Attempting connection...');
  setSplashMsg('Attempting connection...');
  ws.onopen = onOpen;
  ws.onmessage = onMessage;
  ws.onclose = onClose;
}

export function wsInit () {
  callTable.deleteVisitor = deleteVisitor; // Extend callTable with deleteVisitor

  connect();

  document.addEventListener('visibilitychange', onBecomePrimaryTab);
  window.addEventListener('focus', onBecomePrimaryTab);

  window.addEventListener('beforeunload', () => {
    if (ws) {
      if (store.state.user.activity === "inCall" || store.state.user.activity === "inRoom") {
        store.setActivityUser(store.state.user.originalActivity);
      }
      store.state.user.requestedPayment.show = false;
      store.state.user.paymentState = {};
      store.state.user.organisationEditable = false;
      wsCall('setUserState', store.state.user);
      wsCall('setUserPersist', store.state.persisted);
    }
  });

  window.addEventListener('unload', () => {
    try {
      if (ws) {
        // console.log('resetted Unload triggering ws.close');
        ws.close();
      }
    } catch (err) {
      // console.warn('unload wsclose error:', err);
    }
  });
}

function onBecomePrimaryTab () {
  if (!document.hidden) {
    if (document.getElementById('two_sandbox')) {
      // console.log('2FA ignore reconnect for focus');
      return;
    }
    if (document.getElementById('paylink_sandbox')) {
      // console.log('Paylink ignore reconnect for focus');
      return;
    }
    if (!ws || (ws.readyState !== 1 && ws.readyState !== 0)) {
      // console.log('Reconnect for focus!');
      connect();
    }
  }
}
