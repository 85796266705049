<template>
  <v-list two-line v-if="messagesArray.length !==0" :min-height="310" style="overflow: hidden">
    <vue-scroll class="vueScroll">
      <div style="height: 310px">
        <v-list-item-group>
          <div v-for="(message, index) in messagesArray" :key="index">
            <!-- <template v-for="(individual, i) in message"> -->
                <!-- @click="setShowModalNotify(message.userUUID, {id: message.uuidMessage, 'type': individual.notificationType, 'notification':message.info, 'date':message.date}) ; handleTotalNotifications(message,individual.notificationType)" -->
                <!-- :class="message.read === false ? 'notiUnread': ''" -->
              <v-list-item
                @click="openNotification(message.messageUUID)"
                :key="index"
                v-if="message"
              >
                <template v-slot:default="{  }">
                  <v-list-item-avatar
                    tile
                    size="60"
                    class="borderRadius10 cursorPointer"
                    :style="{border: `2px solid ${setBorderByStatus(state.group[Object.keys(message.users)[0]])}`}"
                  >
                    <DefaultAvatarGrid
                      :size="120"
                      :userData="state.group[Object.keys(message.users)[0]]"
                      v-if="getAvatarForUuid(Object.keys(message.users)[0]) == 'img/default_profile_picture.png'"
                    ></DefaultAvatarGrid>
                    <v-img
                      v-if="getAvatarForUuid(Object.keys(message.users)[0]) != 'img/default_profile_picture.png'"
                      class="mx-2 borderRadius10"
                      max-height="60"
                      max-width="60"
                      contain
                      :src="getAvatarForUuid(Object.keys(message.users)[0])"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <!-- :class="( (message.type==='message' || message.type === 'plannerEvent')&& !message.users[ownUUID].read) ? 'font-weight-bold textBlue':''" -->
                    <v-list-item-title  v-text="getNameForUuid(Object.keys(message.users)[0])"></v-list-item-title>
                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="message.type === 'call' ? $t('components.notificationsModal.missedCall') : message.body || 'New message'"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                   <v-list-item-action>
                    <v-list-item-action-text v-text="formatNoticationDate(message.date)"></v-list-item-action-text>
                    <div class="d-flex" style="position: relative">
                    </div>
                  </v-list-item-action> 
                </template>
              </v-list-item>
              <!-- <v-divider v-if="index + 1 < messagesArray.length"></v-divider> -->
            <!-- </template> -->
          </div>
        </v-list-item-group>
      </div>
    </vue-scroll>
  </v-list>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import { getStatusColorByUser } from "../../utils/status";
// import { hasRepresentative } from "../../utils/representatives";
import { isWaitingRoomUser } from "../../utils/privileges";
import {messagesSent, /* dispatchDeleteAllMessageEvent, dispatchDeleteMessageEvent */ } from '../../effector/message'
import {setNotificationModalEvent} from "../../effector/modals"
export default {
  props: [""],
  components: { DefaultAvatarGrid },
  data() {
    return {
      state: store.state,
      persisted: store.state.persisted,
      moment: moment,
      user: store.state.user,
      ownUUID: store.state.ownUUID
    };
  },
  watch: {},
  effector:{
    messagesSent: messagesSent
  },
  methods: {
    openNotification(messageUUID){
      setNotificationModalEvent(messageUUID)
    },
    // removeAllListedItemsEffect(){
    //   dispatchDeleteAllMessageEvent()
    // },
    // removeIndividualMessageEffect(messageUUID){
    //   dispatchDeleteMessageEvent(messageUUID)
    // },
    // hasRepresentative(uuid) {
    //   return hasRepresentative(uuid);
    // },
    formatNoticationDate(date) {
      return this.moment(date).format("DD.MM.YYYY, H:mm") + " " + this.$t("components.meetingLineModal.hours");
    },
    setShowModalSenMsg(uuid, type = "") {
      return store.setShowModalSenMsg(uuid, type);
    },
    // mailBoxFull(person) {
    //   return ((person || {}).user || {}).unreadMessageCounter > 9;
    // },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    // handleTotalNotifications(message, type = "unknown") {
    //   if (type !== "unknown") {
    //     switch (type) {
    //       case "message":
    //         this.setTotalNotificationMessage(message);
    //         break;
    //       case "ticket":
    //         this.setTotalNotificationTicket(message);
    //         break;
    //       case "call":
    //         this.setTotalNotificationCall(message);
    //         break;
    //       case "file":
    //         this.setTotalNotificationFile(message);
    //         break;
    //       default:
    //         break;
    //     }
    //   }
    // },
    // setTotalNotificationCall: function(message) {
    //   let totalChips = this.state.persisted.totalNotificationCall;
    //   if (!message.read && totalChips > 0) {
    //     store.setTotalNotificationCall(--totalChips);
    //   }
    //   if (!message.read) {
    //     store.setMessageAsRead(message.uuidMessage, message);
    //   }
    // },
    // setTotalNotificationTicket: function(message) {
    //   let totalChips = this.state.persisted.totalNotificationTicket;
    //   if (!message.read && totalChips > 0) {
    //     store.setTotalNotificationTicket(--totalChips);
    //   }
    //   if (!message.read) {
    //     store.setMessageAsRead(message.uuidMessage, message);
    //   }
    // },
    // setTotalNotificationFile: function(message) {
    //   let totalChips = this.state.persisted.totalNotificationFile;
    //   if (!message.read && totalChips > 0) {
    //     store.setTotalNotificationFile(--totalChips);
    //   }
    //   if (!message.read) {
    //     store.setMessageAsRead(message.uuidMessage, message);
    //   }
    // },
    // setTotalNotificationMessage: function(message) {
    //   let totalChips = this.state.persisted.totalNotificationMessage;
    //   if (!message.read && totalChips > 0) {
    //     store.setTotalNotificationMessage(--totalChips);
    //   }
    //   if (!message.read) {
    //     store.setMessageAsRead(message.uuidMessage, message);
    //   }
    // },
    getUserIsGuest(uuid) {
      return store.getUserIsGuest(uuid);
    },
    // removeMessage: function(...args) {
    //   store.removeMessage(...args);
    // },
    getNameForUuid: function(...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function(...args) {
      return store.getAvatarForUuid(...args);
    },
    setShowModalNotify: function(...args) {
      store.setShowModalNotify(...args);
    },
    // settotalNotification: function(...args) {
    //   store.settotalNotification(...args);
    // },
  },
  computed: {
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    getEmptyText: function() {
      // if (this.notificationType == "missedCalls") {
      //   return this.$t("components.notificationDropDown.noMissedCalls");
      // } else if (this.notificationType == "incomingTickets") {
      //   return this.$t("components.notificationDropDown.noNewTasks");
      // } else if (this.notificationType === "messages") {
      //   return this.$t("components.notificationDropDown.noNewMessages");
      // } else if (this.notificationType === "uploads") {
      //   return this.$t("components.notificationDropDown.noNewFiles");
      // } else {
      //   return this.$t("components.notificationDropDown.noNewNotifications");
      // }
    },
    messagesArray: function() {
      // if(this.notificationType === 'message'){
        return this.messagesSent
      // }
    }
  },
  mounted: function mounted() {}
};
</script>

<style scoped lang="scss">
.lockMessageIcon{
  position: absolute;
  right: 28px;
  top: -6px;
}
.textBlue{
  color: #2a3133;
}
.notiUnread {
  /* background-color: #f5f5f5; */
}
.btnRed {
  background: red;
  border-radius: 4px;
}
.vueScroll {
  .__view {
    width: unset !important;
  }
}
.divNotNotis {
  height: 40px;
  padding: 9px;
  .notNotifications {
    color: var(--form-text);
  }
}
</style>