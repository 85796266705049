// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://use.typekit.net/ved4nhz.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-panel[data-v-19645f50] {
  padding: 0 12px 12px 12px;
}
.panel-header[data-v-19645f50] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "altesse-std-24pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.panel-content[data-v-19645f50] {
  font-size: 32px;
}
.colorBlack[data-v-19645f50] {
  color: black;
}
.colorWhite[data-v-19645f50] {
  color: white;
}
.adobe-link[data-v-19645f50] {
  position: absolute;
  top: 0;
  right: 15px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/socialMediaContent.vue","webpack://./socialMediaContent.vue"],"names":[],"mappings":"AAGA;EACI,yBAAA;ACDJ;ADIA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,2CAAA;EACA,gBAAA;EACA,kBAAA;ACDJ;ADIA;EAEI,eAAA;ACFJ;ADKA;EACI,YAAA;ACFJ;ADIA;EACI,YAAA;ACDJ;ADMA;EACI,kBAAA;EACA,MAAA;EACA,WAAA;ACHJ","sourcesContent":["\n@import url(\"https://use.typekit.net/ved4nhz.css\");\n\n.custom-panel {\n    padding: 0 12px 12px 12px;\n}\n\n.panel-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-family: \"altesse-std-24pt\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n}\n\n.panel-content {\n    //   margin-top: 8px;\n    font-size: 32px;\n    \n}\n.colorBlack{\n    color: black;\n}\n.colorWhite{\n    color: white;\n}\n\n\n\n.adobe-link {\n    position: absolute;\n    top: 0;\n    right: 15px;\n}\n","@import url(\"https://use.typekit.net/ved4nhz.css\");\n.custom-panel {\n  padding: 0 12px 12px 12px;\n}\n\n.panel-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-family: \"altesse-std-24pt\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.panel-content {\n  font-size: 32px;\n}\n\n.colorBlack {\n  color: black;\n}\n\n.colorWhite {\n  color: white;\n}\n\n.adobe-link {\n  position: absolute;\n  top: 0;\n  right: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
