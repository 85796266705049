var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560px" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.support"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "messageForm",
              attrs: { "lazy-validation": "", autocomplete: false },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex flex-column px-5 my-4",
                  style: { width: "100%", margin: 0 }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex" },
                        [
                          _c("v-combobox", {
                            staticClass: "mr-2",
                            attrs: {
                              items: _vm.itemsProblems,
                              label: _vm.$t(
                                "components.bugReport.vofficeProblem"
                              ),
                              outlined: "",
                              rules: _vm.rules,
                              dense: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.selectProblem,
                              callback: function($$v) {
                                _vm.selectProblem = $$v
                              },
                              expression: "selectProblem"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-combobox", {
                            attrs: {
                              "hide-details": "",
                              items: _vm.itemsBrowser,
                              label: _vm.$t("components.bugReport.browser"),
                              outlined: "",
                              rules: _vm.rules,
                              dense: ""
                            },
                            model: {
                              value: _vm.selectBrowser,
                              callback: function($$v) {
                                _vm.selectBrowser = $$v
                              },
                              expression: "selectBrowser"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex" },
                        [
                          _c("v-combobox", {
                            staticClass: "mr-2",
                            attrs: {
                              "hide-details": "",
                              items: _vm.itemsSystem,
                              label: _vm.$t("components.bugReport.system"),
                              outlined: "",
                              rules: _vm.rules,
                              dense: ""
                            },
                            model: {
                              value: _vm.selectSystem,
                              callback: function($$v) {
                                _vm.selectSystem = $$v
                              },
                              expression: "selectSystem"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-combobox", {
                            attrs: {
                              rules: _vm.rules,
                              "hide-details": "",
                              items: _vm.itemsNetwork,
                              label: _vm.$t("components.bugReport.network"),
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.selectNetwork,
                              callback: function($$v) {
                                _vm.selectNetwork = $$v
                              },
                              expression: "selectNetwork"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex py-0" },
                        [
                          _c("v-text-field", {
                            staticClass: "mr-2",
                            attrs: {
                              counter: "",
                              label: _vm.$t("components.bugReport.location"),
                              placeholder: "Office, Home Office",
                              rules: _vm.rules,
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.location,
                              callback: function($$v) {
                                _vm.location = $$v
                              },
                              expression: "location"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              counter: "",
                              label: _vm.$t("components.bugReport.subject"),
                              rules: _vm.rules,
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.bugTitle,
                              callback: function($$v) {
                                _vm.bugTitle = $$v
                              },
                              expression: "bugTitle"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex py-0" },
                        [
                          _c("v-textarea", {
                            attrs: {
                              counter: "",
                              contenteditable: "true",
                              label: _vm.$t("components.bugReport.body"),
                              "no-resize": true,
                              maxlength: "5000",
                              id: "bugTextarea"
                            },
                            on: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "esc",
                                    27,
                                    $event.key,
                                    ["Esc", "Escape"]
                                  )
                                ) {
                                  return null
                                }
                                return _vm.closeModal.apply(null, arguments)
                              }
                            },
                            model: {
                              value: _vm.bugText,
                              callback: function($$v) {
                                _vm.bugText = $$v
                              },
                              expression: "bugText"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FooterModal",
                {
                  attrs: {
                    closeModalFunction: _vm.closeModal,
                    showCancel: true
                  }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.disabledSendBug
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendBug()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("components.sendMessageModal.send")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }