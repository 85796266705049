var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "sandbox" } },
    [
      _c("v-app-bar", {
        attrs: { "clipped-left": _vm.primaryDrawer.clipped, app: "" }
      }),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0 rowDownload text-center",
              attrs: { flex: "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0 py-0 text-center contentDownload",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "fontSize30 font-weight-bold justify-center"
                    },
                    [
                      _c("v-img", {
                        staticClass: "mr-2",
                        attrs: {
                          "max-height": 45,
                          "max-width": 45,
                          src: "assets/icon.png",
                          contain: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "primary--text" }, [
                        _vm._v("vOffice ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm._v(
                      "\n         " +
                        _vm._s(
                          _vm.$t("components.downloadApp.downloadAppText")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.mobileOperatingSystem === "iOS"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "app-btn blu flex vert",
                          attrs: {
                            target: "_blank",
                            href:
                              "https://apps.apple.com/de/app/voffice-app/id1523298495"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "28px", marginRight: 7 },
                            attrs: { icon: ["fab", "apple"] }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.downloadApp.availableIn")
                              ) + " "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "big-txt" }, [
                              _vm._v("App Store")
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mobileOperatingSystem === "Android"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "app-btn blu flex vert",
                          attrs: {
                            target: "_blank",
                            href:
                              "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.voffice.vofficepro"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "28px", marginRight: 7 },
                            attrs: { icon: ["fab", "google-play"] }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("components.downloadApp.getItOn")) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "big-txt" }, [
                              _vm._v("Google Play")
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }