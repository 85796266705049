var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          !_vm.editingPhone
            ? [
                _c(
                  "div",
                  { staticClass: "tex0t-truncate body-1 containerWrapper" },
                  [
                    _c(
                      "span",
                      { style: _vm.phoneNumberValid ? {} : { color: "red" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.2faSms.recoveryPhoneNumber")
                          )
                        )
                      ]
                    ),
                    _vm._v(
                      "\n        " + _vm._s(_vm.phoneNumber) + "\n        "
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggle2faActivationModal()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "pen"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3698454049
                        )
                      },
                      [
                        _vm._v(" "),
                        _vm.phoneNumberValid
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.2faSms.editPhoneNumber")
                                )
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.2faSms.phoneNumberRequired"
                                  )
                                )
                              )
                            ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("components.2faSms.warningMsg")))
                      ])
                    ]),
                    _vm._v(" "),
                    !_vm.phoneNumberValid
                      ? _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.2faSms.userPhoneNumberLabel"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-checkbox", {
                              staticClass: "mt-0 py-0 px-1",
                              on: {
                                change: function($event) {
                                  return _vm.toggle2faActivationModal()
                                }
                              },
                              model: {
                                value: _vm.dontUsePhone,
                                callback: function($$v) {
                                  _vm.dontUsePhone = $$v
                                },
                                expression: "dontUsePhone"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.editingPhone
            ? [
                _c("vue-tel-input", {
                  staticClass:
                    "form-control text-center input-sm inputPhone2FA",
                  class: { borderError: !_vm.phoneInputValid },
                  attrs: {
                    required: "",
                    defaultCountry: "DE",
                    enabledCountryCode: "",
                    name: "number",
                    id: "numDisplay",
                    value: "",
                    placeholder: "",
                    autocomplete: "off"
                  },
                  on: { onInput: _vm.onTelInput },
                  model: {
                    value: _vm.phoneNumberInput,
                    callback: function($$v) {
                      _vm.phoneNumberInput = $$v
                    },
                    expression: "phoneNumberInput"
                  }
                }),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      icon: "",
                                      disabled: !_vm.phoneInputValid,
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.savePhone()
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "check"] }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3922108162
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generics.save")))])
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.showActivation
        ? _c("TwoFactorActivationModal", {
            attrs: {
              showActivation: _vm.showActivation,
              toggle2faActivationModal: _vm.toggle2faActivationModal,
              saveEntryNoPhone: _vm.saveEntryNoPhone
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }