<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="type === 'Holidays' ? $t('components.holidays.setYourHolidays') : $t('components.holidays.setYourIll')"
        :closeModalFunction="closeModal"
        :showInfoButton="type"
      />
      <v-card-text class="mx-auto">
        <!-- <v-row class="mx-auto" flex>
          <v-col cols="6" align="center"
            >{{ $t("components.holidays.start") }}
            {{ getStartHolidaysFormatted }}</v-col
          >
          <v-col cols="6" align="center"
            >{{ $t("components.holidays.end") }}
            {{ getEndHolidaysFormatted }}</v-col
          >
        </v-row> -->
        <v-row class="mx-auto" flex>
          <v-col :cols="isMobile? '12':'6'">
            <div class="mb-2 text-center">
            {{ $t("components.holidays.start") }}
            {{ getStartHolidaysFormatted }}</div>
            <v-date-picker
              no-title
              v-model="startHolidays"
              :show-current="startHolidays"
              :pickTime="true"
              :format="'DD.MM.YYYY H:mm'"
              :max="endHolidays"
              :locale="this.$locale.current()"
              first-day-of-week="1"
              width="100%"
            ></v-date-picker>
          </v-col>
          <v-col :cols="isMobile? '12':'6'">
            <div class="mb-2 text-center">{{ $t("components.holidays.end") }}
            {{ getEndHolidaysFormatted }}</div>
            <v-date-picker
              :min="startHolidays"
              no-title
              v-model="endHolidays"
              :show-current="startHolidays"
              :pickTime="true"
              :format="'DD.MM.YYYY H:mm'"
              :locale="this.$locale.current()"
              first-day-of-week="1"
              width="100%"
            ></v-date-picker>
          </v-col>
        </v-row>
        <v-row class="mx-auto" flex>
          <v-col :cols="12">
            <v-combobox
              outlined
              v-model="representative"
              :items="dataComboboxAllUsers"
              :search-input.sync="searchUsers"
              @change="
                searchUsers = '';
              "
              select
              :label="$t('excel.representative')"
              hide-details
              class="mb-5"
              dense
              item-value="value"
              item-text="text"
              :return-object="true"
              @blur="selectItemOnBlur"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row class="mx-auto py-0" flex>
          <v-col class="py-0 my-0" :cols="12">
            <v-radio-group  @change="onRadioChange" v-if="!checkDisableType" hide-details class="my-0 py-0" :column="false" v-model="type" :disabled="checkDisableType">
              <v-radio
                :label="$t('status.Holidays')"
                value="Holidays"
                class="mr-4"
              ></v-radio>
              <v-radio
                :label="$t('status.Ill')"
                value="Ill"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <FooterModal :showCancel="true" :closeModalFunction="closeModal">
        <v-btn
          :disabled="disabledSave"
          depressed
          color="primary"
          class="ml-2"
          @click="deleteHolidays"
          >{{ $t("components.holidays.deleteHolidays") }}</v-btn
        >
        <v-btn
          color="primary"
          depressed
          class="ml-2"
          :disabled="disabledSave"
          @click="saveHolidays"
          >{{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import { isMobile } from "../../lib/mobileUtil";
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges.js";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: ["showModal", "closeModal", "userUUID", "calendarStatus"],
  data: () => ({
    state: store.state,
    startHolidays: "",
    endHolidays: "",
    moment: moment,
    isMobile: isMobile(),
    searchUsers: null,
    representative: "",
    initialRepresentative: "",
    type: "Holidays"
  }),
  mounted: function () {
    if (this.calendarStatus) {
      this.type = this.calendarStatus;
    }
    if (this.type === 'Holidays' && store.state.group[this.userUUID].user?.startHolidays && store.state.group[this.userUUID].user?.endHolidays) {
      this.startHolidays = store.state.group[this.userUUID].user.startHolidays;
      this.endHolidays = store.state.group[this.userUUID].user.endHolidays;
      this.type = 'Holidays';
    } else if (this.type === 'Ill' && store.state.group[this.userUUID].user?.startIllness && store.state.group[this.userUUID].user?.endIllness) {
      this.startHolidays = store.state.group[this.userUUID].user.startIllness;
      this.endHolidays = store.state.group[this.userUUID].user.endIllness;
      this.type = 'Ill';
    }
    this.representative = store.state.group[this.userUUID].user?.representative;
    this.initialRepresentative = store.state.group[this.userUUID].user?.representative;
  },
  methods: {
    onRadioChange(value) {
      if (value === 'Holidays') {
        this.startHolidays = store.state.group[this.userUUID].user.startHolidays;
        this.endHolidays = store.state.group[this.userUUID].user.endHolidays;
      } else if (value === 'Ill') {
        this.startHolidays = store.state.group[this.userUUID].user.startIllness || '';
        this.endHolidays = store.state.group[this.userUUID].user.endIllness || '';
      }
    },
    deleteHolidays() {
      this.setSaveHolidays(undefined, undefined);
      this.state.group[this.userUUID].user.activity = "Available";
      this.closeModal();
    },
    saveHolidays() {
      try {
        const startHolidaysTimeStamp = new Date(
          moment(this.startHolidays).format("DD.MM.YYYY HH:mm:ss")
        ).getTime();
        if (startHolidaysTimeStamp < Date.now()) {
          // if the current date is higher than the start holidays setted, set activity to holidays automatically
          this.state.group[this.userUUID].user.activity = this.type;
        }

        const representative = this.representative && this.representative.value ? { 
          value: this.representative.value,
          text: this.representative.text 
        } : null;

        store.setSaveHolidaysRepresentative(this.startHolidays, this.endHolidays, representative, this.userUUID, this.type)

        this.closeModal();
      } catch {}
    },
    setSaveHolidays(startDate, endDate) {
      store.setSaveHolidays(startDate, endDate, this.userUUID, this.type);
    },
    selectItemOnBlur() {
      if (this.filteredItems?.length == 1) {
        this.representative = this.filteredItems[0];
      }
    },
  },
  computed: {
    checkDisableType() {
      if (this.calendarStatus) return true;
      // if (this.state.group[this.userUUID].user.startHolidays || this.state.group[this.userUUID].user.startIllness) {
      //   return true;
      // }
      return false;
    },
    getStatus() {
      return this.state.user.activity;
    },
    getStartHolidaysFormatted() {
      if (this.startHolidays) {
        return moment(this.startHolidays).format("DD.MM.YYYY");
      }
      return null;
    },
    getEndHolidaysFormatted() {
      if (this.endHolidays) {
        return moment(this.endHolidays).format("DD.MM.YYYY");
      }
      return null;
    },
    disabledSave() {
      if (!this.startHolidays || !this.endHolidays) {
        return true;
      }
      return false;
    },
    dataComboboxAllUsers() {
      const membersList = JSON.parse(JSON.stringify(this.state.group));
      const selectedUser = store.state.group[this.userUUID].user;
      if (selectedUser?.uuid && membersList && membersList[selectedUser?.uuid]) {
        delete membersList[selectedUser?.uuid]
      }
      let finalResult = [];
      for (let uuid in membersList) {
        let person = membersList[uuid];
        if (person && person.user && person.user.name && person.user.uuid && hasPrivilege(person.user.uuid)) {
          const item = {
            value: person.user.uuid,
            text: person.user.name,
          };
          finalResult.push(item);
        }
      }
      return finalResult;
    },
    filteredItems() {
      if (!this.searchUsers) return;
      return this.dataComboboxAllUsers.filter(item => 
        item.text.toLowerCase().includes(this.searchUsers.toLowerCase()) || 
        item.value.toLowerCase().includes(this.searchUsers.toLowerCase())
      );
    },
  },
};
</script>