var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getTTPosition + " " + (_vm.extraclass || "") },
    [
      _c("span", { class: "tooltippedtooltip tooltippedtooltipButton" }, [
        _vm._v(_vm._s(_vm.text))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }