var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "620" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.adminSettingsModal.settingPay"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "components.adminSettingsModal.payInfoText1"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("components.adminSettingsModal.payText2")
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "components.adminSettingsModal.payOfficePaylink"
                                )
                              )
                          )
                        ]),
                        _vm._v(
                          "\n               " +
                            _vm._s(
                              _vm.$t("components.adminSettingsModal.paytext3")
                            ) +
                            " \n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "components.adminSettingsModal.payOfficeFacepya"
                                )
                              )
                          )
                        ]),
                        _vm._v(
                          "\n               " +
                            _vm._s(
                              _vm.$t("components.adminSettingsModal.payText4")
                            ) +
                            " \n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("components.adminSettingsModal.payText5", [
                                "RA-MICRO Software AG"
                              ])
                            ) +
                            "\n            "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "FooterModal",
            {
              attrs: {
                hideDefaultButtons: true,
                closeModalFunction: _vm.closeModal,
                showFooter: true
              }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary" },
                                on: { click: _vm.saveSettings }
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$t("generics.ok")))]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.ok")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.closeCancelModal()
                                  }
                                }
                              },
                              on
                            ),
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.cancel")))])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }