var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dayTimeOverlay d-flex flex-row" }, [
    _c("div", { staticStyle: { width: "80%" } }),
    _vm._v(" "),
    _vm.room.isFavorite
      ? _c(
          "span",
          { staticClass: "mx-2 pt-1" },
          [
            _c("font-awesome-icon", {
              staticClass: "yellow-star",
              attrs: { icon: ["fas", "star"] }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }