<template>
  <v-card
    v-if="showFooter ? showFooter : true"
    class="py-2 pl-4 pr-2 footerModal footersBackground"
  >
    <v-row class="pl-2 btns w-100 mx-0 my-0">
      <slot></slot>
      <template v-if="!hideDefaultButtons">
        <v-btn
          color="primary"
          style="border-radius: 4px; min-width: 45px;"
          icon
          tile
          outlined
          class="ml-3 mr-2 greyBtn"
          v-if="showOkButton"
          @click="closeModalFunction()"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '20px' }"
          />
          <!--{{ $t("generics.ok") }}-->
        </v-btn>
        <v-btn
          color="primary"
          style="border-radius: 4px; min-width: 45px;"
          icon
          tile
          outlined
          class="ml-3 mr-2 greyBtn"
          v-if="showCancelButton"
          @click="closeModalFunction()"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '20px' }"
          />
          <!--{{ $t("generics.cancel") }}-->
        </v-btn>

        <v-btn
          color="primary"
          style="border-radius: 4px; min-width: 45px;"
          icon
          tile
          outlined
          class="ml-3 mr-2 greyBtn"
          v-if="showClose"
          @click="closeModalFunction()"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '20px' }"
          />
          <!--{{ $t("generics.close") }}-->
        </v-btn>
        <v-btn
          color="primary"
          style="border-radius: 4px; min-width: 45px;"
          class="ml-3 mr-2 greyBtn"
          v-if="closeModalFunction && crossIcon"
          v-on="on"
          icon
          tile
          @click="closeModalFunction()"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '20px' }"
          />
        </v-btn>
      </template>
    </v-row>
  </v-card>
</template>


<script>
import store from "../../../store";
export default {
  components: {},
  props: [
    "closeModalFunction",
    "showFooter",
    "crossIcon",
    "hideDefaultButtons",
    "showCancel",
    "showClose",
  ],
  data: () => ({
    state: store.state,
  }),
  mounted() {},
  methods: {},
  computed: {
    showCancelButton() {
      if (this.closeModalFunction && !this.crossIcon && this.showCancel) {
        return true;
      }
      return false;
    },
    showOkButton() {
      if (
        this.closeModalFunction &&
        !this.crossIcon &&
        !this.showCancel &&
        !this.showClose
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
<style>
.buttonIconPrimaryFooter {
  box-shadow: none !important;
  border-radius: 4px !important;
  /* background-color: white !important; */
  min-width: 45px !important;
}
.buttonIconPrimaryFooterDisable {
  background-color: rgba(255, 255, 255, 0.40) !important;
  color: #11889d!important;
}
.buttonPrimaryFooter{
  box-shadow:none!important;
  color: #11889d!important;
}
</style>