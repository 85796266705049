var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShow
    ? _c("div", { staticClass: "box", style: _vm.magicStyle }, [
        _c("div", { staticClass: "rulerDigit" })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }