var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "buttonCallFrontCard",
                        attrs: { icon: "", color: "#0000008a" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.sendMessage()
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c("img", {
                        staticStyle: { width: "20px" },
                        attrs: { src: "/img/icon_icon.svg" }
                      })
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.$t("components.userListItem.callBackTT")))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }