var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto elevation-0",
      attrs: { flat: "", "max-width": "100%", outlined: "" }
    },
    [
      _c("v-divider", { staticClass: "mt-1" }),
      _vm._v(" "),
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-list-item-avatar",
                        _vm._g(
                          {
                            class:
                              "borderRadius5 mx-2 my-2 " +
                              (!_vm.personIsConnected ? "grayScale" : ""),
                            attrs: { tile: "", size: "80" }
                          },
                          on
                        ),
                        [
                          _vm.avatarSrc === "img/default_profile_picture.png"
                            ? _c("DefaultAvatar", {
                                attrs: { size: 80, userData: _vm.visitor }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.avatarSrc !== "img/default_profile_picture.png"
                            ? _c("v-img", {
                                staticClass: "mx-2 borderRadius5 cursorPointer",
                                attrs: {
                                  src: _vm.avatarSrc,
                                  "max-height": "80",
                                  "max-width": "80",
                                  contain: "",
                                  "lazy-src":
                                    "assets/default_profile_picture.png"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.visitor.sideBarSubject || ""))])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            { staticClass: "contentWrapper" },
            [
              _c("v-list-item-subtitle", [
                _vm.visitor.created
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "font-weight": "600"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.formatDateToShow(_vm.visitor.created)) +
                            " h  " +
                            _vm._s(
                              _vm.visitor.isWaitingRoom
                                ? _vm.$t("components.waitingRoom.waitingRoom")
                                : _vm.$t("generics.user")
                            )
                        )
                      ]
                    )
                  : _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("Unconfirmed user")
                    ])
              ]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "12px", "font-weight": "600" }
                  },
                  [_vm._v(_vm._s(_vm.visitor.name))]
                )
              ]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v(
                    _vm._s(_vm.visitor.email || _vm.visitor.mobilePhone || "")
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.visitor.linkDateTo &&
              _vm.visitor.linkTimeTo &&
              _vm.visitor.duration
                ? _c("v-list-item-subtitle", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.inviteVisitor.appointmentshort")
                      ) +
                        " " +
                        _vm._s(_vm.getTimeMessage)
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      _vm._s(
                        _vm.visitor.sideBarSubject
                          ? _vm.visitor.sideBarSubject.substring(
                              0,
                              Math.min(_vm.visitor.sideBarSubject.length, 20)
                            ) + "..."
                          : null
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "px-10 noBRadius",
          style: {
            borderWidth: 1,
            borderColor: _vm.borderClass,
            borderStyle: "solid",
            height: 36
          },
          attrs: { color: _vm.badgetClassObj }
        },
        [
          _c(
            "v-row",
            [
              !_vm.isInCallWithUs
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", disabled: _vm.hasActivityNoCall },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        color: _vm.phoneColorColor,
                                        disabled: _vm.disableCallBtn
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.callUser(_vm.uuid)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    !_vm.wholeBarWithColors()
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fas", "phone"] }
                                        })
                                      : _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "phone"] }
                                        })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3530868344
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.userListItem.videoCallStart")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isInCallWithUs
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", disabled: _vm.hasActivityNoCall },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        color: _vm.phoneColorColor
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm
                                            .getWebrtcConnections()
                                            [_vm.uuid].die(true)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    !_vm.wholeBarWithColors()
                                      ? _c("font-awesome-icon", {
                                          staticClass: "faPhoneRotate",
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "phone"],
                                            rotation: 270
                                          }
                                        })
                                      : _c("font-awesome-icon", {
                                          staticClass: "faPhoneRotate",
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fas", "phone"],
                                            rotation: 270
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2282392504
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.userListItem.hangOff"))
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticStyle: { "margin-left": "15px" },
                                attrs: {
                                  icon: "",
                                  color: _vm.wholeBarWithColors()
                                    ? "white"
                                    : "rgb(83, 82, 82)"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.SendLinkToVisitor()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fas", "redo"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.inviteVisitor.resendInvLink"))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticStyle: { "margin-left": "15px" },
                                attrs: {
                                  icon: "",
                                  color: _vm.wholeBarWithColors()
                                    ? "white"
                                    : "rgb(83, 82, 82)"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyLink($event)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "copy"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.copylink")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-end": "" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        scrollable: "",
                        "close-on-click": true
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticStyle: {
                                      width: "1px",
                                      height: "1px",
                                      display: "none"
                                    }
                                  },
                                  onMenu
                                )
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "btnr15",
                                                  attrs: {
                                                    icon: "",
                                                    color: _vm.wholeBarWithColors()
                                                      ? "white"
                                                      : "rgb(83, 82, 82)"
                                                  }
                                                },
                                                Object.assign({}, on, onMenu)
                                              ),
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "23px" },
                                                  attrs: {
                                                    icon: ["fal", "info-circle"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteVisitor.onecalldetails"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        {
                          staticStyle: {
                            width: "550px",
                            "padding-bottom": "0px !important"
                          }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-title",
                                { staticStyle: { "font-weight": "400" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteVisitor.onecalldetails"
                                      )
                                    ) + ":"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "mt-1" }),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-row",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    margin: "0",
                                    "padding-bottom": "20px"
                                  }
                                },
                                [
                                  _c("v-col", { staticClass: "col-3 tigth" }, [
                                    _c("p", { staticClass: "fieldHeader" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.inviteVisitor.name"
                                          )
                                        ) + ":"
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "col-9 tigth" }, [
                                    _vm._v(_vm._s(_vm.visitor.name))
                                  ]),
                                  _vm._v(" "),
                                  _vm.visitor.email
                                    ? [
                                        _c(
                                          "v-col",
                                          { staticClass: "col-3 tigth" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "fieldHeader" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteVisitor.invitationEmail"
                                                    )
                                                  ) + ":"
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-9 tigth" },
                                          [_vm._v(_vm._s(_vm.visitor.email))]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.mobilePhone
                                    ? [
                                        _c(
                                          "v-col",
                                          { staticClass: "col-3 tigth" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "fieldHeader" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("excel.mobilePhone")
                                                  ) + ":"
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { staticClass: "col-9 tigth" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.visitor.mobilePhone)
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "col-3 tigth" }, [
                                    _c("p", { staticClass: "fieldHeader" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.inviteVisitor.invitation"
                                          )
                                        ) + ":"
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm.visitor.created
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-9 tigth" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getVisitorDate(
                                                _vm.visitor.created
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.linkDateTo &&
                                  _vm.visitor.linkTimeTo
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-3 tigth" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "fieldHeader" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.inviteVisitor.appointmentshort"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.linkDateTo &&
                                  _vm.visitor.linkTimeTo
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-9 tigth" },
                                        [_vm._v(_vm._s(_vm.getTimeMessage))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.internalnote
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-3 tigth" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "fieldHeader" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.inviteVisitor.subject"
                                                  )
                                                ) + ":"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.internalnote
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-9 tigth" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              (
                                                _vm.visitor.subject || ""
                                              ).substring(0, 44)
                                            ) + "..."
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.internalnote
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-3 tigth" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "fieldHeader" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.inviteVisitor.notice"
                                                  )
                                                ) + ":"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.visitor.internalnote
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-9 tigth" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.visitor.internalnote)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-card", {
                            staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                            staticStyle: { "min-height": "35px" },
                            attrs: { color: "primary" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "btnr15",
                                    attrs: {
                                      icon: "",
                                      disabled: _vm.state.isConfDeleting,
                                      color: _vm.wholeBarWithColors()
                                        ? "white"
                                        : "rgb(83, 82, 82)"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("DeleteBasket", {
                                    attrs: {
                                      indexData: _vm.visitor.uuid,
                                      delFunction: _vm.deleteVisitor,
                                      extraClasses: "",
                                      fontSize: "20",
                                      prevent: "false"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.inviteVisitor.deleteOneCall")
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.state.persisted.userVisitors[_vm.uuid]
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            scrollable: "",
                            "close-on-click": true
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "btnAbsolute",
                                          attrs: {
                                            icon: "",
                                            color: _vm.state.user.colorsettings
                                              .wholeBar
                                              ? "white"
                                              : "rgb(83, 82, 82)"
                                          },
                                          on: { click: _vm.resolveLink }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "30px" },
                                          attrs: { icon: ["fal", "ellipsis-v"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3075971708
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "cursorPointer",
                                  on: {
                                    click: function($event) {
                                      _vm.showTransferVisitorModal = true
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteVisitor.transferVisitor"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      [
        _vm.showTransferVisitorModal
          ? _c("TransferVisitorModal", {
              attrs: {
                closeTransferVisitorModal: _vm.closeTransferVisitorModal,
                showTransferVisitorModal: _vm.showTransferVisitorModal,
                doTransferVisitor: _vm.doTransferVisitor,
                visitor: _vm.visitor
              }
            })
          : _vm._e()
      ],
      _vm._v(" "),
      [
        _vm.showInvitationModal
          ? _c("SendInvitationModal", {
              attrs: {
                closeShowInviteInfo: _vm.closeShowInviteInfo,
                showInviteInfo: _vm.showInvitationModal
              }
            })
          : _vm._e()
      ],
      _vm._v(" "),
      _vm.showModalDelete
        ? [
            _c("RemoveVisitorModal", {
              attrs: {
                showModal: _vm.showModalDelete,
                closeModal: _vm.closeRemoveUserModal,
                deleteVisitor: _vm.deleteVisitor
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }