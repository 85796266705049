var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "d-none": _vm.hideVideoContainer
      },
      attrs: { id: "video-container" }
    },
    [
      _vm.showDebug
        ? _c(
            "v-btn",
            {
              staticStyle: { "z-index": "10000", position: "fixed" },
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  return _vm.showRecorderDebug()
                }
              }
            },
            [_vm._v("\n    Debug\n   ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("VideoBridgeContentGrid", {
        attrs: {
          isRecorder: _vm.isRecorder,
          hasPrivilege: _vm.userHasPrivilege,
          showTimeLine: _vm.showTimeLine,
          userIsOneCall: _vm.userOneCall
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sidenav d-none recorderBar",
          attrs: { id: "recorderBar" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "togglePlay tooltipped",
              attrs: { icon: "", color: "white" }
            },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "20px" },
                attrs: { icon: ["fal", "play"] }
              }),
              _vm._v(" "),
              _c("font-awesome-icon", {
                staticClass: "d-none fa-spin",
                style: { fontSize: "20px" },
                attrs: { icon: ["fal", "spinner"] }
              }),
              _vm._v(" "),
              _c("font-awesome-icon", {
                staticClass: "d-none",
                style: { fontSize: "20px" },
                attrs: { icon: ["fal", "pause"] }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "tooltippedtooltip ttside sidePlayStop" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.bridgeCallTooltips.playStop"))
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "toggleStop tooltipped",
              attrs: { icon: "", color: "white" }
            },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "20px" },
                attrs: { icon: ["fal", "stop"] }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "tooltippedtooltip ttside sideStopStream" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.bridgeCallTooltips.stopStream"))
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "openRecorder tooltipped",
              attrs: { icon: "", color: "white" }
            },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "20px" },
                attrs: { icon: ["fal", "external-link"] }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "tooltippedtooltip ttside sideViewRec" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.bridgeCallTooltips.showRecorder"))
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "toggleSettings tooltipped",
              attrs: { color: "white", icon: "" }
            },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "20px" },
                attrs: { icon: ["fal", "cog"] }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "tooltippedtooltip ttside sideSettings" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.bridgeCallTooltips.streamSettings")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("video", {
        staticClass: "d-none",
        attrs: { autoplay: "", loop: "", id: "localVideo", playsinline: "" }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tile videoBridgeWrapper d-none",
          attrs: { id: "contentElement" }
        },
        [
          _c(
            "div",
            { staticClass: "topbar" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              { staticClass: "userIconInfo tooltipped" },
                              on
                            ),
                            [
                              _c(
                                "span",
                                {
                                  staticClass: " userIconTile font-weight-light"
                                },
                                [
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(_vm.$t("generics.user")) +
                                      "\n             "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    " d-none guestLabelTile font-weight-light"
                                },
                                [
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(
                                        _vm.$t("components.userSettings.guest")
                                      ) +
                                      "\n             "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.remotePersonCall.user")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "moderatorIconInfo d-none" }, [
                _vm._v(_vm._s(_vm.$t("components.remotePersonCall.moderator")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "callerName nameNormal" }),
              _vm._v(" "),
              _c("input", {
                staticClass: "inputTopbar d-none",
                attrs: { type: "text" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "iconbar" })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tile d-none", attrs: { id: "localData" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "iconbar", attrs: { id: "localIconbar" } }),
        _vm._v(" "),
        _c("video", {
          attrs: { id: "vid0", autoplay: "", muted: "", loop: "" },
          domProps: { muted: true }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "qualityLevel d-none", attrs: { id: "qualityLevel" } },
        [_vm._v(_vm._s(_vm.$t("components.bridgeCallTooltips.badQuality")))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "qualityLevel d-none", attrs: { id: "deafen" } },
        [_vm._v(_vm._s(_vm.$t("components.bridgeCallTooltips.deafenTT")))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "iconBarLocalStream d-none",
          attrs: { id: "iconBarLocalStream" }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "requestPayment tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "currency",
                            attrs: {
                              src: "img/icons/facepay.svg",
                              "lazy-src": "img/icons/facepay_white.svg"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip  ttcamera" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.callsContent.requestPayment"
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.requestPayment")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleVideo tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "video",
                            attrs: { src: "/img/camera.svg" }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "video-slash d-none",
                            attrs: { src: "/img/camera-red.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip  ttcamera" },
                            [_vm._v(_vm._s(_vm.$t("generics.camera")))]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.camera")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleMic tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("div", { staticClass: "progressBarBridge" }, [
                            _c("div", { staticClass: "progressBarLevelBridge" })
                          ]),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "microphone"] }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "microphone-slash d-none",
                            attrs: { src: "/img/micro-red.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttmike" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.callsContent.mic"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.mic")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleMuteAll tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "muteAllButon",
                            attrs: { src: "/img/muteAllBridge.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttmike" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.callsContent.muteAllTT")
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.muteAllTT")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleMuteAllRed tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "muteAllButon",
                            attrs: { src: "/img/muteAllBridgeRed.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttmike" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.callsContent.unmuteAllParticipants"
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.callsContent.unmuteAllParticipants")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleMuteAudio tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "volume-up"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttmike" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.callsContent.muteAllParticipants"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.callsContent.muteAllParticipants"))
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleMuteAudioSlash tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "volume-slash"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttmike" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.callsContent.unmuteAllParticipants"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v("\n        s ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.callsContent.unmuteAllParticipants")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleRemoteMic tooltipped",
                          attrs: { icon: "", color: "white" }
                        },
                        on
                      ),
                      [
                        _c("div", { staticClass: "progressBarBridge" }, [
                          _c("div", { staticClass: "progressBarLevelBridge" })
                        ]),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "microphone"] }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "microphone-slash d-none",
                          attrs: { src: "/img/micro-red.svg" }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "tooltippedtooltip ttmike" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("components.callsContent.mic"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleFullScreen tooltipped",
                          attrs: { icon: "", color: "white", target: "_blank" }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "expand-wide"] }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "tooltippedtooltip ttfulls" },
                          [_vm._v(_vm._s(_vm.$t("generics.fullscreen")))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleCloseFullScreen tooltipped",
                          attrs: { icon: "", color: "white", target: "_blank" }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "border-all"] }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "tooltippedtooltip ttfulls" },
                          [_vm._v(_vm._s(_vm.$t("generics.normalScreen")))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleMaximizeScreen tooltipped",
                          attrs: { icon: "", color: "white", target: "_blank" }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fas", "rectangle-wide"] }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "tooltippedtooltip ttfulls" },
                          [_vm._v(_vm._s(_vm.$t("generics.maximizeScreen")))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleMessage tooltipped",
                          attrs: { icon: "", color: "white" }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "paper-plane"] }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "tooltippedtooltip ttmssg",
                            staticStyle: { "font-size": "14px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.userProfile.sendAMessage")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleSendEmail tooltipped",
                          attrs: { icon: "", color: "white", target: "_blank" }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: {
                            color: "white",
                            icon: ["fal", "envelope-square"]
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "tooltippedtooltip ttemail",
                            staticStyle: { "font-size": "14px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.userListItem.sendEMail")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleHangUp tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            staticClass: "faPhoneRotate",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "phone"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttphone" },
                            [_vm._v(_vm._s(_vm.$t("generics.hangUp")))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "rejectCall tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            staticClass: "faPhoneRotate",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "phone"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttphone" },
                            [_vm._v(_vm._s(_vm.$t("generics.hangUp")))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleRotateCamera tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "rotateCameraButton",
                            attrs: { src: "/img/rotateCamera.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "tooltippedtooltip ttmike"
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span")]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleShareScreen tooltipped",
                            attrs: { color: "white", icon: "" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "desktop"] }
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "toggleShareScreenSlash",
                            style: {
                              fontSize: "20px",
                              position: "absolute",
                              top: -1,
                              left: 5
                            },
                            attrs: { icon: ["fal", "slash"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "toggleShareTT tooltippedtooltip ttshare"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.callsContent.shareScreen")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.shareScreen")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleModerator tooltipped",
                            attrs: { color: "white", icon: "" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "users-crown"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttadmin" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.remotePersonCall.transferModerator"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.remotePersonCall.transferModerator")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "togglePresentation tooltipped",
                            attrs: {
                              icon: "",
                              color: "white",
                              target: "_blank"
                            }
                          },
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "imgButton",
                            attrs: { src: "/img/icons/voffice_viewB.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttpresent" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.bridgeCallTooltips.presentation"
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.fullscreen")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "manageConference tooltipped",
                            attrs: {
                              icon: "",
                              color: "white",
                              target: "_blank"
                            }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "users"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip ttpresent" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("generics.manageConference"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { staticClass: "listAttendees d-none" },
                            _vm._l(_vm.conferenceAttendees, function(user) {
                              return _c(
                                "v-list-item",
                                { key: user.uuid, attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                 " +
                                            _vm._s(user.name) +
                                            "\n                 "
                                        ),
                                        user.uuid !== _vm.ownUUID
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex dbtnsAttendees"
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tooltippedButtonMenu"
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2 inIcon greenButton",
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "door-open"
                                                                          ],
                                                                          id:
                                                                            "inIcon-" +
                                                                            user.uuid
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "tooltippedtooltipButton ttdoor"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "generics.addUser"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "generics.addUser"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tooltippedButtonMenu"
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2 outIcon tooltipped",
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "door-closed"
                                                                          ],
                                                                          id:
                                                                            "outIcon-" +
                                                                            user.uuid
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "tooltippedtooltipButton ttdoor"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "generics.removeUser"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "generics.removeUser"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.manageConference")))])
            ]
          ),
          _vm._v(" "),
          _c("v-tooltip", {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleMultiMessage tooltipped",
                          attrs: { icon: "", color: "white" }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "paper-plane"] }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "tooltippedtooltip ttmssg",
                            staticStyle: { "font-size": "14px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.bridgeCallTooltips.messageAll"
                                )
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "btnDocs tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c(
                            "font-awesome-icon",
                            _vm._g(
                              {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "file-pdf"] }
                              },
                              on
                            )
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "tooltippedtooltip  ttcamera" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.bridgeCallTooltips.documents"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { staticClass: "listDocuments d-none" },
                            _vm._l(_vm.conferenceDocuments, function(document) {
                              return _c(
                                "v-list-item",
                                {
                                  key: document.documentId,
                                  attrs: { dense: "" }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: document.documentURL,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(document.documentName))]
                                    )
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.bridgeCallTooltips.documents"))
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleGiveWord tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "comment"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "toggleGiveWordBadge d-none" },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "19px", padding: 1 },
                                attrs: { icon: ["fal", "exclamation"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tooltippedtooltip ttmssg",
                              staticStyle: { "font-size": "14px" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.callsContent.giveWord")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.giveWord")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleWaveHand tooltipped",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "hand-point-up"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tooltippedtooltip ttmssg",
                              staticStyle: { "font-size": "14px" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.bridgeCallTooltips.raiseHand"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.remotePersonCall.requestView"))
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleWaveHandFilled",
                            attrs: { icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fas", "hand-point-up"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.remotePersonCall.requestView"))
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "topbar" }, [
      _c("p", { staticClass: "callerName nameNormal" }),
      _vm._v(" "),
      _c("input", {
        staticClass: "inputTopbar d-none",
        attrs: { type: "text" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "localUserImage d-none", attrs: { id: "localImage" } },
      [
        _c("img", {
          staticClass: "userImageBridge avatar",
          attrs: { src: "img/default_profile_picture.png" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "companyLogo d-none", attrs: { id: "companyLogo" } },
      [
        _c("img", {
          staticClass: "companyLogoBridge avatar",
          attrs: { src: "img/icon.png" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "microphoneSlashOverlay d-none",
        attrs: { id: "microphoneSlashOverlay" }
      },
      [
        _c("img", {
          staticClass:
            "microphone-slash-overlay microphone-slash-overlay-blink",
          attrs: { src: "/img/micro-full-red.svg" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }