var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.entry
      ? _c("div", [
          _vm._v(
            "\n    " +
              _vm._s(_vm.personName) +
              ": " +
              _vm._s(_vm.entry.fileName) +
              "\n    "
          ),
          _vm.percentDone
            ? _c("div", [
                _vm._v(
                  _vm._s(_vm.entry.sizeSoFar) +
                    " / " +
                    _vm._s(_vm.entry.expectedSize) +
                    " " +
                    _vm._s(_vm.percentDone) +
                    " "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.entry.incoming
            ? _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("components.fileTransferEntry.incoming")) +
                    "\n      "
                ),
                _vm.entry.expectedMD5 && _vm.entry.actualMD5
                  ? _c("div", [
                      _vm._v(
                        "MD5 Match: " +
                          _vm._s(_vm.md5Match) +
                          " Expected: " +
                          _vm._s(_vm.entry.expectedMD5) +
                          " Actual: " +
                          _vm._s(_vm.entry.actualMD5)
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.entry.resolve,
                        expression: "entry.resolve"
                      }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.entry.resolve(true)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("generics.accept")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.entry.resolve,
                        expression: "entry.resolve"
                      }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.entry.resolve(false)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("generics.reject")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.entry.complete,
                        expression: "entry.complete"
                      }
                    ],
                    attrs: {
                      href: _vm.entry.finalData,
                      download: _vm.entry.fileName,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("components.fileItemList.download")))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.entry.outgoing
            ? _c("div", [
                _vm._v(
                  "\n      Outgoing\n      Pending: " +
                    _vm._s(_vm.entry.pending) +
                    " Accepted: " +
                    _vm._s(_vm.entry.accepted) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.entry.complete,
                  expression: "entry.complete"
                }
              ]
            },
            [_vm._v("Complete!")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.entry.complete,
                  expression: "entry.complete"
                }
              ],
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_vm._v("[X]")]
          )
        ])
      : _c("div", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("components.fileTransferEntry.brokenEntry")) +
              " "
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }