var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "560" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.$t(
                    "components.companyRegister.regPaypal.headline"
                  ),
                  closeModalFunction: _vm.closeModal
                }
              }),
              _vm._v(" "),
              _c("PayPal", {
                attrs: {
                  isFromRegister: false,
                  daysLeft: _vm.daysLeft,
                  closeModal: _vm.closeModal,
                  isFromModal: true
                }
              }),
              _vm._v(" "),
              _c("FooterModal", {
                attrs: { closeModalFunction: _vm.closeModal, showClose: true }
              }),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }