<template>
    <v-dialog persistent v-model="agreementModalData.show" width="80vw">
        <v-card>
            <HeaderModal :fullwith="true" :titleModal="agreementTitle" />
            <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
                <v-card-text class="contentAgreement">
                    <p v-html="$sanitize(agreementContent)" class="breakSpaces"></p>
                </v-card-text>
            </v-row>
            <FooterModal>
                <v-btn color="primary" @click="acceptAgreement">{{ $t("components.socialMedia.readAndUnderstood")
                    }}</v-btn>
            </FooterModal>
        </v-card>
    </v-dialog>
</template>

<script>
import store, { EventBus } from "../../../../store";
import HeaderModal from "../../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../../modal/modalComponents/footerModal.vue";

export default {
    props: ['agreementModalData', 'closeAgreementModal'],
    components: {
        HeaderModal,
        FooterModal,
    },
    data() {
        return {
            state: store.state,
            agreementSocialMediaDe: store.state.namespaceSettings.agreementSocialMedia.agreementSocialMediaDe || '',
            agreementSocialMediaEs: store.state.namespaceSettings.agreementSocialMedia.agreementSocialMediaEs || '',
            agreementSocialMediaEn: store.state.namespaceSettings.agreementSocialMedia.agreementSocialMediaEn || '',
            agreementSocialMediaTitleDe: store.state.namespaceSettings.agreementSocialMedia.agreementSocialMediaTitleDe || '', 
            agreementSocialMediaTitleEn: store.state.namespaceSettings.agreementSocialMedia.agreementSocialMediaTitleEn || '',
            agreementSocialMediaTitleEs: store.state.namespaceSettings.agreementSocialMedia.agreementSocialMediaTitleEs || '', 
        };
    },
    watch: {
    },
    methods: {
        acceptAgreement() {
            fetch(`/api/socialmedia/accept-agreement`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ uuid: this.agreementModalData.userUuid })
            }).then(async (response) => {
                const result = await response.json();
                if (result.success) {
                    this.closeModal();
                } else {
                    // TODO: Handle error
                    console.error(result.error);
                }
            });
        },
        closeModal() {
            this.closeAgreementModal();
        }
    },
    computed: {
        getLanguage() {
            return this.state.user.language.toUpperCase();
        },
        agreementContent() {
            // Return the appropriate agreement content based on the user's language
            if (this.getLanguage === 'DE') {
                return this.agreementSocialMediaDe;
            } else if (this.getLanguage === 'ES') {
                return this.agreementSocialMediaEs;
            } else if (this.getLanguage === 'EN') {
                return this.agreementSocialMediaEn;
            } else {
                return this.agreementSocialMediaDe; // Default to De if language is unknown
            }
        },
        agreementTitle() {
            // Return the appropriate agreement content based on the user's language
            if (this.getLanguage === 'DE') {
                return this.agreementSocialMediaTitleDe;
            } else if (this.getLanguage === 'ES') {
                return this.agreementSocialMediaTitleEs;
            } else if (this.getLanguage === 'EN') {
                return this.agreementSocialMediaTitleEn;
            } else {
                return this.agreementSocialMediaDe; // Default to de if language is unknown
            }
        }
    }
};
</script>

<style scoped>
.headline {
    font-size: 1.5rem;
    font-weight: 600;
}
.contentAgreement{
    max-height: 620px;
    overflow: auto;
}
</style>