var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messagesArray.length !== 0
    ? _c(
        "v-list",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { "two-line": "", "min-height": 310 }
        },
        [
          _c("vue-scroll", { staticClass: "vueScroll" }, [
            _c(
              "div",
              { staticStyle: { height: "310px" } },
              [
                _c(
                  "v-list-item-group",
                  _vm._l(_vm.messagesArray, function(message, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        message
                          ? _c("v-list-item", {
                              key: index,
                              on: {
                                click: function($event) {
                                  return _vm.openNotification(
                                    message.messageUUID
                                  )
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "v-list-item-avatar",
                                          {
                                            staticClass:
                                              "borderRadius10 cursorPointer",
                                            style: {
                                              border:
                                                "2px solid " +
                                                _vm.setBorderByStatus(
                                                  _vm.state.group[
                                                    Object.keys(
                                                      message.users
                                                    )[0]
                                                  ]
                                                )
                                            },
                                            attrs: { tile: "", size: "60" }
                                          },
                                          [
                                            _vm.getAvatarForUuid(
                                              Object.keys(message.users)[0]
                                            ) ==
                                            "img/default_profile_picture.png"
                                              ? _c("DefaultAvatarGrid", {
                                                  attrs: {
                                                    size: 120,
                                                    userData:
                                                      _vm.state.group[
                                                        Object.keys(
                                                          message.users
                                                        )[0]
                                                      ]
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.getAvatarForUuid(
                                              Object.keys(message.users)[0]
                                            ) !=
                                            "img/default_profile_picture.png"
                                              ? _c("v-img", {
                                                  staticClass:
                                                    "mx-2 borderRadius10",
                                                  attrs: {
                                                    "max-height": "60",
                                                    "max-width": "60",
                                                    contain: "",
                                                    src: _vm.getAvatarForUuid(
                                                      Object.keys(
                                                        message.users
                                                      )[0]
                                                    )
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getNameForUuid(
                                                    Object.keys(
                                                      message.users
                                                    )[0]
                                                  )
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("v-list-item-subtitle", {
                                              staticClass: "text--primary",
                                              domProps: {
                                                textContent: _vm._s(
                                                  message.type === "call"
                                                    ? _vm.$t(
                                                        "components.notificationsModal.missedCall"
                                                      )
                                                    : message.body ||
                                                        "New message"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-list-item-action-text", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.formatNoticationDate(
                                                    message.date
                                                  )
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "d-flex",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }