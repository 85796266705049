var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDevPanel
    ? _c(
        "div",
        { staticClass: "devPanel" },
        [
          _c("EffectorTest"),
          _vm._v(" "),
          _c("div", [_vm._v("File Transfer Testing (WIP)")]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.state.FileTransferModal.files, function(entry, key) {
              return _c(
                "li",
                { key: key },
                [_c("FileTransferEntry", { attrs: { entry: entry } })],
                1
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }