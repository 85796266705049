<template>
  <v-app id="sandbox">
    <Drawer
      :primaryDrawer="primaryDrawer"
      v-if="!isMobile ? showSidebar : showSidebarMobile"
      :mini="mini"
      :openCloseSidebar="openCloseSidebar"
      :openSidebar="openSidebar"
    />
    <NavBarNew
      :mini="mini"
      :openCloseSidebar="openCloseSidebar"
      :primaryDrawer="primaryDrawer"
      :isLarge="isLarge"
      :isSmall="isSmall"
      :isXSmall="isXSmall"
      v-if="!isMobile ? showNavbar : showNavbarMobile"
    />
    <AlertContainer />
    <ContentContainer
      :openCloseSidebar="openCloseSidebar"
      :primaryDrawer="primaryDrawer"
      v-if="!showSetUp && !isOnlyOneCallUser"
      :mini="mini"
    />
    <SetupContainer v-if="showSetUp && !isOnlyOneCallUser" />
    <SambaCall v-if="showSambaCall" />
    <P2pCall v-if="showP2pCall"/>
    <!-- <CallsContent
      v-if="Object.keys(state.remoteStreams).length > 0 && isOnlyOneCallUser"
    />
    <JitsiCall
      v-if="
       this.state.user.inBridgeCall && isOnlyOneCallUser
      "
    />
    <CallListenerWrapper
      v-if="
       this.state.user.inBridgeCallListener && isOnlyOneCallUser
      "
    /> -->
    <EndMeetingTimeModal
      v-if="state.showModalEndMeeting && isOnlyOneCallUser"
    />
    <VisitorPanel class="visitorPanel" v-if="isOnlyOneCallUser" />
    <FooterMobile v-if="showFooterMobile" />
    <DevPanel />
    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="tourCallBacks"
      :options="tourOptions"
    ></v-tour>
    <template v-if="!isNotificationMuted">
      <WaitingRoomNotification />
    </template>
  </v-app>
</template>
<script>
import debounce from "lodash.debounce";
import { P2P_SAMBA_NUM } from "../../sharedsrc/constants";
import { webLicensedBaseFeatures } from "../../sharedsrc/licensedFeatures";
import store, {
  syncedGroupState,
  syncedUserState,
  saveUserState,
  sendUserState,
  actuallySendUserState,
  loadUserState,
  savePersistedState,
  EventBus,
} from "../store";
import EndMeetingTimeModal from "../components/modal/endMeetingModal.vue";
import { isGuestOrVisitor } from "../utils/routerAcl.js";
import { isMobile } from "../lib/mobileUtil";
import { setNamespaceSetting } from "../lib/wsMsg";
import Drawer from "../components/sidebar/drawer.vue";
import NavBarNew from "../components/navbar/navbarNew.vue";
import ContentContainer from "../components/content/contentContainer.vue";
import Footer from "../components/footer/footer.vue";
import FooterMobile from "../components/mobile/footernav.vue";
import SetupContainer from "../components/firstSetup/setupContainer.vue";
import DevPanel from "../components/dev/devPanel.vue";
import VisitorPanel from "../components/visitor/visitorPanel.vue";
import CallsContent from "../components/content/callsContent.vue";
import VideoBridgeContent from "../components/content/videoBridgeContent.vue";
import AlertContainer from "../components/alerts/alertContainer.vue";
import { pokeRemoveSplash } from "../lib/splashAndTheme";
import { updateRtcStatus } from "../lib/rtcConn";
import {
  isOnlyOneCallUser,
  hasPrivilege,
  isWaitingRoomUser,
  isVisitor,
} from "../utils/privileges";
import { amInABridgeCall, callPerson, wsCallStartBridgeCall } from "../utils/calls";
import { isDailyReportEnabled } from "../utils/basicFunctions"
import { totalUsersInRoom } from "../utils/staticRoom";
import { unseenMessagesToMe } from "../effector/message";
import FaviconNotification from "favicon-notification";
import hotkeys from "hotkeys-js";
import {
  setHotkeysInfoEvent,
  resetHotkeysInfoEvent,
} from "../effector/hotkeysInfo";
import WaitingRoomNotification from "../components/waitingRoomNotification/waitingRoomNotification.vue";
import JitsiCall from "../components/content/calls/jitsiCall.vue";
import SambaCall from "../components/content/calls/sambaCall.vue";
import P2pCall from "../components/content/calls/p2pCall.vue";
import CallListenerWrapper from "../components/content/calls/callListenerWrapper.vue"
import {
  setDailyReportModalEvent
} from "../effector/modals";

export default {
  components: {
    Drawer,
    VideoBridgeContent,
    NavBarNew,
    ContentContainer,
    Footer,
    FooterMobile,
    SetupContainer,
    VisitorPanel,
    CallsContent,
    EndMeetingTimeModal,
    DevPanel,
    AlertContainer,
    WaitingRoomNotification,
    JitsiCall,
    SambaCall,
    P2pCall,
    CallListenerWrapper
  },
  effector: {
    unseenMessagesToMe: unseenMessagesToMe,
  },
  data() {
    return {
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      isLarge: this.$vuetify.breakpoint.lgAndUp,
      isSmall: this.$vuetify.breakpoint.mdOnly,
      isXSmall: this.$vuetify.breakpoint.smAndDown,
      drawers: ["Default (no property)", "Permanent", "Temporary"],
      mini: true,
      primaryDrawer: {
        model: true,
        type: "default (no property)",
        clipped: false,
        floating: false,
      },
      faviconNotificationAdded: false,
      initialSyncPromise: undefined,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      ctrlPressed: false,
      steps: [
        {
          target: "body",
          header: {
            title: this.$t("tour.startTourTitle"),
          },
          content: this.$t("tour.startTourContent"),
          params: {
            placement: "none",
            modifiers: {
              arrow: {
                enabled: false,
              },
              computeStyle: {
                enabled: true,
                fn(data) {
                  data.styles = Object.assign({}, data.styles, {
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  });
                  return data;
                },
              },
            },
          },
        },
        {
          target: ".navIcon", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.navIconTitle"),
          },
          content: this.$t("tour.navIconContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: ".settingsIcon", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.settingsTitle"),
          },
          content: this.$t("tour.settingsContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-userActionBar",
          header: {
            title: this.$t("tour.userActionBarTitle"),
          },
          content: this.$t("tour.userActionBarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#buttonMissedCalls", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.missedCallsTitle"),
          },
          content: this.$t("tour.missedCallsContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-sentMessages", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.sentMessagesTitle"),
          },
          content: this.$t("tour.sentMessagesContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-messages", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.messagesTitle"),
          },
          content: this.$t("tour.messagesContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-files", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.filesTitle"),
          },
          content: this.$t("tour.filesContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-logout", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.logoutTitle"),
          },
          content: this.$t("tour.logoutContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-dots", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.dotsTitle"),
          },
          content: this.$t("tour.dotsContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-navBar", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.navBarTitle"),
          },
          content: this.$t("tour.navBarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-favoriteNavbar", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.favoriteNavbarTitle"),
          },
          content: this.$t("tour.favoriteNavbarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-usersNavbar", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.usersNavbarTitle"),
          },
          content: this.$t("tour.usersNavbarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-newConferenceNavbar", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.newConferenceNavbarTitle"),
          },
          content: this.$t("tour.newConferenceNavbarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-waitingroomNavbar", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.waitingroomNavbarTitle"),
          },
          content: this.$t("tour.waitingroomNavbarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "#v-tour-infoBoardNavbar", // We're using document.querySelector() under the hood
          header: {
            title: this.$t("tour.infoBoardNavbarTitle"),
          },
          content: this.$t("tour.infoBoardNavbarContent"),
          params: {
            enableScrolling: false,
          },
        },
        {
          target: "body",
          header: {
            title: this.$t("tour.finishTourTitle"),
          },
          content: this.$t("tour.finishTourContent"),
          params: {
            placement: "none",
            modifiers: {
              computeStyle: {
                enabled: true,
                fn(data) {
                  data.styles = Object.assign({}, data.styles, {
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  });
                  return data;
                },
              },
            },
          },
        },
      ],
      tourOptions: {
        labels: {
          buttonSkip: this.$t("tour.buttonSkip"),
          buttonPrevious: this.$t("tour.buttonPrevious"),
          buttonNext: this.$t("tour.buttonNext"),
          buttonStop: this.$t("tour.buttonStop"),
        },
      },
      tourCallBacks: {
        onFinish: this.finishTour,
        onSkip: this.finishTour,
      },
      mousetimeout: undefined,
    };
  },
  watch: {
    getCallUUID: {
      immediate: true,
      handler: function (callUUID) {
        if (!callUUID && (this.state.user.activity === "inCall" || this.state.user.activity === "inRoom")) {
          store.setActivityUser(this.state.user.originalActivity);
        }
      },
    },
    "state.user.presentFrom": {
      handler: debounce(function (value) {
        if (value !== undefined && !value && !this.state.showModalActiveUser) {
          // show modal active user session
          store.setShowModalActiveUser(true);
        }
      }, 250),
    },
    "state.user.userSettings.screensaver.value": {
      immediate: true,
      handler: function (value) {
        if (value !== "never") {
          // detect whether the mouse is moving
          document.addEventListener("mousemove", this.checkScreensaver, {
            capture: true,
            passive: true,
          });
        } else {
          clearTimeout(this.mousetimeout);
          this.mousetimeout = undefined;
          document.removeEventListener("mousemove", this.checkScreensaver, {
            capture: true,
            passive: true,
          });
        }
      },
      deep: true,
    },
    "state.user": {
      handler: function (value) {
        saveUserState();
        sendUserState();
      },
      deep: true,
    },
    "state.persisted": {
      handler: function (value) {
        savePersistedState();
      },
      deep: true,
    },
    "state.persisted.isDark": {
      immediate: true,
      handler: function (isDark) {
        if (isDark !== undefined) {
          this.$vuetify.theme.dark = isDark; // Set dark theme based on persisted
        }
      },
    },
    "state.persisted.showSidebar": {
      immediate: true,
      handler: function (showSidebar) {
        if (showSidebar !== undefined) {
          const mini = this.mini;
          const show = !mini;
          if (show === showSidebar) return; // Correct value
          store.setShowSidebar(show); // Fix value in store
        }
      },
    },
    mini: {
      immediate: true,
      handler: function (mini) {
        const showSidebar = !mini;
        store.setShowSidebar(showSidebar);
      },
    },
    totalNotis: {
      handler: function (value) {
        if (value != 0) {
          document.title =
            "(" +
            value +
            ") vOffice " +
            ((store.state.namespaceSettings.companyInfo || {})
              .virtualOfficePrefix || store.getVirtualOfficePrefix());
          FaviconNotification.add();
          this.faviconNotificationAdded = true;
        } else {
          document.title =
            "vOffice " +
            ((store.state.namespaceSettings.companyInfo || {})
              .virtualOfficePrefix || store.getVirtualOfficePrefix());
          if (this.faviconNotificationAdded) {
            this.faviconNotificationAdded = false;
            FaviconNotification.remove();
          }
        }
      },
    },
  },
  mounted: function () {
    if (store.state.user.userSettings && this.$route.path !== "/home") {
      this.mini = store.state.user.userSettings.sidebarMini;
    }
    if (isVisitor(this.state.ownUUID)) {
      this.mini = false;
    }
    if (
      (isVisitor(this.state.ownUUID) || this.isWaitingRoomUser) &&
      this.state.user.requestedPayment &&
      this.state.user.requestedPayment.goBackToCall
    ) {
      this.state.user.paymentState.fromPayment = true;
      // CALL WHO REQUESTED PAYMENT AND SET GOBACKTOCALL TO FALSE
      const userToCall = this.state.user.requestedPayment.userToPay.uuid;
      if (userToCall !== this.state.ownUUID) {
        this.state.user.requestedPayment.goBackToCall = false;
        setTimeout(() => {
          callPerson(userToCall, this.$router);
        }, 250);
      }
    }
    this.activateControlKeyDown();
    this.hotkeysMethod();
    // set dailyReport modal
    if(this.showModalDailyReport) setDailyReportModalEvent(true);
  },
  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
  },
  created() {
    if (
      this.userIsAdmin &&
      this.supportSetupRoute &&
      this.$route.path === "/setup"
    ) {
      store.state.namespaceSettings.noNewUsers = true;
      store.state.namespaceSettings.setupDone = true;
      setNamespaceSetting("noNewUsers", true);
      setNamespaceSetting("setupDone", true);
    }
    this.updatePresentFrom(true);
    if (
      0 && // eslint-disable-line no-constant-condition
      this.isMobile &&
      !this.isWaitingRoomUser &&
      !webLicensedBaseFeatures.isDev
    ) {
      document.closeConnection(4994); // app download or use on desktop
    } else if (store.state.namespaceSettings.decryptError) {
      document.closeConnection(4995);
    } else if (!store.state.namespaceSettings.setupDone) {
      store.setShowSetup(true);
    } else if (localStorage.getItem("creatorFirstSetup")) {
      localStorage.removeItem("creatorFirstSetup");
      localStorage.setItem("firstSetupInvite", true);
      this.$router.push({ path: "/home" });
      // this.$router.push({ path: "/invitationformuser" });
    } else if (this.$route.path === "/") {
      if (this.isMobile || isGuestOrVisitor()) {
        this.$router.push({ path: "/home" });
      } else {
        if (store.state.user.userSettings.startView) {
          this.$router.push({ path: store.state.user.userSettings.startView });
        } else {
          const temporalUserSettings = store.state.user.userSettings;
          temporalUserSettings.startView = "my-favorites";
          this.setUserSettings(temporalUserSettings);
          this.$router.push({ path: "/my-favorites" });
        }
      }
    } else if (
      this.isMobile &&
      this.$route.path !== "/" &&
      this.$route.path !== "/home"
    ) {
      this.$router.push({ path: "/home" });
    }
    this.initialSyncPromise = (async () => {
      await syncedGroupState(true);
      await actuallySendUserState(true);
      await syncedUserState(() => {
        updateRtcStatus();
        store.removeInBridgeCall();
        this.updatePresentFrom();
        if (this.state.user.accessmail == 1)
          this.$delete(this.state.user, "accessmail");
        if (this.state.user.lastViewedCalendar === "")
          this.$delete(this.state.user, "lastViewedCalendar");
        if (this.state.user.lastViewedInfoboard === "")
          this.$delete(this.state.user, "lastViewedInfoboard");
        saveUserState();
      }, true);
      document.addEventListener("visibilitychange", loadUserState);
      window.addEventListener("focus", loadUserState);
      pokeRemoveSplash();
      await this.processDirectCallInvite();
    })();
  },
  methods: {
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    async processDirectCallInvite() {
      const ownerUUID = localStorage.getItem("directCallInviteOwnerUUID");
      const callUUID = localStorage.getItem("directCallInviteCallUUID");
      if (ownerUUID && callUUID) {
        localStorage.removeItem("directCallInviteOwnerUUID");
        localStorage.removeItem("directCallInviteCallUUID");
        // Audio Engagement For Direct Call
        const audio = new Audio("/media/silence.mp3");
        let deferred;
        const promise = new Promise((resolve) => (deferred = resolve));
        audio.play()
          .then(deferred, () => store.setShowModalAudioPolicy(deferred));
        await promise;
        if ((this.state.group[ownerUUID] || {}).user?.inBridgeCall === callUUID) {
          const infoStartBridgeCall = Object.assign({}, (this.state.group[ownerUUID].user.bridgeCallInfo || {}), { calling: [], isAudioOnly: true });
          infoStartBridgeCall.inBridgeCallListener = !!infoStartBridgeCall.conferenceHall;
          Object.keys(infoStartBridgeCall).forEach(key => infoStartBridgeCall[key] === undefined && delete infoStartBridgeCall[key]);
          if (infoStartBridgeCall.conferenceHall && infoStartBridgeCall.conferenceAttendees?.length) {
            const member = infoStartBridgeCall.conferenceAttendees.find((member) => (member?.uuid === this.state.ownUUID)) || {};
            infoStartBridgeCall.inBridgeCallListener = !member.isPodium;
            if (!member.uuid) {
              infoStartBridgeCall.conferenceAttendees.push({
                addUserToCall: true,
                isPodium: false,
                name: this.getNameForUuid(this.state.ownUUID),
                showUserName: true,
                uuid: this.state.ownUUID,
              });
            }
          }
          const delay = Promise.all([
            syncedGroupState(true),
            syncedUserState().catch(() => {}),
          ]);
          delay.then(() => {
            wsCallStartBridgeCall(
              ownerUUID,
              this.state.ownUUID,
              { ...infoStartBridgeCall, directCallInvite: true },
              true,
              true
            );
          });
          store.addRemoteBridgeStream(
            infoStartBridgeCall.callUUID,
            infoStartBridgeCall,
          );
          store.setCurrentContentVisile("", false, this.$router);
        }
      } else if (this.state.user.avatar && this.state.user.email && this.state.user.name) {
        // Audio Engagement For Tone
        const audio = new Audio("/media/silence.mp3");
        audio.play().catch(() => store.setShowModalAudioPolicy(true));
      }
    },
    changeLanguage() {
      const languages = ["de", "en", "es"];
      let currentLang = this.$locale.current().toLowerCase();
      const langIndex = languages.indexOf(currentLang);
      if (langIndex != -1) {
        if (langIndex >= 2) {
          currentLang = languages[0];
        } else {
          currentLang = languages[langIndex + 1];
        }
        setTimeout(() => {
          this.$locale.change(currentLang);
          this.$set(this.state.user, "language", currentLang);
        }, 300);
      }
    },
    checkScreensaver() {
      clearTimeout(this.mousetimeout);
      this.mousetimeout = undefined;
      if(!(this.state.user.userSettings.screensaver || {}).value || this.state.user.userSettings.screensaver.value == 'never' || this.$route.path == "/stage-hall") return 
      // const screensaveTime = (this.state.user.userSettings.screensaver.value * 60000);
      const screensaveTime = (this.state.user.userSettings.screensaver.value);
      this.mousetimeout = setTimeout(() => {
        if (!this.amInABridgeCall){
          this.showScreensaver();
        }
      }, screensaveTime);
    },
    // show screensaver function
    showScreensaver() {
      // GO TO HOME
      this.setCurrentContentVisile("home", true, this.$router);
      clearTimeout(this.mousetimeout);
      this.mousetimeout = undefined;
    },
    activateControlKeyDown() {
      document.addEventListener("visibilitychange", this.resetHotkeyInfoEvent); // trigger resetHotkeyInfoEvent if it is visible when the windows loses focus
      document.addEventListener("keydown", this.onKeyDown, {
        capture: true,
        passive: true,
      });
      document.addEventListener("keyup", this.onKeyUp, {
        capture: true,
        passive: true,
      });
    },
    onKeyUp(event) {
      if (event.type === "keyup" && event.keyCode === 17) {
        this.ctrlPressed = false;
        // set store to false
        resetHotkeysInfoEvent();
      }
    },
    onKeyDown(event) {
      if (event.type === "keydown" && event.keyCode === 17) {
        if (!this.ctrlPressed) {
          this.ctrlPressed = true;
          // set store to true
          setHotkeysInfoEvent(true);
        }
      }
    },
    resetHotkeyInfoEvent() {
      if (this.ctrlPressed) {
        this.ctrlPressed = false;
        // set store to false
        resetHotkeysInfoEvent();
      }
    },
    hotkeysMethod() {
      hotkeys(
        "ctrl+s,ctrl+f,ctrl+i,ctrl+u,ctrl+k,ctrl+r,ctrl+b,ctrl+m,ctrl+o,ctrl+p,ctrl+a,ctrl+l,ctrl+q,ctrl+alt+l",
        (event, handler) => {
          event.preventDefault();
          switch (handler.key) {
            case "ctrl+s":
              EventBus.$emit("focusSearchFieldEvent");
              break;
            case "ctrl+f":
              EventBus.$emit("openFavoritesEvent");
              break;
            case "ctrl+i":
              this.$router.push({ path: "/invitationform" });
              break;
            case "ctrl+u":
              EventBus.$emit("openInviteUserModalEvent");
              break;
            case "ctrl+k":
              EventBus.$emit("openNewConferenceEvent");
              break;
            case "ctrl+r":
              EventBus.$emit("openWaitingRoomEvent");
              break;
            case "ctrl+b":
              EventBus.$emit("openInfoboardEvent");
              break;
            // case "ctrl+b":
            //  EventBus.$emit("openCoffeeBreakEvent");
            //  break;
            case "ctrl+m":
              EventBus.$emit("openSettingsMenuEvent");
              break;
            case "ctrl+o":
              EventBus.$emit("openOrganigramEvent");
              break;
            case "ctrl+p":
              EventBus.$emit("openUserInfoEvent");
              break;
            case "ctrl+a":
              EventBus.$emit("openMyStatusEvent");
              break;
            case "ctrl+l":
              EventBus.$emit("openMyLocationEvent");
              break;
            case "ctrl+q":
              EventBus.$emit("focusQuickBarEvent");
              break;
            case "ctrl+alt+l":
              if (this.state.nerd) {
                this.changeLanguage();
              }
              break;
            default:
          }
        }
      );
    },
    finishTour() {
      const exdays = 365;
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString();
      document.cookie = "vOfficeTourDone=true;" + expires + ";path=/";
    },
    setUserSettings(userSettings) {
      return store.setUserSettings(userSettings);
    },
    openSidebar() {
      this.mini = false;
    },
    openCloseSidebar() {
      this.mini = !this.mini;
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    isToday(someDate) {
      const date = new Date(store.state.currentTS);
      if (typeof someDate === "string") {
        const today = date.toISOString().slice(0, 10);
        return someDate.startsWith(today);
      } else if (someDate instanceof Date && !isNaN(someDate)) {
        const today = date;
        return (
          someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
        );
      }
      return false;
    },
    updatePresentFrom(startup = false) {
      if (startup) {
        if (
          !store.state.user.presentFrom ||
          !store.state.user.presentFrom.length ||
          !this.isToday(store.state.user.presentFrom)
        ) {
          store.setPresentFrom(undefined);
        }
      } else if (
        isGuestOrVisitor() ||
        !store.state.user.presentFrom ||
        !store.state.user.presentFrom.length ||
        !this.isToday(store.state.user.presentFrom)
      ) {
        const date = new Date(store.state.currentTS).toISOString();
        store.setPresentFrom(date);
      }
      if (!startup && !store.state.user.created) {
        store.state.user.created = store.state.currentTS;
      }
    },
  },
  computed: {
    getTotalUsers() {
      if (!this.isSambaStaticRoom) return false;
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      const remoteStream = store.state.remoteBridgeStreams[callUUID];
      const { roomId } = remoteStream;
      const room = { roomId };
      return totalUsersInRoom(room)
    },
    isSambaStaticRoom() {
       const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
       if(!callUUID) return false;
      if (this.state.remoteBridgeStreams[callUUID] && this.state.remoteBridgeStreams[callUUID].staticRoom) {
        return true;
      }
      return false;
    },
    haveCalls() {
      return this.isOnlyOneCallUser && (this.state.user.inBridgeCall || this.state.user.inBridgeCallListener);
    },
    showSambaCall() {
      if (!this.haveCalls) return false;
      if (!this.isSambaStaticRoom) return true;
      if (this.isSambaStaticRoom && this.getTotalUsers > P2P_SAMBA_NUM) return true;
      return false;
    },
    showP2pCall() {
      if (this.haveCalls && this.isSambaStaticRoom && this.getTotalUsers <= P2P_SAMBA_NUM) return true;
      return false;
    },
    isDailyReportEnabled() {
      return isDailyReportEnabled()
    },
    showModalDailyReport(){
      if(this.isDailyReportEnabled && this.state.namespaceSettings.dailyReportSettings.members && this.state.namespaceSettings.dailyReportSettings.members[this.state.ownUUID] &&  this.state.namespaceSettings.dailyReportSettings.members[this.state.ownUUID].length > 0){
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const dailyReportDay =  new Date(this.state.persisted.dailyReportDay);
        yesterday.setUTCHours(0,0,0,0);
        dailyReportDay.setUTCHours(0,0,0,0);
        today.setUTCHours(0,0,0,0);
        // check if today is monday and the last daily report was on Friday
        if(today.getDay() == 1){
          const friday = new Date();
          friday.setDate(friday.getDate() - 3);
          friday.setUTCHours(0,0,0,0);
          if(friday.getTime() == dailyReportDay.getTime() || dailyReportDay.getTime() == today.getTime()){
            return false
          }
        }
        // check if the user set daily report from yesterday or if the user set the daily report today
        if(yesterday.getTime() == dailyReportDay.getTime() || dailyReportDay.getTime() == today.getTime()){
          return false
        }
        // show if the others conditions are not true
        return true;
      }
      return false;
    },
    getCallUUID() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      return remoteStreamsKey;
    },
    isNotificationMuted() {
      return this.state.persisted.notificationsMuted;
    },
    amInABridgeCall() {
      return amInABridgeCall();
    },
    totalNotis() {
      const total = this.totalNotificationMessages + this.totalNotificationCall;
      return total;
    },
    totalNotificationCall() {
      return this.state.persisted.totalNotificationCall > 0
        ? this.state.persisted.totalNotificationCall
        : 0;
    },
    totalNotificationMessages() {
      return this.unseenMessagesToMe;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.state.ownUUID);
    },
    isOnlyOneCallUser() {
      return isOnlyOneCallUser(this.ownUUID);
    },
    userIsAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    showSetUp() {
      return this.state.setUpConfig.showSetUp;
    },
    showSidebar() {
      return (
        (!this.showSetUp &&
          !this.isOnlyOneCallUser &&
          !this.isWaitingRoomUser) ||
        (this.isMobile &&
          !this.state.user.inBridgeCall &&
          !this.state.user.inBridgeCallListener
        )
      );
    },
    showFooterMobile() {
      return (
        !this.showSetUp &&
        !this.isOnlyOneCallUser &&
        !this.isWaitingRoomUser &&
        this.isMobile &&
        !this.state.user.inBridgeCall &&
        !this.state.user.inBridgeCallListener
      );
    },
    showSidebarMobile() {
      return false;
    },
    showNavbar() {
      return (
        (!this.isOnlyOneCallUser && !this.showSetUp) ||
        (this.isMobile &&
          !this.state.user.inBridgeCall &&
          !this.state.user.inBridgeCallListener)
      );
    },
    showNavbarMobile() {
      return (
        !this.isOnlyOneCallUser &&
        !this.showSetUp &&
        this.isMobile &&
        !this.state.user.inBridgeCall &&
        !this.state.user.inBridgeCallListener
      );
    },
    supportSetupRoute() {
      return webLicensedBaseFeatures.supportSetupRoute;
    },
  },
};
</script>
<style scoped lang="scss">
@import "~vuetify/dist/vuetify.min.css";
@import "~vue-popperjs/dist/vue-popper.css";
.v-tour {
  z-index: 202;
}
.visitorPanel {
  width: 200px;
  height: 100px;
  position: absolute;
  top: 25px;
  left: 25px;
}
</style>
<style lang="scss">
.deleteBasketNormal {
  color: gray !important;
}
.deleteBasketActive {
  color: red !important;
}
.deleteBasketWhite {
  color: white !important;
}
svg {
  outline: none !important;
}
.v-menu__content .v-list-item__title {
  line-height: 1.5 !important;
}

@media (max-width: 765px) {
  .v-tooltip__content {
    display: none !important;
  }
}
</style>
