<template >
  <v-main style="width: 100%; height: 90vh" class="px-0 py-0">
    <v-card
      style="width: 100%; height: 100%; display: table"
      width="100%"
      height="100%"
      class="justify-center align-center noBoxShadow transparentBG menuItemsCustomStyles"
      v-if="hasPrivilege(ownUUID)"
    >
      <v-row
        style="
          width: 100%;
          height: 100%;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          padding-bottom: 150px;
        "
      >
        <div v-if="isFirstView" cols="12" style="margin-bottom: 10px">
          <h2>
            {{
              $t("components.firstSetup.welcomeHeader", [virtualOfficePrefix])
            }}
          </h2>
        </div>
        <div v-if="isFirstView" cols="12" style="margin-bottom: 14px">
          <span>{{ $t("components.firstSetup.welcomeSub", [getPath]) }}</span>
        </div>
        <div cols="12" style="text-align: center; padding-bottom: 15px">
          <v-img
            style="margin: 0 auto"
            max-height="225"
            max-width="225"
            :src="showCompanyLogo ? showCompanyLogo : 'assets/icon.png'"
            @click="
              state.namespaceSettings.companyHomePage
                ? goToExternalHomePage()
                : null
            "
            contain
          ></v-img>
        </div>
        <div cols="12" style="text-align: center;" class="mb-3 mt-3">
          <p class="mb-0 text-h5 font-weight-regular" >{{$t("components.homeButtons.startMeeting")}}</p>
        </div> 
        <div cols="12" style="text-align: center; padding-top: 15px">
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                @click="callInstaconference()"
                class="justStart no-bg list buttonHome1 mb-2"
                flat
                :color="isDark ? 'white' : 'primary'"
                variant="outlined"
              >
                <font-awesome-icon
                  :icon="['fal', 'bolt']"
                  :style="{ fontSize: '18px' }"
                  :class="isDark? 'white--text':'primary--text'"
                />
                <span class="ml-3 font-weight-regular">{{
                  $t("components.homeButtons.immediateMeeting")
                }}</span>
              </v-btn>
            </template>
            <span>{{
              $t("components.homeButtons.immediateMeeting")
            }}</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                @click="goToPage('waitingRoom')"
                class="justStart no-bg list buttonHome2 mb-2"
                flat
                :color="isDark ? 'white' : 'primary'"
                variant="outlined"
              >
                <font-awesome-icon
                  :icon="['fal', 'users-class']"
                  :style="{ fontSize: '18px' }"
                  :class="isDark? 'white--text':'primary--text'"
                />
                <span class="ml-3 font-weight-regular">{{
                  $t("components.homeButtons.WRGuest")
                }}</span>
              </v-btn>
            </template>
            <span>{{
              $t("components.homeButtons.WRGuest")
            }}</span>
          </v-tooltip>
          
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                @click="goToPage('conferences')"
                class="justStart no-bg list mb-2"
                flat
                :color="isDark ? 'white' : 'primary'"
                variant="outlined"
              >
                <font-awesome-icon
                  :style="{ fontSize: '18px' }"
                  :icon="['fal', 'users']"
                  :class="isDark? 'white--text':'primary--text'"
                />
                <span class="ml-3 font-weight-regular">{{
                   $t("components.conferenceForm.createConference")
                }}</span>
              </v-btn>
            </template>
            <span>{{
              $t("components.conferenceForm.createConference")
            }}</span>
          </v-tooltip>
        </div>  

        <div v-if="isFirstView" cols="12" style="margin-bottom: 20px">
          <span>{{ $t("components.firstSetup.welcomeText1") }}</span>
        </div>
        <div v-if="isFirstView" cols="12" style="margin-bottom: 20px">
          <span>{{ $t("components.firstSetup.welcomeText2") }}</span>
        </div>
        <div v-if="isFirstView"  cols="12" style="margin-bottom: 20px">
          <span v-html="$sanitize($t('components.firstSetup.welcomeText3'))"></span>
      </div>
      </v-row>
    </v-card>
    <template v-if="!hasPrivilege(ownUUID)">
      <WaitingRoomContainerNew />
    </template>
  </v-main>
</template>

<script>
import store, { EventBus } from "../../store";
import WaitingRoomContainerNew from "./waitingRoom/waitingRoomContainerNew.vue";
import { hasPrivilege, isVisitor, isWaitingRoomUser } from "../../utils/privileges";
import { callPerson } from "../../utils/calls";
import { startInstaConference } from "../../utils/instaConferenceUtil"
import { isMobile } from "../../lib/mobileUtil";
export default {
  components: { WaitingRoomContainerNew },
  data() {
    return {
      isMobile: isMobile(),
      state: store.state,
      ownUUID: store.state.ownUUID,
      showOnlyInframe: false,
      isFirstView: false,
      setCurrentContentVisile: store.setCurrentContentVisile
    };
  },
  watch: {
    "state.persisted.mediaFirstSetupDone": {
      handler: function (value) {
        if (value) {
          if (isVisitor(this.state.ownUUID)) {
            const uuid = this.state.user.visitorData.userInviter;
            if (uuid) {
              this.showModalCall(uuid);
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    goToPage(page){
      if (page && page.length){
        if (page === 'conferences'){
          if(this.$route.path !== '/newconference'){
            let route = `/newconference/00-form`;
            this.setCurrentContentVisile(route, true, this.$router);
          }
        } else if (page === 'waitingRoom'){
          if(this.$route.path !== '/invitationform'){
            let route = "/invitationform"
            this.setCurrentContentVisile(route, true, this.$router);
          }
        }
      } 

    },
    callInstaconference(){
      if(this.isMobile) {
        startInstaConference(this.$router);
      } else {
        EventBus.$emit("startInstaConference");
      }
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    hideContent() {
      this.showOnlyInframe = !this.showOnlyInframe;
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    goToExternalHomePage() {
      //open in new page
      let expression = /^http[s]?\:\/\//;
      let regex = new RegExp(expression);
      let companyHomePage = this.state.namespaceSettings.companyHomePage;
      if (!companyHomePage.match(regex)) {
        companyHomePage = "https://" + companyHomePage;
      }
      window.open(companyHomePage, "_blank");
    },
    showModalCall(uuid) {
      let hasStoreUser = setInterval(() => {
        if (this.state.group[uuid]) {
          clearInterval(hasStoreUser);
          callPerson(uuid, this.$router);
          hasStoreUser = null;
        }
      }, 1000);
    },
  },

  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    getPath() {
      return window.location.hostname;
    },
    virtualOfficePrefix() {
      return store.state.namespaceSettings.companyInfo.virtualOfficePrefix;
    },
    showCompanyLogo() {
      if (
        !this.state.namespaceSettings.companyLogo ||
        this.state.namespaceSettings.companyLogo !== ""
      ) {
        return this.state.namespaceSettings.companyLogo || "assets/icon.png";
      }
      return "assets/icon.png";
    },
  },
  mounted: function () {
    // if (this.state.persisted.showSidebar) {
    //   setTimeout(() => {
    //     EventBus.$emit("openCloseSidebar");
    //   }, 50);
    // }
    if (localStorage.getItem("firstSetupInvite")) {
      localStorage.removeItem("firstSetupInvite");
      this.isFirstView = true;
      EventBus.$emit("openSidebar");
    }
    if (isVisitor(this.state.ownUUID)) {
      const uuid = this.state.user.visitorData.userInviter;
      if (uuid) {
        if (this.state.persisted.mediaFirstSetupDone) {
          this.showModalCall(uuid);
        }
      }
    }
  },
};
</script>

<style lang="scss">
.v-list-item-title {
  font-weight: 300 !important;
}
.buttonHome1, .buttonHome2 {
  margin-right: 36px;
}
.noBoxShadow {
  box-shadow: none !important;
}
@media only screen and (max-width: 592px) {
      .buttonHome1, .buttonHome2{
         margin-right: 0px;
      }
    }
@media only screen and (min-width: 593px) and (max-width: 857px){
      .buttonHome2{
         margin-right: 0px;
      }
    }
</style>