var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pdfContainer" },
    [
      _c("pdf", {
        staticClass: "mb-3",
        staticStyle: { height: "calc(100% - 100px)", overflow: "auto" },
        attrs: { src: _vm.getPdf, page: _vm.currentPage },
        on: {
          "num-pages": function($event) {
            _vm.pageCount = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "toolbarPDF pt-1" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                depressed: "",
                fab: "",
                "x-small": "",
                disabled: _vm.noPrevPage
              },
              on: { click: _vm.prevPage }
            },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "12px" },
                attrs: { icon: ["fal", "chevron-left"] }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "mx-2" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.currentPage) + " - " + _vm._s(_vm.pageCount)
            )
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                depressed: "",
                fab: "",
                "x-small": "",
                disabled: _vm.noNextPage
              },
              on: { click: _vm.nextPage }
            },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "12px" },
                attrs: { icon: ["fal", "chevron-right"] }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }