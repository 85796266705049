<template>
<!-- UNSUED MODAL -->
  <div>
    <v-dialog v-model="this.state.showAddTeamModal" persistent max-width="570" @keydown.esc="closeModal">
      <v-card>
        <div>
          <v-card-title class="headline">
            <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
            Add new team
          </v-card-title>
          <v-row flex class="w-100 mx-0 my-0"></v-row>
          <v-form style="width: 100%; margin: 0" ref="form" v-model="valid" lazy-validation>
            <!-- Department selector -->
            <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
              :close-on-click="closeOnClick"
              :close-on-content-click="false"
              v-model="isDepartment"
            >
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-on="on"
                  v-model="departmentsChecked"
                  class="mencheckbox departments cbNoteHead"
                  :label="$t('components.addTeamModal.chooseTeamDepartment')"
                  readonly="readonly"
                ></v-checkbox>
              </template>
              <v-list style="padding-bottom:0px !important">
                <v-list-item>
                  <div style="margin: 8px;">
                    <v-list-item-title class="py-0">
                      <v-text-field
                        :label="$t('generics.searchDepartment')"
                        style="font-weight: 600;font-size: 19px; !important"
                        v-model="selectionSearchTerm"
                      ></v-text-field>
                    </v-list-item-title>
                  </div>
                </v-list-item>
                <div class="confined">
                  <v-list-item
                    v-for="(item) in departmentData"
                    :key="item"
                    class="mencheckbox"
                    item-text="name"
                    :class="{ selactive: item === activeItem}"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-model="selectedDepartment"
                        class="mencheckbox listOrgItem"
                        v-text="item"
                        @click="setSelectedArea('department', item)"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item class="select-box"></v-list-item>
              </v-list>
            </v-menu>
            <!-- End Department Selector -->
            <v-row style="width: 100%; margin: 0">
              <v-col class="col-12 px-2 py-2">
                <v-text-field
                  autofocus
                  :disabled="!departmentsChecked"
                  v-model="newTeamName"
                  :label="$t('components.addTeamModal.teamName')"
                  required
                  counter
                  maxlength="20"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns w-100 mx-0 my-0">
              <template>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="!valid || !departmentsChecked" v-on="on" icon color="white" @click="handleNewTeam">
                      <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>{{ $t('generics.accept') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon color="white" @click="closeModal">
                      <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>{{ $t('generics.close') }}</span>
                </v-tooltip>
              </template>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";

export default {
  data () {
    return {
      state: store.state,
      newTeamName: "",
      activeItem: null,
      closeOnClick: true,
      closeOnContent: false,
      isDepartment: false,
      selectedDepartment: [],
      selectionSearchTerm: "",
      valid: true
    };
  },
  watch: {
    "selectedDepartment": function () {
      this.selectionSearchTerm = "";
    },
  },
  methods: {
    setSelectedArea (area, name) {
      this.isDepartment = false;
      this.selectedDepartment = [name];
      this.activeItem = name;
    },
    closeModal () {
      this.newTeamName = "";
      this.activeItem = null;
      this.isDepartment = false;
      this.selectedDepartment = [];
      this.selectionSearchTerm = "";
      store.state.showAddTeamModal = false;
    },
    handleNewTeam () {
      if (this.$refs.form.validate()) {
        const newTeam = {};
        newTeam.newTeamName = this.newTeamName.trim();
        newTeam.members = [];
        this.closeModal();
      }
    },
    clearObject () {
      this.newTeam.newTeamName = '';
      this.newTeam.members = [];
      this.newTeam.owner = '';
    }
  },
  computed: {
    requiredRule () {
      return [
        v => !!v || this.$t('components.addTeamModal.teamNameRequired'),
        v => !this.doesNameExists || this.$t('components.addTeamModal.teamAlreadyExists')
      ];
    },
    departmentsChecked () {
      return this.selectedDepartment.length > 0;
    },
    departmentData () {
      const departments = Object.keys(this.state.namespaceSettings.processedOrganisation.departments || {});
      const filteredDepartments = departments.filter(name => (!this.selectionSearchTerm || (name.toLowerCase().indexOf(this.selectionSearchTerm.toLowerCase()) !== -1)));
      return filteredDepartments;
    },
    doesNameExists () {
      return !!((this.state.namespaceSettings.processedOrganisation || {}).teams || {})[this.newTeamName.trim()];
    }
  }
};
</script>
<style scoped lang="scss">
.selactive{
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
