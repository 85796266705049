<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      :value="isActive"
      @click="handlerClickFav($event)"
      :appendIcon="sortedFavoriteUsers.length === 0 ? null : '$expand'"
    >
      <template v-slot:activator>
        <v-list-item-icon>
          <font-awesome-icon
            v-if="currentPage('/my-favorites')"
            :icon="['fas', 'star']"
            class="primary--text-sidepanel"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'star']"
            class="primary--text-sidepanel"
          />
        </v-list-item-icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
              <!-- class="primary--text" -->
            <v-list-item-title
            class="primary--text-sidepanel"
              :class="`${
                getRoute === '/my-favorites'
                  ? 'font-weight-bold'
                  : ''
              }`"
              @click.stop.prevent="
                setCurrentContentVisile('', false, $router);
                handleMyFavoriteView();
              "
              v-on="on"
            >
              {{ $t("components.footernav.myFavorites") }}
              <span v-if="sortedFavoriteUsers.length > 0">
                (<span class="textColor">{{ connectedFavoriteUsersLen }}</span
                >/ {{ totalUnfilteredUsers }})</span
              >
            </v-list-item-title>
          </template>
          <span>{{ $t("components.footernav.myFavoritesTT") }}</span>
        </v-tooltip>
        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + F`"
          position="bottom"
          :extraclass="'ctrf'"
        />
      </template>
      <UserList :persons="sortedFavoriteUsers" :section="'Favorites'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" class="cursorPointer">
          <v-list-item-icon @click.stop.prevent="handlerClickFav()">
            <font-awesome-icon
              v-if="currentPage('/my-favorites')"
              :icon="['fas', 'star']"
              class="primary--text-sidepanel"
            />
            <font-awesome-icon
              v-if="!currentPage('/my-favorites')"
              :icon="['fal', 'star']"
              class="primary--text-sidepanel"
            />
          </v-list-item-icon>
        </v-list-item>
      </template>
      <span>{{ $t("components.sidebar.favoritesMiniTT") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";

export default {
  props: [
    "mini",
    "persons",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "sortedFavoriteUsers",
    "active",
    "setList",
    "totalUnfilteredUsers",
    "isMobile",
  ],
  components: {
    UserList,
    CustomTT,
  },
  effector: {
    showCustomTT: hotkeysInfoStore,
  },
  data: () => ({
    state: store.state,
  }),
  mounted() {
    EventBus.$on("openFavoritesEvent", this.handleMyFavoriteView);
  },
  destroyed(){
    EventBus.$off("openFavoritesEvent", this.handleMyFavoriteView);
  },
  methods: {
    handlerClickFav(event) {
      if (this.mini) {
        this.goToPage("my-favorites");
      } else {
        this.setList("favorites");
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    goToPage(page) {
      this.setCurrentContentVisile(page, true, this.$router);
    },
    handleMyFavoriteView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("my-favorites", true, this.$router);
      }
    },
  },
  computed: {
    connectedFavoriteUsersLen: function () {
      return this.sortedFavoriteUsers.filter((p) => p.connected).length;
    },
    isActive() {
      return this.active == "favorites";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: #008000;
}

.theme--dark .listColor {
  color: white !important;
}
.theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrf {
  left: 15px;
}
</style>

