 <template>
  <div>
    <div>{{ conference.confName }}</div>
    <div>{{ getNameModerator }}</div>
    <div>
      <span> {{ $t("components.remotePersonCall.attendees") }}: </span>
      <div>
        {{ getAllUsers }}
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import {myConferenceDetails} from "../../effector/groupConferences";
import {
  myConferenceHallDetails,
} from "../../effector/conferenceHalls";

export default {
  props: ["conference"],
  data() {
    const effector = {
      myConference: myConferenceDetails,
      myConferenceHall: myConferenceHallDetails,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,

      // Effector
      ...effector,
    };
  },

  methods: {
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    getNameModerator() {
      const conference = this.myConference.find(x => x.confId === this.conference.confId);
      const conferenceHall = this.myConferenceHall.find(x => x.confId === this.conference.confId);
      const moderatorLabel = this.$t("components.remotePersonCall.moderator") + ":";

      let moderatorNames = [];

      if (conference && conference.confModerators?.length) {
        moderatorNames = conference.confModerators.map(moderator =>
          `${this.getTitelByUuid(moderator)} ${this.getNameByUuid(moderator)}`
        );
      } else if ( conferenceHall && conferenceHall.confModerators?.length){
        moderatorNames = conferenceHall.confModerators.map(moderator =>
          `${this.getTitelByUuid(moderator)} ${this.getNameByUuid(moderator)}`
        );
      }else {
        const creatorName = `${this.getTitelByUuid(this.conference.creatorUUID)} ${this.getNameByUuid(this.conference.creatorUUID)}`;
        moderatorNames.push(creatorName);
      }

      return `${moderatorLabel} ${moderatorNames.join(", ")}`;
    },
    getAllUsers() {
      const users = this.conference.members || [];
      if (users.length > 0) {
        const participants = users
          .filter((user) => this.ownUUID !== user.uuid)
          .map((user) => {
            return store.state.group[user.uuid]
              ? this.getTitelByUuid(user.uuid) + " " + this.getNameByUuid(user.uuid) ||
                  "Anonymous"
              : "";
          })
          .join(", ");
        return participants;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
</style>