var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "groupshead" } },
    [
      _c(
        "v-content",
        {
          class: "px-0 py-0 contentGridWrapper",
          nativeOn: {
            scroll: function($event) {
              return _vm.handleScroll.apply(null, arguments)
            }
          }
        },
        [
          _vm.sortedData.length > 0 && _vm.sortedMembers.length === 0
            ? _c(
                "v-row",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-1 px-2", attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "apartTitle mb-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.conferenceForm.conferenceTemplate"
                            )
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "custom6cols pb-1 px-2",
                      attrs: { cols: "6" }
                    },
                    [
                      _vm.$route.path === "/groups"
                        ? _c(
                            "v-card",
                            {
                              staticClass: "cardWidth cursorPointer",
                              style: { border: "1px solid #efefef" },
                              on: { click: _vm.clickOnAddCard }
                            },
                            [
                              _c("div", { staticClass: "divCardAdd" }, [
                                _c(
                                  "div",
                                  { staticClass: "divAdd" },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "buttonAdd",
                                      attrs: { icon: ["fal", "plus"] }
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "mb-0 mt-2" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.newConference"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sortedData, function(section, index) {
                    return section.isTemplate
                      ? _c(
                          "v-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.activeGroupId === section.id ||
                                  _vm.activeGroupId === 0,
                                expression:
                                  "activeGroupId === section.id || activeGroupId === 0"
                              }
                            ],
                            key: index,
                            staticClass: "custom6cols pb-1 px-2",
                            attrs: { cols: "6" }
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "cardWidth",
                                staticStyle: { cursor: "pointer" },
                                style: { border: "1px solid #efefef" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.editTemplate(section.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-img",
                                  {
                                    staticClass: "white--text align-end",
                                    attrs: {
                                      src: "img/video-conference.svg",
                                      height: "180px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "white black--text" },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "titleCard  pt-1 pb-1 px-2 customFontSize",
                                            staticStyle: { height: "25px" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(section.title) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-card-subtitle",
                                          {
                                            staticClass:
                                              "subTitleCard  pb-1 px-2 customFontSize"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.groups.members"
                                                  )
                                                ) +
                                                ":\n                " +
                                                _vm._s(section.members.length)
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "buttonEdit",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.editTemplate(
                                                              section.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: ["fal", "pen"]
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("generics.edit"))
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }