var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.startView,
        callback: function($$v) {
          _vm.startView = $$v
        },
        expression: "startView"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.userSettings.startView"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-list-item",
                { staticClass: "py-0 switchWrapper" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "py-0 px-3 my-0" },
                    _vm._l(_vm.getStartView, function(data) {
                      return _c(
                        "v-radio-group",
                        {
                          key: data.index,
                          attrs: { row: "" },
                          model: {
                            value: _vm.startViewPage,
                            callback: function($$v) {
                              _vm.startViewPage = $$v
                            },
                            expression: "startViewPage"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: data.name, value: data.value }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.changeStartView }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }