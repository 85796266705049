var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "callIcon",
          class: { noWhiteBg: _vm.isRegularGrid },
          style: _vm.defaultAvatar
            ? "color:#808080;"
            : _vm.isRegularGrid
            ? "color:#ffffff; font-size: 15px;"
            : null,
          attrs: {
            width: _vm.isRegularGrid ? 25 : 20,
            height: _vm.isRegularGrid ? 25 : 20,
            icon: "",
            color: _vm.isRegularGrid ? "#ffffff" : "#0000008a",
            disabled: _vm.disableCallBtn(_vm.person)
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.callUser(_vm.person.uuid || _vm.person.user.uuid)
            }
          }
        },
        [
          _c("font-awesome-icon", {
            style: { fontSize: "14px" },
            attrs: { icon: ["fal", "phone"] }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }