<template>
  <v-card>
    <v-card-title v-if="isVisitor(call.uuid)" class="headline">
      <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
      {{ $t('components.incomingCall.incomingOneCall') }}
    </v-card-title>
    <v-card-title v-else class="headline">
      <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
      {{ $t('components.incomingCall.incomingCall') }}
    </v-card-title>
    <!-- branch if pin protection -->
    <v-row flex class="w-100 mx-0 my-0">
      <v-col
        v-for="n in 2"
        :key="n"
        :lg="cols[n - 1]"
        :md="cols[n - 1]"
        :sm="cols[n - 1]"
        :xl="cols[n - 1]"
      >
        <div v-if="n == 1" align="center">
          <v-badge
            bordered
            overlay
            height="60"
            width="60"
            bottom
            color="#008000"
            dot
            offset-x="22"
            offset-y="15"
          >
            <v-list-item-avatar tile size="150" class="borderRadius10">
              <v-img
                class="borderRadius10"
                max-height="150"
                max-width="150"
                contain
                :src="getAvatarForUuid(call.uuid)"
                lazy-src="assets/default_profile_picture.png"
              ></v-img>
            </v-list-item-avatar>
          </v-badge>
        </div>
        <div v-if="n == 2" v-bind:class="{'text-center': isMobile}">
          <p class="userNameCard my-0">{{getNameForUuid(call.uuid)}}</p>
          <p
            class="dataUserCard my-0"
            v-if="state.group[call.uuid].user.position"
          >{{state.group[call.uuid].user.position}}</p>
          <p
            class="dataUserCard my-0"
            v-if="state.group[call.uuid].user.email"
          >{{state.group[call.uuid].user.email}} | {{state.group[call.uuid].user.phone}}</p>
          <v-divider class="mt-4"></v-divider>
          <!-- v-if="!call.isPinProtected call.pinCode" -->
          <v-row v-if="isCallProtected" 
            class="px-2 btns w-100 mx-0 my-0" style="text-align: center" 
            v-bind:class="{'centerRow' : isMobile, 'mt-2':isMobile}"
          >
            <v-col class="col-4 pb-0 col-12" >
              <p>{{ $t("components.callsContent.protectedCall") }}</p>
            </v-col>
            <v-col class="col-9 pt-0" style="margin: 0 auto">
            <template>
              <v-text-field
                class="whiteControl"
                outlined
                dense
                v-model="userPin"
                :style="{width:290, color:white}"
                hide-details
                :label="$t('components.callsContent.insertPin')"
                @change="checkIt($event)"
              />
            </template> 
            </v-col>
        </v-row>  

          <div>
            <span
              class="dataUserCard my-0"
              v-if="state.group[call.uuid].user.sections && state.group[call.uuid].user.sections.length > 0 && state.group[call.uuid].user.locations && state.group[call.uuid].user.locations.length > 0"
            >{{state.group[call.uuid].user.sections[0]}} | {{state.group[call.uuid].user.locations[0]}}</span>
          </div>
          <div v-if="call && call.dialGroup && call.dialGroup.length">
            <span>{{ $t('components.inviteVisitor.participants')}}:</span>
            <ul>
              <li v-for="uuid of call.dialGroup" :key="uuid">{{ getNameForUuid(uuid) }}</li>
            </ul>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card color="" class="py-2 pl-4 mt-4 pr-2 footerModal footersBackground">
      <template v-if="!isVisitor(ownUUID)">
        <v-row
          class="px-2 w-100 mx-0 my-0"
          v-bind:class="{'text-center': isMobile}"
          v-if="state.persisted.tmpMessages.showInfoIncomingCallModal"
        >
          <small>{{ $t('components.incomingCall.enableCamMic') }}</small>
          <small>{{ $t('components.incomingCall.answerSpace') }}</small>
          <v-checkbox
            v-model="checkedMessage"
            dark
            @change="setShowInfoIncomingCallModal(false)"
            class="mt-0 white--text"
            :label="$t('components.meetingTime.dontShowAgain')"
          ></v-checkbox>
        </v-row>
        <v-row
          class="px-2 btns w-100 mx-0 my-0 mb-2"
          v-bind:class="{'centerRow' : isMobile, 'mt-2':isMobile}"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-2"
                v-on="on"
                outlined
                x-small
                color="white"
                ref="cancelCall"
                @click="call.resolve(false); declineDefaultMessage(call.uuid, $t('components.incomingCall.callLater') )"
              >{{ $t('components.incomingCall.callLater') }}</v-btn>
            </template>
            <span>{{ $t('components.incomingCall.callLater') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-2"
                v-on="on"
                outlined
                x-small
                color="white"
                ref="cancelCall"
                @click="call.resolve(false); declineDefaultMessage(call.uuid, $t('components.incomingCall.callTomorrow'))"
              >{{ $t('components.incomingCall.callTomorrow') }}</v-btn>
            </template>
            <span>{{ $t('components.incomingCall.callTomorrow') }}</span>
          </v-tooltip>
        </v-row>
        <v-row
          class="px-2 btns w-100 mx-0 my-0 mb-2"
          v-bind:class="{'centerRow' : isMobile, 'mt-2':isMobile}"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-2"
                v-on="on"
                outlined
                x-small
                color="white"
                ref="cancelCall"
                @click="call.resolve(false); declineDefaultMessage(call.uuid,  $t('components.incomingCall.callYouBack') )"
              >{{ $t('components.incomingCall.callYouBack') }}</v-btn>
            </template>
            <span>{{ $t('components.incomingCall.callYouBack') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-2"
                v-on="on"
                outlined
                x-small
                color="white"
                ref="cancelCall"
                @click="call.resolve(false);"
              >{{ $t('components.incomingCall.dontAnswer') }}</v-btn>
            </template>
            <span>{{ $t('components.incomingCall.dontAnswer') }}</span>
          </v-tooltip>
        </v-row>
      </template>
   
      <v-row 
        class="px-2 btns w-100 mx-0 my-0"
        v-bind:class="{'centerRow' : isMobile, 'mt-2':isMobile}"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }" v-if="call.typeCall !== 'audio'">
            <v-btn
              v-on="on"
              outlined
              class="mr-4"
              color="white"
              ref="acceptCall"
              @click="resolveCall(call, 'video')"
              :disabled="isCallProtected"
            >
              <font-awesome-icon class="mr-3" :icon="['fal', 'video']" :style="{ fontSize: '20px' }" />Video
            </v-btn>
          </template>
          <span>{{ $t('components.incomingCall.hookOff') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mr-4"
              outlined
              color="white"
              @click="resolveCall(call, 'audio')"
              :disabled="isCallProtected"
              
            >
              <font-awesome-icon
                class="mr-3"
                :icon="['fal', 'microphone']"
                :style="{ fontSize: '20px' }"
              />Audio
            </v-btn>
          </template>
          <span>{{ $t('components.incomingCall.hookOff') }}</span>
        </v-tooltip>
        <v-tooltip top v-if="!isVisitor(ownUUID)">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              color="primary"
              ref="cancelCall"
              @click="setShowModalSenMsg(call.uuid); call.resolve(false)"
            >
              <font-awesome-icon :icon="['fal', 'comment-times']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{ $t('components.incomingCall.declineWithMessage') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="primary" ref="cancelCall" @click="call.resolve(false)">
              <font-awesome-icon
                :icon="['fal', 'phone']"
                class="faPhoneRotate"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t('generics.hangUp') }}</span>
        </v-tooltip>
      </v-row>
    </v-card>
    <v-spacer></v-spacer>
  </v-card>
</template>
<script>
import Vue from "vue";
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { setTimeJoinConversation, MD5 } from "../../lib/rtcConn";
import { wsCall } from "../../lib/wsConnect";
import { setSendMessageModalEvent } from "../../effector/modals"
export default {
  props: ["call"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      spaceShortcutCallAcceptTimeout: null,
      checkedMessage: false,
      isMobile: isMobile(),
      limit_time: null,
      timeLeft: null,
      storeDialUpTime: store.state.user.userSettings.dialUpTime,
      hasConfrimmedPin: false,
      userPin: "",
    };
  },
  mounted() {
    document.addEventListener("keyup", this.onKeyUp, {
      capture: false,
      passive: true,
    });
    this.timeLeft = this.storeDialUpTime / 1000 || 30;
    this._interval = setInterval(this.tick.bind(this), 1000);
  },
  beforeDestroy: function () {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  destroyed() {
    // console.log("incomingcall destroy");
    this.timeLeft = null;
    clearInterval(this._interval);
  },
  watch: {
    timeLeft(val) {
      if (val === null) return;
      if (val <= 0) this.call.resolve(false);
    },
  },
  methods: {
    checkIt(evt){
      if(this.userPin > 12 || this.userPin < 12) return false;
      // console.log(MD5(this.userPin) , this.call.pinCode)
      if( MD5(this.userPin) === this.call.pinCode){
        this.hasConfrimmedPin = true;
      }
      // console.log(this.userPin);
    },
    tick() {
      this.timeLeft--;
    },
    isVisitor(uuid) {
      return ((store.state.group[uuid] || {}).user || {}).visitor || false;
    },
    declineDefaultMessage(uuid, message) {
      wsCall("sendToUUID", uuid, {
        type: "rejectCall",
        message: message,
        sender: this.state.ownUUID,
      });
    },
    setShowModalSenMsg(uuid) {
      // return store.setShowModalSenMsg(uuid, "popUp");
      setSendMessageModalEvent({show:true, users: [uuid], type: 'popUp'})
    },
    setShowInfoIncomingCallModal(val) {
      return store.setShowInfoIncomingCallModal(val);
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (this.$refs.cancelCall && this.$refs.cancelCall.length) {
          this.$refs.cancelCall[this.$refs.cancelCall.length - 1].$el.click();
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        if (this.$refs.acceptCall) {
          if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT' && !this.state.showModalSenMsg){
            this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
              this.$refs.acceptCall.$el.click();
              this.spaceShortcutCallAcceptTimeout = null;
            }, 300);
          }
        }
      }
    },
    resolveCall(call, method) {
      this.setCurrentContentVisile("home", false, this.$router);
      switch (method) {
        case "audio":
          store.state.persisted.rtcVideo = false;
          break;
        case "video":
          store.state.persisted.rtcVideo = true;
          break;
      }
      call.resolve(true);
      if (!call.join) {
        // New call
        // We changed time, set local and send to caller
        // No change to time, set locally
        store.setdurationMeeting(call.limit_time);
        store.setmeetingStartAt(call.meeting_start);
      } else {
        // Wants to join existing call, send to caller
        setTimeJoinConversation(
          call.uuid,
          store.state.durationMeeting,
          store.state.meetingStartAt
        );
      }
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
  },
  computed: {
    isCallProtected(){
      if(this.hasConfrimmedPin){
        return false;
      }else {
        return this.call.isPinProtected && this.call.pinCode.length > 0;
      }
      
    },
    volumCallTone() {
      let volum = store.state.persisted.mediaDeviceSetup.volumeCallTone;
      if (isNaN(volum)) {
        volum = 100;
      }
      volum = volum / 100;
      return volum;
    },
    cols() {
      const { lg, sm, md, xs, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
    haveCalls: function () {
      return this.incommingCallsArray.length > 0;
    },
    incommingCallsArray: function () {
      const calls = [];
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
        calls.push({ ...call, uuid });
      }
      return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    playingRingTone() {
      if (!this.incommingCallsArray.length) return "";
      for (let call of this.incommingCallsArray) {
        if (this.$refs.audioSource) {
          this.$refs.audioSource.volume = this.volumCallTone;
        }
        if (
          store.getNameForUuid(call.uuid).toLowerCase().indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    },
  },
};
</script>
<style scoped lang="scss">
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.w-100 {
  width: 100%;
}
.centerRow {
  align-items: center;
  justify-content: center !important;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: unset !important;
}
</style>
