var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeMethod.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModalSettings,
        callback: function($$v) {
          _vm.showModalSettings = $$v
        },
        expression: "showModalSettings"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.callsContent.addParticipants"),
              closeModalFunction: _vm.closeMethod
            }
          }),
          _vm._v(" "),
          _c(
            "v-card",
            {
              attrs: {
                "min-height":
                  _vm.$refs.usersCombo && _vm.$refs.usersCombo.isMenuActive
                    ? "450px"
                    : ""
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("components.callsContent.shareLinkText")
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-text-field", {
                        ref: "linkRef",
                        staticClass: "inputDirectInviteLink",
                        attrs: {
                          id: "inputLink",
                          readonly: "",
                          dense: "",
                          outlined: "",
                          "hide-details": "",
                          height: "50"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "buttonCopyLink",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.copyLink($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "copy"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.instaLink,
                          callback: function($$v) {
                            _vm.instaLink = $$v
                          },
                          expression: "instaLink"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                    _c("p", { staticClass: "my-0" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("components.callsContent.addEmailNote")
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-combobox", {
                        ref: "usersCombo",
                        staticClass: "usersCombo",
                        attrs: {
                          "menu-props": {
                            maxHeight: 180
                          },
                          items: _vm.getData,
                          "search-input": _vm.searchUsers,
                          "item-text": "text",
                          "item-key": "value",
                          multiple: "",
                          outlined: "",
                          dense: "",
                          "return-object": true,
                          chips: ""
                        },
                        on: {
                          "update:searchInput": function($event) {
                            _vm.searchUsers = $event
                          },
                          "update:search-input": function($event) {
                            _vm.searchUsers = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-chip",
                                  _vm._b(
                                    {
                                      key: JSON.stringify(data.item.value),
                                      attrs: {
                                        "input-value": data.selected,
                                        disabled: data.disabled,
                                        close: "",
                                        color: !_vm.emailIsCorrect(data.item)
                                          ? "red"
                                          : "",
                                        "text-color": !_vm.emailIsCorrect(
                                          data.item
                                        )
                                          ? "white"
                                          : ""
                                      },
                                      on: {
                                        "click:close": function($event) {
                                          return data.parent.selectItem(
                                            data.item
                                          )
                                        }
                                      }
                                    },
                                    "v-chip",
                                    data.attrs,
                                    false
                                  ),
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      {
                                        staticClass: "ml-0 mr-1",
                                        style: _vm.getBorderByStatusFull(
                                          _vm.state.group[data.item.value]
                                        )
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: data.item.avatar,
                                            contain: ""
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(data.item.text) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handlerSelectUser(data.item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      {
                                        style:
                                          _vm.getBorderByStatusFull(
                                            _vm.state.group[data.item.value]
                                          ) + ", borderRadius: '5px'"
                                      },
                                      [
                                        _c("v-img", {
                                          staticClass: "white--text align-end",
                                          attrs: {
                                            contain: "",
                                            height: "40",
                                            src: data.item.avatar
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(data.item.text) +
                                          "\n                "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedUsers,
                          callback: function($$v) {
                            _vm.selectedUsers = $$v
                          },
                          expression: "selectedUsers"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeMethod } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.disableCallUsers,
                    loading: _vm.loadingAddBtn
                  },
                  on: {
                    click: function($event) {
                      return _vm.callUsers()
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "loader",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { staticClass: "custom-loader" },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "15px", color: "white" },
                                attrs: { icon: ["fal", "spinner-third"] }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [_vm._v(_vm._s(_vm.$t("generics.sendInvite")) + "\n        ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }