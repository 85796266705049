var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", [
      _vm._v("Event " + _vm._s(_vm.name) + " Count: " + _vm._s(_vm.counter))
    ]),
    _vm._v(" "),
    _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.lastPayload, null, 2)))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }