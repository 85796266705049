var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-resizable",
    {
      staticClass: "dragFullScreen",
      attrs: {
        "min-width": 197,
        "min-height": 144,
        width: _vm.width,
        top: _vm.ownVideo ? _vm.top : "",
        height: _vm.height,
        dragSelector: _vm.dragSelector
      },
      on: {
        mount: function($event) {
          _vm.ownVideo ? _vm.eHandler : _vm.localHandler
        },
        "resize:move": function($event) {
          _vm.ownVideo ? _vm.eHandler : _vm.localHandler
        }
      }
    },
    [
      _c("div", { staticClass: "resizable-content" }, [
        _vm.getVideoSrc
          ? _c("video", {
              staticClass: "local-video drag-container-1",
              class: {
                hidden: !_vm.getVideoSrc || !_vm.haveRemoteFirstVideo
              },
              attrs: {
                id: "local-video",
                autoplay: "autoplay",
                width: _vm.videoWidth,
                height: _vm.videoHeigth
              },
              domProps: { srcObject: _vm.getVideoSrc, muted: true },
              on: {
                click: function($event) {
                  $event.preventDefault()
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class:
              _vm.getVideoSrc && _vm.haveRemoteFirstVideo
                ? "hidden"
                : "noCameraStream",
            style: { width: _vm.imageWidth, height: _vm.imageheigth }
          },
          [
            _c(
              "div",
              { staticClass: "noCameraContent" },
              [
                _c("v-img", {
                  staticClass: "imageNotVideo",
                  attrs: {
                    contain: "",
                    src: _vm.getAvatarFromUuid(_vm.userSrc),
                    "lazy-src": "assets/default_profile_picture.png"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "emptyDiv" })
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }