var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-content",
        {
          class: "px-0 py-0 contentGridWrapper",
          nativeOn: {
            scroll: function($event) {
              return _vm.handleScroll.apply(null, arguments)
            }
          }
        },
        [
          [
            _c(
              "v-row",
              { staticClass: "mx-auto" },
              [
                !_vm.isMobile
                  ? _c(
                      "v-col",
                      { staticClass: "pb-1 px-2", attrs: { cols: "2" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "firstCard cardWidth wr cursorPointer",
                            attrs: { flat: "" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "divAddBox",
                                staticStyle: { height: "180px", width: "100%" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "divAdd",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToInviteGuest()
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "buttonAdd",
                                      attrs: { icon: ["fal", "plus"] }
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "mb-0 mt-5" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("generics.addGuestCard")
                                          ) +
                                          "\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-3",
                                            staticStyle: {
                                              "max-width": "200px"
                                            },
                                            attrs: {
                                              color: "primary",
                                              outlined: "",
                                              dense: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.gotoInviteGuestList()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.inviteGuestPanel.inviteList"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.paginatedData, function(element, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "pb-1 px-2",
                      attrs: { cols: "2" }
                    },
                    [
                      element.uuid
                        ? _c(
                            "v-card",
                            {
                              staticClass: "cardWidth wr",
                              style: _vm.getBorderByStatusFull(element),
                              attrs: { flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.handleClicks(
                                    $event,
                                    element,
                                    "flipCard-" + index
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g({}, on),
                                              [
                                                _c(
                                                  "v-img",
                                                  {
                                                    staticClass:
                                                      "white--text align-end",
                                                    attrs: {
                                                      src: _vm.getAvatarByType(
                                                        element.uuid
                                                      ),
                                                      height: "180px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "iconCardsContainer pa-3"
                                                      },
                                                      [
                                                        _vm.getColorPhone(
                                                          element
                                                        )
                                                          ? _c(
                                                              "font-awesome-icon",
                                                              {
                                                                staticClass:
                                                                  "faPhoneRotate",
                                                                style: {
                                                                  fontSize:
                                                                    "20px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "phone"
                                                                  ],
                                                                  rotation: 270,
                                                                  color: _vm.getColorPhone(
                                                                    element
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    element.user.presentFrom
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "black--text text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.formatDate(
                                                                    element.user
                                                                      .presentFrom
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gradientBg"
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-subtitle",
                                                          {
                                                            staticClass:
                                                              "\n                          titleCard\n                          colorWhite\n                          pt-1\n                          pb-1\n                          px-2\n                          customFontSize\n                          text-truncate\n                        ",
                                                            staticStyle: {
                                                              color:
                                                                "#fff !important"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.titelPerson(
                                                                    element
                                                                  )
                                                                ) +
                                                                "\n                        " +
                                                                _vm._s(
                                                                  _vm.namePerson(
                                                                    element,
                                                                    index
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("ButtonsFrontCard", {
                                                          attrs: {
                                                            person: element,
                                                            uuid: element.uuid,
                                                            sectionName:
                                                              "members",
                                                            flipCard:
                                                              _vm.flipCard,
                                                            index: index,
                                                            typeData:
                                                              "waitingroom"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.titelPerson(element)) +
                                          "\n                  " +
                                          _vm._s(_vm.namePerson(element, index))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.getUserActivity(element))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    element.user.visitorData.linkDateTo &&
                                    element.user.visitorData.linkTimeTo &&
                                    element.user.visitorData.duration
                                      ? _c("br")
                                      : _vm._e(),
                                    _vm._v(" "),
                                    element.user.visitorData.linkDateTo &&
                                    element.user.visitorData.linkTimeTo &&
                                    element.user.visitorData.duration
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.inviteVisitor.appointmentshort"
                                                )
                                              ) +
                                              "\n                  " +
                                              _vm._s(
                                                _vm.getTimeMessage(
                                                  element.user.visitorData
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    element.user.visitorData.noteForContact
                                      ? _c("br")
                                      : _vm._e(),
                                    _vm._v(" "),
                                    element.user.visitorData.noteForContact
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              element.user.visitorData
                                                .noteForContact
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    element.paylinkInfo ? _c("br") : _vm._e(),
                                    _vm._v(" "),
                                    element.paylinkInfo
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.adminSettingsModal.payOfficePaylink"
                                                )
                                              ) +
                                              " -\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.paymentModal.price"
                                                )
                                              ) +
                                              ":\n                  " +
                                              _vm._s(
                                                element.paylinkInfo.total
                                              ) +
                                              "\n                  " +
                                              _vm._s(
                                                element.paylinkInfo.currency
                                              ) +
                                              "\n                  " +
                                              _vm._s(
                                                _vm.$t("generics.status")
                                              ) +
                                              ":\n                  " +
                                              _vm._s(
                                                !element.paylinkInfo.unpaid
                                                  ? _vm.$t("generics.paid")
                                                  : _vm.$t("generics.unpaid")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !element.uuid && element.isConference
                        ? _c(
                            "v-card",
                            {
                              staticClass: "cardWidth wr",
                              staticStyle: { border: "2px solid white" },
                              attrs: { flat: "", height: "184px" },
                              on: {
                                click: function($event) {
                                  return _vm.selectConference(element)
                                }
                              }
                            },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "conferenceCard px-0 py-0 text--primary footerCard"
                                },
                                [
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "\n                  titleConferenceCard\n                  font-weight-medium\n                  align-end\n                  px-3\n                  mt-0\n                  pt-3\n                  text-truncate\n                  notranslate\n                "
                                    },
                                    [_vm._v(_vm._s(element.confTile))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "\n                  dateConferenceCard\n                  align-end\n                  px-3\n                  pt-4\n                  pb-0\n                  text-truncate\n                  notranslate\n                "
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm
                                              .moment(element.dateTimeStart)
                                              .format("DD.MM.YYYY | H:mm")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "\n                  usersConferenceCard\n                  align-end\n                  px-3\n                  pt-2\n                  pb-0\n                  text-truncate\n                  notranslate\n                "
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.groups.totalUsers"
                                            )
                                          ) +
                                          ": \n                " +
                                          _vm._s(element.totalUsers) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("CallConference", {
                                        attrs: {
                                          conference: _vm.getConference(
                                            element.confId
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            )
          ],
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "paginationPosition" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm.getTotalPages > 1
                      ? _c("v-pagination", {
                          attrs: { length: _vm.getTotalPages, circle: "" },
                          model: {
                            value: _vm.page,
                            callback: function($$v) {
                              _vm.page = $$v
                            },
                            expression: "page"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }