export default class SpeechService {
  constructor(language = "de-DE", callback) {
    if (!("webkitSpeechRecognition" in window)) {
      throw new Error("webkitSpeechRecognition is not available in this browser");
    }

    // eslint-disable-next-line no-undef
    this.recognition = new webkitSpeechRecognition();
    this.language = language;  // Guardamos el idioma
    this.recognition.lang = language;
    this.recognition.continuous = true; // Esto permite que siga escuchando
    this.recognition.interimResults = false; // Solo resultados finales
    this.callback = callback;

    // Cuando se obtiene un resultado
    this.recognition.onresult = (event) => {
      let finalTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        }
      }
      if (finalTranscript) {
        this.callback(finalTranscript);
      }
    };

    // Cuando ocurre un error
    this.recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    // Cuando el reconocimiento termina (cuando el usuario deja de hablar)
    this.recognition.onend = () => {
      // Reinicia el reconocimiento automáticamente para seguir escuchando
      this.recognition.start();
    };

    // Configuración para la detección de voz
    this.audioContext = null;
    this.analyser = null;
    this.voiceThreshold = 0.05; // Nivel mínimo de ruido para detectar voz
    this.lastNoiseTime = Date.now();
    this.silenceDuration = 1500; // ms para detectar silencio
  }

  async init() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Crear AudioContext y AnalyserNode
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = this.audioContext.createMediaStreamSource(stream);
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 256;
      source.connect(this.analyser);

      this.monitorVoice();
    } catch (error) {
      console.error("Error al acceder al micrófono:", error);
    }
  }

  monitorVoice() {
    const buffer = new Uint8Array(this.analyser.frequencyBinCount);

    const checkVoice = () => {
      this.analyser.getByteFrequencyData(buffer);
      const average = buffer.reduce((a, b) => a + b) / buffer.length / 255;

      if (average > this.voiceThreshold) {
        this.lastNoiseTime = Date.now();
        // Si no estamos escuchando, iniciamos la transcripción
        if (!this.isListening) {
          this.start();
        }
      } else if (this.isListening && Date.now() - this.lastNoiseTime > this.silenceDuration) {
        this.stop();
      }

      requestAnimationFrame(checkVoice);
    };

    checkVoice();
  }

  // Método para iniciar el reconocimiento de voz
  start() {
    if (!this.isListening) {
      this.recognition.start();
      this.isListening = true;
    }
  }

  // Método para detener el reconocimiento de voz
  stop() {
    if (this.isListening) {
      this.recognition.stop();
      this.isListening = false;
    }
  }

  destroy() {
    this.isListening = false;
    this.recognition.onend = null; // Evita reinicios automáticos
    this.recognition.stop();
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
    
  }
}
