var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClassChat },
    [
      _c(
        "v-col",
        {
          staticClass: "flex-grow-1 flex-shrink-0 containerMessages",
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column fill-height backgroundCard",
              attrs: { flat: "" }
            },
            [
              _c(
                "v-card-text",
                {
                  ref: "chatMessageContainer",
                  staticClass:
                    "pl-1 pr-3 flex-grow-1 overflow-y-auto chatMessageContainer"
                },
                [
                  _vm._l(_vm.chatMessages, function(message, index) {
                    return [
                      _vm.getTypeMessage(message) === "ai"
                        ? _c(
                            "div",
                            {
                              key: index,
                              class: {
                                "d-flex flex-row-reverse":
                                  message.user === _vm.ownUUID
                              }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "px-2 pt-3 pb-0 mb-2 white--text cardMessage",
                                  attrs: {
                                    color:
                                      message.user === _vm.ownUUID
                                        ? "#484a4f"
                                        : "#242528",
                                    label: ""
                                  }
                                },
                                [
                                  index == 0 ||
                                  (index > 0 &&
                                    _vm.chatMessages[index - 1].user !==
                                      message.user)
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "\n                    pt-0\n                    pb-2\n                    text-left\n                    align-self-start\n                    font-weight-bold\n                    text-truncate\n                  ",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getTitelForUuid(
                                                      message.user
                                                    )
                                                  ) +
                                                  "\n                  " +
                                                  _vm._s(
                                                    _vm.getNameForUuid(
                                                      message.user
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.isOnlyOneEmoji(message.text)
                                        ? "messageContentEmoji"
                                        : null,
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "messageText",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.checkMessage(message)
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pb-0 pt-1 text-right align-self-end",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-end mb-2"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    icon: "",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.copyMessage(
                                                        message
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: { fontSize: "18px" },
                                                    attrs: {
                                                      icon: ["fal", "copy"],
                                                      color:
                                                        _vm.copyingMessage &&
                                                        _vm.copyingMessage ===
                                                          message.id
                                                          ? "green"
                                                          : "white"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.dateFormatted(
                                                        message.ts
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }