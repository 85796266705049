var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isDominantSpeaker,
          expression: "isDominantSpeaker"
        }
      ],
      staticClass: "dominantSpeakerIcon",
      attrs: {
        id: _vm.isLocal
          ? "localIconDominant"
          : "remoteIconDominant-" + _vm.participantId
      }
    },
    [
      _c("div", { staticClass: "point left" }),
      _vm._v(" "),
      _c("div", { staticClass: "point middle" }),
      _vm._v(" "),
      _c("div", { staticClass: "point right" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }