<template>
  <v-card flat class="notification-box rounded-sm my-6">
    <v-card-title class="text-h6 mt-2 pb-0">
      {{ info.title }}
    </v-card-title>
    <v-card-text>
      <v-list class="bg-transparent">
        <v-list-item class="px-0" v-for="(content, index) in info.content" :key="index">
          <v-list-item-content>
            <v-list-item-title v-if="content.title" class="font-weight-medium">
              {{ content.title }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="content.date" class="text-grey-600 font-weight-light">
              {{ content.date }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="content.subtitle" class="text-grey-600 font-weight-light">
              {{ content.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <div v-if="content.content" class="font-weight-light">
            {{ content.content }}
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../../../store";
import { isMobile } from "../../../../lib/mobileUtil";

export default {
  props: ["sidebar", "mini", "info"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  watch: {},
  mounted() { },
  unmounted() { },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss"></style>