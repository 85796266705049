var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", { attrs: { titleModal: _vm.$t("generics.info") } }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-5", style: { width: "100%", margin: 0 } },
            [
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("components.inviteVisitor.typeYourName"),
                      outlined: "",
                      dense: "",
                      counter: "",
                      maxlength: 120
                    },
                    model: {
                      value: _vm.visitorName,
                      callback: function($$v) {
                        _vm.visitorName = $$v
                      },
                      expression: "visitorName"
                    }
                  }),
                  _vm._v(" "),
                  _vm.isConferenceCall
                    ? _c("v-text-field", {
                        attrs: {
                          label: _vm.$t(
                            "components.inviteVisitor.typeYourDescription"
                          ),
                          outlined: "",
                          dense: "",
                          counter: "",
                          maxlength: 120
                        },
                        model: {
                          value: _vm.visitorDescription,
                          callback: function($$v) {
                            _vm.visitorDescription = $$v
                          },
                          expression: "visitorDescription"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { hideDefaultButtons: true } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "buttonIconPrimaryFooter saveButton px-2",
                  attrs: {
                    icon: "",
                    tile: "",
                    color: "primary",
                    disabled: _vm.disableSaveVisitorInfo
                  },
                  on: { click: _vm.saveVisitorInfo }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("generics.save")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }