<template>
  <div>
    <v-content
      v-if="viewingGroups"
      :class="`px-0 py-0 ${removeGeneralWrapper ? null : 'contentGridWrapper'}`"
      v-on:scroll.native="handleScroll"
    >
        <UserProfle
          v-if="showUserProfile"
          :showUserProfile="showUserProfile"
          :toggleUserProfile="toggleUserProfile"
          :enableCam="true"
        />
        <v-row class="mx-auto">
          <v-col
            class="custom6cols pb-1 px-2"
            cols="6"
          >
            <v-card
              class="cardWidth cursorPointer"
              :style="{ border: `1px solid #efefef` }"
              @click="clickOnAddCard"
            >
              <div style="height: 180px; display: table; width: 100%">
                <div class="divAdd">
                  <font-awesome-icon
                    :icon="['fal', 'plus']"
                    class="buttonAdd"
                  />
                  <p class="mb-0 mt-2">{{ $t("components.conferenceForm.createGroup")}}</p>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col
            v-for="(group, index) in userGroups"
            :key="index"
            class="custom6cols pb-1 px-2"
            cols="6"
          >
            <v-card
              class="cardWidth"
              @click="editGroup(group)"
            >
            <!--  @mouseenter="hoverBar = true" @mouseout="hoverBar = false"> :disabled="hoverBar" -->
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-img
                      :src="group.groupImage"
                      class="white--text align-end"
                      height="180px"
                    >
                      <font-awesome-icon
                        v-if="group.favorite"
                        :icon="['fas', 'star']"
                        class="primary--text"
                        :style="{ fontSize: '20px', position: 'absolute', top: 5, right: 5 }"
                      />
                      <div class="gradientBg">
                        <v-card-subtitle
                          style="color: #fff !important"
                          class="titleCard colorWhite pt-1 pb-1 px-2 text-truncate"
                        >
                        <!--  elipses menu -->
                          <GroupCardActions :group="group" :editGroup="editGroup" :deleteGroup="deleteGroup" :setFavorite="setFavorite" :checkSendMessage="checkSendMessage" :existingGroup="existingGroup" :callMembers="callMembers" />
                        </v-card-subtitle>

                      </div>
                    </v-img>
                  </div>
                </template>
                <GroupInfoTooltip :group="group"></GroupInfoTooltip>
              </v-tooltip>
            </v-card>

          </v-col>

         <infinite-loading :distance="5" @infinite="infiniteHandler">
            <div slot="no-results"></div>
            <div slot="no-more"></div>
          </infinite-loading>
        </v-row>
    </v-content>

    <v-content
      v-if="!viewingGroups"
      :class="`px-0 py-0 ${removeGeneralWrapper ? null : 'contentGridWrapper'}`"
      v-on:scroll.native="handleScroll"
    >
      <v-row class="heightScroll mx-0">
        <v-col class="mx-0 py-0" cols="5">

          <v-row  class="mx-0">
            <v-col cols="12" class="pl-0 ">
             <v-text-field :class="{ borderError: groupName == '' }" outlined dense
             label="Name"
             v-model="groupName"
             hide-details>
             </v-text-field>
            </v-col>
             <v-col cols="10" class="pl-0 pb-0">
              <v-autocomplete
                v-model="selectedDropDowItem"
                :items="fillDropDown"
                outlined
                dense
                color="blue-grey lighten-2"
                :label="$t('components.groupGrid.selectParticipant')"
                item-text="name"
                item-value="uuid"
                multiple
                @change="handleSelection($event)"
                hide-selected
                @input="searchInput=null"
                :search-input.sync="searchInput"
              >
                <template v-slot:selection="data">{{ data.item.name }}</template>
                <template v-slot:item="data">
                  <div class="selectorBack d-flex">
                  <template>
                    <v-list-item-avatar class="borderRadius-4">
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>

                    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                    <v-list-item-content v-if="data.item.group !== 'Teams'">
                      <v-list-item-title
                        v-html="$sanitize(!data.item.isGroup ? data.item.name : data.item.groupName)"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="!data.item.isGroup ? getTypeOfParticipant(data.item.group) : $t('components.addConferenceModal.group') +` - ${data.item.groupMembers.length}` + ' '+ $t('components.addConferenceModal.members')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content  v-else>
                      <v-list-item-title v-html="$sanitize(data.item.name)"></v-list-item-title>
                      <v-list-item-subtitle
                         v-html="$t('components.addConferenceModal.team') + ` - ${data.item.members.length}` + ' '+ $t('components.addConferenceModal.members')"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  </div>
                </template>
              </v-autocomplete>
             </v-col>
            <v-col cols="2" class="pl-0 pb-0 text-center">
              {{ $t("components.conferenceForm.guest")}}
              <font-awesome-icon @click="showAddGuestModal = true;" class="primary--text cursorPointer" :icon="['fal', 'plus-circle']" :style="{ fontSize: '14px' }" />
            </v-col>
            <v-col cols="8" class="pl-0 pb-0">
              <v-textarea  outlined dense rows="2" v-model="groupDescription" :label="$t('components.groupGrid.description')" no-resize/>
            </v-col>

            <v-col cols="4" class="pb-0">
              <v-img :src="groupImage"
                width="110px"
                height="110px"
                max-height="110px"
                max-width="110px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 borderRadius-4 uploadGroupImage"
                        small
                        fab
                        dark
                        v-on="on"
                        @click="setGroupImage"
                        depressed
                        color="primary"
                        :disabled="false">
                          <font-awesome-icon :icon="['fal', 'upload']" :style="{ fontSize: '18px' }"/>
                      </v-btn>
                    </template>
                    <span>{{ $t("components.groups.uploadGroupImage")}}</span>
                </v-tooltip>
              </v-img>

              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                ref="groupImageInput"
                class="d-none"
                @change="saveGroupImage($event)"
              />
            </v-col>

            <v-col cols="12" class="pb-1 px-0 pt-0">
              <v-btn
                  class="justStart no-bg list"
                  depressed

                  :disabled="false"
                  @click="setShowModalSendMsg(groupMembers)"
                  >
                    <font-awesome-icon
                    :icon="['fal', 'paper-plane']"
                    :style="{ fontSize: '18px' }"
                    ></font-awesome-icon>
                    <span class="ml-3">{{$t('components.addGroupModal.sendMessageToAll')}}</span>
                </v-btn>
              </v-col>
              <v-col cols="12" class="pb-1 px-0 pt-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="justStart no-bg list"
                      :class="!deleteActive ? 'deleteBasketButtonNormal' :'deleteBasketButtonActive'"
                      depressed
                      :disabled="false"
                      @click="deleteGroup($event, groupId)"
                      >
                        <font-awesome-icon
                        :icon="['fal', 'trash']"
                        :style="{ fontSize: '18px' }"
                        ></font-awesome-icon>
                        <span class="ml-3">{{$t('components.addGroupModal.deleteGroup')}}</span>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("components.conferenceForm.confirmRemove")
                  }}</span>
                </v-tooltip>
                </v-col>

          </v-row>
        </v-col>

        <v-col cols="7" class="py-0 userList">
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <v-row class="mx-0">
            <v-col :cols="getCols" v-for="user in getGroupMembers" :key="user.uuid">
              <ListConferencesGroups :isEditingGroup="isEditingGroup" :deleteUser="deleteUser"  :user="user" :groupModerator="groupModerator" :isConferenceUser="false" />
            </v-col>
          </v-row>
          <!-- eslint-enable vue/no-use-v-if-with-v-for -->
        </v-col>
      </v-row>
      <!--  Buttons -->
      <v-row class="mx-0 pt-4">
        <v-col cols="12" class="pl-0 text-right">
          <v-divider class="pb-4"></v-divider>
          <v-btn
        class="ml-2"
          v-if="!isEditingGroup"
          @click="saveGroup"
          depressed
          color="primary"
          :disabled="disableSaveButton"
          >{{$t('generics.save')}}</v-btn>

          <v-btn
          class="ml-2"
          v-else
          @click="doUpdate"
          depressed
          color="primary"
          >{{$t('components.footer.doUpdate')}}</v-btn>

          <v-btn
          class="ml-2"
          v-if="isEditingGroup"
          @click="callGroup"
          depressed
          color="primary"
          :disabled="disableCallGroup"
          >
          <font-awesome-icon
            :icon="['fal', 'phone']"
            :style="{ fontSize: '20px' }"
          />
          </v-btn>
          <v-btn
            class="ml-2 borderRadius-4"
            style="height:36px;"
            @click="goBackToGrid"
            icon
            outlined
            depressed
            small
            fab
            dark
            color="primary"
            >
            <font-awesome-icon
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px' }"
            />
            </v-btn>
        </v-col>
      </v-row>
      <!-- End buttons -->

      <!-- Modals -->
      <!-- <template v-if="showAddUsersModal">
        <AddUserModal :addMember="addMember" :members="groupMembers" :isConference="false" :showModal="showAddUsersModal" :closeModal="closeUserModal" />
      </template> -->
       <template v-if="showAddGuestModal">
         <AddGuestModal :addMember="addMember" :members="groupMembers" :isConference="false" :showModal="showAddGuestModal" :closeModal="closeGuestModal" />
      </template>
      <template v-if="showCroppie">
        <UploadGroupConfImg
          :confOrGroupId="groupId"
          :uploadConferenceImage="uploadGroupImage"
          :showCroppie="showCroppie"
          :temporalImage="temporalImage"
          :resetInputImg="resetGroupImageInput"
          :closeCroppie="openCloseCroppie"
          :isConference="false"
        />

      </template>
      <!-- End modals -->
    </v-content>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import store, { EventBus } from "../../store";
import GroupInfoTooltip from "./groupInfoTooltip.vue";
import GroupCardActions from "./groupCardActions.vue";
import { callUUID, sendFileTo, getWebrtcConnections } from "../../lib/rtcConn";
import { dispatchSuccessAlert, dispatchErrorAlert } from '../../effector/alerts';
import { setSendMessageModalEvent } from "../../effector/modals"
import { wsCall } from "../../lib/wsConnect";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import { sendInviteMailCustom, sendNotificationToUUID, uploadGroupImage } from "../../lib/wsMsg";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import RemoveFromFavoriteModal from "../modal/removeFromFavoriteModal.vue";
import ChangeFunctionModal from "../modal/changeFunctionModal.vue";
import UserProfle from "../modal/userProfile.vue";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import BackOrganisationCard from "./backOrganisationCard.vue";
import ButtonsFrontCard from "./buttonsFrontCard.vue";
import ButtonsCardWrapper from "./buttonsCardWrapper.vue";
// NEW IMPORTS
import AddUserModal from "../modal/groups-conferences/addUserModal.vue";
import AddGuestModal from "../modal/groups-conferences/addGuestModal.vue";
import UploadGroupConfImg from "./uploadGroupConfImg.vue";


// END NEW IMPORTS
import {
  wsCallStartBridgeCall,
  callPerson,
  disableCallBtn,
  userInCallText,
  amInABridgeCall,
  userInCall,
  directBridgeCall,
  personIsInBridgeCall,
} from "../../utils/calls";
import {
  setBorderByStatus,
  checkListSort,
  getPictureSize,
  getAvatarByType,
  uid
} from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";
import InviteGuest from "../navbar/inviteGuest.vue";
import RegularGridHeader from "./regularGridHeader.vue";
import ListConferencesGroups from "./listConferencesGroups.vue";
export default {
  components: {
    ListConferencesGroups,
    ButtonsFrontCard,
    DefaultAvatarGrid,
    RemoveFromFavoriteModal,
    ChangeFunctionModal,
    UserProfle,
    GroupInfoTooltip,
    GroupCardActions,
    SendInvitationModal,
    BackOrganisationCard,
    InviteGuest,
    RegularGridHeader,
    ButtonsCardWrapper,
    AddUserModal,
    AddGuestModal,
    UploadGroupConfImg
  },
  props: ["typeData",
  "removeGeneralWrapper",
  "createGroup",
  "updateGroup",
  "selectOrUnselectFavorite",
  "existingGroup",
  "membersFromConference",
  "sendMailToAll"
  ],
  data() {
    return {
      state: store.state,
      clicks: 0,
      timer: null,
      deleteFavorite: {
        show: false,
        person: undefined,
      },
      changeUserFunction: {
        show: false,
        person: undefined,
      },
      ownUUID: store.state.ownUUID,
      showUserProfile: false,
      showInvitationModal: false,
      sliceIndex: 48,
      // searchUser: "",
      isMobile: isMobile(),
      isIpad: isIpad(),
      showGreen: false,
      showOrange: false,
      ItemIndex: null,
      // NEW
      isInserting: false,
      allUsers: {},
      allTeams: {},
      allGroups: [],
      selectedDropDowItem: [],
      searchInput: null,
      hoverBar: false,
      isEditingGroup: false,
      viewingGroups: true,
      groupModerator: store.state.ownUUID,
      groupName: '',
      groupId: uuidv4(),
      groupDescription: '',
      groupImage: '/img/default_profile_picture.png',
      groupMembers: [],
      showAddUsersModal: false,
      showAddGuestModal: false,
      groupToEdit: null,
      deleteActive: false,
      isDeleting: false,
      isCloning: false,
      dataToCloneFromConf: {},
      showCroppie: false,
      temporalImage: ''
    };
  },
  watch:{
    groupToEdit: {
      deep: true,
      handler: function(value) {
        if (value) {
          this.loadExistingGroup();
        } else {
          this.resetForm();
        }
      }
    },
    membersFromConference: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.dataToCloneFromConf = value;
          this.isCloning = true;
          this.populateGroupFromMembers();
        }
      }
    },
    viewingGroups(value) {
      if (value) return;
      this.allUsers = JSON.parse(JSON.stringify(this.state.group || {}));
      this.allTeams = JSON.parse(
        JSON.stringify(
          this.state.namespaceSettings.processedOrganisation.teams || {}
        )
      );
      this.allGroups = JSON.parse(JSON.stringify(this.state.user.groups || []));
    },
  },
  mounted() {
    EventBus.$on(
      "filterUsers",
      function (data) {
        this.showGreen = data.showGreen;
        this.showOrange = data.showOrange;
      }.bind(this)
    );
    if (!this.isEditingGroup && !this.isCloning) {
      this.populateDefaultModerator();
    }
  },
  computed: {
    getCols() {
      if ( this.getGroupMembers.length <= 4 ){
        return 6;
      }else if ( (this.getGroupMembers.length > 4 && this.getGroupMembers.length <= 15) ){
        return 4;
      }else if ( this.getGroupMembers.length > 15 ){
        return 3;
      }
    },
    fillDropDown() {
      if ((!this.groupToEdit && !this.isInserting) || (!this.groupToEdit && this.isEditingGroup)) return;
      let jumpRec = false;
      let result;
      let header;
      const groups = this.allGroups;
      const users = this.allUsers;
      const tmpUsersArray = [];
      const tmpVisitorsArray = [];
      const tmpUserGroupsArray = [];
      const tmpUserTeamsArray = [];
      let teamsObj = this.allTeams;
      // get team members
      let data;
      for (const key in users) {
        data = {
          name: (users[key].user || {}).name || "",
          uuid: key,
          avatar:(users[key].user || {}).avatar || "img/default_profile_picture.png"
        };
        if (tmpUsersArray.length === 0) {
          tmpUsersArray.push({ header: this.$t('components.addConferenceModal.users') });
        }
        if (!users[key].user.visitor && key !== store.state.ownUUID) {
          data.group = "Users";
          tmpUsersArray.push(data);
        } else if (
          users[key].user.visitor &&
          users[key].user.visitorData.userInviter === this.state.ownUUID
        ) {
          data.group = "Visitors";
          tmpVisitorsArray.push(data);
        }
      }

      if (tmpUsersArray.length > 0) {
        if (tmpVisitorsArray.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({ header: this.$t('components.addConferenceModal.visitors') });
          tmpUsersArray.sort((a, b) => {
            if ( a.name && b.name ){
              let fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();

              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
            }
            return 0;
          });
          tmpVisitorsArray.sort((a, b) => {
            if ( a.name && b.name ){
              let fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();

              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpVisitorsArray);
        }

        if (groups.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({ header: this.$t('components.addConferenceModal.groups') });
          groups.forEach(group => {
            if (this.isEditingGroup && this.groupToEdit) {
              if (this.groupToEdit.groupMembers.find(e => e.uuid === group.groupId)
              ) {
                jumpRec = true;
              }
            }
            if (!jumpRec) {
              const tmpObj = group;
              tmpObj.group = "Groups";
              tmpObj.name = group.groupName;
              tmpObj.avatar =
              group.groupImage || "img/default_profile_picture.png";
              tmpObj.isGroup = true;
              tmpUserGroupsArray.push(tmpObj);
            } else {
              jumpRec = false;
            }
          });
          tmpUserGroupsArray.sort((a, b) => {
            if ( a.name && b.name ){
              let fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();

              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpUserGroupsArray);
        }

        for (const key in this.allTeams) {
          if (this.allTeams.hasOwnProperty(key)) {
            const team = this.allTeams[key];
            if (this.isEditingGroup) {
              if (
                this.groupToEdit.groupMembers.findIndex(e => e.name === key) !== -1) {
                jumpRec = true;
              }
            }
            if (!jumpRec) {
              const tmpTeamObj = {
                name: key,
                members: team.members,
                group: "Teams",
                avatar: "img/default_profile_picture.png"
              };
              tmpUserTeamsArray.push(tmpTeamObj);
            } else {
              jumpRec = false;
            }
          }
        }
        if (tmpUserTeamsArray.length > 0) {
          tmpUsersArray.push({ divider: true });
          tmpUsersArray.push({ header: this.$t('components.addConferenceModal.teams') });
          tmpUserTeamsArray.sort((a, b) => {
            if ( a.name && b.name ){
              let fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();

              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
            }
            return 0;
          });
          Array.prototype.push.apply(tmpUsersArray, tmpUserTeamsArray);
        }
        result = tmpUsersArray;
      }
      return result;
    },
    disableSaveButton(){
      return this.groupName == '';
    },
    disableCallGroup(){
      if(!this.groupMembers) return true;
      return this.groupMembers.length < 1;
    },
    getGroupMembers(){
      return this.groupMembers;
    },
    userGroups() {
      let myGroups = store.state.user.groups || [];
      return myGroups;
    },
    // END NEw
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    inBridgeCall() {
      return amInABridgeCall();
    },
    getPictureSize() {
      return getPictureSize();
    },
    canISeeUser() {
      return (
        // this.searchUser.trim().length > 2 ||
        this.amIAdmin ||
        this.typeData === "favorites" || //exclude limit 24 in favorites #1816
        Object.keys(this.sortedPersons).length < 24 ||
        (this.state.namespaceSettings.organisation || []).length === 0 ||
        this.state.namespaceSettings.displayAllUsers
      );
    },
    amInACall() {
      return amInABridgeCall()
    },
    userList() {
      return store.getProcessedUserList();
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    slicedPersons() {
      return this.sortedPersons.slice(0, this.sliceIndex);
    },
    sortedPersons: function () {
      if (this.amIAdmin) {
        let persons = [];
        if (this.typeData === "users") {
          for (let uuid in store.state.group) {
            if (uuid) {
              const person = store.state.group[uuid];
              if (!person || !person.user || !hasPrivilege(uuid)) {
                continue;
              } else {
                // if (this.searchUser.trim().length > 2) {
                //   if (
                //     person.user.name
                //       .toLowerCase()
                //       .trim()
                //       .includes(this.searchUser.trim().toLowerCase())
                //   ) {
                //     persons.push({ ...person, uuid });
                //   }
                // } else {
                persons.push({ ...person, uuid });
                // }
              }
            }
          }
          return checkListSort(persons, this.state.user.userSettings.usersSort);
        } else if (this.typeData === "favorites") {
          for (let uuid in store.state.group) {
            const exists = store.getUuidFromFavorites(uuid);
            if (exists) {
              const person = store.state.group[uuid];
              const isGuest = person.user;
              if (!person || !person.user) {
                continue;
              } else {
                persons.push({ ...person, uuid });
              }
            }
          }
          return checkListSort(
            persons,
            this.state.user.userSettings.favoritesSort
          );
        } else if (this.typeData === "group") {
          let mygroups = store.state.user.userGroups;
          let name = this.$route.params.name;
          let daGroup = mygroups.find((e) => e.newGroupName === name);
          if (!daGroup) {
            return persons;
          }
          if (daGroup.members) {
            for (let idx in daGroup.members) {
              const uuid = daGroup.members[idx].uuid;
              const person = store.state.group[uuid];
              if (!person || !person.user || person.user.invisibleAdmin)
                continue;
              persons.push({ ...person, uuid });
            }
          }
          if (daGroup.guests) {
            for (let idx in daGroup.guests) {
              const uuid = daGroup.guests[idx].uuid;
              const person = store.state.group[uuid];
              if (!person || !person.user || person.user.invisibleAdmin)
                continue;
              persons.push({ ...person, uuid });
            }
          }
          if (daGroup.visitors) {
            for (let idx in daGroup.visitors) {
              const uuid = daGroup.visitors[idx].visitorId;
              const person = store.state.group[uuid];
              if (!person || !person.user || person.user.invisibleAdmin)
                continue;
              persons.push({ ...person, uuid });
            }
          }
          return this.getFinalPersons(persons);
        } else if (this.typeData === "guests") {
          const visitors = Object.keys(
            store.state.persisted.userVisitors
          ).filter(
            (uuid) =>
              !!store.state.persisted.userVisitors[uuid] &&
              store.state.group[uuid]
          );
          for (let uuid in store.state.group) {
            const exists = store.getUuidFromGuests(uuid);
            if (exists) {
              const person = store.state.group[uuid];
              const addPerson =
                !person ||
                !person.user ||
                person.user.name == "" ||
                person.user.invisibleAdmin ||
                visitors.indexOf(uuid) == -1 ||
                store.state.persisted.userVisitors[uuid].userInviter !==
                  this.ownUUID
                  ? false
                  : true;
              if (addPerson) {
                persons.push({ ...person, uuid });
              }
            }
          }
          return this.getFinalPersons(persons);
        } else if (this.typeData === "invites") {
          for (let uuid in store.state.group) {
            const person = store.state.group[uuid];
            const isGuest =
              person.permissions === 10 ||
              !person.user ||
              person.user.guest ||
              person.user.visitor;
            if (
              !person ||
              !person.user ||
              person.user.name == "" ||
              isGuest ||
              !(person.user.inviters || {})[store.state.ownUUID] ||
              person.user.rtcCallStart !== undefined
            )
              continue;
            persons.push({ ...person, uuid });
          }
          return this.getFinalPersons(persons);
        } else if (this.typeData === "usersWaitingRoom") {
          // for now are only guests users
          persons = store.getWaitingRoomList();
          return this.getFinalPersons(persons);
        } else {
          return undefined;
        }
      } else {
        // just show user that are not hided by an admin
        let persons = [];
        // || this.searchUser.trim().length > 2
        if (this.typeData === "users") {
          for (let uuid in store.state.group) {
            if (uuid) {
              const person = store.state.group[uuid];
              if (
                !person ||
                !person.user ||
                person.user.hidedByAdmin ||
                !hasPrivilege(uuid)
              ) {
                continue;
              } else {
                // if (this.searchUser.trim().length > 2) {
                //   if (
                //     person.user.name
                //       .toLowerCase()
                //       .trim()
                //       .includes(this.searchUser.trim().toLowerCase())
                //   ) {
                //     persons.push({ ...person, uuid });
                //   }
                // } else {
                persons.push({ ...person, uuid });
                // }
              }
            }
          }
          return checkListSort(persons, this.state.user.userSettings.usersSort);
        } else if (this.typeData === "favorites") {
          for (let uuid in store.state.group) {
            const exists = store.getUuidFromFavorites(uuid);
            if (exists) {
              const person = store.state.group[uuid];
              const isGuest = person.user;
              if (!person || !person.user || person.user.hidedByAdmin) {
                continue;
              } else {
                persons.push({ ...person, uuid });
              }
            }
          }
          return checkListSort(
            persons,
            this.state.user.userSettings.favoritesSort
          );
        } else if (this.typeData === "group") {
          let mygroups = store.state.user.userGroups;
          let name = this.$route.params.name;
          let daGroup = mygroups.find((e) => e.newGroupName === name);
          if (!daGroup) {
            return persons;
          }
          for (let idx in daGroup.members) {
            const uuid = daGroup.members[idx].uuid;
            const person = store.state.group[uuid];
            persons.push({ ...person, uuid });
          }
          return this.getFinalPersons(persons);
        } else if (this.typeData === "guests") {
          const visitors = Object.keys(
            store.state.persisted.userVisitors
          ).filter(
            (uuid) =>
              !!store.state.persisted.userVisitors[uuid] &&
              store.state.group[uuid]
          );
          for (let uuid in store.state.group) {
            const exists = store.getUuidFromGuests(uuid);
            if (exists) {
              const person = store.state.group[uuid];
              const addPerson =
                !person ||
                !person.user ||
                person.user.name == "" ||
                person.user.hidedByAdmin ||
                visitors.indexOf(uuid) == -1 ||
                store.state.persisted.userVisitors[uuid].userInviter !==
                  this.ownUUID
                  ? false
                  : true;
              if (addPerson) {
                persons.push({ ...person, uuid });
              }
            }
          }
          return this.getFinalPersons(persons);
        } else if (this.typeData === "invites") {
          for (let uuid in store.state.group) {
            const person = store.state.group[uuid];
            const isGuest =
              person.permissions === 10 ||
              !person.user ||
              person.user.guest ||
              person.user.visitor;
            if (
              !person ||
              !person.user ||
              person.user.name == "" ||
              isGuest ||
              !(person.user.inviters || {})[store.state.ownUUID] ||
              person.user.rtcCallStart !== undefined
            )
              continue;
            persons.push({ ...person, uuid });
          }
          return this.getFinalPersons(persons);
        } else {
          return undefined;
        }
      }
    },
  },
  methods: {
    openCloseCroppie(value = undefined) {
      this.showCroppie = value ? value : !this.showCroppie;
    },
    getTypeOfParticipant(type){
      if (type === "Visitors"){
        return this.$t('components.addConferenceModal.visitor')
      } else if(type === "Users"){
        return this.$t('components.addConferenceModal.users')
      }
    },
    async callMembers(groupId){
      const extant = store.state.user.groups.filter(e => e.groupId == groupId)[0];
      if (extant) {
        this.groupMembers = extant.groupMembers;
        this.callGroup();
      }

    },
    syncSelector() {
      // removes allready selected users groups or teams from dropDown
      if(!this.groupMembers){
        this.goBackToGrid();
        return;
      }
      this.groupMembers.forEach(member => {
        if (member.isTeam) {
          const extant = this.allTeams[member.name];
          if (extant) {
            this.allTeams[member.name] = undefined;
          }
        } else if (member.isGroup) {
          const extant = this.allGroups.find(e => e.groupId === member.uuid);
          if (extant) {
            this.allGroups = this.allGroups.filter(
              group => group.groupId !== member.uuid
            );
          }
        } else {
          // users and guests
          this.allUsers[member.uuid] = undefined;
          delete this.allUsers[member.uuid];
        }
      });
    },
        handleSelection(event) {
      if (this.isConferenceFull){
        this.searchInput = null;
        event.preventDefault();
        event.stopPropagation();
      }
      let name = '';
      const idx = event[event.length - 1];
      const user = this.allUsers[idx];
      if (user && user.user) {
        const uuid = idx;
        if (user.user.visitor && user.user.visitorData.visitorId) {
          // is visitor
          const dataObj = {
            isGuest: true,
            isModerator: false,
            name: user.user.name,
            userFunction: user.user.userFunction,
            uuid: user.user.visitorData.visitorId,
            isGroup: false,
            isTeam: false
          };
          name = this.$t('components.conferenceForm.guestAdded', [user.user.name]);
          this.addMember(dataObj);
          this.allUsers[idx] = undefined;
          delete this.allUsers[idx];
          this.selectedDropDowItem = [];
        } else if (user.user && uuid) {
          // is user
          const dataObj = {
            isGuest: false,
            isModerator: false,
            name: user.user.name,
            userFunction: user.user.userFunction,
            uuid: uuid,
            isGroup: false,
            isTeam: false
          };
          name = this.$t('components.conferenceForm.userAdded', [user.user.name]);
          this.addMember(dataObj);
          this.allUsers[idx] = undefined;
          delete this.allUsers[idx];
          this.selectedDropDowItem = [];
        }
      } else if (this.allGroups.findIndex(e => e.groupName === idx) !== -1) {
        // is group
        const extant = this.allGroups.find(e => e.groupName === idx);
        if (extant && extant.groupMembers) {
          extant.groupMembers.forEach(member => {
           this.addMember(member);
          });
          name = this.$t('components.conferenceForm.groupAdded', [extant.groupName]);
          const newArray = this.allGroups.filter(e => e.groupName !== idx);
          this.allGroups = newArray;
          this.selectedDropDowItem = [];
        }
      } else if (this.allTeams[idx] != undefined) {
        // is team
        const extant = this.allTeams[idx];
        if (extant && extant.members) {
          extant.members.forEach(uuid => {
            if ((((store.state.group[uuid] || {}).user || {}).name || false) === false){
              // skip no data in user
            } else {
              const user = store.state.group[uuid].user;
                const dataObj = {
                    isGuest: false,
                    isModerator: false,
                    name: user.name,
                    userFunction: user.userFunction || '',
                    uuid: uuid,
                    isGroup: false,
                    isTeam: false
                  };
                this.addMember(dataObj);
              }
          });
          if (extant && extant.supervisors){
            const superv = extant.supervisors.map(e =>e.uuid)
            if (superv && superv.length){
              superv.forEach(uuid => {
                if ((((store.state.group[uuid] || {}).user || {}).name || false) === false){
                  // skip no data in user
                } else {
                  const user = store.state.group[uuid].user;
                    const dataObj = {
                        isGuest: false,
                        isModerator: false,
                        name: user.name,
                        userFunction: user.userFunction || '',
                        uuid: uuid,
                        isGroup: false,
                        isTeam: false
                      };
                    this.addMember(dataObj);
                  }
              });

            }
          }
          name = this.$t('components.conferenceForm.teamAdded', [idx]);
          // remove from array and clear entry
          this.allTeams[idx] = undefined;
          delete this.allTeams[idx];
          this.selectedDropDowItem = [];
        }
      }
      dispatchSuccessAlert(name);
    },
    populateGroupFromMembers(){
      this.groupName = this.dataToCloneFromConf.title || '';
      this.groupId = uuidv4();
      this.groupDescription = this.dataToCloneFromConf.description || '';
      this.groupImage = '/img/default_profile_picture.png';
      this.groupMembers = this.dataToCloneFromConf.members;
      this.viewingGroups = false;
      this.groupToEdit = null;
      this.isEditingGroup = false;
      this.syncSelector();
    },
    extractUsers(owner) {
      let users = [];
      this.groupMembers.forEach(member => {
        if (member.isTeam) {
          const extant = this.state.namespaceSettings.processedOrganisation
            .teams[member.name];
          const tempMembers = extant.members;
          extant.supervisors.forEach(element => {
            if (tempMembers.indexOf(element.uuid) == -1) {
              tempMembers.push(element.uuid);
            }
          });
          tempMembers.forEach(id => {
            if (users.indexOf(id) == -1) {
              users.push(id);
            }
          });
        } else if (member.isGroup) {
          const extant = store.state.group[owner].user.groups.find(
            e => e.groupId == member.uuid
          );
          const extantMmembers = extant.groupMembers;
          const members = extantMmembers.map(member => member.uuid);
          members.forEach(el => {
            if (users.indexOf(el) == -1) {
              users.push(el);
            }
          });
        } else if (member.uuid) {
          if (users.indexOf(member.uuid) == -1) {
            users.push(member.uuid);
          }
        }
      });
      return users;
    },
    checkSendMessage(groupMembers) {
      const uuidArrays = [];
      groupMembers.forEach((uuid) => {
        if ( uuid !== this.ownUUID ){
          uuidArrays.push(uuid)
        }
      });
      this.setShowModalSendMsg(uuidArrays);
    },
    setShowModalSendMsg(members) {
      setSendMessageModalEvent({show:true, users: members, type: 'groupmessage'})
    },
    deleteUser(user){
     if (!user) return false; // missing param
     let tobeNotified = [];
      if (!user.isGroup && !user.isTeam) {
        if (!user.visitor) {
          const extant = store.state.group[user.uuid] || false;
          if (!extant) return false;
          this.groupMembers = this.groupMembers.filter(
            e => e.uuid !== user.uuid
          );
          this.allUsers = { ...this.allUsers, [user.uuid]: extant };
          if(tobeNotified.indexOf(user.uuid) === -1){
            tobeNotified.push(user.uuid);
          }
        } else {
          const extant = store.state.group[user.visitorData.visitorId] || false;
          if (!extant) return false;
          this.groupMembers = this.groupMembers.filter(
            e => e.uuid !== user.visitorData.visitorId
          );
          this.allUsers = { ...this.allUsers, [user.uuid]: extant };
           if(tobeNotified.indexOf(user.visitorData.visitorId) === -1){
            tobeNotified.push(user.visitorData.visitorId);
          }
        }
        this.totalMembers--;
      } else if (user.isGroup) {
        const extant = store.state.user.groups.find(
          e => e.groupId === user.uuid
        );
        if (extant) {
          this.allGroups.push(extant);

          const idx = this.groupMembers.findIndex(
            e => e.uuid === user.uuid
          );
          if (idx !== -1) {
            this.groupMembers.splice(idx, 1);
          }
          const groupM = extant.groupMembers.map(e => e.uuid)
          groupM.forEach(uuid => {
            if(tobeNotified.indexOf(uuid) === -1){
              tobeNotified.push(user.visitorData.visitorId);
            }
          });
        }
      } else if (user.isTeam) {
        const extant = this.state.namespaceSettings.processedOrganisation.teams[user.name];
        if (extant) {
          this.allTeams[user.name] = extant;

          const idx = this.groupMembers.findIndex(
            e => e.name === user.name
          );
          if (idx !== -1) {
            this.groupMembers.splice(idx, 1);
          }
         let tempUsers =  extant.members || [];
         if(extant.supervisors && extant.supervisors.length){
           extant.supervisors.forEach(element => {
            if (tempUsers.indexOf(element.uuid) == -1){
              tempUsers.push(element.uuid);
            }
           });
         }
          tempUsers.forEach(uuid => {
            if(tobeNotified.indexOf(uuid) === -1){
              tobeNotified.push(user.visitorData.visitorId);
            }
          });

        }
      }
      if (tobeNotified && tobeNotified.length){
       // this.$t('components.conferenceForm.subjectCanceledEmail', [event.name]),
        const text = this.$t('components.conferenceForm.conferenceHasBeenCanceled') + " " + this.$t('components.conferenceForm.youWillBeNotified');
        const subject = this.$t('components.addConferenceModal.confHeader')
        this.sendMailToAll(tobeNotified,subject, text)
      }

    },
    deleteGroup(event, groupId){
      event.stopPropagation();
      event.preventDefault();
       try{
        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false;
          },1500)
        } else {
          this.deleteActive = false;
          const groupIndex = store.state.user.groups.findIndex(e => e.groupId === groupId);
          store.state.user.groups.splice(groupIndex, 1)
          this.viewingGroups = true;
          dispatchSuccessAlert(this.$t('components.groups.groupDeleted'));

        }
      }catch(err){
          this.deleteActive = false;
          console.warn('Error deleting group', err)
          dispatchErrorAlert('Error deleting group')
      }
    },
    loadExistingGroup(){
      this.groupName = this.groupToEdit.groupName;
      this.groupId = this.groupToEdit.groupId;
      this.groupDescription = this.groupToEdit.groupDescription;
      this.groupImage = this.groupToEdit.groupImage;
      this.groupMembers = this.groupToEdit.groupMembers;
      this.syncSelector();
    },
    resetForm(){
      this.groupName = '';
      this.groupId = uuidv4();
      this.groupDescription = '';
      this.groupImage = '/img/default_profile_picture.png';
      this.groupMembers = [];
      this.populateDefaultModerator();
    },
    populateDefaultModerator() {
      const tmpObj = {
        name: store.state.user.name,
        uuid: store.state.ownUUID,
        isModerator: true,
        isGuest: false,
        userFunction: this.state.group[store.state.ownUUID].user.position || ''
      }
      this.groupMembers.push(tmpObj);
    },
    goBackToGrid(){
      this.resetForm();
      this.isCloning = false;
      this.viewingGroups = true;
      this.groupToEdit = null;
      this.isEditingGroup = false;
    },
    addMember(memberToAdd){
      if (!this.groupMembers.find(e => e.uuid === memberToAdd.uuid)) {
        this.groupMembers.push(memberToAdd);
      }
    },
    closeUserModal(){
        this.showAddUsersModal = false;
    },
    closeGuestModal(){
        this.showAddGuestModal = false;
    },
    async saveGroup(){
    // saveGroup
      try{
        const groupData = {
          groupId: this.groupId,
          groupName: this.groupName,
          groupImage: this.groupImage == null ? '/img/default_profile_picture.png' : this.groupImage,
          groupDescription: this.groupDescription,
          groupMembers: this.groupMembers,
          groupUUIDS: await this.extractUsers(store.state.user.uuid),
        }
        await this.createGroup(groupData);
        this.goBackToGrid();
        dispatchSuccessAlert(this.$t('components.groups.groupCreated'))
      }catch(err){
        console.warn('Error creating group', err)
        dispatchErrorAlert('Error creating group')
      }
    },
    async doUpdate(){
      try{
        const tmpObj = {
          groupId: this.groupToEdit.groupId,
          groupName: this.groupName,
          groupImage: this.groupImage,
          groupDescription: this.groupDescription,
          groupMembers: this.groupMembers,
          groupUUIDS: await this.extractUsers(store.state.user.uuid),

        }
        await this.updateGroup(tmpObj);
        this.goBackToGrid();
        this.resetForm();
        dispatchSuccessAlert(this.$t('components.groups.groupUpdated'));
      }catch(err){
      console.warn('Error updating group', err)
      dispatchErrorAlert('Error updating group')
    }

    },
    callGroup(){
      const duration = (24 * 3600 * 1000);
      this.setdurationMeeting(duration);

      if (this.groupMembers.length > 0) {
        const usersToCall = [];
        this.$router.push({ path: "/home" });
        this.groupMembers.forEach(async (user) => {
          if ( user.uuid !== this.state.ownUUID){
            const isConnected = ((store.state.group[user.uuid] || {}).connected || false);
            const person = store.state.group[user.uuid];
            if (person && person.user &&
              !person.user.rtcCallStatus  &&
              !person.user.rtcCallStatus.length || 0 &&
              ((!person.user.rtcCallStatus.length || 0 > 5) ||
              !person.user.inBridgeCall)){
              if (!this.amInACall) {
                if (isConnected && (((store.state.group[user.uuid] || {}).user || {}).activity || false) !== "Holidays"){
                  usersToCall.push(user.uuid);
                }
              }
            }
          }
        });
        const id = uid();
        const info = {
          callUUID: id,
          calling: usersToCall,
          isNormalMode: true,
          initiator: this.checkInitiator === '' ? this.state.ownUUID : this.checkInitiator,
          callStartTs: Date.now(),
          callDurationMs: duration,
          infiniteCall: false,
        };
        // let thereIsSomeoneToCall = false;
        usersToCall.forEach((user, idx) => {
            const infoStartBridgeCall = {
              callUUID: id,
              isNormalMode: this.groupMembers.length > 0 ? true : false,
              initiator: this.state.ownUUID,
              callStartTs: Date.now(),
              callDurationMs: info.callDurationMs,
              infiniteCall: false,
              conferenceDocuments: this.documentsPdf || [],
              userConferencePosition: user.usersFunction || [],
              excludedFromCalling: [],
              conferenceAttendees: this.groupMembers || [],
              conferenceSettings: {},
              usersToHideName: [],
              isConference: true,
              confId: this.groupId,
              isConferenceCall: undefined
            };
            wsCallStartBridgeCall(user, this.state.ownUUID, infoStartBridgeCall, (idx > 0))
        })
        store.addRemoteBridgeStream(id, info);
        store.setCurrentContentVisile("", false, this.$router);
      }
    },
    checkInitiator(){
      const callUUID = this.getCallUUID;
      if (callUUID !== ''){
        return this.state.remoteBridgeStreams[callUUID].initiator;
      } else {
        return '';
      }
    },
    setdurationMeeting(duration = null) {
      return store.setdurationMeeting(duration || 300000);
    },
    setFavorite(groupId){
      this.selectOrUnselectFavorite(groupId)
    },
    getNameForUuid(uuid){
      return store.getNameForUuid(uuid);
    },
    async saveGroupImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.temporalImage = e.target.result;
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
    },
    async uploadGroupImage(id, img){
      var location =  await new Promise((resolve, reject) => {
         uploadGroupImage(id, img).then(resolve).catch(reject);
      });
      var url = "/" + location + "?_=" + Date.now();
      this.groupImage = url;
      this.resetGroupImageInput();
    },
    resetGroupImageInput() {
      this.$refs.groupImageInput.value = "";
    },
    setGroupImage() {
      this.$refs.groupImageInput.click();
    },
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    openMenu: function (e, uuid) {
      //userActionsList
      e.preventDefault();
      const element = document.getElementById(`userActionList-${uuid}`);
      if (element) {
        EventBus.$emit("closeMenu");
        element.click();
      }
      // EventBus.$emit("openMenu");
    },
    selectIndex(Index) {
      this.ItemIndex = Index;
    },
    clickOnAddCard() {
      this.viewingGroups = false;
      this.groupToEdit = null;
      this.isEditingGroup = false;
      this.isInserting = true;
      this.resetForm();

    },
    editGroup(group) {
      this.viewingGroups = false;
      this.isEditingGroup = true;
      this.$nextTick(() => (this.groupToEdit = group));
    },
    getCallText(person) {
      return userInCallText(person);
    },
    getFinalPersons(persons) {
      // function to sort the userlist
      return persons.sort((a, b) => {
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a.user || {}).name || a.uuid || "").localeCompare(
          (b.user || {}).name || b.uuid || ""
        );
      });
    },
    infiniteHandler($state) {
      if (this.sliceIndex < store.state.user.groups.length) {
        this.sliceIndex += 6;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    directBridgeCall(uuid) {
      directBridgeCall(uuid, this.$router);
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    async setShowModalSendInvitation(person) {
      /* const result = */ await sendInviteMailCustom(person.uuid, person.user);
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.user.name,
          email: person.user.emailAlias || person.user.email,
          date: new Date(),
        },
      };
      this.showInvitationModal = true;
      setTimeout(() => {
        if (this.showInvitationModal) {
          this.showInvitationModal = false;
        }
      }, 2000);
    },
    checkImsupervisor(person) {
      try {
        // check if the person is in the array, if not avoid all the process
        if (this.userList[person.uuid]) {
          // check if my user exists in the list
          if (this.userList[this.ownUUID]) {
            // check if my users is supervisor of a section
            if (this.userList[this.ownUUID].supervisorOfSections) {
              const mySections = this.userList[this.ownUUID]
                .supervisorOfSections;
              for (let i = 0; i < mySections.length; i++) {
                const section = mySections[i];
                if (this.userList[person.uuid].teamSections[0] === section) {
                  // i'm the supervisor of this user
                  return true;
                }
              }
            }
          }
          return false;
        }
      } catch {}
    },
    changeFunction(person) {
      this.changeUserFunction = {
        show: true,
        person: person,
      };
    },
    cancelChangeFunction() {
      this.changeUserFunction = {
        show: false,
        person: undefined,
      };
    },
    closeDeleteFavoriteModal() {
      this.deleteFavorite = {
        show: false,
        person: undefined,
      };
    },
    userIsAdmin(uuid) {
      return store.getUserIsAdmin(uuid);
    },
    personPing: function (person) {
      // store.setLastInteractionWithUuid(person.uuid);
      wsCall("sendToUUID", person.uuid, { type: "ping" });
      sendNotificationToUUID(person.uuid, { type: "ping" });
    },
    setPersonFav: function (person) {
      store.setUuidInFavorites(person.uuid, true);
    },
    removePersonFav: function (person) {
      store.setUuidInFavorites(person.uuid, undefined);
      this.deleteFavorite = {
        show: false,
        person: undefined,
      };
    },
    sendFileTo(uuid) {
      return sendFileTo(uuid);
    },
    // ShowTicketEntry(assignee) {
    //   this.state.currentContentVisile.showComponent = true;
    //   this.state.currentContentVisile.component = "ticketEntry";
    //   this.state.showTicketEntry = true;
    //   this.state.ticket.t_assignee = assignee;
    // },
    personIsGuest(person) {
      return person.uuid && store.getUserIsGuest(person.uuid);
    },
    isInCallWithUs(person) {
      return (
        !!this.state.rtc[person.uuid] && !!this.state.remoteStreams[person.uuid]
      );
    },
    flipCard(reference) {
      this.$refs[reference][0].flipped = !this.$refs[reference][0].flipped;
    },
    handleClicks(event, person, reference) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
          if (person.uuid !== this.ownUUID) {
            if (!this.disableCallBtn(person)) {
              // if (!this.userIsInCall(person)) {
              //   if (this.amInACall) {
              //     this.personCall(person);
              //   } else {
              //     this.setShowModalMeetingTime(person.uuid);
              //   }
              // } else {
              //   this.joinCall(person);
              // }
              this.callUser(person.uuid);
            }
          } else {
            this.setUserInModal();
          }
        }, 700);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
        if (!this.disableCallBtn(person)) {
          if (this.inBridgeCall) {
            this.callUser(person.uuid);
          } else {
            if (!this.userIsInCall(person)) {
              if (personIsInBridgeCall(person.uuid)) {
                this.callUser(person.uuid);
              } else {
                this.directBridgeCall(person.uuid);
              }
            }
            // this.defaultCall(person.uuid);
          }
        }
      }
    },
    defaultCall(uuid) {
      this.setdurationMeeting();
      this.setCurrentContentVisile("", false, this.$router);
      callUUID(uuid);
      // if(this.checkedMessage){
      //   store.setShowMessageInMeetingTimeModal(false);
      // }
    },
    setUserInModal() {
      store.state.ownModalUser = true;
      store.state.userOnModal = {
        ...store.state.user,
        connected: store.state.wsOpen,
        uuid: store.state.ownUUID,
      };
      this.toggleUserProfile();
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
    fullCall() {
      return this.state.isCallFull;
    },
    userIsInCall(person) {
      return userInCall(person.uuid);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    namePerson(person) {
      if (person && person.user.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      return store.getTitelForUuid(person.uuid);
      // if (person && person.user.titel) {
      //   if (person.user.titel === "unknown") {
      //     return "";
      //   } else {
      //     return person.user.titel;
      //   }
      // }
    },
    personConected(person) {
      if (person) {
        if (person.connected) {
          return person.user.activity || "";
        } else if (person.user.guest) {
          return this.$t("generics.guest");
        } else {
          return person.user.position;
        }
      }
    },
    personFunction(person) {
      if (person) {
        if (person.user && person.user.position) {
          return person.user.position;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    setBorderByStatus(person) {
      return setBorderByStatus(person);
    },
    canRemoveGuest(person) {
      const inviters = person.user.inviters;
      if (this.amIAdmin || (inviters && inviters[this.state.ownUUID])) {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.borderRadius-4 {
  border-radius: 4px;
}
.uploadGroupImage{
  position: absolute;
  bottom: 8px;
  left: 25px;
}

.theme--light.v-btn.list {
   color:  rgb(117, 117, 117) !important;
}
.list {
  text-transform: none;
  font-weight: 300;
  padding-left: 5px!important;
  letter-spacing: 0;
      font-size: 16px!important;
}
.no-bg{
  background-color:transparent !important;
}
.deleteBasketButtonNormal {
 color:  rgb(117, 117, 117) !important;
}
.deleteBasketButtonActive {
  color: red !important;
  font-weight: 600 !important;
}
.w-100 {
  width: 100%;
}
.justStart {
  justify-content: flex-start;
}
.heightScroll {
  max-height: calc(100% - 200px);
overflow: auto;
}
.userList {
  overflow: auto;
  max-height: 100%;
}
@media (max-width: 959.9px) {
  .userDiv {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .userDiv {
    width: 50%;
  }
}
.userDiv {

  display: inline-block;
  text-align: left;
  padding: 12px;
}
.titleUserDiv{
border-bottom: 1px solid rgba(0,0,0,.12);
}
.userAvatar{
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
}
.userName{
  vertical-align: middle;
}
.hoverDiv {
  position: absolute;
  width: 100%;
  left: 0px;
  height: 100%;
  display: table;
  text-align: center;
  z-index: 1;
  background: #78787854;
  & div {
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    svg {
      font-size: 30px;
      color: white;
    }
  }
}
.cursorPointer {
  cursor: pointer;
}
.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}
.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.titleCard {
  // height: 25px !important;
  // line-height: 0 !important;
}
.subTitleCard {
  color: rgb(255, 255, 255) !important;
  margin-top: -6px !important;
}

.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}

.gradientBg {
  // min-height: 45px;
  // max-height: 45px;
  position: relative;
}
.buttonFlip {
  font-size: 20px;
  width: 1.25em;
}
.customFontSize {
  font-size: 13px !important;
  word-break: break-word;
  max-width: 85%;
}
.colorWhite {
  color: white !important;
}
.theme--dark.v-btn.v-btn--disabled {
  color: #00000042 !important;
}
.buttonCallFrontCard {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  // padding: 2px !important;
  font-size: 0px !important;
  // position: relative !important;
  // right: -2px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.divIconUserOnline {
  text-align: center;
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
  display: table;
  & .frontOnline {
    width: 17px;
    vertical-align: middle;
    margin-top: 4px !important;
  }
}

.iconCardsContainerBlack {
  position: absolute;
  top: 0;
  right: 0;
  // background: #00000054;
  background: unset;
  height: 120px;
}
.iconCardsContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
}
.contentGridWrapper {
  height: calc(100vh - 130px);
  overflow: auto;
}
.custom6cols {
  width: 16%;
  flex-basis: 16%;
}
.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}
@media (max-width: 765px) {
  .contentGridWrapper .custom6cols {
    width: 50%;
    min-width: 50%;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .contentGridWrapper .custom6cols {
    width: 20% !important;
    flex-basis: 20% !important;
  }
}

@media (max-width: 890px) {
  .contentGridWrapper .custom6cols {
    width: 25% !important;
    flex-basis: 25% !important;
  }
}

@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}

.footerCard {
  background: white;
  padding: 0 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.v-card__subtitle {
  color: black !important;
}
.v-card__title {
  font-size: 15px !important;
}
.opactity0 {
  opacity: 0;
}
.flipButton {
  padding: 0 5px;
  position: absolute;
  right: 5px;
  bottom: 13px;
  color: black;
  cursor: pointer;
  font-size: 22px;
}
.selectorBack{
    width: 100%;
    background-color: #e4f4f6;
    margin: 3px;
    padding-left: 7px;
}
.theme--dark .selectorBack{
    background-color: #e4f4f61a;
}

.theme--dark .selectorBack .deleteBasketButtonNormal{
  color:white;
}
.borderError {
  fieldset {
    border-color: red !important;
  }
}
</style>
