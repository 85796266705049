var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mx-0", attrs: { align: "center" } },
        [
          _c("v-col", { staticClass: "col-12" }, [
            _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("components.companyRegister.emailConfirmation")
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-text-field",
                {
                  attrs: {
                    label: _vm.$t("components.verifyEmailRegister.sixDigits"),
                    required: "",
                    outlined: "",
                    dense: "",
                    rules: _vm.verifyCodeRules,
                    disabled: _vm.verifyCodeLoading || _vm.verifyCodeSuccess,
                    counter: "",
                    maxlength: "6",
                    clearable: ""
                  },
                  on: {
                    change: function($event) {
                      ;(_vm.verifyCode || "").length === 6 &&
                        !(_vm.verifyCodeLoading || _vm.verifyCodeSuccess) &&
                        _vm.checkCodeRules()
                    },
                    keypress: _vm.onCodeInput,
                    keyup: _vm.checkCodeRules
                  },
                  model: {
                    value: _vm.verifyCode,
                    callback: function($$v) {
                      _vm.verifyCode = $$v
                    },
                    expression: "verifyCode"
                  }
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm.verifyCodeLoading && _vm.verifyCode.length > 0
                      ? _c(
                          "span",
                          { staticClass: "custom-loader-verify-code mt-1" },
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "15px" },
                              attrs: { icon: ["fal", "spinner-third"] }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.verifyCodeSuccess
                      ? _c(
                          "span",
                          { staticClass: "mt-1" },
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "15px" },
                              attrs: { icon: ["fal", "check"], color: "green" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "right" },
        [
          _c(
            "v-col",
            { staticClass: "py-0 text-right", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    depressed: "",
                    color: "primary",
                    disabled: !_vm.hasVerifiedEmail
                  },
                  on: {
                    click: function($event) {
                      return _vm.endSetup()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isGermanLawyer || _vm.isPackagedVersion
                          ? _vm.$t("generics.startNow")
                          : _vm.$t("components.companyRegister.buttonFreeTrial")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              !(_vm.isGermanLawyer || _vm.isPackagedVersion)
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "primary",
                        disabled: !_vm.hasVerifiedEmail
                      },
                      on: {
                        click: function($event) {
                          return _vm.showPayPalScreen()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("components.companyRegister.buttonPayPal")
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }