var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showQrCodeModal,
        callback: function($$v) {
          _vm.showQrCodeModal = $$v
        },
        expression: "showQrCodeModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c(
              "HeaderModal",
              {
                attrs: {
                  titleModal: _vm.$t("components.qrModal.qrCode"),
                  closeModalFunction: _vm.closeModal
                }
              },
              [
                _c("v-spacer"),
                _vm._v(" "),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.timeLeft))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("v-card-text", { staticClass: "py-0 pt-4" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _vm.inviteQR
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.inviteQR,
                          "max-height": "200",
                          "max-width": "200"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errMsg
                    ? _c(
                        "v-alert",
                        { attrs: { type: "error", dismissible: true } },
                        [_vm._v(_vm._s(_vm.errMsg))]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }