
<template>
  <v-list flat>
    <v-list-item-group color="primary">
      <v-list-item style="margin-right: 24px">
        <v-list-item-icon>
          <font-awesome-icon
            class="primary--text-sidepanel"
            :icon="['fal', 'sticky-note']"
          />
        </v-list-item-icon>
        <v-list-item-title
          class="primary--text-sidepanel"
          @click.stop.prevent="handleMyInfoboardView()"
          :class="`${
            this.$route.path === '/infoboard'
              ? 'font-weight-bold primary--text-sidepanel'
              : ''
          }`"
        >
          {{ $t("generics.infoboard") }}
          {{ `${getTotalNotes > 0 ? "(" + getTotalNotes + ")" : ""}` }}
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="btnAddInfo"
                @click.stop.prevent="handleNewInfoNote()"
                v-if="amIAdmin"
              >
                <font-awesome-icon
                  class="primary--text-sidepanel"
                  :icon="['fal', 'plus-circle']"
                  style="font-size: 20px; margin-right: 5px"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.drawerContentExpansion.newNote") }}</span>
          </v-tooltip>
          <!-- <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      class="btnTaskExit"
                      @click.stop.prevent="exitInfoBoard();"
                    >
                      <font-awesome-icon :icon="['fal', 'door-open']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>{{ $t('components.drawerContentExpansion.exitInfoboard') }}</span>
            </v-tooltip>-->
        </v-list-item-title>
        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + B`"
          position="bottom"
        />
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import { getTicketInfo, calculateTicketCounters } from "../../lib/wsMsg.js";
import { basicView } from "../../utils/privileges";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
export default {
  components: { CustomTT },
  data: () => ({
    state: store.state,
    setCurrentContentVisile: store.setCurrentContentVisile,
    ownUUID: store.state.ownUUID,
    userList: store.getProcessedUserList(),
  }),
  effector: {
    showCustomTT: hotkeysInfoStore,
  },
  mounted: function () {
    EventBus.$on("openInfoboardEvent", this.openInfoboard);
  },
  destroyed(){
    EventBus.$off("openInfoboardEvent", this.openInfoboard);
  },
  methods: {
    openInfoboard() {
      this.handleMyInfoboardView();
      if (this.amIAdmin) {
        this.handleNewInfoNote();
      }
    },
    noteIsOpen(note) {
      return (
        (note.targetTeams || []).length === 0 &&
        (note.targetDepartments || []).length === 0 &&
        (note.targetSections || []).length === 0
      );
    },
    handleNewInfoNote() {
      if (this.$route.path !== "/infoboard") {
        store.setCurrentContentVisile("infoboard", true, this.$router);
      }
      store.setShowNote(true, this.newNote(), true, false);
    },
    // Collect Note Data
    newNote() {
      return {
        id: this.calcID,
        title: this.noteTitle,
        date: this.todayDate,
        text: this.noteText,
        color: this.noteColor,
        long: this.longNote,
        completed: false,
        author: this.author,
        authorName: this.authorName,
        targetUsers: [],
      };
    },
    handleMyInfoboardView() {
      if (this.$route.path !== "/infoboard") {
        store.setCurrentContentVisile("infoboard", true, this.$router);
      } else if (this.$route.path == "/infoboard") {
        this.setCurrentContentVisile("home", true, this.$router);
        store.setShowHomeView(true);
      }
    },
    exitInfoBoard() {
      if (this.$route.path === "/infoboard")
        this.setCurrentContentVisile("", false, this.$router);
    },
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    getTotalNotes() {
      let everyNote = store.state.namespaceSettings.notes;
      let filteredNotes = [];
      let noteUsers = [];
      const currentDate = new Date();
      filteredNotes = everyNote.filter((note) => {
        return !note.activeUntil || new Date(note.activeUntil) > currentDate;
      });

      filteredNotes = filteredNotes.filter((note) => {
        const castToArray = (elem) => {
          if (typeof elem === undefined) return [];
          if (Array.isArray(elem)) return elem;
          return [elem];
        };
        return (
          this.amIAdmin ||
          this.noteIsOpen(note) ||
          note.author === this.ownUUID ||
          castToArray(note.targetUsers).includes(this.ownUUID) ||
          castToArray(note.targetSections).filter((section) => {
            return (
              (
                (this.userList[this.ownUUID] || {}).supervisorOfSections || []
              ).includes(section) ||
              ((this.userList[this.ownUUID] || {}).teamSections || []).includes(
                section
              )
            );
          }).length ||
          castToArray(note.targetDepartments).filter((department) => {
            return (
              (
                (this.userList[this.ownUUID] || {}).supervisorOfDepartments ||
                []
              ).includes(department) ||
              (
                (this.userList[this.ownUUID] || {}).teamDepartments || []
              ).includes(department)
            );
          }).length ||
          castToArray(note.targetTeams).filter((team) => {
            return (
              (
                (this.userList[this.ownUUID] || {}).supervisorOfTeams || []
              ).includes(team) ||
              (
                (this.userList[this.ownUUID] || {}).memberOfTeams || []
              ).includes(team)
            );
          }).length
        );
      });

      return filteredNotes.length || 0;
    },
    isBasicView() {
      return basicView();
    },
  },
};
</script>
<style scoped lang="scss">
.nBRadius {
  border-radius: 0px;
}
.titleGroup {
  color: white;
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}

.btnIn {
  position: absolute;
  right: 76px;
  top: 5px;
}
.btnOut {
  position: absolute;
  top: 5;
  right: 143px;
}
.btnAddInfo {
  position: absolute;
  right: 31px;
  top: 5px;
}
.boxactive {
  background-color: #0668778c;
}
</style>

