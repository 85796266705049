var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "595" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showLastChance,
        callback: function($$v) {
          _vm.showLastChance = $$v
        },
        expression: "showLastChance"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.2faActivation.lastChanceTitle")) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", { staticClass: "pt-2" }, [
            _c("div", { staticClass: "py-2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.2faActivation.checkBoxText")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-0 pr-2 footerModal footersBackground"
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "12", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.closeModal }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.saveEntry()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }