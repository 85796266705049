<template>
  <div
    style="back"
    :class="{
      'd-none': hideVideoContainer,
      maximizeScreen: true,
      maximizeScreenMobile: isMobile,
    }"
  >
    <div :class="{ sidebarCallWrapper: !showTimeLine, sidebarCallWrapperTimeline: showTimeLine}" class="sidebarCallWrapper">
      <SidebarCall
        v-if="maximizeScreen && hasPrivilege(ownUUID) && !isExternalUser"
        :maximizeScreen="maximizeScreen"
        :isConferenceCall="isConferenceCall"
        :getAmIModerator="true"
        :showAskAiUser="showAskAiUser"
        :removeParticipantPerson="removeParticipantPerson"
        :isMobile="isMobile"
        :showTimeLine="showTimeLine"
      />
      <SidebarExternalCall
        v-if="maximizeScreen && hasPrivilege(ownUUID) && isExternalUser"
        :maximizeScreen="maximizeScreen"
        :isConferenceCall="isConferenceCall"
        :getAmIModerator="true"
        :showAskAiUser="showAskAiUser"
        :removeParticipantPerson="removeParticipantPerson"
        :isMobile="isMobile"
        :showTimeLine="showTimeLine"
      />
    </div>
    <div class="w100 progressLinearBridge">
    <ProgressLinearBridge
      :amIModerator="!isGuestOrVisitor"
      :updateTimeForUser="updateTimeForUser"
      :setCallDuration="setCallDuration"
      :hangUpBridgeCall="hangUpBridgeCall"
      :redirectToStartView="redirectToStartView"
      :isConferenceCall="isConferenceCall"
      :rejectCall="rejectCall"
      v-if="showTimeLine"
    />
    </div>
    <div style="margin-left: 55px; margin-top: 59px; height:10%; overflow:auto" v-if="state.user.bridgeCallInfo.vofficeTranscript && showLeaveSession">
      <Transcription :showTimeLine="showTimeLine" :audioDisabled="audioDisabled"/>
    </div>
    <div class="callingToaster" v-if="amICalling.length || usersCalling.length">
      <div v-if="usersCalling.length">
        <div v-for="(data) in usersCalling" :key="data.user">
          <v-alert
            v-for="(participant) in data.calling"
            :key="participant"
            prominent
            color="white"
          >
            <v-row align="center">
              <v-col class="grow d-flex vColCallingToaster">
                <div class="waveCallingBridge">
                  <div class="dot" v-for="index in 3" :key="index"></div>
                </div>
                <div class="ml-2" :style="`color: black;`">{{ getNameForUuid(data.user) }} - {{ $t('components.remotePersonCall.calling')}} {{ getNameForUuid(participant) }}</div>
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </div>
      <div v-if="amICalling.length">
        <v-alert
          v-for="(participant) in amICalling"
          :key="participant"
          prominent
          color="white"
        >
          <v-row align="center">
            <v-col class="grow d-flex vColCallingToaster">
              <div class="waveCallingBridge">
                <div class="dot" v-for="index in 3" :key="index"></div>
              </div>
              <div class="ml-2" :style="`color: black;`">{{ $t('components.remotePersonCall.calling')}} {{ getNameForUuid(participant) }}</div>
            </v-col>
            <v-col class="shrink pt-4 pb-2">
              <v-btn
                class="buttonsCall"
                icon
                variant="text"
                density="comfortable"
                color="black"
                @click.prevent.stop="hangUpCalling(participant)"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  class="faPhoneRotate"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </div>
    <div :class="{ heightNoTimeline: !showTimeLine, heightTimeline: showTimeLine, callContainerNoSidebar: !hasPrivilege(ownUUID), callContainerSidebar: hasPrivilege(ownUUID), callContainerSidebarMobile: hasPrivilege(ownUUID) && isMobile }">
      <div id="video" ref="video" :style="`height: 100%; width: ${!hasPrivilege(ownUUID) ? '100%' : '99%'}; -ms-user-select: none; -moz-user-select: none; -webkit-user-select: none; user-select: none;`">
        <div v-if="!isMobile && frameMuted !== undefined" id="muteCall" :class="mutedBtnClass">
           <v-tooltip
            color="black"
            top
            nudge-top="25"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                large
                v-bind="props"
                class="custom-btn"
                icon
                variant="text"
                density="comfortable"
                color="white"
                @click="toggleMuteAudioFunction"
              >
                <font-awesome-icon
                  v-if="!frameMuted"
                  :icon="['far', 'volume-up']"
                  :style="{ fontSize: '20px' }"
                />
                <font-awesome-icon
                  v-else
                  :icon="['far', 'volume-slash']"
                  :style="{ fontSize: '20px', color: 'red' }"
                />
              </v-btn>
            </template>
            <span>{{
              !frameMuted ? $t("components.callsContent.turOffSound") : $t("components.callsContent.turnOnSound")
            }}</span>
          </v-tooltip>
        </div>
        <div v-if="!isMobile && showLeaveSession" id="leaveSession" :style="`position: absolute; bottom: 28px; right: 180px;`">
          <v-tooltip
            top
            color="black"
            nudge-top="25"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                :style="`width: 40px; height: 40px;`"
                large
                icon
                variant="text"
                density="comfortable"
                class="custom-btn"
                color="white"
                @click="toggleLeaveSession"
              >
                <font-awesome-icon
                  :icon="['fas', 'phone']"
                  :style="{ fontSize: '23px', color: 'red', transform: 'rotate(225deg)' }"
                />
              </v-btn>
            </template>
            <span>{{
              $t("generics.hangUp")
            }}</span>
        </v-tooltip>
        </div>
        <div v-if="showAiMenu && !isMobile && showAskAiUser" class="aiMenuOverlay"></div>
        <div v-if="!isMobile && showAskAiUser" id="askAiUser" :style="`position: absolute; bottom: 28px; right: 240px;`"> <!-- before right: 300px -->
          <div style="position: relative;">
            <v-menu
              v-click-outside="closeAiMenuClick"
              min-width="150"
              v-model="showAiMenu"
              offset-y
              :nudge-y="-5"
              :offset="[0, 220]"
              absolute
              submenu
              :close-on-click="false"
              :close-on-content-click="false"
              activator="parent">
                <v-list style="width: 102%;">
                  <!-- Open conversation with AI (chat) -->
                  <v-list-item class="pointer">
                    <v-list-item-title @click="openConversationAi">{{$t("components.callsContent.openConversation")}}</v-list-item-title>
                  </v-list-item>

                  <!-- Checkbox for Ai-Interpreter -->
                  <v-tooltip bottom>
                    <template #activator="{ props }">
                      <v-list-item  :key="updateKey" v-bind="props" class="pointer" @click="useAiAsInterpreter = !useAiAsInterpreter; updateKey++">
                        <v-list-item-title>
                          {{ useAiAsInterpreter && (!selectedSourceLanguageCode || !selectedDestinationLanguageCode) ?
                            $t("components.callsContent.iaAsInterpreter") + ' *'
                            :
                            $t("components.callsContent.iaAsInterpreter")
                            }}
                        </v-list-item-title>
                        <v-list-item-action end>
                          <v-checkbox 
                            hide-details
                            :model-value="useAiAsInterpreter" 
                            @update:model-value="useAiAsInterpreter = $event; updateKey++"
                          />
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>{{ $t("components.callsContent.aiAsInterpreterTooltip")}}</span>
                  </v-tooltip>

                  <!-- Submenu for Source Language -->
                  <v-list-item link :disabled="!useAiAsInterpreter">
                    <v-list-item-title :class="{'redColor': useAiAsInterpreter && !selectedSourceLanguageCode}">
                      {{useAiAsInterpreter && !selectedSourceLanguageCode ? 
                        $t("components.callsContent.sourceLanguage") + ' *' 
                        : 
                        $t("components.callsContent.sourceLanguage")
                      }}
                    </v-list-item-title>
                    <template v-slot:append>
                      <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                    </template>

                    <v-menu :open-on-focus="false" :close-on-click="false" :close-on-content-click="false" activator="parent" open-on-hover offset-x submenu :offset="[-40, 240]" max-height="500">
                      <v-list>
                        <!-- Main Language items -->
                        <v-list-item link
                          v-for="language in supportedLanguages"
                          :key="'source-' + language.code"
                          @click="setSourceLanguage(language.code)"
                        >
                          <v-list-item-title :class="{ 'font-weight-bold': language.code === selectedSourceLanguageCode }">
                            {{ language[`name${userLanguageCode}`] }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <!-- Extended Language items list -->
                        <v-list-item link
                          v-for="language in supportedLanguagesExtended"
                          :key="'source-' + language.code"
                          @click="setSourceLanguage(language.code)"
                        >
                          <v-list-item-title :class="{ 'font-weight-bold': language.code === selectedSourceLanguageCode }">
                            {{ language[`name${userLanguageCode}`] }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item>
                  
                  <!-- Destination Language Item -->
                  <v-list-item link :disabled="!useAiAsInterpreter">
                    <v-list-item-title :class="{'redColor': useAiAsInterpreter && !selectedDestinationLanguageCode}">
                      {{ useAiAsInterpreter && !selectedDestinationLanguageCode ? 
                        $t("components.callsContent.destinationLanguage") + ' *'
                        :
                        $t("components.callsContent.destinationLanguage")
                      }}
                    </v-list-item-title>
                    <template v-slot:append>
                      <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                    </template>

                    <v-menu :open-on-focus="false" :close-on-click="false" :close-on-content-click="false" activator="parent" open-on-hover submenu :offset="[-40, 240]" max-height="500">
                      <v-list>
                        <v-list-item link
                          v-for="language in supportedLanguages"
                          :key="'source-' + language.code"
                          @click="setDestinationLanguage(language.code)"
                        >
                          <v-list-item-title :class="{ 'font-weight-bold': language.code === selectedDestinationLanguageCode }">
                            {{ language[`name${userLanguageCode}`] }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                         <!-- Extended Language items list -->
                        <v-list-item link
                          v-for="language in supportedLanguagesExtended"
                          :key="'source-' + language.code"
                          @click="setDestinationLanguage(language.code)"
                        >
                          <v-list-item-title :class="{ 'font-weight-bold': language.code === selectedDestinationLanguageCode }">
                            {{ language[`name${userLanguageCode}`] }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item>

                  <!-- Stop talking button -->
                  <v-list-item class="pointer" v-if="!aiUserRecording">
                    <v-list-item-title @click="stopTalkingAi">{{$t("components.callsContent.stopTalking")}}</v-list-item-title>
                  </v-list-item>

                </v-list>
            </v-menu>
            <v-tooltip
              top
              color="black"
              nudge-top="25"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-if="!processingAiAudio"
                  v-bind="props"
                  :style="`width: 40px; height: 40px;`"
                  large
                  icon
                  variant="text"
                  density="comfortable"
                  class="custom-btn"
                  color="white"
                  @click.stop="toggleAskAiUser"
                  @contextmenu.prevent="showAiMenuClick($event)"
                >
                  <img
                    :style="`width: 26px; height: 26px;`"
                    v-if="aiUserRecording"
                    class="customIcons"
                    src="/img/aiStopIcon.svg"
                  />
                  <img
                    :style="`width: 26px; height: 26px;`"
                    v-else
                    class="customIcons"
                    src="/img/aiStartIcon.svg"
                  />
                </v-btn>
                <v-btn
                  v-else
                  v-bind="props"
                  :style="`width: 40px; height: 40px;`"
                  large
                  icon
                  variant="text"
                  density="comfortable"
                  class="custom-btn"
                  color="white"
                  @click.stop="stopTalkingAi"
                  @contextmenu.prevent="showAiMenuClick($event)"
                >
                  <img
                    :style="`width: 26px; height: 26px;`"
                    class="customIcons"
                    src="/img/aiStopProcesingIcon.svg"
                  />
                </v-btn>
              </template>
              <span>{{
                $t("components.sidebar.askTo", [
                this.showAskAiUser,
              ])
              }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <FinishCallModal
      v-if="showFinishCallModal"
      :showFinishCallModal="showFinishCallModal"
      :closeModal="showCloseFinishCallModal"
      :rejectCall="rejectCall"
    />
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import debounce from "lodash.debounce";
import isEqual from "lodash.isequal";
import { webLicensedBaseFeatures } from "../../../../sharedsrc/licensedFeatures";
import store, { syncedUserState, EventBus } from "../../../store";
import DigitalSambaEmbedded from "@digitalsamba/embedded-sdk";
import Deferred from "../../../jitsi/modules/util/Deferred";
import {
  bridgeCallCreateRoom,
  bridgeCallGetAccessToken,
  bridgeCallGetAllChatMessages,
  bridgeCallSendChatMessage,
  getDirectCallInviteLink
} from "../../../lib/wsMsg";
import { aDelay } from "../../../lib/asyncUtil";
import { setCallChatEvent, callChatStore } from "../../../effector/callChat";
import SidebarCall from "./sidebarCall/sidebarCall.vue";
import SidebarExternalCall from "./sidebarCall/sidebarExternalCall.vue"
import { isConferenceCall, prepareDataForVirtualBackground, amInAStaticRoom, wsCallStartBridgeCall } from "../../../utils/calls";
import { isGuestOrVisitor } from "../../../utils/routerAcl.js";
import ProgressLinearBridge from "../../progressLinearBridge/progressLinearBridge.vue";
import { wsCall } from "../../../lib/wsConnect";
import { hasPrivilege, isVisitor, isWaitingRoomUser } from "../../../utils/privileges";
import FinishCallModal from  "../../modal/finishCallModal.vue";
import { allUsersState, receivedSpecificUserStateEvent, specificUsersState } from '../../../effector/users';
import { isMobile } from "../../../lib/mobileUtil";
import { joinSambaRoom } from "../../../utils/staticRoom";
import { isAiUser, parseJSON } from "../../../utils/basicFunctions";
import Transcription from "./transcription.vue"
// import { setQualityVotingModalEvent } from '../../../effector/modals';
export default {
  components: { SidebarCall, ProgressLinearBridge, FinishCallModal, SidebarExternalCall, Transcription },
  data() {
    const effector = {
      chatMessages: callChatStore,
      allUsersState,
      specificUsersState,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      audioDisabled: false,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      maximizeScreen: false,
      currentCallTime: undefined,
      remoteParticipants: [],
      spaceShortcutCallAcceptTimeout: null,
      showFinishCallModal: false,
      frameMuted: undefined,
      showLeaveSession: false,
      loadingAskAiUser: null,
      showAskAiUser: null,
      mySambaRole: '',
      aiUserRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      processingAiAudio: false,
      setCurrentContentVisile: store.setCurrentContentVisile,
      showAiMenu: false,
      xAiMenu: 0,
      yAiMenu: 0,
      autoCloseTimer: null,
      aiChatConversationOpen: false,
      usersCalling: [],
      botIsListening: false,
      isGuestOrVisitor: isGuestOrVisitor(),
      showSourceLanguageMenu: false,
      userLanguageCode: this.$locale.current().toUpperCase() || 'EN',
      selectedSourceLanguageCode: undefined,
      selectedDestinationLanguageCode: undefined,
      supportedLanguages: store.state.supportedLanguages.standardLanguages,
      supportedLanguagesExtended: store.state.supportedLanguages.extendedLanguages,
      updateKey: 0,
      // Effector
      ...effector,
    };
  },
  watch: {
    aiUserRecording: {
      handler: function (value) {
        if(this.isMobile){
          EventBus.$emit("sambaAiUserRecording", value);
        }
      },
    },
    processingAiAudio: {
      handler: function (value) {
        if(this.isMobile){
          EventBus.$emit("sambaProcessingAiAudio", value);
        }
      },
    },
    frameMuted: {
      handler: function (value) {
        if(this.isMobile){
          EventBus.$emit("sambaFrameMuted", value);
        }
      },
    },
    currentCallTime: {
      handler: function (currentTime) {
        const legacy = false;
        const message = legacy
          ? this.$sanitize(currentTime)
          : JSON.stringify({ type: 'timeline', time: this.$sanitize(currentTime) })
        bridgeCallSendChatMessage(message);
      },
    },
    chatMessages: {
      handler: function (message) {
        const callUUID = this.getCallUUID;
        const lastMessage = [...message].reverse().find((elem) => {
          const { type } = parseJSON(elem.text) || {};
          const legacy = !type && typeof elem.text === 'string' && parseInt(elem.text, 10);
          return legacy || type === 'timeline';
        });
        if (lastMessage) {
          const { time } = parseJSON(lastMessage.text) || {};
          const legacy = !time && typeof lastMessage.text === 'string' && parseInt(lastMessage.text, 10);
          const callDuration = parseInt(time, 10) || legacy;
          store.changeCallDurationMsBridgeStream(callUUID, callDuration);
        }
      }
    },
    remoteParticipants: {
      immediate: true,
      handler: debounce(function () {
        if (!this.hasPrivilege(this.ownUUID) || this._destroyed || this.remoteParticipants?.length || this.amICalling?.length) return;
        if (!this.getIsAiUser(this.ownUUID) && (this.isConferenceCall || this.isSambaStaticRoom)) return;
        console.log("hang up as no remote participants", { _notMyCall: !!this._notMyCall, _isSane: !this._isSaneOrAbortThrow(true) });
        if (this._notMyCall && !this._isSaneOrAbortThrow(true)) {
          this.rejoinMeetingRoomAfterLeave();
          this.rejectCall();
        }
      }, 50000 /* 50 seconds */)
    },
    specificUsersState: {
      handler: function (event) {
        if (!event) return; // Exit early if event is not defined
        if (!this.hasPrivilege(this.ownUUID)) return; // The overlay should only be displayed to other users
        const callUUID = this.getCallUUID; // Get the call UUID
        const usersInCallEvents = this.filterSpecificUsersEvents(event, callUUID); // Filter specific user events based on call UUID
        const usersWithCalling = []; // Initialize an array to store users with calling info
        // Iterate over each state in the event
        Object.entries(usersInCallEvents).forEach(([uuid, state]) => {
          // Check if the state has valid user and calling data
          if (state.user &&
            state.user.bridgeCallInfo &&
            Array.isArray(state.user.bridgeCallInfo.calling) &&
            state.user.bridgeCallInfo.calling.length > 0) {
            // Filter out ownUUID from the calling array
            const filteredCalling = state.user.bridgeCallInfo.calling.filter(uuid => uuid !== this.ownUUID && uuid in this.state.group);
            if (filteredCalling.length) {
              // Create an object with the uuid and the calling array
              const userWithCalling = {
                user: uuid,
                calling: filteredCalling,
              };
              // Add the object to the array of users with calls
              usersWithCalling.push(userWithCalling);
            }
          }
        });
        // Set the derived users with calling info to the component's data property
        this.usersCalling = usersWithCalling;
      }
    },
    amICalling: {
      deep: true,
      immediate: true,
      handler: function (users) {
        if (users.length) {
          if (this._destroyed) return;
          const callUUID = this.getCallUUID;
          if (!this._receivedUserEventUnwatch) {
            this._receivedUserEventUnwatch = receivedSpecificUserStateEvent.watch((payload) => {
              if (this._destroyed) return (this._receivedUserEventUnwatch && this._receivedUserEventUnwatch());
              const [uuid, state] = payload;
              // https://gitlab.ra-micro.de/devcups/voffice/-/issues/256
              if (
                users.includes(uuid) && this.getCallUUID &&
                state?.user?.bridgeCallInfo?.callUUID &&
                state?.user?.bridgeCallInfo?.calling?.length &&
                Array.isArray(state.user.bridgeCallInfo.calling) &&
                state.user.bridgeCallInfo.calling.includes(this.ownUUID) &&
                this.getCallUUID < state.user.bridgeCallInfo.callUUID
              ) {
                console.log("both called each other at the same time", { myCallUUID: this.getCallUUID, theirCallUUID: state.user.bridgeCallInfo.callUUID });
                this.hangUpCalling(uuid);
                this.rejectCall();
                this._destroyedPromise
                  .then(() => syncedUserState())
                  .then(() => this.$nextTick())
                  .then(() => !this.state.user.bridgeCallInfo && wsCallStartBridgeCall(
                    uuid,
                    this.ownUUID,
                    { ...state.user.bridgeCallInfo, calling: [], isAudioOnly: true, directCallInvite: true },
                    true,
                    true
                  ));
                return;
              }
              // https://gitlab.ra-micro.de/devcups/voffice/-/issues/176
              if (!state || state.connected || !users.includes(uuid) || this.getIsAiUser(uuid)) return;
              this.hangUpCalling(uuid);
              this.onRejectBridgeCallEvent({callUUID});
              const dataInfoModal = {
                show: true,
                header: this.$t("generics.info"),
                body: this.$t("components.callsContent.userDisconnected", [this.getNameForUuid(uuid)]),
              };
              this.setInfoModal(dataInfoModal);
            });
          }
          users.forEach(uuid => {
            if(this.getIsAiUser(uuid)){
              if (uuid && callUUID) {
                if (this._joinAiUserTimeout) clearTimeout(this._joinAiUserTimeout);
                this._joinAiUserTimeout = setTimeout(async () => {
                  await (this._mountedPromise || this._createRoomPromise);
                  if (this._joinAiUserTimeout) clearTimeout(this._joinAiUserTimeout);
                  if (!this._destroyed && this.amICalling && this.amICalling.includes(uuid)) {
                    store.removeCallingUser(uuid, { callUUID });
                    await this.joinAiUser(uuid);
                  }
                }, 300);
              }
            }
          });
        } else if (this._receivedUserEventUnwatch) {
          this._receivedUserEventUnwatch();
          this._receivedUserEventUnwatch = null;
        }
      }
    }
  },
  created() {
    const remoteStreamsKey = this.getCallUUID || Object.keys(store.state.remoteBridgeStreams)[0];
    if (!remoteStreamsKey) return console.error(new Error("Unexpected state encountered: remoteStreamsKey missing."));
    let remoteStream = store.state.remoteBridgeStreams[remoteStreamsKey];
    if (!remoteStream) return console.error(new Error("Unexpected state encountered: remoteStream missing."));
    const { roomId, roomGuid } = remoteStream;
    if (remoteStream.initiator && remoteStream.initiator !== this.ownUUID) {
      this._notMyCall = true; // hang up when everyone leaves
    }
    /** Prevents race conditions in async calls, by asserting premises still hold true */
    const isSaneOrAbortThrow = this._isSaneOrAbortThrow = (fail = false) => {
      if (this._destroyed) return true; // Abort gracefully if destroyed
      if (Object.keys(store.state.remoteBridgeStreams)[0] !== remoteStreamsKey) {
        if (fail) {
          if (typeof fail === 'function') fail(new Error("Inconsistent remote bridge stream."));
          return true; // Abort gracefully if fail is truthy
        }
        throw new Error("Inconsistent remote bridge stream.");
      }
      remoteStream = store.state.remoteBridgeStreams[remoteStreamsKey];
      return false;
    };
    const initialSync = syncedUserState()
      .catch(() => {})
      .then(async () => {
        if (isSaneOrAbortThrow(true)) return; // Abort gracefully to avoid async throw
        const messages = await bridgeCallGetAllChatMessages();
        setCallChatEvent(messages);
      });
    if (roomId && roomGuid) {
      this._createRoomPromise = initialSync.then(() => {
        if (isSaneOrAbortThrow()) return;
        const role = this.state.user.inBridgeCallListener ? 'attendee' : ''; // empty value leaves decision to backend
        this.mySambaRole = role;
        return bridgeCallGetAccessToken(roomId, role).then(
          (token) => {
            if (isSaneOrAbortThrow()) return;
            return { roomId, roomGuid, token };
          }
        );
      });
    } else if (!roomId && !roomGuid) {
      if (remoteStream.initiator && remoteStream.initiator !== this.ownUUID) {
        this._createRoomPromise = initialSync.then(() => {
          if (isSaneOrAbortThrow()) return;
          let done = (_val) => {}, fail = (_err) => {};
          let maxRetries = 4;
          const promise = new Promise((resolve, reject) => (done = resolve, fail = reject));
          const interval = setInterval(() => {
            maxRetries--;
            if (isSaneOrAbortThrow(fail)) {
              clearInterval(interval);
              return done();
            }
            const bridgeCallInfo = store.state.group[remoteStream.initiator]?.user?.bridgeCallInfo;
            if (bridgeCallInfo && bridgeCallInfo.roomId && bridgeCallInfo.roomGuid) {
              clearInterval(interval);
              const { roomId, roomGuid } = bridgeCallInfo;
              const role = this.state.user.inBridgeCallListener ? 'attendee' : ''; // empty value leaves decision to backend
              this.mySambaRole = role;
              return bridgeCallGetAccessToken(roomId, role).then(
                (token) => {
                  if (isSaneOrAbortThrow(fail)) return done();
                  store.setRemoteBridgeStream(remoteStreamsKey, { ...remoteStream, roomId, roomGuid });
                  return done({ roomId, roomGuid, token });
                },
                fail
              );
            } else if ((maxRetries < 0 && !bridgeCallInfo) || Boolean(bridgeCallInfo.roomId) !== Boolean(bridgeCallInfo.roomGuid)) {
              clearInterval(interval);
              if (isSaneOrAbortThrow(fail)) return done();
              return fail(new Error(`Initiator ${remoteStream.initiator} lacks remote bridge stream.`));
            }
          }, 300);
          return promise;
        });
      } else {
        this._createRoomPromise = initialSync.then(() => {
          if (isSaneOrAbortThrow()) return;
          return bridgeCallCreateRoom(remoteStreamsKey).then(
            (result) => {
              if (isSaneOrAbortThrow()) return;
              const { roomId, roomGuid, token } = result;
              store.setRemoteBridgeStream(remoteStreamsKey, { ...remoteStream, roomId, roomGuid });
              return { roomId, roomGuid, token };
            }
          );
        });
      }
    } else {
      console.error(new Error("Unexpected room state encountered."));
    }
  },
  mounted() {
    if (!this._createRoomPromise) return console.error("Unexpected state encountered: _createRoomPromise missing.");
    window.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    window.addEventListener("beforeunload", this.onBeforeUnload);
    EventBus.$on("reject_bridge_call", this.onRejectBridgeCallEvent);
    this._mountedPromise = this._createRoomPromise.then((result) => {
      if (!this.$refs.video || this._isSaneOrAbortThrow(true) || this._destroyed) throw new Error("Unexpected state encountered: _createRoomPromise delayed.");
      const { roomId, roomGuid, token } = result;
      const parentElement = this.$refs.video;
      const roomName = roomGuid;
      // TODO: Add equivalent of jvbOptions for samba to get the domain (or team name)
      const roomUrl = `https://voffice.digitalsamba.com/${roomName}`;
      // https://docs.digitalsamba.com/reference/sdk/digitalsambaembedded-class
      const mediaDevices = {
        videoinput: store.state.persisted.mediaDeviceSetup.videoDeviceId,
        audioinput: store.state.persisted.mediaDeviceSetup.audioDeviceId,
        audiooutput: store.state.persisted.mediaDeviceSetup.audioOutputId,
      };
      Object.keys(mediaDevices).forEach(key => mediaDevices[key] === undefined && delete mediaDevices[key]);
      let audioEnabled = this.state.user.userSettings.audioCallOn || false;
      let speakerEnabled = true;
      let videoEnabled = !this.getIsAudioOnly && (this.state.user.userSettings.videoCameraCallOn || false);
      if (this.isSambaStaticRoom) {
        const storageAudioDisabled = sessionStorage.getItem('audioDisabled');
        const storageSpeakerDisabled = sessionStorage.getItem('speakerDisabled');
        const storageVideoDisabled = sessionStorage.getItem('videoDisabled');
        if (storageAudioDisabled && storageAudioDisabled === 'true'){
          this.audioDisabled = true;
          audioEnabled = false;
        }
        if (storageSpeakerDisabled && storageSpeakerDisabled === 'true') speakerEnabled = false;
        if (storageVideoDisabled && storageVideoDisabled === 'true') videoEnabled = false;
      }
      if (isAiUser(this.ownUUID)) {
        audioEnabled = true;
        videoEnabled = true;
        document.sendAnswerTextCallback = (text) => bridgeCallSendChatMessage(JSON.stringify({ type: 'ai', text }));
      }
      let languageCode = this.$locale.current().toLowerCase();
      // Select user language as default for source language of Peter as interpreter.
      switch (languageCode){
        case 'de':
          this.selectedSourceLanguageCode = 'de-DE';
        break;
        case 'es':
          this.selectedSourceLanguageCode = 'es-ES';
        break;
        case 'en':
          this.selectedSourceLanguageCode = 'en-US';
        break;
      }
      if (languageCode === "de") languageCode = "df"; // Deutsch (Förmlich) language - code of it is "df" for SDK and API
      const sambaFrame = DigitalSambaEmbedded.createControl({
        url: roomUrl,
        token,
        root: parentElement,
        roomSettings: {
          appLanguage: languageCode,
          requireRemoveUserConfirmation: false,
          mediaDevices,
          audioEnabled,
          videoEnabled,
          muteFrame: !speakerEnabled,
          ...(this.virtualBackground ? { virtualBackground: this.virtualBackground } : {}),
        }
      });
      const instanceProperties = {
        frameAttributes: { style: "border:0;height:100%;width:100%;" },
        reportErrors: true,
      };
      const { roomSettings } = sambaFrame.initOptions;
      console.log("digital samba embedded create control", { roomId, roomGuid, roomSettings });
      this.frameMuted = roomSettings.muteFrame;
      sambaFrame.load(instanceProperties);
      sambaFrame.on("*", this.onAnyEvent);
      sambaFrame.on("activeSpeakerChanged", this.activeSpeakerChanged);
      sambaFrame.on("speakerStoppedTalking", this.speakerStoppedTalking);
      sambaFrame.on("mediaDeviceChanged", this.mediaDeviceChanged);
      sambaFrame.on("roomStateUpdated", this.roomStateUpdated);
      sambaFrame.on("userJoined", this.onUserJoined);
      sambaFrame.on("userLeft", this.onUserLeft);
      sambaFrame.on("usersUpdated", this.onUsersUpdated);
      sambaFrame.on("audioEnabled", this.onAudioEnabled);
      sambaFrame.on("audioDisabled", this.onAudioDisabled);
      sambaFrame.on("videoEnabled", this.onVideoEnabled);
      sambaFrame.on("videoDisabled", this.onVideoDisabled);
      sambaFrame.on("roleChanged", this.onRoleChanged);
      sambaFrame.addFrameEventListener("keyup", "window", this.onKeyUp);
      this._sambaFrame = sambaFrame;
      setTimeout(() => {
        this.setCurrentContentVisile("", false, this.$router);
      }, 50);
      // if (!this.isMobile) {
      this.setMaximizeScreen(true);
      // }
    }).catch((err) => {
      console.error("Unexpected error:", err, { _notMyCall: !!this._notMyCall, _isSane: !this._isSaneOrAbortThrow(true) });
      if (this._destroyed) return;
      this.rejectCall();
    });
    EventBus.$on('aiChatStatus', this.setStatusAiChat);
    if (this.isExternalUser) {
      const data = { inCall: true };
      const event = new CustomEvent('syncExternalCall', { detail: data });
      window.parent?.document?.dispatchEvent(event);
    }
    if(this.isMobile){
      EventBus.$on("toggleMuteAudioFunctionFromSidebarCall", this.toggleMuteAudioFunction);
      EventBus.$on("toggleLeaveSessionFromSidebarCall", this.toggleLeaveSession);
      EventBus.$on("toggleAskAiUserFromSidebarCall", this.toggleAskAiUser);
      EventBus.$on("stopTalkingAiFromSidebarCall", this.stopTalkingAi);
    }
  },
  beforeUnmount() {
    this._destroyed = true;
    if (this._preventAloneInCallTimeout) clearTimeout(this._preventAloneInCallTimeout);
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    EventBus.$off("reject_bridge_call", this.onRejectBridgeCallEvent);
    EventBus.$off("toggleMuteAudioFunctionFromSidebarCall", this.toggleMuteAudioFunction);
    EventBus.$off("toggleLeaveSessionFromSidebarCall", this.toggleLeaveSession);
    EventBus.$off("toggleAskAiUserFromSidebarCall", this.toggleAskAiUser);
    EventBus.$off("stopTalkingAiFromSidebarCall", this.stopTalkingAi);
    if (isAiUser(this.ownUUID)) delete document.sendAnswerTextCallback;
    if (this._sambaFrame) {
      /** @type DigitalSambaEmbedded */
      const sambaFrame = this._sambaFrame;
      // sambaFrame.endSession(); // Ends the session for everyone. All users are immediately removed from the room and the call ends
      sambaFrame.leaveSession(); // Leaves the session, so the user is no longer participating in the call
      sambaFrame.off("*", this.onAnyEvent);
      sambaFrame.off("activeSpeakerChanged", this.activeSpeakerChanged);
      sambaFrame.off("speakerStoppedTalking", this.speakerStoppedTalking);
      sambaFrame.off("mediaDeviceChanged", this.mediaDeviceChanged);
      sambaFrame.off("roomStateUpdated", this.roomStateUpdated);
      sambaFrame.off("userJoined", this.onUserJoined);
      sambaFrame.off("userLeft", this.onUserLeft);
      sambaFrame.off("usersUpdated", this.onUsersUpdated);
      sambaFrame.off("audioEnabled", this.onAudioEnabled);
      sambaFrame.off("audioDisabled", this.onAudioDisabled);
      sambaFrame.off("videoEnabled", this.onVideoEnabled);
      sambaFrame.off("videoDisabled", this.onVideoDisabled);
      sambaFrame.off("roleChanged", this.onRoleChanged);
      sambaFrame.removeFrameEventListener("keyup", "window", this.onKeyUp);
    }
    if (!this.isSambaStaticRoom && (this.isVisitor(this.ownUUID) || this.isWaitingRoomUser(this.ownUUID))) {
      store.setEndCallDateVisitor(); // Set the call end time for visitors in their store
    }
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.isExternalUser) {
      const data = { inCall: false };
      const event = new CustomEvent('syncExternalCall', { detail: data });
      window.parent?.document?.dispatchEvent(event);
    }
  },
  unmounted() {
    window.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    this.redirectToStartView();
    if (this._receivedUserEventUnwatch) {
      this._receivedUserEventUnwatch();
      this._receivedUserEventUnwatch = null;
    }
    if (this._destroyedPromise && typeof this._destroyedPromise.resolve === 'function') {
      this._destroyedPromise.resolve();
    }
    this._destroyedPromise = Promise.resolve();
    EventBus.$off('aiChatStatus', this.setStatusAiChat);
  },
  methods: {
    setSourceLanguage(code) {
      this.selectedSourceLanguageCode = code;
    },
    setDestinationLanguage(code) {
      this.selectedDestinationLanguageCode = code;
    },
    removeParticipantPerson(person) {
      if (person?.uuid) {
        const participant = this.remoteParticipants.find(({ externalId }) => externalId === person.uuid);
        if (participant?.id && this._sambaFrame) {
          this._sambaFrame.removeUser(participant.id);
        }
      }
    },
    filterSpecificUsersEvents(event, callUUID) {
      const filteredEntries = Object.entries(event).filter(([uuid, state]) => {
        return state?.user &&
          state.user.bridgeCallInfo &&
          state.user.bridgeCallInfo.callUUID === callUUID &&
          state.user.uuid !== this.ownUUID;
      });
      // Convert the filtered entries back into an object
      const filteredEvent = Object.fromEntries(filteredEntries);
      return filteredEvent;
    },
    setStatusAiChat(status) {
      this.aiChatConversationOpen = status;
    },
    openConversationAi() {
      if(this.aiChatConversationOpen){
        EventBus.$emit("closeAiPanel");
      } else {
        EventBus.$emit("openAiPanel");
      }
      this.showAiMenu = false;
    },
    stopTalkingAi() {
      fetch(this.aiApiBaseUrl + '/stop_response', {
        method: 'GET',
      })
      .then(response => response.json())
      .then(data => {
        console.debug('Audio stopped successfully:', data);
        this.processingAiAudio = false;
        this.aiUserRecording = false;
        this.showAiMenu = false;
      })
      .catch((error) => {
        console.error('stopTalkingAi Error:', error);
        this.showAiMenu = false;
      });
    },
    startAutoCloseTimer() {
      // Function to start the auto-close timer
      this.resetAutoCloseTimer(); // Reset any previous timer before starting a new one

      // Auto-close disabled for ticket #629
      // this.autoCloseTimer = setTimeout(() => {
      //   this.closeAiMenuClick(); // Close the menu
      // }, 5000); // After 5 seconds
    },
    resetAutoCloseTimer() {
      // Function to clear the auto-close timer
      if (this.autoCloseTimer) {
        clearTimeout(this.autoCloseTimer);
        this.autoCloseTimer = null;
      }
    },
    closeAiMenuClick() {
        this.resetAutoCloseTimer();
        this.showAiMenu = false;
    },
    showAiMenuClick(e){
      e.preventDefault()
        this.showAiMenu = false
        this.xAiMenu = (e.clientX + 30)
        this.yAiMenu = e.clientY
        this.$nextTick(() => {
          this.showAiMenu = true
          this.startAutoCloseTimer();
        })
    },
    toggleAskAiUser() {
      const useWebSpeech = this.state.namespaceSettings.useWebSpeech && !this.isMobile;
      this.aiUserRecording = !this.aiUserRecording;
      if ('webkitSpeechRecognition' in window && (this.isConfidentialAi || useWebSpeech || this.botIsListening)) {
        console.debug('webkitSpeechRecognition is available.');
        if (!this.aiUserRecording) {
          this.stopSpeechRecognition();
        } else {
          this.startSpeechRecognition();
        }
      } else {
        if (this.isConfidentialAi) {
          // Show Modal only for Chrome based browsers
          const dataInfoModal = {
            show: true,
            header: this.$t("generics.info"),
            body: this.$t("components.callsContent.onlyChromeFunction"),
          };
          this.setInfoModal(dataInfoModal);
          this.aiUserRecording = false;
          return;
        }
        console.debug('webkitSpeechRecognition is not activated/supported in this browser.');
        // old start/stop recording
        if (!this.aiUserRecording) {
          this.stopRecording();
        } else {
          this.startRecording();
        }
      }
    },
    uploadAudioToAiUser(audioBlob) {
      this.processingAiAudio = true;
      const formData = new FormData();
      formData.append('audio', audioBlob);
      fetch(this.aiApiBaseUrl + '/upload_audio', {
          method: 'POST',
          body: formData,
      })
      .then(response => response.json())
      .then(data => {
        console.debug('Audio uploaded successfully:', data);
        const { success, question: text } = data;
        if (success && text) bridgeCallSendChatMessage(JSON.stringify({ type: 'ai', text }));
        this._aiSpeakingTimeout && clearTimeout(this._aiSpeakingTimeout);
        this._aiSpeakingTimeout = setTimeout(() => {
          this.processingAiAudio = false;
        }, 5000); // Kai's request, wait 5s more with the loader
      })
      .catch((error) => {
        console.error('uploadAudioToAiUser Error:', error);
        this.processingAiAudio = false;
      });
    },
    translateAudioByAi(audioBlob) {
      this.processingAiAudio = true;
      const formData = new FormData();
      formData.append('audio', audioBlob);
      formData.append('sourceLang', this.selectedSourceLanguageCode);
      formData.append('destinationLang', this.selectedDestinationLanguageCode);
      fetch(this.aiApiBaseUrl + '/translate_audio', {
          method: 'POST',
          body: formData,
      })
      .then(response => response.json())
      .then(data => {
        console.debug('Audio uploaded successfully:', data);
        const { success, question: text } = data;
        if (success && text) bridgeCallSendChatMessage(JSON.stringify({ type: 'ai', text }));
        this._aiSpeakingTimeout && clearTimeout(this._aiSpeakingTimeout);
        this._aiSpeakingTimeout = setTimeout(() => {
          this.processingAiAudio = false;
        }, 5000); // Kai's request, wait 5s more with the loader
      })
      .catch((error) => {
        console.error('uploadAudioToAiUser Error:', error);
        this.processingAiAudio = false;
      });
    },
    translateTextAtAi(textForAi, selectedSourceLanguageCode, selectedDestinationLanguageCode) {
      this.processingAiAudio = true;
      // When this.useAiAsInterpreter, we must use another Api (translate_text)
      fetch(this.aiApiBaseUrl + '/translate_text', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({text: textForAi, sourceLang: selectedSourceLanguageCode, destinationLang: selectedDestinationLanguageCode}),
      })
      .then(response => response.json())
      .then(data => {
        console.debug('Text uploaded successfully:', data);
        const { success, question: text } = data;
        if (success && text) bridgeCallSendChatMessage(JSON.stringify({ type: 'ai', text }));
        this.aiUserRecording = false;
        this._aiSpeakingTimeout && clearTimeout(this._aiSpeakingTimeout);
        this._aiSpeakingTimeout = setTimeout(() => {
          this.processingAiAudio = false;
        }, 5000); // Kai's request, wait 5s more with the loader
      })
      .catch((error) => {
        console.error('uploadTextToAiUser Error:', error);
        this.processingAiAudio = false;
        this.aiUserRecording = false;
      });
    },
    uploadTextToAiUser(textForAi) {
      this.processingAiAudio = true;
      // When this.useAiAsInterpreter, we must use another Api (translate_text)
      fetch(this.aiApiBaseUrl + '/upload_text', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({text: textForAi, sourceLang: this.selectedSourceLanguageCode, destinationLang: this.selectedDestinationLanguageCode}),
      })
      .then(response => response.json())
      .then(data => {
        console.debug('Text uploaded successfully:', data);
        const { success, question: text } = data;
        if (success && text) bridgeCallSendChatMessage(JSON.stringify({ type: 'ai', text }));
        this.aiUserRecording = false;
        this._aiSpeakingTimeout && clearTimeout(this._aiSpeakingTimeout);
        this._aiSpeakingTimeout = setTimeout(() => {
          this.processingAiAudio = false;
        }, 5000); // Kai's request, wait 5s more with the loader
      })
      .catch((error) => {
        console.error('uploadTextToAiUser Error:', error);
        this.processingAiAudio = false;
        this.aiUserRecording = false;
      });
    },
    stopSpeechRecognition() {
      if (!this.recognition) {
        return;
      }
      this.recognition.stop();
      this.aiUserRecording = false;
    },
    stopRecording() {
       if (!this.mediaRecorder) {
        return;
      }
      this.mediaRecorder.stop();
      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav; codecs=opus' });
        // Clean up the chunks array
        this.audioChunks = [];
        // As soon as the media recorder stops, we upload the audio
        if (this.useAiAsInterpreter && this.selectedSourceLanguageCode && this.selectedDestinationLanguageCode){
          this.translateAudioByAi(audioBlob);
        } else {
          this.uploadAudioToAiUser(audioBlob);
        }
        if (this._audioRecordingStream) {
          this._audioRecordingStream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };
    },
    startSpeechRecognition() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && 'webkitSpeechRecognition' in window) {
        try {
          if(!this.botIsListening){
            // Function to handle speech recognition and submission in one step
            console.debug("Starting speech recognition...");
            // eslint-disable-next-line no-undef
            const recognition = this.recognition = new webkitSpeechRecognition(); // Use SpeechRecognition if using Chrome
            const localeLang = this.$locale.current();
            let langForRecognition = (localeLang == 'de' || localeLang == 'es') ? `${localeLang}-${localeLang.toUpperCase()}` : 'en-US';
            if (this.useAiAsInterpreter && this.selectedSourceLanguageCode){
              langForRecognition = this.selectedSourceLanguageCode;
            }
            recognition.lang = langForRecognition;
            recognition.continuous = true;
            recognition.interimResults = false;
            recognition.maxAlternatives = 1;
            let finalTranscript = ''; // Store the final transcript

            recognition.onresult = (event) => {
              for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                  finalTranscript += event.results[i][0].transcript; // Append final results
                }
              }
              // recognition.stop();
              // const transcript = event.results[0][0].transcript;
              // console.debug("Speech recognized:", transcript);
              // this.uploadTextToAiUser(transcript); // Automatically submit the recognized text
            };
            recognition.onerror = (event) => {
              console.error("Speech recognition error:", event.error, (event.message ? "\nAdditional information:" : ""), event.message);
              // if (event.error !== "no-speech") {
              this.aiUserRecording = false; // Re-enable the button if there is an error
              this.botIsListening = false;
              recognition.stop(); // Explicitly stop recognition on error
              // }
            };
            recognition.onend = () => {
              console.debug("Speech recognition ended");
              // Upload or process after stopping
              if (finalTranscript) {
                console.debug("Final Speech recognized:", finalTranscript);
                recognition.stop();
                if (this.useAiAsInterpreter && this.selectedSourceLanguageCode && this.selectedDestinationLanguageCode){
                  this.translateTextAtAi(finalTranscript, this.selectedSourceLanguageCode, this.selectedDestinationLanguageCode);
                } else {
                  this.uploadTextToAiUser(finalTranscript);
                }
              }
              this.botIsListening = false;
            };
            recognition.start();
            this.botIsListening = true;
          } else {
            this.recognition.stop(); // Stopping will trigger onend where the processing happens
            this.botIsListening = false;
          }
        } catch (error) {
          console.error("Error initializing speech recognition:", error);
        }
      } else {
        console.error('startSpeechRecognition: webkitSpeechRecognition not supported on your browser!');
      }
    },
    async startRecording() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const audioSource = store.state.persisted.mediaDeviceSetup.audioDeviceId;
          let audioConstraint = audioSource ? { deviceId: { exact: audioSource } } : true;
          const stream = this._audioRecordingStream = await navigator.mediaDevices.getUserMedia({ audio: audioConstraint }).catch(
            (err) => {
              console.warn('startRecording: Error accessing the microphone:', err);
              return navigator.mediaDevices.getUserMedia({ audio: true });
            }
          );
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();
          this.mediaRecorder.ondataavailable = event => {
            this.audioChunks.push(event.data);
          };
        } catch (err) {
          console.error('startRecording: Error accessing the microphone:', err);
        }
      } else {
        console.error('startRecording: getUserMedia not supported on your browser!');
      }
    },
    async joinAiUser(uuid) {
      this.loadingAskAiUser = store.getNameForUuid(uuid);
      const link = await getDirectCallInviteLink(true);
      await this.$nextTick(); // Wait for computed to update
      const apiUrl = this.aiApiBaseUrl + '/join_assistant_by_url';
      const apiOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: link,
        })
      };
      return await fetch(apiUrl, apiOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response;
        })
        .catch(error => {
          console.error('Fetch error:', error.message);
        });
    },
    getIsAiUser(uuid){
      return isAiUser(uuid);
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (
          event.target.nodeName !== "TEXTAREA" &&
          event.target.nodeName !== "INPUT"
        ) {
          // return this.rejectCall();
          this.showCloseFinishCallModal(this.isConferenceCall ? true : 'components.callsContent.confirmFinishCall');
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
          this.spaceShortcutCallAcceptTimeout = null;
        }, 300);
      }
    },
    showCloseFinishCallModal(val){
      this.showFinishCallModal = val;
    },
    setMaximizeScreen(data) {
      this.maximizeScreen = data;
      this.localFullScreen = false;
      this.userMaximized = null;
    },
    aiSpeaking(aiSpeaking = false) {
      this._aiSpeakingTimeout && clearTimeout(this._aiSpeakingTimeout);
      if (aiSpeaking && !this.aiUserRecording) {
        this.processingAiAudio = aiSpeaking;
      } else {
        this._aiSpeakingTimeout = setTimeout(() => {
          if (!this.aiUserRecording) {
            this.processingAiAudio = aiSpeaking;
          }
        }, 2500 /* 2.5 seconds */);
      }
    },
    activeSpeakerChanged(event) {
      console.debug("activeSpeakerChanged", JSON.stringify(event, null, 2));
      const { data } = event;
      if (data.type === "local") {
        console.debug("You are speaking");
      } else {
        const uuid = data.user.externalId;
        if(isAiUser(uuid)){
          console.debug("AI user", data.user.name, "is speaking");
          this.aiSpeaking(true);
        } else {
          console.debug(data.user.name, "is speaking");
        }
      }
    },
    speakerStoppedTalking(event) {
      console.debug("speakerStoppedTalking", JSON.stringify(event, null, 2));
      const { data } = event;
      if (data.type === "local") {
        console.debug("You stopped speaking");
      } else {
        const uuid = data.user.externalId;
        if(isAiUser(uuid)){
          console.debug("AI user", data.user.name, "stopped speaking");
          this.aiSpeaking(false);
        } else {
          console.debug(data.user.name, "stopped speaking");
        }
      }
    },
    mediaDeviceChanged(event) { // sync devices (audioDeviceId, audioOutputId, videoDeviceId)
      const { kind, deviceId, previousDeviceId } = event.data;
      const { audioDeviceId, audioOutputId } = store.state.persisted.mediaDeviceSetup;
      if (kind === "audioinput" && (previousDeviceId || !["communications", "default"].includes(audioDeviceId)) && audioDeviceId !== deviceId) {
        store.state.persisted.mediaDeviceSetup.audioDeviceId = deviceId;
      }
      if (kind === "audiooutput" && (previousDeviceId || !["communications", "default"].includes(audioOutputId)) && audioOutputId !== deviceId) {
        store.state.persisted.mediaDeviceSetup.audioOutputId = deviceId;
      }
      if (kind === "videoinput" && store.state.persisted.mediaDeviceSetup.videoDeviceId !== deviceId) {
        store.state.persisted.mediaDeviceSetup.videoDeviceId = deviceId;
      }
      // These logs should not be sent to telemetry as they are big
      console.debug('mediaDeviceChanged', JSON.stringify(event, null, 2));
    },
    roomStateUpdated(event) {
      this.frameMuted = event.data.state.frameMuted;
      const { appLanguage, virtualBackground } = event.data.state;
      if (virtualBackground && virtualBackground.enabled) { // sync virtualBackground
        const dataVirtualBackground = this.getDataForVirtualBackground(virtualBackground);
        if (dataVirtualBackground && !isEqual(store.state.persisted.mediaDeviceSetup.videoBackground, dataVirtualBackground)) {
          store.state.persisted.mediaDeviceSetup.videoBackground = dataVirtualBackground;
        }
      }
      const languages = ["de", "en", "es"];
      let sambaLanguage = String.prototype.slice.call(appLanguage, 0, 2).toLowerCase();
      if (sambaLanguage === "df") sambaLanguage = "de"; // Deutsch (Förmlich) language - code of it is "df" for SDK and API
      if (languages.includes(sambaLanguage) && sambaLanguage !== this.$locale.current()) {
        this.$locale.change(sambaLanguage);
        this.$set(this.state.user, "language", this.$locale.current());
      }
      this._sambaFrame.listUsers().forEach(user => {
        if((user.externalId && isAiUser(user.externalId)) || user.name?.startsWith("AI-")){
          this.showAskAiUser = user.name;
        }
      });
      // These logs should not be sent to telemetry as they are big
      console.debug('roomStateUpdated', JSON.stringify({ event, localUser: this._sambaFrame?.localUser, listUsers: this._sambaFrame?.listUsers() }, null, 2));
    },
    onBeforeUnload(e) {
      e = e || window.event;
      if (e) {
        e.preventDefault();
        e.returnValue = '';
      }
      return '';
    },
    onAnyEvent(event) {
      // These logs should not be sent to telemetry as they happen too often
      console.debug("onAnyEvent", JSON.stringify(event, null, 2));
    },
    onAudioEnabled(event) {
      console.debug("onAudioEnabled", JSON.stringify(event, null, 2));
      if(event?.data?.user?.externalId === this.ownUUID){
        this.audioDisabled = false;
      }
      if(event?.data?.user?.externalId === this.ownUUID && this.isSambaStaticRoom){
        this.saveCameraMicrophoneStatus(event)
      }
    },
    onAudioDisabled(event) {
      console.debug("onAudioDisabled", JSON.stringify(event, null, 2));
      if(event?.data?.user?.externalId === this.ownUUID){
        this.audioDisabled = true;
      }
      if(event?.data?.user?.externalId === this.ownUUID && this.isSambaStaticRoom){
        this.saveCameraMicrophoneStatus(event)
      }
    },
    onVideoEnabled(event) {
      console.debug("onVideoEnabled", JSON.stringify(event, null, 2));
      if(event?.data?.user?.externalId === this.ownUUID && this.isSambaStaticRoom){
        this.saveCameraMicrophoneStatus(event)
      }
    },
    onVideoDisabled(event) {
      console.debug("onVideoDisabled", JSON.stringify(event, null, 2));
      if(event?.data?.user?.externalId === this.ownUUID){
        this.saveCameraMicrophoneStatus(event)
      }
    },
    onRoleChanged(event) {
      if(!this._sambaFrame) return;
      const localUser = this._sambaFrame.localUser;
      const {to, userId} = event.data;
      if(localUser && localUser.id === userId){
        this.mySambaRole = to;
      }
    },
    onUsersUpdated(event) {
      const { data } = event;
      const users = this._sambaFrame?.listUsers();
      console.debug("onUsersUpdated", JSON.stringify({ event, users }, null, 2));
      const remoteUsersInRoom = (users || data.users).filter(
        (user) => user.kind === "remote"
      );
      const callUUID = this.getCallUUID;
      if (callUUID && this.state.remoteBridgeStreams[callUUID]?.calling?.length) {
        const callingList = this.state.remoteBridgeStreams[callUUID].calling;
        remoteUsersInRoom.forEach(({ externalId: uuid }) => {
          if (uuid && callingList.includes(uuid)) {
            store.removeCallingUser(uuid, { callUUID });
          }
        });
      }
      this.remoteParticipants = remoteUsersInRoom;
    },
    onUserJoined(event) {
      console.debug("onUserJoined", JSON.stringify(event, null, 2));
      const { data } = event;
      if (data.type === "local") {
        console.log("You have joined the room");
        if (!this.hasPrivilege(this.ownUUID) && !store.state.user.name && data.user.name) {
          store.setUserName(data.user.name);
        }
        if (this.state.user.activity !== "inCall" && this.state.user.activity !== "inRoom" && this.state.user.activity !== "No status" && this.state.user.activity !== "Coffee break") {
          this.state.user.originalActivity = this.state.user.activity;
          if (this.isSambaStaticRoom) {
            this.state.user.activity = "inRoom";
          } else {
            this.state.user.activity = "inCall";
          }
        }
        // set virtual background if user set into cam and mic modal
        if (this._sambaFrame && this.virtualBackground) {
          const { roomSettings } = this._sambaFrame.initOptions;
          if (!roomSettings.videoEnabled) this._sambaFrame.configureVirtualBackground(this.virtualBackground);
          console.log("digital samba configure virtual background", { roomSettings, virtualBackground: this.virtualBackground });
        }
        this.showLeaveSession = true;
      } else {
        if (this._preventAloneInCallTimeout) clearTimeout(this._preventAloneInCallTimeout);
        console.log(data.user.name, "has joined the room");
        // dismiss the calling modal
        const uuid = data.user.externalId;
        if(isAiUser(uuid)){
          this.showAskAiUser = data.user.name;
        }
        const callUUID = this.getCallUUID;
        if (uuid && callUUID) store.removeCallingUser(uuid, { callUUID });
      }
    },
    rejoinMeetingRoomAfterLeave() {
      if (this._triggeredRejoinMeetingRoom) return;
      this._triggeredRejoinMeetingRoom = true;
      if (!this.amInAStaticRoom && this.state.wasInARoom) {
        const minDelay = aDelay(100);
        syncedUserState()
          .catch(() => {})
          .then(async () => {
            if (this._destroyedPromise) await this._destroyedPromise;
            await minDelay;
            const remoteStreams = Object.keys(store.state.remoteBridgeStreams);
            if (!remoteStreams.length && !this.amInAStaticRoom && this.state.wasInARoom) {
              joinSambaRoom(this.state.wasInARoom, this.$route);
            }
          });
      }
    },
    onUserLeft(event) {
      const { data } = event;
      const remoteStreamsKey = Object.keys(store.state.remoteBridgeStreams)[0];
      if (data.type === "local") {
        if (!this._destroyedPromise) this._destroyedPromise = new Deferred();
        console.log("You have left the room");
        this.rejoinMeetingRoomAfterLeave();
        if (remoteStreamsKey && this.state.remoteBridgeStreams[remoteStreamsKey]?.calling?.length) {
          const callingList = this.state.remoteBridgeStreams[remoteStreamsKey].calling;
          callingList.forEach(uuid => this.hangUpCalling(uuid));
        }
        store.removeRemoteBridgeStreams(remoteStreamsKey);
        // setQualityVotingModalEvent(true)
      } else {
        console.log(data.user.name, "has left the room");
        if (!this._destroyed && this._sambaFrame) {
          const users = this._sambaFrame.listUsers().filter((user, idx, arr) => {
            return idx === arr.findLastIndex(u => u.externalId === user.externalId);
          });
          const uuid = data.user.externalId;
          if(isAiUser(uuid)){
            this.loadingAskAiUser = null;
            this.showAskAiUser = null;
          }
          if (users && users.length === 1 && !this.isSambaStaticRoom) {
            console.log("hang up as all users left", this._notMyCall);
            return (this._notMyCall || !this.isConferenceCall)
              ? (this.state.remoteBridgeStreams[remoteStreamsKey]?.externalCall
                ? this.preventAloneInCall()
                : (this.rejoinMeetingRoomAfterLeave(), this.rejectCall()))
              : this.showCloseFinishCallModal(this.isConferenceCall ? true : 'components.callsContent.confirmFinishCall');
          } else if (isAiUser(this.ownUUID) && !users.some(u => !isAiUser(u?.externalId))) {
            return this.state.remoteBridgeStreams[remoteStreamsKey]?.externalCall
              ? this.preventAloneInCall()
              : this.rejectCall();
          }
        }
      }
    },
    saveCameraMicrophoneStatus(data) {
      switch (data.type) {
        case 'audioEnabled':
          sessionStorage.setItem('audioDisabled', 'false');
          break;
        case 'audioDisabled':
          sessionStorage.setItem('audioDisabled', 'true');
          break;
        case 'videoEnabled':
          sessionStorage.setItem('videoDisabled', 'false');
          break;
        case 'videoDisabled':
          sessionStorage.setItem('videoDisabled', 'true');
          break;
        default:
          break;
      }
    },
    updateTimeForUser(newTime) {
      if (newTime) {
        this.currentCallTime = newTime;
      }
    },
    setCallDuration(callUUID, callDuration, users = null) {
      if (users) {
        if (users.indexOf(this.ownUUID) !== -1) {
          return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
        }
      } else {
        return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
      }
    },
    hangUpBridgeCall() {
      if (!this._destroyedPromise) this._destroyedPromise = new Deferred();
      console.log("timeline end: hang up bridge call");
      if (this._sambaFrame) {
        this._sambaFrame.endSession(); // Ends the session for everyone. All users are immediately removed from the room and the call ends
      }
      const remoteStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      )[0];
      if (remoteStreamsKey && this.state.remoteBridgeStreams[remoteStreamsKey]?.calling?.length) {
        const callingList = this.state.remoteBridgeStreams[remoteStreamsKey].calling;
        callingList.forEach(uuid => this.hangUpCalling(uuid));
      }
      store.removeRemoteBridgeStreams(remoteStreamsKey);
    },
    redirectToStartView() {
      const currentPath = this.$route.path;
      if (currentPath === '/newconference') return;
      const startView =
        "/" +
        (isGuestOrVisitor()
          ? "home"
          : store.state.user.userSettings.startView || "my-favorites");
      this.setCurrentContentVisile(startView, true, this.$router);
    },
    preventAloneInCall() {
      if (this._preventAloneInCallTimeout) clearTimeout(this._preventAloneInCallTimeout);
      this._preventAloneInCallTimeout = setTimeout(() => {
        const remoteParticipants = isAiUser(this.ownUUID)
          ? this.remoteParticipants?.filter(u => !isAiUser(u?.externalId))
          : this.remoteParticipants;
        if (!this.hasPrivilege(this.ownUUID) || this._destroyed || remoteParticipants?.length || this.amICalling?.length) return;
        if (!this.getIsAiUser(this.ownUUID) && (this.isConferenceCall || this.isSambaStaticRoom)) return;
        if (!this._isSaneOrAbortThrow(true)) {
          console.log("hang up as no remote participants");
          this.rejoinMeetingRoomAfterLeave();
          this.rejectCall();
        }
      }, 20000 /* 20 seconds */);
    },
    rejectCall() {
      if (!this._destroyedPromise) this._destroyedPromise = new Deferred();
      console.log("timeline end: reject call");
      if (this._sambaFrame) {
        this._sambaFrame.leaveSession(); // Leaves the session, so the user is no longer participating in the call
      }
      const remoteStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      )[0];
      if (remoteStreamsKey && this.state.remoteBridgeStreams[remoteStreamsKey]?.calling?.length) {
        const callingList = this.state.remoteBridgeStreams[remoteStreamsKey].calling;
        callingList.forEach(uuid => this.hangUpCalling(uuid));
      }
      store.removeRemoteBridgeStreams(remoteStreamsKey);
    },
    hangUpCalling(id) {
      const callUUID = this.getCallUUID;
      const callInfo = this.state.remoteBridgeStreams[callUUID];
      if (!callInfo) return;
      wsCall("sendToUUID", id, {
        type: "bridge-signal",
        action: "cancel_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: callInfo.callUUID,
        },
      });
      store.removeCallingUser(id, callInfo);
    },
    getNameForUuid(userUUID) {
      return store.getNameForUuid(userUUID);
    },
    hasPrivilege(userUUID) {
      return hasPrivilege(userUUID);
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    isWaitingRoomUser(uuid) {
      return isWaitingRoomUser(uuid);
    },
    onRejectBridgeCallEvent(info) {
      console.log("hang up as received reject event", !this._notMyCall, this.getCallUUID === info.callUUID);
      if (!this._notMyCall && this.getCallUUID === info.callUUID && this.remoteParticipants && !this.remoteParticipants.length && this.amICalling && !this.amICalling.length) {
        this.rejoinMeetingRoomAfterLeave();
        this.rejectCall();
      }
    },
    setInfoModal(data) {
      return store.setinfoModal(data);
    },
    toggleMuteAudioFunction() {
      if(!this.frameMuted){
        this._sambaFrame.muteFrame();
        this._sambaFrame.disableAudio();
        if (this.isSambaStaticRoom) sessionStorage.setItem('speakerDisabled', 'true');
      }else{
        this._sambaFrame.unmuteFrame();
        if (this.isSambaStaticRoom) sessionStorage.setItem('speakerDisabled', 'false');
      }
    },
    toggleLeaveSession() {
      this._useLeaveSession = !this._useLeaveSession;
      if (this._sambaFrame && this._useLeaveSession) {
        this._sambaFrame.leaveSession();
      } else {
        this.rejectCall();
      }
    },
    getDataForVirtualBackground(virtualBackground) {
      return prepareDataForVirtualBackground(virtualBackground);
    }
  },
  computed: {
    isConfidentialAi() {
      const aiUser = this.showAskAiUser || this.loadingAskAiUser;
      return /Confidential/.test(aiUser);
    },
    isExternalUser() {
      return this.state.group[this.state.ownUUID].externalCall;
    },
    aiApiBaseUrl() {
      const aiUser = this.showAskAiUser || this.loadingAskAiUser;
      if (/Confidential/.test(aiUser)) {
        return 'https://ai-confidential.voffice.pro';
      } else if (/Mary/.test(aiUser)) {
        return 'https://ai-mary.voffice.pro';
      } else if (webLicensedBaseFeatures.isDev) {
        return 'https://voffice-ai-testing.webconnect.pro';
      }
      // return 'https://voffice-ai.webconnect.pro';
      return 'https://ai-peter.voffice.pro';
    },
    mutedBtnClass() {
      if(this.mySambaRole === 'attendee'){
        return 'mutedBtnAttendee'
      }
      return 'mutedBtn'
    },
    amInAStaticRoom() {
      return amInAStaticRoom(this.ownUUID);
    },
    virtualBackground() {
      if (!store.state.persisted.mediaDeviceSetup.videoBackground) return false;
      const { enforce, blur, image, imageUrl } = store.state.persisted.mediaDeviceSetup.videoBackground;
      if (!enforce && !blur && !image && !imageUrl) return false; // do not set if user disabled it
      const options = { enforce, blur, image, imageUrl };
      Object.keys(options).forEach(key => options[key] === undefined && delete options[key]);
      return options;
    },
    hideVideoContainer() {
      return (
        this.state.currentContentVisile.showComponent ||
        Object.keys(this.state.remoteBridgeStreams).length === 0
      );
    },
    showTimeLine() {
      if (
        !this.getTotalRemoteParticipants ||
        this.getTotalRemoteParticipants == 0
      ) {
        return false;
      }
      // if (this.getIsMobile) {
      //   return false;
      // }
      const callUUID = this.getCallUUID;
      if (callUUID && this.state.remoteBridgeStreams[callUUID]) {
        if (this.state.remoteBridgeStreams[callUUID].infiniteCall) {
          return false; // Looks like this was removed in the past... a infiniteCall have a duration of 86400000 sec or 31536000000 sec
        } else if (this.state.remoteBridgeStreams[callUUID].callDurationMs) {
          return true;
        }
      }
      return false;
    },
    isSambaStaticRoom() {
      const callUUID = this.getCallUUID;
      if (callUUID && this.state.remoteBridgeStreams[callUUID] && this.state.remoteBridgeStreams[callUUID].staticRoom) {
        return true;
      }
      return false;
    },
    getCallUUID() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return callUUID;
    },
    getIsAudioOnly() {
      const callUUID = this.getCallUUID;
      if (callUUID && this.state.remoteBridgeStreams[callUUID]) {
        return this.state.remoteBridgeStreams[callUUID].isAudioOnly;
      } else {
        return true;
      }
    },
    getTotalRemoteParticipants() {
      return (this.remoteParticipants || []).length;
    },
    isConferenceCall() {
      const callUUID = this.getCallUUID;
      return callUUID && isConferenceCall(callUUID);
    },
    amICalling() {
      const callUUID = this.getCallUUID;
      // const excluded = this.getExcludedFromCalling;
      // if (excluded && excluded.length > 0) {
      //   return this.state.remoteBridgeStreams[callUUID]?.calling?.filter(
      //     (e) => this.getExcludedFromCalling.indexOf(e) == -1
      //   ) || [];
      // } else {
      return (
        (this.state.remoteBridgeStreams[callUUID] &&
          this.state.remoteBridgeStreams[callUUID].calling) ||
        []
      ).filter(uuid => uuid in this.state.group);
      // }
    },
    callingParticipants() {},
  },
};
</script>
<style scoped lang="scss">
  .redColor{
    color: red;
  }
  .aiMenuOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .vColCallingToaster{
    min-width: 80%;
    margin-top: 15px;
    padding-top: 0px;
  }
.mutedBtnAttendee{
  position: absolute;
  bottom: 30px;
  left: 365px;
}
.mutedBtn{
  position: absolute;
  bottom: 30px;
  left: 236px;
}
.custom-btn:hover {
  background-color: #333333 !important; /* This will change the background color */
}
.custom-btn:focus {
  background-color: #333333 !important; /* This will change the background color */
}
.maximizeScreen {
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  z-index: 1008;
  display: flex;
}
.sidebarCallWrapper{
  position: fixed;
  bottom: 0;
  height: 100%;
  z-index: 1;
  left: 0;
}
.sidebarCallWrapperTimeline{
  position: fixed;
  bottom: -24px;
  height: 100%;
  z-index: 1;
  left: 0;
}
.progressLinearBridge{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
}
.callContainerNoSidebar{
  width: 100%;
  // height: calc(100% + 30px);
  left: 0px;
  position: fixed;
  bottom: 0;
}
.callContainerSidebar{
  width: calc(100% - 40px);
  left: 55px;
  position: fixed;
  bottom: 0;
}
.callContainerSidebarExternal {
  width: 100%;
  left: 0;
  position: fixed;
  bottom: 0;
}
.callContainerSidebarMobile{
  width: calc(100% - 32px) !important;
  left: 40px !important;
}
.heightNoTimeline {
  height: 100%;
  // top: -45px;
  // position: absolute;
}
.heightTimeline {
  height: calc(100% - 24px);
  // top: -20px;
  // position: absolute;
}
.callingToaster {
  position: absolute;
  bottom: 75px;
  right: 10px;
  z-index: 1;
  width: 23%;
  min-width: 310px;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.waveCallingBridge {
  // position: absolute;
  // left: 30px;
  // -webkit-transform: translate(-50%, -50%);
  // -moz-transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // -o-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // margin: 20px auto;
  .dot {
    background: #2a3133;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    animation: wave 1.3s linear infinite;
  }
  .dot:nth-child(2) {
    animation-delay: -1.1s;
  }
  .dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }
    30% {
      transform: translateY(-15px);
    }
  }
}
</style>
