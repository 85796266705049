var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showTeamsModal,
        callback: function($$v) {
          _vm.showTeamsModal = $$v
        },
        expression: "showTeamsModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.organisationGrid.showTeams"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-3" },
            [
              _vm.getTeams.length
                ? _c(
                    "v-col",
                    { staticClass: "containerTeams", attrs: { cols: "12" } },
                    _vm._l(_vm.getTeams, function(team) {
                      return _c("div", { key: team.id }, [
                        team.id
                          ? _c(
                              "div",
                              { staticClass: "font-weight-bold mb-2" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(team.name || "") +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        team.children
                          ? _c(
                              "div",
                              { staticClass: "d-flex flex-wrap" },
                              _vm._l(team.children, function(child) {
                                return _c(
                                  "v-card",
                                  {
                                    key: child.id,
                                    staticClass: "cardWidth mr-2 mb-2",
                                    style: _vm.getBorderByStatusFull(
                                      _vm.getPersonByUuid(child.supervisors[0])
                                    )
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c(
                                                        "v-img",
                                                        {
                                                          staticClass:
                                                            "white--text align-end",
                                                          attrs: {
                                                            src: _vm.getAvatarByType(
                                                              child
                                                                .supervisors[0]
                                                            ),
                                                            height: "100px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "gradientBg"
                                                            },
                                                            [
                                                              _c(
                                                                "v-card-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fff !important"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.titelPerson(
                                                                          _vm.getPersonByUuid(
                                                                            child
                                                                              .supervisors[0]
                                                                          )
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.namePerson(
                                                                          _vm.getPersonByUuid(
                                                                            child
                                                                              .supervisors[0]
                                                                          )
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              child
                                                                .supervisors[0] &&
                                                              _vm.hasRepresentative(
                                                                child
                                                                  .supervisors[0]
                                                              )
                                                                ? _c(
                                                                    "v-card-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                      style: _vm.getBorderByStatusFull(
                                                                        _vm.getPersonByUuid(
                                                                          _vm.hasRepresentative(
                                                                            child
                                                                              .supervisors[0]
                                                                          )
                                                                        )
                                                                      ),
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "representative-subtitle text-truncate px-1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "colorWhite"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    _vm.namePerson(
                                                                                      _vm.getPersonByUuid(
                                                                                        _vm.hasRepresentative(
                                                                                          child
                                                                                            .supervisors[0]
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("UserInfoTooltip", {
                                          attrs: {
                                            person: _vm.getPersonByUuid(
                                              child.supervisors[0]
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _c(
                    "v-col",
                    {
                      staticClass:
                        "containerTeams d-flex justify-center align-center",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("components.organisationGrid.userNotFound")
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  )
            ],
            1
          ),
          _vm._v(" "),
          [_c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }