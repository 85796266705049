var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emoji-picker", {
    staticClass: "emojiContainer",
    attrs: { search: _vm.search },
    on: { emoji: _vm.selectEmoji },
    scopedSlots: _vm._u([
      {
        key: "emoji-invoker",
        fn: function(ref) {
          var clickEvent = ref.events.click
          return _c(
            "div",
            {
              ref: "test",
              staticClass: "emoji-invoker tooltipped",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return clickEvent.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    height: "24",
                    viewBox: "0 0 24 24",
                    width: "24",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "tooltippedtooltip" }, [
                _vm._v(_vm._s(_vm.$t("components.callsContent.addEmoji")))
              ])
            ]
          )
        }
      },
      {
        key: "emoji-picker",
        fn: function(ref) {
          var emojis = ref.emojis
          var insert = ref.insert
          return _c("div", {}, [
            _c("div", { staticClass: "emoji-picker" }, [
              _c("div", { staticClass: "emoji-picker__search mb-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    },
                    { name: "focus", rawName: "v-focus" }
                  ],
                  ref: "searchEmoji",
                  attrs: {
                    id: "searchEmoji",
                    type: "text",
                    placeholder: _vm.$t("generics.search")
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(emojis, function(emojiGroup, category) {
                  return _c(
                    "div",
                    { key: category, staticClass: "mb-2" },
                    [
                      category !== "Frequently used"
                        ? [
                            _c("h5", { staticClass: "mb-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.emojiPicker." + category)
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "emojis" },
                              _vm._l(emojiGroup, function(emoji, emojiName) {
                                return _c(
                                  "span",
                                  {
                                    key: emojiName,
                                    attrs: {
                                      title: _vm.$t("emojis." + emojiName)
                                    },
                                    on: {
                                      click: function($event) {
                                        insert(emoji)
                                        _vm.closeEmoji()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(emoji))]
                                )
                              }),
                              0
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }