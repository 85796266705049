var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showEntry && _vm.entryForShow
    ? _c(
        "div",
        {
          staticClass: "timelineWrapper",
          style: _vm.magicParentStyle,
          on: { click: _vm.handleEventEntry }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "max-width": "750" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _vm.entry.eventType === "directCall"
                          ? _c(
                              "v-img",
                              _vm._g(
                                {
                                  staticClass:
                                    "mx-2 borderRadius5 cursorPointer eventEntryImage",
                                  attrs: {
                                    src: _vm.firstAvatar,
                                    contain: "",
                                    "lazy-src":
                                      "assets/default_profile_picture.png"
                                  },
                                  on: { error: _vm.onImgError }
                                },
                                on
                              )
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass:
                                "btn customButton eventButton text-truncate",
                              style: _vm.magicStyle,
                              attrs: { height: "30" }
                            },
                            on
                          ),
                          [
                            _c(
                              "p",
                              {
                                staticClass: "timeEntryHint",
                                class:
                                  _vm.entry.eventType === "directCall"
                                    ? "ml-5"
                                    : null
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isWaitingRoomUserWithDate
                                      ? _vm.getTitelForUuid(
                                          _vm.entry.users[0]
                                        ) +
                                          " " +
                                          _vm.getNameForUuid(_vm.entry.users[0])
                                      : _vm.$t("generics.group")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                428770433
              )
            },
            [
              _vm._v(" "),
              _vm.entry.color !== "red"
                ? _c(
                    "span",
                    [
                      !_vm.isWaitingRoomUserWithDate
                        ? _c("GeneralDetails", { attrs: { entry: _vm.entry } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isWaitingRoomUserWithDate
                        ? _c("WaitingroomUserDetails", {
                            attrs: { entry: _vm.entry }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOneCall
                        ? _c("OneCallDetails", { attrs: { entry: _vm.entry } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAppointment
                        ? _c("AppointmentDetails", {
                            attrs: { entry: _vm.entry }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.getInCurseConferenceDetail)
                    }
                  })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }