<template>
  <v-card flat class="notification-box rounded-sm my-6">
    <v-card-title class="text-h6 mt-2">
      {{ userInfo.title }}
    </v-card-title>
    <v-card-text class="font-weight-light">
      {{ userInfo.content }}
    </v-card-text>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../../../store";
import { isMobile } from "../../../../lib/mobileUtil";

export default {
  props: ["userInfo"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  watch: {},
  mounted() { },
  unmounted() { },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss"></style>