var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getClassChat },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-space-between align-baseline w100 pt-2 pl-4 pr-3"
        },
        [
          _c("div", { staticClass: "title white--text" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("generics.info")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleUserList()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: {
                                  icon: ["fal", "times"],
                                  color: "white"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-col",
        {
          staticClass:
            "flex-grow-1 flex-shrink-0 containerUsers  overflow-y-auto",
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-list",
            { staticClass: "bgColor" },
            _vm._l(_vm.getUserList, function(uuid) {
              return _c(
                "v-list-item",
                { key: uuid, staticClass: "px-0" },
                [
                  _c(
                    "v-list-item-avatar",
                    {
                      staticClass: "borderRadius5 mx-2 my-2",
                      attrs: { tile: "", size: "60" }
                    },
                    [
                      _vm.getAvatarSrc(uuid) ===
                      "img/default_profile_picture.png"
                        ? _c("DefaultAvatar", { attrs: { size: 60 } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getAvatarSrc(uuid) !==
                      "img/default_profile_picture.png"
                        ? _c("v-img", {
                            staticClass: "mx-2 borderRadius5 cursorPointer",
                            attrs: {
                              src: _vm.getAvatarSrc(uuid),
                              "max-height": "60",
                              "max-width": "60",
                              contain: "",
                              "lazy-src": "assets/default_profile_picture.png"
                            },
                            on: { error: _vm.onImgError }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-content", { staticClass: "contentWrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "notranslate white--text text-truncate nameWidth"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getTitelForUuid(uuid)) +
                            "\n            " +
                            _vm._s(_vm.getNameForUuid(uuid)) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "iconWidth" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.setUserInModal(uuid)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: {
                                              icon: ["fal", "info-circle"],
                                              color: "white"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.adminSettingsModal.userInfo"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }