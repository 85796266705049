var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "560", "hide-overlay": "" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline d-flex" },
                    [
                      _c("v-img", {
                        staticClass: "mr-1",
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "mr-auto" }, [
                        _vm._v("Call " + _vm._s(_vm.inviterName))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      style: { width: "100%", margin: 0 },
                      attrs: { flex: "" }
                    },
                    [
                      _c("v-col", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-badge",
                              {
                                attrs: {
                                  bordered: "",
                                  overlay: "",
                                  height: "60",
                                  width: "60",
                                  bottom: "",
                                  dot: "",
                                  "offset-x": "17",
                                  "offset-y": "9"
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  {
                                    staticClass: "borderRadius10",
                                    staticStyle: { margin: "0px 10px" },
                                    attrs: { tile: "", size: "76" }
                                  },
                                  [
                                    _c("v-img", {
                                      staticClass: "borderRadius10",
                                      attrs: {
                                        "max-height": "76",
                                        "max-width": "76",
                                        contain: "",
                                        src: _vm.getAvatarForUuid(
                                          _vm.inviterUUID
                                        ),
                                        "lazy-src":
                                          "assets/default_profile_picture.png"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { style: { width: "80%", margin: 0 } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 pt-1 d-flex",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticStyle: {
                                          width: "500px",
                                          "list-style-type": "none"
                                        }
                                      },
                                      [
                                        _c("li", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "userNameCard mb-0 pb-0 mr-auto",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(_vm.inviterName) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.appointmentStarts.length >
                                                  10,
                                                expression:
                                                  "appointmentStarts.length > 10"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.inviteVisitor.appointment"
                                                  )
                                                ) +
                                                "\n                      " +
                                                _vm._s(
                                                  _vm.appointmentStarts || ""
                                                ) +
                                                ":\n                    "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      ref: "callInviter",
                                                      staticClass: "bR4",
                                                      staticStyle: {
                                                        width:
                                                          "70px !important",
                                                        height:
                                                          "70px !important",
                                                        "'":
                                                          "'width: 60px !important"
                                                      },
                                                      attrs: {
                                                        id:
                                                          "buttonCallDirectly",
                                                        color: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          !_vm.inviterOnline
                                                            ? false
                                                            : _vm.callInviter()
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "50px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "phone"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              !_vm.inviterOnline
                                                ? "Dieser Benutzer ist zur Zeit offline"
                                                : _vm.$t(
                                                    "components.userProfile.callUser"
                                                  )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                      attrs: { color: "primary" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 py-0",
                              attrs: { cols: "8", align: "right" }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "white"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.showCamSettings = true
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "30px" },
                                                attrs: {
                                                  icon: ["fal", "camera"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("components.navbar.camAndMic")
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showCamSettings
        ? _c("DeviceSetupModal", {
            attrs: {
              showSetupDevice: true,
              toggleModalSettings: _vm.closeModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showHistoryMessagesModal && _vm.isOnlyOneCallUser
        ? _c("HistoryMessagesModalEffector")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.showSuccessPayment
        ? _c("SuccessPaymentModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.showErrorPayment
        ? _c("ErrorPaymentModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.paymentState.showCancelPayment
        ? _c("PaymentCanceled")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.user.requestedPayment.show ? _c("PaymentEventModal") : _vm._e(),
      _vm._v(" "),
      _vm.showInfoModal ? _c("InfoModal") : _vm._e(),
      _vm._v(" "),
      _vm.showVisitorNameModal ? _c("SetVisitorNameModal") : _vm._e(),
      _vm._v(" "),
      _vm.showUserProfilesModal ? _c("UserProfile") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }