var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "localButtonsBar myLocal" }, [
    _c(
      "div",
      { staticClass: "leftLocalBar" },
      [
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "darkGreyIcon pAbsoluteLeft",
                          attrs: { large: "", icon: "", color: "white" },
                          on: { click: _vm.openCloseChat }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "comments"] }
                        }),
                        _vm._v(" "),
                        _vm.hasUnreadChatMessages
                          ? _c("v-badge", {
                              staticClass: "docsBadgeDots",
                              attrs: {
                                right: "",
                                color: "error",
                                overlap: "",
                                "offset-x": "25",
                                "offset-y": "3",
                                content: _vm.totalUnreadChatMessages,
                                value: _vm.hasUnreadChatMessages
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          [
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("components.callsContent.openGroupChat")))
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rightLocalBar" },
      [
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleMic darkGreyIcon",
                          class: {
                            "mx-3": _vm.isMobile,
                            "mx-6": !_vm.isMobile
                          },
                          attrs: {
                            large: "",
                            icon: "",
                            color: "white",
                            disabled:
                              (_vm.room &&
                                _vm.micMutedByAdmin.indexOf(
                                  _vm.room.myUserId()
                                ) !== -1) ||
                              _vm.loadingMic,
                            loading: _vm.loadingMic
                          },
                          on: {
                            click: function($event) {
                              _vm.toggleMic(false)
                              _vm.preventMultiClick("mic")
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "loader",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "custom-loader" },
                                      [
                                        _c("font-awesome-icon", {
                                          style: {
                                            fontSize: "15px",
                                            color: "white"
                                          },
                                          attrs: {
                                            icon: ["fal", "spinner-third"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        on
                      ),
                      [
                        _vm.room &&
                        !_vm.micMute &&
                        _vm.micMutedByAdmin.indexOf(_vm.room.myUserId()) == -1
                          ? _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "microphone"] }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.room &&
                        _vm.micMute &&
                        _vm.micMutedByAdmin.indexOf(_vm.room.myUserId()) == -1
                          ? _c("img", {
                              staticClass: "microphone-slash",
                              attrs: {
                                src: "/img/microphone-slash-red-white.svg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.room &&
                        _vm.micMutedByAdmin.indexOf(_vm.room.myUserId()) !== -1
                          ? _c("img", {
                              staticClass: "microphone-slash",
                              attrs: {
                                src: "/img/microphone-slash-red-grey.svg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.room &&
                                  !_vm.micMute &&
                                  _vm.micMutedByAdmin.indexOf(
                                    _vm.room.myUserId()
                                  ) == -1,
                                expression:
                                  "room && !micMute && micMutedByAdmin.indexOf(room.myUserId()) == -1"
                              }
                            ],
                            staticClass: "progressBarBridge",
                            attrs: { id: "myAudioBar" }
                          },
                          [_c("div", { staticClass: "progressBarLevelBridge" })]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          [
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.room &&
                    !_vm.micMute &&
                    _vm.micMutedByAdmin.indexOf(_vm.room.myUserId()) == -1
                    ? _vm.$t("components.callsContent.micOff")
                    : _vm.$t("components.callsContent.micOn")
                )
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleVideo darkGreyIcon",
                          class: {
                            "mx-3": _vm.isMobile,
                            "mx-6": !_vm.isMobile
                          },
                          attrs: {
                            large: "",
                            icon: "",
                            disabled:
                              !_vm.hasVideoDevice ||
                              _vm.cameraOffByModerator ||
                              _vm.loadingVideo,
                            color: "white",
                            loading: _vm.loadingVideo
                          },
                          on: {
                            click: function($event) {
                              _vm.toggleVideo()
                              _vm.preventMultiClick("video")
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "loader",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "custom-loader" },
                                      [
                                        _c("font-awesome-icon", {
                                          style: {
                                            fontSize: "15px",
                                            color: "white"
                                          },
                                          attrs: {
                                            icon: ["fal", "spinner-third"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        on
                      ),
                      [
                        !_vm.hasVideoDevice || _vm.cameraOffByModerator
                          ? _c("img", {
                              staticClass: "video-slash",
                              attrs: { src: "/img/video-slash-red-gray.svg" }
                            })
                          : !_vm.videoDisabled
                          ? _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "video"] }
                            })
                          : _c("img", {
                              staticClass: "video-slash",
                              attrs: { src: "/img/video-slash-red-white.svg" }
                            })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          [
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.videoDisabled
                    ? _vm.$t("components.callsContent.cameraOn")
                    : _vm.$t("components.callsContent.cameraOff")
                )
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "toggleMuteAudio darkGreyIcon",
                          class: {
                            "mx-3": _vm.isMobile,
                            "mx-6": !_vm.isMobile
                          },
                          attrs: { large: "", icon: "", color: "white" },
                          on: {
                            click: function($event) {
                              return _vm.toggleMuteAudioFunction()
                            }
                          }
                        },
                        on
                      ),
                      [
                        !_vm.audioOutputMuted
                          ? _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "volume-up"] }
                            })
                          : _c("img", {
                              staticClass: "volume-up-slash",
                              attrs: { src: "/img/volume-slash-red-white.svg" }
                            })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          [
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.audioOutputMuted
                    ? _vm.$t(
                        "components.callsContent.unmuteSoundAllParticipants"
                      )
                    : _vm.$t("components.callsContent.muteAllParticipants")
                )
              )
            ])
          ]
        ),
        _vm._v(" "),
        !_vm.isMobile &&
        !_vm.getIsMobile &&
        !_vm.getIsConferenceHall &&
        !_vm.currentDesktop &&
        ((_vm.room && _vm.presenterModeInitiatedBy == _vm.room.myUserId()) ||
          (_vm.room && !_vm.presenterModeInitiatedBy) ||
          _vm.getAmIModerator)
          ? _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "darkGreyIcon",
                                class: {
                                  "mx-3": _vm.isMobile,
                                  "mx-6": !_vm.isMobile
                                },
                                attrs: {
                                  large: "",
                                  disabled: _vm.getTotalRemoteParticipants < 1,
                                  color: "white",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.togglePresentation()
                                    _vm.speakerView
                                      ? _vm.changeSpeakerView(!_vm.speakerView)
                                      : null
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _vm.getTotalRemoteParticipants < 1
                                ? _c("img", {
                                    staticClass: "volume-up-slash",
                                    attrs: {
                                      src:
                                        "/img/icons/voffice_viewB_black-grey.svg"
                                    }
                                  })
                                : _vm.presenterModeInitiatedBy &&
                                  _vm.presenterModeInitiatedBy ==
                                    _vm.room.myUserId()
                                ? _c("img", {
                                    staticClass: "volume-up-slash",
                                    attrs: {
                                      src:
                                        "/img/icons/voffice_viewB-redslash-white.svg"
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "volume-up-slash",
                                    attrs: {
                                      src:
                                        "/img/icons/voffice_viewB_black-white.svg"
                                    }
                                  })
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3084989715
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.presenterModeInitiatedBy &&
                        _vm.presenterModeInitiatedBy == _vm.room.myUserId()
                        ? _vm.$t(
                            "components.bridgeCallTooltips.endPresentation"
                          )
                        : _vm.$t("components.bridgeCallTooltips.presentation")
                    )
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isMobile && !_vm.getIsMobile
          ? _c(
              "v-tooltip",
              {
                attrs: {
                  disabled:
                    _vm.currentDesktop &&
                    _vm.currentDesktop == _vm.getMyDesktopRoomUserID,
                  top: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var onTooltip = ref.on
                        return [
                          _c(
                            "v-menu",
                            {
                              staticClass: "customMenuLocal",
                              attrs: {
                                disabled: !_vm.currentDesktop,
                                right: "",
                                top: "",
                                "nudge-top": 10,
                                "nudge-left": 25,
                                "offset-y": true,
                                "offset-x": true,
                                "close-on-content-click": true,
                                "z-index": "999999",
                                attach: !_vm.isMobile ? ".myLocal" : false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var onMenu = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "darkGreyIcon",
                                                class: {
                                                  "mx-3": _vm.isMobile,
                                                  "mx-6": !_vm.isMobile
                                                },
                                                attrs: {
                                                  large: "",
                                                  disabled:
                                                    _vm.currentDesktop &&
                                                    _vm.currentDesktop !==
                                                      _vm.getMyDesktopRoomUserID,
                                                  color: "white",
                                                  icon: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    !_vm.currentDesktop
                                                      ? _vm.toggleShareScreen()
                                                      : null
                                                    _vm.speakerView
                                                      ? _vm.changeSpeakerView(
                                                          !_vm.speakerView
                                                        )
                                                      : null
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            Object.assign({}, onMenu, onTooltip)
                                          ),
                                          [
                                            _vm.currentDesktop &&
                                            _vm.currentDesktop ==
                                              _vm.getMyDesktopRoomUserID
                                              ? _c("img", {
                                                  staticClass:
                                                    "volume-up-slash",
                                                  attrs: {
                                                    src:
                                                      "/img/desktop-slash-red-white.svg"
                                                  }
                                                })
                                              : _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "desktop"]
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menuShareScreenOpen,
                                callback: function($$v) {
                                  _vm.menuShareScreenOpen = $$v
                                },
                                expression: "menuShareScreenOpen"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticClass: "text-left",
                                  attrs: { dark: "" }
                                },
                                [
                                  _vm.currentDesktop &&
                                  _vm.currentDesktop ==
                                    _vm.getMyDesktopRoomUserID
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.stopShareScreen()
                                            }
                                          }
                                        },
                                        [
                                          _c("v-list-item-icon", [
                                            _c("img", {
                                              staticClass: "volume-up-slash",
                                              attrs: {
                                                src:
                                                  "/img/desktop-slash-red-white.svg"
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.callsContent.endShareScreen"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentDesktop &&
                                  _vm.currentDesktop ==
                                    _vm.getMyDesktopRoomUserID &&
                                  _vm.getTotalRemoteParticipants >= 1
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.stopStartShareScreen()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "desktop"]
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.callsContent.changeShareScreen"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2004902974
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.callsContent.shareScreen")) +
                      "\n      "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isMobile
          ? _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "darkGreyIcon",
                                class: {
                                  "mx-3": _vm.isMobile,
                                  "mx-6": !_vm.isMobile
                                },
                                attrs: { large: "", icon: "", color: "white" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocalFullScreen()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              !_vm.localFullScreen
                                ? _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "expand-wide"] }
                                  })
                                : _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "compress-wide"] }
                                  })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2695434238
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      !_vm.localFullScreen
                        ? _vm.$t("generics.fullscreen")
                        : _vm.$t("generics.normalScreen")
                    ) + "\n      "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "red",
                          class: {
                            "mx-3": _vm.isMobile,
                            "mx-6": !_vm.isMobile
                          },
                          attrs: {
                            large: "",
                            icon: "",
                            color: "white",
                            loading: _vm.rejectingCall,
                            disabled: _vm.rejectingCall
                          },
                          on: {
                            click: function($event) {
                              return _vm.rejectCallFromLocal()
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "loader",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "custom-loader" },
                                      [
                                        _c("font-awesome-icon", {
                                          style: {
                                            fontSize: "15px",
                                            color: "white"
                                          },
                                          attrs: {
                                            icon: ["fal", "spinner-third"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          staticClass: "faPhoneRotate",
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "phone"] }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          },
          [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pAbsoluteRight" },
          [
            _c(
              "v-menu",
              {
                staticClass: "customMenuLocal",
                attrs: {
                  "content-class": "customDotsMenuLocal",
                  right: "",
                  "offset-x": true,
                  "close-on-content-click": true,
                  "z-index": "999999",
                  attach: !_vm.isMobile ? ".myLocal" : false
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "buttonsCall darkGreyIcon",
                                class: { "mx-6": !_vm.isMobile },
                                attrs: { large: "", color: "white", icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  }
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "ellipsis-v"] }
                            }),
                            _vm._v(" "),
                            _vm.hasDocuments
                              ? _c("v-badge", {
                                  staticClass: "docsBadgeDots",
                                  attrs: {
                                    right: "",
                                    color: "error",
                                    overlap: "",
                                    "offset-x": "25",
                                    "offset-y": "3",
                                    content: _vm.getTotalDocumentsRead,
                                    value: _vm.getTotalDocumentsRead
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.menuOpen,
                  callback: function($$v) {
                    _vm.menuOpen = $$v
                  },
                  expression: "menuOpen"
                }
              },
              [
                _vm._v(" "),
                _c(
                  "v-list",
                  { staticClass: "text-left", attrs: { dark: "" } },
                  [
                    _vm.getAmIModerator &&
                    _vm.getTotalRemoteParticipants > 1 &&
                    !_vm.allMods &&
                    !_vm.getIsConferenceHall
                      ? _c(
                          "v-list-item",
                          { on: { click: _vm.toggleAllRemoteMic } },
                          [
                            _c("v-list-item-icon", [
                              !_vm.allRemoteMicMuted
                                ? _c("img", {
                                    staticClass: "muteAllButon",
                                    attrs: {
                                      src:
                                        "/img/icons/microphone_all_muted-white.svg"
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "muteAllButon",
                                    attrs: {
                                      src:
                                        "/img/icons/microphone_all_muted-slash-red-white.svg"
                                    }
                                  })
                            ]),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.allRemoteMicMuted
                                      ? _vm.$t(
                                          "components.callsContent.unmuteAllParticipants"
                                        )
                                      : _vm.$t(
                                          "components.callsContent.muteAllTT"
                                        )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.room &&
                    _vm.getAmIModerator &&
                    _vm.presenterModeInitiatedBy &&
                    !_vm.currentDesktop &&
                    !_vm.getIsConferenceHall
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "d-flex ml-2 switchContainer"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-switch", {
                                                    staticClass: "mt-0",
                                                    attrs: {
                                                      "hide-details": "",
                                                      width: "100%"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.changeSpeakerView(
                                                          !_vm.speakerView
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.speakerView,
                                                      callback: function($$v) {
                                                        _vm.speakerView = $$v
                                                      },
                                                      expression: "speakerView"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.speakerView
                                                    ? _c("img", {
                                                        staticClass:
                                                          "imgButton",
                                                        attrs: {
                                                          src:
                                                            "/img/icons/account-tie-voice-outline-white.svg"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.speakerView
                                                    ? _c("img", {
                                                        staticClass:
                                                          "imgButton",
                                                        attrs: {
                                                          src:
                                                            "/img/icons/account-tie-voice-outline-white-slash.svg"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "pt-1 pl-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.callsContent.speakerView"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1762903546
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.speakerView
                                            ? _vm.$t(
                                                "components.callsContent.presentationView"
                                              )
                                            : _vm.$t(
                                                "components.callsContent.speakerViewTT"
                                              )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.room &&
                    _vm.getAmIModerator &&
                    _vm.currentDesktop &&
                    _vm.currentDesktop !== _vm.getMyDesktopRoomUserID
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.toggleGiveWord(_vm.room.myUserId())
                              }
                            }
                          },
                          [
                            _c("v-list-item-icon", [
                              _c("img", {
                                staticClass: "imgButton",
                                attrs: {
                                  src:
                                    "/img/icons/voffice_viewB_black-white.svg"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.callsContent.endShareScreen"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.room &&
                    _vm.presenterModeInitiatedBy &&
                    _vm.presenterModeInitiatedBy !== _vm.room.myUserId() &&
                    !_vm.getAmIModerator &&
                    !_vm.currentDesktop &&
                    !_vm.getIsConferenceHall
                      ? _c(
                          "v-list-item",
                          { on: { click: _vm.toggleWaveHand } },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _vm.wavingHand.indexOf(_vm.room.myUserId()) ==
                                -1
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "hand-point-up"] }
                                    })
                                  : _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fas", "hand-point-up"] }
                                    })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.wavingHand.indexOf(
                                      _vm.room.myUserId()
                                    ) == -1
                                      ? _vm.$t(
                                          "components.callsContent.requestWord"
                                        )
                                      : _vm.$t(
                                          "components.callsContent.endRequestWord"
                                        )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getAmIModerator &&
                    ((_vm.room &&
                      !_vm.currentDesktop &&
                      !_vm.implicitPresenterMode &&
                      _vm.presenterModeInitiatedBy &&
                      _vm.presenterModeInitiatedBy !== _vm.room.myUserId()) ||
                      (_vm.room &&
                        !_vm.currentDesktop &&
                        !_vm.implicitPresenterMode &&
                        _vm.presenterModeInitiatedBy &&
                        _vm.presenterModeInitiatedBy !== _vm.room.myUserId() &&
                        _vm.getIsConferenceHall &&
                        _vm.getIsParticipantIsPodium(_vm.state.ownUUID)))
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.toggleGiveWord(_vm.room.myUserId())
                                _vm.speakerView
                                  ? _vm.changeSpeakerView(!_vm.speakerView)
                                  : null
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "comment"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("components.callsContent.giveWord")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.fullScreenActive
                      ? _c(
                          "v-list-item",
                          { on: { click: _vm.toggleCamMic } },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "cog"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("components.navbar.camAndMic")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.room &&
                    _vm.getAmIModerator &&
                    !_vm.allMods &&
                    !_vm.getIsConferenceHall
                      ? _c(
                          "v-list-item",
                          { on: { click: _vm.toggleAllCameraOff } },
                          [
                            _c("v-list-item-icon", [
                              _vm.allRemoteCameraOff
                                ? _c("img", {
                                    staticClass: "imgButton",
                                    attrs: {
                                      src:
                                        "/img/users-camera-slash-red-white.svg"
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "imgButton",
                                    attrs: {
                                      src: "/img/users-camera-white.svg"
                                    }
                                  })
                            ]),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    !_vm.allRemoteCameraOff
                                      ? _vm.$t(
                                          "components.callsContent.cameraOffAllParticipants"
                                        )
                                      : _vm.$t(
                                          "components.callsContent.stopCameraOffAllParticipants"
                                        )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          disabled: !_vm.getAmIModerator && !_vm.hasDocuments
                        },
                        on: {
                          click: function($event) {
                            return _vm.openConferenceDocumentsModal()
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "file-upload"] }
                            }),
                            _vm._v(" "),
                            _vm.hasDocuments
                              ? _c("v-badge", {
                                  staticClass: "docsBadge",
                                  attrs: {
                                    right: "",
                                    color: "error",
                                    overlap: "",
                                    "offset-x": "25",
                                    "offset-y": "3",
                                    content: _vm.getTotalDocumentsRead,
                                    value: _vm.getTotalDocumentsRead
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-list-item-title", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "components.callsContent.documentManagementShare"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.getIsConferenceHall &&
                    _vm.getDataConference &&
                    (_vm.getDataConference.creatorUUID === _vm.state.ownUUID ||
                      _vm.getDataConference.confOwner === _vm.state.ownUUID ||
                      _vm.getAmIModerator ||
                      (_vm.getDataConference.confModerators &&
                        _vm.getDataConference.confModerators.indexOf(
                          _vm.state.ownUUID
                        ) !== -1))
                      ? _c(
                          "v-list-item",
                          { on: { click: _vm.openManageConference } },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "users"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.callsContent.manageConference"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getDataConference &&
                    (_vm.getDataConference.creatorUUID === _vm.state.ownUUID ||
                      _vm.getDataConference.confOwner === _vm.state.ownUUID ||
                      (!_vm.getIsConferenceHall && _vm.getAmIModerator))
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.endConference()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "door-open"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    !_vm.getIsConferenceHall
                                      ? _vm.$t(
                                          "components.conferenceForm.endConference"
                                        )
                                      : _vm.$t(
                                          "components.conferenceForm.endConferenceHall"
                                        )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showDownloadSupportLog
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.downloadSupportLog()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "notes-medical"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.callsContent.downloadSupportLog"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showDownloadChat
                      ? _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.downloadChat()
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "comments"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.callsContent.downloadChat"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.showConferenceDocumentsModal
          ? _c("DocumentsManager", {
              attrs: {
                showModal: _vm.showConferenceDocumentsModal,
                eventType: _vm.isConferenceCall ? "conference" : "uuid",
                documentsStore: _vm.isConferenceCall
                  ? _vm.conferenceStore
                  : _vm.callStore,
                amIModerator: _vm.getAmIModerator,
                attachTo: "videos",
                setTempDocStore: _vm.setTempDocStore,
                isConferenceCall: _vm.isConferenceCall
                  ? _vm.isConferenceCall
                  : false
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showManageConference
          ? _c("ManageConference", {
              attrs: {
                isConferenceCall: _vm.isConferenceCall,
                showModal: _vm.showManageConference,
                closeModal: _vm.closeManageConference,
                attachTo: "videos",
                callUUID: this.getCallUUID,
                checkUserInCall: _vm.checkUserInCall,
                kickUser: _vm.kickUser,
                getIsConferenceHall: _vm.getIsConferenceHall
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }