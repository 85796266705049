import { render, staticRenderFns } from "./callTopBar.vue?vue&type=template&id=0d960b34&scoped=true"
import script from "./callTopBar.vue?vue&type=script&lang=js"
export * from "./callTopBar.vue?vue&type=script&lang=js"
import style0 from "./callTopBar.vue?vue&type=style&index=0&id=0d960b34&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d960b34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/voffice-dev04/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d960b34')) {
      api.createRecord('0d960b34', component.options)
    } else {
      api.reload('0d960b34', component.options)
    }
    module.hot.accept("./callTopBar.vue?vue&type=template&id=0d960b34&scoped=true", function () {
      api.rerender('0d960b34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/content/calls/callTopBar.vue"
export default component.exports