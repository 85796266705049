var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "paymentModal",
      attrs: {
        persistent: "",
        "max-width": "570",
        "hide-overlay": "",
        attach: "#videos"
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
      },
      model: {
        value: _vm.showRequestPaymentModal,
        callback: function($$v) {
          _vm.showRequestPaymentModal = $$v
        },
        expression: "showRequestPaymentModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.paymentModal.titleRequestPayment"
                ),
                closeModalFunction: _vm.closeModal,
                actualDate: _vm.actualDate
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "v-row",
                  { staticClass: "mx-3 mb-4 mt-5" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "mb-2", attrs: { cols: "12" } },
                      [
                        _c("v-text-field", {
                          class: { borderError: _vm.customerNumber == "" },
                          attrs: {
                            label: _vm.$t(
                              "components.paymentModal.customerNumber"
                            ),
                            outlined: "",
                            dense: "",
                            "hide-details": "auto"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.customerNumber,
                            callback: function($$v) {
                              _vm.customerNumber = $$v
                            },
                            expression: "customerNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "mb-2", attrs: { cols: "12" } },
                      [
                        _c("v-combobox", {
                          attrs: {
                            items: _vm.dataCombobox.users,
                            select: "",
                            "item-text": "name",
                            "item-value": "uuid",
                            label: _vm.$t(
                              "components.paymentModal.conversationWith"
                            ),
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.selectedUser,
                            callback: function($$v) {
                              _vm.selectedUser = $$v
                            },
                            expression: "selectedUser"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "mb-2 pt-0", attrs: { cols: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.$t("components.paymentModal.note"),
                            outlined: "",
                            dense: "",
                            "hide-details": "auto",
                            maxlength: "250"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.note,
                            callback: function($$v) {
                              _vm.note = $$v
                            },
                            expression: "note"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", {
                      staticClass: "mb-0 py-0 pt-0",
                      attrs: { cols: "5" }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "mb-0 py-0 pt-0", attrs: { cols: "4" } },
                      [
                        _c("v-checkbox", {
                          staticClass: "mt-0 inoutUpToOption",
                          attrs: {
                            label: _vm.$t(
                              "components.paymentModal.upToCheckBox"
                            )
                          },
                          model: {
                            value: _vm.uptoOption,
                            callback: function($$v) {
                              _vm.uptoOption = $$v
                            },
                            expression: "uptoOption"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", {
                      staticClass: "mb-0 py-0 pt-0",
                      attrs: { cols: "3" }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "mb-2 pt-0", attrs: { cols: "5" } },
                      [
                        _c("v-combobox", {
                          attrs: {
                            items: _vm.getProducts,
                            select: "",
                            "item-value": "productId",
                            "item-text": "productName",
                            "return-object": "",
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("components.paymentModal.product")
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            },
                            change: _vm.selectProduct
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "itemName" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(data.item.productName) +
                                          " -\n                  " +
                                          _vm._s(
                                            _vm.formatProductPrice(
                                              data.item.productPrice
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                      _vm.state.namespaceSettings.currency ===
                                      "EUR"
                                        ? _c("font-awesome-icon", {
                                            style: { fontSize: "15px" },
                                            attrs: {
                                              icon: ["fal", "euro-sign"]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.state.namespaceSettings.currency ===
                                      "GBP"
                                        ? _c("font-awesome-icon", {
                                            style: { fontSize: "15px" },
                                            attrs: {
                                              icon: ["far", "pound-sign"]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.state.namespaceSettings.currency ===
                                      "CHF"
                                        ? _c("v-img", {
                                            attrs: {
                                              contain: "",
                                              height: "20",
                                              src:
                                                "img/paymentIcons/swiss-franc-black.png",
                                              "lazy-src":
                                                "img/paymentIcons/swiss-franc.png"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.selectedProduct,
                            callback: function($$v) {
                              _vm.selectedProduct = $$v
                            },
                            expression: "selectedProduct"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "mb-2 pt-0", attrs: { cols: "4" } },
                      [
                        _c(
                          "v-text-field",
                          {
                            class:
                              "inputWithPrepend " +
                              (_vm.uptoOption ? "uptoOption" : ""),
                            attrs: {
                              outlined: "",
                              dense: "",
                              min: 5,
                              type: "number",
                              label: _vm.$t(
                                "components.paymentModal.callDuration"
                              ),
                              "hide-details": "auto"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                              }
                            },
                            model: {
                              value: _vm.callDuration,
                              callback: function($$v) {
                                _vm.callDuration = $$v
                              },
                              expression: "callDuration"
                            }
                          },
                          [
                            _vm.uptoOption
                              ? _c("template", { slot: "prepend-inner" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.paymentModal.upToInput"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.uptoOption
                              ? _c("template", { slot: "append" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("literals.Minutes")))
                                  ])
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "mb-2 pt-0", attrs: { cols: "3" } },
                      [
                        _c("v-text-field", {
                          class: {
                            borderError:
                              _vm.totalAmount == "" || _vm.totalAmount === 0
                          },
                          attrs: {
                            max: "10000",
                            rules: _vm.amountRules,
                            label: _vm.$t("components.paymentModal.price"),
                            outlined: "",
                            dense: "",
                            type: "number",
                            "hide-details": "auto"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            },
                            change: _vm.checkAmountValue,
                            keyup: _vm.checkAmountValue
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _vm.state.namespaceSettings.currency === "EUR"
                                    ? _c("font-awesome-icon", {
                                        staticClass: "mt-1",
                                        style: { fontSize: "15px" },
                                        attrs: { icon: ["fal", "euro-sign"] }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.state.namespaceSettings.currency === "GBP"
                                    ? _c("font-awesome-icon", {
                                        staticClass: "mt-1",
                                        style: { fontSize: "15px" },
                                        attrs: { icon: ["far", "pound-sign"] }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.state.namespaceSettings.currency === "CHF"
                                    ? _c("v-img", {
                                        attrs: {
                                          contain: "",
                                          height: "20",
                                          src:
                                            "img/paymentIcons/swiss-franc-black.png",
                                          "lazy-src":
                                            "img/paymentIcons/swiss-franc.png"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.totalAmount,
                            callback: function($$v) {
                              _vm.totalAmount = $$v
                            },
                            expression: "totalAmount"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-card",
              {
                staticClass: "py-2 pl-4 pr-2 footerModal",
                attrs: { color: "primary" }
              },
              [
                _c(
                  "v-row",
                  { staticClass: "px-2 btns w-100 mx-0 my-0" },
                  [
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        class:
                                          "mr-2 buttonIconPrimaryFooter " +
                                          (_vm.disableSendButton
                                            ? "buttonIconPrimaryFooterDisable"
                                            : ""),
                                        attrs: {
                                          outlined: "",
                                          loading: _vm.loadingButton,
                                          disabled: _vm.disableSendButton,
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.sendPaymentRequestToCustomer()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.paymentModal.buttonSend"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.paymentModal.send"))
                            )
                          ])
                        ]
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }