var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    { attrs: { fixed: "", value: _vm.activeBtn, grow: "", color: "teal" } },
    [
      _c(
        "div",
        { staticClass: "footerElement" },
        [
          _c(
            "v-btn",
            {
              staticClass: "fullHeight",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.goToPage("users")
                }
              }
            },
            [
              _vm.currentPage("/users")
                ? _c("font-awesome-icon", {
                    staticClass: "primary--text",
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fas", "user"] }
                  })
                : _c("font-awesome-icon", {
                    staticClass: "primary--text",
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fal", "user"] }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footerElement" },
        [
          _c(
            "v-btn",
            {
              staticClass: "fullHeight",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.goToPage("my-favorites")
                }
              }
            },
            [
              _vm.currentPage("/my-favorites")
                ? _c("font-awesome-icon", {
                    staticClass: "primary--text",
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fas", "star"] }
                  })
                : _c("font-awesome-icon", {
                    staticClass: "primary--text",
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fal", "star"] }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footerElement" },
        [
          _vm.showWaitingRoom
            ? _c(
                "v-badge",
                {
                  staticClass: "waitingRoomBadge",
                  attrs: {
                    right: "",
                    color: "error",
                    content: _vm.getconnecetedWaitingrooms,
                    value: _vm.getconnecetedWaitingrooms,
                    bordered: "",
                    overlap: "",
                    "offset-x": "36",
                    "offset-y": "23"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fullHeight",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.goToPage("waitingroom")
                        }
                      }
                    },
                    [
                      _vm.currentPage("/waitingroom")
                        ? _c("font-awesome-icon", {
                            staticClass: "primary--text",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fas", "users-class"] }
                          })
                        : _c("font-awesome-icon", {
                            staticClass: "primary--text",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "users-class"] }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footerElement" },
        [
          _c(
            "v-dialog",
            {
              attrs: {
                "hide-overlay": "",
                transition: "dialog-bottom-transition"
              },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  _vm.userCard = false
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _vm.state.user.avatar ===
                      "img/default_profile_picture.png"
                        ? _c(
                            "DefaultAvatar",
                            _vm._g(
                              {
                                attrs: {
                                  size: 30,
                                  setUserInModal: _vm.setUserInModal,
                                  userData: _vm.state.user
                                }
                              },
                              on
                            )
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.user.avatar !==
                      "img/default_profile_picture.png"
                        ? _c(
                            "v-img",
                            _vm._g(
                              {
                                staticClass: "borderRadius50 footerImg",
                                attrs: {
                                  "max-height": "30",
                                  "max-width": "30",
                                  contain: _vm.getPictureSize,
                                  src: _vm.state.user.avatar,
                                  "lazy-src":
                                    "assets/default_profile_picture.png"
                                }
                              },
                              on
                            )
                          )
                        : _vm._e()
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.userCard,
                callback: function($$v) {
                  _vm.userCard = $$v
                },
                expression: "userCard"
              }
            },
            [
              _vm._v(" "),
              _c("DrawerContentCard", { ref: "drawerContentCardRef" })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }