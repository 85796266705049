var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "v-tour-navBar" } },
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex justify-center flex-wrap py-1",
          attrs: {
            height: "30px",
            color: _vm.getColornavbarIcon,
            flat: "",
            tile: ""
          }
        },
        [
          _vm.state.namespaceSettings.notes.length > 0 &&
          this.state.namespaceSettings.featureInfoBoard
            ? _c(
                "v-card",
                {
                  staticClass: "pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3",
                  staticStyle: { height: "25px" },
                  attrs: { color: _vm.getColornavbarIcon, flat: "", tile: "" }
                },
                [
                  _vm.unreadInfoNotes > 0
                    ? _c("v-badge", {
                        staticStyle: { zindex: "9" },
                        attrs: {
                          right: "",
                          color: "error",
                          content: _vm.unreadInfoNotes,
                          value: _vm.unreadInfoNotes,
                          bordered: "",
                          overlap: "",
                          "offset-x": "-19",
                          "offset-y": "-4"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticClass: "cursorPointer",
                                      attrs: { id: "v-tour-infoBoardNavbar" },
                                      on: {
                                        click: function($event) {
                                          _vm.gotoPage("infoboard")
                                          _vm.handleNewInfoNote()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.currentPage("/infoboard")
                                      ? _c("font-awesome-icon", {
                                          staticClass: "primary--text",
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fas", "sticky-note"]
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.currentPage("/infoboard")
                                      ? _c("font-awesome-icon", {
                                          staticClass: "primary--text",
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "sticky-note"]
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        826178243
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generics.infoboard")))])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }