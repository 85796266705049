var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "messagesWrapper", attrs: { "two-line": "" } },
    [
      _c(
        "div",
        [
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "primary--text" } },
            _vm._l(_vm.messageArray, function(message, index) {
              return _c(
                "div",
                { key: index },
                [
                  message
                    ? _c(
                        "v-list-item",
                        {
                          key: index,
                          on: {
                            click: function($event) {
                              return _vm.openNotification(message.messageUUID)
                            }
                          }
                        },
                        [
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                staticClass: "borderRadius10 cursorPointer",
                                style: {
                                  border:
                                    "2px solid " +
                                    _vm.setBorderByStatus(
                                      _vm.state.group[message.creatorUUID]
                                    )
                                },
                                attrs: { tile: "", size: "60" }
                              },
                              [
                                _vm.getAvatarForUuid(message.creatorUUID) ==
                                "img/default_profile_picture.png"
                                  ? _c("DefaultAvatarGrid", {
                                      attrs: {
                                        size: 120,
                                        userData:
                                          _vm.state.group[message.creatorUUID]
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getAvatarForUuid(message.creatorUUID) !=
                                "img/default_profile_picture.png"
                                  ? _c("v-img", {
                                      staticClass: "mx-2 borderRadius10",
                                      attrs: {
                                        "max-height": "60",
                                        "max-width": "60",
                                        contain: "",
                                        src: _vm.getAvatarForUuid(
                                          message.creatorUUID
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  class:
                                    message.type === "message" &&
                                    !message.users[_vm.ownUUID].read
                                      ? "font-weight-bold textBlue"
                                      : "",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.getNameForUuid(message.creatorUUID)
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-list-item-subtitle", {
                                  staticClass: "text--primary",
                                  domProps: {
                                    textContent: _vm._s(
                                      message.type === "call"
                                        ? _vm.$t(
                                            "components.notificationsModal.missedCall"
                                          )
                                        : message.body || "New message"
                                    )
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-list-item-action-text", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.formatNoticationDate(message.date)
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }