var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      attrs: { "max-width": "320" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
      }
    },
    [
      !_vm.isGuest && !_vm.mini
        ? _c("v-list-group", {
            attrs: { "active-class": "listColor", appendIcon: null },
            on: {
              click: function($event) {
                return _vm.handlerClickSupport($event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function() {
                    return [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "primary--text-sidepanel",
                            attrs: { icon: ["fal", "headset"] }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-list-item-title",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "primary--text-sidepanel",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.handlerClickSupport()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("generics.support")) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2742535690
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.support")))
                          ])
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1569334160
            )
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handlerClickSupport()
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "primary--text-sidepanel",
                                  attrs: { icon: ["fal", "headset"] }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1837806818
              )
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.support")))])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }