var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show2faStatus
        ? _c("TwofaModal", {
            attrs: {
              showModal: _vm.show2faStatus,
              toggle2Modal2faSetting: _vm.toggle2Modal2faSetting,
              goToModal: _vm.goToModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show2faOtp
        ? _c("TwofaOtp", {
            attrs: { showModal: _vm.show2faOtp, toggle2faOtp: _vm.toggle2faOtp }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show2faPin
        ? _c("TwofaPin", {
            attrs: { showModal: _vm.show2faPin, toggle2faPin: _vm.toggle2faPin }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show2faSms
        ? _c("TwofaSms", {
            attrs: { showModal: _vm.show2faSms, toggle2faSms: _vm.toggle2faSms }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show2faDisable
        ? _c("TwofaDisable", {
            attrs: {
              showModal: _vm.show2faDisable,
              toggle2faDisable: _vm.toggle2faDisable,
              goToModal: _vm.goToModal,
              redirectTo: _vm.redirectTo
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }