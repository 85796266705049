import { render, staticRenderFns } from "./organisationRegularGrid.vue?vue&type=template&id=72bc920c&scoped=true"
import script from "./organisationRegularGrid.vue?vue&type=script&lang=js"
export * from "./organisationRegularGrid.vue?vue&type=script&lang=js"
import style0 from "./organisationRegularGrid.vue?vue&type=style&index=0&id=72bc920c&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72bc920c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/voffice-dev04/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72bc920c')) {
      api.createRecord('72bc920c', component.options)
    } else {
      api.reload('72bc920c', component.options)
    }
    module.hot.accept("./organisationRegularGrid.vue?vue&type=template&id=72bc920c&scoped=true", function () {
      api.rerender('72bc920c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/content/organisationRegularGrid.vue"
export default component.exports