var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "px-0 py-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("div", { staticClass: "n pa-4" }, [
                    _c("span", { staticClass: "subtitle-1 font-weight-bold" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getTitelForUuid(_vm.selectedUser)) +
                          "\n            " +
                          _vm._s(_vm.getNameForUuid(_vm.selectedUser)) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  [
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.dailyReports,
                        "items-per-page": 5,
                        page: _vm.page,
                        "fixed-header": "",
                        height: "calc(100vh - 365px)"
                      },
                      on: {
                        "update:page": function($event) {
                          _vm.page = $event
                        },
                        "page-count": function($event) {
                          _vm.pageCount = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.report",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$sanitize(_vm.checkForUrls(item.report))
                                  )
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "item.characters",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [_vm._v(_vm._s(item.report.length))])
                            ]
                          }
                        }
                      ])
                    })
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }