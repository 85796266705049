var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    _vm._l(_vm.sortedGroups, function(group) {
      return _c(
        "v-list-item",
        { key: group.id },
        [
          _c("v-checkbox", {
            staticClass: "mencheckbox departments cbNoteHead",
            attrs: {
              items: _vm.sortedGroups,
              label: group.title || group.newGroupName,
              value: group.id,
              "return-object": ""
            },
            model: {
              value: _vm.state.groupSelected,
              callback: function($$v) {
                _vm.$set(_vm.state, "groupSelected", $$v)
              },
              expression: "state.groupSelected"
            }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }