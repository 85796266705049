var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-2 primaryBorder",
                    attrs: {
                      outlined: "",
                      small: "",
                      depressed: "",
                      color: "default"
                    }
                  },
                  on
                ),
                [
                  _c("v-img", {
                    staticClass: "borderRadius10",
                    attrs: {
                      "max-height": "23",
                      "max-width": "17",
                      src: "img/flags/" + _vm.language + ".png"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "notranslate primary--text" }, [
                    _vm._v(_vm._s(_vm.language))
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            _vm._l(_vm.locales, function(locale) {
              return _c(
                "v-tooltip",
                {
                  key: locale.code,
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-list-item",
                              _vm._g(
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLanguage(locale.code)
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-list-item-avatar",
                                  {
                                    attrs: {
                                      tile: "",
                                      width: "23",
                                      height: "17",
                                      "min-width": "23",
                                      "max-height": "17",
                                      "max-width": "23"
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: "img/flags/" + locale.code + ".png"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "notranslate primary--text" },
                                  [_vm._v(_vm._s(locale.code))]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_vm._v(" "), _c("span", [_vm._v(_vm._s(locale.name))])]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }