var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-menu", {
        attrs: {
          "offset-y": "",
          "close-on-click": true,
          "max-height": 370,
          "max-width": 350
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "div",
                  _vm._g({}, on),
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      {
                                        staticClass: "cursorPointer",
                                        on: {
                                          click: function($event) {
                                            return _vm.openCloseModalDirectLink()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm.iconsBlack
                                        ? _c("font-awesome-icon", {
                                            staticClass: "primary--text",
                                            style: { fontSize: "18px" },
                                            attrs: {
                                              icon: ["fas", "user-friends"]
                                            }
                                          })
                                        : _c("font-awesome-icon", {
                                            staticClass: "primary--text",
                                            style: { fontSize: "18px" },
                                            attrs: {
                                              icon: ["fal", "user-friends"]
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.drawerContentCard.directLink")
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm.showModalDirectLink
        ? _c("DirectLinkInvitationModal", {
            attrs: {
              showModalDirectLink: _vm.showModalDirectLink,
              openCloseModalDirectLink: _vm.openCloseModalDirectLink
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }