var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isRecorder
      ? _c(
          "div",
          { class: _vm.getRightClass },
          [_c("BridgeCallGrid", { attrs: { isRecorder: _vm.isRecorder } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isRecorder
      ? _c(
          "div",
          { staticStyle: { height: "calc(100vh - 8px)" } },
          [_c("BridgeCallGrid_rec", { attrs: { isRecorder: _vm.isRecorder } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }