var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _vm.loaded
        ? [
            _c(
              "v-card",
              { staticClass: "mx-auto" },
              [
                _c("HeaderModal", {
                  attrs: {
                    titleModal: _vm.$t("components.paypal.title"),
                    closeModalFunction: _vm.closeModal
                  }
                }),
                _vm._v(" "),
                _c(
                  "v-container",
                  [
                    _c("v-text-field", {
                      attrs: {
                        autocomplete: "false",
                        dense: "",
                        outlined: "",
                        label: _vm.$t("components.modalPayPalSettings.APIUser")
                      },
                      model: {
                        value: _vm.APIUser,
                        callback: function($$v) {
                          _vm.APIUser = $$v
                        },
                        expression: "APIUser"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        autocomplete: "false",
                        dense: "",
                        outlined: "",
                        label: _vm.$t(
                          "components.modalPayPalSettings.APIPassword"
                        ),
                        type: "password"
                      },
                      model: {
                        value: _vm.APIPassword,
                        callback: function($$v) {
                          _vm.APIPassword = $$v
                        },
                        expression: "APIPassword"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-between align-baseline"
                      },
                      [
                        _c(
                          "p",
                          { staticStyle: { "word-break": "break-word" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "components.modalPayPalSettings.yourWebHookURL"
                                  )
                                ) +
                                ":\n            " +
                                _vm._s(_vm.webhookURL) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.openInfoPaypalSettings()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("generics.info")) + "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-text-field", {
                      staticStyle: { height: "50px" },
                      attrs: {
                        autocomplete: "false",
                        dense: "",
                        outlined: "",
                        label: _vm.$t(
                          "components.modalPayPalSettings.APISignature"
                        ),
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.APISignature,
                        callback: function($$v) {
                          _vm.APISignature = $$v
                        },
                        expression: "APISignature"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c("p", { staticClass: "my-0" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://helpcenter.voffice.pro/paypalsetup",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.modalPayPalSettings.helpLink"
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "my-0" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://developer.paypal.com/developer/applications/",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.modalPayPalSettings.developerLink"
                                )
                              )
                            )
                          ]
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "FooterModal",
                  { attrs: { closeModalFunction: _vm.closeModal } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          loading: _vm.savingPayPalSettings,
                          disabled: !_vm.paypalSettingsFilled
                        },
                        on: { click: _vm.savePayPalSettings }
                      },
                      [_vm._v(_vm._s(_vm.$t("generics.save")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }