var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.room
    ? _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c(
            "div",
            { staticClass: "text-truncate", staticStyle: { width: "100%" } },
            [
              _c("label", { staticClass: "ml-2 userName font-weight-medium" }, [
                _vm._v(
                  _vm._s(_vm.$t("components.sambaRooms.totalUsers")) +
                    ": " +
                    _vm._s(_vm.getTotalUsers)
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.room.roomId
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    scrollable: "",
                    "close-on-click": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "groupCardEllipses",
                                  attrs: { icon: "" }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "ellipsis-v"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    871106781
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      !_vm.room.isFavorite
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.addRoomToFavorites(_vm.room)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.sambaRooms.addToFavorites"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.room.isFavorite
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.removeRoomFromFavorites(_vm.room)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.sambaRooms.removeFromFavorites"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEditRoom && _vm.isAdmin(_vm.ownUUID)
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.editRoom(_vm.room)
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.sambaRooms.editRoom")
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canRemoveRoom && _vm.isAdmin(_vm.ownUUID)
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.removeRoom(_vm.room, $event)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  class: !_vm.deleteActive
                                    ? "deleteBasketButtonNormal"
                                    : "deleteBasketButtonActive"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.sambaRooms.removeRoom"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }