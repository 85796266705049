var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelModalEventTypeFunction.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModalEventType,
        callback: function($$v) {
          _vm.showModalEventType = $$v
        },
        expression: "showModalEventType"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.meetingLineModal.eventType"),
                closeModalFunction: _vm.cancelModalEventTypeFunction
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "mx-0" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "mt-0",
                    attrs: { row: "", dense: "" },
                    on: { change: _vm.changeEventType },
                    model: {
                      value: _vm.typeVideoCall,
                      callback: function($$v) {
                        _vm.typeVideoCall = $$v
                      },
                      expression: "typeVideoCall"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mb-0 mx-0" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "max-width": "300" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-radio",
                                          _vm._g(
                                            {
                                              attrs: { value: "directCall" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        !_vm.$vuetify.theme.dark
                                                          ? _c(
                                                              "v-img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    src:
                                                                      "img/direct-call-b.svg",
                                                                    "max-height":
                                                                      "40",
                                                                    "max-width":
                                                                      "40",
                                                                    width: "40",
                                                                    height:
                                                                      "40",
                                                                    contain: ""
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _c(
                                                              "v-img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    src:
                                                                      "img/direct-call-b-white.svg",
                                                                    "max-height":
                                                                      "40",
                                                                    "max-width":
                                                                      "40",
                                                                    width: "40",
                                                                    height:
                                                                      "40",
                                                                    contain: ""
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.callsContent.meetingCall"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.callsContent.direktCallTT"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "max-width": "300" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-radio",
                                          _vm._g(
                                            {
                                              attrs: { value: "bridgeCall" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        !_vm.$vuetify.theme.dark
                                                          ? _c(
                                                              "v-img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    src:
                                                                      "img/direct-call-c.svg",
                                                                    "max-height":
                                                                      "40",
                                                                    "max-width":
                                                                      "40",
                                                                    width: "40",
                                                                    height:
                                                                      "40",
                                                                    contain: ""
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _c(
                                                              "v-img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    src:
                                                                      "img/direct-call-c-white.svg",
                                                                    "max-height":
                                                                      "40",
                                                                    "max-width":
                                                                      "40",
                                                                    width: "40",
                                                                    height:
                                                                      "40",
                                                                    contain: ""
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.callsContent.conferenceCall"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.callsContent.bridgeCallTT"
                                      )
                                    ) + "\n                "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.cancelModalEventTypeFunction }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: { click: _vm.closeModalEventTypeFunction }
                  },
                  [_vm._v(_vm._s(_vm.$t("generics.ok")))]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }