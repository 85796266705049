var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "sandbox" } },
    [
      (!_vm.isMobile
      ? _vm.showSidebar
      : _vm.showSidebarMobile)
        ? _c("Drawer", {
            attrs: {
              primaryDrawer: _vm.primaryDrawer,
              mini: _vm.mini,
              openCloseSidebar: _vm.openCloseSidebar,
              openSidebar: _vm.openSidebar
            }
          })
        : _vm._e(),
      _vm._v(" "),
      (!_vm.isMobile
      ? _vm.showNavbar
      : _vm.showNavbarMobile)
        ? _c("NavBarNew", {
            attrs: {
              mini: _vm.mini,
              openCloseSidebar: _vm.openCloseSidebar,
              primaryDrawer: _vm.primaryDrawer,
              isLarge: _vm.isLarge,
              isSmall: _vm.isSmall,
              isXSmall: _vm.isXSmall
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("AlertContainer"),
      _vm._v(" "),
      !_vm.showSetUp && !_vm.isOnlyOneCallUser
        ? _c("ContentContainer", {
            attrs: {
              openCloseSidebar: _vm.openCloseSidebar,
              primaryDrawer: _vm.primaryDrawer,
              mini: _vm.mini
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSetUp && !_vm.isOnlyOneCallUser ? _c("SetupContainer") : _vm._e(),
      _vm._v(" "),
      _vm.showSambaCall ? _c("SambaCall") : _vm._e(),
      _vm._v(" "),
      _vm.showP2pCall ? _c("P2pCall") : _vm._e(),
      _vm._v(" "),
      _vm.state.showModalEndMeeting && _vm.isOnlyOneCallUser
        ? _c("EndMeetingTimeModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.isOnlyOneCallUser
        ? _c("VisitorPanel", { staticClass: "visitorPanel" })
        : _vm._e(),
      _vm._v(" "),
      _vm.showFooterMobile ? _c("FooterMobile") : _vm._e(),
      _vm._v(" "),
      _c("DevPanel"),
      _vm._v(" "),
      _c("v-tour", {
        attrs: {
          name: "myTour",
          steps: _vm.steps,
          callbacks: _vm.tourCallBacks,
          options: _vm.tourOptions
        }
      }),
      _vm._v(" "),
      !_vm.isNotificationMuted ? [_c("WaitingRoomNotification")] : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }