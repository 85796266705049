var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "buttonColor",
      attrs: { id: "returnToCall" },
      on: { click: _vm.goBackToCall }
    },
    [
      _c("font-awesome-icon", {
        staticClass: "whiteColor",
        style: { fontSize: "16px" },
        attrs: { icon: ["fal", "undo"] }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "pl-1 whiteColor" }, [
        _vm._v(_vm._s(_vm.$t("components.returnToCall.returnToCall")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }