var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.isGuest
        ? _c(
            "draggable",
            {
              model: {
                value: _vm.modifiedNotes,
                callback: function($$v) {
                  _vm.modifiedNotes = $$v
                },
                expression: "modifiedNotes"
              }
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "card" } },
                _vm._l(_vm.modifiedNotes, function(oneNote, i) {
                  return _c(
                    "v-col",
                    {
                      key: "divnote-" + i,
                      staticClass: "noteDiv px-1 col-12",
                      staticStyle: { height: "300px" },
                      attrs: { draggable: "true", md: 4, sm: 4 }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "note-" + i } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto",
                              attrs: { outlined: "", flat: "" }
                            },
                            [
                              _vm.showUserProfile
                                ? _c("UserProfle", {
                                    attrs: {
                                      showUserProfile: _vm.showUserProfile,
                                      toggleUserProfile: _vm.toggleUserProfile
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  class: [oneNote.noteColor],
                                  attrs: { "three-line": "" }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    {
                                      class: _vm.isDark
                                        ? "noteListItemBlack"
                                        : "noteListItem",
                                      staticStyle: { height: "250px" }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between",
                                          class: _vm.isDark
                                            ? "footerDate"
                                            : "footerDateBlack",
                                          staticStyle: { "font-size": "14px" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setUserInModal(
                                                oneNote.author
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "  " +
                                                _vm._s(
                                                  _vm.getDateFormatted(
                                                    oneNote.date,
                                                    true
                                                  ) +
                                                    " " +
                                                    _vm.$t("literals.by") +
                                                    " " +
                                                    ((
                                                      (
                                                        _vm.state.group[
                                                          oneNote.author
                                                        ] || {}
                                                      ).user || {}
                                                    ).titel &&
                                                    _vm.state.group[
                                                      oneNote.author
                                                    ].user.titel !== "unknown"
                                                      ? _vm.state.group[
                                                          oneNote.author
                                                        ].user.titel
                                                      : "") +
                                                    " " +
                                                    oneNote.authorName || ""
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getNoteViews(oneNote.id)
                                              ) + " Views"
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.getNoteRData(oneNote) === 1
                                        ? _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "font-weight": "600"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.singleNote.todepartment"
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    oneNote.targetDepartments[0]
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.state.namespaceSettings
                                                      .companyInfo
                                                      .virtualOfficePrefix
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm.getNoteRData(oneNote) === 2
                                        ? _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "font-weight": "600"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.singleNote.tosection"
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    oneNote.targetSections[0]
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.state.namespaceSettings
                                                      .companyInfo
                                                      .virtualOfficePrefix
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm.getNoteRData(oneNote) === 3
                                        ? _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "font-weight": "600"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.singleNote.toteam"
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    oneNote.targetTeams[0]
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.state.namespaceSettings
                                                      .companyInfo
                                                      .virtualOfficePrefix
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm.getNoteRData(oneNote) === 4
                                        ? _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "font-weight": "600",
                                                color: "black!important"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.singleNote.toallusers"
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.state.namespaceSettings
                                                      .companyInfo
                                                      .virtualOfficePrefix
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "noBottom",
                                          class: { "my-2": _vm.editId !== i }
                                        },
                                        [
                                          _vm.edit && _vm.editId == i
                                            ? _c("v-text-field", {
                                                attrs: { rules: _vm.rules },
                                                model: {
                                                  value: oneNote.title,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      oneNote,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "oneNote.title"
                                                }
                                              })
                                            : _c(
                                                "label",
                                                {
                                                  staticClass: "labelBold",
                                                  class: {
                                                    isDone: oneNote.completed
                                                  }
                                                },
                                                [_vm._v(_vm._s(oneNote.title))]
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass: "noTop",
                                          class: {
                                            "my-2": _vm.editId !== i,
                                            blackText: _vm.isDark
                                          }
                                        },
                                        [
                                          _vm.edit && _vm.editId == i
                                            ? _c("v-textarea", {
                                                attrs: {
                                                  name: "input-7-1",
                                                  "no-resize": ""
                                                },
                                                model: {
                                                  value: oneNote.text,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      oneNote,
                                                      "text",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "oneNote.text"
                                                }
                                              })
                                            : _c("p", {
                                                class: {
                                                  expand: _vm.idToExpand == i,
                                                  isDone: oneNote.completed
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.modifiedText(i)
                                                  )
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                { staticClass: "primary noteFooter" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-start": "" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    !_vm.edit && _vm.amIAdmin
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleEditNote(
                                                                    i,
                                                                    oneNote
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                style: {
                                                                  fontSize:
                                                                    "20px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "pen"
                                                                  ]
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("generics.edit"))
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _vm.amIAdmin
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "20px"
                                                              },
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("DeleteBasket", {
                                                              attrs: {
                                                                indexData: [
                                                                  _vm.$event,
                                                                  oneNote.id,
                                                                  i
                                                                ],
                                                                delFunction:
                                                                  _vm.showModalDelete,
                                                                extraClasses:
                                                                  "",
                                                                fontSize: "20",
                                                                prevent: "false"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("generics.remove"))
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.amIAdmin && 1 === 2
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleTransition(i)
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "palette"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit && _vm.editId == i
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateNote(
                                                    i,
                                                    oneNote
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "check"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-end": "" } },
                                    [
                                      oneNote.activeUntil !== undefined &&
                                      oneNote.activeUntil !== ""
                                        ? _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                "margin-right": "42px",
                                                "line-height": "2.5em",
                                                color: "white"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.modalNote.postingUntil"
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.getDateFormatted(
                                                      oneNote.activeUntil,
                                                      false
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.noteIsLong(i)
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                justify: "end",
                                                icon: "",
                                                color: "white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openModalNote(
                                                    oneNote,
                                                    false
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: 20 },
                                                attrs: {
                                                  icon: ["fal", "expand-alt"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "460" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModalDeleteNote,
            callback: function($$v) {
              _vm.showModalDeleteNote = $$v
            },
            expression: "showModalDeleteNote"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.singleNote.deleteNote")) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "Are you sure you want to remove this note? You can not undo this action."
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "8", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.closeModal }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteNote()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.delete")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }