var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", [
      _vm._v(
        "Effect " +
          _vm._s(_vm.name) +
          " -- Working: " +
          _vm._s(_vm.pendingBool) +
          " " +
          _vm._s(_vm.inFlightAmount) +
          " Done: " +
          _vm._s(_vm.doneCounter) +
          " Fail: " +
          _vm._s(_vm.failCounter) +
          " Finally: " +
          _vm._s(_vm.finallyCounter)
      )
    ]),
    _vm._v(" "),
    _vm.failLastError
      ? _c("div", [
          _c("span", [_vm._v("Last Error:")]),
          _vm._v(" "),
          _c("pre", [
            _vm._v(_vm._s(JSON.stringify(_vm.failLastParams, null, 2)))
          ]),
          _vm._v(" "),
          _c("pre", [
            _vm._v(_vm._s(JSON.stringify(_vm.failLastError.message, null, 2)))
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }