<template>
<div>
  <div
    :class="customTop ? 'iconCardsContainerRightCustom' : 'iconCardsContainerRight'"
  >
    <template v-if="(person||{}).connected && !isBasicView">
      <LocationIcon
        :person="person"
        :defaultAvatar="hasUserImage(person.uuid)"
        :isRegularGrid="isRegularGrid"
      ></LocationIcon>
    </template>
    <template v-if="showCallButton && !isBasicView"> 
      <CallIcon
        :person="person"
        :defaultAvatar="hasUserImage(person.uuid)"
        :isRegularGrid="isRegularGrid"
      ></CallIcon>
    </template>
  </div>
    <div
      :class="customTop ? 'iconCardsContainerLeftCustom' : 'iconCardsContainerLeft'"
      v-if="showAddMultiUserCall"
    >
      <MultiUserCallIconGrid :person="person"/>
    </div>
</div>
</template>
<script>
import store from "../../store";
import {
  getPictureSize,
  hasAdditionalStatus,
  getAvatarByType,
  hasUserImage,
  canIseeSpeechliveIcon,
  userHasSpeechLiveActivated,
} from "../../utils/basicFunctions";
import { basicView } from "../../utils/privileges";
import LocationIcon from "./locationIcon.vue";
import MultiUserCallIconGrid from "./multiUserCallIconGrid.vue"
import CustomStatusIcon from "./customStatusIcon.vue";
import CallBackIcon from "./callBackIcon.vue";
import SpeechLiveIcon from "./speechLiveIcon.vue";
import CallIcon from "./callIcon.vue";
import { watchingList } from "../../effector/presence";
import { userInCall, userInStaticRoom, amInABridgeCall } from "../../utils/calls"
export default {
  components: {
    LocationIcon,
    CustomStatusIcon,
    SpeechLiveIcon,
    CallIcon,
    CallBackIcon,
    MultiUserCallIconGrid
  },
  effector: {
    watchingList: watchingList,
  },
  props: ["person", "isRegularGrid", "showMultiUserCallIcon", "isOrganigram", "customTop"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  methods: {
    userInCall(uuid){
      return userInCall(uuid)
    },
    userHasSpeechLiveActivated(person) {
      return userHasSpeechLiveActivated(person);
    },
    hasUserImage(uuid) {
      return hasUserImage(uuid);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    hasAdditionalStatus(person) {
      return hasAdditionalStatus(person);
    },
  },
  computed: {
    showCallButton() {
      const uuid = this.person?.uuid || this.person?.user?.uuid;
      if (this.isOrganigram && uuid && this.person.connected && uuid !== this.ownUUID) return true;
      return false;
    },
    showAddMultiUserCall() {
      return this.person?.connected &&
        this.person.user &&
        this.person.user.uuid!==this.ownUUID &&
        !(userInCall(this.person.user.uuid) && !userInStaticRoom(this.person.user.uuid)) &&
        this.person.user.activity !== 'Out of Office' &&
        this.person.user.activity !== 'No Calls' &&
        this.person.user.activity !== 'No status' &&
        this.person.user.activity !== 'Break' &&
        this.person.user.activity !== 'Only phone' &&
        !this.amInABridgeCall &&
        this.showMultiUserCallIcon;
    },
    amInABridgeCall() {
      return amInABridgeCall();
    },
    canIseeSpeechliveIcon() {
      return canIseeSpeechliveIcon();
    },
    getPictureSize() {
      return getPictureSize();
    },
    isBasicView() {
      return basicView();
    },
  },
};
</script>
<style scoped lang="scss">
.iconCardsContainerRightCustom {
  position: absolute;
  top: 30px;
  right: 0;
  background: unset;
  height: 28px;
}
.iconCardsContainerRight {
  position: absolute;
  top: 0;
  right: 0;
  background: unset;
  height: 28px;
}
.iconCardsContainerLeftCustom {
  position: absolute;
  top: 30px;
  left: 0;
  background: unset;
  height: 28px;
}
.iconCardsContainerLeft {
  position: absolute;
  top: 0;
  left: 0;
  background: unset;
  height: 28px;
}
</style>