<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="560px"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.support')"
        :closeModalFunction="closeModal"
      />
      <v-divider></v-divider>
      <v-form
        ref="messageForm"
        v-model="valid"
        lazy-validation
        :autocomplete="false"
      >
        <v-row
          :style="{ width: '100%', margin: 0 }"
          class="d-flex flex-column px-5 my-4"
        >
          <v-row>
            <v-col class="d-flex">
              <v-combobox
                v-model="selectProblem"
                :items="itemsProblems"
                :label="$t('components.bugReport.vofficeProblem')"
                outlined
                :rules="rules"
                dense
                hide-details
                class="mr-2"
              ></v-combobox>
              <v-combobox
                hide-details
                v-model="selectBrowser"
                :items="itemsBrowser"
                :label="$t('components.bugReport.browser')"
                outlined
                :rules="rules"
                dense
                class=""
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <v-combobox
                hide-details
                v-model="selectSystem"
                :items="itemsSystem"
                :label="$t('components.bugReport.system')"
                outlined
                :rules="rules"
                dense
                class="mr-2"
              ></v-combobox>
              <v-combobox
                :rules="rules"
                hide-details
                v-model="selectNetwork"
                :items="itemsNetwork"
                :label="$t('components.bugReport.network')"
                outlined
                dense
                class=""
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex py-0">
              <v-text-field
                counter
                :label="$t('components.bugReport.location')"
                :placeholder="'Office, Home Office'"
                :rules="rules"
                maxlength="100"
                v-model="location"
                class="mr-2"
              ></v-text-field>
              <v-text-field
                counter
                :label="$t('components.bugReport.subject')"
                :rules="rules"
                maxlength="100"
                v-model="bugTitle"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex py-0">
              <v-textarea
                counter
                contenteditable="true"
                :label="$t('components.bugReport.body')"
                v-model="bugText"
                :no-resize="true"
                maxlength="5000"
                id="bugTextarea"
                @keydown.esc="closeModal"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-row>
        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="sendBug()"
            :disabled="disabledSendBug"
            >{{ $t("components.sendMessageModal.send") }}</v-btn
          >
        </FooterModal>
      </v-form>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import DialogDrag from "vue-dialog-drag";
import { createGitLabIssue } from "../../lib/wsMsg";
import {
  sendBugModalStore,
  resetSendBugModalEvent,
} from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal, DialogDrag },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    setCurrentContentVisile: store.setCurrentContentVisile,
    rules: [(v) => !!(v || "").trim() || ""],
    bugText: "",
    bugTitle: "",
    selectProblem: "",
    selectBrowser: "",
    selectSystem: "",
    selectNetwork: "",
    location: "",
    itemsProblems: ['vOffice App', 'vOffice Call'],
    itemsBrowser: ['Chrome', 'Edge', 'Firefox', 'Safari', 'Andere'],
    itemsSystem: ['Windows', 'macOS', 'Linux', 'iOS', 'Android'],
    itemsNetwork: ['Kabel' , 'WLAN', 'Hotspot'],
    valid: true,
  }),
  effector: {
    showModal: sendBugModalStore,
  },
  watch: {},
  mounted() {
    this.loadDataFromLocalstorage();
  },
  methods: {
    closeModal() {
      this.closeModalMsg();
      this.bugText = "";
      this.bugTitle = "";
      this.selectProblem = "";
      this.selectBrowser = "";
      this.selectSystem = "";
      this.selectNetwork = "";
      this.location = "";
    },
    sendBug() {
      createGitLabIssue(this.bugTitle, this.bugText, this.selectProblem, this.selectBrowser, this.selectSystem, this.selectNetwork, this.location);
      this.saveDataInToLocalstorage();
    },
    closeModalMsg() {
      resetSendBugModalEvent();
    },
    loadDataFromLocalstorage(){
      const localStorageItem = localStorage.getItem('bugModalSelections');
      if (!localStorageItem) return;
      const bugDataSelections = JSON.parse(localStorageItem);
      if (!bugDataSelections) return;
      this.selectProblem = bugDataSelections?.selectProblem || "";
      this.selectBrowser = bugDataSelections?.selectBrowser || "";
      this.selectSystem = bugDataSelections?.selectSystem || "";
      this.selectNetwork = bugDataSelections?.selectNetwork || "";
      this.location = bugDataSelections?.location || "";
    },
    saveDataInToLocalstorage(){
      const bugDataSelections = {
        selectProblem: this.selectProblem,
        selectBrowser: this.selectBrowser,
        selectSystem: this.selectSystem,
        selectNetwork: this.selectNetwork,
        location: this.location,
      };
      localStorage.setItem('bugModalSelections', JSON.stringify(bugDataSelections));
      this.closeModal();
    }
  },
  computed: {
    disabledSendBug(){
      return !this.bugTitle ||
        this.bugTitle.trim().length == 0 ||
        !this.location ||
        this.location.trim().length == 0 ||
        !this.selectProblem ||
        this.selectProblem.trim().length == 0 ||
        !this.selectBrowser ||
        this.selectBrowser.trim().length == 0 ||
        !this.selectSystem ||
        this.selectSystem.trim().length == 0 ||
        !this.selectNetwork ||
        this.selectNetwork.trim().length == 0
    }
  },
};
</script>
<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>