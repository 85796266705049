<template>
  <div style="position: absolute; width: 100%" id="counter">
    <div
      class="backImage"
      :style="{ backgroundImage: 'url(' + getWaitingRoomBg + ')' }"
    >
      <img
        id="over"
        :class="getClassTemplate"
        v-if="showLogoCompany"
        :src="showCompanyLogo"
        alt="1"
      />
    <div v-if="showText" class="black--text" :class="computedClassText">
      Waiting Area
    </div>
    <div v-if="state.user && state.user.visitorData" class="black--text text-center" :class="computedClassTitle">
     {{getWelcomeMsg}}
    </div>
    </div>
  </div>
</template>

<script>
import store from "../../../store";

export default {
  data() {
    return {
      state: store.state,
      waitingRoomWelcomeTextDE: store.state.namespaceSettings.waitingRoomWelcomeTextDE, 
      waitingRoomWelcomeTextEN: store.state.namespaceSettings.waitingRoomWelcomeTextEN, 
      waitingRoomWelcomeTextES: store.state.namespaceSettings.waitingRoomWelcomeTextES, 
    };
  },
  computed: {
    getWelcomeMsg() {
      switch (this.state.user.language) {
        case 'de':
          return this.waitingRoomWelcomeTextDE.length ? this.waitingRoomWelcomeTextDE : 'Herzlich willkommen, Ihr Video-Anruf wird in Kürze entgegengenommen.'
        case 'en':
          return this.waitingRoomWelcomeTextEN.length ? this.waitingRoomWelcomeTextEN : 'Welcome, your video call will be answered shortly.'
        case 'es':
        return this.waitingRoomWelcomeTextES.length ? this.waitingRoomWelcomeTextES : 'Bienvenido, tu videollamada será respondida en breve.'
        default:
        return this.waitingRoomWelcomeTextDE.length ? this.waitingRoomWelcomeTextDE : 'Herzlich willkommen, Ihr Video-Anruf wird in Kürze entgegengenommen.'
      }
    },
    computedClassTitle() {
      // You can check against this.$vuetify.breakpoint names like xs, sm, md, lg, and xl.
      if (this.$vuetify.breakpoint.xs) {
        return "waitingTitleSmall font-weight-black headline"; // Default class class for extra small screens
      } else if (this.$vuetify.breakpoint.sm) {
        return "waitingTitleSmall font-weight-black headline"; // Default class class for small screens
      } else if (this.$vuetify.breakpoint.md) {
        return "waitingTitle font-weight-black display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.breakpoint.lg) {
        return "waitingTitle font-weight-black display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.breakpoint.xl) {
        return "waitingTitle font-weight-black display-1"; // Default class for medium to larger screens
      }
      return "waitingTitle font-weight-black display-1"; // Default if none of the above
    },
    computedClassText() {
      // You can check against this.$vuetify.breakpoint names like xs, sm, md, lg, and xl.
      if (this.$vuetify.breakpoint.xs) {
        return "waitingTextSmall font-weight-black headline"; // Default class class for extra small screens
      } else if (this.$vuetify.breakpoint.sm) {
        return "waitingTextSmall font-weight-black headline"; // Default class class for small screens
      } else if (this.$vuetify.breakpoint.md) {
        return "waitingText font-weight-black display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.breakpoint.lg) {
        return "waitingText font-weight-black display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.breakpoint.xl) {
        return "waitingText font-weight-black display-1"; // Default class for medium to larger screens
      }
      return "waitingText font-weight-black display-1"; // Default if none of the above
    },
    showText() {
      const waitingRoomBg = this.getWaitingRoomBg;
      if (waitingRoomBg && waitingRoomBg === 'img/templates/wr_back_02.png') return true;
      return false;
    },
    getWaitingRoomBg() {
      if (store.state.namespaceSettings.waitingRoomBackground) {
        return (
          store.state.namespaceSettings.waitingRoomBackground ||
          "img/templates/wr_back_01.png"
        );
      } else {
        return (
          store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
            .imagePath || "img/templates/wr_back_01.png"
        );
      }
    },
    showLogoCompany() {
      if (
        !store.state.namespaceSettings.waitingRoomBackground &&
        store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
      ) {
        return true;
      }
      return false;
    },
    getClassTemplate() {
      if (this.showLogoCompany) {
        return (
          store.state.namespaceSettings.waitingRoomSocialMedia.templateBg
            .imageClass || "imageClass1"
        );
      } else {
        return "";
      }
    },
    showCompanyLogo() {
      return this.state.namespaceSettings.companyLogo || "img/icon.png";
    },
  },
};
</script>

<style scoped lang="scss">
.waitingTitleSmall{
  position: relative; /* or absolute for out-of-flow positioning */
  left: 50%;
  top: 15%;
  transform: translate(-48%, 0);
  width: 100%; /* or any specific width */
  margin-left: auto;
  margin-right: auto;
}
.waitingTitle{
  position: relative; /* or absolute for out-of-flow positioning */
  left: 50%;
  top: 15%;
  transform: translate(-48%, 0);
  width: 100%; /* or any specific width */
  margin-left: auto;
  margin-right: auto;
}
.waitingText{
     left: 29%;
    top: 33%;
    position: relative;
    width: 23%;
}
.waitingTextSmall{
     left: 29%;
    top: 30%;
    position: relative;
    width: 23%;
}
.backImage {
  height: calc(100vh - 123px);
  min-width: calc(100vw - 58px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.backImage {
  height: calc(100vh - 50px);
  width: 100vw;
}
#counter {
  position: relative;
  display: inline;
}
#over {
  position: absolute;
  width: 8%;
  z-index: 0;
  background-color: #e4e4e4;
  border-radius: 50%;
  padding: 1%;
  border: 3px solid #dadada;
  box-shadow: 0 5px 15px 0 #3f3f40;
}
.imageClass1 {
  left: 52%;
  top: 28%;
}
.imageClass2{
left: 53%;
    top: 29%;
}
.imageClass3{
left: 71%;
    top: 12%;
}
.imageClass4{
  left: 50%;
  top: 34%;
}
.imageClass5{
  left: 51%;
  top: 16%;

}
.imageClass6{
  left: 43%;
  top: 37%;
}
.imageClass7{
  left: 56%;
  top: 27%;
}
.imageClass8{
  left: 54%;
  top: 36%;
}
.imageClass9{
  left: 63%;
  top: 28%;
}
.imageClass10{
  left: 38%;
  top: 28%;
}
</style>