var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "735" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.message"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { style: { width: "100%", margin: 0 }, attrs: { flex: "" } },
            [
              _c("div", { staticClass: "d-flex", style: { width: "100%" } }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "v-badge",
                      {
                        attrs: {
                          bordered: "",
                          overlay: "",
                          height: "60",
                          width: "60",
                          bottom: "",
                          color: _vm.badgetClassObj,
                          dot: "",
                          "offset-x": "22",
                          "offset-y": "15"
                        }
                      },
                      [
                        _c(
                          "v-list-item-avatar",
                          {
                            staticClass: "borderRadius10",
                            attrs: { tile: "", size: "150" }
                          },
                          [
                            [
                              _vm.getUserData.avatar ===
                              "img/default_profile_picture.png"
                                ? _c("DefaultAvatar", {
                                    attrs: {
                                      size: 150,
                                      userData: _vm.getUserData
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-img", {
                                staticClass: "borderRadius10",
                                attrs: {
                                  "max-height": "150",
                                  "max-width": "150",
                                  contain: "",
                                  src:
                                    _vm.getUserData.avatar !==
                                    "img/default_profile_picture.png"
                                      ? _vm.getUserData.avatar
                                      : "img/default_profile_picture.png",
                                  "lazy-src":
                                    "assets/default_profile_picture.png"
                                }
                              })
                            ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-center", style: { width: "100%" } },
                  [
                    _c("v-divider", { staticClass: "mt-4" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2 ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass: "userNameCard",
                          attrs: { color: "primary" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getUserData.titel) +
                              " " +
                              _vm._s(_vm.getUserData.name) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { attrs: { color: "primary" } }, [
                        _vm._v(_vm._s(_vm.getTextModal))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-4" })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: {
              hideDefaultButtons:
                _vm.state.modalPopUp.type === "answerJoinConference"
                  ? false
                  : true,
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }