var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("v-card", [
            _c(
              "div",
              [
                _c(
                  "v-card-title",
                  { staticClass: "headline" },
                  [
                    _c("v-img", {
                      staticClass: "mr-1",
                      attrs: {
                        src: "assets/icon.png",
                        "max-height": "30",
                        "max-width": "30",
                        contain: ""
                      }
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("components.adminSettingsModal.addLocation")
                        ) +
                        "\n        "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "py-0 switchWrapper" },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-2 my-0" },
                            [
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-auto py-0" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "17" } },
                                      [
                                        _c("v-textarea", {
                                          staticClass: "py-0 my-0 mr-6",
                                          attrs: {
                                            name: "inputinvite",
                                            "no-resize": true,
                                            rows: "1",
                                            rules: _vm.requiredRule,
                                            maxlength: "20"
                                          },
                                          model: {
                                            value: _vm.newCustomLocation,
                                            callback: function($$v) {
                                              _vm.newCustomLocation = $$v
                                            },
                                            expression: "newCustomLocation"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "5" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              disabled:
                                                _vm.doesLocationExists ||
                                                _vm.newCustomLocation.trim() ===
                                                  ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.saveCustomLocation()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.adminSettingsModal.addLocation"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              _vm._v(" "),
                              [
                                _c("v-combobox", {
                                  staticClass: "mx-1 label600",
                                  attrs: {
                                    items: _vm.locationCombobox,
                                    select: "",
                                    "search-input": _vm.searchLocation,
                                    "item-text": "name",
                                    label: _vm.$t(
                                      "components.adminSettingsModal.customLocations"
                                    ),
                                    multiple: "",
                                    dense: "",
                                    "auto-select-first": ""
                                  },
                                  on: {
                                    "update:searchInput": function($event) {
                                      _vm.searchLocation = $event
                                    },
                                    "update:search-input": function($event) {
                                      _vm.searchLocation = $event
                                    },
                                    change: function($event) {
                                      _vm.searchLocation = ""
                                    }
                                  },
                                  model: {
                                    value: _vm.userLocationsToEdit,
                                    callback: function($$v) {
                                      _vm.userLocationsToEdit = $$v
                                    },
                                    expression: "userLocationsToEdit"
                                  }
                                })
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "v-card",
                  {
                    staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                    attrs: { color: "primary" }
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "px-2 btns w-100 mx-0 my-0" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: _vm.userLocationsToEdit.length === 0,
                              color: "primary"
                            },
                            on: { click: _vm.deleteCustomLocations }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.adminSettingsModal.deleteLocation"
                                )
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.saveData()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("generics.save")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.closeModal()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("generics.close")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }