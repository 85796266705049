// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customImageFit .v-img__img--contain {
  object-fit: cover !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/workersLounge/regularGridWorkersLoungeNew.vue","webpack://./regularGridWorkersLoungeNew.vue"],"names":[],"mappings":"AAEE;EACE,4BAAA;ACDJ","sourcesContent":["\n.customImageFit{\n  .v-img__img--contain{\n    object-fit: cover !important;\n  }\n}\n",".customImageFit .v-img__img--contain {\n  object-fit: cover !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
