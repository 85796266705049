<template>
  <v-dialog
    persistent
     :model-value="showModal"
    @update:model-value="showModal= $event"
    max-width="570"
    @keydown.esc="
      resetChip();
      toggleNotificationModal();
    "
    :fullscreen="isMobile"
  >
    <div v-for="(key, i) in state.showModalNotify" :key="i">
      <v-card>
        <!-- HEADER -->
        <v-card-title class="pt-4 pb-2 text-h5 styleHeader">
          <v-row class="mx-0">
            <v-col cols="6" class="d-flex px-1 py-1">
              <v-img
                class="imageLogo"
                src="assets/icon.png"
                max-height="30"
                max-width="30"
                contain
                v-if="!isMobile"
              ></v-img>
              <span class="font-weight-medium pl-4 spanModalCall">
                {{
                  key.type == "message" && isEventTimeLine(key)
                    ? $t("components.notificationsModal.meetingChange")
                    : key.type == "message" && !isEventTimeLine(key)
                    ? $t("components.notificationsModal.message")
                    : key.type == "call"
                    ? $t("components.notificationsModal.missedCall")
                    : "Notification"
                }}
              </span>
            </v-col>
            <v-col cols="6" class="px-1 py-1 text-right">
              <span :class="isMobile ? 'datePositionMobile' : ''" v-if="key.date" class="font15 pr-2">
                  {{
                    key.date ? moment(key.date).format("DD.MM.YYYY, H:mm") : ""
                  }}
              </span>
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    icon
                    variant="text"
                    color="primary"
                    @click="
                      resetChip();
                      toggleNotificationModal();
                    "
                  >
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      :style="{ fontSize: '25px' }"
                      :color="state.persisted.isDark ? 'white' : 'black'"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("generics.close") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <!-- CONTENT -->
        <v-row
          :class="`row py-3 mx-0 px-0 ${isMobile ? 'heightModalMobile' : ''}`"
          flex
          @click="!isWaitingRoomUser ? handleClickZone(key.type) : null"
        >
          <v-col v-for="n in 1" :key="n" class="col-12">
            <div
              v-if="n == 1"
              :class="`d-flex ${isMobile ? 'directionModalMobilie' : ''}`"
            >
              <div>
                <v-avatar
                  tile
                  size="120"
                  class="borderRadius4"
                  style="margin: 0px 10px"
                >
                  <v-img
                    class="borderRadius4"
                    max-height="120"
                    max-width="120"
                    contain
                    :src="
                      getAvatarForUuid(Object.keys(state.showModalNotify)[0])
                    "
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                </v-avatar>
              </div>
              <v-row>
                <v-col cols="12" class="pl-6 pb-0 pt-2">
                  <p class="font-weight-medium mb-0 pb-0 mr-auto">
                    {{ getNameForUuid(Object.keys(state.showModalNotify)[0]) }}
                  </p>
                  <p
                    v-if="shouldShowParragraph(state.showModalNotify[0])"
                    class="mb-0 pb-0 mr-auto"
                  >
                    {{
                      state.group[Object.keys(state.showModalNotify)[0]].user
                        .position
                    }}
                  </p>
                  <v-tooltip location="top" v-if="!isMobile && !isModalUserGuest && !state.persisted.superGroup[Object.keys(state.showModalNotify)[0]]">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        icon
                        variant="text"
                        @click="
                          goToOrganisation(
                            Object.keys(state.showModalNotify)[0]
                          );
                          resetChip();
                          toggleNotificationModal();
                        "
                      >
                        <font-awesome-icon
                          :icon="['fal', 'sitemap']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.organisationCarousel.showInOrganigram")
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="key.type === 'message'"
          flex
          :style="{ width: '100%', margin: 0 }"
        >
          <v-col class="col-12">
            <vue-scroll class="vueScroll">
              <div
                v-if="!isReply"
                class="messageTextWrapper px-6"
                v-html="
                  $sanitize(key.notification.text || key.notification.info)
                "
                  v-linkify:options="{
                  attributes: { rel: 'nofollow noopener noreferrer' },
                }"
              ></div>
              <div v-if="isReply" class="messageTextWrapper px-6">
                <UserMessageComp
                  ref="userMssgComp"
                  :toggleIsReply="toggleIsReply"
                  :isReply="isReply"
                  :setIsReply="setIsReply"
                />
              </div>
            </vue-scroll>
            <vue-scroll class="vueScroll" v-if="key.type === 'ticket'">
              <div class="messageTextWrapper">
                {{ key.notification.text || key.notification.info }}
              </div>
            </vue-scroll>
          </v-col>
        </v-row>
        <v-card  class="py-2 pl-4 pr-2 footerModal footersBackground">
          <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
            <v-col lg="12" class="px-0 py-0" align="right">
              <p
                v-if="
                  key.type === 'call' &&
                  !isWaitingRoomUser &&
                  state.persisted.tmpMessages.showMessageInitiateCallSpace
                "
                style="position: absolute; top: 11px"
                align="left"
                class="text-body-2"
              >
                * {{ $t("components.notificationsModal.callSpaceBar") }}
              </p>
              <v-tooltip
                top
                v-if="
                  (key.type === 'call' || key.type === 'message') &&
                  !isWaitingRoomUser &&
                  !state.persisted.superGroup[Object.keys(state.showModalNotify)[0]]
                "
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon
                    variant="text"
                    tile
                    id="buttonCallDirectly"
                    v-bind="props"
                    color="primary"
                    :disabled="disableCallBtn"
                    :class="`mr-2 buttonIconPrimaryFooter ${
                      disableCallBtn ? 'buttonIconPrimaryFooterDisable' : ''
                    }`"
                    :style="` ${
                      disableCallBtn ? 'color: #11889d!important;' : ''
                    }`"
                    v-on:click="
                      disableCallBtn
                        ? false
                        : callUser(Object.keys(state.showModalNotify)[0])
                    "
                  >
                    <font-awesome-icon
                      v-if="key.type === 'call'"
                      :icon="['fal', 'phone']"
                      :style="{ fontSize: '20px' }"
                    />
                    <font-awesome-icon
                      v-if="key.type === 'message'"
                      :icon="['fal', 'phone']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{
                  disableCallBtn
                    ? "Dieser Benutzer ist zur Zeit offline"
                    : $t("components.userProfile.callUser")
                }}</span>
              </v-tooltip>
              <v-tooltip location="top" v-if="key.type === 'call' && !isWaitingRoomUser && !state.persisted.superGroup[Object.keys(state.showModalNotify)[0]]">
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon
                    variant="text"
                    tile
                    v-bind="props"
                    color="primary"
                    class="buttonIconPrimaryFooter mr-2"
                    @click.stop.prevent="
                      setShowModalSenMsg(
                        Object.keys(state.showModalNotify)[0],
                        'regularMsg'
                      )
                    "
                  >
                    <font-awesome-icon
                      v-if="key.type === 'call'"
                      :icon="['fal', 'paper-plane']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("components.userProfile.sendAMessage") }}</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="key.type === 'message' && !isWaitingRoomUser"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon
                    tile
                    variant="text"
                    v-bind="props"
                    color="primary"
                    :class="`mr-2 buttonIconPrimaryFooter ${
                      isReply ? 'buttonIconPrimaryFooterDisable' : ''
                    }`"
                    :style="` ${isReply ? 'color: #11889d!important;' : ''}`"
                    @click="
                      setshowCompSendMsg(
                        Object.keys(state.showModalNotify)[0],
                        key.notification.header
                      )
                    "
                    :disabled="isReply"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'reply']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{
                  !state.group[Object.keys(state.showModalNotify)[0]].connected
                    ? "Dieser Benutzer ist zur Zeit offline"
                    : $t("components.notificationsModal.reply")
                }}</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  key.type === 'message' &&
                  isEventTimeLine(key) &&
                  !isWaitingRoomUser &&
                  !isMyTimeLineEvent(key)
                "
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon
                    tile
                    variant="text"
                    v-bind="props"
                    color="primary"
                    class="mr-2 buttonIconPrimaryFooter"
                    @click="
                      acceptEventTimeline(key.notification.header.eventTimeLine)
                    "
                  >
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>accept event</span>
              </v-tooltip>
              <v-btn
                v-if="isReply"
                class="mr-2 buttonPrimaryFooter"
                flat
                color="white"
                @click="sendReply()"
                >{{ $t("components.sendMessageModal.send") }}</v-btn
              >
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    :loading="loading"
                    v-bind="props"
                    icon
                    variant="text"
                    tile
                    class="buttonIconPrimaryFooter"
                    color="red"
                    @click="
                      removeMessage(
                        Object.keys(state.showModalNotify)[0],
                        key.id,
                        undefined
                      )
                    "
                  >
                    <DeleteBasket
                      :indexData="[
                        Object.keys(state.showModalNotify)[0],
                        key.id,
                        undefined,
                      ]"
                      :delFunction="removeMessage"
                      extraClasses="colorPrimary"
                      fontSize="20"
                      prevent="false"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("generics.delete") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { callPerson, disableCallBtn } from "../../utils/calls";
import CamAndMic from "../firstSetup/camAndMic.vue";
import DeleteBasket from "../ui/deleteBasket.vue";
import moment from "../../../sharedsrc/moment";
import UserMessageComp from "../sidebar/userMessageComponent.vue";
import { dispatchRsvpTimelineEntry } from "../../effector/timeline";
import { isWaitingRoomUser } from "../../utils/privileges";
import { setHistoryMessagesModalEvent } from "../../effector/modals";
export default {
  components: { CamAndMic, UserMessageComp, DeleteBasket },
  data: () => ({
    state: store.state,
    loading: false,
    chip: true,
    moment,
    isReply: false,
    ownUUID: store.state.ownUUID,
    isMobile: isMobile(),
  }),
  mounted() {
    if (!this.isWaitingRoomUser) {
      document.addEventListener("keydown", this.handleModalSpace, {
        capture: false,
        passive: false,
      });
    }
  },
  beforeUnmount() {
    if (!this.isWaitingRoomUser) {
      document.removeEventListener("keydown", this.handleModalSpace);
    }
  },
  methods: {
    shouldShowParragraph(modaluid) {
      return (
        this.state.group[modaluid]?.user ||
        this.state.persisted.superGroup[modaluid] || 
        false
      );
    },
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
      this.toggleNotificationModal();
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    goToOrganisation(uuid) {
      // go to the user organisation
      if ( !this.state.persisted.superGroup[uuid] ){
        this.setCurrentContentVisile("organisation/" + uuid, true, this.$router);
      }
    },
    isMyTimeLineEvent(key) {
      if (
        this.isEventTimeLine(key) &&
        key.notification.header.eventTimeLine.creatorUUID == this.state.ownUUID
      ) {
        return true;
      } else {
        return false;
      }
    },
    isEventTimeLine(key) {
      if (key.notification.header && key.notification.header.eventTimeLine) {
        return true;
      } else {
        return false;
      }
    },
    acceptEventTimeline(modalEntry) {
      if (!modalEntry || !modalEntry.uuid) {
        throw new Error("Missing modal entry/uuid");
      }
      dispatchRsvpTimelineEntry([modalEntry.uuid, true]);
      this.resetChip();
      this.toggleNotificationModal();
    },
    sendReply() {
      if (this.$refs.userMssgComp) {
        this.$refs.userMssgComp[0].preparedataToSendMsg(
          this.state.showCompSendMsg.uuid,
          this.state.tmpMessagetext
        );
        this.$refs.userMssgComp[0].closeModalMsg();
        this.$refs.userMssgComp[0].resettmpMessagetext("");
      }
    },
    handleClickZone(type) {
      if (type === "call") {
        this.disableCallBtn
          ? false
          : this.callUser(Object.keys(store.state.showModalNotify)[0]);
      }
    },
    handleModalSpace(evt) {
      let e = evt || window.event,
        target = e.target || e.srcElement;
      if (evt.keyCode != 32) return;
      /// only space bar
      const mssgLen = store.state.tmpMessagetext.trim().length || 0;
      let type = this.resolveType();
      if (type === "" && mssgLen < 5) return; ///only types
      if (type === "" && mssgLen > 5) type = "message"; // messaging from call or other places with no type
      const hitarea = target.tagName.toUpperCase() || "unknown";
      const origin = this.resolveComponent();
      if (
        origin.trim().length === 0 &&
        type !== "call" &&
        origin.trim().length === 0 &&
        type !== "message"
      )
        return; ///
      switch (type) {
        case "message":
          if (hitarea === "BODY" && origin === "modal") {
            EventBus.$emit("sendMyMessage");
          } else if (hitarea === "BODY" && origin === "" && mssgLen > 5) {
            EventBus.$emit("sendMyMessage");
          } else if (
            hitarea === "DIV" &&
            origin === "comp" &&
            this.isReply &&
            store.state.tmpMessagetext.trim().length > 0
          ) {
            if (store.state.tmpMessagetext.trim().length === 0) return;
            this.$refs.userMssgComp[0].preparedataToSendMsg(
              store.state.showCompSendMsg.uuid,
              store.state.tmpMessagetext
            );
          } else if (hitarea === "DIV" && !this.isReply && origin === "comp") {
            let uuid = Object.keys(store.state.showModalNotify)[0];
            this.disableCallBtn ? false : this.callUser(uuid);
          } else if (
            hitarea === "DIV" &&
            !this.isReply &&
            origin.trim().length === 0
          ) {
            let uuid = Object.keys(store.state.showModalNotify)[0];
            this.disableCallBtn ? false : this.callUser(uuid);
          }
          break;
        case "call":
          // eslint-disable-next-line no-case-declarations
          let uuid = Object.keys(store.state.showModalNotify)[0];
          this.disableCallBtn ? false : this.callUser(uuid);
          break;
        default:
          break;
      }
    },
    resolveType() {
      let hresult = "";
      if (((store.state.showModalSenMsg || {}).type || "") !== "") {
        hresult = store.state.showModalSenMsg.type;
      }
      if (
        (((store.state.showCompSendMsg || {}).type || {}).type || "") !== ""
      ) {
        hresult = store.state.showCompSendMsg.type.type;
      }
      if (
        hresult === "" &&
        Object.keys(store.state.showModalNotify).length > 0
      ) {
        try {
          hresult =
            store.state.showModalNotify[
              Object.keys(store.state.showModalNotify)[0]
            ].type;
        } catch (error) {
          hresult = "";
        }
      }
      return hresult;
    },
    resolveComponent() {
      let hcomp = "";
      if (((store.state.showModalSenMsg || {}).type || "") !== "") {
        hcomp = "modal";
      } else if (((store.state.showCompSendMsg || {}).type || "") !== "") {
        hcomp = "comp";
      }
      return hcomp;
    },
    callUser(uuid) {
      setTimeout(function () {
        callPerson(uuid, this.$router);
      }, 200);
      this.resetChip();
      this.toggleNotificationModal();
    },
    toggleIsReply() {
      this.isReply = !this.isReply;
    },
    setIsReply(val) {
      this.isReply = val;
    },
    toggleNotificationModal() {
      if (this.isCall) {
        store.setShowMessageInitiateCallSpace(false);
      }
      this.state.showModalNotify = {};
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    resetChip() {
      this.chip = true;
    },
    removeMessage: function (...args) {
      this.loading = true;
      store.removeMessage(...args);
      this.loading = false;
      this.toggleNotificationModal();
    },
    setshowCompSendMsg(uuid, header) {
      if (this.chip && header) {
        const id = header.sendTo.name;
        const data = {
          type: "message",
          options: header.sendTo.category,
        };
        this.resetChip();
        this.isReply = true;
        return store.setShowCompSendMessage(id, data);
      } else {
        this.resetChip();
        this.isReply = true;
        const data = {
          type: "message",
        };
        return store.setShowCompSendMessage(uuid, data);
      }
    },
  },
  computed: {
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    isModalUserGuest() {
      if (Object.keys(this.state.showModalNotify).length > 0) {
        const uuid = Object.keys(store.state.showModalNotify)[0];
        return (uuid && store.getUserIsGuest(uuid));
      } else {
        return false;
      }
    },
    isCall() {
      if (Object.keys(this.state.showModalNotify).length > 0) {
        return (
          store.state.showModalNotify[Object.keys(store.state.showModalNotify)[0]]
            .type === "call"
        );
      } else {
        return false;
      }
    },
    disableCallBtn() {
      if (Object.keys(this.state.showModalNotify).length > 0) {
        let person =
          this.state.group[Object.keys(this.state.showModalNotify)[0]];
        return disableCallBtn(person);
      } else {
        return true;
      }
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
    showModal() {
      return Object.keys(this.state.showModalNotify).length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
.heightModalMobile {
  height: calc(100% - 150px);
}
.directionModalMobilie {
  flex-direction: column;
  text-align: center;
}
.borderRadius4 {
  border-radius: 4px!important;
}
.font15 {
  font-size: 15px;
}
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.vueScroll {
  height: calc(100% - 64px) !important;
  .__view {
    width: unset !important;
  }
}
.messageTextWrapper {
  height: 300px;
  white-space: pre-line;
}
.datePositionMobile{
  position: absolute;
  left: 35px;
  top: 35px;
}
</style>