var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-right" },
    [
      !_vm.state.setUpConfig.showSetUp
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: { icon: "", id: "firstElement" },
                              on: {
                                dblclick: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.seeAllExcel.apply(null, arguments)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "font-awesome-icon",
                                            _vm._g(
                                              {
                                                staticClass: "settingsIcon",
                                                class: !_vm.$vuetify.theme.dark
                                                  ? "iconsApp"
                                                  : "iconsAppWhite",
                                                style: { fontSize: "20px" },
                                                attrs: { icon: ["fal", "cog"] }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("generics.settings")) + " "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1247725541
              ),
              model: {
                value: _vm.showMenu,
                callback: function($$v) {
                  _vm.showMenu = $$v
                },
                expression: "showMenu"
              }
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleCamMic()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "marginRight10" },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "16px" },
                                attrs: { icon: ["fal", "video"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("components.navbar.camAndMic"))
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.getSortedList, function(item, i) {
                        return _c(
                          "v-list-item",
                          {
                            key: i,
                            style: [
                              ((item.index === 4 ||
                                item.index === 3 ||
                                item.index === 2 ||
                                item.index === 5) &&
                                !_vm.amIAdmin) ||
                              ((item.index === 4 ||
                                item.index === 3 ||
                                item.index === 2 ||
                                item.index === 5) &&
                                _vm.isMobile) ||
                              (item.index === 7 &&
                                !_vm.hasDailyReportEnabled) ||
                              (item.index === 8 &&
                                !(
                                  (_vm.amIAdmin || _vm.state.user.countTime) &&
                                  _vm.state.namespaceSettings.showWorkingTime
                                ))
                                ? { display: "none" }
                                : { display: "flex" }
                            ],
                            on: {
                              click: function($event) {
                                item.index === 1
                                  ? _vm.openUserSettings()
                                  : item.index === 2
                                  ? _vm.seeExcel()
                                  : item.index === 4
                                  ? _vm.toggleModalAdminSettings()
                                  : item.index === 5
                                  ? _vm.goToPayments()
                                  : item.index === 7 &&
                                    _vm.hasDailyReportEnabled
                                  ? _vm.openDailyReportModal()
                                  : item.index === 8 &&
                                    _vm.state.namespaceSettings.showWorkingTime
                                  ? _vm.gotoPage("working-time")
                                  : null
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "marginRight10" },
                              [
                                item.index == 1
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["fal", "user-cog"] }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.index == 2
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["fal", "users-cog"] }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.index == 4
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["fal", "cog"] }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.index == 5 && !_vm.$vuetify.theme.dark
                                  ? _c("img", {
                                      staticClass: "facePayLogo",
                                      attrs: {
                                        src: "img/icons/facepay-settings.svg",
                                        "lazy-src":
                                          "img/icons/facepay-settings.svg"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.index == 5 && _vm.$vuetify.theme.dark
                                  ? _c("img", {
                                      staticClass: "facePayLogo",
                                      attrs: {
                                        src:
                                          "img/icons/facepay-settings-white.svg",
                                        "lazy-src":
                                          "img/icons/facepay-settings-white.svg"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.index == 7
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["fal", "file"] }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.index == 8
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["fal", "calendar"] }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item.index == 1
                              ? _c("v-list-item-title", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.userSettings.userSettings"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.index == 2
                              ? _c("v-list-item-title", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.userSettings.userManagement"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.index == 4
                              ? _c("v-list-item-title", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("generics.adminSettings"))
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.index == 5
                              ? _c("v-list-item-title", [
                                  _c("span", [_vm._v(_vm._s(item.name))])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.index == 6
                              ? _c("v-list-item-title", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("components.navbar.camAndMic")
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.index == 7
                              ? _c("v-list-item-title", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.adminSettingsModal.dailyReport"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.index == 8
                              ? _c("v-list-item-title", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.workingTime.workingTime"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showHelpCenter()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "marginRight10" },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "16px" },
                                attrs: { icon: ["fal", "question-circle"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.support")))
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showHotkeysModal()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "marginRight10" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "cursorPointer imageIcon",
                                style: { fontSize: "16px" },
                                attrs: { icon: ["far", "keyboard"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.hotkeys")))
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showChangelog()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "marginRight10" },
                            [
                              _c("v-img", {
                                staticClass: "cursorPointer imgBubble",
                                attrs: {
                                  height: "25",
                                  width: "25",
                                  "max-height": "25",
                                  "max-width": "25",
                                  src:
                                    !_vm.updateInfoDisplayed &&
                                    _vm.dirty &&
                                    !_vm.callInfoStateStore.callActive &&
                                    !_vm.inBridgeCall
                                      ? "img/light-bulb.svg"
                                      : "img/light-bulb-blue.svg",
                                  contain: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.changeLog")))
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showvOfficePro()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "marginRight10" },
                            [
                              _c("v-img", {
                                staticClass: "cursorPointer imageIcon",
                                attrs: {
                                  height: "20",
                                  width: "20",
                                  "max-height": "20",
                                  "max-width": "20",
                                  src: "assets/icon.png",
                                  contain: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.aboutvOffice")))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCustomTT
        ? _c("CustomTT", {
            attrs: {
              text: _vm.$t("components.hotkeys.controlKey") + " + M",
              position: "bottom",
              extraclass: "ctrm"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }