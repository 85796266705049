var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      !_vm.isChildren && _vm.showHeadlineOrganization
        ? _c("v-row", { staticClass: "relative" }, [
            _c(
              "div",
              {
                staticClass:
                  "organigramHeadlineFirst text-white subtitle-1 font-weight-bold"
              },
              [_vm._v(_vm._s(_vm.headlineOrganigram[0][_vm.language]))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex flex-wrap justify-center",
          class: !_vm.isChildren ? "pt-8" : ""
        },
        [
          _vm.showAddSectionNoData
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center align-end pr-4 py0" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "cursorPointer borderAddCard rounded-lg",
                        attrs: {
                          color: "#f5f5f5",
                          "max-width": "240",
                          width: "2240",
                          height: "230",
                          flat: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.openAddEditModal(null, false, false)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-center align-center",
                            staticStyle: { height: "100%" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "containerImg100 text-center" },
                              [
                                _c("font-awesome-icon", {
                                  staticStyle: { "font-size": "40px" },
                                  attrs: { icon: ["fal", "plus"] }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "mt-2 font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.organisationGrid.addDepart"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "div",
              {
                key: "item" + (item.id || item.uuid),
                staticClass: "d-flex",
                style: item.isUser ? "min-height: 260px;" : ""
              },
              [
                index === 0 && !_vm.isSectionOpen(item.id) && _vm.editMode
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-center align-end pr-4 py-0"
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "cursorPointer borderAddCard rounded-lg",
                              attrs: {
                                color: "#f5f5f5",
                                "max-width": "240",
                                width: "2240",
                                height: item && item.isUser ? "245" : "230",
                                flat: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openAddEditModal(
                                    item,
                                    false,
                                    false
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center",
                                  staticStyle: { height: "100%" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "containerImg100 text-center"
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticStyle: { "font-size": "40px" },
                                        attrs: { icon: ["fal", "plus"] }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.organisationGrid.addDepart"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-center align-end pr-4 py-0"
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "cardWithImg",
                            attrs: {
                              "max-width": "240",
                              height: "230",
                              flat: ""
                            }
                          },
                          [
                            item.supervisors && item.supervisors.length
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex flex-wrap" },
                                  [
                                    _vm._l(item.supervisors, function(
                                      supervisor,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key:
                                            (item.id || item.uuid) +
                                            "supervisor" +
                                            supervisor,
                                          staticClass: "rounded-lg",
                                          class: [
                                            _vm.getBorderStyleSupervisor(
                                              item.supervisors,
                                              index
                                            )
                                          ],
                                          style: _vm.getBorderCard(
                                            item.supervisors,
                                            supervisor,
                                            index
                                          )
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-img",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "align-end",
                                                              class: _vm.appearInAHigherSection(
                                                                supervisor,
                                                                item
                                                              )
                                                                ? "grayScale"
                                                                : "",
                                                              attrs: {
                                                                height:
                                                                  item
                                                                    .supervisors
                                                                    .length > 2
                                                                    ? "94"
                                                                    : "187",
                                                                width: "auto",
                                                                src: _vm.getAvatarForUuid(
                                                                  supervisor
                                                                ),
                                                                cover: "",
                                                                loading: "lazy",
                                                                "lazy-src":
                                                                  _vm.getDefaultPicture
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "containerCheckbox"
                                                              },
                                                              [
                                                                !_vm.disableCallBtn(
                                                                  supervisor
                                                                )
                                                                  ? _c(
                                                                      "MultiUserCallIconGrid",
                                                                      {
                                                                        attrs: {
                                                                          person: _vm.getPersonByUuid(
                                                                            supervisor
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "containerIcons"
                                                              },
                                                              [
                                                                supervisor in
                                                                _vm.state.group
                                                                  ? _c(
                                                                      "LocationIcon",
                                                                      {
                                                                        attrs: {
                                                                          person: _vm.getPersonByUuid(
                                                                            supervisor
                                                                          ),
                                                                          defaultAvatar: _vm.hasUserImage(
                                                                            supervisor
                                                                          ),
                                                                          isRegularGrid: true
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                !_vm.disableCallBtn(
                                                                  supervisor
                                                                )
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "noWhiteBg",
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          color:
                                                                            "white",
                                                                          small:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.callUser(
                                                                              supervisor
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            staticClass:
                                                                              "buttonCallSwiper",
                                                                            style: {
                                                                              fontSize:
                                                                                "15px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fas",
                                                                                "phone"
                                                                              ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gradientBg align-center white--text d-flex"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-truncate pl-3 pt-1 userNameContainer caption"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          _vm.getTitelByUuid(
                                                                            supervisor
                                                                          )
                                                                        ) +
                                                                        "\n                        " +
                                                                        _vm._s(
                                                                          _vm.getNameByUuid(
                                                                            supervisor
                                                                          )
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "ButtonsFrontCard",
                                                                      {
                                                                        attrs: {
                                                                          isOrga: true,
                                                                          person: _vm.getPersonByUuid(
                                                                            supervisor
                                                                          ),
                                                                          uuid: supervisor,
                                                                          isRegularGrid: true
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            $event.preventDefault()
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.hasRepresentative(
                                                              supervisor
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "gradientBg align-center d-flex flex-row white--text justify-space-between",
                                                                    style: _vm.getBorderByStatusFull(
                                                                      _vm.getRepresentativeByUuid(
                                                                        supervisor
                                                                      )
                                                                    )
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex flex-row align-center",
                                                                        staticStyle: {
                                                                          "max-width":
                                                                            "85%"
                                                                        }
                                                                      },
                                                                      [
                                                                        !_vm.disableCallBtn(
                                                                          _vm.getRepresentativeByUuid(
                                                                            supervisor
                                                                          )
                                                                        )
                                                                          ? _c(
                                                                              "CallIcon",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1",
                                                                                attrs: {
                                                                                  person: _vm.getRepresentativeByUuid(
                                                                                    supervisor
                                                                                  ),
                                                                                  isRegularGrid: true,
                                                                                  ownUUID:
                                                                                    _vm.ownUUID
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-truncate pl-2 caption"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            " +
                                                                                _vm._s(
                                                                                  _vm.getRepresentativeName(
                                                                                    supervisor
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("UserInfoTooltip", {
                                                attrs: {
                                                  person: _vm.getPersonByUuid(
                                                    supervisor
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      {
                                        staticClass:
                                          "pa-0 py-2 px-3 cardTextContainer",
                                        staticStyle: { height: "40px" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex absolute",
                                            staticStyle: { width: "88%" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-truncate body-1",
                                                staticStyle: { width: "95%" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.name) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            item.supervisors &&
                                            item.supervisors.length &&
                                            !_vm.isSectionOpen(item.id)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "text-end" },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "cursorPointer",
                                                      class: !_vm.editMode
                                                        ? "hidden"
                                                        : "",
                                                      staticStyle: {
                                                        "font-size": "17px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "pen"]
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openAddEditModal(
                                                            item,
                                                            true,
                                                            false
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showHeadlineOrganization &&
                            item.uuid &&
                            index === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "userSectionHeadline text-white subtitle-1 font-weight-bold",
                                    class: _vm.isDarkMode
                                      ? "bgblack"
                                      : "bgwhite",
                                    style: _vm.editMode
                                      ? "left: -260px!important;"
                                      : ""
                                  },
                                  [_vm._v(_vm._s(_vm.getLevels(item)))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.uuid &&
                            (!item.supervisor || !item.supervisor.length)
                              ? _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "containerImg100 rounded-lg",
                                      style:
                                        _vm.getBorderCard(
                                          [],
                                          item.uuid,
                                          index
                                        ) + "; border-radius: 4px;",
                                      on: {
                                        click: function($event) {
                                          !_vm.disableCallBtn(item.uuid)
                                            ? _vm.callUser(item.uuid)
                                            : null
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-img",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "align-end",
                                                          attrs: {
                                                            height: "187",
                                                            width: "auto",
                                                            src: _vm.getAvatarForUuid(
                                                              item.uuid
                                                            ),
                                                            cover: "",
                                                            loading: "lazy",
                                                            "lazy-src":
                                                              _vm.getDefaultPicture
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "containerCheckbox"
                                                          },
                                                          [
                                                            !_vm.disableCallBtn(
                                                              item.uuid
                                                            )
                                                              ? _c(
                                                                  "MultiUserCallIconGrid",
                                                                  {
                                                                    attrs: {
                                                                      person: _vm.getPersonByUuid(
                                                                        item.uuid
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "containerIcons"
                                                          },
                                                          [
                                                            item.uuid in
                                                            _vm.state.group
                                                              ? _c(
                                                                  "LocationIcon",
                                                                  {
                                                                    attrs: {
                                                                      person: _vm.getPersonByUuid(
                                                                        item.uuid
                                                                      ),
                                                                      defaultAvatar: _vm.hasUserImage(
                                                                        item.uuid
                                                                      ),
                                                                      isRegularGrid: true
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.disableCallBtn(
                                                              item.uuid
                                                            )
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "noWhiteBg",
                                                                    attrs: {
                                                                      icon: "",
                                                                      color:
                                                                        "white",
                                                                      small: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.callUser(
                                                                          item.uuid
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        staticClass:
                                                                          "buttonCallSwiper",
                                                                        style: {
                                                                          fontSize:
                                                                            "15px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fas",
                                                                            "phone"
                                                                          ]
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        !_vm.hasRepresentative(
                                                          item.uuid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gradientBg align-center white--text d-flex"
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "text-truncate pl-3 pt-1 userNameContainer caption"
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mr-4"
                                                                      },
                                                                      [
                                                                        _vm.showTlfIcon(
                                                                          item.uuid
                                                                        )
                                                                          ? _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  color:
                                                                                    "white",
                                                                                  disabled: _vm.disableCallBtn(
                                                                                    item.uuid
                                                                                  )
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.callUser(
                                                                                      _vm.uuid
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-phone-outline"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "ButtonsFrontCard",
                                                                      {
                                                                        attrs: {
                                                                          isOrga: true,
                                                                          person: _vm.getPersonByUuid(
                                                                            item.uuid
                                                                          ),
                                                                          uuid:
                                                                            item.uuid,
                                                                          isRegularGrid: true
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            $event.preventDefault()
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.hasRepresentative(
                                                          item.uuid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gradientBg align-center white--text d-flex justify-space-between",
                                                                style: _vm.getBorderByStatusFull(
                                                                  _vm.getRepresentativeByUuid(
                                                                    item.uuid
                                                                  )
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center pl-1 representativeUserName"
                                                                  },
                                                                  [
                                                                    !_vm.disableCallBtn(
                                                                      _vm.getRepresentativeByUuid(
                                                                        item.uuid
                                                                      )
                                                                    )
                                                                      ? _c(
                                                                          "CallIcon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1",
                                                                            attrs: {
                                                                              person: _vm.getRepresentativeByUuid(
                                                                                item.uuid
                                                                              ),
                                                                              isRegularGrid: true,
                                                                              ownUUID:
                                                                                _vm.ownUUID
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-truncate pl-1 caption"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.getRepresentativeName(
                                                                                item.uuid
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "ButtonsFrontCard",
                                                                      {
                                                                        attrs: {
                                                                          isOrga: true,
                                                                          person: _vm.getPersonByUuid(
                                                                            item.uuid
                                                                          ),
                                                                          uuid:
                                                                            item.uuid,
                                                                          isRegularGrid: true
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            $event.preventDefault()
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("UserInfoTooltip", {
                                            attrs: {
                                              person: _vm.getPersonByUuid(
                                                item.uuid
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass: "pa-0 py-2 px-3",
                                          staticStyle: { height: "54px" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-weight-bold text-truncate body-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.getTitelByUuid(
                                                      item.uuid
                                                    )
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    _vm.getNameByUuid(item.uuid)
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "text-truncate" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.getPositionForUuid(
                                                      item.uuid
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.loading &&
                        (_vm.isSectionOpen(item.id) ||
                          (_vm.isChildren && _vm.filteringBy)) &&
                        _vm.getPersonAssistantsByUuids(
                          item.supervisors,
                          item.assistants
                        ).length
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "ml-2 mb-2 font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.organisationCarousel.assistant"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                _vm._l(
                                  _vm.getPersonAssistantsByUuids(
                                    item.supervisors,
                                    item.assistants
                                  ),
                                  function(assistant, index) {
                                    return _c(
                                      "v-card",
                                      {
                                        key:
                                          (item.id || item.uuid) +
                                          "assistant" +
                                          assistant,
                                        staticClass: "ml-2 cardWithImg",
                                        attrs: {
                                          "max-width": "111",
                                          height: "112",
                                          flat: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            style: _vm.getBorderCard(
                                              [],
                                              assistant,
                                              index
                                            )
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-img",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "align-end removeBorderLeft removeBorderRight",
                                                                attrs: {
                                                                  height: "105",
                                                                  width: "auto",
                                                                  src: _vm.getAvatarForUuid(
                                                                    assistant
                                                                  ),
                                                                  cover: "",
                                                                  loading:
                                                                    "lazy",
                                                                  "lazy-src":
                                                                    _vm.getDefaultPicture
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              !_vm.hasRepresentative(
                                                                assistant
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "gradientBg align-center d-flex white--text"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-truncate pl-3 pt-1 caption userNameContainer"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                _vm.getTitelByUuid(
                                                                                  assistant
                                                                                )
                                                                              ) +
                                                                              "\n                          " +
                                                                              _vm._s(
                                                                                _vm.getNameByUuid(
                                                                                  assistant
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "ButtonsFrontCard",
                                                                            {
                                                                              attrs: {
                                                                                isOrga: true,
                                                                                person: _vm.getPersonByUuid(
                                                                                  assistant
                                                                                ),
                                                                                uuid: assistant,
                                                                                isRegularGrid: true
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  $event.preventDefault()
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.hasRepresentative(
                                                                assistant
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "gradientBg align-center white--text d-flex justify-space-between",
                                                                      style: _vm.getBorderByStatusFull(
                                                                        _vm.getRepresentativeByUuid(
                                                                          assistant
                                                                        )
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center pl-1 representativeUserName"
                                                                        },
                                                                        [
                                                                          !_vm.disableCallBtn(
                                                                            _vm.getRepresentativeByUuid(
                                                                              assistant
                                                                            )
                                                                          )
                                                                            ? _c(
                                                                                "CallIcon",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1",
                                                                                  attrs: {
                                                                                    person: _vm.getRepresentativeByUuid(
                                                                                      assistant
                                                                                    ),
                                                                                    isRegularGrid: true,
                                                                                    ownUUID:
                                                                                      _vm.ownUUID
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-truncate pl-1 caption"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    _vm.getRepresentativeName(
                                                                                      assistant
                                                                                    )
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "ButtonsFrontCard",
                                                                            {
                                                                              attrs: {
                                                                                isOrga: true,
                                                                                person: _vm.getPersonByUuid(
                                                                                  assistant
                                                                                ),
                                                                                uuid: assistant,
                                                                                isRegularGrid: true
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  $event.preventDefault()
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("UserInfoTooltip", {
                                                  attrs: {
                                                    person: _vm.getPersonByUuid(
                                                      assistant
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.loading && !item.uuid
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-center w100 pr-8 sectionArrow",
                            class: _vm.showHeadlineOrganization ? "pb-6" : ""
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "cursorPointer",
                              class:
                                (!item.children || !item.children.length) &&
                                !_vm.editMode
                                  ? "hidden"
                                  : "",
                              staticStyle: { "font-size": "40px" },
                              attrs: {
                                icon: !_vm.isSectionOpen(item.id)
                                  ? ["fal", "angle-down"]
                                  : ["fal", "angle-up"]
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openCloseSection(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    [
                      _c(
                        "div",
                        {
                          ref: "panel-" + item.id,
                          refInFor: true,
                          staticClass: "hiddenCards pr-4 py-0",
                          staticStyle: { position: "relative" },
                          attrs: { id: "panel-" + item.id }
                        },
                        [
                          _vm.showHeadlineOrganization
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "sectionHeadline text-white subtitle-1 font-weight-bold"
                                },
                                [_vm._v(_vm._s(_vm.getLevels(item)))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.filteringBy || !(_vm.loadingGrid || _vm.loading)
                            ? _c("organisationCard", {
                                attrs: {
                                  filteringBy: _vm.filteringBy,
                                  loadingGrid: _vm.loadingGrid,
                                  showSectionFunction: _vm.showSectionFunction,
                                  isChildren: true,
                                  items: item.children || [],
                                  editMode: _vm.editMode,
                                  openAddEditModal: _vm.openAddEditModal
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          index === 0 &&
                          _vm.isSectionOpen(item.id) &&
                          _vm.editMode &&
                          (!item.children || !item.children.length)
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-end" },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "cursorPointer borderAddCard rounded-lg",
                                        attrs: {
                                          color: "#f5f5f5",
                                          "max-width": "240",
                                          width: "240",
                                          height: "230",
                                          flat: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openAddEditModal(
                                              item,
                                              false,
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-center align-center",
                                            staticStyle: { height: "100%" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "containerImg100 text-center"
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticStyle: {
                                                    "font-size": "40px"
                                                  },
                                                  attrs: {
                                                    icon: ["fal", "plus"]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-2 font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.organisationGrid.addDepart"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ],
                  2
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }