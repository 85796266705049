// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-light[data-v-1ec04ea0] {
  background-color: var(--v-primary-100);
}
.bg-dark[data-v-1ec04ea0] {
  background-color: var(--v-primary-900);
}
.height-custom[data-v-1ec04ea0] {
  height: calc(100% + 85px);
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/socialMediaContainer.vue","webpack://./socialMediaContainer.vue"],"names":[],"mappings":"AACA;EACE,sCAAA;ACAF;ADGA;EACE,sCAAA;ACAF;ADGA;EACE,yBAAA;ACAF","sourcesContent":["\n.bg-light {\n  background-color: var(--v-primary-100);\n}\n\n.bg-dark {\n  background-color: var(--v-primary-900);\n}\n\n.height-custom {\n  height: calc(100% + 85px);\n}\n",".bg-light {\n  background-color: var(--v-primary-100);\n}\n\n.bg-dark {\n  background-color: var(--v-primary-900);\n}\n\n.height-custom {\n  height: calc(100% + 85px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
