var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "domain" },
    [
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v("Domain " + _vm._s(_vm.name))
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                [
                  _vm._l(_vm.domains, function(d) {
                    return _c("DomainComp", {
                      key: d.shortName,
                      attrs: { domain: d }
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.stores, function(s) {
                    return _c("StoreComp", {
                      key: s.shortName,
                      attrs: { store: s }
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.events, function(e) {
                    return _c("EventComp", {
                      key: e.shortName,
                      attrs: { event: e }
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.effects, function(f) {
                    return _c("EffectComp", {
                      key: f.shortName,
                      attrs: { effect: f }
                    })
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }