var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showAddEditOrganigramModal.show,
        callback: function($$v) {
          _vm.$set(_vm.showAddEditOrganigramModal, "show", $$v)
        },
        expression: "showAddEditOrganigramModal.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.isEdit
                ? _vm.$t("components.organisationGrid.editDepart")
                : _vm.$t("components.organisationGrid.addDepart"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "mt-8" }, [
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("v-text-field", {
                  attrs: {
                    disabled: _vm.addUsers,
                    dense: "",
                    label: _vm.$t("components.organisationGrid.areaFunction"),
                    outlined: "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                !_vm.isEdit && _vm.canAddUsers
                  ? _c("v-checkbox", {
                      attrs: {
                        "hide-details": "",
                        disabled: _vm.disabledAddUsers,
                        label: _vm.$t("components.organisationGrid.teamMember")
                      },
                      model: {
                        value: _vm.addUsers,
                        callback: function($$v) {
                          _vm.addUsers = $$v
                        },
                        expression: "addUsers"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("v-combobox", {
                  staticClass: "mb-5 managerCombobox",
                  attrs: {
                    disabled: !_vm.addUsers && _vm.selectedUsers.length === 4,
                    outlined: "",
                    items: _vm.dataComboboxAllUsers,
                    "search-input": _vm.searchUsers,
                    select: "",
                    label: !_vm.addUsers
                      ? _vm.$t("components.organisationGrid.manager")
                      : _vm.$t("generics.users"),
                    multiple: "",
                    "hide-details": "",
                    dense: "",
                    "item-value": "value",
                    "item-text": "text",
                    "return-object": true
                  },
                  on: {
                    "update:searchInput": function($event) {
                      _vm.searchUsers = $event
                    },
                    "update:search-input": function($event) {
                      _vm.searchUsers = $event
                    },
                    change: function($event) {
                      _vm.searchUsers = ""
                    },
                    input: _vm.limitUserSelected
                  },
                  model: {
                    value: _vm.selectedUsers,
                    callback: function($$v) {
                      _vm.selectedUsers = $$v
                    },
                    expression: "selectedUsers"
                  }
                }),
                _vm._v(" "),
                _vm._l(_vm.selectedUsers, function(user) {
                  return _c(
                    "v-chip",
                    {
                      key: user.text,
                      staticClass: "mb-2 mr-2 chipContainer",
                      attrs: { default: "", label: "", close: "" },
                      on: {
                        "click:close": function($event) {
                          return _vm.removeUserSelected(user.value)
                        }
                      }
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "rounded",
                          attrs: {
                            width: "32px!important",
                            height: "32px!important",
                            "min-width": "32px!important",
                            left: ""
                          }
                        },
                        [
                          _c("v-img", {
                            staticClass: "rounded",
                            attrs: { src: _vm.getAvatarForUuid(user.value) }
                          })
                        ],
                        1
                      ),
                      _vm._v("\n          " + _vm._s(user.text) + "\n        ")
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            !_vm.addUsers
              ? _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    !_vm.addUsers
                      ? _c("v-combobox", {
                          staticClass: "mb-5",
                          attrs: {
                            disabled: _vm.addUsers,
                            outlined: "",
                            items: _vm.dataComboboxAllUsers,
                            "search-input": _vm.searchUsers,
                            select: "",
                            label: _vm.$t("excel.assistants"),
                            multiple: "",
                            "hide-details": "",
                            dense: "",
                            "item-value": "value",
                            "item-text": "text",
                            "return-object": true
                          },
                          on: {
                            "update:searchInput": function($event) {
                              _vm.searchUsers = $event
                            },
                            "update:search-input": function($event) {
                              _vm.searchUsers = $event
                            },
                            change: function($event) {
                              _vm.searchUsers = ""
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(data) {
                                  return [data ? _c("div") : _vm._e()]
                                }
                              }
                            ],
                            null,
                            false,
                            1162935712
                          ),
                          model: {
                            value: _vm.selectedAssistants,
                            callback: function($$v) {
                              _vm.selectedAssistants = $$v
                            },
                            expression: "selectedAssistants"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.selectedAssistants, function(user) {
                      return _c(
                        "v-chip",
                        {
                          key: user.text,
                          staticClass: "mb-2 mr-2 chipContainer",
                          attrs: { default: "", label: "", close: "" },
                          on: {
                            "click:close": function($event) {
                              return _vm.removeAssistantsSelected(user.value)
                            }
                          }
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "rounded",
                              attrs: { tile: "", left: "" }
                            },
                            [
                              _c("v-img", {
                                staticClass: "rounded",
                                attrs: {
                                  width: "32px",
                                  height: "32px",
                                  src: _vm.getAvatarForUuid(user.value)
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(
                            "\n          " + _vm._s(user.text) + "\n        "
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _vm.isEdit
                ? _c(
                    "v-btn",
                    {
                      staticClass: "leftSidePosition ml-3",
                      attrs: { text: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.remove()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.organisationGrid.removeDepart")
                          )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    depressed: "",
                    color: "primary",
                    disabled: _vm.disableSave
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }