var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messagesArray.length !== 0 ||
    Object.keys(_vm.state.FileTransferModal.files).length !== 0
    ? _c(
        "v-list",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { "two-line": "", "min-height": 310 }
        },
        [
          _c("vue-scroll", { staticClass: "vueScroll" }, [
            _c(
              "div",
              { staticStyle: { height: "310px" } },
              [
                _c(
                  "v-list-item-group",
                  { attrs: { id: "messagesDropDown" } },
                  [
                    Object.keys(_vm.state.FileTransferModal.files).length !== 0
                      ? _c(
                          "div",
                          _vm._l(_vm.state.FileTransferModal.files, function(
                            entry,
                            key
                          ) {
                            return _c(
                              "div",
                              { key: key },
                              [_c("FileItemList", { attrs: { entry: entry } })],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.messagesArray, function(message, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          message
                            ? _c("v-list-item", {
                                key: index,
                                on: {
                                  click: function($event) {
                                    return _vm.openNotification(message)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        return [
                                          !_vm.isSentMessage(message)
                                            ? _c("font-awesome-icon", {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  icon: ["fal", "inbox-in"]
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSentMessage(message)
                                            ? _c("font-awesome-icon", {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  icon: ["fal", "inbox-out"]
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-avatar",
                                            {
                                              staticClass:
                                                "borderRadius10 cursorPointer hidden-sm-and-down",
                                              style: {
                                                border:
                                                  "2px solid " +
                                                  _vm.setBorderByStatus(
                                                    _vm.state.group[
                                                      _vm.getDataUserMessage(
                                                        message
                                                      )
                                                    ]
                                                  )
                                              },
                                              attrs: { tile: "", size: "60" }
                                            },
                                            [
                                              _vm.getAvatarForUuid(
                                                _vm.getDataUserMessage(message)
                                              ) ==
                                              "img/default_profile_picture.png"
                                                ? _c("DefaultAvatarGrid", {
                                                    attrs: {
                                                      size: 120,
                                                      userData:
                                                        _vm.state.group[
                                                          _vm.getDataUserMessage(
                                                            message
                                                          )
                                                        ]
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.getAvatarForUuid(
                                                _vm.getDataUserMessage(message)
                                              ) !=
                                              "img/default_profile_picture.png"
                                                ? _c("v-img", {
                                                    staticClass:
                                                      "mx-2 borderRadius10",
                                                    attrs: {
                                                      "max-height": "60",
                                                      "max-width": "60",
                                                      contain: "",
                                                      src: _vm.getAvatarForUuid(
                                                        _vm.getDataUserMessage(
                                                          message
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "pl-2" },
                                            [
                                              _c("v-list-item-title", {
                                                class: _vm.unreadNotification(
                                                  message
                                                ),
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.getNameForUuid(
                                                      _vm.getDataUserMessage(
                                                        message
                                                      )
                                                    )
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-list-item-subtitle", {
                                                staticClass: "text--primary",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.getTextBody(message)
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-action",
                                            { staticClass: "ml-0" },
                                            [
                                              _c("v-list-item-action-text", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.formatNoticationDate(
                                                      message.date
                                                    )
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex",
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                [
                                                  _c("DeleteBasket", {
                                                    attrs: {
                                                      indexData: message,
                                                      delFunction:
                                                        _vm.deleteNotification,
                                                      extraClasses: "mr-4",
                                                      fontSize: "16",
                                                      prevent: "true"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  !_vm.isWaitingRoomUser &&
                                                  (message.type == "message" ||
                                                    message.type ==
                                                      "plannerEvent")
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mr-4",
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "paper-plane"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                              _vm.setShowModalSenMsg(
                                                                                _vm.getDataUserMessage(
                                                                                  message
                                                                                ),
                                                                                "message"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.userProfile.sendAMessage"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  message.type === "call" &&
                                                  !_vm.isWaitingRoomUser &&
                                                  !_vm.isSentMessage(message)
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mr-4",
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "phone"
                                                                            ],
                                                                            color: !_vm.disableCallBtn(
                                                                              message.creatorUUID
                                                                            )
                                                                              ? _vm.setBorderByStatus(
                                                                                  _vm
                                                                                    .state
                                                                                    .group[
                                                                                    message
                                                                                      .creatorUUID
                                                                                  ]
                                                                                )
                                                                              : "grey"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              _vm.disableCallBtn(
                                                                                message.creatorUUID
                                                                              )
                                                                                ? false
                                                                                : _vm.callUser(
                                                                                    message.creatorUUID
                                                                                  )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.disableCallBtn(
                                                                  message.creatorUUID
                                                                )
                                                                  ? "Dieser Benutzer ist zur Zeit offline"
                                                                  : _vm.$t(
                                                                      "components.userProfile.callUser"
                                                                    )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  message.type === "file"
                                                    ? _c(
                                                        "v-tooltip",
                                                        { attrs: { top: "" } },
                                                        [
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-4",
                                                                staticStyle: {
                                                                  "pointer-events":
                                                                    "none"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "download"
                                                                  ],
                                                                  color:
                                                                    "grey lighten-1"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.downloadsDropdown.fileNotAvailable"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "btnRed",
                              staticStyle: {
                                float: "right",
                                margin: "5px 5px 0px 0px"
                              },
                              attrs: { icon: "", color: "white" }
                            },
                            on
                          ),
                          [
                            _c("DeleteBasket", {
                              attrs: {
                                indexData: "",
                                delFunction: _vm.removeAllListedItemsEffect,
                                extraClasses: "",
                                fontSize: "20",
                                prevent: "false"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2380448236
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.downloadsDropdown.removeAll")))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }