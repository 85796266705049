<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.logout')"
        :closeModalFunction="closeModal"
      />
      <v-row class="mx-0 pt-4 pb-1 px-4">
        <v-col>
          <p class="font-weight-medium">{{ $t("generics.logoutModal") }}</p>
          <p>{{ $t("generics.logoutTT") }}</p>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn
          style="color: #2a3133"
          depressed
          color="white"
          @click="logout()"
          >{{ $t("generics.logout") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { wsCall } from "../../lib/wsConnect";
import { resetlogoutModalEvent, logoutModalStore } from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  effector: {
    showModal: logoutModalStore,
  },
  methods: {
    closeModal() {
      resetlogoutModalEvent();
    },
    logout() {
      const localSecretKey = "ownSecret_" + this.ownUUID;
      const localTwoTokenKey = "ownTwoToken_" + this.ownUUID;
      localStorage.removeItem(localSecretKey);
      localStorage.removeItem(localTwoTokenKey);
      localStorage.removeItem("temporalStatus");
      wsCall('revokeTwoToken');
      window.location.reload();
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
