var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "paymentModal",
      attrs: {
        persistent: "",
        "max-width": "570",
        "hide-overlay": "",
        attach: "#videos"
      },
      model: {
        value: _vm.showSuccessPayment,
        callback: function($$v) {
          _vm.showSuccessPayment = $$v
        },
        expression: "showSuccessPayment"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.paymentModal.titleRequestPayment"
                ),
                closeModalFunction: _vm.declinePayment,
                actualDate: _vm.actualDate
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c(
                  "v-row",
                  { staticClass: "mx-0 mb-2" },
                  [
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPayPalSandbox,
                            expression: "isPayPalSandbox"
                          }
                        ],
                        attrs: { cols: "12" }
                      },
                      [
                        _c("p", { staticClass: "mb-0 colorRed" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("components.paymentModal.testMode")
                              ) +
                              "\n            "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "components.paymentModal.paymentSuccessText",
                                [
                                  this.formatProductPrice(
                                    this.state.user.paymentState.total
                                  ) +
                                    " " +
                                    this.state.namespaceSettings.currency,
                                  this.virtualOfficePrefix
                                ]
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-card",
              {
                staticClass: "py-2 pl-4 pr-2 footerModal",
                attrs: { color: "primary" }
              },
              [
                _c(
                  "v-row",
                  { staticClass: "px-2 btns w-100 mx-0 my-0" },
                  [
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "closeButton ml-2",
                                        attrs: {
                                          outlined: "",
                                          icon: "",
                                          tile: "",
                                          color: "white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.declinePayment($event)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "times"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                        ]
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }