var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("youtube", {
    ref: "youtube",
    attrs: {
      "video-id": _vm.youTubeVideoId,
      "player-vars": _vm.playerVars,
      width: "100%",
      height: "100%"
    },
    on: { playing: _vm.playing, ready: _vm.setEventListener }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }