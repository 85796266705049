<template>
  <v-dialog v-model="showJoinBridgeCallModal.show" persistent max-width="560" @keydown.esc="cancelJoinBridgeCall">
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="`Calling ${getNameForUuid(
            showJoinBridgeCallModal.info.user
          )}`"
          :closeModalFunction="cancelJoinBridgeCall"
        />
        <!-- <v-card-text>
          <div class="mt-8 d-flex justify-center">
            Calling {{ getNameForUuid(showJoinBridgeCallModal.info.user) }}
            <button @click="cancelJoinBridgeCalll">cancel</button>
          </div>
        </v-card-text> -->
        <v-row flex class="w-100 mx-0 my-0">
          <v-divider class="mt-4"></v-divider>
          <v-col
            v-for="n in 2"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
          >
            <div v-if="n == 1" align="center">
              <v-badge
                bordered
                overlay
                height="60"
                width="60"
                bottom
                color="#008000"
                dot
                offset-x="22"
                offset-y="15"
              >
                <v-list-item-avatar tile size="150" class="borderRadius10">
                  <v-img
                    class="borderRadius10"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="getAvatarForUuid(showJoinBridgeCallModal.info.user)"
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
                </v-list-item-avatar>
              </v-badge>
            </div>
            <div v-if="n == 2">
              <div
                class="userNameCard my-0 mt-5 d-flex justify-center"
                :style="{ fontSize: '20px' }"
              >
                Calling...
              </div>
            </div>
          </v-col>
          <!-- <v-divider class="mt-4"></v-divider> -->
        </v-row>
        <!-- :closeModalFunction="cancelJoinBridgeCall" -->
        <FooterModal :hideDefaultButtons="true">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                :color="isDark ? 'white' : 'primary'"
                ref="cancelCall"
                @click="cancelJoinBridgeCall()"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  class="faPhoneRotate"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.hangUp") }}</span>
          </v-tooltip>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { wsCall } from "../../lib/wsConnect";
import {
  joinBridgeCallStore,
  resetJoinBridgeCallModalEvent,
} from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: [],
  data: () => ({
    state: store.state,
  }),
  effector: {
    showJoinBridgeCallModal: joinBridgeCallStore,
  },
  mounted() {},
  destroyed() {
    if (this.state.user.inBridgeCall && this.state.user.inBridgeCall.length > 0) {
      store.setCurrentContentVisile("/", false, this.$router);
    }
  },
  // beforeDestroy() {
  //   if (this.state.inBridgeCall && this.state.inBridgeCall.length > 0) {
  //     store.setCurrentContentVisile("/", false, this.$router);
  //   }
  // },
  methods: {
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    cancelJoinBridgeCall() {
      const info = this.showJoinBridgeCallModal.info;
      wsCall("sendToUUID", info.user, {
        type: "bridge-signal",
        action: "cancel_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: info.callUUID,
        },
      });
      this.closeModal();
    },
    closeModal() {
      resetJoinBridgeCallModalEvent();
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    cols() {
      const { lg, sm, md, xs, xl } = this.$vuetify.breakpoint;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>

<style scoped lang="scss">
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
</style>