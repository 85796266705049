var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.inviteVisitor.textTitle"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              { staticClass: "py-0 pt-4" },
              [
                _c("v-text-field", {
                  attrs: {
                    required: "",
                    outlined: "",
                    dense: "",
                    maxlength: "50",
                    counter: "",
                    "error-messages": _vm.titleExists
                      ? _vm.$t("components.inviteVisitor.templateExists")
                      : null
                  },
                  model: {
                    value: _vm.textTitle,
                    callback: function($$v) {
                      _vm.textTitle = $$v
                    },
                    expression: "textTitle"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              { attrs: { closeModalFunction: _vm.closeModal } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.titleExists || _vm.textTitle.length == 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveTextAs(_vm.textTitle)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("generics.save")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }