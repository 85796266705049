var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "inviteVisitorMenu" } },
    [
      _c(
        "v-list",
        {
          staticClass: "invitationMenuList",
          attrs: { width: _vm.width, dense: "" }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-2",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.typeInvite === "guest"
                      ? _vm.$t("components.inviteGuestPanel.headlineGuest")
                      : _vm.$t("components.inviteGuestPanel.headlineUser")
                  ) +
                  "\n        "
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticStyle: { "margin-left": "auto" },
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.showInviteInfo = true
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "30px" },
                                attrs: { icon: ["fal", "info-circle"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.info")))])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        margin: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "col-6 px-2 py-0" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "components.inviteGuestPanel.name"
                                              ),
                                              required: "",
                                              rules: _vm.userNameRules
                                            },
                                            model: {
                                              value: _vm.username,
                                              callback: function($$v) {
                                                _vm.username = $$v
                                              },
                                              expression: "username"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-6 px-2 py-0" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t(
                                                "components.inviteGuestPanel.eMail"
                                              ),
                                              required: "",
                                              rules: _vm.emailRules
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.checkEmailPreSend()
                                              }
                                            },
                                            model: {
                                              value: _vm.email,
                                              callback: function($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !_vm.isBasicView
                                    ? _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            margin: "0"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "col-6 px-2 py-0" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "components.inviteGuestPanel.title"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.title,
                                                  callback: function($$v) {
                                                    _vm.title = $$v
                                                  },
                                                  expression: "title"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { staticClass: "col-6 px-2 py-0" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "components.inviteGuestPanel.position"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.position,
                                                  callback: function($$v) {
                                                    _vm.position = $$v
                                                  },
                                                  expression: "position"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.teams && !_vm.isBasicView
                                    ? _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            margin: "0"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0 px-2",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "components.inviteGuestPanel.teams"
                                                  ),
                                                  items: _vm.teams,
                                                  "item-value": "team",
                                                  "item-text": "team",
                                                  multiple: "",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: _vm.selectedTeams,
                                                  callback: function($$v) {
                                                    _vm.selectedTeams = $$v
                                                  },
                                                  expression: "selectedTeams"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 px-2",
                                          attrs: { cols: "12" }
                                        },
                                        [_c("v-row", { staticClass: "mx-0" })],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: {
                                        width: "100%",
                                        margin: "0",
                                        padding: "0px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-baseline"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "max-width": "350"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.amIAdmin &&
                                                      _vm.typeInvite !== "guest"
                                                        ? _c(
                                                            "v-switch",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "my-0 py-2 mr-6",
                                                                attrs: {
                                                                  width: "100%"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.createAdmin,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.createAdmin = $$v
                                                                  },
                                                                  expression:
                                                                    "createAdmin"
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "label"
                                                                    },
                                                                    slot:
                                                                      "label"
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.inviteGuestPanel.adminRights"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.adminRightsText"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "max-width": "350"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.amIAdmin &&
                                                      _vm.typeInvite !== "guest"
                                                        ? _c(
                                                            "v-switch",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "my-0 py-2 mr-6",
                                                                attrs: {
                                                                  width: "100%"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.activateUserSupport()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userSupport,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.userSupport = $$v
                                                                  },
                                                                  expression:
                                                                    "userSupport"
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "label"
                                                                    },
                                                                    slot:
                                                                      "label"
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.inviteGuestPanel.inviteUserSupport"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.inviteSupportText"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "max-width": "350"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-checkbox",
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-6",
                                                            model: {
                                                              value:
                                                                _vm.pinActive,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.pinActive = $$v
                                                              },
                                                              expression:
                                                                "pinActive"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  slot: "label"
                                                                },
                                                                slot: "label"
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.2faModal.PINInviteUser"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.2faModal.btnEnablePINText"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.pinActive
                                            ? _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    staticClass: "mr-6",
                                                    attrs: {
                                                      autocomplete: "off",
                                                      label: _vm.$t(
                                                        "components.inviteGuestPanel.enterInvitationPinCode"
                                                      ),
                                                      outlined: "",
                                                      dense: "",
                                                      maxlength: 6
                                                    },
                                                    on: {
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "space",
                                                            32,
                                                            $event.key,
                                                            [" ", "Spacebar"]
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.pinCode,
                                                      callback: function($$v) {
                                                        _vm.pinCode = $$v
                                                      },
                                                      expression: "pinCode"
                                                    }
                                                  },
                                                  _vm.on
                                                )
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        margin: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "col-12 px-2 py-2" },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "py-0 my-0",
                                            attrs: {
                                              name: "inputinvite",
                                              "no-resize": true,
                                              rows: "7"
                                            },
                                            model: {
                                              value: _vm.customMessage,
                                              callback: function($$v) {
                                                _vm.customMessage = $$v
                                              },
                                              expression: "customMessage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.errMssg
                                    ? _c(
                                        "v-row",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            margin: "0",
                                            padding: "0px"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticStyle: {
                                                height: "24px",
                                                margin: "0px",
                                                padding: "0px",
                                                color: "red"
                                              },
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "danger",
                                                  staticStyle: {
                                                    float: "right",
                                                    "margin-right": "45px"
                                                  }
                                                },
                                                [_vm._v(_vm._s(_vm.errMssg))]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                {
                                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal"
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "btns mx-0",
                                      attrs: { flex: "" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 py-0",
                                          attrs: { cols: "12", align: "right" }
                                        },
                                        [
                                          _vm.amIAdmin
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.deleteCustomMessage
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.inviteGuestPanel.deleteCustomInviteText"
                                                      )
                                                    ) + "\n                   "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.amIAdmin
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: _vm.saveCustomMessage
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.inviteGuestPanel.saveText"
                                                      )
                                                    ) + "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.amIAdmin
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    loading:
                                                      _vm.sendingInvitation
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showListInvite
                                                        ? (_vm.showListInvite = false)
                                                        : (_vm.showListInvite = true)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.inviteGuestPanel.inviteList"
                                                      )
                                                    ) + "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.valid ||
                                                                _vm.disableButtonSend,
                                                              color: "primary",
                                                              loading:
                                                                _vm.sendingInvitation
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendInvitation(
                                                                  false
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.inviteGuestPanel.sendAndNew"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.sendAndNewText"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled:
                                                  !_vm.valid ||
                                                  _vm.disableButtonSend,
                                                color: "primary",
                                                loading: _vm.sendingInvitation
                                              },
                                              on: { click: _vm.sendInvitation }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.inviteGuestPanel.sendInvitation"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.closeMenu()
                                                  _vm.cleanData()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("generics.cancel")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.state.persisted.invitationsSent.length > 0
                    ? _c("v-divider")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.persisted.invitationsSent.length > 0 &&
                  _vm.showListInvite
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-container",
                                    {
                                      style: { paddingLeft: 0, paddingRight: 0 }
                                    },
                                    _vm._l(_vm.getInvitationList, function(
                                      invitation,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "p",
                                              { staticClass: "email" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(invitation.email) +
                                                    "\n                      "
                                                ),
                                                invitation.uuid
                                                  ? _c("font-awesome-icon", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.hasUuidBeenOnPage(
                                                            invitation.uuid
                                                          ),
                                                          expression:
                                                            "hasUuidBeenOnPage(invitation.uuid)"
                                                        }
                                                      ],
                                                      attrs: {
                                                        icon: [
                                                          "fal",
                                                          "check-double"
                                                        ]
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "date" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .moment(invitation.date)
                                                    .format("DD.MM.YYYY | H:mm")
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mt-2 mb-2"
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showInviteInfo
        ? _c("sendInviteInfoModal", {
            attrs: {
              typeInvite: _vm.typeInvite,
              closeShowInviteInfo: _vm.closeShowInviteInfo,
              showInviteInfo: _vm.showInviteInfo
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalInfo
        ? _c("infoModalTypeInvitation", {
            attrs: {
              showInfoModalTypeInvitation: _vm.showModalInfo,
              closeshowInfoModalTypeInvitation:
                _vm.closeshowInfoModalTypeInvitation
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPinInfoModal
        ? _c("InfoPinModal", {
            attrs: {
              showPinInfoModal: _vm.showPinInfoModal,
              closePinInfoModal: _vm.closePinInfoModal
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }