var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show2FaModal
    ? _c("TwoFaAdminConfirmation", {
        attrs: {
          showModal: _vm.show2FaModal,
          toggle2faAdminConfirmation: _vm.toggle2faAdminConfirmation
        }
      })
    : _c(
        "div",
        [
          _c("ExcelEditor", {
            attrs: { fromWhere: "content", excelEdited: _vm.excelEdited }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }