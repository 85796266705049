var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InviteFormContainer" },
    [
      _c(
        "v-card",
        { staticClass: "pt-6 pl-10 pr-10 h100" },
        [_c("SambaRoomGrid", { ref: "confRoom" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }