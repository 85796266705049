var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.state.showNote.note,
        callback: function($$v) {
          _vm.$set(_vm.state.showNote, "note", $$v)
        },
        expression: "state.showNote.note"
      }
    },
    [
      _c(
        "v-col",
        { staticClass: "noteDiv px-1 col-12", attrs: { md: 12, sm: 12 } },
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.$t("components.modalNote.newInfoToUser", [
                    _vm.selectionName
                  ]),
                  closeModalFunction: _vm.closeModal
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.editing || this.inserting
            ? _c(
                "v-card",
                {
                  staticClass: "single-note add-note",
                  attrs: { outlined: "", flat: "" }
                },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "white-note", attrs: { "three-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-checkbox", {
                            staticClass: "mencheckbox allusers",
                            attrs: {
                              label: _vm.$t("components.modalNote.allUsers"),
                              readonly: "readonly"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setSelectedArea(
                                  "allUsers",
                                  "allUsers"
                                )
                              }
                            },
                            model: {
                              value: _vm.selectAll,
                              callback: function($$v) {
                                _vm.selectAll = $$v
                              },
                              expression: "selectAll"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "max-height": "400",
                                bottom: "",
                                origin: "center center",
                                transition: "scale-transition",
                                "close-on-click": _vm.closeOnClick,
                                "close-on-content-click": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-checkbox",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "mencheckbox sections cbNoteHead",
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.modalNote.section"
                                                ),
                                                readonly: "readonly"
                                              },
                                              model: {
                                                value: _vm.sectionsChecked,
                                                callback: function($$v) {
                                                  _vm.sectionsChecked = $$v
                                                },
                                                expression: "sectionsChecked"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2003458042
                              ),
                              model: {
                                value: _vm.isSection,
                                callback: function($$v) {
                                  _vm.isSection = $$v
                                },
                                expression: "isSection"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticStyle: {
                                    "padding-bottom": "0px !important"
                                  }
                                },
                                [
                                  _c("v-list-item", [
                                    _c(
                                      "div",
                                      { staticStyle: { margin: "8px" } },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "py-0" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "font-weight": "600",
                                                "font-size": "19px"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.modalNote.findSection"
                                                )
                                              },
                                              model: {
                                                value: _vm.selectionSearchTerm,
                                                callback: function($$v) {
                                                  _vm.selectionSearchTerm = $$v
                                                },
                                                expression:
                                                  "selectionSearchTerm"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "confined" },
                                    _vm._l(_vm.sectionData, function(item) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: item.name,
                                          staticClass: "mencheckbox",
                                          class: {
                                            selactive:
                                              item.name === _vm.activeItem
                                          },
                                          attrs: { "item-text": "name" }
                                        },
                                        [
                                          _c("v-list-item-title", {
                                            staticClass:
                                              "mencheckbox listOrgItem",
                                            domProps: {
                                              textContent: _vm._s(item.name)
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.setSelectedArea(
                                                  "section",
                                                  item.name
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.state
                                                  .showModalMultiSelectNote.info
                                                  .selectSection,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.state
                                                    .showModalMultiSelectNote
                                                    .info,
                                                  "selectSection",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "state.showModalMultiSelectNote.info.selectSection"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item", {
                                    staticClass: "select-box"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                origin: "center center",
                                transition: "scale-transition",
                                "close-on-click": _vm.closeOnClick,
                                "close-on-content-click": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-checkbox",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "mencheckbox departments cbNoteHead",
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.modalNote.department"
                                                ),
                                                readonly: "readonly"
                                              },
                                              model: {
                                                value: _vm.departmentsChecked,
                                                callback: function($$v) {
                                                  _vm.departmentsChecked = $$v
                                                },
                                                expression: "departmentsChecked"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                864714039
                              ),
                              model: {
                                value: _vm.isDepartment,
                                callback: function($$v) {
                                  _vm.isDepartment = $$v
                                },
                                expression: "isDepartment"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticStyle: {
                                    "padding-bottom": "0px !important"
                                  }
                                },
                                [
                                  _c("v-list-item", [
                                    _c(
                                      "div",
                                      { staticStyle: { margin: "8px" } },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "py-0" },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: {
                                                "font-weight": "600",
                                                "font-size": "19px"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.modalNote.findDepartments"
                                                )
                                              },
                                              model: {
                                                value: _vm.selectionSearchTerm,
                                                callback: function($$v) {
                                                  _vm.selectionSearchTerm = $$v
                                                },
                                                expression:
                                                  "selectionSearchTerm"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "confined" },
                                    _vm._l(_vm.departmentData, function(item) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: item.name,
                                          staticClass: "mencheckbox",
                                          class: {
                                            selactive:
                                              item.name === _vm.activeItem
                                          },
                                          attrs: { "item-text": "name" }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                staticClass:
                                                  "mencheckbox listOrgItem",
                                                domProps: {
                                                  textContent: _vm._s(item.name)
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setSelectedArea(
                                                      "department",
                                                      item.name
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.state
                                                      .showModalMultiSelectNote
                                                      .info.selectDepartments,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.state
                                                        .showModalMultiSelectNote
                                                        .info,
                                                      "selectDepartments",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "state.showModalMultiSelectNote.info.selectDepartments"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item", {
                                    staticClass: "select-box"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "max-height": "400",
                                bottom: "",
                                origin: "center center",
                                transition: "scale-transition",
                                "close-on-click": _vm.closeOnClick,
                                "close-on-content-click": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-checkbox",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "mencheckbox teams cbNoteHead",
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.modalNote.team"
                                                ),
                                                readonly: "readonly"
                                              },
                                              model: {
                                                value: _vm.teamsChecked,
                                                callback: function($$v) {
                                                  _vm.teamsChecked = $$v
                                                },
                                                expression: "teamsChecked"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3549487758
                              ),
                              model: {
                                value: _vm.isTeam,
                                callback: function($$v) {
                                  _vm.isTeam = $$v
                                },
                                expression: "isTeam"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticStyle: {
                                    "padding-bottom": "0px !important"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { margin: "8px" } },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "py-0" },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "font-weight": "600",
                                              "font-size": "19px"
                                            },
                                            attrs: {
                                              label: _vm.$t(
                                                "components.modalNote.findTeams"
                                              )
                                            },
                                            model: {
                                              value: _vm.selectionSearchTerm,
                                              callback: function($$v) {
                                                _vm.selectionSearchTerm = $$v
                                              },
                                              expression: "selectionSearchTerm"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "confined" },
                                    _vm._l(_vm.teamsData, function(item) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: item.name,
                                          staticClass: "mencheckbox",
                                          class: {
                                            selactive:
                                              item.name === _vm.activeItem
                                          },
                                          attrs: {
                                            "active-class": "activeSelected",
                                            "item-text": "name"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                staticClass:
                                                  "mencheckbox listOrgItem",
                                                domProps: {
                                                  textContent: _vm._s(item.name)
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setSelectedArea(
                                                      "team",
                                                      item.name
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.state
                                                      .showModalMultiSelectNote
                                                      .info.selectTeams,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.state
                                                        .showModalMultiSelectNote
                                                        .info,
                                                      "selectTeams",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "state.showModalMultiSelectNote.info.selectTeams"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item", {
                                    staticClass: "select-box"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "py-0",
                              staticStyle: { "margin-left": "60px" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "noteEntryText",
                                attrs: {
                                  rules: _vm.rules,
                                  counter: _vm.titleMax,
                                  label: _vm.$t("components.firstModal.title")
                                },
                                on: { keydown: _vm.onTitleKeyDown },
                                model: {
                                  value: _vm.noteTitle,
                                  callback: function($$v) {
                                    _vm.noteTitle = $$v
                                  },
                                  expression: "noteTitle"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-subtitle",
                            { staticStyle: { "margin-left": "60px" } },
                            [
                              _c("v-textarea", {
                                staticClass: "noteEntryText",
                                class:
                                  _vm.isMessageExpanded(
                                    _vm.state.showNote.note.text
                                  ) === true
                                    ? "messageTextExpand"
                                    : "messageText",
                                staticStyle: { "font-size": "20px" },
                                attrs: {
                                  rules: _vm.ruledesc,
                                  counter: _vm.descMax,
                                  name: "input-7-1",
                                  "no-resize": ""
                                },
                                on: { keydown: _vm.onDescKeyDown },
                                model: {
                                  value: _vm.noteText,
                                  callback: function($$v) {
                                    _vm.noteText = $$v
                                  },
                                  expression: "noteText"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-5 mx-0",
                              style: { width: "100%", margin: 0 }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0",
                                  staticStyle: { "margin-left": "39px" },
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "290px"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "components.modalNote.postingUntil"
                                                        )
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formatDateActiveUntil,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.formatDateActiveUntil = $$v
                                                        },
                                                        expression:
                                                          "formatDateActiveUntil"
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        slot: "prepend",
                                                        icon: [
                                                          "fal",
                                                          "calendar-alt"
                                                        ]
                                                      },
                                                      slot: "prepend"
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1935465081
                                      ),
                                      model: {
                                        value: _vm.notedate,
                                        callback: function($$v) {
                                          _vm.notedate = $$v
                                        },
                                        expression: "notedate"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-date-picker", {
                                        attrs: {
                                          "show-current": true,
                                          pickTime: true,
                                          format: "YYYY-MM-DD H:mm",
                                          locale: this.$locale.current()
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.notedate = false
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.state.showModalMultiSelectNote
                                              .activeUntil,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.state
                                                .showModalMultiSelectNote,
                                              "activeUntil",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "state.showModalMultiSelectNote.activeUntil"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          1 === 2
                            ? _c(
                                "v-row",
                                { staticClass: "px-2 btns mx-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0 py-0",
                                      attrs: { lg: "12", align: "left" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _vm.amIAdmin
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.toggleMultiSender
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                style: {
                                                                  fontSize:
                                                                    "20px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "paper-plane"
                                                                  ]
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1016434827
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.modalNote.sendTo"
                                                )
                                              ) + " ..."
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "primary" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-start": "" } },
                        [
                          _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "footerDate",
                              staticStyle: { "text-align": "left" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .moment(new Date())
                                    .format("DD.MM.YYYY H:mm")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: {
                                "margin-left": "19px",
                                "margin-right": "10px"
                              },
                              attrs: {
                                color: "primary",
                                disabled: !_vm.isAllowToPublish
                              },
                              on: {
                                click: function($event) {
                                  return _vm.confirmPublish()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n           " +
                                  _vm._s(_vm.$t("generics.publish")) +
                                  " \n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setShowNote(
                                                    false,
                                                    undefined
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("generics.cancel")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1231629829
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.cancel")) + " ")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.editing && !_vm.inserting
            ? _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.noteTitle))
                  ]),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "px-5 mx-0" },
                    [
                      _c("vue-scroll", [
                        _c("div", { staticStyle: { height: "300px" } }, [
                          _vm.state.showNote
                            ? _c("div", {
                                staticClass: "noteText",
                                class:
                                  _vm.isMessageExpanded(_vm.noteText) === true
                                    ? "messageTextExpand"
                                    : "messageText",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.modifiedText(_vm.noteText)
                                  )
                                }
                              })
                            : _vm._e()
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                      attrs: { color: "primary" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-2 btns mx-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 py-0",
                              attrs: { lg: "12", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { icon: "", color: "white" } },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "check"] },
                                    on: {
                                      click: function($event) {
                                        return _vm.setShowNote(false, undefined)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "460" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeConfirmationModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModalInsertNote,
            callback: function($$v) {
              _vm.showModalInsertNote = $$v
            },
            expression: "showModalInsertNote"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("components.modalNote.publishNote")))
              ]),
              _vm._v(" "),
              _vm.inserting
                ? _c("v-card-text", [
                    _vm._v(
                      _vm._s(_vm.$t("components.modalNote.sureWantPublishNote"))
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editing
                ? _c("v-card-text", [
                    _vm._v(
                      _vm._s(_vm.$t("components.modalNote.sureWantUpdateNote"))
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "12", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.insertNote }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.inserting === true
                                    ? _vm.$t("components.modalNote.publishNote")
                                    : _vm.$t("components.modalNote.send")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.closeConfirmationModal }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }