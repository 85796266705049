var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    {
      staticClass: "mx-2 noBorderRadius",
      attrs: {
        "min-height": _vm.size,
        "min-width": _vm.size,
        "max-height": _vm.size,
        "max-width": _vm.size,
        color: !_vm.userHasRejected ? "primary" : "#ff00006e"
      }
    },
    [
      _c("span", { staticClass: "white--text" }, [
        _vm._v(_vm._s(_vm.getInitials))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }