<template>
  <div
    style="back"
    :class="{
      'd-none': hideVideoContainer,
      maximizeScreen: true,
      maximizeScreenMobile: isMobile,
    }"
  >
    <div :class="{ sidebarCallWrapper: !showTimeLine, sidebarCallWrapperTimeline: showTimeLine}" class="sidebarCallWrapper">
      <SidebarCall
        v-if="maximizeScreen && hasPrivilege(this.ownUUID)"
        :maximizeScreen="maximizeScreen"
        :isConferenceCall="isConferenceCall"
        :getAmIModerator="true"
      />
    </div>
    <div class="w100 progressLinearBridge">
    <ProgressLinearBridge
      :amIModerator="true"
      :updateTimeForUser="updateTimeForUser"
      :setCallDuration="setCallDuration"
      :hangUpBridgeCall="hangUpBridgeCall"
      :redirectToStartView="redirectToStartView"
      :isConferenceCall="isConferenceCall"
      :rejectCall="rejectCall"
      v-if="showTimeLine"
    />
    </div>
    <div class="callingToaster" v-if="amICalling.length">
      <v-alert
        v-for="(participant) in amICalling"
        :key="participant"
        prominent
        color="white"
      >
        <v-row align="center">
          <v-col class="grow d-flex vColCallingToaster">
            <div class="waveCallingBridge">
              <div class="dot" v-for="index in 3" :key="index"></div>
            </div>
            <div class="ml-2" style="color:black;">Calling {{getNameForUuid(participant)}}</div>
          </v-col>
          <v-col class="shrink pt-4 pb-2">
            <v-btn
              class="buttonsCall"
              icon
              variant="text"
              color="primary"
              @click.prevent.stop="hangUpCalling(participant)"
            >
              <font-awesome-icon
                :icon="['fal', 'phone']"
                class="faPhoneRotate"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <div style="background: black;" :class="{ heightNoTimeline: !showTimeLine, heightTimeline: showTimeLine, callContainerNoSidebar:!hasPrivilege(ownUUID), callContainerSidebar:hasPrivilege(ownUUID) }">
      <!-- UI HERE -->
      <!-- Example usage of vue-resizable-box -->
      <div v-show="shareScreenEnabled" class="top-left resizable-container" :style="topLeftStyle">
        <div v-show="shareScreenEnabled" class="screen-share-container">
          <div class="screen-share-item">
            <!-- Screenshare name on hover -->
            <div class="container-div-hover">
              <div class="name-on-hover">
                <span class="span-hover">{{getNameForUuid(shareScreenEnabled)}} Screenshare</span>
              </div>
            </div>
            <div class="containerButtonsOverlay">
              <div class="divButtonsOverlay">
                <button class="buttonOverlay" @click="toggleFullScreen('shareScreenVideo')">
                  <font-awesome-icon
                      v-if="!isFullScreenEnabled"
                      :icon="['fad', 'expand']"
                      :style="{fontSize: '16px'}"
                    />
                  <font-awesome-icon
                    v-else
                    :icon="['fad', 'compress']"
                    :style="{fontSize: '16px'}"
                  />
                </button>
              </div>
            </div>
            <video :muted="false" :id="`shareScreenVideo`" ref="ownScreenShareVideoRef" autoplay playsinline></video>
          </div>
        </div>
        <!-- <div v-if="somebodyShareScreenEnabled" class="screen-share-container">
          <div class="screen-share-item">
            <div class="container-div-hover">
              <div class="name-on-hover">
                <span class="span-hover">{{getParticipantName(shareScreenParticipant.id)}} Screenshare</span>
              </div>
            </div>
            <div class="containerButtonsOverlay">
                <div class="divButtonsOverlay">
                  <button class="buttonOverlay" @click="toggleFullScreen('shareScreenVideo')">
                    <font-awesome-icon
                        v-if="!isFullScreenEnabled"
                        :icon="['fad', 'expand']"
                        :style="{fontSize: '16px'}"
                      />
                    <font-awesome-icon
                      v-else
                      :icon="['fad', 'compress']"
                      :style="{fontSize: '16px'}"
                    />
                  </button>
                </div>
            </div>
            <video :muted="false" :id="`shareScreenVideo`" :ref="`remoteDesktop-${participant.id}`" autoplay playsinline></video>
          </div>
        </div> -->
      </div>
      <div class="top-right resizable-container" :class="{ camerasFullWidth: !shareScreenEnabled}" :style="topRightStyle">
        <div class="webcamsVideoContainer">
          <div class="webcams-flex-container" :class="getClassForGrid">
            <!--                                      -->
            <!-- YOUR OWN VIDEO CONTAINER && CONTROLS -->
            <!--                                      -->
            <div class="flex-item ownVideoContainer" id="localVideoContainer">
              <!-- Name on hover -->
              <div  class="container-div-hover" >
                <div class="name-on-hover">
                  <span class="span-hover">{{getNameForUuid(ownUUID)}}</span>
                </div>
              </div>
              <!-- Own microphone status (always visible) -->
              <div class="containerOwnMicrophone">
                <div class="divButtonsOverlay">
                  <button class="buttonOverlay">
                    <font-awesome-icon
                      v-if="microphoneEnabled"
                      :icon="['fas', 'microphone']"
                      :style="{fontSize: '18px'}"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'microphone-slash']"
                      :style="{fontSize: '18px'}"
                    />
                    <!-- , color: 'red' -->
                  </button>
                </div>
              </div>
              <!-- Own controls (Pin && FullScreen) -->
              <div class="containerButtonsOverlay containerOwnButtonsOverlay">
                <div class="divButtonsOverlay">
                  <!-- TODO: Pin button && functionality -->
                  <!-- <button class="buttonOverlay">
                    <font-awesome-icon
                      v-if="!isOwnPinned"
                      :icon="['fas', 'thumbtack']"
                      :style="{fontSize: '16px'}"
                    />
                    <span v-else class="Icon-root icon-unpin css-0" role="img"></span>
                  </button> -->
                  <button class="buttonOverlay" @click="toggleFullScreen()">
                    <font-awesome-icon
                        v-if="!isFullScreenEnabled"
                        :icon="['fad', 'expand']"
                        :style="{fontSize: '16px'}"
                      />
                    <font-awesome-icon
                      v-else
                      :icon="['fad', 'compress']"
                      :style="{fontSize: '16px'}"
                    />
                  </button>
                </div>
              </div>
              <!-- Overlay with two letters bubble when you video is disabled -->
              <div v-if="!videoEnabled" class="lettersBackground">
                <div class="lettersOverlay">
                  <div class="lettersCircle" :style="{ backgroundColor: getRandomColor(0)}">
                    {{ getInitialsFromParticipant(getNameForUuid(ownUUID)) }}
                  </div>
                </div>
              </div>
              <!-- Own video -->
              <video muted key="localVideo" id="localVideo" ref="localVideo" autoplay playsinline></video>
            </div>
            <!--                                         -->
            <!-- PARTICIPANT VIDEO CONTAINER && CONTROLS -->
            <!--                                         -->
            <div class="flex-item somebodyVideoContainer" v-for="(participant, index) in remoteParticipants" :key="participant.id">
              <!-- Participant name on hover -->
              <div class="container-div-hover">
                <div class="name-on-hover">
                  <span class="span-hover">{{getParticipantName(participant.id)}}</span>
                </div>
              </div>
              <!-- Participant status && controls (mic,pin,fullScreen) -->
              <div class="containerButtonsOverlay">
                <div class="divButtonsOverlay">
                  <button class="buttonOverlay">
                    <font-awesome-icon
                      v-if="!participant.isAudioMuted"
                      :icon="['fas', 'microphone']"
                      :style="{fontSize: '18px'}"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'microphone-slash']"
                      :style="{fontSize: '18px'}"
                    />
                    <!-- , color: 'red' -->
                  </button>
                  <!-- TODO: Pin button && functionality -->
                  <!-- <button class="buttonOverlay">
                    <font-awesome-icon
                      v-if="!isOwnPinned"
                      :icon="['fas', 'thumbtack']"
                      :style="{fontSize: '16px'}"
                    />
                    <span v-else class="Icon-root icon-unpin css-0" role="img"></span>
                  </button> -->
                  <button class="buttonOverlay" @click="toggleFullScreen(participant)">
                    <font-awesome-icon
                      v-if="!isFullScreenEnabled"
                      :icon="['fad', 'expand']"
                      :style="{fontSize: '16px'}"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fad', 'compress']"
                      :style="{fontSize: '16px'}"
                    />
                  </button>
                </div>
              </div>
              <!-- Participant two letters overlay when video is disabled -->
              <div v-if="participant.isVideoMuted" class="lettersBackground">
                <div class="lettersOverlay">
                  <div class="lettersCircle" :style="{ backgroundColor: getRandomColor(index +1) }">
                    {{ getInitialsFromParticipant(getParticipantName(participant.id)) }}
                  </div>
                </div>
              </div>
              <!--                                   -->
              <!-- Participant Video && Audio tracks -->
              <!--                                   -->
              <video :muted="true" :key="`remoteVideo-${participant.id}`" :id="`remoteVideo-${participant.id}`" :ref="`remoteVideo-${participant.id}`" autoplay playsinline></video>
              <audio class="remoteAudio" :muted="participant.isAudioMuted || audioOutputMuted" :key="`remoteAudio-${participant.id}`" :id="`remoteAudio-${participant.id}`" :ref="`remoteAudio-${participant.id}`" autoplay playsinline></audio>
            </div>
          </div>
          <!-- Participants Container end (including myself) -->
        </div>
      </div>
      <div v-if="shareScreenEnabled" class="left-right divider" draggable="true" @dragstart="dividerDragStart" @drag="lrDividerDrag" :style="lrDividerStyles">
        <div class="resize-pill"></div>
      </div>
      <!-- END Example usage of vue-resizable-box END -->
      <div class="topBarCall"></div>
        <!--                          -->
        <!-- General Room Buttons bar -->
        <!--                          -->
        <div class="btnBar">
          <div class="d-flex align-center">
            <v-btn
              class="mr-6"
              large
              icon
              variant="text"
              color="white"
              @click="toggleVideoBtn"
            >
              <font-awesome-icon
                v-if="videoEnabled"
                :icon="['fas', 'video']"
                :style="{fontSize: '18px'}"
              />
               <font-awesome-icon
                v-else
                :icon="['fas', 'video-slash']"
                :style="{fontSize: '18px', color: 'red'}"
              />
            </v-btn>
             <v-btn
              class="mr-6"
              large
              icon
              variant="text"
              color="white"
              @click="toggleMicBtn"
            >
            <font-awesome-icon
                v-if="microphoneEnabled"
                :icon="['fas', 'microphone']"
                :style="{fontSize: '18px'}"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'microphone-slash']"
                :style="{fontSize: '18px', color: 'red'}"
              />
            </v-btn>
            <v-btn
              class="mr-6"
              large
              icon
              variant="text"
              color="white"
              @click="toggleShareScreenBtn"
            >
              <img
                v-if="shareScreenEnabled"
                class="customIcons"
                src="/img/share-screen-blue.svg"
              />
              <img
                v-else
                class="customIcons"
                src="/img/share-screen.svg"
              />
            </v-btn>
            <v-btn
              class="mr-6"
              large
              icon
              variant="text"
              color="white"
              @click="toggleMuteAudio"
            >
              <font-awesome-icon
                v-if="!audioOutputMuted"
                :icon="['fas', 'volume']"
                :style="{fontSize: '18px'}"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'volume-slash']"
                :style="{fontSize: '18px', color: 'red'}"
              />
            </v-btn>
          </div>
          <div></div>
          <div class="d-flex align-center">
            <v-btn
              large
              icon
              variant="text"
              color="white"
              @click="rejectCall()"
            >
              <font-awesome-icon
                :icon="['fas', 'phone']"
                :style="{fontSize: '18px', color: 'red', transform: 'rotate(225deg)' }"
              />
            </v-btn>
          </div>
        </div>
    </div>
    <FinishCallModal
      v-if="showFinishCallModal"
      :showFinishCallModal="showFinishCallModal"
      :closeModal="showCloseFinishCallModal"
      :rejectCall="rejectCall"
    />
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { syncedUserState, EventBus } from "../../../store";
import Deferred from "../../../jitsi/modules/util/Deferred";
import isEqual from 'lodash.isequal';
import {
  bridgeCallGetAllChatMessages,
  bridgeCallSendChatMessage
} from "../../../lib/wsMsg";
import { aDelay, withResolvers } from "../../../lib/asyncUtil";
import { setCallChatEvent, callChatStore } from "../../../effector/callChat";
import SidebarCall from "./sidebarCall/sidebarCall.vue";
import { isConferenceCall, prepareDataForVirtualBackground, amInAStaticRoom } from "../../../utils/calls";
import { isGuestOrVisitor } from "../../../utils/routerAcl.js";
import ProgressLinearBridge from "../../progressLinearBridge/progressLinearBridge.vue";
import { wsCall } from "../../../lib/wsConnect";
import { hasPrivilege, isVisitor, isWaitingRoomUser } from "../../../utils/privileges";
import FinishCallModal from  "../../modal/finishCallModal.vue";
import { allUsersState, receivedSpecificUserStateEvent } from '../../../effector/users';
import { isMobile } from "../../../lib/mobileUtil";
import { joinSambaRoom } from "../../../utils/staticRoom";
import Meeting from "../../../utils/meeting"
import ResizableBox from 'vue-resizable-box';
import { parseJSON } from "../../../utils/basicFunctions";

const {
  generateID,
  destroyMeeting,
  joinRoom,
  toggleMic,
  toggleVideo,
  toggleShareScreen,
  onLocalVideo,
  onRemoteVideo,
  onSocketMessage,
  onChatReady,
  onChatNotReady,
  onChatMessage,
  onParticipantHangup,
  onLocalScreen,
  onRemoteScreen,
  onRemoveRemoteScreen,
} = Meeting('wss://p2p.dev03.voffice.pro');

// import { setQualityVotingModalEvent } from '../../../effector/modals';
export default {
  components: { SidebarCall, ProgressLinearBridge, FinishCallModal, 'vue-resizable-box': ResizableBox },
  data() {
    const effector = {
      chatMessages: callChatStore,
      allUsersState: allUsersState
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
       containersOption: {
        left: {
          size: 1,
          buttons: [{ direction: 'right' }]
        },
        center: {
          size: 2,
          buttons: [{
            direction: 'left'
          }, {
            direction: 'right'
          }]
        },
        right: {
          size: 1,
          buttons: [{ direction: 'left' }]
        }
      },
      state: store.state,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      maximizeScreen: null,
      currentCallTime: undefined,
      remoteParticipants: [],
      spaceShortcutCallAcceptTimeout: null,
      showFinishCallModal: false,
      frameMuted: undefined,
      showLeaveSession: false,
      audioOutputMuted: false,
      videoEnabled: true,
      isFullScreenEnabled: false,
      userInFullScreen: null,
      isOwnPinned: false,
      microphoneEnabled: true,
      shareScreenEnabled: false,
      // somebodyShareScreenEnabled: false,
      shareScreenParticipant: null,
      setCurrentContentVisile: store.setCurrentContentVisile,
      participantsMap: {},
      lrDividerPos: '',
      rtbDividerPos: '',
      ltbDividerPos: '',
      colorArray: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
      '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
      '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
      colorIndex: 0,
      localStream: undefined,
      webcamsContainerWidth: (window.innerWidth) - 53,
      // Effector
      ...effector,
    };
  },
  watch: {
    currentCallTime: {
      handler: function (currentTime) {
        const legacy = false;
        const message = legacy
          ? this.$sanitize(currentTime)
          : JSON.stringify({ type: 'timeline', time: this.$sanitize(currentTime) })
        bridgeCallSendChatMessage(message);
      },
    },
    chatMessages: {
      handler: function (message) {
        const callUUID = this.getCallUUID;
        const lastMessage = [...message].reverse().find((elem) => {
          const { type } = parseJSON(elem.text) || {};
          const legacy = !type && typeof elem.text === 'string' && parseInt(elem.text, 10);
          return legacy || type === 'timeline';
        });
        if (lastMessage) {
          const { time } = parseJSON(lastMessage.text) || {};
          const legacy = !time && typeof lastMessage.text === 'string' && parseInt(lastMessage.text, 10);
          const callDuration = parseInt(time, 10) || legacy;
          store.changeCallDurationMsBridgeStream(callUUID, callDuration);
        }
      }
    },
    amICalling:{
      handler: function (users) {
        if (users.length) {
          if (this._destroyed) return;
          const callUUID = this.getCallUUID;
          if (!this._receivedUserEventUnwatch) {
            this._receivedUserEventUnwatch = receivedSpecificUserStateEvent.watch((payload) => {
              if (this._destroyed) return (this._receivedUserEventUnwatch && this._receivedUserEventUnwatch());
              const [uuid, state] = payload;
              if (!state || state.connected || !users.includes(uuid)) return;
              this.hangUpCalling(uuid);
              this.onRejectBridgeCallEvent({callUUID});
              const dataInfoModal = {
                show: true,
                header: this.$t("generics.info"),
                body: this.$t("components.callsContent.userDisconnected", [this.getNameForUuid(uuid)]),
              };
              this.setInfoModal(dataInfoModal);
            });
          }
        } else if (this._receivedUserEventUnwatch) {
          this._receivedUserEventUnwatch();
          this._receivedUserEventUnwatch = null;
        }
      }
    },
    allUsersState: {
      deep: true,
      immediate: true,
      handler: function (users) {
        if (this._destroyed) return;
        for (const uuid in users) {
          if (Object.prototype.hasOwnProperty.call(users, uuid)) {
            const { callUUID, p2pId } = users[uuid]?.user?.bridgeCallInfo || {};
            if (callUUID === this.getCallUUID && p2pId && this.participantsMap[p2pId] !== uuid) {
              this.participantsMap[p2pId] = uuid;
            }
          }
        }
        // This ensures that stale entries in participantsMap are cleaned up
        if (this._participantsClean) clearTimeout(this._participantsClean);
        this._participantsClean = setTimeout(() => {
          Object.entries(this.participantsMap).forEach(([p2pId, uuid]) => {
            const { callUUID, p2pId: theirId } = this.allUsersState[uuid]?.user?.bridgeCallInfo || {};
            if (!callUUID || callUUID !== this.getCallUUID || !theirId || theirId !== p2pId) {
              delete this.participantsMap[p2pId];
            }
          });
        }, 300);
      },
    },
  },
  created() {
    const remoteStreamsKey = this.getCallUUID || Object.keys(store.state.remoteBridgeStreams)[0];
    if (!remoteStreamsKey) return console.error(new Error("Unexpected state encountered: remoteStreamsKey missing."));
    let remoteStream = store.state.remoteBridgeStreams[remoteStreamsKey];
    if (!remoteStream) return console.error(new Error("Unexpected state encountered: remoteStream missing."));
    let { roomId, roomGuid } = remoteStream;
    if (remoteStream.initiator && remoteStream.initiator !== this.ownUUID) {
      this._notMyCall = true; // hang up when everyone leaves
    }
    this.$set(remoteStream, 'p2pId', generateID());
    /** Prevents race conditions in async calls, by asserting premises still hold true */
    const isSaneOrAbortThrow = (fail = false) => {
      if (this._destroyed) return true; // Abort gracefully if destroyed
      if (Object.keys(store.state.remoteBridgeStreams)[0] !== remoteStreamsKey) {
        if (fail) {
          if (typeof fail === 'function') fail(new Error("Inconsistent remote bridge stream."));
          return true; // Abort gracefully if fail is truthy
        }
        throw new Error("Inconsistent remote bridge stream key.");
      }
      remoteStream = store.state.remoteBridgeStreams[remoteStreamsKey];
      return false;
    };
    this._createRoomPromise = syncedUserState()
      .catch(() => {})
      .then(async () => {
        if (isSaneOrAbortThrow(true)) return; // Abort gracefully to avoid async throw
        const messages = await bridgeCallGetAllChatMessages();
        setCallChatEvent(messages);
        if (!roomId || !roomGuid) roomId = roomGuid = remoteStreamsKey;
        if (this.state.user.activity !== "inCall" && this.state.user.activity !== "inRoom" && this.state.user.activity !== "No status" && this.state.user.activity !== "Coffee break") {
          this.state.user.originalActivity = this.state.user.activity;
          if (this.isSambaStaticRoom) {
            this.state.user.activity = "inRoom";
          } else {
            this.state.user.activity = "inCall";
          }
        }
        store.setRemoteBridgeStream(remoteStreamsKey, { ...remoteStream, roomId, roomGuid });
        return { roomId, roomGuid };
      });
  },
  mounted() {
    this.setCurrentContentVisile("", false, this.$router);
    if (!this._createRoomPromise) return console.error("Unexpected state encountered: _createRoomPromise missing.");
    window.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    window.addEventListener("beforeunload", this.onBeforeUnload);
    EventBus.$on("reject_bridge_call", this.onRejectBridgeCallEvent);
    this._mountedPromise = this._createRoomPromise.then((result) => {
      if (this._destroyed) return;
      const { roomGuid } = result;
      // joinRoom(roomGuid, this.createLocalTracks); // Old
      this.createLocalTracks().then(tracks => joinRoom(roomGuid, tracks)); // New
      onLocalVideo((mediaSource) => {
        const storageAudioDisabled = sessionStorage.getItem('audioDisabled');
        if (this.microphoneEnabled && storageAudioDisabled && storageAudioDisabled === 'true') this.toggleMicBtn();
        const storageVideoDisabled = sessionStorage.getItem('videoDisabled');
        if (this.videoEnabled && storageVideoDisabled && storageVideoDisabled === 'true') this.toggleVideoBtn();
        this.localStream = mediaSource;
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
        const video = this.$refs.localVideo;
        // Older browsers may not have srcObject
        if ("srcObject" in video) {
          try {
            video.srcObject = mediaSource;
          } catch (err) {
            if (err.name !== "TypeError") {
              throw err;
            }
            // Even if they do, they may only support MediaStream
            video.src = URL.createObjectURL(mediaSource);
          }
        } else {
          video.src = URL.createObjectURL(mediaSource);
        }
      });
      onRemoteVideo((stream, participantID) => {
        this.addRemoteVideo(stream, participantID);
      });
      onSocketMessage(({ type, from, enabled }) => {
        switch(type) {
          case "micToggled": {
            // Update the UI based on data.from and data.enabled
            // data.from is the participant ID
            // data.enabled is the new state of the microphone
            const participantObject = this.remoteParticipants.find(participant => participant.id === from);
            if (participantObject) {
              participantObject.isAudioMuted = !enabled;
            }
          } break;
          case "videoToggled": {
            // Update the UI based on data.from and data.enabled
            // data.from is the participant ID
            // data.enabled is the new state of the camera
            const participantObject = this.remoteParticipants.find(participant => participant.id === from);
            if (participantObject) {
              participantObject.isVideoMuted = !enabled;
            }
          } break;
          default:
            console.log("Unknown message", { type, from, enabled });
        }
      });
      onParticipantHangup((participantID) => {
        const userUUID = this.participantsMap[participantID];
        if(userUUID){
          if (userUUID === this.ownUUID){
            console.log("You have left the room");
            this.rejoinMeetingRoomAfterLeave();
            if (!this._destroyedPromise) this._destroyedPromise = new Deferred();
            this.removeRemoteVideo(participantID);
          } else {
            // Remote
            console.log(userUUID, "has left the room");
            this.removeRemoteVideo(participantID);
            if (!this._destroyed) { // if (!this._destroyed && this._sambaFrame) {
              const users = this.remoteParticipants
              if (users && users.length === 1 && !this.isSambaStaticRoom) {
                console.log("hang up as all users left", this._notMyCall);
                return (this._notMyCall || !this.isConferenceCall)
                  ? (this.rejoinMeetingRoomAfterLeave(), this.rejectCall())
                  : this.showCloseFinishCallModal(this.isConferenceCall ? true : 'components.callsContent.confirmFinishCall');
              }
            }
          }
        }
      });
      onChatReady(()=> {
        console.log("Chat is ready");
      });
      onChatNotReady(()=> {
        console.log("Chat is not ready");
      });
      onChatMessage((msg) => {
        console.log("Chat message", msg);
      })
      onLocalScreen((stream) => {
        const refNameVideo = `ownScreenShareVideoRef`;
        const video = this.$refs[refNameVideo] ? this.$refs[refNameVideo] : undefined;
        if (video) {
          // Attach media source
          // Older browsers may not have srcObject
          if ("srcObject" in video) {
            try {
              video.srcObject = stream;
            } catch (err) {
              if (err.name !== "TypeError") {
                throw err;
              }
              // Even if they do, they may only support MediaStream
              video.src = URL.createObjectURL(stream);
            }
          } else {
            video.src = URL.createObjectURL(stream);
          }
        } else {
          console.warn('Did not find', refNameVideo, 'ref...');
        }
        this.shareScreenEnabled = this.ownUUID;
      })
      onRemoteScreen((stream, userId) => {
        const userUUID = this.participantsMap[userId];
        // We clean my share screen because someone starts doing share screen
        if(this.shareScreenEnabled && this.shareScreenEnabled === this.ownUUID){
          this.toggleShareScreenBtn();
        }
        const refNameVideo = `ownScreenShareVideoRef`;
        const video = this.$refs[refNameVideo] ? this.$refs[refNameVideo] : undefined;
        if (video) {
          // Attach media source
          // Older browsers may not have srcObject
          if ("srcObject" in video) {
            try {
              video.srcObject = stream;
            } catch (err) {
              if (err.name !== "TypeError") {
                throw err;
              }
              // Even if they do, they may only support MediaStream
              video.src = URL.createObjectURL(stream);
            }
          } else {
            video.src = URL.createObjectURL(stream);
          }
        } else {
          console.warn('Did not find', refNameVideo, 'ref...');
        }
        this.shareScreenEnabled = userUUID || userId; // i need the participant to set
      });
      onRemoveRemoteScreen((offerId) => {
        const userUUID = this.participantsMap[offerId];
        if (this.shareScreenEnabled !== this.ownUUID && (this.shareScreenEnabled === userUUID || this.shareScreenEnabled === offerId)) {
          this.shareScreenEnabled = false;
        }
      });
      if (!this.isMobile) {
        this.setMaximizeScreen(true);
      }
    }).catch((err) => {
      console.error("Unexpected error:", err);
      if (this._destroyed) return;
      this.rejectCall();
    });

    // Check sessionStorage value to set speaker disabled
    const isAudioOutputMuted = sessionStorage.getItem('speakerDisabled') === 'true';
    if (isAudioOutputMuted !== null) {
      this.audioOutputMuted = isAudioOutputMuted;
    }

    // Add the event listener for the 'resize' event on the window object.
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // Remove the event listener when the component is about to be destroyed
    window.removeEventListener('resize', this.handleResize);
    this._destroyed = true;
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    EventBus.$off("reject_bridge_call", this.onRejectBridgeCallEvent);
    /*
    if (this._sambaFrame) {
      const sambaFrame = this._sambaFrame;
      // sambaFrame.endSession(); // Ends the session for everyone. All users are immediately removed from the room and the call ends
      sambaFrame.leaveSession(); // Leaves the session, so the user is no longer participating in the call
      sambaFrame.removeFrameEventListener("keyup", "window", this.onKeyUp);
    } */
    if (!this.isSambaStaticRoom && (this.isVisitor(this.ownUUID) || this.isWaitingRoomUser(this.ownUUID))) {
      store.setEndCallDateVisitor(); // Set the call end time for visitors in their store
    }
    if (this.shareScreenEnabled && this.shareScreenEnabled === this.ownUUID) {
      this.shareScreenEnabled = false;
      toggleShareScreen(false);
    }
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    destroyMeeting();
    const remoteStreamsKey = this.getCallUUID || Object.keys(store.state.remoteBridgeStreams)[0];
    const remoteStream = remoteStreamsKey && store.state.remoteBridgeStreams[remoteStreamsKey];
    if (remoteStream) { this.$set(remoteStream, 'p2pId', ''); }
    this.redirectToStartView();
  },
  unmounted() {
    window.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });

    if (this._receivedUserEventUnwatch) {
      this._receivedUserEventUnwatch();
      this._receivedUserEventUnwatch = null;
    }
    if (this._destroyedPromise && typeof this._destroyedPromise.resolve === 'function') {
      this._destroyedPromise.resolve();
    }
    this._destroyedPromise = Promise.resolve();
  },
  methods: {
    handleResize: function() {
      // Calculate webcamsContainerWidth depending on window size && screenShare enabled or not
      const dividerPosition = this.lrDividerPos !== '' ? this.lrDividerPos : (window.innerWidth * 0.70 - 4 / 2) - 55;
      this.webcamsContainerWidth = this.shareScreenEnabled !== false  ? (window.innerWidth - dividerPosition + 2) - 55 : (window.innerWidth) - 53;
    },
    lrDividerDrag: function(e) {
      if (e.clientX < 300 || e.clientX > window.innerWidth - 300){
        e.preventDefault();
        e.stopPropagation();
      } else {
        if (e.clientX) {
          this.lrDividerPos = e.clientX;
        }
      }
    },
    dividerDragStart: function(e) {
      e.dataTransfer.setDragImage(new Image(), 0, 0);
    },
    // VUeResizableBox container methods end
    getRandomColor(index){
      if (index < this.colorArray.length - 1) {
        return this.colorArray[this.colorIndex];
      } else {
        return this.colorArray[0];
      }
    },
    getInitialsFromParticipant(name){
      const initials = name.replace(/\(.+?\)|\s+".*?"$/g, '').trim().replace(/([ÑÖÄÜñöäü\wÀ-ú])[ÑÖÄÜñöäü\wÀ-ú]*[^ÑÖÄÜñöäü\wÀ-ú]*/g, '$1').replace(/[^ÑÖÄÜñöäü\wÀ-ú]/g, '').replace(/([ÑÖÄÜñöäü\wÀ-ú])[ÑÖÄÜñöäü\wÀ-ú]*([ÑÖÄÜñöäü\wÀ-ú])/g, '$1$2').padEnd(2, name.trim()[1]).toUpperCase();
      return initials;
    },
    getParticipantName(partId) {
      const userUUID = this.participantsMap[partId];
      if (userUUID) {
        return this.getNameForUuid(userUUID);
      } else {
        return '';
      }
    },
    toggleFullScreen(participant=null){
      const participantId = participant?.id;
      this.userInFullScreen = participant && participant.id ? participant.id : participant;
      this.isFullScreenEnabled = !this.isFullScreenEnabled
      if (this.isFullScreenEnabled) {
        if (this.shareScreenEnabled) {
          // Find the element with the specified class name
          let elementDivider = document.querySelector('.left-right.divider');
          // Check if the element exists to avoid null reference errors
          if (elementDivider) {
              // Add a new class to the element
              elementDivider.classList.add('displayNoneFullScreen');
          }
          if(participant === 'shareScreenVideo'){
            // Find the element with the specified class name
            let elementLeft = document.querySelector('.top-left.resizable-container');
            // Check if the element exists to avoid null reference errors
            if (elementLeft) {
                // Add a new class to the element
                elementLeft.classList.add('customFullScreenShare');
            }
            // Find the element with the specified class name
            let elementRigth = document.querySelector('.top-right.resizable-container');
            // Check if the element exists to avoid null reference errors
            if (elementRigth) {
                // Add a new class to the element
                // elementRigth.classList.remove('resizable-container');
                elementRigth.classList.add('displayNoneFullScreen');
            }
          } else {
            // Find the element with the specified class name
            let elementLeft = document.querySelector('.top-left.resizable-container');
            // Check if the element exists to avoid null reference errors
            if (elementLeft) {
                // Add a new class to the element
                elementLeft.classList.add('displayNoneFullScreen');
            }
            // Find the element with the specified class name
            let elementRigth = document.querySelector('.top-right.resizable-container');
            // Check if the element exists to avoid null reference errors
            if (elementRigth) {
                // Add a new class to the element
                // elementRigth.classList.remove('resizable-container');
                elementRigth.classList.add('customFullScreenShare');
            }
          }
        }
        // Logic to set fullscrenn
        if(!participantId){
          // Local users
          // add fullScreen class to localVideoContainer
          const localContainer = document.getElementById('localVideoContainer');
          if(localContainer)  localContainer.classList.add('customFullScreen');
          // hiddem other containers
          // Select all elements with the class 'somebodyVideoContainer'
          const videoContainers = document.querySelectorAll('.somebodyVideoContainer');
          // Loop through the NodeList and add a class to each element
          videoContainers.forEach(container => {
            container.classList.add('displayNoneFullScreen');
          });
        } else {
          // Remote users
          // Find the target element by ID
          const targetElement = document.getElementById(`remoteVideo-${participantId}`);
          if (targetElement) {
            // Traverse up the DOM tree to find the parent with 'somebodyVideoContainer' class
            let parentElement = targetElement.parentElement;
            while (parentElement && !parentElement.classList.contains('somebodyVideoContainer')) {
                parentElement = parentElement.parentElement;
            }
            // Add the new class to the found parent element
            if (parentElement) {
                parentElement.classList.add('customFullScreen');
            }
            // Find all elements with class 'somebodyVideoContainer'
            const allContainers = document.querySelectorAll('.somebodyVideoContainer');
            // Loop through the list and add the different class to elements without the target ID
            for (let container of allContainers) {
                if (container !== parentElement) { // Skip the element that already has the new class added
                    container.classList.add('displayNoneFullScreen');
                }
            }
          }
          // Local users
          // add fullScreen class to localVideoContainer
          const localContainer = document.getElementById('localVideoContainer');
          if(localContainer)  localContainer.classList.add('displayNoneFullScreen');
        }
      } else {
        // Logic to remove fullscrenn
        // Find all elements with 'customFullScreen' class
        const elementsFullScreen = document.querySelectorAll('.customFullScreen');
        // Loop through the NodeList to remove 'customFullScreen' class from each element
        elementsFullScreen.forEach((element) => {
            element.classList.remove('customFullScreen');
        });

        // Find all elements with 'customFullScreen' class
        const elementsDisplayNone = document.querySelectorAll('.displayNoneFullScreen');
        // Loop through the NodeList to remove 'customFullScreen' class from each element
        elementsDisplayNone.forEach((element) => {
            element.classList.remove('displayNoneFullScreen');
        });
        if (this.shareScreenEnabled) {
          // Find all elements with 'customFullScreen' class
          const elementsDisplayNone = document.querySelectorAll('.customFullScreenShare');
          // Loop through the NodeList to remove 'customFullScreen' class from each element
          elementsDisplayNone.forEach((element) => {
              element.classList.remove('customFullScreenShare');
          });
        }
      }
    },
    toggleMuteAudio() {
      this.audioOutputMuted = !this.audioOutputMuted;
      sessionStorage.setItem('speakerDisabled', this.audioOutputMuted);
      const remoteAudios = document.getElementsByClassName("remoteAudio");
      if (remoteAudios.length > 0) {
        remoteAudios.forEach((remote) => {
          remote.muted = this.audioOutputMuted;
        });
      }
    },
    toggleVideoBtn() {
      this.videoEnabled = toggleVideo();
      sessionStorage.setItem('videoDisabled', JSON.stringify(!this.videoEnabled));
    },
    toggleMicBtn() {
      this.microphoneEnabled = toggleMic();
      sessionStorage.setItem('audioDisabled', JSON.stringify(!this.microphoneEnabled));
    },
    async toggleShareScreenBtn() {
      if (this.shareScreenEnabled && this.shareScreenEnabled === this.ownUUID) {
        if(this.isFullScreenEnabled && this.userInFullScreen === 'shareScreenVideo'){
          this.toggleFullScreen();
        }
        // this.shareScreenEnabled?.getTracks().forEach((track) => track.stop());
        this.shareScreenEnabled = false;
        toggleShareScreen(false);
      } else {
        const mediaSource = /* this.shareScreenEnabled = */ await this.createLocalDesktop();
        const videoTrack = mediaSource?.getTracks('video')[0];
        if (videoTrack) videoTrack.onended = () => {
          if (this.shareScreenEnabled) this.toggleShareScreenBtn();
        };
        toggleShareScreen(mediaSource);
      }
      // Calculate webcamsContainerWidth depending on window size && screenShare enabled or not
      this.webcamsContainerWidth = this.shareScreenEnabled ? (window.innerWidth * 0.30 - 4 / 2) - 55 : (window.innerWidth) - 53;
    },
    addRemoteVideo(mediaSource, participantID) {
      this.syncRemoteParticipants(participantID, 'add').then(result => {
        console.log('addRemoteVideo syncRemoteParticipants then', result, participantID, mediaSource); // If participant was successfully added
        const found = this.remoteParticipants.find(participant => participant.id === participantID);
        if (found) {
          const toAdd = {
            mediaSource,
            isVideoMuted: undefined,
            isAudioMuted: undefined,
          }
          Object.assign(found, toAdd);
          console.log('addRemoteVideo syncRemoteParticipants remoteParticipants', result, participantID, found);
          const refNameAudio = `remoteAudio-${participantID}`;
          const audio = this.$refs[refNameAudio][0];
          const refNameVideo = `remoteVideo-${participantID}`;
          const video = this.$refs[refNameVideo][0];
          // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
          if (video) {
            video.srcObject = mediaSource;
            video.play();
          }
          if (audio) {
            audio.srcObject = mediaSource;
            audio.play();
          }
        }
      })
      .catch(error => {
        console.error('addRemoteVideo syncRemoteParticipants Err:', error); // If there was an error (participant already exists or unsupported action)
      });
    },
    removeRemoteVideo(participantID) {
      this.syncRemoteParticipants(participantID, 'remove')
      .then(_result => {
        console.log('user left the room', participantID); // If participant was successfully removed
      })
      .catch(error => {
        console.error('removeRemoteVideo syncRemoteParticipants Err:', error); // If there was an error (participant already exists or unsupported action)
      });
    },
    syncRemoteParticipants(participantID, action) {
      return new Promise((resolve, reject) => {
        if (action === 'add') {
          const find = this.remoteParticipants.find(participant => participant.id === participantID);
          if (!find) {
            const toAdd = {
              id: participantID,
              isVideoMuted: true,
              isAudioMuted: true
            }
            this.remoteParticipants.push(toAdd);
            if (this.audioOutputMuted) {
              let attempts = 0;
              const maxAttempts = 20; // Check for the element up to 20 times (given an interval of 500ms, this equals 10 seconds)
              const attemptMute = () => { // Function to attempt to find and mute the audio element
                const audioElement = document.getElementById(`remoteAudio-${participantID}`);
                if (audioElement) {
                  if (this.audioOutputMuted) audioElement.muted = true;
                  clearInterval(attemptInterval); // Clear the interval immediately once we've muted the element
                } else {
                  attempts++;
                  if (attempts >= maxAttempts) {
                    clearInterval(attemptInterval); // Stop trying after max attempts
                  }
                }
              };
              // Start attempting to find and mute the audio element every 500ms
              const attemptInterval = setInterval(attemptMute, 500);
            }
            resolve(`Participant with ID ${participantID} added.`);
          } else {
            // reject(new Error(`Participant with ID ${participantID} already exists.`));
            resolve(`Participant with ID ${participantID} already exists.`);
          }
        } else if(action === 'remove'){
          const findIndex = this.remoteParticipants.findIndex(participant => participant.id === participantID);
          if(findIndex !== -1){
            this.remoteParticipants.splice(findIndex, 1);
            resolve(`Participant with ID ${participantID} removed.`);
          } else {
            reject(new Error(`Participant with ID ${participantID} already removed.`));
          }
        } else {
          reject(new Error('Unsupported action'));
        }
      });
    },
    createLocalDesktop() {
      const { promise, resolve, reject } = withResolvers();
      const constraints = { video: true, audio: false };
      window.navigator.mediaDevices
        .getDisplayMedia(constraints)
        .then(resolve)
        .catch(reject);
      return promise;
    },
    async createLocalTracks() {
      const constraints = {
        video: {
          width: { ideal: 640 },
          height: { ideal: 480 },
          frameRate: { ideal: 20 },
        },
        audio: {
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true,
          googEchoCancellation2: true,
          googAutoGainControl2: true,
          googNoiseSuppression2: true,
        },
        options: {
          mirror: true,
        },
      };
      const audioDeviceId = this.state.persisted.mediaDeviceSetup.audioDeviceId;
      console.log("switch selected mic:", audioDeviceId);
      const videoDeviceId = this.state.persisted.mediaDeviceSetup.videoDeviceId;
      console.log("switch selected cam:", videoDeviceId);
      const devices = await navigator.mediaDevices
        .enumerateDevices("audio", "video")
        .then((devices) => {
          let audioInput;
          if (audioDeviceId /* this.audioDeviceId */) {
            audioInput = devices.find(
              (d) => d.kind === "audioinput" && d.deviceId === audioDeviceId
            );
          }
          // should try for "communications", then "default" then 1st element existing
          if (!audioInput) {
            audioInput = devices.find(
              (d) => d.kind === "audioinput" && d.deviceId === "communications"
            );
          }
          if (!audioInput) {
            audioInput = devices.find(
              (d) => d.kind === "audioinput" && d.deviceId === "default"
            );
          }
          if (!audioInput) {
            audioInput = devices.find(
              (d) => d.kind === "audioinput"
            );
          }
          let videoInput;
          if (videoDeviceId /* this.videoDeviceId */) {
            videoInput = devices.find(
              (d) => d.kind === "videoinput" && d.deviceId === videoDeviceId
            );
          }
          if (!videoInput) {
            videoInput = devices.find(
              (d) => d.kind === "videoinput"
            );
          }
          return {
            audioInputId: (audioInput || {}).deviceId,
            videoInputId: (videoInput || {}).deviceId,
          };
        });
      console.log(`selected mic: ${devices.audioInputId}`);
      console.log(`selected cam: ${devices.videoInputId}`);
      Object.assign(constraints.audio, { deviceId: devices.audioInputId /* audioDeviceId */ ? { exact: devices.audioInputId /* audioDeviceId */ } : undefined });
      Object.assign(constraints.video, { deviceId: devices.videoInputId /* videoDeviceId */ ? { exact: devices.videoInputId /* videoDeviceId */ } : undefined });
      return await window.navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((err) => {
          console.warn('Video stream not accessible, falling back to audio only.', err);
          constraints.video = false;
          return navigator.mediaDevices.getUserMedia(constraints)
            .then((mediaSteam) => {
              // https://blog.mozilla.org/webrtc/warm-up-with-replacetrack/
              /** @returns {MediaStreamTrack} dummy video */
              const black = ({ width = 640, height = 480 } = {}) => {
                const canvas = Object.assign(document.createElement("canvas"), { width, height });
                canvas.getContext('2d').fillRect(0, 0, width, height);
                const stream = canvas.captureStream();
                const videoTrack = stream.getVideoTracks()[0];
                return Object.assign(videoTrack, { enabled: false });
              };
              return new MediaStream([black(), ...mediaSteam.getTracks()])
            });
        });
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (
          event.target.nodeName !== "TEXTAREA" &&
          event.target.nodeName !== "INPUT"
        ) {
          // return this.rejectCall();
          this.showCloseFinishCallModal(this.isConferenceCall ? true : 'components.callsContent.confirmFinishCall');
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
          this.spaceShortcutCallAcceptTimeout = null;
        }, 300);
      }
    },
    showCloseFinishCallModal(val){
      this.showFinishCallModal = val;
    },
    setMaximizeScreen(data) {
      this.maximizeScreen = data;
      this.localFullScreen = false;
      this.userMaximized = null;
    },
    roomStateUpdated(event) {
      this.frameMuted = event.data.state.frameMuted;
      // sync devices (audioDeviceId, videoDeviceId, audioOutputId)
      const { audioinput, audiooutput, videoinput } = event.data.state.media.activeDevices;
      const { appLanguage, virtualBackground } = event.data.state;
      if (audioinput && store.state.persisted.mediaDeviceSetup.audioDeviceId !== audioinput) {
        store.state.persisted.mediaDeviceSetup.audioDeviceId = audioinput;
      }
      if (videoinput && store.state.persisted.mediaDeviceSetup.videoDeviceId !== videoinput) {
        store.state.persisted.mediaDeviceSetup.videoDeviceId = videoinput;
      }
      if (audiooutput && store.state.persisted.mediaDeviceSetup.audioOutputId !== audiooutput) {
        store.state.persisted.mediaDeviceSetup.audioOutputId = audiooutput;
      }
      if (virtualBackground && virtualBackground.enabled) {
        const datavirtualBackground = this.getDataForVirtualBackground(virtualBackground);
        if (datavirtualBackground && !isEqual(store.state.persisted.mediaDeviceSetup.videoBackground, datavirtualBackground)) {
          store.state.persisted.mediaDeviceSetup.videoBackground = datavirtualBackground;
        }
      }
      const languages = ["de", "en", "es"];
      const sambaLanguage = String.prototype.slice.call(appLanguage, 0, 2).toLowerCase();
      if (languages.includes(sambaLanguage) && sambaLanguage !== this.$locale.current()) {
        this.$locale.change(sambaLanguage);
        this.$set(this.state.user, "language", this.$locale.current());
      }
      // These logs should not be sent to telemetry as they are big
      console.debug('roomStateUpdated', JSON.stringify([event, /*this._sambaFrame.localUser, this._sambaFrame.listUsers()*/], null, 2));
    },
    onBeforeUnload(e) {
      e = e || window.event;
      if (e) {
        e.preventDefault();
        e.returnValue = '';
      }
      return '';
    },
    rejoinMeetingRoomAfterLeave() {
      if (this._triggeredRejoinMeetingRoom) return;
      this._triggeredRejoinMeetingRoom = true;
      if (!this.amInAStaticRoom && this.state.wasInARoom) {
        const minDelay = aDelay(100);
        syncedUserState()
          .catch(() => {})
          .then(async () => {
            if (this._destroyedPromise) await this._destroyedPromise;
            await minDelay;
            const remoteStreams = Object.keys(store.state.remoteBridgeStreams);
            if (!remoteStreams.length && !this.amInAStaticRoom && this.state.wasInARoom) {
              joinSambaRoom(this.state.wasInARoom, this.$route);
            }
          });
      }
    },
    onUserLeft(event) {
      const { data } = event;
      if (data.type === "local") {
        console.log("You have left the room");
        this.rejoinMeetingRoomAfterLeave();
        const remoteStreamsKey = Object.keys(
          store.state.remoteBridgeStreams
        )[0];
        if (!this._destroyedPromise) this._destroyedPromise = new Deferred();
        store.removeRemoteBridgeStreams(remoteStreamsKey);
        // setQualityVotingModalEvent(true)
      } else {
        console.log(data.user.name, "has left the room");
        /*
        if (!this._destroyed && this._sambaFrame) {
          const users = this._sambaFrame.listUsers().filter((user, idx, arr) => {
            return idx === arr.findLastIndex(u => u.externalId === user.externalId);
          });
          if (users && users.length === 1 && !this.isSambaStaticRoom) {
            console.log("hang up as all users left", this._notMyCall);
            return (this._notMyCall || !this.isConferenceCall)
              ? (this.rejoinMeetingRoomAfterLeave(), this.rejectCall())
              : this.showCloseFinishCallModal(this.isConferenceCall ? true : 'components.callsContent.confirmFinishCall');
          }
        }
        */
      }
    },
    updateTimeForUser(newTime) {
      if (newTime) {
        this.currentCallTime = newTime;
      }
    },
    setCallDuration(callUUID, callDuration, users = null) {
      if (users) {
        if (users.indexOf(this.ownUUID) !== -1) {
          return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
        }
      } else {
        return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
      }
    },
    hangUpBridgeCall() {
      console.log("timeline end: hang up bridge call");
      /*
      if (this._sambaFrame) {
        this._sambaFrame.endSession(); // Ends the session for everyone. All users are immediately removed from the room and the call ends
      }
      */
      const remoteStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      )[0];
      store.removeRemoteBridgeStreams(remoteStreamsKey);
    },
    redirectToStartView() {
      const startView =
        "/" +
        (isGuestOrVisitor()
          ? "home"
          : store.state.user.userSettings.startView || "my-favorites");
      this.setCurrentContentVisile(startView, true, this.$router);
    },
    rejectCall() {
      console.log("timeline end: reject call");
      // if (this._sambaFrame) {
      //   this._sambaFrame.leaveSession(); // Leaves the session, so the user is no longer participating in the call
      // }
      const remoteStreamsKey = Object.keys(
        store.state.remoteBridgeStreams
      )[0];
      store.removeRemoteBridgeStreams(remoteStreamsKey);
      destroyMeeting();
    },
    hangUpCalling(id) {
      // this.$refs[`callingBar_${id}`][0].classList.add("d-none");
      const callUUID = this.getCallUUID;
      const callInfo = this.state.remoteBridgeStreams[callUUID];
      wsCall("sendToUUID", id, {
        type: "bridge-signal",
        action: "cancel_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: callInfo.callUUID,
        },
      });
      store.removeCallingUser(id, callInfo);
    },
    getNameForUuid(userUUID) {
      return store.getNameForUuid(userUUID);
    },
    hasPrivilege(userUUID) {
      return hasPrivilege(userUUID);
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    isWaitingRoomUser(uuid) {
      return isWaitingRoomUser(uuid);
    },
    onRejectBridgeCallEvent(info) {
      console.log("hang up as received reject event", !this._notMyCall, this.getCallUUID === info.callUUID);
      if (!this._notMyCall && this.getCallUUID === info.callUUID && this.remoteParticipants && !this.remoteParticipants.length && this.amICalling && !this.amICalling.length) {
        this.rejectCall();
      }
    },
    setInfoModal(data) {
      return store.setinfoModal(data);
    },
    toggleMuteAudioFunction() {
      /*
      if(!this.frameMuted){
        this._sambaFrame.muteFrame();
        this._sambaFrame.disableAudio();
      }else{
        this._sambaFrame.unmuteFrame();
      }
      */
    },
    toggleLeaveSession() {
      // this._sambaFrame.leaveSession();
    },
    getDataForVirtualBackground(virtualBackground) {
      return prepareDataForVirtualBackground(virtualBackground);
    }
  },
  computed: {
    lrDividerStyles: function() {
      if (this.lrDividerPos) {
        return {
          left: this.lrDividerPos + 'px'
        };
      }
      return {};
    },
    topLeftStyle: function() {
      const style = {};
      if (this.ltbDividerPos) {
        style.height = this.ltbDividerPos + 'px';
      }
      if (this.lrDividerPos) {
        style.width = this.lrDividerPos + 'px';
      }
      return style;
    },
    topRightStyle: function() {
      const style = {};
      if (!this.shareScreenEnabled) {
        style.left = 0;
        style.width = '100%';
        return style;
      }
      if (this.lrDividerPos) {
        style.left = this.lrDividerPos + 4 + 'px';
        this.webcamsContainerWidth = (window.innerWidth - this.lrDividerPos + 2) - 55; //Need to take 58px less of space to fit with sidebar
        style.width = this.webcamsContainerWidth + 'px';
      }
      if (this.rtbDividerPos) {
        style.height = this.rtbDividerPos + 'px';
      }
      return style;
    },
    // Resizable container properties end
    getClassForGrid(){
      const usersLength = this.remoteParticipants.length + 1; // more one for include localvideo
      if (this.webcamsContainerWidth < 800){
        switch (usersLength) {
          case 1:
            return 'one';
          case 2:
            return 'scsTwo';
          case 3:
            return 'scsThree';
          case 4:
            return 'scsFour';
          case 5:
            return 'scsFive';
          case 6:
            return 'scsSix';
          default:
            break;
        }
      } else {
        switch (usersLength) {
          case 1:
            return 'one';
          case 2:
            return 'two';
          case 3:
            return 'three';
          case 4:
            return 'four';
          case 5:
            return 'five';
          case 6:
            return 'six';
          default:
            break;
        }
      }
    },
    amInAStaticRoom() {
      return amInAStaticRoom(this.ownUUID);
    },
    virtualBackground() {
      if (!store.state.persisted.mediaDeviceSetup.videoBackground) return false;
      const { enforce, blur, image } = store.state.persisted.mediaDeviceSetup.videoBackground;
      if (!enforce && !blur && !image) return false; // do not set if user disabled it
      const options = { enforce, blur, image };
      Object.keys(options).forEach(key => options[key] === undefined && delete options[key]);
      return options;
    },
    hideVideoContainer() {
      return (
        this.state.currentContentVisile.showComponent ||
        Object.keys(this.state.remoteBridgeStreams).length === 0
      );
    },
    showTimeLine() {
      if (
        !this.getTotalRemoteParticipants ||
        this.getTotalRemoteParticipants == 0
      ) {
        return false;
      }
      // if (this.getIsMobile) {
      //   return false;
      // }
      const callUUID = this.getCallUUID;
      if (this.state.remoteBridgeStreams[callUUID]) {
        if (this.state.remoteBridgeStreams[callUUID].infiniteCall) {
          return false; // Looks like this was removed in the past... a infiniteCall have a duration of 86400000 sec or 31536000000 sec
        } else if (this.state.remoteBridgeStreams[callUUID].callDurationMs) {
          return true;
        }
      }
      return false;
    },
    isSambaStaticRoom() {
      const callUUID = this.getCallUUID;
      if (this.state.remoteBridgeStreams[callUUID] && this.state.remoteBridgeStreams[callUUID].staticRoom) {
        return true;
      }
      return false;
    },
    getCallUUID() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return callUUID;
    },
    getIsAudioOnly() {
      const callUUID = this.getCallUUID;
      if (callUUID && this.state.remoteBridgeStreams[callUUID]) {
        return this.state.remoteBridgeStreams[callUUID].isAudioOnly;
      } else {
        return true;
      }
    },
    getTotalRemoteParticipants() {
      return (this.remoteParticipants || []).length;
    },
    isConferenceCall() {
      const callUUID = this.getCallUUID;
      return callUUID && isConferenceCall(callUUID);
    },
    amICalling() {
      const callUUID = this.getCallUUID;
      const excluded = this.getExcludedFromCalling;
      if (excluded && excluded.length > 0) {
        return this.state.remoteBridgeStreams[callUUID].calling.filter(
          (e) => this.getExcludedFromCalling.indexOf(e) == -1
        );
      } else {
        return (
          (this.state.remoteBridgeStreams[callUUID] &&
            this.state.remoteBridgeStreams[callUUID].calling) ||
          []
        );
      }
    },
    callingParticipants() {},
  },
};
</script>
<style scoped lang="scss">
  .vColCallingToaster{
    min-width: 80%;
    margin-top: 15px;
    padding-top: 0px;
  }
  .customIcons{
    height: 20px;
    max-height: 20px;
    min-height: 20px;
  }
  .customFullScreen { // do not reuse it is only for full screen
    width: 100%!important;
    height: 100%!important;
  }
  .customFullScreenShare{
    width: 100%!important; // do not reuse it is only for full screen
    left: 0!important;
  }
  .displayNoneFullScreen { // do not reuse it is only for full screen
    display: none!important;
  }
  .topBarCall{
      min-height: 40px;
      width: 100%;
      background: black;
  }
  .btnBar{
    position: absolute;
    bottom: 0;
    min-height: 80px;
    width: 100%;
    background: black;
    display: flex;
    justify-content: space-between;
    padding-right: 25px;
    padding-left: 10px;
  }
  video{
    width: 100%;
    border-radius: 5px;
    height: 100%;
  }
  /*
    Webcams GRID css
  */
    .screen-share-container {
      display: flex;
      flex-wrap: wrap;
      height: calc(100vh - 120px);
      align-items: stretch;
      gap: 10px;
      // padding: 10px;
      padding-left: 10px;
      padding-right: 5px;
      box-sizing: border-box;
      background: black;
    }

    .screen-share-item {
      background-color: #272a2e;
      border: 1px solid #272a2e;
      border-radius: 5px;
      // height: 100%;
      // padding: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      width: 100%; /* Adjust for gap */
      height: 100%;
    }
  .webcams-flex-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: stretch;
    gap: 5px;
    // padding: 10px;
    padding-left: 10px;
    padding-right: 30px;
    box-sizing: border-box;
    background: black;
  }
  .webcamsVideoContainer{
    height: 100%;
  }
  .flex-item {
    background-color: #272a2e;
    border: 1px solid #272a2e;
    border-radius: 5px;
    // height: 100%;
    // padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-grow: 1;
    height: 100%;
    width: calc(100% - 2.5px); /* Adjust for gap */
  }

  /* For two elements */
  .two .flex-item { width: calc(50% - 2.5px); } /* Adjust for gap */

  /* For three-4 elements */
  .three .flex-item,
  .four .flex-item {
    width: calc(50% - 2.5px);
    height: calc(50% - 2.5px);
  } /* Adjust for gap */
  .five .flex-item,
  .six .flex-item {
    width: calc(33.333% - 3.333px);
    height: calc(50% - 3.333px);
  }

  /* For Two tight elements*/
  .scsTwo .flex-item{
    height: calc(50% - 2.5px);
  }
  /* For Three tight elements*/
  .scsThree .flex-item{
    height: calc(33.333% - 2.5px);
  }
  /* For Four tight elements*/
  .scsFour .flex-item{
    height: calc(25% - 2.5px);
  }
  /* For Five-Six tight elements*/
  .scsFive .flex-item, .scsSix .flex-item{
    height: calc(33.333% - 3.333px);
    width: calc(50% - 3.333px);
  }
  /* For third element in a three-item layout */
  .three .flex-item:last-child { width: calc(50% - 2.5px); } /* Adjust for gap */

  /* When there are five elements, adjust the first three */
  .five .flex-item:nth-last-child(n+3) { width: calc(33.333% - 3.333px); } /* Adjust for gap */

  /* When there are six elements, each gets even space */
  .six .flex-item { width: calc(33.333% - 3.333px); } /* Adjust for gap */

  /**
    Full-Screen Css
   */
.maximizeScreen {
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  z-index: 1007;
  display: flex;
}
.sidebarCallWrapper{
  position: fixed;
  bottom: 0;
  height: 100%;
  z-index: 1;
}
.sidebarCallWrapperTimeline{
  position: fixed;
  bottom: -24px;
  height: 100%;
  z-index: 1;
}
.progressLinearBridge{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
}
.callContainerNoSidebar{
  width: 100%;
  // height: calc(100% + 30px);
  left: 0px;
  position: fixed;
  bottom: 0;
}
.callContainerSidebar{
    width: calc(100% - 40px);
    left: 55px;
    position: fixed;
    bottom: 0;
}
.heightNoTimeline {
  height: 100%;
  // top: -45px;
  // position: absolute;
}
.heightTimeline {
  height: calc(100% - 24px);
  // top: -20px;
  // position: absolute;
}
// .noLogo{
//    height: 50px;
//     position: absolute;
//     top: 2px;
//     width: 250px;
//     z-index: 9;
//     left: 9px;
//     background: black;
// }
.callingToaster {
  position: absolute;
  bottom: 75px;
  right: 10px;
  z-index: 1;
  width: 23%;
  min-width: 310px;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.waveCallingBridge {
  // position: absolute;
  // left: 30px;
  // -webkit-transform: translate(-50%, -50%);
  // -moz-transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // -o-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // margin: 20px auto;
  .dot {
    background: #02889d;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    animation: wave 1.3s linear infinite;
  }
  .dot:nth-child(2) {
    animation-delay: -1.1s;
  }
  .dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }
    30% {
      transform: translateY(-15px);
    }
  }
}
.ownVideoContainer, .somebodyVideoContainer{
  // min-width: 600px;
  max-height: 100%;
  position: relative;
}
@media (max-width: 999px) {
  // .ownVideoContainer, .somebodyVideoContainer{
  // flex-basis: 100%; /* Each item takes full width */
  // max-width: 100%; /* Ensures the item does not exceed container's width */
  // }
}
.ownVideoContainer:hover {
  .container-div-hover, .containerButtonsOverlay {
    opacity: 1;
  }
}
.somebodyVideoContainer:hover {
  .container-div-hover, .containerButtonsOverlay {
    opacity: 1;
  }
}
.screen-share-item:hover {
    .container-div-hover, .containerButtonsOverlay {
    opacity: 1;
  }
}
.container-div-hover{
  opacity: 0;
  transition: opacity 0.2s ease-in 0s;
  width: 100%;
  text-align: center;
  padding: 0px 8px;
  z-index: 3;
  left: 0px;
  position: absolute;
  top: 8px;
  .name-on-hover{
      max-width: 100%;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border-radius: 16px;
      white-space: nowrap;
      cursor: unset;
      outline: 0px;
      text-decoration: none;
      border: 0px;
      padding: 0px;
      vertical-align: middle;
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 0.875rem;
      height: 26px;
      text-transform: capitalize;
      transition: none 0s ease 0s;
    .span-hover{
      padding: 0px 16px;
      line-height: 1.4;
      overflow: hidden;
      white-space: nowrap;
      color: white;
      font-size: 0.875rem;
      font-weight: 400;
      text-overflow: ellipsis;
      text-transform: capitalize;
      text-align: center;
    }
  }
}
.lettersBackground{
  background: #272a2e;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  min-height: 40px;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lettersOverlay{
  // z-index: 5;
  // position: absolute;
  max-width: 120px;
  max-height: 120px;
  height: 120px;
  width: 120px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.lettersCircle{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(255, 255, 255);
  flex-shrink: 1;
  // background-color: rgb(38, 194, 129);
  letter-spacing: normal;
  font-size: clamp(0.75rem, 36px, 4rem);
  font-weight: 400;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.containerButtonsOverlay{
  opacity: 0;
  transition: opacity 0.2s ease-in 0s;
  // width: 100%;
  // text-align: center;
  padding: 0px 8px;
  z-index: 3;
  left: 0px;
  position: absolute;
  bottom: 15px;
}
.containerOwnButtonsOverlay{
  left: 56px;
}
.containerOwnMicrophone{
  // opacity: 0;
  // transition: opacity 0.2s ease-in 0s;
  // width: 100%;
  // text-align: center;
  padding: 0px 8px;
  z-index: 3;
  left: 0px;
  position: absolute;
  bottom: 15px;
}
.divButtonsOverlay{
  max-width: 100%;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  cursor: unset;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 5px 10px;
  transition: none 0s ease 0s;
}
.buttonOverlay{
  vertical-align: middle;
  box-sizing: border-box;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  height: 33px;
  width: 33px;
  text-transform: capitalize;
  border-radius: 16px;
  padding: 5px;
  margin: 0px 12px;
}
//VueResizableContainer test classes
.resizable-container {
  position: absolute;
  overflow: scroll;
  top: 40px;
  left: 0;
  height: calc(100% - 120px);
  width: 70%;
  &.top-right{
    width: 30%;
  }
}
.top-left {
  background-color: pink;
}

.top-right {
  background-color: lightgreen;
  left: 70%;
  &.camerasFullWidth{
    width: 100%;
    left: 0;
  }
}

// .bottom-left {
//   background-color: lightblue;
//   top: 50%;
// }

// .bottom-right {
//   background-color: lightyellow;
//   top: 50%;
//   left: 50%;
// }

.divider {
  position: absolute;
  background-color: black;
}

.left-right {
  width: 10px;
  height: 100%;
  top: 0;
  left: calc((70% - 4px / 2));
}

// .right-top-bottom {
//   width: 50%;
//   height: 4px;
//   top: calc(50% - 4px / 2);
//   left: 50%;
// }

// .left-top-bottom {
//   width: 50%;
//   height: 4px;
//   top: calc(50% - 4px / 2);
//   left: 0;
// }
.resize-pill {
  position: absolute;
  top: calc(50% - 40px);
  left: 1px;
  opacity: 1;
  height: 36px;
  width: 6px;
  border-radius: 0.25rem;
  border: 1px solid rgb(39, 42, 46);
  background-color: rgb(255, 255, 255);
}

.left-right:hover {
  // cursor: col-resize;
  cursor: ew-resize;
}

.left-top-bottom:hover,
.right-top-bottom:hover {
  cursor: row-resize;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}
</style>
