var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "absolute", width: "100%" },
      attrs: { id: "counter" }
    },
    [
      _c(
        "div",
        {
          staticClass: "backImage",
          style: { backgroundImage: "url(" + _vm.getWaitingRoomBg + ")" }
        },
        [
          _vm.showLogoCompany
            ? _c("img", {
                class: _vm.getClassTemplate,
                attrs: { id: "over", src: _vm.showCompanyLogo, alt: "1" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showText
            ? _c(
                "div",
                { staticClass: "black--text", class: _vm.computedClassText },
                [_vm._v("\n    Waiting Area\n  ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.state.user && _vm.state.user.visitorData
            ? _c(
                "div",
                {
                  staticClass: "black--text text-center",
                  class: _vm.computedClassTitle
                },
                [_vm._v("\n   " + _vm._s(_vm.getWelcomeMsg) + "\n  ")]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }