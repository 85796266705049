<template>
      <v-col @click.stop>
        <v-textarea
          v-model="editedContent"
          :label="$t('components.socialMedia.editYourPost')"
          auto-grow
          variant="outlined"
          rows="3"
        />
        <div class="text-right mt-2">
            <v-btn color="primary" @click.stop="saveEdit">{{ $t('components.socialMedia.save') }}</v-btn>
            <v-btn variant="text" class="ml-2" @click.stop="$emit('closeEdit')">{{ $t('components.socialMedia.cancel') }}</v-btn>
        </div>
      </v-col>
  </template>
  
  <script>
  import store,{ EventBus } from "../../../../store";
  export default {

    props:['postData',],
    data() {
      return {
        state: store.state,
        ownUUID: store.state.ownUUID,
        editedContent: this.postData?.comment.replace(/<br\s*\/?>/g, '\n') || '', 
      };
    },
    mounted() {
      EventBus.$on("closeEdit", this.closeEdit);
    },
    unmounted() {
    EventBus.$off('closeEdit', this.closeEdit);
    },
    methods: {
      closeEdit(){
        this.$emit('closeEdit');
      },
      saveEdit() {
        const dataToUpdate = {
          postId: this.postData._id,
          comment: this.editedContent.replace(/\n/g, "<br>")
        }

        fetch('/api/socialmedia/edit-comment', {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToUpdate)
        }).then((result) => {
            if(!result.error) {
              // add api to edit 
              const updateComment = {
                ...this.postData,
                comment: dataToUpdate.comment
              }
              EventBus.$emit('updateComment', updateComment);
              this.closeEdit()
            }else{
                //TODO: Handler error
            }
        })
      },
    },
  };
  </script>
  