import { render, staticRenderFns } from "./errorInCall.vue?vue&type=template&id=58c9cc31&scoped=true"
import script from "./errorInCall.vue?vue&type=script&lang=js"
export * from "./errorInCall.vue?vue&type=script&lang=js"
import style0 from "./errorInCall.vue?vue&type=style&index=0&id=58c9cc31&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c9cc31",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/voffice-dev04/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58c9cc31')) {
      api.createRecord('58c9cc31', component.options)
    } else {
      api.reload('58c9cc31', component.options)
    }
    module.hot.accept("./errorInCall.vue?vue&type=template&id=58c9cc31&scoped=true", function () {
      api.rerender('58c9cc31', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/modal/errorInCall.vue"
export default component.exports