var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px" } },
    [
      _vm.hideUserLocation
        ? [_vm._v(_vm._s(null))]
        : [
            _c(
              "v-btn",
              {
                staticClass: "locationIcon",
                class: { noWhiteBg: _vm.isRegularGrid },
                style: _vm.defaultAvatar
                  ? "color:#000000"
                  : _vm.isRegularGrid
                  ? "color:#000000; font-size: 15px;"
                  : null,
                attrs: {
                  width: _vm.isRegularGrid ? 25 : 20,
                  height: _vm.isRegularGrid ? 25 : 20,
                  icon: "",
                  color: _vm.isRegularGrid ? "#000000" : "#000000"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              },
              [
                _vm.getPersonLocation(_vm.person) === "homeOffice"
                  ? _c("font-awesome-icon", {
                      staticStyle: { color: "#000000" },
                      attrs: { icon: ["fal", "house-user"] }
                    })
                  : _vm.getPersonLocation(_vm.person) === "outOfHouse"
                  ? _c("font-awesome-icon", {
                      staticStyle: { color: "#000000" },
                      attrs: { icon: ["fal", "car-building"] }
                    })
                  : _vm.getPersonLocation(_vm.person) === "realoOffice"
                  ? _c("font-awesome-icon", {
                      staticStyle: { color: "#000000" },
                      attrs: { icon: ["fal", "building"] }
                    })
                  : _vm.getPersonLocation(_vm.person) === "notSpecified"
                  ? _c("span", { staticStyle: { color: "#000000" } }, [
                      _vm._v("--")
                    ])
                  : _c("font-awesome-icon", {
                      staticStyle: { color: "#000000" },
                      attrs: { icon: ["fal", "location-arrow"] }
                    })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }