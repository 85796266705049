var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelJoinBridgeCall.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showJoinBridgeCallModal.show,
        callback: function($$v) {
          _vm.$set(_vm.showJoinBridgeCallModal, "show", $$v)
        },
        expression: "showJoinBridgeCallModal.show"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal:
                  "Calling " +
                  _vm.getNameForUuid(_vm.showJoinBridgeCallModal.info.user),
                closeModalFunction: _vm.cancelJoinBridgeCall
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
              [
                _c("v-divider", { staticClass: "mt-4" }),
                _vm._v(" "),
                _vm._l(2, function(n) {
                  return _c(
                    "v-col",
                    {
                      key: n,
                      attrs: {
                        lg: _vm.cols[n - 1],
                        md: _vm.cols[n - 1],
                        sm: _vm.cols[n - 1],
                        xl: _vm.cols[n - 1]
                      }
                    },
                    [
                      n == 1
                        ? _c(
                            "div",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    bordered: "",
                                    overlay: "",
                                    height: "60",
                                    width: "60",
                                    bottom: "",
                                    color: "#008000",
                                    dot: "",
                                    "offset-x": "22",
                                    "offset-y": "15"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      staticClass: "borderRadius10",
                                      attrs: { tile: "", size: "150" }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "borderRadius10",
                                        attrs: {
                                          "max-height": "150",
                                          "max-width": "150",
                                          contain: "",
                                          src: _vm.getAvatarForUuid(
                                            _vm.showJoinBridgeCallModal.info
                                              .user
                                          ),
                                          "lazy-src":
                                            "assets/default_profile_picture.png"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      n == 2
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "userNameCard my-0 mt-5 d-flex justify-center",
                                style: { fontSize: "20px" }
                              },
                              [
                                _vm._v(
                                  "\n              Calling...\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              { attrs: { hideDefaultButtons: true } },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  ref: "cancelCall",
                                  attrs: {
                                    icon: "",
                                    color: _vm.isDark ? "white" : "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelJoinBridgeCall()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "faPhoneRotate",
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "phone"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }