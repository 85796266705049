var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mx-0 py-2 text-center takeSnapshotScreen" },
        [
          _c(
            "v-col",
            {
              staticClass: "px-0 takeSnapshotScreenSquare",
              attrs: { cols: "12" }
            },
            [
              _c("WebCam", {
                ref: "webcam",
                attrs: { "device-id": _vm.deviceId },
                on: {
                  error: _vm.onError,
                  cameras: _vm.onCameras,
                  "camera-change": _vm.onCameraChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "py-2 pl-4 footerModal", attrs: { color: "primary" } },
        [
          _c(
            "v-row",
            { staticClass: "px-2 btns mx-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0 py-0",
                  attrs: { lg: "6", align: "right" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "buttonPrimaryFooter",
                      attrs: { color: "white" },
                      on: { click: _vm.take_snapshot }
                    },
                    [_vm._v(_vm._s(_vm.$t("components.camera.takeSnapshot")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3 mr-2",
                      staticStyle: {
                        "border-radius": "4px",
                        "min-width": "45px"
                      },
                      attrs: {
                        color: "white",
                        outlined: "",
                        icon: "",
                        tile: ""
                      },
                      on: { click: _vm.onStop }
                    },
                    [
                      _c("font-awesome-icon", {
                        style: { fontSize: "20px" },
                        attrs: { icon: ["fal", "times"] }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }