<template>
  <div>
    <div>{{ getTimeRange }}</div>
    <div>{{ getNameModerator }}</div>
    <div>
      <span>
        {{ $t("components.remotePersonCall.attendees") }}:
      </span>
      <div>
        <!-- v-for="(uuid, key) in entry.users" :key="key" -->
        <!-- {{getTitelByUuid(uuid)}} {{getNameByUuid(uuid)}} -->
        {{ getAllUsers() }}
      </div>
    </div>
    <div>{{ entry.title || "" }}</div>
    <!-- <div>{{ entry.name }}</div> Same info-->
    <div>{{ entry.details }}</div>
    <!-- <div v-if="1 === 2" >
       <p> Participant: get name here </p>
        <p>{{ rsvp[uuid].status.charAt(0).toUpperCase() + rsvp[uuid].status.slice(1) }}: 
        {{  moment(new Date(rsvp[uuid].lastChange)).format('MM.DD.YYYY HH:mm') }} </p>

    </div> -->
    <!-- TODO: ADD paylink info -->
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../../sharedsrc/moment";
import store from "../../../store";
import {myConferenceDetails} from "../../../effector/groupConferences";
import {
  myConferenceHallDetails,
} from "../../../effector/conferenceHalls";
// import { getUserStateStore, ownUserUUIDState } from '../../../effector/users';
export default {
  props: ["entry"],
  data() {
    const effector = {
      myConference: myConferenceDetails,
      myConferenceHall: myConferenceHallDetails,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      moment: moment,
      ownUUID: store.state.ownUUID,
      // Effector
      ...effector,
    };
  },
  mounted() {},
  computed: {
    getNameModerator() {
      const conference = this.myConference.find(x => x.confId === this.entry.confId);
      const conferenceHall = this.myConferenceHall.find(x => x.confId === this.entry.confId); // Fixed reference
      const moderatorLabel = this.$t("components.remotePersonCall.moderator") + ":";
      let moderatorNames = [];

      if (conference && conference.confModerators?.length) {
        moderatorNames = conference.confModerators.map(moderator =>
          `${this.getTitelByUuid(moderator)} ${this.getNameByUuid(moderator)}`
        );
      } else if (conferenceHall && conferenceHall.confModerators?.length) {
        moderatorNames = conferenceHall.confModerators.map(moderator =>
          `${this.getTitelByUuid(moderator)} ${this.getNameByUuid(moderator)}`
        );
      } else {
        const creatorName = `${this.getTitelByUuid(this.entry.creatorUUID)} ${this.getNameByUuid(this.entry.creatorUUID)}`;
        moderatorNames.push(creatorName);
      }

      return `${moderatorLabel} ${moderatorNames.join(", ")}`;
    },
    getTimeRange() {
      if (this.entry.isUnlimited) {
        return (
          this.$t("components.stats.conference") +
          " " +
          moment(this.entry.start).format("HH:mm") +
          " " +
          this.$t("components.meetingLineModal.hours")
        );
      } else {
        return (
          this.$t("components.stats.conference") +
          " " +
          moment(this.entry.start).format("HH:mm") +
          " - " +
          moment(this.entry.end).format("HH:mm") +
          " " +
          this.$t("components.meetingLineModal.hours")
        );
      }
    },
    rsvp() {
      return this.entry.rsvp;
    },
    keys() {
      if (!this.rsvp) return [];
      return Object.keys(this.rsvp);
    },
  },
  methods: {
    getAllUsers() {
      if (this.entry.users) {
        const participants = this.entry.users
          .filter((uuid) => this.ownUUID !== uuid)
          .map((uuid) => {
            return store.state.group[uuid]
              ? this.getTitelByUuid(uuid) + " " + this.getNameByUuid(uuid) ||
                  "Anonymous"
              : "";
          })
          .join(", ");
        return participants;
      }
      return false;
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>