var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.conference &&
    _vm.conference.confUUIDS &&
    _vm.conference.confModerators.indexOf(_vm.state.ownUUID) != -1
    ? _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "mt-2 mx-auto",
                          attrs: {
                            depressed: "",
                            color: "primary",
                            small: "",
                            disabled: _vm.amInACall
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.callEveryone(false, null)
                            }
                          }
                        },
                        on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "phone"] }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            false,
            3275726505
          )
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.$t("components.conferenceForm.startConference")))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }