var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.customTop
          ? "iconCardsContainerRightCustom"
          : "iconCardsContainerRight"
      },
      [
        (_vm.person || {}).connected && !_vm.isBasicView
          ? [
              _c("LocationIcon", {
                attrs: {
                  person: _vm.person,
                  defaultAvatar: _vm.hasUserImage(_vm.person.uuid),
                  isRegularGrid: _vm.isRegularGrid
                }
              })
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.showCallButton && !_vm.isBasicView
          ? [
              _c("CallIcon", {
                attrs: {
                  person: _vm.person,
                  defaultAvatar: _vm.hasUserImage(_vm.person.uuid),
                  isRegularGrid: _vm.isRegularGrid
                }
              })
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm.showAddMultiUserCall
      ? _c(
          "div",
          {
            class: _vm.customTop
              ? "iconCardsContainerLeftCustom"
              : "iconCardsContainerLeft"
          },
          [_c("MultiUserCallIconGrid", { attrs: { person: _vm.person } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }