var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InviteVisitorModal", {
    ref: "inviteVisitorModal",
    attrs: { standalone: true, showModal: true, options: _vm.$route.query }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }