var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showOrganigram && !_vm.isMobile && !_vm.mini
        ? _c(
            "v-list",
            {
              staticClass: "d-flex pl-4 pointer relative",
              on: {
                click: function($event) {
                  return _vm.setList("organisation")
                }
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _vm.currentPage("/organisation")
                    ? _c("font-awesome-icon", {
                        staticClass: "primary--text-sidepanel",
                        attrs: { icon: ["fas", "sitemap"] }
                      })
                    : _c("font-awesome-icon", {
                        staticClass: "primary--text-sidepanel",
                        attrs: { icon: ["fal", "sitemap"] }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-title",
                {
                  staticClass: "primary--text-sidepanel",
                  class:
                    "" +
                    (_vm.getRoute === "/organisation"
                      ? "font-weight-bold primary--text-sidepanel"
                      : ""),
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleOrganisation()
                    }
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.organisation")))]),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                !_vm.isMobile &&
                                _vm.amIAdmin &&
                                !_vm.isBigOrganisation
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "btnAddGroup",
                                          attrs: { icon: "", disabled: false },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.showEditableView()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass:
                                            "primary--text-sidepanel",
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "plus-circle"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2964048291
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.state.user.organisationEditable
                              ? _vm.$t("components.sidebar.cancelEditOrga")
                              : _vm.$t("components.sidebar.organigramPlusTT")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showOrganigram && !_vm.isMobile && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handlerClickOrgaMini.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _vm.currentPage("/organisation")
                                  ? _c("font-awesome-icon", {
                                      staticClass: "primary--text-sidepanel",
                                      attrs: { icon: ["fas", "sitemap"] }
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "primary--text-sidepanel",
                                      attrs: { icon: ["fal", "sitemap"] }
                                    })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1267158458
              )
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.organisation")))])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }