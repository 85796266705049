var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.visible, persistent: "", "max-width": "460" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.hide(false)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v("\n      Helpscout Schnittstelle\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.excelEditor.helpscoutInfo")))
              ]),
              _vm._v(" "),
              _c("p", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://docs.helpscout.com/article/1250-beacon-jumpstart-guide",
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.excelEditor.helpscoutQuickstart")
                      )
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  name: "input-7-4",
                  label: "Helpscout Beacon HTML Code / Beacon ID",
                  placeholder: "<script type='text/javascript'..."
                },
                model: {
                  value: _vm.rawHelpscoutBeaconText,
                  callback: function($$v) {
                    _vm.rawHelpscoutBeaconText = $$v
                  },
                  expression: "rawHelpscoutBeaconText"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "8", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.hide(true)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.ok")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.hide(false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }