<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal :titleModal="titleModal" :closeModalFunction="closeModal" />
      <v-row class="mx-0 pt-4 pb-3 px-4">
        <v-col>
        <!-- <p class="font-weight-medium">{{
          $t("generics.attention")
        }}</p> -->
        <p>{{
          isSuperVisorOrUserOrganisation ? $t("components.manageOrganisation.hasAmanagementFunction") : isAssitantOfSomebody ? $t("components.manageOrganisation.userIsAssistant") : $t("generics.deleteUser")
        }}</p>
        </v-col>
        </v-row>
      <FooterModal :closeModalFunction="closeModal" :showCancel="true">
        <v-btn v-if="isAssitantOfSomebody" style="color:red;" flat color="white" @click="removeFromAssistants()">{{
          $t("components.manageOrganisation.removeUserFromAssistants")
        }}</v-btn>
        <v-btn v-if="!isSuperVisorOrUserOrganisation && !isAssitantOfSomebody" style="color:red;" flat color="white" @click="revokeInvite()">{{
          $t("generics.delete")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { deleteVisitor } from "../../utils/waitingRoom";
import { deleteUserByUUID, removeUserInviters } from "../../lib/wsMsg";
import { checkUuidPresenceInOrganigram } from "../../utils/basicFunctions"
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["person", "closeModal", "showModal"],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    supervisors: []
  }),
  mounted() {
    const dataOrganisation = this.state.namespaceSettings.processedOrganisation || {};
    this.findSupervisors(dataOrganisation);
  },
  unmounted() {
    this.supervisors = [];
  },
  methods: {
    removeFromAssistants(){
      const assistantUUID = this.person.uuid;
      // For each 'manager' that this.person.uuid assists, we need to remove assistantUUID
      this.person.user.assists.forEach(managerUUID => {
        this.state.group[managerUUID].user.assistants = this.state.group[managerUUID].user.assistants.filter(uuid => uuid != assistantUUID)
      });
      // After cleaning this.person from 'managers' assistants list,
      //  we need to clean the 'assists' list at this.person
      this.person.user.assists = [];
    },
    findSupervisors(obj) {
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          if (Object.prototype.hasOwnProperty.call(obj[key], "supervisors")) {
            this.supervisors.push(...obj[key].supervisors);
          }
          this.findSupervisors(obj[key]);
        }
      }
    },
    async revokeInvite() {
      const uuid = this.person.uuid || this.person.user?.uuid;
      if (
        this.person.user &&
        this.person.user.guest &&
        this.person.user.inviters &&
        Object.keys(this.person.user.inviters).length > 1
      ) {
        await removeUserInviters(uuid);
      } else if (this.state.persisted.userVisitors[uuid]) {
        await deleteVisitor(uuid, true);
      } else {
        await deleteUserByUUID(uuid);
      }
      if (this.state.persisted.favoriteUsers[uuid]) {
        store.setUuidInFavorites(uuid, undefined);
      }
      if (this.state.persisted.invitedUsers[uuid]) {
        store.setUuidInInvited(uuid, undefined);
      }
      this.closeModal();
    },
    checkUuidPresenceInOrganigram(uuid){
      return checkUuidPresenceInOrganigram(uuid);
    }
  },
  computed: {
    isAssitantOfSomebody() {
      return !!this.person.user.assists.length;
    },
    isSuperVisorOrUserOrganisation() {
      return !!this.checkUuidPresenceInOrganigram(this.person.uuid || this.person.user?.uuid).length;
    },
    titleModal() {
      return !this.person.user.guest
        ? this.$t("components.userListItem.removeUser")
        : this.$t("components.userListItem.removeGuest");
    },
  },
};
</script>