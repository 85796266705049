<template>
    <vue-scroll class="vueScrollInfoboard">
       <v-row no-gutters v-if="getTotalNotes === 0" >
       <p style="margin: 0 auto;">{{ $t('generics.noInfoboard') }}</p>
      </v-row>
      <div>
        <Infoboard></Infoboard>
        <ModalNote  v-if="state.showNote.note"/>
      </div>
    </vue-scroll>
  <!-- </v-container> -->
</template>

<script>
import store from "../../store";
import Infoboard from '../content/infoboard/infoboard.vue';
import ModalNote from '../modal/modalNote.vue';
export default {
  data: () => ({
    state: store.state,
  }),
  components: {
    Infoboard,
    ModalNote
  },
  methods: {
    setCurrentContentVisile(...args) {
      store.setCurrentContentVisile(...args);
    }
  },
  computed: {
      getTotalNotes(){
      let count = 0;
      //my notes
      const allNotes = store.state.namespaceSettings.notes;
      // console.log(allNotes)
      const filteredNotes = allNotes.filter((note) => {
        return (
          note.targetUsers.length === 0 ||
          (note.targetUsers.length === 1 && note.targetUsers[0] === "PartyTime") ||
          note.author === this.state.ownUUID ||
          note.targetUsers.includes(this.state.ownUUID)
        );
      });
      // console.log(filteredNotes, 'filteredNotes');
      if(filteredNotes.length > 0){
        count = filteredNotes.length;
      }

      return count;
    },
  },
  mounted() {
    this.setCurrentContentVisile("infoboard", true);
  }
};
</script>

<style lang="scss">
  .vueScrollInfoboard{
    height: calc(100vh - 64px) !important;
    .__view{
      width: unset !important;
    }
  }
</style>