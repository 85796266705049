/** @param {string} hostname */
function isAllowed(hostname, relaxed = false) {
  // strict reserved checks
  if (
    !hostname ||
    /\.\.|\.$/.test(hostname) ||
    /^[_\d.]+$/.test(hostname) ||
    hostname.split(".")[0].length > 40 ||
    /^(admin|config|local|system|to|__proto__)(\.|_\d|$)/.test(hostname) ||
    hostname.replace(/[^\w\d_.-]/g, '').length !== hostname.length ||
    hostname.replace(/\.voffice\.pro$/, '').replace(/[.]/g, '__').length > 63
  ) return false;
  // pkg and backend overrides
  if (process.env.PKG || relaxed || isDev(hostname)) return true;
  // frontend sanity checks
  if (/^[^a-zA-Z]|\.\.|[_-]\.|__|--|\.$/.test(hostname)) return false;
  // match domain-value in RFC 6265 sec 4.1.1
  if (!/^([a-z0-9]([a-z0-9_-]{0,61}[a-z0-9])?)([.][a-z0-9]([a-z0-9_-]{0,61}[a-z0-9])?)*$/i.test(hostname)) return false;
  const startNotAllowed = [
    "www.",
    "de.",
    "uk.",
    "at.",
    "cl.",
    "ch.",
    "es.",
    "se.",
  ];
  const subSubAllowed = [
    ".tunnel.bittube.",
    ".beta.",
    ".private.",
    ".ra-micro.",
    ".voffice.",
    ".de.",
    ".uk.",
    ".at.",
    ".cl.",
    ".ch.",
    ".es.",
    ".se.",
  ];
  for (const start of startNotAllowed) {
    if (hostname.indexOf(start) === 0) return false;
  }
  for (const sub of subSubAllowed) {
    if (hostname.indexOf(sub) !== -1) return hostname.split(".").length <= 4;
  }
  return hostname.split(".").length <= 3;
}

/** @param {string} hostname */
function isDev(hostname) {
  // Override to false if packaged version
  if (process.env.PKG) return false;
  // Domains running in prodution mode that need isDev
  if ((hostname.startsWith('beta.voffice.') || hostname.includes('.beta.voffice.')) && !(/\.voffice\.(ch|es)$/.test(hostname))) return true;
  // Override to false if production mode
  if (process.env.NODE_ENV === 'production') return false;
  // Override to false if production domain
  if (/\.(ch|es|se)\.voffice\./.test(hostname) || /\.voffice\.(ch|es)$/.test(hostname)) return false;
  // Domains running in development mode that need isDev
  return !!(
    hostname === 'localhost' ||
    (hostname.includes('.localhost') && /\.localhost$/.test(hostname)) ||
    hostname === 'v-viewer.com' ||
    hostname.includes('.v-viewer.com') ||
    // hostname.includes('.tunnel.bittube.app') ||
    hostname.startsWith('beta.') ||
    (hostname.startsWith('dev') && /^dev\d{2}\./.test(hostname)) ||
    (hostname.includes('dev') && /\.dev\d{2}\./.test(hostname))
  );
}

/** @param {string} hostname */
function isSeCustomer(hostname) {
  return hostname.includes('.se.localhost') || /\.(ch|es|se)\.voffice\./.test(hostname) || /\.voffice\.(ch|es)$/.test(hostname);
}

/** @param {string} hostname */
function isRaMicro(hostname) {
  return /^ra-micro(?:|2)\./.test(hostname) && !(/\.voffice\.(ch|es)$/.test(hostname));
}

/** @param {string} hostname */
function isRaMicroCustomer(hostname) {
  return hostname.includes('.ra-micro.') && !(/\.voffice\.(ch|es)$/.test(hostname));
}

/** @param {string} hostname */
function isVOfficeWorld(hostname) {
  return /\.voffice\.world$/.test(hostname);
}

/** @param {string} hostname */
function isVOfficePro(hostname) {
  return /\.voffice\.pro$/.test(hostname);
}

/** @param {string} hostname */
function isBridgeCallMeetingTimeAvailable(hostname) {
  return true; // isDev(hostname) || isRaMicro(hostname);
}

/** @param {string} hostname */
function isVisitorPayAvailable(hostname) {
  return true;
}

/** @param {string} hostname */
function isConferenceWhatsAppInviteAvailable(hostname) {
  // https://gitlab.ra-micro.de/devcups/voffice/-/issues/401
  return false; // isDev(hostname) || isRaMicro(hostname);
}

/** @param {string} hostname */
function isSpeechliveAvailable(hostname) {
  // https://gitlab.devcups.com/devcups/voffice/-/issues/2549
  return false; // isDev(hostname) || isRaMicro(hostname);
}

/** @param {string} hostname */
function supportSetupRoute(hostname) {
  return isDev(hostname) || isRaMicroCustomer(hostname);
}

/** @param {string} hostname */
function getLicensedBaseFeatures(hostname) {
  const domain = hostname.toLowerCase();
  if (process.env.PKG) {
    return {
      isAllowed: isAllowed(domain),
      isDev: false,
      isPkg: true,
      isRaMicro: false,
      isRaMicroCustomer: false,
      isSeCustomer: false,
      isVOfficeWorld: false,
      isBridgeCallMeetingTimeAvailable: true,
      isVisitorPayAvailable: false,
      isConferenceWhatsAppInviteAvailable: false,
      isSpeechliveAvailable: false,
      supportSetupRoute: true,
    };
  } else {
    return {
      isAllowed: isAllowed(domain),
      isDev: isDev(domain),
      isPkg: false,
      isRaMicro: isRaMicro(domain),
      isRaMicroCustomer: isRaMicroCustomer(domain),
      isSeCustomer: isSeCustomer(domain),
      isVOfficeWorld: isVOfficeWorld(domain),
      isBridgeCallMeetingTimeAvailable: isBridgeCallMeetingTimeAvailable(domain),
      isVisitorPayAvailable: isVisitorPayAvailable(domain),
      isConferenceWhatsAppInviteAvailable: isConferenceWhatsAppInviteAvailable(domain),
      isSpeechliveAvailable: isSpeechliveAvailable(domain),
      supportSetupRoute: supportSetupRoute(domain),
    };
  }
}

/** Licensed features - Import and use only in websrc files */
const webLicensedBaseFeatures = (typeof window !== 'undefined' && typeof window.location !== 'undefined')
  ? getLicensedBaseFeatures(window.location.hostname)
  : null;
// document.webLicensedBaseFeatures = webLicensedBaseFeatures;

module.exports = {
  // Individual checks
  isAllowed,
  isDev,
  isRaMicroCustomer,
  isSeCustomer,
  isVOfficeWorld,
  isVOfficePro,
  isBridgeCallMeetingTimeAvailable,
  isVisitorPayAvailable,
  isConferenceWhatsAppInviteAvailable,
  isSpeechliveAvailable,
  supportSetupRoute,
  // Complete information
  getLicensedBaseFeatures,
  // Frontend specific
  webLicensedBaseFeatures,
};
