var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "absolute",
      class: {
        "d-none":
          _vm.state.currentContentVisile.showComponent ||
          Object.keys(_vm.state.remoteStreams).length === 0
      },
      attrs: { id: "video-container" }
    },
    [
      !_vm.infiniteCall
        ? _c("div", { staticClass: "progressBar" }, [_c("ProgressLinear")], 1)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "splitpanes",
        {
          staticClass: "default-theme",
          class: "" + (!_vm.infiniteCall ? " mt-6" : ""),
          staticStyle: { height: "calc(100% - 24px)" },
          attrs: { horizontal: "" }
        },
        [
          _vm._l(_vm.state.remoteStreams, function(remote, uuid) {
            return _c(
              "pane",
              {
                key: uuid,
                attrs: {
                  size:
                    Object.keys(_vm.state.remoteStreams).length == 1
                      ? _vm.actualSize.remote
                      : 50
                }
              },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        return _vm.changeActualSize()
                      }
                    }
                  },
                  [
                    _c("RemotePersonCall", {
                      attrs: { uuid: uuid, remote: remote }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "pane",
            {
              attrs: {
                size:
                  Object.keys(_vm.state.remoteStreams).length == 1
                    ? _vm.actualSize.me
                    : 50
              }
            },
            [
              _c(
                "div",
                {
                  ref: "localVideoDiv",
                  staticClass: "relative localVideo",
                  on: {
                    click: function($event) {
                      return _vm.changeActualSize()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "callerName" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getNameForUuid(_vm.state.ownUUID)))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.state.localStreams.display
                    ? _c("div", { staticClass: "divSharingScreen" }, [
                        _c("video", {
                          class: { "d-none": !_vm.haveLocalVideo },
                          attrs: {
                            id: "local-video",
                            "webkit-playsinline": "",
                            playsinline: "",
                            autoplay: "autoplay",
                            muted: ""
                          },
                          domProps: {
                            srcObject: _vm.state.localStreams.user,
                            muted: true
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.state.localStreams.display
                    ? _c("video", {
                        class: { "d-none": !_vm.haveLocalVideo },
                        attrs: {
                          id: "local-video",
                          "webkit-playsinline": "",
                          playsinline: "",
                          autoplay: "autoplay",
                          muted: ""
                        },
                        domProps: {
                          srcObject: _vm.state.localStreams.user,
                          muted: true
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("video", {
                    class: { "d-none": !_vm.state.localStreams.display },
                    attrs: {
                      id: "local-screen",
                      "webkit-playsinline": "",
                      playsinline: "",
                      autoplay: "autoplay",
                      muted: ""
                    },
                    domProps: {
                      srcObject: _vm.state.localStreams.display,
                      muted: true
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "noCameraStream",
                      class: { "d-none": _vm.haveLocalVideo }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "noCameraContent" },
                        [
                          _c("v-img", {
                            staticClass: "imageNotVideo",
                            attrs: {
                              contain: "",
                              src: _vm.getAvatarFromUuid(_vm.state.ownUUID),
                              "lazy-src": "assets/default_profile_picture.png"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "emptyDiv" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "buttonsUser",
                      class: {
                        hidden:
                          Object.keys(_vm.state.remoteStreams).length == 0,
                        buttonsIpad: _vm.isIpad && _vm.isIos
                      }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { color: "white", icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.tryWebcam()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm.state.persisted.rtcVideo
                                        ? _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "video"] }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.state.persisted.rtcVideo
                                        ? _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: {
                                              icon: ["fal", "video-slash"]
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.camera")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { color: "white", icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.toggleMuteLocalAudio()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      !_vm.isLocalAudioMuted
                                        ? _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: {
                                              icon: ["fal", "microphone"]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isLocalAudioMuted
                                        ? _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: {
                                              icon: ["fal", "microphone-slash"]
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.callsContent.mic"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "redBtn",
                                        attrs: { color: "white", icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.rtcHangup()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: {
                                          icon: ["fal", "phone"],
                                          rotation: 270
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.hangUp")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }