<template>
    <div>
        <div>
            <v-btn icon variant="text" @click="goBack" class="mb-4">
                <font-awesome-icon :icon="['fal', 'arrow-left']" :style="{ fontSize: '20px' }" />
            </v-btn>
        </div>
        <v-row class="d-flex" no-gutters>
            <v-col cols="12" md="12" class="px-4 pb-4">
                <Profile :userProfile="userProfile" />
                <!-- <UserOverview v-for="(info, index) in getUserOverview" :key="index" :userInfo="info" /> -->
            </v-col>
            <!-- <v-col cols="12" md="6" class="px-4 pb-4">
                <h3 class="font-weight-regular">{{ $t("components.socialMedia.timeline") }}</h3>
                <v-divider class="mb-6"></v-divider>
                <Vita v-for="(infoVita, index) in getVitaData" :key="index" :info="infoVita" />
            </v-col> -->
        </v-row>

        <v-row class="d-flex pt-6" no-gutters>
            <v-col cols="12" class="px-4">
                <!-- <h3>{{ $t("components.socialMedia.weTogether") }}</h3> -->
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="6" class="pa-4" style="height: calc(100vh - 390px); overflow: auto;">
                <h3 class="font-weight-regular">{{ $t("components.socialMedia.weTogether") }}</h3>
                <Post v-for="(post, index) in postData" :key="`post-${index}`" :postData="post" />
                <infinite-loading :identifier="date" ref="infiniteLoadingPosts" :distance="3"
                    @infinite="infiniteHandlerPosts">
                    <template #error>
                        <div v-html="'&nbsp;'"></div>
                    </template>
                    <template #complete>
                        <div v-html="'&nbsp;'"></div>
                    </template>
                </infinite-loading>
            </v-col>
            <v-col cols="12" md="6" class="pa-4" style="height: calc(100vh - 390px); overflow: auto;">
                <h3 class="font-weight-regular">{{ $t("components.socialMedia.whatsWork") }}</h3>
                <WhatsWork v-for="(whatsWork, index) in whatsWorkData" :key="`whatsWork-${index}`"
                    :whatsWorkData="whatsWork" />
                <infinite-loading :identifier="date" ref="infiniteLoadingWhatsWork" :distance="3"
                    @infinite="infiniteHandlerWhatsWork">
                    <template #error>
                        <div v-html="'&nbsp;'"></div>
                    </template>
                    <template #complete>
                        <div v-html="'&nbsp;'"></div>
                    </template>
                </infinite-loading>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import moment from "../../../../sharedsrc/moment";
import CreatePost from "./post/createPost.vue";
import Post from "./post/post.vue";
import WhatsWork from "./workUpdates/whatsWork.vue";
import Profile from "./profile/profile.vue";
import Vita from "./profile/vita.vue";
import UserOverview from "./profile/userOverview.vue";
import InfiniteLoading from "v3-infinite-loading";

export default {
    props: [],
    components: { CreatePost, Post, Profile, Vita, UserOverview, WhatsWork, "infinite-loading": InfiniteLoading },
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            userProfile: store.state.ownUUID,
            educationBackground_info: "",
            additionalKnowledge_info: "",
            additionalBriefDesc_info: "",
            additionalWorkExp_info: "",
            additionalInterests_info: "",
            additionalStartDate: "",
            additionalCompanyStartDate: "",

            postData: [],
            whatsWorkData: [],
            // WhatsWork Infinite Scroll
            currentPageWhatsWork: 1,
            limitWhatsWork: 20,
            isLoadingWhatsWork: false,
            hasMoreWhatsWork: true,
            // mypost Infinite Scroll
            currentPagePosts: 1,
            limitPosts: 20,
            isLoadingPosts: false,
            hasMorePosts: true,

        };
    },
    watch: {
        'getProfileUser': {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (value) {
                    this.userProfile = value === 'profile' ? this.ownUUID : value;
                    this.resetData();
                    this.loadUserSettings();
                    this.getUserPosts();
                    this.getUserWhatsWork();
                }
            },
        },
    },
    mounted() {
        EventBus.$on('updatePost', this.updatePost);
        EventBus.$on('removePost', this.removePost);
        EventBus.$on('removeWhatsWork', this.removeWhatsWork);
        EventBus.$on('updateWhatsWork', this.updateWhatsWork);
        EventBus.$on('updateComment', this.updateComment);
        EventBus.$on('removeComment', this.removeComment);
        // this.getUserPosts();
        // this.getUserWhatsWork();
    },
    unmounted() { 
        EventBus.$off('updatePost', this.updatePost);
        EventBus.$off('removePost', this.removePost);
        EventBus.$off('removeWhatsWork', this.removeWhatsWork);
        EventBus.$off('updateWhatsWork', this.updateWhatsWork);
        EventBus.$off('updateComment', this.updateComment);
        EventBus.$on('removeComment', this.removeComment);
    },
    methods: {
        resetData(){
            this.postData =  [];
            this.whatsWorkData = [];
            this.currentPageWhatsWork= 1;
            this.limitWhatsWork= 20;
            this.isLoadingWhatsWork= false;
            this.hasMoreWhatsWork= true;
            this.currentPagePosts= 1;
            this.limitPosts= 20;
            this.isLoadingPosts= false;
            this.hasMorePosts= true;
        },
        removeComment(comment) {
            // Helper function to remove the comment from both postData and whatsWorkData
            const removeCommentFromData = (data, index) => {
                if (index !== -1) {
                    // Find the comment index inside the comments array
                    const commentIndex = data[index].comments.findIndex(c => c._id === comment._id);
                    
                    if (commentIndex !== -1) {
                        // Remove the comment from the comments array
                        const updatedComments = [...data[index].comments];
                        updatedComments.splice(commentIndex, 1); // Removes the comment at the found index

                        // Update the post with the new comments array and the updated timestamp
                        this.$set(data, index, {
                            ...data[index],
                            comments: updatedComments,
                            updatedAt: new Date().toISOString()
                        });
                    }
                }
            };

            // Find the index in postData and remove the comment if found
            const postIndex = this.postData.findIndex(x => x.id === comment.postId);
            removeCommentFromData(this.postData, postIndex);

            // Find the index in whatsWorkData and remove the comment if found
            const postWhatsworkIndex = this.whatsWorkData.findIndex(x => x.id === comment.postId);
            removeCommentFromData(this.whatsWorkData, postWhatsworkIndex);
        },
        updateComment(comment) {
            const postIndex = this.postData.findIndex(x => x.id === comment.postId);
            const postWhatsworkIndex = this.whatsWorkData.findIndex(x => x.id === comment.postId);
            // Helper function to update the comments for both postData and whatsWorkData
            const updateData = (data, index) => {
                if (index !== -1) {
                    this.$set(data, index, {
                        ...data[index],
                        comments: [...data[index].comments, comment], // Add the new comment to the comments array
                        updatedAt: new Date().toISOString() // Update the timestamp
                    });
                }
            };
            // Update postData if found
            updateData(this.postData, postIndex);
            // Update whatsWorkData if found
            updateData(this.whatsWorkData, postWhatsworkIndex);
        },
        updatePost(post) {
            const postIndex = this.postData.findIndex(x => x.id === post.postId);
            if (postIndex !== -1) {
                this.$set(this.postData, postIndex, {
                    ...this.postData[postIndex],
                    content: post.content,
                    images: post.images,
                    documents: post.documents,
                    updatedAt: new Date().toISOString()
                });
            }
        },
        updateWhatsWork(whatsWork) {
            const postIndex = this.whatsWorkData.findIndex(x => x.id === whatsWork.postId);
            if (postIndex !== -1) {
                this.$set(this.whatsWorkData, postIndex, {
                    ...this.whatsWorkData[postIndex],
                    content: whatsWork.content,
                    images: whatsWork.images,
                    documents: whatsWork.documents,
                    updatedAt: new Date().toISOString()
                });
            }
        },
        removePost(post){
            const postIndex = this.postData.findIndex(x => x.id === post._id);
            if (postIndex !== -1) {
                this.postData.splice(postIndex, 1); // Elimina el post del array
            }
        },
        removeWhatsWork(whatsWork){
            const postIndex = this.whatsWorkData.findIndex(x => x.id === whatsWork._id);
            if (postIndex !== -1) {
                this.whatsWorkData.splice(postIndex, 1); // Elimina el post del array
            }
        },
        loadUserSettings() {
            const userSettings = store.state.group[this.userProfile]?.user?.userSettings || {};

            this.educationBackground_info = userSettings.educationBackground || "";
            this.additionalKnowledge_info = userSettings.additionalKnowledge || "";
            this.additionalBriefDesc_info = userSettings.additionalBriefDesc || "";
            this.additionalWorkExp_info = userSettings.additionalWorkExp || "";
            this.additionalInterests_info = userSettings.additionalInterests || "";
            this.additionalStartDate = userSettings.additionalStartDate ? new Date(userSettings.additionalStartDate) : '';
            this.additionalCompanyStartDate = userSettings.additionalCompanyStartDate ? new Date(userSettings.additionalCompanyStartDate) : '';
        },
        goBack() {
            this.$router.push({ path: `/socialmedia` });
            // this.$router.back();
        },
        getUserPosts() {
            // Si ya estamos cargando, no hacer nada
            if (this.isLoadingPosts || !this.hasMorePosts) return;

            // Marcar como cargando
            this.isLoadingPosts = true;

            // Hacer el fetch de los posts
            fetch(`/api/socialmedia/my-posts?userId=${this.userProfile}&page=${this.currentPagePosts}&limit=${this.limitPosts}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then(async (response) => {
                    const result = await response.json();
                    if (!result.error) {
                        if (result.posts.length > 0) {
                            // Filtrar duplicados antes de agregar
                            const newPosts = result.posts.filter(post => !this.postData.some(existingPost => existingPost._id === post._id));

                            // Concatenar nuevos posts (sin duplicados)
                            if (newPosts.length > 0) {
                                this.postData = [...this.postData, ...newPosts]; // Concatenar nuevos posts
                                this.currentPagePosts++; // Incrementar la página
                            }
                        }
                        if (this.postData.length >= result.totalPosts) {
                            this.hasMorePosts = false; // No más posts para cargar
                        }
                    } else {
                        // Manejar errores aquí
                    }
                })
                .catch(err => console.error("Error al obtener posts del usuario:", err))
                .finally(() => this.isLoadingPosts = false); // Marcar como no cargando
        },
        infiniteHandlerPosts($state) {
            if (this.hasMorePosts) {
                this.getUserPosts(); // Obtener más posts
                setTimeout(() => {
                    $state.loaded(); // Marcar como cargados los posts
                }, 500);
            } else {
                $state.complete(); // Marcar como completado si no hay más posts
            }
        },
        infiniteHandlerWhatsWork($state) {
            if (this.hasMoreWhatsWork) {
                this.getUserWhatsWork();
                setTimeout(() => {
                    $state.loaded();
                }, 500);
            } else {
                $state.complete();
            }
        },
        getUserWhatsWork() {
            // Si ya estamos cargando, no hacer nada
            if (this.isLoadingWhatsWork || !this.hasMoreWhatsWork) return;

            // Marcar como cargando
            this.isLoadingWhatsWork = true;

            // Hacer el fetch de los whatsWork
            fetch(`/api/socialmedia/whatswork?userId=${this.userProfile}&page=${this.currentPageWhatsWork}&limit=${this.limitWhatsWork}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then(async (response) => {
                    const result = await response.json();
                    if (!result.error) {
                        if (result.posts.length > 0) {
                            // Filtrar duplicados antes de agregar
                            const newWhatsWork = result.posts.filter(work => !this.whatsWorkData.some(existingWork => existingWork._id === work._id));

                            // Concatenar nuevos posts de whatsWork (sin duplicados)
                            if (newWhatsWork.length > 0) {
                                this.whatsWorkData = [...this.whatsWorkData, ...newWhatsWork];
                                this.currentPageWhatsWork++;
                            }
                        }
                        if (this.whatsWorkData.length >= result.totalPosts) {
                            this.hasMoreWhatsWork = false;
                        }
                    } else {
                        // Manejar errores aquí
                    }
                })
                .catch(err => console.error("Error al obtener whatsWork:", err))
                .finally(() => this.isLoadingWhatsWork = false); // Marcar como no cargando
        },
    },
    computed: {
        getProfileUser() {
            return this.$route.params.profile
        },
        getUserOverview() {
            const data = [
                {
                    title: `${this.$t("components.socialMedia.activityOverview")}:`,
                    content: this.additionalBriefDesc_info
                },
                {
                    title: `${this.$t("components.socialMedia.skills")}:`,
                    content: this.additionalKnowledge_info
                },
                {
                    title: `${this.$t("components.socialMedia.hobbies")}:`,
                    content: this.additionalInterests_info
                },
            ]
            return data;
        },
        getVitaData() {
            const data = [
                {
                    title: `${this.$t("components.socialMedia.education")}:`,
                    content: [
                        {
                            content: this.educationBackground_info
                        },
                    ]
                },
                {
                    title: `${this.$t("components.socialMedia.workExperience")}:`,
                    content: [
                        {
                            content: this.additionalWorkExp_info
                        },
                        {
                            title: this.$t('components.userSettings.startCompanyDate'),
                            date: this.additionalCompanyStartDate && moment(this.additionalCompanyStartDate).isValid()
                                ? moment(this.additionalCompanyStartDate).format('DD-MM-YYYY')
                                : '', // Deja vacío si no es una fecha válida
                        },
                        {
                            title: this.$t('components.userSettings.startDate'),
                            date: this.additionalStartDate && moment(this.additionalStartDate).isValid()
                                ? moment(this.additionalStartDate).format('DD-MM-YYYY')
                                : '', // Deja vacío si no es una fecha válida
                        }
                    ]
                },
            ]
            return data
        }
    },
};
</script>

<style scoped lang="scss">
.adobe-link {
    position: absolute;
    top: 0;
    right: 15px;
}
</style>