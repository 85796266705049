var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings-right" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { icon: "", color: "default" } }, on),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "font-awesome-icon",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "primary--text settingsIcon",
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "cog"] }
                                        },
                                        on
                                      )
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.settings")) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            [
              _c(
                "v-list-item-group",
                _vm._l(_vm.getSortedList, function(item, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      on: {
                        click: function($event) {
                          item.index === 0
                            ? _vm.setShowLanguage()
                            : item.index === 1
                            ? _vm.toggleCamMic()
                            : null
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          item.index == 0
                            ? _c("font-awesome-icon", {
                                style: { fontSize: "16px" },
                                attrs: { icon: ["fal", "language"] }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          item.index == 1
                            ? _c("img", {
                                staticClass: "camMicIcon",
                                attrs: { src: "img/icons/cam-mic-settings.svg" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [
                        _c("span", [_vm._v(_vm._s(item.name))])
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }