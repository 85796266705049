var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          model: {
            value: _vm.showFirstModal,
            callback: function($$v) {
              _vm.showFirstModal = $$v
            },
            expression: "showFirstModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "w-100 mx-0 my-0 justify-space-between",
                      attrs: { flex: "" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-0 py-0", attrs: { align: "left" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "headline d-flex" },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: "assets/icon.png",
                                  "max-height": "30",
                                  "max-width": "30",
                                  contain: ""
                                }
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass:
                                  "font-weight-medium pl-4 spanModalCall",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "components.firstModal.firstUserSetup"
                                    )
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _vm.amIAdmin
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "pl-2 mb-2" },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            small: "",
                                                            icon: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "17px"
                                                            },
                                                            attrs: {
                                                              color: "primary",
                                                              icon: [
                                                                "fal",
                                                                "info-circle"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2485088313
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.firstModal.infoA"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.firstModal.infoB"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider")
                ],
                1
              ),
              _vm._v(" "),
              this.showCam
                ? _c("camera", {
                    attrs: {
                      getSnapshot: _vm.getSnapshot,
                      closeCamera: _vm.closeCamera
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCroppie,
                      expression: "showCroppie"
                    }
                  ],
                  staticClass: "pt-5"
                },
                [
                  _c("vue-croppie", {
                    ref: "croppieRef",
                    staticClass: "sliderSize",
                    attrs: {
                      enableResize: false,
                      enforceBoundary: true,
                      enableExif: true,
                      boundary: { width: 216, height: 216 },
                      viewport: { width: 206, height: 206, type: "square" }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "rotateIcons" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "", color: "grey" },
                                        on: {
                                          click: function($event) {
                                            return _vm.rotate(-90)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "undo"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.navbar.rotateLeft"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "", color: "grey" },
                                        on: {
                                          click: function($event) {
                                            return _vm.rotate(90)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "fa-flip-horizontal",
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "undo"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.navbar.rotateRight"))
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                      attrs: { color: "primary" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "px-2 btns",
                          style: { width: "100%", margin: 0 },
                          attrs: { flex: "" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 py-0",
                              attrs: { lg: "6", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: { depressed: "", color: "white" },
                                  on: { click: _vm.crop }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("generics.save")) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "border-radius": "4px" },
                                  attrs: {
                                    color: "white",
                                    icon: "",
                                    tile: "",
                                    outlined: ""
                                  },
                                  on: { click: _vm.cancelCrop }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "times"] }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showCroppie && !this.showCam
                ? _c(
                    "v-form",
                    {
                      ref: "userForm",
                      attrs: { "lazy-validation": "", autocomplete: false },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("input", {
                        ref: "fileInput",
                        staticClass: "d-none",
                        attrs: { type: "file" },
                        on: {
                          change: function($event) {
                            return _vm.showCroppieModal($event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "pt-2",
                          style: { width: "100%", margin: 0 }
                        },
                        _vm._l(2, function(n) {
                          return _c(
                            "v-col",
                            {
                              key: n,
                              staticClass: "col-12",
                              attrs: {
                                lg: _vm.cols[n - 1],
                                md: _vm.cols[n - 1],
                                sm: _vm.cols[n - 1],
                                xl: _vm.cols[n - 1]
                              }
                            },
                            [
                              n == 1
                                ? _c("div", { attrs: { align: "center" } }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          {
                                            staticClass:
                                              "\n                    mx-0\n                    my-0\n                    buttonOverflow\n                    borderRadius4\n                    borderRadius10\n                  ",
                                            attrs: { tile: "", size: "190" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "borderRadius4",
                                                staticStyle: {
                                                  overflow: "hidden"
                                                }
                                              },
                                              [
                                                !_vm.showCam
                                                  ? _c(
                                                      "v-img",
                                                      {
                                                        staticClass:
                                                          "borderRadius10",
                                                        attrs: {
                                                          "max-height": "190",
                                                          "max-width": "190",
                                                          contain: "",
                                                          src:
                                                            _vm.state.user
                                                              .avatar,
                                                          "lazy-src":
                                                            "assets/default_profile_picture.png"
                                                        }
                                                      },
                                                      [
                                                        _vm.state.user.avatar ==
                                                        "img/default_profile_picture.png"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "\n                            imageTextWrapper\n                            px-2\n                            font-weight-bold\n                            justify-center\n                          "
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.firstModal.textWrapper"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.showCam
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "uploadAvatarButtons p-4",
                                                        class: {
                                                          hasImage:
                                                            _vm.state.user
                                                              .avatar !=
                                                            "img/default_profile_picture.png",
                                                          hasNoImage:
                                                            _vm.state.user
                                                              .avatar ==
                                                            "img/default_profile_picture.png"
                                                        },
                                                        attrs: { flex: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "mx-1",
                                                                            attrs: {
                                                                              depressed:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                              isSmall:
                                                                                "true"
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.selectAvatar
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "font-awesome-icon",
                                                                            {
                                                                              style: {
                                                                                fontSize:
                                                                                  "20px"
                                                                              },
                                                                              attrs: {
                                                                                icon: [
                                                                                  "fal",
                                                                                  "upload"
                                                                                ]
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.navbar.uploadPicture"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "mx-1",
                                                                            attrs: {
                                                                              depressed:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                              isSmall:
                                                                                "true"
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.showCamFunc
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "font-awesome-icon",
                                                                            {
                                                                              style: {
                                                                                fontSize:
                                                                                  "20px"
                                                                              },
                                                                              attrs: {
                                                                                icon: [
                                                                                  "fal",
                                                                                  "camera"
                                                                                ]
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.camera.takeSnapshot"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              n == 2 &&
                              _vm.state.user.name == "" &&
                              !_vm.state.persisted.userUploadedExcel
                                ? _c(
                                    "div",
                                    { staticClass: "pt-2 pr-4 pl-0" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0 pr-0",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "pb-0",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  label: _vm.$t(
                                                    "components.firstModal.title"
                                                  ),
                                                  rules: _vm.tileRules
                                                },
                                                model: {
                                                  value: _vm.titleName,
                                                  callback: function($$v) {
                                                    _vm.titleName = $$v
                                                  },
                                                  expression: "titleName"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "8" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  label: _vm.$t(
                                                    "components.firstModal.firstName"
                                                  ),
                                                  rules: _vm.firstNameRules,
                                                  required: ""
                                                },
                                                model: {
                                                  value: _vm.first_name,
                                                  callback: function($$v) {
                                                    _vm.first_name = $$v
                                                  },
                                                  expression: "first_name"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          label: _vm.$t(
                                            "components.firstModal.lastName"
                                          ),
                                          rules: _vm.lastNameRules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.last_name,
                                          callback: function($$v) {
                                            _vm.last_name = $$v
                                          },
                                          expression: "last_name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          label: _vm.$t(
                                            "components.firstModal.email"
                                          ),
                                          rules: _vm.emailRules,
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.errText
                        ? _c("v-row", { style: { width: "100%", margin: 0 } }, [
                            _c("div", { staticClass: "mx-auto" }, [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(_vm.errText))
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        {
                          staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                          attrs: { color: "primary" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-2 btns",
                              style: { width: "100%", margin: 0 },
                              attrs: { flex: "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 py-0",
                                  attrs: { lg: "12", align: "right" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class:
                                        "buttonIconPrimaryFooter " +
                                        (_vm.disableButton
                                          ? "buttonIconPrimaryFooterDisable"
                                          : ""),
                                      attrs: {
                                        depressed: "",
                                        color: "white",
                                        disabled: _vm.disableButton
                                      },
                                      on: { click: _vm.validate }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("generics.save")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }