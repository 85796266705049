var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPlayer && _vm.getSrc
    ? _c("v-clappr", {
        ref: "clappr",
        staticClass: "playerClass",
        class: { preventClick: !_vm.isMobile },
        attrs: {
          el: "mycustomid",
          options: _vm.playerOptions,
          source: _vm.getSrc
        },
        on: { init: _vm.onInit, error: _vm.onError }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }