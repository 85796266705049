var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "eventFromCard" },
    [
      _c(
        "v-card-title",
        {
          class: _vm.setBackground,
          style:
            "color:" +
            (_vm.setBackground !== "white" && _vm.setBackground !== null
              ? "white"
              : "unset")
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c("v-col", { staticClass: "px-1 py-1", attrs: { cols: "4" } }, [
                _vm.existingEvent && _vm.existingEvent.isConference
                  ? _c(
                      "span",
                      { staticClass: "headline largeTitle" },
                      [
                        _c("v-img", {
                          staticClass: "mr-1 logoModal",
                          attrs: {
                            src: "assets/icon.png",
                            "max-height": "30",
                            "max-width": "30",
                            contain: ""
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.existingEvent.editConference
                                ? _vm.$t("components.groups.group")
                                : _vm.$t("components.addGroupModal.addNewGroup")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "headline" },
                      [
                        _c("v-img", {
                          staticClass: "mr-1 logoModal",
                          attrs: {
                            src: "assets/icon.png",
                            "max-height": "30",
                            "max-width": "30",
                            contain: ""
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "components.meetingLineModal.videoCallPlaner"
                              )
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    )
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "px-1 py-2 mx-auto", attrs: { cols: "8" } },
                [
                  _vm.getStartDate
                    ? _c("span", { staticClass: "font16 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.getStartDate))
                      ])
                    : _c(
                        "v-menu",
                        {
                          ref: "startDateMenu",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        staticClass: "w154",
                                        attrs: {
                                          readonly: "",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          label: _vm.$t(
                                            "components.eventEntryForm.startEvent"
                                          )
                                        },
                                        model: {
                                          value: _vm.formatedStart,
                                          callback: function($$v) {
                                            _vm.formatedStart = $$v
                                          },
                                          expression: "formatedStart"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.startDateMenu,
                            callback: function($$v) {
                              _vm.startDateMenu = $$v
                            },
                            expression: "startDateMenu"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: { "no-title": "", scrollable: "" },
                            on: {
                              "click:date": function($event) {
                                return _vm.$refs.startDateMenu.save(
                                  _vm.startEvent
                                )
                              }
                            },
                            model: {
                              value: _vm.startEvent,
                              callback: function($$v) {
                                _vm.startEvent = $$v
                              },
                              expression: "startEvent"
                            }
                          })
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      ref: "startTimeMenu",
                      attrs: {
                        disabled: !_vm.canEditEvent,
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        "return-value": _vm.timeStart,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      on: {
                        "update:returnValue": function($event) {
                          _vm.timeStart = $event
                        },
                        "update:return-value": function($event) {
                          _vm.timeStart = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "buttonMinutes",
                                    class: {
                                      "mr-8": !_vm.isCreatingConference,
                                      "ml-1 mr-1": _vm.isCreatingConference
                                    },
                                    staticStyle: { height: "40px" },
                                    attrs: {
                                      disabled:
                                        !_vm.canEditEvent || !_vm.formatedStart
                                    }
                                  },
                                  on
                                ),
                                [
                                  !_vm.timeStart && !_vm.timeEnd
                                    ? _c("span", { staticClass: "font16" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.eventEntryForm.startTime"
                                            )
                                          )
                                        )
                                      ])
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font16 font-weight-bold",
                                          staticStyle: {
                                            "text-transform": "none"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.timeStart) +
                                              " - " +
                                              _vm._s(_vm.timeEnd)
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c("font-awesome-icon", {
                                    staticClass: "iconClock",
                                    attrs: { icon: ["fal", "clock"] }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.startTimeMenu,
                        callback: function($$v) {
                          _vm.startTimeMenu = $$v
                        },
                        expression: "startTimeMenu"
                      }
                    },
                    [
                      _vm._v(" "),
                      _vm.startTimeMenu
                        ? _c("v-time-picker", {
                            staticClass: "font16",
                            attrs: {
                              disabled: !_vm.canEditEvent,
                              "full-width": "",
                              format: "24hr"
                            },
                            on: {
                              "click:minute": function($event) {
                                return _vm.$refs.startTimeMenu.save(
                                  _vm.timeStart
                                )
                              }
                            },
                            model: {
                              value: _vm.timeStart,
                              callback: function($$v) {
                                _vm.timeStart = $$v
                              },
                              expression: "timeStart"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "minutesInput",
                    class: {
                      "ml-8": !_vm.isCreatingConference,
                      "ml-0": _vm.isCreatingConference
                    },
                    attrs: {
                      outlined: "",
                      dense: "",
                      type: "number",
                      min: "0",
                      step: "1",
                      label: _vm.$t("components.eventEntryForm.minutes"),
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.durationMin,
                      callback: function($$v) {
                        _vm.durationMin = $$v
                      },
                      expression: "durationMin"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.checkImSupervisor
        ? _c(
            "div",
            {
              class: { "mr-4": !_vm.editMode, "px-2": _vm.editMode },
              staticStyle: { width: "100%" }
            },
            [
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-1", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              disabled: !_vm.canEditEvent,
                              dense: "",
                              outlined: "",
                              label: _vm.$t(
                                "components.eventEntryForm.titleEvent"
                              )
                            },
                            model: {
                              value: _vm.eventName,
                              callback: function($$v) {
                                _vm.eventName = $$v
                              },
                              expression: "eventName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      1 === 3
                        ? _c(
                            "v-col",
                            { staticClass: "py-0 px-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 px-1",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: { "hide-details": "true" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "p",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mb-0 labelTitle"
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.2faModal.btnEnablePINComplex"
                                                                      )
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "generics.comingSoon"
                                                                      )
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3076649674
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.inviteGuestPanel.complexPinTT"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      4057530025
                                    ),
                                    model: {
                                      value: _vm.protectCallComplex,
                                      callback: function($$v) {
                                        _vm.protectCallComplex = $$v
                                      },
                                      expression: "protectCallComplex"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mx-0" },
                                [
                                  _vm.protectCallComplex
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 px-1",
                                          attrs: { cols: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "margin-top": "15px"
                                            },
                                            attrs: {
                                              autocomplete: "off",
                                              label: _vm.$t(
                                                "components.inviteGuestPanel.enterComplexInvitationPinCode"
                                              ),
                                              dense: "",
                                              maxlength: 12,
                                              rules: [
                                                function(v) {
                                                  return !!v || ""
                                                },
                                                function(v) {
                                                  return (
                                                    (v && v.length >= 12) || ""
                                                  )
                                                },
                                                function(v) {
                                                  return (
                                                    _vm.complexPinRegex.test(
                                                      v
                                                    ) ||
                                                    this$1.$t(
                                                      "components.inviteGuestPanel.ttComplexPinRequirements"
                                                    )
                                                  )
                                                }
                                              ]
                                            },
                                            on: {
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                              },
                                              blur: _vm.checkPin
                                            },
                                            model: {
                                              value: _vm.pinCode,
                                              callback: function($$v) {
                                                _vm.pinCode = $$v
                                              },
                                              expression: "pinCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.protectCallComplex
                                    ? _c(
                                        "v-col",
                                        { staticClass: "col-1 px-1 py-0" },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "my-3 btnPinDet",
                                                              attrs: {
                                                                icon: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                style: {
                                                                  fontSize:
                                                                    "15px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "question-circle"
                                                                  ]
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                863388870
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _vm.protectCallComplex
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.inviteGuestPanel.ttComplexPinRequirements"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.protectCallComplex
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "col-3 px-1 py-0",
                                          staticStyle: {
                                            height: "30px",
                                            "margin-top": "14px"
                                          }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "mt-0",
                                            attrs: { "hide-details": "true" },
                                            on: {
                                              change: function($event) {
                                                return _vm.generateComplxPin()
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "p",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 labelTitle"
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.2fa.generatePin"
                                                                              )
                                                                            ) +
                                                                            "\n                      "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            1460940767
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.2fa.generatePin"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              2722451433
                                            ),
                                            model: {
                                              value: _vm.pinGenerated,
                                              callback: function($$v) {
                                                _vm.pinGenerated = $$v
                                              },
                                              expression: "pinGenerated"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-1", attrs: { cols: "12" } },
                        [
                          _c("v-textarea", {
                            staticClass: "mt-3 textAreaDetails",
                            attrs: {
                              disabled: !_vm.canEditEvent,
                              "no-resize": "",
                              "hide-details": "",
                              outlined: "",
                              rows: this.detailsRow,
                              label: _vm.$t(
                                "components.eventEntryForm.detailEvents"
                              )
                            },
                            model: {
                              value: _vm.eventDetail,
                              callback: function($$v) {
                                _vm.eventDetail = $$v
                              },
                              expression: "eventDetail"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0 px-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-1",
                              staticStyle: {
                                margin: "0 auto",
                                "text-align": "center",
                                "font-weight": "bold"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.inviteVisitor.titleInvitationMenu"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0 bgBlack" },
                    [
                      _vm.uuidToRepresentStore
                        ? _c("v-col", {
                            staticClass: "px-1 px-1",
                            attrs: { cols: "3" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "userColumn px-1 px-1",
                          attrs: { cols: "3" }
                        },
                        [
                          _c("v-checkbox", {
                            staticClass:
                              "mencheckbox departments cbNoteHead anfitrionCheckbox mt-0",
                            attrs: {
                              "input-value":
                                _vm.selectedAnfitrion === _vm.state.ownUUID
                            },
                            on: {
                              click: function($event) {
                                return _vm.setAnfitrion(_vm.state.ownUUID)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("UserDisplay", {
                            attrs: { userUUID: _vm.state.ownUUID }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.uuidToRepresentStore
                        ? _c(
                            "v-col",
                            {
                              staticClass: "userColumn px-1 px-1",
                              attrs: { cols: "3" }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass:
                                  "mencheckbox departments cbNoteHead anfitrionCheckbox mt-0",
                                attrs: {
                                  "input-value":
                                    _vm.selectedAnfitrion === _vm.actingAsUuid
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setAnfitrion(_vm.actingAsUuid)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("UserDisplay", {
                                attrs: { userUUID: _vm.actingAsUuid }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uuidToRepresentStore
                        ? _c("v-col", {
                            staticClass: "px-1 px-1",
                            attrs: { cols: "3" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0 px-1 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "mb-1 text-center",
                              staticStyle: { "font-weight": "bold" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.eventEntryForm.participants"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-1 px-1",
                          attrs: { cols: "3" },
                          on: {
                            click: function($event) {
                              _vm.activeIndex = 0
                            }
                          }
                        },
                        [
                          _c("UserDisplay", {
                            attrs: {
                              isEditMode: true,
                              disabled: _vm.isDisabled0,
                              userUUID:
                                _vm.allSelectedUsers.length > 0 &&
                                _vm.allSelectedUsers[0]
                                  ? _vm.allSelectedUsers[0].uuid
                                  : null,
                              newEvent: true,
                              uuid: _vm.allSelectedUsers[0]
                                ? _vm.allSelectedUsers[0].uuid
                                : null,
                              handlerShowUsers: _vm.handlerShowUsers,
                              userHasRejected: _vm.userHasRejectedEvent(
                                _vm.allSelectedUsers[0]
                                  ? _vm.allSelectedUsers[0].uuid
                                  : null
                              ),
                              clearParticipant: _vm.clearParticipant,
                              activeIndex: 0
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "14px" } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-1 px-1",
                          attrs: { cols: "3" },
                          on: {
                            click: function($event) {
                              _vm.activeIndex = 1
                            }
                          }
                        },
                        [
                          _c("UserDisplay", {
                            attrs: {
                              isEditMode: true,
                              disabled: _vm.isDisabled1,
                              userUUID:
                                _vm.allSelectedUsers.length > 0 &&
                                _vm.allSelectedUsers[1]
                                  ? _vm.allSelectedUsers[1].uuid
                                  : null,
                              newEvent: true,
                              uuid: _vm.allSelectedUsers[1]
                                ? _vm.allSelectedUsers[1].uuid
                                : null,
                              handlerShowUsers: _vm.handlerShowUsers,
                              userHasRejected: _vm.userHasRejectedEvent(
                                _vm.allSelectedUsers[1]
                                  ? _vm.allSelectedUsers[1].uuid
                                  : null
                              ),
                              clearParticipant: _vm.clearParticipant,
                              activeIndex: 1
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "14px" } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-1 px-1",
                          attrs: { cols: "3" },
                          on: {
                            click: function($event) {
                              _vm.activeIndex = 2
                            }
                          }
                        },
                        [
                          _c("UserDisplay", {
                            attrs: {
                              isEditMode: true,
                              disabled: _vm.isDisabled2,
                              userUUID:
                                _vm.allSelectedUsers.length > 0 &&
                                _vm.allSelectedUsers[2]
                                  ? _vm.allSelectedUsers[2].uuid
                                  : null,
                              newEvent: true,
                              uuid: _vm.allSelectedUsers[2]
                                ? _vm.allSelectedUsers[2].uuid
                                : null,
                              handlerShowUsers: _vm.handlerShowUsers,
                              userHasRejected: _vm.userHasRejectedEvent(
                                _vm.allSelectedUsers[2]
                                  ? _vm.allSelectedUsers[2].uuid
                                  : null
                              ),
                              clearParticipant: _vm.clearParticipant,
                              activeIndex: 2
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "14px" } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-1 px-1",
                          attrs: { cols: "3" },
                          on: {
                            click: function($event) {
                              _vm.activeIndex = 3
                            }
                          }
                        },
                        [
                          _c("UserDisplay", {
                            attrs: {
                              isEditMode: true,
                              disabled: _vm.isDisabled3,
                              userUUID:
                                _vm.allSelectedUsers.length > 0 &&
                                _vm.allSelectedUsers[3]
                                  ? _vm.allSelectedUsers[3].uuid
                                  : null,
                              newEvent: true,
                              uuid: _vm.allSelectedUsers[3]
                                ? _vm.allSelectedUsers[3].uuid
                                : null,
                              handlerShowUsers: _vm.handlerShowUsers,
                              userHasRejected: _vm.userHasRejectedEvent(
                                _vm.allSelectedUsers[3]
                                  ? _vm.allSelectedUsers[3].uuid
                                  : null
                              ),
                              clearParticipant: _vm.clearParticipant,
                              activeIndex: 3
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "14px" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.hideButtons
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-3",
                              attrs: {
                                disabled: !_vm.canDeleteEvent,
                                small: "",
                                color: "error"
                              },
                              on: { click: _vm.deleteEvent }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.eventEntryForm.delete")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-3",
                              attrs: {
                                disabled: !_vm.canEditEvent,
                                small: "",
                                color: "error"
                              },
                              on: { click: _vm.resetInputEvent }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.eventEntryForm.reset")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.canAddEvent,
                                small: "",
                                color: "primary"
                              },
                              on: { click: _vm.saveEventData }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.eventEntryForm.delete")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "650" },
          model: {
            value: _vm.showUsers,
            callback: function($$v) {
              _vm.showUsers = $$v
            },
            expression: "showUsers"
          }
        },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tabSelected,
                callback: function($$v) {
                  _vm.tabSelected = $$v
                },
                expression: "tabSelected"
              }
            },
            [
              _c("v-img", {
                staticClass: "mx-2 logoModal top11",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(" "),
              _c("v-tab", { key: "users" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.inviteVisitor.participants")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-tab", { key: "invitees" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("components.inviteVisitor.participantsGuests")
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tabSelected,
                callback: function($$v) {
                  _vm.tabSelected = $$v
                },
                expression: "tabSelected"
              }
            },
            [
              _c(
                "v-tab-item",
                { key: "users" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-combobox", {
                                staticClass: "mt-3",
                                attrs: {
                                  items: _vm.dataCombobox.users,
                                  "search-input": _vm.searchUsers,
                                  "item-disabled": _vm.disableItem,
                                  select: "",
                                  "item-text": "name",
                                  "item-value": "uuid",
                                  label: _vm.$t(
                                    "components.meetingLineModal.participants"
                                  ),
                                  dense: "",
                                  outlined: "",
                                  disabled: !_vm.canEditEvent,
                                  "hide-details": "",
                                  autofocus: ""
                                },
                                on: {
                                  "update:searchInput": function($event) {
                                    _vm.searchUsers = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.searchUsers = $event
                                  },
                                  change: _vm.handleChangeCombo
                                },
                                model: {
                                  value: _vm.selectedUser,
                                  callback: function($$v) {
                                    _vm.selectedUser = $$v
                                  },
                                  expression: "selectedUser"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { key: "invitees" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("DirectCallMask", {
                                ref: "directCallMaskRef",
                                attrs: {
                                  disableItem: _vm.disableItem,
                                  allSelectedUsers: _vm.allSelectedUsers,
                                  linkDateFrom: _vm.formattedStartDate,
                                  linkTimeFrom: _vm.timeStart,
                                  linkDateTo: _vm.formattedStartDate,
                                  linkTimeTo: _vm.timeEnd,
                                  durationMin: _vm.durationMin,
                                  subject: _vm.eventName,
                                  setNoticeText: _vm.setNoticeText
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns w-100 mx-0 my-0" },
                [
                  [
                    _vm.tabSelected == 1 && _vm.directCallMaskRef
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              loading:
                                                _vm.directCallMaskRef
                                                  .isProcessing,
                                              disabled: !_vm.disabledSaveInviteesButton
                                            },
                                            on: {
                                              click: _vm.precheckNewVisitor
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.sendMessageModal.send"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3784638019
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.sendMessageModal.send")
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", color: "white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancel()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "30px" },
                                      attrs: { icon: ["fal", "times"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }