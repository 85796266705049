var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "callContainer",
      class: {
        "d-none": _vm.hideVideoContainer,
        maximizeScreen: _vm.maximizeScreen,
        maximizeScreenMobile: _vm.isMobile
      }
    },
    [
      _c(
        "div",
        {
          class: _vm.localFullScreen
            ? "containerWrapperFullScreen"
            : "containerWrapper"
        },
        [
          _vm.hasPrivilege(_vm.state.ownUUID)
            ? _c("SidebarCall", {
                attrs: {
                  maximizeScreen: true,
                  isConferenceCall: true,
                  getAmIModerator: false
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.chatOpen || _vm.userListOpen
            ? _c(
                "div",
                { staticClass: "containerChat" },
                [
                  _vm.chatOpen
                    ? _c("JitsiChat", {
                        attrs: {
                          toggleChat: _vm.toggleChat,
                          chatMessages: _vm.chatMessages,
                          chatOpen: _vm.chatOpen,
                          chatMessageNotificationSound:
                            _vm.chatMessageNotificationSound,
                          toggleChatMessageNotificationSound:
                            _vm.toggleChatMessageNotificationSound
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.userListOpen
                    ? _c("UserInCallList", {
                        attrs: {
                          userListOpen: _vm.userListOpen,
                          toggleUserList: _vm.toggleUserList,
                          isListenerView: true
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class:
                _vm.chatOpen || _vm.userListOpen
                  ? "containerIframe"
                  : "containerIframeFull"
            },
            [
              _c(
                "div",
                {
                  ref: "iframe",
                  staticClass: "iframe",
                  class: { preventClick: !_vm.isMobile },
                  attrs: { id: "iframe" }
                },
                [
                  _vm.getYoutubeVideoId
                    ? _c("CallIframe", {
                        attrs: {
                          setYoutubePlayer: _vm.setYoutubePlayer,
                          youTubeVideoId: _vm.getYoutubeVideoId
                        }
                      })
                    : _c("RecorderIframe", {
                        attrs: { setVideoClappr: _vm.setVideoClappr }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "listener" },
                [
                  _c("ListenerView", {
                    attrs: {
                      chatOpen: _vm.chatOpen,
                      getAmIModerator: false,
                      isListenerView: true
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.localFullScreen
            ? "containerLocalButtonsFullScreen"
            : "containerLocalButtons"
        },
        [
          _c("CallListenerLocalButtons", {
            attrs: {
              toggleRequestToSpeakConferenceHall:
                _vm.toggleRequestToSpeakConferenceHall,
              requestedRaiseHand: _vm.requestedRaiseHand,
              fullScreenActive: _vm.fullScreenActive,
              toggleLocalFullScreen: _vm.toggleLocalFullScreen,
              toggleOpenChat: _vm.toggleChat,
              toggleVideoMute: _vm.toggleVideoMute,
              toggleVideoClapprPlayerMute: _vm.toggleVideoClapprPlayerMute,
              getTotalReceivedChatMessages: _vm.getTotalReceivedChatMessages,
              chatOpen: _vm.chatOpen,
              chatMessageNotificationSound: _vm.chatMessageNotificationSound,
              toggleUserList: _vm.toggleUserList,
              videoReady: !_vm.isPkg ? _vm.videoReady : true
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }