var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visiblePrompt
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "560" },
          model: {
            value: _vm.showModalAudioPolicy,
            callback: function($$v) {
              _vm.showModalAudioPolicy = $$v
            },
            expression: "showModalAudioPolicy"
          }
        },
        [
          [
            _c(
              "v-card",
              { staticClass: "mx-auto" },
              [
                _c(
                  "v-card-title",
                  { staticClass: "headline", attrs: { "primary-title": "" } },
                  [
                    _c("v-img", {
                      staticClass: "mr-1",
                      attrs: {
                        src: "assets/icon.png",
                        "max-height": "30",
                        "max-width": "30",
                        contain: ""
                      }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("components.audioPolicy.title")) +
                        "\n      "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-card-text", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.audioPolicy.text")) +
                      "\n        "
                  ),
                  _c("div", { staticClass: "mt-8 d-flex justify-center" }, [
                    _c(
                      "div",
                      { staticClass: "mx-3 pointer" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            on: {
                              click: function($event) {
                                return _vm.closeModal()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("generics.ok")))]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            )
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }