var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.modalProducts.title"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            !_vm.newProduct && !_vm.productToEdit
              ? _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("p", { staticClass: "body-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.modalProducts.titleBody")
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.getProducts.length > 0
                      ? _c(
                          "v-row",
                          { staticClass: "mx-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 py-0",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.modalProducts.productName"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 py-0",
                                        attrs: { cols: "3" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.modalProducts.productHour"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("v-col", {
                                      staticClass: "px-0 py-0",
                                      attrs: { cols: "3" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.getProducts, function(product) {
                              return _c(
                                "v-col",
                                {
                                  key: product.productId,
                                  staticClass: "py-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 py-0",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(product.productName) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 py-0",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(product.productPrice) +
                                              "\n                "
                                          ),
                                          _vm.state.namespaceSettings
                                            .currency === "EUR"
                                            ? _c("font-awesome-icon", {
                                                style: { fontSize: "15px" },
                                                attrs: {
                                                  icon: ["fal", "euro-sign"]
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.state.namespaceSettings
                                            .currency === "GBP"
                                            ? _c("font-awesome-icon", {
                                                style: { fontSize: "15px" },
                                                attrs: {
                                                  icon: ["far", "pound-sign"]
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.state.namespaceSettings
                                            .currency === "CHF"
                                            ? _c("v-img", {
                                                attrs: {
                                                  contain: "",
                                                  height: "20",
                                                  src:
                                                    "img/paymentIcons/swiss-franc-black.png",
                                                  "lazy-src":
                                                    "img/paymentIcons/swiss-franc.png"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 py-0 text-center",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            style: {
                                              fontSize: "15px",
                                              cursor: "pointer"
                                            },
                                            attrs: { icon: ["fal", "pen"] },
                                            on: {
                                              click: function($event) {
                                                return _vm.setProductToEdit(
                                                  product
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("DeleteBasket", {
                                            attrs: {
                                              indexData: product,
                                              delFunction: _vm.deleteProduct,
                                              extraClasses:
                                                "cursorPointer ml-3",
                                              fontSize: "15",
                                              prevent: "true"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-right", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.showNewProduct }
                              },
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { icon: ["fal", "plus"] }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "pl-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.modalProducts.addNewProducts"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.newProduct || _vm.productToEdit
              ? _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                maxLength: "15",
                                label: _vm.$t(
                                  "components.modalProducts.productName"
                                )
                              },
                              model: {
                                value: _vm.productName,
                                callback: function($$v) {
                                  _vm.productName = $$v
                                },
                                expression: "productName"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                outlined: "",
                                dense: "",
                                type: "number",
                                max: "10000",
                                rules: _vm.amountRules,
                                label: _vm.$t(
                                  "components.modalProducts.productHour"
                                )
                              },
                              on: {
                                change: _vm.checkAmountValue,
                                keyup: _vm.checkAmountValue
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _vm.state.namespaceSettings.currency ===
                                        "EUR"
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "15px" },
                                              attrs: {
                                                icon: ["fal", "euro-sign"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.state.namespaceSettings.currency ===
                                        "GBP"
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "15px" },
                                              attrs: {
                                                icon: ["far", "pound-sign"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.state.namespaceSettings.currency ===
                                        "CHF"
                                          ? _c("v-img", {
                                              attrs: {
                                                contain: "",
                                                height: "20",
                                                src:
                                                  "img/paymentIcons/swiss-franc-black.png",
                                                "lazy-src":
                                                  "img/paymentIcons/swiss-franc.png"
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3337046141
                              ),
                              model: {
                                value: _vm.pricePerHour,
                                callback: function($$v) {
                                  _vm.pricePerHour = $$v
                                },
                                expression: "pricePerHour"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-right", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  disabled: !_vm.checkNewProduct
                                },
                                on: {
                                  click: function($event) {
                                    !_vm.productToEdit
                                      ? _vm.saveNewProduct()
                                      : _vm.editProduct()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("generics.save")) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { color: "primary" },
                                on: { click: _vm.showNewProduct }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("generics.cancel")) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }