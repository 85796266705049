var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "incomingCallModalTest",
          attrs: {
            fullscreen: _vm.isMobile,
            persistent: "",
            "max-width": "700"
          },
          model: {
            value: _vm.haveCalls,
            callback: function($$v) {
              _vm.haveCalls = $$v
            },
            expression: "haveCalls"
          }
        },
        [
          _c("audio", {
            ref: "audioSource",
            attrs: {
              id: "audioSource",
              src: _vm.playingRingTone,
              autoplay: "",
              loop: ""
            }
          }),
          _vm._v(" "),
          _vm.shouldPlayRinging
            ? _c("audio", {
                ref: "ringingSource",
                attrs: {
                  id: "ringingSource",
                  src: _vm.playingRingTone,
                  autoplay: "",
                  loop: ""
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.incomingCallsArray, function(call) {
            return _c("IncomingBridgeCallSub", {
              key: call.uuid,
              attrs: { tabindex: "0", call: call }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          staticClass: "incomingCallModalTest",
          attrs: {
            fullscreen: _vm.isMobile,
            persistent: "",
            "max-width": "700"
          },
          model: {
            value: _vm.haveConferenceCalls,
            callback: function($$v) {
              _vm.haveConferenceCalls = $$v
            },
            expression: "haveConferenceCalls"
          }
        },
        [
          _c("audio", {
            ref: "audioSource",
            attrs: {
              id: "audioSource",
              src: _vm.playingConfRingTone,
              autoplay: "",
              loop: ""
            }
          }),
          _vm._v(" "),
          _vm.shouldPlayRinging
            ? _c("audio", {
                ref: "ringingSource",
                attrs: {
                  id: "ringingSource",
                  src: _vm.playingConfRingTone,
                  autoplay: "",
                  loop: ""
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.incommingConfCallsArray, function(call) {
            return _c("ConferenceIncomingBridgeCallSub", {
              key: call.uuid,
              attrs: { tabindex: "0", call: call }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }