<template>
  <v-dialog persistent v-model="showModal" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ $t("components.workingTime.template") }}</span
        >
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="templateName"
                :label="$t('components.workingTime.template')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("components.workingTime.day") }}
                    </th>
                    <th class="text-left">
                      {{ $t("components.workingTime.workTime") }}
                    </th>
                    <th class="text-left">
                      {{ $t("components.workingTime.pause") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.monday") }}</td>
                    <td>
                      <v-text-field
                        v-model="mondayWork"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="mondayPause"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.tuesday") }}</td>
                    <td>
                      <v-text-field
                        v-model="tuesdayWork"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="tuesdayPause"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.wednesday") }}</td>
                    <td>
                      <v-text-field
                        v-model="wednesdayWork"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="wednesdayPause"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.thursday") }}</td>
                    <td>
                      <v-text-field
                        v-model="thursdayWork"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="thursdayPause"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.friday") }}</td>
                    <td>
                      <v-text-field
                        v-model="fridayWork"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="fridayPause"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.saturday") }}</td>
                    <td>
                      <v-text-field
                        v-model="saturdayWork"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="saturdayPause"
                        dense
                        type="time"
                        class="timePicker"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td>{{ $t("components.workingTime.sunday") }}</td>
                    <td>
                      <v-text-field
                        v-model="sundayWork"
                        dense
                        class="timePicker"
                        type="time"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model="sundayPause"
                        class="timePicker"
                        dense
                        type="time"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-container>
      </v-card-text>
      <FooterModal :closeModalFunction="close">
        <div class="mr-2 mt-2">
          <DeleteBasket
            v-if="editTemplate"
            color="white"
            :indexData="[templateId, true]"
            :delFunction="removeTemplate"
            fontSize="20"
            prevent="false"
          />
        </div>
        <v-btn color="primary" @click="save">{{ $t("generics.save") }}</v-btn>
      </FooterModal>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <DeleteBasket
          v-if="editTemplate"
          :indexData="[templateId, true]"
          :delFunction="removeTemplate"
          extraClasses=""
          fontSize="20"
          prevent="false"
        />
        <v-btn color="blue darken-1" text @click="close">
          {{ $t("generics.cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
          :disabled="!templateName.length"
        >
          {{ $t("generics.save") }}
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>


<script>
import store from "../../store";
import { v4 as uuidv4 } from "uuid";
import { setNamespaceSetting } from "../../lib/wsMsg";
import DeleteBasket from "../ui/deleteBasket.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  data() {
    return {
      state: store.state,
      templateName: "",
      templateId: undefined,
      mondayWork: "00:00",
      mondayPause: "00:00",
      tuesdayWork: "00:00",
      tuesdayPause: "00:00",
      wednesdayWork: "00:00",
      wednesdayPause: "00:00",
      thursdayWork: "00:00",
      thursdayPause: "00:00",
      fridayWork: "00:00",
      fridayPause: "00:00",
      saturdayWork: "00:00",
      saturdayPause: "00:00",
      sundayWork: "00:00",
      sundayPause: "00:00",
    };
  },
  props: ["showModal", "closeModal", "editTemplate"],
  components: {
    DeleteBasket,
    FooterModal,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.editTemplate) return false;
      const template = JSON.parse(JSON.stringify( this.editTemplate));
      this.templateName = template.name || "";
      this.templateId = template._id;
      const sortData = template.data;
      sortData.push(sortData.shift());
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      days.push(days.shift());
      sortData.forEach((dataDay, index) => {
        this[`${days[index].toLowerCase()}Work`] = this.toHoursAndMinutes(
          dataDay.work
        );
        this[`${days[index].toLowerCase()}Pause`] = this.toHoursAndMinutes(
          dataDay.pause
        );
      });
    },
    prepareDate() {
      const obj = {
        name: this.templateName,
        _id: this.templateId || uuidv4(),
        data: [
          {
            work: this.toMinutes(this.sundayWork),
            pause: this.toMinutes(this.sundayPause),
          },
          {
            work: this.toMinutes(this.mondayWork),
            pause: this.toMinutes(this.mondayPause),
          },
          {
            work: this.toMinutes(this.tuesdayWork),
            pause: this.toMinutes(this.tuesdayPause),
          },
          {
            work: this.toMinutes(this.wednesdayWork),
            pause: this.toMinutes(this.wednesdayPause),
          },
          {
            work: this.toMinutes(this.thursdayWork),
            pause: this.toMinutes(this.thursdayPause),
          },
          {
            work: this.toMinutes(this.fridayWork),
            pause: this.toMinutes(this.fridayPause),
          },
          {
            work: this.toMinutes(this.saturdayWork),
            pause: this.toMinutes(this.saturdayPause),
          },
        ],
      };
      return obj;
    },
    removeTemplate() {
      const dataToSend = this.prepareDate();
      store.setWeeklyTimecountingTemplates(dataToSend, "delete");
      setNamespaceSetting(
        "weeklyTimecountingTemplates",
        this.state.namespaceSettings.weeklyTimecountingTemplates
      );
      this.closeModal(false);
    },
    save() {
      const dataToSend = this.prepareDate();
      if (this.editTemplate) {
        store.setWeeklyTimecountingTemplates(dataToSend, "update");
      } else {
        store.setWeeklyTimecountingTemplates(dataToSend, "add");
      }
      setNamespaceSetting(
        "weeklyTimecountingTemplates",
        this.state.namespaceSettings.weeklyTimecountingTemplates
      );
      this.closeModal(false);
    },
    close() {
      this.closeModal(false);
    },
    toMinutes(hhmm) {
      const hoursDotMinutes = hhmm;
      const fieldArray = hoursDotMinutes.split(":");
      const minutes = 60 * parseInt(fieldArray[0]) + parseInt(fieldArray[1]);
      return minutes;
    },
    toHoursAndMinutes(totalMinutes) {
      const minutes = totalMinutes % 60;
      const hours = Math.floor(totalMinutes / 60);
      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:00`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
</style>
