var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "d-none": _vm.hideVideoContainer,
        maximizeScreen: true,
        maximizeScreenMobile: _vm.isMobile
      },
      staticStyle: {}
    },
    [
      _c(
        "div",
        {
          staticClass: "sidebarCallWrapper",
          class: {
            sidebarCallWrapper: !_vm.showTimeLine,
            sidebarCallWrapperTimeline: _vm.showTimeLine
          }
        },
        [
          _vm.maximizeScreen && _vm.hasPrivilege(this.ownUUID)
            ? _c("SidebarCall", {
                attrs: {
                  maximizeScreen: _vm.maximizeScreen,
                  isConferenceCall: _vm.isConferenceCall,
                  getAmIModerator: true
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w100 progressLinearBridge" },
        [
          _vm.showTimeLine
            ? _c("ProgressLinearBridge", {
                attrs: {
                  amIModerator: true,
                  updateTimeForUser: _vm.updateTimeForUser,
                  setCallDuration: _vm.setCallDuration,
                  hangUpBridgeCall: _vm.hangUpBridgeCall,
                  redirectToStartView: _vm.redirectToStartView,
                  isConferenceCall: _vm.isConferenceCall,
                  rejectCall: _vm.rejectCall
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.amICalling.length
        ? _c(
            "div",
            { staticClass: "callingToaster" },
            _vm._l(_vm.amICalling, function(participant) {
              return _c(
                "v-alert",
                { key: participant, attrs: { prominent: "", color: "white" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "waveCallingBridge" },
                          _vm._l(3, function(index) {
                            return _c("div", { key: index, staticClass: "dot" })
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ml-2",
                            staticStyle: { color: "black" }
                          },
                          [
                            _vm._v(
                              "Calling " +
                                _vm._s(_vm.getNameForUuid(participant))
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.hangUpCalling(participant)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "faPhoneRotate",
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "phone"] }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            heightNoTimeline: !_vm.showTimeLine,
            heightTimeline: _vm.showTimeLine,
            callContainerNoSidebar: !_vm.hasPrivilege(_vm.ownUUID),
            callContainerSidebar: _vm.hasPrivilege(_vm.ownUUID)
          },
          staticStyle: { background: "black" }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shareScreenEnabled,
                  expression: "shareScreenEnabled"
                }
              ],
              staticClass: "top-left resizable-container",
              style: _vm.topLeftStyle
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shareScreenEnabled,
                      expression: "shareScreenEnabled"
                    }
                  ],
                  staticClass: "screen-share-container"
                },
                [
                  _c("div", { staticClass: "screen-share-item" }, [
                    _c("div", { staticClass: "container-div-hover" }, [
                      _c("div", { staticClass: "name-on-hover" }, [
                        _c("span", { staticClass: "span-hover" }, [
                          _vm._v(
                            _vm._s(_vm.getNameForUuid(_vm.shareScreenEnabled)) +
                              " Screenshare"
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "containerButtonsOverlay" }, [
                      _c("div", { staticClass: "divButtonsOverlay" }, [
                        _c(
                          "button",
                          {
                            staticClass: "buttonOverlay",
                            on: {
                              click: function($event) {
                                return _vm.toggleFullScreen("shareScreenVideo")
                              }
                            }
                          },
                          [
                            !_vm.isFullScreenEnabled
                              ? _c("font-awesome-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { icon: ["fad", "expand"] }
                                })
                              : _c("font-awesome-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { icon: ["fad", "compress"] }
                                })
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("video", {
                      ref: "ownScreenShareVideoRef",
                      attrs: {
                        id: "shareScreenVideo",
                        autoplay: "",
                        playsinline: ""
                      },
                      domProps: { muted: false }
                    })
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "top-right resizable-container",
              class: { camerasFullWidth: !_vm.shareScreenEnabled },
              style: _vm.topRightStyle
            },
            [
              _c("div", { staticClass: "webcamsVideoContainer" }, [
                _c(
                  "div",
                  {
                    staticClass: "webcams-flex-container",
                    class: _vm.getClassForGrid
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-item ownVideoContainer",
                        attrs: { id: "localVideoContainer" }
                      },
                      [
                        _c("div", { staticClass: "container-div-hover" }, [
                          _c("div", { staticClass: "name-on-hover" }, [
                            _c("span", { staticClass: "span-hover" }, [
                              _vm._v(_vm._s(_vm.getNameForUuid(_vm.ownUUID)))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "containerOwnMicrophone" }, [
                          _c("div", { staticClass: "divButtonsOverlay" }, [
                            _c(
                              "button",
                              { staticClass: "buttonOverlay" },
                              [
                                _vm.microphoneEnabled
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "18px" },
                                      attrs: { icon: ["fas", "microphone"] }
                                    })
                                  : _c("font-awesome-icon", {
                                      style: { fontSize: "18px" },
                                      attrs: {
                                        icon: ["fas", "microphone-slash"]
                                      }
                                    })
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "containerButtonsOverlay containerOwnButtonsOverlay"
                          },
                          [
                            _c("div", { staticClass: "divButtonsOverlay" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "buttonOverlay",
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleFullScreen()
                                    }
                                  }
                                },
                                [
                                  !_vm.isFullScreenEnabled
                                    ? _c("font-awesome-icon", {
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fad", "expand"] }
                                      })
                                    : _c("font-awesome-icon", {
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fad", "compress"] }
                                      })
                                ],
                                1
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.videoEnabled
                          ? _c("div", { staticClass: "lettersBackground" }, [
                              _c("div", { staticClass: "lettersOverlay" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "lettersCircle",
                                    style: {
                                      backgroundColor: _vm.getRandomColor(0)
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.getInitialsFromParticipant(
                                            _vm.getNameForUuid(_vm.ownUUID)
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("video", {
                          key: "localVideo",
                          ref: "localVideo",
                          attrs: {
                            muted: "",
                            id: "localVideo",
                            autoplay: "",
                            playsinline: ""
                          },
                          domProps: { muted: true }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.remoteParticipants, function(
                      participant,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: participant.id,
                          staticClass: "flex-item somebodyVideoContainer"
                        },
                        [
                          _c("div", { staticClass: "container-div-hover" }, [
                            _c("div", { staticClass: "name-on-hover" }, [
                              _c("span", { staticClass: "span-hover" }, [
                                _vm._v(
                                  _vm._s(_vm.getParticipantName(participant.id))
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "containerButtonsOverlay" },
                            [
                              _c("div", { staticClass: "divButtonsOverlay" }, [
                                _c(
                                  "button",
                                  { staticClass: "buttonOverlay" },
                                  [
                                    !participant.isAudioMuted
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "18px" },
                                          attrs: { icon: ["fas", "microphone"] }
                                        })
                                      : _c("font-awesome-icon", {
                                          style: { fontSize: "18px" },
                                          attrs: {
                                            icon: ["fas", "microphone-slash"]
                                          }
                                        })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "buttonOverlay",
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleFullScreen(participant)
                                      }
                                    }
                                  },
                                  [
                                    !_vm.isFullScreenEnabled
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "16px" },
                                          attrs: { icon: ["fad", "expand"] }
                                        })
                                      : _c("font-awesome-icon", {
                                          style: { fontSize: "16px" },
                                          attrs: { icon: ["fad", "compress"] }
                                        })
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          participant.isVideoMuted
                            ? _c("div", { staticClass: "lettersBackground" }, [
                                _c("div", { staticClass: "lettersOverlay" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "lettersCircle",
                                      style: {
                                        backgroundColor: _vm.getRandomColor(
                                          index + 1
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.getInitialsFromParticipant(
                                              _vm.getParticipantName(
                                                participant.id
                                              )
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("video", {
                            key: "remoteVideo-" + participant.id,
                            ref: "remoteVideo-" + participant.id,
                            refInFor: true,
                            attrs: {
                              id: "remoteVideo-" + participant.id,
                              autoplay: "",
                              playsinline: ""
                            },
                            domProps: { muted: true }
                          }),
                          _vm._v(" "),
                          _c("audio", {
                            key: "remoteAudio-" + participant.id,
                            ref: "remoteAudio-" + participant.id,
                            refInFor: true,
                            staticClass: "remoteAudio",
                            attrs: {
                              muted:
                                participant.isAudioMuted ||
                                _vm.audioOutputMuted,
                              id: "remoteAudio-" + participant.id,
                              autoplay: "",
                              playsinline: ""
                            }
                          })
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.shareScreenEnabled
            ? _c(
                "div",
                {
                  staticClass: "left-right divider",
                  style: _vm.lrDividerStyles,
                  attrs: { draggable: "true" },
                  on: {
                    dragstart: _vm.dividerDragStart,
                    drag: _vm.lrDividerDrag
                  }
                },
                [_c("div", { staticClass: "resize-pill" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "topBarCall" }),
          _vm._v(" "),
          _c("div", { staticClass: "btnBar" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-6",
                    attrs: { large: "", icon: "", color: "white" },
                    on: { click: _vm.toggleVideoBtn }
                  },
                  [
                    _vm.videoEnabled
                      ? _c("font-awesome-icon", {
                          style: { fontSize: "18px" },
                          attrs: { icon: ["fas", "video"] }
                        })
                      : _c("font-awesome-icon", {
                          style: { fontSize: "18px", color: "red" },
                          attrs: { icon: ["fas", "video-slash"] }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-6",
                    attrs: { large: "", icon: "", color: "white" },
                    on: { click: _vm.toggleMicBtn }
                  },
                  [
                    _vm.microphoneEnabled
                      ? _c("font-awesome-icon", {
                          style: { fontSize: "18px" },
                          attrs: { icon: ["fas", "microphone"] }
                        })
                      : _c("font-awesome-icon", {
                          style: { fontSize: "18px", color: "red" },
                          attrs: { icon: ["fas", "microphone-slash"] }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-6",
                    attrs: { large: "", icon: "", color: "white" },
                    on: { click: _vm.toggleShareScreenBtn }
                  },
                  [
                    _vm.shareScreenEnabled
                      ? _c("img", {
                          staticClass: "customIcons",
                          attrs: { src: "/img/share-screen-blue.svg" }
                        })
                      : _c("img", {
                          staticClass: "customIcons",
                          attrs: { src: "/img/share-screen.svg" }
                        })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-6",
                    attrs: { large: "", icon: "", color: "white" },
                    on: { click: _vm.toggleMuteAudio }
                  },
                  [
                    !_vm.audioOutputMuted
                      ? _c("font-awesome-icon", {
                          style: { fontSize: "18px" },
                          attrs: { icon: ["fas", "volume"] }
                        })
                      : _c("font-awesome-icon", {
                          style: { fontSize: "18px", color: "red" },
                          attrs: { icon: ["fas", "volume-slash"] }
                        })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { large: "", icon: "", color: "white" },
                    on: {
                      click: function($event) {
                        return _vm.rejectCall()
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      style: {
                        fontSize: "18px",
                        color: "red",
                        transform: "rotate(225deg)"
                      },
                      attrs: { icon: ["fas", "phone"] }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.showFinishCallModal
        ? _c("FinishCallModal", {
            attrs: {
              showFinishCallModal: _vm.showFinishCallModal,
              closeModal: _vm.showCloseFinishCallModal,
              rejectCall: _vm.rejectCall
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }