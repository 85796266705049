var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "350" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.breakStatusModal,
        callback: function($$v) {
          _vm.breakStatusModal = $$v
        },
        expression: "breakStatusModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("status.Break"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "mx-auto py-2" },
            [
              _c(
                "v-row",
                { staticClass: "mx-auto", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "endTimeMenu",
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-left": 130,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        staticClass: "timePicker",
                                        attrs: {
                                          id: "clockInputBreakStatusModal",
                                          type: "time",
                                          dense: "",
                                          "hide-details": "",
                                          outlined: "",
                                          label: _vm.$t(
                                            "generics.expectedReturn"
                                          ),
                                          autofocus: ""
                                        },
                                        on: {
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              )
                                            ) {
                                              return null
                                            }
                                            return function(event) {
                                              return event.preventDefault()
                                            }.apply(null, arguments)
                                          }
                                        },
                                        model: {
                                          value: _vm.breakTimeEnd,
                                          callback: function($$v) {
                                            _vm.breakTimeEnd = $$v
                                          },
                                          expression: "breakTimeEnd"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.endTimeMenu,
                            callback: function($$v) {
                              _vm.endTimeMenu = $$v
                            },
                            expression: "endTimeMenu"
                          }
                        },
                        [
                          _vm._v(" "),
                          _vm.endTimeMenu
                            ? _c("v-time-picker", {
                                staticClass: "font16",
                                attrs: { "full-width": "", format: "24hr" },
                                on: {
                                  "click:minute": function($event) {
                                    return _vm.$refs.endTimeMenu.save(
                                      _vm.breakTimeEnd
                                    )
                                  },
                                  "click:hour": _vm.autocompleteMinutesEnd
                                },
                                model: {
                                  value: _vm.breakTimeEnd,
                                  callback: function($$v) {
                                    _vm.breakTimeEnd = $$v
                                  },
                                  expression: "breakTimeEnd"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { showCancel: true, closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { color: "primary", disabled: _vm.disabledSave },
                  on: { click: _vm.saveTime }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              ),
              _vm._v(" "),
              _vm.showRemoveButton
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary" },
                      on: { click: _vm.deleteTime }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("components.holidays.deleteHolidays"))
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }