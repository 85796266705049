<template>
  <!-- Incoming call modal  -->
  <div>
    <v-dialog :model-value="haveCalls" @update:model-value="haveCalls = $event" hide-overlay persistent max-width="550"
      class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <audio v-if="shouldPlayRinging" id="ringingSource" ref="ringingSource" :src="playingRingTone" autoplay
        loop></audio>
      <IncomingCallSub :key="call.uuid" v-for="call in incommingCallsArray" tabindex="0" :call="call" />
    </v-dialog>
    <v-dialog :model-value="haveConferenceCalls" @update:model-value="haveConferenceCalls = $event" hide-overlay
      persistent max-width="550" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <audio v-if="shouldPlayRinging" id="ringingSource" ref="ringingSource" :src="playingRingTone" autoplay
        loop></audio>
      <ConferenceIncomingCallSub :key="call.uuid" v-for="call in incommingConfCallsArray" tabindex="0" :call="call" />
    </v-dialog>
  </div>

  <!-- end incoming call modal -->
</template>

<script>
import store from "../../store";
import IncomingCallSub from "./incomingCallSub.vue";
import ConferenceIncomingCallSub from "./conferenceIncomingCallSub.vue";

export default {
  components: { IncomingCallSub, ConferenceIncomingCallSub },
  data() {
    return {
      state: store.state,
      shouldPlayRinging: true,
    };
  },
  mounted() {
    const { ringingOutputId, audioOutputId } = store.state.persisted.mediaDeviceSetup;
    const audioElement = this.$refs.audioSource;
    const ringingElement = this.$refs.ringingSource;

    // Configurar dispositivos de audio
    if (ringingElement) {
      this.setAudioSink(ringingElement, ringingOutputId);
      if (ringingOutputId === audioOutputId || !ringingOutputId) {
        this.shouldPlayRinging = false;
      }
    }
    if (audioElement) {
      this.setAudioSink(audioElement, audioOutputId);
    }

    // Evitar doble tono de llamada
    if (audioElement && this.shouldPlayRinging) {
      audioElement.muted = true;
      audioElement.volume = 0;
    }

    // Intentar reproducir el audio
    this.playAudio(audioElement);
    this.playAudio(ringingElement);
  },
  beforeUnmount() {
    // Remove ringing source after call is answered or rejected
    if (this.$refs.ringingSource) this.$refs.ringingSource.volume = 0;
    if (this.$refs.audioSource) this.$refs.audioSource.volume = 0;
    this.shouldPlayRinging = false;
  },
  methods: {
    playAudio(audioElement) {
      if (audioElement) {
        audioElement.volume = this.volumCallTone;
        audioElement.play().catch((error) => {
          console.warn('Error al intentar reproducir el audio:', error);
          this.handleAudioError(audioElement);
        });
      }
    },
    setAudioSink(audioElement, deviceId) {
      if (audioElement && deviceId && 'sinkId' in audioElement && 'setSinkId' in audioElement) {
        audioElement.setSinkId(deviceId)
          .then(() => {
            console.debug('Audio output device attached:', deviceId);
          })
          .catch((error) => {
            console.warn('Error attaching audio output device:', error);
          });
      }
    },
    handleAudioError(audioElement) {
      // Cargar una alternativa si el audio no se reproduce
      if (audioElement) {
        audioElement.src = this.playingRingToneFallback;
        audioElement.play().catch((error) => {
          console.error('Error intentando recargar el archivo de audio:', error);
        });
      }
    }
  },
  computed: {
    volumCallTone() {
      let volum = store.state.persisted.mediaDeviceSetup.volumeCallTone;
      if (isNaN(volum)) {
        volum = 100;
      }
      volum = volum / 100;
      return volum;
    },
    haveCalls: function () {
      // this.$refs.test.removeOverlay
      //console.log('incomming calls ' , this.incommingCallsArray)
      return this.incommingCallsArray.length > 0;
    },
    haveConferenceCalls: function () {
      return this.incommingConfCallsArray.length > 0;
    },
    incommingConfCallsArray: function () {
      const confCalls = [];
      // this.$nextTick(() => this.$refs.divModal.$el.focus())
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
        if (call.isConference) {
          confCalls.push({ ...call, uuid });
        }
      }
      // console.log('incommingConfCallsArray ', confCalls)
      return confCalls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    incommingCallsArray: function () {
      const calls = [];
      // this.$nextTick(() => this.$refs.divModal.$el.focus())
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
        if (!call.isConference) {
          calls.push({ ...call, uuid });
        }
      }
      // console.log('incommingCallsArray ', calls)
      return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    playingRingTone() {
      if (!this.incommingCallsArray.length) return "";
      for (let call of this.incommingCallsArray) {
        if (this.$refs.audioSource) {
          this.$refs.audioSource.volume = this.volumCallTone;
        }
        if (this.$refs.ringingSource) {
          this.$refs.ringingSource.volume = this.volumCallTone;
        }
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    }
  }
};
</script>
<style scoped lang="scss"></style>
