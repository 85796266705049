<template>
  <v-btn class="buttonColor" @click="goBackToCall" id="returnToCall">
    <font-awesome-icon
      :icon="['fal', 'undo']"
      :style="{ fontSize: '16px' }"
      class="whiteColor"
    />
    <span class="pl-1 whiteColor">{{
      $t("components.returnToCall.returnToCall")
    }}</span>
  </v-btn>
</template>
<script>
import store from "../../store";
export default {
  data() {
    return {
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
    };
  },
  methods: {
    goBackToCall() {
      this.setCurrentContentVisile("", false, this.$router);
    },
  },
};
</script>

<style scoped lang="scss">
.whiteColor {
  color: white;
}
.buttonColor {
  background-color: #ffa500 !important;
}
#returnToCall {
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>