var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confWrapper", attrs: { id: "confWrapper" } },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.isConferenceCall
            ? _c("ConferenceLeftBar", {
                attrs: {
                  isConferenceCall: _vm.isConferenceCall,
                  amIModerator: _vm.amIModerator,
                  hangUpBridgeCall: _vm.hangUpBridgeCall,
                  getCallUUID: _vm.getCallUUID
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showTimeLine
            ? _c("ProgressLinearBridge", {
                attrs: {
                  amIModerator: _vm.amIModerator,
                  updateTimeForUser: _vm.updateTimeForUser,
                  setCallDuration: _vm.setCallDuration,
                  hangUpBridgeCall: _vm.hangUpBridgeCall,
                  redirectToStartView: _vm.redirectToStartView,
                  isConferenceCall: _vm.isConferenceCall
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isConferenceCall
            ? _c("ConferenceRightBar", {
                attrs: {
                  isConferenceCall: _vm.isConferenceCall,
                  amIModerator: _vm.amIModerator
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.rejectedCall
        ? _c("VideoBridgeManager", {
            attrs: {
              isRecorder: false,
              ownUUID: _vm.ownUUID,
              amICalling: _vm.amICalling,
              getCallUUID: _vm.getCallUUID,
              getAvatarForUuid: _vm.getAvatarForUuid,
              getTitelForUuid: _vm.getTitelForUuid,
              getVisitorFunctionForUuid: _vm.getVisitorFunctionForUuid,
              bridgeCallScreenShareState: _vm.bridgeCallScreenShareState,
              getNameForUuid: _vm.getNameForUuid,
              jvbOptions: _vm.jvbOptions,
              hangUpCallingCb: _vm.hangUpCalling,
              rejectCallCb: _vm.rejectCall,
              showDebug: _vm.state.nerd,
              isNormalMode: _vm.isNormalMode,
              hideVideoContainer: _vm.hideVideoContainer,
              videoDeviceId: _vm.videoDeviceId,
              audioDeviceId: _vm.audioDeviceId,
              amIInitiator: _vm.amIInitiator,
              assistantsInitiatorList: _vm.assistantsInitiatorList,
              showSendMessage: _vm.setShowModalSenMsg,
              getEmailForUuid: _vm.getEmailForUuid,
              emailSalutation: _vm.emailSalutation,
              openCloseBridgeStreamSettings: _vm.openCloseBridgeStreamSettings,
              getStreamData: _vm.getStreamData,
              getModerator: _vm.getModerator,
              currentCallTime: _vm.currentCallTime,
              setCallDuration: _vm.setCallDuration,
              bridgeCallEnd: _vm.bridgeCallEnd,
              redirectToStartView: _vm.redirectToStartView,
              toggleModalSettings: _vm.toggleModalSettings,
              companyLogo: _vm.companyLogo,
              hasPrivilege: _vm.hasPrivilege,
              userIsOneCall: _vm.userIsOneCall,
              setSendMessageInCallModal: _vm.setSendMessageInCallModal,
              setNewMessageFromCall: _vm.setNewMessageFromCall,
              newMessageFromCall: _vm.newMessageFromCall,
              fetchWallPaperSrc: _vm.fetchWallPaperSrc,
              resetNewMessageFromCallEvent: _vm.resetMessange,
              conferenceDocuments: _vm.confDocuments,
              userConferencePosition: _vm.getuserConferencePosition,
              conferenceAttendees: _vm.confAttendees,
              confModerators: _vm.confPreModerators,
              dispatchMessage: _vm.dispatchMessage,
              callUserConference: _vm.callUserConference,
              conferenceSettings: _vm.gettConferenceSettings,
              usersToHideName: _vm.getUsersToHideName,
              showTimeLine: _vm.showTimeLine,
              isConferenceCall: _vm.isConferenceCall,
              updateAttendanceList: _vm.updateAttendanceList,
              isVisitor: _vm.isVisitor,
              isWaitingRoomUser: _vm.isWaitingRoomUser,
              isPayEnabled: _vm.isPayEnabled,
              currency: _vm.getCurrency,
              setRequestPayment: _vm.setRequestPayment,
              isAudioOnly: _vm.getIsAudioOnly,
              hasMobileParticipant: _vm.getIsMobile,
              hasCallEnded: _vm.hasCallEnded,
              setInfoModal: _vm.setInfoModal,
              allMessages: _vm.allMessages
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showStreamSettings
        ? [
            _c("BridgeCallStreamSettingsModal", {
              attrs: {
                showModal: _vm.showStreamSettings,
                closeModal: _vm.openCloseBridgeStreamSettings
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }