
import {
  newGroupConferenceEvent,
  updateGroupConferenceEvent,
  dispatchFetchMyGroupConferencesEvent,
  dispatchDeleteGroupConferenceEvent,
  dispatchFetchGroupConferenceInfosEvent,
  setGroupConferencesInfoBatchedEvent,
  setGroupConferenceUUIDsEvent,
  getMyConferenceDetailsEvent,
  setGroupConferenceDetailEvent,
} from './events';
import { resetAndRefetchDatesEvent } from "../timeline/events"
import {
  fetchMyGroupConferencesEffect,
  getGroupConferenceEntryByUUIDEffect,
  createGroupConferenceEntryEffect,
  updateGroupConferenceEffect,
  deleteGroupConferenceEntryEffect,
  getMyConferencesDetailEffect,
} from './effects';

import { parallel } from '../../lib/asyncUtil';
import { wsConnectedEvent } from '../ws';

let hasUserEntry = false;
let tempConfId = null;

// TODO: Remove from here put in the actual component for messages
wsConnectedEvent.watch(() => {
  dispatchFetchMyGroupConferencesEvent();
});

resetAndRefetchDatesEvent.watch(() => {
  dispatchFetchMyGroupConferencesEvent();
});

dispatchFetchMyGroupConferencesEvent.watch((payload) => {
  fetchMyGroupConferencesEffect();
});

fetchMyGroupConferencesEffect.done.watch(({ params, result }) => {
  if (result && result.length) {
    hasUserEntry = true;
    tempConfId = result[0].confId;
  }
  setGroupConferenceUUIDsEvent(result);
  getMyConferenceDetailsEvent();
});

getMyConferenceDetailsEvent.watch((payload) => {
  getMyConferencesDetailEffect();
});

getMyConferencesDetailEffect.done.watch(({ params, result }) => {
  if (hasUserEntry && !result.length) {
    getGroupConferenceEntryByUUIDEffect(tempConfId).then(function (value) {
      setGroupConferenceDetailEvent([value]);
    }, function (reason) {
    });
  } else {
    setGroupConferenceDetailEvent(result);
  }

});

newGroupConferenceEvent.watch((payload) => {
  createGroupConferenceEntryEffect(payload);
});

updateGroupConferenceEvent.watch((payload) => {
  updateGroupConferenceEffect(payload);
});

updateGroupConferenceEffect.done.watch(({ params, result }) => {
  fetchMyGroupConferencesEffect();
});

dispatchFetchGroupConferenceInfosEvent.watch(async (confIds) => {
  if (!confIds.length) return; // Nope
  if (getGroupConferenceEntryByUUIDEffect.pending.getState()) {
    // No need to fetch while fetching, new fetched things will trigger an update which will then fetch whatever new thing could be missing
    return;
  }
  const acc = {};
  await parallel(10, [...confIds], async (confId) => {
    const info = await getGroupConferenceEntryByUUIDEffect(confId);
    if (typeof info !== 'undefined' && info !== null) {
      acc[confId] = info;
    }
  });
  const notEmpty = Object.keys(acc).length;
  // console.log('dispatchFetchGroupConferenceInfosEvent Fetched', notEmpty, acc);
  if (notEmpty) setGroupConferencesInfoBatchedEvent(acc);
});

dispatchDeleteGroupConferenceEvent.watch((confId) => {
  const params = [confId];
  deleteGroupConferenceEntryEffect(params);
});
