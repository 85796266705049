var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showStatusColorSettings,
        callback: function($$v) {
          _vm.showStatusColorSettings = $$v
        },
        expression: "showStatusColorSettings"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-row",
            {
              staticClass: "w-100 mx-0 my-0 justify-space-between",
              attrs: { flex: "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0 py-0",
                  attrs: { cols: _vm.isMobile ? 12 : 5, align: "left" }
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("v-img", {
                        staticClass: "mr-1",
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("components.navbar.statusColor")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", color: "black" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeModal()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "25px" },
                                      attrs: { icon: ["fal", "times"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-list-item",
            { staticClass: "py-0 switchWrapper" },
            [
              _c(
                "v-list-item-content",
                { staticClass: "py-0 my-0" },
                [
                  _c("v-switch", {
                    staticClass: "my-0 py-2",
                    attrs: {
                      label: _vm.$t("components.navbar.wholeActionBar")
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeColor($event, "whole")
                      }
                    },
                    model: {
                      value: _vm.state.user.colorsettings.wholeBar,
                      callback: function($$v) {
                        _vm.$set(_vm.state.user.colorsettings, "wholeBar", $$v)
                      },
                      expression: "state.user.colorsettings.wholeBar"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            { staticClass: "py-0 switchWrapper" },
            [
              _c(
                "v-list-item-content",
                { staticClass: "py-0 my-0" },
                [
                  _c("v-switch", {
                    staticClass: "my-0 py-2",
                    attrs: { label: _vm.$t("components.navbar.onlyHandset") },
                    on: {
                      change: function($event) {
                        return _vm.changeColor($event, "handset")
                      }
                    },
                    model: {
                      value: _vm.state.user.colorsettings.handSetOnly,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user.colorsettings,
                          "handSetOnly",
                          $$v
                        )
                      },
                      expression: "state.user.colorsettings.handSetOnly"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns w-100 mx-0 my-0" },
                [
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "closeButton",
                                      attrs: { icon: "", color: "white" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeModal()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "25px" },
                                      attrs: { icon: ["fal", "check"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }