<template>
    <div>
        <v-row class="d-flex" no-gutters>
            <v-col cols="12" md="6" class="px-4 pb-4">
                <div class="custom-panel">
                    <div class="panel-header" ref="panelHeader">
                        

                        <div v-if="isPanelOpen" :class="isDarkMode ? 'colorWhite': 'colorBlack'" class="panel-content cursor-default">
                            {{ $t("components.socialMedia.postAlert") }}
                        </div>
                        <v-btn  :class="isDarkMode ? 'arrow-btn-white': 'arrow-btn-black'" class="arrow-btn no-hover" @click="toggleTextContent" variant="text"
                            density="compact" :icon="isPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'">
                        </v-btn>
                    </div>
                    <transition name="fade">
                    </transition>
                </div>
                <v-card flat class="px-4 rounded-sm">
                    <v-tabs v-model="selectedTab" class="text-sm mb-2" align-tabs="start" background-color="transparent"
                        dense>
                        <v-tab :min-width="50" class="font-weight-light text-capitalize px-3">{{
                            $t("components.socialMedia.weTogether") }}</v-tab>
                        <v-tab :min-width="50" class="font-weight-light text-capitalize px-3">{{
                            $t("components.socialMedia.whatsWork") }}</v-tab>
                    </v-tabs>
                    <CreatePost :viewSelected="viewSelected" @addPost="addPost" @addWhatsWork="addWhatsWork" />
                </v-card>
            </v-col>
            <v-col cols="12" md="6" class="px-4 pb-4">
                <Notification />
            </v-col>
        </v-row>
        <!-- <img class="socialMediaImg" src="/img/vOfficeSocialMediaPic.png" /> -->

        <v-row class="d-flex" no-gutters>
            <v-tabs-items v-model="selectedTab" class="w-100">
                <v-tab-item v-if="selectedTab === 0">
                    <v-row class="d-flex" no-gutters>
                        <v-col cols="12" md="6" class="pa-4" :style="computedPanelHeight">
                            <h3 class="font-weight-regular">{{ $t("components.socialMedia.weTogether") }}</h3>
                            <v-divider class="mb-6"></v-divider>

                            <Post v-for="(post, index) in postData" :key="`post-${index}`" :postData="post" />
                            <infinite-loading :identifier="date" ref="infiniteLoading" :distance="3"
                                @infinite="infiniteHandlerPost">
                                <template #error>
                                    <div v-html="'&nbsp;'"></div>
                                </template>
                                <template #complete>
                                    <div v-html="'&nbsp;'"></div>
                                </template>
                            </infinite-loading>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-4" :style="computedPanelHeight">
                            <Favorites />
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item v-if="selectedTab === 1">
                    <v-row class="d-flex" no-gutters>
                        <v-col cols="12" md="12" class="pa-4" :style="computedPanelHeight">
                            <h3 class="font-weight-regular">{{ $t("components.socialMedia.whatsWork") }}</h3>
                            <v-divider class="mb-6"></v-divider>
                            <WhatsWork v-for="(whatsWork, index) in whatsWorkData" :key="`whatsWork-${index}`"
                                :whatsWorkData="whatsWork" />
                            <infinite-loading :identifier="date" ref="infiniteLoadingWhatsWork" :distance="3"
                                @infinite="infiniteHandlerWhatsWork">
                                <template #error>
                                    <div v-html="'&nbsp;'"></div>
                                </template>
                                <template #complete>
                                    <div v-html="'&nbsp;'"></div>
                                </template>
                            </infinite-loading>
                        </v-col>
                        <!-- <v-col cols="12" md="6" class="pa-4" style="height: calc(100vh - 420px); overflow: auto;">
                            <Favorites />
                        </v-col> -->
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-row>

        <!-- <a href="https://xd.adobe.com/view/3997d75f-b00a-47ce-b0ea-8a2535b34856-6cc4/" target="_blank"
            class="adobe-link text-primary">Demo</a>-->
    </div>
</template>



<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import moment from "../../../../sharedsrc/moment";
import CreatePost from "./post/createPost.vue";
import Post from "./post/post.vue";
import Notification from "./common/notifications.vue";
import WhatsWork from "./workUpdates/whatsWork.vue";
import Favorites from "./favorites/favorites.vue"
import InfiniteLoading from "v3-infinite-loading";

export default {
    props: [],
    components: { CreatePost, Post, WhatsWork, Notification, Favorites, "infinite-loading": InfiniteLoading, },
    data() {
        return {
            selectedTab: 0,
            state: store.state,
            ownUUID: store.state.ownUUID,
            viewSelected: 'posting',
            postData: [],
            whatsWorkData: [],
            // Post Infinite Scroll
            currentPagePosts: 1,
            limitPosts: 20,
            isLoadingPosts: false,
            hasMorePosts: true,
            // WhatsWork Infinite Scroll
            currentPageWhatsWork: 1,
            limitWhatsWork: 20,
            isLoadingWhatsWork: false,
            hasMoreWhatsWork: true,
            isPanelOpen: true,
            screenWidth: window.innerWidth,

        };
    },
    watch: {
        selectedTab: {
            inmediate: true,
            handler: function (value) {
                if (value === 0) {
                    this.viewSelected = 'posting';
                    this.getPosts()
                } else {
                    this.viewSelected = 'whatswork';
                    this.getWhatsWork();
                }
            }
        }
    },
    mounted() {
        EventBus.$on('updatePost', this.updatePost);
        EventBus.$on('removePost', this.removePost);
        EventBus.$on('removeWhatsWork', this.removeWhatsWork);
        EventBus.$on('updateWhatsWork', this.updateWhatsWork);
        EventBus.$on('updateComment', this.updateComment);
        EventBus.$on('removeComment', this.removeComment);
        window.addEventListener("resize", this.handleResize);
        this.getPosts();
    },
    unmounted() {
        EventBus.$off('updatePost', this.updatePost);
        EventBus.$off('removePost', this.removePost);
        EventBus.$off('removeWhatsWork', this.removeWhatsWork);
        EventBus.$off('updateWhatsWork', this.updateWhatsWork);
        EventBus.$off('updateComment', this.updateComment);
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth; // Actualizamos el ancho de la pantalla
        },
        toggleTextContent() {
            this.isPanelOpen = !this.isPanelOpen;
        },
        infiniteHandlerPost($state) {
            if (this.hasMorePosts) {
                this.getPosts()
                setTimeout(() => {
                    $state.loaded();
                }, 500);
            } else {
                $state.complete();
            }
        },
        infiniteHandlerWhatsWork($state) {
            if (this.hasMoreWhatsWork) {
                this.getWhatsWork();
                setTimeout(() => {
                    $state.loaded();
                }, 500);
            } else {
                $state.complete();
            }
        },
        getWhatsWork() {
            if (this.isLoadingWhatsWork || !this.hasMoreWhatsWork) return;

            this.isLoadingWhatsWork = true;

            fetch(`/api/socialmedia/whatswork?userId=${this.ownUUID}&page=${this.currentPageWhatsWork}&limit=${this.limitWhatsWork}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then(async (response) => {
                    const result = await response.json();
                    if (!result.error) {
                        if (result.posts.length > 0) {
                            this.whatsWorkData = [...this.whatsWorkData, ...result.posts];
                            this.currentPageWhatsWork++;
                        }
                        if (this.whatsWorkData.length >= result.totalPosts) {
                            this.hasMoreWhatsWork = false;
                        }
                    } else {
                        // Manejar errores aquí
                    }
                })
                .catch(err => console.error("Error al obtener whatsWork:", err))
                .finally(() => this.isLoadingWhatsWork = false);
        },
        getPosts() {
            if (this.isLoadingPosts || !this.hasMorePosts) return;

            this.isLoadingPosts = true;

            fetch(`/api/socialmedia/posts?page=${this.currentPagePosts}&limit=${this.limitPosts}`)
                .then(async (response) => {
                    const result = await response.json();
                    if (!result.error) {
                        if (result.posts.length > 0) {
                            this.postData = [...this.postData, ...result.posts];
                            this.currentPagePosts++;
                        }
                        if (this.postData.length >= result.totalPosts) {
                            this.hasMorePosts = false;
                        }
                    }
                })
                .catch(err => console.error("Error al obtener posts:", err))
                .finally(() => this.isLoadingPosts = false);
        },
        addPost(post) {
            this.postData.unshift(post); // Lo agrega al inicio de la lista
        },
        addWhatsWork(post) {
            this.whatsWorkData.unshift(post); // Lo agrega al inicio de la lista
        },
        updateComment(comment) {
            const postIndex = this.postData.findIndex(x => x.id === comment.postId);
            const postWhatsworkIndex = this.whatsWorkData.findIndex(x => x.id === comment.postId);
            // Helper function to update the comments for both postData and whatsWorkData
            // Helper function to update or add the comment
            const updateData = (data, index) => {
                if (index !== -1) {
                    const existingComments = data[index].comments;
                    const commentIndex = existingComments.findIndex(x => x.id === comment.id);

                    if (commentIndex !== -1) {
                        // Update existing comment
                        existingComments[commentIndex] = comment;
                    } else {
                        // Add new comment
                        existingComments.push(comment);
                    }

                    this.$set(data, index, {
                        ...data[index],
                        comments: existingComments,
                        updatedAt: new Date().toISOString()
                    });
                }
            };
            // Update postData if found
            updateData(this.postData, postIndex);
            // Update whatsWorkData if found
            updateData(this.whatsWorkData, postWhatsworkIndex);
        },
        removeComment(comment) {
            // Helper function to remove the comment from both postData and whatsWorkData
            const removeCommentFromData = (data, index) => {
                if (index !== -1) {
                    // Find the comment index inside the comments array
                    const commentIndex = data[index].comments.findIndex(c => c._id === comment._id);

                    if (commentIndex !== -1) {
                        // Remove the comment from the comments array
                        const updatedComments = [...data[index].comments];
                        updatedComments.splice(commentIndex, 1); // Removes the comment at the found index

                        // Update the post with the new comments array and the updated timestamp
                        this.$set(data, index, {
                            ...data[index],
                            comments: updatedComments,
                            updatedAt: new Date().toISOString()
                        });
                    }
                }
            };

            // Find the index in postData and remove the comment if found
            const postIndex = this.postData.findIndex(x => x.id === comment.postId);
            removeCommentFromData(this.postData, postIndex);

            // Find the index in whatsWorkData and remove the comment if found
            const postWhatsworkIndex = this.whatsWorkData.findIndex(x => x.id === comment.postId);
            removeCommentFromData(this.whatsWorkData, postWhatsworkIndex);
        },

        updatePost(post) {
            const postIndex = this.postData.findIndex(x => x.id === post.postId);
            if (postIndex !== -1) {
                this.$set(this.postData, postIndex, {
                    ...this.postData[postIndex],
                    content: post.content,
                    images: post.images,
                    documents: post.documents,
                    updatedAt: new Date().toISOString()
                });
            }
        },
        updateWhatsWork(whatsWork) {
            const postIndex = this.whatsWorkData.findIndex(x => x.id === whatsWork.postId);
            if (postIndex !== -1) {
                this.$set(this.whatsWorkData, postIndex, {
                    ...this.whatsWorkData[postIndex],
                    content: whatsWork.content,
                    images: whatsWork.images,
                    documents: whatsWork.documents,
                    updatedAt: new Date().toISOString()
                });
            }
        },
        removePost(post) {
            const postIndex = this.postData.findIndex(x => x.id === post._id);
            if (postIndex !== -1) {
                this.postData.splice(postIndex, 1); // Elimina el post del array
            }
        },
        removeWhatsWork(whatsWork) {
            const postIndex = this.whatsWorkData.findIndex(x => x.id === whatsWork._id);
            if (postIndex !== -1) {
                this.whatsWorkData.splice(postIndex, 1); // Elimina el post del array
            }
        }
    },
    computed: {
        isDarkMode() {
            return this.state.persisted.isDark;
        },
        computedPanelHeight() {
            const screenWidth = this.screenWidth;
            if (screenWidth < 1878) {
                const panelHeight = this.isPanelOpen ? 525 : 455; // Ajuste original si el ancho es menor a 1878
                return `height: calc(100vh - ${panelHeight}px); overflow: auto;`;
            } else {
                // Si el ancho de la pantalla es mayor o igual a 1878, calculamos otro valor
                const panelHeight = this.isPanelOpen ? 475 : 455; // Ajuste diferente si el ancho es mayor a 1878
                return `height: calc(100vh - ${panelHeight}px); overflow: auto;`;
            }
        }
    },
};
</script>

<style scoped lang="scss">
@import url("https://use.typekit.net/ved4nhz.css");

.custom-panel {
    padding: 0 12px 12px 12px;
}

.panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "altesse-std-24pt", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.panel-content {
    //   margin-top: 8px;
    font-size: 32px;
    
}
.colorBlack{
    color: black;
}
.colorWhite{
    color: white;
}



.adobe-link {
    position: absolute;
    top: 0;
    right: 15px;
}
</style>
<style lang="scss">
.arrow-btn {
    margin-left: auto;
}
.arrow-btn-black {
    .v-btn__content .v-icon {
        color: black !important;
        font-size: 25px !important;
    }
}
.arrow-btn-White {
    .v-btn__content .v-icon {
        color: white !important;
        font-size: 25px !important;
    } 
}

.no-hover .v-btn__overlay {
    display: none !important;
}
</style>