<template>
  <v-card flat class="mx-auto primaryBackground" width="100%">
    <!-- Favorites -->
    <Favorites
      :setList="setList"
      :active="active"
      :setCurrentContentVisile="setCurrentContentVisile"
      :isGuest="isGuest"
      :sortedFavoriteUsers="sortedFavoriteUsers"
      :totalUnfilteredUsers="totalUnfilteredUsers"
      :isMobile="isMobile"
      :mini="mini"
    />
    <!-- :searchFoundSomethingFav="searchFoundSomethingFav" -->
    <!-- <Stats :setList="setList" :active="active" v-show="state.nerd"></Stats> -->
  </v-card>
</template>    

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import Sections from "./sections.vue";
import Departments from "./departments.vue";
import Teams from "./teams.vue";
import Users from "./users.vue";
import Favorites from "./favorites.vue";
import Guests from "./guests.vue";
// import Stats from "./stats.vue";
import Conference from "./conference.vue";
import { checkListSort } from "../../utils/basicFunctions";

export default {
  components: {
    Sections,
    Departments,
    Teams,
    Users,
    Favorites,
    Guests,
    // Stats,
    Conference,
  },
  props: ["active", "setList", "mini"],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    isMobile: isMobile(),
    // usersList: [],
    // usersAvailable: [],
    // usersBusy: [],
    // usersNotAvailable: [],
    // usersOffline: []
  }),
  methods: {
    setActiveOrganisationSection(section) {
      return store.setActiveOrganisationSection(section);
    },

    // filterPerson: function(person) {
    //   return (
    //     person.uuid != this.ownUUID &&
    //     (this.state.searchTerm.length === 0 ||
    //       ((person.user.name || "").trim().toLowerCase() || "").indexOf(
    //         this.state.searchTerm.trim().toLowerCase()
    //       ) !== -1 )
    //     //  ||
    //     //  ((person.user.activity || "").trim().toLowerCase() || "").indexOf(
    //     //     this.state.searchTerm.trim().toLowerCase()
    //     //   ) !== -1)
    //   );
    // },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
  },
  computed: {
    getRoute() {
      return this.$route.path;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    totalUnfilteredUsers() {
      let total = 0;
      for (let uuid in store.state.group) {
        const exists = store.getUuidFromFavorites(uuid);
        if (exists) {
          const person = store.state.group[uuid];
          if (!person || !person.user) continue;
          ++total;
        }
      }
      return total;
    },
    sortedFavoriteUsers () {
      const favoritePersons = [];
      for (let uuid in store.state.group) {
        const exists = store.getUuidFromFavorites(uuid);
        if (exists) {
          const person = store.state.group[uuid];
          if (!person || !person.user) continue;
          favoritePersons.push({ ...person, uuid });
        }
      }
      return checkListSort(favoritePersons);
    },
    separatedPeople() {
      const result = { sections: {}, departments: {}, teams: {} };

      if (!store.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      const sections =
        store.state.namespaceSettings.processedOrganisation.sections || {};
      const departments =
        store.state.namespaceSettings.processedOrganisation.departments || {};
      const teams =
        store.state.namespaceSettings.processedOrganisation.teams || {};

      if (Object.keys(sections).length > 0) {
        Object.keys(sections).forEach((orgKey) => {
          if (!result.sections[orgKey]) result.sections[orgKey] = [];
          for (let i = 0; i < sections[orgKey].supervisors.length; i++) {
            if (
              this.ownUUID !== sections[orgKey].supervisors[i].uuid &&
              !result.sections[orgKey].includes(
                sections[orgKey].supervisors[i].uuid
              )
            ) {
              result.sections[orgKey].push(
                sections[orgKey].supervisors[i].uuid
              );
            }
          }

          for (let i = 0; i < sections[orgKey].departments.length; i++) {
            const departKey = sections[orgKey].departments[i];
            const department = departments[departKey];
            if (!result.departments[departKey])
              result.departments[departKey] = [];
            for (let j = 0; j < department.supervisors.length; j++) {
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.sections[orgKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.sections[orgKey].push(department.supervisors[j].uuid);
              }
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.departments[departKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.departments[departKey].push(
                  department.supervisors[j].uuid
                );
              }
            }

            for (
              let teamKey = 0;
              teamKey < department.teams.length;
              teamKey++
            ) {
              const team_key = department.teams[teamKey];
              const team = teams[team_key];
              if (!result.teams[team_key]) result.teams[team_key] = [];
              for (let j = 0; j < team.supervisors.length; j++) {
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.sections[orgKey].includes(team.supervisors[j].uuid)
                ) {
                  result.sections[orgKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.departments[departKey].includes(
                    team.supervisors[j].uuid
                  )
                ) {
                  result.departments[departKey].push(team.supervisors[j].uuid);
                }

                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.teams[team_key].includes(team.supervisors[j].uuid)
                ) {
                  result.teams[team_key].push(team.supervisors[j].uuid);
                }
              }

              for (let j = 0; j < team.members.length; j++) {
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.sections[orgKey].includes(team.members[j])
                ) {
                  result.sections[orgKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.departments[departKey].includes(team.members[j])
                ) {
                  result.departments[departKey].push(team.members[j]);
                }

                if (
                  this.ownUUID !== team.members[j] &&
                  !result.teams[team_key].includes(team.members[j])
                ) {
                  result.teams[team_key].push(team.members[j]);
                }
              }
            }
          }
        });
      }
      function sortObject(o) {
        return Object.keys(o)
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      result.sections = sortObject(result.sections);
      result.departments = sortObject(result.departments);
      result.teams = sortObject(result.teams);
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.maxwidth320 {
  max-width: 319px;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title {
  line-height: 2.5 !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon {
  min-width: 48px !important;
  margin-right: 8px !important;
}
</style>