var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", fullscreen: true },
      model: {
        value: _vm.showModalMessage,
        callback: function($$v) {
          _vm.showModalMessage = $$v
        },
        expression: "showModalMessage"
      }
    },
    [
      _c(
        "v-card",
        [
          _vm.state.showModalSenMsg && _vm.state.showModalSenMsg.uuid
            ? _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v("\n      " + _vm._s(_vm.modalTitle) + "\n    ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "messageForm",
              attrs: { "lazy-validation": "", autocomplete: false },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-5", style: { width: "100%", margin: 0 } },
                [
                  _c("v-textarea", {
                    attrs: {
                      counter: "",
                      contenteditable: "true",
                      label: _vm.$t("components.sendMessageModal.message"),
                      rules: _vm.rules,
                      "no-resize": true,
                      maxlength: "250",
                      autofocus: ""
                    },
                    model: {
                      value: _vm.state.tmpMessagetext,
                      callback: function($$v) {
                        _vm.$set(_vm.state, "tmpMessagetext", $$v)
                      },
                      expression: "state.tmpMessagetext"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModalMobile",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { lg: "3", align: "left" }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "btnRed mr-2",
                                            attrs: { icon: "", color: "white" },
                                            on: {
                                              click: function($event) {
                                                return _vm.seeUserMessages()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "eye"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.sendMessageModal.seeYourMessages"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          1 == 2
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    value: _vm.shown,
                                    top: "",
                                    "offset-y": _vm.offset,
                                    "close-on-click": _vm.closeOnClick,
                                    "close-on-content-click": _vm.closeOnContent
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.shown = true
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-link")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3143863845
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "425px",
                                        height: "120px",
                                        "background-color": "white"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "px-2 btns",
                                          style: {
                                            width: "100%",
                                            margin: 0,
                                            "margin-botton": "12px"
                                          },
                                          attrs: { flex: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              margin: "2px 10px 2px 10px"
                                            },
                                            attrs: {
                                              "hide-details": "",
                                              label: "Text",
                                              autofocus: ""
                                            },
                                            model: {
                                              value: _vm.linkText,
                                              callback: function($$v) {
                                                _vm.linkText = $$v
                                              },
                                              expression: "linkText"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "px-2 btns",
                                          staticStyle: { "margin-top": "12px" },
                                          style: { width: "100%", margin: 0 },
                                          attrs: { flex: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              margin: "2px 10px 2px 10px"
                                            },
                                            attrs: {
                                              "hide-details": "",
                                              label: "Url"
                                            },
                                            model: {
                                              value: _vm.linkUrl,
                                              callback: function($$v) {
                                                _vm.linkUrl = $$v
                                              },
                                              expression: "linkUrl"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "py-2 pl-4 mt-4 pr-2 footerModal",
                                      attrs: { color: "primary" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 py-0",
                                          attrs: { lg: "12", align: "right" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: { click: _vm.linkifyme }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-check")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: { click: _vm.closeMenu }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-cancel")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { lg: "9", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.closeModalMsg(undefined)
                                  _vm.resettmpMessagetext("")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled:
                                  _vm.state.tmpMessagetext.length == 0 ||
                                  !_vm.state.tmpMessagetext
                              },
                              on: {
                                click: function($event) {
                                  _vm.preparedataToSendMsg(
                                    _vm.state.showModalSenMsg.uuid,
                                    _vm.state.tmpMessagetext
                                  )
                                  _vm.closeModalMsg()
                                  _vm.resettmpMessagetext("")
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.sendMessageModal.send")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }