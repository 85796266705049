var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showVideoTestModal,
        callback: function($$v) {
          _vm.showVideoTestModal = $$v
        },
        expression: "showVideoTestModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.userProfile.videoTest"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c("v-card-text", [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-center align-center black heigth70"
                },
                [
                  _c("CameraPreview", {
                    attrs: { height: "100%", width: "100%" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }