var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("span", [_vm._v(_vm._s(_vm.group.groupName))]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._l(_vm.getGroupMembers, function(user) {
        return _c("ul", { key: user.uuid }, [
          _c("li", [
            _vm._v(
              _vm._s(_vm.getUserTitel(user.uuid)) + " " + _vm._s(user.name)
            )
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }