var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.userProfile.sendAMessage")) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "messageForm",
              attrs: { "lazy-validation": "", autocomplete: false }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-5", style: { width: "100%", margin: 0 } },
                [
                  _c("v-textarea", {
                    attrs: {
                      counter: "",
                      contenteditable: "true",
                      label: _vm.$t("components.sendMessageModal.message"),
                      rules: _vm.rules,
                      "no-resize": true,
                      maxlength: "250",
                      autofocus: ""
                    },
                    model: {
                      value: _vm.messageText,
                      callback: function($$v) {
                        _vm.messageText = $$v
                      },
                      expression: "messageText"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "px-2", style: { width: "100%", margin: 0 } },
                [
                  _c("v-checkbox", {
                    staticClass:
                      "mencheckbox sel-check-item-first px-1 mx-auto",
                    attrs: {
                      disabled: _vm.disabledDepartCheck,
                      label: _vm.$t(
                        "components.organisationCarousel.departmentManagers"
                      ),
                      value: _vm.departmentsChecked
                    },
                    on: { change: _vm.changeSendToDepartments },
                    model: {
                      value: _vm.departmentsChecked,
                      callback: function($$v) {
                        _vm.departmentsChecked = $$v
                      },
                      expression: "departmentsChecked"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass:
                      "mencheckbox sel-check-item-first px-1 mx-auto",
                    attrs: {
                      disabled: _vm.disabledTeamCheck,
                      label: _vm.$t(
                        "components.organisationCarousel.teamManagers"
                      ),
                      value: _vm.teamsChecked
                    },
                    on: { change: _vm.changeSendToTeams },
                    model: {
                      value: _vm.teamsChecked,
                      callback: function($$v) {
                        _vm.teamsChecked = $$v
                      },
                      expression: "teamsChecked"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass:
                      "mencheckbox sel-check-item-first px-1 mx-auto",
                    attrs: {
                      disabled: _vm.disabledMemberCheck,
                      label: _vm.$t("components.organisationCarousel.members"),
                      value: _vm.membersChecked
                    },
                    on: { change: _vm.changeSendToMembers },
                    model: {
                      value: _vm.membersChecked,
                      callback: function($$v) {
                        _vm.membersChecked = $$v
                      },
                      expression: "membersChecked"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-2 btns",
                      style: { width: "100%", margin: 0 },
                      attrs: { flex: "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { lg: "6", align: "left" }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "btnRed mr-2",
                                            attrs: { icon: "", color: "white" },
                                            on: {
                                              click: function($event) {
                                                return _vm.seeUserMessages()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "eye"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.sendMessageModal.seeYourMessages"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { lg: "6", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: _vm.disabledSendButton
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sendMessage()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.sendMessageModal.send")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }