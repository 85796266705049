var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeTransferVisitorModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showTransferVisitorModal,
        callback: function($$v) {
          _vm.showTransferVisitorModal = $$v
        },
        expression: "showTransferVisitorModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", { attrs: { titleModal: _vm.getModalTitle } }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-2 mx-0" },
            [
              _c("v-col", { staticClass: "d-flex", attrs: { cols: "4" } }, [
                _c("span", { staticClass: "align-center d-flex" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("components.inviteVisitor.transferTo")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.getUserList,
                      select: "",
                      "search-input": _vm.searchUsers,
                      "item-text": "name",
                      label: _vm.$t("generics.users"),
                      dense: "",
                      outlined: "",
                      "auto-select-first": "",
                      "hide-details": "auto"
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.searchUsers = $event
                      },
                      "update:search-input": function($event) {
                        _vm.searchUsers = $event
                      },
                      change: function($event) {
                        _vm.searchUsers = ""
                      }
                    },
                    model: {
                      value: _vm.selectedUser,
                      callback: function($$v) {
                        _vm.selectedUser = $$v
                      },
                      expression: "selectedUser"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            {
              attrs: {
                closeModalFunction: _vm.closeTransferVisitorModal,
                showFooter: true
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.isDataValid, color: "primary" },
                  on: { click: _vm.prepTransfer }
                },
                [_vm._v(_vm._s(_vm.$t("components.inviteVisitor.transfer")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }