var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.adminSettingsModal.mailFooter"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c("v-textarea", {
                  attrs: {
                    name: "footerText",
                    label: _vm.$t("components.adminSettingsModal.mailFooter"),
                    "no-resize": ""
                  },
                  model: {
                    value: _vm.footerText,
                    callback: function($$v) {
                      _vm.footerText = $$v
                    },
                    expression: "footerText"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.closeModal, showCancel: true }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2 white--text",
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.saveFooterText()
                      }
                    }
                  },
                  [_vm._v("\n          " + _vm._s(_vm.$t("generics.save")))]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }