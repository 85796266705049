var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getAmIModerator && _vm.hasPrivilege
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "mx-2 darkGreyIcon",
                              attrs: { color: "white", icon: "", large: "" },
                              on: {
                                click: function($event) {
                                  return _vm.copyLink($event)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "link"] }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2546028092
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.copyLink")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }