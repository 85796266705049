var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mx-0", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center px-0 py-0", attrs: { cols: "12" } },
            [
              _c("div", { staticClass: "headline" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.plans.title")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.plans.subtitle")) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0", attrs: { align: "center" } },
        _vm._l(2, function(n) {
          return _c(
            "v-col",
            {
              key: n,
              staticClass: "col-12",
              attrs: {
                lg: _vm.cols[n - 1],
                md: _vm.cols[n - 1],
                sm: _vm.cols[n - 1]
              }
            },
            [
              n === 1
                ? _c(
                    "div",
                    [
                      _c("v-card", { attrs: { "min-height": "500" } }, [
                        _c("div", { staticClass: "pa-4" }, [
                          _c(
                            "div",
                            { staticClass: "headerPlan" },
                            [
                              _c("p", { staticClass: "title mb-0" }, [
                                _vm._v(
                                  "\n                Basis version\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "captiom" }, [
                                _vm._v(
                                  "\n                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "title" }, [
                                _vm._v(
                                  "\n                25€ / Mo\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", width: "100%" },
                                  on: {
                                    click: function($event) {
                                      return _vm.buyIt("2500")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Buy it\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bodyPlan mt-4" }, [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(
                                "\n                Features\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "featuresDiv" }, [
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "14px" },
                                    attrs: {
                                      color: "primary",
                                      icon: ["fal", "check"]
                                    }
                                  }),
                                  _vm._v(
                                    "\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit\n                "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "14px" },
                                    attrs: {
                                      icon: ["fal", "check"],
                                      color: "primary"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit\n                "
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              n === 2
                ? _c(
                    "div",
                    [
                      _c("v-card", { attrs: { "min-height": "500" } }, [
                        _c("div", { staticClass: "pa-4" }, [
                          _c(
                            "div",
                            { staticClass: "headerPlan" },
                            [
                              _c("p", { staticClass: "title mb-0" }, [
                                _vm._v(
                                  "\n                Pro version\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "captiom" }, [
                                _vm._v(
                                  "\n                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "title" }, [
                                _vm._v(
                                  "\n                50€ / Mo\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", width: "100%" },
                                  on: {
                                    click: function($event) {
                                      return _vm.buyIt("5000")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Buy it\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bodyPlan mt-4" }, [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(
                                "\n                Features\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "featuresDiv" }, [
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "14px" },
                                    attrs: {
                                      color: "primary",
                                      icon: ["fal", "check"]
                                    }
                                  }),
                                  _vm._v(
                                    "\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit\n                "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "14px" },
                                    attrs: {
                                      icon: ["fal", "check"],
                                      color: "primary"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit\n                "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "14px" },
                                    attrs: {
                                      icon: ["fal", "check"],
                                      color: "primary"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit\n                "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "caption mb-0" },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "14px" },
                                    attrs: {
                                      icon: ["fal", "check"],
                                      color: "primary"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit\n                "
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center px-0 py-0", attrs: { cols: "12" } },
            [
              _c("v-card-subtitle", [
                _vm._v("\n        Supported payment methods:\n\n      ")
              ]),
              _vm._v(" "),
              _c("v-flex", [
                _c("span", [
                  _c("img", {
                    staticClass: "paymentIcon",
                    attrs: { src: "/img/paymentIcons/cc.png" }
                  }),
                  _vm._v("\n           Credit Card\n        ")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }