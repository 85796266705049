var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "740" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.language"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "ml-2 mt-0",
                  attrs: { row: "", mandatory: false },
                  model: {
                    value: _vm.radiosLanguage,
                    callback: function($$v) {
                      _vm.radiosLanguage = $$v
                    },
                    expression: "radiosLanguage"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: { value: "DE" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-avatar",
                                  {
                                    attrs: {
                                      tile: "",
                                      width: "23",
                                      height: "17",
                                      "min-width": "23",
                                      "max-height": "17",
                                      "max-width": "23"
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: { src: "img/flags/DE.png" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "notranslate primary--text" },
                                  [_vm._v("DE")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { value: "EN" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-avatar",
                                  {
                                    attrs: {
                                      tile: "",
                                      width: "23",
                                      height: "17",
                                      "min-width": "23",
                                      "max-height": "17",
                                      "max-width": "23"
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: { src: "img/flags/EN.png" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "notranslate primary--text" },
                                  [_vm._v("EN")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { value: "ES" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-avatar",
                                  {
                                    attrs: {
                                      tile: "",
                                      width: "23",
                                      height: "17",
                                      "min-width": "23",
                                      "max-height": "17",
                                      "max-width": "23"
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: { src: "img/flags/ES.png" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "notranslate primary--text" },
                                  [_vm._v("ES")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.saveLanguage()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }