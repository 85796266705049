var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _c("div", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("components.calendarContainer.oneCallParticipant")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "px-1 py-1", attrs: { cols: "6" } },
            [
              _c("OneCallParticipants", {
                attrs: { flat: "", visitor: _vm.visitorUser }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }