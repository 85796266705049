var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "primaryBackground",
      class:
        "" +
        (_vm.isMobile
          ? "userListMobile"
          : _vm.section === "Users"
          ? "userList"
          : _vm.section == "coffee-break"
          ? "userListCoffee"
          : "userListNormal")
    },
    [
      _vm._l(_vm.slicedPersons, function(item, index) {
        return _c(
          "div",
          {
            key: item.uuid,
            staticClass: "person",
            attrs: { id: "tixfor" + index }
          },
          [
            _c("UserListItem", {
              attrs: {
                flat: "",
                person: item,
                section: _vm.section,
                typeData: _vm.typeData
              }
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "infinite-loading",
        { attrs: { distance: 10 }, on: { infinite: _vm.infiniteHandler } },
        [
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }