<template>
  <v-dialog v-model="showModal" persistent max-width="650" @keydown.esc="closeModal" :attach="getAttach" :hide-overlay="getAttach ? true : false">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.conferenceForm.titleInviteUser')"
        :closeModalFunction="closeModal"
      />
      <v-col cols="12">
        <!-- :item-disabled="disableItem" -->
        <v-combobox
          v-model="selectedUser"
          :items="dataCombobox.users"
          :search-input.sync="searchUsers"
          multiple
          item-text="name"
          item-value="uuid"
          :label="$t('components.conferenceForm.vOfficeUsers')"
          dense
          outlined
          class="mt-3"
          @change="handleChangeCombo"
          hide-details
          autofocus
        >
        <template v-slot:item="data">
          <div>
              <span class="statusSpan" :style="{background: data.item.statusColor}"></span>
              <span :style="{marginLeft: '25px'}" v-text="data.item.name"></span>
          </div>
        </template>
        </v-combobox>
      </v-col>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn 
        style="border-radius: 4px; min-width: 45px;"
        tile  
        outlined 
        color="primary" 
        icon 
        class="mx-1 callButtonUserInvite"
         @click="callUser()" 
         :disabled="disableCallBtn">
          <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
        </v-btn>
      </FooterModal>
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>
 
<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { disableCallBtn, callPerson } from "../../utils/calls";
import { wsCall } from "../../lib/wsConnect";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import {
  myConferenceDetails,
  updateGroupConferenceEvent
} from "../../effector/groupConferences";
import { updateTimelineItemEvent } from "../../effector/timeline";
import { getFillColorByStatus } from "../../utils/basicFunctions";

export default {
  props: [
    "isConferenceCall",
    "amIModerator",
    "showModal",
    "closeModal",
    "dataConference",
    "attachTo"
  ],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      searchUsers: null,
      selectedUser: [],
      allSelectedUsers: []
    };
  },
  watch: {},
  effector: {
    myConferences: myConferenceDetails,
    bridgeInfoStore: bridgeInfoStore
  },
  mounted() {},
  destroyed() {},
  methods: {
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach(uuid => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    callUser() {
      // if (this.selectedUser && this.selectedUser.uuid)
      //   this.addUserToConference(this.selectedUser.uuid);
      // callPerson(this.selectedUser.uuid, this.$router);
      this.selectedUser.forEach(user => {
        if(typeof user === 'object' && user !== null &&  user.uuid){
          const person = this.state.group[user.uuid];
          if (!disableCallBtn(person)) {
            callPerson(user.uuid, this.$router);
          }
        }
      });
      this.closeModal();
    },
    async addUserToConference(uuid) {
      let tmpData = this.dataConference; // full conference
      if(!tmpData || !tmpData.confId) {
        console.warn('No conference to add user to');
        return;
      }
      if (tmpData.confUUIDS.indexOf(uuid) == -1) {
        const newMember = {
            isGuest: false,
            isModerator: false,
            name: this.state.group[uuid].user.name,
            uuid: uuid,
            isGroup: false,
            isTeam: false
          }
          tmpData.members.push(newMember);
          tmpData.confRealUsers.push(uuid);
          tmpData.confUUIDS.push(uuid);
          await updateGroupConferenceEvent(tmpData);
          const newAttendees = this.getAttendeesObject(tmpData.confUUIDS);
          // console.log('newAttendees', newAttendees, 'tmpData', tmpData)
          this.getConfOnlineUsers(tmpData, newAttendees);
      }
    },
    handleChangeCombo(value) {
      if (!value || !value.uuid) {
        this.searchUsers = "";
        return;
      }
      if (
        this.allSelectedUsers.find(x => x.uuid === value.uuid) === undefined
      ) {
        this.allSelectedUsers.push(value);
      }
      this.searchUsers = ""; // claear search field
    },
    getConfOnlineUsers(entry, newAttendees) {
      let usernames = [];
      if (entry && entry.confUUIDS) {
        entry.confUUIDS.forEach(uuid => {
          const username =
            ((store.state.group[uuid] || {}).user || {}).name || "";
            // console.log('username ====> ', username)
          if (username && username !== "") {
            let data = { username: username, uuid: uuid };
            usernames.push(data);
          }
        });
        // console.log('usernames ====> ', usernames)
        usernames.forEach(name => {
          let isOnline = false;
          // console.log('this.bridgeInfoStore', this.bridgeInfoStore)
          if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
            Object.keys(this.bridgeInfoStore.calls).forEach(call => {
              if (
                this.bridgeInfoStore.calls[call].confId === entry.confId
              ) {
                // console.log('setting is online', this.bridgeInfoStore.users[name.uuid])
                isOnline = this.bridgeInfoStore.users[name.uuid] || false;
              }
            });
          }
          if (isOnline) {
            // console.log('isOnlineeeee')
            wsCall("sendToUUID", name.uuid, {
              type: "bridge-signal",
              action: "updateConferenceAttendees",
              sender: this.state.ownUUID,
              info: {
                callUUID: this.getCallUUID,
                confAttendees: newAttendees
              }
            });
          }
        });
      }
    }
  },
  computed: {
    getAttach(){
      if ( this.attachTo ){
        const element = document.getElementById(this.attachTo);
        if ( element ){
          return element;
        }
      }
      return false;
    },
    getCallUUID() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      return remoteStreamsKey;
    },
    disableCallBtn() {
      if (this.selectedUser && this.selectedUser.length ==  0) {
        return true
      }
      return false
      //   const person = this.state.group[this.selectedUser.uuid];
      //   return disableCallBtn(person);
      // }else{
      //   return true
      // }
    },
    disableItem(item) {
      return this.allSelectedUsers.find(e => e.uuid === item.uuid) != undefined;
    },
    dataCombobox() {
      const result = { users: [], guests: [] };
      const users = this.state.group || {};
      for (const key in users) {
        const data = {
          name: (users[key].user || {}).name || "",
          uuid: key,
          addUserToCall: true,
          showUserName: true,
          statusColor: false, 
          disabled: false,
        };
        if (data) {
          if (data.uuid !== this.actingAsUuid && data.name !== "") {
            if (this.allSelectedUsers.find(e => e.value === data.uuid))
              continue;
            if (
              !users[key].user.visitor &&
              users[key].user.uuid !== this.state.ownUUID &&
              !users[key].user.inBridgeCall &&
              users[key].connected
            ) {
              data.statusColor = getFillColorByStatus(users[key])
              result.users.push(data);
            }
            if (
              users[key].user.visitor &&
              users[key].connected &&
              ((users[key].user || {}).name || "") != "" &&
              (((users[key].user || {}).visitorData || {}).userInviter || 0) ===
                store.state.ownUUID
            ) {
              data.statusColor = getFillColorByStatus(users[key])
              result.guests.push(data);
            }
          }
        }
      }

      result.users.sort((a, b) => a.name.localeCompare(b.name));
      result.guests.sort((a, b) => a.name.localeCompare(b.name));

      return result;
    }
  }
};
</script>

<style  lang="scss">
.statusSpan{
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  top: 14px;
}
.theme--light.v-input, 
.theme--light.v-input input, 
.theme--light.v-input textarea {
    color: black !important;
}
</style>