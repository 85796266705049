var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "720" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.$t("generics.messages"),
                  closeModalFunction: _vm.closeModal
                }
              }),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { style: { width: "100%", margin: 0 }, attrs: { flex: "" } },
                _vm._l(1, function(n) {
                  return _c("v-col", { key: n, staticClass: "col-12" }, [
                    n == 1
                      ? _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                staticClass: "borderRadius4",
                                staticStyle: { margin: "0px 10px" },
                                style: _vm.getBorderByStatusFull(
                                  _vm.state.group[_vm.showModal],
                                  "2px"
                                ),
                                attrs: { tile: "", size: "76" }
                              },
                              [
                                _vm.getAvatarForUuid(_vm.showModal) ===
                                "img/default_profile_picture.png"
                                  ? _c("DefaultAvatar", {
                                      attrs: {
                                        size: 76,
                                        userData: _vm.historyMessagesInfo.user
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getAvatarForUuid(_vm.showModal) !==
                                "img/default_profile_picture.png"
                                  ? _c("v-img", {
                                      staticClass: "borderRadius4",
                                      attrs: {
                                        "max-height": "76",
                                        "max-width": "76",
                                        contain: "",
                                        src: _vm.getAvatarForUuid(
                                          _vm.showModal
                                        ),
                                        "lazy-src":
                                          "img/default_profile_picture.png"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { style: { width: "80%", margin: 0 } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 pt-1 d-flex flex-column",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "userNameCard mb-0 pb-0 mr-auto",
                                        attrs: { color: "primary" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.getNameForUuid(_vm.showModal)
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "pt-7" },
                                      [
                                        !_vm.isWaitingRoomUser
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.disableCallBtn(
                                                                      _vm.showModal
                                                                    ) == true
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    return _vm.callUser(
                                                                      _vm.showModal
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    fontSize:
                                                                      "16px"
                                                                  },
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "phone"
                                                                    ],
                                                                    color: _vm.setBorderByStatus(
                                                                      _vm.state
                                                                        .group[
                                                                        _vm
                                                                          .showModal
                                                                      ]
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userProfile.callUser"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.messageHistory.length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _c("DeleteBasket", {
                                                  attrs: {
                                                    indexData: _vm.showModal,
                                                    delFunction:
                                                      _vm.deleteMessage,
                                                    fontSize: "16",
                                                    prevent: "true"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  class: { heightMobileMessages: _vm.isMobile },
                  style: { width: "100%", margin: 0 },
                  attrs: { flex: "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12" },
                    [
                      [
                        _c(
                          "v-container",
                          {
                            ref: "messageContainer",
                            class:
                              "contentMessagesIsReply " +
                              (_vm.isMobile
                                ? "contentMessagesIsReplyMobile"
                                : "")
                          },
                          [
                            _c("MessageHistoryEffector", {
                              attrs: { messages: _vm.messageHistory }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "px-6" }, [_c("v-divider")], 1)
                      ]
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  class: _vm.isDark
                    ? "footersBackgroundMessagesDark"
                    : "footersBackgroundMessages"
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-2 btns",
                      style: { width: "100%", margin: 0 },
                      attrs: { flex: "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0 d-flex align-center",
                          attrs: { lg: "12", align: "right" }
                        },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "inputMessageWaitingRoom",
                              attrs: {
                                "background-color": _vm.isDark
                                  ? "#1e1e1e"
                                  : "#fff",
                                autofocus: "",
                                "hide-details": "",
                                outlined: "",
                                "single-line": "",
                                maxlength:
                                  (!_vm.replyMessage.trim().length && 960) ||
                                  (_vm.replyMessage.match(/(https?:|www\.)/i)
                                    ? 2048
                                    : 480),
                                autocomplete: "off",
                                dense: "",
                                label: _vm.$t(
                                  "components.notificationsModal.reply"
                                )
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.replyMessage.trim().length > 0
                                    ? _vm.sendReply()
                                    : null
                                }
                              },
                              model: {
                                value: _vm.replyMessage,
                                callback: function($$v) {
                                  _vm.replyMessage = $$v
                                },
                                expression: "replyMessage"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "append" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        icon: "",
                                        disabled:
                                          _vm.replyMessage.trim().length === 0
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.sendReply()
                                        }
                                      }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: {
                                          icon: ["fal", "paper-plane"],
                                          color: "primary"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3 mr-2 greyBtn",
                              staticStyle: {
                                "border-radius": "4px",
                                "min-width": "45px"
                              },
                              attrs: { icon: "", tile: "", outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }