<template>
  <!-- Incoming call modal  -->
  <div>
    <v-dialog :fullscreen="isMobile" :model-value="haveCalls" @update:model-value="haveCalls = $event"  persistent max-width="700" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingToneComputed" autoplay loop></audio>
      <audio v-if="shouldPlayRinging" id="ringingSource" ref="ringingSource" :src="playingRingToneComputed" autoplay loop></audio>
      <IncomingBridgeCallSub :key="call.uuid" v-for="call in incomingCallsArray" tabindex="0" :call="call" />
    </v-dialog>
    <v-dialog :fullscreen="isMobile" :model-value="haveConferenceCalls" @update:model-value="haveConferenceCalls = $event" persistent max-width="700" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingToneComputed" autoplay loop></audio>
      <audio v-if="shouldPlayRinging" id="ringingSource" ref="ringingSource" :src="playingRingToneComputed" autoplay loop></audio>
      <ConferenceIncomingBridgeCallSub :key="call.uuid" v-for="call in incommingConfCallsArray" tabindex="0" :call="call" />
    </v-dialog>
  </div>
  <!-- end incoming call modal -->
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import IncomingBridgeCallSub from "./incomingBridgeCallSub.vue";
import ConferenceIncomingBridgeCallSub from "./conferenceIncomingBridgeCallSub.vue";

export default {
  components: { IncomingBridgeCallSub, ConferenceIncomingBridgeCallSub },
  data() {
    return {
      state: store.state,
      shouldPlayRinging: true,
      isMobile: isMobile()
    };
  },
  watch: {
    playingRingToneComputed: {
      deep: true,
      handler:function (newSrc){
        this.$nextTick(() => {
          if (this.$refs.audioSource && newSrc) {
            this.$refs.audioSource.volume = this.volumCallTone;
            this.$refs.audioSource.muted = false; // Asegurar que no esté en mute
          }
          if (this.$refs.ringingSource && newSrc) {
            this.$refs.ringingSource.volume = this.volumCallTone;
            this.$refs.ringingSource.muted = false;
          }
      });
      }
    },
    playingConfRingToneComputed: {
      deep: true,
      handler:function (newSrc){
        this.$nextTick(() => {
          if (this.$refs.audioSource && newSrc) {
            this.$refs.audioSource.volume = this.volumCallTone ?? 1.0;
            this.$refs.audioSource.muted = false;
          }
          if (this.$refs.ringingSource && newSrc) {
            this.$refs.ringingSource.volume = this.volumCallTone ?? 1.0;
            this.$refs.ringingSource.muted = false;
          }
        });
      }
    },
  },
  mounted() {
    const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
    const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
    // #87 - Check if we have a different ringing device and play ringing sound
    if (this.$refs.ringingSource) {
      const ringingElement = this.$refs.ringingSource;
      if (!ringingDeviceId || !audioDeviceId || ringingDeviceId === audioDeviceId) {
        this.shouldPlayRinging = false; // Don't play double sound if no different ringing Device
      } else if ('sinkId' in ringingElement && 'setSinkId' in ringingElement) {
        ringingElement.setSinkId(ringingDeviceId)
          .then(() => {
            console.debug('Success, ringing output device attached:', ringingDeviceId);
          })
          .catch((error) => {
            console.warn('Error, unable to attach ringing output device:', error);
          });
      } else {
        this.shouldPlayRinging = false;
      }
    }
    // Check if selected audioDevice is different from Windows Default device
    if (this.$refs.audioSource && audioDeviceId && audioDeviceId !== 'default') {
      const audioElement = this.$refs.audioSource;
      if ('sinkId' in audioElement && 'setSinkId' in audioElement) {
        audioElement.setSinkId(audioDeviceId).then(() => {
          console.debug('Success, setting main audio element with ID:', audioDeviceId);
        }).catch((error) => {
          console.warn('Possible Error, unable to attach main audio output device, will use browser default audio output.\n', error);
        });
      }
    }
    // Avoid double ringtone: https://gitlab.ra-micro.de/devcups/voffice/-/issues/468
    if (this.$refs.audioSource && this.shouldPlayRinging) {
      this.$refs.audioSource.muted = true;
      this.$refs.audioSource.volume = 0;
    }
    this.$nextTick(() => {
      if (this.$refs.audioSource && !this.shouldPlayRinging) {
        this.$refs.audioSource.volume = this.volumCallTone;
        this.$refs.audioSource.muted = false;
      }
      if (this.$refs.ringingSource) {
        this.$refs.ringingSource.volume = this.volumCallTone;
        this.$refs.ringingSource.muted = false;
      }
    });
  },
  beforeUnmount() {
    // Remove ringing source after call is answered or rejected
    if (this.$refs.ringingSource) this.$refs.ringingSource.volume = 0;
    if (this.$refs.audioSource) this.$refs.audioSource.volume = 0;
    this.shouldPlayRinging = false;
  },
  methods: {},
  computed: {
    playingConfRingToneComputed() {
      if (this.state.persisted.notificationsMuted) return "";
      if (!this.incommingConfCallsArray.length) return "";
      
      for (let call of this.incommingConfCallsArray) {
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    },
    playingRingToneComputed() {
      if (this.state.persisted.notificationsMuted) return "";
      for (let call of this.incomingCallsArray) {
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    },
    volumCallTone() {
      let volum = store.state.persisted.mediaDeviceSetup.volumeCallTone;
      if (isNaN(volum)) {
        volum = 100;
      }
      volum = volum / 100;
      return volum;
    },
    haveCalls() {
      if (store.state.nerd) console.debug('have calls', this.incomingCallsArray.length);
      return this.incomingCallsArray.length > 0;
    },
    haveConferenceCalls() {
      if (store.state.nerd) console.debug('have conference calls', this.incommingConfCallsArray.length);
      return this.incommingConfCallsArray.length > 0;
    },
    incommingConfCallsArray() {
      const confCalls = [];
      for (let uuid in store.state.incomingBridgeCallModal.calls) {
        const call = store.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        if (call.isConference){
          confCalls.push({ ...call, uuid });
        }
      }
      return confCalls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    incomingCallsArray() {
      const calls = [];
      for (let uuid in store.state.incomingBridgeCallModal.calls) {
        const call = store.state.incomingBridgeCallModal.calls[uuid];
        if (!call) continue;
        if (!call.isConference){
          calls.push({ ...call, uuid });
        }
      }
      return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    // playingRingTone() {
    //   if (this.state.persisted.notificationsMuted) return "";
    //   // if (!this.incomingCallsArray.length) return "";
    //   for (let call of this.incomingCallsArray) {
    //     if (this.$refs.audioSource) {
    //       this.$refs.audioSource.volume = this.volumCallTone;
    //     }
    //     if (this.$refs.ringingSource) {
    //       this.$refs.ringingSource.volume = this.volumCallTone;
    //     }
    //     if (
    //       store
    //         .getNameForUuid(call.uuid)
    //         .toLowerCase()
    //         .indexOf("lazy") !== -1
    //     ) {
    //       return "/media/incommingCallLazy.mp3";
    //     }
    //   }
    //   return "/media/incommingCall1.mp3";
    // },
    // playingConfRingTone() {
    //   if (this.state.persisted.notificationsMuted) return "";
    //   if (!this.incommingConfCallsArray.length) return "";
    //   for (let call of this.incommingConfCallsArray) {
    //     if (this.$refs.audioSource) {
    //       this.$refs.audioSource.volume = this.volumCallTone;
    //     }
    //     if (this.$refs.ringingSource) {
    //       this.$refs.ringingSource.volume = this.volumCallTone;
    //     }
    //     if (
    //       store
    //         .getNameForUuid(call.uuid)
    //         .toLowerCase()
    //         .indexOf("lazy") !== -1
    //     ) {
    //       return "/media/incommingCallLazy.mp3";
    //     }
    //   }
    //   return "/media/incommingCall1.mp3";
    // },
  }
};
</script>
<style scoped lang="scss">
</style>
