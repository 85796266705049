var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "580" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.toggleMeetingTimeTitle.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.meetingTime.messages"),
              closeModalFunction: _vm.toggleMeetingTimeTitle
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 px-3" },
            [
              _c("v-col", { staticClass: "col-12 px-1 py-1 pt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    !_vm.editingText
                      ? [
                          _c(
                            "v-card-title",
                            { staticClass: "text-truncate body-1" },
                            [_vm._v(_vm._s(_vm.titleText))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "my-3",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editText()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "pen"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                799354578
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.meetingTime.editText")
                                  )
                                )
                              ])
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editingText
                      ? [
                          _c("v-text-field", {
                            staticClass: "body-2",
                            attrs: { label: "" },
                            model: {
                              value: _vm.titleText,
                              callback: function($$v) {
                                _vm.titleText = $$v
                              },
                              expression: "titleText"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "my-3",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveText()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "check"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3989074866
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.save")))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "my-3",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancelText()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "times"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3824315957
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.cancel")))
                              ])
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.toggleMeetingTimeTitle }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }