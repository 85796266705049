var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "740" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.aditionalStatusInfoModal.title"),
              closeModalFunction: _vm.saveStatus
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                attrs: {
                  id: "textareaStatus",
                  name: "input-7-1",
                  counter: "",
                  "no-resize": "",
                  maxlength: "150"
                },
                model: {
                  value: _vm.additionalStatus,
                  callback: function($$v) {
                    _vm.additionalStatus = $$v
                  },
                  expression: "additionalStatus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.additionalStatus.length === 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveStatus()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }