<template>
<v-flex class="mt-90">
  <v-card fill-height class="mx-auto elevation-0" flat max-width="100%" v-bind:class="{'v-card-ios': isIos || isSafari}">
      <!-- <v-img 
      class="imgLogo"
      contain
      src="assets/icon.png"
      lazy-src="assets/icon.png"
    ></v-img> -->
    <v-card-title class="titleWelcome mx-0 my-0 py-0">{{ $t('components.conditions.terms') }}</v-card-title>
    <vue-scroll class="vueScrollConditions">
      <div>
        <div class="px-4 conditionsContent" >
         Allgemeine Geschäftsbedingungen
          Um fortzufahren, lesen und akzeptieren Sie die AGB.

          Die VOffice (nachfolgend auch „VOffice“) bietet VOffice Online („VOffice“) Services und Software (im Folgenden auch: „Dienste“, „Produkte“, Software oder Services) für diverse Plattformen an.

          § 1 Allgemeines
          Diese Allgemeinen Geschäftsbedingungen (AGB) stellen die Grundlage für die Nutzung der VOffice Services dar und regeln die vertraglichen Beziehungen zwischen Anbieter(n) und dem Kunden (im Folgenden auch Nutzer, Benutzer, Besteller oder Anwender genannt). Wenn und soweit es für Produkte gesonderte Lizenzvereinbarungen gibt (so z.B. Softwarelizenzverträge bzw. Abo-Bedingungen im VOffice-Store, AGB von Drittanbietern etc.), so gelten diese ergänzend.

          Leistungen von VOffice erfolgen ausschließlich auf der Grundlage dieser AGB in ihrer zum Zeitpunkt der Nutzung jeweils gültigen Fassung. Abweichende Regelungen haben nur dann Geltung, wenn sie zwischen allen Parteien schriftlich vereinbart worden sind. Dieses Schriftformerfordernis gilt auch für die Abbedingung der Schriftform.

          Die Registrierung für das VOffice Kunden-Konto ist kostenlos. Es können nutzungsabhängige oder laufende Kosten entstehen, wenn und soweit VOffice Services in Anspruch genommen bzw. Produkte oder Dienste lizenziert, aktiviert bzw. abonniert werden. Die Nutzung des VOffice Kunden-Kontos setzt die wirksame Erteilung und Aufrechterhaltung einer gültigen Einzugsermächtigung (SEPA-Lastschriftmandat) oder PayPal Abozahlung voraus.

          VOffice ist berechtigt, Kunden aus wichtigem Grund unverzüglich ganz oder teilweise zu sperren, z.B. wenn gegen vertragswesentliche Pflichten verstoßen wird.

          § 1a Grundsätzliches
          Anwender, die sich mit der VOffice registrieren, bestätigen, dass Sie

          • eine etwaige Statusänderung unverzüglich schriftlich anzeigen
          • von Ihrem Unternehmen zur Registrierung bevollmächtigt sind.

          § 1b Schriftform, elektronischer Kommunikationsweg

          Mit dem Kunden gilt der elektronische Kommunikationsweg als vereinbart.

          § 2 Vertragsverhältnis, Leistungsbeschreibung
          1. Der Inhalt des Vertragsverhältnisses zwischen VOffice und dem Kunden ergibt sich aus diesen AGB, den VOffice Webseiten inkl. Online-Produktinformationen und VOffice Produktblättern.

          2. Auf aktuelle Meldungen, Produktblätter, Newsletter, Kundeninformationen wird ausdrücklich hingewiesen. Sofern nicht anders angegeben, unterliegen die Bereitstellung neuer Dienste und die Erweiterung von bestehenden Services diesen AGB.

          3. VOffice behält sich jederzeit Änderungen an Services im Zuge von Produktentwicklung und Produktpflege vor. VOffice kann Inhalte/Merkmale einzelner Dienste bzw. die Dienste selbst aus wichtigem Grund vorübergehend oder auf Dauer ändern oder einstellen, soweit dies dem Kunden zumutbar ist bzw. ein für den Kunden nachvollziehbarer, im Voraus als gewichtiger Grund erkennbarer, triftiger Umstand vorliegt.

          Ein solcher gewichtiger Grund für eine Einschränkung, Einstellung oder Änderung der VOffice Services liegt insbesondere dann vor, wenn

          • aufgrund von rechtlichen, insbesondere auch datenschutzrechtlichen Gründen ein Dienst oder Produkt nicht mehr aufrechterhalten werden kann, oder
          • ein von einem Kooperationspartner ursprünglich zur Verfügung gestelltes Produkt nicht oder nicht mehr verfügbar ist, oder
          • sich mit der Zurverfügungstellung von Diensten oder Produkten im Zusammenhang mit der (versuchten) Aufrechterhaltung der Verfügbarkeit unüberwindliche technische Schwierigkeiten ergeben, oder
          • die Zurverfügungstellung von Diensten oder Produkten und die Aufrechterhaltung der Verfügbarkeit derselben aus betrieblichen, unternehmerischen Gründen nicht mehr sinnvoll erscheinen.

          VOffice wird etwaige Änderungen per E-Mail, in der Software oder auch über Webinformationen/ Webseiten mitteilen. Eine Benachrichtigung kann auch auf anderem Wege erfolgen. Das Vertragsverhältnis wird dann mit den neuen Bedingungen/Merkmalen fortgesetzt.


          § 3 Zustandekommen des Vertrages
          1. Der Vertrag zwischen VOffice und dem Kunden kommt nach Maßgabe von Ziffer 2. dadurch zustande, dass der Kunde sich registriert, sein Einverständnis in die vorliegenden AGB erklärt und der VOffice Datenschutzerklärung zustimmt (Angebot) und die Registrierung durch VOffice schriftlich, per E-Mail oder sonst elektronisch bestätigt wird bzw. bei einzelnen Produkten/Diensten durch Lizenzierung von entsprechenden Produkten gemäß Ziffer 2 im VOffice Store (Annahme).


          § 4 Pflichten und Obliegenheiten des Kunden
          1. Der Kunde ist für die richtige Eingabe seiner Daten bei Nutzung der VOffice Services verantwortlich und muss wahrheitsgemäße, genaue, aktuelle und vollständige Angaben zu seiner Person machen (Registrierungsdaten) und diese regelmäßig aktualisieren. Falls der Kunde hiergegen verstößt, bzw. falls VOffice dies annehmen sollte, insbes. dass Registrierungsdaten nicht aktuell bzw. unwahr oder unvollständig sind, ist VOffice berechtigt, das Online-Konto des Kunden vorübergehend oder auf Dauer ganz oder teilweise zu sperren bzw. zu löschen und den Kunden von jeglicher Nutzung einzelner oder sämtlicher Services gegenwärtig und in Zukunft auszuschließen. Das gleiche gilt bei erheblichen Verstößen gegen die dem Kunden obliegenden Pflichten sowie bei begründeten erheblichen Verdachtsmomenten für eine Pflichtverletzung durch den Kunden.

          2. Nach der Registrierung werden dem Kunden VOffice Zugangsdaten übermittelt. Zugangsdaten, insb. Passwörter (im Folgenden auch RMO VOffice angsdaten, Kennwörter oder Chiffren genannt), d.h. alle Buchstaben- und/oder Zahlenfolgen, welche dazu bestimmt sind, die Nutzung durch unberechtigte Personen auszuschließen, dürfen nicht an Dritte weitergegeben werden.

          VOffice Zugangsdaten sind vor dem Zugriff durch Dritte geschützt aufzubewahren und müssen zur Sicherheit in regelmäßigen Abständen geändert werden. Soweit Anlass zu der Vermutung besteht, dass unberechtigte Personen von den VOffice Zugangsdaten Kenntnis erlangt haben, hat der Kunde Kunden- bzw. Benutzer-Passwörter unverzüglich zu ändern und VOffice schriftlich zu informieren.
          Der Kunde hat zudem sicherzustellen, dass bei Nutzung von VOffice Diensten über den zentralen Internetzugang des lokalen Netzwerkes des Kunden dieses lokale Netzwerk gegen das Eindringen unberechtigter Personen geschützt ist.

          Der Kunde darf Zugangsdaten und Kennwörter in digitalen Medien nur in verschlüsselter (dem aktuellen Stand der Technik entsprechender) Form speichern oder übermitteln. Bei mehrmaliger Falscheingabe von Zugangsdaten kann dies zu einer Sperrung der Online-Kontos oder der Nutzungsmöglichkeit, für die das Kennwort gilt, führen.

          3. Der Kunde trägt für alle Handlungen, die unter Verwendung seiner VOffice Zugangsdaten in Verbindung mit seinem VOffice Online-Konto vorgenommen werden, die Verantwortung. Der Kunde ist verpflichtet, VOffice unverzüglich über jede missbräuchliche Benutzung der VOffice Zugangsdaten und/oder des Online-Kontos sowie über jegliche sonstige Verletzung von kundenbezogenen Sicherheitsvorschriften zu unterrichten.

          Der Kunde muss zudem sicherstellen, dass nach jeder Nutzung eine Abmeldung vom Online-Konto erfolgt.

          4. VOffice Software und Services werden laufend aktualisiert. Der Kunde wird über Updates informiert und kann entscheiden, ob er diese ausführen möchte oder nicht.

          Die Installation aktueller Updates ist Voraussetzung für den Betrieb neuester Funktionalitäten und der Funktionalität der VOffice Services insgesamt, derer Betrieb nicht oder nur eingeschränkt gegeben ist, wenn Update-Installationen unterbleiben.

          5. Der Kunde hat die Pflicht, seine Daten regelmäßig auf eigenen Medien zu sichern.

          6. Der Kunde ist für die Inhalte, welche über sein Online-Konto eingestellt oder verbreitet werden, verantwortlich, insbesondere für deren Rechtmäßigkeit. Die Verantwortung für sämtliche Medien und Inhalte, d.h. Informationen, Daten, Texte, Software, Fotos, Grafiken, Videos, Nachrichten oder sonstige Materialien, die übermittelt werden, liegt ausschließlich beim Kunden, d.h. beim Nutzer und Verwender.
          Der Kunde darf keine Inhalte versenden, die schädlichen Code, Software-Viren, Spam- oder Werbe-Code bzw. andere Informationen, Dateien oder Programme enthalten, die die Funktion von Soft- oder Hardware oder von Telekommunikationsvorrichtungen unterbrechen, zerstören und/oder einschränken können. Der Kunde darf auch nicht in die den VOffice Diensten zugrundeliegenden Server oder Netzwerke eingreifen oder diese unterbrechen.
          Der Kunde darf VOffice Dienste nicht als Wiederverkäufer nutzen.

          7. Der Kunde wird ausdrücklich darauf hingewiesen, dass er für die Einhaltung der berufs- und standesrechtlichen wie auch der allgemeinen Vorschriften des Strafgesetzbuches, des Datenschutzrechtes und der Vorschriften zum Schutz von Persönlichkeitsrechten im Rahmen der Nutzung der VOffice Dienste verantwortlich ist.

          8. Der Kunde wird explizit darauf hingewiesen, dass Informationen über Änderungen /Verbesserungen /Einstellungen an bzw. von Produkten- bzw. Diensten regelmäßig über die VOffice-Webseiten publiziert werden. Der Kunde hat sich regelmäßig über das insoweit auf der Webseite publizierte Leistungsangebot zu informieren (siehe insoweit auch § 2 dieser AGB).

          9. Soweit spezielle Systemvoraussetzungen für die Nutzung von VOffice-Diensten oder Produkten genannt sind, so hat der Kunde sicherzustellen, dass diese durch sein System erfüllt werden.

          10. Der Kunde stellt VOffice von jeglichen Ansprüchen Dritter, denen aufgrund der Verletzung von Bestimmungen in diesen AGB ein Schaden entstanden ist, frei. VOffice behält sich vor, bei Verstoß gegen diese AGB das Online-Konto des Kunden zu sperren und ggf. zu löschen, sowie auch Inhalte des Kunden auf VOffice Servern nicht mehr weiter allgemein zugänglich zu machen bzw. zu löschen.


          § 5 Datenschutz
          1. VOffice weist den Kunden darauf hin, dass die im Rahmen des Vertragsschlusses aufgenommenen Daten gemäß dem Bundesdatenschutzgesetz (BDSG) und der ab 25.05.2018 geltenden EU-Datenschutzgrundverordnung (DS-GVO) sowie dem Telemediengesetz (TMG) von RMO zur Erfüllung der Verpflichtungen aus dem Vertrag erhoben, verarbeitet und genutzt werden.

          Dies gilt insbesondere für die personenbezogenen Daten des Kunden, d.h. seine Kontaktinformationen, einschließlich Namen, Telefon- und Telefaxnummern sowie E-Mail Adressen. Personenbezogene Kunden- und Abrechnungsdaten können im Rahmen der bestehenden Geschäftsbeziehung verarbeitet und genutzt werden.

          Die vorgenannten Daten (mit Ausnahme der VOffice Zugangsdaten) können zum Zweck von Bonitätsprüfungen auch an Beauftragte und gemäß Art. 28 DS-GVO sorgfältig ausgesuchte Partner von RAM übermittelt werden. Eine Weitergabe personenbezogener Kundendaten durch VOffice an Dritte kann nur erfolgen, wenn und soweit eine durch Gesetz begründete Rechtspflicht hierzu besteht.

          2. Der Kunde wird darauf hingewiesen, dass die im Zuge der Nutzung der VOffice Recherchen erlangten Daten nur im Rahmen einer bereits bestehenden Kundenbeziehung (z.B. Wiederaufnahme des Kontaktes mit unbekannt verzogenen Geschäftspartnern) oder zur Durchsetzung von Rechtsansprüchen (vorgerichtliche und gerichtliche Korrespondenz) zu verwenden sind (berechtigtes Interesse) und diese zudem weder zum Zwecke der Markt- oder Meinungsforschung genutzt, noch an Dritte weitergegeben werden dürfen.

          3. Es gelten ergänzend die allgemeine VOffice Datenschutzerklärung sowie etwaige produktbezogene Bestimmungen, die bei der Nutzung angezeigt werden können.


          § 5a Datenschutz, Dateien und Daten – Schnittstellen zu Drittanbietern
          Einige VOffice Produkte können Schnittstellen zu Anwendungen und Produkten von Drittanbietern bieten. Für die Nutzung dieser Anwendungen der Drittanbieter ist es erforderlich, dass der Kunde sich dort anmeldet und die dortigen Nutzungs-, Geschäftsbedingungen akzeptiert. VOffice macht darauf aufmerksam, dass diese Anwendungen von Drittanbietern möglicherweise abweichende Regelungen für die Nutzung von Kunden-Daten und Kunden-Dateien getroffen haben. Bei der dortigen Registrierung sollte der Kunde diese Bedingungen sowie die Datenschutzrichtlinien prüfen und jeweils über die Nutzung der bereitgestellten Funktionalitäten entscheiden. VOffice weist ferner bei der erstmaligen Aktivierung von VOffice Software bzw. Applikationen darauf hin, dass Produkte von Drittanbietern genutzt werden (können). Der Kunde hat jederzeit die Möglichkeit, die Nutzung der Schnittstelle/n zu beenden, wobei dann der Funktionsumfang der VOffice Software eingeschränkt sein kann.


          § 6 Haftungsbeschränkung
          1. VOffice haftet nicht für Schäden, die kausal aus einer vom Kunden zu vertretenden Pflichtverletzung herrühren, soweit diese bei pflichtgemäßem Handeln des Kunden nicht eingetreten wären. Zudem wird der Kunde darauf hingewiesen, dass die Datenkommunikation über das Internet nach dem derzeitigen Stand der Technik nicht gänzlich fehlerfrei und/oder jederzeit verfügbar gewährleistet werden kann. RMO haftet daher weder VOffice die ständige und ununterbrochene Verfügbarkeit des Onlinesystems noch für etwaige technische und elektronische Fehler der RMO Software, Applikationen und Services.

          VOffice wird sich bestmöglich bemühen, die VOffice Services mit so wenigen Unterbrechungen wie möglich bereitzustellen, kann aber nicht zu 100% sicherstellen, dass die Produkte und Dienste immer ohne Unterbrechungen, Verzögerungen oder sonstige Mängel funktionieren. Dies liegt daran, dass die Produkte über (öffentliche) Internetleitungen übertragen werden können und daher Stromausfälle oder Internetdienstunterbrechungen möglich sind, es z.B. so zu Ausfällen kommen kann, z.B. Datenpaketverluste und Verzögerungen, die die Qualität der Kommunikation beeinträchtigen können.

          Generell gilt: Eine etwaige Haftung von VOffice (Ansprüche des Kunden auf Schadensersatz oder Ersatz vergeblicher Aufwendungen), sofern eine solche aus welchem Rechtsgrund auch immer gegeben sein sollte, richtet sich nach den Bestimmungen dieses Paragrafen.

          2. Für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer fahrlässigen Pflichtverletzung des Dienstleisters oder einer vorsätzlichen oder fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen des Dienstleisters beruhen, haftet VOffice unbeschränkt.

          3. Bei den übrigen Haftungsansprüchen haftet VOffice unbeschränkt nur bei nicht Vorhandensein der garantierten Beschaffenheit sowie für Vorsatz und grobe Fahrlässigkeit auch seiner gesetzlichen Vertreter und leitenden Angestellten.

          VOffice haftet für das Verschulden sonstiger Erfüllungsgehilfen nur im Umfang der Haftung für leichte Fahrlässigkeit nach Abs. 4.

          4. Für leichte Fahrlässigkeit haftet VOffice nur, sofern eine Pflicht verletzt wird, deren Einhaltung für die Erreichung des Vertragszwecks von besonderer Bedeutung ist (Kardinalpflicht). Bei der leicht fahrlässigen Verletzung einer Kardinalpflicht ist die Haftung beschränkt auf den vorhersehbaren, vertragstypischen Schaden.

          5. Die Haftung für Datenverlust wird auf den typischen Wiederherstellungsaufwand beschränkt, der bei regelmäßiger und gefahrenentsprechender Anfertigung von Sicherungskopien eingetreten wäre. Es sei denn, es liegt eine der Voraussetzungen nach Abs. 2 oder Abs. 3 vor.

          6. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.

          7. Die vertraglichen Haftungsansprüche verjähren nach einem Jahr.

          8. Der Kunde ist sich bei Verwendung einer als „Betaversion“ gekennzeichneten Software oder eines Dienstes/Services darüber bewusst, dass die Software bzw. der Dienst/Service noch fehlerhaft sein kann und Beschädigungen am System unter Umständen möglich sein können.

          Der Kunde ist damit einverstanden, im Falle solcher Beschädigungen keine direkten oder indirekten Ansprüche an VOffice zu stellen.

          VOffice versichert, jeden vom Kunden benannten Softwarefehler, der Dringlichkeit und den technischen Gegebenheiten entsprechend, schnellstmöglich beheben zu wollen.
          Der Kunde stellt sicher, dass durch den Einsatz einer als Betaversion gekennzeichneten Software bzw. eines Dienstes/Services keine Systeme betroffen werden können, die in irgendeiner Form sicherheitsrelevant sind.


          § 7 Haftung des Kunden, Folgen von Obliegenheitsverletzungen
          Bei durch ihn zu vertretenden Verletzungen von Rechten Dritter haftet der Kunde gegenüber diesen Dritten selbst und unmittelbar. Bei begründeten Ansprüchen Dritter ist der Kunde verpflichtet, VOffice freizustellen, wobei etwas Anderes nur dann gilt, soweit der Kunde nachweist, dass er die schadensursächliche Pflichtverletzung nicht zu vertreten hat.


          § 8 Zahlungspflichten, Abrechnung, Verwaltung, elektronische Rechnung
          Der Kunde erhält eine monatliche Rechnung, in der ausdrücklich auf eine Zahlungsfrist hingewiesen wird. Die Rechnung erfolgt in elektronischer Form und wird monatlich im Kunden-Konto des VOffice Online-Kontos zur Ansicht und zum Download bereitgestellt. Im Falle des Zahlungsverzuges gelten die gesetzlichen Regeln. Ein Aufrechnungsrecht steht dem Kunden nur zu, soweit seine Gegenforderung rechtskräftig festgestellt, unbestritten oder anerkannt ist. Das Zurückbehaltungsrecht, insbesondere die Einrede des nicht erfüllten Vertrages, bleibt unberührt. VOffice kann – unbeschadet anderer Rechte – das VOffice Online-Konto eines Kunden aufgrund erheblicher Pflichtverletzungen sperren, z.B. im Falle eines Zahlungsverzuges in Höhe von mindestens 50,- €. Im Falle einer Sperrung bestehen die Zahlungspflicht sowie die Geltendmachung weiterer Ansprüche wegen Zahlungsverzuges fort.


          § 9 Kündigung, Laufzeit
          1. Das Vertragsverhältnis kann vom Kunden jederzeit, jedoch nicht vor Ablauf der Kündigungsfrist eines von ihm abonnierten Produktes, mit sofortiger Wirkung schriftlich gekündigt werden. (Mindest-)Laufzeiten, Kündigungsfristen von Produkten und Services sind im VOffice Online Konto, dort im VOffice-Store beim jeweiligen Dienst bzw. Produkt genannt und werden durch Aktivierung eines Abos bzw. einer Lizenz vom Kunden akzeptiert im Rahmen des Bestellvorganges. Sofern nicht anders im VOffice Store technisch realisiert, hat eine Kündigung vom Kunden in Schriftform zu erfolgen.
          Von VOffice kann das Vertragsverhältnis mit einer Frist von 2 Wochen zum Ende eines Kalendermonats schriftlich gekündigt werden.

          2. Das Vertragsverhältnis kann zudem von VOffice fristlos aus wichtigem Grund außerordentlich gekündigt werden, so insbesondere, wenn der Kunde mit der Zahlung für zwei aufeinander folgende Monate bzw. mit einem nicht unerheblichen Teil gegenüber VOffice in Verzug ist oder VOffice das Festhalten an dem Vertragsverhältnis nicht weiter zugemutet werden kann, z. B. weil die Geschäftsgrundlage wegefallen ist oder das vertragliche Vertrauensverhältnis nicht mehr besteht.

          3. Sofern nichts Anderes vereinbart, ist mit Beendigung des Vertragsverhältnisses der Kunde verpflichtet, die VOffice Software unverzüglich von seinen Systemen zu entfernen.


          § 10 Auftragsverarbeitung, Geltung von weiteren AGB und besondere Bestimmungen
          1. Der Kunde ist im Rahmen der Auftragsverarbeitung in Bezug auf die über VOffice Dienste angefragten bzw. übermittelten Daten und Datensätze für die Einhaltung sowohl der EU-Datenschutzgrundverordnung (DS-GVO) als auch der anderen Bestimmungen über den Datenschutz allein verantwortlich. Er verpflichtet sich, VOffice unter Angabe von Kontaktdaten (Name, Organisation, Telefonnummer etc.) die Personen schriftlich mitzuteilen, die gegenüber VOffice in Bezug auf die Auftragsdatenbearbeitung weisungsberechtigt sind oder als Ansprechpartner fungieren.

          2. Der Kunde ist ferner verpflichtet, den Auftragnehmer über bekannt gewordene Fehler oder Unregelmäßigkeiten bei der Auftragsdatenverarbeitung unverzüglich und vollständig zu unterrichten.

          3. VOffice verpflichtet sich, die an sie übermittelten Daten zweckbestimmt und nur im Rahmen der Weisungen (zu Weisung, Weisungsberechtigung s. o.) des Kunden (d. h. allein im Rahmen der Nutzung der VOffice Dienste) zu erheben, zu verarbeiten und zu nutzen. VOffice trifft die erforderlichen technischen und organisatorischen Maßnahmen im Sinne des Art. 32 DS-GVO, um die Einhaltung von Datenschutzvorschriften zu gewährleisten.

          VOffice unterrichtet den Kunden umgehend bei schwerwiegenden Störungen des Betriebsablaufs, bei Verdacht auf Datenschutzverletzungen oder andere Unregelmäßigkeiten bei der Verarbeitung der Daten des Kunden.

          4. Auf schriftliche Anfrage des Kunden hin wird VOffice diesem jene Informationen bereitstellen, die der Kunde zur Erfüllung seiner datenschutzrechtlichen Verpflichtungen benötigt.

          5. Der Kunde ist damit einverstanden, dass VOffice zur Erfüllung der vertraglich vereinbarten Leistungen verbundene Unternehmen von VOffice sowie weitere Dritte zur Leistungserfüllung heranziehen kann bzw. Unternehmen mit Leistungen unterbeauftragen kann (Subunternehmer).
          Wenn und soweit Subunternehmer eingeschaltet werden, so werden die vertraglichen Vereinbarungen zwischen VOffice und dem Subunternehmer so gestaltet, dass sie den Anforderungen zu Vertraulichkeit, Datenschutz und Datensicherheit zwischen den Vertragspartnern dieses Vertrages entsprechen.

          § 11 Änderungen von AGB, Leistungsbeschreibungen, Preise, Preiserhöhungen, Leistungen von Drittanbietern
          1. Soweit wesentliche Bestimmungen des geschlossenen Vertrages nicht tangiert werden und es zur Anpassung an aktuelle Entwicklungen erforderlich ist, welche bei Vertragsschluss nicht vorhersehbar waren und deren Nichtberücksichtigung die Ausgewogenheit des Vertragsverhältnisses merklich beeinträchtigen würde, ist eine Änderung dieser AGB zulässig.

          Die AGB können auch angepasst, ergänzt oder sonst verändert werden, soweit dies zur Beseitigung von etwaigen Schwierigkeiten bei der Durchführung des Vertrages, z.B. aufgrund von nach Vertragsschluss entstandenen Regelungslücken erforderlich ist, so z.B. wenn sich die Rechtsprechung ändert und eine oder mehrere Klauseln dieser AGB hiervon betroffen sind.

          2. Auch können die z. B. in Produktblättern enthaltenen Leistungsbeschreibungen aus wichtigem Grund geändert werden, soweit der Kunde hierdurch gegenüber der bei Vertragsschluss einbezogenen Leistungsbeschreibung objektiv nicht schlechter gestellt wird.

          3. Für die Inanspruchnahme der einzelnen Leistungen gelten die genannten Preise im Online-Konto, VOffice-Store bzw. die innerhalb des Produktes bzw. Dienstes angegebenen Preise. Alle genannten Preise für die einzelnen kostenpflichtigen Inhalte bzw. Dienste sind Netto-Preise und verstehen sich zzgl. Umsatzsteuer (derzeit 19%). Entstandene Kosten werden monatlich zusammengefasst abgerechnet und auf Grundlage der erteilten Einzugsermächtigung eingezogen. Wird der Rechnungsbetrag trotz erteilter Einzugsermächtigung aus Gründen zurückgebucht, die VOffice nicht zu vertreten hat, ist VOffice berechtigt, eine Pauschale für die entstandenen Bankgebühren in Höhe von bis zu 10,00 € zu erheben.“

          4. Vereinbarte Preise, insb. Preise von laufenden Abos, können zum Ausgleich von gestiegenen Kosten erhöht werden, so z.B. auch, wenn die Umsatzsteuer erhöht wird, eine Preiserhöhung von der Bundesnetzagentur aufgrund von Regulierungsvorschriften verbindlich gefordert wird oder sich die Preise unserer Dienstleister erhöhen.

          Preisänderungen/Preiserhöhungen werden dem Kunden von VOffice mit einer Frist von einem Monat zum Monatsende schriftlich bekannt gegeben.

          Die Änderungen gelten als vom Kunden genehmigt, wenn der Kunde nicht schriftlich innerhalb von einem Monat nach Bekanntgabe der Änderungen Widerspruch erhebt. Auf diese Folge wird der Kunde von VOffice bei der Änderung ausdrücklich hingewiesen. Zur Fristwahrung genügt die fristgerechte Absendung des Widerspruches.

          5. Werden Leistungen in Anspruch genommen, die von Drittanbietern erbracht werden, so gelten die Allgemeinen Geschäftsbedingungen dieser Anbieter ergänzend. Eine Liste der jeweils aktuell gültigen Bedingungen wird auf den VOffice Webseiten veröffentlicht.


          § 12 Probe-, Test- und Basisversionen von Software bzw. Diensten
          VOffice kann Software, Services und Applikationen mit kostenloser, eingeschränkter Basisfunktionalität, z.B. auch für einen bestimmten Probezeitraum, anbieten. Über Art und Umfang der Funktionalitäten wird VOffice jeweils informieren. VOffice ist berechtigt, mit Ablauf des Probezeitraums Daten und Datenbestände des Kunden auf VOffice-Servern zu löschen.

          § 13 Sonstiges
          1. Es gilt das Recht der Bundesrepublik Deutschland. Die Geltung des UN-Kaufrechts wird ausgeschlossen.

          2. Ausschließlicher Gerichtsstand ist Berlin. Dies gilt auch, wenn der Kunde keinen allgemeinen Gerichtsstand im Inland hat, ein Kunde nach Vertragsabschluss seinen Wohnsitz oder gewöhnlichen Aufenthalt in das Ausland verlegt hat oder sein Wohnsitz oder gewöhnlicher Aufenthaltsort zum Zeitpunkt der Klageerhebung unbekannt ist.

          3. Rechte und Pflichten aus diesem Vertrag können nur nach vorheriger schriftlicher Zustimmung von VOffice auf Dritte übertragen werden.
      </div>
      </div>
    </vue-scroll>

    <v-divider class="mt-4"></v-divider>
    
    <v-row class="mx-0">
      
       <div class="col-12" align="right">
          <!-- <v-btn color="primary" :isLarge="true" class="btnWelcome" @click="showTerms()" v-bind:disabled="terms">Terms</v-btn> -->
         <!-- <v-checkbox
          v-model="terms"
          label="Accept the terms"
          class="my-0 mx-4 checkTerms"
        ></v-checkbox> -->
        <v-btn v-if="isRaMicroSubdomain" color="primary" :isLarge="true" class="btnWelcome" @click="setupRaMicroSubdomain()" > <!-- v-bind:disabled="!terms" -->
          {{ $t('components.conditions.acceptTheTerms') }}
        </v-btn>
        <v-btn v-else color="primary" :isLarge="true" class="btnWelcome" @click="setSetUpConfigRoute('configuration')" > <!-- v-bind:disabled="!terms" -->
          {{ $t('components.conditions.acceptTheTerms') }}
        </v-btn>
      </div>
    </v-row>
    <!--  -->
  </v-card>
</v-flex>
</template>

<script>
  import { webLicensedBaseFeatures } from '../../../sharedsrc/licensedFeatures';
  import store from '../../store';
  import Vue from 'vue';
  import device from "vue-device-detector";
  import { setNamespaceSetting } from '../../lib/wsMsg';
  Vue.use(device)
  export default {
    components: {  },
    data () {
      return {
       state: store.state,
       setSetUpConfigRoute: store.setSetUpConfigRoute,
       terms: false,
      }
    },
    methods: {
      showTerms(){
        this.terms=true
      },
      async setupRaMicroSubdomain () {
        await setNamespaceSetting('companyInfo', {
          "employees": 999,
          "company_name": store.getVirtualOfficePrefix().trim(),
          "comercial_register": "",
          "tax_number": "",
          "street_address": "",
          "email": `${store.getVirtualOfficePrefix().trim().toLowerCase()}@voffice.pro`,
          "city": "",
          "state": "",
          "phone": "",
          "virtualOfficePrefix": ""
        });
        await setNamespaceSetting('setupDone', true);
        await setNamespaceSetting('noNewUsers', true);
        window.location.reload();
      }
    },
    computed:{
      isIos(){
        return this.$device.ios
      },
      isSafari(){
        var isSafari = navigator.userAgent.toLowerCase(); 
        if (isSafari.indexOf('safari') != -1) { 
          if (isSafari.indexOf('chrome') > -1) {
            return false;
          } else {
            return true;
          }
        }
      },
      isRaMicroSubdomain () {
        return webLicensedBaseFeatures.isRaMicroCustomer;
      }
    }
  }
</script>
<style scoped lang="scss">
.vueScrollConditions{
  max-height: calc(100% - 90px);
}
.imgLogo{
  height: 100px;

}
.titleWelcome{
    font-size: 30px;
    display: block;
    color: #2a3133; 
      margin: 50px auto 10px;
      text-align: left !important;
}
.v-card{
  height: calc(100% - 110px) !important;
}
.v-card-ios{
  height: calc(100vh - 250px) !important;
}
.conditionsContent{
    max-height: 350px;
    text-align: left !important;
    white-space: pre-line;
}
.mt-90{
  margin-top: 90px;
}
.checkTerms{
  display: inline-block;
  height: 30px;
  vertical-align: middle;
}
</style>