var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "cardWidth mx-auto pt-0 mb-0",
      staticStyle: {
        border: "2px solid #808080",
        "border-radius": "4px 4px 0px 0px"
      },
      attrs: { flat: "" }
    },
    [
      _c(
        "v-img",
        {
          class:
            "white--text align-end " +
            (_vm.isMobile ? "heightMob" : "height240"),
          attrs: { src: _vm.getAvatarByType(_vm.ownUUID) },
          on: { click: _vm.setGuestInModal }
        },
        [
          _vm.conferenceData
            ? _c("div", { staticClass: "gradientBg dataWrapper" }, [
                _c("p", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.conferenceData.name) + "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.holidays.start")) +
                      ":\n        " +
                      _vm._s(
                        _vm
                          .moment(new Date(_vm.conferenceData.date))
                          .format("DD.MM.YYYY | H:mm")
                      ) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.eventEntryForm.duration")) +
                      ":\n        " +
                      _vm._s(
                        _vm.moment(_vm.conferenceData.time).format("H:mm")
                      ) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.groups.totalUsers")) +
                      ":\n        " +
                      _vm._s(_vm.conferenceData.membersLength) +
                      "\n      "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gradientBg" },
            [
              _c(
                "v-card-subtitle",
                {
                  staticClass:
                    "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                  staticStyle: { color: "#fff !important" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.titelPerson(_vm.state.group[_vm.ownUUID])) +
                      "\n        " +
                      _vm._s(_vm.namePerson(_vm.state.group[_vm.ownUUID])) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("ButtonsFrontCard", {
                attrs: {
                  person: _vm.state.group[_vm.state.user.visitorData.visitorId],
                  uuid: _vm.state.user.visitorData.visitorId,
                  sectionName: "members",
                  flipCard: _vm.flipCard,
                  index: 0,
                  typeData: "waitingroom"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }