var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "750" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cleanUpOnCancel.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModalDirectLink,
            callback: function($$v) {
              _vm.showModalDirectLink = $$v
            },
            expression: "showModalDirectLink"
          }
        },
        [
          _c("v-card", [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  {
                    staticClass: "w-100 mx-0 my-0 justify-space-between",
                    attrs: { flex: "" }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "px-0 py-0", attrs: { align: "left" } },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "headline" },
                          [
                            _c("v-img", {
                              staticClass: "mr-1",
                              attrs: {
                                src: "assets/icon.png",
                                "max-height": "30",
                                "max-width": "30",
                                contain: ""
                              }
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "components.drawerContentCard.directLink"
                                  )
                                ) +
                                "\n            "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "col-6 px-4 py-1 pt-0 mt-3" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.$t(
                          "components.inviteVisitor.conferenceName"
                        ),
                        required: "",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.confTitle,
                        callback: function($$v) {
                          _vm.confTitle = $$v
                        },
                        expression: "confTitle"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("directCallMaskMultiUser", {
                  ref: "directCallMaskRef",
                  attrs: { inviteList: _vm.inviteList }
                }),
                _vm._v(" "),
                _c(
                  "v-card",
                  {
                    staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                    attrs: { color: "primary" }
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "px-2 btns w-100 mx-0 my-0" },
                      [
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              disabled:
                                                !_vm.$refs.directCallMaskRef
                                                  .userListToSend ||
                                                _vm.$refs.directCallMaskRef
                                                  .userListToSend.length ===
                                                  0 ||
                                                _vm.confTitle.trim().length < 1,
                                              loading:
                                                _vm.$refs.directCallMaskRef
                                                  .isProcessing
                                            },
                                            on: {
                                              click: _vm.precheckNewVisitor
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v(_vm._s(_vm.$t("generics.add")))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.add")))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              disabled:
                                                _vm.$refs.directCallMaskRef
                                                  .isProcessing
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.cleanUpOnCancel()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("generics.cancel"))
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.cancel")))
                              ])
                            ]
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }