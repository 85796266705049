var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.systemMessagesArray
    ? _c(
        "div",
        {
          staticClass: "px-2 d-flex justify-space-between align-baseline w100",
          style:
            "background:" +
            _vm.getColorSystemMessage +
            "; color:" +
            _vm.systemMessagesColorText(_vm.getColorSystemMessage)
        },
        [
          _vm.systemMessagesArray[0] && _vm.systemMessagesArray[0].body
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(
                      _vm.checkForUrls(_vm.systemMessagesArray[0].body)
                    )
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    color: _vm.systemMessagesColorText(
                      _vm.getColorSystemMessage
                    )
                  },
                  on: {
                    click: function($event) {
                      return _vm.readSystemMessage(
                        _vm.systemMessagesArray[0].messageUUID
                      )
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    style: { fontSize: "25px" },
                    attrs: { icon: ["fal", "times"] }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }