var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { color: "primary" } }, on),
                [
                  _c("font-awesome-icon", {
                    style: { fontSize: "16px" },
                    attrs: { icon: ["far", "shopping-cart"] }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hidden-sm-and-down pl-1" }, [
                    _vm._v(_vm._s(_vm.$t("components.upgradeModal.upgrade")))
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.showUpgrade,
        callback: function($$v) {
          _vm.showUpgrade = $$v
        },
        expression: "showUpgrade"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline grey lighten-2",
              attrs: { "primary-title": "" }
            },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("components.upgradeModal.privacyPolicy")) +
                  "\n        "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _vm._v(
              "\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n        "
            )
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("components.upgradeModal.iAccept")) +
                      "\n          "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }