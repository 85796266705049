var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { flat: "" } },
    [
      _c(
        "v-list-item-group",
        { attrs: { color: "primary" } },
        [
          _c(
            "v-list-item",
            { staticStyle: { "margin-right": "24px" } },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("font-awesome-icon", {
                    staticClass: "primary--text-sidepanel",
                    attrs: { icon: ["fal", "sticky-note"] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-title",
                {
                  staticClass: "primary--text-sidepanel",
                  class:
                    "" +
                    (this.$route.path === "/infoboard"
                      ? "font-weight-bold primary--text-sidepanel"
                      : ""),
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleMyInfoboardView()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("generics.infoboard")) +
                      "\n        " +
                      _vm._s(
                        "" +
                          (_vm.getTotalNotes > 0
                            ? "(" + _vm.getTotalNotes + ")"
                            : "")
                      ) +
                      "\n        "
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _vm.amIAdmin
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "btnAddInfo",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.handleNewInfoNote()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "primary--text-sidepanel",
                                        staticStyle: {
                                          "font-size": "20px",
                                          "margin-right": "5px"
                                        },
                                        attrs: { icon: ["fal", "plus-circle"] }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.drawerContentExpansion.newNote")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showCustomTT
                ? _c("CustomTT", {
                    attrs: {
                      text: _vm.$t("components.hotkeys.controlKey") + " + B",
                      position: "bottom"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }