var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-row", {
        staticClass: "px-0 btns",
        style: { width: "100%", margin: 0 },
        attrs: { flex: "" }
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("v-textarea", {
        attrs: {
          rules: _vm.rules,
          "no-resize": true,
          autofocus: "",
          rows: "3",
          outlined: "",
          label: _vm.$t("components.notificationsModal.reply")
        },
        model: {
          value: _vm.replyMessage,
          callback: function($$v) {
            _vm.replyMessage = $$v
          },
          expression: "replyMessage"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }