<template>
<div class="box" :style="magicStyle" v-if="shouldShow">
  <div class="rulerDigit"></div>
</div>
</template>
<script>
import { timelineEvents, deleteTimelineItemEvent } from '../../effector/timeline';
import { updateTimelineEntryColor } from "../../lib/wsMsg";
import moment from 'moment';
export default {
  data() {
    return {
      date: new Date(),
      color: '#aa2626',
    };
  },
  computed: {
    shouldShow() {
      const minDate = new Date();
      minDate.setHours(8,0,0,0);
      // console.log('shouldShow minDate', minDate, this.date, this.date < minDate);
      if (this.date < minDate) return false;

      const maxDate = new Date();
      maxDate.setHours(19,0,0,0);
      // console.log('shouldShow maxDate', maxDate, this.date, this.date > maxDate);
      if (this.date > maxDate) return false;
      return true;
    },
    magicStyle() {
      // if (!this.shouldShow) return { display: 'none', };
      return {
        position: 'absolute',
        top: '0px',
        bottom: '0px',
        left: '0px', //'calc((100% / 13) - 15px)', // left: '0%', //this.leftStartRule + '%',
        right: this.rightEndRule + '%',
       // width: (100 - this.rightEndRule) - this.leftStartRule + '%',
        width: this.leftStartRule + '%',
       // borderColor: this.color,
       //backGroundColr:this.color,
      };
    },
    leftStartRule() {
      const aDayMs = 60000 * 60 * 12;
      const aDayMsSplit = 100 / aDayMs;

      const date = new Date(this.date);
      date.setHours(8,0,0,0);

      const diff = this.date - date;
      const per = aDayMsSplit * diff;
      return per;
    },
    rightEndRule() {
      const per = this.leftStartRule + 0.1;
      return (100 - per );
    },
  },
  effector: {
    rawEvents: timelineEvents
  },
  methods: {
    updateTs() {
      const now = moment();
      const toChange = [];
      const past = this.rawEvents.filter(e => ((moment(e.end) < now) && e.color !== 'gray'))
      if (past && past.length > 0) {
        for (let index = 0; index < past.length; index++) {
          const element = past[index];
          toChange.push(element);
        }
      }
      if (toChange && toChange.length > 0) {
        toChange.forEach(element => {
          updateTimelineEntryColor(element.uuid, 'gray');
        });
      }
      this.date = new Date();
    },
  },
  mounted() {
    this._timer = setInterval(this.updateTs.bind(this), 60000);
  },
  destroyed() {
    if (this._timer) clearInterval(this._timer);
  }
};
</script>

<style lang="scss" scoped>
.box {
  /*border-right: 3px solid #aa2626; */
  width: calc(100% / 13);
  display: inline-block;
  height: 36px;
  z-index: 5;
  pointer-events: none;
}

.theme--light .box{
  background-color: #f7f7f7;
}

.theme--dark .box{
  background-color: #363636;
}
.rulerDigit{
    width: 100%;
    font-size: 12px;
    // font-size: 0.8em;
    text-align: right;
    position: relative;
    top: calc(100% - 1px);
    /* border-top: 1px solid #aa2626; */
    z-index: 9;
}
</style>
