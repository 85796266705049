var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("WebCam", {
        ref: "webcam",
        attrs: {
          "device-id": _vm.deviceId,
          width: _vm.width,
          height: _vm.height || 500
        },
        on: {
          error: _vm.onError,
          cameras: _vm.onCameras,
          "camera-change": _vm.onCameraChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }