var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewingGroups
        ? _c(
            "v-content",
            {
              class:
                "px-0 py-0 " +
                (_vm.removeGeneralWrapper ? null : "contentGridWrapper"),
              nativeOn: {
                scroll: function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _vm.showUserProfile
                ? _c("UserProfle", {
                    attrs: {
                      showUserProfile: _vm.showUserProfile,
                      toggleUserProfile: _vm.toggleUserProfile,
                      enableCam: true
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "custom6cols pb-1 px-2",
                      attrs: { cols: "6" }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "cardWidth cursorPointer",
                          style: { border: "1px solid #efefef" },
                          on: { click: _vm.clickOnAddCard }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "180px",
                                display: "table",
                                width: "100%"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "divAdd" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "buttonAdd",
                                    attrs: { icon: ["fal", "plus"] }
                                  }),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0 mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.createGroup"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.userGroups, function(group, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        staticClass: "custom6cols pb-1 px-2",
                        attrs: { cols: "6" }
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "cardWidth",
                            on: {
                              click: function($event) {
                                return _vm.editGroup(group)
                              }
                            }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "v-img",
                                                {
                                                  staticClass:
                                                    "white--text align-end",
                                                  attrs: {
                                                    src: group.groupImage,
                                                    height: "180px"
                                                  }
                                                },
                                                [
                                                  group.favorite
                                                    ? _c("font-awesome-icon", {
                                                        staticClass:
                                                          "primary--text",
                                                        style: {
                                                          fontSize: "20px",
                                                          position: "absolute",
                                                          top: 5,
                                                          right: 5
                                                        },
                                                        attrs: {
                                                          icon: ["fas", "star"]
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "gradientBg"
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-subtitle",
                                                        {
                                                          staticClass:
                                                            "titleCard colorWhite pt-1 pb-1 px-2 text-truncate",
                                                          staticStyle: {
                                                            color:
                                                              "#fff !important"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "GroupCardActions",
                                                            {
                                                              attrs: {
                                                                group: group,
                                                                editGroup:
                                                                  _vm.editGroup,
                                                                deleteGroup:
                                                                  _vm.deleteGroup,
                                                                setFavorite:
                                                                  _vm.setFavorite,
                                                                checkSendMessage:
                                                                  _vm.checkSendMessage,
                                                                existingGroup:
                                                                  _vm.existingGroup,
                                                                callMembers:
                                                                  _vm.callMembers
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("GroupInfoTooltip", {
                                  attrs: { group: group }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "infinite-loading",
                    {
                      attrs: { distance: 5 },
                      on: { infinite: _vm.infiniteHandler }
                    },
                    [
                      _c("div", {
                        attrs: { slot: "no-results" },
                        slot: "no-results"
                      }),
                      _vm._v(" "),
                      _c("div", { attrs: { slot: "no-more" }, slot: "no-more" })
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.viewingGroups
        ? _c(
            "v-content",
            {
              class:
                "px-0 py-0 " +
                (_vm.removeGeneralWrapper ? null : "contentGridWrapper"),
              nativeOn: {
                scroll: function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "heightScroll mx-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "mx-0 py-0", attrs: { cols: "5" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0 ", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                class: { borderError: _vm.groupName == "" },
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "Name",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.groupName,
                                  callback: function($$v) {
                                    _vm.groupName = $$v
                                  },
                                  expression: "groupName"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-0 pb-0", attrs: { cols: "10" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.fillDropDown,
                                  outlined: "",
                                  dense: "",
                                  color: "blue-grey lighten-2",
                                  label: _vm.$t(
                                    "components.groupGrid.selectParticipant"
                                  ),
                                  "item-text": "name",
                                  "item-value": "uuid",
                                  multiple: "",
                                  "hide-selected": "",
                                  "search-input": _vm.searchInput
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleSelection($event)
                                  },
                                  input: function($event) {
                                    _vm.searchInput = null
                                  },
                                  "update:searchInput": function($event) {
                                    _vm.searchInput = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.searchInput = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(data) {
                                        return [_vm._v(_vm._s(data.item.name))]
                                      }
                                    },
                                    {
                                      key: "item",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "selectorBack d-flex"
                                            },
                                            [
                                              [
                                                _c(
                                                  "v-list-item-avatar",
                                                  {
                                                    staticClass:
                                                      "borderRadius-4"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: data.item.avatar
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                data.item.group !== "Teams"
                                                  ? _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.$sanitize(
                                                                  !data.item
                                                                    .isGroup
                                                                    ? data.item
                                                                        .name
                                                                    : data.item
                                                                        .groupName
                                                                )
                                                              )
                                                            }
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item-subtitle",
                                                          {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                !data.item
                                                                  .isGroup
                                                                  ? _vm.getTypeOfParticipant(
                                                                      data.item
                                                                        .group
                                                                    )
                                                                  : _vm.$t(
                                                                      "components.addConferenceModal.group"
                                                                    ) +
                                                                      " - " +
                                                                      data.item
                                                                        .groupMembers
                                                                        .length +
                                                                      " " +
                                                                      _vm.$t(
                                                                        "components.addConferenceModal.members"
                                                                      )
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.$sanitize(
                                                                  data.item.name
                                                                )
                                                              )
                                                            }
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item-subtitle",
                                                          {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.$t(
                                                                  "components.addConferenceModal.team"
                                                                ) +
                                                                  " - " +
                                                                  data.item
                                                                    .members
                                                                    .length +
                                                                  " " +
                                                                  _vm.$t(
                                                                    "components.addConferenceModal.members"
                                                                  )
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                              ]
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  825720278
                                ),
                                model: {
                                  value: _vm.selectedDropDowItem,
                                  callback: function($$v) {
                                    _vm.selectedDropDowItem = $$v
                                  },
                                  expression: "selectedDropDowItem"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-0 pb-0 text-center",
                              attrs: { cols: "2" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("components.conferenceForm.guest")
                                  ) +
                                  "\n            "
                              ),
                              _c("font-awesome-icon", {
                                staticClass: "primary--text cursorPointer",
                                style: { fontSize: "14px" },
                                attrs: { icon: ["fal", "plus-circle"] },
                                on: {
                                  click: function($event) {
                                    _vm.showAddGuestModal = true
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-0 pb-0", attrs: { cols: "8" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  rows: "2",
                                  label: _vm.$t(
                                    "components.groupGrid.description"
                                  ),
                                  "no-resize": ""
                                },
                                model: {
                                  value: _vm.groupDescription,
                                  callback: function($$v) {
                                    _vm.groupDescription = $$v
                                  },
                                  expression: "groupDescription"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "4" } },
                            [
                              _c(
                                "v-img",
                                {
                                  attrs: {
                                    src: _vm.groupImage,
                                    width: "110px",
                                    height: "110px",
                                    "max-height": "110px",
                                    "max-width": "110px"
                                  }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "mx-2 borderRadius-4 uploadGroupImage",
                                                      attrs: {
                                                        small: "",
                                                        fab: "",
                                                        dark: "",
                                                        depressed: "",
                                                        color: "primary",
                                                        disabled: false
                                                      },
                                                      on: {
                                                        click: _vm.setGroupImage
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "18px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "upload"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3120962794
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.groups.uploadGroupImage"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("input", {
                                ref: "groupImageInput",
                                staticClass: "d-none",
                                attrs: {
                                  type: "file",
                                  accept: "image/x-png,image/gif,image/jpeg"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.saveGroupImage($event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 px-0 pt-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "justStart no-bg list",
                                  attrs: { depressed: "", disabled: false },
                                  on: {
                                    click: function($event) {
                                      return _vm.setShowModalSendMsg(
                                        _vm.groupMembers
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "18px" },
                                    attrs: { icon: ["fal", "paper-plane"] }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.addGroupModal.sendMessageToAll"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-1 px-0 pt-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "justStart no-bg list",
                                                  class: !_vm.deleteActive
                                                    ? "deleteBasketButtonNormal"
                                                    : "deleteBasketButtonActive",
                                                  attrs: {
                                                    depressed: "",
                                                    disabled: false
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteGroup(
                                                        $event,
                                                        _vm.groupId
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "18px" },
                                                  attrs: {
                                                    icon: ["fal", "trash"]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-3" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.addGroupModal.deleteGroup"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3363173318
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.confirmRemove"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-0 userList", attrs: { cols: "7" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        _vm._l(_vm.getGroupMembers, function(user) {
                          return _c(
                            "v-col",
                            { key: user.uuid, attrs: { cols: _vm.getCols } },
                            [
                              _c("ListConferencesGroups", {
                                attrs: {
                                  isEditingGroup: _vm.isEditingGroup,
                                  deleteUser: _vm.deleteUser,
                                  user: user,
                                  groupModerator: _vm.groupModerator,
                                  isConferenceUser: false
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-0 pt-4" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-0 text-right", attrs: { cols: "12" } },
                    [
                      _c("v-divider", { staticClass: "pb-4" }),
                      _vm._v(" "),
                      !_vm.isEditingGroup
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                depressed: "",
                                color: "primary",
                                disabled: _vm.disableSaveButton
                              },
                              on: { click: _vm.saveGroup }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.save")))]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: { depressed: "", color: "primary" },
                              on: { click: _vm.doUpdate }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.footer.doUpdate"))
                              )
                            ]
                          ),
                      _vm._v(" "),
                      _vm.isEditingGroup
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                depressed: "",
                                color: "primary",
                                disabled: _vm.disableCallGroup
                              },
                              on: { click: _vm.callGroup }
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "phone"] }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2 borderRadius-4",
                          staticStyle: { height: "36px" },
                          attrs: {
                            icon: "",
                            outlined: "",
                            depressed: "",
                            small: "",
                            fab: "",
                            dark: "",
                            color: "primary"
                          },
                          on: { click: _vm.goBackToGrid }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "times"] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showAddGuestModal
                ? [
                    _c("AddGuestModal", {
                      attrs: {
                        addMember: _vm.addMember,
                        members: _vm.groupMembers,
                        isConference: false,
                        showModal: _vm.showAddGuestModal,
                        closeModal: _vm.closeGuestModal
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showCroppie
                ? [
                    _c("UploadGroupConfImg", {
                      attrs: {
                        confOrGroupId: _vm.groupId,
                        uploadConferenceImage: _vm.uploadGroupImage,
                        showCroppie: _vm.showCroppie,
                        temporalImage: _vm.temporalImage,
                        resetInputImg: _vm.resetGroupImageInput,
                        closeCroppie: _vm.openCloseCroppie,
                        isConference: false
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }