var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "tightDialog",
          attrs: {
            fullscreen: _vm.standalone,
            persistent: "",
            "max-width": "980"
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeMenu.apply(null, arguments)
            }
          },
          model: {
            value: _vm.state.showInviteInstaLink || _vm.showModal,
            callback: function($$v) {
              _vm.$set(_vm.state, "showInviteInstaLink || showModal", $$v)
            },
            expression: "state.showInviteInstaLink || showModal"
          }
        },
        [
          _c(
            "div",
            { ref: "modalContent", attrs: { id: "itsme" } },
            [
              _c(
                "v-row",
                { staticClass: "mx-0", attrs: { justify: "center" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "ml-5 mr-5 pt-2", attrs: { flat: "" } },
                    [
                      _c("v-img", {
                        staticClass: "imgLogo",
                        attrs: {
                          contain: "",
                          src: "assets/icon.png",
                          "lazy-src": "assets/icon.png",
                          height: "50",
                          width: "50"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-bold headline" },
                    [_vm._v(_vm._s(_vm.$t("components.inviteVisitor.title")))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("vue-scroll", { staticClass: "vueScrollinviteOneCall" }, [
                _c(
                  "div",
                  { staticStyle: { height: "310px" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "px-1 py-1", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-form",
                                          {
                                            ref: "form",
                                            attrs: { "lazy-validation": "" },
                                            model: {
                                              value: _vm.valid,
                                              callback: function($$v) {
                                                _vm.valid = $$v
                                              },
                                              expression: "valid"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "mx-0" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-12 px-1 py-1 pt-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 body-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.titleInvitationMenu"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-select", {
                                                      staticClass: "mt-3",
                                                      attrs: {
                                                        items: _vm.getUserList,
                                                        label: _vm.$t(
                                                          "generics.users"
                                                        ),
                                                        "hide-details": "",
                                                        outlined: "",
                                                        dense: "",
                                                        "item-text": "name",
                                                        "item-value": "value",
                                                        "return-object": ""
                                                      },
                                                      model: {
                                                        value: _vm.userSelected,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.userSelected = $$v
                                                        },
                                                        expression:
                                                          "userSelected"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              { staticClass: "mx-0" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-12 px-1 py-0 pt-2"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "body-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.invitationTo"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1 pt-0"
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        required: "",
                                                        rules:
                                                          _vm.userNameRules,
                                                        label: _vm.$t(
                                                          "components.inviteVisitor.name"
                                                        )
                                                      },
                                                      model: {
                                                        value: _vm.name,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.name = $$v
                                                        },
                                                        expression: "name"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1 pt-2"
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "components.inviteVisitor.invitationEmail"
                                                        ),
                                                        required: "",
                                                        rules: _vm.emailRules
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.errText = ""
                                                          _vm.successText = ""
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.email,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.email = $$v
                                                        },
                                                        expression: "email"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  margin: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      margin: "0"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "py-0 px-2",
                                                        attrs: { cols: "12" }
                                                      },
                                                      [
                                                        _c("v-textarea", {
                                                          staticClass:
                                                            "py-0 my-0 bolder",
                                                          attrs: {
                                                            name:
                                                              "subjectinput",
                                                            "no-resize": true,
                                                            rows: "2",
                                                            placeholder: _vm.$t(
                                                              "components.inviteVisitor.subject"
                                                            ),
                                                            counter: "",
                                                            maxlength: "80",
                                                            "auto-grow": ""
                                                          },
                                                          model: {
                                                            value: _vm.subject,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.subject = $$v
                                                            },
                                                            expression:
                                                              "subject"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "16px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.linkValidUntil"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          "close-on-content-click": false,
                                                          "nudge-right": 40,
                                                          transition:
                                                            "scale-transition",
                                                          "offset-y": ""
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        readonly:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.linkDateFrom,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.linkDateFrom = $$v
                                                                        },
                                                                        expression:
                                                                          "linkDateFrom"
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          slot:
                                                                            "prepend",
                                                                          icon: [
                                                                            "fal",
                                                                            "calendar-alt"
                                                                          ]
                                                                        },
                                                                        slot:
                                                                          "prepend"
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("v-date-picker", {
                                                          attrs: {
                                                            pickTime: true,
                                                            locale: this.$locale.current()
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              _vm.tixdate = false
                                                            }
                                                          },
                                                          model: {
                                                            value: _vm.date,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.date = $$v
                                                            },
                                                            expression: "date"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "16px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.videoCallTime"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _c("v-combobox", {
                                                      attrs: {
                                                        items:
                                                          _vm.combinedAppointmentOptions
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.getText()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.appmtOptionselect,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.appmtOptionselect = $$v
                                                        },
                                                        expression:
                                                          "appmtOptionselect"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              { staticClass: "mx-0" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-12 px-1 py-1"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "17px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.fixtermin"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  margin: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1"
                                                  },
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          "close-on-content-click": false,
                                                          "nudge-right": 40,
                                                          transition:
                                                            "scale-transition",
                                                          "offset-y": ""
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        readonly:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.linkDateTo,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.linkDateTo = $$v
                                                                        },
                                                                        expression:
                                                                          "linkDateTo"
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          slot:
                                                                            "prepend",
                                                                          icon: [
                                                                            "fal",
                                                                            "calendar-alt"
                                                                          ]
                                                                        },
                                                                        slot:
                                                                          "prepend"
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("v-date-picker", {
                                                          attrs: {
                                                            pickTime: true,
                                                            locale: this.$locale.current()
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              _vm.tixdate = false
                                                            }
                                                          },
                                                          model: {
                                                            value: _vm.dateTo,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.dateTo = $$v
                                                            },
                                                            expression: "dateTo"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "px-1 py-1",
                                                    attrs: {
                                                      cols: "6",
                                                      sm: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        ref: "menutTo",
                                                        attrs: {
                                                          "close-on-content-click": false,
                                                          "nudge-left": 150,
                                                          "return-value":
                                                            _vm.time,
                                                          transition:
                                                            "scale-transition",
                                                          "offset-y": ""
                                                        },
                                                        on: {
                                                          "update:returnValue": function(
                                                            $event
                                                          ) {
                                                            _vm.time = $event
                                                          },
                                                          "update:return-value": function(
                                                            $event
                                                          ) {
                                                            _vm.time = $event
                                                          }
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.linkTimeTo,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.linkTimeTo = $$v
                                                                          },
                                                                          expression:
                                                                            "linkTimeTo"
                                                                        }
                                                                      },
                                                                      "v-text-field",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          slot:
                                                                            "prepend",
                                                                          icon: [
                                                                            "far",
                                                                            "clock"
                                                                          ]
                                                                        },
                                                                        slot:
                                                                          "prepend"
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ]),
                                                        model: {
                                                          value: _vm.menutTo,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.menutTo = $$v
                                                          },
                                                          expression: "menutTo"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _vm.menutTo
                                                          ? _c(
                                                              "v-time-picker",
                                                              {
                                                                attrs: {
                                                                  format: "24hr"
                                                                },
                                                                on: {
                                                                  "click:minute": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$refs.menutTo.save(
                                                                      _vm.linkTimeTo
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.linkTimeTo,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.linkTimeTo = $$v
                                                                  },
                                                                  expression:
                                                                    "linkTimeTo"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  margin: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-12 px-1 py-1 pb-0"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "mb-0",
                                                        staticStyle: {
                                                          "font-size": "15px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.duration"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-9 px-1 py-1"
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "max-width": "50",
                                                        "hide-details": "",
                                                        "single-line": "",
                                                        type: "number"
                                                      },
                                                      model: {
                                                        value: _vm.durationMin,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.durationMin = $$v
                                                        },
                                                        expression:
                                                          "durationMin"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("v-col", {
                                                  staticClass: "col-3 px-1 py-1"
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  margin: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-12 px-1 py-1",
                                                    staticStyle: {
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "15px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.payOneCall"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1 pt-0"
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        disabled: true,
                                                        label: _vm.$t(
                                                          "components.inviteVisitor.price"
                                                        )
                                                      },
                                                      model: {
                                                        value: _vm.price,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.price = $$v
                                                        },
                                                        expression: "price"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "col-6 px-1 py-1 pt-0"
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "components.inviteVisitor.fixedPrice"
                                                        ),
                                                        disabled: true
                                                      },
                                                      model: {
                                                        value: _vm.fixedPrice,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.fixedPrice = $$v
                                                        },
                                                        expression: "fixedPrice"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.errText
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      margin: "0"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mx-auto"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "red"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errText
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "px-1 py-1 pt-2",
                            attrs: { cols: "6" }
                          },
                          [
                            _c("v-divider", {
                              staticStyle: { "padding-bottom": "15px" }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0 body-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.inviteVisitor.invitationText"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "15px" } },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-weight": "600",
                                      margin: "0px",
                                      "word-break": "break-all"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.computedSubject))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("v-textarea", {
                                  staticClass: "py-0 my-0 pre inviteTextClass",
                                  attrs: {
                                    spellcheck: "false",
                                    contenteditable: "true",
                                    value: _vm.inviteText,
                                    name: "noteinput",
                                    "no-resize": true,
                                    counter: "",
                                    rows: "13"
                                  },
                                  model: {
                                    value: _vm.inviteText,
                                    callback: function($$v) {
                                      _vm.inviteText = $$v
                                    },
                                    expression: "inviteText"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mt-12",
                                    staticStyle: { display: "none" }
                                  },
                                  [_vm._v("Value: " + _vm._s(_vm.inviteText))]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "col-8 px-1 py-1",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("v-textarea", {
                                  staticClass: "py-0 my-0",
                                  attrs: {
                                    name: "noteinput",
                                    "no-resize": true,
                                    rows: "2",
                                    placeholder: "Interne Bemerkung",
                                    counter: "",
                                    maxlength: "80"
                                  },
                                  model: {
                                    value: _vm.internalnote,
                                    callback: function($$v) {
                                      _vm.internalnote = $$v
                                    },
                                    expression: "internalnote"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "col-8 px-1 py-1",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    label: _vm.$t(
                                      "components.inviteVisitor.desactivateAutoMail"
                                    )
                                  },
                                  model: {
                                    value: _vm.desactiveAutoSend,
                                    callback: function($$v) {
                                      _vm.desactiveAutoSend = $$v
                                    },
                                    expression: "desactiveAutoSend"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "col-8 px-1 py-1",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-text-field",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.invitateLink &&
                                          (_vm.desactiveAutoSend ||
                                            _vm.standalone),
                                        expression:
                                          "invitateLink && (desactiveAutoSend || standalone)"
                                      }
                                    ],
                                    attrs: {
                                      id: "invitateLink",
                                      readonly: "",
                                      label: _vm.$t(
                                        "components.inviteVisitor.invitateLink"
                                      ),
                                      value: _vm.invitateLink
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "cursorPointer",
                                      style: { fontSize: "20px" },
                                      attrs: {
                                        slot: "append",
                                        icon: ["fal", "copy"]
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyLink()
                                        }
                                      },
                                      slot: "append"
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.copiedLInk
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.inviteVisitor.copiedLInk"
                                          )
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "col-8 px-1 py-1",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { color: "primary" },
                                    on: { click: _vm.saveCustomMessage }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteGuestPanel.saveText"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.inviteText = ""
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteGuestPanel.emptyTextField"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns w-100 mx-0 my-0" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "white",
                            "line-height": "2.3em",
                            "margin-right": "30px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.successText))]
                      ),
                      _vm._v(" "),
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            disabled: !_vm.valid,
                                            icon: "",
                                            color: "white"
                                          },
                                          on: { click: _vm.precheckNewVisitor }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "check"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.accept")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-3",
                                          attrs: { icon: "", color: "white" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.closeMenu()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "times"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.close")))
                            ])
                          ]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }