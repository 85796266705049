var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showBadQualityMessageModal,
        callback: function($$v) {
          _vm.showBadQualityMessageModal = $$v
        },
        expression: "showBadQualityMessageModal"
      }
    },
    [
      [
        _c(
          "v-card",
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.votingModal.badQualityMessageTitle"
                ),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "px-5", style: { width: "100%", margin: 0 } },
              [
                _c("v-textarea", {
                  attrs: {
                    counter: "",
                    contenteditable: "true",
                    label: _vm.$t("components.sendMessageModal.message"),
                    "no-resize": true,
                    maxlength: "250",
                    autofocus: ""
                  },
                  model: {
                    value: _vm.badMessage,
                    callback: function($$v) {
                      _vm.badMessage = $$v
                    },
                    expression: "badMessage"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: {
                  hideDefaultButtons: true,
                  closeModalFunction: _vm.closeModal,
                  showFooter: true
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      disabled: _vm.badMessage.trim().length === 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.sendMessage()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("components.sendMessageModal.send")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }