var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v(_vm._s(_vm.conference.confName))]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.getNameModerator))]),
    _vm._v(" "),
    _c("div", [
      _c("span", [
        _vm._v(
          " " + _vm._s(_vm.$t("components.remotePersonCall.attendees")) + ": "
        )
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("\n      " + _vm._s(_vm.getAllUsers) + "\n    ")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }