<template>
    <v-card v-if="whatsWorkData" flat :class="[isDarkMode ? 'bg-grey-darken-3' : 'bg-grey-lighten-2']"
        class="pa-3 my-6 rounded-sm" @click="openPreview(whatsWorkData.id)">
        <v-row class="d-flex align-start">
            <v-col class="pt-4">
                <div class="font-weight-medium">{{ getTitelByUuid(whatsWorkData.userId) }}
                    {{ getNameByUuid(whatsWorkData.userId) }}</div>
                <div class="text-medium-emphasis text-caption">{{ formattedDate(whatsWorkData.createdAt) }}</div>
            </v-col>
            <v-col cols="1" class="pt-4 pl-0 text-right">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn density="compact" icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="isEditing = true" class="pointer" v-if="whatsWorkData.userId === ownUUID">
                            <v-list-item-title>{{ $t("components.socialMedia.edit") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :class="{ 'text-error': confirmDeleteId === whatsWorkData._id }" class="pointer"
                            v-if="whatsWorkData.userId === ownUUID || amIAdmin"
                            @click.stop="removeWhatsWork(whatsWorkData)">
                            <v-list-item-title>{{ $t("components.socialMedia.remove") }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-row class="mt-2 mb-2">
            <v-col v-if="!isEditing">
                <p class="mb-2" v-html="$sanitize(whatsWorkData.content)">
                </p>
            </v-col>
            <EditPost v-if="isEditing" :viewSelected="'Whatswork'" :postData="whatsWorkData"
                @closeEdit="isEditing = false" />
        </v-row>
        <SocialMediaDocumentGallery v-if="whatsWorkData?.documents && whatsWorkData?.documents.length" :documents="whatsWorkData?.documents"></SocialMediaDocumentGallery>
        <SocialMediaGallery v-if="whatsWorkData?.images && whatsWorkData?.images.length" :images="whatsWorkData?.images" />


        <v-divider></v-divider>

        <WhatsWorkFooter :toggleComments="toggleCommentSection" :toogleLike="toogleLike" :postData="whatsWorkData" />

        <v-row v-if="showComments">
            <v-col>
                <CommentItem v-for="(comment, index) in whatsWorkData.comments" :key="index" :comment="comment"
                    class="mt-4" />
                <v-divider class="my-4"></v-divider>
                <CreateComment :postData="whatsWorkData" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import store, { EventBus } from "../../../../store";
import moment from "../../../../../sharedsrc/moment";
import WhatsWorkFooter from "./whatsWorkFooter.vue";
import CreateComment from "../comments/createComment.vue";
import CommentItem from "../comments/commentItem.vue";
import EditPost from "../common/editPost.vue";
import SocialMediaGallery from "../common/socialMediaGallery.vue";
import SocialMediaDocumentGallery from "../common/socialMediaDocumentGallery.vue";

export default {
    components: { WhatsWorkFooter, CreateComment, CommentItem, EditPost, SocialMediaGallery, SocialMediaDocumentGallery },
    props: ['whatsWorkData'],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            showComments: false,
            showAddComment: false,
            isEditing: false,
            confirmDeleteId: null,
        };
    },
    methods: {
        formattedDate(createdAt) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const formattedDate = moment(createdAt).tz(userTimeZone).format('DD.MM.YYYY');
            return formattedDate
        },
        openPreview(postId) {
            const dataToSend = {
                show: true,
                postId
            }
            EventBus.$emit('previewPost', dataToSend)
        },
        toggleCommentSection() {
            this.showComments = !this.showComments;
        },
        toggleAddComments() {
            this.showAddComment = !this.showAddComment;
        },
        getNameByUuid(uuid) {
            return store.getNameForUuid(uuid);
        },
        getTitelByUuid(uuid) {
            return store.getTitelForUuid(uuid);
        },
        toogleLike() {
            // Check if the current user has already liked the post
            const isLiked = this.whatsWorkData.likes.includes(this.ownUUID);

            // If the post is already liked by the user, remove the like
            if (isLiked) {
                // Call the API to remove the like
                this.removeLike(this.whatsWorkData.id, this.ownUUID);
            } else {
                // If the post is not liked by the user, add the like
                this.addLike(this.whatsWorkData.id, this.ownUUID);
            }
        },
        addLike(postId, userId) {
            fetch("/api/socialmedia/like-whatswork", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: postId, userId })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        // Update local postData to reflect the new like
                        this.$set(this.whatsWorkData, 'likes', [...this.whatsWorkData.likes, userId]);
                    } else {
                        // Handle any errors
                        console.error('Error adding like:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removeLike(postId, userId) {
            fetch("/api/socialmedia/unlike-whatswork", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: postId, userId })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        // Remove the like from local postData
                        this.$set(this.whatsWorkData, 'likes', this.whatsWorkData.likes.filter(like => like !== userId));
                    } else {
                        // Handle any errors
                        console.error('Error removing like:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removeWhatsWork(whatsworkData) {
            if (this.confirmDeleteId === whatsworkData._id) {
                fetch("/api/socialmedia/remove-whatswork", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ postId: whatsworkData._id })
                }).then(async (response) => {
                    const result = await response.json()
                    if (!result.error) {
                        EventBus.$emit('removeWhatsWork', whatsworkData);
                        this.confirmDeleteId = null; // Restablecer después de eliminar
                    } else {
                        //TODO: Handler error
                    }
                })
            } else {
                this.confirmDeleteId = whatsworkData._id; // Primer clic: activar confirmación
                setTimeout(() => {
                    if (this.confirmDeleteId === whatsworkData._id) {
                        this.confirmDeleteId = null; // Si no hacen el segundo clic en 3s, se cancela
                    }
                }, 2000);
            }
        }
    },
    computed: {
        isDarkMode() {
            return this.state.persisted.isDark; // Asumiendo que 'state.persisted.isDark' está definido en el componente
        },
        amIAdmin() {
            return store.getUserIsAdmin(this.ownUUID);
        },
    }
};
</script>
