// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styling to make list items transparent */
.v-list[data-v-a45e60cc] {
  background-color: transparent !important;
  /* Remove background color */
  color: white;
  /* Ensure text is white */
}
.v-list-item[data-v-a45e60cc] {
  background-color: transparent !important;
  /* Remove background color */
}
.v-list-item-group[data-v-a45e60cc] {
  background-color: transparent !important;
  /* Remove background color */
}
.detelebtnNotification[data-v-a45e60cc] {
  height: 25px !important;
}
.listHeightNotification[data-v-a45e60cc] {
  min-height: 25px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/common/notifications.vue","webpack://./notifications.vue"],"names":[],"mappings":"AACA,2CAAA;AACA;EACE,wCAAA;EACA,4BAAA;EACA,YAAA;EACA,yBAAA;ACAF;ADGA;EACE,wCAAA;EACA,4BAAA;ACAF;ADGA;EACE,wCAAA;EACA,4BAAA;ACAF;ADEA;EACE,uBAAA;ACCF;ADCA;EACE,2BAAA;ACEF","sourcesContent":["\n/* Styling to make list items transparent */\n.v-list {\n  background-color: transparent !important;\n  /* Remove background color */\n  color: white;\n  /* Ensure text is white */\n}\n\n.v-list-item {\n  background-color: transparent !important;\n  /* Remove background color */\n}\n\n.v-list-item-group {\n  background-color: transparent !important;\n  /* Remove background color */\n}\n.detelebtnNotification{\n  height: 25px!important;\n}\n.listHeightNotification{\n  min-height: 25px!important;\n}\n","/* Styling to make list items transparent */\n.v-list {\n  background-color: transparent !important;\n  /* Remove background color */\n  color: white;\n  /* Ensure text is white */\n}\n\n.v-list-item {\n  background-color: transparent !important;\n  /* Remove background color */\n}\n\n.v-list-item-group {\n  background-color: transparent !important;\n  /* Remove background color */\n}\n\n.detelebtnNotification {\n  height: 25px !important;\n}\n\n.listHeightNotification {\n  min-height: 25px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
