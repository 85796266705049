var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.vOfficeProVersionModal,
        callback: function($$v) {
          _vm.vOfficeProVersionModal = $$v
        },
        expression: "vOfficeProVersionModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c(
              "v-row",
              {
                staticClass: "mx-0 my-0 justify-space-between align-end",
                attrs: { flex: "" }
              },
              [
                _c(
                  "v-col",
                  { staticClass: "px-0 py-0", attrs: { align: "left" } },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass: "headline",
                        attrs: { "primary-title": "" }
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-1",
                          attrs: {
                            src: "assets/icon.png",
                            "max-height": "30",
                            "max-width": "30",
                            contain: ""
                          }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("components.proVersionModal.title")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pa-4" },
                  [
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "", color: "black" },
                                        on: {
                                          click: function($event) {
                                            return _vm.closeModal()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "25px" },
                                        attrs: { icon: ["fal", "times"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                        ]
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("v-card-text", [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.text")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb0-i" }, [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section1")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section11")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb0-i" }, [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section3")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section31")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb0-i" }, [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section4")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("components.proVersionModal.section41")))
              ])
            ]),
            _vm._v(" "),
            _c("v-divider"),
            _vm._v(" "),
            _c(
              "v-card-actions",
              { staticClass: "justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", loading: _vm.proVersionLoading },
                    on: { click: _vm.saveProVersion }
                  },
                  [_vm._v(_vm._s(_vm.$t("generics.accept")))]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v(_vm._s(_vm.$t("generics.close")))]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }