var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { dense: "" } },
    [
      _c(
        "v-list-item-title",
        { staticClass: "primary--text flex-column d-flex px-2 pb-3" },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: _vm.$t("generics.search"),
              "single-line": "",
              "hide-details": "",
              dense: ""
            },
            model: {
              value: _vm.searchUserList,
              callback: function($$v) {
                _vm.searchUserList = $$v
              },
              expression: "searchUserList"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-group",
        {
          staticClass: "listWrapper",
          attrs: { color: "primary" },
          on: {
            change: function($event) {
              return _vm.setSelectedUser(_vm.selectedUserModel)
            }
          },
          model: {
            value: _vm.selectedUserModel,
            callback: function($$v) {
              _vm.selectedUserModel = $$v
            },
            expression: "selectedUserModel"
          }
        },
        _vm._l(_vm.usersList, function(uuid, index) {
          return _c(
            "v-list-item",
            { key: uuid + "-" + (index + 1), attrs: { value: uuid } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.getTitelForUuid(uuid) +
                          "  " +
                          _vm.getNameForUuid(uuid)
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }