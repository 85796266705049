var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "d-none": _vm.hideVideoContainer,
        maximizeScreen: _vm.maximizeScreen,
        maximizeScreenMobile: _vm.isMobile
      }
    },
    [
      _c(
        "div",
        { staticClass: "sidebarCallWrapper" },
        [
          _vm.maximizeScreen && _vm.hasPrivilege(this.ownUUID)
            ? _c("SidebarCall", {
                attrs: {
                  maximizeScreen: _vm.maximizeScreen,
                  isConferenceCall: _vm.isConferenceCall,
                  getAmIModerator: _vm.getAmIModerator
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "callContainerWithProgressLinear",
          staticClass: "displayContainer"
        },
        [
          _vm.chatOpen
            ? _c(
                "div",
                { staticClass: "chatWrapper" },
                [
                  _c("JitsiChat", {
                    attrs: {
                      chatOpen: _vm.chatOpen,
                      chatMessages: _vm.chatMessages,
                      showTimeLine: _vm.showTimeLine,
                      toggleChat: _vm.toggleChat,
                      chatMessageNotificationSound:
                        _vm.chatMessageNotificationSound,
                      toggleChatMessageNotificationSound:
                        _vm.toggleChatMessageNotificationSound
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.getIsConferenceHall
                ? "gridContainerConferenceHall"
                : "gridContainer"
            },
            [
              _vm.state.nerd
                ? _c("DebugCall", {
                    attrs: {
                      localStats: _vm.getLocalStats,
                      remoteParticipants: _vm.remoteParticipants,
                      room: _vm.room,
                      getMyDesktopRoomUserID: _vm.getMyDesktopRoomUserID
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "100%" } }, [
                _c(
                  "div",
                  {
                    staticClass: "w100",
                    class: { videoContainer: _vm.isMobile },
                    staticStyle: { position: "relative", height: "100%" }
                  },
                  [
                    _vm.showTimeLine
                      ? _c("ProgressLinearBridge", {
                          attrs: {
                            amIModerator: _vm.getAmIModerator,
                            updateTimeForUser: _vm.updateTimeForUser,
                            setCallDuration: _vm.setCallDuration,
                            hangUpBridgeCall: _vm.hangUpBridgeCall,
                            redirectToStartView: _vm.redirectToStartView,
                            isConferenceCall: _vm.isConferenceCall,
                            rejectCall: _vm.rejectCall
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "callContainer",
                        class: [
                          _vm.getIsConferenceHall && _vm.showTimeLine
                            ? "conferenceHallPodiumContainer"
                            : _vm.getIsConferenceHall && !_vm.showTimeLine
                            ? "conferenceHallPodiumContainerNoTimeline"
                            : !_vm.showTimeLine && !_vm.isMobile
                            ? "hideTimeLine"
                            : "showTimeLine",
                          _vm.currentDesktop || _vm.presenterMode
                            ? _vm.getGridPresenterContainerClass
                            : _vm.getGridRegularContainerClass,
                          _vm.isMobile ? "hideTimeLineMobile" : null
                        ],
                        attrs: { id: "videos" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "participantColumn px-0 py-0",
                            class: [
                              _vm.userMaximized ? "d-none" : "",
                              _vm.isMobile ? "myLocalMobile" : "",
                              _vm.isMobile && _vm.showLocalBar
                                ? "myLocalMobileWithBar"
                                : "",
                              _vm.currentDesktop || _vm.presenterMode
                                ? _vm.getPresentationStyle(1)
                                : _vm.getRegularStyle(1)
                            ],
                            attrs: { id: _vm.getMyRoomId }
                          },
                          [
                            !_vm.isMobile
                              ? _c("CallTopBar", {
                                  attrs: {
                                    currentDesktop: _vm.currentDesktop,
                                    presenterMode: _vm.presenterMode,
                                    uuid: _vm.state.ownUUID,
                                    amImoderator: _vm.getAmIModerator,
                                    room: _vm.room,
                                    isOnlyOneCallUser: _vm.isOnlyOneCallUser
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("DominantSpeakerIconCall", {
                              attrs: {
                                isDominantSpeaker: _vm.isDominantSpeaker(),
                                isLocal: true
                              }
                            }),
                            _vm._v(" "),
                            !_vm.localVideoTrack || _vm.videoDisabled
                              ? [
                                  _c("div", { staticClass: "imgContent" }, [
                                    _c(
                                      "div",
                                      { staticClass: "imgUserContainer" },
                                      [
                                        _c("v-img", {
                                          class: "imageCall",
                                          attrs: {
                                            contain: "",
                                            "max-height": !_vm.isMobile
                                              ? 105
                                              : 80,
                                            "max-width": !_vm.isMobile
                                              ? 105
                                              : 80,
                                            src:
                                              _vm.getAvatarForUuid(
                                                _vm.state.ownUUID
                                              ) ||
                                              "img/default_profile_picture.png",
                                            "lazy-src":
                                              "img/default_profile_picture.png"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("video", {
                              ref: "localVideo",
                              staticClass: "localVideo objectFit",
                              class: {
                                "d-none":
                                  !_vm.localVideoTrack || _vm.videoDisabled
                              },
                              attrs: {
                                autoplay: "",
                                playsinline: "",
                                muted: ""
                              },
                              domProps: { muted: true }
                            }),
                            _vm._v(" "),
                            _c("audio", {
                              ref: "localAudio",
                              staticClass: "d-none",
                              attrs: { controls: "", autoplay: "", muted: "" }
                            }),
                            _vm._v(" "),
                            _c("LocalAlertsMessages", {
                              attrs: {
                                micMute: _vm.micMute,
                                room: _vm.room,
                                badQualityUser: _vm.badQualityUser,
                                audioOutputMuted: _vm.audioOutputMuted,
                                cameraOffByModerator: _vm.cameraOffByModerator
                              }
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.remoteParticipants, function(
                          participant,
                          index
                        ) {
                          return _c(
                            "v-col",
                            {
                              key: participant.participantId,
                              ref:
                                "remoteParticipantContainer-" +
                                participant.participantId,
                              refInFor: true,
                              staticClass: "participantColumn px-0 py-0",
                              class: [
                                _vm.userMaximized &&
                                _vm.userMaximized !== participant.participantId
                                  ? "d-none"
                                  : "",
                                _vm.currentDesktop || _vm.presenterMode
                                  ? _vm.getPresentationStyle(
                                      index + 2,
                                      participant.participantId
                                    )
                                  : _vm.getRegularStyle(index, participant)
                              ],
                              attrs: { id: participant.participantId },
                              on: {
                                mouseleave: function($event) {
                                  _vm.hideRemoteBar
                                    ? _vm.hideRemoteBar()
                                    : false
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pRelative removeVideoParent" },
                                [
                                  _c("CallTopBar", {
                                    attrs: {
                                      uuid: participant.userUUID,
                                      amImoderator: _vm.isUserModerator(
                                        participant.participantId
                                      ),
                                      currentDesktop: _vm.currentDesktop,
                                      presenterMode: _vm.presenterMode,
                                      showHand: _vm.showWaveHand(
                                        participant.participantId
                                      ),
                                      moveMouserCardRemote:
                                        _vm.moveMouserCardRemote,
                                      hideRemoteBar: _vm.hideRemoteBar,
                                      participantId: participant.participantId,
                                      getActiveUsers: _vm.getActiveUsers,
                                      room: _vm.room,
                                      isOnlyOneCallUser: _vm.isOnlyOneCallUser
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("CallMessageAlerts", {
                                    attrs: {
                                      participantId: participant.participantId,
                                      userUUID: participant.userUUID,
                                      isUserAudioOff: _vm.getUserAudioOff,
                                      badQualityUser: _vm.badQualityUser,
                                      participantsMuted: _vm.participantsMuted,
                                      participantsCameraOff:
                                        _vm.participantsCameraOff
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("DominantSpeakerIconCall", {
                                    attrs: {
                                      isDominantSpeaker: _vm.isDominantSpeaker(
                                        participant.participantId
                                      ),
                                      isLocal: false,
                                      participantId: participant.participantId
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imgContent",
                                      class: {
                                        "d-none": participant.videoTrackActive
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            imgUserContainerCalling:
                                              participant.calling,
                                            imgUserContainer: !participant.calling
                                          }
                                        },
                                        [
                                          _c("v-img", {
                                            class: "imageCall",
                                            attrs: {
                                              contain: "",
                                              "max-height": "105px",
                                              "max-width": "105px",
                                              src:
                                                _vm.getAvatarForUuid(
                                                  participant.userUUID
                                                ) ||
                                                "img/default_profile_picture.png",
                                              "lazy-src":
                                                "img/default_profile_picture.png"
                                            }
                                          }),
                                          _vm._v(" "),
                                          participant.calling
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "waveCallingBridge"
                                                },
                                                _vm._l(3, function(index) {
                                                  return _c("div", {
                                                    key: index,
                                                    staticClass: "dot"
                                                  })
                                                }),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          participant.calling
                                            ? _c(
                                                "div",
                                                {
                                                  ref:
                                                    "callingBar_" +
                                                    participant.userUUID,
                                                  refInFor: true,
                                                  staticClass: "icons"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "buttosContainer"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "buttonsCall",
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              color:
                                                                                "white"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                $event.stopPropagation()
                                                                                return _vm.hangUpCalling(
                                                                                  participant.userUUID
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            staticClass:
                                                                              "faPhoneRotate",
                                                                            style: {
                                                                              fontSize:
                                                                                "20px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "phone"
                                                                              ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "generics.hangUp"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "wh100",
                                      class: {
                                        "d-none": !participant.videoTrackActive
                                      }
                                    },
                                    [
                                      _c("video", {
                                        ref: participant.videoTrack,
                                        refInFor: true,
                                        staticClass: "remoteVideo",
                                        class: {
                                          "d-none": !participant.videoTrackActive,
                                          objectFit: _vm.addObjectFit(index)
                                        },
                                        attrs: { autoplay: "", playsinline: "" }
                                      }),
                                      _vm._v(" "),
                                      _c("audio", {
                                        ref: participant.audioTrack,
                                        refInFor: true,
                                        staticClass: "d-none remoteAudio",
                                        attrs: { controls: "", autoplay: "" }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  participant.participantId
                                    ? _c("RemoteButtonsBar", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.showBarOnOverRemote ===
                                              participant.participantId,
                                            expression:
                                              "showBarOnOverRemote === participant.participantId"
                                          }
                                        ],
                                        attrs: {
                                          parentReferences: _vm.$refs,
                                          participant: participant,
                                          isUserModerator: _vm.isUserModerator(
                                            participant.participantId
                                          ),
                                          room: _vm.room,
                                          participantsMuted:
                                            _vm.participantsMuted,
                                          getMicrophoneLevel:
                                            _vm.getMicrophoneLevel,
                                          isFullScreen: _vm.isFullScreen,
                                          exitFullScreen: _vm.exitFullScreen,
                                          enterFullScreen: _vm.enterFullScreen,
                                          setUserMaximized:
                                            _vm.setUserMaximized,
                                          isLocalFullScreen:
                                            _vm.localFullScreen,
                                          hasPrivilege: _vm.hasPrivilege(
                                            participant.userUUID
                                          ),
                                          getAmIModerator: _vm.getAmIModerator,
                                          presenterModeInitiatedBy:
                                            _vm.presenterModeInitiatedBy,
                                          toggleGiveWord: _vm.toggleGiveWord,
                                          wavingHand: _vm.wavingHand,
                                          currentDesktop: _vm.currentDesktop,
                                          setMaximizeScreen:
                                            _vm.setMaximizeScreen,
                                          showBarOnOverRemote:
                                            _vm.showBarOnOverRemote ===
                                            participant.participantId,
                                          fullScreenActive:
                                            _vm.fullScreenActive,
                                          isOnlyOneCallUser:
                                            _vm.isOnlyOneCallUser,
                                          isConferenceCall:
                                            _vm.isConferenceCall,
                                          changeActualFullScreenTypeRemote:
                                            _vm.changeActualFullScreenTypeRemote,
                                          actualFullScreenTypeRemote:
                                            _vm.actualFullScreenTypeRemote,
                                          changeMenuIsOpen:
                                            _vm.changeMenuIsOpen,
                                          wordGivenTo: _vm.wordGivenTo,
                                          speakerView: _vm.speakerView,
                                          changeSpeakerView:
                                            _vm.changeSpeakerView,
                                          moveMouserCardRemote:
                                            _vm.moveMouserCardRemote,
                                          hideRemoteBar: _vm.hideRemoteBar,
                                          getActiveUsers: _vm.getActiveUsers,
                                          participantsCameraOff:
                                            _vm.participantsCameraOff,
                                          toggleCameraOff: _vm.toggleCameraOff,
                                          getIsParticipantIsPodium:
                                            _vm.getIsParticipantIsPodium,
                                          getIsConferenceHall:
                                            _vm.getIsConferenceHall,
                                          getCallUUID: _vm.getCallUUID,
                                          micMutedByAdmin: _vm.micMutedByAdmin,
                                          toggleRemoteMic: _vm.toggleRemoteMic
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.currentDesktop
                          ? _c(
                              "v-col",
                              {
                                ref: "shareScreenContainer",
                                staticClass: "participantColumn px-0 py-0",
                                class: [
                                  _vm.userMaximized ? "d-none" : "",
                                  _vm.currentDesktop ? _vm.getMiddleClass : "",
                                  _vm.classChooseShareScreen
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      mousemove: function($event) {
                                        return _vm.moveMouseShareScreen()
                                      }
                                    }
                                  },
                                  [
                                    _c("CallTopBar", {
                                      attrs: {
                                        uuid: _vm.getShareScreenTopBar,
                                        amImoderator: _vm.getDesktopIsModerator,
                                        currentDesktop: _vm.currentDesktop,
                                        presenterMode: _vm.presenterMode,
                                        isOnlyOneCallUser:
                                          _vm.isOnlyOneCallUser,
                                        room: _vm.room
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showShareScreenBar,
                                            expression: "showShareScreenBar"
                                          }
                                        ],
                                        staticClass: "iconsShareScreen",
                                        on: {
                                          mouseleave: function($event) {
                                            return _vm.hideShareScreenBar()
                                          },
                                          mousemove: function($event) {
                                            return _vm.moveMouseShareScreen()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "buttosContainer" },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "white"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    return _vm.toggleFullSharescreen()
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              !_vm.localFullScreen
                                                                ? _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      style: {
                                                                        fontSize:
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "expand-wide"
                                                                        ]
                                                                      }
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      style: {
                                                                        fontSize:
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "compress-wide"
                                                                        ]
                                                                      }
                                                                    }
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2094230790
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm.localFullScreen
                                                        ? _vm.$t(
                                                            "generics.fullscreen"
                                                          )
                                                        : _vm.$t(
                                                            "generics.normalScreen"
                                                          )
                                                    ) +
                                                      "\n                      "
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.classChooseShareScreen
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "textChooseShareScreen text-center white--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.callsContent.screenSharePreparation"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("video", {
                                  ref: "localVideoShareScreen",
                                  staticClass: "localVideoShareScreen",
                                  attrs: {
                                    autoplay: "",
                                    playsinline: "",
                                    muted: ""
                                  },
                                  domProps: { muted: true }
                                }),
                                _vm._v(" "),
                                _c("audio", {
                                  ref: "remoteAudioShareScreen",
                                  staticClass: "d-none remoteAudio",
                                  attrs: { controls: "", autoplay: "" }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.getIsConferenceHall
                  ? _c(
                      "div",
                      { staticClass: "listenerWrapper" },
                      [
                        _c("ListenerView", {
                          attrs: {
                            chatOpen: _vm.chatOpen,
                            getAmIModerator: _vm.getAmIModerator,
                            isListenerView: false
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "localButtonsWrapper localButtonsBar" },
            [
              _c("LocalButtonsBar", {
                attrs: {
                  downloadSupportLog: _vm.downloadSupportLog,
                  rejectCall: _vm.rejectCall,
                  rejectingCall: _vm.rejectingCall,
                  toggleVideo: _vm.toggleVideo,
                  videoDisabled: _vm.videoDisabled,
                  toggleMic: _vm.toggleMic,
                  micMute: _vm.micMute,
                  getMicrophoneLevel: _vm.getMicrophoneLevel,
                  toggleMuteAudio: _vm.toggleMuteAudio,
                  audioOutputMuted: _vm.audioOutputMuted,
                  toggleLocalFullScreen: _vm.toggleLocalFullScreen,
                  localFullScreen: _vm.localFullScreen,
                  getAmIModerator: _vm.getAmIModerator,
                  toggleAllRemoteMic: _vm.toggleAllRemoteMic,
                  toggleShareScreen: _vm.toggleShareScreen,
                  currentDesktop: _vm.currentDesktop,
                  getMyDesktopRoomUserID: _vm.getMyDesktopRoomUserID,
                  togglePresentation: _vm.togglePresentation,
                  presenterModeInitiatedBy: _vm.presenterModeInitiatedBy,
                  room: _vm.room,
                  toggleGiveWord: _vm.toggleGiveWord,
                  toggleWaveHand: _vm.toggleWaveHand,
                  wavingHand: _vm.wavingHand,
                  getTotalRemoteParticipants: _vm.getTotalRemoteParticipants,
                  implicitPresenterMode: _vm.implicitPresenterMode,
                  isConferenceCall: _vm.isConferenceCall,
                  hangUpBridgeCall: _vm.hangUpBridgeCall,
                  getCallUUID: _vm.getCallUUID,
                  getconfId: _vm.getconfId,
                  presenterMode: _vm.presenterMode,
                  wordGivenTo: _vm.wordGivenTo,
                  fullScreenActive: _vm.fullScreenActive,
                  changeMenuIsOpen: _vm.changeMenuIsOpen,
                  isUserInCall: _vm.isUserInCall,
                  kickUser: _vm.kickUser,
                  getIsMobile: _vm.getIsMobile,
                  toggleRotateCamera: _vm.toggleRotateCamera,
                  changeSpeakerView: _vm.changeSpeakerView,
                  speakerView: _vm.speakerView,
                  getActiveUsers: _vm.getActiveUsers,
                  myDocuments: _vm.myDocuments,
                  videoDeviceId: _vm.videoDeviceId,
                  toggleChat: _vm.toggleChat,
                  getTotalReceivedChatMessages:
                    _vm.getTotalReceivedChatMessages,
                  chatOpen: _vm.chatOpen,
                  stopShareScreen: _vm.stopShareScreen,
                  cameraOffByModerator: _vm.cameraOffByModerator,
                  toggleAllCameraOff: _vm.toggleAllCameraOff,
                  allRemoteCameraOff: _vm.allRemoteCameraOff,
                  allMods: _vm.allMods,
                  getIsParticipantIsPodium: _vm.getIsParticipantIsPodium,
                  getIsConferenceHall: _vm.getIsConferenceHall,
                  chatMessageNotificationSound:
                    _vm.chatMessageNotificationSound,
                  micMutedByAdmin: _vm.micMutedByAdmin,
                  allRemoteMicMuted: _vm.allRemoteMicMuted,
                  downloadChat: _vm.downloadChat
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showFinishCallModal
        ? _c("FinishCallModal", {
            attrs: {
              showFinishCallModal: _vm.showFinishCallModal,
              closeModal: _vm.showCloseFinishCallModal,
              rejectCall: _vm.rejectCall
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }