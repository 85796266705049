 <template>
  <span v-if="person && person.user && person.user.uuid">
    <template v-if="organisationData && organisationData.name">
      <span>{{ organisationData.name }}</span>
      <br />
    </template>
    <!-- <template v-if="organisationData && organisationData.position">
      <span>{{ organisationData.position }}</span>
      <br />
    </template> -->
    <template v-if="person && person.user && person.user.uuid">
      <span v-if="!isDummyUser(person)"
        >{{ assistant ? "Assistant" : getUserTitel(person.user.uuid) }}
        {{ person.user.name }}
      </span>
      <span v-else>
        {{$t("components.adminSettingsModal.callEveryUser")}}
      </span>
      <br
        v-if="hasRepresentative(person.user.uuid)"
      />
      <span v-if="hasRepresentative(person.user.uuid)">{{ getUserRepresentative(person.user.uuid) }}</span>
      <br
        v-if="
          person.user.position ||
          (person.user.visitorData &&
            person.user.visitorData.noteForContact &&
            person.user.visitorData.noteForContact.length)
        "
      />
    </template>
    <span v-if="hasPrivilege(person.user.uuid) && person.user.position">{{
      person.user.position
    }}</span>
    <span
      v-if="
        !hasPrivilege(person.user.uuid) &&
        person.user.visitorData &&
        person.user.visitorData.noteForContact
      "
      >{{ person.user.visitorData.noteForContact }}</span
    >
    <template
      v-if="
        hasPrivilege(person.user.uuid) && getLocationTranslated(person.user)
      "
    >
      <br />
      <span>{{ getLocationTranslated(person.user)}}</span>
    </template>
    <template v-if="state.namespaceSettings.displayUserStatus">
      <template v-if="!isGuest && userInStaticRoom(person.user.uuid) && !isDummyUser(person)">
        <br/>
        <span class="bold">
          {{getStaticRoomName(person.user.uuid)}}
        </span>
      </template>
      <!-- email indicates if it is dummy or not -->
      <br v-if="getUserActivity(person) && !isGuest && !isDummyUser(person)" />
      <span v-if="!isGuest && !isDummyUser(person)">
        <span v-if="isUserInConference()">{{ getConferenceName }}<br/></span>
        <font-awesome-icon
          :icon="['fal', 'video']"
          v-if="getCallText(person) && person.user.activity !== 'No status'"
        />
        {{
          getCallText(person) && person.user.activity !== 'No status'
            ? getCallText(person)
            : checkStatusLabel
        }}
      </span>
      <br v-if="person.user.activity !== 'No status' && person.user.activity !== 'Available' && getCallText(person)" />
      <span v-if="person.user.activity !== 'No status' && person.user.activity !== 'Available' && getCallText(person)">{{ checkStatusLabel }}</span>
      <br
        v-if="
          (!getCallText(person) && getPersonInHolidays && !isGuest) ||
          (getCallText(person) && getPersonInHolidays) ||
          (!getCallText(person) && getPersonInIllness && !isGuest) ||
          (getCallText(person) && getPersonInIllness)
        "
      />
      <span v-if="getPersonInHolidays && !isGuest"
        >{{ moment(new Date(person.user.startHolidays)).format("DD.MM.YY") }}
        -
        {{ moment(new Date(person.user.endHolidays)).format("DD.MM.YY") }}</span
      >
      <span v-if="getPersonInIllness && !isGuest"
        >{{ moment(new Date(person.user.startIllness)).format(absenceMomentFormat) }}
        -
        {{ moment(new Date(person.user.endIllness)).format(absenceMomentFormat) }}</span
      >
      <template
        v-if="person.user.activity == 'Break' && person.user.breakTimeEnd"
      >
        <br />
        <span> {{ breakTimeEnd }} {{ $t("generics.hoursGerman") }} </span>
      </template>
      <br />
      <span v-if="getAdditionalStatus" :style="`white-space: pre-line;`">
        {{ getAdditionalStatus }}
      </span>
    </template>
  </span>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { userInCallText, userInStaticRoom, getStaticRoomName, personIsInConference, getConfId, personIsInConferenceHall } from "../../utils/calls";
import {
  locationTranslated,
  userHasHolidaysSetted,
  userHasIllnessSetted,
  getUserActivityFunction,
} from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges.js";
import { hasRepresentative } from "../../utils/representatives";

export default {
  props: ["person", "assistant", "organisationData", "isRegularGrid"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment,
      intervalId: null,
      userAbsent: false,
      date: Date.now(), // Unique identifier
      conferenceName: "", // In theory should never be empty as name for conf is required
    };
  },
  created() {
    if (this.isRegularGrid && /T/.test(this.person?.user?.startIllness) && /T/.test(this.person?.user?.endIllness)) {
      this.userAbsent = userHasIllnessSetted(this.person.user.uuid);
    }
  },
  mounted() {
    if (this.isRegularGrid && /T/.test(this.person?.user?.startIllness) && /T/.test(this.person?.user?.endIllness)) {
      this.intervalId = setInterval(this.checkIllnessTime, 5000);
    }
  },
  beforeUnmount() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    isDummyUser(person){
      return person?.user && person.user.email === '';
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getLocationTranslated(person) {
      return locationTranslated(person);
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getUserRepresentative(uuid) {
      return store.getUserRepresentativeName(uuid);
    },
    getCallText(person) {
      return userInCallText(person);
    },
    userInStaticRoom(uuid) {
      return userInStaticRoom(uuid);
    },
    getStaticRoomName(uuid) {
      return getStaticRoomName(uuid);
    },
    getUserActivity(person) {
      return getUserActivityFunction(person);
    },
    hasRepresentative(uuid) {
      return hasRepresentative(uuid);
    },
    checkIllnessTime() {
      const userAbsentNow = userHasIllnessSetted(this.person.user.uuid);
      if (this.userAbsent != userAbsentNow) {
        this.userAbsent = userAbsentNow;
        this.updateGrid();
      }
    },
    updateGrid() {
      this.date = Date.now();
      this.$emit('updateGrid');
    },
    isUserInConference() {
      return (personIsInConference(this.person.user.uuid) || personIsInConferenceHall(this.person.user.uuid));
    },
    getConfId() {
      return getConfId(this.person.user.uuid);
    },
  },
  computed: {
    getConferenceName() {
      if (this.person.user.uuid && this.state.group[this.person.user.uuid]?.user?.bridgeCallInfo?.confName) {
        return this.state.group[this.person.user.uuid]?.user?.bridgeCallInfo?.confName;
      }
      return 'NameNotFound';
    },
    absenceMomentFormat() {
      if (/T/.test(this.person?.user?.startIllness) && /T/.test(this.person?.user?.endIllness)) {
        return "DD.MM.YY HH:mm";
      }      
      return "DD.MM.YY";
    },
    getAdditionalStatus() {
      const noCallsAdditionalInfo =
        this.person?.user?.noCallsAdditionalInfo === 'components.conferenceForm.externalConference'
          ? this.$t(this.person.user.noCallsAdditionalInfo)
          : this.person?.user?.noCallsAdditionalInfo;
      return ((noCallsAdditionalInfo || "") + "\n" + (this.person?.user?.additionalStatus || "")).trim();
    },
    breakTimeEnd() {
      if ((this.person.user.breakTimeEnd || '').length > 5 && Date.parse(this.person.user.breakTimeEnd)) {
        return moment(this.person.user.breakTimeEnd).format("HH:mm");
      }
      return this.person.user.breakTimeEnd;
    },
    checkStatusLabel() {
      if (
        this.person.user.activity === "Break" &&
        this.person.user.breakTimeEnd
      ) {
        return this.$t("generics.expectedReturn");
      } else {
        if (this.getPersonInHolidays) {
          return this.$t('status.Holidays');
        } else if (this.getPersonInIllness) {
          return this.$t('status.Ill');
        } else {
          return this.getUserActivity(this.person);
        }
      }
    },
    getPersonInHolidays() {
      return userHasHolidaysSetted(this.person.user.uuid);
    },
    getPersonInIllness() {
      return userHasIllnessSetted(this.person.user.uuid);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>