var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeInfoModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showInfoModal,
        callback: function($$v) {
          _vm.showInfoModal = $$v
        },
        expression: "showInfoModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.inviteVisitor.title"),
              closeModalFunction: _vm.closeInfoModal
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "bodyContainer",
              style: { width: "100%" },
              attrs: { flex: "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0 py-0 mx-auto text-center",
                  attrs: { lg: "6" }
                },
                [
                  !_vm.userSaveLimit
                    ? _c("v-card-text", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.inviteVisitor.youCantInviteUser")
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.userSaveLimit
                    ? _c("v-card-text", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.inviteVisitor.maxEmailsAllowed")
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.closeInfoModal, showClose: true }
          }),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }