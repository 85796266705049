var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      model: {
        value: _vm.showUploadUserPictureModal,
        callback: function($$v) {
          _vm.showUploadUserPictureModal = $$v
        },
        expression: "showUploadUserPictureModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "HeaderModal",
            {
              attrs: {
                titleModal: _vm.$t(
                  "components.uploadUserPictureModal.providePhoto"
                ),
                closeModalFunction: _vm.closeUserProfileAndCamera
              }
            },
            [_c("v-spacer")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCroppie,
                  expression: "showCroppie"
                }
              ]
            },
            [
              _c(
                "v-row",
                { staticClass: "d-flex mx-auto pt-5 pb-2" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("vue-croppie", {
                        ref: "croppieRef",
                        staticClass: "sliderSize",
                        attrs: {
                          enableResize: false,
                          enforceBoundary: true,
                          enableExif: true,
                          boundary: { width: 256, height: 256 },
                          viewport: { width: 246, height: 246, type: "square" }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "rotateIcons" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "", color: "grey" },
                                        on: {
                                          click: function($event) {
                                            return _vm.rotate(-90)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "undo"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.navbar.rotateLeft"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "", color: "grey" },
                                        on: {
                                          click: function($event) {
                                            return _vm.rotate(90)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "fa-flip-horizontal",
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "undo"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.navbar.rotateRight"))
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-2 btns",
                      style: { width: "100%", margin: 0 },
                      attrs: { flex: "" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "px-0 py-0", attrs: { align: "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "buttonPrimaryFooter",
                              attrs: { depressed: "", color: "white" },
                              on: { click: _vm.crop }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.save")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3 mr-2",
                              staticStyle: {
                                "border-radius": "4px",
                                "min-width": "45px"
                              },
                              attrs: {
                                color: "white",
                                icon: "",
                                tile: "",
                                outlined: ""
                              },
                              on: { click: _vm.cancelCrop }
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          ),
          _vm._v(" "),
          !_vm.showCroppie
            ? _c(
                "v-row",
                { style: { width: "100%", margin: 0 }, attrs: { flex: "" } },
                [
                  _c("input", {
                    ref: "fileInput",
                    staticClass: "d-none",
                    attrs: { type: "file" },
                    on: {
                      change: function($event) {
                        return _vm.showCroppieModal($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "div",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-list-item-avatar",
                          {
                            staticClass: "borderRadius10",
                            attrs: { tile: "", size: "150" }
                          },
                          [
                            [
                              !_vm.state.user.avatar ||
                              _vm.state.user.avatar ===
                                "img/default_profile_picture.png"
                                ? _c("DefaultAvatar", {
                                    attrs: {
                                      size: 150,
                                      userData: _vm.state.user
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.state.user.avatar &&
                              _vm.state.user.avatar !==
                                "img/default_profile_picture.png"
                                ? _c("v-img", {
                                    staticClass: "borderRadius10",
                                    attrs: {
                                      "max-height": "150",
                                      "max-width": "150",
                                      contain: "",
                                      src: _vm.state.user.avatar,
                                      "lazy-src":
                                        "assets/default_profile_picture.png"
                                    }
                                  })
                                : _vm._e()
                            ],
                            _vm._v(" "),
                            _vm.state.ownModalUser
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uploadAvatarButtons p-4 mx-auto",
                                    class: {
                                      hasImage:
                                        _vm.state.user.avatar !=
                                        "img/default_profile_picture.png",
                                      hasNoImage:
                                        _vm.state.user.avatar ==
                                        "img/default_profile_picture.png"
                                    },
                                    attrs: { flex: "" }
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          depressed: "",
                                                          color: "primary",
                                                          isSmall: "true"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.selectAvatar
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          fontSize: "20px"
                                                        },
                                                        attrs: {
                                                          icon: [
                                                            "fal",
                                                            "upload"
                                                          ]
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1093277184
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.navbar.uploadPicture"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { style: { width: "100%", margin: 0 } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0",
                            attrs: {
                              label: _vm.$t("generics.dontShowAgain"),
                              "hide-details": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateDontShowAgain()
                              }
                            },
                            model: {
                              value: _vm.dontShowModal,
                              callback: function($$v) {
                                _vm.dontShowModal = $$v
                              },
                              expression: "dontShowModal"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.showCroppie
            ? _c(
                "FooterModal",
                {
                  attrs: {
                    closeModalFunction: _vm.closeUserProfileAndCamera,
                    hideDefaultButtons: true
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3 mr-2",
                      staticStyle: {
                        "border-radius": "4px",
                        "min-width": "45px"
                      },
                      attrs: { tile: "" },
                      on: { click: _vm.closeUserProfileAndCamera }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("components.newVersionAvailable.later")
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }