var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "550" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "title" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("components.licenseExpiredModal.licenseExpired")
                      ) +
                      "\n    "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "mt-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("components.licenseExpiredModal.pleaseRenew"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.licenseExpiredModal.continueUsing")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "8", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.openPage()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.licenseExpiredModal.activate"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }