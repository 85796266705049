<template>
  <v-row class="px-4">
    <v-col cols="2" v-if="amIAdmin && getUsers.length">
      <v-list dense>
        <!-- <v-list-item-title class="mb-3 primary--text">
          {{ $t("generics.users") }}
        </v-list-item-title> -->
        <span class="d-flex align-center mb-3">
          <v-text-field
            class="pt-0"
            style="width: 310px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('generics.search')"
            single-line
            hide-details
          />
          <span class="ml-4 pointer" @click="cleanSearch()">
            <font-awesome-icon
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px' }"
            />
          </span>
        </span>
        <v-list-item-group
          v-model="selectedUser"
          color="primary"
          class="listWrapper"
        >
          <v-list-item
            v-for="user in filteredUsers"
            :key="user.uuid"
            :value="user.uuid"
          >
            <v-list-item-content>
              <v-list-item-title v-text="`${user.titel} ${user.name}`"/>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col :cols="(amIAdmin && getUsers.length) ? 10 : 12">
      <div class="pt-2 px-3  tableTitle">
        <span>
          {{ $t("components.workingTime.workingTime") }}
          {{
            selectedUser
              ? `${getTitelForUuid(selectedUser)} ${getNameForUuid(
                  selectedUser
                )}`
              : ""
          }}
        </span>
      </div>
      <template v-if="selectedUser">
        <WorkingTimeCalendar :selectedUser="selectedUser" />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import store, { syncedGroupState, syncedUserState } from "../../store";
import WorkingTimeCalendar from "./workingTimeCalendar.vue";
import { hasPrivilege } from "../../utils/privileges";
export default {
  components: { WorkingTimeCalendar },
  data() {
    return {
      state: store.state,
      loadedDataPromise: Promise.resolve(),
      selectedUser: "",
      search: "",
      setCurrentContentVisile: store.setCurrentContentVisile,
    };
  },
  created() {
    this.loadedDataPromise = Promise.all([
      // wait for state
      syncedGroupState(true),
      syncedUserState().catch(() => {}),
      // wait for computed
    ]).then(() => this.$nextTick());
  },
  mounted() {
    if (!this.state.namespaceSettings.showWorkingTime) {
      this.gotoPage('home')
    }
  },
  watch: {
    selectedUser: {
      immediate: true,
      handler(value) {
        this.loadedDataPromise.then(() => {
          if (this.selectedUser !== value) return;
          if (this.amIAdmin) {
            // admin first open: select first user or own user
            if (!value || value == '') {
              if (this.getUsers.length && this.filteredUsers.length) {
                this.selectedUser = this.filteredUsers[0].uuid;
              } else {
                this.selectedUser = this.state.ownUUID;
              }
            }
          } else if (value !== this.state.ownUUID) {
            // prevent non-admin of seeing other's calendar
            this.selectedUser = this.state.ownUUID;
          }
        });
      },
    },
  },
  methods: {
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    cleanSearch() {
      this.search = "";
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    filteredUsers() {
      if (!this.search) return this.getUsers;
      return this.getUsers.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.titel.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getUsers() {
      const persons = [];
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (!person || !person.user || !person.user.name || !hasPrivilege(uuid) || !person.user.countTime) continue;
        const temporalObject = {
          uuid,
          name: this.getNameForUuid(uuid),
          titel: this.getTitelForUuid(uuid)
        }
        persons.push(temporalObject);
      }
      persons.sort((a, b) => a.name.localeCompare(b.name));
      return persons;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.listWrapper {
  overflow-y: auto;
  min-height: calc(100vh - 25vh);
  max-height: calc(100vh - 25vh);
  height: calc(100vh - 25vh);
}

.tableTitle {
  font-size: 1rem;
}
</style>