var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { "max-width": "320" } },
    [
      _c(
        "v-list-group",
        {
          attrs: {
            value: _vm.searchFoundSomethingSection || _vm.isActive,
            color: _vm.isDarkMode ? "white" : "black"
          },
          on: {
            click: function($event) {
              return _vm.setList("sections")
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "primary--text",
                        attrs: { icon: ["fal", "sitemap"] }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-title",
                    {
                      class:
                        "" +
                        (_vm.getRoute === "/organisation" &&
                        _vm.state.activeOrganisationSection == "sections"
                          ? "font-weight-bold"
                          : ""),
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.handleOrganisation()
                          _vm.searchFoundSomethingSection
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "pr-4" }, [
                        _vm._v(
                          _vm._s(_vm.data ? Object.keys(_vm.data).length : "0")
                        )
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.state.namespaceSettings.sectionAlias
                              ? _vm.state.namespaceSettings.sectionAlias
                              : _vm.$t("generics.sections")
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-list",
                { staticClass: "listHeight", attrs: { dense: "" } },
                _vm._l(_vm.data, function(people, section) {
                  return _c(
                    "v-list-item",
                    {
                      key: section,
                      staticClass: "ml-9 cursorAuto",
                      attrs: { link: "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-truncate ",
                          class:
                            _vm.selectedSectionName === section
                              ? "font-weight-bold primary--text"
                              : "",
                          on: {
                            click: function($event) {
                              return _vm.openUserDepartment(section)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(section) + "\n          "
                          )
                        ]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }