var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "sandbox" } },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "toTheLeft",
          attrs: { "clipped-left": _vm.primaryDrawer.clipped, app: "" }
        },
        [
          _c("v-img", {
            staticClass: "mx-2",
            attrs: {
              src: "assets/icon.png",
              "max-height": "45",
              "max-width": "45",
              contain: ""
            }
          }),
          _vm._v(" "),
          _c(
            "v-toolbar-title",
            { staticClass: "linkApp d-none d-md-flex font-weight-bold body-1" },
            [_vm._v("vOffice")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        { staticClass: "justPaddingTop" },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto elevation-0 text-center",
              attrs: { "fill-height": "", flat: "", "max-width": "100%" }
            },
            [
              _c("v-card-title", { staticClass: "primary--text fontSize22" }, [
                _vm._v("\n        Decrypt Error\n      ")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0 text-center",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-card-text", { staticClass: "fontSize17" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("components.decryptError.errorText")
                            ) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }