var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-avatar",
        {
          class:
            (!_vm.imageAssistant ? "borderRadius0" : "") +
            "  whiteBackground defaultAvatar " +
            (_vm.showSectionName ? "gridStyle" : "") +
            " " +
            (_vm.imageAssistant ? "imageAssistant" : "") +
            " " +
            (_vm.isGroupGrid ? "displayGroupGrid" : ""),
          attrs: { width: "100%", height: "100%", color: null }
        },
        [
          _vm.showSectionName
            ? _c("span", { staticClass: "letterColor" }, [
                _vm._v(_vm._s(_vm.mainSectionName))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isGroupGrid
            ? _c(
                "span",
                { staticClass: "title letterColor headlingGroupGrid" },
                [
                  _vm._v(_vm._s(_vm.getGroupName) + " \n      "),
                  _vm.getGroupName ? _c("br") : _vm._e(),
                  _vm._v(
                    "\n      " + _vm._s(_vm.getGroupTotalMembers) + "\n      "
                  ),
                  _vm.getGroupDateTime ? _c("br") : _vm._e(),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.getGroupDateTime
                          ? _vm
                              .moment(new Date(_vm.getGroupDateTime))
                              .format("DD.MM.YY H:mm")
                          : ""
                      ) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isGuestGrid || _vm.isUserGrid
            ? _c("v-img", {
                staticClass: "cursorPointer",
                class: {
                  borderColor: _vm.imageAssistant,
                  borderNone: !_vm.imageAssistant,
                  "mt-6": !_vm.isLateralPanel
                },
                style: _vm.imageAssistant
                  ? "border-color: " +
                    _vm.getStatusColorByUser(
                      _vm.userData.user,
                      _vm.userData.connected
                    )
                  : "",
                attrs: {
                  src: _vm.userAvatar,
                  contain: _vm.getPictureSize,
                  "lazy-src": _vm.getDefaultPicture
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }