var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "950",
            fullscreen: _vm.isMobile
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.toggleModalAdminSettings.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showAdminSettings,
            callback: function($$v) {
              _vm.showAdminSettings = $$v
            },
            expression: "showAdminSettings"
          }
        },
        [
          _c(
            "v-card",
            {
              class: {
                height494: _vm.showCroppieLogo,
                heightAuto: !_vm.showCroppieLogo
              },
              attrs: { flat: "" }
            },
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.$t("generics.adminSettings"),
                  closeModalFunction: _vm.closeModalFunction
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.showCroppieLogo &&
                        !_vm.showRelayText &&
                        !_vm.showAliasText,
                      expression:
                        "showCroppieLogo && !showRelayText && !showAliasText"
                    }
                  ],
                  staticClass: "mx-3"
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 py-0 px-0" },
                    [
                      _c("vue-croppie", {
                        ref: "croppieRef",
                        attrs: {
                          enableResize: false,
                          enforceBoundary: true,
                          enableExif: true,
                          boundary: { width: 256, height: 256 },
                          viewport: { width: 246, height: 246, type: "circle" }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        {
                          staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                          attrs: { color: "primary" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-2 btns",
                              style: { width: "100%", margin: 0 },
                              attrs: { flex: "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 py-0",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "white"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.rotate(-90)
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: ["fal", "undo"]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.navbar.rotateLeft"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "white"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.rotate(90)
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "fa-flip-horizontal",
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: ["fal", "undo"]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.navbar.rotateRight"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0 py-0",
                                  attrs: { cols: "8", align: "right" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        depressed: ""
                                      },
                                      on: { click: _vm.cancelCrop }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        depressed: ""
                                      },
                                      on: { click: _vm.crop }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("generics.save")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showCroppieLogo && !_vm.showRelayText && !_vm.showAliasText
                ? _c(
                    "v-row",
                    {
                      staticClass: "mx-0",
                      class: {
                        rowSettingsMobile: _vm.isMobile,
                        rowSettings: !_vm.isMobile
                      }
                    },
                    _vm._l(2, function(n) {
                      return _c(
                        "v-col",
                        {
                          key: n,
                          staticClass: "text-center py-0",
                          attrs: {
                            lg: _vm.cols[n - 1],
                            md: _vm.cols[n - 1],
                            sm: _vm.cols[n - 1],
                            xl: _vm.cols[n - 1],
                            id: n == 2 ? "accordionsSection" : "upperSection"
                          }
                        },
                        [
                          n == 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-0" },
                                    _vm._l(2, function(n) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: n,
                                          staticClass: "text-center col-12",
                                          attrs: {
                                            lg: _vm.colsLogo[n - 1],
                                            md: _vm.colsLogo[n - 1],
                                            sm: _vm.colsLogo[n - 1],
                                            xl: _vm.colsLogo[n - 1]
                                          }
                                        },
                                        [
                                          n == 1
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "py-0 my-0"
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-left mb-1 subtitle-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.adminSettingsModal.vOfficeLogo"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-left mb-0 caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.adminSettingsModal.vOfficeLogoText"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-avatar",
                                                    {
                                                      staticClass:
                                                        "borderRadius10",
                                                      class: {
                                                        "mr-0": _vm.isMobile
                                                      },
                                                      attrs: {
                                                        tile: "",
                                                        size: "150"
                                                      }
                                                    },
                                                    [
                                                      _c("v-divider"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              style: {
                                                                width: "100%",
                                                                margin: 0
                                                              },
                                                              attrs: {
                                                                flex: ""
                                                              }
                                                            },
                                                            [
                                                              _c("input", {
                                                                ref:
                                                                  "companyLogoInput",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "d-none",
                                                                attrs: {
                                                                  type: "file"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.showLogoPreviewModal(
                                                                      $event,
                                                                      "companyLogo"
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-col", [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-avatar",
                                                                      {
                                                                        staticClass:
                                                                          "borderCircle",
                                                                        class: {
                                                                          "mr-0":
                                                                            _vm.isMobile
                                                                        },
                                                                        attrs: {
                                                                          tile:
                                                                            "",
                                                                          size:
                                                                            "130"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticClass:
                                                                              "borderCircle",
                                                                            attrs: {
                                                                              id:
                                                                                "logoModal",
                                                                              "max-height":
                                                                                "130",
                                                                              "max-width":
                                                                                "130",
                                                                              contain:
                                                                                "",
                                                                              src:
                                                                                _vm
                                                                                  .state
                                                                                  .namespaceSettings
                                                                                  .companyLogo ||
                                                                                _vm
                                                                                  .state
                                                                                  .namespaceSettings
                                                                                  .companyLogo !==
                                                                                  ""
                                                                                  ? _vm
                                                                                      .state
                                                                                      .namespaceSettings
                                                                                      .companyLogo
                                                                                  : "",
                                                                              "lazy-src":
                                                                                "assets/icon.png"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "uploadAvatarButtons p-4 hasImage",
                                                                            style:
                                                                              _vm
                                                                                .state
                                                                                .namespaceSettings
                                                                                .companyLogo ===
                                                                              ""
                                                                                ? "display:block!important"
                                                                                : "",
                                                                            attrs: {
                                                                              flex:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  top:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        return [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            _vm._g(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mb-2",
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  isSmall:
                                                                                                    "true"
                                                                                                },
                                                                                                on: {
                                                                                                  click:
                                                                                                    _vm.uploadCompanyLogo
                                                                                                }
                                                                                              },
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _c(
                                                                                                "font-awesome-icon",
                                                                                                {
                                                                                                  style: {
                                                                                                    fontSize:
                                                                                                      "20px"
                                                                                                  },
                                                                                                  attrs: {
                                                                                                    icon: [
                                                                                                      "fal",
                                                                                                      "upload"
                                                                                                    ]
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "components.navbar.uploadPicture"
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "removeAvatarButtons p-4 hasImage",
                                                                            style:
                                                                              _vm
                                                                                .state
                                                                                .namespaceSettings
                                                                                .companyLogo ===
                                                                              ""
                                                                                ? "display:block!important"
                                                                                : "",
                                                                            attrs: {
                                                                              flex:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  top:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        return [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            _vm._g(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mb-2",
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  isSmall:
                                                                                                    "true"
                                                                                                },
                                                                                                on: {
                                                                                                  click:
                                                                                                    _vm.removeCompanyLogo
                                                                                                }
                                                                                              },
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _c(
                                                                                                "DeleteBasket",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    indexData:
                                                                                                      "",
                                                                                                    delFunction:
                                                                                                      _vm.removeCompanyLogo,
                                                                                                    extraClasses:
                                                                                                      "deleteBasketWhite",
                                                                                                    fontSize:
                                                                                                      "20",
                                                                                                    prevent:
                                                                                                      "false"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "generics.remove"
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-center align-baseline"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "py-0 my-0"
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-left mb-1 subtitle-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.adminSettingsModal.screenSaverLogo"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-left mb-0 caption"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.adminSettingsModal.screenSaverText"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      { staticClass: "mx-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "pl-0",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                outlined: "",
                                                                dense: "",
                                                                label: "URL",
                                                                "hide-details":
                                                                  "auto"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.companyHomePage,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.companyHomePage = $$v
                                                                },
                                                                expression:
                                                                  "companyHomePage"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "py-0 pl-0 my-0",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-left mb-1 subtitle-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.adminSettingsModal.setAlias"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-form",
                                                              {
                                                                on: {
                                                                  submit: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.aliasFormValid,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.aliasFormValid = $$v
                                                                  },
                                                                  expression:
                                                                    "aliasFormValid"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "my-0",
                                                                        staticStyle: {
                                                                          height:
                                                                            "50px"
                                                                        },
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          dense:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.officeName,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.officeName = $$v
                                                                          },
                                                                          expression:
                                                                            "officeName"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2 mt-0",
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm.officeName ||
                                                                            _vm.officeName.trim()
                                                                              .length <=
                                                                              0,
                                                                          depressed:
                                                                            "",
                                                                          color:
                                                                            "primary"
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.saveOfficeName
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "generics.save"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "py-0 pl-0",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-left mb-1 subtitle-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.adminSettingsModal.companyEmailInfo"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                    staticStyle: {
                                                                      height:
                                                                        "40px"
                                                                    },
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      label: _vm.$t(
                                                                        "components.adminSettingsModal.companyEmail"
                                                                      ),
                                                                      rules:
                                                                        _vm.companyEmailRules
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.companyEmail,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.companyEmail = $$v
                                                                      },
                                                                      expression:
                                                                        "companyEmail"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 mt-2",
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm.companyEmail ||
                                                                        !_vm
                                                                          .companyEmail
                                                                          .length ||
                                                                        _vm
                                                                          .companyEmail
                                                                          .length <
                                                                          5 ||
                                                                        !_vm.companyEmailRegex.test(
                                                                          _vm.companyEmail
                                                                        ),
                                                                      depressed:
                                                                        "",
                                                                      color:
                                                                        "primary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.setCompanyEmail()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "generics.save"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      { staticClass: "mx-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "pl-0 pt-2 pb-0 mt-1 d-flex align-center",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {},
                                                              [
                                                                _c(
                                                                  "v-radio-group",
                                                                  {
                                                                    attrs: {
                                                                      row: ""
                                                                    },
                                                                    on: {
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeBasicView()
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.basicView,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.basicView = $$v
                                                                      },
                                                                      expression:
                                                                        "basicView"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-radio",
                                                                      {
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "components.adminSettingsModal.viewReduced"
                                                                          ),
                                                                          value: true
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-radio",
                                                                      {
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "components.adminSettingsModal.viewExtended"
                                                                          ),
                                                                          value: false
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.opencloseInfoCompact()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "generics.info"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    1 === 2
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            staticClass: "mx-0",
                                                            attrs: {
                                                              justify: "center"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "py-0 px-0",
                                                                attrs: {
                                                                  cols: "6"
                                                                }
                                                              },
                                                              [
                                                                _c("v-switch", {
                                                                  attrs: {
                                                                    label: _vm.$t(
                                                                      "generics.yes"
                                                                    ),
                                                                    width:
                                                                      "100%",
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.activateScreenSaver,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.activateScreenSaver = $$v
                                                                    },
                                                                    expression:
                                                                      "activateScreenSaver"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "py-0 px-0",
                                                                attrs: {
                                                                  cols: "6"
                                                                }
                                                              },
                                                              [
                                                                _vm.activateScreenSaver
                                                                  ? _c(
                                                                      "v-radio-group",
                                                                      {
                                                                        staticClass:
                                                                          "mt-1 radioGroup",
                                                                        model: {
                                                                          value:
                                                                            _vm.radioGroup,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.radioGroup = $$v
                                                                          },
                                                                          expression:
                                                                            "radioGroup"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-radio",
                                                                          {
                                                                            attrs: {
                                                                              label:
                                                                                "1 h",
                                                                              value: 1
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-radio",
                                                                          {
                                                                            attrs: {
                                                                              label:
                                                                                "2 h",
                                                                              value: 2
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ])
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          n == 2
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-expansion-panels",
                                    {
                                      attrs: {
                                        accordion: false,
                                        popout: false,
                                        inset: false,
                                        multiple: false,
                                        focusable: false,
                                        disabled: false,
                                        readonly: false,
                                        flat: false,
                                        hover: false,
                                        tile: false
                                      },
                                      model: {
                                        value: _vm.panel,
                                        callback: function($$v) {
                                          _vm.panel = $$v
                                        },
                                        expression: "panel"
                                      }
                                    },
                                    _vm._l(_vm.getExpansionPanels, function(
                                      item,
                                      i
                                    ) {
                                      return _c(
                                        "v-expansion-panel",
                                        {
                                          key: i,
                                          style: [
                                            ((item.index === 1 ||
                                              item.index === 2) &&
                                              _vm.isBasicView) ||
                                            (item.index === 4 &&
                                              !_vm.isPayAvailable) ||
                                            (item.index === 5 &&
                                              !_vm.isDevelopment) ||
                                            (item.index === 9 &&
                                              !_vm.featureFileTranfer) ||
                                            (item.index === 14 &&
                                              !_vm.showWorkingTime)
                                              ? { display: "none" }
                                              : { display: "block" }
                                          ]
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            { staticClass: "px-3" },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { staticClass: "subtitle-2" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.name) +
                                                      "\n                        "
                                                  ),
                                                  _vm._v(" "),
                                                  item.index == 6 ||
                                                  item.index == 7 ||
                                                  item.index === 10 ||
                                                  item.index == 16
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            top: "",
                                                            "content-class":
                                                              "videoButtonTT"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "17px",
                                                                            position:
                                                                              "relative",
                                                                            top:
                                                                              "2px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "#2a3133",
                                                                            icon: [
                                                                              "fal",
                                                                              "info-circle"
                                                                            ]
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.index ==
                                                                    6
                                                                    ? _vm.$t(
                                                                        "components.adminSettingsModal.beaconInfoTT"
                                                                      )
                                                                    : item.index ==
                                                                      7
                                                                    ? _vm.$t(
                                                                        "components.adminSettingsModal.messageForAllTT"
                                                                      )
                                                                    : item.index ==
                                                                      10
                                                                    ? _vm.$t(
                                                                        "components.adminSettingsModal.editEmailsTextsTT"
                                                                      )
                                                                    : item.index ==
                                                                      16
                                                                    ? _vm.$t(
                                                                        "components.adminSettingsModal.searchGuestTT"
                                                                      )
                                                                    : ""
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("br")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          item.index == 0
                                            ? _c("v-expansion-panel-content", [
                                                _c(
                                                  "div",
                                                  [
                                                    [
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 my-0 pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-baseline"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "my-0 py-3 mr-2",
                                                                          attrs: {
                                                                            "hide-details":
                                                                              "",
                                                                            disabled:
                                                                              _vm.isBasicView,
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.saveFirstLoginDaily"
                                                                            )
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeShowUserLoginDaily()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.userLoginDaily,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.userLoginDaily = $$v
                                                                            },
                                                                            expression:
                                                                              "userLoginDaily"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "content-class":
                                                                              "videoButtonTT"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "font-awesome-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          style: {
                                                                                            fontSize:
                                                                                              "17px",
                                                                                            position:
                                                                                              "relative"
                                                                                          },
                                                                                          attrs: {
                                                                                            color:
                                                                                              "#2a3133",
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "info-circle"
                                                                                            ]
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.loginDailyTT"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 my-0 pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-3",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                        label: _vm.$t(
                                                                          "components.adminSettingsModal.statusDisplay"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeUserStatusDisplay()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.userStatusDisplay,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.userStatusDisplay = $$v
                                                                        },
                                                                        expression:
                                                                          "userStatusDisplay"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 my-0 pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-3",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                        label: _vm.$t(
                                                                          "components.adminSettingsModal.showNoStatus"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeShowNoStatus()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.showNoStatus,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.showNoStatus = $$v
                                                                        },
                                                                        expression:
                                                                          "showNoStatus"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 my-0 pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-3",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                        label: _vm.$t(
                                                                          "components.adminSettingsModal.coffeeBreakDisplay"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeShowCoffeeBreak()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.showCoffeeBreak,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.showCoffeeBreak = $$v
                                                                        },
                                                                        expression:
                                                                          "showCoffeeBreak"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 my-0 pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-baseline"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "my-0 py-3 mr-2",
                                                                          attrs: {
                                                                            "hide-details":
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "components.workingTime.workingTime"
                                                                            )
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeShowWorkingTime()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.showWorkingTime,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.showWorkingTime = $$v
                                                                            },
                                                                            expression:
                                                                              "showWorkingTime"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      [
                                                        _vm.amIAdmin
                                                          ? _c(
                                                              "v-list-item",
                                                              {
                                                                staticClass:
                                                                  "py-0 px-0 switchWrapper mt-4"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 my-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "subtitle-2 d-flex ml-0"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "span",
                                                                                        _vm._g(
                                                                                          {},
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                    " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "components.adminSettingsModal.timezone"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                  "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.ttTimezone"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "autocomplete_timezone"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-autocomplete",
                                                                          {
                                                                            ref:
                                                                              "section",
                                                                            refInFor: true,
                                                                            attrs: {
                                                                              attach:
                                                                                "#autocomplete_timezone",
                                                                              items:
                                                                                _vm.getTimeZones,
                                                                              "item-text":
                                                                                "name",
                                                                              "item-value":
                                                                                "tz",
                                                                              "auto-select-first": false,
                                                                              "hide-no-data":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.changeCompanyTimeZone()
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.timezone,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.timezone = $$v
                                                                              },
                                                                              expression:
                                                                                "timezone"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      _vm._v(" "),
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0 my-0 pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 d-flex ml-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mr-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.userVideoCall"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "content-class":
                                                                              "videoButtonTT"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "font-awesome-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          style: {
                                                                                            fontSize:
                                                                                              "17px",
                                                                                            position:
                                                                                              "relative"
                                                                                          },
                                                                                          attrs: {
                                                                                            color:
                                                                                              "#2a3133",
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "info-circle"
                                                                                            ]
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.loginDailyTT"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-3 mr-6",
                                                                      attrs: {
                                                                        disabled: _vm.isBasicView
                                                                          ? true
                                                                          : null,
                                                                        "hide-details":
                                                                          "",
                                                                        label: _vm.$t(
                                                                          "components.adminSettingsModal.forAllUser"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeDisplayUserVideoCall(
                                                                            "user"
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.videoCallAllUser,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.videoCallAllUser = $$v
                                                                        },
                                                                        expression:
                                                                          "videoCallAllUser"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "my-0 py-3",
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.featureOrganization ||
                                                                          _vm.isBasicView,
                                                                        "hide-details":
                                                                          "",
                                                                        label: _vm.$t(
                                                                          "components.adminSettingsModal.forManagement"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeDisplayUserVideoCall(
                                                                            "management"
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.videoCallManagement,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.videoCallManagement = $$v
                                                                        },
                                                                        expression:
                                                                          "videoCallManagement"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "py-0 my-0  pl-3 d-flex"
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "my-0 py-3 d-flex",
                                                                attrs: {
                                                                  "hide-details":
                                                                    "",
                                                                  label: _vm.$t(
                                                                    "components.adminSettingsModal.displayAllUsers"
                                                                  )
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeDisplayUserVideoCall(
                                                                      "allUsers"
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.displayAllUsers,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.displayAllUsers = $$v
                                                                  },
                                                                  expression:
                                                                    "displayAllUsers"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "py-0 my-0  pl-3 d-flex"
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-switch",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "my-0 py-3 d-flex",
                                                                                  attrs: {
                                                                                    "hide-details":
                                                                                      "",
                                                                                    label: _vm.$t(
                                                                                      "components.adminSettingsModal.featureFileTranfer"
                                                                                    )
                                                                                  },
                                                                                  on: {
                                                                                    change: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.changeFeatureFiletranfer()
                                                                                    }
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.featureFileTranfer,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.featureFileTranfer = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "featureFileTranfer"
                                                                                  }
                                                                                },
                                                                                on
                                                                              )
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.userDataTransfer"
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "d-block  pl-3 py-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticStyle: {
                                                                    height:
                                                                      "45px"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "py-3",
                                                                          attrs: {
                                                                            "hide-details":
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.useFeatureTimeline"
                                                                            ),
                                                                            width:
                                                                              "100%"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeUseFeatureTimeline()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.useFeatureTimeline,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.useFeatureTimeline = $$v
                                                                            },
                                                                            expression:
                                                                              "useFeatureTimeline"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          staticClass:
                                                                            "mx-5",
                                                                          attrs: {
                                                                            row: true,
                                                                            disabled: !_vm.useFeatureTimeline
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeTimelineSelection()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.timelineSelection,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.timelineSelection = $$v
                                                                            },
                                                                            expression:
                                                                              "timelineSelection"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              key:
                                                                                "1",
                                                                              staticClass:
                                                                                "ml-0 mx-6",
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.timelineObligatory"
                                                                                ),
                                                                                value:
                                                                                  "timelineObligatory"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              key:
                                                                                "2",
                                                                              staticClass:
                                                                                "mx-6",
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.timelineUserSelection"
                                                                                ),
                                                                                value:
                                                                                  "timelineUserSelection"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.amIAdmin
                                                        ? _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0 switchWrapper mt-4"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-0  pl-3 my-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 d-flex ml-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.adminSettingsModal.qualityLogging"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      attrs: {
                                                                        row: ""
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.changequalityLogging
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.qualityLogging,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.qualityLogging = $$v
                                                                        },
                                                                        expression:
                                                                          "qualityLogging"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            label:
                                                                              "" +
                                                                              _vm.$t(
                                                                                "components.adminSettingsModal.activate"
                                                                              ),
                                                                            value: true
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            label:
                                                                              "" +
                                                                              _vm.$t(
                                                                                "components.adminSettingsModal.desactivate"
                                                                              ),
                                                                            value: false
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-item",
                                                      { staticClass: "px-0" },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass: "pl-3"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 d-flex ml-0"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.adminSettingsModal.reviews"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mt-3",
                                                                staticStyle: {
                                                                  "max-width":
                                                                    "350px"
                                                                },
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.opencloseStats()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.adminSettingsModal.showRatingStats"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-item",
                                                      { staticClass: "px-0" },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass: " pl-3"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 d-flex ml-0 mt-4"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.adminSettingsModal.quality"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex"
                                                              },
                                                              [
                                                                _c("v-switch", {
                                                                  staticClass:
                                                                    "py-3",
                                                                  attrs: {
                                                                    "hide-details":
                                                                      "",
                                                                    label: _vm.$t(
                                                                      "components.adminSettingsModal.capture"
                                                                    ),
                                                                    width:
                                                                      "100%"
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.changeShowCountQualityStatistics
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.showCountQualityStatistics,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.showCountQualityStatistics = $$v
                                                                    },
                                                                    expression:
                                                                      "showCountQualityStatistics"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-item",
                                                      { staticClass: "px-0" },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass: " pl-3"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 d-flex ml-0"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.adminSettingsModal.mailSettings"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mt-3",
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "primary",
                                                                  "max-width":
                                                                    "350px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openMailFooterModal()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.adminSettingsModal.mailFooter"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  2
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 1
                                            ? _c("v-expansion-panel-content", [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _vm.amIAdmin
                                                      ? _c(
                                                          "v-list-item",
                                                          {
                                                            staticClass:
                                                              "py-0 px-0 switchWrapper"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "py-0 pl-3 my-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mt-1 cursorPointer",
                                                                                        attrs: {
                                                                                          depressed:
                                                                                            "",
                                                                                          color:
                                                                                            "primary"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openClose2faModal()
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "generics.info"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.twoFAMainTT"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-radio-group",
                                                                      {
                                                                        attrs: {
                                                                          row: true
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.change2FASetting(
                                                                              "2fa"
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.activate2FAType,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.activate2FAType = $$v
                                                                          },
                                                                          expression:
                                                                            "activate2FAType"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-radio",
                                                                                        _vm._g(
                                                                                          {
                                                                                            key:
                                                                                              "1",
                                                                                            staticClass:
                                                                                              "ml-0 mx-0",
                                                                                            attrs: {
                                                                                              value:
                                                                                                "allUsers"
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.twoFAAllUsersTT"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "span",
                                                                                        _vm._g(
                                                                                          {
                                                                                            staticClass:
                                                                                              "py-1 lighten-4"
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.adminSettingsModal.2faTypeAllUsers"
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.twoFAAllUsersTT"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-radio",
                                                                                        _vm._g(
                                                                                          {
                                                                                            key:
                                                                                              "3",
                                                                                            staticClass:
                                                                                              "ml-2 mr-0",
                                                                                            attrs: {
                                                                                              value:
                                                                                                "default"
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.twoFAUserSettingTT"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "span",
                                                                                        _vm._g(
                                                                                          {
                                                                                            staticClass:
                                                                                              "py-1 lighten-4"
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.adminSettingsModal.2faTypeDefault"
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.2faTypeDefaultTT"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("v-row", [
                                                                  _vm.activateSMS ||
                                                                  !(
                                                                    _vm.activatePIN ||
                                                                    _vm.activatePassword
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  "",
                                                                                "max-width":
                                                                                  "400"
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "v-switch",
                                                                                          _vm._g(
                                                                                            {
                                                                                              staticClass:
                                                                                                "my-0 py-2 pl-3",
                                                                                              attrs: {
                                                                                                disabled:
                                                                                                  _vm.activateSMS &&
                                                                                                  !_vm.activatePIN &&
                                                                                                  !_vm.activatePassword,
                                                                                                label: _vm.$t(
                                                                                                  "components.adminSettingsModal.2faActivateSMS"
                                                                                                )
                                                                                              },
                                                                                              on: {
                                                                                                change: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.change2FASetting(
                                                                                                    "sms"
                                                                                                  )
                                                                                                }
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.activateSMS,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.activateSMS = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "activateSMS"
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "components.adminSettingsModal.2faActivateSMSTT"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "max-width":
                                                                              "400"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-switch",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "my-0 py-2 pl-3",
                                                                                          attrs: {
                                                                                            disabled:
                                                                                              _vm.activatePassword &&
                                                                                              !_vm.activatePIN,
                                                                                            label: _vm.$t(
                                                                                              "components.adminSettingsModal.2faActivatePassword"
                                                                                            )
                                                                                          },
                                                                                          on: {
                                                                                            change: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.change2FASetting(
                                                                                                "password"
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm.activatePassword,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.activatePassword = $$v
                                                                                            },
                                                                                            expression:
                                                                                              "activatePassword"
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.adminSettingsModal.2faActivatePasswordTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "max-width":
                                                                              "400"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-switch",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "my-0 py-2 pl-3 inlineflex",
                                                                                          attrs: {
                                                                                            disabled:
                                                                                              _vm.activatePIN &&
                                                                                              !_vm.activatePassword,
                                                                                            label: _vm.$t(
                                                                                              "components.adminSettingsModal.2faActivatePin"
                                                                                            )
                                                                                          },
                                                                                          on: {
                                                                                            change: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.change2FASetting(
                                                                                                "pin"
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm.activatePIN,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.activatePIN = $$v
                                                                                            },
                                                                                            expression:
                                                                                              "activatePIN"
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.adminSettingsModal.2faActivatePinTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm.activatePIN
                                                                        ? _c(
                                                                            "v-radio-group",
                                                                            {
                                                                              staticClass:
                                                                                "mt-1 radioGroup inlineflex",
                                                                              attrs: {
                                                                                row:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.change2FASetting(
                                                                                    "pinProtectionType"
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.pinProtectionType,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.pinProtectionType = $$v
                                                                                },
                                                                                expression:
                                                                                  "pinProtectionType"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-left subtitle-1",
                                                                    attrs: {
                                                                      align:
                                                                        "left"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.adminSettingsModal.twoFaLifetimeHours"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "autocomplete_2faHours"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-overflow-btn",
                                                                      {
                                                                        staticClass:
                                                                          "my-2",
                                                                        attrs: {
                                                                          attach:
                                                                            "#autocomplete_2faHours",
                                                                          items:
                                                                            _vm.twoFaLifetimeHours
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            _vm.setTwoFaLifetimeHours
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedTwoFaLifetimeHours,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.selectedTwoFaLifetimeHours = $$v
                                                                          },
                                                                          expression:
                                                                            "selectedTwoFaLifetimeHours"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-switch",
                                                                      {
                                                                        staticClass:
                                                                          "my-0 py-2 pl-3",
                                                                        attrs: {
                                                                          label: _vm.$t(
                                                                            "components.adminSettingsModal.2faOwnTime"
                                                                          )
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.change2FASetting(
                                                                              "time"
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.userSetTime,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.userSetTime = $$v
                                                                          },
                                                                          expression:
                                                                            "userSetTime"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "containerRecovery"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "absoluteDropdown"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "TwoFactorAdminRecoveryEntry"
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 2
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _vm.amIAdmin
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          staticClass:
                                                            "py-0 px-0 switchWrapper"
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "py-0 my-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "my-0 pl-3 py-2",
                                                                          attrs: {
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.featureOrganization"
                                                                            ),
                                                                            "hide-details":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changefeatureOrganization()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.featureOrganization,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.featureOrganization = $$v
                                                                            },
                                                                            expression:
                                                                              "featureOrganization"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-1",
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          attrs: {
                                                                            id:
                                                                              "combobox_organigram"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-combobox",
                                                                            {
                                                                              staticClass:
                                                                                "mx-1",
                                                                              attrs: {
                                                                                attach:
                                                                                  "#combobox_organigram",
                                                                                disabled: !_vm.featureOrganization,
                                                                                items:
                                                                                  _vm.dataComboboxShowOrganigrammFor,
                                                                                select:
                                                                                  "",
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.showOrganigrammFor"
                                                                                ),
                                                                                multiple:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "item-value":
                                                                                  "value",
                                                                                "item-text":
                                                                                  "text",
                                                                                "return-object": true,
                                                                                counter:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.updateShowOrganigrammFor()
                                                                                },
                                                                                blur: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.blurShowOrganigrammFor()
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.showOrganigrammFor,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.showOrganigrammFor = $$v
                                                                                },
                                                                                expression:
                                                                                  "showOrganigrammFor"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.featureOrganization
                                                                    ? _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "py-1 d-flex align-center",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-switch",
                                                                                {
                                                                                  staticClass:
                                                                                    "my-0 pl-6 py-2",
                                                                                  attrs: {
                                                                                    label: _vm.$t(
                                                                                      "components.adminSettingsModal.showHeadline"
                                                                                    ),
                                                                                    "hide-details":
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    change: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.changeShowHeadlineOrganization()
                                                                                    }
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.showHeadlineOrganization,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.showHeadlineOrganization = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "showHeadlineOrganization"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    top:
                                                                                      "",
                                                                                    "content-class":
                                                                                      "videoButtonTT"
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          return [
                                                                                            _c(
                                                                                              "font-awesome-icon",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ml-4",
                                                                                                  style: {
                                                                                                    fontSize:
                                                                                                      "17px"
                                                                                                  },
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "#2a3133",
                                                                                                    icon: [
                                                                                                      "fal",
                                                                                                      "info-circle"
                                                                                                    ]
                                                                                                  }
                                                                                                },
                                                                                                on
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.headlineTT"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showHeadlineOrganization
                                                                            ? _c(
                                                                                "v-row",
                                                                                [
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      staticClass:
                                                                                        "py-1 px-7",
                                                                                      attrs: {
                                                                                        cols:
                                                                                          "12"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex px-3 align-center"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                outlined:
                                                                                                  "",
                                                                                                dense:
                                                                                                  "",
                                                                                                label:
                                                                                                  "\n                                   " +
                                                                                                  _vm.$t(
                                                                                                    "components.adminSettingsModal.headline"
                                                                                                  ) +
                                                                                                  " DE"
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.headlineOrganigramDe,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.headlineOrganigramDe = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "headlineOrganigramDe"
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "ml-2",
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                outlined:
                                                                                                  "",
                                                                                                dense:
                                                                                                  "",
                                                                                                label:
                                                                                                  "\n                                   " +
                                                                                                  _vm.$t(
                                                                                                    "components.adminSettingsModal.headline"
                                                                                                  ) +
                                                                                                  " EN"
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.headlineOrganigramEn,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.headlineOrganigramEn = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "headlineOrganigramEn"
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              staticClass:
                                                                                                "ml-2",
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                outlined:
                                                                                                  "",
                                                                                                dense:
                                                                                                  "",
                                                                                                label:
                                                                                                  "\n                                   " +
                                                                                                  _vm.$t(
                                                                                                    "components.adminSettingsModal.headline"
                                                                                                  ) +
                                                                                                  " ES"
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.headlineOrganigramEs,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.headlineOrganigramEs = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "headlineOrganigramEs"
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              staticClass:
                                                                                                "ml-2",
                                                                                              attrs: {
                                                                                                disabled:
                                                                                                  !_vm
                                                                                                    .headlineOrganigramDe
                                                                                                    .length &&
                                                                                                  !_vm
                                                                                                    .headlineOrganigramEs
                                                                                                    .length &&
                                                                                                  !_vm
                                                                                                    .headlineOrganigramEn
                                                                                                    .length,
                                                                                                "max-width":
                                                                                                  "150px",
                                                                                                depressed:
                                                                                                  "",
                                                                                                color:
                                                                                                  "primary"
                                                                                              },
                                                                                              on: {
                                                                                                click:
                                                                                                  _vm.addHeadlineOrganigram
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "generics.add"
                                                                                                  )
                                                                                                ) +
                                                                                                  "\n                                  "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      staticClass:
                                                                                        "py-1 px-7 headlineOrganigramList",
                                                                                      attrs: {
                                                                                        cols:
                                                                                          "12"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "px-3 py-1 d-flex justify-space-between align-center"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                " text-left",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "232px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .headlineOrganigram[0]
                                                                                                    .de
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                " text-left",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "232px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .headlineOrganigram[0]
                                                                                                    .en
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                " text-left",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "232px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .headlineOrganigram[0]
                                                                                                    .es
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-right  d-flex justify-end",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "131px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ml-2",
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "primary",
                                                                                                    icon:
                                                                                                      ""
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.editHeadlineOrganigram(
                                                                                                        _vm
                                                                                                          .headlineOrganigram[0]
                                                                                                          .id
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "font-awesome-icon",
                                                                                                    {
                                                                                                      style: {
                                                                                                        fontSize:
                                                                                                          "16px"
                                                                                                      },
                                                                                                      attrs: {
                                                                                                        icon: [
                                                                                                          "fal",
                                                                                                          "pen"
                                                                                                        ]
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "36px",
                                                                                                    height:
                                                                                                      "36px"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "draggable",
                                                                                        {
                                                                                          on: {
                                                                                            change:
                                                                                              _vm.headlineOrderChanged
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm.headlineOrganigram,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.headlineOrganigram = $$v
                                                                                            },
                                                                                            expression:
                                                                                              "headlineOrganigram"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "transition-group",
                                                                                            {
                                                                                              attrs: {
                                                                                                name:
                                                                                                  "card"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._l(
                                                                                                _vm.headlineOrganigram,
                                                                                                function(
                                                                                                  headline,
                                                                                                  index
                                                                                                ) {
                                                                                                  return [
                                                                                                    headline.id !==
                                                                                                      0 &&
                                                                                                    headline.id !==
                                                                                                      9999
                                                                                                      ? _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            key: index,
                                                                                                            staticClass:
                                                                                                              "px-3  py-1 d-flex justify-space-between align-center"
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  " text-left",
                                                                                                                staticStyle: {
                                                                                                                  "min-width":
                                                                                                                    "232px"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    _vm
                                                                                                                      .headlineOrganigram[
                                                                                                                      index
                                                                                                                    ]
                                                                                                                      .de
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " "
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  " text-left",
                                                                                                                staticStyle: {
                                                                                                                  "min-width":
                                                                                                                    "232px"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    _vm
                                                                                                                      .headlineOrganigram[
                                                                                                                      index
                                                                                                                    ]
                                                                                                                      .en
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " "
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  " text-left",
                                                                                                                staticStyle: {
                                                                                                                  "min-width":
                                                                                                                    "232px"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    _vm
                                                                                                                      .headlineOrganigram[
                                                                                                                      index
                                                                                                                    ]
                                                                                                                      .es
                                                                                                                  )
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " "
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  " text-right",
                                                                                                                staticStyle: {
                                                                                                                  "min-width":
                                                                                                                    "131px"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "DeleteBasket",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      indexData:
                                                                                                                        headline.id,
                                                                                                                      delFunction:
                                                                                                                        _vm.removeHeadlineOrganigram,
                                                                                                                      fontSize:
                                                                                                                        "16"
                                                                                                                    }
                                                                                                                  }
                                                                                                                ),
                                                                                                                _vm._v(
                                                                                                                  " "
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "mb-1",
                                                                                                                    attrs: {
                                                                                                                      color:
                                                                                                                        "primary",
                                                                                                                      icon:
                                                                                                                        ""
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.editHeadlineOrganigram(
                                                                                                                          headline.id
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "font-awesome-icon",
                                                                                                                      {
                                                                                                                        style: {
                                                                                                                          fontSize:
                                                                                                                            "16px"
                                                                                                                        },
                                                                                                                        attrs: {
                                                                                                                          icon: [
                                                                                                                            "fal",
                                                                                                                            "pen"
                                                                                                                          ]
                                                                                                                        }
                                                                                                                      }
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _vm._v(
                                                                                                                  " "
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "mb-1",
                                                                                                                    attrs: {
                                                                                                                      color:
                                                                                                                        "primary",
                                                                                                                      icon:
                                                                                                                        ""
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "font-awesome-icon",
                                                                                                                      {
                                                                                                                        style: {
                                                                                                                          fontSize:
                                                                                                                            "16px"
                                                                                                                        },
                                                                                                                        attrs: {
                                                                                                                          icon: [
                                                                                                                            "fal",
                                                                                                                            "bars"
                                                                                                                          ]
                                                                                                                        }
                                                                                                                      }
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      : _vm._e()
                                                                                                  ]
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "px-3  py-1 d-flex justify-space-between align-center"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                " text-left",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "232px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .headlineOrganigram[
                                                                                                    _vm
                                                                                                      .headlineOrganigram
                                                                                                      .length -
                                                                                                      1
                                                                                                  ]
                                                                                                    .de
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                " text-left",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "232px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .headlineOrganigram[
                                                                                                    _vm
                                                                                                      .headlineOrganigram
                                                                                                      .length -
                                                                                                      1
                                                                                                  ]
                                                                                                    .en
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                " text-left",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "232px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .headlineOrganigram[
                                                                                                    _vm
                                                                                                      .headlineOrganigram
                                                                                                      .length -
                                                                                                      1
                                                                                                  ]
                                                                                                    .es
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-right   d-flex justify-end",
                                                                                              staticStyle: {
                                                                                                "min-width":
                                                                                                  "131px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ml-2",
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      "primary",
                                                                                                    icon:
                                                                                                      ""
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      _vm.editHeadlineOrganigram(
                                                                                                        _vm
                                                                                                          .headlineOrganigram[
                                                                                                          _vm
                                                                                                            .headlineOrganigram
                                                                                                            .length -
                                                                                                            1
                                                                                                        ]
                                                                                                          .id
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "font-awesome-icon",
                                                                                                    {
                                                                                                      style: {
                                                                                                        fontSize:
                                                                                                          "16px"
                                                                                                      },
                                                                                                      attrs: {
                                                                                                        icon: [
                                                                                                          "fal",
                                                                                                          "pen"
                                                                                                        ]
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "36px",
                                                                                                    height:
                                                                                                      "36px"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 3
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "py-0" },
                                                        [
                                                          _c(
                                                            "v-form",
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm.waitingRoomFormValid,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.waitingRoomFormValid = $$v
                                                                },
                                                                expression:
                                                                  "waitingRoomFormValid"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {},
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _vm
                                                                        .getCustomInviteTexts
                                                                        .length >
                                                                      0
                                                                        ? [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "pb-0"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-tooltip",
                                                                                  {
                                                                                    attrs: {
                                                                                      top:
                                                                                        ""
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "activator",
                                                                                          fn: function(
                                                                                            ref
                                                                                          ) {
                                                                                            var on =
                                                                                              ref.on
                                                                                            return [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    id:
                                                                                                      "autocomplete_templates"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-autocomplete",
                                                                                                    _vm._g(
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          attach:
                                                                                                            "#autocomplete_templates",
                                                                                                          "search-input":
                                                                                                            _vm.templateSearch,
                                                                                                          items:
                                                                                                            _vm.getCustomInviteTexts,
                                                                                                          outlined:
                                                                                                            "",
                                                                                                          "item-text":
                                                                                                            "title",
                                                                                                          "item-value":
                                                                                                            "title",
                                                                                                          "hide-details":
                                                                                                            "auto",
                                                                                                          label: _vm.$t(
                                                                                                            "generics.templates"
                                                                                                          ),
                                                                                                          dense:
                                                                                                            "",
                                                                                                          "menu-props": {
                                                                                                            closeOnContentClick: true
                                                                                                          }
                                                                                                        },
                                                                                                        on: {
                                                                                                          "update:searchInput": function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.templateSearch = $event
                                                                                                          },
                                                                                                          "update:search-input": function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.templateSearch = $event
                                                                                                          }
                                                                                                        },
                                                                                                        scopedSlots: _vm._u(
                                                                                                          [
                                                                                                            {
                                                                                                              key:
                                                                                                                "item",
                                                                                                              fn: function(
                                                                                                                data
                                                                                                              ) {
                                                                                                                return [
                                                                                                                  _c(
                                                                                                                    "v-list-item",
                                                                                                                    {
                                                                                                                      staticStyle: {
                                                                                                                        position:
                                                                                                                          "relative",
                                                                                                                        "min-height":
                                                                                                                          "25px"
                                                                                                                      },
                                                                                                                      on: {
                                                                                                                        click: function(
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.saveCustomTemplateWaitingRoomSelected(
                                                                                                                            data
                                                                                                                          )
                                                                                                                        }
                                                                                                                      }
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-list-item-content",
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "div",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "listTitle mb-1"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "\n                                                " +
                                                                                                                                  _vm._s(
                                                                                                                                    data
                                                                                                                                      .item
                                                                                                                                      .title
                                                                                                                                  ) +
                                                                                                                                  "\n                                              "
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                ]
                                                                                                              }
                                                                                                            }
                                                                                                          ],
                                                                                                          null,
                                                                                                          true
                                                                                                        ),
                                                                                                        model: {
                                                                                                          value:
                                                                                                            _vm.customTemplateWaitingRoomSelected,
                                                                                                          callback: function(
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.customTemplateWaitingRoomSelected = $$v
                                                                                                          },
                                                                                                          expression:
                                                                                                            "customTemplateWaitingRoomSelected"
                                                                                                        }
                                                                                                      },
                                                                                                      on
                                                                                                    )
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    )
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.waitingroomTemplate"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "pb-0"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      depressed:
                                                                                        "",
                                                                                      disabled: !_vm
                                                                                        .state
                                                                                        .namespaceSettings
                                                                                        .customTemplateWaitingRoomSelected,
                                                                                      color:
                                                                                        "primary"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.resetCustomTemplateWaitingRoomSelected()
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                  " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "components.adminSettingsModal.resetTemplateWaitingRoom"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "pb-0",
                                                                          attrs: {
                                                                            cols:
                                                                              "12"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          _vm._g(
                                                                                            {
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                outlined:
                                                                                                  "",
                                                                                                dense:
                                                                                                  "",
                                                                                                label: _vm.$t(
                                                                                                  "components.adminSettingsModal.waitingRoomAlias"
                                                                                                )
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.waitingRoomAlias,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.waitingRoomAlias = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "waitingRoomAlias"
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "components.adminSettingsModal.displayDescriptionTT"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _vm.aliasTakenError
                                                                    ? _c(
                                                                        "v-alert",
                                                                        {
                                                                          attrs: {
                                                                            dense:
                                                                              "",
                                                                            type:
                                                                              "error"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.adminSettingsModal.aliasTaken"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.aliasSuccess
                                                                    ? _c(
                                                                        "v-alert",
                                                                        {
                                                                          attrs: {
                                                                            dense:
                                                                              "",
                                                                            type:
                                                                              "success"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.aliasSuccess
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols:
                                                              "12 d-flex align-center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label:
                                                                _vm.$t(
                                                                  "components.waitingRoom.welcomeMsg"
                                                                ) +
                                                                " " +
                                                                _vm.getLanguage
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.waitingRoomWelcomeActualLanguageModel,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.waitingRoomWelcomeActualLanguageModel = $$v
                                                              },
                                                              expression:
                                                                "waitingRoomWelcomeActualLanguageModel"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "ml-1",
                                                                              attrs: {
                                                                                icon:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.showModelWaitingRoomWelcome = !_vm.showModelWaitingRoomWelcome
                                                                                }
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            !_vm.showModelWaitingRoomWelcome
                                                                              ? _c(
                                                                                  "font-awesome-icon",
                                                                                  {
                                                                                    style: {
                                                                                      fontSize:
                                                                                        "16px"
                                                                                    },
                                                                                    attrs: {
                                                                                      icon: [
                                                                                        "fal",
                                                                                        "chevron-down"
                                                                                      ],
                                                                                      color:
                                                                                        "primary"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "font-awesome-icon",
                                                                                  {
                                                                                    style: {
                                                                                      fontSize:
                                                                                        "16px"
                                                                                    },
                                                                                    attrs: {
                                                                                      icon: [
                                                                                        "fal",
                                                                                        "chevron-up"
                                                                                      ],
                                                                                      color:
                                                                                        "primary"
                                                                                    }
                                                                                  }
                                                                                )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    !_vm.showModelWaitingRoomWelcome
                                                                      ? _vm.$t(
                                                                          "components.adminSettingsModal.moreLanguages"
                                                                        )
                                                                      : _vm.$t(
                                                                          "components.adminSettingsModal.hideMoreLangauges"
                                                                        )
                                                                  ) +
                                                                    "\n                            "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.waitingRoomWelcomeOtherLanguageModel,
                                                        function(
                                                          modelsData,
                                                          index
                                                        ) {
                                                          return _vm.showModelWaitingRoomWelcome
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  key:
                                                                    "modelData-" +
                                                                    modelsData.lang +
                                                                    "-" +
                                                                    index,
                                                                  staticClass:
                                                                    "pr52",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        value:
                                                                          modelsData.value,
                                                                        label:
                                                                          _vm.$t(
                                                                            "components.waitingRoom.welcomeMsg"
                                                                          ) +
                                                                          " " +
                                                                          modelsData.lang
                                                                      },
                                                                      on: {
                                                                        input: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.waitingRoomWelcomeOtherLanguageModel = {
                                                                            lang:
                                                                              modelsData.lang,
                                                                            value: $event
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "containerResetBgWaitingroom",
                                                              on: {
                                                                mouseover: function(
                                                                  $event
                                                                ) {
                                                                  _vm.showResetBgWaitingRoom = true
                                                                },
                                                                mouseleave: function(
                                                                  $event
                                                                ) {
                                                                  _vm.showResetBgWaitingRoom = false
                                                                }
                                                              }
                                                            },
                                                            [
                                                              !_vm.isPdfBg
                                                                ? _c("v-img", {
                                                                    staticClass:
                                                                      "grey darken-4",
                                                                    attrs: {
                                                                      src:
                                                                        _vm.actualWaitingRoomBg,
                                                                      "max-height":
                                                                        "150",
                                                                      contain:
                                                                        "",
                                                                      "lazy-src":
                                                                        "img/default_profile_picture.png"
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.setCustomBackground
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.showResetBgWaitingRoom &&
                                                              _vm.hasCustomBg
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "btnResetBgWaitingroom",
                                                                      attrs: {
                                                                        depressed:
                                                                          "",
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "#2a3133"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.resetBgWaitingRoom()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "16px"
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "times"
                                                                            ],
                                                                            color:
                                                                              "white"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2 d-flex"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.waitingRoom.currentBackground"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "d-flex",
                                                          staticStyle: {
                                                            "flex-direction":
                                                              "column"
                                                          },
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                attrs: {
                                                                                  id:
                                                                                    "combobox_templates"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-combobox",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs: {
                                                                                        attach:
                                                                                          "#combobox_templates",
                                                                                        items:
                                                                                          _vm.getWaitingRoomBgs,
                                                                                        label: _vm.$t(
                                                                                          "components.adminSettingsModal.templates"
                                                                                        ),
                                                                                        outlined:
                                                                                          "",
                                                                                        dense:
                                                                                          "",
                                                                                        "return-object":
                                                                                          "",
                                                                                        "item-text":
                                                                                          "imageName",
                                                                                        "item-value":
                                                                                          "imagePath",
                                                                                        "hide-details":
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.updateTemplateBg
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "item",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var item =
                                                                                                ref.item
                                                                                              return [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "my-1",
                                                                                                    staticStyle: {
                                                                                                      "max-width":
                                                                                                        "145px"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      src:
                                                                                                        item.imagePath,
                                                                                                      "lazy-src":
                                                                                                        "img/default_profile_picture.png"
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ml-2"
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        item.imageName
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                      model: {
                                                                                        value:
                                                                                          _vm.selectedTemplate,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.selectedTemplate = $$v
                                                                                        },
                                                                                        expression:
                                                                                          "selectedTemplate"
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.templatesTT"
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-0 w-100",
                                                                                  attrs: {
                                                                                    depressed:
                                                                                      "",
                                                                                    color:
                                                                                      "primary"
                                                                                  },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.setCustomBackground
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.waitingRoom.uploadBG"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.waitingRoom.customBgTT"
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label: "Facebook",
                                                              "prepend-inner-icon":
                                                                "mdi-facebook"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.facebookLink,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.facebookLink = $$v
                                                              },
                                                              expression:
                                                                "facebookLink"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label:
                                                                "Instagram",
                                                              "prepend-inner-icon":
                                                                "mdi-instagram"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.instagramLink,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.instagramLink = $$v
                                                              },
                                                              expression:
                                                                "instagramLink"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label: "Twitter",
                                                              "prepend-inner-icon":
                                                                "mdi-twitter"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.twitterLink,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.twitterLink = $$v
                                                              },
                                                              expression:
                                                                "twitterLink"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label: "YouTube",
                                                              "prepend-inner-icon":
                                                                "mdi-youtube"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.youtubeLink,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.youtubeLink = $$v
                                                              },
                                                              expression:
                                                                "youtubeLink"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label: "LinkedIn",
                                                              "prepend-inner-icon":
                                                                "mdi-linkedin"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.linkedinLink,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.linkedinLink = $$v
                                                              },
                                                              expression:
                                                                "linkedinLink"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "hide-details":
                                                                "",
                                                              outlined: "",
                                                              dense: "",
                                                              label: "Web",
                                                              "prepend-inner-icon":
                                                                "mdi-link"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.webLink,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.webLink = $$v
                                                              },
                                                              expression:
                                                                "webLink"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right"
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "ml-0",
                                                              attrs: {
                                                                depressed: "",
                                                                disabled: !_vm.waitingRoomFormValid,
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.saveWaitingRoomSettings
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.calendarContainer.save"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "ml-0",
                                                              attrs: {
                                                                depressed: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.cancelWaitingRoomSettings
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "generics.cancel"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 4
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mx-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "5" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-left subtitle-2 d-flex mr-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "py-0 my-0 pr-2",
                                                                      attrs: {
                                                                        "hide-details":
                                                                          "",
                                                                        width:
                                                                          "100%"
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changePaySetting()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.paySetting,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.paySetting = $$v
                                                                        },
                                                                        expression:
                                                                          "paySetting"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.paySetting"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                              attrs: {
                                                                cols: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "select_currency"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        attach:
                                                                          "#select_currency",
                                                                        items:
                                                                          _vm.currencies,
                                                                        label: _vm.$t(
                                                                          "components.adminSettingsModal.currency"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.setCurrency
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.selectCurrency,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.selectCurrency = $$v
                                                                        },
                                                                        expression:
                                                                          "selectCurrency"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "7" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "btnvOfficePay mx-1",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    color: _vm.paypalSettingsFilled
                                                                      ? "primary"
                                                                      : "orange"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.openClosePayPalSettings
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.paypalSettings"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.openInfoPaypalSettings
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "generics.info"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "btnvOfficePay mx-1",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.openCloseModalProducts
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.paypalProducts"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    color:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.openInfoPaypalProducts
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "generics.info"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-row", {
                                                    staticClass:
                                                      "w-100 mx-0 my-0"
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 6
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mx-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "8" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 d-flex ml-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.adminSettingsModal.beaconDomainWhitelist"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-left my-3"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            depressed:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openBeaconDomainWhitelist()
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconDomainWhitelist"
                                                                                )
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 d-flex ml-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.adminSettingsModal.beaconTitle"
                                                                            )
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm.getLanguage
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-baseline"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2",
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            maxLength:
                                                                              "25",
                                                                            counter:
                                                                              "",
                                                                            "hide-details":
                                                                              "auto"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.setBeaconTitle()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.beaconTitleActualLanguageModel,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.beaconTitleActualLanguageModel = $$v
                                                                            },
                                                                            expression:
                                                                              "beaconTitleActualLanguageModel"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-1",
                                                                                          attrs: {
                                                                                            icon:
                                                                                              ""
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.showModelBeaconTitle = !_vm.showModelBeaconTitle
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        !_vm.showModelBeaconTitle
                                                                                          ? _c(
                                                                                              "font-awesome-icon",
                                                                                              {
                                                                                                style: {
                                                                                                  fontSize:
                                                                                                    "16px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  icon: [
                                                                                                    "fal",
                                                                                                    "chevron-down"
                                                                                                  ],
                                                                                                  color:
                                                                                                    "primary"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : _c(
                                                                                              "font-awesome-icon",
                                                                                              {
                                                                                                style: {
                                                                                                  fontSize:
                                                                                                    "16px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  icon: [
                                                                                                    "fal",
                                                                                                    "chevron-up"
                                                                                                  ],
                                                                                                  color:
                                                                                                    "primary"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  !_vm.showModelBeaconTitle
                                                                                    ? _vm.$t(
                                                                                        "components.adminSettingsModal.moreLanguages"
                                                                                      )
                                                                                    : _vm.$t(
                                                                                        "components.adminSettingsModal.hideMoreLangauges"
                                                                                      )
                                                                                ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.beaconTitleOtherLanguageModel,
                                                                    function(
                                                                      modelsData,
                                                                      index
                                                                    ) {
                                                                      return _vm.showModelBeaconTitle
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              key:
                                                                                "modelData-" +
                                                                                modelsData.lang +
                                                                                "-" +
                                                                                index,
                                                                              staticClass:
                                                                                "pr40",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-subtitle",
                                                                                {
                                                                                  staticClass:
                                                                                    "subtitle-2 d-flex ml-0"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                            " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "components.adminSettingsModal.beaconTitle"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        modelsData.lang
                                                                                      ) +
                                                                                      "\n                          "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-2",
                                                                                  attrs: {
                                                                                    outlined:
                                                                                      "",
                                                                                    dense:
                                                                                      "",
                                                                                    value:
                                                                                      modelsData.value,
                                                                                    maxLength:
                                                                                      "25",
                                                                                    counter:
                                                                                      "",
                                                                                    "hide-details":
                                                                                      "auto"
                                                                                  },
                                                                                  on: {
                                                                                    input: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.beaconTitleOtherLanguageModel = {
                                                                                        lang:
                                                                                          modelsData.lang,
                                                                                        value: $event
                                                                                      }
                                                                                    },
                                                                                    change: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.setBeaconTitle()
                                                                                    }
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: "",
                                                                        "max-width":
                                                                          "450"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2 d-flex ml-0"
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "components.adminSettingsModal.beaconNoUserTitle"
                                                                                          )
                                                                                        ) +
                                                                                        "  " +
                                                                                        _vm._s(
                                                                                          _vm.getLanguage
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconNoUserTT"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-baseline"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-textarea",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            maxLength:
                                                                              "500",
                                                                            counter:
                                                                              "",
                                                                            "hide-details":
                                                                              "auto",
                                                                            rows:
                                                                              "3",
                                                                            "no-resize":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.setNoUserMsg()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.noUserMessageActualLanguageModel,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.noUserMessageActualLanguageModel = $$v
                                                                            },
                                                                            expression:
                                                                              "noUserMessageActualLanguageModel"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-1",
                                                                                          attrs: {
                                                                                            icon:
                                                                                              ""
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.showModelnoUserMessage = !_vm.showModelnoUserMessage
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        !_vm.showModelnoUserMessage
                                                                                          ? _c(
                                                                                              "font-awesome-icon",
                                                                                              {
                                                                                                style: {
                                                                                                  fontSize:
                                                                                                    "16px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  icon: [
                                                                                                    "fal",
                                                                                                    "chevron-down"
                                                                                                  ],
                                                                                                  color:
                                                                                                    "primary"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : _c(
                                                                                              "font-awesome-icon",
                                                                                              {
                                                                                                style: {
                                                                                                  fontSize:
                                                                                                    "16px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  icon: [
                                                                                                    "fal",
                                                                                                    "chevron-up"
                                                                                                  ],
                                                                                                  color:
                                                                                                    "primary"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  !_vm.showModelnoUserMessage
                                                                                    ? _vm.$t(
                                                                                        "components.adminSettingsModal.moreLanguages"
                                                                                      )
                                                                                    : _vm.$t(
                                                                                        "components.adminSettingsModal.hideMoreLangauges"
                                                                                      )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.noUserMessageOtherLanguageModel,
                                                                    function(
                                                                      modelsData,
                                                                      index
                                                                    ) {
                                                                      return _vm.showModelnoUserMessage
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              key:
                                                                                "modelData-" +
                                                                                modelsData.lang +
                                                                                "-" +
                                                                                index,
                                                                              staticClass:
                                                                                "pr40",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    top:
                                                                                      "",
                                                                                    "max-width":
                                                                                      "450"
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          return [
                                                                                            _c(
                                                                                              "v-list-item-subtitle",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "subtitle-2 d-flex ml-0"
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "components.adminSettingsModal.beaconNoUserTitle"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " " +
                                                                                                    _vm._s(
                                                                                                      modelsData.lang
                                                                                                    ) +
                                                                                                    "\n                              "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.beaconNoUserTT"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-textarea",
                                                                                {
                                                                                  attrs: {
                                                                                    outlined:
                                                                                      "",
                                                                                    dense:
                                                                                      "",
                                                                                    value:
                                                                                      modelsData.value,
                                                                                    maxLength:
                                                                                      "500",
                                                                                    counter:
                                                                                      "",
                                                                                    "hide-details":
                                                                                      "auto",
                                                                                    rows:
                                                                                      "3",
                                                                                    "no-resize":
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    input: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.noUserMessageOtherLanguageModel = {
                                                                                        lang:
                                                                                          modelsData.lang,
                                                                                        value: $event
                                                                                      }
                                                                                    },
                                                                                    change: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.setNoUserMsg()
                                                                                    }
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "px-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2 pl-2 py-2 d-flex ml-0"
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-checkbox",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mt-0",
                                                                                        attrs: {
                                                                                          "hide-details":
                                                                                            "",
                                                                                          label: _vm.$t(
                                                                                            "components.adminSettingsModal.showTeamsInBeacon"
                                                                                          )
                                                                                        },
                                                                                        on: {
                                                                                          change: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.setShowTeamsInBeacon()
                                                                                          }
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm.showTeamsInBeacon,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.showTeamsInBeacon = $$v
                                                                                          },
                                                                                          expression:
                                                                                            "showTeamsInBeacon"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.adminSettingsModal.showTeamsInBeaconTT"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "combobox_beaconTeams"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-combobox",
                                                                        {
                                                                          staticClass:
                                                                            "mt-3",
                                                                          attrs: {
                                                                            attach:
                                                                              "#combobox_beaconTeams",
                                                                            disabled: !_vm.showTeamsInBeacon,
                                                                            items:
                                                                              _vm.nameSpaceTeams,
                                                                            select:
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "components.organisationCarousel.teams"
                                                                            ),
                                                                            multiple:
                                                                              "",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            "item-value":
                                                                              "team",
                                                                            "item-text":
                                                                              "team",
                                                                            "return-object": true
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.setTeamsShownInBeacon()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.teamsShownInBeacon,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.teamsShownInBeacon = $$v
                                                                            },
                                                                            expression:
                                                                              "teamsShownInBeacon"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "mt-6 px-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: "",
                                                                        "max-width":
                                                                          "200px"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2 d-flex ml-0"
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "components.adminSettingsModal.beaconUsersInCall"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconUsersInCallTT"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      attrs: {
                                                                        row: ""
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeBeaconUsersInCall()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.beaconUsersIcall,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.beaconUsersIcall = $$v
                                                                        },
                                                                        expression:
                                                                          "beaconUsersIcall"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.green"
                                                                            ),
                                                                            value:
                                                                              "green"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.yellow"
                                                                            ),
                                                                            value:
                                                                              "yellow"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "px-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: "",
                                                                        "max-width":
                                                                          "200px"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2 d-flex ml-0"
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "components.adminSettingsModal.beaconInactiveUsers"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconInactiveUsersTT"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      attrs: {
                                                                        row: ""
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeBeaconInactiveUsers()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.beaconInactiveUsers,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.beaconInactiveUsers = $$v
                                                                        },
                                                                        expression:
                                                                          "beaconInactiveUsers"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.gray"
                                                                            ),
                                                                            value:
                                                                              "gray"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.hidden"
                                                                            ),
                                                                            value:
                                                                              "hidden"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "px-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "pl-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: "",
                                                                        "max-width":
                                                                          "200px"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "subtitle-2 d-flex ml-0"
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "generics.language"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconUsersInCallTT"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-radio-group",
                                                                    {
                                                                      attrs: {
                                                                        row: ""
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeBeaconLanguage()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.beaconLanguage,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.beaconLanguage = $$v
                                                                        },
                                                                        expression:
                                                                          "beaconLanguage"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label: _vm.$t(
                                                                              "components.adminSettingsModal.browserLanguage"
                                                                            ),
                                                                            value:
                                                                              "default"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "\n                                DE\n                              ",
                                                                            value:
                                                                              "de"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "\n                                EN\n                              ",
                                                                            value:
                                                                              "en"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "\n                               ES\n                              ",
                                                                            value:
                                                                              "es"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-0 px-0",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("div", {
                                                                attrs: {
                                                                  id:
                                                                    "containerCopy"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "pt-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              "",
                                                                            "max-width":
                                                                              "200px"
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 d-flex ml-0"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.adminSettingsModal.beaconCode"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.adminSettingsModal.beaconCodeTT"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 d-flex ml-0 mb-0 mt-3"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconCopyHead"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "containerCode"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "font-awesome-icon",
                                                                            {
                                                                              staticClass:
                                                                                "iconCopy",
                                                                              style: {
                                                                                fontSize:
                                                                                  "20px",
                                                                                color:
                                                                                  _vm.colorIconHead
                                                                              },
                                                                              attrs: {
                                                                                icon: [
                                                                                  "fal",
                                                                                  "copy"
                                                                                ]
                                                                              },
                                                                              on: {
                                                                                click:
                                                                                  _vm.copyContentHead
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              ref:
                                                                                "codeViewer",
                                                                              refInFor: true,
                                                                              staticClass:
                                                                                "codeViewer"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                '\n                                <script src="' +
                                                                                  _vm._s(
                                                                                    _vm.getHost()
                                                                                  ) +
                                                                                  '/widget/voffice-widget.js"></script>\n                              '
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 d-flex ml-0 mb-0 mt-3"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.adminSettingsModal.beaconCopyBody"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "containerCode"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "font-awesome-icon",
                                                                            {
                                                                              staticClass:
                                                                                "iconCopy",
                                                                              style: {
                                                                                fontSize:
                                                                                  "20px",
                                                                                color:
                                                                                  _vm.colorIconBody
                                                                              },
                                                                              attrs: {
                                                                                icon: [
                                                                                  "fal",
                                                                                  "copy"
                                                                                ]
                                                                              },
                                                                              on: {
                                                                                click:
                                                                                  _vm.copyContentBody
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              ref:
                                                                                "codeViewerBody",
                                                                              refInFor: true,
                                                                              staticClass:
                                                                                "codeViewer"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                '\n                                <voffice-widget namespace="' +
                                                                                  _vm._s(
                                                                                    _vm.getHost()
                                                                                  ) +
                                                                                  '"\n                                title="' +
                                                                                  _vm._s(
                                                                                    _vm.beaconTitleDE
                                                                                  ) +
                                                                                  '" color="' +
                                                                                  _vm._s(
                                                                                    _vm.beaconColor
                                                                                  ) +
                                                                                  "\" position='" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .state
                                                                                      .namespaceSettings
                                                                                      .beaconPosition
                                                                                  ) +
                                                                                  "'\n                                 icon=\"" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .state
                                                                                      .namespaceSettings
                                                                                      .companyLogo
                                                                                  ) +
                                                                                  '" ' +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .state
                                                                                      .namespaceSettings
                                                                                      .beaconLanguage &&
                                                                                      _vm
                                                                                        .state
                                                                                        .namespaceSettings
                                                                                        .beaconLanguage !==
                                                                                        "default"
                                                                                      ? 'language="' +
                                                                                          _vm.beaconLanguage +
                                                                                          '"'
                                                                                      : ""
                                                                                  ) +
                                                                                  "></voffice-widget>\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "py-0 px-0",
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "px-0 mb-3"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 d-flex ml-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            Icon\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("input", {
                                                                    ref:
                                                                      "beaconLogoInput",
                                                                    refInFor: true,
                                                                    staticClass:
                                                                      "d-none",
                                                                    attrs: {
                                                                      type:
                                                                        "file"
                                                                    },
                                                                    on: {
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.showLogoPreviewModal(
                                                                          $event,
                                                                          "beaconLogo"
                                                                        )
                                                                      }
                                                                    }
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    {
                                                                      attrs: {
                                                                        tile:
                                                                          "",
                                                                        size:
                                                                          "130"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "borderCircle",
                                                                          attrs: {
                                                                            id:
                                                                              "logoModal",
                                                                            "max-height":
                                                                              "130",
                                                                            "max-width":
                                                                              "130",
                                                                            contain:
                                                                              "",
                                                                            src:
                                                                              _vm
                                                                                .state
                                                                                .namespaceSettings
                                                                                .beaconLogo ||
                                                                              "assets/icon.png",
                                                                            "lazy-src":
                                                                              "assets/icon.png"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "uploadBeaconButton p-4",
                                                                          attrs: {
                                                                            flex:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          _vm._g(
                                                                                            {
                                                                                              staticClass:
                                                                                                "mb-2",
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                isSmall:
                                                                                                  "true"
                                                                                              },
                                                                                              on: {
                                                                                                click:
                                                                                                  _vm.uploadBeaconLogo
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _c(
                                                                                              "font-awesome-icon",
                                                                                              {
                                                                                                style: {
                                                                                                  fontSize:
                                                                                                    "20px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  icon: [
                                                                                                    "fal",
                                                                                                    "upload"
                                                                                                  ]
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "components.navbar.uploadPicture"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              staticClass:
                                                                "px-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle-2 d-flex ml-0"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.adminSettingsModal.beaconColor"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-color-picker",
                                                                    {
                                                                      staticClass:
                                                                        "pt-2",
                                                                      attrs: {
                                                                        mode:
                                                                          _vm.mode,
                                                                        "dot-size":
                                                                          "20",
                                                                        "swatches-max-height":
                                                                          "200",
                                                                        "hide-mode-switch":
                                                                          "",
                                                                        width:
                                                                          "280"
                                                                      },
                                                                      on: {
                                                                        "update:mode": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.mode = $event
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.beaconColor,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.beaconColor = $$v
                                                                        },
                                                                        expression:
                                                                          "beaconColor"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 mt-2 d-flex ml-0"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "generics.position"
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-radio-group",
                                                                            {
                                                                              staticClass:
                                                                                "px-2",
                                                                              attrs: {
                                                                                "hide-details":
                                                                                  "",
                                                                                row:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.beaconPositionBottom = 0
                                                                                  _vm.beaconPositionRight = 0
                                                                                  _vm.beaconPositionLeft = 0
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.beaconPositionSide,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.beaconPositionSide = $$v
                                                                                },
                                                                                expression:
                                                                                  "beaconPositionSide"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-radio",
                                                                                {
                                                                                  attrs: {
                                                                                    label: _vm.$t(
                                                                                      "generics.right"
                                                                                    ),
                                                                                    value:
                                                                                      "right"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-radio",
                                                                                {
                                                                                  attrs: {
                                                                                    label: _vm.$t(
                                                                                      "generics.left"
                                                                                    ),
                                                                                    value:
                                                                                      "left"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex mt-2"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  staticClass:
                                                                                    "px-2",
                                                                                  attrs: {
                                                                                    label:
                                                                                      "Bottom",
                                                                                    min: 0,
                                                                                    type:
                                                                                      "number"
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.beaconPositionBottom,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.beaconPositionBottom = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "beaconPositionBottom"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm.beaconPositionSide ===
                                                                              "right"
                                                                                ? _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-2",
                                                                                      attrs: {
                                                                                        label:
                                                                                          _vm.beaconPositionSide,
                                                                                        min: 0,
                                                                                        type:
                                                                                          "number"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm.beaconPositionRight,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.beaconPositionRight = $$v
                                                                                        },
                                                                                        expression:
                                                                                          "beaconPositionRight"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                : _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      staticClass:
                                                                                        "px-2",
                                                                                      attrs: {
                                                                                        label:
                                                                                          _vm.beaconPositionSide,
                                                                                        min: 0,
                                                                                        type:
                                                                                          "number"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm.beaconPositionLeft,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.beaconPositionLeft = $$v
                                                                                        },
                                                                                        expression:
                                                                                          "beaconPositionLeft"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 7
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mx-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "8" }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              counter: "",
                                                              contenteditable:
                                                                "true",
                                                              label: _vm.$t(
                                                                "components.sendMessageModal.message"
                                                              ),
                                                              rules:
                                                                _vm.systemMessageRules,
                                                              "no-resize": true,
                                                              maxlength: "250",
                                                              autofocus: "",
                                                              id:
                                                                "messageTextarea"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.systemMessagetext,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.systemMessagetext = $$v
                                                              },
                                                              expression:
                                                                "systemMessagetext"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-checkbox",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mt-0 mb-2 messageForAllExpires",
                                                                              attrs: {
                                                                                "hide-details":
                                                                                  "",
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.expiredTime"
                                                                                )
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.messageForAllExpires,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.messageForAllExpires = $$v
                                                                                },
                                                                                expression:
                                                                                  "messageForAllExpires"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.adminSettingsModal.expiredTimeTT"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.messageForAllExpires
                                                            ? [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "6"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-menu",
                                                                          {
                                                                            attrs: {
                                                                              "close-on-content-click": false,
                                                                              transition:
                                                                                "scale-transition",
                                                                              "offset-y":
                                                                                "",
                                                                              "max-width":
                                                                                "290px",
                                                                              "min-width":
                                                                                "auto"
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-text-field",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-0",
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                readonly:
                                                                                                  ""
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.messageForAllDate,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.messageForAllDate = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "messageForAllDate"
                                                                                              }
                                                                                            },
                                                                                            "v-text-field",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "font-awesome-icon",
                                                                                            {
                                                                                              style: {
                                                                                                fontSize:
                                                                                                  "20px",
                                                                                                color:
                                                                                                  "#757575"
                                                                                              },
                                                                                              attrs: {
                                                                                                slot:
                                                                                                  "prepend",
                                                                                                icon: [
                                                                                                  "fal",
                                                                                                  "calendar"
                                                                                                ]
                                                                                              },
                                                                                              slot:
                                                                                                "prepend"
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                            model: {
                                                                              value:
                                                                                _vm.messageForAllDateMenu,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.messageForAllDateMenu = $$v
                                                                              },
                                                                              expression:
                                                                                "messageForAllDateMenu"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-date-picker",
                                                                              {
                                                                                attrs: {
                                                                                  min: new Date(
                                                                                    Date.now() -
                                                                                      new Date().getTimezoneOffset() *
                                                                                        60000
                                                                                  )
                                                                                    .toISOString()
                                                                                    .slice(
                                                                                      0,
                                                                                      10
                                                                                    ),
                                                                                  locale: _vm.$locale.current(),
                                                                                  "no-title":
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.messageForAllDateMenu = false
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.messageForAllDate,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.messageForAllDate = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "messageForAllDate"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "6"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-menu",
                                                                          {
                                                                            ref:
                                                                              "messageForAllTimeMenu",
                                                                            refInFor: true,
                                                                            attrs: {
                                                                              "close-on-content-click": false,
                                                                              transition:
                                                                                "scale-transition",
                                                                              "return-value":
                                                                                _vm.messageForAllTime,
                                                                              "offset-y":
                                                                                "",
                                                                              "max-width":
                                                                                "290px",
                                                                              "min-width":
                                                                                "290px"
                                                                            },
                                                                            on: {
                                                                              "update:returnValue": function(
                                                                                $event
                                                                              ) {
                                                                                _vm.messageForAllTime = $event
                                                                              },
                                                                              "update:return-value": function(
                                                                                $event
                                                                              ) {
                                                                                _vm.messageForAllTime = $event
                                                                              }
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-text-field",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-0",
                                                                                              attrs: {
                                                                                                readonly:
                                                                                                  "",
                                                                                                "hide-details":
                                                                                                  ""
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.messageForAllTime,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.messageForAllTime = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "messageForAllTime"
                                                                                              }
                                                                                            },
                                                                                            "v-text-field",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "font-awesome-icon",
                                                                                            {
                                                                                              style: {
                                                                                                fontSize:
                                                                                                  "20px",
                                                                                                color:
                                                                                                  "#757575"
                                                                                              },
                                                                                              attrs: {
                                                                                                slot:
                                                                                                  "prepend",
                                                                                                icon: [
                                                                                                  "fal",
                                                                                                  "clock"
                                                                                                ]
                                                                                              },
                                                                                              slot:
                                                                                                "prepend"
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                            model: {
                                                                              value:
                                                                                _vm.messageForAllTimeMenu,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.messageForAllTimeMenu = $$v
                                                                              },
                                                                              expression:
                                                                                "messageForAllTimeMenu"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.messageForAllTimeMenu
                                                                              ? _c(
                                                                                  "v-time-picker",
                                                                                  {
                                                                                    attrs: {
                                                                                      "full-width":
                                                                                        "",
                                                                                      format:
                                                                                        "24hr",
                                                                                      min:
                                                                                        _vm.minMessageForAllTime
                                                                                    },
                                                                                    on: {
                                                                                      "click:minute": function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$refs.messageForAllTimeMenu[0].save(
                                                                                          _vm.messageForAllTime
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.messageForAllTime,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.messageForAllTime = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "messageForAllTime"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "4" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              attrs: {
                                                                column: "",
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.systemMessageColor,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.systemMessageColor = $$v
                                                                },
                                                                expression:
                                                                  "systemMessageColor"
                                                              }
                                                            },
                                                            [
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value: "red"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w100 ml-1 red text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "white--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "generics.sampleText"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value: "green"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w100 ml-1 green text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "white--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "generics.sampleText"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value:
                                                                    "yellow"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w100 ml-1 yellow text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "black--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "generics.sampleText"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value: "teal"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w100 ml-1 teal text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "white--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "generics.sampleText"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value: "white"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w100 ml-1 white text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "black--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "generics.sampleText"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  value: "black"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "w100 ml-1 black text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "white--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "generics.sampleText"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mx-0" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            depressed: "",
                                                            color: "primary",
                                                            disabled:
                                                              _vm.disabledSystemMessageBtn
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.sendSystemMessage()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.adminSettingsModal.sendMessageToAll"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 10
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left"
                                                    },
                                                    [
                                                      _c("v-col", {
                                                        staticClass: "py-0",
                                                        attrs: { cols: "12" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            staticClass:
                                                              "paddingTextArea",
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "components.adminSettingsModal.userEmailText"
                                                              ),
                                                              contenteditable: true,
                                                              "no-resize": true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userInviteEmailText,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.userInviteEmailText = $$v
                                                              },
                                                              expression:
                                                                "userInviteEmailText"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pt-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                color:
                                                                  "primary",
                                                                loading:
                                                                  _vm.savingInviteEmailText,
                                                                disabled:
                                                                  _vm.savingInviteEmailText
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.resetUserInviteEmailText()
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "loader",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "custom-loader"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                style: {
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  color:
                                                                                    "#2a3133"
                                                                                },
                                                                                attrs: {
                                                                                  icon: [
                                                                                    "fal",
                                                                                    "spinner-third"
                                                                                  ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteGuestPanel.resetCustomInviteText"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  !_vm.userInviteEmailText ||
                                                                  _vm.savingInviteEmailText,
                                                                loading:
                                                                  _vm.savingInviteEmailText
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.saveUserInviteEmailText()
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "loader",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "custom-loader"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                style: {
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  color:
                                                                                    "#2a3133"
                                                                                },
                                                                                attrs: {
                                                                                  icon: [
                                                                                    "fal",
                                                                                    "spinner-third"
                                                                                  ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteGuestPanel.saveText"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left"
                                                    },
                                                    [
                                                      _c("v-col", {
                                                        staticClass: "py-0",
                                                        attrs: { cols: "12" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pb-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            staticClass:
                                                              "paddingTextArea",
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "components.adminSettingsModal.accessLinkText"
                                                              ),
                                                              contenteditable: true,
                                                              "no-resize": true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.accessLinkEmailText,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.accessLinkEmailText = $$v
                                                              },
                                                              expression:
                                                                "accessLinkEmailText"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                color:
                                                                  "primary",
                                                                loading:
                                                                  _vm.savingAccessLinkEmailText,
                                                                disabled:
                                                                  _vm.savingAccessLinkEmailText
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.resetAccessLinkEmailText()
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "loader",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "custom-loader"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                style: {
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  color:
                                                                                    "#2a3133"
                                                                                },
                                                                                attrs: {
                                                                                  icon: [
                                                                                    "fal",
                                                                                    "spinner-third"
                                                                                  ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteGuestPanel.resetCustomInviteText"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                color:
                                                                  "primary",
                                                                loading:
                                                                  _vm.savingAccessLinkEmailText,
                                                                disabled:
                                                                  !_vm.accessLinkEmailText ||
                                                                  _vm.savingAccessLinkEmailText
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.saveAccessLinkEmailText()
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "loader",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "custom-loader"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                style: {
                                                                                  fontSize:
                                                                                    "15px",
                                                                                  color:
                                                                                    "#2a3133"
                                                                                },
                                                                                attrs: {
                                                                                  icon: [
                                                                                    "fal",
                                                                                    "spinner-third"
                                                                                  ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteGuestPanel.saveText"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 11
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "px-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-left subtitle-2 d-flex mr-2"
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "py-0 my-0 pr-2",
                                                                attrs: {
                                                                  "hide-details":
                                                                    "",
                                                                  width: "100%"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeShowConferencehall()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.showConferencehall,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.showConferencehall = $$v
                                                                  },
                                                                  expression:
                                                                    "showConferencehall"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.adminSettingsModal.showEvents"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 12
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "px-0 py-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-left subtitle-2 d-flex mr-2"
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "py-0 my-0 pr-2",
                                                                attrs: {
                                                                  "hide-details":
                                                                    "",
                                                                  width: "100%"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeShowDailyReport()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.showDailyReport,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.showDailyReport = $$v
                                                                  },
                                                                  expression:
                                                                    "showDailyReport"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.adminSettingsModal.showDailyReport"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "px-0 pt-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              attrs: {
                                                                row: "",
                                                                disabled: !_vm.showDailyReport
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeDailyReportValue()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.dailyReportValue,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.dailyReportValue = $$v
                                                                },
                                                                expression:
                                                                  "dailyReportValue"
                                                              }
                                                            },
                                                            [
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "generics.none"
                                                                  ),
                                                                  value:
                                                                    "perUser"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "components.organisationCarousel.team"
                                                                  ),
                                                                  value: "team"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "components.organisationCarousel.department"
                                                                  ),
                                                                  value:
                                                                    "department"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "components.organisationCarousel.section"
                                                                  ),
                                                                  value:
                                                                    "section"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 13
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _vm.amIAdmin &&
                                                  _vm.isDevelopment
                                                    ? [
                                                        _vm.amIAdmin &&
                                                        _vm.isDevelopment
                                                          ? _c(
                                                              "v-list-item",
                                                              {
                                                                staticClass:
                                                                  "py-0 px-0 switchWrapper"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  {
                                                                    staticClass:
                                                                      "py-0 pl-3 my-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "subtitle-2 d-flex ml-0"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.adminSettingsModal.timeframeDeletion"
                                                                              )
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        [
                                                          _vm.amIAdmin &&
                                                          _vm.isDevelopment
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "py-0 px-0 switchWrapper"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    {
                                                                      staticClass:
                                                                        "py-0 pl-3 my-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 d-flex ml-0"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.deletionMessages"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.adminSettingsModal.deletionTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          attrs: {
                                                                            row:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeDeletionMessages()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.deletionMessages,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.deletionMessages = $$v
                                                                            },
                                                                            expression:
                                                                              "deletionMessages"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1day"
                                                                                ),
                                                                                value:
                                                                                  "1day"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.2day"
                                                                                ),
                                                                                value: false
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1week"
                                                                                ),
                                                                                value:
                                                                                  "1week"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1month"
                                                                                ),
                                                                                value:
                                                                                  "1month"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.3month"
                                                                                ),
                                                                                value:
                                                                                  "3months"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.never"
                                                                                ),
                                                                                value:
                                                                                  "never"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        _vm._v(" "),
                                                        [
                                                          _vm.amIAdmin &&
                                                          _vm.isDevelopment
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "py-0 px-0 switchWrapper"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    {
                                                                      staticClass:
                                                                        "py-0 pl-3 my-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 d-flex ml-0"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.deletionMissedCalls"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.adminSettingsModal.deletionTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          attrs: {
                                                                            row:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeDeletionMissedCalls()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.deletionCalls,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.deletionCalls = $$v
                                                                            },
                                                                            expression:
                                                                              "deletionCalls"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1day"
                                                                                ),
                                                                                value:
                                                                                  "1day"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.2day"
                                                                                ),
                                                                                value: false
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1week"
                                                                                ),
                                                                                value:
                                                                                  "1week"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1month"
                                                                                ),
                                                                                value:
                                                                                  "1month"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.3month"
                                                                                ),
                                                                                value:
                                                                                  "3months"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.never"
                                                                                ),
                                                                                value:
                                                                                  "never"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        _vm._v(" "),
                                                        [
                                                          _vm.amIAdmin &&
                                                          _vm.isDevelopment
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "py-0 px-0 switchWrapper"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    {
                                                                      staticClass:
                                                                        "py-0 pl-3 my-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 d-flex ml-0"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.deletionInvites"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.adminSettingsModal.deletionTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          attrs: {
                                                                            row:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeDeletionInvites()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.deletionInvites,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.deletionInvites = $$v
                                                                            },
                                                                            expression:
                                                                              "deletionInvites"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1day"
                                                                                ),
                                                                                value:
                                                                                  "1day"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.2day"
                                                                                ),
                                                                                value: false
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1week"
                                                                                ),
                                                                                value:
                                                                                  "1week"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1month"
                                                                                ),
                                                                                value:
                                                                                  "1month"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.3month"
                                                                                ),
                                                                                value:
                                                                                  "3months"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.never"
                                                                                ),
                                                                                value:
                                                                                  "never"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        _vm._v(" "),
                                                        [
                                                          _vm.amIAdmin &&
                                                          _vm.isDevelopment
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "py-0 px-0 switchWrapper"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    {
                                                                      staticClass:
                                                                        "py-0 pl-3 my-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item-subtitle",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 d-flex ml-0"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "components.adminSettingsModal.autoDeleteFrequency"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.adminSettingsModal.deletionTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-radio-group",
                                                                        {
                                                                          attrs: {
                                                                            row:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change:
                                                                              _vm.changeAutoDeleteConferences
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.autoDeleteConferences,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.autoDeleteConferences = $$v
                                                                            },
                                                                            expression:
                                                                              "autoDeleteConferences"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1day"
                                                                                ),
                                                                                value:
                                                                                  "1"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.2day"
                                                                                ),
                                                                                value:
                                                                                  "2"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1week"
                                                                                ),
                                                                                value:
                                                                                  "7"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.1month"
                                                                                ),
                                                                                value:
                                                                                  "30"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.adminSettingsModal.3month"
                                                                                ),
                                                                                value:
                                                                                  "90"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-radio",
                                                                            {
                                                                              attrs: {
                                                                                label: _vm.$t(
                                                                                  "components.addConferenceModal.never"
                                                                                ),
                                                                                value:
                                                                                  "0"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        staticClass:
                                                          "py-0 px-0 switchWrapper"
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass:
                                                              "py-0 pl-3 my-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 d-flex ml-0 align-baseline"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mr-4"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.adminSettingsModal.ICE_servers"
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                      "content-class":
                                                                        "videoButtonTT"
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "font-awesome-icon",
                                                                                _vm._g(
                                                                                  {
                                                                                    style: {
                                                                                      fontSize:
                                                                                        "17px",
                                                                                      position:
                                                                                        "relative",
                                                                                      top:
                                                                                        "2px"
                                                                                    },
                                                                                    attrs: {
                                                                                      color:
                                                                                        "#2a3133",
                                                                                      icon: [
                                                                                        "fal",
                                                                                        "info-circle"
                                                                                      ]
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                )
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.adminSettingsModal.ICE_serversInfo"
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      { staticClass: "mx-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols:
                                                                "12 text-left"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                ref:
                                                                  "selectIceServers",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "selectIceServers",
                                                                attrs: {
                                                                  size: "4"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.getIceServers,
                                                                function(
                                                                  server,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: index,
                                                                      domProps: {
                                                                        value: server
                                                                      },
                                                                      on: {
                                                                        dblclick: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectServer(
                                                                            server
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            server.urls
                                                                          ) +
                                                                          "\n                            "
                                                                      ),
                                                                      server.credential &&
                                                                      server.username
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "[" +
                                                                                  _vm._s(
                                                                                    server.username
                                                                                  ) +
                                                                                  ":" +
                                                                                  _vm._s(
                                                                                    server.credential
                                                                                  ) +
                                                                                  "]"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                            _vm._v(" "),
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                outlined: "",
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                label: _vm.$t(
                                                                  "components.adminSettingsModal.TURN_STUN_URI"
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.STUN_TURN_URI,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.STUN_TURN_URI = $$v
                                                                },
                                                                expression:
                                                                  "STUN_TURN_URI"
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                outlined: "",
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                label: _vm.$t(
                                                                  "components.adminSettingsModal.TURN_username"
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.TURN_username,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.TURN_username = $$v
                                                                },
                                                                expression:
                                                                  "TURN_username"
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                outlined: "",
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                type:
                                                                  "password",
                                                                label: _vm.$t(
                                                                  "components.adminSettingsModal.TURN_password"
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.TURN_password,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.TURN_password = $$v
                                                                },
                                                                expression:
                                                                  "TURN_password"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass: "mx-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "px-0",
                                                                attrs: {
                                                                  cols: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    style:
                                                                      "text-transform:none!important",
                                                                    attrs: {
                                                                      depressed:
                                                                        "",
                                                                      color:
                                                                        "primary",
                                                                      disabled:
                                                                        !_vm.STUN_TURN_URI ||
                                                                        _vm
                                                                          .STUN_TURN_URI
                                                                          .length ==
                                                                          0
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.addServer.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.AddServer"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "px-0",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    style:
                                                                      "text-transform:none!important",
                                                                    attrs: {
                                                                      depressed:
                                                                        "",
                                                                      color:
                                                                        "primary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.openCloseModalRemoveICEServer.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.RemoveServer"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "px-0",
                                                                attrs: {
                                                                  cols: "5"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    style:
                                                                      "text-transform:none!important",
                                                                    attrs: {
                                                                      depressed:
                                                                        "",
                                                                      color:
                                                                        "primary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.resetDefault.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.adminSettingsModal.ResetDefaults"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 14
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _vm.amIAdmin
                                                    ? [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "text-left"
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "5"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-combobox",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          items:
                                                                            _vm.getTemplatesWorkingTime,
                                                                          "item-text":
                                                                            "name",
                                                                          "item-value":
                                                                            "_id"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedWorkingTimeTemplate,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.selectedWorkingTimeTemplate = $$v
                                                                          },
                                                                          expression:
                                                                            "selectedWorkingTimeTemplate"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "text-right"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-4",
                                                                                attrs: {
                                                                                  disabled: !_vm.selectedWorkingTimeTemplate,
                                                                                  color:
                                                                                    "primary",
                                                                                  depressed:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openCloseModalAddEditWorkingTimeTemplate(
                                                                                      true,
                                                                                      _vm.selectedWorkingTimeTemplate
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                               " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "components.workingTime.editTemplate"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                              "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-4",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  depressed:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openCloseModalAddEditWorkingTimeTemplate(
                                                                                      true,
                                                                                      undefined
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                 " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "components.workingTime.addTemplate"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "7"
                                                                }
                                                              },
                                                              [
                                                                _vm.selectedWorkingTimeTemplate
                                                                  ? [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-center"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.workingTime.template"
                                                                                )
                                                                              ) +
                                                                              ": " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .selectedWorkingTimeTemplate
                                                                                  .name
                                                                              )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-simple-table",
                                                                        {
                                                                          attrs: {
                                                                            "fixed-header":
                                                                              "",
                                                                            dense:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "default",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "thead",
                                                                                      [
                                                                                        _c(
                                                                                          "tr",
                                                                                          [
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-left"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "components.workingTime.day"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-left"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "components.workingTime.workTime"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "text-left"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                   " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "components.workingTime.pause"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "tbody",
                                                                                      _vm._l(
                                                                                        _vm.getSelectedWorkingTimeData,
                                                                                        function(
                                                                                          data,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "tr",
                                                                                            {
                                                                                              key: index
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "components.workingTime." +
                                                                                                          _vm.days[
                                                                                                            index
                                                                                                          ].toLowerCase() +
                                                                                                          ""
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.toHoursAndMinutes(
                                                                                                        data.work
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.toHoursAndMinutes(
                                                                                                        data.pause
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        }
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ],
                                                              2
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 15
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "px-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-left subtitle-2 d-flex mr-2"
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "py-0 my-0 pr-2",
                                                                attrs: {
                                                                  "hide-details":
                                                                    "",
                                                                  width: "100%"
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeShowGPT4BOT()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.showGPT4BOT,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.showGPT4BOT = $$v
                                                                  },
                                                                  expression:
                                                                    "showGPT4BOT"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.adminSettingsModal.showIa"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "text-left"
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "px-0",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-left subtitle-2 d-flex mr-2"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                    staticStyle: {
                                                                      height:
                                                                        "40px"
                                                                    },
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      label: _vm.$t(
                                                                        "components.adminSettingsModal.apiKeyOpenAi"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.openAiApiKey,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.openAiApiKey = $$v
                                                                      },
                                                                      expression:
                                                                        "openAiApiKey"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "text-left"
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "px-0",
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-left subtitle-2 d-flex mr-2"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                    staticStyle: {
                                                                      height:
                                                                        "40px"
                                                                    },
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      label: _vm.$t(
                                                                        "components.adminSettingsModal.apiKeyHumanPicture"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.humanPictureApiKey,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.humanPictureApiKey = $$v
                                                                      },
                                                                      expression:
                                                                        "humanPictureApiKey"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "text-right"
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.setGpt4bot()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "generics.save"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.index == 16
                                            ? _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "px-0",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass: "mt-2",
                                                            staticStyle: {
                                                              height: "40px"
                                                            },
                                                            attrs: {
                                                              outlined: "",
                                                              dense: "",
                                                              label: _vm.$t(
                                                                "literals.Email address"
                                                              ),
                                                              "prepend-inner-icon":
                                                                "mdi-magnify",
                                                              clearable: ""
                                                            },
                                                            on: {
                                                              "click:clear": function(
                                                                $event
                                                              ) {
                                                                _vm.searchGuest =
                                                                  ""
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.searchGuest,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.searchGuest = $$v
                                                              },
                                                              expression:
                                                                "searchGuest"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        _vm._l(
                                                          _vm.guestEmailList,
                                                          function(guest) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  guest.user
                                                                    .uuid,
                                                                staticClass:
                                                                  "d-flex"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "11"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        guest
                                                                          .user
                                                                          .email
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "text-right pr-0",
                                                                    attrs: {
                                                                      cols: "1"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "DeleteBasket",
                                                                      {
                                                                        attrs: {
                                                                          indexData:
                                                                            guest
                                                                              .user
                                                                              .uuid,
                                                                          delFunction:
                                                                            _vm.removeGuestEmail,
                                                                          extraClasses:
                                                                            "",
                                                                          fontSize:
                                                                            "20",
                                                                          prevent:
                                                                            "false"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showRelayText
                ? _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "py-0 switchWrapper" },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.adminSettingsModal.relayServerInfo0"
                                    )
                                  ) + "{{}}"
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.adminSettingsModal.relayServerInfo1"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.adminSettingsModal.relayServerInfo2"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAliasText
                ? _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "py-0 switchWrapper" },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.adminSettingsModal.setAlias"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.adminSettingsModal.setAliasInfo"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.showCroppieLogo
                ? _c("FooterModal", {
                    attrs: { closeModalFunction: _vm.closeModalFunction }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        ref: "backgroundWaitingRoomInput",
        staticClass: "d-none",
        attrs: { type: "file", accept: "image/x-png,image/gif,image/jpeg" },
        on: {
          change: function($event) {
            return _vm.saveBackgroundWaitingRoom($event)
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "pdfWaitingRoomInput",
        staticClass: "d-none",
        attrs: { type: "file", accept: "application/pdf" },
        on: {
          change: function($event) {
            return _vm.savePdfWaitingRoom($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.openLocationModal
        ? [
            _c("AddLocationModal", {
              attrs: {
                showModal: _vm.openLocationModal,
                closeModal: _vm.setCustomLocation
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showFtpModal
        ? [
            _c("ConfirmFtpSettingModal", {
              attrs: {
                showModal: _vm.showFtpModal,
                closeModal: _vm.openCloseConfirmationFtp,
                cancelFtp: _vm.changeFtp
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.openInfoCompact
        ? [
            _c("CompactViewInfoModal", {
              attrs: {
                showModal: _vm.openInfoCompact,
                closeModal: _vm.opencloseInfoCompact
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showPayModal
        ? [
            _c("PayModal", {
              attrs: {
                showModal: _vm.showPayModal,
                closeModal: _vm.openClosePayModal
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      [
        _c("TwoFAInfoModal", {
          attrs: {
            showModal: _vm.show2faInfoModal,
            closeModal: _vm.openClose2faModal
          }
        })
      ],
      _vm._v(" "),
      _vm.showShortRelayModal
        ? [
            _c("RelyShortHelpModal", {
              attrs: {
                showModal: _vm.showShortRelayModal,
                closeModal: _vm.openCloseShortRelyModal
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showRatingStats
        ? [
            _c("CallsRatingModal", {
              attrs: {
                showModal: _vm.showRatingStats,
                closeModal: _vm.opencloseStats
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showPayPalSettings
        ? [
            _c("PayPalSettingsModal", {
              attrs: {
                showModal: _vm.showPayPalSettings,
                closeModal: _vm.openClosePayPalSettings
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalProducts
        ? [
            _c("ProductsModal", {
              attrs: {
                showModal: _vm.showModalProducts,
                closeModal: _vm.openCloseModalProducts
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalRemoveICEServer
        ? [
            _c("RemoveIceServerModal", {
              attrs: {
                showModal: _vm.showModalRemoveICEServer,
                closeModal: _vm.openCloseModalRemoveICEServer,
                removeICEServer: _vm.removeServer
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalAddEditWorkingTimeTemplate
        ? [
            _c("AddEditWorkingTimeTemplate", {
              attrs: {
                showModal: _vm.showModalAddEditWorkingTimeTemplate,
                editTemplate: _vm.editTemplatesWorkingTime,
                closeModal: _vm.openCloseModalAddEditWorkingTimeTemplate
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }