<template>
    <div>
        <v-row class="d-flex align-start">
            <v-col class="d-flex justify-center" cols="auto">
                <UserAvatar :src="getAvatarForUuid(comment.userId)" :size="36" />
            </v-col>
            <v-col>
                <div class="border-sm rounded-sm pa-4">
                    <div class="font-weight-bold">{{ getTitelByUuid(comment.userId) }} {{
                        getNameForUuid(comment.userId)}}</div>
                    <div v-if="!isEditing" v-html="$sanitize(comment.comment)"></div>
                    <EditComment v-if="isEditing" :postData="comment" @closeEdit="isEditing = false" />
                </div>
            </v-col>

            <v-col class="text-right" cols="1" v-if="comment.userId === ownUUID">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn density="compact" icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
                    </template>
                    <v-list>
                        <!-- <v-list-item @click="isEditing = true" class="pointer" v-if="comment.userId === ownUUID">
                        <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item> -->
                        <v-list-item @click="isEditing = true" class="pointer" v-if="comment.userId === ownUUID">
                            <v-list-item-title>{{ $t('components.socialMedia.edit') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :class="{ 'text-error': confirmDeleteId === comment._id }" class="pointer"
                            v-if="comment.userId === ownUUID || amIAdmin" @click.stop="removeComment(comment)">
                            <v-list-item-title> {{ $t('components.socialMedia.remove') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="d-flex align-center mt-0">
            <div class="text-muted text-caption pl-12 ml-6">{{ formattedDate(comment.createdAt) }}</div>
            <div>
                <v-btn @click.stop="toogleLike" icon variant="text" size="small" class="me-12">
                    <v-icon size="16" class="me-1">{{ comment?.likes?.includes(ownUUID) ? 'mdi-thumb-up' :
                        'mdi-thumb-up-outline' }}</v-icon>
                    <span class="me-2">{{ comment.likes.length }}</span>
                </v-btn>
                <!-- <v-btn variant="text" size="small">Antworten</v-btn> -->
            </div>
        </v-row>
    </div>
</template>

<script>
import store, { EventBus } from "../../../../store";
import moment from "../../../../../sharedsrc/moment";
import UserAvatar from "../common/userAvatar.vue";
import EditComment from "../common/editComment.vue";

export default {
    components: { UserAvatar, EditComment },
    props: ['comment'],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            newComment: '',
            confirmDeleteId: null,
            isEditing: false
        };
    },
    methods: {
        formattedDate(createdAt) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const formattedDate = moment(createdAt).tz(userTimeZone).format('DD.MM.YYYY HH:mm');
            return formattedDate
        },
        getAvatarForUuid(uuid) {
            return store.getAvatarForUuid(uuid);
        },
        getNameForUuid(uuid) {
            return store.getNameForUuid(uuid);
        },
        getTitelByUuid(uuid) {
            return store.getTitelForUuid(uuid);
        },
        toogleLike() {
            // Check if the current user has already liked the post
            const isLiked = this.comment.likes.includes(this.ownUUID);

            // If the post is already liked by the user, remove the like
            if (isLiked) {
                // Call the API to remove the like
                this.removeLike(this.comment.id, this.ownUUID);
            } else {
                // If the post is not liked by the user, add the like
                this.addLike(this.comment.id, this.ownUUID);
            }
        },
        addLike(postId, userId) {
            fetch("/api/socialmedia/like-comment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: postId, userId })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        // Update local postData to reflect the new like
                        this.$set(this.comment, 'likes', [...this.comment.likes, userId]);
                    } else {
                        // Handle any errors
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removeLike(postId, userId) {
            fetch("/api/socialmedia/unlike-comment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: postId, userId })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        // Remove the like from local postData
                        this.$set(this.comment, 'likes', this.comment.likes.filter(like => like !== userId));
                    } else {
                        // Handle any errors
                        console.error('Error removing like:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removeComment(comment) {
            if (this.confirmDeleteId === comment._id) {
                fetch("/api/socialmedia/remove-comment", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ commentId: comment._id })
                }).then((result) => {
                    if (!result.error) {
                        EventBus.$emit('removeComment', comment);
                        this.confirmDeleteId = null; // Restablecer después de eliminar
                    } else {
                        //TODO: Handler error
                    }
                })

            } else {
                this.confirmDeleteId = comment._id; // Primer clic: activar confirmación
                setTimeout(() => {
                    if (this.confirmDeleteId === comment._id) {
                        this.confirmDeleteId = null; // Si no hacen el segundo clic en 3s, se cancela
                    }
                }, 2000);
            }
        }
    },
    computed: {
        amIAdmin() {
            return store.getUserIsAdmin(this.ownUUID);
        },
    }
};
</script>
