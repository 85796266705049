var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "px-0 py-0" },
    [
      _c(
        "v-row",
        { staticClass: "px-3" },
        [
          _c("v-col", { staticClass: "font-weight-bold" }, [
            _vm.typeView === "month"
              ? _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("components.workingTime.month")) +
                      ": " +
                      _vm._s(_vm.timeCalculation)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.typeView === "week"
              ? _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("components.workingTime.week")) +
                      ": " +
                      _vm._s(_vm.timeCalculation)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.typeView === "day"
              ? _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("components.workingTime.day")) +
                      ": " +
                      _vm._s(_vm.timeCalculation)
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  color: _vm.isDark ? "white" : "primary",
                                  dense: "",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.typeView = "month"
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "25px" },
                                attrs: { icon: ["fal", "calendar"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.workingTime.monthlyView")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  color: _vm.isDark ? "white" : "primary",
                                  dense: "",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.typeView = "week"
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "25px" },
                                attrs: { icon: ["fal", "calendar-week"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.workingTime.weeklyView")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  color: _vm.isDark ? "white" : "primary",
                                  dense: "",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.typeView = "day"
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "25px" },
                                attrs: { icon: ["fal", "calendar-day"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.workingTime.dayView")))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showAddMoreTimeBtn
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { color: "", dense: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.addTimeModal()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        style: { fontSize: "25px" },
                        attrs: { icon: ["fal", "plus"] }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    outlined: "",
                    color: _vm.isDark ? "white" : "grey darken-2"
                  },
                  on: {
                    click: function($event) {
                      _vm.value = ""
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.workingTime.today")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2 btnCalendar",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.calendarWorkingTime.prev()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fal", "chevron-left"] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btnCalendar",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.calendarWorkingTime.next()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fal", "chevron-right"] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "text-white subtitle-1 font-weight-bold" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.calendarMonth) +
                      " " +
                      _vm._s(_vm.calendarYear) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-2 calendarContainer" },
            [
              _c(
                "v-card",
                {
                  staticClass: "h100",
                  attrs: { outlined: "", id: "calendarCard" }
                },
                [
                  [
                    _c("v-calendar", {
                      ref: "calendarWorkingTime",
                      attrs: {
                        weekdays: _vm.weekday,
                        color: "primary",
                        "first-interval": "5",
                        type: _vm.typeView,
                        events: _vm.events,
                        "event-color": _vm.getEventColor
                      },
                      on: {
                        "click:day": _vm.viewDay,
                        "click:date": _vm.viewDay,
                        "click:event": _vm.openEvent,
                        "click:more": _vm.viewDay,
                        change: _vm.calChangeHandler
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "event",
                          fn: function(ref) {
                            var event = ref.event
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(event.name) +
                                  ", " +
                                  _vm._s(_vm.getFormatedTime(event.start)) +
                                  " -\n              " +
                                  _vm._s(_vm.getFormatedTime(event.end)) +
                                  "\n            "
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.value,
                        callback: function($$v) {
                          _vm.value = $$v
                        },
                        expression: "value"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          activator: _vm.selectedElement,
                          "offset-y": "",
                          "max-width": "200px"
                        },
                        model: {
                          value: _vm.selectedOpen,
                          callback: function($$v) {
                            _vm.selectedOpen = $$v
                          },
                          expression: "selectedOpen"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          { attrs: { color: "grey lighten-4", flat: "" } },
                          [
                            _c(
                              "v-toolbar",
                              { attrs: { dense: "" } },
                              [
                                _c("v-toolbar-title", {
                                  style: { fontSize: "15px!important" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$sanitize(_vm.selectedEvent.name)
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c("DeleteBasket", {
                                  attrs: {
                                    indexData: _vm.selectedEvent,
                                    delFunction: _vm.removeEvent,
                                    extraClasses: "cursorPointer",
                                    fontSize: "18"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "560" },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.closeModal.apply(null, arguments)
              }
            },
            model: {
              value: _vm.showAddTimeModal,
              callback: function($$v) {
                _vm.showAddTimeModal = $$v
              },
              expression: "showAddTimeModal"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("HeaderModal", {
                  attrs: {
                    titleModal:
                      _vm.$t("components.workingTime.workingTime") +
                      " " +
                      _vm.value,
                    closeModalFunction: _vm.closeModal
                  }
                }),
                _vm._v(" "),
                _c("v-card-text", { staticClass: "mx-auto py-2" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0 pl-4", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menuStartPicker",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    "return-value": _vm.startTime,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      _vm.startTime = $event
                                    },
                                    "update:return-value": function($event) {
                                      _vm.startTime = $event
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                staticClass: "timePicker",
                                                attrs: {
                                                  type: "time",
                                                  "hide-details": "",
                                                  label: _vm.$t(
                                                    "components.conferenceForm.from"
                                                  )
                                                },
                                                on: {
                                                  keydown: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "space",
                                                        32,
                                                        $event.key,
                                                        [" ", "Spacebar"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return function(event) {
                                                      return event.preventDefault()
                                                    }.apply(null, arguments)
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$refs.menuStartPicker.save(
                                                      _vm.startTime
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.startTime,
                                                  callback: function($$v) {
                                                    _vm.startTime = $$v
                                                  },
                                                  expression: "startTime"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.menuStartPicker,
                                    callback: function($$v) {
                                      _vm.menuStartPicker = $$v
                                    },
                                    expression: "menuStartPicker"
                                  }
                                },
                                [
                                  _vm._v(" "),
                                  _vm.menuStartPicker
                                    ? _c("v-time-picker", {
                                        attrs: {
                                          format: "24hr",
                                          "full-width": "",
                                          min: "00:00"
                                        },
                                        on: {
                                          "click:minute": function($event) {
                                            return _vm.$refs.menuStartPicker.save(
                                              _vm.startTime
                                            )
                                          },
                                          "click:hour": _vm.autocompleteMinutes
                                        },
                                        model: {
                                          value: _vm.startTime,
                                          callback: function($$v) {
                                            _vm.startTime = $$v
                                          },
                                          expression: "startTime"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pb-0 pr-4", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menuEndPicker",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    "return-value": _vm.endTime,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      _vm.endTime = $event
                                    },
                                    "update:return-value": function($event) {
                                      _vm.endTime = $event
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "timePicker",
                                                  attrs: {
                                                    type: "time",
                                                    label: _vm.$t(
                                                      "components.conferenceForm.to"
                                                    ),
                                                    disabled: !_vm.startTime,
                                                    "hide-details": ""
                                                  },
                                                  on: {
                                                    keydown: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "space",
                                                          32,
                                                          $event.key,
                                                          [" ", "Spacebar"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return function(event) {
                                                        return event.preventDefault()
                                                      }.apply(null, arguments)
                                                    },
                                                    blur: function($event) {
                                                      return _vm.$refs.menuEndPicker.save(
                                                        _vm.endTime
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.endTime,
                                                    callback: function($$v) {
                                                      _vm.endTime = $$v
                                                    },
                                                    expression: "endTime"
                                                  }
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.menuEndPicker,
                                    callback: function($$v) {
                                      _vm.menuEndPicker = $$v
                                    },
                                    expression: "menuEndPicker"
                                  }
                                },
                                [
                                  _vm._v(" "),
                                  _vm.menuEndPicker
                                    ? _c("v-time-picker", {
                                        attrs: {
                                          format: "24hr",
                                          "full-width": "",
                                          min: _vm.startTime
                                        },
                                        on: {
                                          "click:minute": function($event) {
                                            return _vm.$refs.menuEndPicker.save(
                                              _vm.endTime
                                            )
                                          },
                                          "click:hour":
                                            _vm.autocompleteMinutesEnd
                                        },
                                        model: {
                                          value: _vm.endTime,
                                          callback: function($$v) {
                                            _vm.endTime = $$v
                                          },
                                          expression: "endTime"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pl-4 pt-4", attrs: { cols: "12" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.statusItems,
                                  label: _vm.$t(
                                    "components.drawerContentCard.status"
                                  ),
                                  "hide-details": "",
                                  dense: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "subtitle-2",
                                            style:
                                              "" +
                                              ("color:" +
                                                _vm.getStatusColor(item) +
                                                "!important")
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("status." + item))
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "subtitle-2",
                                            style:
                                              "" +
                                              ("color:" +
                                                _vm.getStatusColor(item) +
                                                "!important")
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("status." + item))
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.statusSelect,
                                  callback: function($$v) {
                                    _vm.statusSelect = $$v
                                  },
                                  expression: "statusSelect"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0" },
                            [
                              _c("v-textarea", {
                                staticClass: "mt-3",
                                attrs: {
                                  "no-resize": "",
                                  label: _vm.$t("generics.info"),
                                  outlined: "",
                                  counter: "255",
                                  maxlength: "255"
                                },
                                model: {
                                  value: _vm.eventDetail,
                                  callback: function($$v) {
                                    _vm.eventDetail = $$v
                                  },
                                  expression: "eventDetail"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "FooterModal",
                  {
                    attrs: {
                      closeModalFunction: _vm.closeModal,
                      showClose: true
                    }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          color: "primary",
                          disabled:
                            !_vm.eventDetail.length ||
                            !_vm.endTime ||
                            !_vm.startTime ||
                            !_vm.statusSelect.length
                        },
                        on: { click: _vm.saveAddTimeModal }
                      },
                      [_vm._v(_vm._s(_vm.$t("generics.save")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }