<template>
  <v-list :opened="opened" @update:opened="newOpened => opened = newOpened" max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      @click="handlerClickWaitingRoom($event)"
      value="waitingroom"
      v-if="!mini"
      :expand-icon="'$expand'"
    >
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" @click="handlerClickWaitingRoom($event)">
          <template v-slot:prepend>
            <v-icon size="20"
              class="primary--text-sidepanel"
              :class="`${getRoute === '/waitingroom' ? ' primary--text-sidepanel' : ''}`"
            >
              <font-awesome-icon
                v-if="currentPage('/waitingroom')"
                :icon="['fas', 'users-class']"
                class="primary--text-sidepanel iconsSize20"
              />
              <font-awesome-icon
                v-else
                :icon="['fal', 'users-class']"
                class="primary--text-sidepanel iconsSize20"
              />
            </v-icon>
          </template>
          <v-badge
            @click.native="handleWaitingRoom()"
            v-if="getconnectedWaitingrooms > 0"
            right
            color="error"
            :content="getconnectedWaitingrooms"
            :value="getconnectedWaitingrooms"
            bordered
            overlap
            offset-x="25"
            offset-y="-5"
            class="waitingRoomBadge"
          ></v-badge>
          <v-list-item-title
            class="pr-4 primary--text-sidepanel"
            :class="`${
              getRoute === '/waitingroom' ||
              (!hasPrivilege(state.ownUUID) && `${getRoute === '/home'}`)
                ? 'font-weight-bold'
                : ''
            }`"
            @click.stop.prevent="
              setCurrentContentVisile('', false, $router);
              handleWaitingRoom();
              handlerClickWaitingRoom($event);
            "
          >
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  {{ getTitleWaitingROOM }}
                  {{
                    `${
                      getconnectedWaitingrooms > 0
                        ? "(" + getconnectedWaitingrooms + ")"
                        : ""
                    }`
                  }}
                </span>
              </template>
              <span>{{ waitingRoomTT }}</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  density="compact"
                  class="btnAddGroup"
                  @click.stop.prevent="gotoPage('invitationform')"
                >
                  <font-awesome-icon
                    class="primary--text-sidepanel"
                    :icon="['fal', 'plus-circle']"
                    :style="{
                      fontSize: '20px',
                    }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.sidebar.guestTT") }}</span>
            </v-tooltip>
          </v-list-item-title>
          <CustomTT
            v-if="showCustomTT"
            :text="`${$t('components.hotkeys.controlKey')} + R`"
            position="bottom"
            :extraclass="'ctrr'"
          />
        </v-list-item>
      </template>
      <UserList
        v-if="sortedUsersWaitingRoom.length"
        :persons="sortedUsersWaitingRoom"
        :section="'guests'"
        typeData="waitingroom"
      />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="20" @click="handleWaitingRoom($event)">
            <font-awesome-icon
              v-if="currentPage('/waitingroom')"
              :icon="['fas', 'users-class']"
              class="primary--text-sidepanel iconsSize20"
            />
            <font-awesome-icon
              v-if="!currentPage('/waitingroom')"
              :icon="['fal', 'users-class']"
              class="primary--text-sidepanel iconsSize20"
            />
            <v-badge
              @click.native="handleWaitingRoom()"
              v-if="getconnectedWaitingrooms > 0"
              right
              color="error"
              :content="getconnectedWaitingrooms"
              :value="getconnectedWaitingrooms"
              bordered
              overlap
              offset-x="-10"
              offset-y="-5"
              :class="!!getconnectedWaitingrooms ? 'badgetWithData': ''"
              class="waitingRoomBadge"
            ></v-badge>
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ waitingRoomTT }}</span>
    </v-tooltip>
  </v-list>
</template>
<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import {userInCall} from "../../utils/calls"
import CustomTT from "../content/customTT.vue";
import UserList from "./userList.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { waitingInfoStore, markAsCallWaitingRoomUserEvent } from "../../effector/users/waitingInfo";

export default {
  components: { CustomTT, UserList },
  props: ["setList", "active", "waitingRoomFor", "mini"],
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
      waitingUsesEffect: waitingInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      moment,
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      opened: [],
      // Effector
      ...effector,
    };
  },
  watch: {
    waitingRoomList: {
    immediate: true,
    deep: true,
    handler: function (waitingList) {
      const MAX_RETRIES = 5;
      let attempts = 0;

      const processList = () => {
        const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
          .filter(([uuid, value]) => {
            return !!(value && this.waitingRoomList.find((e) => e.uuid === uuid));
          })
          .map(([uuid, value]) => uuid);

        let foundNewUser = false;

        waitingList.forEach(waitinVisitor => {
          if (this.userInCall(waitinVisitor.user.uuid)) {
            if (!calledVisitors.find(uuid => uuid === waitinVisitor.user.uuid)) {
              markAsCallWaitingRoomUserEvent(waitinVisitor.user.uuid);
              foundNewUser = true;
            }
          }
        });

        if (!foundNewUser && attempts < MAX_RETRIES) {
          attempts++;
          setTimeout(processList, 2000); // Reintenta después de 2 segundos
        }
      };
      processList();
    },
  },
    "isActive": {
      handler(value) {
        if(!value){
          this.opened = [];
        }
      },
    },
  },
  methods: {
    userInCall(uuid){
      return userInCall(uuid);
    },
    handlerClickWaitingRoom(event) {
      if (this.mini) {
        event.preventDefault();
        event.stopPropagation();
        this.goToPage("waitingroom");
      } else {
        this.setList("waitingroom");
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    handleWaitingRoom() {
      if (hasPrivilege(this.state.ownUUID)) {
        if (this.$route.path !== "/waitingroom") {
          if (this.state.ownUUID === this.waitingRoomFor) {
            store.setCurrentContentVisile("waitingroom", true, this.$router);
          } else {
            store.setCurrentContentVisile(
              `waitingroomfor/${this.waitingRoomFor}`,
              true,
              this.$router
            );
          }
        }
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
  },
  computed: {
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    waitingRoomList() {
      return store.getWaitingRoomList(this.ownUUID);
    },
    waitingRoomAlias() {
      return store.state.namespaceSettings.waitingRoomAlias;
    },
    isActive() {
      return this.active == "waitingroom";
    },
    waitingRoomTT() {
      return this.waitingRoomAlias
        ? this.$t("components.sidebar.waitingRoomTTAlias", [
            this.waitingRoomAlias,
          ])
        : this.$t("components.sidebar.waitingRoomMiniTT");
    },
    assistantsUser() {
      return (store.state.user.assists || []).length || 0;
    },
    getTitleWaitingROOM() {
      const waitingRoomName = this.$t(
        "components.drawerContentExpansion.guest"
      );
      if (this.waitingRoomFor === this.state.ownUUID) {
        return (
          waitingRoomName +
          ` ${
            this.assistantsUser > 0
              ? this.$t("generics.from") +
                " " +
                this.getTitelForUuid(this.state.ownUUID) +
                " " +
                this.getNameForUuid(this.state.ownUUID)
              : ""
          }`
        );
      } else {
        return (
          waitingRoomName +
          " " +
          this.$t("generics.from") +
          " " +
          this.getTitelForUuid(this.waitingRoomFor) +
          " " +
          this.getNameForUuid(this.waitingRoomFor)
        );
      }
    },
    sortedUsersWaitingRoom: function () {
      let waitingUsers = [];
      let connectedArray = [];
      let disconnectedArray = [];
      const visitors = this.state.persisted.userVisitors || {};
      for (const visitorId in visitors) {
        const visitor = store.state.persisted.userVisitors[visitorId];
        if (visitor && visitor.isWaitingRoom) {
          let person = store.state.group[visitorId];
          if (!person || !person.user || person.user.name == "") continue;
          if (person.connected) {
            connectedArray.push(person);
          } else {
            disconnectedArray.push(person);
          }
          waitingUsers.push({ ...person, uuid: visitorId });
        }
      }
      const connectedPeople = connectedArray.sort(function (a, b) {
        return (
          new Date(b.user.presentFrom).getTime() -
          new Date(a.user.presentFrom).getTime()
        );
      });
      const disconnectedPeople = disconnectedArray.sort(function (a, b) {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
      });
      waitingUsers = connectedPeople.concat(disconnectedPeople);
      return waitingUsers;
    },
    getconnectedWaitingrooms() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect?.called || {})
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    getRoute() {
      return this.$route.path;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.ctrr {
  top: 58px;
  left: 15px;
}
.waitingRoomBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  padding: 2px 6px !important;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
  font-style: normal;
}
.btnAddGroup {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
.cursorPointer {
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-list-group__header__append-icon .v-icon {
  margin-left: 36px !important;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
</style>
