var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "", "hide-overlay": "", persistent: "" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("generics.hotkeys"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              { staticClass: "fullHeigth d-flex justify-center align-center" },
              [
                _c(
                  "div",
                  { class: _vm.isMobile ? "containerScroll" : "" },
                  [
                    _c(
                      "v-list",
                      [
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + a: " +
                              _vm._s(_vm.$t("components.hotkeys.myStatus")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + b: " +
                              _vm._s(_vm.$t("status.Coffee break")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + f: " +
                              _vm._s(_vm.$t("components.hotkeys.favorites")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + k: " +
                              _vm._s(
                                _vm.$t("components.hotkeys.conferenceCreation")
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + m: " +
                              _vm._s(
                                _vm.$t("components.hotkeys.settingsMenu")
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + p: " +
                              _vm._s(_vm.$t("components.hotkeys.userInfo")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + q: " +
                              _vm._s(_vm.$t("components.hotkeys.quickBar")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + r: " +
                              _vm._s(_vm.$t("components.hotkeys.waitingRoom")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + s: " +
                              _vm._s(
                                _vm.$t("components.hotkeys.sidebarSearch")
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.controlKey")) +
                              " + enter: " +
                              _vm._s(
                                _vm.$t("components.hotkeys.removeTimeFromCalls")
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.space")) +
                              ": " +
                              _vm._s(
                                _vm.$t(
                                  "components.hotkeys.acceptAnIncomingCall"
                                )
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.doublespace")) +
                              ": " +
                              _vm._s(_vm.$t("components.hotkeys.hangUp")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.doubleclick")) +
                              ": " +
                              _vm._s(_vm.$t("components.hotkeys.toCall")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-list-item", { staticClass: "mx-3" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("components.hotkeys.enter")) +
                              " + u: " +
                              _vm._s(_vm.$t("components.hotkeys.changeView")) +
                              "\n            "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }