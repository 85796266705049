var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "630" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.titleModal,
                  closeModalFunction: _vm.closeModal
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "w-100 mx-0 my-0 px-3 pt-3" },
                _vm._l(2, function(n) {
                  return _c(
                    "v-col",
                    {
                      key: n,
                      staticClass: "my-0 py-0 px-0",
                      class: { "text-center": _vm.isMobile && n == 1 },
                      attrs: {
                        lg: _vm.cols[n - 1],
                        md: _vm.cols[n - 1],
                        sm: _vm.cols[n - 1],
                        xl: _vm.cols[n - 1]
                      }
                    },
                    [
                      n == 1
                        ? _c(
                            "div",
                            { staticClass: "pl-3" },
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "borderRadius4",
                                  style: _vm.getBorderByStatusFull(
                                    _vm.state.group[
                                      _vm.callUserInCallModalStore.uuid
                                    ]
                                  ),
                                  attrs: { tile: "", size: "150" }
                                },
                                [
                                  _vm.getAvatarForUuid(
                                    _vm.callUserInCallModalStore.uuid
                                  ) !== "img/default_profile_picture.png"
                                    ? _c("v-img", {
                                        staticClass: "borderRadius4",
                                        attrs: {
                                          "max-height": "150",
                                          "max-width": "150",
                                          contain: "",
                                          src: _vm.getAvatarForUuid(
                                            _vm.callUserInCallModalStore.uuid
                                          ),
                                          "lazy-src":
                                            "img/default_profile_picture.png"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.getAvatarForUuid(
                                    _vm.callUserInCallModalStore.uuid
                                  ) === "img/default_profile_picture.png"
                                    ? _c("DefaultAvatar", {
                                        attrs: {
                                          size: 120,
                                          userData:
                                            _vm.state.group[
                                              _vm.callUserInCallModalStore.uuid
                                            ].user
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      n == 2
                        ? _c(
                            "div",
                            { staticClass: "pt-3 pr-3" },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("generics.inConversation"))
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.getUsersInCall, function(uuid, index) {
                                return _c("span", { key: uuid }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.titelPerson(uuid)) +
                                      " " +
                                      _vm._s(_vm.getNameForUuid(uuid)) +
                                      _vm._s(
                                        _vm.getUsersInCall.length - 1 == index
                                          ? ""
                                          : ","
                                      ) +
                                      "\n            "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "FooterModal",
                { attrs: { closeModalFunction: _vm.closeModal } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.sendMessage(
                            _vm.callUserInCallModalStore.uuid
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("components.userListItem.callBackTT"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.callUser()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("generics.call")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-3",
                                    staticStyle: {
                                      "border-radius": "4px",
                                      "min-width": "45px"
                                    },
                                    attrs: {
                                      outlined: "",
                                      icon: "",
                                      color: "primary",
                                      tile: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setShowModalSenMsg(
                                          _vm.callUserInCallModalStore.uuid,
                                          "message"
                                        )
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "paper-plane"] }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.userProfile.sendAMessage"))
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }