var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "550" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.uploadAvatarModalData.showModal,
        callback: function($$v) {
          _vm.$set(_vm.uploadAvatarModalData, "showModal", $$v)
        },
        expression: "uploadAvatarModalData.showModal"
      }
    },
    [
      _c("HeaderModal", {
        attrs: {
          titleModal: _vm.$t("components.userManagement.changePicture"),
          closeModalFunction: _vm.closeModal
        }
      }),
      _vm._v(" "),
      _c("v-card-text", [
        _c(
          "div",
          {
            staticClass: "d-flex justify-center",
            staticStyle: { position: "relative" }
          },
          [
            _c(
              "v-row",
              { staticClass: "d-flex mx-auto pt-5 pb-2 pl-3" },
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12" } },
                  [
                    _c("vue-croppie", {
                      ref: "croppieRefSquare",
                      staticClass: "sliderSize",
                      attrs: {
                        enableResize: false,
                        enforceBoundary: true,
                        enableExif: true,
                        boundary: { width: 256, height: 256 },
                        viewport: { width: 246, height: 246, type: "square" }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "rotateIcons" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", color: "grey" },
                                      on: {
                                        click: function($event) {
                                          return _vm.rotate(-90)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "undo"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("components.navbar.rotateLeft")))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", color: "grey" },
                                      on: {
                                        click: function($event) {
                                          return _vm.rotate(90)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "fa-flip-horizontal",
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "undo"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("components.navbar.rotateRight"))
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "FooterModal",
        { attrs: { closeModalFunction: _vm.closeModal, showFooter: true } },
        [
          _c(
            "v-btn",
            {
              staticClass: "buttonPrimaryFooter",
              attrs: { color: "white" },
              on: {
                click: function($event) {
                  return _vm.crop()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("generics.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }