<template>
  <div v-if="user">
    <v-card
      :style="`height: 100%, position: relative , ${getBorderByStatusFull(getPerson(user.uuid))}`"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-img
              :src="getAvatarByType(user.uuid)"
              class="white--text align-end"
              height="100%"
              width="100%"
            >
              <div class="userType">
                {{ user.isModerator ? $t("components.conferenceForm.moderator") : user.isGuest ? $t("components.conferenceForm.guest") : $t("components.conferenceForm.user") }}
              </div>
              <div class="gradientBg">
                <v-card-subtitle
                  style="color: #fff !important"
                  class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate"
                >
                   {{ titelPerson(getPerson(user.uuid)) }} {{ getName(user) }}
                </v-card-subtitle>
                <div class="frontButtons">
                  <v-menu offset-y scrollable :close-on-click="true" v-if="user.uuid !== groupModerator">
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" style="float: right;" v-if="user.uuid !== groupModerator" class="buttonCallFrontCard">
                        <font-awesome-icon
                          :icon="['fal', 'ellipsis-v']"
                          :color="'white'"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                      </template>
                      <v-list>

                        <v-list-item v-if="!hasPrivilege(user.uuid)" @click="resendInvite(user.uuid)" :disabled="!isEditingGroup">
                          <v-list-item-title>{{ $t("components.inviteVisitor.resendInvLink")}}</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="checkSendMessage(user.uuid)" :disabled="!isEditingGroup && user.isGuest">
                          <v-list-item-title>{{ $t("components.userProfile.sendAMessage")}}</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="preDeleteUser($event,user)">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-list-item-title v-on="on" :class="!deleteActive ? 'deleteBasketButtonNormal' :'deleteBasketButtonActive'">{{ $t("components.conferenceForm.delete")}}</v-list-item-title>
                            </template>
                            <span>{{
                              $t("components.conferenceForm.confirmRemove")
                            }}</span>
                          </v-tooltip>
                        </v-list-item>

                      </v-list>
                  </v-menu>
                </div>
              </div>
              <ButtonsCardWrapper
                :person="getPerson(user.uuid)"
                :isRegularGrid="true"
              />
            </v-img>
          </div>
        </template>
        <UserInfoTooltip :person="getPerson(user.uuid)"></UserInfoTooltip>
      </v-tooltip>
    </v-card>

  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { sendInviteMailCustom } from "../../lib/wsMsg";
import { sendVisitorEmail } from "../../lib/inviteHelpers";
import { dispatchSuccessAlert } from '../../effector/alerts';
import { getAvatarByType, getBorderByStatusFull } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";
import { setSendMessageModalEvent } from "../../effector/modals"
import UserInfoTooltip from "./userInfoTooltip.vue";
import ButtonsCardWrapper from "./buttonsCardWrapper.vue";
export default {
  components: {
    ButtonsCardWrapper,
    UserInfoTooltip
  },
  props: ["user", "groupModerator", "isEditingGroup", "deleteUser","toggleModerator","isConferenceUser"],
  data() {
    return {
      state: store.state,
      deleteActive: false
    }
  },
  computed: {
    isEligible(){
      if (!this.user) return false;

      if (
        this.isConferenceUser &&
        !this.user.isGuest &&
        !this.user.isTeam &&
        !this.user.isGroup &&
        this.user.uuid !== this.groupModerator) {
          return true;
        } else {
          return false;
        }
    },
  },
  methods: {
    getPerson(uuid){
      return store.state.group[uuid];
    },
    titelPerson(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
     preDeleteUser(event, user){
      event.stopPropagation();
      event.preventDefault();
       try{

        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false;
          },1500)
        } else {
          this.deleteActive = false;
          this.deleteUser(user)
        }
      }catch(err){
        this.deleteActive = false;
        console.warn('Error deleting member', err)
      }
    },
    getName(user){
      if ( !user) return '';
      if (user.isGroup){
          return this.$t('components.groups.agroup') + ': ' + user.name;
      } else if (user.isTeam){
          return this.$t('components.modalNote.team') + ': ' + user.name;
      } else {
          return store.getNameForUuid(user.uuid) || '';
      }
    },
    async resendInvite(uuid){
      if (((this.state.group[uuid] || {}).user || {}).visitor) {
        const result = await sendVisitorEmail(this.state.group[uuid].user.visitorData);
        if (result) {
          dispatchSuccessAlert(
            this.$t("components.inviteVisitor.invitationLinkSent")
          );
        }
      } else {
        this.setShowModalSendInvitation(this.state.group[uuid].user);
      }
    },
    async setShowModalSendInvitation(person) {
      /* const result = */ await sendInviteMailCustom(person.visitorId, person);
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.name,
          email: person.emailAlias || person.email,
          date: new Date(),
        },
      };
      dispatchSuccessAlert(
        this.$t("components.inviteVisitor.invitationLinkSent")
      );
    },
    checkSendMessage(uuid) {
      if (uuid === this.ownUUID) {
        // I'm the user, send message to others managers and users (#178)
        this.showSendMessageToAll = true;
      } else {
        this.setShowModalSenMsg(uuid);
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
     setShowModalSenMsg(uuid) {
      setSendMessageModalEvent({show:true, users: [uuid], type: 'regularMsg'})
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    getNameForUuid(uuid){
      return store.getNameForUuid(uuid);
    },
  }
}
</script>
<style scoped lang="scss">
.buttonCallFrontCard {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.userType {
  position: absolute;
  left: 1px;
  top: 2px;
  padding: 2px 5px;
  font-size: 14px;
  color: white;
}
.frontButtons {
  position: absolute;
  right: 1px;
  bottom: 6px;
  padding: 0px;
  border-radius: 2px;
}
.customFontSize {
  font-size: 13px !important;
  word-break: break-word;
  max-width: 85%;
}
.colorWhite {
  color: white !important;
}
.theme--light .gradientBg, .theme--light .userType {
  background: linear-gradient(#00000040, #00000099);
}
.theme--dark .gradientBg, .theme--dark .userType {
  background: linear-gradient(#00000099, #000000cc);
}

.gradientBg {
  position: relative;
}
.deleteBasketButtonNormal {
  color: #201f1f !important;
}
.deleteBasketButtonActive {
  color: red !important;
  font-weight: 600 !important;
}
.userAvatar {
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
}
.userName {
  vertical-align: middle;
  display: inline-block;
  max-width: 50%;
}
</style>