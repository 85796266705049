var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "absolute",
      class: {
        "d-none":
          _vm.state.currentContentVisile.showComponent ||
          Object.keys(_vm.state.remoteStreams).length === 0
      },
      staticStyle: { "z-index": "999" },
      attrs: { id: "video-container" }
    },
    [
      !_vm.infiniteCall
        ? _c("div", { staticClass: "progressBar" }, [_c("ProgressLinear")], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.isLocalAudioMuted || _vm.amISoundMuted
        ? _c(
            "div",
            { staticClass: "messageToParticipants messageToParticipantsBlink" },
            [
              _vm.isLocalAudioMuted
                ? _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("components.callsContent.audioMessage")) +
                        "\n              "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.amISoundMuted
                ? _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("components.callsContent.soundMessage")) +
                        "\n              "
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.state.localStreams.display &&
        Object.keys(_vm.state.remoteStreams).length === 4) ||
      (_vm.state.presentationView.owner !== _vm.state.ownUUID &&
        (_vm.state.remoteStreams[_vm.state.presentationView.owner] || {})
          .second &&
        Object.keys(_vm.state.remoteStreams).length === 4) ||
      _vm.remoteFullSizeJustVideoLeftSide
        ? _c(
            "div",
            {
              staticClass: "mainDivRemote",
              class: "" + (!_vm.infiniteCall ? " heightTop" : ""),
              style: {
                backgroundImage: "url(" + _vm.backgroundPresentation + ")"
              }
            },
            [
              _vm.state.localStreams.display &&
              Object.keys(_vm.state.remoteStreams).length === 4
                ? _c("VideoSharingScreen")
                : _vm._e(),
              _vm._v(" "),
              _vm.state.presentationView.owner !== _vm.state.ownUUID &&
              (_vm.state.remoteStreams[_vm.state.presentationView.owner] || {})
                .second &&
              Object.keys(_vm.state.remoteStreams).length === 4
                ? _c("RemotePersonJustVideo", {
                    attrs: {
                      uuid: _vm.state.presentationView.owner,
                      remote:
                        _vm.state.remoteStreams[
                          _vm.state.presentationView.owner
                        ],
                      isLocalSoundMuted: _vm.isLocalSoundMuted
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.remoteFullSizeJustVideoLeftSide
                ? _c("RemotePersonJustVideo", {
                    attrs: {
                      setFullSizeVideo: _vm.setFullSizeVideo,
                      uuid: _vm.state.remoteToFullSizeUUID,
                      remote:
                        _vm.state.remoteStreams[_vm.state.remoteToFullSizeUUID],
                      isLocalSoundMuted: _vm.isLocalSoundMuted
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "videos",
          staticClass: "mainDivCall default-theme",
          class: {
            "mt-6": !_vm.infiniteCall,
            mainDivCall2Columns:
              (_vm.state.localStreams.display &&
                Object.keys(_vm.state.remoteStreams).length === 4) ||
              (_vm.state.presentationView.owner !== _vm.state.ownUUID &&
                (
                  _vm.state.remoteStreams[_vm.state.presentationView.owner] ||
                  {}
                ).second &&
                Object.keys(_vm.state.remoteStreams).length === 4)
          }
        },
        [
          (_vm.state.ownUUID === _vm.state.presentationView.owner &&
            !_vm.isRemoteFullSize) ||
          (_vm.isRemoteFullSize &&
            _vm.state.remoteToFullSizeUUID == _vm.state.ownUUID)
            ? _c(
                "div",
                { ref: "localVideoDiv", staticClass: "relative localVideo" },
                [
                  _c(
                    "div",
                    { staticClass: "callerName" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.isVisitor(_vm.state.ownUUID)
                              ? _vm.$t("components.userSettings.guest") + " - "
                              : ""
                          ) +
                            " " +
                            _vm._s(_vm.getTitelForUuid(_vm.state.ownUUID)) +
                            " " +
                            _vm._s(_vm.getNameForUuid(_vm.state.ownUUID)) +
                            "  "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass: "italicFont",
                                          staticStyle: {
                                            float: "right",
                                            "margin-right": "9px"
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v(" P2P")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1525856356
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.remotePersonCall.p2p"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.state.ownUUID === _vm.ownerUUID
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-left": "9px"
                              }
                            },
                            [_vm._v("Moderator")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.ownUUID !== _vm.ownerUUID &&
                      !_vm.isWaitingRoomUser(_vm.state.ownUUID) &&
                        !_vm.isVisitor(_vm.state.ownUUID)
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                float: "left",
                                                "margin-left": "9px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "font-awesome-icon",
                                              _vm._g(
                                                {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "user-crown"]
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                477651809
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.remotePersonCall.user")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.ownUUID !== _vm.ownerUUID &&
                      (_vm.isWaitingRoomUser(_vm.state.ownUUID) ||
                        _vm.isVisitor(_vm.state.ownUUID))
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                float: "left",
                                                "margin-left": "9px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "font-awesome-icon",
                                              _vm._g(
                                                {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: [
                                                      "fal",
                                                      "user-friends"
                                                    ]
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1591339111
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.remotePersonCall.guest")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("AudioStreamMeter", {
                        attrs: {
                          canvasId: "canvasAudioCallsContentPresentation",
                          stream: _vm.state.localStreams.user
                        }
                      }),
                      _vm._v(" "),
                      _c("AudioStreamMeter", {
                        attrs: {
                          canvasId: "canvasAudioCallsContentPresentation",
                          stream: _vm.state.localStreams.display
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.state.localStreams.display &&
                  !this.state.persisted.useBlurEffect
                    ? _c("video", {
                        class: { "d-none": !_vm.haveLocalVideo },
                        attrs: {
                          id: "local-video",
                          playsinline: "",
                          autoplay: "autoplay",
                          muted: ""
                        },
                        domProps: {
                          srcObject: _vm.state.localStreams.user,
                          muted: true
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.state.localStreams.display &&
                  this.state.persisted.useBlurEffect
                    ? _c("StreamCanvas", {
                        class: { "d-none": !_vm.haveLocalVideo },
                        attrs: {
                          id: "local-video",
                          stream: _vm.state.localStreams.user
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("video", {
                    class: { "d-none": !_vm.state.localStreams.display },
                    attrs: {
                      id: "local-screen",
                      playsinline: "",
                      autoplay: "autoplay",
                      muted: ""
                    },
                    domProps: {
                      srcObject: _vm.state.localStreams.display,
                      muted: true
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "noCameraStream",
                      class: { "d-none": _vm.haveLocalVideo }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "noCameraContent" },
                        [
                          _c("v-img", {
                            staticClass: "imageNotVideo",
                            attrs: {
                              contain: "",
                              src: _vm.getAvatarFromUuid(_vm.state.ownUUID),
                              "lazy-src": "assets/default_profile_picture.png"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "emptyDiv" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showTypingInfo.show
                    ? _c(
                        "div",
                        {
                          staticClass: "testMeSomehow",
                          class: { active: _vm.showTypingInfo.show }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.getNameForUuid(_vm.showTypingInfo.from)
                              ) +
                              " " +
                              _vm._s(
                                _vm.$t("components.callsContent.isTyping")
                              ) +
                              " ...\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "buttonsUser",
                      class: {
                        hidden:
                          Object.keys(_vm.state.remoteStreams).length == 0,
                        buttonsIpad: _vm.isIpad && _vm.isIos
                      }
                    },
                    [
                      [
                        _vm.openCameraQuality
                          ? _c(
                              "v-list",
                              { staticClass: "pt-0 cameraQuality" },
                              [
                                _c(
                                  "v-list-item-group",
                                  { attrs: { color: "primary" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-end blueBackground"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                _vm.openCameraQuality = false
                                              }
                                            }
                                          },
                                          [
                                            _vm.state.persisted.rtcVideo
                                              ? _c("font-awesome-icon", {
                                                  style: { fontSize: "15px" },
                                                  attrs: {
                                                    icon: ["fal", "times"],
                                                    color: "white"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.setQuality("high")
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.camAndMic.high"
                                                  )
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.setQuality("medium")
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.camAndMic.medium"
                                                  )
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.setQuality("low")
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.camAndMic.low"
                                                  )
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          directives: [
                                            {
                                              name: "long-press",
                                              rawName: "v-long-press",
                                              value: 300,
                                              expression: "300"
                                            }
                                          ],
                                          staticClass: "mx-3",
                                          attrs: { color: "white", icon: "" },
                                          on: {
                                            "long-press-start":
                                              _vm.openMenuQuality,
                                            "long-press-stop":
                                              _vm.onLongPressStop,
                                            click: function($event) {
                                              return _vm.tryWebcam()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm.state.persisted.rtcVideo
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "video"] }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.state.persisted.rtcVideo
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "video-slash"]
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4022386495
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.camera")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            color: "white",
                                            icon: "",
                                            disabled: _vm.amIMutedByAdmin
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleMuteLocalAudio()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        !_vm.isLocalAudioMuted
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "microphone"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isLocalAudioMuted
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: [
                                                  "fal",
                                                  "microphone-slash"
                                                ]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("canvas", {
                                          class: {
                                            hidden: _vm.isLocalAudioMuted
                                          },
                                          attrs: {
                                            id:
                                              "canvasAudioCallsContentPresentation",
                                            width: "50",
                                            height: "15"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3857259276
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.callsContent.mic"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: { color: "white", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleMuteSound()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        !_vm.isLocalSoundMuted
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "volume-up"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isLocalSoundMuted
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "volume-slash"]
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1344696976
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.callsContent.loudspeaker")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          ref: "cancelCall",
                                          staticClass: "redBtn mx-3",
                                          attrs: { color: "white", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.rtcHangup()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "faPhoneRotate",
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "phone"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1741010579
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.hangUp")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: { color: "white", icon: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.state.localStreams.display
                                                ? _vm.closeDisplayMedia()
                                                : _vm.tryScreenShare()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "desktop"] }
                                        }),
                                        _vm._v(" "),
                                        !_vm.state.localStreams.display
                                          ? _c("font-awesome-icon", {
                                              style: {
                                                fontSize: "20px",
                                                position: "absolute",
                                                top: -1,
                                                left: 5
                                              },
                                              attrs: { icon: ["fal", "slash"] }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2760596537
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.callsContent.shareScreen")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.state.ownUUID === _vm.state.presentationView.owner
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "white",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.hasOnHold
                                                    ? _vm.unholdCall()
                                                    : _vm.holdCall()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              class: {
                                                progressBarBlink: _vm.hasOnHold
                                              },
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "long-arrow-up"],
                                                color: _vm.hasOnHold
                                                  ? "red"
                                                  : "white"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              staticClass: "rotatedArrow",
                                              class: {
                                                progressBarBlink: _vm.hasOnHold
                                              },
                                              attrs: {
                                                color: _vm.hasOnHold
                                                  ? "red"
                                                  : "white",
                                                icon: ["fal", "long-arrow-up"]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4267318775
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.userListItem.forwarding")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: { color: "white", icon: "" },
                                          on: { click: _vm.toggleModalSettings }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "cog"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3310042900
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.navbar.camAndMic"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.state.ownUUID === _vm.state.presentationView.owner
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "white",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.backToGridView()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "th-large"]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4160892908
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.callsContent.endPresentationMode"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.ownUUID === _vm.state.presentationView.owner
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                icon: "",
                                                color: "white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.setSendMessageInCallModal.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "paper-plane"]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3362231525
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.bridgeCallTooltips.messageAll"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.ownerMeeting &&
                      Object.keys(_vm.state.remoteStreams).length > 1
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "white",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleMuteGlobalAudio()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "user-friends"]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1261905822
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.remotePersonCall.muteAll"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.state.ownUUID !== _vm.state.presentationView.owner &&
          !_vm.isRemoteFullSize
            ? _c(
                "div",
                {
                  ref: "localVideoDiv",
                  staticClass: "relative localVideo TestMeeeee"
                },
                [
                  _vm.hasOnHold
                    ? _c(
                        "div",
                        { staticClass: "onHoldOverlay" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "onHoldCenterIcon",
                            style: { fontSize: "45px" },
                            attrs: { icon: ["fal", "phone-plus"] }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "messageToParticipants messageToParticipantsBlink"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.remotePersonCall.onHoldMessage"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "callerName" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.isVisitor(_vm.state.presentationView.owner)
                              ? _vm.$t("components.userSettings.guest") + " - "
                              : ""
                          ) +
                            " " +
                            _vm._s(
                              _vm.getTitelForUuid(
                                _vm.state.presentationView.owner
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm.getNameForUuid(
                                _vm.state.presentationView.owner
                              )
                            ) +
                            " "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass: "italicFont",
                                          staticStyle: {
                                            float: "right",
                                            "margin-right": "9px"
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v(" P2P")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1525856356
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.remotePersonCall.p2p"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.state.presentationView.owner === _vm.ownerUUID
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-left": "9px"
                              }
                            },
                            [_vm._v("Moderator")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.presentationView.owner !== _vm.ownerUUID &&
                      !_vm.isWaitingRoomUser(
                        _vm.state.presentationView.owner
                      ) &&
                        !_vm.isVisitor(_vm.state.presentationView.owner)
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                float: "left",
                                                "margin-left": "9px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "font-awesome-icon",
                                              _vm._g(
                                                {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "user-crown"]
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                477651809
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.remotePersonCall.user")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.presentationView.owner !== _vm.ownerUUID &&
                      (_vm.isWaitingRoomUser(
                        _vm.state.presentationView.owner
                      ) ||
                        _vm.isVisitor(_vm.state.presentationView.owner))
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                float: "left",
                                                "margin-left": "9px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "font-awesome-icon",
                                              _vm._g(
                                                {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: [
                                                      "fal",
                                                      "user-friends"
                                                    ]
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1591339111
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.remotePersonCall.guest")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("WebCamDragable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showWebCamDrag &&
                          (
                            _vm.state.remoteStreams[
                              _vm.state.presentationView.owner
                            ] || {}
                          ).second,
                        expression:
                          "showWebCamDrag && ((state.remoteStreams[state.presentationView.owner] || {}).second)"
                      }
                    ],
                    attrs: { userSrc: _vm.state.presentationView.owner }
                  }),
                  _vm._v(" "),
                  _vm.state.remoteStreams[_vm.state.presentationView.owner] &&
                  !(
                    _vm.state.remoteStreams[_vm.state.presentationView.owner] ||
                    {}
                  ).second
                    ? _c("video", {
                        staticClass: "local-video",
                        class: {
                          hidden:
                            !_vm.state.remoteStreams[
                              _vm.state.presentationView.owner
                            ].first || !_vm.haveRemoteFirstVideo
                        },
                        attrs: { id: "local-video", autoplay: "autoplay" },
                        domProps: {
                          srcObject:
                            _vm.state.remoteStreams[
                              _vm.state.presentationView.owner
                            ].first,
                          muted: _vm.isLocalSoundMuted
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("WebCamDragable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showWebCamDrag &&
                          (
                            _vm.state.remoteStreams[
                              _vm.state.presentationView.owner
                            ] || {}
                          ).second,
                        expression:
                          "showWebCamDrag && ((state.remoteStreams[state.presentationView.owner] || {}).second)"
                      }
                    ],
                    attrs: { userSrc: _vm.state.ownUUID, ownVideo: true }
                  }),
                  _vm._v(" "),
                  _vm.state.remoteStreams[_vm.state.presentationView.owner]
                    ? _c("video", {
                        class: {
                          hidden: !_vm.state.remoteStreams[
                            _vm.state.presentationView.owner
                          ].second
                        },
                        attrs: {
                          id: "local-screen",
                          playsinline: "",
                          autoplay: "autoplay"
                        },
                        domProps: {
                          srcObject:
                            _vm.state.remoteStreams[
                              _vm.state.presentationView.owner
                            ].second,
                          muted: _vm.isLocalSoundMuted
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "noCameraStream",
                      class: { "d-none": _vm.haveLocalVideo }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "noCameraContent" },
                        [
                          _c("v-img", {
                            staticClass: "imageNotVideo",
                            attrs: {
                              contain: "",
                              src: _vm.getAvatarFromUuid(
                                _vm.state.presentationView.owner
                              ),
                              "lazy-src": "assets/default_profile_picture.png"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "emptyDiv" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "buttonsUser",
                      class: {
                        hidden: Object.keys(_vm.state.remoteStreams).length == 0
                      }
                    },
                    [
                      Object.keys(_vm.state.remoteStreams).length > 1 &&
                      _vm.state.ownerMeeting &&
                      _vm.state.ownUUID === _vm.state.presentationView.owner
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "white",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm
                                                    .getWebrtcConnections()
                                                    [_vm.uuid].die(true)
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "faPhoneRotate",
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "phone"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3478738157
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.hangUp")))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: { icon: "", color: "white" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setShowModalSenMsg(
                                                _vm.state.presentationView.owner
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "paper-plane"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3094064782
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.userProfile.sendAMessage")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            icon: "",
                                            color: "white",
                                            target: "_blank",
                                            href:
                                              "mailto:" +
                                              _vm.getEmailForUuid(
                                                _vm.state.presentationView.owner
                                              ) +
                                              "?subject=" +
                                              _vm.state.user.name +
                                              "%20from%20vOffice&body=" +
                                              _vm.emailSalutation +
                                              "%20" +
                                              _vm.getNameForUuid(
                                                _vm.state.presentationView.owner
                                              ) +
                                              ","
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "24px" },
                                          attrs: {
                                            icon: ["fal", "envelope-square"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            141733775
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.userListItem.sendEMail")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            icon: "",
                                            color: "white",
                                            target: "_blank"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.fullSreen($event)
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        !_vm.fullScreen
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "24px" },
                                              attrs: {
                                                icon: ["fal", "expand-wide"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.fullScreen
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fas", "compress"]
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2928340679
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.fullscreen")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.state.remoteToFullSizeUUID !== _vm.state.ownUUID &&
          _vm.isRemoteFullSize
            ? _c(
                "div",
                { ref: "localVideoDiv", staticClass: "relative localVideo" },
                [
                  _c(
                    "div",
                    { staticClass: "callerName" },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isVisitor(_vm.state.remoteToFullSizeUUID)
                                ? _vm.$t("components.userSettings.guest") +
                                    " - "
                                : ""
                            ) +
                            " " +
                            _vm._s(
                              _vm.getTitelForUuid(
                                _vm.state.remoteToFullSizeUUID
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm.getNameForUuid(_vm.state.remoteToFullSizeUUID)
                            ) +
                            "  "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass: "italicFont",
                                          staticStyle: {
                                            float: "right",
                                            "margin-right": "9px"
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v(" P2P")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1525856356
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.remotePersonCall.p2p"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.state.remoteToFullSizeUUID === _vm.ownerUUID
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-left": "9px"
                              }
                            },
                            [_vm._v("Moderator")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.remoteToFullSizeUUID !== _vm.ownerUUID &&
                      !_vm.isWaitingRoomUser(_vm.state.remoteToFullSizeUUID) &&
                        !_vm.isVisitor(_vm.state.remoteToFullSizeUUID)
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                float: "left",
                                                "margin-left": "9px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "font-awesome-icon",
                                              _vm._g(
                                                {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "user-crown"]
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                477651809
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.remotePersonCall.user")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.remoteToFullSizeUUID !== _vm.ownerUUID &&
                      (_vm.isWaitingRoomUser(_vm.state.remoteToFullSizeUUID) ||
                        _vm.isVisitor(_vm.state.remoteToFullSizeUUID))
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                float: "left",
                                                "margin-left": "9px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "font-awesome-icon",
                                              _vm._g(
                                                {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: [
                                                      "fal",
                                                      "user-friends"
                                                    ]
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1591339111
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.remotePersonCall.guest")
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("WebCamDragable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showWebCamDrag &&
                          (
                            _vm.state.remoteStreams[
                              _vm.state.remoteToFullSizeUUID
                            ] || {}
                          ).second,
                        expression:
                          "showWebCamDrag && ((state.remoteStreams[state.remoteToFullSizeUUID] || {}).second)"
                      }
                    ],
                    attrs: { userSrc: _vm.state.remoteToFullSizeUUID }
                  }),
                  _vm._v(" "),
                  _vm.state.remoteStreams[_vm.state.remoteToFullSizeUUID] &&
                  !(
                    _vm.state.remoteStreams[_vm.state.remoteToFullSizeUUID] ||
                    {}
                  ).second
                    ? _c("video", {
                        staticClass: "local-video",
                        class: {
                          hidden:
                            !_vm.state.remoteStreams[
                              _vm.state.remoteToFullSizeUUID
                            ].first || !_vm.haveRemoteFullSizeFirstVideo
                        },
                        attrs: { id: "local-video", autoplay: "autoplay" },
                        domProps: {
                          srcObject:
                            _vm.state.remoteStreams[
                              _vm.state.remoteToFullSizeUUID
                            ].first,
                          muted: _vm.isLocalSoundMuted
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("WebCamDragable", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showWebCamDrag &&
                          (
                            _vm.state.remoteStreams[
                              _vm.state.remoteToFullSizeUUID
                            ] || {}
                          ).second,
                        expression:
                          "showWebCamDrag && ((state.remoteStreams[state.remoteToFullSizeUUID] || {}).second)"
                      }
                    ],
                    attrs: { userSrc: _vm.state.ownUUID, ownVideo: true }
                  }),
                  _vm._v(" "),
                  _vm.state.remoteStreams[_vm.state.remoteToFullSizeUUID]
                    ? _c("video", {
                        class: {
                          hidden: !_vm.state.remoteStreams[
                            _vm.state.remoteToFullSizeUUID
                          ].second
                        },
                        attrs: {
                          id: "local-screen",
                          playsinline: "",
                          autoplay: "autoplay"
                        },
                        domProps: {
                          srcObject:
                            _vm.state.remoteStreams[
                              _vm.state.remoteToFullSizeUUID
                            ].second,
                          muted: _vm.isLocalSoundMuted
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "noCameraStream",
                      class: { "d-none": _vm.haveLocalVideo }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "noCameraContent" },
                        [
                          _c("v-img", {
                            staticClass: "imageNotVideo",
                            attrs: {
                              contain: "",
                              src: _vm.getAvatarFromUuid(
                                _vm.state.remoteToFullSizeUUID
                              ),
                              "lazy-src": "assets/default_profile_picture.png"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "emptyDiv" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "buttonsUser",
                      class: {
                        hidden: Object.keys(_vm.state.remoteStreams).length == 0
                      },
                      attrs: { id: "secondaryButtonUser" }
                    },
                    [
                      Object.keys(_vm.state.remoteStreams).length > 1 &&
                      _vm.state.ownerMeeting
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "white",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm
                                                    .getWebrtcConnections()
                                                    [
                                                      _vm.state
                                                        .remoteToFullSizeUUID
                                                    ].die(true)
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "faPhoneRotate",
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "phone"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2816512125
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.hangUp")))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.ownUUID === _vm.ownerUUID
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "white",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.transferModerator(
                                                    _vm.state
                                                      .remoteToFullSizeUUID
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "users-crown"]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1121818201
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.remotePersonCall.transferModerator"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: { icon: "", color: "white" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setShowModalSenMsg(
                                                _vm.state.remoteToFullSizeUUID
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "paper-plane"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            830678110
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.userProfile.sendAMessage")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            icon: "",
                                            color: "white",
                                            target: "_blank",
                                            href:
                                              "mailto:" +
                                              _vm.getEmailForUuid(
                                                _vm.state.remoteToFullSizeUUID
                                              ) +
                                              "?subject=" +
                                              _vm.state.user.name +
                                              "%20from%20vOffice&body=" +
                                              _vm.emailSalutation +
                                              "%20" +
                                              _vm.getNameForUuid(
                                                _vm.state.remoteToFullSizeUUID
                                              ) +
                                              ","
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "24px" },
                                          attrs: {
                                            icon: ["fal", "envelope-square"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2623480463
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.userListItem.sendEMail")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            icon: "",
                                            color: "white",
                                            target: "_blank"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.fullSreen($event)
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        !_vm.fullScreen
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "24px" },
                                              attrs: {
                                                icon: ["fal", "expand-wide"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.fullScreen
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fas", "compress"]
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2928340679
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.fullscreen")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mainDivRemote",
          class: "" + (!_vm.infiniteCall ? " heightTop mt-6" : ""),
          style: { backgroundImage: "url(" + _vm.backgroundPresentation + ")" }
        },
        [
          (_vm.state.ownUUID !== _vm.state.presentationView.owner &&
            !_vm.isRemoteFullSize) ||
          (_vm.state.ownUUID !== _vm.state.remoteToFullSizeUUID &&
            _vm.isRemoteFullSize)
            ? _c(
                "div",
                { staticClass: "mb-7" },
                [
                  _c("MyLocalCall", {
                    attrs: { toggleModalSettings: _vm.toggleModalSettings }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.state.remoteStreams, function(remote, uuid, index) {
            return (uuid !== _vm.state.presentationView.owner &&
              !_vm.isRemoteFullSize) ||
              (uuid !== _vm.state.remoteToFullSizeUUID && _vm.isRemoteFullSize)
              ? _c(
                  "div",
                  { key: uuid },
                  [
                    _c("RemotePersonCallPresentation", {
                      attrs: {
                        uuid: uuid,
                        remote: remote,
                        isLocalSoundMuted: _vm.isLocalSoundMuted,
                        setFullSizeVideo: _vm.setFullSizeVideo,
                        index: index
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.state.presentationView.owner !== _vm.state.ownUUID &&
          (_vm.state.remoteStreams[_vm.state.presentationView.owner] || {})
            .second &&
          Object.keys(_vm.state.remoteStreams).length < 4
            ? _c("RemotePersonJustVideo", {
                attrs: {
                  setFullSizeVideo: _vm.setFullSizeVideo,
                  uuid: _vm.state.presentationView.owner,
                  remote:
                    _vm.state.remoteStreams[_vm.state.presentationView.owner],
                  isLocalSoundMuted: _vm.isLocalSoundMuted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.remoteFullSizeJustVideoRightSide
            ? _c("RemotePersonJustVideo", {
                attrs: {
                  setFullSizeVideo: _vm.setFullSizeVideo,
                  uuid: _vm.state.remoteToFullSizeUUID,
                  remote:
                    _vm.state.remoteStreams[_vm.state.remoteToFullSizeUUID],
                  isLocalSoundMuted: _vm.isLocalSoundMuted
                }
              })
            : _vm._e(),
          _vm._v(" "),
          (_vm.state.ownUUID == _vm.state.presentationView.owner &&
            _vm.state.localStreams.display &&
            Object.keys(_vm.state.remoteStreams).length < 4) ||
          (_vm.isRemoteFullSize &&
            _vm.state.ownUUID === _vm.state.remoteToFullSizeUUID &&
            _vm.state.localStreams.display &&
            Object.keys(_vm.state.remoteStreams).length < 4)
            ? _c("div", [_c("VideoSharingScreen")], 1)
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }