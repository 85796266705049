var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("components.drawerContentCard.files")) +
                      "\n        "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("DownloadsDropdown", { attrs: { customHeight: true } }),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns w-100 mx-0 my-0" },
                    [
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { icon: "", color: "white" },
                                          on: { click: _vm.closeModal }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "25px" },
                                          attrs: { icon: ["fal", "times"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.close")))
                            ])
                          ]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }