var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "550" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelChangeFunction.apply(null, arguments)
        }
      },
      model: {
        value: _vm.modalData.show,
        callback: function($$v) {
          _vm.$set(_vm.modalData, "show", $$v)
        },
        expression: "modalData.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.changeFunctionModal.editFunction"),
              closeModalFunction: _vm.cancelChangeFunction
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "body-2",
                attrs: { label: "", type: "text", maxlength: 20 },
                model: {
                  value: _vm.newFunction,
                  callback: function($$v) {
                    _vm.newFunction = $$v
                  },
                  expression: "newFunction"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            {
              attrs: {
                closeModalFunction: _vm.cancelChangeFunction,
                showCancel: true
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.setNewFunction(_vm.modalData.person)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }