var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c("v-text-field", {
        attrs: { placeholder: _vm.placeholder, rules: _vm.rules },
        model: {
          value: _vm.tempValue,
          callback: function($$v) {
            _vm.tempValue = $$v
          },
          expression: "tempValue"
        }
      }),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: { "margin-top": "6px" },
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("accept", _vm.tempValue)
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "check"] }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("components.manageOrganisation.acceptChanges"))
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: { "margin-top": "6px" },
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("discard")
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "times"] }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("components.manageOrganisation.discardChanges"))
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }