<template>
  <v-row class="px-4">
    <v-col cols="2" class="mt-1" v-if="!isMobile && getUsers.length">
      <v-list density="compact" class="h100 px-3 py-3">
        <!-- <v-list-item-title class="mb-3 primary--text">
          {{ $t("generics.users") }}
        </v-list-item-title> -->
        <span class="d-flex align-center mb-3">
          <v-text-field
            class="pt-0"
            style="width: 310px"
            variant="outlined"
            density="compact"
            :model-value="search" @update:model-value="search = $event"
            prepend-inner-icon="mdi-magnify"
            :label="$t('generics.search')"
            clearable
            @click.clear="cleanSearch()"
            single-line
            hide-details
          />
        </span>
        <v-list
          :model-value="selectedUser" @update:model-value="selectedUser = $event"
          color="primary"
          class="listWrapper"
        >
        <v-list-item
            :value="'all'"
            @click="selectedUser='all'"
            v-bind:class="{ 'v-list-item--active': selectedUser === 'all'}"
          >
          <div>
              <v-list-item-title v-text="$t('components.adminSettingsModal.showAll')" />
            </div>
        </v-list-item>
          <v-list-item
            v-for="user in filteredUsers"
            :key="user.uuid"
            :value="user.uuid"
            @click="selectedUser=user.uuid"
            v-bind:class="{ 'v-list-item--active': selectedUser === user.uuid }"
          >
            <div>
              <v-list-item-title v-text="`${user.titel} ${user.name}`" />
            </div>
          </v-list-item>
        </v-list>
      </v-list>
    </v-col>
    <v-col :cols="(getUsers.length) && !isMobile ? 10 : 12">
        <SupportCalendar :selectedUser="selectedUser" />
    </v-col>
  </v-row>
</template>

<script>
import store, { syncedGroupState, syncedUserState } from "../../store";
import SupportCalendar from "./supportCalendar.vue";
import { checkListSort } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";
import { isMobile } from "../../lib/mobileUtil";
export default {
  components: { SupportCalendar },
  data() {
    return {
      state: store.state,
      isMobile: isMobile(),
      loadedDataPromise: Promise.resolve(),
      selectedUser: "",
      search: "",
      setCurrentContentVisile: store.setCurrentContentVisile,
    };
  },
  created() {
    this.loadedDataPromise = Promise.all([
      // wait for state
      syncedGroupState(true),
      syncedUserState().catch(() => {}),
      // wait for computed
    ]).then(() => this.$nextTick());
  },
  mounted() {
    if (!this.state.namespaceSettings.showWorkingTime) {
      this.gotoPage('home')
    }
  },
  watch: {
    selectedUser: {
      immediate: true,
      handler(value) {
        this.loadedDataPromise.then(() => {
          if (this.selectedUser !== value) return;
          if (!this.isMobile && this.amIAdmin) {
            // admin first open: select first user or own user
            if (!value || value == '') {
              if (
                this.getUsers.length && this.filteredUsers.length &&
                !this.filteredUsers.find(e => e.uuid === this.state.ownUUID)
              ) {
                this.selectedUser = this.filteredUsers[0].uuid;
              } else {
                this.selectedUser = this.state.ownUUID;
              }
            }
          } else if (value !== this.state.ownUUID) {
            // prevent non-admin of seeing other's calendar
            this.selectedUser = this.state.ownUUID;
          }
        });
      },
    },
  },
  methods: {
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    cleanSearch() {
      this.search = "";
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    filteredUsers() {
      if (!this.search) return this.getUsers;
      return this.getUsers.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.titel.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getUsers() {
      let persons = [];
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (!person || !person.user || !person.user.name || !hasPrivilege(uuid) || !person.user.supportUser) continue;
        const temporalObject = {
          uuid,
          name: this.getNameForUuid(uuid),
          titel: this.getTitelForUuid(uuid),
          ...person
        }
        persons.push(temporalObject);
      }
      persons.sort((a, b) => a.name.localeCompare(b.name));
      return persons;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.listWrapper {
  overflow-y: auto;
  min-height: calc(100vh - 25vh);
  max-height: calc(100vh - 25vh);
  height: calc(100vh - 25vh);
}

.tableTitle {
  font-size: 1rem;
}
</style>