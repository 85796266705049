var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { flat: "", width: "100%" } },
    [
      _vm.searchFoundSomethingPerson && _vm.state.showSearch
        ? _c("UsersSearch", {
            attrs: {
              setList: _vm.setList,
              active: _vm.active,
              filterPerson: _vm.filterPerson,
              setCurrentContentVisile: _vm.setCurrentContentVisile,
              isGuest: _vm.isGuest,
              sortedPersons: _vm.sortedPersons,
              totalUnfilteredUsers: _vm.totalUnfilteredUsers,
              searchFoundSomethingPerson: _vm.searchFoundSomethingPerson,
              isMobile: _vm.isMobile
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }