var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        {
          staticClass: "w-100 mx-0 my-0 justify-space-between",
          attrs: { flex: "" }
        },
        [
          _c(
            "v-col",
            { staticClass: "px-0 py-0", attrs: { align: "left" } },
            [
              _vm.isVisitor(_vm.call.uuid)
                ? _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-medium pl-4 spanModalCall"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "components.incomingCall.incomingOneCall"
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-medium pl-4 spanModalCall"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "components.incomingCall.incommingConfCall"
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "w-100 mx-0 my-0 px-3 pt-3", attrs: { flex: "" } },
        [
          _vm._l(3, function(n) {
            return _c(
              "v-col",
              {
                key: n,
                staticClass: "my-0 py-0",
                attrs: {
                  lg: _vm.isMobile ? 12 : _vm.cols[n - 1],
                  md: _vm.isMobile ? 12 : _vm.cols[n - 1],
                  sm: _vm.isMobile ? 12 : _vm.cols[n - 1],
                  xl: _vm.isMobile ? 12 : _vm.cols[n - 1]
                }
              },
              [
                n == 1
                  ? _c(
                      "div",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-list-item-avatar",
                          {
                            staticClass: "borderRadius4",
                            attrs: { tile: "", size: "120" }
                          },
                          [
                            _c("v-img", {
                              staticClass: "borderRadius4",
                              attrs: {
                                "max-height": "120",
                                "max-width": "120",
                                contain: "",
                                src: _vm.getAvatarForUuid(_vm.call.uuid),
                                "lazy-src": "assets/default_profile_picture.png"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                n == 2
                  ? _c(
                      "div",
                      {
                        staticClass: "pt-3 pl-3",
                        class: { "text-center": _vm.isMobile }
                      },
                      [
                        _c("p", { staticClass: "font-weight-medium my-0" }, [
                          _vm._v(_vm._s(_vm.getNameForUuid(_vm.call.uuid)))
                        ]),
                        _vm._v(" "),
                        _vm.state.group[_vm.call.uuid].user.position
                          ? _c("p", { staticClass: "dataUserCard my-0" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.state.group[_vm.call.uuid].user.position
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state.group[_vm.call.uuid].user.sections &&
                        _vm.state.group[_vm.call.uuid].user.sections.length >
                          0 &&
                        _vm.state.group[_vm.call.uuid].user.locations &&
                        _vm.state.group[_vm.call.uuid].user.locations.length > 0
                          ? _c("p", { staticClass: "dataUserCard my-0" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.state.group[_vm.call.uuid].user
                                      .sections[0]
                                  ) +
                                  " |\n          " +
                                  _vm._s(
                                    _vm.state.group[_vm.call.uuid].user
                                      .locations[0]
                                  ) +
                                  "\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                n == 3
                  ? _c(
                      "div",
                      {
                        staticClass: "pb-2 pt-3 pl-3 text-right",
                        class: { "text-center": _vm.isMobile }
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            ref: "cancelCall",
                                            refInFor: true,
                                            staticClass: "mx-2 mb-2",
                                            attrs: {
                                              outlined: "",
                                              small: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.rejectCall(
                                                  _vm.call,
                                                  _vm.$t(
                                                    "components.incomingCall.declineText1",
                                                    _vm.state.group[
                                                      _vm.call.uuid
                                                    ].user.language
                                                  )
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.incomingCall.declineText1"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.incomingCall.declineText1")
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            ref: "cancelCall",
                                            refInFor: true,
                                            staticClass: "mx-2",
                                            attrs: {
                                              outlined: "",
                                              small: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.rejectCall(
                                                  _vm.call,
                                                  _vm.$t(
                                                    "components.incomingCall.declineText2",
                                                    _vm.state.group[
                                                      _vm.call.uuid
                                                    ].user.language
                                                  )
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.incomingCall.declineText2"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.incomingCall.declineText2")
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _vm.showMembersContent
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "p",
                  { staticClass: "dataUserCard my-0 conferenceMembers" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.getCallMembersName) + "\n      "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.call && _vm.call.dialGroup && _vm.call.dialGroup.length
            ? _c(
                "v-col",
                { staticClass: "px-2 w-100 mx-0 my-0", attrs: { cols: "12" } },
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("p", { staticClass: "pt-5" }, [
                    _vm._v(
                      _vm._s(_vm.$t("components.inviteVisitor.participants")) +
                        ":"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.call.dialGroup, function(uuid) {
                      return _c("li", { key: uuid }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getNameForUuid(uuid)) +
                            "\n        "
                        )
                      ])
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isVisitor(_vm.ownUUID)
            ? [
                _vm.state.persisted.tmpMessages.showInfoIncomingCallModal
                  ? _c(
                      "v-col",
                      {
                        staticClass: "px-2 w-100 mx-0 my-0",
                        class: { "text-center": _vm.isMobile },
                        attrs: { cols: "12" }
                      },
                      [
                        _c("v-divider"),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "pt-3" },
                          [
                            _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.incomingCall.answerSpaceB"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-checkbox", {
                              staticClass: "mt-0 white--text",
                              attrs: {
                                label: _vm.$t(
                                  "components.meetingTime.dontShowAgain"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.setShowInfoIncomingCallModal(false)
                                }
                              },
                              model: {
                                value: _vm.checkedMessage,
                                callback: function($$v) {
                                  _vm.checkedMessage = $$v
                                },
                                expression: "checkedMessage"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-divider")
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showCameraPreview
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("CallModalCameraPreview", {
                    attrs: { height: "100%", width: "100%" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isCallProtected
            ? [
                _c(
                  "v-col",
                  {
                    staticClass: "px-2 w-100 mx-0 my-0",
                    attrs: { cols: "12" }
                  },
                  [
                    _c("v-divider"),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-column pt-5" }, [
                      _c(
                        "div",
                        {
                          staticClass: "pr-4 font-weight-medium primary--text"
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.callsContent.protectedCall")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-50" },
                        [
                          _c("v-text-field", {
                            ref: "userPinInput",
                            staticClass: "whiteControl",
                            style: { width: 300, color: "white" },
                            attrs: {
                              autofocus: true,
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t(
                                "components.callsContent.insertPin"
                              ),
                              maxLength: 6
                            },
                            on: {
                              keyup: function($event) {
                                return _vm.checkIt($event)
                              }
                            },
                            model: {
                              value: _vm.userPin,
                              callback: function($$v) {
                                _vm.userPin = $$v
                              },
                              expression: "userPin"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "FooterModal",
        { attrs: { closeModalFunction: _vm.closeModal } },
        [
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              ref: "acceptCall",
                              staticClass:
                                "ml-3 buttonIconPrimaryFooter callButton px-2",
                              attrs: {
                                disabled: _vm.isCallProtected,
                                icon: "",
                                tile: "",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.resolveCall(_vm.call)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("generics.accept")) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.meetingTime.acceptCall")))
                ])
              ]
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }