var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showIcon
    ? _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "customDASD white--text",
                          on: {
                            click: function($event) {
                              return _vm.toggleDasdActivation()
                            }
                          }
                        },
                        on
                      ),
                      [
                        _c("v-img", {
                          staticClass: "mr-1",
                          attrs: {
                            src: _vm.dasdIconIsActive
                              ? "img/icons/dasd-green.svg"
                              : "img/icons/dasd-gr.svg",
                            height: "16",
                            width: "40",
                            contain: ""
                          }
                        }),
                        _vm._v(" "),
                        _c("v-img", {
                          attrs: {
                            src: _vm.dasdIconIsActive
                              ? "img/icons/symbol-green.svg"
                              : "img/icons/symbol-gray.svg",
                            height: "16",
                            width: "16",
                            contain: ""
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            false,
            1318094392
          )
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("components.externalServices.dasdTT1")) +
                " www.dasd.de\n    " +
                _vm._s(_vm.$t("components.externalServices.dasdTT2"))
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }