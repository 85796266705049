<template>
  <div>
    <v-dialog
      v-model="showModalDirectLink"
      persistent
      max-width="750"
      @keydown.esc="openCloseModalDirectLink"
    >
      <v-card>
        <div>
          <HeaderModal
            :titleModal="$t('components.drawerContentCard.directLink')"
            :closeModalFunction="openCloseModalDirectLink"
          />
          <DirectCallMask ref="directCallMaskRef" />
          <FooterModal
            :closeModalFunction="openCloseModalDirectLink"
            :showCancel="true"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  :disabled="
                    !$refs.directCallMaskRef.userListToSend ||
                    $refs.directCallMaskRef.userListToSend.length === 0
                  "
                  v-on="on"
                  @click="precheckNewVisitor"
                  :loading="$refs.directCallMaskRef.isProcessing"
                  >{{ $t("components.sendMessageModal.send") }}</v-btn
                >
              </template>
              <span>{{ $t("components.sendMessageModal.send") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  v-on="on"
                  @click="addUserToList()"
                  :disabled="
                    !$refs.directCallMaskRef.validData ||
                    $refs.directCallMaskRef.isProcessing
                  "
                  >{{ $t("generics.save") }}</v-btn
                >
              </template>
              <span>{{ $t("generics.save") }}</span>
            </v-tooltip>
          </FooterModal>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { dispatchSuccessAlert } from "../../effector/alerts";
import DirectCallMask from "../timeline/directCallMask.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModalDirectLink", "openCloseModalDirectLink"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  components: { DirectCallMask, HeaderModal, FooterModal },
  watch: {},
  methods: {
    async precheckNewVisitor() {
      this.$refs.directCallMaskRef.precheckNewVisitor();
      this.openCloseModalDirectLink();
      // toaster
      // dispatchSuccessAlert(
      //   Vue.prototype.$t("components.eventEntryForm.updatedSuccess")
      // );
    },
    async addUserToList() {
      this.$refs.directCallMaskRef.addUserToList();
    },
  },
};
</script>
<style scoped lang="scss">
.buttonAddUserToList {
  position: relative;
  top: 13px;
}
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
