<template>
  <v-app id="sandbox">
    <!--<v-app-bar :clipped-left="primaryDrawer.clipped" app>-->
      <!-- <v-app-bar-nav-icon   v-if="primaryDrawer.type !== 'permanent'"/> -->
    <!-- <v-img
        class="mx-2"
        src="assets/icon.png"
        max-height="45"
        max-width="45"
        contain
      ></v-img>
      <v-toolbar-title class="linkApp d-none d-md-flex font-weight-bold body-1"
        >vOffice</v-toolbar-title
      > -->
    <!--</v-app-bar>-->
    <v-content>
      <v-row class="fill-height">
        <v-col
          cols="6"
          class="primary backgroundImage hidden-sm-and-down"
          :md="6"
          :xl="6"
          v-if="!isMobile"
        >
          <div class="fill-half-width divContentText">
            <div class="logoLeftsideDiv">
              <v-img
                src="assets/icon.png"
                max-height="150"
                max-width="150"
                contain
              ></v-img>
            </div>
            <h2 class="font-weight-bold mb-2">{{ getUrl }}</h2>
            <p class="mb-0 customFontSize font-weight-medium">
              {{ $t("components.subdomainTaken.restrictedAccess") }}
            </p>
            <!--<p class="mb-0 customFontSize font-weight-medium">
              {{
                isFree
                  ? $t("components.companyRegister.domainIsFree")
                  : $t("components.subdomainTaken.namespaceNotFree")
              }}
            </p>-->
          </div>

          <div class="copyRightDiv">RA-MICRO Software AG © 2024 <span class="ml-2 "><a class="imprintLink" href='https://www.ra-micro.de/info/impressum.html' rel='nofollow noopener noreferrer' target='_blank'>{{ $t("literals.Imprint")}}</a></span></div>
        </v-col>

        <v-col :cols="isMobile ? 12 : 6" class="formRightSide" :md="6" :sm="12" :xs="12" :xl="6">
          <div class="contentRightSide">
            <!-- <div class="w-100"> -->
              <v-row flex class="w-100 mx-0 my-0 justify-space-between">
                <v-col class="px-0 py-0" align="left">
                  <v-card-title class="titleSize d-flex px-0">
                    <!-- <v-img
                      src="assets/icon.png"
                      max-height="30"
                      max-width="30"
                      contain
                    ></v-img> -->
                    <span
                      class="font-weight-medium"
                      v-html="$t('components.subdomainTaken.requestLink')"
                    ></span>
                  </v-card-title>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              
              <v-row class="pt-2">
                <v-col v-if="emailSent" cols="12" class="pb-0">
                  <p>{{ $t("components.subdomainTaken.mailSentCard") }}
                  </p>
                </v-col>
                <v-col v-if="emailSent" cols="12" class="">
                  <p v-if="!sendWhatsApp"
                  >{{ $t("components.subdomainTaken.mailSentCardSecondLine") }}
                  </p>
                  <p v-else
                  >{{ $t("components.subdomainTaken.whatsAppSentCardSecondLine") }}
                  </p>
                </v-col>

                <v-col v-if="!emailSent" cols="12" >
                  <p class="px-0">
                  {{ $t("components.subdomainTaken.vofficeUserEmailAddress") }} <span class="font-weight-medium">{{ getUrl }}</span>
                  </p>
                  <v-form class="mx-0" ref="form" v-model="valid" lazy-validation>
                    <v-row class="mx-0">
                      <v-col class="px-0 pb-0">
                        <v-text-field
                        dense
                        outlined
                        @keydown.enter.prevent
                        @keydown.space.prevent
                        v-model="email"
                        :label="$t('components.subdomainTaken.registeredEmail')"
                        required
                        :rules="emailRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    
                    <v-row>
                      <v-col col="12" class="pt-0">
                        <div class="mx-0">
                          <div  align="left">
                            <p v-if="showResult">{{
                              message
                              }}</p>
                              </div>
                              
                              <v-row class="mx-0">
                                <v-btn
                                depressed
                                :class="`requestButton mr-4 ${isMobile ? 'mb-2' : null}`"
                                :disabled="!valid || email.length == 0"
                                color="primary"
                                :loading="requestingAccess"
                                @click="requestAccess(true)"
                                >{{ $t("components.subdomainTaken.emailLinkSend") }}</v-btn
                                >
                                
                                <v-btn
                                v-if="isWhatsAppInviteAvailable"
                                depressed
                                class="requestButton"
                                :disabled="!valid || email.length == 0"
                                color="primary"
                                :loading="requestingAccess"
                                @click="requestAccess(false)"
                                >{{
                                  $t("components.subdomainTaken.whatsAppLinkSend")
                                  }}</v-btn>
                              </v-row>
                          </div>
                      </v-col>
                    </v-row>
           
                    
                    <!--<v-row class="mx-0">
                      <v-col class="col-12 px-0 py-2" align="left">
                        <v-card-text class="px-0" v-if="showResult">{{
                          message
                          }}</v-card-text>
                      </v-col>
                      
                      <v-col class="col-6 px-0 py-2 mx-auto">
                        <v-btn
                    class="requestButton"
                    :disabled="!valid"
                    color="primary"
                    :loading="requestingAccess"
                    @click="requestAccess(true)"
                    >{{ $t("components.subdomainTaken.emailLinkSend") }}</v-btn
                  >
                  </v-col>

                <v-col
                  class="col-6 px-0 py-2 mx-auto"
                  v-if="isWhatsAppInviteAvailable"
                >
                  <v-btn
                    class="requestButton"
                    :disabled="!valid"
                    color="primary"
                    :loading="requestingAccess"
                    @click="requestAccess(false)"
                    >{{
                      $t("components.subdomainTaken.whatsAppLinkSend")
                    }}</v-btn
                  >
                </v-col>
              </v-row>-->
              
              </v-form>
              <p class="pt-3" v-if="isWhatsAppInviteAvailable">
                {{ $t("components.subdomainTaken.vofficeUserPhoneNumber") }}
              </p>
            </v-col>
          </v-row>
        <!-- </div> -->
      </div>
    </v-col>
  </v-row>

    </v-content>
  </v-app>
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
// import Drawer from '../components/sidebar/drawer.vue';
// import NavBar from '../../components/navbar/navbar.vue';
export default {
  // components: {NavBar} ,
  data() {
    return {
      isMobile: isMobile(),
      isLarge: this.$vuetify.breakpoint.lgAndUp,
      isSmall: this.$vuetify.breakpoint.mdOnly,
      isXSmall: this.$vuetify.breakpoint.smAndDown,
      drawers: ["Default (no property)", "Permanent", "Temporary"],
      primaryDrawer: {
        model: null,
        type: "default (no property)",
        clipped: false,
        floating: false,
        mini: false,
      },
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
      email: "",
      valid: true,
      requestingAccess: false,
      showResult: false,
      message: "",
      emailSent: false,
      emailRules: [
        (v) => !!v || "",
        (v) => (v || "").indexOf(" ") < 0 || "", //No spaces are allowed
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || "", //this.$t("generics.emailMustBeValid")
      ],
      sendWhatsApp: false,
    };
  },
  methods: {
    async requestAccess(email = true) {
      if (this.$refs.form.validate()) {
        this.requestingAccess = true;
        this.sendWhatsApp = !email;
        this.emailSent = true;
        this.email = this.email.trim().toLowerCase();
        const result = await fetch("/api/reinvite", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email,
            whatsApp: this.sendWhatsApp,
          }),
        }).then(res => res.json());
        if (result.sent) {
          // EMAIL SENT
          // this.message = 'The new link has been sent, please check your mailbox.';
          this.showResult = true;
        } else if (result.success) {
          // EMAIL NOT SENT
          // this.message = 'If this email address is registered, we sent a message to the mailbox.';
          this.showResult = true;
        } else if (result.error) {
          // COULD NOT SEND EMAIL
          // this.message = 'An error occurred, please try later';
          this.showResult = true;
        }
        // after everything is done
        this.email = "";
        this.mssg = "close this window and check your Mailbox.";
        // this.requestingAccess = false;  // don´t close it
      }
    },
  },
  computed: {
    showCompanyLogo() {
      return this.state.namespaceSettings.companyLogo || "assets/icon.png";
    },
    getUrl() {
      return window.location.host; //window.location.href;
    },
    isWhatsAppInviteAvailable() {
      // See also isWhatsAppInviteAvailable @ websrc/components/sidebar/inviteVisitorsMenu_new.vue
      // https://gitlab.ra-micro.de/devcups/voffice/-/issues/401
      return false;
      /*
      if (process.env.PKG) {
        return false;
      } else {
        return true;
      }
      */
    },
  },
};
</script>

<style scoped lang="scss">
.imprintLink{
  color: white!important;
}
.logoLeftsideDiv{
  width: 155px;
  background: white;
  border-radius: 50%;
  height: 155px;
  padding: 3px;
  margin-bottom: 20px;
}
.titleSize {
  font-size: 18px;
}

.copyRightDiv {
  position: absolute;
  bottom: 20;
  left: 30px;
  font-size: 13px;
}
.customFontSize {
  font-size: 17px;
}
.backgroundImage {
  color: white;
  // align-items: center;
  // justify-content: center;
  // padding-top: 193px;
  display: table;
  // flex-direction: column;
  height: 100%;
  padding-left: 8%;
  padding-right: 8%;
  .divContentText {
    display: table-cell;
    vertical-align: middle;
  }
}
.contentRightSide {
  display: table-cell;
  vertical-align: middle;
}
.formRightSide {
  position: relative;
  // padding-top: 10%;
  width: 50%;
  // padding-bottom: 10%;
  display: table;
  padding-left: 10%;
  padding-right: 10%;
  height: 100%;
  //max-width: 700px;
}
.fill-half-width {
  width: 100%;
}
.fill-width {
  width: 100%;
}
.customForm {
  margin-top: 7%;
}
.fontSize17 {
  font-size: 17px !important;
}
.fontSize22 {
  font-size: 22px !important;
}
.fontSize21 {
  font-size: 21px !important;
}
.fontSize30 {
  font-size: 30px !important;
}
.title {
  color: #2a3133;
}
.requestButton {
  background-color: #2a3133 !important;
  border-color: #2a3133 !important;
}
.linkApp {
  text-decoration: unset;
  color: unset !important;
}
</style>