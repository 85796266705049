var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.companyRegisterObject.isRamicroCustomer
        ? _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "", autocomplete: "off" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: 6 } },
                    [
                      _c("v-autocomplete", {
                        ref: "countryDropdown",
                        attrs: {
                          items: _vm.countriesArray,
                          label: _vm.$t("components.companyRegister.state"),
                          autocomplete: "very_false",
                          required: "",
                          "return-object": "",
                          "item-text": "name",
                          "item-value": "value",
                          outlined: "",
                          dense: "",
                          rules: _vm.stateRules,
                          "hide-details": _vm.isGermanLawyer
                        },
                        on: {
                          change: _vm.setCountry,
                          mousedown: function($event) {
                            return _vm.clearDropDown()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c("img", {
                                    staticClass: "countryFlag",
                                    attrs: {
                                      src:
                                        "/img/icons/flags/" +
                                        data.item.value.toLowerCase() +
                                        ".png"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.item.name) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function(data) {
                                return [
                                  _c("img", {
                                    staticClass: "countryFlag",
                                    attrs: {
                                      src:
                                        "/img/icons/flags/" +
                                        data.item.value.toLowerCase() +
                                        ".png"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.item.name) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2240524892
                        ),
                        model: {
                          value: _vm.countryDropDown,
                          callback: function($$v) {
                            _vm.countryDropDown = $$v
                          },
                          expression: "countryDropDown"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "hide-details": _vm.isGermanLawyer,
                          items: _vm.branchesArray,
                          filter: _vm.customFilter,
                          label:
                            _vm.$t("components.companyRegister.branch") + "*",
                          autocomplete: "very_false",
                          required: "",
                          "return-object": true,
                          rules: _vm.branchRules,
                          outlined: "",
                          dense: ""
                        },
                        on: { change: _vm.setBranch },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "spanDropdownSelection" },
                                    [_vm._v(" " + _vm._s(data.item.text))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2920035136
                        ),
                        model: {
                          value: _vm.companyRegisterObject.branchSelected,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.companyRegisterObject,
                              "branchSelected",
                              $$v
                            )
                          },
                          expression: "companyRegisterObject.branchSelected"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isGermanLawyer && _vm.namespaceIsFree
                ? _c("LawyerCompanyRegister")
                : _vm._e(),
              _vm._v(" "),
              _vm.namespaceIsFree
                ? _c("CommonCompanyRegister", {
                    ref: "CommonCompanyRegister",
                    attrs: {
                      isGermanLawyer: _vm.isGermanLawyer,
                      isPackagedVersion: _vm.isPackagedVersion,
                      countryCodeInput: _vm.countryCodeInput
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.namespaceIsFree
                ? _c(
                    "v-row",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-col",
                        {
                          class: {
                            "pb-0 pt-4": _vm.isGermanLawyer,
                            "py-0": !_vm.isGermanLawyer
                          },
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.companyRegister.labelnewNamespace"
                              ),
                              rules: _vm.namespaceRules,
                              required: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.newNamespace,
                              callback: function($$v) {
                                _vm.newNamespace = $$v
                              },
                              expression: "newNamespace"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 text-right",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled:
                                  _vm.newNamespace == "" ||
                                  _vm.newNamespace.trim().length == 0
                              },
                              on: {
                                click: function($event) {
                                  return _vm.checkIfNeedRedirect(
                                    _vm.companyRegisterObject.branchSelected,
                                    _vm.newNamespace
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.companyRegister.checkSubdomain"
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.namespaceIsFree
                ? _c(
                    "v-row",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 checkBoxTerms",
                            attrs: { required: "", rules: _vm.acceptRules },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("span", {
                                        staticStyle: {
                                          color: "rgba(0, 0, 0, 0.6) !important"
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "components.takeAction.conditionsAccept"
                                            )
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2589559830
                            ),
                            model: {
                              value: _vm.terms,
                              callback: function($$v) {
                                _vm.terms = $$v
                              },
                              expression: "terms"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.namespaceIsFree
                ? _c(
                    "v-row",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 text-right",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: {
                                depressed: "",
                                color: "primary",
                                disabled:
                                  !_vm.terms ||
                                  !_vm.companyPhoneValid ||
                                  (_vm.isGermanLawyer &&
                                    !_vm.termsRAMicro &&
                                    !_vm.terms) ||
                                  !_vm.isFormPreValid
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validate()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.isGermanLawyer || _vm.isPackagedVersion
                                      ? _vm.$t("generics.startNow")
                                      : _vm.$t(
                                          "components.companyRegister.buttonFreeTrial"
                                        )
                                  ) +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !(_vm.isGermanLawyer || _vm.isPackagedVersion)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    disabled:
                                      !_vm.terms ||
                                      !_vm.companyPhoneValid ||
                                      (_vm.isGermanLawyer &&
                                        !_vm.termsRAMicro &&
                                        !_vm.terms) ||
                                      !_vm.isFormPreValid
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showPayPalScreen()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.companyRegister.buttonPayPal"
                                        )
                                      ) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.companyRegisterObject.isRamicroCustomer
        ? _c("div", { staticClass: "divInfoUser text-center" }, [
            _vm._v("\n    Lieber RA-MICRO Kunde.\n    "),
            _c("br"),
            _vm._v(
              "\n    Sie verwenden die RA-MICRO Kanzleisoftware. Bitte aktivieren Sie Ihr\n    kostenloses vOffice direkt aus Ihrer Kanzleisoftware heraus.\n    "
            ),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("img", {
              staticClass: "imgRaMicro",
              attrs: { src: "/img/vOffice-ra-micro.png" }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttonBackRACustomer" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { depressed: "", outlined: "", color: "primary" },
                    on: {
                      click: function($event) {
                        _vm.companyRegisterObject.isRamicroCustomer = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("tour.buttonPrevious")))]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ModalConditions", {
        attrs: {
          showModalConditions: _vm.showModalConditions,
          closeModalConditions: _vm.closeModalConditions
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }