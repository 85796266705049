var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReply
    ? _c(
        "v-card",
        [
          _c(
            "v-row",
            {
              staticClass: "px-2 btns",
              style: { width: "100%", margin: 0 },
              attrs: { flex: "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "px-0 py-0", attrs: { lg: "6", align: "left" } },
                [
                  _vm.amIAdmin
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "btnRed mr-2",
                                          attrs: { icon: "", color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.seeUserMessages(
                                                _vm.state.showCompSendMsg.uuid
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "eye"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3400090176
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.sendMessageModal.seeUserMessages"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "px-0 py-0",
                  attrs: { lg: "6", align: "right" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "btnRed mr-2",
                                      attrs: { icon: "", color: "primary" },
                                      on: { click: _vm.toggleIsReply }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "times"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2922878964
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generics.cancel")))])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "messageForm",
              attrs: { "lazy-validation": "", autocomplete: false },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-5", style: { width: "100%", margin: 0 } },
                [
                  _c("v-textarea", {
                    attrs: {
                      counter: "",
                      contenteditable: "true",
                      label: _vm.$t("components.sendMessageModal.message"),
                      rules: _vm.rules,
                      "no-resize": true,
                      maxlength: "250",
                      autofocus: ""
                    },
                    model: {
                      value: _vm.state.tmpMessagetext,
                      callback: function($$v) {
                        _vm.$set(_vm.state, "tmpMessagetext", $$v)
                      },
                      expression: "state.tmpMessagetext"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }