var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-0 col-xl-4 col-lg-3 col-md-3 col-sm-5  px-2 d-flex white"
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-0 text-truncate justify-space-between",
          class: _vm.amIModerator ? "col-3" : "col-12"
        },
        [_vm._v("\n    " + _vm._s(_vm.getTitleText()) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.amIModerator
        ? _c(
            "div",
            { staticClass: "pa-0 col-9 text-center" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-1",
                                  attrs: { color: "primary", "x-small": "" },
                                  on: { click: _vm.openAddUserConference }
                                },
                                on
                              ),
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "hidden-md-and-down pl-1 mr-3"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("components.conferenceForm.user")
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  style: { fontSize: "12px" },
                                  attrs: { icon: ["fal", "plus"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2436308632
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.conferenceForm.user")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-1",
                                  attrs: { color: "primary", "x-small": "" },
                                  on: { click: _vm.openVisitorConference }
                                },
                                on
                              ),
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "hidden-md-and-down pl-1 mr-3"
                                  },
                                  [_vm._v(_vm._s(_vm.$t("generics.guests")))]
                                ),
                                _vm._v(" "),
                                _c("font-awesome-icon", {
                                  style: { fontSize: "12px" },
                                  attrs: { icon: ["fal", "plus"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2773241934
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.guests")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-1",
                                  attrs: { color: "primary", "x-small": "" },
                                  on: { click: _vm.getUniversalLink }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "12px" },
                                  attrs: { icon: ["fal", "plus"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2965956267
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.conferenceForm.directCallInvite")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.getDataConference &&
              _vm.getDataConference.confOwner === _vm.state.ownUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-1",
                                      attrs: {
                                        color: "primary",
                                        "x-small": ""
                                      },
                                      on: { click: _vm.endConference }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "12px" },
                                      attrs: { icon: ["fal", "door-open"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1695195380
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.conferenceForm.endConference")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddUserConference
        ? _c("AddUsersConference", {
            attrs: {
              isConferenceCall: _vm.isConferenceCall,
              dataConference: _vm.getDataConference,
              showModal: _vm.showAddUserConference,
              closeModal: _vm.closeAddUserConference
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddVisitorConference
        ? _c("AddVisitorsConference", {
            attrs: {
              isConferenceCall: _vm.isConferenceCall,
              showModal: _vm.showAddVisitorConference,
              closeModal: _vm.closeVisitorConference,
              dataConference: _vm.getDataConference
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }