<template>
  <div>
    <v-menu
      :position-x="260"
      :position-y="120"
      absolute
      offset-y
      max-height="100%"
      max-width="100%"
      content-class="inviteVisitorMenu"
      v-model="menu"
      v-if="canInvite && !isMobile"
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <div style="position: relative">
            <v-btn
              :color="typeInvite == 'user' ? 'white' : ''"
              icon
              :class="typeInvite == 'guest' ? 'btnSendGuestInvitation' : ''"
              @click="toggleInstaLink"
              v-if="!mini"
            >
              <font-awesome-icon
                v-if="!isSidebar"
                class="primary--text"
                :icon="['fal', 'user-plus']"
                :style="{ fontSize: '20px' }"
              />
              <font-awesome-icon
                v-if="isSidebar"
                class="primary--text"
                :icon="['fal', 'plus-circle']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
            <v-tooltip v-if="mini" right>
              <template v-slot:activator="{ on }">
                <font-awesome-icon
                  v-on="on"
                  v-if="currentPage('/guest')"
                  class="primary--text cursorPointer"
                  :icon="['fas', 'user-friends']"
                  @click.stop.prevent="handlerClickVisitorsMini()"
                  style="outline:none"
                />
                <font-awesome-icon
                  style="outline:none"
                  v-on="on"
                  v-else
                  class="primary--text cursorPointer"
                  :icon="['fal', 'user-friends']"
                  @click.stop.prevent="handlerClickVisitorsMini()"
                />
              </template>
              <span>{{ $t("generics.invitationNavbar") }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <InviteVisitorsMenuNew :closeMenu="closeMenu" v-if="menu" />
    </v-menu>
    <!-- <v-dialog
      v-model="dialogInvite"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="canInvite && isMobile"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="white" icon v-on="on">
          <font-awesome-icon :icon="['fal', 'user-plus']" :style="{ fontSize: '20px' }" />
        </v-btn>
      </template>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialogInvite = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="px-0">{{ $t('components.footernav.inviteUser') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
      </v-toolbar>
      <InviteGuestPanel :typeInvite="typeInvite" :fromRoot="fromRoot" />
    </v-dialog> -->
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import InviteVisitorsMenuNew from "../sidebar/inviteVisitorsMenu_new.vue";

export default {
  props: [
    "isLarge",
    "isXSmall",
    "isSmall",
    "typeInvite",
    "isSidebar",
    "mini",
    "resetInviteUserNotification",
    "visitors",
  ],
  components: {
    InviteVisitorsMenuNew,
  },
  data() {
    return {
      state: store.state,
      closeOnClick: false,
      menu: false,
      dialogInvite: false,
      fromRoot: true,
      isMobile: isMobile(),
    };
  },
  mounted() {
    // EventBus.$on("openInviteGuestModalEvent", this.openMenu);
  },
  methods: {
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    handlerClickVisitorsMini() {
      if (this.mini && this.visitors.length == 0) {
        this.menu = true;
        this.toggleInstaLink();
      } else if (this.mini && this.visitors.length > 0) {
        if (this.$router.currentRoute.path !== "/guest") {
          this.$router.push({ path: "/guest" });
        }
      }
    },
    toggleInstaLink() {
      // console.log('toggling    ',!store.state.showInviteInstaLink )
      // store.state.showInviteInstaLink = !store.state.showInviteInstaLink;
      store.setCurrentContentVisile("invitationform", true, this.$router);
    },
    openMenu() {
      this.menu = true;
    },
    closeMenu() {
      this.menu = false;
      // document.getElementById('drawerContentCard').classList.remove('hideElement');
    },
  },
  watch: {
    menu: {
      handler: function (value) {
        this.fromRoot = value;
        if (this.resetInviteUserNotification)
          this.resetInviteUserNotification();
        // console.log("showing / hiding", value);
      },
    },
  },
  computed: {
    canInvite() {
      //   if (store.getUserIsGuest(this.state.ownUUID)) {
      //     return false;
      //   }
      //   if (this.typeInvite === 'guest') {
      //     return store.getUserCanInviteGuests(this.state.ownUUID);
      //   }
      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.hideElement {
  opacity: 0;
}
.theme--dark {
  .inviteVisitorMenu {
    background-color: #1f1f1f;
  }
}
.theme--light {
  .inviteVisitorMenu {
    background-color: #ffffff;
  }
}
.inviteVisitorMenu {
  top: 0px !important;
  left: 0px !important;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  & .invitationMenuList {
    width: 100% !important;
    padding-top: 0px;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
.btnSendGuestInvitation {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
.btnSend {
  float: right;
  margin-bottom: 20px;
}
.d-flex {
  p {
    &.email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 65%;
      color: #2a3133;
      margin: 0px;
    }
    &.date {
      width: 50%;
      font-size: 12px;
      vertical-align: middle;
      margin: 4px;
    }
  }
}
</style>
