<template>
    <v-card flat class="pa-3 my-6 rounded-sm" v-if="postData" @click="openPreview(postData.id)">
        <v-row class="d-flex align-start">
            <v-col cols="auto">
                <router-link @click.stop :to="`/socialmedia/${postData?.userId}`"
                    class="d-flex align-center text-decoration-none">

                    <UserAvatar :src="getAvatarForUuid(postData?.userId)" :size="48" />
                </router-link>
            </v-col>
            <v-col class="pt-4 pl-0">
                <div class="font-weight-medium">{{ getTitelByUuid(postData.userId) }} {{ getNameByUuid(postData.userId)
                }}
                </div>
                <div class="text-medium-emphasis text-caption">{{ formattedDate(postData.createdAt) }}</div>
            </v-col>
            <v-col cols="1" class="pt-4 pl-0 text-right">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn @click="checkIfPostIsFavorite" density="compact" icon="mdi-dots-vertical" variant="text"
                            v-bind="props"></v-btn>
                    </template>

                    <v-list>
                        <v-list-item class="pointer" @click="toggleFavorite">
                            <v-list-item-title>{{ isFavorite ? $t('components.socialMedia.removeFavorite') :
                                $t('components.socialMedia.addFavorite') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="isEditing = true" class="pointer" v-if="postData.userId === ownUUID">
                            <v-list-item-title>{{ $t('components.socialMedia.edit') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item :class="{ 'text-error': confirmDeleteId === postData._id }" class="pointer"
                            v-if="postData.userId === ownUUID || amIAdmin" @click.stop="removePost(postData)">
                            <v-list-item-title>{{ $t('components.socialMedia.remove') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-row class="mt-2 mb-2">
            <v-col v-if="!isEditing">
                <p class="mb-1" v-html="$sanitize(postData.content)">
                </p>
            </v-col>
            <EditPost v-if="isEditing" :viewSelected="'Post'" :postData="postData" @closeEdit="isEditing = false" />
        </v-row>
        <SocialMediaDocumentGallery v-if="postData?.documents && postData?.documents.length" :documents="postData?.documents"></SocialMediaDocumentGallery>
        <SocialMediaGallery v-if="postData?.images && postData?.images.length" :images="postData?.images" />

        <v-divider></v-divider>

        <PostFooter :toggleComments="toggleCommentSection" :toogleLike="toogleLike" :postData="postData" />


        <v-row v-if="showComments">
            <v-col>
                <CommentItem v-for="(comment, index) in postData.comments" :key="index" :comment="comment"
                    class="mt-4" />
                <v-divider class="my-4"></v-divider>
                <CreateComment :postData="postData" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import store, { EventBus } from "../../../../store";
import moment from "../../../../../sharedsrc/moment";
import SocialMediaGallery from "../common/socialMediaGallery.vue";
import SocialMediaDocumentGallery from "../common/socialMediaDocumentGallery.vue";
import PostFooter from "./postFooter.vue";
import EditPost from "../common/editPost.vue"
import CreateComment from "../comments/createComment.vue";
import CommentItem from "../comments/commentItem.vue";
import UserAvatar from "../common/userAvatar.vue";

export default {
    components: { SocialMediaGallery, PostFooter, CreateComment, CommentItem, UserAvatar, EditPost, SocialMediaDocumentGallery },
    props: ['postData'],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            showComments: false,
            showAddComment: false,
            isEditing: false,
            confirmDeleteId: null,
            favoritesPost: [],
            isFavorite: false
        };
    },
    mounted() {
    },
    methods: {
        formattedDate(createdAt) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const formattedDate = moment(createdAt).tz(userTimeZone).format('DD.MM.YYYY HH:mm');
            return formattedDate
        },
        openPreview(postId) {
            const dataToSend = {
                show: true,
                postId
            }
            EventBus.$emit('previewPost', dataToSend)
        },
        getAvatarForUuid(uuid) {
            return store.getAvatarForUuid(uuid);
        },
        toggleCommentSection() {
            this.showComments = !this.showComments;
        },
        getNameByUuid(uuid) {
            return store.getNameForUuid(uuid);
        },
        getTitelByUuid(uuid) {
            return store.getTitelForUuid(uuid);
        },
        toggleFavorite() {
            if (this.isFavorite) {
                this.removeFromFavorite();
            } else {
                this.addToFavorite();
            }
        },
        checkIfPostIsFavorite() {
            fetch(`/api/socialmedia/is-post-favorite?uuid=${this.ownUUID}&postId=${this.postData.id}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then((result) => result.json())
                .then((data) => {
                    if (data.success) {
                        this.isFavorite = data.isFavorite;
                    } else {
                        console.error('Error checking favorite status:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removeFromFavorite() {
            fetch("/api/socialmedia/remove-post-from-favorite", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ uuid: this.ownUUID, postId: this.postData.id })
            })
                .then((result) => result.json())
                .then((data) => {
                    if (data.success) {
                        this.isFavorite = false;
                        EventBus.$emit('removeFavoritePost', this.postData);
                    } else {
                        console.error('Error removing favorite:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        addToFavorite() {
            fetch("/api/socialmedia/add-post-to-favorite", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ uuid: this.ownUUID, postId: this.postData.id })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        this.isFavorite = false;
                        EventBus.$emit('addFavoritePost', this.postData);
                    } else {
                        // Handle any errors
                        console.error('Error adding like:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        toogleLike() {
            // Check if the current user has already liked the post
            const isLiked = this.postData.likes.includes(this.ownUUID);

            // If the post is already liked by the user, remove the like
            if (isLiked) {
                // Call the API to remove the like
                this.removeLike(this.postData.id, this.ownUUID);
            } else {
                // If the post is not liked by the user, add the like
                this.addLike(this.postData.id, this.ownUUID);
            }
        },
        addLike(postId, userId) {
            fetch("/api/socialmedia/like-post", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: postId, userId })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        // Update local postData to reflect the new like
                        this.$set(this.postData, 'likes', [...this.postData.likes, userId]);
                    } else {
                        // Handle any errors
                        console.error('Error adding like:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removeLike(postId, userId) {
            fetch("/api/socialmedia/unlike-post", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ id: postId, userId })
            })
                .then((result) => {
                    return result.json();
                })
                .then((data) => {
                    if (data.success) {
                        // Remove the like from local postData
                        this.$set(this.postData, 'likes', this.postData.likes.filter(like => like !== userId));
                    } else {
                        // Handle any errors
                        console.error('Error removing like:', data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        removePost(postData) {
            if (this.confirmDeleteId === postData._id) {
                fetch("/api/socialmedia/remove-post", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ postId: postData._id })
                }).then((result) => {
                    if (!result.error) {
                        EventBus.$emit('removePost', postData);
                        EventBus.$emit('removeFavoritePost', postData);
                        
                        this.confirmDeleteId = null; // Restablecer después de eliminar
                    } else {
                        //TODO: Handler error
                    }
                })

            } else {
                this.confirmDeleteId = postData._id; // Primer clic: activar confirmación
                setTimeout(() => {
                    if (this.confirmDeleteId === postData._id) {
                        this.confirmDeleteId = null; // Si no hacen el segundo clic en 3s, se cancela
                    }
                }, 2000);
            }
        }

    },
    computed: {
        amIAdmin() {
            return store.getUserIsAdmin(this.ownUUID);
        },
    }
};
</script>
