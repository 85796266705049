var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.validPerson
    ? _c(
        "v-card",
        {
          staticClass: "mx-auto elevation-0 primaryBackground",
          attrs: { flat: "", "max-width": "100%", outlined: "" }
        },
        [
          _vm.person.uuid !== _vm.ownUUID &&
          _vm.section !== "guests" &&
          _vm.person.connected
            ? _c(
                "div",
                { staticClass: "iconCardsContainerLeft" },
                [
                  _c("MultiUserCallIconGrid", { attrs: { person: _vm.person } })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showUserProfile
            ? _c("UserProfle", {
                attrs: {
                  showUserProfile: _vm.showUserProfile,
                  toggleUserProfile: _vm.toggleUserProfile
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-1" }),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              staticClass: "py-2 primaryBackground",
              attrs: { "three-line": "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "", disabled: _vm.disabledUserInfoTT },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-list-item-avatar",
                              _vm._g(
                                {
                                  class:
                                    "borderRadius5 mx-2 my-0 " +
                                    (!_vm.person.connected ? "grayScale" : ""),
                                  attrs: { tile: "", size: "80" }
                                },
                                on
                              ),
                              [
                                _vm.avatarSrc ===
                                "img/default_profile_picture.png"
                                  ? _c("DefaultAvatar", {
                                      attrs: {
                                        size: 80,
                                        userData: _vm.person.user,
                                        setUserInModal: _vm.setUserInModal
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.avatarSrc !==
                                "img/default_profile_picture.png"
                                  ? _c("v-img", {
                                      staticClass:
                                        "mx-2 borderRadius5 cursorPointer",
                                      attrs: {
                                        src: _vm.avatarSrc,
                                        "max-height": "80",
                                        "max-width": "80",
                                        contain: "",
                                        "lazy-src":
                                          "assets/default_profile_picture.png"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setUserInModal()
                                        },
                                        error: _vm.onImgError
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2116328670
                  )
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        (_vm.person.user.visitorData || {}).noteForContact
                      ) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                { staticClass: "contentWrapper" },
                [
                  _c("div", { staticClass: "notranslate white--text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getUserTitel(_vm.person.uuid)) +
                        "\n        " +
                        _vm._s(_vm.userName) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.getCallText(_vm.person) &&
                  _vm.person.user.activity !== "No status"
                    ? _c(
                        "v-list-item-subtitle",
                        { staticClass: "userActivity userInCall white--text" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fal", "video"] }
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.getCallText(_vm.person)) +
                              "\n      "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.getCallText(_vm.person) &&
                  !_vm.getUserIsGuest(_vm.person.uuid || _vm.person.user.uuid)
                    ? _c(
                        "v-list-item-subtitle",
                        {
                          staticClass:
                            "userActivity userInCall d-inline-block text-truncate white--text"
                        },
                        [_vm._v(_vm._s(_vm.userPosition))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getUserIsGuest(_vm.person.uuid || _vm.person.user.uuid)
                    ? _c(
                        "v-list-item-subtitle",
                        {
                          staticClass:
                            "userActivity userInCall d-inline-block text-truncate my-0 white--text"
                        },
                        [
                          _c("p", { staticClass: "my-0" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm
                                    .moment(_vm.person.user.created)
                                    .format("DD.MM.YYYY HH:mm")
                                ) +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.person.user.paylinkInfo
                            ? _c("p", { staticClass: "my-0" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("generics.paylink")) +
                                    ": " +
                                    _vm._s(_vm.person.user.paylinkInfo.amount) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.namespaceSettings.displayUserStatus
                    ? _c(
                        "v-list-item-subtitle",
                        { style: { color: _vm.badgetClassObj } },
                        [
                          _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getUserActivity(_vm.person)) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", "max-width": "250px" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        !_vm.isGuest
                          ? _c(
                              "v-card",
                              _vm._g(
                                {
                                  staticClass: "px-2 noBRadius",
                                  style: {
                                    borderWidth: 1,
                                    borderColor: _vm.borderClass,
                                    borderStyle: "solid",
                                    height: 36
                                  },
                                  attrs: { color: _vm.badgetClassObj }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0 customWidh",
                                    attrs: {
                                      align: "center",
                                      justify: "space-between"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          bordered: "",
                                          color: "error",
                                          content: _vm.state.namespaceSettings
                                            .publicMsgs
                                            ? _vm.person.user
                                                .unreadMessageCounter === 0 ||
                                              !_vm.person.user
                                                .unreadMessageCounter
                                              ? null
                                              : _vm.person.user
                                                  .unreadMessageCounter
                                            : null,
                                          value: _vm.state.namespaceSettings
                                            .publicMsgs
                                            ? _vm.person.user
                                                .unreadMessageCounter === 0 ||
                                              !_vm.person.user
                                                .unreadMessageCounter
                                              ? null
                                              : _vm.person.user
                                                  .unreadMessageCounter
                                            : null,
                                          overlap: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              color: _vm.wholeBarWithColors()
                                                                ? "white"
                                                                : "black",
                                                              disabled:
                                                                _vm.person.user
                                                                  .uuid ==
                                                                  _vm.ownUUID ||
                                                                _vm.isAiUser(
                                                                  _vm.person
                                                                    .user.uuid
                                                                )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.setShowModalSenMsg(
                                                                  _vm.person
                                                                    .user.uuid,
                                                                  "message"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "16px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "paper-plane"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.userProfile.sendAMessage"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.isInCallWithUs &&
                                    _vm.person.user.uuid !== _vm.ownUUID
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              disabled:
                                                _vm.person.user.activity ===
                                                "No Calls"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              color:
                                                                _vm.phoneColorColor,
                                                              disabled:
                                                                _vm.disableCallBtn
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.callUser(
                                                                  _vm.person
                                                                    .user.uuid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          !_vm.wholeBarWithColors()
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    fontSize:
                                                                      "16px"
                                                                  },
                                                                  attrs: {
                                                                    icon: [
                                                                      "fas",
                                                                      "phone"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            : _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    fontSize:
                                                                      "16px"
                                                                  },
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "phone"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.typeData == "waitingroom"
                                                    ? _vm.$t(
                                                        "components.userListItem.videoCallStartWr"
                                                      )
                                                    : _vm.$t(
                                                        "components.userListItem.videoCallStart"
                                                      )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isMobile &&
                                    _vm.typeData !== "waitingroom" &&
                                    _vm.showOrganigram
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled: _vm.isAiUser(
                                                                _vm.person.user
                                                                  .uuid
                                                              ),
                                                              color: _vm.wholeBarWithColors()
                                                                ? "white"
                                                                : "black"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                return _vm.goToOrganisation(
                                                                  _vm.person
                                                                    .uuid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "20px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "sitemap"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.organisationCarousel.showInOrganigram"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isMobile &&
                                    !_vm.isBasicView &&
                                    _vm.showUploadFile
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              color: _vm.wholeBarWithColors()
                                                                ? "white"
                                                                : "black",
                                                              disabled:
                                                                _vm.disableSendFileBtn ||
                                                                _vm.isAiUser(
                                                                  _vm.person
                                                                    .user.uuid
                                                                )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendFileTo(
                                                                  _vm.person
                                                                    .uuid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "16px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "upload"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("generics.uploadFile")
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("UserMoreActions", {
                                      attrs: {
                                        isRegularGrid: false,
                                        person: _vm.person,
                                        section: _vm.section,
                                        customStyle: true,
                                        typeData: _vm.typeData
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                4152972543
              )
            },
            [
              _vm._v(" "),
              _c(
                "span",
                [_c("UserInfoTooltip", { attrs: { person: _vm.person } })],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.isGuest
            ? _c(
                "v-card",
                {
                  staticClass: "px-2 noBRadius",
                  style: {
                    borderWidth: 1,
                    borderColor: _vm.borderClass,
                    borderStyle: "solid",
                    height: 36
                  },
                  attrs: { color: _vm.badgetClassObj }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0 customWidh",
                      attrs: { align: "center", justify: "space-between" }
                    },
                    [
                      !_vm.isInCallWithUs &&
                      _vm.person.user.uuid !== _vm.ownUUID
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                disabled:
                                  _vm.person.user.activity === "No Calls"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                color: _vm.phoneColorColor,
                                                disabled: _vm.disableCallBtn
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.callUser(
                                                    _vm.person.user.uuid
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            !_vm.wholeBarWithColors()
                                              ? _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fas", "phone"]
                                                  }
                                                })
                                              : _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "phone"]
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4023195260
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.userListItem.videoCallStart"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            bordered: "",
                            color: "error",
                            content: _vm.state.namespaceSettings.publicMsgs
                              ? _vm.person.user.unreadMessageCounter === 0 ||
                                !_vm.person.user.unreadMessageCounter
                                ? null
                                : _vm.person.user.unreadMessageCounter
                              : null,
                            value: _vm.state.namespaceSettings.publicMsgs
                              ? _vm.person.user.unreadMessageCounter === 0 ||
                                !_vm.person.user.unreadMessageCounter
                                ? null
                                : _vm.person.user.unreadMessageCounter
                              : null,
                            overlap: ""
                          }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                color: _vm.wholeBarWithColors()
                                                  ? "white"
                                                  : "black"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setShowModalSenMsg(
                                                    _vm.person.uuid,
                                                    "message"
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "paper-plane"]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2251138430
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.userProfile.sendAMessage"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isMobile && !_vm.isBasicView && _vm.showUploadFile
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                color: _vm.wholeBarWithColors()
                                                  ? "white"
                                                  : "black",
                                                disabled: _vm.disableSendFileBtn
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.sendFileTo(
                                                    _vm.person.uuid
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "upload"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1875817997
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.uploadFile")))
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }