var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _c("div", [_vm._v(_vm._s(_vm.entry.name))]),
      _vm._v(" "),
      _vm.entry.details
        ? _c("div", [_vm._v(_vm._s(_vm.entry.details))])
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v(_vm._s(_vm.$t("components.remotePersonCall.moderator")))
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "px-1 py-1", attrs: { cols: "3" } },
            [_c("UserDisplay", { attrs: { userUUID: _vm.hostUUID } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }