var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.conferenceForm.titleInviteUser"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c(
                  "v-row",
                  { staticClass: "mx-0" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-combobox", {
                          staticClass: "mt-3",
                          attrs: {
                            items: _vm.dataCombobox,
                            "search-input": _vm.searchUsers,
                            "item-disabled": _vm.disableItem,
                            select: "",
                            "item-text": "name",
                            "item-value": "uuid",
                            label: _vm.$t(
                              "components.conferenceForm.vOfficeUsers"
                            ),
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            autofocus: ""
                          },
                          on: {
                            "update:searchInput": function($event) {
                              _vm.searchUsers = $event
                            },
                            "update:search-input": function($event) {
                              _vm.searchUsers = $event
                            },
                            change: _vm.handleChangeUsers
                          },
                          model: {
                            value: _vm.selectedUser,
                            callback: function($$v) {
                              _vm.selectedUser = $$v
                            },
                            expression: "selectedUser"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isConference
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-checkbox", {
                              staticClass: "mt-0 fontSize14 mb-0",
                              attrs: {
                                label: _vm.$t(
                                  "components.conferenceForm.showVisitorName"
                                )
                              },
                              model: {
                                value: _vm.showUserName,
                                callback: function($$v) {
                                  _vm.showUserName = $$v
                                },
                                expression: "showUserName"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-text-field",
                          {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "auto",
                              label: _vm.$t(
                                "components.inviteVisitor.function"
                              ),
                              autofocus: _vm.editingFunction,
                              readonly: !_vm.editingFunction
                            },
                            model: {
                              value: _vm.userFunction,
                              callback: function($$v) {
                                _vm.userFunction = $$v
                              },
                              expression: "userFunction"
                            }
                          },
                          [
                            _c(
                              "template",
                              { staticClass: "noMarginTop", slot: "append" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.edit()
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "17px" },
                                      attrs: { icon: ["fas", "pen"] }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editFunction()
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "17px" },
                                      attrs: { icon: ["fal", "save"] }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isConference
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-checkbox", {
                              staticClass: "mt-0 fontSize14 mb-0",
                              attrs: {
                                label: _vm.$t(
                                  "components.conferenceForm.moderator"
                                )
                              },
                              model: {
                                value: _vm.isModerator,
                                callback: function($$v) {
                                  _vm.isModerator = $$v
                                },
                                expression: "isModerator"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              { attrs: { hideDefaultButtons: true } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      depressed: "",
                      disabled: !_vm.selectedUser
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveMember(true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n             " +
                        _vm._s(_vm.$t("components.addGroupModal.add")) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      depressed: "",
                      disabled: !_vm.selectedUser
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveMember(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n               " +
                        _vm._s(_vm.$t("components.addGroupModal.addplus")) +
                        "\n            "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }