var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-0" },
    [
      _c(
        "v-card",
        {
          class:
            "cardWidth mx-auto mt-2 mb-0 py-0 " +
            (!_vm.isMobile
              ? "heightDesktop"
              : _vm.isMobile && _vm.mobileOperatingSystem == "iOS"
              ? "heightMobileIOS"
              : _vm.isMobile && _vm.mobileOperatingSystem !== "iOS"
              ? "heightMobile"
              : "")
        },
        [
          _c("v-divider"),
          _vm._v(" "),
          _vm.cachedMessages
            ? _c(
                "v-container",
                {
                  ref: "messageContainer",
                  staticClass: "contentMessagesIsReply"
                },
                [
                  _c("MessageHistoryEffector", {
                    attrs: { messages: _vm.getHistoryMessages }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "px-6" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footerCard" },
            [
              _c(
                "v-card-subtitle",
                { staticClass: "d-flex pt-1 pb-1 mb-0 px-2 text-right" },
                [
                  _c(
                    "v-text-field",
                    {
                      staticClass: "inputMessageWaitingRoom",
                      attrs: {
                        autofocus: "",
                        "hide-details": "",
                        outlined: "",
                        "single-line": "",
                        dense: "",
                        label: _vm.$t("components.notificationsModal.reply")
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          _vm.replyMessage.trim().length > 0
                            ? _vm.sendNewMessage()
                            : null
                        }
                      },
                      model: {
                        value: _vm.replyMessage,
                        callback: function($$v) {
                          _vm.replyMessage = $$v
                        },
                        expression: "replyMessage"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                icon: "",
                                disabled: _vm.replyMessage.trim().length === 0
                              },
                              on: { click: _vm.sendNewMessage }
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: {
                                  icon: ["fal", "paper-plane"],
                                  color: "grey"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }