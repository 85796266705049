<template>
<div>
    <v-form v-model="validData">
      <v-row flex class="w-100 mx-0 my-0">
        <v-row flex class="w-100 mx-0 my-0">
        <!-- select waiting room or direct call -->
        <v-row class="w-100 mx-0 my-0" v-show="userListToSend.length < 4">
          <v-col cols="6" class="py-0">
            <v-text-field
              color="white"
              hide-details
              :disabled="atUserLimit || userSelected"
              v-model="selectedUserName"
              dense
              solo
              :label="$t('components.inviteVisitor.name')"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
                color="white"
                hide-details
                :disabled="emailDisabled" 
                v-model="selectedEmail"
                dense
                solo
                :label="$t('components.inviteVisitor.invitationEmail')"
                :rules="phoneInputValid ? [] : emailRules"
              ></v-text-field>
          </v-col>
          
        </v-row>
        <v-row class="mx-0 my-3">
            <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="visFunction"
                  required
                  :label="$t('components.inviteVisitor.function')"
                  dense
                  solo
                ></v-text-field>
            </v-col>
            <template v-if="isWhatsAppInviteAvailable">
              <v-col cols="6" class="py-0 positionRelative">
                 <vue-tel-input
                  v-model="phoneNumberInput"
                  required
                  defaultCountry="DE"
                  enabledCountryCode
                  @onInput="onTelInput"
                  name="number"
                  id="numDisplay"
                  class="form-control text-center input-sm inputPhone2FA"
                  value
                  placeholder
                  autocomplete="off"
                  :class="phoneDisabled ? {} : { borderError: !phoneInputValid }"
                  :disabled="phoneDisabled" 
                >
                </vue-tel-input>
                <span class="whatsAppIcon">
                      <font-awesome-icon :icon="['fab', 'whatsapp']" :style="{ fontSize: '20px' }" /> 
                </span>
              </v-col>
            </template>
        </v-row>
          <v-row class="mx-0 my-0">
             <v-col cols="12" class="py-0 positionRelative">
              <span class="font-14">{{ getNoticeText }}</span>
             </v-col>
          </v-row>  
      </v-row>
      </v-row>
    </v-form>

    <!-- list user to invite -->
       <v-row class="w-100 mx-0 my-0" >
      <v-list-item v-for="(data, index) in userListToSend" :key="index"  :class="{'rowOdd': index % 2 === 0, 'rowEven': index % 2 !== 0 }">
          <v-list-item-content>
            <v-list-item><strong>{{data.name}}:</strong>&nbsp; {{data.email}}</v-list-item>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn icon :disabled="isProcessing" style="margin-top: -9px;">
             <DeleteBasket :indexData="index" :delFunction="removeUserList" extraClasses="" fontSize="20" prevent= false />
            </v-btn>
          </v-list-item-icon>
      </v-list-item>
    </v-row>
    <!-- end list user to invite -->
  </div>
</template>

<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import { createVisitor, sendVisitorEmail } from "../../lib/inviteHelpers";
import { dispatchErrorAlert } from "../../effector/alerts";
import { isEmailRegistered } from "../../lib/wsMsg";
import { actingAsUuidStore, setVisitorForRepresentiveEffect } from '../../effector/representative';

//personToAddToGroup
export default {
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      invitateType: "waiting",
      isProcessing: false,
      doIgnoreRule:false,
      isforMail: false,
      isdup: false,
      dupError: "",
      userNameRules: [  (v) => !!v || ""], //this.$t("generics.nameRequired")],
      emailRules: [
        (v) => !!v || "" , //remove red mesagge ticket #132
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||this.$t('generics.emailMustBeValid'), //remove red mesagge ticket #132
      ],
      userListToSend: [],
      selectedUserName: null,
      selectedEmail: null,
      visFunction: '',
      validData: false,
      isWaitingRoom: false,
      emailValidator: /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/,
      mobilePhoneNumber: '',
      phoneNumberParsed: '',
      phoneNumberInput: '',
      phoneInputValid: false,
      editingPhone: false,
    };
  },
   effector:{
    actingAsUuid: actingAsUuidStore,
  },
  props:['disableItem', 'allSelectedUsers', 'linkDateFrom', 'linkTimeFrom', 'linkDateTo', 'linkTimeTo', 'durationMin', 'subject', 'isNewConferenceForm', 'rowNumbers','setNoticeText'],
  components:{ DeleteBasket },
  mounted(){
    EventBus.$emit('setReference', this);
  },
  watch: {},
  methods: {
  getDurationPhrase(num) {
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

      if (rhours === 1 && rminutes === 1) return this.$t("components.invitePreview.inviteAllLineDurationHourMinute", [rhours,rminutes]) + ".";
      if (rhours === 1 && rminutes > 1) return this.$t("components.invitePreview.inviteAllLineDurationHourMinutes", [rhours,rminutes]) + ".";
      if (rhours > 1 && rminutes === 1) return this.$t("components.invitePreview.inviteAllLineDurationHoursMinute", [rhours,rminutes]) + ".";
      if (rhours > 1 && rminutes > 1) return this.$t("components.invitePreview.inviteAllLineDurationHoursMinutes", [rhours,rminutes]) + ".";
      if (rhours === 0 && rminutes === 1) return this.$t("components.invitePreview.inviteAllLineDurationMinute", [rminutes]) + ".";
      if (rhours === 0 && rminutes > 1) return this.$t("components.invitePreview.inviteAllLineDuration", [rminutes]) + ".";
      if (rhours === 1 && rminutes === 0) return this.$t("components.invitePreview.inviteAllLineDurationHour", [rhours]) + ".";
      if (rhours > 1 && rminutes === 0) return this.$t("components.invitePreview.inviteAllLineDurationHours", [rhours]) + ".";

    },
  cleanForm() {
    this.doIgnoreRule = true;
    this.selectedUserName = null;
    this.selectedEmail = null;
    this.visFunction = '';
    this.mobilePhoneNumber = '';
    this.phoneNumberInput = '';
    this.phoneNumberParsed  = '';

  },
    handlerDisableItem(item) {
      if ( this.disableItem ){
        if (this.atUserLimit) return;
        return this.allSelectedUsers.find(e => e.email === item.email || e.name === item.name || e.id === item.id || e.uuid === item.uuid) != undefined;
      }
    },
    handleSelect(evt) {
      if (evt && evt.email && evt.name) {
        this.selectedEmail = evt.email;
        this.selectedUserName = evt.name;
      }
    },
    validateDuplication(iemail) {
      const oemail = this.userListToSend.find((e) => e.email === iemail);
      if (oemail) {
        this.isdup = true;
        this.dupError = "duplicated email";
      } else {
        this.isdup = false;
        this.dupError = "";
      }
    },
    async precheckNewVisitor() {
      // check if email is already registered
      if (this.selectedEmail && this.selectedEmail.indexOf('@') !== -1){
        const { key, found } = await isEmailRegistered(this.selectedEmail);
          if (found) {
            dispatchErrorAlert(this.$t('components.inviteGuestPanel.errAlreadyUser'))
            return;
          }
      }
      
      this.doIgnoreRule = false;
      this.validData = true;
      let visitorId;
      const tmpArray = [];
      if (this.isProcessing) return;
      if (this.validData) {
      this.isProcessing = true;
      const element = {
        name: this.selectedUserName,
        email: this.selectedEmail || '',
        mobilePhone: this.phoneNumberParsed || '',
      };
          if (element && element.name){
            visitorId = await this.simpleUserCreate(element);
            if ( tmpArray.indexOf(visitorId) === -1 ){
              tmpArray.push(visitorId)
            }
          }
      }
      this.isProcessing = false;
      this.userListToSend = [];
      return tmpArray;
    },

    async simpleUserCreate(element, isWaitingRoom) {
      // console.log('element ', element)
      const uuid = actingAsUuidStore.getState();
      const odata = {
        visitorId: uuidv4(),
        name: element.name,
        email: element.email,
        mobilePhone: element.mobilePhone || '',
        inviteText: '',
        subject: '',
        subjectDisplay: '',
        created: Date.now(),
        linkDateFrom: this.linkDateFrom || "",
        linkTimeFrom: this.linkTimeFrom || "",
        linkDateTo: this.linkDateTo || "",
        linkTimeTo: this.linkTimeTo || "",
        internalnote: "",
        instalink: "",
        duration: this.durationMin || "",
        tariff: "",
        isSupportCall: false,
        isWaitingRoom: false,
        isOneTime: false,
        function: this.visFunction,
        inviters: {
          [uuid]: true,
        },
        userInviter: uuid,
      };

       const inviteLink = await createVisitor(odata, {
        sendMail: false, 
      });
       odata.instalink = this.instalink = inviteLink;
      if ( uuid !== store.state.ownUUID ){
        const tmpObj = {
          uuid: uuid,
          visitorData: odata
        }
        setVisitorForRepresentiveEffect(tmpObj);
      }else{
        store.setUserVisitor(odata.visitorId, odata);    
      }
      this.cleanForm();
      return odata.visitorId;
    },

     async handleUsersInvite(visitorUsers, meeting) {
        for (let index = 0; index < visitorUsers.length; index++) {
          const visitorId = visitorUsers[index];
          const element = (store.state.group[visitorId].user || {})
          if (element && element.visitorData){
             await this.simpleUserInvite(element,true,meeting)
          } 
        };
     },

    async simpleUserInvite(element, isWaitingRoom, meeting) {
      const uuid = actingAsUuidStore.getState();
      let dateFrom, dateTo, timeTo, timeFrom, duration;

      dateFrom = moment(meeting.start).format("DD.MM.YYYY");
      timeFrom = moment(meeting.start).format("HH:mm");
      dateTo = moment(meeting.end).format("DD.MM.YYYY");
      timeTo = moment(meeting.end).format("HH:mm");
      duration = moment(meeting.end).diff(moment(meeting.start), 'minutes');

      const subject = this.subject || this.$t("components.inviteVisitor.line1", [
        window.location.hostname,
        (this.getUserTitel(this.ownUUID) + " " + store.state.user.name).trim(),
      ]);

        let inviteText = this.fixedDetailText(dateFrom, dateTo, timeFrom, timeTo, duration);
        inviteText += "\n" +
          this.$t("components.inviteVisitor.yourLink") +
          ":\n" +
          "%INVITE_LINK%\n\n" + this.$t('generics.declineLinkText') + ":\n%DECLINE_LINK%" +
          "\n" +
          "\n" +
          this.$t('components.inviteVisitor.mobileNote') +
          "\n" ;
      // }

      const odata = {
        visitorId: element.visitorData.visitorId,
        name: element.visitorData.name,
        email: element.visitorData.email,
        mobilePhone: store.state.group[element.visitorData.visitorId].user.mobilePhone || '',
        inviteText: inviteText,
        subject: subject,
        subjectDisplay: subject,
        created: element.visitorData.created,
        linkDateFrom: meeting.linkDateFrom || "",
        linkTimeFrom: meeting.linkTimeFrom || "",
        linkDateTo: meeting.linkDateTo || "",
        linkTimeTo: meeting.linkTimeTo || "",
        internalnote: "",
        instalink: "",
        duration: meeting.durationMin || "",
        tariff: "",
        function: this.visFunction,
        isSupportCall: false,
        isWaitingRoom: true,
        isOneTime: false,
        inviters: {
          [uuid]: true,
        },
        userInviter: uuid,
      };

      const options = {
       eventUUID: meeting.uuid 
      };

      sendVisitorEmail(odata, options);
    },

        fixedDetailText(dateFrom, dateTo,timeFrom, timeTo, duration) {
      let line1 =
        this.state.user.name +
        " " +
        this.$t("components.inviteVisitor.hasInvitedYou") +
        "\n";
      let line2 =
        this.$t("components.inviteVisitor.conferenceStartsAt") +
        " " +
        dateTo +
        " " +
        this.$t("components.inviteVisitor.at") +
        " " +
        timeFrom +
        " " +
        this.$t("components.meetingLineModal.hours") +
        "\n";
      let line2_1 = "";
       if (this.getDurationMin){
        line2_1 = line2_1 + "\n" + this.getDurationPhrase(this.getDurationMin) + 
        " " +
        "\n"; 
       }
      let line4 =
        this.$t("components.inviteVisitor.linkGoodUntil") +
        " " +
        dateFrom +
        "\n";
      if (!dateTo || !timeTo) {
        line2 = "";
      }
      if (!dateFrom) {
        line4 = "";
      }
      if (!duration) {
        line2_1 = "";
      }
      return line2 + line2_1 + line4;
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getDurationMin() {
      if (
        !this.durationMin ||
        this.durationMin.length <= 0 ||
        this.durationMin <= 0
      ) {
        return null;
      } else {
        return this.durationMin;
      }
    },  

    async addUserToList() {
      this.doIgnoreRule = false;
      if ( !this.validateEmail) return;
      this.dup = false;
      const oemail = this.userListToSend.find(
        (e) => e.email === this.selectedEmail
      );
      // console.log('oemail',oemail)
      if (oemail) {
        this.dup = true;
        return;
      }
      // check if email is already registered
      const { key, found } = await isEmailRegistered(this.selectedEmail);
      if (found) {
        dispatchErrorAlert(this.$t('components.inviteGuestPanel.errAlreadyUser'))
        return;
      }

      // add to data if new
      const extant = await store.state.user.outsideInvitees.find(
        (e) => e.email === this.selectedEmail
      );

      if (!extant) {
        const existing = store.state.user.outsideInvitees;
        const newItem = {
          id: uuidv4(),
          name: this.selectedUserName,
          email: this.selectedEmail,
        };
        existing.push(newItem);
        // console.log(existing)
        store.setOutsideInvitees(existing);
      }
      this.userListToSend.push({
        name: this.selectedUserName,
        email: this.selectedEmail,
      });
     // console.log('users to send ',this.userListToSend)
      this.cleanForm();
    },

    async removeUserFromList(){
      if ( !this.validateEmail) return;
        const filtered = await store.state.user.outsideInvitees.filter(
        (e) => e.email !== this.selectedEmail
        );

      store.setOutsideInvitees(filtered);
      this.cleanForm();
    },
   
    removeUserList(index) {
      this.userListToSend.splice(index, 1);
    },
    onTelInput({ number, isValid, country }) {
      this.phoneNumberParsed = (number.international || '').replace(/[-\s]/g, "");
      this.phoneInputValid = isValid;
    },
    checkEmail(evt){
      if (/^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(evt.target.value) && this.selectedUserName !== null){
        this.selectedEmail = evt.target.value;
      }else{
         this.selectedEmail = null;
      }
    },
  },
  computed: {
     getNoticeText(){
      const noteText = this.$t('components.inviteVisitor.mobileNote');
      this.setNoticeText(noteText);
      return noteText;
    },
    isWhatsAppInviteAvailable() {
      return webLicensedBaseFeatures.isConferenceWhatsAppInviteAvailable;
    },
     emailDisabled(){
      if (this.atUserLimit || 
          this.userSelected || 
          this.selectedUserName == '' || 
          this.selectedUserName === null || 
          (this.phoneNumberInput || '').length > 0){
            return true  
      } else {
        return false
      }    
    },
    phoneDisabled(){
      if (this.atUserLimit || 
        this.userSelected || 
        this.selectedUserName == '' || 
        this.selectedUserName === null || 
        (this.selectedEmail || '').length > 0){
          return true  
      } else {
        return false
      }
    },
    userSelected(){
      return this.isNewConferenceForm && this.userListToSend.length === 1;
    },
    ignoreRule(){
      return this.doIgnoreRule;
    },
     validateEmail(){
      return this.emailValidator.test(this.selectedEmail)
    },
    atUserLimit() {
      if (!this.allSelectedUsers) return false;
      if ( this.isNewConferenceForm ){
        return ((this.allSelectedUsers.length + this.userListToSend.length) >= this.rowNumbers);
      }else{
        return ((this.allSelectedUsers.length + this.userListToSend.length) >= 4);
      }
    },
    filteredInvitees() {
      let myInvitees = store.state.user.outsideInvitees;
      return myInvitees.filter((invitee) => {
        for (
          let index = 0;
          index < Object.keys(this.userListToSend).length;
          index++
        ) {
          const element = Object.keys(this.userListToSend)[index];
          if (invitee.email === this.userListToSend[element].email) {
            return false;
          }
        }
        return true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.positionRelative{
  position: relative;
}
.whatsAppIcon{
  position: absolute;
  top: 9px;
  right: 20px;
}
.inputPhone2FA{
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !important;
  border-bottom: none !important;
  height: 38px;
}
.rowOdd{
  background-color: #d8dfe02b  !important;
  height: 39px;
}
.rowEven{
  background-color: #b9c2c42b  !important;
  height: 39px;
}
.buttonAddUserToList {
  position: relative;
  top: 13px;
}
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.cursorPointer {
  cursor: pointer;
  font-size: 27px;
  margin-top: 8px;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.font-14{
  font-size: 14px !important;
}
</style>
