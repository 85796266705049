var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "650",
        attach: _vm.getAttach,
        "hide-overlay": _vm.getAttach ? true : false
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t(
                "components.conferenceForm.titleInviteVisitor"
              ),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.isConferenceCall && _vm.existingGuests.length > 0
                ? _c(
                    "v-col",
                    { staticClass: "px-6", attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("components.conferenceForm.inviteGuest")
                            ) +
                            " "
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-combobox", {
                        staticClass: "mt-3",
                        attrs: {
                          items: _vm.existingGuests,
                          "search-input": _vm.searchGuest,
                          select: "",
                          "item-text": "name",
                          "item-value": "uuid",
                          label: _vm.$t(
                            "components.conferenceForm.vOfficeUsers"
                          ),
                          dense: "",
                          outlined: "",
                          "hide-details": "",
                          autofocus: "",
                          "item-disabled": _vm.userIsInCall
                        },
                        on: {
                          "update:searchInput": function($event) {
                            _vm.searchGuest = $event
                          },
                          "update:search-input": function($event) {
                            _vm.searchGuest = $event
                          },
                          change: _vm.callWRVisitor
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c("div", [
                                    _c("span", {
                                      staticClass: "statusSpan",
                                      style: {
                                        background: data.item.statusColor
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      style: { marginLeft: "25px" },
                                      domProps: {
                                        textContent: _vm._s(data.item.name)
                                      }
                                    })
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2144998801
                        ),
                        model: {
                          value: _vm.selectedGuest,
                          callback: function($$v) {
                            _vm.selectedGuest = $$v
                          },
                          expression: "selectedGuest"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("ConferencesDirectCallMask", {
                    ref: "directCallMaskRef",
                    attrs: {
                      setNoticeText: _vm.setNoticeText,
                      dataConference: _vm.dataConference
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _vm.directCallMaskRef
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        color: "primary",
                                        disabled:
                                          !_vm.directCallMaskRef.validData ||
                                          _vm.directCallMaskRef.isProcessing
                                      },
                                      on: { click: _vm.addVisitor }
                                    },
                                    on
                                  ),
                                  [_vm._v(_vm._s(_vm.$t("generics.add")))]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1113008675
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generics.add")))])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }