var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { class: { "mx-0": _vm.isFromModal, "fill-height": !_vm.isFromModal } },
    [
      !_vm.isFromModal
        ? _c(
            "v-col",
            {
              staticClass: "primary backgroundImage hidden-sm-and-down",
              attrs: { cols: "6", md: 6, xl: 6 }
            },
            [
              _c("div", { staticClass: "fill-half-width divContentText" }, [
                _c(
                  "div",
                  { staticClass: "logoLeftsideDiv" },
                  [
                    _c("v-img", {
                      attrs: {
                        src: "assets/icon.png",
                        "max-height": "150",
                        "max-width": "150",
                        contain: ""
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "font-weight-bold mb-2" }, [
                  _vm._v(_vm._s(_vm.getUrl))
                ]),
                _vm._v(" "),
                _vm.isFromRegister
                  ? _c(
                      "p",
                      { staticClass: "mb-0 customFontSize font-weight-medium" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.checkIfFree
                              ? _vm.$t(
                                  "components.companyRegister.domainIsFree"
                                )
                              : _vm.$t(
                                  "components.subdomainTaken.namespaceNotFree"
                                )
                          )
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "copyRightDiv" }, [
                _vm._v("RA-MICRO Software AG © 2024")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        {
          staticClass: "formRightSide",
          attrs: {
            cols: _vm.isFromModal ? 12 : 6,
            md: _vm.isFromModal ? 12 : 6,
            sm: 12,
            xs: 12,
            xl: _vm.isFromModal ? 12 : 6
          }
        },
        [
          _c(
            "div",
            {
              class: {
                contentRightSideModal: _vm.isFromModal,
                contentRightSide: !_vm.isFromModal
              }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "w-100 mx-0 my-0 justify-space-between",
                  attrs: { flex: "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0 py-0", attrs: { align: "left" } },
                    [
                      !_vm.isFromModal
                        ? _c(
                            "v-card-title",
                            { staticClass: "titleSize d-flex px-0" },
                            [
                              _vm.isFromModal
                                ? _c("v-img", {
                                    attrs: {
                                      src: "assets/icon.png",
                                      "max-height": "30",
                                      "max-width": "30",
                                      contain: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "font-weight-medium",
                                class: { "pl-4": _vm.isFromModal },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "components.companyRegister.regPaypal.headline"
                                    )
                                  )
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isFromModal ? _c("v-divider") : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _vm.isAdmin
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0 priceStyle mt-6" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 px-0",
                                  attrs: { cols: "10" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "titleSize px-0 py-0" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.companyRegister.regPaypal.subtitle"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 px-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "titleSize px-0 py-0 justifyContentEnd",
                                      attrs: { justify: "flex-end" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.companyRegister.regPaypal.price",
                                            ["Euro"]
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFromRegister
                    ? [
                        _c(
                          "v-col",
                          { staticClass: "contenText", attrs: { cols: "12" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.companyRegister.regPaypal.text1"
                                  )
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "contenText", attrs: { cols: "12" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.companyRegister.regPaypal.text2"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    : [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.getMessage()) + "\n            ")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.getMessage2()) + "\n            ")
                          ])
                        ])
                      ],
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      class: {
                        "text-left": !_vm.isFromModal,
                        "text-right": _vm.isFromModal
                      },
                      attrs: { cols: _vm.isFromModal ? 12 : 8 }
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loaderDone && _vm.isAdmin,
                            expression: "loaderDone && isAdmin"
                          }
                        ],
                        staticClass: "paypal-button-container",
                        attrs: { id: "paypal-button-container" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isFromModal
                    ? _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { cols: "4" } },
                        [
                          _vm.isFromRegister
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "verticalTop ",
                                  attrs: {
                                    depressed: "",
                                    outlined: "",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goBack()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("tour.buttonPrevious")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }