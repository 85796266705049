var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        [
          _c(
            "v-card",
            {
              style:
                "height: 100%, position: relative , " +
                _vm.getBorderByStatusFull(_vm.getPerson(_vm.user.uuid))
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c(
                                  "v-img",
                                  {
                                    staticClass: "white--text align-end",
                                    attrs: {
                                      src: _vm.getAvatarByType(_vm.user.uuid),
                                      height: "100%",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "userType" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.user.isModerator
                                              ? _vm.$t(
                                                  "components.conferenceForm.moderator"
                                                )
                                              : _vm.user.isGuest
                                              ? _vm.$t(
                                                  "components.conferenceForm.guest"
                                                )
                                              : _vm.$t(
                                                  "components.conferenceForm.user"
                                                )
                                          ) +
                                          "\n            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "gradientBg" },
                                      [
                                        _c(
                                          "v-card-subtitle",
                                          {
                                            staticClass:
                                              "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                            staticStyle: {
                                              color: "#fff !important"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                 " +
                                                _vm._s(
                                                  _vm.titelPerson(
                                                    _vm.getPerson(_vm.user.uuid)
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(_vm.getName(_vm.user)) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "frontButtons" },
                                          [
                                            _vm.user.uuid !== _vm.groupModerator
                                              ? _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      "offset-y": "",
                                                      scrollable: "",
                                                      "close-on-click": true
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _vm.user.uuid !==
                                                              _vm.groupModerator
                                                                ? _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "buttonCallFrontCard",
                                                                        staticStyle: {
                                                                          float:
                                                                            "right"
                                                                        },
                                                                        attrs: {
                                                                          icon:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "20px"
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "ellipsis-v"
                                                                            ],
                                                                            color:
                                                                              "white"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list",
                                                      [
                                                        !_vm.hasPrivilege(
                                                          _vm.user.uuid
                                                        )
                                                          ? _c(
                                                              "v-list-item",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm.isEditingGroup
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.resendInvite(
                                                                      _vm.user
                                                                        .uuid
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.inviteVisitor.resendInvLink"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.isEditingGroup &&
                                                                _vm.user.isGuest
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.checkSendMessage(
                                                                  _vm.user.uuid
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.userProfile.sendAMessage"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.preDeleteUser(
                                                                  $event,
                                                                  _vm.user
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            _vm._g(
                                                                              {
                                                                                class: !_vm.deleteActive
                                                                                  ? "deleteBasketButtonNormal"
                                                                                  : "deleteBasketButtonActive"
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.conferenceForm.delete"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "components.conferenceForm.confirmRemove"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("ButtonsCardWrapper", {
                                      attrs: {
                                        person: _vm.getPerson(_vm.user.uuid),
                                        isRegularGrid: true
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2858437226
                  )
                },
                [
                  _vm._v(" "),
                  _c("UserInfoTooltip", {
                    attrs: { person: _vm.getPerson(_vm.user.uuid) }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }