var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "menuWrapper",
      class: { buttonMoreActionsGrid: _vm.isRegularGrid }
    },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", scrollable: "", "close-on-click": true },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  !_vm.isRegularGrid
                    ? _c(
                        "v-btn",
                        _vm._g(
                          {
                            class:
                              (_vm.customStyle ? "btnAbsolute" : null) +
                              " " +
                              (_vm.isRegularGrid ? "buttonCallFrontCard" : ""),
                            attrs: {
                              id: _vm.isRegularGrid
                                ? "userActionList-" + _vm.getPersonUUID
                                : "userActionsList-" + _vm.getPersonUUID,
                              color: _vm.customStyle
                                ? _vm.wholeBarWithColors()
                                  ? "white"
                                  : "black"
                                : null,
                              icon: ""
                            }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: {
                              fontSize: _vm.isRegularGrid ? "20px" : "30px"
                            },
                            attrs: { icon: ["fal", "ellipsis-v"] }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRegularGrid
                    ? _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "spanMoreActions",
                            attrs: {
                              id: _vm.isRegularGrid
                                ? "userActionList-" + _vm.getPersonUUID
                                : "userActionsList-" + _vm.getPersonUUID
                            }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            staticClass: "iconMoreActions",
                            attrs: { icon: ["fal", "ellipsis-v"] }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _vm._v(" "),
          !_vm.isAiUser(_vm.person.user.uuid)
            ? _c(
                "v-list",
                [
                  _vm.typeData !== "waitingroom"
                    ? [
                        _vm.showCallTeam
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.callTeam()
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.userListItem.callTeam")
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.showTeamsModalEvent(
                                  _vm.person.user.uuid
                                )
                              }
                            }
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.organisationGrid.showTeams"
                                  )
                                )
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isRegularGrid &&
                        _vm.person.connected &&
                          _vm.person.user.activity !== "No status" &&
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: _vm.disableCallBtn },
                                on: {
                                  click: function($event) {
                                    return _vm.callUser(_vm.getPersonUUID)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.notificationsModal.callUser"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRegularGrid &&
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sendMessage(_vm.getPersonUUID)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userProfile.sendAMessage"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRegularGrid && _vm.isOrga && _vm.showDailyReport
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.goToDailyReport(
                                      _vm.getPersonUUID
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.adminSettingsModal.dailyReport"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRegularGrid &&
                        _vm.typeData !== "guests" &&
                        !_vm.isMobile
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.goToOrganisation(
                                      _vm.getPersonUUID
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.organisationCarousel.showInOrganigram"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRegularGrid &&
                        !_vm.isBasicView &&
                        _vm.showUploadFile &&
                        _vm.person.connected &&
                        !_vm.isMobile
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.getPersonUUID !== _vm.state.ownUUID
                                      ? _vm.sendFileTo(_vm.getPersonUUID)
                                      : _vm.openDropdown()
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPersonUUID !== _vm.state.ownUUID
                                        ? _vm.$t("generics.uploadFileMax50")
                                        : _vm.$t(
                                            "components.drawerContentCard.files"
                                          )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.personIsGuest &&
                        _vm.getPersonUUID &&
                        !_vm.isreachable
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.toggleMessageMeRequest(
                                      _vm.getPersonUUID
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.callWhenAvailable"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sendCallBackMessage(_vm.person)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.callBackTT"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isOrga &&
                        _vm.isRegularGrid &&
                        !_vm.personIsGuest &&
                        _vm.person.user &&
                        _vm.showHelpscoutButton &&
                        _vm.showVisitPage
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.openHelpsCoutModal } },
                              [_c("v-list-item-title", [_vm._v("Helpscout")])],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state.nerd && _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: !_vm.person.connected },
                                on: {
                                  click: function($event) {
                                    return _vm.personPing(_vm.person)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.userListItem.pingUser")
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                attrs: {
                                  target: "_blank",
                                  href:
                                    "mailto:" +
                                    _vm.person.user.email +
                                    "?subject=" +
                                    _vm.state.user.name +
                                    "%20from%20vOffice&body=" +
                                    _vm.emailSalutation +
                                    "%20" +
                                    _vm.person.user.name +
                                    ","
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.sendEMail"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasRepresentative(_vm.getPersonUUID)
                          ? _c(
                              "v-list-item",
                              {
                                attrs: {
                                  disabled: _vm.disableRepresentativeCallBtn
                                },
                                on: {
                                  click: function($event) {
                                    !_vm.disableRepresentativeCallBtn &&
                                      _vm.callUser(_vm.representative.user.uuid)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.callRepresentative"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.amIAdmin
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.copyUserLink(
                                      $event,
                                      _vm.getPersonUUID
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$t("generics.copylink")))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isFavorite &&
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setPersonFav(_vm.person)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.addToMyFavorites"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isFavorite
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.personIsGuest
                                      ? (_vm.deleteFavorite = true)
                                      : _vm.removePersonFav(_vm.person)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.removeFromMyFavorites"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.amIAdmin
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "pingButton",
                                on: {
                                  click: function($event) {
                                    return _vm.setShowModalSendInvitation(
                                      _vm.person
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  [
                                    _vm.person.user.accessmail
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.userListItem.sendAccessLink"
                                              )
                                            ) +
                                              "  \n              " +
                                              _vm._s(
                                                _vm.person.user.accessmail ===
                                                  "0"
                                                  ? ""
                                                  : "(" +
                                                      _vm.person.user
                                                        .accessmail +
                                                      ")"
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.person.user.accessmail
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.userListItem.sendAccessLink"
                                              )
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("font-awesome-icon", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.hasBeenOnPage,
                                          expression: "hasBeenOnPage"
                                        }
                                      ],
                                      attrs: { icon: ["fal", "check-double"] }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canRemoveGuest &&
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.setShowDeleteUser } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.section === "My Guests"
                                          ? _vm.$t(
                                              "components.userListItem.removeGuest"
                                            )
                                          : _vm.$t(
                                              "components.userListItem.removeUser"
                                            )
                                      ) +
                                      "\n          "
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.amIAdmin && _vm.typeData !== "guests"
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.changeFunction(_vm.person)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userListItem.changeFunction"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.setUserInModal } },
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.adminSettingsModal.userInfo"
                                  )
                                )
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.amIGuest &&
                        (_vm.getPersonUUID === _vm.ownUUID || _vm.amIAdmin) &&
                        _vm.typeData !== "guests"
                          ? _c(
                              "v-list-item",
                              { on: { click: _vm.toggleShowHolidaysModal } },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.holidays.setYourHolidays"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.amIAdmin && !_vm.isOrga
                          ? _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "py-0 my-0" },
                                  [
                                    _c("v-switch", {
                                      staticClass: "my-0 py-2",
                                      attrs: {
                                        label: _vm.$t(
                                          "components.userListItem.hideShowUser"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeUserVisibility(
                                            _vm.getPersonUUID
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.hideShowUser,
                                        callback: function($$v) {
                                          _vm.hideShowUser = $$v
                                        },
                                        expression: "hideShowUser"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _vm.amIAdmin &&
                                      _vm.getPersonUUID != _vm.state.ownUUID
                                        ? _c(
                                            "v-list-item",
                                            _vm._g(
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.disable2fa(
                                                      _vm.getPersonUUID
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.userListItem.disable2fa"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3241580219
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.userListItem.twofaTT")
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isOrga &&
                        _vm.isEditableView &&
                        (_vm.isMovingDepa || _vm.isMovingTeam)
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "cursorPointer",
                                on: { click: _vm.handlerMove }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n           " +
                                      _vm._s(
                                        _vm.isMovingDepa
                                          ? _vm.$t(
                                              "components.manageOrganisation.department.move"
                                            )
                                          : _vm.isMovingTeam
                                          ? _vm.$t(
                                              "components.manageOrganisation.team.move"
                                            )
                                          : ""
                                      ) +
                                      "\n          "
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeData === "waitingroom"
                    ? [
                        _vm.person.connected &&
                        _vm.hasPrivilege(_vm.state.ownUUID) &&
                        _vm.getPersonUUID !== _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                attrs: { disabled: _vm.disableCallBtn },
                                on: {
                                  click: function($event) {
                                    _vm.getPersonUUID !== _vm.state.ownUUID
                                      ? _vm.callUser(_vm.getPersonUUID)
                                      : _vm.callUser(
                                          _vm.person.user.visitorData
                                            .userInviter
                                        )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.hasPrivilege(_vm.getPersonUUID)
                                        ? _vm.$t(
                                            "components.notificationsModal.callUser"
                                          )
                                        : _vm.$t(
                                            "components.userListItem.callGuest"
                                          )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.person.user.conferenceTitle &&
                        _vm.getPersonUUID !== _vm.state.ownUUID &&
                        !_vm.hasPrivilege(_vm.getPersonUUID)
                          ? _c(
                              "v-list-item",
                              { staticClass: "cursorPointer" },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteVisitor(
                                          _vm.getPersonUUID
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("generics.deleteGuest")) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.hasPrivilege(_vm.getPersonUUID) &&
                        _vm.getPersonUUID != _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sendMessage(_vm.getPersonUUID)
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.userProfile.sendAMessage"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getPersonUUID !== _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.SendLinkToUserOrVisitor(
                                      _vm.person
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteVisitor.resendInvLink"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showUploadFile &&
                        _vm.person.connected &&
                        _vm.getPersonUUID !== _vm.state.ownUUID
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.getPersonUUID !== _vm.state.ownUUID
                                      ? _vm.sendFileTo(_vm.getPersonUUID)
                                      : _vm.sendFileTo(
                                          _vm.person.user.visitorData
                                            .userInviter
                                        )
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("generics.uploadFileMax50")
                                      ) +
                                      "\n          "
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.copyVisitorLink($event, _vm.person)
                              }
                            }
                          },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("generics.copylink")))
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.hasPrivilege(_vm.getPersonUUID) &&
                        !_vm.isConferenceVisitor(_vm.getPersonUUID)
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "cursorPointer",
                                on: {
                                  click: function($event) {
                                    _vm.showTransferVisitorModal = true
                                  }
                                }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteVisitor.transferVisitor"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPrivilege(_vm.getPersonUUID)
                          ? _c(
                              "v-list-item",
                              {
                                staticClass: "cursorPointer",
                                on: { click: _vm.setGuestInModal }
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.adminSettingsModal.userInfo"
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAiUser(_vm.person.user.uuid)
            ? _c(
                "v-list",
                [
                  _vm.isRegularGrid &&
                  _vm.person.connected &&
                    _vm.person.user.activity !== "No status" &&
                  _vm.getPersonUUID != _vm.state.ownUUID
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { disabled: _vm.disableCallBtn },
                          on: {
                            click: function($event) {
                              return _vm.callUser(_vm.getPersonUUID)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.notificationsModal.callUser")
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isFavorite && _vm.getPersonUUID != _vm.state.ownUUID
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.setPersonFav(_vm.person)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.userListItem.addToMyFavorites"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFavorite
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.personIsGuest
                                ? (_vm.deleteFavorite = true)
                                : _vm.removePersonFav(_vm.person)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.userListItem.removeFromMyFavorites"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.nerd && _vm.amIAdmin
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.copyUserLink($event, _vm.getPersonUUID)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("generics.copylink")))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      [
        _vm.showCamSettings
          ? _c("DeviceSetupModal", {
              attrs: {
                showSetupDevice: true,
                toggleModalSettings: _vm.closeMicModal
              }
            })
          : _vm._e()
      ],
      _vm._v(" "),
      [
        _vm.showInvitationModal
          ? _c("SendInvitationModal", {
              attrs: {
                closeShowInviteInfo: _vm.closeShowInviteInfo,
                showInviteInfo: _vm.showInvitationModal
              }
            })
          : _vm._e()
      ],
      _vm._v(" "),
      _vm.deleteUser
        ? [
            _c("RemoveUserModal", {
              attrs: {
                person: _vm.person,
                showModal: _vm.deleteUser,
                closeModal: _vm.setShowDeleteUser
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteFavorite
        ? [
            _c("RemoveFromFavoriteModal", {
              attrs: {
                person: _vm.person,
                showModal: _vm.deleteFavorite,
                closeModal: _vm.closeDeleteFavoriteModal
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      [
        _vm.changeUserFunction.show
          ? _c("ChangeFunctionModal", {
              attrs: {
                modalData: _vm.changeUserFunction,
                cancelChangeFunction: _vm.cancelChangeFunction
              }
            })
          : _vm._e()
      ],
      _vm._v(" "),
      [
        _vm.openHolidaysCalendar
          ? [
              _c("HolidaysCalendarModal", {
                attrs: {
                  userUUID: _vm.getPersonUUID,
                  closeModal: _vm.toggleShowHolidaysModal,
                  showModal: _vm.openHolidaysCalendar
                }
              })
            ]
          : _vm._e(),
        _vm._v(" "),
        [
          _vm.showTransferVisitorModal
            ? _c("TransferVisitorModal", {
                attrs: {
                  closeTransferVisitorModal: _vm.closeTransferVisitorModal,
                  showTransferVisitorModal: _vm.showTransferVisitorModal,
                  doTransferVisitor: _vm.doTransferVisitor,
                  visitor: _vm.person
                }
              })
            : _vm._e()
        ]
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }