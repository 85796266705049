var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _vm.isAllowed
        ? _c(
            "v-card",
            {
              staticClass: "mx-auto elevation-0 text-center",
              attrs: { "fill-height": "", flat: "", "max-width": "100%" }
            },
            [
              _c(
                "v-card-subtitle",
                { staticClass: "text-center mt-5 mb-5 subtitleWelcome" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.subdomainAvailable.thisDomainIsAvailableForYourSetup"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btnWelcome",
                  attrs: { color: "primary", isLarge: true },
                  on: {
                    click: function($event) {
                      return _vm.setSetUpConfigRoute("configuration")
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.subdomainAvailable.registerNow"))
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "mx-auto elevation-0 text-center",
              attrs: { "fill-height": "", flat: "", "max-width": "100%" }
            },
            [
              _c("v-img", {
                staticClass: "imgLogo",
                attrs: {
                  contain: "",
                  src: "assets/icon.png",
                  "lazy-src": "assets/icon.png"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-subtitle",
                { staticClass: "text-center mt-5 mb-5 subtitleWelcome" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.subdomainAvailable.nameNotAllowed")
                    )
                  )
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }