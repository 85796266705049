var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "550" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.info"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-5", style: { width: "100%", margin: 0 } },
            [
              _c("v-card-text", [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("components.adminSettingsModal.removeICEServer")
                      )
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            {
              attrs: {
                closeModalFunction: _vm.closeModal,
                hideDefaultButtons: true
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-3 buttonIconPrimaryFooter footerButton px-2",
                  attrs: { icon: "", tile: "", color: "primary" },
                  on: { click: _vm.remove }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("generics.accept")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3 buttonIconPrimaryFooter footerButton px-2",
                  attrs: { icon: "", tile: "", color: "primary" },
                  on: { click: _vm.closeModal }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("generics.cancel")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }