var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { right: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-checkbox",
                    _vm._g(
                      {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          "hide-details": "",
                          disabled: _vm.multiUserCallQueueStore.length + 1 > 18
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.addUserToCallQueue.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.checkBox,
                          callback: function($$v) {
                            _vm.checkBox = $$v
                          },
                          expression: "checkBox"
                        }
                      },
                      on
                    )
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("components.organisationGrid.addUserGroupCallTT"))
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }