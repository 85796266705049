var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c(
        "div",
        { staticClass: "blinken" },
        [
          _c(
            "v-expansion-panels",
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("Messages " + _vm._s(_vm.messagesToMe.length))
                  ]),
                  _vm._v(" "),
                  _c("v-expansion-panel-content", [
                    _c("span", [_vm._v("!")]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.messagesToMe, function(msg) {
                        return _c("li", { key: msg.messageUUID }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(msg.messageUUID) +
                              " " +
                              _vm._s(msg.header) +
                              " " +
                              _vm._s(msg.body) +
                              " " +
                              _vm._s(msg.date) +
                              " "
                          ),
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.deleteThis(msg.messageUUID)
                                }
                              }
                            },
                            [_vm._v("DEL")]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("LogFilter"),
          _vm._v(" "),
          _c("DomainComp", { attrs: { domain: _vm.domain } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }