var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showBeaconDomainWhitelistModal,
        callback: function($$v) {
          _vm.showBeaconDomainWhitelistModal = $$v
        },
        expression: "showBeaconDomainWhitelistModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.adminSettingsModal.beaconDomainWhitelist"
                ),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              { staticClass: "py-0 pt-4" },
              [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-checkbox", {
                          staticClass: "py-2 px-2",
                          attrs: {
                            "hide-details": "",
                            label: _vm.$t(
                              "components.adminSettingsModal.beaconActiveWhitelist"
                            )
                          },
                          on: {
                            change: _vm.changeEnabledBeaconDomainWhitelist
                          },
                          model: {
                            value: _vm.enabledBeaconDomainWhitelist,
                            callback: function($$v) {
                              _vm.enabledBeaconDomainWhitelist = $$v
                            },
                            expression: "enabledBeaconDomainWhitelist"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-item",
                  [
                    _c("v-list-item-content", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex py-2 px-2 align-center justify-space-between"
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: !_vm.enabledBeaconDomainWhitelist,
                              label: _vm.$t("generics.domain")
                            },
                            model: {
                              value: _vm.domain,
                              callback: function($$v) {
                                _vm.domain = $$v
                              },
                              expression: "domain"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                disabled: !_vm.enabledBeaconDomainWhitelist,
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addDomainWhitelist()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.adminSettingsModal.beaconAddDomain"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.enabledBeaconDomainWhitelist
                  ? _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "contentDomainList" },
                          _vm._l(_vm.domainsWhitelist, function(domain) {
                            return _c(
                              "div",
                              {
                                key: domain,
                                staticClass:
                                  "d-flex py-2 px-2 align-center justify-space-between"
                              },
                              [
                                _c("span", [_vm._v(_vm._s(domain))]),
                                _vm._v(" "),
                                _c("DeleteBasket", {
                                  attrs: {
                                    indexData: domain,
                                    delFunction: _vm.deleteDomainWhitelist,
                                    fontSize: "20",
                                    prevent: "false"
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }