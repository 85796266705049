var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icons iconsRemote",
      attrs: { id: "remoteBar_" + _vm.participant.participantId },
      on: {
        mousemove: function($event) {
          _vm.moveMouserCardRemote
            ? _vm.moveMouserCardRemote(_vm.participant.participantId)
            : false
        },
        mouseleave: function($event) {
          _vm.hideRemoteBar ? _vm.hideRemoteBar() : false
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "buttosContainer" },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      !_vm.getIsConferenceHall
                        ? _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.amIModerator &&
                                        !_vm.isUserModerator,
                                      expression:
                                        "amIModerator && !isUserModerator"
                                    }
                                  ],
                                  staticClass: "buttonsCall",
                                  attrs: { icon: "", color: "white" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.toggleRemoteMic(
                                        _vm.participant
                                      )
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showIconsMic == "mic",
                                    expression: "showIconsMic == 'mic'"
                                  }
                                ],
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "microphone"] }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showIconsMic == "micMuted",
                                    expression: "showIconsMic == 'micMuted'"
                                  }
                                ],
                                staticClass: "microphone-slash",
                                attrs: { src: "/img/micro-red.svg" }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.showIconsMic == "micMutedByAdmin",
                                    expression:
                                      "showIconsMic == 'micMutedByAdmin'"
                                  }
                                ],
                                staticClass: "microphone-slash",
                                attrs: {
                                  src:
                                    "/img/microphone-moderator-white-slash-red.svg"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.participantsMuted.indexOf(
                                          _vm.participant.participantId
                                        ) == -1,
                                      expression:
                                        "participantsMuted.indexOf(participant.participantId) == -1"
                                    }
                                  ],
                                  staticClass: "progressBarBridge",
                                  attrs: {
                                    id:
                                      "remotAudioBar-" +
                                      _vm.participant.participantId
                                  }
                                },
                                [
                                  _c("div", {
                                    staticClass: "progressBarLevelBridge"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.micMutedByAdmin.indexOf(
                      _vm.participant.participantId
                    ) !== -1
                      ? "moderador"
                      : _vm.participantsMuted.indexOf(
                          _vm.participant.participantId
                        ) == -1
                      ? _vm.$t("components.callsContent.micOff")
                      : _vm.$t("components.callsContent.micOn")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.amIModerator &&
                                    !_vm.isUserModerator &&
                                    !_vm.getIsConferenceHall,
                                  expression:
                                    "amIModerator && !isUserModerator && !getIsConferenceHall"
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleCameraOffRemote(
                                    _vm.participant.participantId
                                  )
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.participantsCameraOff[
                                  _vm.participant.participantId
                                ],
                                expression:
                                  "!participantsCameraOff[participant.participantId]"
                              }
                            ],
                            staticClass: "microphone-slash",
                            attrs: { src: "/img/user-camera-white.svg" }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.participantsCameraOff[
                                    _vm.participant.participantId
                                  ],
                                expression:
                                  "participantsCameraOff[participant.participantId]"
                              }
                            ],
                            staticClass: "microphone-slash",
                            attrs: {
                              src: "/img/user-camera-slash-red-white.svg"
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.participantsCameraOff[_vm.participant.participantId]
                      ? _vm.$t(
                          "components.callsContent.stopCameraOffParticipant"
                        )
                      : _vm.$t("components.callsContent.cameraOffParticipant")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showHangUpButton,
                                  expression: "showHangUpButton"
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleHangUp.apply(null, arguments)
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            staticClass: "faPhoneRotate",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "phone"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.amIModerator && !_vm.fullScreenActive,
                                  expression:
                                    "!amIModerator && !fullScreenActive"
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: {
                                icon: "",
                                color:
                                  (_vm.isOnlyOneCallUser || _vm.amIGuest) &&
                                  _vm.unseeMessageOneCallUser
                                    ? "red"
                                    : "white"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleMessage.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "paper-plane"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.userProfile.sendAMessage")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.isMobile && !_vm.isOnlyOneCallUser,
                                  expression: "!isMobile && !isOnlyOneCallUser"
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleFullscreenRoutine(
                                    _vm.participant.participantId
                                  )
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.actualFullScreenTypeRemote ==
                                  "maximizeScreen",
                                expression:
                                  "actualFullScreenTypeRemote == 'maximizeScreen'"
                              }
                            ],
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "expand-wide"] }
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.actualFullScreenTypeRemote ==
                                  "fullscreen",
                                expression:
                                  "actualFullScreenTypeRemote == 'fullscreen'"
                              }
                            ],
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "compress-wide"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.actualFullScreenTypeRemote == "maximizeScreen"
                        ? _vm.$t("generics.fullscreen")
                        : _vm.actualFullScreenTypeRemote == "fullscreen"
                        ? _vm.$t("generics.minimize")
                        : _vm.$t("components.callsContent.defaultView")
                    ) +
                    "\n      "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.isMobile &&
                                    !_vm.isOnlyOneCallUser &&
                                    _vm.getIsConferenceHall,
                                  expression:
                                    "!isMobile && !isOnlyOneCallUser && getIsConferenceHall"
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.setUserInModal(
                                    _vm.participant.userUUID
                                  )
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "info-circle"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.adminSettingsModal.userInfo")) +
                    "\n      "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.isUserModerator &&
                                    _vm.amIModerator &&
                                    !_vm.hasPrivilege &&
                                    _vm.getActiveUsers < 15 &&
                                    !_vm.currentDesktop &&
                                    !_vm.presenterModeInitiatedBy &&
                                    _vm.isPayAvailable &&
                                    !_vm.getIsConferenceHall,
                                  expression:
                                    "\n            !isUserModerator &&\n            amIModerator &&\n            !hasPrivilege &&\n            getActiveUsers < 15 &&\n            !currentDesktop &&\n            !presenterModeInitiatedBy &&\n            isPayAvailable &&\n            !getIsConferenceHall\n          "
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.requestPayment()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("img", {
                            staticClass: "currency",
                            attrs: {
                              src: "img/icons/facepay-white.svg",
                              "lazy-src": "img/icons/facepay.svg"
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.requestPayment")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (!_vm.isUserModerator &&
                                      _vm.amIModerator &&
                                      _vm.hasPrivilege &&
                                      _vm.getActiveUsers < 15 &&
                                      !_vm.currentDesktop &&
                                      !_vm.presenterModeInitiatedBy &&
                                      !_vm.getIsConferenceHall) ||
                                    (!_vm.isUserModerator &&
                                      _vm.amIModerator &&
                                      _vm.hasPrivilege &&
                                      _vm.getActiveUsers < 15 &&
                                      !_vm.currentDesktop &&
                                      !_vm.presenterModeInitiatedBy &&
                                      _vm.getIsConferenceHall &&
                                      _vm.getIsParticipantIsPodium(
                                        _vm.participant.userUUID
                                      )),
                                  expression:
                                    "\n            (!isUserModerator &&\n            amIModerator &&\n            hasPrivilege &&\n            getActiveUsers < 15 &&\n            !currentDesktop &&\n            !presenterModeInitiatedBy && !getIsConferenceHall) || \n            ( !isUserModerator &&\n            amIModerator &&\n            hasPrivilege &&\n            getActiveUsers < 15 &&\n            !currentDesktop &&\n            !presenterModeInitiatedBy && getIsConferenceHall && getIsParticipantIsPodium(participant.userUUID))\n          "
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleModerator(
                                    _vm.participant.participantId
                                  )
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "users-crown"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.remotePersonCall.transferModerator")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.amIModerator &&
                                    !_vm.fullScreenActive &&
                                    _vm.getActiveUsers < 15 &&
                                    !_vm.currentDesktop &&
                                    !_vm.presenterModeInitiatedBy,
                                  expression:
                                    "\n            amIModerator &&\n            !fullScreenActive &&\n            getActiveUsers < 15 &&\n            !currentDesktop &&\n            !presenterModeInitiatedBy\n          "
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleMessage()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: {
                              icon: ["fal", "paper-plane"],
                              color:
                                (_vm.isOnlyOneCallUser || _vm.amIGuest) &&
                                _vm.unseeMessageOneCallUser
                                  ? "red"
                                  : "white"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.userProfile.sendAMessage")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "content-class":
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "ttFullScreen"
                    : undefined,
                attach:
                  _vm.actualFullScreenTypeRemote == "fullscreen"
                    ? "#remoteBar_" + _vm.participant.participantId
                    : false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.userHasEmail &&
                                    _vm.amIModerator &&
                                    _vm.getActiveUsers < 15 &&
                                    !_vm.currentDesktop &&
                                    !_vm.presenterModeInitiatedBy,
                                  expression:
                                    "\n            userHasEmail &&\n            amIModerator &&\n            getActiveUsers < 15 &&\n            !currentDesktop &&\n            !presenterModeInitiatedBy\n          "
                                }
                              ],
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.toggleSendEmail()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "envelope-square"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.userListItem.sendEMail")))
              ])
            ]
          ),
          _vm._v(" "),
          _vm.amIModerator &&
          (_vm.getActiveUsers >= 15 ||
            _vm.currentDesktop ||
            _vm.presenterModeInitiatedBy)
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "content-class":
                      !_vm.getIsConferenceHall ||
                      (_vm.getIsConferenceHall &&
                        (_vm.getIsParticipantIsPodium(
                          _vm.participant.userUUID
                        ) ||
                          (!_vm.getIsParticipantIsPodium(
                            _vm.participant.userUUID
                          ) &&
                            this.isFullScreen())))
                        ? "customMenu"
                        : "",
                    bottom: "",
                    "offset-x":
                      _vm.getIsConferenceHall &&
                      !_vm.getIsParticipantIsPodium(_vm.participant.userUUID) &&
                      !this.isFullScreen()
                        ? true
                        : false,
                    "z-index": "50",
                    absolute: "",
                    attach:
                      _vm.getIsConferenceHall &&
                      !_vm.getIsParticipantIsPodium(_vm.participant.userUUID) &&
                      !this.isFullScreen()
                        ? false
                        : "#remoteBar_" + _vm.participant.participantId,
                    "min-width": "220px"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "buttonsCall",
                                    attrs: { icon: "", color: "white" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px", color: "#ffffff" },
                                  attrs: { icon: ["fal", "ellipsis-v"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2617021053
                  ),
                  model: {
                    value: _vm.menuOpen,
                    callback: function($$v) {
                      _vm.menuOpen = $$v
                    },
                    expression: "menuOpen"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.isUserModerator &&
                                _vm.amIModerator &&
                                !_vm.hasPrivilege &&
                                !_vm.getIsConferenceHall,
                              expression:
                                "!isUserModerator && amIModerator && !hasPrivilege && !getIsConferenceHall"
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.requestPayment()
                            }
                          }
                        },
                        [
                          _c("v-list-item-icon", [
                            _vm.$vuetify.theme.dark
                              ? _c("img", {
                                  staticClass: "currency",
                                  attrs: {
                                    src: "img/icons/facepay-white.svg",
                                    "lazy-src": "img/icons/facepay.svg"
                                  }
                                })
                              : _c("img", {
                                  staticClass: "currency",
                                  attrs: {
                                    src: "img/icons/facepay.svg",
                                    "lazy-src": "img/icons/facepay.svg"
                                  }
                                })
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "components.callsContent.requestPayment"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                (!_vm.isUserModerator &&
                                  _vm.amIModerator &&
                                  _vm.hasPrivilege &&
                                  !_vm.getIsConferenceHall) ||
                                (!_vm.isUserModerator &&
                                  _vm.amIModerator &&
                                  _vm.getIsConferenceHall &&
                                  _vm.getIsParticipantIsPodium(
                                    _vm.participant.userUUID
                                  ) &&
                                  _vm.hasPrivilege),
                              expression:
                                "!isUserModerator && amIModerator && hasPrivilege && !getIsConferenceHall || (!isUserModerator && amIModerator && getIsConferenceHall && getIsParticipantIsPodium(participant.userUUID) && hasPrivilege)"
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleModerator(
                                _vm.participant.participantId
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "users-crown"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "components.remotePersonCall.transferModerator"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.amIModerator && !_vm.fullScreenActive,
                              expression: "amIModerator && !fullScreenActive"
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleMessage()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: {
                                  icon: ["fal", "paper-plane"],
                                  color:
                                    _vm.isOnlyOneCallUser &&
                                    _vm.unseeMessageOneCallUser
                                      ? "red"
                                      : _vm.isDark
                                      ? "white"
                                      : "black"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("components.userProfile.sendAMessage")
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.amIModerator,
                              expression: "amIModerator"
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleSendEmail()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "envelope-square"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("components.userListItem.sendEMail")
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showHangUpButtonMenu,
                              expression: "showHangUpButtonMenu"
                            }
                          ],
                          on: { click: _vm.toggleHangUp }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                staticClass: "faPhoneRotate",
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "phone"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("generics.hangUp")) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.room &&
                                _vm.getAmIModerator &&
                                _vm.presenterModeInitiatedBy &&
                                _vm.presenterModeInitiatedBy !==
                                  _vm.participant.participantId &&
                                !_vm.currentDesktop,
                              expression:
                                "\n            (room &&\n            getAmIModerator &&\n            presenterModeInitiatedBy &&\n            presenterModeInitiatedBy !== participant.participantId &&\n            !currentDesktop) \n          "
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleGiveWord(
                                _vm.participant.participantId
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "comment"] }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.wavingHand.indexOf(
                                          _vm.participant.participantId
                                        ) !== -1,
                                      expression:
                                        "wavingHand.indexOf(participant.participantId) !== -1"
                                    }
                                  ],
                                  staticClass: "toggleGiveWordBadge"
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "19px", padding: 1 },
                                    attrs: { icon: ["fal", "exclamation"] }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("components.callsContent.giveWord")
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showTakeWord,
                              expression: "showTakeWord"
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleListenerSpeakerConferenceHall(
                                _vm.participant.userUUID
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "comment-slash"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-title",
                            { staticClass: "inTwoLine" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("components.callsContent.moveToHall")
                                  ) +
                                  " \n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }