var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto primaryBackground",
      attrs: { flat: "", width: "100%" }
    },
    [
      _vm.showWaitingRoom && _vm.hasPrivilege(_vm.ownUUID)
        ? _c("WaitingRoom", {
            attrs: {
              active: _vm.active,
              waitingRoomFor: _vm.ownUUID,
              setList: _vm.setList,
              filterPerson: _vm.filterPerson,
              mini: _vm.mini
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showWaitingRoom && _vm.assistantsUser
        ? _vm._l(_vm.assistantsUser, function(uuid) {
            return _c("WaitingRoomFor", {
              key: uuid,
              attrs: {
                waitingRoomFor: uuid,
                active: _vm.active,
                setList: _vm.setList,
                filterPerson: _vm.filterPerson,
                mini: _vm.mini
              }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile && !_vm.isIpad
        ? _c("Conference", {
            attrs: {
              active: _vm.active,
              setList: _vm.setList,
              isGuest: _vm.isGuest,
              isMobile: _vm.isMobile,
              mini: _vm.mini,
              setCurrentContentVisile: _vm.setCurrentContentVisile
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.oneCallAndWaitingUser(_vm.ownUUID) &&
      _vm.hasPrivilege(_vm.ownUUID) &&
      (_vm.featureOrganizationOption || _vm.amIAdmin) &&
      !_vm.isBasicView
        ? _c("OrganisationTree", {
            attrs: {
              mini: _vm.mini,
              openSidebar: _vm.openSidebar,
              closeOnClick: _vm.closeOnClick,
              closeOnContent: _vm.closeOnContent,
              sectionSelected: _vm.sectionSelected,
              handleOrganisation: _vm.handleOrganisation,
              setActiveOrganisationSection: _vm.setActiveOrganisationSection,
              setList: _vm.setList,
              isMobile: _vm.isMobile,
              getTotalTeams: _vm.getTotalTeams,
              sendToEntities: _vm.sendToEntities,
              sendToEntitiesSelected: _vm.sendToEntitiesSelected,
              active: _vm.active
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest &&
      !_vm.isMobile &&
      !_vm.oneCallAndWaitingUser(_vm.ownUUID) &&
      _vm.hasPrivilege(_vm.ownUUID) &&
      true /* featureInfoBoard */ &&
      !_vm.isBasicView
        ? _c("infoboard")
        : _vm._e(),
      _vm._v(" "),
      _vm.state.namespaceSettings.showCoffeeBreak
        ? _c("CoffeeBreak", {
            attrs: {
              setList: _vm.setList,
              active: _vm.active,
              setCurrentContentVisile: _vm.setCurrentContentVisile,
              isGuest: _vm.isGuest,
              sortedCoffeeBreakUsers: _vm.sortedCoffeeBreakUsers,
              isMobile: _vm.isMobile,
              mini: _vm.mini
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile && !_vm.isIpad
        ? _c("SambaRoom", {
            attrs: {
              setList: _vm.setList,
              active: _vm.active,
              setCurrentContentVisile: _vm.setCurrentContentVisile,
              isGuest: _vm.isGuest,
              isMobile: _vm.isMobile,
              mini: _vm.mini
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("SupportCall", {
        attrs: { isGuest: _vm.isGuest, isMobile: _vm.isMobile, mini: _vm.mini }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }