<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <v-row flex class="w-100 mx-0 my-0 justify-space-between">
        <v-col class="px-0 py-0" align="left">
          <v-card-title class="headline">
            <v-img
              class="mr-1"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            {{ $t("components.2faModal.twoFaModalTitle") }}
          </v-card-title>
        </v-col>
      </v-row>
      <v-card-subtitle class="pt-2">
        <div class="py-2" v-if="!enabled">
          <span>{{ $t("components.2faModal.subtitleSentence") }}</span>
        </div>
        &nbsp;
        <v-row class="py-2 d-flex">
          <v-col>
            <template v-if="enabled">
              {{ $t("components.2faModal.twoFactorActiveAuthentication") }}
              <span v-if="enabled === 'sms'">{{
                $t("components.2faModal.enabledSMS")
              }}</span>
              <span v-else-if="enabled === 'pin'">{{
                $t("components.2faModal.enabledPIN")
              }}</span>
              <span v-else-if="enabled === 'otp'">{{
                $t("components.2faModal.enabledOTP")
              }}</span>
            </template>
            <span v-if="!enabled"
              >{{ $t("components.2faModal.twoFactorAuthentication") }}
              <v-tooltip top max-width="400">
                <template v-slot:activator="{ on }"
                  ><span v-on="on" class="cursorPointer">
                    <font-awesome-icon
                      color="#2a3133"
                      :icon="['fal', 'info-circle']"
                      :style="{
                        fontSize: '17px',
                        position: 'relative',
                        top: '2px',
                      }"
                    />
                  </span>
                </template>
                <span>{{
                  $t("components.2faModal.twoFactorAuthenticationInfo")
                }}</span>
              </v-tooltip>
              {{ $t("components.2faModal.disabled") }}</span
            >
          </v-col>
          <v-col class="d-flex justify-end">
            <span v-if="enabled === 'sms'"
              >{{ $t("components.2faSms.phoneNumber") }} {{ phoneNumber }}</span
            >
            <span v-if="enabled" class="ml-4">{{
              $t("components.2faModal.alternativeActivate")
            }}</span>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-row v-if="!enabled" class="mx-auto" flex>
        <v-col class="mx-4 px-0 py-0">
          <v-tooltip
            top
            max-width="400"
            v-if="state.namespaceSettings.activateSMS"
          >
            <template v-slot:activator="{ on }">
              <v-btn depressed v-on="on" color="primary" @click="enableSMS">{{
                $t("components.2faModal.btnEnableSMS")
              }}</v-btn>
            </template>
            <span>{{
              $t("components.adminSettingsModal.2faActivateSMSTT")
            }}</span>
          </v-tooltip>
          <v-tooltip top v-if="state.namespaceSettings.activatePIN">
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                v-on="on"
                color="primary"
                @click="enablePIN"
                min-width="150px"
                >{{
                  state.namespaceSettings.pinProtectionType === "complex"
                    ? $t("components.2faModal.btnEnablePINComplex")
                    : $t("components.2faModal.btnEnablePIN")
                }}</v-btn
              >
            </template>
            <span>{{
              state.namespaceSettings.pinProtectionType === "complex"
                ? $t("components.2fa.complexTT")
                : $t("components.2fa.pinTT")
            }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="400"
            v-if="state.namespaceSettings.activatePassword"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                v-on="on"
                color="primary"
                @click="enableOTP"
                min-width="150px"
                >{{ $t("components.2faModal.btnEnableOTP") }}</v-btn
              >
            </template>
            <span>{{ $t("components.2fa.authenticatorTT") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-else class="px-2 btns mx-0 mb-3" flex>
        <v-col class="px-2 py-2" align="left">
          <v-btn depressed color="primary" @click="disable">{{
            $t("components.2faModal.btnDisable")
          }}</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="px-2 py-2 d-flex">
          <v-tooltip top max-width="400">
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                v-on="on"
                v-if="enabled !== 'sms' && state.namespaceSettings.activateSMS"
                color="primary"
                @click="disable('2faSms')"
                >{{ $t("components.2faModal.btnEnableSMS") }}</v-btn
              >
            </template>
            <span>{{
              $t("components.adminSettingsModal.2faActivateSMSTT")
            }}</span>
          </v-tooltip>
          <v-btn
            class="ml-2"
            depressed
            v-if="enabled !== 'pin' && state.namespaceSettings.activatePIN"
            color="primary"
            @click="disable('2faPin')"
            >{{ $t("components.2faModal.btnEnablePINShort") }}</v-btn
          >
          <v-tooltip top max-width="400">
            <template v-slot:activator="{ on }">
              <v-btn
              depressed
                v-on="on"
                class="ml-2"
                v-if="
                  enabled !== 'otp' && state.namespaceSettings.activatePassword
                "
                color="primary"
                @click="disable('2faOtp')"
                >{{ $t("components.2faModal.btnEnableOTP") }}</v-btn
              >
            </template>
            <span>{{ $t("components.2fa.authenticatorTT") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="shouldShowSetOwnTime" class="mx-auto px-2" flex>
        <v-col class="mx-2 px-0 py-0">
          <p class="text-left subtitle-1" align="left">
            {{ $t("components.adminSettingsModal.twoFaLifetimeHours") }}
          </p>
          <v-overflow-btn
            class="my-2 test"
            :items="gettwoFaLifetimeHoursByAdmin"
            v-model="selectedTwoFaLifetimeHours"
            @change="setTwoFaLifetimeHours"
          ></v-overflow-btn>
        </v-col>
      </v-row>
      <v-card
        :style="shouldAllowClose ? {} : { 'z-index': -1 }"
        :color="isDark ? '' : 'white'"
        class="py-2 pl-4 mt-4 pr-2 footerModal footersBackground"
      >
        <v-row v-if="shouldAllowClose" class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn
              @click="closeModal()"
              style="border-radius: 4px; min-width: 45px"
              icon
              tile
              outlined
              color="primary"
            >
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { status2FA, setup2FAlifetimeHours } from "../../../lib/wsMsg";
export default {
  props: ["showModal", "toggle2Modal2faSetting", "goToModal"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      selectedTwoFaLifetimeHours:
        store.state.namespaceSettings.twoFaLifetimeHours,
      enabled: false,
      smsRecovery: false,
      userSupport: false,
    };
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isDark() {
      return store.state.persisted.isDark;
    },
    phoneNumber: {
      get: function () {
        return this.state.user.mobilePhone &&
          this.state.user.mobilePhone !== "unknown"
          ? this.state.user.mobilePhone
            ? `${this.state.user.mobilePhone}`
            : ""
          : this.state.user.phone
          ? `${this.state.user.phone}`
          : "";
      },
      set: function (newValue) {
        this.state.user.mobilePhone = newValue;
      },
    },
    // the user can select the number that the admin has selected and lower #1761
    gettwoFaLifetimeHoursByAdmin() {
      let items = [];
      const twoFaLifetimeHours =
        this.state.namespaceSettings.twoFaLifetimeHours;
      if (twoFaLifetimeHours) {
        for (let index = 1; index <= twoFaLifetimeHours; index++) {
          items.push(index);
        }
        if (items.indexOf(this.selectedTwoFaLifetimeHours) == -1) {
          this.selectedTwoFaLifetimeHours = items[items.length - 1];
          this.setTwoFaLifetimeHours();
        }
        return items;
      } else {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      }
    },
    shouldAllowClose() {
      return (
        this.userSupport ||
        this.enabled ||
        (store.state.namespaceSettings.activate2FAType === "default" &&
          (!this.amIAdmin || store.state.nerd))
      );
    },
    shouldShowSetOwnTime() {
      return store.state.namespaceSettings.userSetTime && this.enabled;
    },
  },
  methods: {
    async loadData() {
      const enabled = await status2FA(true);
      this.selectedTwoFaLifetimeHours =
        enabled && enabled.method && enabled.lifetimeHours
          ? enabled.lifetimeHours
          : store.state.namespaceSettings.twoFaLifetimeHours;
      this.enabled = enabled && enabled.method ? enabled.method : false;
      this.userSupport = !!(enabled && enabled.userSupport);
      this.smsRecovery =
        enabled && enabled.method
          ? enabled.admin && enabled.smsRecovery && enabled.method !== "sms"
          : false;
    },
    async setTwoFaLifetimeHours() {
      return await setup2FAlifetimeHours(this.selectedTwoFaLifetimeHours);
    },
    closeModal() {
      this.toggle2Modal2faSetting();
    },
    enableOTP() {
      this.goToModal("2faOtp");
    },
    enablePIN() {
      this.goToModal("2faPin");
    },
    enableSMS() {
      this.goToModal("2faSms");
    },
    disable(extraParam = null) {
      this.goToModal("2faDisable", extraParam);
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.loadData();
        } else {
          this.enabled = false;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}

</style>
<style >
.theme--dark .v-list-item__title {
  color: #fff; /* Example of a light color suitable for dark themes */
}
</style>
