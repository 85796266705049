<template>
  <div> 
    <!--Start The Loop-->
    <single-note :all-notes="state.namespaceSettings.notes"></single-note>
  </div>
</template>

<script>
import store from '../../../store';
import singleNote from './singleNote.vue'
import { setNamespaceSetting } from '../../../lib/wsMsg';

export default {
  components: {
    'single-note': singleNote,
  },
  data () {
    return {
      state: store.state,
      rules: [
        value => !!value || this.$t('generics.required'),
        value => (value || "").length <= 30 || "Max 20 characters"
      ],
      
      // The Note Props
      noteTitle: '',
      noteColor: 'white-note',
      noteText: '',

      // Transitions
      opened: false,

      // Temporary IDs
      currentID: -2,
      editId: -1,
      idToCopy: -1,

      // author data
      author: store.state.ownUUID,
      authorName: (store.state.group[store.state.ownUUID].user || {}).name,
    }
  },
  computed: {
   
    // Define The Date
    todayDate () {
      const today = new Date().toLocaleDateString([], {day: '2-digit', month: '2-digit', year: '2-digt', hour: '2-digit', minute:'2-digit'});;
      return today;
    },

    // Check if the note is longer than 106 letters
    longNote () {
      if (this.noteText.length > 106) {
        return true;
      } else {
        return false;
      }
    },
    calcID () {
      if (this.theNotes.length == 0) {
        return 1;
      } else {
        return (this.theNotes[this.theNotes.length - 1].id+1);
      }
    },
    // Collect Note Data
    newNote () {
      return {
        id: this.calcID,
        title: this.noteTitle,
        date: this.todayDate,
        text: this.noteText,
        color: this.noteColor,
        long: this.longNote,
        completed: false,
        author: this.author,
        authorName: this.authorName,
        targetUsers:[],
        targetSections:[],
        targetTeams: [],
        targetDepartments: [],
        
      }
    }
  },
  methods: {
    // Toggle The Effect
    toggleTransition (id) {
      if (id >= 0) {
        this.currentID = id;
      } else if (id == -1) {
        this.currentID = -1;
      }

      if (this.opened == false) {
        this.opened = true;
      } else {
        this.currentID = -2;
        this.opened = false;
      }
    },
    // Storage
    todoSave (notes) {
      setNamespaceSetting('notes', notes);
    },
    insertNote () {
      if ((this.noteTitle || '').trim() !== '' && (this.noteText || '').trim() !== '') {
        this.theNotes.push(this.newNote);
        this.todoSave(this.theNotes);

        // Remove!
        this.noteTitle = '';
        this.noteText = '';
        this.noteColor = '#fff';
      }
    }
  }
}
</script>

<style>
.labelBold{
  font-weight: bold !important;
}
.label600{
  font-weight: 600 !important;
}
.noteDiv{
  /* width: 309px; */
  float: left;
}
/*-----Add Note-----*/
.notes .single-note input {
  width: 100%;
  border: none;
  background-color: unset;
  position: relative;
  margin-bottom: 10px;
}

.notes .single-note textarea {
  width: 100%;
  background-color: unset;
  resize: none;
  border: none;
  margin-top: -1px;
}
/*-----Single Note-----*/
.notes .single-note {
  position: relative;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11);
  transition: all 0.3s;
  cursor: move;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border: none;
}
.notes .single-note:hover {
  box-shadow: 2px 7px 9px rgba(0, 0, 0, 0.11);
}
.notes .single-note.add-note {
  cursor: initial !important;
}
.notes .single-note .notDone {
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 10px;
  /* color: rgba(0, 0, 0, 0.4); */
  cursor: pointer;
}

.notes .single-note input {
  
  font-size: 15px;
  transition: all 0.3s;
  margin-top: 0;
  height: 30px;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid var( --border);
  /* color: black; */
  margin-bottom: 10px;
}
.notes .single-note .isDone {
  text-decoration: line-through !important; 
  /* color: rgba(0, 0, 0, 0.3607843137254902) !important; */
}
.notes .single-note small {
  
  font-size: 14px;
  /* color: rgba(0, 0, 0, 0.55); */
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
}


.notes .single-note textarea:hover,
.notes .single-note p:hover{
  overflow-y: auto;
}

.notes .single-note p{
  line-height: 21px !important;
  height: 100px !important;
  margin-bottom: 16px;
}

.notes .single-note textarea,
.notes .single-note p {
  
  font-size: 15px;
  /* color: rgba(0, 0, 0, 0.69); */
  
  word-wrap: break-word;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  height: 50px;
  width: calc(100% - 20px);
  overflow: hidden;
  white-space: pre-line;
}

/*
  =====================
  Shared Note
  =====================
*/
.shared-note {
  background-color: #2fa9d8;
}
.header.shared-note img {
  position: absolute;
  right: 20px;
  width: 41%;
  top: 14px;
}
.notes .single-note.shared p {
  height: auto !important;
  overflow: unset !important;
}

/* Transitions */
.card {
  transition: all 0.5s;
}
.card-enter-active,
.card-leave-active {
  transition: all 0.3s;
}
.card-enter,
.card-leave-to {
  opacity: 0;
  transform: scale(0);
}

.card-enter-to {
  opacity: 1;
  transform: scale(1);
}

.card-move {
  opacity: 1;
  transition: all 0.5s;
}

.openDivs {
  transform: translateX(-50%) scale(1) !important;
}
.colors,
.copied {
  display: -webkit-inline-box;
  padding: 6px;
  position: absolute;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.31);
  left: 36%;
  bottom: 60px;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%) scale(0);
}
.copied {
  padding: 6px 15px;
  color: #fff;
  background-color: #3e3e3e;
  border-radius: 60px;
  font-family: 'poppin-light';
  left: 50%;
}
.colors .circle {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 50%; 
}
.colors .circle.selected {
  border: 2px dashed #777;
}
.yellow-note {
  background-color: #FFEDA5 !important;
}
.blue-note {
  background-color: #BCE0FF !important;
}
.red-note {
  background-color: #FFB2B2 !important;
}
.purple-note {
  background-color: #B0A4E6 !important;
}
.white-note, .white   {
  background-color: unset !important;
}
.whiteCircle-note   {
  background-color: #fff !important;
  border: 1px solid #ccc;
}


.theme--dark .white-note, .theme--dark .white, .theme--dark .single-note:not(.add-note) .white-note .v-list-item__title, .theme--dark .single-note:not(.add-note) .white-note .v-list-item__subtitle, .theme--dark .white-note .single-note:not(.add-note) .v-list-item__title  {
  color: white !important;
}
.single-note:not(.add-note) .v-list-item__subtitle, .single-note:not(.add-note) .v-list-item__subtitle{
  color: rgba(0,0,0,.6) !important;
}

.single-note:not(.add-note) .v-list-item__title{
  color: rgba(0,0,0,.87) !important;
}
</style>