var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.groups.length > 0
    ? _c(
        "vue-scroll",
        {
          class: {
            vueScrollTemplates: _vm.applyClass,
            vueScrollOne: _vm.applyOne,
            vueScrollTwo: _vm.applyTwo,
            vueScrollThree: _vm.applyThree
          }
        },
        _vm._l(_vm.sortedGroups, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "conf", attrs: { id: item.groupId } },
            [
              _c("TemplateListItem", {
                attrs: {
                  makeSelection: _vm.makeSelection,
                  flat: "",
                  confTemplate: item
                }
              })
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }