<template>
  <v-dialog v-model="showVotingModal" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.callsContent.qualityConnectionTitle')" />
        <v-card-text>
          <!-- <div class="mt-2">
            {{$t('components.callsContent.qualityConnection')}}
          </div> -->
           <div class="mt-2">
            {{$t('components.callsContent.qualityConnectionText')}}
          </div>
          <div class="mt-4 d-flex justify-center">
            <v-rating
              v-model="rating"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              hover
            ></v-rating>
          </div>
        </v-card-text>
        <FooterModal :showCancel="false">
          <v-btn
            color="primary"
            class="ml-2"
            :disabled="disableSaveRate"
            @click="rate"
            >{{ $t("generics.save") }}</v-btn
          >
      </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import {
  resetQualityVotingModalEvent,
  qualityVotingModalStore,
  setRateMessageModalEvent,
  setSendBugModalEvent
} from "../../effector/modals";
import { voteQualityCall } from "../../lib/wsMsg";
export default {
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    rating: 0,
  }),
  effector: {
    showVotingModal: qualityVotingModalStore,
  },
  mounted() {},
  destroyed() {},
  methods: {
    rate() {
      // const finalResult = this.rating === 0 ? 5 : this.rating;
      voteQualityCall(this.rating);
      setRateMessageModalEvent(this.rating);
      if (this.rating && this.rating < 5) {
        this.openBugModal();
      }
      this.closeModal();
    },
    closeModal() {
      if (store.state.user.activity === "inCall" || store.state.user.activity === "inRoom") {
        store.setActivityUser(store.state.user.originalActivity);
      }
      // clearTimeout(this.closeTimeout);
      resetQualityVotingModalEvent();
    },
    openBugModal() {
      setSendBugModalEvent(true)
    },
  },
  computed: {
    disableSaveRate(){
      return this.rating === 0;
    },
  }
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>