var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "close-on-click": true,
        "max-height": 370,
        "max-width": 350
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g(
                  {
                    on: {
                      click: function($event) {
                        return _vm.setTotalNotificationCall(0)
                      }
                    }
                  },
                  on
                ),
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        bordered: "",
                        color: "error",
                        content: _vm.totalNotificationCall,
                        value: _vm.totalNotificationCall,
                        overlap: ""
                      }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: "",
                                            color: _vm.phoneColorColor,
                                            id: "buttonMissedCalls"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setTotalNotificationCall(
                                                0
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm.iconsBlack
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "16px" },
                                              attrs: { icon: ["fas", "phone"] }
                                            })
                                          : _c("font-awesome-icon", {
                                              style: { fontSize: "16px" },
                                              attrs: { icon: ["fal", "phone"] }
                                            })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.drawerContentCard.missedCalls"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c("NotificationDropDown", { attrs: { notificationType: "missedCalls" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }