var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-img", {
    staticClass: "borderRadius4 cursorPointer",
    class: {
      "mx-2": !_vm.mini,
      borderRadius50: _vm.mini && _vm.isMobile,
      borderRadius4: !_vm.isMobile
    },
    attrs: {
      src: "img/default_profile_picture.png",
      contain: _vm.getPictureSize,
      "lazy-src": "assets/default_profile_picture.png"
    },
    on: {
      click: function($event) {
        _vm.setUserInModal ? _vm.setUserInModal() : null
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }