<template>
  <div>
    <v-form v-model="validData">
      <v-row flex class="w-100 mx-0 my-0">
        <!-- select waiting room or direct call -->
        <v-row class="w-100 mx-0 my-0" v-show="userListToSend.length < 4">
          <v-col cols="6" class="py-0">
              <!-- color="white" -->
            <v-text-field
              hide-details
              :disabled="atUserLimit || userSelected"
              v-model="selectedUserName"
              :rules="nameRules"
              :label="$t('components.inviteVisitor.name')"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
              <!-- color="white" -->
            <v-text-field
              hide-details
              :disabled="emailDisabled"
              v-model="selectedEmail"
              :label="$t('components.inviteVisitor.invitationEmail')"
              :rules="phoneNumberInput.length > 0 ? [] : emailRules"
              @keyup="changeEmail"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-0 my-3">
          <v-col cols="6" class="py-0">
            <v-text-field
              v-model="visFunction"
              required
              :label="$t('components.inviteVisitor.function')"

            ></v-text-field>
          </v-col>
          <template v-if="isWhatsAppInviteAvailable">
            <v-col cols="6" class="py-0 positionRelative">
              <vue-tel-input
                v-model="phoneNumberInput"
                required
                defaultCountry="DE"
                enabledCountryCode
                @onInput="onTelInput"
                name="number"
                id="numDisplay"
                class="form-control text-center input-sm direscCallMasktTlfInput"
                value
                placeholder
                autocomplete="off"
                :class="phoneDisabled ? {} : ((!phoneInputValid && phoneNumberInput || '').length > 0) ? 'borderError' : isDarkMode ?   'borderDarkMode' : 'borderLigthMode' "
                :disabled="phoneDisabled"
                v-bind="bindProps"
              >
                <!-- <v-text-field
                  color="white"
                  hide-details
                  v-model="mobilePhoneNumber"
                  dense
                  solo
                  :label="$t('components.addGroupModal.whatsAppPhone')"
                >-->
              </vue-tel-input>
              <span class="whatsAppIcon">
                <font-awesome-icon :icon="['fab', 'whatsapp']" :style="{ fontSize: '20px' }" />
              </span>
            </v-col>
          </template>
        </v-row>
        <v-row class="mx-0 my-0">
          <v-col cols="12" class="py-0 positionRelative">
            <span class="font-14">{{ getNoticeText }}</span>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </div>
</template>

<script>
// import Vue from "vue";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { createVisitor } from "../../lib/inviteHelpers";
import {
  myConferenceDetails,
  updateGroupConferenceEvent
} from "../../effector/groupConferences";
import {
  dispatchErrorAlert,
  dispatchSuccessAlert
} from "../../effector/alerts";
import { isEmailRegistered, sendInviteMailCustom } from "../../lib/wsMsg";
import {
  actingAsUuidStore,
  setVisitorForRepresentiveEffect
} from "../../effector/representative";
import { wsCall } from "../../lib/wsConnect";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";

//personToAddToGroup
export default {
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      // invitateType: "waiting",
      isProcessing: false,
      doIgnoreRule: false,
      // isforMail: false,
      // isdup: false,
      // dupError: "",
      // userNameRules: [  (v) => !!v || ""], //this.$t("generics.nameRequired")],
      emailRules: [
        v => !!v || "", //remove red mesagge ticket #132
        v => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || this.$t("generics.emailMustBeValid") //remove red mesagge ticket #132
      ],
      nameRules:[ v => !!v || ""],
      userListToSend: [],
      selectedUserName: null,
      selectedEmail: null,
      validData: false,
      isWaitingRoom: false,
      visFunction: "",
      emailValidator: /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/,
      mobilePhoneNumber: "",
      phoneNumberParsed: "",
      phoneNumberInput: "",
      phoneInputValid: false,
      // editingPhone: false,
      moment: moment,
      bindProps: {
        enabledCountryCode: true,
        enabledFlags: false,
      }
    };
  },
  effector: {
    actingAsUuid: actingAsUuidStore,
    myConferences: myConferenceDetails,
    bridgeInfoStore: bridgeInfoStore
  },
  props: [
    "disableItem",
    "existingEvent",
    "allSelectedUsers",
    "linkDateFrom",
    "linkTimeFrom",
    "linkDateTo",
    "linkTimeTo",
    "durationMin",
    "subject",
    "isNewConferenceForm",
    "rowNumbers",
    "conferenceUUID",
    "isWaitingGroup",
    "conferenceTitle",
    "closeModal",
    "setNoticeText",
    "dataConference"
  ],
  mounted() {
    EventBus.$emit("setReference", this);
  },
  watch: {},
  methods: {
    // inConferenceInvite(){
    //   console.log()
    // },
    changeEmail(){
      this.selectedEmail = this.selectedEmail.trim().toLowerCase()
    },
    onTelInput({ number, isValid, country }) {
      this.phoneNumberParsed = (number.international || "").replace(/[-\s]/g, "");
      this.phoneInputValid = isValid;
      !isValid ? (this.validData = false) : (this.validData = true);
    },
    // handlerDisableItem(item) {
    //   if ( this.disableItem ){
    //     if (this.atUserLimit) return;
    //     return this.allSelectedUsers.find(e => e.email === item.email || e.name === item.name || e.id === item.id || e.uuid === item.uuid) != undefined;
    //   }
    // },
    // handleSelect(evt) {
    //   // console.log("invitee selected or written");
    //   if (evt && evt.email && evt.name) {
    //     this.selectedEmail = evt.email;
    //     this.selectedUserName = evt.name;
    //   }
    // },
    // validateDuplication(iemail) {
    //   const oemail = this.userListToSend.find((e) => e.email === iemail);
    //   if (oemail) {
    //     this.isdup = true;
    //     this.dupError = "duplicated email";
    //   } else {
    //     this.isdup = false;
    //     this.dupError = "";
    //   }
    // },
    async precheckNewVisitor() {
      // if (this.emailUser.length < 5) {
      this.doIgnoreRule = false;
      this.validData = true;
      //   return;
      // }
      let visitorId;
      const tmpArray = [];
      if (this.isProcessing) return;
      if (this.validData && this.userListToSend.length) {
        this.isProcessing = true;
        for (let index = 0; index < this.userListToSend.length; index++) {
          const element = this.userListToSend[index];
          visitorId = await this.simpleUserInvite(element);
          if (tmpArray.indexOf(visitorId) === -1) {
            tmpArray.push(visitorId);
          }
        }
      }
      this.isProcessing = false;
      this.userListToSend = [];
      return tmpArray;
    },

    async simpleUserInvite(element, isWaitingRoom) {
      // console.log('simpleUserInvite ', element, isWaitingRoom)
      const uuid = actingAsUuidStore.getState();
       const inProgressInvite = await this.getQuickCallInvitetext();
      const odata = {
        visitorId: uuidv4(),
        name: element.name,
        email: element.email || "",
        mobilePhone: element.mobilePhone || "",
        inviteText: inProgressInvite,
        // subject: subject,
        // subjectDisplay: subject,
        created: Date.now(),
        linkDateFrom: this.linkDateFrom || "",
        linkTimeFrom: this.linkTimeFrom || "",
        linkDateTo: this.linkDateTo || "",
        linkTimeTo: this.linkTimeTo || "",
        internalnote: "",
        instalink: "",
        duration: this.durationMin || "",
        tariff: "",
        isSupportCall: false,
        isWaitingRoom: true,
        isOneTime: false,
        function: this.visFunction,
        inviters: {
          [uuid]: true
        },
        userInviter: uuid,
        conferenceUUID: !this.isWaitingGroup
          ? null
          : this.existingEvent
          ? this.existingEvent.uuid
          : this.conferenceUUID,
        conferenceTitle: !this.isWaitingGroup ? null : this.conferenceTitle,
        eventUUID: this.existingEvent ? this.existingEvent.uuid : null
      };

      const inviteLink = await createVisitor(odata, {
        sendMail: true
      });



      if (uuid !== store.state.ownUUID) {
        const tmpObj = {
          uuid: uuid,
          visitorData: odata
        };
        setVisitorForRepresentiveEffect(tmpObj);
      } else {
        store.setUserVisitor(odata.visitorId, odata);
      }
      return odata.visitorId;
    },
    async addUserToList(isFromConf = false) {
      this.doIgnoreRule = false;
      if (!this.validatePhoneOrEmail) return;
      const { key, found } = this.selectedEmail
        ? await isEmailRegistered(this.selectedEmail)
        : { key: uuidv4(), found: false };
      if (found) {
        dispatchErrorAlert(
          this.$t("components.inviteGuestPanel.errAlreadyUser")
        );
        if(this.closeModal){
          this.closeModal();
        }
        return;
      }

      if (isFromConf) {
        const newItem = {
          id: uuidv4(),
          name: this.selectedUserName,
          email: this.selectedEmail || "",
          mobilePhone: this.phoneNumberParsed || "",
          function: this.visFunction || ""
        };
        // create User
        const newVisitorId = await this.inConferenceHandleVisitor(newItem);

        return newVisitorId;
      }

      this.userListToSend.push({
        name: this.selectedUserName,
        email: this.selectedEmail || "",
        mobilePhone: this.phoneNumberParsed || ""
      });

      if (!isFromConf) {
        const newVisitorUUID = await this.precheckNewVisitor();
        const newUser = {
          uuid: newVisitorUUID[0],
          name: this.selectedUserName,
          addUserToCall: true
        };
        // console.log('newUser', newUser)
        this.cleanForm();

        return newUser;
      } else {
        this.cleanForm();
        // return newItem;
      }
    },
    // async removeUserFromList(){
    //   if ( !this.validatePhoneOrEmail) return;
    //   const filtered = await store.state.user.outsideInvitees.filter(
    //     (e) => e.email !== this.selectedEmail
    //   );
    //   store.setOutsideInvitees(filtered);
    //   this.cleanForm();
    // },
    cleanForm() {
      this.doIgnoreRule = true;
      this.selectedUserName = null;
      this.selectedEmail = null;
      this.visFunction = "";
      this.mobilePhoneNumber = "";
      this.phoneNumberInput = "";
      this.phoneNumberParsed = "";
      this.validData = false;
      this.isProcessing = false;
    },
    // removeUserList(index) {
    //   this.userListToSend.splice(index, 1);
    // },
    // checkEmail(evt){
    //   if (/^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(evt.target.value) && this.selectedUserName !== null){
    //     this.selectedEmail = evt.target.value;
    //   }else{
    //      this.selectedEmail = null;
    //   }
    // },
    async inConferenceHandleVisitor(element) {
      // console.log('element ',  this.selectedUserName,  this.selectedEmail,  this.visFunction,  this.mobilePhoneNumber  )
      const uuid = actingAsUuidStore.getState();
      const inProgressInvite = await this.getQuickInvitetext();
      const odata = {
        visitorId: element.uuid,
        name: element.name,
        email: element.email,
        mobilePhone: element.mobilePhone || "",
        inviteText: inProgressInvite,
        subject: "",
        subjectDisplay: "",
        created: Date.now(),
        linkDateFrom: this.linkDateFrom || "",
        linkTimeFrom: this.linkTimeFrom || "",
        linkDateTo: this.linkDateTo || "",
        linkTimeTo: this.linkTimeTo || "",
        internalnote: "",
        instalink: "",
        duration: this.durationMin || "",
        tariff: "",
        isSupportCall: false,
        isWaitingRoom: true,
        isOneTime: false,
        function: this.visFunction || "",
        inviters: {
          [uuid]: true
        },
        userInviter: uuid
      };

      const inviteLink = await createVisitor(odata, {
        sendMail: true
      });
      odata.instalink = this.instalink = inviteLink;
      if (uuid !== store.state.ownUUID) {
        const tmpObj = {
          uuid: uuid,
          visitorData: odata
        };
        setVisitorForRepresentiveEffect(tmpObj);
      } else {
        store.setUserVisitor(odata.visitorId, odata);
      }
      // set conference for guests
      let tmpData = this.myConferences.find(
        e => e.confId === this.dataConference.confId
      );
      if (tmpData) {
        if (tmpData.confUUIDS.indexOf(odata.visitorId) == -1) {
          const newMember = {
            isGuest: true,
            isModerator: false,
            name: odata.name,
            uuid: odata.visitorId,
            isGroup: false,
            isTeam: false
          };
          tmpData.members.push(newMember);
          tmpData.confUUIDS.push(newMember.uuid);
          await updateGroupConferenceEvent(tmpData);
          const newAttendees = this.getAttendeesObject(tmpData.confUUIDS);
          this.getConfOnlineUsers(tmpData, newAttendees);
        }
      }

      this.cleanForm();
      return odata.visitorId;
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach(uuid => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    getConfOnlineUsers(entry, newAttendees) {
      let usernames = [];
      if (entry && entry.confUUIDS) {
        entry.confUUIDS.forEach(uuid => {
          const username =
            ((store.state.group[uuid] || {}).user || {}).name || "";
          if (username && username !== "") {
            let data = { username: username, uuid: uuid };
            usernames.push(data);
          }
        });
        usernames.forEach(name => {
          let isOnline = false;
          if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
            Object.keys(this.bridgeInfoStore.calls).forEach(call => {
              if (
                this.bridgeInfoStore.calls[call].confId === entry.confId
              ) {
                isOnline = this.bridgeInfoStore.users[name.uuid] || false;
              }
            });
          }
          // console.log('isOnline', isOnline)
          // console.log('name', name.uuid)

          if (isOnline) {
            wsCall("sendToUUID", name.uuid, {
              type: "bridge-signal",
              action: "updateConferenceAttendees",
              sender: this.state.ownUUID,
              info: {
                callUUID: this.getCallUUID,
                confAttendees: newAttendees
              }
            });
          }
        });
      }
    },
     getQuickCallInvitetext() {
       let newLine = "";
      let mailBody =
        " " +
        this.$t("components.conferenceForm.youHaveBeenInvitedFrom", [
          store.state.user.name
        ]) +
        newLine +
        "\n" +
        "\n" +
        "\n" +
        this.$t("components.conferenceForm.pleaseClickTheLink") +
        "\n" +
        "%INVITE_LINK%\n\n" +
        this.getNoticeText +
        "\n";

      return mailBody;
    },

    getQuickInvitetext() {
      let newLine = "";
      if (
        this.dataConference &&
        this.dataConference.start !== "" &&
        this.dataConference.end !== ""
      ) {
        const duration = moment(this.dataConference.end).diff(
          moment(this.dataConference.start),
          "minutes"
        );
        if (parseInt(duration) > 0) {
          newLine =
            "\n" +
            "\n" +
            this.$t("components.conferenceForm.conferenceDurationMinText", [
              duration
            ]);
        }
      }
      let title = this.dataConference.title || "";
      let name = this.dataConference.name || "";
      let mailBody =
        " " +
        this.$t("components.conferenceForm.youHaveBeenInvitedFrom", [
          store.state.user.name
        ]) +
        newLine +
        "\n" +
        "\n" +
        this.$t("components.groups.group") +
        ": " +
        title +
        "\n" +
        "\n" +
        name +
        "\n" +
        "\n" +
        this.$t("components.conferenceForm.pleaseClickTheLink") +
        "\n" +
        "%INVITE_LINK%\n\n" +
        this.getNoticeText +
        "\n";

      return mailBody;
    }
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    getCallUUID() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      return remoteStreamsKey;
    },
    getNoticeText() {
      const noteText = this.$t("components.inviteVisitor.mobileNote");
      this.setNoticeText(noteText);
      return noteText;
    },
    isWhatsAppInviteAvailable() {
      return webLicensedBaseFeatures.isConferenceWhatsAppInviteAvailable;
    },
    emailDisabled() {
      if (
        this.atUserLimit ||
        this.userSelected ||
        // this.selectedUserName == "" ||
        // this.selectedUserName === null ||
        (this.phoneNumberInput || "").length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    phoneDisabled() {
      if (
        this.atUserLimit ||
        this.userSelected ||
        // this.selectedUserName == "" ||
        // this.selectedUserName === null ||
        (this.selectedEmail || "").length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    userSelected() {
      return this.isNewConferenceForm && this.userListToSend.length === 1;
    },
    // ignoreRule(){
    //   return this.doIgnoreRule;
    // },
    validatePhoneOrEmail() {
      return (
        this.phoneInputValid || this.emailValidator.test(this.selectedEmail)
      );
    },
    atUserLimit() {
      if (!this.allSelectedUsers) return false;
      if (this.isNewConferenceForm) {
        return (
          this.allSelectedUsers.length + this.userListToSend.length >=
          this.rowNumbers
        );
      } else {
        return this.allSelectedUsers.length + this.userListToSend.length >= 4;
      }
    }
    // filteredInvitees() {
    //   let myInvitees = store.state.user.outsideInvitees;
    //   return myInvitees.filter((invitee) => {
    //     for (
    //       let index = 0;
    //       index < Object.keys(this.userListToSend).length;
    //       index++
    //     ) {
    //       const element = Object.keys(this.userListToSend)[index];
    //       if (invitee.email === this.userListToSend[element].email) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   });
    // },
  }
};
</script>
<style scoped lang="scss">
.borderError {
  fieldset {
    border-color: red !important;
  }
}

.positionRelative {
  position: relative;
}
.whatsAppIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}
.direscCallMasktTlfInput {
  box-shadow: none !important;
  height: 38px;
    margin-top: 11px;
    border-radius: inherit;
    border-bottom: 1px solid ;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.borderDarkMode{
  border-color: #949494;
}
.borderLigthMode{
   border-color:#bcbcbc;
}
// #bcbcbc!important
//  #949494!important
.rowOdd {
  background-color: #d8dfe02b !important;
  height: 39px;
}
.rowEven {
  background-color: #b9c2c42b !important;
  height: 39px;
}
.buttonAddUserToList {
  position: relative;
  top: 13px;
}
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.cursorPointer {
  cursor: pointer;
  font-size: 27px;
  margin-top: 8px;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.font-14 {
  font-size: 14px !important;
}

</style>
<style lang="scss">
.theme--dark .direscCallMasktTlfInput{
  .vti__dropdown.open, .vti__dropdown:hover{
    background-color: #202020;
    color: black;
  }
  .vti__dropdown-list, .vti__dropdown-list:hover{
    background: #202020;
    color: white !important;
    border: none;
  }
  .vti__dropdown-item.highlighted, .vti__dropdown-item:hover{
    background: #202020;
    color: white !important;
  }
  .vti__country-code, .vti__dropdown-arrow{
    color: #ffffffb3 !important;
  }
}
.direscCallMasktTlfInput{
  .vti__country-code, .vti__dropdown-arrow{
    font-size: 16px!important;
  }
  .vti__dropdown-list.below{
    width: 277px;
    max-height: 103px;
  }
}
</style>
