var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-scroll",
    {
      class: "" + (!_vm.isMobile ? "vueScrollFooter" : "vueScrollFooterMobile")
    },
    [
      _c(
        "v-row",
        {
          staticClass: "px-12 mx-0 text-justify",
          style: { width: "100%", margin: 0 }
        },
        [
          _c("v-col", [
            _c("p", [
              _vm._v(
                "vOffice wurde 2020 entwickelt von der Berliner RA-MICRO Unternehmensgruppe unter Leitung des geschäftsführenden Unternehmers Rechtsanwalt Dr. Becker. Die zur Unternehmensgruppe gehörende RA-MICRO Software AG wurde von RA Dr. Becker 1982 gegründet; Diese ist seit Jahrzehnten der deutsche Marktführer für Anwalts-EDV, ihr vertraut die deutsche Anwaltschaft."
              )
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Ziel von vOffice ist:")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Maximal effiziente und produktive arbeitsteilige Internet-Zusammenarbeit im vOffice Virtuellen Büro von ortsunabhängig organisierten Mitarbeitern von kleinen und mittlerem Office-Strukturen mittels Visualisierung der Organisationsstrukturen als strukturierte Arbeitsgrundlage."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Zielgruppe sind KMUs und Organisations-Teile von Unternehmen mit gesondertem"
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Sichere Daten und Videokommunikation innerhalb der Dateninsel vOffice Virtuelles Büro."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Moderne Peer to Peer Sicherheitstechnologie Ende zu Ende verschlüsselt für hohe Sicherheitsanforderungen, wie diese insb.. für Anwälte und Steuerberater gelten."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Herstellung in der Europäischen Union (EU). Vertrieb und Support in der EU für Kunden mit Sitz in der EU."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Keine Server Datenerfassungen, keine Datensammlungen, keine Werbung."
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Einfaches und sicheres Produkt für den Arbeitsalltag - vom Unternehmer für Unternehmer."
                )
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "vOffice wird in Deutschland von Unternehmen der RA-MICRO Unternehmensgruppe vertrieben und supportet:"
              )
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "RA-MICRO Software AG Berlin exklusiv für deutsche Rechtsanwälte und Steuerberater"
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "Jurasoft AG Berlin für vOffice Betreiber mit Sitz in Deutschland"
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "vOffice AG Schweiz für vOffice Betreiber mit Sitz im Ausland"
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }