var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.nerd
    ? _c("div", { staticClass: "meter" }, [
        _vm._v("\n  " + _vm._s(_vm.avg) + "\n")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }