
<template>
  <v-navigation-drawer
    width="320"
    id="v-tour-drawer"
    v-model="primaryDrawer.model"
    :mini-variant.sync="mini"
    clipped
    :absolute="true"
    :temporary="false"
    :touchless="isMobile ? true : false"
    permanent
    class="drawerBorder primaryBackground"
    :class="`${
      hasPrivilege(ownUUID) && !isMobile
        ? 'custom-navigation-drawer'
        : hasPrivilege(ownUUID) && isMobile
        ? 'custom-navigation-drawer-mobile'
        : 'custom-navigation-drawerWaitingVisitor'
    } ${
      moveToTop && !isMobile
        ? 'sidebarAdjust'
        : moveToTop && isMobile
        ? 'sidebarAdjustMobile'
        : 'sidebarAdjust'
    } ${0 && getRoute === '/newconference' ? 'zindex-1' : ''} ${
      amInACall ? 'zindex-0' : ''
    }`"
     @update:mini-variant="openCloseSidebar()"
  >
  
    <DrawerContentCard
      :primaryDrawer="primaryDrawer"
      :openCloseSidebar="openCloseSidebar"
      v-if="hasPrivilege(ownUUID) && isMobile"
      :mini="mini"
    />
    <div
      @click.stop.prevent
      :class="mini ? 'width60': null"
      class="primaryBackground"
    >
    <DrawerContentExpansion
      :active="active"
      :setList="setList"
      :mini="mini"
      v-if="hasPrivilege(ownUUID)"
    />
    </div>
    <v-card flat class="mx-auto borderRadius0 primaryBackground" width="100%" @click.stop.prevent>
    <Users
      :active="active"
      :setList="setList"
      :mini="mini"
      :isGuest="amIGuest"
      :openSidebar="openSidebar"
      v-if="
        !amIGuest ||
        amIAdmin ||
        ((canISeeUser ||
          oneCallAndWaitingUser(ownUUID) ||
          hasPrivilege(ownUUID)) &&
          displayAllUsers)
      "
    />
    </v-card>
    <div
      :class="mini ? 'width60' : 'divDrawerContentUser'"
      class="primaryBackground"
      v-if="!searchFoundSomethingPerson"
      @click.stop.prevent
    >
       <v-card flat class="mx-auto borderRadius0 primaryBackground" width="100%">
      <InstaConference :mini="mini" :sidebar="true" v-if="hasPrivilege(ownUUID)"/>
      <Stats :setList="setList" :active="active" v-show="state.nerd"></Stats>
       </v-card>
      <DrawerContentGuest
        :active="active"
        :setList="setList"
        :mini="mini"
        v-if="isGuest && !isWaitingRoom"
      />
      <DrawerContentUser
        :active="active"
        :setList="setList"
        :mini="mini"
        :openSidebar="openSidebar"
        v-else
      />
    </div>
    <div class="askMeContainer" v-if="state.namespaceSettings.showGPT4BOT">
      <AskMe :mini="mini" :sidebar="true" v-if="hasPrivilege(ownUUID)"/>
    </div>
    <div class="divDrawerContentUser primaryBackground" v-if="searchFoundSomethingPerson">
      <DrawerContentUserSearch />
    </div>
    <Footer :mini="mini" />
  </v-navigation-drawer>
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import DrawerContentCard from "./drawerContentCard.vue";
import DrawerContentExpansion from "./drawerContentExpansion.vue";
import DrawerContentUser from "./drawerContentUser.vue";
import DrawerContentGuest from "./drawerContentGuest.vue";
import Footer from "../footer/footer.vue";
import {
  hasPrivilege,
  isWaitingRoomUser,
  oneCallAndWaitingUser,
} from "../../utils/privileges";
import { personIsInBridgeCall } from "../../utils/calls";
import DrawerContentUserSearch from "./drawerContentUserSearch.vue";
import Users from "./users.vue";
import Stats from "./stats.vue";
import InstaConference from "../navbar/instaConference.vue";
import AskMe from "../navbar/askMe.vue";
export default {
  props: ["primaryDrawer", "openCloseSidebar", "mini", "openSidebar"],
  components: {
    DrawerContentCard,
    DrawerContentExpansion,
    DrawerContentUser,
    DrawerContentGuest,
    Footer,
    DrawerContentUserSearch,
    Users,
    Stats,
    InstaConference,
    AskMe
  },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      active: "",
      isMobile: isMobile(),
    };
  },
  mounted() {},
  watch: {
    mini: {
      immediate: true,
      handler: function (value) {
        if (!this.value) {
          this.active = "";
        }
      },
    },
  },
  computed: {
    displayAllUsers() {
      return this.state.namespaceSettings.displayAllUsers;
    },
    canISeeUser() {
      if (!this.hasPrivilege(this.ownUUID)) {
        return false;
      }
      return (
        this.amIAdmin ||
        Object.keys(this.sortedPersons || {}).length < 24 ||
        (this.state.namespaceSettings.organisation || []).length === 0
      );
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    getRoute() {
      return this.$route.path;
    },
    amInACall() {
      return personIsInBridgeCall(this.state.ownUUID);
    },
    moveToTop() {
      if (!this.showMeetingLine) {
        return true;
      }
      return false;
    },
    searchFoundSomethingPerson() {
      if (this.state.searchTerm.length >= 3) {
        if (this.mini) {
          this.openCloseSidebar();
        }
      }
      return this.state.searchTerm.length >= 3;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    isWaitingRoom() {
      return isWaitingRoomUser(this.state.ownUUID);
    },
  },
  methods: {
    oneCallAndWaitingUser(uuid) {
      return oneCallAndWaitingUser(uuid);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    setList(section, value = null) {
      this.active = section;
      setTimeout(function () {
        store.state.searchTerm = "";
        store.state.showSearch = false;
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.mdi-chevron-down {
  color: var(--v-primary-300) !important;
}
.v-navigation-drawer {
  .__rail-is-horizontal {
    display: none !important;
  }
  .v-navigation-drawer__border {
    display: none !important;
  }
  .v-navigation-drawer__content {
    overflow-y: hidden !important;
  }
}
</style>
<style scoped lang="scss">
.askMeContainer{
  position: absolute;
  bottom: 38px;
  width: 100%
}
.width60{
  width: 60px;
}
.borderRadius0{
 border-radius: 0px !important;
}
.divDrawerContentUser {
  height: calc(100% - 58px);
}
.theme--light .drawerBorder {
  border-right: 1px solid #efefef;
}
.theme--dark .drawerBorder {
  border-right: 1px solid #171717;
}
.zindex-1 {
  z-index: -1 !important;
}
.zindex-0 {
  z-index: 0 !important;
}
.sidebarAdjust {
  height: calc(100vh - 124px) !important;
}
.sidebarAdjustMobile {
  height: calc(100vh - 58px) !important;
}
.custom-navigation-drawerWaitingVisitor {
  height: calc(100% - 106px) !important ;
  top: 127px !important;
}
.custom-navigation-drawer {
  height: calc(100% - 178px);
  top: 124px !important;
  border: none !important;
}
.custom-navigation-drawer-mobile {
  top: 56px !important;
}
$contentCardHeight: 148px;
$footerHeight: 43px;
.vueScrollSidebar {
  height: calc(100% - 40px) !important;
  .__view {
    width: unset !important;
  }
  .__rail-is-vertical {
    overflow: hidden !important;
    display: none !important;
  }
}
</style>

