var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      model: {
        value: _vm.callEndedInfoModal,
        callback: function($$v) {
          _vm.callEndedInfoModal = $$v
        },
        expression: "callEndedInfoModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.beforeEndingCallModal.callEndedInfoTitle"
                )
              }
            }),
            _vm._v(" "),
            _c("v-card-text", [
              _c("div", { staticClass: "mt-2 d-flex justify-center" }, [
                _c("div", { staticClass: "mx-3 pointer" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("components.beforeEndingCallModal.callEndedInfo")
                      ) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2 d-flex justify-center" }, [
                _c(
                  "div",
                  { staticClass: "mx-3 pointer" },
                  [
                    _c(
                      "v-btn",
                      { staticClass: "primary", on: { click: _vm.closeModal } },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("components.userAgreementModal.ok"))
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }