var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "460" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeShowInviteInfo.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showInviteInfo,
        callback: function($$v) {
          _vm.showInviteInfo = $$v
        },
        expression: "showInviteInfo"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.userListItem.invitationSent"),
              closeModalFunction: _vm.closeShowInviteInfo
            }
          }),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pt-6 pb-7" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("components.userListItem.yourInvitationTo")) +
                "\n      "
            ),
            _c("b", [
              _vm._v(
                _vm._s(_vm.state.sendInvitationGuestModal.invitationData.name) +
                  " (" +
                  _vm._s(
                    _vm.state.sendInvitationGuestModal.invitationData.email
                  ) +
                  ")"
              )
            ]),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("components.userListItem.hasBeenSuccessfullySent")
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.closeShowInviteInfo }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }