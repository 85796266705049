<template>
  <div>
    <h3 class="font-weight-regular">{{ $t("components.socialMedia.favorites") }}</h3>
    <v-divider class="mb-6"></v-divider>
    <Post v-for="(post, index) in postData" :key="`post-favorite-${index}`" :postData="post" />
  </div>
</template>

<script>
import Post from "../post/post.vue";
import store, { EventBus } from "../../../../store";
export default {
  components: { Post },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      postData: [],
      whatsWorkData: []
    };
  },
  mounted() {
    this.getMyFavoritesPost();
    EventBus.$on('removeFavoritePost', this.removeFavoritePost);
    EventBus.$on('addFavoritePost', this.addFavoritePost);
    EventBus.$on('updateComment', this.updateComment);
    EventBus.$on('removeComment', this.removeComment);
  },
  unmounted() {
    EventBus.$off('removeFavoritePost', this.removeFavoritePost);
    EventBus.$off('addFavoritePost', this.addFavoritePost);
    EventBus.$off('updateComment', this.updateComment);
    EventBus.$off('removeComment', this.removeComment);
  },
  methods: {
    updateComment(comment) {
      const postIndex = this.postData.findIndex(x => x.id === comment.postId);
      const postWhatsworkIndex = this.whatsWorkData.findIndex(x => x.id === comment.postId);
      // Helper function to update the comments for both postData and whatsWorkData
      const updateData = (data, index) => {
        if (index !== -1) {
          this.$set(data, index, {
            ...data[index],
            comments: [...data[index].comments, comment], // Add the new comment to the comments array
            updatedAt: new Date().toISOString() // Update the timestamp
          });
        }
      };
      // Update postData if found
      updateData(this.postData, postIndex);
      // Update whatsWorkData if found
      updateData(this.whatsWorkData, postWhatsworkIndex);
    },
    removeComment(comment) {
      // Helper function to remove the comment from both postData and whatsWorkData
      const removeCommentFromData = (data, index) => {
        if (index !== -1) {
          // Find the comment index inside the comments array
          const commentIndex = data[index].comments.findIndex(c => c._id === comment._id);

          if (commentIndex !== -1) {
            // Remove the comment from the comments array
            const updatedComments = [...data[index].comments];
            updatedComments.splice(commentIndex, 1); // Removes the comment at the found index

            // Update the post with the new comments array and the updated timestamp
            this.$set(data, index, {
              ...data[index],
              comments: updatedComments,
              updatedAt: new Date().toISOString()
            });
          }
        }
      };

      // Find the index in postData and remove the comment if found
      const postIndex = this.postData.findIndex(x => x.id === comment.postId);
      removeCommentFromData(this.postData, postIndex);

      // Find the index in whatsWorkData and remove the comment if found
      const postWhatsworkIndex = this.whatsWorkData.findIndex(x => x.id === comment.postId);
      removeCommentFromData(this.whatsWorkData, postWhatsworkIndex);
    },
    removeFavoritePost(data) {
      // Find the index of the post to be removed in the postData array
      const index = this.postData.findIndex(post => post.id === data.id);
      // Check if the post was found
      if (index !== -1) {
        // Remove the post from the array if found
        this.postData.splice(index, 1);
      }
    },

    addFavoritePost(data) {
      // Check if the post is already in the favorites
      const exists = this.postData.some(post => post.id === data.id);
      // If the post isn't already a favorite, add it
      if (!exists) {
        // Add the post to the favorites array
        this.postData.push(data);
      }
    },
    getMyFavoritesPost() {
      fetch(`/api/socialmedia/get-favorites-post?uuid=${this.ownUUID}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      }).then(async (response) => {
        const result = await response.json();
        if (!result.error) {
          this.postData = result.data;
        } else {
          //TODO: Handler error
        }
      })
    }
  }
};
</script>