<template >
  <v-menu offset-y :close-on-click="true" :max-height="370" :max-width="350">
    <template v-slot:activator="{ on }">
      <div v-on="on" @click="setTotalNotificationCall(0)">
        <v-badge
          bordered
          color="error"
          :content="totalNotificationCall"
          :value="totalNotificationCall"
          overlap
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :color="phoneColorColor"
                v-on="on"
                @click="setTotalNotificationCall(0)"
                id="buttonMissedCalls"
              >
                <font-awesome-icon
                  :icon="['fas', 'phone']"
                  :style="{ fontSize: '16px' }"
                  v-if="iconsBlack"
                />
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '16px' }"
                  v-else
                />
              </v-btn>
            </template>
            <span>{{ $t("components.drawerContentCard.missedCalls") }}</span>
          </v-tooltip>
        </v-badge>
      </div>
    </template>
    <NotificationDropDown :notificationType="'missedCalls'" />
  </v-menu>
</template>

<script>
import NotificationDropDown from "../notificationDropDown.vue";
export default {
  props: [
    "setTotalNotificationCall",
    "totalNotificationCall",
    "phoneColorColor",
    "iconsBlack",
  ],
  components: { NotificationDropDown },
};
</script>
<style scoped lang="scss">
.v-menu__content {
  // height: 370px !important;
  overflow: hidden;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #2a3133;
    border-radius: 10px;
  }
}
</style>