var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messagesArray.length !== 0 ||
    Object.keys(_vm.state.FileTransferModal.files).length > 0
    ? _c(
        "v-list",
        {
          style: "" + (_vm.customHeight ? "height:500px" : "height: 310px"),
          attrs: { "tree-line": "" }
        },
        [
          _c("vue-scroll", { staticClass: "vueScroll" }, [
            _c(
              "div",
              { staticStyle: { height: "310px" } },
              [
                _c(
                  "v-list-item-group",
                  { attrs: { "active-class": "primary--text" } },
                  [
                    Object.keys(_vm.state.FileTransferModal.files).length !== 0
                      ? _c(
                          "div",
                          _vm._l(_vm.state.FileTransferModal.files, function(
                            entry,
                            key
                          ) {
                            return _c(
                              "div",
                              { key: key },
                              [_c("FileItemList", { attrs: { entry: entry } })],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.messagesArray, function(message, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _vm._l(message, function(individual, i) {
                            return [
                              (individual || {}).info
                                ? _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      class:
                                        message.read === false
                                          ? "notiUnread"
                                          : ""
                                    },
                                    [
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          {
                                            staticClass:
                                              "borderRadius10 cursorPointer",
                                            attrs: { tile: "", size: "60" }
                                          },
                                          [
                                            _c("v-img", {
                                              staticClass:
                                                "mx-2 borderRadius10",
                                              attrs: {
                                                "max-height": "60",
                                                "max-width": "60",
                                                contain: "",
                                                src: _vm.getAvatarForUuid(
                                                  message.userUUID
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.getNameForUuid(
                                                    message.userUUID
                                                  )
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("v-list-item-subtitle", {
                                              staticClass: "text--primary",
                                              domProps: {
                                                textContent: _vm._s(
                                                  individual.fileName
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-list-item-action-text", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  new Date(
                                                    message.date
                                                  ).toLocaleDateString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit"
                                                  })
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "v-flex",
                                              { staticClass: "mt-5" },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "download"
                                                                        ],
                                                                        color:
                                                                          "grey lighten-1"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.downloadsDropdown.fileNotAvailable"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "DeleteBasket",
                                                                    {
                                                                      attrs: {
                                                                        indexData: message,
                                                                        delFunction:
                                                                          _vm.setTotalNotificationFile,
                                                                        extraFunction:
                                                                          _vm.removeMessage,
                                                                        extraFunctionParams: [
                                                                          message.userUUID,
                                                                          message.uuidMessage,
                                                                          undefined
                                                                        ],
                                                                        extraClasses:
                                                                          "",
                                                                        fontSize:
                                                                          "17",
                                                                        prevent:
                                                                          "true"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "generics.delete"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "btnRed",
                              staticStyle: {
                                float: "right",
                                margin: "5px 5px 0px 0px"
                              },
                              attrs: { icon: "", color: "white" },
                              on: {
                                click: function($event) {
                                  return _vm.removeAllListedItems()
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c("DeleteBasket", {
                              attrs: {
                                indexData: "",
                                delFunction: _vm.removeAllListedItems,
                                extraClasses: "",
                                fontSize: "20",
                                prevent: "false"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                275721338
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.downloadsDropdown.removeAll")))
              ])
            ]
          )
        ],
        1
      )
    : _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-title", [
                _vm._v(
                  _vm._s(_vm.$t("components.notificationDropDown.noNewFiles"))
                )
              ])
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }