var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.validData,
            callback: function($$v) {
              _vm.validData = $$v
            },
            expression: "validData"
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userListToSend.length < 4,
                      expression: "userListToSend.length < 4"
                    }
                  ],
                  staticClass: "mx-0 my-0"
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-combobox", {
                        staticClass: "mt-3",
                        attrs: {
                          items: _vm.filteredInvitees,
                          label: _vm.$t("components.inviteVisitor.name"),
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "item-text": "name",
                          "item-value": "id",
                          "return-object": "",
                          rules:
                            _vm.userListToSend.length > 0
                              ? _vm.nada
                              : _vm.userNameRules,
                          "item-disabled": _vm.handlerDisableItem,
                          disabled: _vm.atUserLimit
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleSelect($event)
                          },
                          keyup: function($event) {
                            return _vm.checkEmail($event)
                          }
                        },
                        model: {
                          value: _vm.selectedUserName,
                          callback: function($$v) {
                            _vm.selectedUserName = $$v
                          },
                          expression: "selectedUserName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "5" } },
                    [
                      _c("v-combobox", {
                        staticClass: "mt-3 ml-10",
                        attrs: {
                          items: _vm.filteredInvitees,
                          label: _vm.$t(
                            "components.inviteVisitor.invitationEmail"
                          ),
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          "item-text": "email",
                          "item-value": "id",
                          "return-object": "",
                          rules:
                            _vm.userListToSend.length > 0
                              ? _vm.nada
                              : _vm.emailRules,
                          "item-disabled": _vm.handlerDisableItem,
                          disabled: _vm.atUserLimit
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleSelect($event)
                          },
                          keyup: function($event) {
                            return _vm.checkEmail($event)
                          }
                        },
                        model: {
                          value: _vm.selectedEmail,
                          callback: function($$v) {
                            _vm.selectedEmail = $$v
                          },
                          expression: "selectedEmail"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      staticStyle: { height: "30px", "margin-top": "7px" },
                      attrs: { cols: "1" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticStyle: { "margin-top": "6px" },
                                        attrs: {
                                          icon: "",
                                          disabled:
                                            !_vm.validData || _vm.isProcessing
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addUserToList()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "30px" },
                                        attrs: { icon: ["fal", "plus-circle"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generics.add")))])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "listDivider" }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "w-100 mx-0 my-0" },
        _vm._l(_vm.userListToSend, function(data, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              class: { rowOdd: index % 2 === 0, rowEven: index % 2 !== 0 }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item", [
                    _c("strong", [_vm._v(_vm._s(data.name) + ":")]),
                    _vm._v("  " + _vm._s(data.email))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "margin-top": "-9px" },
                      attrs: { icon: "", disabled: _vm.isProcessing }
                    },
                    [
                      _c("DeleteBasket", {
                        attrs: {
                          indexData: index,
                          delFunction: _vm.removeUserList,
                          extraClasses: "",
                          fontSize: "20",
                          prevent: "false"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }