var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "sandbox" } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              !_vm.isMobile
                ? _c(
                    "v-col",
                    {
                      staticClass: "primary backgroundImage hidden-sm-and-down",
                      attrs: { cols: "6", md: 6, xl: 6 }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "fill-half-width divContentText" },
                        [
                          _c(
                            "div",
                            { staticClass: "logoLeftsideDiv" },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: "assets/icon.png",
                                  "max-height": "150",
                                  "max-width": "150",
                                  contain: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "font-weight-bold mb-2" }, [
                            _vm._v(_vm._s(_vm.getUrl))
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-0 customFontSize font-weight-medium"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.subdomainTaken.restrictedAccess"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "copyRightDiv" }, [
                        _vm._v("RA-MICRO Software AG © 2024 "),
                        _c("span", { staticClass: "ml-2 " }, [
                          _c(
                            "a",
                            {
                              staticClass: "imprintLink",
                              attrs: {
                                href:
                                  "https://www.ra-micro.de/info/impressum.html",
                                rel: "nofollow noopener noreferrer",
                                target: "_blank"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("literals.Imprint")))]
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "formRightSide",
                  attrs: {
                    cols: _vm.isMobile ? 12 : 6,
                    md: 6,
                    sm: 12,
                    xs: 12,
                    xl: 6
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "contentRightSide" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "w-100 mx-0 my-0 justify-space-between",
                          attrs: { flex: "" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 py-0",
                              attrs: { align: "left" }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "titleSize d-flex px-0" },
                                [
                                  _c("span", {
                                    staticClass: "font-weight-medium",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "components.subdomainTaken.requestLink"
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pt-2" },
                        [
                          _vm.emailSent
                            ? _c(
                                "v-col",
                                { staticClass: "pb-0", attrs: { cols: "12" } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.subdomainTaken.mailSentCard"
                                        )
                                      ) + "\n                "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.emailSent
                            ? _c("v-col", { attrs: { cols: "12" } }, [
                                !_vm.sendWhatsApp
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.subdomainTaken.mailSentCardSecondLine"
                                          )
                                        ) + "\n                "
                                      )
                                    ])
                                  : _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.subdomainTaken.whatsAppSentCardSecondLine"
                                          )
                                        ) + "\n                "
                                      )
                                    ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.emailSent
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("p", { staticClass: "px-0" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.subdomainTaken.vofficeUserEmailAddress"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-medium" },
                                      [_vm._v(_vm._s(_vm.getUrl))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mx-0",
                                      attrs: { "lazy-validation": "" },
                                      model: {
                                        value: _vm.valid,
                                        callback: function($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "px-0 pb-0" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "components.subdomainTaken.registeredEmail"
                                                  ),
                                                  required: "",
                                                  rules: _vm.emailRules
                                                },
                                                on: {
                                                  keydown: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.preventDefault()
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "space",
                                                          32,
                                                          $event.key,
                                                          [" ", "Spacebar"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.preventDefault()
                                                    }
                                                  ]
                                                },
                                                model: {
                                                  value: _vm.email,
                                                  callback: function($$v) {
                                                    _vm.email = $$v
                                                  },
                                                  expression: "email"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { col: "12" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mx-0" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { align: "left" }
                                                    },
                                                    [
                                                      _vm.showResult
                                                        ? _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.message
                                                              )
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mx-0" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          class:
                                                            "requestButton mr-4 " +
                                                            (_vm.isMobile
                                                              ? "mb-2"
                                                              : null),
                                                          attrs: {
                                                            depressed: "",
                                                            disabled:
                                                              !_vm.valid ||
                                                              _vm.email
                                                                .length == 0,
                                                            color: "primary",
                                                            loading:
                                                              _vm.requestingAccess
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.requestAccess(
                                                                true
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.subdomainTaken.emailLinkSend"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.isWhatsAppInviteAvailable
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "requestButton",
                                                              attrs: {
                                                                depressed: "",
                                                                disabled:
                                                                  !_vm.valid ||
                                                                  _vm.email
                                                                    .length ==
                                                                    0,
                                                                color:
                                                                  "primary",
                                                                loading:
                                                                  _vm.requestingAccess
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.requestAccess(
                                                                    false
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.subdomainTaken.whatsAppLinkSend"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.isWhatsAppInviteAvailable
                                    ? _c("p", { staticClass: "pt-3" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.subdomainTaken.vofficeUserPhoneNumber"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }