var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h100" },
    [
      _vm.viewingRoom
        ? _c(
            "v-content",
            {
              staticClass: "px-0 py-0",
              nativeOn: {
                scroll: function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "mx-auto" },
                [
                  _vm.isAdmin(_vm.ownUUID) &&
                  _vm.getMyRoomsDetailsConfIsTemp.length < 100
                    ? _c(
                        "v-col",
                        {
                          staticClass: "custom6cols pb-1 px-2",
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "cardWidth cursorPointer",
                              style: { border: "1px solid #efefef" },
                              on: {
                                click: function($event) {
                                  return _vm.clickOnAddCard()
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "180px",
                                    display: "table",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "divAdd" },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "buttonAdd",
                                        attrs: { icon: ["fal", "plus"] }
                                      }),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "mb-0 mt-2" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.sambaRooms.createRoom"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.getMyRoomsDetailsConfIsTemp, function(
                    room,
                    index
                  ) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        staticClass: "custom6cols pb-1 px-2",
                        attrs: { cols: "6" }
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "cardWidth",
                            staticStyle: { "background-color": "#eaeaea" },
                            style: {
                              border: "2px solid #0abce6",
                              backgroundColor: "#eaeaea"
                            },
                            on: {
                              click: function($event) {
                                return _vm.joinRoom(room)
                              }
                            }
                          },
                          [
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    disabled: !_vm.getTotalUsers(room)
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "contentSambaRomm",
                                                  class:
                                                    room.roomImage &&
                                                    room.roomImage ===
                                                      "img/icon.png"
                                                      ? "imageSambaRoom"
                                                      : ""
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "contentSambaRomm"
                                                  },
                                                  [
                                                    _c("SambaRoomOverlay", {
                                                      attrs: { room: room }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "centered-text"
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "title font-weight-bold px-4"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                room.roomName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gradientBg"
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-subtitle",
                                                          {
                                                            staticClass:
                                                              "titleCard colorWhite pt-1 pb-1 px-2 text-truncate",
                                                            staticStyle: {
                                                              color:
                                                                "#fff !important"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "RoomCardActions",
                                                              {
                                                                attrs: {
                                                                  room: room,
                                                                  editRoom:
                                                                    _vm.editRoom,
                                                                  removeRoom:
                                                                    _vm.removeRoom,
                                                                  deleteActive:
                                                                    _vm.deleteActive,
                                                                  addRoomToFavorites:
                                                                    _vm.addRoomToFavorites,
                                                                  removeRoomFromFavorites:
                                                                    _vm.removeRoomFromFavorites
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("div", [
                                    !_vm.isGuest
                                      ? _c(
                                          "span",
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["fal", "video"] }
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.getUsersNameInRoom(room)
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.viewingRoom
        ? _c(
            "v-content",
            {
              staticClass: "px-0 py-0 h100",
              nativeOn: {
                scroll: function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "h100" },
                [
                  _c(
                    "v-row",
                    { class: _vm.isMobile ? null : "heightScroll" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-0 py-0",
                          attrs: {
                            cols: _vm.isMobile ? 12 : 6,
                            md: _vm.isMobile ? 12 : 6
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-0 pb-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-0 pt-0",
                                    attrs: {
                                      label: _vm.$t(
                                        "components.sambaRooms.roomName"
                                      ),
                                      outlined: "",
                                      autocomplete: "off",
                                      dense: "",
                                      maxlength: 30
                                    },
                                    model: {
                                      value: _vm.roomName,
                                      callback: function($$v) {
                                        _vm.roomName = $$v
                                      },
                                      expression: "roomName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-0 pb-0 pt-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      rows: "4",
                                      counter: 150,
                                      maxlength: "150",
                                      label: _vm.$t(
                                        "components.sambaRooms.roomDescription"
                                      ),
                                      "no-resize": ""
                                    },
                                    model: {
                                      value: _vm.roomDescription,
                                      callback: function($$v) {
                                        _vm.roomDescription = $$v
                                      },
                                      expression: "roomDescription"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          class:
                            (_vm.isMobile ? "text-center" : "text-right") +
                            " pl-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-divider", { staticClass: "pb-4" }),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "max-width": "210" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              class:
                                                (_vm.isMobile ? "mb-2" : null) +
                                                " ml-2",
                                              attrs: {
                                                disabled: !_vm.canSaveRoom,
                                                depressed: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveRoom()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.sambaRooms.saveRoom"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4122373453
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("components.sambaRooms.saveRoom")
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "max-width": "210" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "ml-2 borderRadius-4",
                                              staticStyle: { height: "36px" },
                                              attrs: {
                                                icon: "",
                                                outlined: "",
                                                depressed: "",
                                                small: "",
                                                fab: "",
                                                dark: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: _vm.goBackToRoomGrid
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "times"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                68015821
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.close")) + " ")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }