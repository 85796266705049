var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    {
      staticClass: "px-0 py-0",
      staticStyle: { width: "100%", height: "100%" }
    },
    [_c("v-row")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }