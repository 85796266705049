<template>
  <v-card>
    <v-row class="w-100 mx-0 my-0 justify-space-between">
      <v-col class="px-0 py-0" align="left">
        <v-card-title v-if="isVisitor(call.uuid)" class="headline">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall">
            {{ $t("components.incomingCall.incomingOneCall") }}
          </span>
        </v-card-title>

        <v-card-title v-else class="headline">
          <v-img
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          <span class="font-weight-medium pl-4 spanModalCall">
            {{ $t("components.incomingCall.incomingCall") }}</span
          >
        </v-card-title>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- branch if pin protection -->
    <v-row class="w-100 mx-0 my-0 px-3 pt-3">
      <v-col
        :class="`my-0 py-0 ${isMobile ? 'col-12' : ''}`"
        v-for="n in 3"
        :key="n"
        :lg="isMobile ? 12 : cols[n - 1]"
        :md="isMobile ? 12 : cols[n - 1]"
        :sm="isMobile ? 12 : cols[n - 1]"
        :xl="isMobile ? 12 : cols[n - 1]"
      >
        <div v-if="n == 1 && !isMobile" align="center">
          <v-list-item-avatar tile size="120" class="borderRadius4">
            <v-img
              class="borderRadius4"
              max-height="120"
              max-width="120"
              contain
              :src="getAvatarForUuid(call.uuid)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </v-list-item-avatar>
        </div>
        <div
          class="pt-3 pl-3"
          v-if="n == 2"
          v-bind:class="{ 'text-center': isMobile }"
        >
          <p class="font-weight-medium my-0">{{ getNameForUuid(call.uuid) }}</p>
          <p
            class="dataUserCard my-0"
            v-if="state.group[call.uuid].user.position"
          >
            {{ state.group[call.uuid].user.position }}
          </p>
          <p
            class="dataUserCard my-0"
            v-if="
              (state.group[call.uuid].user.sections &&
                state.group[call.uuid].user.sections.length > 0) ||
              (state.group[call.uuid].user.locations &&
                state.group[call.uuid].user.locations.length > 0)
            "
          >
            {{ state.group[call.uuid].user.sections[0] }}
            {{
              state.group[call.uuid].user.locations
                ? "| " + state.group[call.uuid].user.locations[0]
                : null
            }}
          </p>
        </div>
        <div
          class="pb-2 pt-3 pl-3 text-right"
          v-if="n == 3"
          v-bind:class="{ 'text-center': isMobile }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 rejectButtons mb-2"
                v-on="on"
                outlined
                color="primary"
                ref="cancelCall"
                @click="
                  rejectCall(
                    call,
                    $t(
                      'components.incomingCall.callLater',
                      state.group[call.uuid].user.language
                    )
                  )
                "
                >{{ $t("components.incomingCall.callLaterB") }}</v-btn
              >
            </template>
            <span>{{ $t("components.incomingCall.callLater") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 rejectButtons mb-2"
                v-on="on"
                outlined
                color="primary"
                ref="cancelCall"
                @click="
                  rejectCall(
                    call,
                    $t(
                      'components.incomingCall.callTomorrow',
                      state.group[call.uuid].user.language
                    )
                  )
                "
                >{{ $t("components.incomingCall.callTomorrowB") }}</v-btn
              >
            </template>
            <span>{{ $t("components.incomingCall.callTomorrow") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 rejectButtons"
                v-on="on"
                outlined
                color="primary"
                ref="cancelCall"
                @click="
                  rejectCall(
                    call,
                    $t(
                      'components.incomingCall.callYouBack',
                      state.group[call.uuid].user.language
                    )
                  )
                "
                >{{ $t("components.incomingCall.callYouBack") }}</v-btn
              >
            </template>
            <span>{{ $t("components.incomingCall.callYouBack") }}</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="px-2 w-100 mx-0 my-0"
        v-if="call && call.dialGroup && call.dialGroup.length"
      >
        <v-divider></v-divider>
        <p class="pt-5">{{ $t("components.inviteVisitor.participants") }}:</p>
        <ul>
          <li v-for="uuid of call.dialGroup" :key="uuid">
            {{ getNameForUuid(uuid) }}
          </li>
        </ul>
      </v-col>
      <template v-if="getUsersInCall && getUsersInCall.length">
        <div class="userQueueContainerStyle">
          <span class="subtitle-2">{{
            $t("components.callsContent.othersParticipants")
          }}</span>
          <div class="d-flex flex-wrap userQueueStyle">
            <div v-for="uuid in getUsersInCall" :key="uuid" class="d-flex mr-2">
              <v-list-item-avatar
                min-width="25px"
                min-height="25px"
                width="25px"
                height="25px"
                max-width="25px"
                max-height="25px"
              >
                <v-img :src="getAvatarForUuid(uuid)"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="queueContent">
                <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                <v-list-item-title
                  v-html="$sanitize(getNameForUuid(uuid))"
                ></v-list-item-title>
              </v-list-item-content>
            </div>
          </div>
          <!-- <v-list class="userQueueStyle pt-0 d-flex">
            <v-list-item dense v-for="uuid in getUsersInCall" :key="uuid">
              <v-list-item-avatar
                min-width="25px"
                min-height="25px"
                width="25px"
                height="25px"
                max-width="25px"
                max-height="25px"
              >
                <v-img :src="getAvatarForUuid(uuid)"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="queueContent">
                <v-list-item-title
                  v-html="$sanitize(getNameForUuid(uuid))"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
        </div>
      </template>
      <template v-if="!isVisitor(ownUUID)">
        <v-col
          cols="12"
          class="px-2 w-100 mx-0 my-0"
          v-bind:class="{ 'text-center': isMobile }"
          v-if="state.persisted.tmpMessages.showInfoIncomingCallModal"
        >
          <v-divider></v-divider>
          <div class="pt-3">
            <p>
              {{ $t("components.incomingCall.answerSpaceB") }}
            </p>
            <v-checkbox
              v-model="checkedMessage"
              @change="setShowInfoIncomingCallModal(false)"
              class="mt-0"
              :label="$t('components.meetingTime.dontShowAgain')"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
        </v-col>
      </template>
      <template>
        <v-col cols="12" v-if="!amInABridgeCall">
          <CallModalCameraPreview
            :height="'100%'"
            :width="'100%'"
          ></CallModalCameraPreview>
        </v-col>
      </template>
      <template v-if="isCallProtected">
        <v-col cols="12" class="px-2 w-100 mx-0 my-0">
          <v-divider></v-divider>
          <div
            class="d-flex flex-column pt-5"
            v-bind:class="{ centerRow: isMobile, 'mt-2': isMobile }"
          >
            <div class="pr-4 font-weight-medium primary--text">
              <p>{{ $t("components.callsContent.protectedCall") }}</p>
            </div>
            <div class="w-50">
              <template>
                <v-text-field
                  :autofocus="true"
                  ref="userPinInput"
                  class="whiteControl"
                  outlined
                  dense
                  v-model="userPin"
                  :style="{ width: 300, color: 'white' }"
                  hide-details
                  :label="$t('components.callsContent.insertPin')"
                  @change="checkIt($event)"
                />
              </template>
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
    <FooterModal :closeModalFunction="closeModal">
      <template>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-3 buttonIconPrimaryFooter callButton px-2"
              v-on="on"
              icon
              tile
              color="primary"
              @click="resolveCall(call)"
              ref="acceptCall"
            >
              {{ $t("generics.accept") }}
            </v-btn>
          </template>
          <span>{{ $t("components.meetingTime.acceptCall") }}</span>
        </v-tooltip>
      </template>
    </FooterModal>
  </v-card>
</template>
<script>
import store, { syncedUserState } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { MD5 } from "../../lib/rtcConn";
import { wsCall } from "../../lib/wsConnect";
import { hasPrivilege } from "../../utils/privileges";
import CallModalCameraPreview from "../camera/callModalCameraPreview.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { amInABridgeCall, amInAStaticRoom } from "../../utils/calls";
export default {
  components: { CallModalCameraPreview, FooterModal },
  props: ["call"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      spaceShortcutCallAcceptTimeout: null,
      checkedMessage: false,
      isMobile: isMobile(),
      limit_time: null,
      timeLeft: null,
      storeDialUpTime: store.state.user.userSettings.dialUpTime,
      hasConfrimmedPin: false,
      userPin: "",
      videoCameraCallOn: false,
      audioCallOn: false,
    };
  },
  mounted() {
    document.addEventListener("keyup", this.onKeyUp, {
      capture: false,
      passive: true,
    });
    this.timeLeft = this.storeDialUpTime / 1000 || 30;
    this._interval = setInterval(this.tick.bind(this), 1000);
  },
  beforeDestroy: function () {
    document.removeEventListener("keyup", this.onKeyUp);
  },
  destroyed() {
    this.timeLeft = null;
    clearInterval(this._interval);
  },
  effector: {
    bridgeInfoStore: bridgeInfoStore,
  },
  watch: {
    isCallProtected: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.$refs.userPinInput) this.$refs.userPinInput.focus();
            }, 10);
          });
        }
      },
    },
    timeLeft(val) {
      if (val === null) return;
      if (val <= 0) this.rejectCall(this.call);
    },
  },
  methods: {
    closeModal() {
      this.rejectCall(this.call);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    checkIt(evt) {
      if (this.userPin > 12 || this.userPin < 12) return false;
      if (MD5(this.userPin) === this.call.pinCode) {
        this.hasConfrimmedPin = true;
      }
    },
    tick() {
      this.timeLeft--;
    },
    isVisitor(uuid) {
      return ((store.state.group[uuid] || {}).user || {}).visitor || false;
    },
    declineDefaultMessage(uuid, message) {
      wsCall("sendToUUID", uuid, {
        type: "rejectCall",
        message: message,
        sender: this.state.ownUUID,
      });
    },
    setShowInfoIncomingCallModal(val) {
      return store.setShowInfoIncomingCallModal(val);
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        // if (this.$refs.cancelCall && this.$refs.cancelCall.length) {
        //   this.$refs.cancelCall[this.$refs.cancelCall.length - 1].$el.click();
        // }
        this.closeModal();
      } else if (event.type === "keyup" && event.keyCode === 32) {
        if (this.$refs.acceptCall) {
          if (
            event.target.nodeName !== "TEXTAREA" &&
            event.target.nodeName !== "INPUT" &&
            !this.state.showModalSenMsg
          ) {
            this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
              this.$refs.acceptCall.$el.click();
              this.spaceShortcutCallAcceptTimeout = null;
            }, 300);
          }
        }
      }
    },
    leaveTheStaticRoom() {
      return new Promise((resolve, reject) => {
        const remoteStreamsKey = Object.keys(
          store.state.remoteBridgeStreams
        )[0];
        console.log('leaveTheStaticRoom remoteStreamsKey', remoteStreamsKey);
        const remoteBridgeStream = store.state.remoteBridgeStreams[remoteStreamsKey];
        store.setRoomInformation(remoteBridgeStream);
        store.removeRemoteBridgeStreams(remoteStreamsKey);
        syncedUserState(undefined, true).then(resolve);
      });
    },
    async resolveCall(call) {
      store.setCurrentContentVisile("home", false, this.$router);
      if(this.amInAStaticRoom){
       // If we are in a room and someone calls us, we leave the room
       await this.leaveTheStaticRoom();
      }
      let normalMode;
      if (call.isNormalMode != undefined) {
        normalMode = call.isNormalMode;
      } else if (
        call.isNormalMode == undefined &&
        this.state.remoteBridgeStreams[call.callUUID]
      ) {
        normalMode = this.state.remoteBridgeStreams[call.callUUID].isNormalMode;
      } else {
        normalMode = true;
      }
      let callStartTs = call.callStartTs;
      let callDurationMs = call.callDurationMs;
      let infiniteCall = call.infiniteCall;
      if (this.state.remoteBridgeStreams[call.callUUID]) {
        callDurationMs =
          this.state.remoteBridgeStreams[call.callUUID].callDurationMs;
        callStartTs = this.state.remoteBridgeStreams[call.callUUID].callStartTs;
        infiniteCall =
          this.state.remoteBridgeStreams[call.callUUID].infiniteCall;
      }
      store.removeAcceptedCallNotification(call.uuid);
      const info = {
        callUUID: call.callUUID,
        calling: [],
        isNormalMode: normalMode,
        initiator: call.initiator || "",
        callStartTs: callStartTs,
        callDurationMs: callDurationMs,
        infiniteCall: infiniteCall,
        conferenceDocuments: call.conferenceDocuments || [],
        userConferencePosition: call.userConferencePosition || [],
        excludedFromCalling: call.usersToExclude || [],
        conferenceAttendees: call.conferenceAttendees || [],
        confModerators: call.confModerators || [],
        conferenceSettings: call.conferenceSettings || {},
        usersToHideName: call.usersToHideName || [],
        isConferenceCall: call.isConferenceCall || undefined,
        conferenceHall: call.conferenceHall || undefined,
        liveBroadcastId: call.liveBroadcastId || undefined,
        isAudioOnly:
          store.state.user.userSettings.videoCameraCallOn === true
            ? false
            : true,
        roomId: call.roomId,
        roomGuid: call.roomGuid,
        recording: call.recording
      };
      store.addRemoteBridgeStream(call.callUUID, info);
      this.setCurrentContentVisile("", false, this.$router);
      wsCall("sendToUUID", call.uuid, {
        type: "bridge-signal",
        action: "acept_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: call.callUUID,
          calling: [],
          isNormalMode: normalMode,
          initiator: call.initiator || "",
          callStartTs: callStartTs,
          callDurationMs: callDurationMs,
          infiniteCall: infiniteCall,
          conferenceDocuments: call.conferenceDocuments || [],
          userConferencePosition: call.userConferencePosition || {},
          excludedFromCalling: call.usersToExclude || [],
          conferenceAttendees: call.conferenceAttendees || [],
          confModerators: call.confModerators || [],
          usersToHideName: call.usersToHideName || [],
          isConferenceCall: call.isConferenceCall || undefined,
          conferenceHall: call.conferenceHall || undefined,
          liveBroadcastId: call.liveBroadcastId || undefined,
          isAudioOnly:
            store.state.user.userSettings.videoCameraCallOn === true
              ? false
              : true,
          roomId: call.roomId,
          roomGuid: call.roomGuid,
          recording: call.recording
        },
      });
      store.appendIncomingCallHistory(call.uuid, call.callUUID, Date.now());
      store.setIncomingBridgeCallFor(call.uuid, undefined);
    },
    rejectCall(call, customMessage) {
      wsCall("sendToUUID", call.uuid, {
        type: "bridge-signal",
        action: "reject_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: call.callUUID,
        },
      });
      store.setIncomingBridgeCallFor(call.uuid, undefined);
      if (customMessage) {
        // setTimeout(() => {
          this.declineDefaultMessage(call.uuid, customMessage);
        // }, 5000);
      }
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      if (!uuid || !store.state.group[uuid]) return this.$t("generics.anonymous");
      if (store.state.group[uuid].beaconCallInvite) {
        return this.$t("components.userSettings.user");
      } else {
        return store.getNameForUuid(uuid);
      }
    },
  },
  computed: {
    getUsersInCall() {
      // const callUUID = this.call.callUUID; // Old behavior // https://gitlab.ra-micro.de/devcups/voffice/-/issues/459
      const callUUID = this.call.uuid && this.state.group[this.call.uuid]?.user?.bridgeCallInfo?.callUUID;
      if (
        !callUUID ||
        !this.bridgeInfoStore ||
        !this.bridgeInfoStore.calls[callUUID]
      ) {
        return [];
      }
      const callInfo = this.bridgeInfoStore.calls[callUUID];
      return callInfo.users.filter((user) => user !== this.call.uuid && user !== this.ownUUID);
    },
    amInABridgeCall() {
      return amInABridgeCall(this.ownUUID);
    },
    amInAStaticRoom() {
      return amInAStaticRoom(this.ownUUID);
    },
    isCallProtected() {
      if (this.hasConfrimmedPin) {
        return false;
      } else {
        return this.call.isPinProtected && this.call.pinCode.length > 0;
      }
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.breakpoint;
      return lg
        ? [3, 4, 5]
        : sm
        ? [3, 4, 5]
        : md
        ? [3, 4, 5]
        : xl
        ? [3, 4, 5]
        : [12, 12, 12];
    },
  },
};
</script>
<style scoped lang="scss">
.callButton {
  width: auto !important;
}
.rejectButtons {
  font-size: 12px !important;
}
.video-slash {
  width: 25px !important;
  height: 25px !important;
}
.microphone-slash {
  width: 25px !important;
  height: 25px !important;
  color: #fff;
}
.spanModalCall {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.dataUserCard {
  margin-bottom: 0px;
}
.v-avatar .v-image {
  border-radius: 10px !important;
}
.borderRadius4,
.v-avatar .v-image {
  border-radius: 4px !important;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.centerRow {
  align-items: center;
  justify-content: center !important;
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: unset !important;
}
.userQueueStyle {
  max-height: 115px;
  overflow-y: auto;
  // margin-top: 19px;
  margin-bottom: 5px;
  margin-right: 10px;
  width: 100%;
}
.trashQueue {
  max-width: 10%;
  width: 10%;
}
.queueContent {
  display: contents;
}
.userQueueStyle::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.userQueueStyle::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ababab;
  border-radius: 10px;
}
/* Handle */
.userQueueStyle::-webkit-scrollbar-thumb {
  background: #06a5df;
  border-radius: 10px;
}
.userQueueContainerStyle {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  width: 100%;
}
</style>