var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "735" },
      model: {
        value: _vm.showjoinConference,
        callback: function($$v) {
          _vm.showjoinConference = $$v
        },
        expression: "showjoinConference"
      }
    },
    _vm._l(_vm.showjoinConference, function(user) {
      return _c(
        "v-card",
        { key: user },
        [
          _c("HeaderModal", {
            attrs: { titleModal: _vm.$t("generics.message") }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { style: { width: "100%", margin: 0 }, attrs: { flex: "" } },
            [
              _c("div", { staticClass: "d-flex", style: { width: "100%" } }, [
                _c(
                  "div",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "v-badge",
                      {
                        attrs: {
                          bordered: "",
                          overlay: "",
                          height: "60",
                          width: "60",
                          bottom: "",
                          color: _vm.badgetClassObj(user),
                          dot: "",
                          "offset-x": "22",
                          "offset-y": "15"
                        }
                      },
                      [
                        _c(
                          "v-list-item-avatar",
                          {
                            staticClass: "borderRadius4",
                            attrs: { tile: "", size: "150" }
                          },
                          [
                            [
                              _vm.getUserData(user).avatar ===
                              "img/default_profile_picture.png"
                                ? _c("DefaultAvatar", {
                                    attrs: {
                                      size: 150,
                                      userData: _vm.getUserData(user)
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getUserData(user).avatar !==
                              "img/default_profile_picture.png"
                                ? _c("v-img", {
                                    staticClass: "borderRadius4",
                                    attrs: {
                                      "max-height": "150",
                                      "max-width": "150",
                                      contain: "",
                                      src: _vm.getUserData(user).avatar,
                                      "lazy-src":
                                        "assets/default_profile_picture.png"
                                    }
                                  })
                                : _vm._e()
                            ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-center", style: { width: "100%" } },
                  [
                    _c("v-divider", { staticClass: "mt-4" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2 ml-4" }, [
                      _c(
                        "p",
                        {
                          staticClass: "userNameCard",
                          attrs: { color: "primary" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getUserData(user).titel) +
                              " " +
                              _vm._s(_vm.getUserData(user).name) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { attrs: { color: "primary" } }, [
                        _vm._v(_vm._s(_vm.getTextModal))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-4" })
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { hideDefaultButtons: true } },
            [
              [
                _c(
                  "div",
                  { staticClass: "text-left d-flex mb-2" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mr-4",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.acceptJoin(user)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.callsContent.beCalledLater"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.callsContent.beCalledLater")
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mr-4",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.declineJoin(user)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.callsContent.cantAnswer"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("components.callsContent.cantAnswer"))
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mr-4",
                                    attrs: { outlined: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.callUser(user)
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("generics.accept")))
                                  ])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generics.accept")))])
                  ]
                )
              ]
            ],
            2
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }