var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    {
      staticClass: "px-0 py-0",
      staticStyle: { width: "100%", height: "95vh" }
    },
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-card",
            { attrs: { flat: "", width: "100%" } },
            [
              [
                _c(
                  "v-row",
                  { staticClass: "mx-0" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: 12, sm: 12, md: 12, xl: 12 } },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "d-flex",
                            attrs: {
                              tile: "",
                              height: "54",
                              color: "primary lighten-1"
                            }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "px-2 py-3",
                                attrs: { cols: "4" }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "white--text subtitle-1 font-weight-bold py-2"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.calendarContainer.videoCallPlanner"
                                        )
                                      ) +
                                        " " +
                                        _vm._s(
                                          _vm.getUserNameUserCalendar(
                                            _vm.uuidToRepresentStore ||
                                              _vm.state.ownUUID
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "refreshData",
                                    attrs: { icon: "", color: "white" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleHome()
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "times"] }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "closeHome",
                                                  attrs: {
                                                    icon: "",
                                                    color: "white"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.fetchBlockedData()
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "16px" },
                                                  attrs: {
                                                    icon: ["far", "redo"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("generics.update")))
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "px-2 py-3 text-center",
                                attrs: { cols: "4" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btnCalendar",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.prevMonth()
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: {
                                        color: "white",
                                        fontSize: "20px"
                                      },
                                      attrs: { icon: ["fal", "chevron-left"] }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "white--text subtitle-1 font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.calendarMonth) +
                                        " " +
                                        _vm._s(_vm.calendarYear)
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btnCalendar",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.nextMonth()
                                      }
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: {
                                        color: "white",
                                        fontSize: "20px"
                                      },
                                      attrs: { icon: ["fal", "chevron-right"] }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-sheet",
                          { staticClass: "mb-6", attrs: { height: "600" } },
                          [
                            _c("v-calendar", {
                              ref: "calendar",
                              attrs: {
                                start: _vm.startCalendarDay,
                                end: _vm.endCalendarDate,
                                weekdays: _vm.weekday,
                                type: "month",
                                events: _vm.events,
                                "event-overlap-mode": _vm.mode.value,
                                "event-overlap-threshold": 30,
                                "event-color": _vm.getEventColor,
                                locale: this.$locale.current()
                              },
                              on: {
                                change: _vm.calChangeHandler,
                                "click:event": _vm.showEvent,
                                "click:day": _vm.syncTimeline,
                                "click:date": _vm.syncTimeline
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "event",
                                  fn: function(ref) {
                                    var event = ref.event
                                    return [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.displayHours(event.start)
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.state.user.language !== "en"
                                                ? _vm.$t("generics.hours")
                                                : ""
                                            )
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(event.name) +
                                          "\n                  "
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.value,
                                callback: function($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  activator: _vm.selectedElement,
                                  "offset-x": ""
                                },
                                model: {
                                  value: _vm.selectedOpen,
                                  callback: function($$v) {
                                    _vm.selectedOpen = $$v
                                  },
                                  expression: "selectedOpen"
                                }
                              },
                              [
                                !_vm.wantDelete
                                  ? _c(
                                      "v-card",
                                      {
                                        attrs: {
                                          color: "grey lighten-4",
                                          "max-width": "350px",
                                          width: "350px",
                                          "min-width": "350px",
                                          flat: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-toolbar",
                                          {
                                            attrs: {
                                              color: _vm.selectedEvent.color,
                                              dark: ""
                                            }
                                          },
                                          [
                                            _c("v-toolbar-title", {
                                              staticClass: "text-truncate",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.sanitizeSelectedEventName
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("v-spacer"),
                                            _vm._v(" "),
                                            _vm.checkImSupervisor
                                              ? _c(
                                                  "v-btn",
                                                  { attrs: { icon: "" } },
                                                  [
                                                    _c("DeleteBasket", {
                                                      attrs: {
                                                        indexData:
                                                          _vm.selectedEvent
                                                            .uuid,
                                                        delFunction:
                                                          _vm.deleteEvent,
                                                        extraClasses: "",
                                                        fontSize: "20",
                                                        prevent: "false"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-card-text", [
                                          _c("span", {
                                            staticClass: "text-truncate",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.sanitizeSelectedEventDetails
                                              )
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  color: "secondary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.selectedOpen = false
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("generics.cancel")
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }