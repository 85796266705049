<template>
    <v-row>
        <v-col cols="auto" class="d-flex justify-center">
            <UserAvatar :src="getAvatarForUuid(ownUUID)" :size="48" />
        </v-col>
        <v-col @click.stop class="pb-0 text-right">
            <v-textarea 
                v-model="newComment" 
                variant="outlined" 
                :label="$t('components.socialMedia.writeAComment')" 
                rows="2" 
            />
            <v-btn @click="submitComment" color="primary" class="mb-4">{{$t('components.socialMedia.post')}}</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import store, {EventBus} from "../../../../store";
import UserAvatar from "../common/userAvatar.vue";
export default {
    components: {  UserAvatar },
    props: ['postData'],
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            newComment: ''
        };
    },
    methods: {
        getAvatarForUuid(uuid) {
            return store.getAvatarForUuid(uuid);
        },
        submitComment() {
            if (this.newComment.trim()) {
                const comment = {
                    postId: this.postData.id,
                    parentId:  null,
                    userId: this.ownUUID, // Asumiendo que tu UUID es tu userId
                    comment: this.newComment.replace(/\n/g, "<br>"),
                    likes: [], // Inicia sin likes
                    comments: [], // Inicia sin respuestas
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                };
                this.saveComment(comment);
            }
        },
        addCommentToLocalData(comment) {
            // Emitir el comment al componente principal para añadirlo al estado
            EventBus.$emit('updateComment', comment);
        },
        cleanComment(){
            this.newComment = '';
        },
        saveComment(comment){
            fetch("/api/socialmedia/comment", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(comment)
            }).then(response => response.json())
            .then((result) => {
                if(result.success) {
                    this.cleanComment();
                    const data = result.data || comment
                    this.addCommentToLocalData(data);
                }else{
                    //TODO: Handler error
                }
            })

        },
    }
};
</script>
