<template>
  <div>
    <div>{{ confTemplate.groupName || "" }}</div>
    <div>{{ getNameModerator }}</div>
    <div>
      <span>
        {{ $t("components.remotePersonCall.attendees") }}
      </span>
      <div v-for="(uuid, key) in confTemplate.groupUUIDS" :key="key">
        {{ getTitelByUuid(uuid) }} {{ getNameByUuid(uuid) }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../../store";
export default {
  props: ["confTemplate"],
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {},
  computed: {
    getNameModerator() {
      console.log("gerNameModerator");
      const moderatorName =
        this.$t("components.remotePersonCall.moderator") +
        ": " +
        this.getTitelByUuid(this.confTemplate.ownerUUID) +
        " " +
        this.getNameByUuid(this.confTemplate.ownerUUID);
      return moderatorName;
    },
  },
  methods: {
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
};
</script>