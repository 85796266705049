var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabled
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "460" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.$t(
                    "components.2faModal.twoFactorAuthentication"
                  ),
                  closeModalFunction: _vm.closeModal
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pb-0 mb-0 pt-2" },
                [
                  _vm.enabled === "sms"
                    ? _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "tex0t-truncate body-1" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("components.2faSms.phoneNumber"))
                            )
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.phoneNumber) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.enabled === "sms"
                    ? _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                disabled: _vm.requestSmsDone,
                                small: "",
                                color: "error"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.requestSms()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.2faSms.sendSMS"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.requestSmsDone
                            ? _c(
                                "span",
                                { staticClass: "px-2 align-center d-flex" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("components.2fa.sentSMSMsg"))
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errMsg && !_vm.showCodeInput
                    ? _c(
                        "v-alert",
                        { attrs: { type: "error", dismissible: true } },
                        [_vm._v(_vm._s(_vm.errMsg))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showCodeInput
                    ? _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "off",
                              label: _vm.tfaCodeLabel,
                              placeholder: " ",
                              outlined: "",
                              dense: "",
                              required: "",
                              "append-icon":
                                "" +
                                (_vm.showPassword ? "mdi-eye-off" : "mdi-eye"),
                              type:
                                "" + (_vm.showPassword ? "text" : "password"),
                              "hide-details": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                _vm.tfaCode && _vm.test2FA()
                              },
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                              "click:append": function($event) {
                                return _vm.showPass()
                              }
                            },
                            model: {
                              value: _vm.tfaCode,
                              callback: function($$v) {
                                _vm.tfaCode = $$v
                              },
                              expression: "tfaCode"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errMsg
                            ? _c(
                                "v-alert",
                                { attrs: { type: "error", dismissible: true } },
                                [_vm._v(_vm._s(_vm.errMsg))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex justify-end mt-3" })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FooterModal",
                { attrs: { closeModalFunction: _vm.closeModal } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.tfaCode },
                      on: {
                        click: function($event) {
                          return _vm.test2FA()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("components.2fa.login")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }