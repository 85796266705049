<template>
  <div class="d-flex align-center" v-if="room">
    <div class="text-truncate" style="width:100%">
      <label class="ml-2 userName font-weight-medium">{{$t('components.sambaRooms.totalUsers')}}:&nbsp;{{getTotalUsers}}</label>
    </div>
      <v-menu offset-y scrollable :close-on-click="true" v-if="room.roomId && !hideOptions">
        <template v-slot:activator="{ props }">
          <v-btn icon density="comfortable" variant="text"  v-bind="props" class="groupCardEllipses" >
            <font-awesome-icon
              :icon="['fal', 'ellipsis-v']"
              :style="{ fontSize: '20px' }"
            />  
          </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="!room.isFavorite" @click="addRoomToFavorites(room)">
              <v-list-item-title>{{ $t("components.sambaRooms.addToFavorites")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="room.isFavorite" @click="removeRoomFromFavorites(room)">
              <v-list-item-title>{{ $t("components.sambaRooms.removeFromFavorites")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canEditRoom && isAdmin(ownUUID)" @click="editRoom(room)">
              <v-list-item-title>{{ $t("components.sambaRooms.editRoom")}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canRemoveRoom && isAdmin(ownUUID)" @click="removeRoom(room, $event)">
              <v-list-item-title :class="!deleteActive
                ? 'deleteBasketButtonNormal'
                : 'deleteBasketButtonActive'
              ">
                {{ $t("components.sambaRooms.removeRoom")}}
              </v-list-item-title>
            </v-list-item>
          </v-list> 
      </v-menu>
  </div>
</template>
<script>
import store from "../../store";
import { isAdmin } from "../../utils/privileges";
import { totalUsersInRoom } from "../../utils/staticRoom"

export default {
  components: {},
  props: ['room', 'editRoom', 'removeRoom', 'deleteActive', 'addRoomToFavorites', 'removeRoomFromFavorites', 'hideOptions'],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    }
  },
  computed:{
    canEditRoom(){
      return true; // check if we can edit room
    },
    canRemoveRoom(){
      if(this.getTotalUsers === 0){
        return true
      }
      return false; // check if the room is empty
    },
    getTotalUsers(){
      return totalUsersInRoom(this.room)
    }
  },
  methods: {
    isAdmin(uuid) {
      return isAdmin(uuid)
    },
  }
}
</script>
<style scoped lang="scss">
.v-theme--dark .deleteBasketButtonNormal {
  color: white !important;
}
.deleteBasketButtonActive {
  color: red !important;
  font-weight: 600 !important;
}
.groupCardEllipses {
  color: white !important;
  float: right;
  max-height: 20px;
  max-width: 20px;
}
.userName{
  vertical-align: middle;
}
</style>