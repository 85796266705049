<template>
  <v-dialog
    v-model="showModal"
    persistent
    max-width="600"
    @keydown.esc="closeModal"
  >
    <v-card>
      <v-card-title class="headline">
        <v-img
          class="mr-1"
          src="assets/icon.png"
          max-height="30"
          max-width="30"
          contain
        ></v-img>
        {{ $t("components.2faPin.title") }}
      </v-card-title>
      <v-card-subtitle class="pt-2">
        <div class="py-2">
          <span>{{ $t("components.2faPin.pinSentence") }}</span>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <div class="mt-3">
          <v-form
            class="mx-0"
            ref="form"
            v-model="valid"
            lazy-validation
            v-on:submit.prevent
            :autocomplete="false"
          >
            <v-row style="width: 100%; margin: 0px; display: flex">
              <v-col
                class="col-12 px-1 py-0"
                style="margin-right: 0px; padding-right: 0px !important"
              >
                <v-text-field
                  :append-icon="`${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`"
                  :type="`${showPassword ? 'text' : 'password'}`"
                  @click:append="showPass()"
                  @keyup.enter="pinCode && valid && showModalActivation()"
                  autocomplete="off"
                  v-model="pinCode"
                  outlined
                  dense
                  @keydown.space.prevent
                  @keypress="onCodeInput"
                  :maxlength="
                    this.state.namespaceSettings.pinProtectionType === 'complex'
                      ? 12
                      : 6
                  "
                  required
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
            <v-alert v-if="errMsg" type="error" :dismissible="true">{{
              errMsg
            }}</v-alert>
            <div class="d-flex justify-end mt-3">
              <v-btn
                color="primary"
                hide-details
                small
                :disabled="!pinCode || !valid || !pinCorrectFormed"
                @click="showModalActivation()"
                >{{ $t("components.2faSms.btnEnable") }}</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-card-text>
      <!-- footer modal -->
      <v-card  class="py-2 pl-4 mt-4 pr-2 footerModal footersBackground">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn
              @click="closeModal()"
              style="border-radius: 4px; min-width: 45px"
              icon
              tile
              outlined
              color="primary"
            >
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
    <TwoFactorActivationModal
      v-if="showActivation"
      :showActivation="showActivation"
      :toggle2faActivationModal="toggle2faActivationModal"
      :saveEntryNoPhone="saveEntryNoPhone"
    ></TwoFactorActivationModal>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { setup2FA, enable2FA } from "../../../lib/wsMsg";
import TwoFactorActivationModal from "./steps/2faActivationModal.vue";
export default {
  props: ["showModal", "toggle2faPin"],
  components: {
    TwoFactorActivationModal,
  },
  data() {
    return {
      state: store.state,
      valid: true,
      errMsg: "",
      pinCode: "",
      complexPinRegex:
        /^(?=.*[0-9])(?=.*[a-zñöäü])(?=.*[A-ZÖÄÜÑ])(?=.*[*.!@$%^&(){}[\]\/:;<>,.?~_\+\-\=|]).{12,}$/,
      showPassword: false,
      showActivation: false,
    };
  },
  computed: {
    complexPinActive() {
      return store.state.namespaceSettings.pinProtectionType === "complex";
    },
    pinCorrectFormed() {
      if (this.complexPinActive) {
        if (this.pinCode.length < 12) {
          return false;
        } else if (this.pinCode.length >= 12) {
          return this.complexPinRegex.test(this.pinCode);
        }
      } else if (!this.complexPinActive) {
        if (this.pinCode.length !== 6) {
          return false;
        } else {
          return /^\d{6}$/.test(this.pinCode);
        }
      }
      return false;
    },
  },
  methods: {
    onCodeInput(evt) {
      if (this.state.namespaceSettings.pinProtectionType === "complex") return;
      if (evt.which < 48 || evt.which > 57) evt.preventDefault();
    },
    saveEntryNoPhone() {
      this.showActivation = false;
      return this.enable2faPin();
    },
    toggle2faActivationModal() {
      this.showActivation = !this.showActivation;
    },
    showPass() {
      this.showPassword = !this.showPassword;
    },
    async loadPinSetup() {
      this.errMsg = "";
      const result = await setup2FA("pin").catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (!result && !this.errMsg) {
        this.errMsg = this.$t("components.2faSms.anErrorOccurred");
      }
    },
    closeModal() {
      this.toggle2faPin();
    },
    showModalActivation() {
      if (
        this.state.user.recoveryPhone ||
        this.state.persisted.ignoreRecoveryPhone
      ) {
        return this.enable2faPin();
      } else {
        this.showActivation = true;
      }
    },
    async enable2faPin() {
      if (this.$refs.form.validate()) {
        this.errMsg = "";
        const result = await enable2FA(this.pinCode).catch((err) => {
          this.errMsg = err.message || err;
          console.warn(err);
          return false;
        });
        if (result) {
          this.toggle2faPin(true);
        } else if (!this.errMsg) {
          this.errMsg = this.$t("components.2faOtp.invalidCode");
        }
      }
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (value) {
        if (value) this.loadPinSetup();
      },
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>