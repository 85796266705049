var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      !_vm.isGuest && !_vm.mini
        ? _c(
            "v-list-group",
            {
              attrs: {
                "active-class": "listColor",
                value:
                  _vm.state.searchTerm.length > 0
                    ? false
                    : null || _vm.isActive,
                appendIcon: _vm.sortedVisitors.length == 0 ? null : "$expand"
              },
              on: {
                click: function($event) {
                  _vm.setList("visitors")
                  _vm.openMenuVisitors()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "v-list-item-icon",
                          {
                            class:
                              "" +
                              (_vm.getRoute === "/visitors"
                                ? "primary--text"
                                : ""),
                            staticStyle: { "min-height": "0px !important" }
                          },
                          [
                            _vm.currentPage("/guest")
                              ? _c("font-awesome-icon", {
                                  staticClass: "primary--text",
                                  attrs: { icon: ["fas", "user-friends"] }
                                })
                              : _c("font-awesome-icon", {
                                  staticClass: "primary--text",
                                  attrs: { icon: ["fal", "user-friends"] }
                                }),
                            _vm._v(" "),
                            _vm.showCustomTT
                              ? _c("CustomTT", {
                                  attrs: {
                                    text:
                                      _vm.$t("components.hotkeys.controlKey") +
                                      " + I",
                                    position: "bottom"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-title",
                          {
                            staticClass: "primary--text",
                            class:
                              "" +
                              (_vm.currentPage("/guest")
                                ? "font-weight-bold primary--text"
                                : ""),
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.openGridVisitors()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("generics.invitationSidebar")) +
                                "\n            "
                            ),
                            _c(
                              "span",
                              [
                                _vm.sortedVisitors.length > 0
                                  ? [
                                      _vm._v("\n                ("),
                                      _c("span", { staticClass: "textColor" }, [
                                        _vm._v(
                                          _vm._s(_vm.sortedVisitors.length)
                                        )
                                      ]),
                                      _vm._v(")\n              ")
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "btnAddGroup",
                                attrs: { icon: "", disabled: false },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.openInviteVisitor.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "primary--text",
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "plus-circle"] }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                885705913
              )
            },
            [
              _vm._v(" "),
              _c("VisitorList", {
                attrs: { visitors: _vm.sortedVisitors, section: "My Visitors" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handlerClickVisitorsMini()
                                  }
                                }
                              },
                              [
                                _vm.currentPage("/guest")
                                  ? _c("font-awesome-icon", {
                                      staticClass:
                                        "primary--text cursorPointer",
                                      staticStyle: { outline: "none" },
                                      attrs: { icon: ["fas", "user-friends"] },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.handlerClickVisitorsMini()
                                        }
                                      }
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass:
                                        "primary--text cursorPointer",
                                      staticStyle: { outline: "none" },
                                      attrs: { icon: ["fal", "user-friends"] }
                                    })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3185129123
              )
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.guests")))])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }