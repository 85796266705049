var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          staticClass: "ml-0",
          attrs: { row: "", wrap: "", "justify-start": "" }
        },
        [
          _vm.hasPrivilege(_vm.ownUUID) && !_vm.isMobile
            ? _c("DrawerContentCard", {
                ref: "drawerContentCardRef",
                attrs: { mini: _vm.mini, primaryDrawer: _vm.primaryDrawer }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-app-bar",
            { class: "topBar " + (_vm.isMobile ? "px-2" : "") },
            [
              _vm.hasPrivilege(_vm.ownUUID) && !_vm.isMobile
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-app-bar-nav-icon",
                                  _vm._g(
                                    {
                                      staticClass: "navIcon mb-0",
                                      attrs: {
                                        color: _vm.isDarkMode
                                          ? "white"
                                          : "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.openCloseMenu()
                                          _vm.saveStatusSidebar()
                                        }
                                      }
                                    },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3175250358
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.navbar.sidebarSwitch"))
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showReducedNavbar
                ? [
                    _vm.state.currentContentVisile.showComponent &&
                    (_vm.state.user.rtcCallStart ||
                      Object.keys(_vm.state.remoteBridgeStreams).length > 0 ||
                      (_vm.state.user.inBridgeCall &&
                        _vm.state.user.inBridgeCall.length > 0))
                      ? _c("ReturnToCall")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.systemMessagesArray &&
                    _vm.systemMessagesArray.length > 0
                      ? _c("SystemMessagesBar", {
                          attrs: {
                            systemMessagesArray: _vm.systemMessagesArray
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.showReducedNavbar
                ? [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-start": "",
                          "flex-column": ""
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-img",
                                            _vm._g(
                                              {
                                                staticClass: "mb-0 mt-0",
                                                class: {
                                                  "ml-4 mt--6": _vm.isMobile,
                                                  "ml-3":
                                                    _vm.isMobile &&
                                                    !_vm.hasPrivilege(
                                                      _vm.ownUUID
                                                    ),
                                                  "mr-2 ml-2": !_vm.isMobile,
                                                  "mt-0 ml-5":
                                                    !_vm.isMobile &&
                                                    !_vm.hasPrivilege(
                                                      _vm.ownUUID
                                                    )
                                                },
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                attrs: {
                                                  "max-height": !_vm.isMobile
                                                    ? _vm.hasPrivilege(
                                                        _vm.ownUUID
                                                      )
                                                      ? 40
                                                      : 40
                                                    : 35,
                                                  "max-width": !_vm.isMobile
                                                    ? _vm.hasPrivilege(
                                                        _vm.ownUUID
                                                      )
                                                      ? 40
                                                      : 40
                                                    : 35,
                                                  src: "assets/icon.png",
                                                  contain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.takeMeHome()
                                                  }
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4238223469
                                )
                              },
                              [_vm._v(" "), _c("span", [_vm._v("vOffice")])]
                            ),
                            _vm._v(" "),
                            !_vm.isMobile
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "font-weight-bold linkApp customTitle",
                                    attrs: {
                                      href: "https://www.voffice.pro",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              vOffice\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-layout",
                      {
                        staticClass: "text-center",
                        attrs: { row: "", wrap: "", "justify-center": "" }
                      },
                      [
                        _vm.hasPrivilege(_vm.ownUUID)
                          ? _c(
                              "v-list-item",
                              { staticClass: "justify-center" },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "navbarCenter" },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "centerTitle" },
                                      [
                                        !_vm.showVirtualOfficePrefix
                                          ? _c(
                                              "v-list-item-avatar",
                                              {
                                                class: {
                                                  "mb-0 mx-0 mt-4":
                                                    _vm.isMobile,
                                                  "mb-3 mt-0 mr-2 ml-0": !_vm.isMobile
                                                }
                                              },
                                              [
                                                !!_vm.showCompanyLogo
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-img",
                                                                    _vm._g(
                                                                      {
                                                                        class: {
                                                                          "ml-2 mt--6":
                                                                            _vm.isMobile
                                                                        },
                                                                        attrs: {
                                                                          "max-height": !_vm.isMobile
                                                                            ? 40
                                                                            : 35,
                                                                          "max-width": !_vm.isMobile
                                                                            ? 40
                                                                            : 35,
                                                                          src:
                                                                            _vm.showCompanyLogo,
                                                                          contain:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.goBackLogicNavbar()
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          1575360373
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.navbar.companyLogoTT"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.showVirtualOfficePrefix &&
                                        !_vm.isMobile
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    "font-weight-bold customTitle " +
                                                    (_vm.isMobile &&
                                                    (_vm.state.namespaceSettings
                                                      .companyInfo
                                                      .virtualOfficePrefix
                                                      .length > 21 ||
                                                      _vm.virtualOfficePrefix
                                                        .length > 21)
                                                      ? "font13"
                                                      : "")
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.state
                                                        .namespaceSettings
                                                        .companyInfo
                                                        .virtualOfficePrefix ||
                                                        _vm.virtualOfficePrefix
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.showStatusNumber && !_vm.isMobile
                                          ? [
                                              _c("StatsNumbers", {
                                                attrs: { inNavbar: true }
                                              })
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.showVirtualOfficePrefix &&
                                        !_vm.isMobile
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "my-0 py-0 mx-auto"
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    rules: _vm.rules,
                                                    "hide-details": "",
                                                    autofocus: ""
                                                  },
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.changeVirtualOfficePrefix()
                                                    },
                                                    blur: function($event) {
                                                      return _vm.changeVirtualOfficePrefix()
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.state
                                                        .namespaceSettings
                                                        .companyInfo
                                                        .virtualOfficePrefix,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.state
                                                          .namespaceSettings
                                                          .companyInfo,
                                                        "virtualOfficePrefix",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                      state.namespaceSettings.companyInfo.virtualOfficePrefix\n                    "
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.hasPrivilege(_vm.ownUUID)
                          ? _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        class:
                                          "font-weight-bold body-1 " +
                                          (_vm.isMobile ? "pr-0" : "mb-5")
                                      },
                                      [
                                        !_vm.showVirtualOfficePrefix
                                          ? _c(
                                              "v-list-item-avatar",
                                              {
                                                class: {
                                                  "mb-0 mx-0 mt-0":
                                                    _vm.isMobile,
                                                  "mb-3 mt-1 mr-0": !_vm.isMobile,
                                                  logoMobileGuest:
                                                    _vm.isMobile &&
                                                    !_vm.hasPrivilege(
                                                      _vm.ownUUID
                                                    )
                                                }
                                              },
                                              [
                                                !!_vm.showCompanyLogo
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-img",
                                                                    _vm._g(
                                                                      {
                                                                        class: {
                                                                          "ml-2 mt--6":
                                                                            _vm.isMobile
                                                                        },
                                                                        attrs: {
                                                                          "max-height": !_vm.isMobile
                                                                            ? 30
                                                                            : 25,
                                                                          "max-width": !_vm.isMobile
                                                                            ? 30
                                                                            : 25,
                                                                          src:
                                                                            _vm.showCompanyLogo,
                                                                          contain:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.goBackLogicNavbar()
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          1811869493
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.navbar.companyLogoTT"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.showVirtualOfficePrefix &&
                                        !_vm.isMobile
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    " font-weight-bold customTitle " +
                                                    (_vm.isMobile &&
                                                    (_vm.state.namespaceSettings
                                                      .companyInfo
                                                      .virtualOfficePrefix
                                                      .length > 21 ||
                                                      _vm.virtualOfficePrefix
                                                        .length > 21)
                                                      ? "font13"
                                                      : ""),
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.setshowVirtualOfficePrefix(
                                                        true
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.state
                                                        .namespaceSettings
                                                        .companyInfo
                                                        .virtualOfficePrefix ||
                                                        _vm.virtualOfficePrefix
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    !_vm.isMobile
                                      ? _c(
                                          "v-list-item-subtitle",
                                          {
                                            staticClass:
                                              "subtitleWaitingroom font-weight-medium",
                                            class: _vm.isDarkMode
                                              ? "white--text"
                                              : "black--text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getSubtitleWaitingroom
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-layout",
                      {
                        staticClass: "px-2",
                        attrs: { row: "", wrap: "", "justify-end": "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { shrink: "" } },
                          [
                            _c(
                              "v-flex",
                              { staticClass: "ml-2" },
                              [
                                _vm.state.currentContentVisile.showComponent &&
                                (_vm.state.user.rtcCallStart ||
                                  Object.keys(_vm.state.remoteBridgeStreams)
                                    .length > 0 ||
                                  (_vm.state.user.inBridgeCall &&
                                    _vm.state.user.inBridgeCall.length > 0))
                                  ? _c("ReturnToCall")
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isMobile && !_vm.isNotificationMuted
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.setMuteUnmute()
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      !_vm.isDarkMode
                                                        ? _c("img", {
                                                            staticClass:
                                                              "themeIcon",
                                                            staticStyle: {
                                                              width: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "img/icons/volume-01.svg"
                                                            }
                                                          })
                                                        : _c("img", {
                                                            staticClass:
                                                              "themeIcon",
                                                            staticStyle: {
                                                              width: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "img/icons/volume-01_white.svg"
                                                            }
                                                          })
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2292752989
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("generics.muteSound"))
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isMobile && _vm.isNotificationMuted
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        class: _vm.notificationBlinking
                                                          ? "mutedIconBlink"
                                                          : "",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.setMuteUnmute()
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "themeIcon",
                                                        staticStyle: {
                                                          width: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.notificationBlinking
                                                            ? "img/icons/vo_unmute_red.svg"
                                                            : !_vm.isDarkMode
                                                            ? "img/icons/vo_unmute.svg "
                                                            : "img/icons/vo_unmute_white.svg"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4190396363
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("generics.unmuteSound")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.hasPrivilege(_vm.ownUUID)
                                  ? _c("NotificationsWaitingRoom", {
                                      attrs: {
                                        setTotalNotificationCall:
                                          _vm.setTotalNotificationCall,
                                        setTotalNotificationFile:
                                          _vm.setTotalNotificationFile,
                                        iconsColor: "#38a2b8",
                                        isNavbar: true
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isMobile
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "default",
                                                          small: _vm.isMobile
                                                        },
                                                        on: {
                                                          click: _vm.switchTheme
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm.$vuetify.theme.dark
                                                        ? _c("img", {
                                                            staticClass:
                                                              "themeIcon",
                                                            attrs: {
                                                              src:
                                                                "img/day-mode_white.svg"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.$vuetify.theme.dark
                                                        ? _c("img", {
                                                            staticClass:
                                                              "themeIcon",
                                                            attrs: {
                                                              src:
                                                                "img/night-mode.svg"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3563232060
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$vuetify.theme.dark
                                                ? _vm.$t("generics.dayMode")
                                                : _vm.$t("generics.nightMode")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasPrivilege(_vm.ownUUID)
                                  ? [
                                      _c("Settings", {
                                        attrs: {
                                          mini: _vm.mini,
                                          primaryDrawer: _vm.primaryDrawer,
                                          amIAdmin: _vm.amIAdmin,
                                          toggleStatusColorSettings:
                                            _vm.toggleStatusColorSettings,
                                          isMobile: _vm.isMobile,
                                          infoDialog: _vm.infoDialog,
                                          toggleModalAdminSettings:
                                            _vm.toggleModalAdminSettings,
                                          seeOrganisationExcel:
                                            _vm.seeOrganisationExcel,
                                          showProtocol: _vm.showProtocol,
                                          setShowBeacon: _vm.setShowBeacon,
                                          toggle2faSettings:
                                            _vm.toggle2Modal2faSetting,
                                          toggleModalRepresentative:
                                            _vm.toggleModalRepresentative,
                                          toggleShowHolidaysModal:
                                            _vm.toggleShowHolidaysModal,
                                          showDialUpTime: _vm.showDialUpTime,
                                          showStartView: _vm.showStartView,
                                          logout: _vm.logout,
                                          showSpeechlive: _vm.showSpeechlive,
                                          openCloseMenu: _vm.openCloseMenu
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.hasPrivilege(_vm.ownUUID)
                                  ? [
                                      _c("SettingsWaitingRoomUser", {
                                        attrs: {
                                          toggleModalSettings:
                                            _vm.toggleModalSettings
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasPrivilege(_vm.ownUUID)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          small: _vm.isMobile,
                                                          id: "v-tour-logout"
                                                        },
                                                        on: {
                                                          click: _vm.logout
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      !_vm.isDarkMode
                                                        ? _c("img", {
                                                            staticStyle: {
                                                              width: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/img/icon-logout-navbar.svg"
                                                            }
                                                          })
                                                        : _c("img", {
                                                            staticStyle: {
                                                              width: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/img/icon-logout-navbar_white.svg"
                                                            }
                                                          })
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3041873927
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("generics.logoutTT"))
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showTimeline
                ? _c(
                    "div",
                    { staticClass: "headerMeetingLine" },
                    [
                      _vm.showMeetingLine && !_vm.isMobile
                        ? _c("MeetingLine", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isVisitor(this.ownUUID),
                                expression: "!isVisitor(this.ownUUID)"
                              }
                            ]
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.infoDialog.show,
            callback: function($$v) {
              _vm.$set(_vm.infoDialog, "show", $$v)
            },
            expression: "infoDialog.show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("components.drawerContentCard.information")
                      ) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [_vm._v(_vm._s(_vm.infoDialog.info))]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "8", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.infoDialog = { show: false, info: "" }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.accept")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAdminSettings
        ? _c("AdminSettingModal", {
            attrs: {
              showAdminSettings: _vm.showAdminSettings,
              toggleModalAdminSettings: _vm.toggleModalAdminSettings
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("StatusColorModal", {
        attrs: {
          showStatusColorSettings: _vm.showStatusColorSettings,
          toggleStatusColorSettings: _vm.toggleStatusColorSettings
        }
      }),
      _vm._v(" "),
      _c("ProtocolModal", {
        attrs: { showProtocol: _vm.showProtocol, protocol: _vm.protocol }
      }),
      _vm._v(" "),
      _vm.show2faSettings
        ? _c("TwofaModalContainer", {
            attrs: {
              showModalStatus: _vm.show2faSettings,
              toggle2Modal2faSetting: _vm.toggle2Modal2faSetting
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("DialUpTimeModal", {
        attrs: {
          showDialUpTime: _vm.showDialUpTime,
          dialUpTime: _vm.dialUpTime
        }
      }),
      _vm._v(" "),
      _vm.startView
        ? _c("StartViewModal", {
            attrs: {
              showStartView: _vm.showStartView,
              startView: _vm.startView
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("SpeechliveModal", {
        attrs: {
          showSpeechlive: _vm.showSpeechlive,
          speechlive: _vm.speechlive
        }
      }),
      _vm._v(" "),
      _vm.showSetupDevice
        ? _c("DeviceSetupModal", {
            attrs: {
              showSetupDevice: _vm.hasPrivilege
                ? _vm.showSetupDevice
                : _vm.showDeviceGuest,
              toggleModalSettings: _vm.toggleModalSettings
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showVisitorNameModal ? _c("SetVisitorNameModal") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }