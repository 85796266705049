var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "460" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showErrorRemoteStorage,
        callback: function($$v) {
          _vm.showErrorRemoteStorage = $$v
        },
        expression: "showErrorRemoteStorage"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("components.adminSettingsModal.remoteStorage")
                  ) +
                  "\n      "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _vm._v(
              _vm._s(_vm.$t("components.adminSettingsModal.errorRemoteStorage"))
            )
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-checkbox", {
                    staticClass: "mencheckbox chkList sel-check-item py-0 my-0",
                    attrs: {
                      label: _vm.$t(
                        "components.adminSettingsModal.enableRemoteStorageftp"
                      )
                    },
                    on: { change: _vm.enableDisabledRemoteStorage },
                    model: {
                      value: _vm.enableRemoteStorage,
                      callback: function($$v) {
                        _vm.enableRemoteStorage = $$v
                      },
                      expression: "enableRemoteStorage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      disabled: !_vm.enableRemoteStorage,
                      label: "URL"
                    },
                    model: {
                      value: _vm.url,
                      callback: function($$v) {
                        _vm.url = $$v
                      },
                      expression: "url"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      disabled: !_vm.enableRemoteStorage,
                      label: "Username"
                    },
                    model: {
                      value: _vm.username,
                      callback: function($$v) {
                        _vm.username = $$v
                      },
                      expression: "username"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      disabled: !_vm.enableRemoteStorage,
                      label: "Password"
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      type: "number",
                      disabled: !_vm.enableRemoteStorage,
                      label: "Port"
                    },
                    model: {
                      value: _vm.port,
                      callback: function($$v) {
                        _vm.port = $$v
                      },
                      expression: "port"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("v-subheader", { staticClass: "pl-0 mb-5" }, [
                    _vm._v("Sync intervale")
                  ]),
                  _vm._v(" "),
                  _c("v-slider", {
                    attrs: {
                      min: "1",
                      max: "60",
                      "thumb-label": "always",
                      disabled: !_vm.enableRemoteStorage
                    },
                    model: {
                      value: _vm.syncIntervale,
                      callback: function($$v) {
                        _vm.syncIntervale = $$v
                      },
                      expression: "syncIntervale"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        disabled: _vm.disabledSaveRemoteStorage,
                        depressed: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveRemoteStorageData()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("generics.save")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "8", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.close")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }