<template>
  <v-col @click.stop>
    <div class="position-relative">
      <v-textarea v-model="editedContent" :label="$t('components.socialMedia.editYourPost')" auto-grow
        variant="outlined" rows="3" />
      <v-btn :disabled="(imagePreviews.length + documentPreviews.length) >= maxAttachments" @click="triggerFileInput" v-bind="props" icon variant="text"
        size="small" class="me-auto position-absolute bottom-0 ml-1" style=" bottom: 21px!important">
        <font-awesome-icon :icon="['fal', 'image']" :style="{ fontSize: '20px' }" />
      </v-btn>
      <input ref="fileInput" type="file" multiple
        accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        @change="handleFileChange" style="display: none" />
    </div>
    <div class="w100 d-flex">
      <!-- Image Previews -->
      <div class="d-flex mt-3 gap-3 flex-wrap">
        <div v-for="(image, index) in imagePreviews" :key="index" class="preview-container mr-2">
          <img :src="image.dataUrl" alt="Preview" class="preview-image" />
          <div class="overlay" @click="removeImage(index)">
            <font-awesome-icon :icon="['fal', 'trash']" class="trash-icon" />
          </div>
        </div>
      </div>
      <!-- Document Previews -->
      <div class="d-flex mt-3 gap-3 flex-wrap">
        <div v-for="(document, index) in documentPreviews" :key="index" class="document-preview-container mr-2">
          <font-awesome-icon :icon="['fal', 'file']" class="document-icon" />
          <span class="ml-1 document-text text-truncate">
            {{ document.name }}
          </span>
          <div class="overlay" @click="removeDocument(index)">
            <font-awesome-icon :icon="['fal', 'trash']" class="trash-icon" />
          </div>
        </div>
      </div>
    </div>

    <div class="text-right mt-2">
      <v-btn color="primary" @click.stop="saveEdit">{{ $t('components.socialMedia.save') }}</v-btn>
      <v-btn variant="text" class="ml-2" @click.stop="$emit('closeEdit')">{{ $t('components.socialMedia.cancel')
      }}</v-btn>
    </div>
  </v-col>
</template>

<script>
import store, { EventBus } from "../../../../store";

export default {
  props: ["postData", "viewSelected"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      editedContent: this.postData?.content.replace(/<br\s*\/?>/g, "\n") || "",
      imagePreviews: [], // Lista de imágenes previas
      documentPreviews: [],
      maxAttachments: 8,
    };
  },
  mounted() {
    // Cargar las imágenes previas si existen
    if (this.postData?.images?.length > 0) {
      this.postData.images.forEach((image) => {
        this.imagePreviews.push({ dataUrl: image });
      });
    }
    if (this.postData?.documents?.length > 0) {
      this.postData.documents.forEach((document) => {
        this.documentPreviews.push(document);
      });
    }
    EventBus.$on("closeEdit", this.closeEdit);
  },
  unmounted() {
    EventBus.$off("closeEdit", this.closeEdit);
  },
  methods: {
    closeEdit() {
      this.$emit("closeEdit");
    },

    // Trigger file input click
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // Manejar el cambio de archivo
    handleFileChange(event) {
      const files = Array.from(event.target.files);
      const remainingSlots = this.maxAttachments - (this.imagePreviews.length + this.documentPreviews.length);
      if (remainingSlots <= 0) return; // No permitir más archivos si ya se alcanzó el límite
      const filesToAdd = files.slice(0, remainingSlots);
      filesToAdd.forEach((file) => {
        if (file.type.startsWith('image/')) {
          this.compressAndConvertToBs64(file).then((compressedImage) => {
            this.imagePreviews.push({
              file,
              dataUrl: compressedImage,
            });
          });
        } else {
          // Si no es una imagen, lo agregamos como un documento
          const reader = new FileReader();
          reader.onload = (e) => {
            this.documentPreviews.push({
              file,
              base64: e.target.result, // Guardamos la base64 del documento
              name: file.name,
              type: file.type,
              size: file.size,
            });
          };
          reader.readAsDataURL(file); // Leemos el documento como base64
        }
      });
    },

    // Método de compresión y conversión a base64
    compressAndConvertToBs64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 600; // max width
            const scaleSize = MAX_WIDTH / img.width;
            canvas.width = MAX_WIDTH;
            canvas.height = img.height * scaleSize;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            const base64Image = canvas.toDataURL("image/jpeg", 0.6); // Calidad 60%
            resolve(base64Image);
          };
        };
      });
    },

    // Eliminar una imagen previa
    removeImage(index) {
      this.imagePreviews.splice(index, 1);
    },
    removeDocument(index) {
      this.documentPreviews.splice(index, 1);
    },

    // Guardar la edición del post
    saveEdit() {
      const dataToUpdate = {
        postId: this.postData._id,
        content: this.editedContent.replace(/\n/g, "<br>"),
        images: this.imagePreviews.map((image) => image.dataUrl), // Incluir las nuevas imágenes,
        documents: this.documentPreviews.map(doc => ({
          name: doc.name,
          type: doc.type,
          base64: doc.base64
        })),
      };

      let url;
      let event = "updatePost";
      if (this.viewSelected === "Post") {
        url = "/api/socialmedia/edit-post";
      } else {
        url = "/api/socialmedia/edit-whatswork";
        event = "updateWhatsWork";
      }

      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToUpdate),
      }).then((result) => {
        if (!result.error) {
          // Emitir evento para actualizar el post en el estado global
          EventBus.$emit(event, dataToUpdate);
          this.closeEdit();
        } else {
          // TODO: Manejar errores
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.preview-container {
  position: relative;
  width: 60px;
  height: 60px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.document-preview-container {
  position: relative;
  width: 60px;
  height: 6cap;
  border: 1px solid #ddd;
  /* Borde alrededor del contenedor */
  border-radius: 8px;
  /* Esquinas redondeadas */
  overflow: hidden;
  /* Evita que el contenido se desborde fuera del contenedor */
  background-color: #f9f9f9;
  /* Color de fondo */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  /* Espaciado interno */
}

.document-preview-text {
  font-size: 14px;
  white-space: nowrap;
  /* Evita que el texto se desborde */
  text-overflow: ellipsis;
  /* Si el texto es muy largo, muestra "..." al final */
  overflow: hidden;
  /* Evita que el texto se desborde fuera del contenedor */
  max-width: 100%;
  /* Asegura que el texto no se salga del contenedor */
  text-align: center;
  /* Centra el texto */
}

.document-text {
  font-size: 12px;
  max-width: 60px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 0.5rem;
}

.preview-container:hover .overlay {
  opacity: 1;
}

.document-preview-container:hover .overlay {
  opacity: 1;
  /* Muestra el overlay al pasar el ratón */
}

.trash-icon {
  color: white;
  font-size: 1.5rem;
}
</style>
