var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "580" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.toggleMeetingTimeGlobalDuration.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.meetingTime.globalDefaultTime"),
              closeModalFunction: _vm.toggleMeetingTimeGlobalDuration
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 px-3" },
            [
              _c(
                "v-col",
                { staticClass: "col-12 px-1 py-1 pt-2" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.items,
                      "item-text": "state",
                      "item-value": "value",
                      label: _vm.$t("components.meetingTime.globalDefaultTime"),
                      "return-object": ""
                    },
                    on: { change: _vm.changeGlobalMeetingDuration },
                    model: {
                      value: _vm.selectGlobalTime,
                      callback: function($$v) {
                        _vm.selectGlobalTime = $$v
                      },
                      expression: "selectGlobalTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.toggleMeetingTimeGlobalDuration }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }