var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        class:
                          _vm.getSizeImage == "rectangle"
                            ? "speechLiveIconWhite"
                            : "speechLiveIcon",
                        attrs: {
                          width: "20",
                          height: "20",
                          icon: "",
                          loading: _vm.loadingSpeechLive,
                          disabled: _vm.loadingSpeechLive
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openSpeechLive.apply(null, arguments)
                          }
                        }
                      },
                      on
                    ),
                    [
                      _vm.getSizeImage == "rectangle"
                        ? _c("v-img", {
                            attrs: {
                              src: "img/speechlive.svg",
                              height: "20",
                              width: "20",
                              contain: ""
                            }
                          })
                        : _c("v-img", {
                            attrs: {
                              src: "img/speechlive.svg",
                              height: "20",
                              width: "20",
                              contain: ""
                            }
                          })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_vm._v(" "), _c("span", [_vm._v("SpeechLive")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }