var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    {
      staticClass: "px-0",
      staticStyle: { width: "100%", height: "100%", overflow: "auto" }
    },
    [
      _c("v-row", { staticClass: "mx-0" }, [
        _c(
          "div",
          { staticClass: "mainEventView pt-2" },
          _vm._l(_vm.filteredEvents, function(evt, key) {
            return _c(
              "div",
              { key: key, staticClass: "child" },
              [
                _c(
                  "div",
                  { staticClass: "bodyHeader pb-3", attrs: { align: "left" } },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.getWeeklyDay(key, "day")) +
                          ",\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.getWeeklyDay(key)) +
                          "\n                "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm._l(evt, function(event) {
                  return _c(
                    "div",
                    {
                      key: event.uuid,
                      staticClass: "eventPill mb-4",
                      attrs: { align: "left" },
                      on: {
                        click: function($event) {
                          return _vm.showEvent(event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "backDate px-4 py-3" }, [
                        _c("div", { attrs: { align: "left" } }, [
                          _c("span", { staticClass: "font-weight-regular" }, [
                            _c("span", { staticClass: "Capital" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("components.conferenceForm.from")
                                  )
                              )
                            ]),
                            _vm._v(
                              "\n                     " +
                                _vm._s(_vm.buildHourText(event)) +
                                "\n                  "
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticClass: "dividerWeight",
                        class: _vm.getEventColor(event)
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "LineHeight px-4 py-3" }, [
                        _c("span", { staticClass: "font-weight-medium" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.buildTitleText(event)) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [
                          _c("span", { staticClass: "font-weight-regular" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("components.inviteVisitor.moderator")
                                ) +
                                ":"
                            )
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.buildModeratorText(event)) +
                              "\n                  "
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [
                          _c("span", { staticClass: "font-weight-regular" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("components.callsContent.participants")
                                ) +
                                ":"
                            )
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.buildUsersText(event)) +
                              "\n                  "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }