var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560px" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModalMessage,
        callback: function($$v) {
          _vm.showModalMessage = $$v
        },
        expression: "showModalMessage"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.titleModal,
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "messageForm",
              attrs: { "lazy-validation": "", autocomplete: false },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-5", style: { width: "100%", margin: 0 } },
                [
                  _c("v-textarea", {
                    attrs: {
                      counter: "",
                      contenteditable: "true",
                      label: _vm.$t("components.sendMessageModal.message"),
                      rules: _vm.rules,
                      "no-resize": true,
                      maxlength: "250",
                      autofocus: "",
                      id: "messageTextarea"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape"
                          ])
                        ) {
                          return null
                        }
                        return _vm.closeModal.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.messagetext,
                      callback: function($$v) {
                        _vm.messagetext = $$v
                      },
                      expression: "messagetext"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FooterModal",
                {
                  attrs: {
                    closeModalFunction: _vm.closeModal,
                    showCancel: true
                  }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled:
                          _vm.messagetext.length == 0 || !_vm.messagetext
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendMessage()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("components.sendMessageModal.send")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }