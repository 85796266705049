import { render, staticRenderFns } from "./departments.vue?vue&type=template&id=1efb45f6&scoped=true"
import script from "./departments.vue?vue&type=script&lang=js"
export * from "./departments.vue?vue&type=script&lang=js"
import style0 from "./departments.vue?vue&type=style&index=0&id=1efb45f6&scoped=true&lang=scss"
import style1 from "./departments.vue?vue&type=style&index=1&id=1efb45f6&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1efb45f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/voffice-dev04/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1efb45f6')) {
      api.createRecord('1efb45f6', component.options)
    } else {
      api.reload('1efb45f6', component.options)
    }
    module.hot.accept("./departments.vue?vue&type=template&id=1efb45f6&scoped=true", function () {
      api.rerender('1efb45f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/sidebar/departments.vue"
export default component.exports