
<template>
  <div>
    <v-avatar
      :class="`${!imageAssistant ? 'borderRadius0' : ''}  whiteBackground defaultAvatar ${showSectionName ? 'gridStyle' : ''} ${imageAssistant ? 'imageAssistant' : ''} ${isGroupGrid ? 'displayGroupGrid': ''}`"
      width="100%"
      height="100%"
      :color="null"
    >
    <!-- START SECTION NAME -->
      <span v-if="showSectionName" class="letterColor">{{
        mainSectionName
      }}</span>
      <!-- END SECTION NAME -->
      <span v-if="isGroupGrid" class="title letterColor headlingGroupGrid"
        >{{ getGroupName }} 
        <br v-if="getGroupName">
        {{ getGroupTotalMembers }}
        <br v-if="getGroupDateTime">
          {{ getGroupDateTime ?  moment(new Date(getGroupDateTime)).format('DD.MM.YY H:mm') : ''}}
        </span>
      <v-img
        v-if="isGuestGrid || isUserGrid"
        class="cursorPointer"
        :src="userAvatar"
        :contain="getPictureSize"
        :lazy-src="getDefaultPicture"
        :class="{'borderColor': imageAssistant, 'borderNone': !imageAssistant, '': !isLateralPanel}"
        :style="imageAssistant ? `border-color: ${getStatusColorByUser(userData.user, userData.connected)}` : ''"
      ></v-img>
    </v-avatar>
  </div>
</template>
<script>
import store from "../../store";
import { getPictureSize } from "../../utils/basicFunctions";
import { getStatusColorByUser } from '../../utils/status';
import moment from 'moment';
export default {
  props: [
    "userData",
    "sectionName",
    "groupData",
    "showSectionName",
    "mainSectionName",
    "mainDepartmentName",
    "imageAssistant",
    "userAvatar",
    "showDepartmentName",
    "isLateralPanel"
  ],
  data: () => ({
    state: store.state,
    moment: moment
  }),
  methods: {
    getStatusColorByUser(user, connected){
      return getStatusColorByUser(user, connected)
    },
    firstNameLetter() {
      const name = this.userData.user && !this.userData.user.group 
                  ? this.userData.user.name 
                  : this.userData?.name;

      if (name) {
        const firstNameChar = name.split(" ")[0]?.charAt(0);
        return firstNameChar || "";
      }

      return "";
    },
    firstSurnameLetter() {
      const name = this.userData.user && !this.userData.user.group 
                  ? this.userData.user.name 
                  : this.userData?.name;

      if (name) {
        const surnameChar = name.split(" ")[1]?.charAt(0);
        return surnameChar || "";
      }

      return "";
    }

  },
  computed: {
    getDefaultPicture() {
      return "img/default_profile_picture.png";
    },
    getPictureSize() {
      return getPictureSize();
    },
    isGroupGrid() {
      if (this.groupData) {
        return true;
      }
      return false;
    },
    isUserGrid() {
      return this.userData && !this.userData.guest && !(this.userData.user?.guest);
    },

    isGuestGrid() {
      return this.userData && (this.userData.guest || this.userData.user?.guest);
    },
    getGroupName() {
      if (this.groupData && this.groupData.title) {
        return this.groupData.title;
      }
      return null;
    },
    getGroupDateTime(){
      if (this.groupData && this.groupData.dateTimeStart && this.groupData.dateTimeStart.trim() !== 'null') {
        return this.groupData.dateTimeStart;
      }
      return false;
    },
    getGroupTotalMembers() {
      if (this.groupData && this.groupData.members) {
        return "(" + this.groupData.members.length + ")";
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-avatar.v-avatar--size-default {
  --v-avatar-height: 60px !important;
}
</style>
<style scoped lang="scss">
.displayGroupGrid{
  display: table;
}
.headlingGroupGrid{
      display: table-cell;
    vertical-align: middle;
}
.borderColor{
  border-radius: 50% !important;
  border: 1px solid;
}
.imageAssistant{
    height: 30px !important;
    width: 30px !important;
    min-height: 30px !important;
    min-width: 30px !important;
    border-radius: 50% !important;
}
.fontSize15 {
  font-size: 15px;
}
.fontWeight600 {
  font-weight: 600 !important;
}
.gridStyle {
  display: grid;
  grid: auto;
  grid-template-rows: 36px 3em 20px;
}
.borderNone {
  border-radius: 0px !important;
}
.borderRadius0 {
  border-radius: 0px !important;
}
.letterColor {
  color: black !important;
  font-size: 14px;
  font-weight: 700!important;
}
.whiteBackground {
  background-color: white;
}
// .ellipsisClass {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
</style>

