var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      ref: "modalDirectInviteCall",
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showDirectInviteCallModal,
        callback: function($$v) {
          _vm.showDirectInviteCallModal = $$v
        },
        expression: "showDirectInviteCallModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", { attrs: { titleModal: "Link" } }),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c(
                  "v-row",
                  [
                    _c("v-text-field", {
                      ref: "linkRef",
                      attrs: {
                        id: "inputLink",
                        readonly: "",
                        dense: "",
                        outlined: "",
                        "hide-details": "",
                        lable: "Direct lnk"
                      },
                      model: {
                        value: _vm.showDirectInviteCallModal,
                        callback: function($$v) {
                          _vm.showDirectInviteCallModal = $$v
                        },
                        expression: "showDirectInviteCallModal"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.closeModal, showFooter: true }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { depressed: "" },
                    on: {
                      click: function($event) {
                        return _vm.copyLink($event)
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      style: { fontSize: "18px" },
                      attrs: { icon: ["fal", "copy"] }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }