var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { outlined: "" } },
    [
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "headline" }, [
                _vm._v("vOffice support")
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-subtitle",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "a",
                    {
                      ref: "supportMail",
                      staticStyle: { "text-decoration": "unset" },
                      attrs: { href: "mailto: support-voffice@ra-micro.de" }
                    },
                    [
                      _c("font-awesome-icon", {
                        style: { fontSize: "20px" },
                        attrs: { icon: ["fal", "envelope-square"] }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-2",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.goToMail()
                        }
                      }
                    },
                    [_vm._v("support-voffice@ra-micro.de")]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-avatar",
            { attrs: { tile: "", size: "80", color: "#9e9e9e21" } },
            [
              _c("font-awesome-icon", {
                style: { fontSize: "40px" },
                attrs: { icon: ["fal", "user-headset"], color: "#00889C" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      [
        _c(
          "v-row",
          { staticClass: "mx-4 scrollfaqs" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.faqs.basicInformation.basicInformation")
                  )
              )
            ]),
            _vm._v(" "),
            _c(
              "v-expansion-panels",
              { staticClass: "my-3", attrs: { focusable: "" } },
              _vm._l(5, function(item, i) {
                return _c(
                  "v-expansion-panel",
                  { key: i },
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.basicInformation.whatIsvOffice"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.basicInformation.whatOperatinSystem"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.basicInformation.isvOfficeAvailableApp"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.basicInformation.whatBrowser"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.basicInformation.howDoIStart"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.basicInformation.whatIsvOfficeText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.basicInformation.whatOperatinSystemText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c("v-list-item-content", {}, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.faqs.basicInformation.isvOfficeAvailableAppText"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n               " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.faqs.basicInformation.isvOfficeAvailableAppText1"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "https://apps.apple.com/de/app/voffice-app/id1523298495"
                                  }
                                },
                                [
                                  _vm._v(
                                    "https://apps.apple.com/de/app/voffice-app/id1523298495"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n               " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.faqs.basicInformation.isvOfficeAvailableAppText2"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "https://play.google.com/store/apps/details?id=com.voffice.vofficepro&gl=DE"
                                  }
                                },
                                [
                                  _vm._v(
                                    " https://play.google.com/store/apps/details?id=com.voffice.vofficepro&gl=DE"
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.basicInformation.whatBrowserText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.basicInformation.howDoIStartText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("components.faqs.utilization.utilization"))
              )
            ]),
            _vm._v(" "),
            _c(
              "v-expansion-panels",
              { staticClass: "my-3", attrs: { focusable: "" } },
              _vm._l(18, function(item, i) {
                return _c(
                  "v-expansion-panel",
                  { key: i },
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoInvite"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoInviteVisitor"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoCreateConference"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoOpenSidepanel"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.whatIsMaximunParticipants"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 5
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoCommunicate"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 6
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canIAddMoreParticipants"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 7
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoIUseWaiting"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 8
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoIForward"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 9
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canIMute"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 10
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canITurnOffCamera"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 11
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howToShare"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 12
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howCanExtendVideo"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 13
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canVideoConferenceRecorded"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 14
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howCanAdjustUserData"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 15
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howCanIChangeUserRights"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 16
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howCanIChangePicture"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 17
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoIUseTwo"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.howDoInviteText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoInviteVisitorText"
                                        )
                                        .join("\n")
                                        .trim()
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoCreateConferenceText"
                                        )
                                        .join("\n")
                                        .trim()
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoOpenSidepanelText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.whatIsMaximunParticipantsText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 5
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoCommunicateText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 6
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canIAddMoreParticipantsText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 7
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoIUseWaitingText"
                                        )
                                        .join("\n")
                                        .trim()
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 8
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoIForwardText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 9
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canIMuteText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 10
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canITurnOffCameraText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 11
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howToShareText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 12
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howCanExtendVideoText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 13
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.utilization.canVideoConferenceRecordedText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 14
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howCanAdjustUserDataText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 15
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howCanIChangeUserRightsText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 16
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howCanIChangePictureText"
                                        )
                                        .join("\n")
                                        .trim()
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 17
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.utilization.howDoIUseTwoText"
                                        )
                                        .join("\n")
                                        .trim()
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.faqs.technicalQuestion.technicalQuestion"
                    )
                  )
              )
            ]),
            _vm._v(" "),
            _c(
              "v-expansion-panels",
              { staticClass: "my-3", attrs: { focusable: "" } },
              _vm._l(13, function(item, i) {
                return _c(
                  "v-expansion-panel",
                  { key: i },
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.iAmNotAsked"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.howCanITestCamera"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.camerNotWork"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.connectionQuality"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.vOfficeTerminal"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 5
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.theWindowIWant"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 6
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.whyDoISee"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 7
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.vOfficeDontFind"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 8
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.vOfficeStartUp"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 9
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.theEmail"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 10
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.whatIsSignaling"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 11
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.whatIsStun"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 12
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.whatIsTurn"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.iAmNotAskedText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.howCanITestCameraText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.camerNotWorkText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.connectionQualityText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.vOfficeTerminalText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 5
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.theWindowIWantText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 6
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.whyDoISeeText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 7
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.vOfficeDontFindText"
                                        )
                                        .join("\n")
                                        .trim()
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 8
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.vOfficeStartUpText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 9
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.theEmailText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 10
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.technicalQuestion.whatIsSignalingText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 11
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.whatIsStunText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 12
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.technicalQuestion.whatIsTurnText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.faqs.dataProtection.dataProtection")
                  )
              )
            ]),
            _vm._v(" "),
            _c(
              "v-expansion-panels",
              { staticClass: "my-3", attrs: { focusable: "" } },
              _vm._l(5, function(item, i) {
                return _c(
                  "v-expansion-panel",
                  { key: i },
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.dataProtection.whereCanIFindPrivacy"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.dataProtection.whereIsData"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.dataProtection.whatData"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.dataProtection.whyIsSafe"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "subtitle-2" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.dataProtection.canThirdParties"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      [
                        i == 0
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.dataProtection.whereCanIFindPrivacyText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 1
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.dataProtection.whereIsDataText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 2
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n             " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.dataProtection.whatDataText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 3
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "components.faqs.dataProtection.whyIsSafeText"
                                        )
                                        .join("\n")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        i == 4
                          ? _c(
                              "v-list-item-content",
                              { staticClass: "preLine" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.faqs.dataProtection.canThirdPartiesText"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }