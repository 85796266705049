var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.doLeavePage.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showConfrimModal,
        callback: function($$v) {
          _vm.showConfrimModal = $$v
        },
        expression: "showConfrimModal"
      }
    },
    [
      [
        _c(
          "v-card",
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("generics.info"),
                closeModalFunction: _vm.doLeavePage
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "px-5 mb-5 mt-5",
                style: { width: "100%", margin: 0 }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("components.conferences.saveData")) +
                      "\n        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isDebugging
              ? _c(
                  "v-row",
                  {
                    staticClass: "px-5 mb-5 mt-5",
                    style: { width: "100%", margin: 0 }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.showDiff) + "\n        ")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: {
                  hideDefaultButtons: true,
                  closeModalFunction: _vm.doLeavePage,
                  showFooter: true
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.doLeavePage()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("components.conferences.yes")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.doStayInPage()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("components.conferences.no")) + "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }