var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h100" },
    [
      _vm.fullCall
        ? _c("fullCallModal", { attrs: { fullCall: _vm.fullCall } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "callerName" },
        [
          _vm.uuid
            ? _c("span", [_vm._v(_vm._s(_vm.getDataForHeader(_vm.uuid)))])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "span",
                        _vm._g(
                          {
                            staticClass: "italicFont",
                            staticStyle: {
                              float: "right",
                              "margin-right": "9px"
                            }
                          },
                          on
                        ),
                        [_vm._v(" P2P")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.remotePersonCall.p2p")))
              ])
            ]
          ),
          _vm._v(" "),
          _vm.uuid && _vm.ownerUUID === _vm.uuid
            ? _c(
                "span",
                { staticStyle: { float: "left", "margin-left": "9px" } },
                [_vm._v("Moderator")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uuid &&
          _vm.ownerUUID !== _vm.uuid &&
          !_vm.isWaitingRoomUser(_vm.uuid) && !_vm.isVisitor
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "span",
                              _vm._g(
                                {
                                  staticStyle: {
                                    float: "left",
                                    "margin-left": "9px"
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "font-awesome-icon",
                                  _vm._g(
                                    {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "user-crown"] }
                                    },
                                    on
                                  )
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    477651809
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.remotePersonCall.user")))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uuid &&
          _vm.ownerUUID !== _vm.uuid &&
          (_vm.isWaitingRoomUser(_vm.uuid) || _vm.isVisitor)
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "span",
                              _vm._g(
                                {
                                  staticStyle: {
                                    float: "left",
                                    "margin-left": "9px"
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "font-awesome-icon",
                                  _vm._g(
                                    {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "user-friends"] }
                                    },
                                    on
                                  )
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1591339111
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.remotePersonCall.guest")))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.state.nerd
        ? _c("div", { staticClass: "rtcStatus" }, [
            _c("div", [
              _vm._v("\n      IceConnectionState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green:
                      _vm.state.rtc[_vm.uuid].iceConnectionState === "connected"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].iceConnectionState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n      IceGatheringState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green:
                      _vm.state.rtc[_vm.uuid].iceGatheringState === "complete"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].iceGatheringState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n      SignalingState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green: _vm.state.rtc[_vm.uuid].signalingState === "stable"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].signalingState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n      RingingState:\n      "),
              _c(
                "span",
                {
                  class: {
                    green: _vm.state.rtc[_vm.uuid].ringingState === "connected"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].ringingState))]
              )
            ]),
            _vm._v(" "),
            _vm.activeCandidates
              ? _c("div", [
                  _vm._v("\n      ConnectionType:\n      "),
                  _c("span", { class: { green: !_vm.isTurn } }, [
                    _vm._v(
                      _vm._s(_vm.activeCandidates.local.candidateType) +
                        " / " +
                        _vm._s(_vm.activeCandidates.remote.candidateType)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            this.videoDetailsFirst && this.videoDetailsFirst.width
              ? _c("div", [
                  _vm._v(
                    "First: " +
                      _vm._s(this.videoDetailsFirst.width || 0) +
                      "x" +
                      _vm._s(this.videoDetailsFirst.height || 0) +
                      "@" +
                      _vm._s(Math.floor(this.videoDetailsFirst.frameRate || 0))
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.videoDetailsSecond && this.videoDetailsSecond.width
              ? _c("div", [
                  _vm._v(
                    "Second: " +
                      _vm._s(this.videoDetailsSecond.width || 0) +
                      "x" +
                      _vm._s(this.videoDetailsSecond.height || 0) +
                      "@" +
                      _vm._s(Math.floor(this.videoDetailsSecond.frameRate || 0))
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "videos",
          staticClass: "videos",
          attrs: { id: "remotePersonCallVideosContainer" }
        },
        [
          _vm.onHold
            ? _c(
                "div",
                { staticClass: "onHoldOverlay" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "onHoldCenterIcon",
                    style: { fontSize: "45px" },
                    attrs: { icon: ["fal", "phone-plus"] }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "messageToParticipants messageToParticipantsBlink"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.remotePersonCall.onHoldMessage")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("video", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.remote.second,
                expression: "!remote.second"
              }
            ],
            ref: "remoteFirst",
            staticClass: "remote-first",
            class: { hidden: !_vm.remote.first || !_vm.haveRemoteFirstVideo },
            attrs: { id: "remote-first", autoplay: "autoplay" },
            domProps: {
              srcObject: _vm.remote.first,
              muted: _vm.isLocalSoundMuted
            },
            on: {
              click: function($event) {
                $event.preventDefault()
              }
            }
          }),
          _vm._v(" "),
          _c("video", {
            ref: "remoteSecond",
            staticClass: "remote-second",
            class: { hidden: !_vm.remote.second },
            attrs: { id: "remote-second", autoplay: "autoplay" },
            domProps: {
              srcObject: _vm.remote.second,
              muted: _vm.isLocalSoundMuted
            },
            on: {
              click: function($event) {
                $event.preventDefault()
              }
            }
          }),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "ringing"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c(
                    "div",
                    { staticClass: "callAvatarWrapper" },
                    [
                      _c("v-img", {
                        staticClass: "noVideoStreamAvatar",
                        attrs: {
                          contain: "",
                          "max-width": "160",
                          "max-height": "160",
                          src: _vm.getAvatarForUuid(_vm.uuid),
                          "lazy-src": "assets/default_profile_picture.png"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "callingText" }, [
                      _vm._v(
                        _vm._s(_vm.$t("components.remotePersonCall.calling")) +
                          " " +
                          _vm._s(_vm.getNameForUuid(_vm.uuid))
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(0)
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "accepted"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c("span", { staticClass: "callingText" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.remotePersonCall.callInProgress")
                      )
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "connected"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c(
                    "div",
                    { staticClass: "callAvatarWrapper" },
                    [
                      _c("v-img", {
                        staticClass: "noVideoStreamAvatar",
                        attrs: {
                          contain: "",
                          "max-width": "160",
                          "max-height": "160",
                          src: _vm.getAvatarForUuid(_vm.uuid),
                          "lazy-src": "assets/default_profile_picture.png"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "callingText" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.remotePersonCall.inCallWith")
                        ) +
                          " " +
                          _vm._s(_vm.getNameForUuid(_vm.uuid))
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.onHold
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("components.remotePersonCall.onHold"))
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "buttonsUser",
              class: {
                hidden: Object.keys(_vm.state.remoteStreams).length == 0
              }
            },
            [
              _vm.iamOwner
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.transferModerator(_vm.uuid)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "users-crown"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2754735369
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.remotePersonCall.transferModerator"
                            )
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.iamOwner
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm
                                            .getWebrtcConnections()
                                            [_vm.uuid].die(true)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "faPhoneRotate",
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "phone"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2365489462
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.state.ownUUID === _vm.ownerUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.toggleMuteUserAudio(
                                            _vm.uuid
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    !_vm.isMyAudioMutedByAdmin
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "microphone"] }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isMyAudioMutedByAdmin
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "microphone-slash"]
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3755788682
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              !_vm.isMyAudioMutedByAdmin
                                ? _vm.$t("components.remotePersonCall.muteUser")
                                : _vm.$t(
                                    "components.remotePersonCall.unMuteUser"
                                  )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mx-3",
                                attrs: { icon: "", color: "white" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.setShowModalSenMsg(_vm.uuid)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "paper-plane"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.userProfile.sendAMessage"))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mx-3",
                                attrs: { icon: "", color: "white" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.sendMail(_vm.uuid)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "24px" },
                                attrs: { icon: ["fal", "envelope-square"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.userListItem.sendEMail")))
                  ])
                ]
              ),
              _vm._v(" "),
              Object.keys(_vm.state.remoteStreams).length == 1
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.changeActualSize.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    !_vm.fullScreen
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "expand-wide"]
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.fullScreen
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fas", "compress"] }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        13001822
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("components.userListItem.enlarge")) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPayEnabled &&
              Object.keys(_vm.state.remoteStreams).length == 1 &&
              (_vm.isWaitingRoom || _vm.isVisitor)
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.showModalPaymentRequest()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.state.namespaceSettings.currency ===
                                    "EUR"
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "euro-sign"] }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.namespaceSettings.currency ===
                                    "GBP"
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["far", "pound-sign"] }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.namespaceSettings.currency ===
                                    "CHF"
                                      ? _c("v-img", {
                                          attrs: {
                                            contain: "",
                                            height: "20",
                                            src:
                                              "img/paymentIcons/swiss-franc.png",
                                            "lazy-src":
                                              "img/paymentIcons/swiss-franc.png"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2308316149
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.callsContent.requestPayment")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.state.nerd
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.triggerIceRestart(_vm.uuid)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.isStable,
                                          expression: "!isStable"
                                        }
                                      ],
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "eclipse"] }
                                    }),
                                    _vm._v(" "),
                                    _c("font-awesome-icon", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isStable,
                                          expression: "isStable"
                                        }
                                      ],
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "star"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3188981614
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Restart ICE")])]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "wave" } }, [
      _c("div", { staticClass: "dot", attrs: { color: "primary" } }),
      _vm._v(" "),
      _c("div", { staticClass: "dot", attrs: { color: "primary" } }),
      _vm._v(" "),
      _c("div", { staticClass: "dot", attrs: { color: "primary" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }