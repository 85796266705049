<template>
  <div class="h100">
    <v-content
      v-if="viewingRoom"
      class="px-0 py-0"
      v-on:scroll.native="handleScroll"
    >
      <v-row class="mx-auto">
        <v-col
          class="custom6cols pb-1 px-2"
          cols="6"
          v-if="
            isAdmin(ownUUID) && getMyRoomsDetailsConfIsTemp.length <100
          "
        >
          <v-card
            class="cardWidth cursorPointer"
            :style="{ border: `1px solid #efefef` }"
            @click="clickOnAddCard()"
          >
            <div style="height: 180px; display: table; width: 100%">
              <div class="divAdd">
                <font-awesome-icon :icon="['fal', 'plus']" class="buttonAdd" />
                <p class="mb-0 mt-2">
                  {{ $t("components.sambaRooms.createRoom") }}
                </p>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          v-for="(room, index) in getMyRoomsDetailsConfIsTemp"
          :key="index"
          class="custom6cols pb-1 px-2"
          cols="6"
        >
          <v-card
            class="cardWidth"
            style="background-color: #eaeaea"
            :style="{ border: '2px solid #0abce6', backgroundColor: '#eaeaea' }"
            @click="joinRoom(room)"
          >
            <!-- <v-tooltip top> -->
            <template>
              <v-tooltip top :disabled="!getTotalUsers(room)">
                <template v-slot:activator="{ on }">
                  <div class="contentSambaRomm" v-on="on" :class="room.roomImage && room.roomImage === 'img/icon.png' ? 'imageSambaRoom': ''">
                    <!-- <v-img
                      :src="room.roomImage"
                      class="white--text align-end"
                      style="border-radius: 4px"
                      height="180px"
                    >
                      <SambaRoomOverlay :room="room" />
                      <div class="gradientBg">
                        <v-card-subtitle
                          style="color: #fff !important"
                          class="titleCard colorWhite pt-1 pb-1 px-2 text-truncate"
                        >
                          <RoomCardActions
                            :room="room"
                            :editRoom="editRoom"
                            :removeRoom="removeRoom"
                            :deleteActive="deleteActive"
                            :addRoomToFavorites="addRoomToFavorites"
                            :removeRoomFromFavorites="removeRoomFromFavorites"
                          />
                        </v-card-subtitle>
                      </div>
                    </v-img> -->
                    <div class="contentSambaRomm">
                       <SambaRoomOverlay :room="room" />
                       <div class="centered-text">
                        <p class="title font-weight-bold px-4">{{ room.roomName }}</p>
                       </div>
                       <div class="gradientBg">
                        <v-card-subtitle
                          style="color: #fff !important"
                          class="titleCard colorWhite pt-1 pb-1 px-2 text-truncate"
                        >
                          <RoomCardActions
                            :room="room"
                            :editRoom="editRoom"
                            :removeRoom="removeRoom"
                            :deleteActive="deleteActive"
                            :addRoomToFavorites="addRoomToFavorites"
                            :removeRoomFromFavorites="removeRoomFromFavorites"
                          />
                        </v-card-subtitle>
                      </div>
                    </div>
                  </div>
                </template>
                <div>
                  <span v-if="!isGuest">
                    <font-awesome-icon
                      :icon="['fal', 'video']"
                    />
                    {{getUsersNameInRoom(room)}}
                  </span>
                </div>
              </v-tooltip>
            </template>
            <!-- <ConferenceInfoTooltip
                :conference="conference"
              ></ConferenceInfoTooltip> -->
            <!-- </v-tooltip> -->
          </v-card>
        </v-col>
      </v-row>
    </v-content>
    <v-content
      v-if="!viewingRoom"
      class="px-0 py-0 h100"
      v-on:scroll.native="handleScroll"
    >
      <div class="h100">
        <v-row :class="isMobile ? null : 'heightScroll'">
          <v-col
            class="mx-0 py-0"
            :cols="isMobile ? 12 : 6"
            :md="isMobile ? 12 : 6"
          >
            <v-row class="mx-0">
              <v-col cols="12" class="pl-0 pb-0">
                <v-text-field
                  :label="$t('components.sambaRooms.roomName')"
                  outlined
                  class="mt-0 pt-0"
                  autocomplete="off"
                  v-model="roomName"
                  dense
                  :maxlength="30"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pl-0 pb-0 pt-0">
                <v-textarea
                  outlined
                  dense
                  rows="4"
                  v-model="roomDescription"
                  :counter="150"
                  maxlength="150"
                  :label="$t('components.sambaRooms.roomDescription')"
                  no-resize
                />
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col
            class="mx-0 py-0 mt-3"
            :cols="isMobile ? 12 : 6"
            :md="isMobile ? 12 : 6"
          >
            <div class="containerImgRoom">
              <v-img
                class="grey darken-4"
                :src="roomImage"
                max-height="141"
                contain
                lazy-src="img/default_profile_picture.png"
              ></v-img>
              <div class="mt-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      depressed
                      class="ml-0 w-100"
                      color="primary"
                      @click="openUploadImage"
                      >{{ $t("components.sambaRooms.uploadImage") }}</v-btn
                    >
                  </template>
                  <span>{{ $t("components.sambaRooms.uploadImage") }}</span>
                </v-tooltip>
              </div>
              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                ref="imageSambaRoom"
                class="d-none"
                @change="setImageSambaRoom($event)"
              />
            </div>
          </v-col> -->

        </v-row>
        <!-- buttons -->
        <v-row class="mx-0">
          <v-col
            cols="12"
            :class="`${isMobile ? 'text-center' : 'text-right'} pl-0`"
          >
            <v-divider class="pb-4"></v-divider>

            <v-tooltip top max-width="210">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :class="`${isMobile ? 'mb-2' : null} ml-2`"
                  :disabled="!canSaveRoom"
                  @click="saveRoom()"
                  depressed
                  color="primary"
                >
                  {{ $t("components.sambaRooms.saveRoom") }}
                </v-btn>
              </template>
              <span>
                {{ $t("components.sambaRooms.saveRoom") }}
              </span>
            </v-tooltip>
            <v-tooltip top max-width="210">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="ml-2 borderRadius-4"
                  style="height: 36px"
                  @click="goBackToRoomGrid"
                  icon
                  outlined
                  depressed
                  small
                  fab
                  dark
                  color="primary"
                >
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }} </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- end buttons -->
      </div>
    </v-content>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { isMobile } from "../../lib/mobileUtil";
import { bridgeCallDoStaticRoom } from "../../lib/wsMsg";
import store from "../../store";
import { amInABridgeCall } from "../../utils/calls";
import { hasPrivilege, isAdmin } from "../../utils/privileges";
import { totalUsersInRoom, usersNamesInRoom, joinSambaRoom } from "../../utils/staticRoom";
import RoomCardActions from "./roomCardActions.vue";
import SambaRoomOverlay from "./sambaRoomOverlay.vue";
export default {
  props: [],
  components: { RoomCardActions, SambaRoomOverlay },
  data() {
    return {
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      state: store.state,
      viewingRoom: true,
      isEditingRoom: false,
      roomName: "",
      roomDescription: "",
      roomImage: "img/icon.png",
      roomId: undefined,
      dataRooms: [],
      deleteActive: false,
    };
  },
  effector: {},
  watch: {},
  beforeMount() {
    this._beforeMountPromise = bridgeCallDoStaticRoom('list')
      .then(data => {
        if (this._destroyed) return;
        this.dataRooms = data;
      });
  },
  mounted() {
    /*
    this.dataRooms = [
      {
        roomId: '0d7aac2b-f902-4fda-a1e0-707cbb6350ce',
        roomGuid: 'TestStaticRoom',
        roomName: "Test room name",
        roomImage: "img/icon.png",
        roomDescription: "test descriptions",
      },
    ];
    */
  },
  destroyed() {
    this._destroyed = true;
  },
  methods: {
    openUploadImage() {
      this.$refs.imageSambaRoom.click();
    },
    resizeImage(imagePath, newWidth, newHeight) {
      const originalImage = new Image();
      originalImage.src = imagePath;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      return new Promise((resolve, reject) => {
        originalImage.addEventListener('error', (err) => reject(err));
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth/originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth/aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
          return resolve(dataUrl);
        });
      });
    },
    async setImageSambaRoom(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) return;
      const file = files[0];
      const reader = new FileReader();
      const base64 = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
      });
      this.roomImage = await this.resizeImage(base64, 180);
      this.$refs.imageSambaRoom.value = "";
    },
    clickOnAddCard() {
      this.resetFormRoom();
      this.isEditingRoom = false;
      this.viewingRoom = false;
    },
    async saveRoom() {
      const obj = {
        roomId: this.isEditingRoom ? this.roomId : uuidv4(),
        roomName: this.roomName,
        roomDescription: this.roomDescription,
        roomImage: this.roomImage,
      };
      const elementIndex = this.dataRooms.findIndex(
        (e) => e.roomId === this.roomId
      );
      if (elementIndex !== -1) {
        const { _id, roomGuid } = this.dataRooms[elementIndex];
        Object.assign(obj, { _id, roomGuid });
        await bridgeCallDoStaticRoom('edit', obj);
        this.dataRooms[elementIndex] = obj;
      } else {
        const { _id, roomId, roomGuid } = await bridgeCallDoStaticRoom('add', obj);
        Object.assign(obj, { _id, roomId, roomGuid });
        this.dataRooms.push(obj);
      }
      this.goBackToRoomGrid();
    },
    goBackToRoomGrid() {
      this.viewingRoom = true;
      this.isEditingRoom = false;
      this.resetFormRoom();
    },
    resetFormRoom() {
      this.roomId = undefined;
      this.roomName = "";
      this.roomDescription = "";
      this.roomImage = "img/icon.png";
    },
    joinRoom(room) {
      if(this.amInABridgeCall){
        return;
      }
      return joinSambaRoom(room, this.$route);
    },
    editRoom(room) {
      this.isEditingRoom = true;
      this.viewingRoom = false;
      this.loadDataRoom(room);
      console.log("edit room", room);
    },
    async removeRoom(room, event) {
      event.stopPropagation();
      event.preventDefault();
      if (!this.deleteActive) {
        this.deleteActive = true;
        setTimeout(() => {
          this.deleteActive = false;
        }, 1500);
      } else {
        this.deleteActive = false;
        const elementIndex = this.dataRooms.findIndex(
          (e) => e.roomId === room.roomId
        );
        if (elementIndex !== -1) {
          this.dataRooms.splice(elementIndex, 1);
        }
        await bridgeCallDoStaticRoom('delete', room);
      }
    },
    addRoomToFavorites(room) {
      const favoriteRoomIds = [...store.getFavoriteRooms(), room.roomId];
      store.setFavoriteRooms(favoriteRoomIds);
    },
    removeRoomFromFavorites(room) {
      const currentFavorites = store.getFavoriteRooms();
      if (!currentFavorites || !currentFavorites.length) {
        return;
      }

      const updatedFavorites = store.getFavoriteRooms().filter(favoriteRoomId => favoriteRoomId !== room.roomId);
      store.setFavoriteRooms(updatedFavorites);
    },
    loadDataRoom(room) {
      this.roomName = room.roomName;
      this.roomDescription = room.roomDescription;
      this.roomImage = room.roomImage;
      this.roomId = room.roomId;
    },
    setRoomImage() {},
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    isAdmin(uuid) {
      return isAdmin(uuid)
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    getUsersNameInRoom(room){
      return usersNamesInRoom(room)
    },
    getTotalUsers(room){
      return totalUsersInRoom(room)
    }
  },
  computed: {
    getMyRoomsDetailsConfIsTemp() {
      const favoriteRoomIds = store.getFavoriteRooms() || [];
      const dataRoomsSafe = this.dataRooms || [];

      const favoriteRooms = dataRoomsSafe
        .filter(room => favoriteRoomIds.includes(room.roomId))
        .map(room => ({ ...room, isFavorite: true }))
        .sort((a, b) => a.roomName.localeCompare(b.roomName));

      const nonFavoriteRooms = dataRoomsSafe
        .filter(room => !favoriteRoomIds.includes(room.roomId))
        .sort((a, b) => a.roomName.localeCompare(b.roomName));

      return [...favoriteRooms, ...nonFavoriteRooms];
    },
    canSaveRoom() {
      if (!this.roomName.length) {
        return false;
      }
      return true; // check if we can save the room
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    amInABridgeCall() {
      return amInABridgeCall();
    },
  },
};
</script>
<style scoped lang="scss">
.centered-text {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 148px; /* Take up full height of the parent container */
  text-align: center; /* Center text when it wraps */
  width: 100%;
  max-height: 100%;
  overflow: hidden;

}

.centered-text p {
  margin: 0; /* Remove default margin of <p> if any */
  white-space: normal; /* Allow line breaks */
  max-width: 100%;
  color: var(--v-primary-600) !important;
  caret-color: var(--v-primary-600) !important;
  line-height: 25px;
  max-height: 100%;
  overflow: hidden;
}
.contentSambaRomm{
  height: 178px;
  min-height: 178px;
  max-height: 178px;
  width: 100%;
}
.containerImgRoom {
  position: relative;
  max-width: 60%;
}
.h100 {
  height: 100%;
}
.borderRadius-4 {
  border-radius: 4px;
}
.theme--light.v-btn.list {
  color: rgb(117, 117, 117);
}
.list {
  text-transform: none;
  font-weight: 300;
  padding-left: 5px !important;
  letter-spacing: 0;
  font-size: 16px !important;
}
.heightScroll {
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow: auto;
}
.cursorPointer {
  cursor: pointer;
}
.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}
.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  position: relative;
  border-bottom-left-radius: 3px; /* Replace with the desired radius */
  border-bottom-right-radius: 3px; /* Replace with the desired radius */
}
.colorWhite {
  color: white !important;
}
.custom6cols {
  width: 16%;
  flex-basis: 16%;
}
.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}
@media only screen and (max-width: 1024px) {
  .custom6cols {
    width: 100%;
    flex-basis: 100%;
  }
}
/* Laptops y desktops pequeños */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  /* Estilos aquí */
  .custom6cols {
    width: 30%;
    flex-basis: 30%;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}
.v-card__subtitle {
  color: black !important;
}
.v-card__title {
  font-size: 15px !important;
}
</style>
<style lang="scss">
.imageSambaRoom{
  .v-image__image{
    padding: 23px 33px 33px 33px;
    background-origin: content-box;
  }
}
</style>
