<template>
<div class="relative">
  <v-row class="relative" v-if="!isChildren && showHeadlineOrganization">
    <div class="organigramHeadlineFirst text-white subtitle-1 font-weight-bold">{{headlineOrganigram[0][language]}}</div>
  </v-row>
  <div class="d-flex flex-wrap justify-center" :class="!isChildren ? 'pt-8' : ''">
    <!-- :cols="!isChildren ? 12 : 'auto'" -->
    <div v-if="showAddSectionNoData">
      <div class="d-flex justify-center align-end pr-4 py0">
        <v-card
          color="#f5f5f5"
          class="cursorPointer borderAddCard rounded-lg"
          max-width="240"
          width="2240"
          height="230"
          flat
          @click="openAddEditModal(null, false, false)"
        >
          <div class="d-flex justify-center align-center" style="height: 100%">
            <div class="containerImg100 text-center">
              <font-awesome-icon
                :icon="['fal', 'plus']"
                style="font-size: 40px"
              />
              <div class="mt-2 font-weight-bold">{{$t('components.organisationGrid.addDepart')}}</div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div v-for="(item, index) in items" :key="'item'+(item.id||item.uuid)" class="d-flex" :style="item.isUser ? 'min-height: 260px;':''">
      <!--
        *** add edit section ***
      -->
      <div v-if="index === 0 && !isSectionOpen(item.id) && editMode">
        <div class="d-flex justify-center align-end pr-4 py-0">
          <v-card
            color="#f5f5f5"
            class="cursorPointer borderAddCard rounded-lg"
            max-width="240"
            width="2240"
            :height="(item && item.isUser) ?  '245': '230'"
            flat
            @click="openAddEditModal(item, false, false)"
          >
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <div class="containerImg100 text-center">
                <font-awesome-icon
                  :icon="['fal', 'plus']"
                  style="font-size: 40px"
                />
                <div class="mt-2 font-weight-bold">{{$t('components.organisationGrid.addDepart')}}</div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <!--
        *** add edit section ***
      -->
      <div>
        <div  class="d-flex justify-center align-end pr-4 py-0">
          <!--
          *** sections card ***
        -->
          <v-card class="cardWithImg" max-width="240" height="230" flat>
            <!--  *** supervisor sections  *** -->
            <div
              class="d-flex flex-wrap"
              v-if="item.supervisors && item.supervisors.length"
            >
              <!-- :class="[
                  item.supervisors.length > 1
                    ? 'containerImg50'
                    : 'containerImg100',
                  index === 0 &&
                  item.supervisors.length > 1 &&
                  item.supervisors[0]
                    ? 'removeBorderRight'
                    : item.supervisors[1]
                    ? 'removeBorderLeft'
                    : '',
                ]" -->

              <div
                class="rounded-lg"
                :class="[getBorderStyleSupervisor(item.supervisors, index)]"
                v-for="(supervisor, index) in item.supervisors"
                :key="(item.id||item.uuid)+'supervisor'+supervisor"
                :style="getBorderCard(item.supervisors, supervisor, index)"
              >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :height="item.supervisors.length > 2 ? '94' : '187'"
                    width="auto"
                    :src="getAvatarForUuid(supervisor)"
                    class="align-end"
                    :class="appearInAHigherSection(supervisor, item) ? 'grayScale': ''"
                    cover
                    loading="lazy"
                    :lazy-src="getDefaultPicture"
                  >
                    <div class="containerCheckbox">
                      <MultiUserCallIconGrid v-if="!disableCallBtn(supervisor)" :person="getPersonByUuid(supervisor)"/>
                    </div>
                    <div class="containerIcons">
                      <LocationIcon
                      v-if="supervisor in state.group"
                      :person="getPersonByUuid(supervisor)"
                      :defaultAvatar="hasUserImage(supervisor)"
                      :isRegularGrid="true"
                    ></LocationIcon>
                    <v-btn icon class="noWhiteBg" color="white" small v-if="!disableCallBtn(supervisor)" @click="callUser(supervisor)">
                      <font-awesome-icon :icon="['fas', 'phone']" class="buttonCallSwiper"
                        :style="{ fontSize: '15px' }" />
                    </v-btn>
                    </div>
                    <div class="gradientBg align-center white--text d-flex">
                      <div
                        class="text-truncate pl-3 pt-1 userNameContainer caption"
                      >
                        {{ getTitelByUuid(supervisor) }}
                        {{ getNameByUuid(supervisor) }}
                      </div>
                      <div>
                        <ButtonsFrontCard
                          :isOrga="true"
                          :person="getPersonByUuid(supervisor)"
                          :uuid="supervisor"
                          :isRegularGrid="true"
                          @click.stop.prevent
                        />
                      </div>
                    </div>
                    <div class="gradientBg align-center d-flex flex-row white--text justify-space-between"
                          v-if="hasRepresentative(supervisor)" :style="getBorderByStatusFull(getRepresentativeByUuid(supervisor))">

                        <div class="d-flex flex-row align-center" style="max-width: 85%">
                           <!-- <div class="status-dot d-flex" :style="{ backgroundColor: representativeStatusColor(item.uuid) }"></div> -->
                        <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(supervisor))" :person="getRepresentativeByUuid(supervisor)" :isRegularGrid="true"
                                :ownUUID="ownUUID" class="mr-1"></CallIcon>
                          <!-- <div class="status-dot d-flex" :style="{ backgroundColor: representativeStatusColor(supervisor) }"></div> -->
                          <span class="text-truncate pl-2 caption">
                            {{ getRepresentativeName(supervisor) }}
                          </span>
                        </div>
                        <!-- <v-btn @click="callUser(getRepresentativeId(supervisor))"
                              icon
                              color="white"
                              :disabled="disableCallBtn(getRepresentativeId(supervisor))">
                          <v-icon>mdi-phone-outline</v-icon>
                        </v-btn> -->
                    </div>
                  </v-img>
                </template>
                <UserInfoTooltip :person="getPersonByUuid(supervisor)"></UserInfoTooltip>
               </v-tooltip>
              </div>
              <v-card-text
                class="pa-0 py-2 px-3 cardTextContainer"
                style="height: 40px"
              >
                <div class="d-flex absolute" style="width: 88%">
                  <div
                    style="width: 95%"
                    class="font-weight-bold text-truncate body-1"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    v-if="
                      item.supervisors &&
                      item.supervisors.length &&
                      !isSectionOpen(item.id)
                    "
                    class="text-end"
                  >
                    <font-awesome-icon
                      :class="!editMode ? 'hidden' : ''"
                      @click="openAddEditModal(item, true, false)"
                      :icon="['fal', 'pen']"
                      class="cursorPointer"
                      style="font-size: 17px"
                    />
                  </div>
                </div>
              </v-card-text>
            </div>
            <!--  *** end supervisor section  *** -->
            <!--  *** user sections  *** -->
            <div :class="isDarkMode? 'bgblack': 'bgwhite'" :style="editMode ? 'left: -260px!important;': ''" class="userSectionHeadline text-white subtitle-1 font-weight-bold" v-if="showHeadlineOrganization && item.uuid && index === 0">{{getLevels(item)}}</div>
            <div
              class="d-flex"
              v-if="item.uuid && (!item.supervisor || !item.supervisor.length)"
            >
              <div
                class="containerImg100 rounded-lg"
                :style="`${getBorderCard([], item.uuid, index)}; border-radius: 4px;`"
                @click="!disableCallBtn( item.uuid) ? callUser(item.uuid) : null"
              >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-img
                  v-on="on"
                    height="187"
                    width="auto"
                    :src="getAvatarForUuid(item.uuid)"
                    class="align-end"
                    cover
                    loading="lazy"
                    :lazy-src="getDefaultPicture"
                  >
                  <div class="containerCheckbox">
                    <MultiUserCallIconGrid v-if="!disableCallBtn(item.uuid)" :person="getPersonByUuid(item.uuid)"/>
                    </div>
                    <div class="containerIcons">
                      <LocationIcon
                        v-if="item.uuid in state.group"
                        :person="getPersonByUuid(item.uuid)"
                        :defaultAvatar="hasUserImage(item.uuid)"
                        :isRegularGrid="true"
                      ></LocationIcon>
                      <v-btn class="noWhiteBg" icon color="white" small v-if="!disableCallBtn(item.uuid)" @click="callUser(item.uuid)">
                        <font-awesome-icon :icon="['fas', 'phone']" class="buttonCallSwiper"
                          :style="{ fontSize: '15px' }" />
                      </v-btn>
                    </div>
                    <div class="gradientBg align-center white--text d-flex"
                        v-if="!hasRepresentative(item.uuid)">
                      <div
                        class="text-truncate pl-3 pt-1 userNameContainer caption"
                      >
                        <!-- {{ getTitelByUuid(item.uuid) }}
                        {{ getNameByUuid(item.uuid) }} -->
                      </div>
                      <div>
                        <div class="mr-4">
                          <v-btn
                            v-if="showTlfIcon(item.uuid)"
                            @click="callUser(uuid)"
                            icon
                            color="white"
                            :disabled="disableCallBtn(item.uuid)"
                          >
                            <!-- <font-awesome-icon
                              :icon="['fal', 'phone']"
                              class="cursorPointer"
                              style="font-size: 20px"
                            /> -->
                            <v-icon>mdi-phone-outline</v-icon>
                          </v-btn>
                        </div>
                        <ButtonsFrontCard
                          :isOrga="true"
                          :person="getPersonByUuid(item.uuid)"
                          :uuid="item.uuid"
                          :isRegularGrid="true"
                          @click.stop.prevent
                        />
                      </div>
                    </div>
                    <div class="gradientBg align-center white--text d-flex justify-space-between"
                        v-if="hasRepresentative(item.uuid)" :style="getBorderByStatusFull(getRepresentativeByUuid(item.uuid))">
                      <div class="d-flex align-center pl-1 representativeUserName" >
                        <!-- <div class="status-dot d-flex" :style="{ backgroundColor: representativeStatusColor(item.uuid) }"></div> -->
                        <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(item.uuid))" :person="getRepresentativeByUuid(item.uuid)" :isRegularGrid="true"
                                :ownUUID="ownUUID" class="mr-1"></CallIcon>
                        <div class="text-truncate pl-1 caption">
                          {{ getRepresentativeName(item.uuid) }}
                        </div>
                      </div>
                      <!-- <div class="mr-4">
                        <v-btn
                          @click="callUser(getRepresentativeId(item.uuid))"
                          icon
                          color="white"
                          :disabled="disableCallBtn(getRepresentativeId(item.uuid))"
                        >
                          <v-icon
                          >mdi-phone-outline</v-icon>
                        </v-btn>
                      </div> -->
                      <div>
                        <ButtonsFrontCard
                          :isOrga="true"
                          :person="getPersonByUuid(item.uuid)"
                          :uuid="item.uuid"
                          :isRegularGrid="true"
                          @click.stop.prevent
                        />
                      </div>
                    </div>
                  </v-img>
                </template>
                <UserInfoTooltip :person="getPersonByUuid(item.uuid)"></UserInfoTooltip>
              </v-tooltip>
                <v-card-text class="pa-0 py-2 px-3" style="height: 54px">
                  <div class="font-weight-bold text-truncate body-2">
                    {{ getTitelByUuid(item.uuid) }}
                    {{ getNameByUuid(item.uuid) }}
                  </div>
                  <div class="text-truncate">
                    {{ getPositionForUuid(item.uuid) }}
                  </div>
                </v-card-text>
              </div>
            </div>
            <!--  *** user sections  *** -->
          </v-card>
          <!--
          *** end sections card ***
        -->

          <!--
          *** add assistants ***
          -->
              <!-- item.children && -->
          <div
            v-if="
              !loading &&
              (isSectionOpen(item.id) || (isChildren && filteringBy)) &&
              getPersonAssistantsByUuids(item.supervisors, item.assistants)
                .length
            "
          >
            <span class="ml-2 mb-2 font-weight-bold">{{
              $t("components.organisationCarousel.assistant")
            }}</span>
            <div class="d-flex">
              <v-card
                v-for="(assistant, index) in getPersonAssistantsByUuids(
                  item.supervisors,
                  item.assistants
                )"
                :key="(item.id||item.uuid)+'assistant'+assistant"
                class="ml-2 cardWithImg"
                max-width="111"
                height="112"
                flat
              >
                <div :style="getBorderCard([], assistant, index)">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-img
                      v-on="on"
                      height="105"
                      width="auto"
                      :src="getAvatarForUuid(assistant)"
                      class="align-end removeBorderLeft removeBorderRight"
                      cover
                      loading="lazy"
                      :lazy-src="getDefaultPicture"
                    >
                      <div class="gradientBg align-center d-flex white--text"
                          v-if="!hasRepresentative(assistant)">
                        <div
                          class="text-truncate pl-3 pt-1 caption userNameContainer"
                        >
                          {{ getTitelByUuid(assistant) }}
                          {{ getNameByUuid(assistant) }}
                          <!-- {{ getTitelByUuid(assistant) }} {{ getNameByUuid(assistant) }} -->
                        </div>
                        <div>
                          <ButtonsFrontCard
                            :isOrga="true"
                            :person="getPersonByUuid(assistant)"
                            :uuid="assistant"
                            :isRegularGrid="true"
                            @click.stop.prevent
                          />
                        </div>
                      </div>
                      <div class="gradientBg align-center white--text d-flex justify-space-between" v-if="hasRepresentative(assistant)" :style="getBorderByStatusFull(getRepresentativeByUuid(assistant))">
                        <div class="d-flex align-center pl-1 representativeUserName">
                          <!-- <div class="status-dot d-flex" :style="{ backgroundColor: representativeStatusColor(assistant) }"></div> -->
                          <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(assistant))" :person="getRepresentativeByUuid(assistant)" :isRegularGrid="true"
                                :ownUUID="ownUUID" class="mr-1"></CallIcon>
                          <div class="text-truncate pl-1 caption">
                            {{ getRepresentativeName(assistant) }}
                          </div>
                        </div>
                        <!-- <div class="mr-4">
                          <v-btn
                            @click="callUser(getRepresentativeId(assistant))"
                            icon
                            color="white"
                            :disabled="disableCallBtn(getRepresentativeId(assistant))"
                          >
                            <v-icon>mdi-phone-outline</v-icon>
                          </v-btn>
                        </div>                 -->
                        <div>
                          <ButtonsFrontCard
                            :isOrga="true"
                            :person="getPersonByUuid(assistant)"
                            :uuid="assistant"
                            :isRegularGrid="true"
                            @click.stop.prevent
                          />
                        </div>
                      </div>
                    </v-img>
                  </template>
                  <UserInfoTooltip :person="getPersonByUuid(assistant)"></UserInfoTooltip>
                </v-tooltip>
                  <!-- <v-card-text class="pa-0 py-2 px-2">
                    <div class="font-weight-bold text-truncate text-capitalize">
                      {{ getTitelByUuid(assistant) }}
                      {{ getNameByUuid(assistant) }}
                    </div>
                  </v-card-text> -->
                </div>
              </v-card>
            </div>
          </div>
          <!--
          *** end assistants ***
           -->
        </div>
        <!--
        *** arrow section ***
        -->
        <div
        :class="showHeadlineOrganization? 'pb-6': ''"
          class="d-flex justify-center w100 pr-8 sectionArrow"
          v-if="!loading && !item.uuid"
        >
          <font-awesome-icon
            :class="
              (!item.children || !item.children.length) && !editMode
                ? 'hidden'
                : ''
            "
            @click="openCloseSection(item)"
            :icon="
              !isSectionOpen(item.id)
                ? ['fal', 'angle-down']
                : ['fal', 'angle-up']
            "
            class="cursorPointer"
            style="font-size: 40px"
          />
        </div>
        <!--
        *** end arrow section ***
      -->
        <!--
        *** children sections recursive ***
       -->
        <template>
          <div
          style="position:relative"
            class="hiddenCards pr-4 py-0"
            :id="'panel-' + item.id"
            :ref="'panel-' + item.id"
          >
          <div class="sectionHeadline text-white subtitle-1 font-weight-bold" v-if="showHeadlineOrganization">{{getLevels(item)}}</div>
            <organisationCard
              v-if="filteringBy || !(loadingGrid || loading)"
              :filteringBy="filteringBy"
              :loadingGrid="loadingGrid"
              :showSectionFunction="showSectionFunction"
              :isChildren="true"
              :items="item.children || []"
              :editMode="editMode"
              :openAddEditModal="openAddEditModal"
            />
            <!-- *** add edit childrens ***-->
            <div
              v-if="
                index === 0 &&
                isSectionOpen(item.id) &&
                editMode &&
                (!item.children || !item.children.length)
              "
            >
              <div class="d-flex align-end">
                <v-card
                  color="#f5f5f5"
                  class="cursorPointer borderAddCard rounded-lg"
                  max-width="240"
                  width="240"
                  height="230"
                  flat
                  @click="openAddEditModal(item, false, true)"
                >
                  <div
                    class="d-flex justify-center align-center"
                    style="height: 100%"
                  >
                    <div class="containerImg100 text-center">
                      <font-awesome-icon
                        :icon="['fal', 'plus']"
                        style="font-size: 40px"
                      />
                      <div class="mt-2 font-weight-bold">
                        {{$t('components.organisationGrid.addDepart')}}
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
            <!-- *** end add edit childrens ***-->
          </div>
        </template>
        <!--
        *** end children sections recursive ***
      --></div>
    </div>
  </div>
  </div>
</template>

<script>
import store, { EventBus }  from "../../../store";
import {
    getBorderByStatusFull,
    getCustomBorderByStatusFull,
    hasUserImage,
} from "../../../utils/basicFunctions";
import { hasRepresentative } from "../../../utils/representatives";
import { callPerson, disableCallBtn } from "../../../utils/calls";
import { getStatusColorByUser } from "../../../utils/status";
import ButtonsFrontCard from "../buttonsFrontCard.vue";
import LocationIcon from "../../content/locationIcon.vue";
import MultiUserCallIconGrid from "../../content/multiUserCallIconGrid.vue"
import UserInfoTooltip from "../userInfoTooltip.vue";
import CallIcon from "../callIcon.vue";

export default {
  name: "organisationCard",
  directives: {},
  components: {
    ButtonsFrontCard,
    LocationIcon, MultiUserCallIconGrid,
    UserInfoTooltip,
    CallIcon
  },
  props: [
    "loadingGrid",
    "items",
    "isChildren",
    "showSectionFunction",
    "editMode",
    "openAddEditModal",
    "filteringBy"
  ],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      loading: true,
      showingUsers: false,
      actualSectionOpen: null,
      showHeadlineOrganization: store.state.namespaceSettings.showHeadlineOrganization,
      headlineOrganigram: store.state.namespaceSettings.headlineOrganigram,
      language: store.state.user.language,
      expandingUser: false
    };
  },
  watch: {
    filteringBy: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (!this.isChildren && newVal && newVal !== oldVal) {
          this.expandUserFilter(newVal);
        }
      },
    },
  },
  mounted() {
    if (!this.isChildren) {
      EventBus.$on("expandUsers", this.eventToExpand);
    }
    this._loadedTimeout = setTimeout(() => {
      if (!this._destroyed) this.loading = false;
    }, 900);
  },
  beforeDestroy() {
    clearTimeout(this._loadedTimeout);
    this._destroyed = true;
  },
  destroyed() {
    EventBus.$off("expandUsers", this.eventToExpand);
  },
  methods: {
    findParentNode(dataset, childNodeId) {
      for (const node of dataset) {
        if (node.children?.some(child => child.id === childNodeId)) {
          return node;
        }
        // If not found, search recursively through children
        if (node.children) {
          const foundNode = this.findParentNode(node.children, childNodeId);
          if (foundNode) return foundNode;
        }
      }
      return null; // Parent not found
    },
    appearInAHigherSection(userUuid, startingNode) {
      let currentNode = startingNode;
      const dataset = JSON.parse(JSON.stringify(this.state.namespaceSettings.newOrganigram || []));
      while (true) {
        // Find parent node
        const parentNode = this.findParentNode(dataset, currentNode.id);
        // If no more parents (we reached the root), stop the loop
        if (!parentNode) break;
        // Check if userUuid is in supervisors of the parent node
        if (parentNode.supervisors.includes(userUuid)) {
          return true;
        }
        // Move up the tree
        currentNode = parentNode;
      }
      return false; // User is not a supervisor at a higher level than the starting node
    },
    eventToExpand() {
      if (this.expandingUser) return;
      this.expandUserFilter(this.filteringBy);
    },
    findParentIdsByUuid(searchUuid, nodes, parentIdChain = []) {
      let parentIds = [];
      for (const node of nodes) {
        // La nueva cadena incluye el ID actual si estamos en un nodo con ID.
        const newParentIdChain = [...parentIdChain];
        if (node.id) {
            newParentIdChain.push(node.id);
        }
        // Chequea si este nodo es o contiene el UUID buscado.
        let hasUuid = node.isUser ? node.uuid === searchUuid :
              ((node.supervisors || []).includes(searchUuid) || (node.children && !!node.children.find(item => item.isUser && item.uuid === searchUuid)) );
        let isAssistant = false;
        // Chequea si el usuario es assistente
        if(node.supervisors){
          const assistants = this.getPersonAssistantsByUuids(node.supervisors, node.assistants);
          if((assistants || []).includes(searchUuid)){
            hasUuid = true;
            isAssistant = true;
          }
        }
      // Decidir si se debe incluir el último ID en parentIds.
      if (hasUuid) {
        if (isAssistant || (node.children && node.children.some(child => child.isUser && child.uuid === searchUuid))) {
          // No excluimos el último ID si searchUuid está en assistants o es un user directo del nodo actual.
          parentIds = parentIds.concat(newParentIdChain);
        } else {
          // Excluimos el último ID ya que es el del nodo actual y no es un asistente.
          parentIds = parentIds.concat(newParentIdChain.slice(0, -1));
        }
      }
      // Si el nodo tiene hijos, continúa la búsqueda recursiva.
        if (node.children && node.children.length > 0 && !node.children.some(item => item.isUser)) {
            parentIds = parentIds.concat(
                this.findParentIdsByUuid(searchUuid, node.children, newParentIdChain)
            );
        }
      }
      return parentIds;
    },
    expandUserFilter(userUuid) {
      this.expandingUser = true;
      setTimeout(() => {
        const dataToFilter = JSON.parse(JSON.stringify(this.items));
        const parentIds = this.findParentIdsByUuid(userUuid, dataToFilter);
        if(!parentIds.length) return;
        const toggleSectionsRecursive = (elements) => {
          for (const element of elements) {
            if(element.isUser){
              return true
            }
            // if((element.supervisors || []).includes(userUuid)){
            //   return true; 
            // }
            if(parentIds.includes(element.id)) {
                this.openCloseSection(element);
            }
            if(element.children && element.children.length > 0) {
              const shouldBreak = toggleSectionsRecursive(element.children);
              if (shouldBreak) {
                return true; // Propagate the signal back up through the recursive calls.
              }
            }
          }
          return false;
        };
        toggleSectionsRecursive(dataToFilter);
      }, 1000);
      this.expandingUser = false;
    },
    getLevels(item) {
      if(!item){
        return ''
      }
      if(item.isUser){
        const headLineUser = this.headlineOrganigram.find(e=> e.id === 9999);
        if(headLineUser){
          return headLineUser[this.language];
        }
      }
      const panelId = `panel-${item.id}`
      // Select all elements in the DOM with an id attribute
      const allElementsWithId = document.querySelectorAll('[id]');

      // Filter elements to match the criteria
      const filteredElements = Array.from(allElementsWithId).filter(element =>
        /^panel-/.test(element.id) && !element.classList.contains('hiddenCards')
      );
      // Find the index of the element with the panelId
      const position = filteredElements.findIndex(element => element.id === panelId);
      if(position !== -1){
        const positionReturn = position + 1;
        return this.headlineOrganigram[positionReturn] && this.headlineOrganigram[positionReturn][this.language] && this.headlineOrganigram[positionReturn]?.id !== 9999 ? this.headlineOrganigram[positionReturn][this.language]  : ''
      } else{
        return ''
      }
    },
    hasUserImage(uuid) {
      return hasUserImage(uuid);
    },
    openCloseSection(item) {
      this.loading = true;
      const id = item.id;
      if (!id) return false;
      const isOpenSection = this.isSectionOpen(item.id);
      const domElement = document.getElementById(`panel-${id}`);
      if (isOpenSection) {
        this.closeOthersPanel(id);
      } else if (domElement) {
        domElement.classList.remove("hiddenCards");
      }
      this.showSectionFunction(id, !isOpenSection);
      this.actualSectionOpen = item;
      this.loading = false;
    },
    isSectionOpen(id) {
      const domElement = document.getElementById(`panel-${id}`);
      if (!domElement) return false;
      if (domElement && domElement.classList.contains("hiddenCards")) {
        return false;
      } else {
        return true;
      }
    },
    closeOthersPanel(id) {
      const dataCopy = JSON.parse(JSON.stringify(this.items));
      const childrensId = this.getIdsRecursive(dataCopy);
      childrensId.forEach((childId) => {
        const domElement = document.getElementById(`panel-${childId}`);
        if (domElement && !domElement.classList.contains("hiddenCards")) {
          domElement.classList.add("hiddenCards");
        }
      });
    },
    getIdsRecursive(data) {
      let ids = [];
      for (let item of data) {
        ids.push(item.id);
        if (item.children && item.children.length) {
          ids = ids.concat(this.getIdsRecursive(item.children));
        }
      }
      return ids;
    },
    getPersonAssistantsByUuids(uuids, assistantsData) {
      let uniqueAssistants = new Set(assistantsData || []);
      if (uuids && uuids.length) {
        for (let i = 0; i < uuids.length; i++) {
          const uuid = uuids[i];
          const person = store.state.group[uuid]?.user;

          if (person && Array.isArray(person.assistants)) {
            for (let j = 0; j < person.assistants.length; j++) {
              const assistant = person.assistants[j];
              uniqueAssistants.add(assistant);
            }
          }

        }
      }
      return Array.from(uniqueAssistants);
    },
    getBorderCard(supervisorArray, uuid, index) {
      if (supervisorArray.length > 1) {
        // adjust the border for 4 people
        if (index == 0 || index == 2) {
          if(supervisorArray.length === 3 && index == 2){
            return this.getCustomBorderByStatusFull(
              this.getPersonByUuid(uuid));
          }
          // remove right border for the first one
          return this.getCustomBorderByStatusFull(
            this.getPersonByUuid(uuid),
            "right"
          );
        } else if (index == 1 || index == 3) {
          // remove left border for the second one
          return this.getCustomBorderByStatusFull(
            this.getPersonByUuid(uuid),
            "left"
          );
        }
      } else {
        // console.log('is one person', supervisorArray)
        // set border just for 1 person
        return `${this.getBorderByStatusFull(this.getPersonByUuid(uuid))};  border-radius: 5px 5px 0px 0px;`;
      }
    },
    getBorderStyleSupervisor(supervisors, index) {
      switch (supervisors.length) {
        case 1:
          return "containerImg100";
        case 2:
          if (index === 0) {
          return "containerImg50 removeBorderRight";
          } else if (index === 1) {
            return "containerImg50 removeBorderLeft";
          } else{
            return "containerImg100";
          }
        case 3:
          if (index === 0) {
            return "containerImg50 removeBorderRight removeBorderBottom";
          } else if (index === 1) {
            return "containerImg50 removeBorderLeft removeBorderBottom";
          } else if (index === 2) {
            return "containerImg100 removeBorderTop bottomImagePosition";
          } else {
            return "containerImg100";
          }
        case 4:
          if (index === 0) {
            return "containerImg50 removeBorderRight removeBorderBottom";
          } else if (index === 1) {
            return "containerImg50 removeBorderLeft removeBorderBottom";
          } else if (index === 2) {
            return "containerImg50 removeBorderRight removeBorderTop";
          } else if (index === 3) {
            return "containerImg50 removeBorderLeft removeBorderTop";
          } else {
            return "containerImg100";
          }
        default:
          return "containerImg100";
      }
    },
    showTlfIcon(uuid) {
      if (!uuid) return false;
      const person = this.getPersonByUuid(uuid);
      return !!(person && person.connected && person.user.activity == 'No status' && uuid != this.state.ownUUID);
    },
    getPersonByUuid(uuid) {
      const person = store.getPersonByUuid(uuid) || {};
      return { uuid: person.uuid || person.user?.uuid, ...person };
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getPositionForUuid(uuid) {
      if(store.getPositionForUuid(uuid) === uuid) return '';
      return store.getPositionForUuid(uuid);
    },
    getCustomBorderByStatusFull(person, position) {
      try {
        return getCustomBorderByStatusFull(person, position);
      } catch {}
    },
    disableCallBtn(uuid) {
      const person = this.getPersonByUuid(uuid);
      return disableCallBtn(person);
    },
    getBorderByStatusFull(person) {
      try {
        return getBorderByStatusFull(person);
      } catch {}
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    hasRepresentative(uuid) {
      if (uuid === this.state.ownUUID) return false;
      return hasRepresentative(uuid);
    },
    getRepresentativeName(uuid) {
      return store.getUserRepresentativeName(uuid);
    },
    getRepresentativeId(uuid) {
      return (((store.state.group[uuid] || {}).user || {}).representative || {}).value || '';
    },
    representativeStatusColor(uuid) {
      if (!uuid) return;
      const person = this.getPersonByUuid(uuid);
      if (!person) return;
      const representative = this.state.group[person.user?.representative?.value || ''] || {};
      return getStatusColorByUser(
        representative.user,
        representative.connected
      );
    },
    getRepresentativeByUuid(uuid) {
      if (!uuid) return;
      const person = this.getPersonByUuid(uuid);
      if (!person) return;
      const representative = person.user?.representative?.value || '';
      return this.getPersonByUuid(representative);
    },
  },
  computed: {
    getDefaultPicture() {
      return "img/default_profile_picture.png";
    },
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    showAddSectionNoData() {
      if (this.editMode && !this.isChildren) {
        if (!this.items || !this.items.length) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.relative{
  position:relative;
}
.bgblack{
  background: #121212;
}
.bgwhite{
  background: white;
}
.organigramHeadlineFirst{
  position: absolute;
  top: -8px;
  left: 11px;
}
.sectionHeadline{
  position: absolute;
  top: -32px
}
.userSectionHeadline{
  position: absolute;
  top: -32px;
  left: 0px;
}
.theme--light .noWhiteBg {
  background: linear-gradient(#00000040, #00000099) !important;
}
.theme--dark .noWhiteBg {
  background: linear-gradient(#00000099, #000000cc) !important;
}
.noWhiteBg {
  color: white !important;
}
.containerIcons{
  position: absolute;
  top: 0;
  right: 5px;
}
.containerCheckbox{
   position: absolute;
  top: 0;
  left: 5px;
}
.removeBorderRight {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}
.removeBorderLeft {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px !important;
}
.removeBorderBottom {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0px !important;
}
.removeBorderTop {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
}
.borderAddCard {
  border: 2px solid #f5f5f5 !important;
}
.absolute {
  position: absolute;
}
.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
  height: 35px;
}
.colorWhite {
  color: white;
}
.containerImg50 {
  border-right: 1px solid white;
  max-width: 50%;
  width: 120px;
}
.containerImg100 {
  max-width: 100%;
  width: 240px;
}
.hiddenCards {
  display: none;
  height: 0px;
}
.sectionArrow {
  // max-width: 272px; // Not needed since we center everything #528
}
.right-div {
  display: contents;
}
.userNameContainer {
  width: 85%;
}
.representativeUserName {
  width: 70%;
  .text-truncate {
    width: 88%;
  }
}
.cardTextContainer {
  border-right: 4px solid #e5e5e5;
  border-left: 4px solid #e5e5e5;
  border-bottom: 4px solid #e5e5e5;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.rowGap{
  row-gap: 27px;
}
.status-dot {
  height: 15px;
  width: 15px;
  min-width: 15px;
  border-radius: 50%;
}
</style>
<style lang="scss">
.checkboxOrganigram .theme--light.v-icon {
  color: white !important;
}
.removeBorderLeft .v-image__image {
  border-top-left-radius: 0px !important;
}
.removeBorderRight .v-image__image {
  border-top-right-radius: 0px !important;
}
.removeBorderTop .v-image__image {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.bottomImagePosition .v-image__image {
  background-position: top center !important;
}
.grayScale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>
