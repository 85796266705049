var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v(_vm._s(_vm.getDate))]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.getTimeRange))]),
      _vm._v(" "),
      _vm._l(_vm.entry.users, function(uuid, key) {
        return _c("div", { key: key }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.getTitelByUuid(uuid)) +
              " " +
              _vm._s(_vm.getNameByUuid(uuid)) +
              "\n  "
          )
        ])
      }),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.getInternalNote))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }