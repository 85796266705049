var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closePinInfoModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showPinInfoModal,
        callback: function($$v) {
          _vm.showPinInfoModal = $$v
        },
        expression: "showPinInfoModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline justify-center" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-start": "" } },
                [
                  _c("v-img", {
                    staticClass: "mx-2 logo",
                    attrs: {
                      "max-height": "40",
                      "max-width": "40",
                      src: "assets/icon.png",
                      contain: ""
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "margin-left": "50px",
                        "line-height": "2em"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("components.adminSettingsModal.showMore"))
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-layout", {
                attrs: { row: "", wrap: "", "justify-end": "" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "py-2" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.$t("components.inviteGuestPanel.inviteSupportText")
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
            [
              _c(
                "v-col",
                { staticClass: "px-2 py-2", attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.closePinInfoModal }
                    },
                    [_vm._v(_vm._s(_vm.$t("generics.ok")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }