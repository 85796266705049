var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    1 === 2
      ? _c("div", [
          _vm._v(
            " OneCall - Visitor Name: " +
              _vm._s(_vm.name) +
              " Is Connected: " +
              _vm._s(_vm.connected)
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }