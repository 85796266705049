var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            _vm._l(_vm.pingsPongsArray, function(pingPong) {
              return _c(
                "div",
                { key: pingPong.uuid },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("v-img", {
                        staticClass: "mr-1",
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("components.pingPongModal.pingPong")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "ml-5 w-200" },
                        [
                          _c(
                            "v-badge",
                            {
                              attrs: {
                                bordered: "",
                                overlay: "",
                                height: "60",
                                width: "60",
                                bottom: "",
                                color: "#008000",
                                dot: "",
                                "offset-x": "22",
                                "offset-y": "15"
                              }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "borderRadius10",
                                  attrs: { tile: "", size: "150" }
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "borderRadius10",
                                    attrs: {
                                      "max-height": "150",
                                      "max-width": "150",
                                      contain: "",
                                      src: _vm.getAvatarForUuid(pingPong.uuid),
                                      "lazy-src":
                                        "assets/default_profile_picture.png"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-100-230 pt-2" }, [
                        _c("p", { staticClass: "userNameCard my-0" }, [
                          _vm._v(_vm._s(_vm.getNameForUuid(pingPong.uuid)))
                        ]),
                        _vm._v(" "),
                        pingPong.status == "pong"
                          ? _c("p", { staticClass: "dataUserCard my-0" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getNameForUuid(pingPong.uuid)) +
                                  " has acknowledged your ping!\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        pingPong.status == "ping"
                          ? _c("p", { staticClass: "dataUserCard my-0" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getNameForUuid(pingPong.uuid)) +
                                  " has pinged you! Dismiss to acknowledge.\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                      attrs: { color: "primary" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-2 btns w-100 mx-0 my-0" },
                        [
                          pingPong.status == "ping"
                            ? [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "white"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.setPingPongs(
                                                          pingPong.uuid,
                                                          undefined
                                                        )
                                                        _vm.pongMsg(
                                                          pingPong.uuid,
                                                          true
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: [
                                                        "fal",
                                                        "table-tennis"
                                                      ]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("generics.accept")))
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "white"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPingPongs(
                                                          pingPong.uuid,
                                                          undefined
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: ["fal", "times"]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("generics.close")))
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          pingPong.status == "pong"
                            ? [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      color: "white"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPingPongs(
                                                          pingPong.uuid,
                                                          undefined
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: ["fal", "check"]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("generics.close")))
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }