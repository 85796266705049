var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.deleteVisitorPaylinkModal.showModal,
        callback: function($$v) {
          _vm.$set(_vm.deleteVisitorPaylinkModal, "showModal", $$v)
        },
        expression: "deleteVisitorPaylinkModal.showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.info"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-5", style: { width: "100%", margin: 0 } },
            [
              _c("v-card-text", [
                _vm.deleteVisitorPaylinkModal.isPaylink
                  ? _c("p", { staticClass: "breakSpaces" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.inviteVisitor.deleteVisitorPaylink"
                          )
                        )
                      )
                    ])
                  : _c("p", { staticClass: "breakSpaces" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.inviteVisitor.deleteVisitorAmountPay"
                          )
                        )
                      )
                    ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", loading: _vm.loading },
                  on: { click: _vm.deleteVisitor }
                },
                [_vm._v(_vm._s(_vm.$t("generics.ok")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }