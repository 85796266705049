var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pRelative" },
    [
      _c("v-col", { staticClass: "conferenceTitleWrap" }, [
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("components.callsContent.conferenceTotalUsers", [
                  _vm.getListenerList.length
                ])
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        { ref: "listenerContainer", staticClass: "listenerContainer" },
        _vm._l(_vm.getSortedListenerList, function(uuid, index) {
          return _c("v-col", { key: index, staticClass: "listenerColumn" }, [
            _c(
              "div",
              {
                staticClass: "pRelative",
                on: {
                  mousemove: function($event) {
                    return _vm.showBar(uuid)
                  },
                  mouseleave: function($event) {
                    return _vm.hidebar(uuid)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "topbar" },
                  [
                    _c("div", { staticClass: "userIconInfo" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "userIconTile font-weight-light d-flex align-center"
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mr-2",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "user"] }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-card-subtitle",
                      {
                        staticClass:
                          "callerName font-weight-light pt-0 pb-0 px-0 text-truncate",
                        staticStyle: { color: "#fff !important" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getNameForUuid(uuid)) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "rightSideBar" },
                      [
                        _vm.listenerRaisedHandStore[uuid] &&
                        _vm.listenerRaisedHandStore[uuid].raisedHand
                          ? _c("font-awesome-icon", {
                              staticClass: "iconHand ml-2",
                              style: { fontSize: "20px" },
                              attrs: {
                                color: "red",
                                icon: ["fas", "hand-point-up"]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.visibleBar == "remoteBar_" + uuid
                  ? _c(
                      "div",
                      {
                        staticClass: "icons iconsRemote",
                        attrs: { id: "remoteBar_" + uuid }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "buttosContainer" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _vm.getAmIModerator
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "buttonsCall",
                                                      attrs: {
                                                        icon: "",
                                                        color: "white"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          $event.stopPropagation()
                                                          return _vm.toggleHangUpListener(
                                                            uuid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "faPhoneRotate",
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: ["fal", "phone"]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("generics.hangUp")))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-menu",
                              {
                                key: index,
                                staticClass: "customMenuLocal",
                                attrs: {
                                  "content-class": "customDotsMenuLocal",
                                  right: "",
                                  "offset-x": true,
                                  "close-on-content-click": true,
                                  "z-index": "999999"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "buttonsCall darkGreyIcon",
                                                  attrs: {
                                                    dense: "",
                                                    color: "white",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "ellipsis-v"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.menuOpen,
                                  callback: function($$v) {
                                    _vm.menuOpen = $$v
                                  },
                                  expression: "menuOpen"
                                }
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-list",
                                  {
                                    staticClass: "text-left",
                                    attrs: { dark: "" }
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.setUserInModal(uuid)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "info-circle"]
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.adminSettingsModal.userInfo"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.getAmIModerator
                                      ? _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.getTotalUserInPodium >= 12
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleGiveWord(uuid)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "comment"]
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.callsContent.moveToStage"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.listenerRaisedHandStore[uuid] &&
                _vm.listenerRaisedHandStore[uuid].raisedHand &&
                _vm.callInitiator == _vm.state.ownUUID &&
                _vm.raisedHandTimerValues[uuid]
                  ? [
                      _c(
                        "div",
                        {
                          key: "timer-" + uuid,
                          staticClass: "timerPosition red--text"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.raisedHandTimerValues[uuid]
                                ? new Date(
                                    _vm.raisedHandTimerValues[uuid].seconds *
                                      1000
                                  )
                                    .toISOString()
                                    .substring(14, 19)
                                : ""
                            )
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "imgContent" }, [
                  _c(
                    "div",
                    { staticClass: "imgUserContainer" },
                    [
                      _c("v-img", {
                        class: "imageCallListeners",
                        attrs: {
                          contain: "",
                          "max-height": "75px",
                          "max-width": "75px",
                          src:
                            _vm.getAvatarForUuid(uuid) ||
                            "img/default_profile_picture.png",
                          "lazy-src": "img/default_profile_picture.png"
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              2
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }