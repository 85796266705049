// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adobe-link[data-v-24072181] {
  position: absolute;
  top: 0;
  right: 15px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/socialMediaProfileContent.vue","webpack://./socialMediaProfileContent.vue"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,MAAA;EACA,WAAA;ACAJ","sourcesContent":["\n.adobe-link {\n    position: absolute;\n    top: 0;\n    right: 15px;\n}\n",".adobe-link {\n  position: absolute;\n  top: 0;\n  right: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
