var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "px-0 py-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between pa-4" },
                    [
                      _c(
                        "span",
                        { staticClass: "subtitle-1 font-weight-bold" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("components.stats.conferenceStats")
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-text-field", {
                            staticClass: "pt-0",
                            staticStyle: { width: "310px" },
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: _vm.$t("generics.search"),
                              "single-line": "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "ml-4 pointer",
                              on: {
                                click: function($event) {
                                  return _vm.closeStatus()
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  [
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.users,
                        "items-per-page": _vm.itemsPerPage,
                        search: _vm.search,
                        page: _vm.page,
                        "fixed-header": "",
                        height: "calc(100vh - 365px)"
                      },
                      on: {
                        "update:page": function($event) {
                          _vm.page = $event
                        },
                        "page-count": function($event) {
                          _vm.pageCount = $event
                        },
                        "update:items-per-page": _vm.getItemPerPage
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.sendMessage",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.setShowModalSenMsg(
                                            item.users
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "paper-plane"] }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }