var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-scroll",
    {
      class: "" + (!_vm.isMobile ? "vueScrollFooter" : "vueScrollFooterMobile")
    },
    [
      _c(
        "v-row",
        { staticClass: "px-12 mx-0", style: { width: "100%", margin: 0 } },
        [
          _c(
            "v-row",
            { staticClass: "mx-0", style: { width: "100%", margin: 0 } },
            [
              _c("v-col", { staticClass: "px-0", attrs: { cols: "12" } }, [
                _c("h1", { staticClass: "title textColor" }, [
                  _vm._v(_vm._s(_vm.$t("components.contact.contactVoffice")))
                ])
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "px-0", attrs: { cols: "12" } }, [
                _c("div", { staticClass: "subtitle-1" }, [
                  _vm._v(_vm._s(_vm.$t("components.contact.weAreHereToHelp")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "subtitle-1" }, [
                  _vm._v(
                    _vm._s(_vm.$t("components.contact.weLookForwardToHearing"))
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "pt-5 mx-0", style: { width: "100%", margin: 0 } },
            [
              _c(
                "v-col",
                { attrs: { xs: "12", lg: "6", sm: "12", md: "12", xl: "6" } },
                [
                  [
                    !_vm.isRaMicro
                      ? _c(
                          "v-card",
                          {
                            staticClass: "mx-auto",
                            attrs: { "max-width": "700", flat: "" }
                          },
                          [
                            _c("iframe", {
                              staticStyle: { border: "0" },
                              attrs: {
                                width: "100%",
                                height: "250",
                                frameborder: "0",
                                src:
                                  "https://www.google.com/maps/embed/v1/place?key=AIzaSyDP2EtzMnb8DHaLF5giLCw64PFLNtBp1ns&q=Washingtonplatz+3,+Cube+Berlin+10557+Berlin,+Germany",
                                allowfullscreen: ""
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              { staticClass: "text--primary" },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0",
                                    style: { width: "100%", margin: 0 }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 d-flex",
                                        attrs: {
                                          xs: "12",
                                          lg: "6",
                                          sm: "6",
                                          md: "6",
                                          xl: "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "align-self-center" },
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "map-marker"]
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "px-5" }, [
                                          !_vm.isRaMicro
                                            ? _c("div", [
                                                _vm._v("RA-MICRO Software AG")
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "Washingtonplatz 3, Cube Berlin"
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [_vm._v("10557 Berlin")])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 flex-column",
                                        attrs: {
                                          xs: "12",
                                          lg: "6",
                                          sm: "6",
                                          md: "6",
                                          xl: "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex mb-5" },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "phone"]
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "px-5" }, [
                                              _c("div", [
                                                _vm._v("+49 30 43598 - 802")
                                              ])
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex mb-5" },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "paper-plane"]
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "px-5" }, [
                                              !_vm.isRaMicro
                                                ? _c("div", [
                                                    _vm._v("info@ra-micro.de")
                                                  ])
                                                : _vm._e()
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0",
                                    style: { width: "100%", margin: 0 }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 d-flex",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        !_vm.isRaMicro
                                          ? _c(
                                              "div",
                                              { staticClass: "caption" },
                                              [
                                                _vm._v(
                                                  "© 2024 - RA-MICRO Software AG"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isRaMicro
                      ? _c(
                          "v-card",
                          {
                            staticClass: "mx-auto",
                            attrs: { "max-width": "700", flat: "" }
                          },
                          [
                            _c("iframe", {
                              staticStyle: { border: "0" },
                              attrs: {
                                width: "100%",
                                height: "250",
                                frameborder: "0",
                                src:
                                  "https://www.google.com/maps/embed/v1/place?key=AIzaSyDP2EtzMnb8DHaLF5giLCw64PFLNtBp1ns&q=Washingtonpl.+3,+10557+Berlin,+Germany",
                                allowfullscreen: ""
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              { staticClass: "text--primary" },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0",
                                    style: { width: "100%", margin: 0 }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 d-flex",
                                        attrs: {
                                          xs: "12",
                                          lg: "6",
                                          sm: "6",
                                          md: "6",
                                          xl: "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "align-self-center" },
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["fal", "map-marker"]
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "px-5" }, [
                                          _c("div", [
                                            _vm._v("RA-MICRO Software AG")
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v("WashingtonPlatz 3, Cube")
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [_vm._v("10557 Berlin")])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 flex-column",
                                        attrs: {
                                          xs: "12",
                                          lg: "6",
                                          sm: "6",
                                          md: "6",
                                          xl: "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex mb-5" },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "phone"]
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "px-5" }, [
                                              _c("div", [
                                                _vm._v("+49 30 43598 - 802")
                                              ])
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex mb-5" },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "paper-plane"]
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "px-5" }, [
                                              _c("div", [
                                                _vm._v("voffice@ra-micro.de")
                                              ])
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0",
                                    style: { width: "100%", margin: 0 }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 d-flex",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v(
                                            "© 2024 - RA-MICRO Software AG"
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "text-right",
                  attrs: { xs: "12", lg: "6", sm: "12", md: "12", xl: "6" }
                },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "mx-auto",
                        attrs: { "max-width": "700", flat: "" }
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "text--primary" },
                          [
                            _c(
                              "p",
                              { staticClass: "title text-left textColor" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.contact.sendUsAMessage")
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            [
                              _c(
                                "v-form",
                                {
                                  model: {
                                    value: _vm.valid,
                                    callback: function($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("components.contact.name"),
                                      required: "",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v ||
                                            this$1.$t("generics.nameRequired")
                                          )
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.name,
                                      callback: function($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("generics.email"),
                                      required: "",
                                      rules: _vm.emailRules
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "components.contact.typeYourMessageHere"
                                      ),
                                      required: "",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v ||
                                            this$1.$t(
                                              "components.contact.messageRequired"
                                            )
                                          )
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.message,
                                      callback: function($$v) {
                                        _vm.message = $$v
                                      },
                                      expression: "message"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.showResult
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            transition: "slide-x-transition",
                                            text: "",
                                            color: "info",
                                            dismissible: ""
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.result))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-4",
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.valid || _vm.loading,
                                        loading: _vm.loading
                                      },
                                      on: { click: _vm.onSubmit }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("components.contact.submit")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }