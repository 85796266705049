<template>
  <v-dialog
    persistent
    v-model="showSetupDevice"
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.settings')"
        :closeModalFunction="closeModal"
      >
        <v-spacer></v-spacer>
      </HeaderModal>
      <CamAndMic :isInModal="true" :closeModal="closeModal" />
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import CamAndMic from "../firstSetup/camAndMic.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  components: { CamAndMic, HeaderModal },
  props: ["showSetupDevice", "toggleModalSettings"],
  data: () => ({
    state: store.state,
  }),
  methods: {
    closeModal() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (this.toggleModalSettings) {
        this.toggleModalSettings();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>