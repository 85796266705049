var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showReminderModal,
        callback: function($$v) {
          _vm.showReminderModal = $$v
        },
        expression: "showReminderModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: { titleModal: _vm.$t("components.reminderModal.title") }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("components.reminderModal.reminderInfo", [
                              _vm.getPersonName,
                              _vm.getCallDate,
                              _vm.getCallTime,
                              _vm.timezone
                            ])
                          ) +
                          "\n           "
                      )
                    ]),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("components.reminderModal.deleteReminder")
                          ) +
                          "\n           "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.closeModal, showFooter: true }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    staticStyle: { "background-color": "white !important" },
                    attrs: { depressed: "" }
                  },
                  [
                    _c("DeleteBasket", {
                      attrs: {
                        indexData: _vm.showReminderModal.uuid,
                        delFunction: _vm.deleteReminder,
                        extraClasses: "cursorPointer",
                        fontSize: "18"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      depressed: "",
                      disabled: !_vm.showReminderModal || _vm.disableCallBtn
                    },
                    on: {
                      click: function($event) {
                        return _vm.callUser()
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      style: { fontSize: "18px" },
                      attrs: { icon: ["fal", "phone"] }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }