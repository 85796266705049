var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "overflow-y": "auto" } },
    [
      _c(
        "div",
        { attrs: { id: "inviteVisitorMenu" } },
        [
          _c(
            "v-list",
            {
              staticClass: "invitationMenuList",
              attrs: { dense: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "mr-2 officeIcon",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "inviteHeader py-2",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "cursorPointer mr-1 logoModal",
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fal", "user-friends"], color: "white" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-auto inviteTextTitle",
                      attrs: { flat: "" }
                    },
                    [_vm._v(_vm._s(_vm.$t("components.inviteVisitor.title")))]
                  ),
                  _vm._v(" "),
                  _vm.isAdvanced
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "max-width": "300" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        { staticClass: "invitationList" },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-select",
                                          {
                                            staticClass: "mr-4 mt-1 headselect",
                                            attrs: {
                                              items: _vm.getvisitors,
                                              "hide-details": "",
                                              "item-text": "name",
                                              label:
                                                "(" +
                                                _vm.getvisitors.length +
                                                ") " +
                                                _vm.$t(
                                                  "components.inviteVisitor.inviteList"
                                                ),
                                              dense: "",
                                              selectable: false
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function(data) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnRed mx-0",
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                            "z-index": "99999"
                                                          },
                                                          attrs: {
                                                            loading:
                                                              _vm.isdeleting,
                                                            icon: "",
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          _c("DeleteBasket", {
                                                            attrs: {
                                                              indexData: data,
                                                              delFunction:
                                                                _vm.removeInvitation,
                                                              extraClasses: "",
                                                              fontSize: "20",
                                                              prevent: "true"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.inviteVisitor.date"
                                                                )
                                                              ) +
                                                              ": \n                    " +
                                                              _vm._s(
                                                                data.item.date
                                                              ) +
                                                              "  " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.inviteVisitor.name"
                                                                )
                                                              ) +
                                                              ": " +
                                                              _vm._s(
                                                                data.item.name
                                                              ) +
                                                              "  " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.inviteVisitor.email"
                                                                )
                                                              ) +
                                                              ": " +
                                                              _vm._s(
                                                                data.item.email
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("template", {
                                              slot: "selection"
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1313658391
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.myVisitorsToolTip")))
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "generalDiv" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0",
                          staticStyle: { height: "65px", "margin-top": "5px" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "col-12 px-1 pb-0 pt-2",
                              staticStyle: { "margin-bottom": "13px" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 font-weight-bold labelTitle bannerTextLeft",
                                        staticStyle: {
                                          color: "white",
                                          "line-height": "2.5em"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.inviteVisitor.titleInvitationMenu"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 font-weight-bold labelTitle bannerTextLeft",
                                        staticStyle: {
                                          color: "white",
                                          "line-height": "2.5em"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.inviteVisitor.videoCallTo"
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-combobox",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "mt-5 ml-0 mx-1 label600",
                                                        attrs: {
                                                          items:
                                                            _vm.getAssistsList,
                                                          select: "",
                                                          "search-input":
                                                            _vm.searchUsers,
                                                          "item-text": "name",
                                                          dense: "",
                                                          "auto-select-first":
                                                            "",
                                                          "hide-details": "auto"
                                                        },
                                                        on: {
                                                          "update:searchInput": function(
                                                            $event
                                                          ) {
                                                            _vm.searchUsers = $event
                                                          },
                                                          "update:search-input": function(
                                                            $event
                                                          ) {
                                                            _vm.searchUsers = $event
                                                          },
                                                          change: function(
                                                            $event
                                                          ) {
                                                            _vm.searchUsers = ""
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.userSelected,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.userSelected = $$v
                                                          },
                                                          expression:
                                                            "userSelected"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.inviteVisitor.oneUserSelected"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "rbgwaiting mt-2",
                                          attrs: { col: "", row: "" },
                                          on: {
                                            change: function($event) {
                                              return _vm.inviteTypeChange(
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.inviteeType,
                                            callback: function($$v) {
                                              _vm.inviteeType = $$v
                                            },
                                            expression: "inviteeType"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            staticClass: "mt-0",
                                            style: {
                                              fontZise: 14,
                                              fontWeight: 500,
                                              marginRight: 5
                                            },
                                            attrs: {
                                              disabled:
                                                _vm.isSupportCall ||
                                                _vm.multiUser,
                                              "hide-details": "true",
                                              label: _vm.getTitleWaitingROOM,
                                              value: 1
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            staticClass: "mt-0",
                                            style: {
                                              marginRight: 5,
                                              marginLeft: 0,
                                              fontZise: 14,
                                              fontWeight: 500
                                            },
                                            attrs: {
                                              disabled:
                                                _vm.isSupportCall ||
                                                _vm.multiUser,
                                              label: _vm.$t("generics.user"),
                                              "hide-details": "true",
                                              value: "0"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-12", attrs: { cols: "8" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 font-weight-bold labelTitle bannerTextLeft",
                                  staticStyle: {
                                    color: "white",
                                    "line-height": "2.5em"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.getTextForInvites) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isWhatsAppInviteAvailable
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mt-11 mb-5",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "rbgwaiting",
                                      attrs: {
                                        disabled: _vm.disableRadioEmailWhatsApp,
                                        row: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.getText()
                                        }
                                      },
                                      model: {
                                        value: _vm.invitationSend,
                                        callback: function($$v) {
                                          _vm.invitationSend = $$v
                                        },
                                        expression: "invitationSend"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          value: "email",
                                          "hide-details": "true"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "p",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mb-0 font-weight-bold labelTitle"
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.inviteVisitor.invitationEmail"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1862028952
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.inviteVisitor.invitationEmail"
                                                              )
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2910646823
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("v-radio", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          "hide-details": "true",
                                          value: "phone"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "p",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mb-0 font-weight-bold labelTitle"
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.inviteVisitor.whatsappPhone"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3914539489
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.whatsappPhone"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1708400754
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "col-4 py-1 pt-0",
                              staticStyle: { "margin-top": "10px" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.applyRules
                                    ? _vm.userNameRules
                                    : _vm.noRules,
                                  label: _vm.$t(
                                    "components.inviteVisitor.name"
                                  ),
                                  dense: ""
                                },
                                on: {
                                  blur: function($event) {
                                    _vm.applyRules = true
                                  }
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.invitationSend === "email"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "col-4 px-1 py-1 pt-0",
                                  staticStyle: { "margin-top": "10px" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("v-text-field", {
                                        ref: "comboEmail",
                                        attrs: {
                                          required: "",
                                          rules:
                                            _vm.applyRules && !_vm.phoneNumber
                                              ? _vm.emailRules
                                              : _vm.noRules,
                                          disabled: _vm.phoneNumber.length > 0,
                                          label: _vm.$t(
                                            "components.inviteVisitor.invitationEmail"
                                          ),
                                          dense: ""
                                        },
                                        on: {
                                          blur: function($event) {
                                            _vm.applyRules = true
                                          }
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "cursorPointer",
                                                          attrs: {
                                                            color: "black",
                                                            icon: "",
                                                            disabled:
                                                              !_vm.name ||
                                                              _vm.name.length ==
                                                                0 ||
                                                              _vm.isEmailInvalid
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.addUserToList()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          _vm._g(
                                                            {
                                                              style: {
                                                                fontSize: "20px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3528293723
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.inviteVisitor.ttSaveDiskette"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.invitationSend === "phone"
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "col-4 px-1 py-1 pt-0 positionRelative",
                                  staticStyle: { "margin-top": "2px" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("vue-tel-input", {
                                        staticClass:
                                          "form-control text-center input-sm inputPhone2FA",
                                        class: {
                                          borderError: !_vm.phoneInputValid
                                        },
                                        attrs: {
                                          defaultCountry: "DE",
                                          enabledCountryCode: "",
                                          name: "number",
                                          id: "numDisplay",
                                          value: "",
                                          placeholder: "",
                                          autocomplete: "off"
                                        },
                                        on: { onInput: _vm.onTelInput },
                                        model: {
                                          value: _vm.phoneNumberInput,
                                          callback: function($$v) {
                                            _vm.phoneNumberInput = $$v
                                          },
                                          expression: "phoneNumberInput"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "cursorPointer mt-2",
                                                          attrs: {
                                                            color: "black",
                                                            icon: "",
                                                            disabled:
                                                              !_vm.name ||
                                                              _vm.name.length ==
                                                                0 ||
                                                              !_vm.phoneNumberInput ||
                                                                _vm
                                                                  .phoneNumberInput
                                                                  .length == 0
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.addUserToList()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          _vm._g(
                                                            {
                                                              style: {
                                                                fontSize: "20px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1648689874
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.inviteVisitor.ttSaveDiskette"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "col-4 px-1 py-1 pt-0",
                              staticStyle: { "margin-top": "10px" }
                            },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.filteredInvitations,
                                  "item-text": "name",
                                  "hide-details": "auto",
                                  label: _vm.$t(
                                    "components.inviteVisitor.contacts"
                                  ),
                                  select: "",
                                  dense: "",
                                  "menu-props": { closeOnContentClick: true }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function(data) {
                                      return [
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleSelectEmail(
                                                    data.item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pt-0" },
                                                [
                                                  _c("v-list-item-title", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        data.item.name
                                                      )
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-list-item-subtitle", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.invitationSend ===
                                                          "email"
                                                          ? data.item.email
                                                          : data.item.phone
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("DeleteBasket", {
                                                attrs: {
                                                  indexData: data.item,
                                                  delFunction:
                                                    _vm.removeUserFromList,
                                                  extraClasses:
                                                    "cursorPointer removeUserBasket",
                                                  fontSize: "15",
                                                  prevent: "true"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.contactSelected,
                                  callback: function($$v) {
                                    _vm.contactSelected = $$v
                                  },
                                  expression: "contactSelected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.inviteList.length > 0
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "col-12 px-1 py-1 pt-0",
                                  staticStyle: {
                                    "border-bottom": "1px solid #00000066"
                                  }
                                },
                                [
                                  _vm._l(_vm.inviteList, function(user, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "col-4 px-1 py-1 pt-0 participantsList"
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteVisitor.name"
                                                    )
                                                  ) +
                                                  ":\n                    " +
                                                  _vm._s(user.name)
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "col-4 px-1 py-1 pt-0 participantsList"
                                          },
                                          [
                                            user.email
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.inviteVisitor.invitationEmail"
                                                        )
                                                      ) +
                                                      ":\n                    " +
                                                      _vm._s(user.email)
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            user.mobilePhone
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "generics.whatsapp"
                                                        )
                                                      ) +
                                                      ":\n                    " +
                                                      _vm._s(user.mobilePhone)
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "col-4 px-1 py-1 pt-0 participantsList",
                                            staticStyle: { "text-align": "end" }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticStyle: {
                                                  "margin-top": "-5px"
                                                },
                                                attrs: { icon: "" }
                                              },
                                              [
                                                _c("DeleteBasket", {
                                                  attrs: {
                                                    indexData: user.email,
                                                    delFunction:
                                                      _vm.removeInviteeFromList,
                                                    extraClasses: "",
                                                    fontSize: "20",
                                                    prevent: "false"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-0 pb-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-6 pl-0 py-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: _vm.$t(
                                    "components.inviteVisitor.function"
                                  ),
                                  dense: ""
                                },
                                model: {
                                  value: _vm.visFunction,
                                  callback: function($$v) {
                                    _vm.visFunction = $$v
                                  },
                                  expression: "visFunction"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isProVersion && _vm.invitationSend === "email"
                            ? _c(
                                "v-col",
                                { staticClass: "col-6 px-1 py-1 pt-0" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Paylink",
                                      dense: "",
                                      type: "number"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "15px" },
                                                attrs: {
                                                  icon: ["fal", "euro-sign"]
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3182358058
                                    ),
                                    model: {
                                      value: _vm.paylinkAmount,
                                      callback: function($$v) {
                                        _vm.paylinkAmount = $$v
                                      },
                                      expression: "paylinkAmount"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: _vm.isAdvanced ? 6 : 12 }
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "py-0 px-0", attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "px-0" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "form",
                                          attrs: { "lazy-validation": "" },
                                          model: {
                                            value: _vm.valid,
                                            callback: function($$v) {
                                              _vm.valid = $$v
                                            },
                                            expression: "valid"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mx-0 pb-0" },
                                            [
                                              _vm.isAdvanced
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "ml-1 px-1 py-0"
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "-8px",
                                                            "margin-bottom":
                                                              "0px !important"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.2faModal.pinLabel"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            { staticClass: "mx-0 pb-0" },
                                            [
                                              _vm.isAdvanced
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "px-1 py-0 mb-5 mimiTest"
                                                    },
                                                    [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          staticClass:
                                                            "rbgwaiting",
                                                          attrs: { row: "" },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.invitePinChange(
                                                                $event
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value: _vm.pinType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.pinType = $$v
                                                            },
                                                            expression:
                                                              "pinType"
                                                          }
                                                        },
                                                        [
                                                          _c("v-radio", {
                                                            staticClass:
                                                              "w100 mt-0",
                                                            attrs: {
                                                              value: "0",
                                                              "hide-details":
                                                                "true"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "p",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-0 font-weight-bold labelTitle"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                    " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.inviteGuestPanel.noPin"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                  "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2490710201
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "components.inviteGuestPanel.noPin"
                                                                                    )
                                                                                  )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              1449357284
                                                            )
                                                          }),
                                                          _vm._v(" "),
                                                          _c("v-radio", {
                                                            staticClass:
                                                              "w100 mt-0",
                                                            attrs: {
                                                              "hide-details":
                                                                "true",
                                                              value: "1"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "p",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-0 font-weight-bold labelTitle"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                    " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.2faModal.pinProtection"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                  "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            111808138
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.inviteGuestPanel.pinProtectionTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              3887611389
                                                            )
                                                          }),
                                                          _vm._v(" "),
                                                          _c("v-radio", {
                                                            staticClass:
                                                              "w100 mt-0",
                                                            attrs: {
                                                              value: "2",
                                                              "hide-details":
                                                                "true"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "p",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-0 font-weight-bold labelTitle"
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                    " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.2faModal.complexPin"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                  "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            695189161
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.inviteGuestPanel.complexPinTT"
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              2314989722
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.pinActive || _vm.complexPinActive
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass: "mx-0 pb-0",
                                                  staticStyle: {
                                                    "margin-top": "40px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-11 px-1 py-0",
                                                      staticStyle: {
                                                        "margin-top": "10px"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          label: _vm.pinActive
                                                            ? _vm.$t(
                                                                "components.inviteGuestPanel.enterInvitationPinCode"
                                                              )
                                                            : _vm.$t(
                                                                "components.inviteGuestPanel.enterComplexInvitationPinCode"
                                                              ),
                                                          dense: "",
                                                          maxlength: _vm.complexPinActive
                                                            ? 12
                                                            : 6,
                                                          rules: _vm.complexPinActive
                                                            ? [
                                                                function(v) {
                                                                  return (
                                                                    !!v || ""
                                                                  )
                                                                },
                                                                function(v) {
                                                                  return (
                                                                    (v &&
                                                                      v.length >=
                                                                        12) ||
                                                                    ""
                                                                  )
                                                                },
                                                                function(v) {
                                                                  return (
                                                                    _vm.complexPinRegex.test(
                                                                      v
                                                                    ) ||
                                                                    this$1.$t(
                                                                      "components.inviteGuestPanel.ttComplexPinRequirements"
                                                                    )
                                                                  )
                                                                }
                                                              ]
                                                            : [
                                                                function(v) {
                                                                  return (
                                                                    !!v || ""
                                                                  )
                                                                },
                                                                function(v) {
                                                                  return (
                                                                    (v &&
                                                                      v.length ===
                                                                        6) ||
                                                                    ""
                                                                  )
                                                                },
                                                                function(v) {
                                                                  return (
                                                                    /^\d{6}$/.test(
                                                                      v
                                                                    ) ||
                                                                    this$1.$t(
                                                                      "components.2faPin.sixDigitsRequired"
                                                                    )
                                                                  )
                                                                }
                                                              ]
                                                        },
                                                        on: {
                                                          keydown: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "space",
                                                                32,
                                                                $event.key,
                                                                [
                                                                  " ",
                                                                  "Spacebar"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            $event.preventDefault()
                                                          },
                                                          blur: _vm.checkPin
                                                        },
                                                        model: {
                                                          value: _vm.pinCode,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.pinCode = $$v
                                                          },
                                                          expression: "pinCode"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-1 px-1 py-0"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "my-3 btnPinDet",
                                                                          attrs: {
                                                                            icon:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            style: {
                                                                              fontSize:
                                                                                "15px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "question-circle"
                                                                              ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            863388870
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _vm.complexPinActive
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteGuestPanel.ttComplexPinRequirements"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteGuestPanel.ttPinRequirements"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mx-0",
                                              staticStyle: {
                                                width: "100%",
                                                margin: "0"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "col-12 px-0 py-0",
                                                  class: {
                                                    textAreaSubjectAdvanced:
                                                      _vm.isAdvanced
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    { staticClass: "py-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "col-8 py-0",
                                                          class: {
                                                            "px-1":
                                                              _vm.isAdvanced,
                                                            "px-0": !_vm.isAdvanced
                                                          },
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            staticClass:
                                                              "py-0 my-0 bolder textAreaSubject",
                                                            staticStyle: {
                                                              "font-size":
                                                                "14px"
                                                            },
                                                            attrs: {
                                                              name:
                                                                "subjectinput",
                                                              "no-resize": true,
                                                              rows: _vm.isAdvanced
                                                                ? 1
                                                                : 4,
                                                              counter: "",
                                                              maxlength: "80",
                                                              "auto-grow": "",
                                                              label: _vm.$t(
                                                                "components.inviteVisitor.subject"
                                                              )
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.subject,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.subject = $$v
                                                              },
                                                              expression:
                                                                "subject"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.isAdvanced
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-12 px-0 py-0",
                                                      staticStyle: {
                                                        height: "79px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        { staticClass: "py-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-8 px-1 py-0",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "6px"
                                                              },
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 font-weight-bold labelTitle bannerText",
                                                                  staticStyle: {
                                                                    color:
                                                                      "white",
                                                                    "line-height":
                                                                      "2.5em"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.inviteVisitor.notiz"
                                                                        )
                                                                      ) +
                                                                      "\n                            "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "col-8 px-1 py-0",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-textarea", {
                                                                staticClass:
                                                                  "py-0 my-0",
                                                                attrs: {
                                                                  name:
                                                                    "noteinput",
                                                                  "no-resize": true,
                                                                  rows: "1",
                                                                  counter: "",
                                                                  maxlength:
                                                                    "80"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.internalnote,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.internalnote = $$v
                                                                  },
                                                                  expression:
                                                                    "internalnote"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.isAdvanced
                                            ? _c(
                                                "v-row",
                                                { staticClass: "mx-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-8 px-1 py-0"
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "checkCallPeriod",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.toggleCallPeriod()
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0 font-weight-bold labelTitle"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.inviteVisitor.videoCallTime"
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          1794964635
                                                        ),
                                                        model: {
                                                          value: _vm.callPeriod,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.callPeriod = $$v
                                                          },
                                                          expression:
                                                            "callPeriod"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-combobox", {
                                                        staticClass: "mt-2",
                                                        attrs: {
                                                          items:
                                                            _vm.combinedAppointmentOptions,
                                                          disabled:
                                                            _vm.callPeriod ===
                                                            false,
                                                          "hide-details": "auto"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.getText()
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.appmtOptionselect,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.appmtOptionselect = $$v
                                                          },
                                                          expression:
                                                            "appmtOptionselect"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-col", {
                                                    staticClass:
                                                      "col-1 px-0 py-0"
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-3 px-1 py-0"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "divTitle"
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mb-0 font-weight-bold labelTitle"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteVisitor.linkValidUntil"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            "close-on-content-click": false,
                                                            "nudge-right": 40,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            readonly:
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.linkDateFrom,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.linkDateFrom = $$v
                                                                            },
                                                                            expression:
                                                                              "linkDateFrom"
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "calendarIcon",
                                                                              attrs: {
                                                                                slot:
                                                                                  "prepend",
                                                                                icon: [
                                                                                  "fal",
                                                                                  "calendar-alt"
                                                                                ]
                                                                              },
                                                                              slot:
                                                                                "prepend"
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            1477335075
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.startLinkDateFrom,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.startLinkDateFrom = $$v
                                                            },
                                                            expression:
                                                              "startLinkDateFrom"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              pickTime: true,
                                                              locale: this.$locale.current(),
                                                              "hide-details":
                                                                "auto",
                                                              "first-day-of-week":
                                                                "1"
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                _vm.tixdate = false
                                                              }
                                                            },
                                                            model: {
                                                              value: _vm.date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.date = $$v
                                                              },
                                                              expression: "date"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-8 px-1 py-0"
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "checkFixedDate",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.toggleFixDate()
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0 font-weight-bold labelTitle"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.inviteVisitor.fixtermin"
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          3557895363
                                                        ),
                                                        model: {
                                                          value: _vm.fixedDate,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.fixedDate = $$v
                                                          },
                                                          expression:
                                                            "fixedDate"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-row",
                                                        { staticClass: "mx-0" },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-1 py-0",
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "close-on-content-click": false,
                                                                    "nudge-right": 40,
                                                                    transition:
                                                                      "scale-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    disabled:
                                                                      _vm.fixedDate ===
                                                                      false
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                {
                                                                                  attrs: {
                                                                                    readonly:
                                                                                      "",
                                                                                    disabled:
                                                                                      _vm.fixedDate ===
                                                                                      false
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.linkDateTo,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.linkDateTo = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "linkDateTo"
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "font-awesome-icon",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "calendarIcon",
                                                                                      attrs: {
                                                                                        slot:
                                                                                          "prepend",
                                                                                        icon: [
                                                                                          "fal",
                                                                                          "calendar-alt"
                                                                                        ]
                                                                                      },
                                                                                      slot:
                                                                                        "prepend"
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    4118289642
                                                                  ),
                                                                  model: {
                                                                    value:
                                                                      _vm.startLinkDateTo,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.startLinkDateTo = $$v
                                                                    },
                                                                    expression:
                                                                      "startLinkDateTo"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-date-picker",
                                                                    {
                                                                      attrs: {
                                                                        pickTime: true,
                                                                        locale: this.$locale.current(),
                                                                        disabled:
                                                                          _vm.fixedDate ===
                                                                          false,
                                                                        "first-day-of-week":
                                                                          "1"
                                                                      },
                                                                      on: {
                                                                        input: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.tixdate = false
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.dateTo,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.dateTo = $$v
                                                                        },
                                                                        expression:
                                                                          "dateTo"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "px-1 py-0",
                                                              attrs: {
                                                                cols: "6",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  ref:
                                                                    "menutTo",
                                                                  attrs: {
                                                                    "close-on-content-click": false,
                                                                    "nudge-left": 150,
                                                                    "return-value":
                                                                      _vm.time,
                                                                    transition:
                                                                      "scale-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    disabled:
                                                                      _vm.fixedDate ===
                                                                      false
                                                                  },
                                                                  on: {
                                                                    "update:returnValue": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.time = $event
                                                                    },
                                                                    "update:return-value": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.time = $event
                                                                    }
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs: {
                                                                                      readonly:
                                                                                        "",
                                                                                      disabled:
                                                                                        _vm.fixedDate ===
                                                                                        false
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.linkTimeTo,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.linkTimeTo = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "linkTimeTo"
                                                                                    }
                                                                                  },
                                                                                  "v-text-field",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "font-awesome-icon",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "calendarIcon",
                                                                                      attrs: {
                                                                                        slot:
                                                                                          "prepend",
                                                                                        icon: [
                                                                                          "far",
                                                                                          "clock"
                                                                                        ]
                                                                                      },
                                                                                      slot:
                                                                                        "prepend"
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2800075987
                                                                  ),
                                                                  model: {
                                                                    value:
                                                                      _vm.menutTo,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.menutTo = $$v
                                                                    },
                                                                    expression:
                                                                      "menutTo"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _vm.menutTo
                                                                    ? _c(
                                                                        "v-time-picker",
                                                                        {
                                                                          attrs: {
                                                                            format:
                                                                              "24hr",
                                                                            disabled:
                                                                              _vm.fixedDate ===
                                                                              false
                                                                          },
                                                                          on: {
                                                                            "click:minute": function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.$refs.menutTo.save(
                                                                                _vm.linkTimeTo
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.linkTimeTo,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.linkTimeTo = $$v
                                                                            },
                                                                            expression:
                                                                              "linkTimeTo"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-col", {
                                                    staticClass:
                                                      "col-1 px-0 py-0"
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-3 px-1 py-0"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "divTitle"
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mb-0 font-weight-bold labelTitle"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.inviteVisitor.duration"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "max-width": "50",
                                                          "hide-details": "",
                                                          "single-line": "",
                                                          type: "number",
                                                          min: "1",
                                                          max: "120"
                                                        },
                                                        on: {
                                                          keyup: _vm.checkValue
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.durationMin,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.durationMin = $$v
                                                          },
                                                          expression:
                                                            "durationMin"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isAdvanced
                        ? _c(
                            "v-col",
                            {
                              staticClass: "px-2 py-2 pt-2",
                              attrs: { cols: "6" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c("v-textarea", {
                                staticClass:
                                  "py-0 my-0 pre inviteTextClass textAreaInvite",
                                attrs: {
                                  readonly: 1 === 1,
                                  spellcheck: "false",
                                  name: "subjectinput",
                                  "no-resize": true,
                                  rows: "3"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value: _vm.fixedDetailText,
                                  callback: function($$v) {
                                    _vm.fixedDetailText = $$v
                                  },
                                  expression: "fixedDetailText"
                                }
                              }),
                              _vm._v(" "),
                              _c("div", {}, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 font-weight-bold labelTitle bannerText",
                                    staticStyle: {
                                      color: "white",
                                      "line-height": "2.5em"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.inviteVisitor.invitationTextHeadline"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-textarea", {
                                staticClass:
                                  "py-0 my-0 pre inviteTextClass textAreaInviteText",
                                attrs: {
                                  spellcheck: "false",
                                  name: "noteinput",
                                  "no-resize": true,
                                  counter: "",
                                  rows: "10"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value: _vm.inviteText,
                                  callback: function($$v) {
                                    _vm.inviteText = $$v
                                  },
                                  expression: "inviteText"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "mt-12",
                                  staticStyle: { display: "none" }
                                },
                                [
                                  _vm._v(
                                    "\n              Value: " +
                                      _vm._s(_vm.inviteText) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.copiedLInk
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "col-8 px-1 py-1",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteVisitor.copiedLInk"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.errText
                    ? _c(
                        "v-row",
                        { staticStyle: { width: "100%", margin: "0" } },
                        [
                          _c("div", { staticClass: "mx-auto" }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(_vm.errText))
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-auto footerRow" },
                    [
                      _vm.successText && _vm.successText !== ""
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center minHeight36 pt-0",
                              attrs: { cols: "12" }
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.successText))])]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isAdvanced
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.isAdvanced = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteGuestPanel.advanced"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAdvanced
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.disableSendButton || _vm.multiUser,
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.copyInvitationLink()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteGuestPanel.copyLink"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.amIAdmin && _vm.isAdvanced
                        ? _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              !_vm.textHasChanged
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.saveCustomMessage.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.inviteGuestPanel.saveText"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAdvanced
                        ? _c(
                            "v-col",
                            { staticClass: "text-center cccheckbox" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-checkbox",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "checkCallPeriod",
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "mb-0 font-weight-bold labelTitle"
                                                              },
                                                              [_vm._v("CC")]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.carboncopy,
                                                    callback: function($$v) {
                                                      _vm.carboncopy = $$v
                                                    },
                                                    expression: "carboncopy"
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2176090801
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteGuestPanel.ccTT"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                disabled: _vm.disableSendButton,
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.precheckNewVisitor.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.inviteGuestPanel.sendInvitation"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeVMenu()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("InfoModalInviteVisitorsMenu", {
        attrs: {
          closeInfoModal: _vm.showHideInfoModal,
          showInfoModal: _vm.showInfoModal,
          userSaveLimit: _vm.userSaveLimit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }