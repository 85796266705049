export default class SpeechRecorder {
  constructor(callback) {
    this.mediaRecorder = null;
    this.audioChunks = [];
    this.callback = callback;
    this.isRecording = false;
    this.stream = null;

    // Configuración para detección de voz
    this.audioContext = null;
    this.analyser = null;
    this.silenceThreshold = 0.02;
    this.silenceDuration = 1500; // ms para detectar silencio
    this.voiceThreshold = 0.05; // Nivel mínimo de ruido para detectar voz
    this.lastNoiseTime = Date.now();
    this.detectingVoice = false;
  }

  async init() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Crear AudioContext y AnalyserNode
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = this.audioContext.createMediaStreamSource(this.stream);
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 256;
      source.connect(this.analyser);

      this.monitorVoice();
    } catch (error) {
      console.error("Error al acceder al micrófono:", error);
    }
  }

  start() {
    if (this.isRecording) return;

    this.audioChunks = [];
    this.mediaRecorder = new MediaRecorder(this.stream);
    this.isRecording = true;

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.audioChunks.push(event.data);
      }
    };

    this.mediaRecorder.onstop = () => {
      this.sendAudioChunk();
    };

    this.mediaRecorder.start();
  }

  stop() {
    if (!this.isRecording) return;
    this.mediaRecorder.stop();
    this.isRecording = false;
  }

  monitorVoice() {
    const buffer = new Uint8Array(this.analyser.frequencyBinCount);

    const checkVoice = () => {
      this.analyser.getByteFrequencyData(buffer);
      const average = buffer.reduce((a, b) => a + b) / buffer.length / 255;

      if (average > this.voiceThreshold) {
        this.lastNoiseTime = Date.now();
        if (!this.isRecording) {
          this.start();
        }
      } else if (this.isRecording && Date.now() - this.lastNoiseTime > this.silenceDuration) {
        this.stop();
      }

      requestAnimationFrame(checkVoice);
    };

    checkVoice();
  }

  sendAudioChunk() {
    if (this.audioChunks.length === 0) return;
    const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
    this.audioChunks = [];
    this.callback(audioBlob);
  }

  destroy() {

    // Detener la grabación si está activa
    if (this.isRecording) {
      this.stop();
    }
  
    // Cerrar el AudioContext si está activo
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
  
    // Detener el stream del micrófono
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  
    // Marcar como no activo
    this.isRecording = false;
  }
}