<template>
    <div class="p-0">
      <v-list v-if="sidebar && !mini" class="pt-0 px-0" max-width="320" @click.stop.prevent>
        <v-list-item @click.stop.prevent="openSupportCalendar()">
          <!-- :appendIcon="sortedFavoriteUsers.length === 0 ? null : '$expand'" -->
          <template v-slot:prepend>
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'calendar']"
                  class="primary--text-sidepanel"
                  :style="{fontSize: '20px'}"
                  style="margin-left: -6px;"
                />
              </v-icon>
          </template>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-list-item-title
                  class="primary--text-sidepanel"
                  v-bind="props"
                >
                  {{ $t("components.supportCalendar.supportCalendarTitle") }}
                </v-list-item-title>
              </template>
              <span>{{ $t("components.supportCalendar.supportCalendarTitle") }}</span>
            </v-tooltip>
        </v-list-item>
    
      </v-list>
        <v-tooltip right v-if="mini">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" class="cursorPointer">
              <v-icon @click.stop.prevent="openSupportCalendar()">
                <font-awesome-icon
                  :icon="['fal', 'calendar']"
                  class="primary--text-sidepanel"
                  :style="{fontSize: '20px'}"
                  style="margin-left: -6px;"
                />
              </v-icon>
            </v-list-item>
          </template>
          <span>{{ $t("components.supportCalendar.supportCalendarTitle") }}</span>
        </v-tooltip>
    </div>
</template>
<script>
  import store from "../../store";
  import { isMobile } from "../../lib/mobileUtil";
  
  export default {
    props: ["sidebar", "mini"],
    components: {},
    data() {
      return {
        state: store.state,
        ownUUID: store.state.ownUUID,
        isMobile: isMobile(),
      };
    },
    watch: {
     
    },
    mounted() {
     
    },
    unmounted() {
      
    },
    methods: {
      openSupportCalendar() {
        this.gotoPage("support-calendar");
      },
      setCurrentContentVisile(...args) {
        return store.setCurrentContentVisile(...args);
      },
      gotoPage(route) {
        this.setCurrentContentVisile(route, true, this.$router);
      },
    },
    computed: {
    },
  };
</script>
<style scoped lang="scss">
</style>