var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-auto" },
    [
      _c("v-col", { staticClass: "px-2 d-flex justify-end" }, [
        _c("div", [
          _c("span", [
            _vm._v(
              " " + _vm._s(_vm.$t("components.readGridMessage.text1")) + " "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              " " + _vm._s(_vm.$t("components.readGridMessage.text2")) + " "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              " " + _vm._s(_vm.$t("components.readGridMessage.text3")) + " "
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }