// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w100[data-v-41646adf] {
  width: 100%;
}
.h100[data-v-41646adf] {
  height: 100%;
}
.calendarContainer[data-v-41646adf] {
  height: calc(100vh - 230px);
}
.calendarContainerMobile[data-v-41646adf] {
  max-height: calc(100vh - 300px);
  overflow: scroll;
}
.workingTimeWrapper[data-v-41646adf] {
  display: flex;
}
.eventPreviewRow[data-v-41646adf] {
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 560px);
}
.eventItem[data-v-41646adf] {
  padding: 0;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/supportCalendar.vue","webpack://./supportCalendar.vue"],"names":[],"mappings":"AACA;EACE,WAAA;ACAF;ADGA;EACE,YAAA;ACAF;ADGA;EACE,2BAAA;ACAF;ADGA;EACE,+BAAA;EACA,gBAAA;ACAF;ADGA;EACE,aAAA;ACAF;ADGA;EACE,cAAA;EACA,gBAAA;EACA,+BAAA;ACAF;ADGA;EACE,UAAA;EACA,aAAA;ACAF","sourcesContent":["\n.w100 {\n  width: 100%;\n}\n\n.h100 {\n  height: 100%;\n}\n\n.calendarContainer {\n  height: calc(100vh - 230px);\n}\n\n.calendarContainerMobile {\n  max-height: calc(100vh - 300px);\n  overflow: scroll;\n}\n\n.workingTimeWrapper {\n  display: flex;\n}\n\n.eventPreviewRow {\n  display: block;\n  overflow-y: auto;\n  max-height: calc(100vh - 560px);\n}\n\n.eventItem {\n  padding: 0;\n  display: flex;\n}\n",".w100 {\n  width: 100%;\n}\n\n.h100 {\n  height: 100%;\n}\n\n.calendarContainer {\n  height: calc(100vh - 230px);\n}\n\n.calendarContainerMobile {\n  max-height: calc(100vh - 300px);\n  overflow: scroll;\n}\n\n.workingTimeWrapper {\n  display: flex;\n}\n\n.eventPreviewRow {\n  display: block;\n  overflow-y: auto;\n  max-height: calc(100vh - 560px);\n}\n\n.eventItem {\n  padding: 0;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
