<template>
    <v-avatar :size="size" :rounded="rounded">
        <v-img :src="src" cover />
    </v-avatar>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: true
        },
        size: {
            type: [String, Number],
            default: 48
        },
        rounded: {
            type: [String, Number],
            default: 'circle'
        }
    }
};
</script>
