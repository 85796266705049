var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.person && _vm.person.user && _vm.uuid
    ? _c(
        "div",
        [
          _c(
            "v-row",
            {
              staticClass: "mx-0 mt-4",
              attrs: { align: "center", justify: "space-around" }
            },
            [
              _vm.uuid !== _vm.ownUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.disableCallBtn(_vm.person)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.callUser(_vm.uuid)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "phone"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1649054396
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.userProfile.callUser"))
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isUserInCall(_vm.person) && _vm.hasOnHold
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        color: _vm.wholeBarWithColors()
                                          ? "white"
                                          : "black"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.transferCall(_vm.uuid)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "long-arrow-up"] }
                                    }),
                                    _vm._v(" "),
                                    _c("font-awesome-icon", {
                                      staticClass: "rotatedArrow",
                                      attrs: { icon: ["fal", "long-arrow-up"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3908645989
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.userListItem.callForwarding")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToOrganisation(_vm.uuid)
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "24px" },
                                  attrs: { icon: ["fal", "sitemap"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2853892413
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.organisationCarousel.showInOrganigram"
                        )
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.checkSendMessage(_vm.uuid)
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "paper-plane"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    955963368
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.userProfile.sendAMessage"))
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mx-0 mt-4",
              attrs: { align: "center", justify: "space-around" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            !_vm.isBasicView && _vm.showUploadFile
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.disableSendFileBtn(
                                          _vm.person
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.uuid !== _vm.ownUUID
                                            ? _vm.sendFileTo(_vm.uuid)
                                            : _vm.openDropdown()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: {
                                        icon: ["fal", "upload"],
                                        rotation:
                                          _vm.uuid === _vm.ownUUID ? 180 : null
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "v-btn",
                                  {
                                    style: "visibility: hidden",
                                    attrs: { icon: "" }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "24px" },
                                      attrs: { icon: ["fas", "comment"] }
                                    })
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    745801712
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.uuid !== _vm.ownUUID
                          ? _vm.$t("generics.uploadFileMax50")
                          : _vm.$t("components.drawerContentCard.files")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              !_vm.personIsGuest(_vm.person) &&
              _vm.person.user &&
              !_vm.isreachable
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g({ attrs: { icon: "" } }, on),
                                  [
                                    _vm.IsInList
                                      ? _c("img", {
                                          staticClass: "backOnLine",
                                          attrs: {
                                            src:
                                              "/img/icons/voffice_online-back-on.svg"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.toggleMessageMeRequest(
                                                _vm.person.uuid
                                              )
                                            }
                                          }
                                        })
                                      : _c("img", {
                                          staticClass: "backOnLine",
                                          attrs: {
                                            src:
                                              "/img/icons/voffice_online-back-off.svg"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.toggleMessageMeRequest(
                                                _vm.person.uuid
                                              )
                                            }
                                          }
                                        })
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        159265837
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.userListItem.callWhenAvailable")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.personIsGuest(_vm.person) &&
              _vm.person.user &&
              _vm.showHelpscoutButton &&
              _vm.showVisitPage
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.showHelpscout = true
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "24px" },
                                      attrs: { icon: ["fas", "comment"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2738063613
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Helpscout")])]
                  )
                : _c(
                    "v-btn",
                    { style: "display: none", attrs: { icon: "" } },
                    [
                      _c("font-awesome-icon", {
                        style: { fontSize: "24px" },
                        attrs: { icon: ["fas", "comment"] }
                      })
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("UserMoreActions", {
                attrs: { person: _vm.person, section: null, customStyle: false }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.openingDropdown
            ? [
                _c("DonwnloadsFilesModal", {
                  attrs: {
                    showModal: _vm.openingDropdown,
                    closeModal: _vm.openDropdown
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.amIGuest
            ? [
                _c("RemoveFromFavoriteModal", {
                  attrs: {
                    person: _vm.deleteFavorite.person,
                    showModal: _vm.deleteFavorite.show,
                    closeModal: _vm.closeDeleteFavoriteModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          [
            _vm.changeUserFunction.show
              ? _c("ChangeFunctionModal", {
                  attrs: {
                    modalData: _vm.changeUserFunction,
                    cancelChangeFunction: _vm.cancelChangeFunction
                  }
                })
              : _vm._e()
          ],
          _vm._v(" "),
          _vm.showInvitationModal
            ? _c("SendInvitationModal", {
                attrs: {
                  closeShowInviteInfo: _vm.closeShowInviteInfo,
                  showInviteInfo: _vm.showInvitationModal
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deleteUser
            ? [
                _c("RemoveUserModal", {
                  attrs: {
                    person: _vm.person,
                    showModal: _vm.deleteUser,
                    closeModal: _vm.closeRemoveUserModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showAddHomePage
            ? [
                _c("HomePageModal", {
                  attrs: {
                    sectionData: _vm.sectionData,
                    showModal: _vm.showAddHomePage,
                    closeModal: _vm.closeSetHomePageModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showSendMessageToAll
            ? [
                _c("SendMessageModalGrid", {
                  attrs: {
                    sectionData: _vm.sectionData,
                    showModal: _vm.showSendMessageToAll,
                    closeModal: _vm.closeSendMessageModalGrid
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showHelpscout
            ? [
                _c("InProgressModal", {
                  attrs: {
                    showModal: _vm.showHelpscout,
                    closeModal: _vm.closeHelpscoutModal
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.openHolidaysCalendar
            ? [
                _c("HolidaysCalendarModal", {
                  attrs: {
                    userUUID: _vm.person.uuid,
                    closeModal: _vm.toggleShowHolidaysModal,
                    showModal: _vm.openHolidaysCalendar
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.openDisplayAssistants
            ? [
                _c("DisplayAssistantsModal", {
                  attrs: {
                    closeModal: _vm.toggleDisplayAssistantsModal,
                    showModal: _vm.openDisplayAssistants
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showDirectLink
            ? [
                _vm.showDirectLink
                  ? _c("DirectLinkInvitationModal", {
                      attrs: {
                        showModalDirectLink: _vm.showDirectLink,
                        openCloseModalDirectLink: _vm.setDirectLink
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }