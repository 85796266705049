var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "720" },
      model: {
        value: _vm.dailyReportSettingsModal.showModal,
        callback: function($$v) {
          _vm.$set(_vm.dailyReportSettingsModal, "showModal", $$v)
        },
        expression: "dailyReportSettingsModal.showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.adminSettingsModal.dailyReport"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-5", style: { width: "100%", margin: 0 } },
            [
              _vm.getTypeModal !== "members"
                ? [
                    _c(
                      "v-card-text",
                      {
                        staticClass: "px-0 pb-1 subtitle-2 d-flex align-center"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getTittle) +
                            "\n          "
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-3",
                            attrs: { icon: "", "x-small": "" },
                            on: {
                              click: function($event) {
                                _vm.addEmail = {
                                  show: true,
                                  type: _vm.dailyReportSettingsModal.type,
                                  value: _vm.dailyReportSettingsModal.value
                                }
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "primary--text",
                              style: {
                                fontSize: "20px"
                              },
                              attrs: { icon: ["fal", "plus-circle"] }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "v-chip-group",
                            {
                              attrs: {
                                column: "",
                                "active-class": "primary--text"
                              }
                            },
                            _vm._l(_vm.getEmailsTeamsOrSections, function(
                              email
                            ) {
                              return _c(
                                "v-chip",
                                {
                                  key: email,
                                  attrs: { small: "", close: "" },
                                  on: {
                                    "click:close": function($event) {
                                      return _vm.removeEmail(
                                        _vm.dailyReportSettingsModal.type,
                                        _vm.dailyReportSettingsModal.value,
                                        email
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(email) +
                                      "\n              "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.getUsers
                ? [
                    _c("v-card-text", { staticClass: "px-0 subtitle-2 pb-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("generics.users")) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "userContainer" },
                      [
                        _c(
                          "v-list",
                          { staticClass: "pt-0", attrs: { "two-line": "" } },
                          _vm._l(_vm.getUsers, function(userUUID) {
                            return _c(
                              "v-list-item",
                              { key: userUUID },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.getTitelForUuid(userUUID)
                                                ) +
                                                "\n                      " +
                                                _vm._s(
                                                  _vm.getNameForUuid(userUUID)
                                                ) +
                                                "\n                    "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                icon: "",
                                                "x-small": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.addEmail = {
                                                    show: true,
                                                    type: "members",
                                                    value: userUUID
                                                  }
                                                }
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "primary--text",
                                                style: {
                                                  fontSize: "20px"
                                                },
                                                attrs: {
                                                  icon: ["fal", "plus-circle"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-subtitle",
                                      [
                                        _c(
                                          "v-chip-group",
                                          {
                                            attrs: {
                                              column: "",
                                              "active-class": "primary--text"
                                            }
                                          },
                                          _vm._l(
                                            _vm.getEmailForUser(userUUID),
                                            function(email) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: email,
                                                  attrs: {
                                                    small: "",
                                                    close: ""
                                                  },
                                                  on: {
                                                    "click:close": function(
                                                      $event
                                                    ) {
                                                      return _vm.removeEmail(
                                                        "members",
                                                        userUUID,
                                                        email
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(email) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.addEmail.show,
                callback: function($$v) {
                  _vm.$set(_vm.addEmail, "show", $$v)
                },
                expression: "addEmail.show"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "px-5 pt-5" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "px-0 pb-1 title d-flex align-center" },
                    [_vm._v("\n          Add Email\n        ")]
                  ),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: { rules: _vm.emailRules, label: "E-mail" },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red darken-1", text: "" },
                          on: { click: _vm.closeAddEmail }
                        },
                        [_vm._v("\n            cancel\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary darken-1",
                            text: "",
                            disabled: _vm.disableSaveButton
                          },
                          on: { click: _vm.saveEmail }
                        },
                        [_vm._v("\n            save\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }