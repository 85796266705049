var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      attrs: { "max-width": "320" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
      }
    },
    [
      !_vm.isGuest && !_vm.mini
        ? _c(
            "v-list-group",
            {
              attrs: {
                disabled: _vm.state.searchTerm !== "" ? true : false,
                "active-class": "listColor",
                appendIcon:
                  _vm.sortedUsers.length === 0 || _vm.state.showSearch
                    ? null
                    : "$expand",
                value:
                  _vm.state.searchTerm.length > 2
                    ? false
                    : _vm.isActive
                    ? true
                    : undefined
              },
              on: {
                click: function($event) {
                  return _vm.handlerClickUsers($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "v-list-item-icon",
                          { class: { iconWithPL: _vm.mini } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "font-awesome-icon",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "primary--text-sidepanel cursorPointer",
                                                class:
                                                  "" +
                                                  (_vm.getRoute === "/users"
                                                    ? "primary--text-sidepanel"
                                                    : ""),
                                                attrs: {
                                                  icon: _vm.currentPage(
                                                    "/users"
                                                  )
                                                    ? ["fas", "user"]
                                                    : ["fal", "user"]
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.handlerClickUserIcon.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1004533674
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.sidebar.userMiniTT")
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.mini
                          ? _c(
                              "v-list-item-title",
                              {
                                staticClass: " cursorPointer",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.showHideSearch.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                !_vm.state.showSearch
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "primary--text-sidepanel"
                                      },
                                      [
                                        _vm._v(
                                          "\n          " +
                                            _vm._s(
                                              _vm.$t("generics.searchUser")
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("hr", { staticClass: "hrUSers" })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.amIGuest && _vm.state.showSearch
                                  ? _c("DrawerContentSearch")
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isMobile &&
                                _vm.amIAdmin &&
                                !_vm.state.showSearch &&
                                !_vm.mini
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "btnAddGroup",
                                                        attrs: {
                                                          icon: "",
                                                          disabled: false
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.openInviteUser
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "primary--text-sidepanel",
                                                        style: {
                                                          fontSize: "20px"
                                                        },
                                                        attrs: {
                                                          icon: [
                                                            "fal",
                                                            "plus-circle"
                                                          ]
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          296469441
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.sidebar.userTT"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCustomTT && !_vm.mini
                          ? _c("CustomTT", {
                              attrs: {
                                text:
                                  _vm.$t("components.hotkeys.controlKey") +
                                  " + S",
                                position: "bottom",
                                extraclass: "ctrs"
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1429023645
              )
            },
            [
              _vm._v(" "),
              _c("UserList", {
                attrs: { persons: _vm.sortedUsers, section: "Favorites" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                class: { iconWithPL: _vm.mini },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handlerClickUserIcon.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass:
                                    "primary--text-sidepanel cursorPointer",
                                  class:
                                    "" +
                                    (_vm.getRoute === "/users"
                                      ? "primary--text-sidepanel"
                                      : ""),
                                  attrs: {
                                    icon: _vm.currentPage("/users")
                                      ? ["fas", "user"]
                                      : ["fal", "user"]
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3063745615
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.sidebar.userMiniTT")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }