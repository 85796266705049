<template>
  <div class="InviteFormContainer">
    <v-card :class="`${!showTimeline ? 'pt-12 mt-6' : 'pt-6'} pl-10 pr-10`">
      <v-tabs centered class="tabInvite" :model-value="tab" @update:model-value="tab = $event">
        <v-tab>
          {{ $t("components.inviteVisitor.inviteVofficeUser") }}
        </v-tab>
        <v-tab>
          {{ $t("components.inviteGuestPanel.inviteList") }}
        </v-tab>
      </v-tabs>
      <v-window :model-value="tab" @update:model-value="tab = $event">
        <!--Tab Content 1-->
        <v-window-item>
          <v-card flat>
            <v-row class="mt-6">
              <!--column 1-->
              <v-col cols="5">
                <v-row>
                  <!--title-->
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      :model-value="title" @update:model-value="title = $event"
                      :label="$t('components.inviteGuestPanel.title')"
                    ></v-text-field>
                  </v-col>
                  <!--name-->
                  <v-col cols="8" class="py-0">
                    <v-text-field
                      :model-value="username" @update:model-value="username = $event"
                      :label="$t('components.inviteFormUser.username')"
                      required
                      variant="outlined"
                      :rules="userNameRules"
                      density="compact"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!--title-->
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      :rules="surnameRules"
                      :model-value="surname" @update:model-value="surname = $event"
                      :label="$t('components.userManagement.surname')"
                    ></v-text-field>
                  </v-col>
                  <!--name-->
                  <v-col cols="6" class="py-0">
                    <v-text-field
                      :model-value="firstName" @update:model-value="firstName = $event"
                      :label="$t('components.userManagement.firstName')"
                      :rules="firstNameRules"
                      required
                      variant="outlined"
                      density="compact"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" class="py-0">
                    <!--email-->
                    <v-text-field
                      :model-value="email" @update:model-value="email = $event; checkEmailPreSend()"
                      :label="$t('components.inviteGuestPanel.eMail')"
                      required
                      variant="outlined"
                      density="compact"
                      :rules="emailRules"
                      @keyup="changeEmail"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 positionRelative">
                    <vue-tel-input
                      :model-value="telNumberModel" 
                      @update:model-value="telNumberModel = $event"
                      defaultCountry="DE"
                      enabledCountryCode
                      @validate="onTelInput"
                      name="number"
                      id="numDisplay"
                      class="focusStyle form-control text-center input-sm inputPhone2FA"
                      value
                      :inputOptions="{placeholder: $t('components.inviteGuestPanel.telOptional')}"
                      autocomplete="off"
                      :class="{ borderError: !phoneInputValid }"
                    ></vue-tel-input>
                    <label
                      :class="isDarkMode ? 'bgBlack' : 'bgWhite'"
                      class="customLabelTlf"
                      >{{
                        $t("components.inviteGuestPanel.telOptional")
                      }}</label
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <!--position-->
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      :model-value="position" @update:model-value="position = $event"
                      :label="$t('components.inviteGuestPanel.position')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <!--team-->
                    <v-autocomplete
                      :label="$t('components.inviteGuestPanel.teams')"
                      :model-value="selectedTeams" @update:model-value="selectedTeams = $event"
                      :items="teams"
                      item-value="team"
                      item-title="team"
                      multiple
                      clearable
                      variant="outlined"
                      density="compact"
                      v-if="teams.length > 0"
                    />
                  </v-col>
                </v-row>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-switch
                      v-on="on"
                      hide-details
                      v-if="amIAdmin"
                      :disabled="userSupport"
                      class="my-0 py-0 pl-3"
                      :model-value="createAdmin" @update:model-value="createAdmin = $event"
                    >
                    <template #label v-bind="props">{{
                        $t("components.inviteGuestPanel.adminRights")
                      }}</template>
                    </v-switch>
                  </template>
                  <span>{{
                    $t("components.inviteGuestPanel.adminRightsText")
                  }}</span>
                </v-tooltip>
                <!--dayli inviation-->
                <v-tooltip location="top" max-width="250">
                  <template v-slot:activator="{ props }">
                    <v-switch
                      v-on="on"
                      v-if="amIAdmin"
                      class="my-0 py-0 pl-3"
                      :disabled="createAdmin"
                      hide-details
                      :model-value="userSupport" @update:model-value="userSupport = $event"
                    >
                      <template #label v-bind="props">{{
                        $t("components.inviteGuestPanel.inviteUserSupport")
                      }}</template>
                    </v-switch>
                  </template>
                  <span>{{
                    $t("components.inviteGuestPanel.inviteSupportText")
                  }}</span>
                </v-tooltip>
                <!--PIN-->
                <v-row style="position: relative">
                  <v-col cols="6" class="py-0">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-checkbox
                          class="mt-0 mb-4"
                          v-on="on"
                          :model-value="pinActive" @update:model-value="pinActive = $event"
                        >
                          <template #label v-bind="props">{{
                            $t("components.2faModal.PINInviteUser")
                          }}</template>
                        </v-checkbox>
                      </template>
                      <span>{{
                        $t("components.2faModal.btnEnablePINText")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <p class="text-body-2 noteStyle pinAdvice" v-if="pinActive">
                      {{ $t("components.inviteGuestPanel.pinUserSenden") }}
                    </p>
                    <!--PIN template ad function-->
                    <v-text-field
                      class="mt-0"
                      v-if="pinActive"
                      autocomplete="off"
                      :model-value="pinCode" @update:model-value="pinCode = $event"
                      :label="'PIN'"
                      variant="outlined"
                      density="compact"
                      @keydown.space.prevent
                      :maxlength="6"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!--column 2-->
              <v-col cols="7" class="pl-8" @click.stop.prevent>
                <v-textarea
                  class="inviteTextAreaClass"
                  spellcheck="false"
                  :model-value="customMessage" @update:model-value="customMessage = $event"
                  variant="outlined"
                  :no-resize="true"
                  name="noteinput"
                  auto-grow
                  rows="1"
                  @click.stop.prevent
                  :label="$t('components.inviteVisitor.invitationTextHeadline')"
                ></v-textarea>
                <div style="display: none">Value: {{ customMessage }}</div>
                <div align="right" class="buttonsTextarea py-4 px-0">
                  <v-tooltip location="top" max-width="240">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        class="mr-3 mb-2"
                        v-bind="props"
                        align="right"
                        density="compact"
                        color="primary"
                        variant="outlined"
                        @click="resetCustomMessage()"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'undo']"
                          :style="{ fontSize: '18px' }"
                          class="mr-3"
                        />
                        {{
                          $t(
                            "components.inviteGuestPanel.resetCustomInviteText"
                          )
                        }}
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteGuestPanel.resetUserInviteTextTT")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        align="right"
                        color="primary"
                        variant="outlined"
                        density="compact"
                        class="mb-2"
                        :disabled="isTextEmpty"
                        @click.stop.prevent="saveCustomMessage()"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'save']"
                          :style="{ fontSize: '18px' }"
                          class="mr-3"
                        />
                        {{ $t("components.inviteGuestPanel.saveText") }}
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteGuestPanel.saveText")
                    }}</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <!-- /////// Error text ///////// -->
            <v-row class="mx-0 py-2" style="width: 100%" v-if="errMssg">
              <div>
                <span align="left" style="color: red">{{
                  $t("components.inviteGuestPanel.errAlreadyUser")
                }}</span>
              </div>
            </v-row>
            <v-row class="mx-auto footerRow">
              <v-col cols="12" class="px-0 pt-2">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" align="right" class="px-0">
                <v-btn
                  width="230"
                  :disabled="isSendDisabled"
                  color="primary"
                  :loading="sendingInvitation"
                  @click="sendInvitation(false)"
                  >{{ $t("components.inviteGuestPanel.sendInvitation") }}
                </v-btn>
                <v-btn
                  style="border-radius: 4px!important; min-width: 45px"
                  icon
                  tile
                  variant="outlined"
                  class="ml-3 mr-2"
                  color="primary"
                  density="comfortable"
                  @click="closeInvitationUserForm()"
                >
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
        <!--Tab Content 2-->
        <v-window-item>
          <div
            class="visitorListTable"
            v-if="state.persisted.invitationsSent.length > 0"
          >
            <div v-for="(invitation, index) in getInvitationList" :key="index">
              <div class="d-flex Table">
                <div class="ListAvatar">
                  <div class="AvatarImage">
                    <v-img
                      :src="
                        getAvatarForUuid(invitation.uuid) ||
                        'img/default_profile_picture.png'
                      "
                      lazy-src="img/default_profile_picture.png"
                    ></v-img>
                  </div>
                </div>
                <!--send-mail-->
                <div class="ListMessage">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1 mx-2"
                        icon
                        small
                        @click="setShowModalSenMsg(invitation.uuid, 'message')"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'paper-plane']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("components.groups.sendMessage") }}</span>
                  </v-tooltip>
                </div>
                <!--name-->
                <div class="ListName pl-2 mb-2">
                  <p class="mt-2 mb-2">
                    {{ getTitelForUuid(invitation.uuid) }}
                    {{ invitation.user.name }}
                  </p>
                </div>
                <!--email-->
                <div class="ListEmail pl-2 mb-2">
                  <p class="mt-2">
                    {{ invitation.user.email }}
                    <font-awesome-icon
                      v-if="invitation.uuid"
                      v-show="hasUuidBeenOnPage(invitation.uuid)"
                      :icon="['fal', 'check']"
                      color="primary"
                      class="ml-2"
                    />
                  </p>
                </div>
                <!--date-->
                <div class="listDate">
                  <p class="mt-2">
                    {{ formatDateToShow(invitation.user.created) }}
                  </p>
                </div>
                <!--funktion-->
                <div class="ListFunktion pl-2 mb-2">
                  <p class="mt-2">{{ invitation.user.position }}</p>
                </div>
                <!--team-->
                <div class="ListTeam pl-2 mb-2 pr-2">
                  <p class="mt-2">{{ getTeamList(invitation.user.teams) }}</p>
                </div>
                <!--resend-->
                <div class="ListResend">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1"
                        icon
                        small
                        @click="SendLinkToUser(invitation.uuid)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'redo']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteVisitor.resendInvLink")
                    }}</span>
                  </v-tooltip>
                </div>
                <!--copy link-->
                <div class="ListCopy">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="mt-1"
                        icon
                        small
                        @click="copyUserLink($event, invitation.uuid)"
                      >
                        <font-awesome-icon
                          :icon="['fal', 'copy']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      $t("components.inviteGuestPanel.copyLink")
                    }}</span>
                  </v-tooltip>
                </div>
                <!--delete-->
                <div class="ListDelete">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" class="mt-1" icon small>
                        <DeleteBasket
                          :indexData="invitation.uuid"
                          :delFunction="deleteVisitor"
                          extraClasses=""
                          fontSize="20"
                        />
                      </v-btn>
                    </template>
                    <span>{{ $t("generics.delete") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider class="mt-2 mb-2"></v-divider>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
    <div>
      <div v-if="showModalDelete">
        <RemoveVisitorModal
          :showModal="showModalDelete"
          :closeModal="closeRemoveUserModal"
          :deleteVisitor="deleteVisitor"
        ></RemoveVisitorModal>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
import {
  addUserInviters,
  createNewUser,
  isEmailRegistered,
  sendInviteMailCustom,
  sendInviteWhatsApp,
  getUserInviteLink,
} from "../../lib/wsMsg";
import sendInviteInfoModal from "../modal/sendInviteInfoModal.vue";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import DeleteBasket from "../ui/deleteBasket.vue";
import InfoPinModal from "../modal/infoPinModal.vue";
import { deleteVisitor } from "../../utils/waitingRoom";
import { setHistoryMessagesModalEvent } from "../../effector/modals";
import { timelineEvents } from "../../effector/timeline";
import RemoveVisitorModal from "../modal/removeVisitorModal.vue";
import { dispatchSuccessAlert, dispatchErrorAlert } from "../../effector/alerts";
export default {
  components: {
    sendInviteInfoModal,
    InfoPinModal,
    SendInvitationModal,
    RemoveVisitorModal,
    DeleteBasket,
  },
  props: ["closeMenu", "fromRoot"],
  data() {
    const effector = {
      timelineEvents: timelineEvents,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      showModalDelete: false,
      state: store.state,
      createAdmin: false,
      username: "",
      surname: "",
      firstName: "",
      email: "",
      position: "",
      title: "",
      errMssg: false,
      customMessage: "",
      pinActive: false,
      pinCode: "",
      moment: moment,
      emailValid: true,
      sendingInvitation: false,
      selectedTeams: [],
      userNameRules: [
        (v) =>
          !!v || "" || this.$t("components.inviteGuestPanel.usernameRequired"),
      ],
      firstNameRules: [
        (v) =>
          !!v || "" || this.$t("components.firstModal.firstNameRequired"),
      ],
      surnameRules: [
        (v) =>
          !!v || "" || this.$t("components.inviteGuestPanel.surnameRequired"),
      ],
      emailRules: [
        (v) => !!v || "", //remove red mesagge ticket #132
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || "",
        (v) => !!v && !v.trim().includes(","),
        (v) => !!v && !v.trim().includes(";"),
      ],
      userSupport: false,
      tab: null,
      setCurrentContentVisile: store.setCurrentContentVisile,
      phoneNumberRegister: "",
      phoneInputValid: true,
      dialCode: "",
      telNumberModel: "",
      // Effector
      ...effector,
    };
  },
  mounted() {
    this.loadActualMessage();
    EventBus.$on("openTabInviteUsertList", this.openListTab);
  },
  unmounted(){
    EventBus.$off("openTabInviteUsertList", this.openListTab);
  },
  methods: {
    changeEmail(){
      this.email = this.email.trim().toLowerCase()
    },
    openListTab() {
      this.tab = 1;
    },
    closeInvitationUserForm() {
      this.setCurrentContentVisile("users", true, this.$router);
    },
    formatDateToShow(created) {
      return this.moment(new Date(created)).format("DD.MM.YY, HH:mm");
    },
    onTelInput({ number, valid, country } = {}) {
      const data = { number, valid, country };
      if (this.telNumberModel.length > 0) {
        if (data.number) {
          // new number
          if (data.valid) {
            this.dialCode = data.country ? "+" + data.country.dialCode : "";
            this.phoneNumberRegister = data.number.replace(/[-\s]/g, "");
          } else {
            this.dialCode = "";
            this.phoneNumberRegister = "";
          }
          this.phoneInputValid = data.valid;
        } else {
          // number from the saved list
          this.phoneNumberRegister = (this.phoneNumberInput || "").replace(
            /\s/g,
            ""
          );
          this.telNumberModel = this.phoneNumberRegister;
          this.phoneInputValid = true;
        }
      } else {
        this.dialCode = "";
        this.phoneNumberRegister = "";
        this.phoneInputValid = true;
      }
    },
    getTeamList(teams) {
      let temporalResult = "";
      if (teams.length > 0) {
        teams.forEach((team, index) => {
          const addComma = index < teams.length - 1 ? true : false;
          temporalResult += team + (addComma ? ", " : "");
        });
        return temporalResult;
      }
      return temporalResult;
    },
    async deleteVisitor(visId) {
      await deleteVisitor(visId, this.timelineEvents);
    },
    closeRemoveUserModal() {
      this.showModalDelete = false;
    },
    async copyUserLink(evt, uuid) {
      let instalink = await getUserInviteLink(uuid);
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
    SendLinkToUser(uuid) {
      const person = this.state.group[uuid] || {};
      return sendInviteMailCustom(uuid, { ...person.user, subject: this.$t('components.invitesText.defaultUserSubject', person.user.language || this.defaultEmailLanguage, [this.virtualOfficePrefix]) })
        .then(() => {
          dispatchSuccessAlert(
            `${this.$t("components.userListItem.yourInvitationTo")} ${
              person.user.name
            } ${person.user.email} ${this.$t(
              "components.userListItem.hasBeenSuccessfullySent"
            )}`
          );
        })
        .catch((err) => {
          dispatchErrorAlert(err.message || err);
        });
    },
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
    },
    goHome() {
      this.setCurrentContentVisile("home", true, this.$router);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    hasUuidBeenOnPage(uuid) {
      const person = this.state.group[uuid];
      return ((person || {}).user || {}).rtcCallStart !== undefined;
    },
    loadActualMessage() {
      this.loadCustomMessage();
    },
    loadCustomMessage() {
      if (this.state.user.customInviteUserMessage) {
        if (
          !this.state.persisted.customInviteUserMessage &&
          typeof this.state.user.customInviteUserMessage === "string"
        ) {
          this.state.persisted.customInviteUserMessage = this.state.user.customInviteUserMessage.trim();
        }
        this.$delete(this.state.user, "customInviteUserMessage");
      }
      this.customMessage =
        this.state.persisted.customInviteUserMessage ||
        this.$t("components.invitesText.defaultUserText");
    },
    resetCustomMessage() {
      this.customMessage = this.state.namespaceSettings.customInviteMessage || this.$t("components.invitesText.defaultUserText");
      this.saveCustomMessage(true);
      dispatchSuccessAlert(
        this.$t("components.inviteVisitor.invitationTextWasReset")
      );
    },
    saveCustomMessage(hideToaster) {
      this.setCustomInviteUserMessage(this.customMessage);
      if (!hideToaster) {
        dispatchSuccessAlert(
          this.$t("components.invitesText.invitationTextSaved")
        );
      }
    },
    setCustomInviteUserMessage(value) {
      return store.setCustomInviteUserMessage(value);
    },
    async checkEmailPreSend() {
      const email = this.email.trim().toLowerCase();
      const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
      if (!re.test(email)) {
        this.emailValid = true;
        this.errMssg = false;
        return;
      }
      const emailRegistered = await isEmailRegistered(email);
      const newUUID = emailRegistered.key;
      if (
        emailRegistered.found &&
        !((this.state.group[newUUID] || {}).user || {}).guest
      ) {
        this.emailValid = false;
        this.errMssg = true;
      } else {
        this.errMssg = false;
        this.emailValid = true;
      }
    },
    async sendInvitation(close = true) {
      this.sendingInvitation = true;
      this.email = this.email.trim().toLowerCase();
      const emailRegistered = await isEmailRegistered(this.email);
      const newUUID = emailRegistered.key;
      if (!emailRegistered.found) {
        const options =
          this.pinActive && this.pinCode
            ? { "2fa": { pin: { code: this.pinCode } } }
            : {};
        if (this.userSupport) {
          options.userSupport = true;
        }
        if (this.createAdmin) {
          options.createAdmin = true;
        }
        if (this.selectedTeams.length > 0) {
          options.selectedTeams = this.selectedTeams;
          const orga = this.state.namespaceSettings.organisation;
          for (const i in orga) {
            if (Object.hasOwnProperty.call(orga, i)) {
              const element = orga[i];
              if (element.team) {
                if (this.selectedTeams[element.team]) {
                  element.teamMembers.push(newUUID);
                }
              }
            }
          }
        }
        await createNewUser(
          {
            sections: [],
            locations: [],
            teams: this.selectedTeams.length > 0 ? this.selectedTeams : [],
            position: this.position,
            qualification: "",
            titel: this.title,
            name: this.username,
            firstName: this.firstName,
            lastName: this.surname,
            email: this.email,
            accessmail: "",
            phone: "",
            extension: "",
            mobilePhone: this.phoneNumberRegister || "",
            device: "",
            hours: "",
            homeHours: "",
            coreHours: "",
            guest: false,
            visitor: false,
            inviters: {
              [this.state.ownUUID]: true,
            },
          },
          newUUID,
          options
        );
       let subject = this.$t("components.invitePreview.inviteCompanySubject"); 
        await sendInviteMailCustom(newUUID, {
          name: this.username,
          email: this.email,
          guest: false,
          subject: subject,
          customMessage: this.customMessage, //EmailText,
        });
        //if have phonenumber send a whatssapp #2983
        if (this.phoneNumberRegister.length > 0) {
          await sendInviteWhatsApp(newUUID, {
            name: this.username,
            email: this.email,
            guest: false,
          });
        }
      } else {
        await addUserInviters(newUUID);
      }
      const invitationObject = {
        uuid: newUUID,
        name: this.username,
        email: this.email,
        guest: false,
        date: new Date(),
        customMessage: this.customMessage,
      };
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: invitationObject,
      };
      store.state.persisted.invitationsSent.push(invitationObject);
      if (this.closeMenu && close) {
        this.closeMenu();
      }
      this.cleanData();
      this.goHome();
      dispatchSuccessAlert(this.$t("components.userListItem.invitationSent"));
    },
    cleanData() {
      this.email = "";
      this.username = "";
      this.title = "";
      this.position = "";
      this.firstName = "";
      this.surname = "";
      this.createAdmin = false;
      this.selectedTeams = [];
      this.customMessage = this.state.namespaceSettings.customInviteGuestMessage;
      this.sendingInvitation = false;
    },
  },
  computed: {
    defaultEmailLanguage() {
      return (webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isRaMicroCustomer)
        ? "de"
        : "en";
    },
    virtualOfficePrefix() {
      return store.state.namespaceSettings.companyInfo.virtualOfficePrefix || store.state.namespaceSettings.companyInfo.company_name || store.getVirtualOfficePrefix();
    },
    showTimeline() {
      if (
        this.$route.path === "/invitationformuser" ||
        this.$route.path === "/invitationform"
      ) {
        return false;
      }
      return true;
    },
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    isSendDisabled() {
      const disabledForm =
        this.isEmailInvalid ||
        !this.username ||
        this.username.trim().length == 0 ||
        !this.firstName ||
        this.firstName.trim().length == 0 ||
        !this.surname ||
        this.surname.trim().length == 0 ||
        this.isTextEmpty ||
        this.customMessage.trim().length == 0 ||
        !this.phoneInputValid
          ? true
          : false;
      if (!this.emailValid || disabledForm) return true;
      return false;
    },
    isTextEmpty() {
      return this.customMessage == "" || this.customMessage.trim().length === 0;
    },
    isEmailInvalid() {
      if (this.email && this.email.length > 0) {
        const email = this.email.trim().toLowerCase();
        if(email.includes(",") || email.includes(";")){
          return true
        }
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return !re.test(email);
      } else {
        return true;
      }
    },
    teams() {
      const organisation = this.state.namespaceSettings.organisation;
      if (organisation) {
        return (
          organisation &&
          organisation.filter(
            (it) => it.departmentRights !== 1 && it.sectionRights !== 1
          )
        );
      }
    },
    getInvitationList() {
      let invites = [];
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        const isGuest =
          person.permissions === 10 ||
          !person.user ||
          person.user.guest ||
          person.user.visitor;
        if (
          !person ||
          !person.user ||
          person.user.name == "" ||
          isGuest ||
          !(person.user.inviters || {})[store.state.ownUUID] ||
          person.user.rtcCallStart !== undefined
        )
          continue;
        invites.push({
          ...person,
          user: {
            ...person.user,
            uuid,
            visitorId: uuid,
            created: person.userTS,
            instalink: "",
          },
          uuid,
        });
      }
      return invites.sort(function (a, b) {
        const aDate = new Date(a.user.created);
        const bDate = new Date(b.user.created);
        return aDate > bDate
          ? -1
          : aDate < bDate
          ? 1
          : (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
  },
  watch: {
    fromRoot: {
      handler: function (value) {
        if (value === true) {
          this.userSupport = false;
          this.loadActualMessage();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.pinAdvice {
  position: absolute;
  top: 49px;
}
.noteStyle {
  font-weight: 300 !important;
}
.focusStyle:focus-within {
  border: 2px solid var(--v-primary-color);
  border-radius: 4px;
  box-shadow: none;
}
.vue-tel-input :focus::-webkit-input-placeholder {
  visibility: hidden; /*hide placeholder on focus*/
}
.focusStyle:focus-within + .customLabelTlf {
  display: block;
}
.bgBlack {
  background: #1e1e1e;
}
.bgWhite {
  background: #fff;
}
.customLabelTlf {
  display: none;
  position: absolute;
  left: 11px;
  top: -11px;
  padding: 0 3px;
  font-size: 16px;
  letter-spacing: normal;
  transform: scale(0.75);
  color: #2a3133;
}
.vue-tel-input {
  height: 40px !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
}
.InviteFormContainer .v-card {
  box-shadow: none;
}
.InviteFormContainer {
  height: 100%;
  overflow: auto;
}
.visitorListTable {
  height: calc(100vh - 33%);
  overflow-y: auto;
}
.Table {
  padding-top: 6px;
  height: 50px;
}
.ListAvatar {
  width: 5%;
}
.AvatarImage {
  max-height: 40px;
  max-width: 40px;
}
.ListMessage {
  width: 4%;
}
.ListName {
  width: 14%;
  overflow: hidden;
}
.ListEmail {
  width: 18%;
  overflow: hidden;
}
.listDate {
  width: 11%;
  overflow: hidden;
}
.ListFunktion {
  width: 14%;
  overflow: hidden;
}
.ListTeam {
  width: 22%;
  overflow: hidden;
}
.ListResend {
  width: 4%;
}
.ListCopy {
  width: 4%;
}
.ListDelete {
  width: 4%;
}
.buttonsTextarea {
  margin-top: -30px;
}
/*old check*/
.positionRelative {
  position: relative;
}
.v-application .mt-5 {
  margin-top: 5px !important;
}
.v-theme--dark .footerRow {
  background-color: #1f1f1f;
}
.v-theme--light .footerRow {
  background-color: #ffffff;
}
.invitationMenuList {
  width: calc(100vw - 0px);
  padding-top: 0px;
  max-width: 1024px;
  max-height: 820px;
}
.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 40px !important;
  font-size: 30px !important;
}
.v-menu__content {
  min-width: unset !important;
}
</style>
<style lang="scss">
.AvatarImage .v-image__image {
  border-radius: 4px;
}

.listTitle {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 2rem !important;
}
.listSubtitle {
  font-size: 15px !important;
  font-weight: 300 !important;
}
.inviteTextAreaClass textarea {
  align-self: auto !important;
  line-height: 1.5rem !important;
  padding-right: 30px !important;
  padding-left: 10px !important;
  padding-top: 15px !important;
  margin-bottom: 10px;
  max-height: calc(100vh - 431px) !important;
  overflow-y: hidden !important;
}
.textAreaSubject {
  & textarea {
    overflow: scroll;
    max-height: 32px;
    border-bottom: 1px #f2f2f2;
  }
}
.textAreaSubject {
  & textarea {
    overflow: scroll;
    max-height: 32px;
    border-bottom: 1px #f2f2f2;
  }
}
.inviteTextAreaClass {
  & .v-input__slot {
    height: auto !important;
    max-height: none;
    min-height: 300px !important;
  }
}

.__view {
  width: unset !important;
}
.v-theme--light.v-input textarea {
  &::placeholder {
    color: black !important;
  }
}

.v-theme--dark.v-input textarea {
  &::placeholder {
    color: white !important;
  }
}

.cccheckbox {
  max-width: 50px !important;
  padding: 0 !important;
  padding-top: 21px !important;
  max-height: 46px !important;
}

.checkCallPeriod {
  & .v-input__control {
    max-height: 35px;
  }
}

.invitecheckb {
  margin-top: 0 !important;
  padding-top: 0px !important;
}
#inviteVisitorMenu {
  height: 100% !important;
  & .v-label {
    font-weight: 500 !important;
    font-size: 14px;
  }
  & .invitationMenuList {
    width: 100% !important;
    padding-top: 0px;
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100%;
  }
}

.checkCallPeriod {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 25px;
}

.checkFixedDate {
  margin-top: 0px !important;
  padding-top: 0px !important;
  max-height: 31px;
}

.inviteHeader {
  background-color: #2a3133;
  height: 40px;
  margin: 0px;
  text-align: center;
}

.inviteTextTitle {
  background-color: transparent;
  color: white;
  margin: 0 auto;
  font-size: 17px;
  font-weight: bold;
  top: 2px;
  position: relative;
}
.invitationList {
  position: absolute;
  top: 0;
  right: 0;
}
.mt-17 {
  margin-top: 17px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.headselect label {
  color: white !important;
}
.headselect .v-icon {
  color: white !important;
}

.headselect .v-theme--light.v-text-field > .v-input__control > .v-input__slot {
  border-color: white !important;
}
.headselect > .v-input__control > .v-input__slot:before {
  border-color: rgb(255, 255, 255) !important;
}

.participantsList {
  margin: 0px;
  padding: 0px !important;
  max-height: 25px;
}
</style>
