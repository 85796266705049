<template>
  <v-row class="px-4">
    <v-col cols="2">
    <v-list dense>
      <v-list-item-title class="mb-3 primary--text" > {{ $t('generics.sections') }}</v-list-item-title>
      <v-list-item-group
        v-model="selectedSection"
        color="primary"
        class="listWrapper"
      >
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <v-list-item key="allSections" value="allSections">
          <v-list-item-content>
            <v-list-item-title v-text="$t('generics.allSections')"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
           v-for="(section, index) in sectionsData"
          :key="index"
          :value="index"
        >
          <v-list-item-content>
            <v-list-item-title v-text="section.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item value="intern">
          <v-list-item-content>
            <v-list-item-title v-text="$t('components.userManagement.intern')"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    </v-col>
    <v-col cols="10">
      <template v-if="selectedSection !== 'intern'">
        <StatusTable :headers="headers" :users="loadDataTable" :itemsPerPage="itemsPerPage" :statusType="getStatusType" :getItemPerPage="getItemPerPage"/> 
      </template>
      <template v-else>
        <InternTable :internUsers="loadDataTable" :statusType="getStatusType" />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import StatusTable from "./statusTable.vue";
import InternTable from "./internTable.vue";
import { checkIsDateInRange, getOrganigramTeams } from "../../utils/basicFunctions";
export default {
  components: { StatusTable, InternTable },
  data() {
    return {
      state: store.state,
      moment: moment,
      itemsPerPage: store.state.persisted.itemsPerPageStatus,
      selectedSection:'allSections'
      
    };
  },
  methods: {
    getItemPerPage(val) {
      this.itemsPerPage = val;
      this.state.persisted.itemsPerPageStatus = this.itemsPerPage;
    },
    getMemberData(member, uuid) {
      const person = JSON.parse(JSON.stringify(member));
      const { emailAlias: toField, email: toFieldOld } = person.user || {};
      person.user.email = toField || toFieldOld;
      person.user.nametitel = this.getUserTitel(uuid) + " " + person.user.name;
      person.user.uuid = uuid;
      person.user.connected = person.connected;
      person.user.holidays =
        person.user.startHolidays &&
        person.user.endHolidays &&
        (moment(new Date(person.user.endHolidays)).isSame(new Date(), "day") ||
          !moment(new Date(person.user.endHolidays)).isBefore(
            new Date(),
            "day"
          ))
          ? moment(new Date(person.user.startHolidays)).format("DD.MM.YYYY") +
            " - " +
            moment(new Date(person.user.endHolidays)).format("DD.MM.YYYY")
          : "";
      person.user.isInHolidays = checkIsDateInRange(
        new Date(person.user.startHolidays),
        new Date(person.user.endHolidays),
        new Date()
      );
      person.user.presentFrom = person.user.presentFrom
        ? moment(new Date(person.user.presentFrom)).format("DD.MM.YY H:mm")
        : "";
      person.user.presentFrom = person.user.presentFrom.startsWith(
        this.todaysDate
      )
        ? person.user.presentFrom
        : "";
      return person;
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    loadDataTable() {
      const membersList = this.getMembersSelectedSection;
      const showUsersAvailable = () => {
        let tempAvailable = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (person.user.activity === "Available") {
              const memberData = this.getMemberData(person, uuid);
              tempAvailable.push(memberData.user);
            }
          }
        }
        return tempAvailable;
      };
      const showUsersBusy = () => {
        let tempBusy = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              person.user.activity === "Out of Office" ||
              person.user.activity === "Only phone" ||
              person.user.activity === "No Calls" ||
              person.user.activity === "Break"
            ) {
              const memberData = this.getMemberData(person,uuid);
              tempBusy.push(memberData.user);
            }
          }
        }
        return tempBusy;
      };
      const showUsersCoffeBreak = () => {
        let tempCoffeBreak = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              person.user.activity === "Coffee break" 
            ) {
              const memberData = this.getMemberData(person,uuid);
              tempCoffeBreak.push(memberData.user);
            }
          }
        }
        return tempCoffeBreak;
      };
      const showUsersNotAvailable = () => {
        let tempNotAvailable = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              (person.user.activity === "Busy" ||
                person.user.activity === "In meeting" ||
                person.user.activity === "Holidays" ||
                person.user.activity === "Ill" ||
                person.user.activity === "inRoom" ||
                person.user.activity === "Just urgent calls") &&
              person.connected
            ) {
              const memberData = this.getMemberData(person,uuid);
              tempNotAvailable.push(memberData.user);
            }
          }
        }
        return tempNotAvailable;
      };
      const showUsersConnections = () => {
        let tempConnections = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person.connected && person.user && person.user.presentFrom) {
            if (
              person.user.inBridgeCall ||
              (person.user.rtcCallStatus &&
                person.user.rtcCallStatus.length > 5)
            ) {
              const memberData = this.getMemberData(person,uuid);
              tempConnections.push(memberData.user);
            }
          }
        }
        return tempConnections;
      };
      const showUsersOffline = () => {
        let tempOffline = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person && !store.getUserIsGuest(uuid) && person.user && (!person.connected || !person.user.presentFrom || person.user.activity === "No status")) {
            const memberData = this.getMemberData(person, uuid);
            tempOffline.push(memberData.user);
          }
        }
        return tempOffline;
      };
      const showUsersList = () => {
        let tempTotal = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (person && !store.getUserIsGuest(uuid) && person.user) {
            const memberData = this.getMemberData(person, uuid);
            tempTotal.push(memberData.user);
          }
        }
        return tempTotal;
      };
      const showUsersInHolidays = () => {
        let tempOffline = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (
            person.user &&
            person.user.startHolidays &&
            person.user.endHolidays &&
            (moment(new Date(person.user.endHolidays)).isSame(
              new Date(),
              "day"
            ) ||
              !moment(new Date(person.user.endHolidays)).isBefore(
                new Date(),
                "day"
              ))
          ) {
            const memberData = this.getMemberData(person,uuid);
            tempOffline.push(memberData.user);
          }
        }
        tempOffline.sort(function (a, b) {
          let aDate = new Date(a.startHolidays);
          let bDate = new Date(b.startHolidays);
          return aDate < bDate
            ? -1
            : aDate > bDate
            ? 1
            : (a.uuid || "").localeCompare(b.uuid || "");
        });
        return tempOffline;
      };
      const showUsersInIllness = () => {
        let tempOffline = [];
        for (let uuid in membersList) {
          let person = membersList[uuid];
          if (
            person.user &&
            person.user.startIllness &&
            person.user.endIllness &&
            (moment(new Date(person.user.endIllness)).isSame(
              new Date(),
              "day"
            ) ||
              !moment(new Date(person.user.endIllness)).isBefore(
                new Date(),
                "day"
              ))
          ) {
            const memberData = this.getMemberData(person,uuid);
            tempOffline.push(memberData.user);
          }
        }
        tempOffline.sort(function (a, b) {
          let aDate = new Date(a.startIllness);
          let bDate = new Date(b.startIllness);
          return aDate < bDate
            ? -1
            : aDate > bDate
            ? 1
            : (a.uuid || "").localeCompare(b.uuid || "");
        });
        return tempOffline;
      };
      
      switch (this.$route.path) {
        case "/status/available":
          return showUsersAvailable();
        case "/status/not-available":
          return showUsersNotAvailable();
        case "/status/no-call":
          return showUsersBusy();
        case "/status/in-call":
          return showUsersConnections();
        case "/status/offline":
          return showUsersOffline();
        case "/status/total":
          return showUsersList();
        case "/status/holidays":
          return showUsersInHolidays();
        case "/status/illness":
          return showUsersInIllness();
        case "/status/coffee-break":
          return showUsersCoffeBreak(); 
        case "/status/in-room":
          return showUsersConnections();
        default:
          break;
      }
    },
    sectionsData() {
      // return Object.keys((this.state.namespaceSettings.processedOrganisation || {}).sections || [])
      const organigram = JSON.parse(
        JSON.stringify(this.state.namespaceSettings.newOrganigram || [])
      );
      const organigramTeams = getOrganigramTeams(organigram);
      return organigramTeams;
    },
    getMembersSelectedSection() {
      let finalMembersList = [];
      if (this.selectedSection == 'intern') {
        const filterUser = Object.values(this.state.group).filter((e) =>(e.user.intern && e.user.uuid));
        let obj = {}
        for (const key in filterUser) {
          if (Object.hasOwnProperty.call(filterUser, key)) {
            const element = filterUser[key];
            obj[element.user.uuid] = element
          }
        }
        finalMembersList = obj
      } else if(this.selectedSection === '' || this.selectedSection == 'allSections' || this.selectedSection === undefined || !this.sectionsData.length) {
        // return all users if there is no section selected
        finalMembersList = this.state.group;
      } else {
        const organigram = JSON.parse(
          JSON.stringify(this.state.namespaceSettings.newOrganigram || [])
        );
        const organigramTeams = getOrganigramTeams(organigram);
        let objUserData = {}
        let membersList = [];
        const selectedTableTeam = this.sectionsData[this.selectedSection].name
        const selectedTeam = organigramTeams.find(e=>e.name === selectedTableTeam)
        if(selectedTeam) {
          const members = selectedTeam.children.map((e) => e.uuid)
            if (members.length > 0) {
              members.forEach(memberUUID => {

                  if(membersList.indexOf(memberUUID) ==-1 && this.state.group[memberUUID]){
                    membersList.push(memberUUID)
                    objUserData[memberUUID] = this.state.group[memberUUID];
                  }
              });
            }
        }
        // // start loop into section teams to search the members
        // for (let i = 0; i < organigramTeams.length; i++) {
        //    const team = organigramTeams[i];
        //   if (team) {
        //     const members = team.children.map((e) => e.uuid)
        //     if (members.length > 0) {
        //       members.forEach(memberUUID => {

        //           if(membersList.indexOf(memberUUID) ==-1 && this.state.group[memberUUID]){
        //             membersList.push(memberUUID)
        //             objUserData[memberUUID] = this.state.group[memberUUID];
        //           }
        //       });
        //     }
        //   }
        // }
        finalMembersList = objUserData;
        // let membersList = [];
        // const getSelectedSection = Object.keys(this.state.namespaceSettings.processedOrganisation.sections)[this.selectedSection]
        // const section = this.state.namespaceSettings.processedOrganisation
        //   .sections[getSelectedSection];
        // const sectionTeams = section.teams;
        // const teams = this.state.namespaceSettings.processedOrganisation.teams;
        // let objUserData = {}
        // // start loop into section teams to search the members
        // for (let i = 0; i < sectionTeams.length; i++) {
        //   const team = sectionTeams[i];
        //   const members = teams[team].members;
        //   if (members.length > 0) {
        //     members.forEach(memberUUID => {

        //         if(membersList.indexOf(memberUUID) ==-1 && this.state.group[memberUUID]){
        //           membersList.push(memberUUID)
        //           objUserData[memberUUID] = this.state.group[memberUUID];
        //         }
        //     });
        //   }
        // }
        // finalMembersList = objUserData;
      }
      return finalMembersList;
    },
    headers() {
      return [
        {
          text: this.$t("literals.Picture"),
          value: "avatar",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.nachricht"),
          value: "sendMessage",
          sortable: false,
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.name"),
          value: "nametitel",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.urlaub"),
          value: "holidays",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.illness"),
          value: "illness",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.E-Mail"),
          value: "email",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("components.statusContainer.telefon"),
          value: "phone",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("literals.Function"),
          value: "position",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("literals.loginday"),
          value: "presentFrom",
          class: "font-weight-bold subtitle-1",
        },
        {
          text: this.$t("literals.Location"),
          value: "location",
          class: "font-weight-bold subtitle-1",
        },
      ];
    },
    getStatusType() {
      let type = "";
      switch (this.$route.path) {
        case "/status/available":
          type = this.$t("generics.available");
          break;
        case "/status/not-available":
          type = this.$t("components.navbar.statusMaybeAvailable");
          break;
        case "/status/no-call":
          type = this.$t("generics.noCall");
          break;
        case "/status/in-call":
          type = this.$t("generics.inCall");
          break;
        case "/status/offline":
          type = this.$t("generics.offline");
          break;
        case "/status/total":
          type = this.$t("generics.total");
          break;
        case "/status/holidays":
          type = this.$t("status.Holidays");
          break;
        case "/status/illness":
          type = this.$t("status.Ill");
          break;
        case "/status/coffee-break":
          type = this.$t("status.Coffee break");
          break;
        case "/status/in-room":
          type = this.$t("status.inRoom");
          break;
        default:
          type = "";
          break;
      }
      return type;
    },
    todaysDate() {
      return moment(new Date(this.state.currentTS)).format("DD.MM.YY");
    },
  },
};
</script>
<style scoped lang="scss">
.listWrapper {
  overflow-y: auto;
  min-height: calc(100vh - 25vh);
  max-height: calc(100vh - 25vh);
  height: calc(100vh - 25vh);
}
</style>