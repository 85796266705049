<template>
  <div :class="[
    isDarkMode ? 'bg-dark' : 'bg-light',
  ]" class="d-flex flex-column pa-3 height-custom overflow-auto pt-6">
  <SocialMediaProfileContent  v-if="isProfile"/>
  <SocialMediaContent v-else/>
  <PostPreview :postPreviewData="postPreviewData"/>
  <AgreementModal v-if="agreementModalData.show" :agreementModalData="agreementModalData" :closeAgreementModal="closeAgreementModal"/>
  </div>
</template>


<script>
import store, {EventBus} from "../../../store";
import SocialMediaContent from "./socialMediaContent.vue";
import SocialMediaProfileContent from "./socialMediaProfileContent.vue";
import PostPreview from "./common/postPreview.vue";
import AgreementModal from "./common/agreementModal.vue"
// const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: { SocialMediaContent, SocialMediaProfileContent, PostPreview, AgreementModal },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      selectedTab: 0,
      postPreviewData: {
        show: false,
        postId: null
      },
      agreementModalData: {
        show: false,
        userUuid: null
      }
    };
  },
  mounted() {
    EventBus.$on('previewPost', this.previewPost);
    EventBus.$on('openUserAgreement', this.openAgreementModal);
    this.checkAgreementStatus();  // Check agreement status when the component is mounted
  },
  unmounted() {
    EventBus.$off('previewPost', this.previewPost);
    EventBus.$off('openUserAgreement', this.openAgreementModal);
  },
  methods: {
    previewPost(data){
      this.postPreviewData = data;
    },
    closeAgreementModal() {
      this.agreementModalData = {
        show: false,
        userUuid: null
      }
    },
    openAgreementModal(){
      this.agreementModalData = {
            show: true,
            userUuid: this.ownUUID
          };
    },
    async checkAgreementStatus() {
      try {
        // Replace 'userUuid' with the actual user ID from your app
        const userUuid = this.ownUUID

        const response = await fetch(`/api/socialmedia/is-agreement-accepted?uuid=${userUuid}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        });

        const result = await response.json();

        if (!result.success || !result.agreementAccepted) {
          this.openAgreementModal();
        }
      } catch (error) {
        console.error("Error checking agreement status:", error);
      }
    }
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    isProfile() {
      return this.$route.params.profile;
    },
  },
};
</script>

<style scoped lang="scss">
.bg-light {
  background-color: var(--v-primary-100);
}

.bg-dark {
  background-color: var(--v-primary-900);
}

.height-custom {
  height: calc(100% + 85px);
}
</style>
