var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _c(
        "v-list-group",
        {
          attrs: { color: "white", value: _vm.isActive },
          on: {
            click: function($event) {
              return _vm.setList("stats")
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "primary--text-sidepanel",
                        attrs: { icon: ["fab", "git"] }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "primary--text-sidepanel",
                      on: {
                        click: function($event) {
                          return _vm.setCurrentContentVisile(
                            "",
                            false,
                            _vm.$router
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("generics.gitStats")))]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("span", { attrs: { id: "statusUUID" } }, [
                _vm._v("UUID: " + _vm._s(_vm.state.ownUUID))
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", { attrs: { id: "statusTS" } }, [
                _vm._v(_vm._s(_vm.currentTSText))
              ])
            ]),
            _vm._v(" "),
            _vm.showStatusGit
              ? _c("li", [
                  _c("span", { attrs: { id: "statusGit" } }, [
                    _vm._v(
                      '\n             "' +
                        _vm._s(_vm.state.gitCommit.subject) +
                        '" (' +
                        _vm._s(_vm.state.gitCommit.shortHash) +
                        ")"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("~ " + _vm._s(_vm.state.gitCommit.author))
                    ]),
                    _vm._v(
                      "\n             " +
                        _vm._s(
                          _vm
                            .moment(
                              parseInt(_vm.state.gitCommit.committedOn + "000")
                            )
                            .from(_vm.state.currentTS)
                        )
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _vm.state.gitCommit.dirty
                      ? _c("span", { staticClass: "gitDirty" }, [
                          _vm._v(
                            "You might need to reload the page to be up to date!"
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }