<template>
  <v-list
    two-line
    v-if="messagesArray.length !== 0"
    :min-height="310"
    style="overflow: hidden;height: 370px;"
  >
    <div class="vueScroll">
      <v-list-item-group active-class="primary--text">
        <div v-for="(message, index) in messagesArray" :key="index">
          <template v-for="(individual, i) in message">
            <v-list-item
              :key="i"
              v-if="(individual || {}).info"
              @click="
                setShowModalNotify(message.userUUID, {
                  id: message.uuidMessage,
                  type: individual.notificationType,
                  notification: message.info,
                  date: message.date,
                });
                handleTotalNotifications(message, individual.notificationType);
              "
              :class="message.read === false ? 'notiUnread' : ''"
            >
              <template v-slot:default="{}">
                <div class="d-flex py-2">
                  <v-avatar
                    tile
                    size="60"
                    class="borderRadius10 cursorPointer"
                    :style="getBorderByStatusFull(message.userUUID, '2px')"
                  >
                    <DefaultAvatarGrid
                      :size="120"
                      :userData="getUserData(message.userUUID)"
                      :isLateralPanel="true"
                      v-if="
                        getAvatarForUuid(message.userUUID) ==
                        'img/default_profile_picture.png'
                      "
                    ></DefaultAvatarGrid>
                    <v-img
                      v-if="
                        getAvatarForUuid(message.userUUID) !=
                        'img/default_profile_picture.png'
                      "
                      class="mx-2 borderRadius10"
                      max-height="60"
                      max-width="60"
                      contain
                      :src="getAvatarForUuid(message.userUUID)"
                    ></v-img>
                  </v-avatar>
                  <div class="h100 ml-2" style="width: 131px;">
                    <v-list-item-title
                      class="mt-2"
                      :class="
                        individual.notificationType === 'message' && !message.read
                          ? 'font-weight-bold textBlue'
                          : ''
                      "
                      v-text="getNameForUuid(message.userUUID)"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="
                        individual.subject === 'Incoming call'
                          ? $t('components.notificationsModal.missedCall')
                          : individual.subject || 'New message'
                      "
                    ></v-list-item-subtitle>
                  </div>
                  <v-list-item-action class="d-flex flex-column  align-end justify-end" style="width: 102px;">
                    <v-list-item-action-text class="text-caption mb-7"
                      v-text="formatNoticationDate(message.date)"
                    ></v-list-item-action-text>
                    <div class="d-flex  align-end justify-end" style="position: relative">
                      <DeleteBasket
                        :indexData="[message, individual.notificationType]"
                        :delFunction="handleTotalNotifications"
                        :extraFunction="removeMessage"
                        :extraFunctionParams="[
                          message.userUUID,
                          message.uuidMessage,
                          undefined,
                        ]"
                        extraClasses="mr-4"
                        fontSize="16"
                        prevent="true"
                      />
                      <v-tooltip
                        top
                        v-if="
                          individual.notificationType === 'call' &&
                          !isWaitingRoomUser
                        "
                      >
                        <template v-slot:activator="{ props }">
                          <font-awesome-icon
                            v-bind="props"
                            disabled
                            class="mr-4"
                            :icon="['fal', 'phone']"
                            @click.stop="
                              handleTotalNotifications(
                                message,
                                individual.notificationType
                              );
                              disableCallBtn(message.userUUID)
                                ? ''
                                : callUser(message.userUUID);
                            "
                            :color="
                              !disableCallBtn(message.userUUID)
                                ? setBorderByStatus(state.group[message.userUUID])
                                : 'grey'
                            "
                          ></font-awesome-icon>
                        </template>
                        <span>{{
                          disableCallBtn(message.userUUID)
                            ? "Dieser Benutzer ist zur Zeit offline"
                            : $t("components.userProfile.callUser")
                        }}</span>
                      </v-tooltip>
                    </div>
                  </v-list-item-action>
                </div>
              </template>
            </v-list-item>
            <v-divider v-if="i + 1 < messagesArray.length" :key="i"></v-divider>
          </template>
        </div>
      </v-list-item-group>
    </div>
    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          style="float: right; margin: 5px 5px 0px 0px"
          class="btnRed"
          icon
          color="white"
          variant="text"
        >
          <DeleteBasket
            indexData=""
            :delFunction="removeAllListedItems"
            extraClasses=""
            fontSize="20"
            prevent="false"
          />
        </v-btn>
      </template>
      <span>{{ $t("components.downloadsDropdown.removeAll") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import { callPerson, disableCallBtn } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import { isWaitingRoomUser } from "../../utils/privileges";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
export default {
  props: ["notificationType", "closeMenuMissedCalls"],
  components: { DefaultAvatarGrid, DeleteBasket },
  data() {
    return {
      state: store.state,
      persisted: store.state.persisted,
      moment: moment,
      user: store.state.user,
      ownUUID: store.state.ownUUID,
    };
  },
  methods: {
    getUserData(uuid){
      if ( this.state.group[uuid] ){
        return this.state.group[uuid]
      } else if ( this.state.persisted.superGroup[uuid] ){
        return this.state.persisted.superGroup[uuid]
      }
      return {};
    },
    getBorderByStatusFull(uuid, customCss) {
      const person = {
        user: this.state.group[uuid]?.user || this.state.persisted.superGroup[uuid]
      };
      return getBorderByStatusFull(person, customCss);
    },
    formatNoticationDate(date) {
      return (
        this.moment(date).format("DD.MM.YYYY, H:mm")
      //   " " +
      //   this.$t("components.meetingLineModal.hours")
      );
    },
    callUser(uuid) {
      this.closeMenuMissedCalls();
      callPerson(uuid, this.$router);
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    handleTotalNotifications(message, type = "unknown") {
      if (type !== "unknown") {
        switch (type) {
          case "message":
            this.setTotalNotificationMessage(message);
            break;
          case "ticket":
            this.setTotalNotificationTicket(message);
            break;
          case "call":
            this.setTotalNotificationCall(message);
            break;
          case "file":
            this.setTotalNotificationFile(message);
            break;
          default:
            break;
        }
      }
    },
    setTotalNotificationCall: function (message) {
      let totalChips = this.state.persisted.totalNotificationCall;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationCall(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    setTotalNotificationTicket: function (message) {
      let totalChips = this.state.persisted.totalNotificationTicket;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationTicket(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    setTotalNotificationFile: function (message) {
      let totalChips = this.state.persisted.totalNotificationFile;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationFile(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    setTotalNotificationMessage: function (message) {
      let totalChips = this.state.persisted.totalNotificationMessage;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationMessage(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    removeMessage: function (...args) {
      store.removeMessage(...args);
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    setShowModalNotify: function (...args) {
      store.setShowModalNotify(...args);
    },
    settotalNotification: function (...args) {
      store.settotalNotification(...args);
    },
    removeAllListedItems: function (...args) {
      if (this.messagesArray.length > 0) {
        if (this.messagesArray[0].info !== undefined) {
          const type = this.messagesArray[0].info.notificationType;
          this.messagesArray.forEach((message) => {
            if (message.info.notificationType === type) {
              this.removeMessage(
                message.userUUID,
                message.uuidMessage,
                undefined
              );
              if (type !== "unknown" && type !== undefined) {
                switch (type) {
                  case "message":
                    store.setTotalNotificationMessage(0);
                    break;
                  case "ticket":
                    store.setTotalNotificationTicket(0);
                    break;
                  case "call":
                    store.setTotalNotificationCall(0);
                    break;
                  default:
                    break;
                }
              }
            }
          });
        }
      }
    },
    disableCallBtn(uuid) {
      let person = this.state.group[uuid];
      if ( person ){
        person.uuid = uuid;
        return disableCallBtn(person);
      }
      return true;
    },
  },
  computed: {
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    messagesArray: function () {
      const messages = [];
      const messagesRead = [];
      const filteredReadMessages = [];
      const aMonthAgo = Date.now() - 2592000000; // minus 30d
      const messagesToDelete = [];
      let readMssgCounter = 0;
      let backendType = "";
      if (this.notificationType == "missedCalls") {
        backendType = "call";
      } else if (this.notificationType == "incomingTickets") {
        backendType = "ticket";
      } else if (this.notificationType === "messages") {
        backendType = "message";
      } else if (this.notificationType === "uploads") {
        backendType = "upload";
      }
      for (let userUUID in store.state.persisted.userMessages.messages) {
        const userMessages =
          store.state.persisted.userMessages.messages[userUUID];
        if (!userMessages) continue;
        for (let msgUUID in userMessages) {
          const date = userMessages[msgUUID].date;
          const notificationType = userMessages[msgUUID].info.notificationType;
          if (notificationType === backendType) {
            if (
              (this.state.group[this.ownUUID] && !this.state.group[userUUID] && !this.state.persisted.superGroup[userUUID]) || // notification from deleted user
              (backendType === "call" && date < aMonthAgo)// old missed call notification
            ) {
              messagesToDelete.push({
                ...userMessages[msgUUID],
                userUUID,
                date,
              });
            } else if (
              notificationType === "message" &&
              userMessages[msgUUID].read === true
            ) {
              messagesRead.push({ ...userMessages[msgUUID], userUUID, date });
            } else {
              messages.push({ ...userMessages[msgUUID], userUUID, date });
            }
          }
        }
      }
      messages.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate > bDate
          ? -1
          : aDate < bDate
          ? 1
          : (a.uuid || "").localeCompare(b.uuid || "");
      });
      messagesRead.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate > bDate
          ? -1
          : aDate < bDate
          ? 1
          : (a.uuid || "").localeCompare(b.uuid || "");
      });
      messagesRead.forEach((element) => {
        if (element.read === true) {
          ++readMssgCounter;
          if (readMssgCounter <= 3) {
            filteredReadMessages.push(element);
          } else {
            // remove read if more than 3
            messagesToDelete.push(element);
          }
        }
      });
      const allMessages = messages.concat(filteredReadMessages);
      messagesToDelete.forEach((element) => {
        store.removeMessage(element.userUUID, element.uuidMessage);
      });
      return allMessages;
    },
  },
};
</script>
<style lang="scss">
.v-img__img--preload {
  filter: blur(1px) !important;
}
</style>
<style scoped lang="scss">
.v-img__img--preload {
  filter: blur(1px) !important;
}
.textBlue {
  color: #2a3133;
}
.btnRed {
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.vueScroll {
  overflow: auto;
  height: 310px;
  .__view {
    width: unset !important;
  }
}
</style>