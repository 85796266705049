var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "localButtonsBar myLocal" },
    [
      _c(
        "div",
        { staticClass: "leftLocalBar" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "darkGreyIcon pAbsoluteLeft",
                            attrs: { large: "", icon: "", color: "white" },
                            on: { click: _vm.openCloseChat }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "comments"] }
                          }),
                          _vm._v(" "),
                          _vm.hasUnreadChatMessages
                            ? _c("v-badge", {
                                staticClass: "docsBadgeDots",
                                attrs: {
                                  right: "",
                                  color: "error",
                                  overlap: "",
                                  "offset-x": "25",
                                  "offset-y": "3",
                                  content: _vm.totalUnreadChatMessages,
                                  value: _vm.hasUnreadChatMessages
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.callsContent.openGroupChat")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "darkGreyIcon pAbsoluteLeftUserList",
                            attrs: { large: "", icon: "", color: "white" },
                            on: { click: _vm.toggleUserList }
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "users"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.adminSettingsModal.userInfo")))
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rightLocalBar" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "toggleMuteAudio darkGreyIcon",
                            class: {
                              "mx-3": _vm.isMobile,
                              "mx-6": !_vm.isMobile
                            },
                            attrs: {
                              large: "",
                              icon: "",
                              color: "white",
                              disabled: !_vm.videoReady
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleMuteVideoFunction()
                              }
                            }
                          },
                          on
                        ),
                        [
                          !_vm.audioOutputMuted && _vm.videoReady
                            ? _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "volume-up"] }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.audioOutputMuted && _vm.videoReady
                            ? _c("img", {
                                staticClass: "volume-up-slash",
                                attrs: {
                                  src: "/img/volume-slash-red-white.svg"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.videoReady
                            ? _c("img", {
                                staticClass: "volume-up-slash",
                                attrs: {
                                  src: "/img/volume-slash-red-white-grey.svg"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.audioOutputMuted
                      ? _vm.$t(
                          "components.callsContent.unmuteSoundAllParticipants"
                        )
                      : _vm.$t("components.callsContent.muteAllParticipants")
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          !_vm.isMobile
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "darkGreyIcon",
                                  class: {
                                    "mx-3": _vm.isMobile,
                                    "mx-6": !_vm.isMobile
                                  },
                                  attrs: {
                                    large: "",
                                    icon: "",
                                    color: "white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleLocalFullScreen()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "expand-wide"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3504581849
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        !_vm.fullScreenActive
                          ? _vm.$t("generics.fullscreen")
                          : _vm.$t("generics.normalScreen")
                      ) + "\n      "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMobile
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "darkGreyIcon",
                                  class: {
                                    "mx-3": _vm.isMobile,
                                    "mx-6": !_vm.isMobile
                                  },
                                  attrs: {
                                    large: "",
                                    icon: "",
                                    color: "white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleRequestToSpeakConferenceHall()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                !_vm.requestedRaiseHand
                                  ? _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "hand-point-up"] }
                                    })
                                  : _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fas", "hand-point-up"] }
                                    })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2431407663
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.requestedRaiseHand
                            ? _vm.$t("components.callsContent.requestWord")
                            : _vm.$t("components.callsContent.endRequestWord")
                        ) +
                        " \n      "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "red",
                            class: {
                              "mx-3": _vm.isMobile,
                              "mx-6": !_vm.isMobile
                            },
                            attrs: {
                              large: "",
                              icon: "",
                              color: "white",
                              loading: _vm.rejectingCall,
                              disabled: _vm.rejectingCall
                            },
                            on: {
                              click: function($event) {
                                return _vm.rejectCallFromLocal()
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "loader",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "custom-loader" },
                                        [
                                          _c("font-awesome-icon", {
                                            style: {
                                              fontSize: "15px",
                                              color: "white"
                                            },
                                            attrs: {
                                              icon: ["fal", "spinner-third"]
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          on
                        ),
                        [
                          _c("font-awesome-icon", {
                            staticClass: "faPhoneRotate",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "phone"] }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pAbsoluteRight" },
            [
              _c(
                "v-menu",
                {
                  staticClass: "customMenuLocal",
                  attrs: {
                    "content-class": "customDotsMenuLocal",
                    right: "",
                    "offset-x": true,
                    "close-on-content-click": true,
                    "z-index": "999999",
                    attach: !_vm.isMobile ? ".myLocal" : false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "buttonsCall darkGreyIcon",
                                  class: { "mx-6": !_vm.isMobile },
                                  attrs: {
                                    large: "",
                                    color: "white",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "ellipsis-v"] }
                              }),
                              _vm._v(" "),
                              _vm.hasDocuments
                                ? _c("v-badge", {
                                    staticClass: "docsBadgeDots",
                                    attrs: {
                                      right: "",
                                      color: "error",
                                      overlap: "",
                                      "offset-x": "25",
                                      "offset-y": "3",
                                      content: _vm.getTotalDocumentsRead,
                                      value: _vm.getTotalDocumentsRead
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menuOpen,
                    callback: function($$v) {
                      _vm.menuOpen = $$v
                    },
                    expression: "menuOpen"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    { staticClass: "text-left", attrs: { dark: "" } },
                    [
                      !_vm.fullScreenActive
                        ? _c(
                            "v-list-item",
                            { on: { click: _vm.toggleCamMic } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "cog"] }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("components.navbar.camAndMic")
                                    ) +
                                    "\n            "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          attrs: { disabled: !_vm.hasDocuments },
                          on: {
                            click: function($event) {
                              return _vm.openConferenceDocumentsModal()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "file-upload"] }
                              }),
                              _vm._v(" "),
                              _vm.hasDocuments
                                ? _c("v-badge", {
                                    staticClass: "docsBadge",
                                    attrs: {
                                      right: "",
                                      color: "error",
                                      overlap: "",
                                      "offset-x": "25",
                                      "offset-y": "3",
                                      content: _vm.getTotalDocumentsRead,
                                      value: _vm.getTotalDocumentsRead
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "components.callsContent.documentManagementShare"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showConferenceDocumentsModal
        ? _c("DocumentsManager", {
            attrs: {
              showModal: _vm.showConferenceDocumentsModal,
              eventType: _vm.isConferenceCall ? "conference" : "uuid",
              documentsStore: _vm.isConferenceCall
                ? _vm.conferenceStore
                : _vm.callStore,
              amIModerator: _vm.getAmIModerator,
              attachTo: "iframe",
              setTempDocStore: _vm.setTempDocStore,
              isConferenceCall: _vm.isConferenceCall
                ? _vm.isConferenceCall
                : false
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }