var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "460" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.stats"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pa-4 pb-0" },
            _vm._l(_vm.getCallStatistics, function(item) {
              return _c(
                "p",
                { key: item.ts, staticClass: " text-left subtitle-1" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(item.tsDate) +
                      ":\n        " +
                      _vm._s(item.rating ? item.rating.toFixed(2) : 5) +
                      "\n        "
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "qualityStar",
                    style: { fontSize: "24px" },
                    attrs: { icon: ["fas", "star"] }
                  })
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.closeModal, showClose: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }