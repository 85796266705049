var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12" } },
            [
              _c("v-checkbox", {
                staticClass: "mt-0 pt-1",
                attrs: { dense: "" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "components.companyRegister.isRamicroCustomer"
                              )
                            )
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.companyRegisterObject.isRamicroCustomer,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.companyRegisterObject,
                      "isRamicroCustomer",
                      $$v
                    )
                  },
                  expression: "companyRegisterObject.isRamicroCustomer"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }