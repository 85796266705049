var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dayTimeOverlay" }, [
    _c("span", { staticClass: "mx-2" }, [
      _vm._v(
        _vm._s(_vm.$t("components.conferenceForm.startDate")) +
          ": " +
          _vm._s(_vm.formatDate)
      )
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "mx-2" }, [
      _vm._v(
        _vm._s(_vm.$t("components.conferenceForm.startTime")) +
          ": " +
          _vm._s(_vm.formatTime)
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }