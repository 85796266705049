var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tableMenu pa-1" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            { attrs: { icon: "" }, on: { click: _vm.addRow } },
                            on
                          ),
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "18px" },
                              attrs: { icon: ["fal", "plus"] }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.excelEditor.addRow")))
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tableSearch ml-2" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search"
                  }
                ],
                ref: "searchInput",
                attrs: { placeholder: _vm.$t("generics.search") },
                domProps: { value: _vm.search },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "icon" },
                [
                  _c("font-awesome-icon", {
                    style: { fontSize: "15px" },
                    attrs: { icon: ["far", "search"] }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("v-data-table", {
        ref: "userTable",
        staticClass: "elevation-0 cursorPointer userTable",
        attrs: {
          headers: _vm.headers,
          items: _vm.loadDataTable,
          "items-per-page": 10,
          search: _vm.search,
          page: _vm.page,
          "fixed-header": "",
          height: "calc(100vh - 395px)",
          "single-select": "",
          "item-key": "uuid"
        },
        on: {
          "page-count": function($event) {
            _vm.pageCount = $event
          },
          "update:items-per-page": function($event) {
            10
          },
          "click:row": _vm.setUser
        },
        scopedSlots: _vm._u([
          {
            key: "item.avatar",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-avatar",
                  {
                    staticClass: "profile my-2",
                    attrs: { color: "grey", size: "40", tile: "" }
                  },
                  [
                    item.avatar &&
                    item.avatar !== "img/default_profile_picture.png"
                      ? _c("v-img", {
                          attrs: {
                            src:
                              item.avatar || "img/default_profile_picture.png",
                            "lazy-src": "img/default_profile_picture.png"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !item.avatar ||
                    item.avatar === "img/default_profile_picture.png"
                      ? _c("DefaultAvatar", {
                          attrs: { userData: item, lowerText: true }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.tableSelectedRows,
          callback: function($$v) {
            _vm.tableSelectedRows = $$v
          },
          expression: "tableSelectedRows"
        }
      }),
      _vm._v(" "),
      _vm.showCloseDetailModal
        ? [
            _c("CloseManagementDetailModal", {
              attrs: {
                setNewUser: _vm.setNewUser,
                acceptCloseModal: _vm.acceptCloseModal,
                showModal: _vm.showCloseDetailModal,
                closeModal: _vm.openCloseDetailModal
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }