var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "w-100 mx-0 my-0 justify-space-between",
              attrs: { flex: "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "px-0 py-0", attrs: { align: "left" } },
                [
                  _c("HeaderModal", {
                    attrs: {
                      titleModal: _vm.$t(
                        "components.userListItem.displayAssistants"
                      ),
                      closeModalFunction: _vm.closeModal
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", color: "black" },
                                      on: { click: _vm.closeModal }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "25px" },
                                      attrs: { icon: ["fal", "times"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "mx-auto" },
            [
              _c(
                "v-row",
                { staticClass: "mx-auto", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", align: "center" } },
                    [
                      _c("v-switch", {
                        staticClass: "my-0 py-2",
                        attrs: {
                          label: _vm.$t(
                            "components.userListItem.displayAssistantOption"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeAssistantDisplay()
                          }
                        },
                        model: {
                          value: _vm.displayAssistant,
                          callback: function($$v) {
                            _vm.displayAssistant = $$v
                          },
                          expression: "displayAssistant"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal, showCancel: true } },
            [
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.closeModal } },
                [_vm._v(_vm._s(_vm.$t("generics.accept")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }