var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "580" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showSendMessageBeforeConferenceModal.show,
        callback: function($$v) {
          _vm.$set(_vm.showSendMessageBeforeConferenceModal, "show", $$v)
        },
        expression: "showSendMessageBeforeConferenceModal.show"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c(
              "HeaderModal",
              {
                attrs: {
                  titleModal: _vm.$t(
                    "components.groups.sendMessageToAllParticipants"
                  ),
                  closeModalFunction: _vm.closeModal
                }
              },
              [_c("v-spacer")],
              1
            ),
            _vm._v(" "),
            _c(
              "v-card-text",
              { staticClass: "py-0 pt-4" },
              [
                _c("v-textarea", {
                  attrs: {
                    counter: "",
                    contenteditable: "true",
                    label: _vm.$t("components.sendMessageModal.message"),
                    rules: _vm.rules,
                    "no-resize": true,
                    maxlength: "250",
                    autofocus: "",
                    id: "messageTextarea"
                  },
                  model: {
                    value: _vm.messageText,
                    callback: function($$v) {
                      _vm.messageText = $$v
                    },
                    expression: "messageText"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.closeModal, showCancel: true }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      disabled: _vm.messageText.length == 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.sendMessage()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("components.sendMessageModal.send")))]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }