var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { id: "v-tour-userActionBar" }, on: { click: _vm.showStatus } },
    [
      _c(
        "div",
        { staticClass: "d-flex pl-2 pr-4 justify-space-between w100" },
        [
          _c("ReceivedAndSentmessages", {
            attrs: { setTotalNotificationFile: _vm.setTotalNotificationFile }
          }),
          _vm._v(" "),
          _c("Calls", {
            attrs: {
              setTotalNotificationCall: _vm.setTotalNotificationCall,
              totalNotificationCall: _vm.totalNotificationCall,
              phoneColorColor: _vm.phoneColorColor,
              iconsBlack: _vm.iconsBlack
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCustomTT
        ? _c("CustomTT", {
            attrs: {
              text: _vm.$t("components.hotkeys.controlKey") + " + Q",
              position: "bottom",
              extraclass: "ctrq"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }