var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.showFooter
  ? _vm.showFooter
  : true)
    ? _c(
        "v-card",
        { staticClass: "py-2 pl-4 pr-2 footerModal footersBackground" },
        [
          _c(
            "v-row",
            { staticClass: "pl-2 btns w-100 mx-0 my-0" },
            [
              _vm._t("default"),
              _vm._v(" "),
              !_vm.hideDefaultButtons
                ? [
                    _vm.showOkButton
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ml-3 mr-2 greyBtn",
                            staticStyle: {
                              "border-radius": "4px",
                              "min-width": "45px"
                            },
                            attrs: {
                              color: "primary",
                              icon: "",
                              tile: "",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.closeModalFunction()
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "times"] }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCancelButton
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ml-3 mr-2 greyBtn",
                            staticStyle: {
                              "border-radius": "4px",
                              "min-width": "45px"
                            },
                            attrs: {
                              color: "primary",
                              icon: "",
                              tile: "",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.closeModalFunction()
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "times"] }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showClose
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ml-3 mr-2 greyBtn",
                            staticStyle: {
                              "border-radius": "4px",
                              "min-width": "45px"
                            },
                            attrs: {
                              color: "primary",
                              icon: "",
                              tile: "",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.closeModalFunction()
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "times"] }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.closeModalFunction && _vm.crossIcon
                      ? _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ml-3 mr-2 greyBtn",
                              staticStyle: {
                                "border-radius": "4px",
                                "min-width": "45px"
                              },
                              attrs: { color: "primary", icon: "", tile: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModalFunction()
                                }
                              }
                            },
                            _vm.on
                          ),
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "times"] }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }