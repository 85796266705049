var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "px-4" },
    [
      _c(
        "v-col",
        { attrs: { cols: "2" } },
        [
          _c("DailyReportUsersList", {
            attrs: {
              setSelectedUser: _vm.setSelectedUser,
              selectedUser: _vm.selectedUser
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c(
            "div",
            { staticClass: "text-right px-3" },
            [
              _c(
                "v-btn",
                { staticClass: "primary", on: { click: _vm.setDailyReport } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.adminSettingsModal.addDailyReport")
                    )
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("DailyReportsTable", { attrs: { selectedUser: _vm.selectedUser } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }