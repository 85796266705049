var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canInviteGuests || _vm.sortedGuests.length
    ? _c(
        "v-list",
        [
          !_vm.isGuest
            ? _c(
                "v-list-group",
                {
                  attrs: {
                    "active-class": "listColor",
                    value:
                      _vm.state.searchTerm.length > 0
                        ? false
                        : null || _vm.isActive
                  },
                  on: {
                    click: function($event) {
                      return _vm.setList("guests")
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-icon",
                              {
                                class:
                                  "" +
                                  (_vm.getRoute === "/guest"
                                    ? "primary--text"
                                    : "")
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fal", "user-secret"] }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-list-item-title",
                                            _vm._g(
                                              {
                                                class:
                                                  "" +
                                                  (_vm.getRoute === "/guest"
                                                    ? "font-weight-bold primary--text"
                                                    : ""),
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    _vm.setCurrentContentVisile(
                                                      "",
                                                      false,
                                                      _vm.$router
                                                    )
                                                    _vm.handleGuestView()
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.$t("generics.myGuests")
                                                  ) +
                                                  "\n            "
                                              ),
                                              _vm.sortedGuests.length > 0
                                                ? _c("span", [
                                                    _vm._v("\n              ("),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "textColor"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.connectedGuestLen
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "/ " +
                                                        _vm._s(
                                                          _vm.sortedGuests
                                                            .length
                                                        ) +
                                                        ")\n            "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1477954266
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("generics.myGuestsTT")))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.isMobile
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g({}, on),
                                                [
                                                  _c("InviteGuest", {
                                                    attrs: {
                                                      typeInvite: "guest",
                                                      isSidebar: true
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1871868329
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.footernav.inviteGuest"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2798606153
                  )
                },
                [
                  _vm._v(" "),
                  _c("UserList", {
                    attrs: { persons: _vm.sortedGuests, section: "My Guests" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }