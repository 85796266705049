var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("v-card", [
            _c(
              "div",
              [
                _c("HeaderModal", {
                  attrs: {
                    titleModal: _vm.$t("generics.setHomePage"),
                    closeModalFunction: _vm.closeModal
                  }
                }),
                _vm._v(" "),
                _c("v-row", {
                  staticClass: "w-100 mx-0 my-0",
                  attrs: { flex: "" }
                }),
                _vm._v(" "),
                _c(
                  "v-form",
                  {
                    ref: "form",
                    staticStyle: { width: "100%", margin: "0" },
                    attrs: { "lazy-validation": "" }
                  },
                  [
                    _c(
                      "v-row",
                      { staticStyle: { width: "100%", margin: "0" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12 px-2 py-2" },
                          [
                            _c("v-text-field", {
                              staticClass: "mt-2",
                              attrs: {
                                outlined: "",
                                dense: "",
                                label: "URL",
                                "hide-details": "auto"
                              },
                              model: {
                                value: _vm.externalHomePage,
                                callback: function($$v) {
                                  _vm.externalHomePage = $$v
                                },
                                expression: "externalHomePage"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "FooterModal",
                  { attrs: { closeModalFunction: _vm.closeModal } },
                  [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          attrs: { icon: "", color: "white" },
                          on: { click: _vm.setHomePage }
                        },
                        _vm.on
                      ),
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "20px" },
                          attrs: { icon: ["fal", "check"] }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }