var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "paymentModal",
      attrs: {
        persistent: "",
        "max-width": "570",
        "hide-overlay": "",
        attach: "#videos"
      },
      model: {
        value: _vm.showPaymentInProgress,
        callback: function($$v) {
          _vm.showPaymentInProgress = $$v
        },
        expression: "showPaymentInProgress"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.paymentModal.titleRequestPayment"
                ),
                actualDate: _vm.actualDate
              }
            }),
            _vm._v(" "),
            _c("v-card-text", { staticClass: "mt-6 pb-7" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("components.paymentModal.paymentInProgress")) +
                  "\n      "
              )
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }