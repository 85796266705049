<template>
  <div class="statsFontSize mb-0 mt-2" :class="inNavbar ? 'displayInlineFlex':'font-weight-bold' ">
    <template>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            v-show="showUsersAvailableLength > 0"
            class="usersAvailable mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('available')"
            >{{ showUsersAvailableLength }}</span
          >
        </template>
        <span
          >{{ showUsersAvailableLength }}&nbsp;
          {{
            1 >= showUsersAvailableLength
              ? $t("components.navBarNumbers.usersOnline")
              : $t("components.navBarNumbers.usersOnlinePlural")
          }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            v-show="showUsersCoffeBreakLength > 0"
            class="usersCoffeBreak mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('coffee-break')"
            >{{ showUsersCoffeBreakLength }}</span
          >
        </template>
        <span>{{ $t("components.navBarNumbers.usersCoffeBreak") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            v-show="showUsersNotAvailableLength > 0"
            class="usersNotAvailable mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('not-available')"
            >{{ showUsersNotAvailableLength }}</span
          >
        </template>
        <span>{{ $t("components.navbar.statusMaybeAvailable") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            v-show="showUsersBusyLength > 0"
            class="usersBusy mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('no-call')"
            >{{ showUsersBusyLength }}</span
          >
        </template>
        <span>{{ $t("generics.noCall") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            class="usersHolidays mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('holidays')"
            >{{ showUsersHolidaysLength }}</span
          >
        </template>
        <span
          >{{ showUsersHolidaysLength }}&nbsp;{{
            $t("components.navBarNumbers.usersAbsent")
          }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            class="usersIllness mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('illness')"
            >{{ showUsersIllnessLength }}</span
          >
        </template>
        <span
          >{{ showUsersIllnessLength }}&nbsp;{{
            $t("components.navBarNumbers.usersIll")
          }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            v-show="showUsersOfflineLength > 0"
            class="usersOffline mx-2 cursorPointer"
            v-on="on"
            @click="goToStatusPage('offline')"
            >{{ getAdjustOffline }}</span
          >
        </template>
        <span
          >{{ getAdjustOffline }}&nbsp;{{
            $t("components.navBarNumbers.usersOffline")
          }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="showNumbers">
        <template v-slot:activator="{ on }">
          <span
            class="mx-2  cursorPointer black--text"
            v-show="showUsersListLength > 0"
            v-on="on"
            @click="goToStatusPage('total')"
          >
            {{ showUsersListLength }}
          </span>
        </template>
        <span
          >{{ showUsersListLength }}&nbsp;{{
            $t("components.navBarNumbers.usersRegistered")
          }}</span
        >
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
            class="usersConnections mx-2 cursorPointer"
            :class="isDark? 'usersConnectionsWhite': 'usersConnections'"
            @click="goToStatusPage('in-call')"
            >{{ showTotalCall }} / {{ showTotalCallToday }}</span
          >
        </template>
        <span>{{ $t("generics.inCallTT") }}</span>
      </v-tooltip>
    </template>
  </div>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { checkIsDateInRange } from "../../utils/basicFunctions";
export default {
  props:['inNavbar'],
  data() {
    return {
      state: store.state,
      moment: moment,
    };
  },
  methods: {
    getUserIsGuest(uuid){
      return store.getUserIsGuest(uuid)
    },
    goToStatusPage(item) {
      store.setCurrentContentVisile("status/" + item, true, this.$router);
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    hasReturnToCall(){
       return this.state.currentContentVisile.showComponent &&
        (this.state.user.rtcCallStart ||
          Object.keys(this.state.remoteBridgeStreams).length > 0 ||
          (this.state.user.inBridgeCall &&
          this.state.user.inBridgeCall.length > 0));
    },
    showNumbers() {
      const { lg, md, sm, xs } = this.$vuetify.breakpoint;
      return (md || sm || xs) ? false : !(lg && this.hasReturnToCall);
    },
    getAdjustOffline() {
      const totalUserWithStatus =
        this.showUsersAvailableLength +
        this.showUsersBusyLength +
        this.showUsersHolidaysDisconnectedLength +
        this.showUsersIllnesssDisconnectedLength +
        this.showUsersNotAvailableLength +
        this.showUsersCoffeBreakLength;
      const totalUser = this.showUsersListLength;
      return Math.max(0, totalUser - totalUserWithStatus);
    },
    showUsersAvailableLength() {
      let tempAvailable = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (person.user.activity === "Available") {
            tempAvailable++;
          }
        }
      }
      return tempAvailable;
    },
    showUsersCoffeBreakLength() {
      let tempBusy = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            person.user.activity === "Coffee break"
          ) {
            tempBusy++;
          }
        }
      }
      return tempBusy;
    },
    showUsersBusyLength() {
      let tempBusy = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            person.user.activity === "Out of Office" ||
            person.user.activity === "Only phone" ||
            person.user.activity === "No Calls" ||
            person.user.activity === "Break"
          ) {
            tempBusy++;
          }
        }
      }
      return tempBusy;
    },
    showUsersHolidaysLength() {
      let tempHoliday = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.user) {
          if (person.user.startHolidays && person.user.endHolidays) {
            let startDate = new Date(person.user.startHolidays);
            let endDate = new Date(person.user.endHolidays);
            let nowDate = new Date();
            if (checkIsDateInRange(startDate, endDate, nowDate)) {
              tempHoliday++;
            }
          }
        }
      }
      return tempHoliday;
    },
    showUsersIllnessLength(){
      let tempIllness = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.user) {
          if (person.user.startIllness && person.user.endIllness) {
            let startDate = new Date(person.user.startIllness);
            let endDate = new Date(person.user.endIllness);
            let nowDate = new Date();
            if (checkIsDateInRange(startDate, endDate, nowDate)) {
              tempIllness++;
            }
          }
        }
      }
      return tempIllness;
    },
    showUsersHolidaysDisconnectedLength() {
      let tempHoliday = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.user) {
          if (person.user.startHolidays && person.user.endHolidays) {
            let startDate = new Date(person.user.startHolidays);
            let endDate = new Date(person.user.endHolidays);
            let nowDate = new Date();
            if (person.connected && person.user && person.user.presentFrom) {
              continue;
            }
            if (checkIsDateInRange(startDate, endDate, nowDate)) {
              tempHoliday++;
            }
          }
        }
      }
      return tempHoliday;
    },
    showUsersIllnesssDisconnectedLength() {
      let tempIllness = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.user) {
          if (person.user.startIllness && person.user.endIllness) {
            let startDate = new Date(person.user.startIllness);
            let endDate = new Date(person.user.endIllness);
            let nowDate = new Date();
            if (person.connected && person.user && person.user.presentFrom) {
              continue;
            }
            if (checkIsDateInRange(startDate, endDate, nowDate)) {
              tempIllness++;
            }
          }
        }
      }
      return tempIllness;
    },
    showUsersNotAvailableLength() {
      let tempNotAvailable = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            (person.user.activity === "Busy" ||
              person.user.activity === "In meeting" ||
              person.user.activity === "Holidays" ||
              person.user.activity === "Ill" ||
              person.user.activity === "inRoom" ||
              person.user.activity === "Just urgent calls") &&
            person.connected
          ) {
            tempNotAvailable++;
          }
        }
      }
      return tempNotAvailable;
    },
    showUsersOfflineLength() {
      let tempOffline = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (
          (person.user &&
          (!person.connected || !person.user.presentFrom) &&
          (person.user.activity !== "Holidays" || person.user.activity !== "Ill")) || (person.user && person.user.activity=="No status")
        ) {
          tempOffline++;
        }
      }
      return tempOffline;
    },
    showUsersListLength() {
      const users = Object.keys(store.state.group);
      if (!users || !users.length) return 0;
      return users.filter((e)=>!this.getUserIsGuest(e)).length;
    },
    showTotalCall() {
      return (
        Object.keys(store.state.group)
          .filter(
            (uuid) =>
              store.state.group[uuid] &&
              store.state.group[uuid].connected &&
              store.state.group[uuid].user &&
              (store.state.group[uuid].user.rtcCallStatus || "").length
          )
          .map((uuid) =>
            (store.state.group[uuid].user.rtcCallStatus + ":" + uuid)
              .split(":")
              .sort()
              .join(":")
          )
          .filter((v, i, a) => a.indexOf(v) === i).length +
        Object.keys(store.state.group)
          .filter(
            (uuid) =>
              store.state.group[uuid] &&
              store.state.group[uuid].connected &&
              store.state.group[uuid].user &&
              (store.state.group[uuid].user.inBridgeCall || "").length
          )
          .map((uuid) => store.state.group[uuid].user.inBridgeCall)
          .filter((v, i, a) => a.indexOf(v) === i).length
      );
    },
    showTotalCallToday() {
      const isToday = (ts) => {
        const someDate = new Date(ts);
        const today = new Date();
        return (
          someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
        );
      };
      const ts = (store.state.namespaceSettings.todaysCalls || {}).ts || 0;
      const total =
        (store.state.namespaceSettings.todaysCalls || {}).total || 0;
      return isToday(ts) ? total : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.displayInlineFlex{
  display: inline-flex;
}
.statsFontSize {
  font-size: 17px;
  text-align: center;
}
.usersAvailable {
  color: #008000;
}
.usersNotAvailable {
  color: #ffa500;
}
.usersHolidays {
  color: #7208ff;
}
.usersIllness {
  color: #fd939e;
}
.usersOffline {
  color: #808080;
}
.usersBusy {
  color: #ff0000;
}
.usersCoffeBreak{
  color: #795548;
}
.usersConnections {
  color: var(--v-primary-base) !important;
}
.usersConnectionsWhite {
  color: white !important;
}
.cursorPointer {
  cursor: pointer;
}
</style>