<template>
    <!-- <v-card flat class="border-primary rounded-sm" style="border: 2px solid #2a3133">
        <div class="position-relative">
            <v-img :src="showCompanyLogo" height="110px" cover />
            <UserAvatar :size="115" :src="getAvatarForUuid(userProfile)"
                class="me-4 border-lg border-opacity-100 border-background position-absolute"
                style="top:30px; left:20px" />
        </div>

        <div class="px-6 pt-10">
            <v-row class="mb-4">
                <v-col cols="12" class="pb-0">
                    <div class="d-flex flex-column justify-end mb-3">
                        <div class="font-weight-medium text-h6">{{ getTitelByUuid(userProfile) }}
                            {{ getNameByUuid(userProfile) }}</div>
                        <div class="text-subtitle-2 text-medium-emphasis">{{ getUserInfo.position }}</div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <div>
                        <span class="font-weight-medium">Abteilung:</span> <span>&nbsp;{{ getOrgaInfoDeps()
                            ? getOrgaInfoDeps() : '' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-medium">Teams:</span> <span>&nbsp;{{ getOrgaInfoTeams() ?
                            getOrgaInfoTeams() : '' }}</span>
                    </div>
                </v-col>

                <v-col cols="12" md="6" class="py-0 text-right">
                    <div class="d-flex">
                        <span class="font-weight-bold">Telefon:</span> <span> &nbsp;{{ getUserInfo.phone }} </span>
                    </div>
                    <div class="d-flex">
                        <span class="font-weight-bold">Email:</span><span>&nbsp;{{ getUserInfo.emailAlias ||
                            getUserInfo.email }} </span>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div class="d-flex justify-end px-4 bg-primary">
            <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props"
                        @click="!isFavorite ? setPersonFav(userProfile) : removePersonFav(userProfile)"
                        v-if="userProfile !== ownUUID" icon variant="text" color="white">
                        <font-awesome-icon v-if="!isFavorite" :icon="['fal', 'star']" :style="{ fontSize: '20px' }" />
                        <font-awesome-icon v-else :icon="['fa', 'star']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                </template>
<span>{{ isFavorite ? $t("components.userListItem.removeFromMyFavorites") :
    $t("components.userListItem.addToMyFavorites") }}</span>
</v-tooltip>
<v-tooltip bottom>
    <template v-slot:activator="{ props }">
                    <v-btn :disabled="disableCallBtn" @click="callUser(userProfile)" v-if="userProfile !== ownUUID"
                        v-bind="props" icon variant="text" color="white">
                        <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                </template>
    <span>{{ $t("components.notificationsModal.callUser") }}</span>
</v-tooltip>

<v-tooltip bottom>
    <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" v-if="userProfile !== ownUUID" @click="setShowModalSenMsg(userProfile)" icon
                        variant="text" color="white">
                        <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                </template>
    <span>{{ $t("components.userProfile.sendAMessage") }}</span>
</v-tooltip>
</div>
</v-card> -->
    <v-card flat class="border-primary rounded-sm" :style="getBorderByStatusFull(userProfile)">
        <v-row no-gutters>
            <!-- Foto de usuario -->
            <v-col class="d-flex justify-start align-center" cols="1">
                <div class="position-relative">
                    <UserAvatar class="pa-4" :size="115" :rounded="'25px'" :src="getAvatarForUuid(userProfile)" />
                </div>
            </v-col>

            <!-- Contenido a la derecha de la foto -->
            <v-col class="d-flex flex-column justify-center" cols="10">
                <div>
                    <span class="font-weight-bold">{{ getTitelByUuid(userProfile) }} {{ getNameByUuid(userProfile)
                        }}</span>
                </div>
                <div>
                    <span>{{ getUserInfo.position }}</span>
                </div>
            </v-col>

            <!-- Botones a la derecha -->
            <v-col class="d-flex flex-column justify-end align-end" cols="1">
                <div class="d-flex flex-column justify-center align-end" :style="{ backgroundColor: badgetClassObj, height:'100%' }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ props }">
                            <v-btn :disabled="disableCallBtn" @click="callUser(userProfile)" v-if="userProfile !== ownUUID"
                                v-bind="props" icon variant="text" color="white">
                                <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
                            </v-btn>
                        </template>
                        <span>{{ $t("components.notificationsModal.callUser") }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="showOrganigram">
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" icon variant="text" 
                                :disabled="isAiUser(userProfile)" :color="wholeBarWithColors() ? 'white' : 'black'"
                                @click.stop.prevent="goToOrganisation(userProfile)">
                                <!-- :disabled="!isUserInOrganigram" -->
                                <font-awesome-icon :icon="['fal', 'sitemap']" :style="{ fontSize: '20px' }" />
                            </v-btn>
                        </template>
                        <span>{{
                            $t("components.organisationCarousel.showInOrganigram")
                            }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" v-if="userProfile !== ownUUID" @click="setShowModalSenMsg(userProfile)"
                                icon variant="text" color="white">
                                <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                            </v-btn>
                        </template>
                        <span>{{ $t("components.userProfile.sendAMessage") }}</span>
                    </v-tooltip>

                </div>
            </v-col>
        </v-row>
    </v-card>
</template>



<script>
import store, {EventBus} from "../../../../store";
import {
    setHistoryMessagesModalEvent,
} from "../../../../effector/modals";
import { getOrganigramTeamsByUserLevel4, getOrganigramTeamsByUserLevel3,getBorderByStatusFull, getOrganigramTeamsByUser,canIShowOrganigram, isAiUser } from "../../../../utils/basicFunctions"
import { callPerson, disableCallBtn } from "../../../../utils/calls";
import { wholeBarWithColor } from "../../../../utils/color";
import { getStatusColorByUser } from "../../../../utils/status";
import UserAvatar from "../common/userAvatar.vue"


export default {
    props: ["userProfile"],
    components: {
        UserAvatar
    },
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            selectedTab: 0,
        };
    },
    watch: {},
    mounted() { },
    unmounted() { },
    methods: {
        getBorderByStatusFull(uuid) {
            // Get the person object from the group by UUID (or an empty object if not found)
            const person = this.state.group[uuid] || {};

            // Get the border value, which might be something like '4px solid #008000'
            const border = getBorderByStatusFull(person);

            // Replace any numeric value followed by 'px' with '2px'
            // The regular expression /(\d+)px/ looks for a number (\d+) followed by 'px' and replaces it with '2px'
            return border.replace(/(\d+)px/, '2px');  
        },
        setCurrentContentVisile(...args) {
            return store.setCurrentContentVisile(...args);
        },
        isAiUser(uuid){
            return isAiUser(uuid)
        },
        goToOrganisation(uuid) {
        // // open large user if you click again in the same button to open #3985
        // if (this.$route.path == ('/organisation/' + uuid) && this.$router.params?.uuid) {
        //     EventBus.$emit("showLargeUserOrganisation", this.$router.params.uuid);
        // }
        // go to the user organisation
        this.setCurrentContentVisile("organisation/" + uuid, true, this.$router);
        },
        removePersonFav: function (uuid) {
            store.setUuidInFavorites(uuid, undefined);
        },
        setPersonFav: function (uuid) {
            store.setUuidInFavorites(uuid, true);
        },
        processedUser() {
            // return store.getProcessedUserList()[this.state.userOnModal.uuid] || {};
            const organigram = JSON.parse(
                JSON.stringify(this.state.namespaceSettings.newOrganigram || [])
            );
            const usersUuid = this.state.userOnModal.uuid;
            return {
                teamDepartments: (getOrganigramTeamsByUser(organigram, usersUuid) || []).map(v => (v.name || v.id || v.uuid || v)),
                memberOfTeams: ([...getOrganigramTeamsByUserLevel3(organigram, 0, usersUuid), ...getOrganigramTeamsByUserLevel4(organigram, 0, usersUuid)] || []).map(v => (v.name || v.id || v.uuid || v)),
            };
        },
        getOrgaInfoDeps() {
            const deps = this.processedUser.teamDepartments;
            if (deps) return deps.join(', ');
            return false;
        },
        getOrgaInfoTeams() {
            const teams = this.processedUser.memberOfTeams;
            if (teams) return teams.join(', ');
            return false;
        },
        getAvatarForUuid(uuid) {
            return store.getAvatarForUuid(uuid);
        },
        getNameByUuid(uuid) {
            return store.getNameForUuid(uuid);
        },
        getTitelByUuid(uuid) {
            return store.getTitelForUuid(uuid);
        },
        setShowModalSenMsg(uuid) {
            setHistoryMessagesModalEvent(uuid);
        },
        callUser(uuid) {
            callPerson(uuid, this.$router);
        },
        wholeBarWithColors() {
            return wholeBarWithColor();
        },
    },
    computed: {
        showOrganigram() {
        return canIShowOrganigram();
        },
        isFavorite() {
            return this.state.persisted.favoriteUsers[this.userProfile];
        },
        getUserInfo() {
            return this.state.group[this.userProfile]?.user
        },
        disableCallBtn() {
            let person = store.state.group[this.userProfile];
            person.uuid = this.userProfile;
            return disableCallBtn(person);
        },
        showCompanyLogo() {
            if (
                !this.state.namespaceSettings.companyLogo ||
                this.state.namespaceSettings.companyLogo !== ""
            ) {
                return this.state.namespaceSettings.companyLogo || "assets/icon.png";
            }
            return "assets/icon.png";
        },
        badgetClassObj() {
            const person = this.state.group[this.userProfile] || {};
            if (this.wholeBarWithColors()) {
                return getStatusColorByUser(
                    person.user,
                    person.connected
                );
            } else {
                return "rgba(255,255,255,0.3)";
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>