<template>
  <v-content class="px-0 py-0">
    <v-row class="px-3">
      <v-col class="font-weight-bold">
        <div v-if="typeView === 'month'">{{ $t("components.workingTime.month") }}: {{timeCalculation}}</div>
        <div v-if="typeView === 'week'">{{ $t("components.workingTime.week") }}: {{timeCalculation}}</div>
        <div v-if="typeView === 'day'">{{ $t("components.workingTime.day") }}: {{timeCalculation}}</div>
      </v-col>
      <!-- <v-col>
        <v-btn class="" color="primary" dense icon @click="typeView = 'month'">
          <font-awesome-icon
            :icon="['fal', 'calendar']"
            :style="{ fontSize: '25px' }"
          />
        </v-btn>
        <v-btn class="" color="primary" dense icon @click="typeView = 'week'">
          <font-awesome-icon
            :icon="['fal', 'calendar-week']"
            :style="{ fontSize: '25px' }"
          />
        </v-btn>
        <v-btn class="" color="primary" dense icon @click="typeView = 'day'">
          <font-awesome-icon
            :icon="['fal', 'calendar-day']"
            :style="{ fontSize: '25px' }"
          />
        </v-btn>
      </v-col> -->
      <v-col class="text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class=""
             :color="isDark ? 'white':'primary'"
              dense
              icon
              @click="typeView = 'month'"
            >
              <font-awesome-icon
                :icon="['fal', 'calendar']"
                :style="{ fontSize: '25px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.workingTime.monthlyView") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class=""
              v-on="on"
             :color="isDark ? 'white':'primary'"
              dense
              icon
              @click="typeView = 'week'"
            >
              <font-awesome-icon
                :icon="['fal', 'calendar-week']"
                :style="{ fontSize: '25px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.workingTime.weeklyView") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mr-2"
             :color="isDark ? 'white':'primary'"
              dense
              icon
              @click="typeView = 'day'"
            >
              <font-awesome-icon
                :icon="['fal', 'calendar-day']"
                :style="{ fontSize: '25px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.workingTime.dayView") }}</span>
        </v-tooltip>
        <!-- <v-btn outlined class="" color="grey darken-2" @click="value = ''"> -->
        <v-btn
          v-if="showAddMoreTimeBtn"
          class="mr-4"
          color=""
          dense
          icon
          @click="addTimeModal()"
        >
          <font-awesome-icon
            :icon="['fal', 'plus']"
            :style="{ fontSize: '25px' }"
          />
        </v-btn>
        <v-btn outlined class="mr-4" :color="isDark ?'white' : 'grey darken-2'" @click="value = ''">
          <!-- @click="setToday" -->
          {{ $t("components.workingTime.today") }}
        </v-btn>
        <v-btn
          icon
          class="ml-2 btnCalendar"
          @click="$refs.calendarWorkingTime.prev()"
        >
          <font-awesome-icon
            :icon="['fal', 'chevron-left']"
            :style="{ fontSize: '20px' }"
          />
        </v-btn>
        <v-btn
          icon
          class="btnCalendar"
          @click="$refs.calendarWorkingTime.next()"
        >
          <font-awesome-icon
            :icon="['fal', 'chevron-right']"
            :style="{ fontSize: '20px' }"
          />
        </v-btn>
        <span class="text-white subtitle-1 font-weight-bold">
          {{ calendarMonth }} {{ calendarYear }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <!-- filter table -->
      <v-col class="mx-2 calendarContainer">
        <v-card outlined class="h100" id="calendarCard">
          <template>
            <v-calendar
              :weekdays="weekday"
              ref="calendarWorkingTime"
              color="primary"
              v-model="value"
              first-interval="5"
              :type="typeView"
              :events="events"
              :event-color="getEventColor"
              @click:day="viewDay"
              @click:date="viewDay"
              @click:event="openEvent"
              @click:more="viewDay"
              @change="calChangeHandler"
            >
              <template v-slot:event="{ event }">
                {{ event.name }}, {{ getFormatedTime(event.start) }} -
                {{ getFormatedTime(event.end) }}
              </template>
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-y
              max-width="200px"
            >
              <v-card color="grey lighten-4" flat>
                <v-toolbar dense>
                  <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                  <v-toolbar-title
                    :style="{ fontSize: '15px!important' }"
                    v-html="$sanitize(selectedEvent.name)"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <DeleteBasket
                    :indexData="selectedEvent"
                    :delFunction="removeEvent"
                    extraClasses="cursorPointer"
                    fontSize="18"
                  />
                  <!-- <v-btn icon @click="removeEvent(selectedEvent)">
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn> -->
                </v-toolbar>
                <!-- <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-menu>
            <!-- //menu to add o edit event keep for future -->
            <!-- <v-menu
              v-model="addEventMenu.showMenu"
              :close-on-content-click="false"
              :close-on-click="false"
              offset-y
              :attach="'#calendarCard'"
            >
              <v-card color="grey lighten-4" min-width="450px" flat>
                <v-toolbar :color="'#2a3133'" dark>
                  <span>Working time {{ currentDay }}</span>
                </v-toolbar>
                <div class="workingTimeWrapper">
                  <v-row class="my-0 mx-0">
                    <v-col cols="5">
                      <v-menu
                        ref="startTimeMenu"
                        v-model="workingInputMenuStart"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            @keydown.space="(event) => event.preventDefault()"
                            v-model="workingInputStart"
                            type="time"
                            class="timePicker py-2"
                            v-on="on"
                            dense
                            hide-details
                            outlined
                            :label="$t('components.conferenceForm.from')"
                          >
                          </v-text-field>
                        </template>
                        <v-time-picker
                          class="font16"
                          v-model="workingInputStart"
                          full-width
                          format="24hr"
                          min="00:00"
                          @click:minute="$refs.startTimeMenu.save(workingInputStart)"
                        ></v-time-picker>
                      </v-menu>
                      <v-menu
                        ref="endTimeMenu"
                        v-model="workingInputMenuEnd"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            @keydown.space="(event) => event.preventDefault()"
                            v-model="workingInputEnd"
                            type="time"
                            class="timePicker"
                            v-on="on"
                            dense
                            hide-details
                            outlined
                            :label="$t('components.conferenceForm.to')"
                            :disabled="workingInputStart === ''"
                          >
                          </v-text-field>
                        </template>
                        <v-time-picker
                          class="font16"
                          v-model="workingInputEnd"
                          full-width
                          format="24hr"
                          :min="workingInputStart"
                          :disabled="workingInputStart === ''"
                          @click:minute="$refs.endTimeMenu.save(workingInputEnd)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="7">
                      <v-select
                        class="pt-0"
                        :items="items"
                        item-value="name"
                        item-text="name"
                        :item-disabled="disableItem"
                        label="Type"
                        v-model="workingTimeType"
                      ></v-select>
                      <v-btn
                        color="primary"
                        class="w100"
                        :disabled="disabledAddEventButton"
                        @click="addEventToPreview()"
                      >
                        Add event
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <v-row
                    class="mx-0 my-0 eventPreviewRow"
                    v-if="temporalPreviewEvents"
                  >
                    <v-col
                      cols="12"
                      v-for="event in temporalPreviewEvents"
                      :key="event.uuid"
                      class="eventItem"
                    >
                      <v-col cols="10" class="align-center d-flex">
                        <span>
                          <font-awesome-icon
                            :icon="['fad', 'circle']"
                            :color="`${getEventBackgroundColor(event.color)}`"
                          />
                          {{event.name}} - {{getFormatedTime(event.start)}} - {{getFormatedTime(event.end)}}
                        </span>
                      </v-col>
                      <v-col cols="2">
                        <v-btn icon @click="deleteTimeEvent(event)">
                          <font-awesome-icon :icon="['fal', 'times']" />
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
                <v-card-actions class="d-flex justify-end">
                  <v-btn :disabled="temporalPreviewEvents.length == 0" color="primary" @click="saveEvent();">
                    Save
                  </v-btn>
                  <v-btn color="primary" @click="addEventMenu = {}">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu> -->
            <!-- //menu to add o edit event keep for future -->
          </template>
        </v-card>
      </v-col>
    </v-row>
    <template>
      <v-dialog
        v-model="showAddTimeModal"
        persistent
        max-width="560"
        @keydown.esc="closeModal"
      >
        <v-card>
          <HeaderModal
            :titleModal="`${$t('components.workingTime.workingTime')} ${value}`"
            :closeModalFunction="closeModal"
          />
          <v-card-text class="mx-auto py-2">
            <div>
              <v-row>
                <v-col cols="6" class="pb-0 pl-4">
                  <v-menu
                    ref="menuStartPicker"
                    v-model="menuStartPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="startTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        @keydown.space="(event) => event.preventDefault()"
                        v-model="startTime"
                        type="time"
                        class="timePicker"
                        v-on="on"
                        hide-details
                        @blur="$refs.menuStartPicker.save(startTime)"
                        :label="$t('components.conferenceForm.from')"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuStartPicker"
                      format="24hr"
                      v-model="startTime"
                      full-width
                      @click:minute="$refs.menuStartPicker.save(startTime)"
                      @click:hour="autocompleteMinutes"
                      min="00:00"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="pb-0 pr-4">
                  <v-menu
                    ref="menuEndPicker"
                    v-model="menuEndPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="endTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        @keydown.space="(event) => event.preventDefault()"
                        v-model="endTime"
                        type="time"
                        class="timePicker"
                        :label="$t('components.conferenceForm.to')"
                        v-bind="attrs"
                        :disabled="!startTime"
                        hide-details
                        @blur="$refs.menuEndPicker.save(endTime)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuEndPicker"
                      format="24hr"
                      v-model="endTime"
                      full-width
                      @click:minute="$refs.menuEndPicker.save(endTime)"
                      @click:hour="autocompleteMinutesEnd"
                      :min="startTime"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col class="pl-4 pt-4" cols="12">
                  <v-combobox
                    v-model="statusSelect"
                    :items="statusItems"
                    :label="$t('components.drawerContentCard.status')"
                    hide-details
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <div class="subtitle-2" :style="`${'color:' + getStatusColor(item) + '!important'}`">{{ $t(`status.${item}`) }}</div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div class="subtitle-2" :style="`${'color:' + getStatusColor(item) + '!important'}`">{{ $t(`status.${item}`) }}</div>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col class="pt-0">
                  <v-textarea
                    class="mt-3"
                    v-model="eventDetail"
                    no-resize
                    :label="$t('generics.info')"
                    outlined
                    counter="255"
                    maxlength="255"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <FooterModal :closeModalFunction="closeModal" :showClose="true">
            <v-btn
              color="primary"
              class="ml-2"
              @click="saveAddTimeModal"
              :disabled="!eventDetail.length || !endTime || !startTime || !statusSelect.length"
              >{{ $t("generics.save") }}</v-btn
            >
          </FooterModal>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
    </template>
  </v-content>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { v4 as uuidv4 } from "uuid";
import {
  readDailyTimeCountings,
  delCustomDailyTimeCounting,
  setCustomDailyTimeCounting,
} from "../../lib/wsMsg";
import { getTimezone } from "../../utils/basicFunctions";
import { getColorByStatus } from "../../utils/color";
import { colorStatus } from "../../utils/status";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import DeleteBasket from "../ui/deleteBasket.vue";
export default {
  props: ["selectedUser"],
  components: { HeaderModal, FooterModal, DeleteBasket },
  data() {
    return {
      state: store.state,
      search: "",
      setCurrentContentVisile: store.setCurrentContentVisile,
      typeView: "month",
      addEventMenu: { showMenu: false, parentUuid: null },
      calendarMonth: "",
      calendarYear: "",
      events: [],
      filteredEvents: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      value: "",
      workingInputMenuStart: "",
      workingInputMenuEnd: "",
      workingInputStart: "",
      workingInputEnd: "",
      // items: ["Daily Work", "Pause"],
      items: [
        {
          name: "Daily work",
          disabled: false,
        },
        {
          name: "Pause",
          disabled: true,
        },
        {
          name: "Lunch",
          disabled: true,
        },
      ],
      workingTimeType: "Daily work",
      currentDay: "",
      temporalPreviewEvents: [],
      showAddTimeModal: false,
      menuStartPicker: false,
      menuEndPicker: false,
      startTime: null,
      endTime: null,
      eventDetail: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      statusItems: ['Available', 'Out of Office', 'Break', 'Holidays'],
      statusSelect: '',
      timeCalculationOp: {
        // Available
        'green': 'plus',
        '#008000': 'plus', // green
        '#006400': 'plus', // dark green
        // Out of Office
        'yellow': 'custom',
        '#ffa500': 'custom', // orange
        '#ffac13': 'custom', // golden yellow
        '#ce7e00': 'custom', // burnt orange
        // Offline
        'gray': 'minus',
        // Break
        'red': 'minus',
        '#ff0000': 'minus', // red
        '#8b0000': 'minus', // dark red
      },
    };
  },
  watch: {
    addEventMenu: {
      deep: true,
      handler(value) {
        if (value.showMenu === false) this.temporalPreviewEvents = [];
      },
    },
    selectedUser: {
      handler(value) {
        if (value) {
          this.events = [];
          this.$nextTick(async () => {
            if (this.selectedUser === value) {
              const dataEvents = await this.getDataEvents(value);
              if (this.selectedUser === value) this.getEvents(dataEvents, value);
            }
          });
        }
      },
    },
    startTime: {
      handler(value) {
        if (this.endTime < value) {
          this.endTime = null;
        }
      },
    },
    events: {
      immediate: true,
      handler(events) {
        // We will create a new filtered array without the duplicates as per conditions.
        const filteredEvents = events.filter((event, _index, self) => {
          if (event.type === "custom") return true;
          // Check if there is another event of type custom:
          return self.findIndex(e => e.type === "custom" && (
            // That replaces the system event with start/end times within two minutes.
            ((this.timeCalculationOp[e.color] === "plus" || this.timeCalculationOp[e.color] === this.timeCalculationOp[event.color]) &&
            Math.abs(this.formatCalendarAsDate(e.start) - this.formatCalendarAsDate(event.start)) <= 120000 &&
            Math.abs(this.formatCalendarAsDate(e.end) - this.formatCalendarAsDate(event.end)) <= 120000) ||
            // That replaces the system Offline event with an overlapping custom Out of Office event.
            (this.timeCalculationOp[e.color] === "custom" && this.timeCalculationOp[event.color] === "minus" && e.start <= event.start && e.end >= event.end)
          )) === -1;
        });
        this.filteredEvents = filteredEvents;
      },
    }
  },
  mounted() {
    const date = new Date();
    this.value = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  },
  methods: {
    /**
     * Adjusts events to a single timeline by normalizing their start and end times and ensuring no overlap for the same operation.
     * @param {Array} events An array of event objects with start and end times in RFC or ISO format.
     * @returns {Array} The adjusted events to fit a single timeline.
     */
    adjustEventsToSingleTimeline(events) {
      // Normalize event times
      const normalizedEvents = events.map(event => ({
        ...event,
        start: this.formatCalendarAsDate(event.start),
        end: this.formatCalendarAsDate(event.end),
      })).sort((a, b) => a.start - b.start); // Sort by start time
      // Group events by operation
      const groupedByOp = normalizedEvents.reduce((acc, event) => {
        const op = this.timeCalculationOp[event.color] || 'unknown';
        if (!acc[op]) acc[op] = [];
        acc[op].push(event);
        return acc;
      }, {});
      // Adjust overlapping events within the same operation group
      const adjustedEvents = [];
      for (const op in groupedByOp) {
        const opEvents = groupedByOp[op];
        // Adjust the overlapping events
        for (let i = 0; i < opEvents.length - 1; i++) {
          const current = opEvents[i];
          const next = opEvents[i + 1];
          if (current.end > next.start) { // Check for overlap
            // Adjust the start of the next event to the end of the current event
            next.start = current.end;
            // Optional: If the next event ends before it starts (due to adjustment), end it right after it starts
            if (next.end < next.start) {
              next.end = next.start;
            }
          }
        }
        adjustedEvents.push(...opEvents);
      }
      // Convert the dates back to the original format
      return adjustedEvents.sort((a, b) => a.start - b.start) // Sort by start time
        .map(event => ({
          ...event,
          start: this.formatDateForCalendar(event.start),
          end: this.formatDateForCalendar(event.end),
        }));
    },
    autocompleteMinutesEnd(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = "0" + hour;
      this.endTime = finalHour + ":00";
    },
    autocompleteMinutes(hour) {
      let finalHour = hour;
      if (hour < 10) finalHour = "0" + hour;
      this.startTime = finalHour + ":00";
    },
    removeEvent(event) {
      const eventIndex = this.events.findIndex((e) => e.id === event.id);
      if (event.start && event.id) {
        delCustomDailyTimeCounting(
          (this.selectedUser || this.state.ownUUID),
          this.formatCalendarDateToISO(event.start).substring(0, 10),
          event.id
        );
      }
      if (eventIndex !== -1) this.events.splice(eventIndex, 1);
      this.selectedEvent = {};
      this.selectedElement = null;
      this.selectedOpen = false;
    },
    openEvent({ nativeEvent, event }) {
      if (
        event.type &&
        event.type === "custom" &&
        (this.selectedUser === this.state.ownUUID || this.amIAdmin)
      ) {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          );
        };
        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      }
    },
    saveAddTimeModal() {
      let eventColor = '#ff0000';
      switch (this.statusSelect) {
        case 'Available':
          eventColor = 'green'
          break;
        case 'Out of Office':
          eventColor = '#ffac13';
          break;
        case 'Break':
          eventColor = '#ff0000'
          break;
        case 'Holidays':
          eventColor = 'purple'
          break;
        default:
          break;
      }
      const parseEvent = {};
      const startDate = this.formatCalendarAsDate(this.value + " " + this.startTime).getTime();
      const endDate = this.formatCalendarAsDate(this.value + " " + this.endTime).getTime();
      if (isNaN(startDate) || isNaN(endDate) || endDate < startDate) return; // Sanity check.
      parseEvent.start = this.formatDateForCalendar(startDate);
      parseEvent.end = this.formatDateForCalendar(endDate);
      parseEvent.name = this.eventDetail;
      parseEvent.color = eventColor;
      parseEvent.timed = false;
      parseEvent.id = uuidv4().replace(/-/g, '');
      parseEvent.type = "custom";
      this.events.push(parseEvent);
      const day = this.value.substring(0, 10);
      const customChanges = this.events
        .filter((e) => e.type === "custom")
        .map((e) => ({
          _id: e.id,
          start: this.formatCalendarDateToISO(e.start),
          end: this.formatCalendarDateToISO(e.end),
          name: e.name,
          color: e.color,
        }))
        .filter((e) => {
          const { start, end, name } = e;
          const valid =
            typeof start === "string" &&
            typeof end === "string" &&
            typeof name === "string" &&
            start.startsWith(day) &&
            end.startsWith(day);
          return valid;
        });
      const saveEventPromise = setCustomDailyTimeCounting(
        (this.selectedUser || this.state.ownUUID),
        this.value,
        customChanges
      );
      saveEventPromise.then(
        (row) => {
          const ours =
            row?.customChanges &&
            [...row.customChanges]
              .reverse()
              .find(
                (e) =>
                  e?.start === this.formatCalendarDateToISO(parseEvent.start) &&
                  e?.end === this.formatCalendarDateToISO(parseEvent.end)
              );
          if (ours) {
            parseEvent.id = ours._id;
            parseEvent.name = ours.name;
          } else {
            console.warn("Failed to enter additional times", { row, ours, parseEvent });
          }
        },
        (err) => {
          console.warn("Error entering additional times", err);
        }
      );
      this.closeModal();
    },
    closeModal() {
      this.showAddTimeModal = !this.showAddTimeModal;
      this.startTime = null;
      this.endTime = null;
      this.eventDetail = "";
      this.statusSelect = "";
    },
    disableItem(item) {
      if (item.name === "Daily work" && this.isDailyWorkSetted) return true;
      if (item.name !== "Daily work" && !this.isDailyWorkSetted) return true;
      return false;
    },
    cleanForm() {
      this.workingInputStart = "";
      this.workingInputEnd = "";
      this.workingTimeType = "";
      this.workingInputMenuStart = "";
      this.workingInputMenuEnd = "";
    },
    getFormatedTime(time) {
      const date = new Date(time);
      return `${String(date.getHours()).padStart(2, "0")}:${String(
        date.getMinutes()
      ).padStart(2, "0")}`;
    },
    calChangeHandler(page) {
      this.calendarMonth = this.$t(
        `components.calendarContainer.months.${page.start.month}`
      );
      this.calendarYear = page.start.year;
      this.events = [];
      const value = this.selectedUser;
      this.$nextTick(async () => {
        if (this.selectedUser === value) {
          const dataEvents = await this.getDataEvents(value);
          if (this.selectedUser === value) this.getEvents(dataEvents, value);
        }
      });
    },
    async getDataEvents(userUuid) {
      return await readDailyTimeCountings(userUuid);
    },
    getEvents(dataEvents, selectedUser) {
      if (!dataEvents) return [];
      const now = new Date();
      dataEvents.forEach((workDay) => {
        const tmpEvent = JSON.parse(JSON.stringify(workDay || {}));
        // Set work day
        if (selectedUser && tmpEvent?.statusChanges?.length) {
          if (!tmpEvent.loginTS)
            tmpEvent.loginTS = tmpEvent.statusChanges[0].ts;
          if (!tmpEvent.logoutTS && tmpEvent.loginTS) {
            const logoutTS = new Date(
              tmpEvent.statusChanges[tmpEvent.statusChanges.length - 1].ts
            );
            if (
              store.state.group[selectedUser]?.connected &&
              now > logoutTS &&
              this.isToday(logoutTS)
            ) {
              tmpEvent.logoutTS = now.toISOString();
            } else if (logoutTS > new Date(tmpEvent.loginTS)) {
              tmpEvent.logoutTS = logoutTS.toISOString();
            }
          }
        }
        const valid = this.setWorkDay(tmpEvent);
        // Set status changes
        if (valid || tmpEvent.customChanges)
          this.setStatusChanges(tmpEvent, valid);
      });
    },
    isToday(someDate) {
      const date = new Date(store.state.currentTS);
      if (typeof someDate === "string") {
        const today = date.toISOString().slice(0, 10);
        return someDate.startsWith(today);
      } else if (someDate instanceof Date && !isNaN(someDate)) {
        const today = date;
        return (
          someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
        );
      }
      return false;
    },
    setStatusChanges(workDay, setStatusChanges) {
      if (
        setStatusChanges &&
        workDay.statusChanges &&
        workDay.statusChanges.length
      ) {
        const statusData = workDay.statusChanges;
        statusData.forEach((status, index) => {
          const parseEvent = {};
          // Set disconnected
          if (!status.connected && index < statusData.length - 1) {
            const date1 = new Date(status.ts);
            const date2 = new Date(statusData[index + 1].ts);
            const diffTime = Math.abs(date2 - date1);
            if (diffTime >= 600000 /* 10 minutes ago */) {
              parseEvent.start = this.formatDateForCalendar(status.ts);
              parseEvent.end = this.formatDateForCalendar(
                statusData[index + 1].ts
              );
              parseEvent.name = this.$t("status.Offline");
              parseEvent.color = "gray";
              parseEvent.timed = false;
              parseEvent.id = status._id;
              const exist = this.events.find((data) => data.id === status._id);
              if (!exist) {
                this.events.push(parseEvent);
              }
            }
          }
          // Set status
          if (
            status.activity !== "Available" &&
            status.connected &&
            index < statusData.length - 1
          ) {
            const key = `status.${status.activity}`;
            const translation = this.$t(key) || key;
            const activity =
              key === translation ? `${status.activity}` : translation;
            parseEvent.start = this.formatDateForCalendar(status.ts);
            parseEvent.end = this.formatDateForCalendar(
              statusData[index + 1].ts
            );
            parseEvent.name = activity;
            parseEvent.color = this.getColorByStatus(status.activity);
            parseEvent.timed = false;
            parseEvent.id = status._id;
            const exist = this.events.find((data) => data.id === status._id);
            if (!exist) {
              this.events.push(parseEvent);
            }
          }
        });
      }
      if (
        workDay.customChanges &&
        workDay.customChanges.length
      ) {
        const statusData = workDay.customChanges;
        statusData.forEach((status, index) => {
          const { name, start, end, _id, color } = status;
          const parseEvent = {};
          parseEvent.start = this.formatDateForCalendar(start);
          parseEvent.end = this.formatDateForCalendar(end);
          parseEvent.name = name;
          parseEvent.color = color || "purple";
          parseEvent.timed = false;
          parseEvent.id = _id;
          parseEvent.type = "custom";
          const exist = this.events.find((data) => data.id === status._id);
          if (!exist) {
            this.events.push(parseEvent);
          }
        });
      }
    },
    setWorkDay(tmpEvent) {
      const exist = this.events.find((data) => data.id === tmpEvent._id);
      if (!exist && tmpEvent.loginTS && tmpEvent.logoutTS) {
        const parseEvent = {};
        parseEvent.start = this.formatDateForCalendar(tmpEvent.loginTS);
        parseEvent.end = this.formatDateForCalendar(tmpEvent.logoutTS);
        parseEvent.name = this.$t("status.Available");
        parseEvent.color = "green";
        parseEvent.timed = false;
        parseEvent.id = tmpEvent._id;
        this.events.push(parseEvent);
        return true;
      }
      return false;
    },
    getColorByStatus(status) {
      return getColorByStatus(status);
    },
    formatDateForCalendar(timeStamp) {
      const date = new Date(timeStamp);
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString().padStart(2, "0")}-${date.getDate()
        .toString().padStart(2, "0")} ${date.getHours()
        .toString().padStart(2, "0")}:${date.getMinutes()
        .toString().padStart(2, "0")}`;
    },
    /**
     * Formats a date string to a Date object or an ISO string.
     * @param {Date|string} dateString The date as a Date object, or string in RFC 3339 or ISO 8601 format.
     * @param {boolean} [asDateObject=true] Determines if the output should be a Date object. If false, returns an ISO string.
     * @returns {Date|string} The formatted Date object or ISO string.
     */
    formatCalendarAsDate(dateString, asDateObject = true) {
      if (dateString && typeof dateString === 'object' && dateString instanceof Date && !isNaN(dateString)) {
        return asDateObject ? new Date(dateString) : dateString.toISOString(); // Input is native date object
      }
      const [date, time] = (typeof dateString === 'string' && dateString.split(" ")) || []; // RFC 3339 on local time-offset
      if (dateString && !date && !time) {
        const momentDate = moment(dateString).toDate();
        return asDateObject ? momentDate : momentDate.toISOString(); // Try processing with moment.js
      }
      const obj = !time && typeof date === 'string' && date.includes("T") // ISO 8601 with embedded timezone
        ? new Date(date)
        : new Date(date
          .split("-")
          .map((e) => e.padStart(2, "0"))
          .join("-") +
          "T" +
          time
            .split(":")
            .map((e) => e.padStart(2, "0"))
            .join(":") +
          ":00" +
          getTimezone(date, time)
        );
      return asDateObject ? obj : obj.toISOString();
    },
    /**
     * Formats a date string to an ISO string.
     * @param {Date|string} dateString The date as a Date object, or string in RFC 3339 or ISO 8601 format.
     * @returns {string} The formatted ISO string.
     */
    formatCalendarDateToISO(dateString) {
      return this.formatCalendarAsDate(dateString, false);
    },
    saveEvent() {
      const foundParentUuid = this.events.find(
        (event) => event.parentUuid == this.addEventMenu.parentUuid
      );
      if (!foundParentUuid) this.events.push(...this.temporalPreviewEvents);
      this.addEventMenu = {};
    },
    addEventToPreview() {
      this.temporalPreviewEvents.push({
        name: this.workingTimeType,
        start: `${this.currentDay} ${this.workingInputStart}`,
        end: `${this.currentDay} ${this.workingInputEnd}`,
        color: this.setColorByTypeEvent(this.workingTimeType),
        timed: false,
        parentUuid: this.addEventMenu.parentUuid,
        uuid: uuidv4(),
      });
      this.cleanForm();
    },
    deleteTimeEvent(event) {
      const eventIndex = this.temporalPreviewEvents.indexOf(event);
      if (eventIndex !== -1) this.temporalPreviewEvents.splice(eventIndex, 1);
      this.cleanForm();
    },
    // showEvent(event) {
    //   if (event.nativeEvent) {
    //     event.nativeEvent.preventDefault();
    //     event.nativeEvent.stopPropagation();
    //   }
    //   this.currentDay = event?.day?.date;
    //   this.cleanForm();
    //   this.temporalPreviewEvents = [];
    //   const dayEvents = this.getEventsByParentUuid(event.event.parentUuid);
    //   this.temporalPreviewEvents = dayEvents;
    //   this.addEventMenu = {
    //     showMenu: true,
    //     parentUuid: event.event.parentUuid,
    //   };
    // },
    // addEvent(event) {
    //   this.addEventMenu.showMenu = true;
    //   this.addEventMenu.parentUuid = uuidv4();
    //   this.cleanForm();
    //   this.temporalPreviewEvents = [];
    //   const hasEvent = this.getEventsByDate(event.date)
    //   if (hasEvent.length > 0) {
    //     this.addEventMenu.parentUuid = hasEvent[0].parentUuid;
    //     const dayEvents = this.getEventsByParentUuid(hasEvent[0].parentUuid);
    //     this.temporalPreviewEvents = dayEvents;
    //   }
    //   this.currentDay = event.date;
    // },
    closeStatus() {
      this.setCurrentContentVisile("home", true, this.$router);
    },
    viewDay(event) {
      this.value = event.date;
      this.typeView = "week";
    },
    getEventColor(event) {
      if (event.type === "custom") {
        switch (event.color) {
          case 'green':
          case '#008000': // green
            return '#006400'; // dark green
          case 'red':
          case '#ff0000': // red
            return '#8b0000'; // dark red
          case 'yellow':
          case '#ffa500': // orange
          case '#ffac13': // golden yellow
            return '#ce7e00'; // burnt orange
        }
      }
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    setColorByTypeEvent(type) {
      switch (type) {
        case "Daily work":
          return "green";
        case "Lunch":
          return "yellow";
        case "Pause":
          return "red";
        default:
          return "green";
      }
    },
    getEventBackgroundColor(color) {
      switch (color) {
        case "green":
          return "#008000";
        case "yellow":
          return "#ffa500";
        case "red":
          return "#ff0000";
        default:
          return "#008000";
      }
    },
    getEventsByParentUuid(parentUuid) {
      if (!parentUuid) return;
      const data = this.events.filter(
        (event) => event.parentUuid == parentUuid
      );
      return data;
    },
    getEventsByDate(date) {
      if (!date) return;
      const data = this.events.filter(
        (event) => this.formatCalendarDateToISO(event.start).slice(0, 10) == date);
      return data;
    },
    addTimeModal() {
      // open modal for add time
      this.showAddTimeModal = true;
    },
    getArrayDatesBetween(start, end) {
      // eslint-disable-next-line no-var
      for(var arr=[], dt=new Date(start); dt<=new Date(end); dt.setUTCDate(dt.getUTCDate()+1)) {
        arr.push(new Date(dt));
      }
      return arr.map((v) => v.toISOString().slice(0,10));
    },
    getStatusColor(status) {
      if (status === 'Out of Office') return '#ffac13';
      return colorStatus(status);
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    timeCalculation() {
      if (!this.events.length) return '00:00';
      const events = [];
      const viewStart = this.$refs.calendarWorkingTime.lastStart.date;
      const viewEnd = this.$refs.calendarWorkingTime.lastEnd.date;
      const eventsDate = this.getArrayDatesBetween(viewStart, viewEnd);
      eventsDate.forEach(date => {
        const data = this.getEventsByDate(new Date(date).toISOString().slice(0, 10));
        data.forEach(info => {
          const findIndex = events.findIndex(e => e.id === info.id);
          if (findIndex === -1 && info.id && this.filteredEvents.includes(info)) {
            events.push(info);
          }
        });
      });
      let hours = 0, minutes = 0;
      const adjustedEvents = this.adjustEventsToSingleTimeline(events);
      adjustedEvents.forEach(event => {
        const startHoursSplit = event.start.split(' ')[1].split(':');
        const endHoursSplit = event.end.split(' ')[1].split(':');
        const eventStartMinutes = (parseInt(startHoursSplit[0]) * 60) + parseInt(startHoursSplit[1]);
        const eventEndMinutes = (parseInt(endHoursSplit[0]) * 60) + parseInt(endHoursSplit[1]);
        const calculateMinutes = eventEndMinutes - eventStartMinutes;
        switch (event.color) {
          case 'green':
          case '#008000': // green
          case '#006400': // dark green
            minutes += calculateMinutes;
            break;
          case 'gray':
          case 'red':
          case '#ff0000': // red
          case '#8b0000': // dark red
            minutes -= calculateMinutes;
            break;
          case 'yellow':
          case '#ffa500': // orange
          case '#ffac13': // golden yellow
          case '#ce7e00': // burnt orange
            {
              let overlapsAvailable = false;
              let overlapsBusy = false;
              let overlapsOffline = false;
              let overlapDuration = 0;
              // Check if there are overlaps
              const hasOverlapEvent = adjustedEvents.some(e => {
                const eStartSplit = e.start.split(' ', 2);
                const eEndSplit = e.end.split(' ', 2);
                const isSameDay = event.start.startsWith(eStartSplit[0]) && event.end.startsWith(eEndSplit[0]);
                if (isSameDay && event !== e && (
                  e.color === 'gray' || // offline
                  e.color === 'green' ||
                  e.color === '#008000' || // green
                  e.color === '#006400' || // dark green
                  e.color === 'yellow' ||
                  e.color === '#ffa500' || // orange
                  e.color === '#ffac13' || // golden yellow
                  e.color === '#ce7e00') // burnt orange
                ) {
                  const eStartHours = eStartSplit[1].split(':');
                  const eEndHours = eEndSplit[1].split(':');
                  const eStartMinutes = parseInt(eStartHours[0]) * 60 + parseInt(eStartHours[1]);
                  const eEndMinutes = parseInt(eEndHours[0]) * 60 + parseInt(eEndHours[1]);
                  // Check if the event is within the same time span
                  return (
                    (eventStartMinutes >= eStartMinutes && eventStartMinutes < eEndMinutes) ||
                    (eventEndMinutes > eStartMinutes && eventEndMinutes <= eEndMinutes) ||
                    (eventStartMinutes <= eStartMinutes && eventEndMinutes >= eEndMinutes)
                  );
                }
                return false;
              });
              if (hasOverlapEvent) {
                // Find overlaps and calculate their span
                adjustedEvents.forEach(e => {
                  if (event === e) return;
                  const eStartSplit = e.start.split(' ', 2);
                  const eEndSplit = e.end.split(' ', 2);
                  const isSameDay = event.start.startsWith(eStartSplit[0]) && event.end.startsWith(eEndSplit[0]);
                  if (!isSameDay) return;
                  const eIsAvailable = (e.color === 'green' || e.color === '#008000' /* green */ || e.color === '#006400' /* dark green */);
                  const eIsBusy = (e.color === 'yellow' || e.color === '#ffa500' /* orange */ || e.color === '#ffac13' /* golden yellow */ || e.color === '#ce7e00' /* burnt orange */);
                  const eIsOffline = (e.color === 'gray' /* offline */);
                  if (eIsAvailable || eIsBusy || eIsOffline) {
                    const eStart = e.start.split(' ')[1].split(':');
                    const eEnd = e.end.split(' ')[1].split(':');
                    const eStartMinutes = parseInt(eStart[0]) * 60 + parseInt(eStart[1]);
                    const eEndMinutes = parseInt(eEnd[0]) * 60 + parseInt(eEnd[1]);
                    if (eventStartMinutes < eEndMinutes && eventEndMinutes > eStartMinutes) {
                      const overlapStart = Math.max(eventStartMinutes, eStartMinutes);
                      const overlapEnd = Math.min(eventEndMinutes, eEndMinutes);
                      if (overlapEnd - overlapStart > 0) {
                        overlapDuration += overlapEnd - overlapStart;
                        overlapsAvailable = overlapsAvailable || eIsAvailable;
                        overlapsBusy = overlapsBusy || eIsBusy;
                        overlapsOffline = overlapsOffline || eIsOffline;
                      }
                    }
                  }
                });
              }
              if (calculateMinutes - overlapDuration > 0) {
                minutes += calculateMinutes - overlapDuration;
              } else if (overlapsBusy || !overlapsAvailable || overlapsOffline) {
                minutes += calculateMinutes;
              }
            }
            break;
          default:
            break;
        }
      });
      hours = Math.floor(minutes/60);
      minutes = minutes % 60;
      if (hours < 0) hours = 0;
      if (minutes < 0) minutes = 0;
      const formattedNumber = (num) => ("" + num).padStart(2, "0");
      const workingTime = formattedNumber(hours) + ':' + formattedNumber(minutes);
      return workingTime;
    },
    showAddMoreTimeBtn() {
      // On the daily view for the last 7 days including today only we show + button
      if (this.typeView === "day") {
        const today = new Date();
        const lastWeek = Date.parse(
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
        );
        const selectedDate = new Date(this.value);
        selectedDate.setHours(0, 0, 0, 0);
        if (
          Date.parse(selectedDate) < lastWeek ||
          Date.parse(selectedDate) >
            Date.parse(
              new Date(today.getFullYear(), today.getMonth(), today.getDate())
            )
        ) {
          return false;
        } else {
          if (this.selectedUser === this.state.ownUUID || this.amIAdmin) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    isDailyWorkSetted() {
      const dailyIndex = this.temporalPreviewEvents.findIndex(
        (element) => element.name === "Daily work"
      );
      if (dailyIndex !== -1) return true;
      return false;
    },
    disabledAddEventButton() {
      if (
        this.workingInputStart === "" ||
        this.workingInputEnd === "" ||
        this.workingTimeType === ""
      )
        return true;
      return false;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.calendarContainer {
  height: calc(100vh - 270px);
}

.workingTimeWrapper {
  display: flex;
}
.eventPreviewRow {
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 560px);
}
.eventItem {
  padding: 0;
  display: flex;
}
</style>
<style lang="scss">
.timePicker input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  pointer-events: none;
}
