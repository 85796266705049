var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.externalServicesModal,
        callback: function($$v) {
          _vm.externalServicesModal = $$v
        },
        expression: "externalServicesModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.externalServices.titleModal"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              [
                _c("v-list-item-title", [
                  _vm._v(
                    _vm._s(_vm.$t("components.externalServices.releaseFor"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        label: _vm.$t("components.externalServices.dasd"),
                        value: "dasd",
                        disabled:
                          !_vm.hasDasdUserId() ||
                          (_vm.selectedServices.length == 1 &&
                            _vm.selectedServices.indexOf("dasd") !== -1)
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeActivesServices()
                        }
                      },
                      model: {
                        value: _vm.selectedServices,
                        callback: function($$v) {
                          _vm.selectedServices = $$v
                        },
                        expression: "selectedServices"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-checkbox", {
                      staticClass: "ml-6",
                      attrs: {
                        label: _vm.$t("components.externalServices.omk"),
                        value: "omk",
                        disabled:
                          !_vm.hasOMKUserId() ||
                          (_vm.selectedServices.length == 1 &&
                            _vm.selectedServices.indexOf("omk") !== -1)
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeActivesServices()
                        }
                      },
                      model: {
                        value: _vm.selectedServices,
                        callback: function($$v) {
                          _vm.selectedServices = $$v
                        },
                        expression: "selectedServices"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-checkbox", {
                      staticClass: "ml-6",
                      attrs: {
                        label: _vm.$t("components.externalServices.beacon"),
                        value: "beacon",
                        disabled:
                          !_vm.hasActiveBeaconUserId() ||
                          (_vm.selectedServices.length == 1 &&
                            _vm.selectedServices.indexOf("beacon") !== -1)
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeActivesServices()
                        }
                      },
                      model: {
                        value: _vm.selectedServices,
                        callback: function($$v) {
                          _vm.selectedServices = $$v
                        },
                        expression: "selectedServices"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-list-item-title", [
                  _vm._v(
                    _vm._s(_vm.$t("components.externalServices.releaseFor"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        label: _vm.$t("components.externalServices.green"),
                        value: "green",
                        disabled:
                          _vm.selectedStatus.length == 1 &&
                          _vm.selectedStatus.indexOf("green") !== -1
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeStatus()
                        }
                      },
                      model: {
                        value: _vm.selectedStatus,
                        callback: function($$v) {
                          _vm.selectedStatus = $$v
                        },
                        expression: "selectedStatus"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-checkbox", {
                      staticClass: "ml-6",
                      attrs: {
                        label: _vm.$t("components.externalServices.yellow"),
                        value: "yellow",
                        disabled:
                          _vm.selectedStatus.length == 1 &&
                          _vm.selectedStatus.indexOf("yellow") !== -1
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeStatus()
                        }
                      },
                      model: {
                        value: _vm.selectedStatus,
                        callback: function($$v) {
                          _vm.selectedStatus = $$v
                        },
                        expression: "selectedStatus"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-checkbox", {
                      staticClass: "ml-6",
                      attrs: {
                        label: _vm.$t("components.externalServices.red"),
                        value: "red",
                        disabled:
                          _vm.selectedStatus.length == 1 &&
                          _vm.selectedStatus.indexOf("red") !== -1
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeStatus()
                        }
                      },
                      model: {
                        value: _vm.selectedStatus,
                        callback: function($$v) {
                          _vm.selectedStatus = $$v
                        },
                        expression: "selectedStatus"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }