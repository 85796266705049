var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", [_vm._v("LogFilter")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.filter,
          expression: "filter"
        }
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.filter },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.filter = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }