<template>
  <v-dialog v-model="showTeamsModal" persistent max-width="560" @keydown.esc="closeModal">
     <v-card>
      <HeaderModal
        :titleModal="$t('components.organisationGrid.showTeams')"
        :closeModalFunction="closeModal"
      />
     <v-row class="mx-3">
        <v-col cols="12" class="containerTeams" v-if="getTeams.length">
          <div  v-for="(team) in getTeams" :key="team.id">
            <div v-if="team.id" class="font-weight-bold mb-2">
              {{team.name || '' }}
            </div>
            <div class="d-flex flex-wrap" v-if="team.children">
              <v-card v-for="(child) in team.children" :key="child.id" class="cardWidth mr-2 mb-2" :style="getBorderByStatusFull(getPersonByUuid(child.supervisors[0]))">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-img :src="getAvatarByType(child.supervisors[0])" class="white--text align-end" height="100px">
                      <div class="gradientBg">
                        <v-card-subtitle style="color: #fff !important"
                          class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate">
                          {{ titelPerson(getPersonByUuid(child.supervisors[0])) }} {{ namePerson(getPersonByUuid(child.supervisors[0])) }}
                        </v-card-subtitle>
                        <v-card-subtitle v-if="child.supervisors[0] && hasRepresentative(child.supervisors[0])"
                          :style="getBorderByStatusFull(getPersonByUuid(hasRepresentative(child.supervisors[0])))"
                          class="d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between"
                          @click.stop.prevent>
                          <div class="representative-subtitle text-truncate px-1">
                            <!-- <CallIcon v-if="!disableCallBtn(getPersonByUuid(hasRepresentative(child.supervisors[0])))" :person="getPersonByUuid(hasRepresentative(child.supervisors[0]))" :isRegularGrid="true"
                              class="mr-1"></CallIcon> -->
                            <span class="colorWhite">
                              {{ namePerson(getPersonByUuid(hasRepresentative(child.supervisors[0]))) }}
                            </span>
                          </div>
                          <!-- <ButtonsFrontCard :person="person" :uuid="person.uuid" sectionName="members" class="pt-1"
                            :flipCard="flipCard" :index="index" :typeData="typeData"
                            style="position: inherit !important" /> -->
                        </v-card-subtitle>
                        <!-- <ButtonsFrontCard v-if="!hasRepresentative(person.uuid)" :person="person" :uuid="person.uuid"
                          sectionName="members" :flipCard="flipCard" :index="index" :typeData="typeData" /> -->
                      </div>
                      <!-- <ButtonsCardWrapper :person="person" :isRegularGrid="true" :showMultiUserCallIcon="true" /> -->
                    </v-img>
                  </div>
                </template>
                <UserInfoTooltip :person="getPersonByUuid(child.supervisors[0])"></UserInfoTooltip>
              </v-tooltip>
            </v-card>
            </div>
          </div>
        </v-col>
        <v-col v-else cols="12" class="containerTeams d-flex justify-center align-center">
          <div class="text-center">
            {{ $t('components.organisationGrid.userNotFound') }}
          </div>
        </v-col>
      </v-row>
      <template>
        <FooterModal :closeModalFunction="closeModal">
        </FooterModal>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { getOrganigramTeamsByUserLevel4, getBorderByStatusFull, getAvatarByType, getOrganigramTeamsByUserLevel3 } from "../../utils/basicFunctions"
import { hasRepresentative } from "../../utils/representatives";
import {
  showTeamsModalStore,
  resetShowTeamsModalEvent,
} from "../../effector/modals";
import UserInfoTooltip from "../content/userInfoTooltip.vue";

export default {
  props: [],
  components: {HeaderModal, FooterModal, UserInfoTooltip},
  data: () => ({
    state: store.state,
  }),
  
  effector: {
    showTeamsModal: showTeamsModalStore,
  },
  mounted() {
  },
  destroyed() {

  },
  methods: {
    hasRepresentative(uuid) {
      return hasRepresentative(uuid);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    getPersonByUuid(uuid) {
      return store.getPersonByUuid(uuid);
    },
    namePerson(person) {
      if (person && person.user.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      return store.getTitelForUuid(person.uuid);
    },
    closeModal() {
      resetShowTeamsModalEvent();
    },
  },
  computed: {
    getTeams(){
      const organigram = JSON.parse(
        JSON.stringify(this.state.namespaceSettings.newOrganigram || [])
      );
      const usersUuid = this.showTeamsModal;
      const teams = [...getOrganigramTeamsByUserLevel3(organigram, 0, usersUuid), ...getOrganigramTeamsByUserLevel4(organigram, 0, usersUuid)];
      return teams || [];
    },
  }
};
</script>

<style scoped lang="scss">
.colorWhite {
  color: white !important;
}
.containerTeams {
  max-height: 470px;
  min-height: 150px;
  overflow: auto;
}
.cardWidth {
  max-width: 100px;
  box-shadow: none !important;
}
.theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}

.theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}

.gradientBg {
  position: relative;
}
</style>