<template>
  <div class="InviteFormContainer">
    <v-card class="pt-6 pl-10 pr-10 h100">
      <SambaRoomGrid ref="confRoom" />
    </v-card>
  </div>
</template>
<script>
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import SambaRoomGrid from "./sambaRoomGrid.vue";
import { isMobile } from "../../lib/mobileUtil";
export default {
  props: [],
  components: { SambaRoomGrid },
  data() {
    return {
      isMobile: isMobile(),
      tab: 0,
      state: store.state,
      loadGroup: false,
      membersFromConference: null,
      loadConferenceForm: null,
      isInserting: false,
    };
  },
  effector: {},
  watch: {},
  beforeMount() {},
  destroyed() {},
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.InviteFormContainer .v-card {
  box-shadow: none;
}
.InviteFormContainer {
  height: calc(100vh - 124px);
  overflow: auto;
  position: relative;
}
.h100 {
  height: 100%;
}
</style>
