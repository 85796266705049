var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "PWAButton" }, [
    _vm.deferredPrompt
      ? _c("div", [
          _c("button", { on: { click: _vm.register } }, [
            _vm._v("Register PWA!")
          ])
        ])
      : _vm.outcome
      ? _c("div", [
          _vm._v("\n    PWA Register Outcome: " + _vm._s(_vm.outcome) + "\n  ")
        ])
      : _vm.installed
      ? _c("div", [
          _vm._v("\n    PWA Installed! " + _vm._s(_vm.installed) + "\n  ")
        ])
      : _c("div", [_vm._v("\n    Cannot Register PWA!\n  ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }