var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      ref: "content",
      class: {
        "pt-5": _vm.fromWhere == "content",
        "px-5": _vm.fromWhere == "content"
      },
      attrs: { flat: "" }
    },
    [
      _vm.importError
        ? _c(
            "div",
            { staticClass: "floatingError" },
            [
              _c("v-alert", { attrs: { type: "error", dismissible: true } }, [
                _vm._v(_vm._s(_vm.importError))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-0 mb-5" },
        [
          _c(
            "v-btn",
            {
              staticClass: "btnTab",
              class: { active: _vm.activeTab == "users" },
              on: {
                click: function($event) {
                  return _vm.setActiveTab("users")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("generics.users")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab !== "users",
              expression: "activeTab !== 'users'"
            }
          ],
          staticClass: "tableMenu pa-1"
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _vm.activeTab == "users"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.addRow }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "18px" },
                                      attrs: { icon: ["fal", "plus"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2597419710
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("components.excelEditor.addRow")))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeTab == "users"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.undo }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["far", "undo"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        998957919
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("components.excelEditor.undo")))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeTab == "users"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.redo }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["far", "redo"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4216430175
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("components.excelEditor.redo")))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "tableSearch ml-2" }, [
                _c("input", {
                  ref: "searchInput",
                  attrs: { dense: "", placeholder: _vm.$t("generics.search") },
                  on: {
                    input: _vm.debouncedSearchFn,
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search.apply(null, arguments)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "icon", on: { click: _vm.search } },
                  [
                    _c("font-awesome-icon", {
                      style: { fontSize: "15px" },
                      attrs: { icon: ["far", "search"] }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeTab === "organisation"
            ? _c("div", [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(
                    _vm._s(_vm.$t("generics.organisationChart")) +
                      "\n        " +
                      _vm._s(_vm.virtualOfficePrefix)
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.activeTab == "organisation"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.state.simpleOrganisationManagerVisible = true
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["far", "edit"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3336410173
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.excelEditor.openOrganisationManager"
                            )
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeTab == "organisation"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: { click: _vm.toggleCollapseAll }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["far", "sort-alt"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1572015913
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.excelEditor.toggleCollapseAll")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.scrollRows(10)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "16px" },
                                attrs: { icon: ["far", "chevron-down"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.excelEditor.scrollDownRows", [
                          _vm.rowsToScrollCount
                        ])
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.scrollRows(-10)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "16px" },
                                attrs: { icon: ["far", "chevron-up"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.excelEditor.scrollUpRows", [
                          _vm.rowsToScrollCount
                        ])
                      )
                    )
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.disabledAllButtons,
              expression: "disabledAllButtons"
            }
          ],
          staticClass: "loaderContainer"
        },
        [
          _c(
            "div",
            {
              staticClass: "htVisibilityParadoxContainer d-flex loaderWrapper"
            },
            [
              _c(
                "span",
                { staticClass: "custom-loader mx-auto" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "loaderColor",
                    style: { fontSize: "120px", width: "auto" },
                    attrs: { icon: ["fal", "spinner-third"] }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.loaded ||
                ((_vm.activeTab === "users" ||
                  _vm.activeTab === "organisation") &&
                  !_vm.disabledAllButtons),
              expression:
                "\n      !loaded ||\n      ((activeTab === 'users' || activeTab === 'organisation') &&\n        !disabledAllButtons)\n    "
            }
          ],
          staticClass: "htVisibilityParadoxContainer"
        },
        [
          _vm.activeTab === "users"
            ? [
                _c("UserManagementWrapper", {
                  attrs: {
                    users: _vm.users.data,
                    hideSaveButtons: _vm.hideSaveButtons
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "organisation"
            ? _c("v-btn", { on: { click: _vm.printTable } }, [
                _vm._v("Print Table")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "hot-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.loaded ||
                    (_vm.activeTab === "organisation" &&
                      _vm.state.excelTotalView),
                  expression:
                    "\n        !loaded || (activeTab === 'organisation' && state.excelTotalView)\n      "
                }
              ],
              ref: "organisationTable",
              attrs: {
                data: _vm.organisation.data,
                settings: Object.assign(
                  {},
                  _vm.settings,
                  _vm.organisation.settings
                ),
                height: "calc(100vh - 370px)"
              }
            },
            _vm._l(_vm.organisation.columns, function(column, key) {
              return _c("hot-column", {
                key: key,
                attrs: { data: column.data, settings: column.settings }
              })
            }),
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mt-4" }),
      _vm._v(" "),
      _c("div", { staticClass: "divButtonsExcel pb-4" }, [
        _c(
          "div",
          { staticClass: "divLeft" },
          [
            _c(
              "v-menu",
              {
                attrs: { rounded: 0, "offset-y": "", top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var attrs = ref.attrs
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "white--text mr-2",
                                attrs: {
                                  depressed: "",
                                  color: "primary",
                                  disabled: _vm.disabledAllButtons
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("components.excelEditor.uploadNewFile")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: { link: "" },
                        on: {
                          click: function($event) {
                            return _vm.$refs["uploadfileReplace"].click()
                          }
                        }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.excelEditor.uploadReplace")
                            )
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      {
                        attrs: { link: "" },
                        on: {
                          click: function($event) {
                            return _vm.$refs["uploadfileMerge"].click()
                          }
                        }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(
                            _vm._s(_vm.$t("components.excelEditor.uploadMerge"))
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$refs["uploadfileDelete"].click()
                          }
                        }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(
                            _vm._s(_vm.$t("components.excelEditor.deleteData"))
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              ref: "uploadfileReplace",
              staticClass: "hidden",
              staticStyle: { display: "none" },
              attrs: { type: "file" },
              on: {
                change: function(event) {
                  return _vm.onExcelFileChange(event, "replace")
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              ref: "uploadfileMerge",
              staticClass: "hidden",
              staticStyle: { display: "none" },
              attrs: { type: "file" },
              on: {
                change: function(event) {
                  return _vm.onExcelFileChange(event, "merge")
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              ref: "uploadfileDelete",
              staticClass: "hidden",
              staticStyle: { display: "none" },
              attrs: { type: "file" },
              on: {
                change: function(event) {
                  return _vm.deleteDataAfterConfirmation(event)
                }
              }
            }),
            _vm._v(" "),
            _vm.activeTab === "users"
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "btn customButton",
                                    attrs: {
                                      disabled: _vm.disabledAllButtons,
                                      depressed: "",
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.downloadExcel()
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.excelEditor.download")
                                    )
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3314290710
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("components.excelEditor.downloadTT"))
                      )
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "divRight" },
          [
            _vm.activeTab === "users"
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "btn customButton mr-2",
                                    class: _vm.enabledButton
                                      ? ""
                                      : "savingData",
                                    attrs: {
                                      depressed: "",
                                      color: "primary",
                                      disabled: _vm.disabledAllButtons
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveNewExcel()
                                      }
                                    }
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(_vm.$t("generics.save")))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      891329846
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("components.excelEditor.tooltipSave"))
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.activeTab === "users"
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "btn customButton mr-2",
                                    class: _vm.enabledButton
                                      ? ""
                                      : "savingData",
                                    attrs: {
                                      depressed: "",
                                      color: "primary",
                                      disabled: _vm.disabledAllButtons
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveNewExcel(true)
                                      }
                                    }
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(_vm.$t("generics.ok")))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3917955461
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("generics.saveAndClose")))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _vm.fromWhere == "content"
                          ? _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "btn customButton",
                                  class: _vm.enabledButton ? "" : "disabled",
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    disabled:
                                      !_vm.enabledButton ||
                                      _vm.disabledAllButtons
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeExcel()
                                    }
                                  }
                                },
                                on
                              ),
                              [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.excelEditor.tooltipClose")))
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("color-picker", { ref: "colorPicker" }),
      _vm._v(" "),
      _c("helpscout-dialog", { ref: "helpscoutDialog" }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showModalOrganisationInfo,
            callback: function($$v) {
              _vm.showModalOrganisationInfo = $$v
            },
            expression: "showModalOrganisationInfo"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("components.excelEditor.organisationInfo0")
                      ) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("components.excelEditor.organisationInfo1"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("components.excelEditor.organisationInfo2"))
                  )
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.excelEditor.organisationInfo2_1")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.excelEditor.organisationInfo2_2")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.excelEditor.organisationInfo2_3")
                        )
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("components.excelEditor.organisationInfo3"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("components.excelEditor.organisationInfo4"))
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("components.excelEditor.organisationInfo5"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "8", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showModalOrganisationInfo = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.excelEditor.shutDown")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showModalNote,
            callback: function($$v) {
              _vm.showModalNote = $$v
            },
            expression: "showModalNote"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("components.excelEditor.uploadNewFile")) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.$t("components.excelEditor.noteNewUpload")))
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "primary" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "8", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showModalNote = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showModalNote = false
                                  _vm.$refs["uploadfileReplace"].click()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.accept")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      ),
      _vm._v(" "),
      _c("DeleteDataExcelModal", {
        attrs: {
          titleModal: _vm.$t("components.excelEditor.deleteData"),
          showModal: _vm.showDeleteModal,
          closeModal: _vm.showCloseDeleteDataModal,
          userList: _vm.confirmDeleteUserList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }