<template>
    <div>
        <div class="d-flex align-start">
            <router-link to="/socialmedia/profile" class="d-flex align-center text-decoration-none"
                v-if="viewSelected === 'posting'">
                <UserAvatar :size="80" :src="getAvatarForUuid(ownUUID)" class="ml-3 mr-6 mt-4" />
            </router-link>

            <input ref="fileInput" type="file" multiple
                accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="handleFileChange" style="display: none;" />
            <div class="flex-grow-1">
                <div class="mt-4" v-if="viewSelected === 'posting'">
                    <div class="position-relative">
                        <v-textarea :model-value="postDescription" @update:model-value="postDescription = $event"
                            variant="outlined" :label="$t('components.socialMedia.weTogetherToday')" rows="2"
                            hide-details no-resize />
                        <v-btn :disabled="(imagePreviews.length + documentPreviews.length) >= maxAttachments" @click="triggerFileInput()" v-bind="props"
                            icon variant="text" size="small" class="me-auto position-absolute bottom-0 ml-1">
                            <font-awesome-icon :icon="['fal', 'image']" :style="{ fontSize: '20px' }" />
                        </v-btn>
                    </div>
                    <div class="w100 d-flex">
                        <!-- Image Previews -->
                        <div class="d-flex mt-3 gap-3 flex-wrap">
                            <div v-for="(image, index) in imagePreviews" :key="index" class="preview-container mr-2">
                                <img :src="image.dataUrl" alt="Preview" class="preview-image" />
                                <div class="overlay" @click="removeImage(index)">
                                    <font-awesome-icon :icon="['fal', 'trash']" class="trash-icon" />
                                </div>
                            </div>
                        </div>
                        <!-- Document Previews -->
                        <div class="d-flex mt-3 gap-3 flex-wrap">
                            <div v-for="(document, index) in documentPreviews" :key="index"
                                class="document-preview-container mr-2">
                                <font-awesome-icon :icon="['fal', 'file']" class="document-icon" />
                                <span class="ml-1 document-text text-truncate">
                                    {{ document.name }}
                                </span>
                                <div class="overlay" @click="removeDocument(index)">
                                    <font-awesome-icon :icon="['fal', 'trash']" class="trash-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4" v-if="viewSelected === 'whatswork'">
                    <div class="position-relative">
                        <v-textarea :model-value="whatsworkDescription" @update:model-value="whatsworkDescription = $event"
                            variant="outlined" :label="$t('components.socialMedia.whatHaveYouComplete')" rows="2"
                            hide-details no-resize>
                        </v-textarea>
                        <v-btn :disabled="(imagePreviews.length + documentPreviews.length) >= maxAttachments" @click="triggerFileInput()" v-bind="props"
                                icon variant="text" size="small" class="me-auto position-absolute bottom-0 ml-1">
                                <font-awesome-icon :icon="['fal', 'image']" :style="{ fontSize: '20px' }" />
                            </v-btn>
                    </div>
                    <div class="w100 d-flex">
                        <!-- Image Previews -->
                        <div class="d-flex mt-3 gap-3 flex-wrap">
                            <div v-for="(image, index) in imagePreviews" :key="index" class="preview-container mr-2">
                                <img :src="image.dataUrl" alt="Preview" class="preview-image" />
                                <div class="overlay" @click="removeImage(index)">
                                    <font-awesome-icon :icon="['fal', 'trash']" class="trash-icon" />
                                </div>
                            </div>
                        </div>
                        <!-- Document Previews -->
                        <div class="d-flex mt-3 gap-3 flex-wrap">
                            <div v-for="(document, index) in documentPreviews" :key="index"
                                class="document-preview-container mr-2">
                                <font-awesome-icon :icon="['fal', 'file']" class="document-icon" />
                                <span class="ml-1 document-text text-truncate">
                                    {{ document.name }}
                                </span>
                                <div class="overlay" @click="removeDocument(index)">
                                    <font-awesome-icon :icon="['fal', 'trash']" class="trash-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-4 d-flex justify-end align-center">
            <p class="userAgreementLink mr-4" @click="openUserAgreement()">{{ $t('components.socialMedia.agreement') }}</p>
            <v-btn :disabled="disableSavePost" @click="submitPost" v-bind="props" flat color="primary">{{
                $t('components.socialMedia.post') }}</v-btn>
        </div>
    </div>
</template>


<script>
import store, { EventBus } from "../../../../store";
import { isMobile } from "../../../../lib/mobileUtil";
import UserAvatar from "../common/userAvatar.vue";


export default {
    props: ["viewSelected"],
    components: { UserAvatar },
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
            postDescription: '',
            whatsworkDescription: '',
            imagePreviews: [],
            documentPreviews: [],
            maxAttachments: 8,
            canIPostWhatswork: false
        };
    },
    watch: {
        viewSelected: {
            inmediate: true,
            handler: function (value) {
                if (value === 'whatswork') {
                    this.checkIfIPostWhatswork();
                } else {
                }
                this.cleanPost();
            }
        }
    },
    mounted() {
        EventBus.$on('removeWhatsWork', this.checkIfIPostWhatswork);
    },
    unmounted() {
        EventBus.$on('removeWhatsWork', this.checkIfIPostWhatswork);
    },
    methods: {
        openUserAgreement(){
            EventBus.$emit('openUserAgreement');
        },
        checkIfIPostWhatswork() {
            fetch(`/api/socialmedia/whatswork-has-posted-today?userId=${this.ownUUID}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then(async (response) => {
                    const result = await response.json();
                    if (!result.error) {
                        if (result.data) {
                            this.canIPostWhatswork = false;
                        } else {
                            this.canIPostWhatswork = true;
                        }
                    } else {
                        // Manejar errores aquí
                    }
                })
                .catch(err => console.error("Error al obtener whatsWork:", err))
                .finally(() => this.isLoadingWhatsWork = false);
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const files = Array.from(event.target.files);
            const remainingSlots = this.maxAttachments - (this.imagePreviews.length + this.documentPreviews.length);
            if (remainingSlots <= 0) return; // No permitir más archivos si ya se alcanzó el límite
            const filesToAdd = files.slice(0, remainingSlots);
            filesToAdd.forEach(file => {
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.imagePreviews.push({
                            file,
                            dataUrl: e.target.result,
                        });
                    };
                    reader.readAsDataURL(file);
                } else {
                    // Si no es una imagen, lo agregamos como un documento
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.documentPreviews.push({
                            file,
                            base64: e.target.result, // Guardamos la base64 del documento
                            name: file.name,
                            type: file.type,
                            size: file.size,
                        });
                    };
                    reader.readAsDataURL(file); // Leemos el documento como base64
                }
            });
        },
        async compressAndConvertToBs64(file) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const MAX_WIDTH = 600; // max width
                        const scaleSize = MAX_WIDTH / img.width;
                        canvas.width = MAX_WIDTH;
                        canvas.height = img.height * scaleSize;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                        const base64Image = canvas.toDataURL('image/jpeg', 0.6); // Calidad 60%
                        resolve(base64Image);
                    };
                };
            });
        },
        removeImage(index) {
            this.imagePreviews.splice(index, 1);
        },
        removeDocument(index) {
            this.documentPreviews.splice(index, 1);
        },
        getAvatarForUuid(uuid) {
            return store.getAvatarForUuid(uuid);
        },
        async submitPost() {
            EventBus.$emit("closeEdit");
            if (this.viewSelected === 'posting') {
                if (this.postDescription.trim() === "") {
                    return;
                }
                const compressedImages = await Promise.all(this.imagePreviews.map(image =>
                    this.compressAndConvertToBs64(image.file)
                ));
                const post = {
                    userId: this.ownUUID,
                    content: this.postDescription.replace(/\n/g, "<br>"),
                    images: compressedImages,
                    documents: this.documentPreviews.map(doc => ({
                        name: doc.name,
                        type: doc.type,
                        base64: doc.base64
                    })), 
                    visibility: "public", //TODO Hookup
                    likes: [],
                    comments: [],
                };
                this.savePost(post)
            } else {
                if (this.whatsworkDescription.trim() === "") {
                    return;
                }
                const compressedImages = await Promise.all(this.imagePreviews.map(image =>
                    this.compressAndConvertToBs64(image.file)
                ));
                const post = {
                    userId: this.ownUUID,
                    content: this.whatsworkDescription.replace(/\n/g, "<br>"),
                    images: compressedImages,
                    documents: this.documentPreviews.map(doc => ({
                        name: doc.name,
                        type: doc.type,
                        base64: doc.base64
                    })),
                    likes: [],
                    comments: [],
                };
                this.saveWhatsWork(post)
            }

        },
        cleanPost() {
            this.postDescription = '';
            this.whatsworkDescription = '';
            this.imagePreviews = [];
            this.documentPreviews = [];
        },
        // Simula la inserción en MongoDB y agrega el post a la lista local de posts
        addPostToLocalData(post) {
            // Emitir el post al componente principal para añadirlo al estado
            this.$emit("addPost", post);
        },
        addWhatsWorkToLocalData(post) {
            // Emitir el post al componente principal para añadirlo al estado
            this.$emit("addWhatsWork", post);
        },
        savePost(post) {
            fetch("/api/socialmedia/post", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(post)
            }).then(response => response.json())
                .then((result) => {
                    if (result.success) {
                        this.cleanPost();
                        const data = result.data || post
                        this.addPostToLocalData(data);
                    } else {
                        //TODO: Handler error
                    }
                })

        },
        saveWhatsWork(post) {
            fetch("/api/socialmedia/whatswork", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(post)
            }).then(response => response.json())
                .then((result) => {
                    if (result.success) {
                        this.cleanPost();
                        const data = result.data || post
                        this.addWhatsWorkToLocalData(data);
                        this.checkIfIPostWhatswork();
                    } else {
                        //TODO: Handler error
                    }
                })

        }
    },
    computed: {
        disableSavePost() {
            if (this.viewSelected === 'whatswork') {
                return !this.canIPostWhatswork || !this.whatsworkDescription.length
            } else {
                return !this.postDescription.length
            }
        }
    },
};
</script>

<style scoped lang="scss">
.userAgreementLink {
  color: #0066cc;
  transition: color 0.2s;
  cursor: pointer;
}

.userAgreementLink:hover {
  color: #004a99;
}
.preview-container {
    position: relative;
    width: 60px;
    height: 6cap;
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}

.document-preview-container {
    position: relative;
    width: 60px;
    height: 6cap;
    border: 1px solid #ddd;
    /* Borde alrededor del contenedor */
    border-radius: 8px;
    /* Esquinas redondeadas */
    overflow: hidden;
    /* Evita que el contenido se desborde fuera del contenedor */
    background-color: #f9f9f9;
    /* Color de fondo */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    /* Espaciado interno */
}

.document-preview-text {
    font-size: 14px;
    white-space: nowrap;
    /* Evita que el texto se desborde */
    text-overflow: ellipsis;
    /* Si el texto es muy largo, muestra "..." al final */
    overflow: hidden;
    /* Evita que el texto se desborde fuera del contenedor */
    max-width: 100%;
    /* Asegura que el texto no se salga del contenedor */
    text-align: center;
    /* Centra el texto */
}

.document-text {
    font-size: 12px;
    max-width: 60px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 0.5rem;
}

.preview-container:hover .overlay {
    opacity: 1;
}

.document-preview-container:hover .overlay {
    opacity: 1;
    /* Muestra el overlay al pasar el ratón */
}

.trash-icon {
    color: white;
    font-size: 1.5rem;
}
</style>
