var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.beforeEndingCallModal.title")
              }
            }),
            _vm._v(" "),
            _c("v-card-text", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.amIOwnerMeeting
                      ? _vm.$t("components.beforeEndingCallModal.text")
                      : _vm.$t(
                          "components.beforeEndingCallModal.textRegularUser"
                        )
                  ) +
                  "\n        "
              ),
              _vm.amIOwnerMeeting
                ? _c("div", { staticClass: "mt-8 d-flex justify-center" }, [
                    _c(
                      "div",
                      { staticClass: "mx-3 pointer" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            on: {
                              click: function($event) {
                                return _vm.addMoreTime()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("components.userAgreementModal.ok"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            on: {
                              click: function($event) {
                                return _vm.endCall()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.beforeEndingCallModal.end")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }