// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w100[data-v-5b0b9620] {
  width: 100%;
}
.lockMessageIcon[data-v-5b0b9620] {
  position: absolute;
  right: 28px;
  top: -6px;
}
.textBlue[data-v-5b0b9620] {
  color: #2a3133;
}
.btnRed[data-v-5b0b9620] {
  border-radius: 4px;
}
.vueScroll .__view[data-v-5b0b9620] {
  width: unset !important;
}
.divNotNotis[data-v-5b0b9620] {
  height: 40px;
  padding: 9px;
}
.divNotNotis .notNotifications[data-v-5b0b9620] {
  color: var(--form-text);
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/notificationsWaitingRoomDropDownEffector.vue","webpack://./notificationsWaitingRoomDropDownEffector.vue"],"names":[],"mappings":"AACA;EACE,WAAA;ACAF;ADEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;ACCF;ADCA;EACE,cAAA;ACEF;ADCA;EAEE,kBAAA;ACCF;ADEE;EACE,uBAAA;ACCJ;ADEA;EACE,YAAA;EACA,YAAA;ACCF;ADAE;EACE,uBAAA;ACEJ","sourcesContent":["\n.w100{\n  width: 100%;\n}\n.lockMessageIcon {\n  position: absolute;\n  right: 28px;\n  top: -6px;\n}\n.textBlue {\n  color: #2a3133;\n}\n\n.btnRed {\n  // background: red;\n  border-radius: 4px;\n}\n.vueScroll {\n  .__view {\n    width: unset !important;\n  }\n}\n.divNotNotis {\n  height: 40px;\n  padding: 9px;\n  .notNotifications {\n    color: var(--form-text);\n  }\n}\n",".w100 {\n  width: 100%;\n}\n\n.lockMessageIcon {\n  position: absolute;\n  right: 28px;\n  top: -6px;\n}\n\n.textBlue {\n  color: #2a3133;\n}\n\n.btnRed {\n  border-radius: 4px;\n}\n\n.vueScroll .__view {\n  width: unset !important;\n}\n\n.divNotNotis {\n  height: 40px;\n  padding: 9px;\n}\n.divNotNotis .notNotifications {\n  color: var(--form-text);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
