const OPENAI_API_KEY = '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';

export async function sendToOpenAIWhisper(input, language = "de-DE") {
  // Verificamos si el input es un audio Blob
  if (input instanceof Blob) {
    // Si es un Blob, es audio
    const formData = new FormData();
    formData.append("file", input, "audio.wav");
    formData.append("model", "whisper-1"); // OpenAI Whisper API
    // formData.append("language", language);  // set language

    try {
      const response = await fetch("https://api.openai.com/v1/audio/transcriptions", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: formData,
      });

      const data = await response.json();
      return data.text || "Could not transcribe";
    } catch (error) {
      console.error("Whisper API error:", error);
      return "Transcription error";
    }
  } else if (typeof input === 'string') {
    // Si es texto, se utiliza el endpoint adecuado para el modelo de chat
    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-4o-mini", // Modelo gpt-4o-mini
          messages: [
            { role: "system", content: `You are an assistant that only translates the received text into ${language}.` },
            { role: "user", content: input }],
        }),
      });

      const data = await response.json();
      return data.choices[0]?.message?.content?.trim() || "Could not process text";
    } catch (error) {
      console.error("Text processing error:", error);
      return "Text processing error";
    }
  } else {
    throw new Error("Invalid input type: must be a Blob or string");
  }
}
