var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelEdit.apply(null, arguments)
        }
      },
      model: {
        value: _vm.state.showUserModalMultiSelect,
        callback: function($$v) {
          _vm.$set(_vm.state, "showUserModalMultiSelect", $$v)
        },
        expression: "state.showUserModalMultiSelect"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("components.userSelectModal.selectGroupMembers")
                  ) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "px-5", style: { width: "100%", margin: 0 } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "mx-1 label600",
                    attrs: {
                      items: _vm.dataCombobox.users,
                      select: "",
                      "search-input": _vm.searchUsers,
                      "item-text": "name",
                      label: _vm.$t("generics.users"),
                      multiple: "",
                      dense: "",
                      counter: "",
                      rules: _vm.rules,
                      "auto-select-first": ""
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.searchUsers = $event
                      },
                      "update:search-input": function($event) {
                        _vm.searchUsers = $event
                      },
                      change: function($event) {
                        _vm.searchUsers = ""
                      }
                    },
                    model: {
                      value: _vm.state.user.activeGroupMembers.members,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user.activeGroupMembers,
                          "members",
                          $$v
                        )
                      },
                      expression: "state.user.activeGroupMembers.members"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "mx-1 label600",
                    attrs: {
                      items: _vm.dataCombobox.guests,
                      select: "",
                      "search-input": _vm.searchVisitors,
                      "item-text": "name",
                      label: _vm.$t("generics.guest"),
                      multiple: "",
                      dense: "",
                      counter: "",
                      rules: _vm.rules,
                      "auto-select-first": ""
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.searchVisitors = $event
                      },
                      "update:search-input": function($event) {
                        _vm.searchVisitors = $event
                      },
                      change: function($event) {
                        _vm.searchVisitors = ""
                      }
                    },
                    model: {
                      value: _vm.state.user.activeGroupMembers.guests,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user.activeGroupMembers,
                          "guests",
                          $$v
                        )
                      },
                      expression: "state.user.activeGroupMembers.guests"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-combobox", {
                    staticClass: "mx-1 label600",
                    attrs: {
                      items: _vm.dataCombobox.visitors,
                      select: "",
                      "search-input": _vm.searchGuests,
                      "item-text": "name",
                      label: _vm.$t("generics.myVisitors"),
                      multiple: "",
                      dense: "",
                      counter: "",
                      "auto-select-first": "",
                      rules: _vm.rules
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.searchGuests = $event
                      },
                      "update:search-input": function($event) {
                        _vm.searchGuests = $event
                      },
                      change: function($event) {
                        _vm.searchGuests = ""
                      }
                    },
                    model: {
                      value: _vm.state.user.activeGroupMembers.visitors,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.state.user.activeGroupMembers,
                          "visitors",
                          $$v
                        )
                      },
                      expression: "state.user.activeGroupMembers.visitors"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "px-2 btns",
                  style: { width: "100%", margin: 0 },
                  attrs: { flex: "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { lg: "6", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.cancelEdit.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.checkMaxUsers
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.prepareUserDataSelection.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }