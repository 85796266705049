<template>
  <div v-show="showCroppie">
    <v-row class="d-flex mx-auto pt-5 pb-2 pl-3">
      <v-col cols="12" class="py-0">
        <vue-croppie
          ref="croppieRefSquare"
          class="sliderSize"
          :enableResize="false"
          :enforceBoundary="true"
          :enableExif="true"
          :boundary="{ width: 256, height: 256 }"
          :viewport="{ width: 246, height: 246, type: 'square' }"
        ></vue-croppie>
      </v-col>
      <div class="rotateIcons">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey" @click="rotate(-90)">
              <font-awesome-icon
                :icon="['fal', 'undo']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.navbar.rotateLeft") }}</span>
        </v-tooltip>
        <br />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey" @click="rotate(90)">
              <font-awesome-icon
                :icon="['fal', 'undo']"
                :style="{ fontSize: '20px' }"
                class="fa-flip-horizontal"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.navbar.rotateRight") }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-card color="primary" class="py-2 pl-4 footerModal">
      <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
        <v-col class="px-0 py-0" align="right">
          <v-btn class="buttonPrimaryFooter" color="white" @click="crop()">{{
            $t("generics.save")
          }}</v-btn>
          <v-btn
            class="ml-3 mr-2"
            color="white"
            style="border-radius: 4px; min-width: 45px"
            icon
            tile
            outlined
            @click="cancelCrop()"
          >
            <font-awesome-icon
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
import store from "../../store";
import { getPictureSize } from "../../utils/basicFunctions";
export default {
  components: {},
  props: [
    "showCroppie",
    "closeCroppie",
    "person",
    "temporalAvatar",
    "resetInputAvatar",
  ],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    async crop() {
      const options = {
        type: "base64",
        size: { width: 256, height: 256 },
        format: "jpg",
      };
      await this.$refs.croppieRefSquare
        .result(options)
        .then((output) => {
          store.setAvatar(output, this.person.uuid);
          if (((this.state.group[this.person.uuid] || {}).user || {}).avatarRectangle)
            store.setAvatarRectangle(output, this.person.uuid);
        });
      this.closeCroppie();
    },
    cancelCrop() {
      this.closeCroppie();
      this.resetInputAvatar();
    },
    rotate(rotationAngle) {
      this.$refs.croppieRefSquare.rotate(rotationAngle);
    },
  },
  computed: {
    getPictureSize() {
      return getPictureSize();
    },
  },
  mounted() {
    if (this.temporalAvatar) {
      this.$refs.croppieRefSquare.bind({
        url: this.temporalAvatar,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}
</style>