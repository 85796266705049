var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "popper",
        {
          attrs: {
            trigger: "clickToOpen",
            "force-show": "",
            "boundaries-selector": _vm.reference,
            options: {
              placement: "auto"
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "popper" },
            [
              _c("v-color-picker", {
                staticClass: "ma-2",
                attrs: { "hide-inputs": "", color: _vm.color },
                on: {
                  "update:color": function($event) {
                    _vm.color = $event
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.hide(true)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.ok")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.hide(false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "reference" }, slot: "reference" })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }