var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "580" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.toggleMeetingTimeRelay.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.meetingTime.forceRelay"),
              closeModalFunction: _vm.toggleMeetingTimeRelay
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 px-3" },
            [
              _c(
                "v-col",
                { staticClass: "col-12 px-1 py-1 pt-2" },
                [
                  _c("v-switch", {
                    staticClass: "my-0 py-2",
                    attrs: {
                      disabled: _vm.relayByAdmin,
                      label: _vm.$t(
                        "components.adminSettingsModal.relayServerInfo0"
                      ),
                      width: "100%"
                    },
                    on: {
                      change: function($event) {
                        return _vm.userRelay()
                      }
                    },
                    model: {
                      value: _vm.individualRelay,
                      callback: function($$v) {
                        _vm.individualRelay = $$v
                      },
                      expression: "individualRelay"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { staticClass: "py-0 switchWrapper" },
                    [
                      _c("v-list-item-content", [
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.adminSettingsModal.relayServerInfo3"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.adminSettingsModal.relayServerInfo2"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.adminSettingsModal.relayServerInfo1"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.toggleMeetingTimeRelay }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }