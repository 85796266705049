var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _c(
        "v-list-item",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.handleConference()
            }
          }
        },
        [
          _c(
            "v-list-item-icon",
            {
              staticClass: "primary--text",
              class:
                "" + (_vm.getRoute === "/newconference" ? " primary--text" : "")
            },
            [
              _c("font-awesome-icon", {
                staticClass: "primary--text",
                attrs: { icon: ["fal", "users"] }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-title",
            {
              staticClass: "text-truncate primary--text",
              class:
                "" +
                (_vm.getRoute === "/groups"
                  ? "font-weight-bold primary--text"
                  : "")
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c("span", _vm._g({}, on), [
                            _vm._v(
                              _vm._s(_vm.$t("components.groups.konferenzen")) +
                                " " +
                                _vm._s(
                                  "" +
                                    (_vm.userGroupsLength > 0
                                      ? "(" + _vm.userGroupsLength + ")"
                                      : "")
                                )
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.groups.manageConferences"))
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }