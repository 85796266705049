var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h100 justVideo" }, [
    _c(
      "div",
      { ref: "localVideoDiv", staticClass: "relative localVideo" },
      [
        !this.state.persisted.useBlurEffect
          ? _c("video", {
              class: { "d-none": !_vm.haveLocalVideo },
              attrs: {
                id: "local-video",
                playsinline: "",
                autoplay: "autoplay",
                muted: ""
              },
              domProps: { srcObject: _vm.state.localStreams.user, muted: true }
            })
          : _vm._e(),
        _vm._v(" "),
        this.state.persisted.useBlurEffect
          ? _c("StreamCanvas", {
              class: { "d-none": !_vm.haveLocalVideo },
              attrs: { id: "local-video", stream: _vm.state.localStreams.user }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "noCameraStream",
            class: { "d-none": _vm.haveLocalVideo }
          },
          [
            _c(
              "div",
              { staticClass: "noCameraContent" },
              [
                _c("v-img", {
                  staticClass: "imageNotVideo",
                  attrs: {
                    contain: "",
                    src: _vm.getAvatarFromUuid(_vm.state.ownUUID),
                    "lazy-src": "assets/default_profile_picture.png"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "emptyDiv" })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "callerName" },
          [
            _c("span", { staticClass: "text-truncate" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.isVisitor(_vm.state.ownUUID)
                      ? _vm.$t("components.userSettings.guest") + " - "
                      : ""
                  ) +
                  "\n        " +
                  _vm._s(_vm.getTitelForUuid(_vm.state.ownUUID)) +
                  "\n        " +
                  _vm._s(_vm.getNameForUuid(_vm.state.ownUUID)) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm.state.ownUUID === _vm.ownerUUID
              ? _c("span", { staticClass: "italicFont" }, [
                  _vm._v("\n        Moderator\n      ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "span",
                          _vm._g(
                            {
                              staticClass: "italicFont",
                              staticStyle: {
                                float: "right",
                                "margin-right": "9px"
                              }
                            },
                            on
                          ),
                          [_vm._v("\n            P2P")]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.remotePersonCall.p2p")))
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }