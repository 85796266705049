var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "2px" } },
    [
      [
        _c(
          "v-btn",
          {
            staticClass: "locationIcon",
            attrs: {
              width: "20",
              height: "20",
              icon: "",
              color: _vm.additionalStatusColor
            }
          },
          [
            _vm.additionalStatusColor === "grey"
              ? _c("font-awesome-icon", {
                  attrs: { icon: ["fal", "sticky-note"] }
                })
              : _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "sticky-note"] }
                })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }