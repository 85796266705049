var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-scroll",
    { staticClass: "vueScrollInfoboard" },
    [
      _vm.getTotalNotes === 0
        ? _c("v-row", { attrs: { "no-gutters": "" } }, [
            _c("p", { staticStyle: { margin: "0 auto" } }, [
              _vm._v(_vm._s(_vm.$t("generics.noInfoboard")))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("Infoboard"),
          _vm._v(" "),
          _vm.state.showNote.note ? _c("ModalNote") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }