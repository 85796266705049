var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "userDisplay text-center relative",
      style: _vm.getCardStyle,
      attrs: { flat: "", outlined: "", disabled: _vm.disabled },
      on: {
        click: function($event) {
          _vm.handlerShowUsers ? _vm.handlerShowUsers(_vm.uuid) : null
        }
      }
    },
    [
      _vm.userUUID && _vm.isEditMode
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "deleteUserFromList",
                              attrs: { color: "primary", icon: "" }
                            },
                            on
                          ),
                          [
                            _c("DeleteBasket", {
                              attrs: {
                                indexData: _vm.$event,
                                delFunction: _vm.deleteParticipant,
                                extraClasses: "",
                                fontSize: "18",
                                prevent: "false"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1157473725
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.inviteVisitor.removeParticipants"))
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isNewConference
        ? _c("DeleteBasket", {
            attrs: {
              indexData: _vm.indexToRemove,
              delFunction: _vm.removeUserFromList,
              extraClasses: "deleteUserFromList",
              fontSize: "18",
              prevent: "false"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.useDefaultAvatar && _vm.userUUID
        ? _c("DefaultAvatarUserDisplay", {
            attrs: {
              size: _vm.windowHeight > 845 ? 78 : 50,
              username: _vm.userName,
              userHasRejected: _vm.userHasRejected
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userUUID && !_vm.useDefaultAvatar
        ? _c("v-img", {
            staticClass: "white--text align-end ma",
            class: { rejectedUser: _vm.userHasRejected },
            attrs: {
              contain: "",
              height: _vm.windowHeight > 845 ? 78 : 50,
              src: _vm.userAvatar,
              "lazy-src": "img/default_profile_picture.png"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.userUUID
        ? _c("div", { staticClass: "px-2 py-1 text-left " }, [
            _c("p", { staticClass: "mb-0 text-truncate" }, [
              _vm._v("\n      " + _vm._s(_vm.userName) + "\n      ")
            ]),
            _vm._v(" "),
            _vm.userFunction
              ? _c("p", { staticClass: "mb-0 text-truncate" }, [
                  _vm._v(_vm._s(_vm.userFunction))
                ])
              : _vm.isOneCall
              ? _c("p", { staticClass: "mb-0 text-truncate" }, [
                  _vm._v("OneCall Participant")
                ])
              : _c("p", { staticClass: "mb-0 text-truncate" }, [_vm._v("---")]),
            _vm._v(" "),
            _vm.userHasRejected && _vm.isEditMode
              ? _c(
                  "div",
                  { class: { buttonOnPic: _vm.userHasRejected } },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { fontSize: "20px" },
                      attrs: { icon: ["fal", "plus-circle"] }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.userUUID
        ? _c(
            "div",
            { class: _vm.windowHeight > 845 ? "marginTop40" : "marginTop23" },
            [
              _c("font-awesome-icon", {
                staticStyle: { fontSize: "20px" },
                attrs: { icon: ["fal", "plus-circle"] }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }