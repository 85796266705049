var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "py-4",
      class: !_vm.showMeetingLine ? "hideTimeline" : "contentGridWrapper"
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loadingContent d-flex align-center mt-1" },
            [
              _c("v-btn", { attrs: { icon: "", loading: "" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.loading")))])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.filteringBy
        ? _c(
            "div",
            {
              staticClass: "d-flex align-center mt-1",
              class: _vm.loading
                ? "filteringContentWithLoading"
                : "filteringContent"
            },
            [
              _vm._l(_vm.numberOfTimesFound, function(number) {
                return _c(
                  "div",
                  { key: number, staticClass: "mr-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: _vm.filterPosition === number - 1 ? "red" : "",
                          fab: "",
                          outlined: "",
                          "x-small": ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.filterElements(
                              _vm.filteringBy,
                              number - 1
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(number))]
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: _vm.filterPosition === "all" ? "red" : "",
                        fab: "",
                        outlined: "",
                        "x-small": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.filterElements(_vm.filteringBy, "all")
                        }
                      }
                    },
                    [_vm._v("all")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { fab: "", outlined: "", "x-small": "" },
                      on: {
                        click: function($event) {
                          return _vm.cleanOrganigramFilter()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        style: {
                          fontSize: "15px",
                          color: _vm.isDark ? "white" : ""
                        },
                        attrs: { icon: ["fal", "times"] }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "caption font-weight-bold mr-2" }, [
                  _vm._v(
                    _vm._s(_vm.getNameByUuid(_vm.filteringBy)) +
                      " " +
                      _vm._s(_vm.$t("generics.organisation")) +
                      " "
                  )
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("OrganisationCard", {
        staticClass: "px-4",
        attrs: {
          loadingGrid: _vm.loading,
          showSectionFunction: _vm.showSectionFunction,
          isChildren: false,
          items: _vm.dataOrganigram || [],
          editMode: _vm.editMode,
          openAddEditModal: _vm.openAddEditModal,
          filteringBy: _vm.filteringBy
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }