var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hour !== _vm.lastHour
    ? _c(
        "div",
        {
          class: _vm.hour !== _vm.lastHour ? "boxupper" : "boxNoTime",
          on: {
            click: function($event) {
              _vm.hour !== _vm.lastHour
                ? _vm.handleNewEventEntry($event, _vm.date, _vm.hour)
                : null
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rulerDigit",
              class: { hide: _vm.hour < _vm.getHour }
            },
            [_vm._v(_vm._s(_vm.hour))]
          ),
          _vm.hour === _vm.lastHour
            ? _c("span", {
                class: {
                  timelineDate: _vm.hour !== _vm.lastHour,
                  timelineLast: _vm.hour == _vm.lastHour
                }
              })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }