<template>
  <div class="px-0 py-0">
    <!-- <div
      style="position: absolute; top: 0; right: 0; background-color: black; color: white; max-width: 500px; max-height: 500px; overflow: auto; z-index: 99999">
      {{ calendarEvents }}
    </div> -->
    <v-row class="px-3">
      <v-col :cols="isMobile ? 12 : ''" class="mt-4 d-flex justify-space-between">
        <div>
          <span>
            {{ $t("components.supportCalendar.supportCalendarTitle") }}
            <span class="font-weight-medium">
              {{
                selectedUser
                  ? `${getTitelForUuid(selectedUser)} ${getNameForUuid(
                    selectedUser
                  )}`
                  : ""
              }}
            </span>
          </span>
        </div>
        <div class="d-flex align-center">
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class="" :color="isDark ? 'white' : 'primary'" density="compact" variant="text" icon
                @click="changeCalendarTypeView('month')">
                <font-awesome-icon :icon="['fal', 'calendar']" :style="{ fontSize: '25px' }" />
              </v-btn>
            </template>
            <span>{{ $t("components.workingTime.monthlyView") }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn class="" v-bind="props" :color="isDark ? 'white' : 'primary'" density="compact" variant="text" icon
                @click="changeCalendarTypeView('week')">
                <font-awesome-icon :icon="['fal', 'calendar-week']" :style="{ fontSize: '25px' }" />
              </v-btn>
            </template>
            <span>{{ $t("components.workingTime.weeklyView") }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class="mr-2" :color="isDark ? 'white' : 'primary'" density="compact" variant="text"
                icon @click="changeCalendarTypeView('day')">
                <font-awesome-icon :icon="['fal', 'calendar-day']" :style="{ fontSize: '25px' }" />
              </v-btn>
            </template>
            <span>{{ $t("components.workingTime.dayView") }}</span>
          </v-tooltip>
          <!-- <v-btn outlined class="" color="grey darken-2" @click="value = ''"> -->
          <v-btn v-if="selectedUser === ownUUID" class="mr-4" color="" density="compact" variant="text" icon
            @click="addSupportEvent()">
            <font-awesome-icon :icon="['fal', 'plus']" :style="{ fontSize: '25px' }" />
          </v-btn>
          <v-btn variant="outlined" class="ml-2 mr-4" :color="isDark ? 'white' : 'primary'" @click="setCalendarToday()">
            {{ $t("components.workingTime.today") }}
          </v-btn>
          <v-btn icon class="ml-2 btnCalendar" density="compact" variant="text" @click="setCalendarPreviousPage()">
            <font-awesome-icon :icon="['fal', 'chevron-left']" :style="{ fontSize: '20px' }" />
          </v-btn>
          <v-btn icon class="btnCalendar" density="compact" variant="text" @click="setCalendarNextPage()">
            <font-awesome-icon :icon="['fal', 'chevron-right']" :style="{ fontSize: '20px' }" />
          </v-btn>
          <span :class="isDark ? 'text-white' : ''" class="">
            <p class="font-weight-medium">
              {{ calendarMonth }} {{ calendarYear }}
            </p>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row :class="isMobile ? 'calendarContainerMobile' : ''">
      <v-col class="mx-2" :class="isMobile ? '' : 'calendarContainer'">
        <v-card v-if="calendarApp" variant="text" class="h100" id="calendarCard">
          <ScheduleXCalendar v-if="calendarApp" :calendar-app="calendarApp">
            <!-- TimeGrid event slot -->
            <template #timeGridEvent="{ calendarEvent }">
              <v-menu :id="calendarEvent.id" location="top" min-width="150px">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" class="eventTemplate text-white px-2"
                    :style="{ backgroundColor: getColor(calendarEvent) }"
                    v-tooltip.top="`${calendarEvent.title}, ${getFormatedTime(calendarEvent.start)} - ${getFormatedTime(calendarEvent.end)}, ${calendarEvent.userEmail}`">
                    <!-- Displaying a concise event summary (Title, Time, and Email) -->
                    {{ calendarEvent.title }}, {{ getFormatedTime(calendarEvent.start) }} - {{
                      getFormatedTime(calendarEvent.end) }} <br>
                    {{ calendarEvent.userEmail }}
                  </div>
                </template>

                <!-- Event details menu when clicked -->
                <v-card color="grey-lighten-4" flat @click.stop>
                  <v-toolbar density="compact">
                    <v-toolbar-title :style="{ fontSize: '15px!important' }"
                      v-html="$sanitize(calendarEvent.title)"></v-toolbar-title>
                  </v-toolbar>

                  <!-- Event details section -->
                  <v-card-text>
                    <p><strong>{{ $t("components.conferenceForm.startDate") }}:</strong> {{
                      getFormattedDate(calendarEvent.start) }}</p>
                    <p><strong>{{ $t("components.conferenceForm.from") }}:</strong> {{
                      getFormatedTime(calendarEvent.start) }}
                    </p>
                    <p><strong>{{ $t("components.conferenceForm.to") }}:</strong> {{ getFormatedTime(calendarEvent.end)
                      }}</p>
                    <p><strong>{{ $t("components.companyRegister.contactName") }}:</strong> {{ calendarEvent.userName }}
                    </p>
                    <p><strong>{{ $t("components.companyRegister.email") }}:</strong> {{ calendarEvent.userEmail }}</p>
                    <p><strong>{{ $t("components.supportCalendar.eventTicket") }}:</strong> <a
                        :href="'https://helpdesk.ra-micro.de/#ticket/zoom/' + calendarEvent.ZammadTikcetID"
                        target="_blank" rel="noopener noreferrer">
                        {{ calendarEvent.ZammadTikcetID }}
                      </a></p>
                    <p><strong>{{ $t("components.drawerContentCard.status") }}:</strong> {{ calendarEvent.status }}</p>
                  </v-card-text>

                  <!-- Delete button for events (if authorized) -->
                  <v-divider></v-divider>
                  <DeleteBasket :indexData="calendarEvent.id" :delFunction="removeEvent"
                    extraClasses="cursorPointer mx-3 my-3" fontSize="18"  v-if="calendarEvent.owner === ownUUID"/>
                </v-card>
              </v-menu>
            </template>

            <!-- MonthGrid event slot -->
            <template #monthGridEvent="{ calendarEvent }">
              <v-menu :id="calendarEvent.id" location="end" min-width="150px">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" class="eventTemplate text-white px-2"
                    :style="{ backgroundColor: getColor(calendarEvent) }"
                    v-tooltip.top="`${calendarEvent.title}, ${getFormatedTime(calendarEvent.start)} - ${getFormatedTime(calendarEvent.end)}, ${calendarEvent.userEmail}`">
                    <!-- Displaying a concise event summary (Title, Time, and Email) -->
                    {{ calendarEvent.title }}, {{ getFormatedTime(calendarEvent.start) }} - {{
                      getFormatedTime(calendarEvent.end) }}
                  </div>
                </template>

                <!-- Event details menu when clicked -->
                <v-card  color="grey-lighten-4" flat @click.stop>
                  <v-toolbar density="compact">
                    <v-toolbar-title :style="{ fontSize: '15px!important' }"
                      v-html="$sanitize(calendarEvent.title)"></v-toolbar-title>
                  </v-toolbar>

                  <!-- Event details section -->
                  <v-card-text>
                    <p><strong>{{ $t("components.conferenceForm.startDate") }}:</strong> {{
                      getFormattedDate(calendarEvent.start) }}</p>
                    <p><strong>{{ $t("components.conferenceForm.from") }}:</strong> {{
                      getFormatedTime(calendarEvent.start) }}
                    </p>
                    <p><strong>{{ $t("components.conferenceForm.to") }}:</strong> {{ getFormatedTime(calendarEvent.end)
                      }}</p>
                    <p><strong>{{ $t("components.companyRegister.contactName") }}:</strong> {{ calendarEvent.userName }}
                    </p>
                    <p><strong>{{ $t("components.companyRegister.email") }}:</strong> {{ calendarEvent.userEmail }}</p>
                    <p><strong>{{ $t("components.supportCalendar.eventTicket") }}:</strong> <a
                        :href="'https://helpdesk.ra-micro.de/#ticket/zoom/' + calendarEvent.ZammadTikcetID"
                        target="_blank" rel="noopener noreferrer">
                        {{ calendarEvent.ZammadTikcetID }}
                      </a></p>
                    <p><strong>{{ $t("components.drawerContentCard.status") }}:</strong> {{ calendarEvent.status }}</p>
                  </v-card-text>

                  <!-- Delete button for events (if authorized) -->
                  <v-divider></v-divider>
                  <DeleteBasket :indexData="calendarEvent.id" :delFunction="removeEvent"
                    extraClasses="cursorPointer mx-3 my-3" fontSize="18" v-if="calendarEvent.owner === ownUUID"/>
                </v-card>
              </v-menu>
            </template>
          </ScheduleXCalendar>

        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="700px"> <!-- Aumentar el max-width para más espacio -->
      <v-card>
        <HeaderModal :titleModal="$t('components.calendarContainer.addEvent')"
          :closeModalFunction="closeAddSupportEvent" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="eventTitle" :label="$t('components.supportCalendar.eventName')" variant="outlined"
                density="compact" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="eventTicket" :label="$t('components.supportCalendar.eventTicket')"
                variant="outlined" density="compact" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="eventUserName" :label="$t('components.supportCalendar.eventUser')"
                variant="outlined" density="compact" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="eventUserEmail" :label="$t('components.supportCalendar.eventMail')"
                variant="outlined" density="compact" outlined></v-text-field>
            </v-col>
          </v-row>

          <div v-for="(event, index) in eventDates" :key="index">
            <v-row>
              <!-- Columna para la fecha -->
              <v-col cols="4">
                <v-menu v-model="event.menuDate" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ props }">
                    <v-text-field variant="outlined" density="compact" v-bind="props" v-model="event.date"
                      :label="$t('components.supportCalendar.eventDate')" readonly outlined></v-text-field>
                  </template>
                  <v-date-picker v-model="event.date"
                    @update:model-value="(val) => { event.date = val; event.menuDate = false; }"></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Columna para la hora de inicio -->
              <v-col cols="4">
                <v-menu v-model="event.menuStart" :close-on-content-click="false" transition="scale-transition"
                  offset-y>
                  <template v-slot:activator="{ props }">
                    <v-text-field variant="outlined" density="compact" v-bind="props" v-model="event.startTime"
                      :label="$t('components.supportCalendar.eventStartTime')" outlined></v-text-field>
                  </template>
                  <v-time-picker v-model="event.startTime" format="24hr"
                    @update:model-value="(val) => { event.startTime = val; event.menuStart = false; }"></v-time-picker>
                </v-menu>
              </v-col>

              <!-- Columna para la hora de fin -->
              <v-col cols="4">
                <v-menu v-model="event.menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ props }">
                    <v-text-field variant="outlined" density="compact" v-bind="props" v-model="event.endTime"
                      :label="$t('components.supportCalendar.eventEndTime')" outlined></v-text-field>
                  </template>
                  <v-time-picker v-model="event.endTime" format="24hr"
                    @update:model-value="(val) => { event.endTime = val; event.menuEnd = false; }"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <FooterModal :closeModalFunction="closeAddSupportEvent">
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn class="mr-2" color="primary" :disabled="isAddEventDisabled" v-bind="props" @click="addEvents">{{
                $t("generics.add") }}</v-btn>
            </template>
            <span>{{ $t("generics.add") }}</span>
          </v-tooltip>
        </FooterModal>
        <!-- <v-card-actions>
          <v-btn color="red" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="green" text @click="addEvents">Guardar</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>



  </div>
</template>

<script>
import { shallowRef } from "vue";
import { ScheduleXCalendar } from "@schedule-x/vue";
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
  viewWeek,
  viewMonthGrid,
  viewDay
} from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";
import { createEventsServicePlugin } from '@schedule-x/events-service';
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls';
import store from "../../store";
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from "../../lib/mobileUtil";
import { setNamespaceSetting } from '../../lib/wsMsg';
import moment from "../../../sharedsrc/moment";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue"
import DeleteBasket from "../ui/deleteBasket.vue";

const calendarApp = shallowRef(null);

export default {
  props: ['selectedUser'],
  components: {
    ScheduleXCalendar,
    FooterModal,
    HeaderModal,
    DeleteBasket
  },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      calendarApp,
      dialog: false,
      eventTitle: "",
      eventUserName: "",
      eventUserEmail: "",
      eventTicket: "",
      eventDates: Array(3).fill().map(() => ({
        date: null,
        startTime: null,
        endTime: null,
        menuDate: false,
        menuStart: false,
        menuEnd: false,
      })),
      eventsServicePlugin: null,
      calendarEvents: store.state.namespaceSettings.supportEvents || [],
      calendarControls: null,
      typeView: 'month', // Inicializamos la vista como mes
      calendarMonth: "",
      calendarYear: "",
      calendarSelectedValue: [new Date()],
      localeLang: null,
      statusDictionary: {
        prebooked: { label: 'Prebooked', color: '#A9A9A9' }, // grey
        confirmed: { label: 'Confirmed', color: '#FFA500' } // Orange
      }
    };
  },
  watch: {
    selectedUser: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            // Get all events from the store
            const allEvent = store.state.namespaceSettings.supportEvents || [];
            // Clear current events and set new ones
            this.eventsServicePlugin?.set([]);
            // If the value is 'all', show all events; otherwise, filter by owner
            const filteredEvents = this.filterEvents(allEvent, value)
            // Set the filtered events to calendarEvents
            this.calendarEvents = filteredEvents;
            // Update the events in the plugin with the filtered events
            this.eventsServicePlugin?.set(this.calendarEvents);
          });
        }
      },
    },
  },
  mounted() {
    this.localeLang = this.$locale.current();
    const currentDate = new Date();
    this.calendarMonth = currentDate.toLocaleString(this.localeLang, { month: 'long' });
    this.calendarYear = currentDate.getFullYear();
    const todayDate = new Date();
    this.calendarSelectedValue = [todayDate];
    // Initialize the events service plugin when the component is mounted
    this.eventsServicePlugin = createEventsServicePlugin();
    this.calendarControls = createCalendarControlsPlugin();
    // Initialize the calendar with the predefined settings
    this.initializeCalendar();
  },
  methods: {
    // Function to initialize the calendar with views and events
    initializeCalendar() {
      calendarApp.value = createCalendar({
        views: [
          viewMonthGrid, viewWeek, viewDay
        ],
        // Define initial events with their start and end times
        events: [
          // {
          //   id: uuidv4(),
          //   title: 'Event 2',
          //   start: '2025-02-11 12:00',
          //   end: '2025-02-11 13:00',
          //   owner: this.ownUUID,
          //   userName: 'test username',
          //   userEmail: 'testuseremail@webconnect.pro',
          //   status: this.statusDictionary.prebooked.label
          // },
        ],
        // Attach the events service plugin to the calendar
        plugins: [this.eventsServicePlugin, this.calendarControls],
        defaultView: viewMonthGrid.name,
        locale: this.localeLang == 'de' || this.localeLang == 'es' ? `${this.localeLang}-${this.localeLang.toUpperCase()}` : 'en-US',
        firstDayOfWeek: 1,
        selectedDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        dayBoundaries: {
          start: '05:00',
          end: '20:00',
        },
      });
      // Store the initialized calendar app
      this.calendarApp = calendarApp.value;
      // set Events
      const allEvent = store.state.namespaceSettings.supportEvents || [];
      // If the value is 'all', show all events; otherwise, filter by owner
      const filteredEvents = this.filterEvents(allEvent, this.selectedUser)
      // Set the filtered events to calendarEvents
      this.calendarEvents = filteredEvents;
      // Update the events in the plugin with the filtered events
      this.eventsServicePlugin?.set(this.calendarEvents);
    },
    // Function to remove an event by ID
    removeEvent(eventId) {
      // Close menu for event
      // Find the index of the event with the given ID
      const eventIndex = this.calendarEvents.findIndex(event => event.id === eventId);
      if (eventIndex !== -1) {
        // Remove the event from the calendar
        const removedEvent = this.calendarEvents.splice(eventIndex, 1)[0];
        // Update the eventsServicePlugin if it exists
        if (this.eventsServicePlugin && typeof this.eventsServicePlugin.set === 'function') {
          this.eventsServicePlugin.set(this.calendarEvents);
          // Get all existing events from the store
          const allEvents = store.state.namespaceSettings.supportEvents || [];
          // Remove the event from the allEvents list
          const updatedAllEvents = allEvents.filter(event => event.id !== removedEvent.id);
          // Update the namespace setting with the modified list of events
          setNamespaceSetting("supportEvents", updatedAllEvents);
        } else {
          console.error("eventsServicePlugin is not defined or does not have a 'set' method.");
        }
      } else {
        console.warn(`Event with ID ${eventId} not found.`);
      }

      const menu = document.getElementById(eventId);
      if (menu) {
        menu.style.display = 'none';
      }
    },
    // Function to add events from the input fields
    addEvents() {
      // Generate a common identifier for the group of events
      const groupId = uuidv4();

      this.eventDates.forEach(event => {
        // Check if the date, start time, and end time are provided for the event
        if (event.date && event.startTime && event.endTime) {
          // Format the start and end times into 'YYYY-MM-DD HH:mm' format
          const formattedStart = this.formatDate(event.date, event.startTime);
          const formattedEnd = this.formatDate(event.date, event.endTime);

          // Create a new event object
          const newEvent = {
            id: uuidv4(), // Generate a unique ID for each event
            groupId, // Assign the common group identifier
            title: this.eventTitle, // Set the event title from the input field
            start: formattedStart, // Use the formatted start date and time
            end: formattedEnd,     // Use the formatted end date and time
            owner: this.ownUUID, // Set the owner of the event
            status: this.statusDictionary.prebooked.label, // Set the status of the event
            userName: this.eventUserName, // Set the username for the event
            userEmail: this.eventUserEmail, // Set the email for the user
            ZammadTikcetID: this.eventTicket // Set Ticket id for https://helpdesk.ra-micro.de/#ticket/zoom/443409
          };

          // Check if the calendarEvents array is initialized before pushing the new event
          if (Array.isArray(this.calendarEvents)) {
            this.calendarEvents.push(newEvent); // Add the new event to the events array
          }

          // If the eventsServicePlugin exists, update it with the new events
          if (this.eventsServicePlugin && typeof this.eventsServicePlugin.set === 'function') {
            this.eventsServicePlugin.set(this.calendarEvents); // Update the plugin with the new event list
            // Get all existing events from the store
            const allEvents = store.state.namespaceSettings.supportEvents || [];
            // Filter out events that already exist in allEvents
            const newEvents = this.calendarEvents.filter(event =>
              !allEvents.some(existingEvent => existingEvent.id === event.id)
            );
            // Add the new events to the existing list
            const updatedEvents = [...allEvents, ...newEvents];
            // Update the namespace setting with the combined list of events
            setNamespaceSetting("supportEvents", updatedEvents);
          } else {
            console.error("eventsServicePlugin is not defined or does not have a 'set' method."); // Log an error if the plugin is not defined or does not have 'set'
          }
        }
      });

      this.cleanModalData()
    },

    cleanModalData() {
      // Reset the input fields after adding events
      this.dialog = false; // Close the dialog/modal
      this.eventTitle = ""; // Clear the event title field
      this.eventUserName = ""; // Clear the user name field
      this.eventUserEmail = ""; // Clear the user email field
      this.eventTicket = ""; // Clear the ticket id
      // Reset the event dates array, which holds the input data for the events
      this.eventDates = Array(3).fill().map(() => ({ date: null, startTime: null, endTime: null, menuDate: false, menuStart: false, menuEnd: false }));
    },

    // Function to format the date and time in 'YYYY-MM-DD HH:mm' format
    formatDate(date, time) {
      const d = new Date(date); // Convert the date string to a Date object
      const hours = time.split(':')[0].padStart(2, '0'); // Extract and format the hour
      const minutes = time.split(':')[1].padStart(2, '0'); // Extract and format the minutes
      const day = String(d.getDate()).padStart(2, '0'); // Format the day
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Format the month (0-indexed)
      const year = d.getFullYear(); // Get the year

      // Return the formatted date and time in 'YYYY-MM-DD HH:mm' format
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    // Métodos para cambiar entre las vistas
    changeCalendarTypeView(viewType, withBoundaries = true) {
      this.typeView = viewType;
      switch (viewType) {
        case 'month':
          this.calendarControls.setView(createViewMonthGrid().name);
          break;
        case 'week':
          this.calendarControls.setView(createViewWeek().name);
          break;
        case 'day':
          this.calendarControls.setView(createViewDay().name);
          break;
        default:
          this.calendarControls.setView(createViewDay().name);
          break;
      }
    },
    setCalendarToday() {
      const now = new Date();
      const today = moment(now).format('YYYY-MM-DD')
      this.changeCalendarTypeView(this.typeView);
      this.setDateinCaledar(today);
    },
    setDateinCaledar(date) {
      const dateToSet = moment(new Date(date)).format('YYYY-MM-DD');
      this.calendarControls.setDate(dateToSet);
      this.calendarSelectedValue = [new Date(date)];
      this.refreshCurrentMonth();
    },
    refreshCurrentMonth() {
      if (this.calendarControls?.getDate()) {
        const actualDate = new Date(this.calendarControls.getDate());
        const month = actualDate.getMonth() + 1;
        const year = actualDate.getFullYear();
        this.calendarMonth = this.$t(
          `components.calendarContainer.months.${month}`
        );
        this.calendarYear = year;
      }
    },
    setCalendarPreviousPage() {
      const now = this.calendarControls.getDate();
      switch (this.typeView) {
        case 'month': {
          // We take today and subtract a month
          const todayMinusOneMonth = moment(now).subtract(1, 'months').format('YYYY-MM-DD');
          this.setDateinCaledar(todayMinusOneMonth);
          this.changeCalendarTypeView('month')
          break;
        }
        case 'week': {
          // We take today and subtract 7 days
          const todayMinusSevenDays = moment(now).subtract(7, 'days').format('YYYY-MM-DD');
          this.setDateinCaledar(todayMinusSevenDays);
          this.changeCalendarTypeView('week')
          break;
        }
        case 'day': {
          // We take today and subtract 1 day
          const todayMinusOneDay = moment(now).subtract(1, 'days').format('YYYY-MM-DD');
          this.setDateinCaledar(todayMinusOneDay);
          this.changeCalendarTypeView('day')
          break;
        }
        default: {
          // Default case logic here if necessary
          break;
        }
      }
      // this.refreshCurrentMonth();
    },
    setCalendarNextPage() {
      const now = this.calendarControls.getDate();
      switch (this.typeView) {
        case 'month': {
          // We take today and add a month
          const todayPlusOneMonth = moment(now).add(1, 'months').format('YYYY-MM-DD');
          this.setDateinCaledar(todayPlusOneMonth);
          this.changeCalendarTypeView('month')
          break;
        }
        case 'week': {
          // We take today and add a 7 days more
          const todayPlusSevenDays = moment(now).add(7, 'days').format('YYYY-MM-DD');
          this.setDateinCaledar(todayPlusSevenDays);
          this.changeCalendarTypeView('week')
          break;
        }
        case 'day': {
          // We take today and add a 1 day more
          const todayPlusOneDay = moment(now).add(1, 'days').format('YYYY-MM-DD');
          this.setDateinCaledar(todayPlusOneDay);
          this.changeCalendarTypeView('day')
          break;
        }
        default: {
          // Default case logic here if necessary
          break;
        }
      }
    },
    addSupportEvent() {
      this.dialog = true;
    },
    closeAddSupportEvent() {
      this.dialog = false;
      this.cleanModalData();
    },
    getFormatedTime(time) {
      const date = new Date(time);
      return `${String(date.getHours()).padStart(2, "0")}:${String(
        date.getMinutes()
      ).padStart(2, "0")}`;
    },
    getFormattedDate(dateTime) {
      const date = new Date(dateTime);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options); // Formats the date like "February 11, 2025"
    },
    getColor(event) {
      const keyDictionary = event.status?.toLowerCase() ?? "";
      return this.statusDictionary[keyDictionary]?.color ?? "";
    },
    filterEvents(events, value) {
      if (value === 'all') {
        return events; // Si 'value' es 'all', no se aplica ningún filtro.
      } else {
        return events.filter(event => event.owner === value); // Filtro por 'owner'.
      }
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    isDark() {
      return this.state.persisted.isDark;
    },
    isAddEventDisabled() {
      return (
        !this.eventTitle ||
        !this.eventUserName ||
        !this.eventUserEmail ||
        !this.eventTicket ||
        !this.eventDates.some(event => event.date && event.startTime && event.endTime) // At least one must be fully filled
      );
    }
  }
};
</script>

<style scoped lang="scss">
.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.calendarContainer {
  height: calc(100vh - 230px);
}

.calendarContainerMobile {
  max-height: calc(100vh - 300px);
  overflow: scroll;
}

.workingTimeWrapper {
  display: flex;
}

.eventPreviewRow {
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 560px);
}

.eventItem {
  padding: 0;
  display: flex;
}
</style>
<style lang="scss">
.sx__time-grid-event.sx__event {
  min-height: 20px !important;
}

.v-picker-title {
  display: none;
}

.sx-vue-calendar-wrapper {
  height: 100%;
}

.timePicker input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  pointer-events: none;
}

.eventTemplate {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.sx__month-grid-day__header-date {
  cursor: pointer;
}

.is-leading-or-trailing {
  background-color: var(--v-primary-100) !important;
}

.is-dark {
  .is-leading-or-trailing {
    background-color: var(--v-primary-600) !important;
  }
}

.sx__calendar-wrapper {
  .sx__calendar {
    // border-top: none !important;
  }
}
</style>
