// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capitalize-first-letter[data-v-d30a05c2] {
  text-transform: lowercase;
}
.capitalize-first-letter[data-v-d30a05c2]::first-letter {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMedia/workUpdates/whatsWorkFooter.vue","webpack://./whatsWorkFooter.vue"],"names":[],"mappings":"AACA;EACI,yBAAA;ACAJ;ADGA;EACI,yBAAA;ACAJ","sourcesContent":["\n.capitalize-first-letter {\n    text-transform: lowercase;\n}\n\n.capitalize-first-letter::first-letter {\n    text-transform: uppercase;\n}\n\n",".capitalize-first-letter {\n  text-transform: lowercase;\n}\n\n.capitalize-first-letter::first-letter {\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
