// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saludationButtonGroup .v-selection-control-group {
  flex-direction: row !important;
}
.sx__time-grid-event.sx__event {
  min-height: 20px !important;
}
.v-picker-title {
  display: none;
}
.sx-vue-calendar-wrapper {
  height: 100%;
}
.timePicker input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  pointer-events: none;
}
.eventTemplate {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.sx__month-grid-day__header-date {
  cursor: pointer;
}
.is-leading-or-trailing {
  background-color: var(--v-primary-100) !important;
}
.is-dark .is-leading-or-trailing {
  background-color: var(--v-primary-600) !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/supportCalendar.vue","webpack://./supportCalendar.vue"],"names":[],"mappings":"AAEE;EACE,8BAAA;ACDJ;ADKA;EACE,2BAAA;ACFF;ADKA;EACE,aAAA;ACFF;ADKA;EACE,YAAA;ACFF;ADKA;EACE,gBAAA;EACA,oBAAA;ACFF;ADKA;EACE,YAAA;EACA,WAAA;EACA,eAAA;ACFF;ADKA;EACE,eAAA;ACFF;ADKA;EACE,iDAAA;ACFF;ADME;EACE,iDAAA;ACHJ","sourcesContent":["\n.saludationButtonGroup {\n  .v-selection-control-group {\n    flex-direction: row !important;\n  }\n}\n\n.sx__time-grid-event.sx__event {\n  min-height: 20px !important;\n}\n\n.v-picker-title {\n  display: none;\n}\n\n.sx-vue-calendar-wrapper {\n  height: 100%;\n}\n\n.timePicker input[type=\"time\"]::-webkit-calendar-picker-indicator {\n  background: none;\n  pointer-events: none;\n}\n\n.eventTemplate {\n  height: 100%;\n  width: 100%;\n  cursor: pointer;\n}\n\n.sx__month-grid-day__header-date {\n  cursor: pointer;\n}\n\n.is-leading-or-trailing {\n  background-color: var(--v-primary-100) !important;\n}\n\n.is-dark {\n  .is-leading-or-trailing {\n    background-color: var(--v-primary-600) !important;\n  }\n}\n\n.sx__calendar-wrapper {\n  .sx__calendar {\n    // border-top: none !important;\n  }\n}\n",".saludationButtonGroup .v-selection-control-group {\n  flex-direction: row !important;\n}\n\n.sx__time-grid-event.sx__event {\n  min-height: 20px !important;\n}\n\n.v-picker-title {\n  display: none;\n}\n\n.sx-vue-calendar-wrapper {\n  height: 100%;\n}\n\n.timePicker input[type=time]::-webkit-calendar-picker-indicator {\n  background: none;\n  pointer-events: none;\n}\n\n.eventTemplate {\n  height: 100%;\n  width: 100%;\n  cursor: pointer;\n}\n\n.sx__month-grid-day__header-date {\n  cursor: pointer;\n}\n\n.is-leading-or-trailing {\n  background-color: var(--v-primary-100) !important;\n}\n\n.is-dark .is-leading-or-trailing {\n  background-color: var(--v-primary-600) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
