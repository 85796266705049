// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customImageFit .v-img__img--contain {
  object-fit: cover !important;
}
.imageSambaRoom .v-image__image {
  padding: 23px 33px 33px 33px;
  background-origin: content-box;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/workersLounge/regularGridWorkersLoungeNew.vue","webpack://./regularGridWorkersLoungeNew.vue"],"names":[],"mappings":"AAEE;EACE,4BAAA;ACDJ;ADKE;EACE,4BAAA;EACA,8BAAA;ACFJ","sourcesContent":["\n.customImageFit{\n  .v-img__img--contain{\n    object-fit: cover !important;\n  }\n}\n.imageSambaRoom{\n  .v-image__image{\n    padding: 23px 33px 33px 33px;\n    background-origin: content-box;\n  }\n}\n",".customImageFit .v-img__img--contain {\n  object-fit: cover !important;\n}\n\n.imageSambaRoom .v-image__image {\n  padding: 23px 33px 33px 33px;\n  background-origin: content-box;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
