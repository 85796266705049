var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "paymentModal",
      attrs: {
        fullscreen: _vm.isMobile,
        persistent: "",
        "max-width": "570",
        "hide-overlay": "",
        attach: "#videos"
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
      },
      model: {
        value: _vm.showModalPaymentModal,
        callback: function($$v) {
          _vm.showModalPaymentModal = $$v
        },
        expression: "showModalPaymentModal"
      }
    },
    [
      [
        _c(
          "v-card",
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.paymentModal.titleRequestPayment"
                ),
                closeModalFunction: _vm.declinePayment,
                actualDate: _vm.actualDate
              }
            }),
            _vm._v(" "),
            !_vm.state.user.requestedPayment.successPayment &&
            !_vm.state.user.requestedPayment.errorPayment
              ? _c(
                  "div",
                  { class: { modalHeightMobile: _vm.isMobile } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mx-3 mb-4 mt-5" },
                      [
                        _c(
                          "v-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isPayPalSandbox,
                                expression: "isPayPalSandbox"
                              }
                            ],
                            attrs: { cols: "12" }
                          },
                          [
                            _c("p", { staticClass: "mb-0 colorRed" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("components.paymentModal.testMode")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "mb-2", attrs: { cols: "12" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$t(
                                  "components.paymentModal.customerNumber"
                                ),
                                outlined: "",
                                dense: "",
                                disabled: "",
                                "hide-details": "auto"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value:
                                  _vm.state.user.requestedPayment
                                    .customerNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.state.user.requestedPayment,
                                    "customerNumber",
                                    $$v
                                  )
                                },
                                expression:
                                  "state.user.requestedPayment.customerNumber"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "mb-2", attrs: { cols: "12" } },
                          [
                            _c("v-combobox", {
                              attrs: {
                                disabled: "",
                                select: "",
                                "item-text": "name",
                                "item-value": "uuid",
                                label: _vm.$t(
                                  "components.paymentModal.conversationWith"
                                ),
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                "append-icon": ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value:
                                  _vm.state.user.requestedPayment.userToPay,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.state.user.requestedPayment,
                                    "userToPay",
                                    $$v
                                  )
                                },
                                expression:
                                  "state.user.requestedPayment.userToPay"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.state.user.requestedPayment.note &&
                        _vm.state.user.requestedPayment.note != ""
                          ? _c(
                              "v-col",
                              {
                                staticClass: "mb-2 pt-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t(
                                      "components.paymentModal.note"
                                    ),
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "auto",
                                    disabled: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                    }
                                  },
                                  model: {
                                    value: _vm.state.user.requestedPayment.note,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.state.user.requestedPayment,
                                        "note",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "state.user.requestedPayment.note"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("v-col", {
                          attrs: { cols: "5", cclass: "mb-0 py-0 pt-0" }
                        }),
                        _vm._v(" "),
                        _c("v-col", {
                          staticClass: "mb-0 py-0 pt-0",
                          attrs: { cols: "3" }
                        }),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "mb-2 pt-0", attrs: { cols: "5" } },
                          [
                            _c("v-combobox", {
                              attrs: {
                                select: "",
                                "item-value": "productId",
                                "item-text": "productName",
                                "return-object": "",
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                label: _vm.$t(
                                  "components.paymentModal.product"
                                ),
                                disabled: "",
                                "append-icon": ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value: _vm.getProductName,
                                callback: function($$v) {
                                  _vm.getProductName = $$v
                                },
                                expression: "getProductName"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "mb-2 pt-0", attrs: { cols: "4" } },
                          [
                            _c(
                              "v-text-field",
                              {
                                class:
                                  "inputWithPrepend " +
                                  (_vm.state.user.requestedPayment.uptoOption
                                    ? "uptoOption"
                                    : ""),
                                attrs: {
                                  outlined: "",
                                  disabled: "",
                                  dense: "",
                                  min: 5,
                                  type: "number",
                                  label: _vm.$t(
                                    "components.paymentModal.callDuration"
                                  ),
                                  "hide-details": "auto"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value:
                                    _vm.state.user.requestedPayment
                                      .callDuration,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.state.user.requestedPayment,
                                      "callDuration",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "state.user.requestedPayment.callDuration"
                                }
                              },
                              [
                                _vm.state.user.requestedPayment.uptoOption
                                  ? _c("template", { slot: "prepend-inner" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.paymentModal.upToInput"
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.state.user.requestedPayment.uptoOption
                                  ? _c("template", { slot: "append" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("literals.Minutes"))
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "mb-2 pt-0", attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$t("components.paymentModal.price"),
                                outlined: "",
                                dense: "",
                                disabled: "",
                                type: "number",
                                "hide-details": "auto"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _vm.state.namespaceSettings.currency ===
                                        "EUR"
                                          ? _c("font-awesome-icon", {
                                              staticClass: "signClass",
                                              style: { fontSize: "15px" },
                                              attrs: {
                                                icon: ["fal", "euro-sign"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.state.namespaceSettings.currency ===
                                        "GBP"
                                          ? _c("font-awesome-icon", {
                                              staticClass: "signClass",
                                              style: { fontSize: "15px" },
                                              attrs: {
                                                icon: ["far", "pound-sign"]
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.state.namespaceSettings.currency ===
                                        "CHF"
                                          ? _c("v-img", {
                                              staticClass: "signClass",
                                              attrs: {
                                                contain: "",
                                                height: "20",
                                                src:
                                                  "img/paymentIcons/swiss-franc-black.png",
                                                "lazy-src":
                                                  "img/paymentIcons/swiss-franc.png"
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                930381856
                              ),
                              model: {
                                value: _vm.state.user.requestedPayment.total,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.state.user.requestedPayment,
                                    "total",
                                    $$v
                                  )
                                },
                                expression: "state.user.requestedPayment.total"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPayPalSandbox,
                            expression: "isPayPalSandbox"
                          }
                        ],
                        attrs: { cols: "12" }
                      },
                      [
                        _c("p", { staticClass: "mb-0 colorRed" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("components.paymentModal.testMode")
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.state.user.requestedPayment.successPayment
                      ? _c("v-col", { attrs: { cols: "12" } }, [
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "components.paymentModal.paymentSuccess"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.user.requestedPayment.errorPayment
                      ? _c("v-col", { attrs: { cols: "12" } }, [
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.paymentModal.paymentError")
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "v-card",
              {
                staticClass: "py-2 pl-4 pr-2 footerModal",
                attrs: { color: "primary" }
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "payPalButtonStyle pl-2 btns w-100 mx-0 my-0"
                  },
                  [
                    _vm._t("default"),
                    _vm._v(" "),
                    !_vm.state.user.requestedPayment.successPayment &&
                    !_vm.state.user.requestedPayment.errorPayment
                      ? _c("PayPalButton", {
                          attrs: {
                            amount: _vm.state.user.requestedPayment.total,
                            currency: _vm.state.namespaceSettings.currency,
                            experience: _vm.experienceOptions,
                            items: _vm.getItems,
                            invoiceNumber:
                              _vm.state.user.requestedPayment.customerNumber
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "closeButton",
                                        attrs: {
                                          outlined: "",
                                          icon: "",
                                          color: "white",
                                          tile: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.declinePayment($event)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "times"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("generics.close")))])
                        ]
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }