var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h100Remote",
      class: {
        mt30:
          _vm.state.ownUUID !== _vm.state.presentationView.owner ||
          _vm.index > 0
      },
      attrs: { "data-test": _vm.index }
    },
    [
      _vm.fullCall
        ? _c("fullCallModal", { attrs: { fullCall: _vm.fullCall } })
        : _vm._e(),
      _vm._v(" "),
      _vm.state.nerd
        ? _c("div", { staticClass: "rtcStatus" }, [
            _c("div", [
              _vm._v("\n        IceConnectionState:\n        "),
              _c(
                "span",
                {
                  class: {
                    green:
                      _vm.state.rtc[_vm.uuid].iceConnectionState === "connected"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].iceConnectionState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n        IceGatheringState:\n        "),
              _c(
                "span",
                {
                  class: {
                    green:
                      _vm.state.rtc[_vm.uuid].iceGatheringState === "complete"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].iceGatheringState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n        SignalingState:\n        "),
              _c(
                "span",
                {
                  class: {
                    green: _vm.state.rtc[_vm.uuid].signalingState === "stable"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].signalingState))]
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n        RingingState:\n        "),
              _c(
                "span",
                {
                  class: {
                    green: _vm.state.rtc[_vm.uuid].ringingState === "connected"
                  }
                },
                [_vm._v(_vm._s(_vm.state.rtc[_vm.uuid].ringingState))]
              )
            ]),
            _vm._v(" "),
            _vm.activeCandidates
              ? _c("div", [
                  _vm._v("\n        ConnectionType:\n        "),
                  _c("span", { class: { green: !_vm.isTurn } }, [
                    _vm._v(
                      _vm._s(_vm.activeCandidates.local.candidateType) +
                        " / " +
                        _vm._s(_vm.activeCandidates.remote.candidateType)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            this.videoDetailsFirst && this.videoDetailsFirst.width
              ? _c("div", [
                  _vm._v(
                    "First: " +
                      _vm._s(this.videoDetailsFirst.width || 0) +
                      "x" +
                      _vm._s(this.videoDetailsFirst.height || 0) +
                      "@" +
                      _vm._s(Math.floor(this.videoDetailsFirst.frameRate || 0))
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            this.videoDetailsSecond && this.videoDetailsSecond.width
              ? _c("div", [
                  _vm._v(
                    "Second: " +
                      _vm._s(this.videoDetailsSecond.width || 0) +
                      "x" +
                      _vm._s(this.videoDetailsSecond.height || 0) +
                      "@" +
                      _vm._s(Math.floor(this.videoDetailsSecond.frameRate || 0))
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "videos",
          staticClass: "videos",
          attrs: { id: "videos" },
          on: {
            dblclick: function($event) {
              return _vm.fullSreen($event)
            }
          }
        },
        [
          _vm.onHold
            ? _c(
                "div",
                { staticClass: "onHoldOverlay" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "onHoldCenterIcon",
                    style: { fontSize: "45px" },
                    attrs: { icon: ["fal", "phone-plus"] }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "messageToParticipants messageToParticipantsBlink"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.remotePersonCall.onHoldMessage")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("video", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.remote.second,
                expression: "!remote.second"
              }
            ],
            staticClass: "remote-first",
            class: { dNone: !_vm.remote.first || !_vm.haveRemoteFirstVideo },
            attrs: { id: "remote-first", autoplay: "autoplay" },
            domProps: {
              srcObject: _vm.remote.first,
              muted: _vm.isLocalSoundMuted
            },
            on: {
              click: function($event) {
                $event.preventDefault()
              }
            }
          }),
          _vm._v(" "),
          _c("video", {
            staticClass: "remote-second",
            class: { dNone: !_vm.remote.second },
            attrs: { id: "remote-second", autoplay: "autoplay" },
            domProps: {
              srcObject: _vm.remote.second,
              muted: _vm.isLocalSoundMuted
            },
            on: {
              click: function($event) {
                $event.preventDefault()
              }
            }
          }),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "ringing"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c(
                    "div",
                    { staticClass: "callAvatarWrapper" },
                    [
                      _c("v-img", {
                        staticClass: "noVideoStreamAvatar",
                        attrs: {
                          contain: "",
                          "max-width": "65",
                          "max-height": "65",
                          src: _vm.getAvatarForUuid(_vm.uuid),
                          "lazy-src": "assets/default_profile_picture.png"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "callingText" }, [
                      _vm._v(
                        _vm._s(_vm.$t("components.remotePersonCall.calling")) +
                          " " +
                          _vm._s(_vm.getNameForUuid(_vm.uuid))
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(0)
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "accepted"
            ? _c("div", { staticClass: "noVideoStream" }, [
                _c("div", { staticClass: "noVideoStreamContent" }, [
                  _c("span", { staticClass: "callingText" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.remotePersonCall.callInProgress")
                      )
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.state.rtc[_vm.uuid].ringingState === "connected"
            ? _c(
                "div",
                {
                  staticClass: "noVideoStream",
                  class: {
                    absolute:
                      _vm.remote.second ||
                      _vm.remote.first ||
                      _vm.haveRemoteFirstVideo
                  }
                },
                [
                  _c("div", { staticClass: "noVideoStreamContent" }, [
                    _c(
                      "div",
                      { staticClass: "callAvatarWrapper" },
                      [
                        _c("v-img", {
                          staticClass: "noVideoStreamAvatar",
                          attrs: {
                            contain: "",
                            "max-width": "65",
                            "max-height": "65",
                            src: _vm.getAvatarForUuid(_vm.uuid),
                            "lazy-src": "assets/default_profile_picture.png"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "callingText" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.remotePersonCall.inCallWith")
                          ) +
                            " " +
                            _vm._s(_vm.getNameForUuid(_vm.uuid))
                        )
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isOnRequest &&
          _vm.showHandFor[_vm.uuid] &&
            _vm.showHandFor[_vm.uuid].showHand === true
            ? _c("div", { staticClass: "waveHandDiv" }, [
                _c(
                  "span",
                  { staticClass: "waveHand" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "iconHand",
                      attrs: { color: "white", icon: ["fas", "hand-paper"] }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "buttonsUser",
              class: {
                hidden: Object.keys(_vm.state.remoteStreams).length == 0,
                showButtons: _vm.showButtons
              }
            },
            [
              _vm.state.ownUUID === _vm.ownerUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm
                                            .getWebrtcConnections()
                                            [_vm.uuid].die(true)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "faPhoneRotate",
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "phone"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3478738157
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.state.ownUUID === _vm.ownerUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.toggleMuteUserAudio(
                                            _vm.uuid
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    !_vm.isMyAudioMutedByAdmin
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "microphone"] }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isMyAudioMutedByAdmin
                                      ? _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "microphone-slash"]
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3755788682
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              !_vm.isMyAudioMutedByAdmin
                                ? _vm.$t("components.remotePersonCall.muteUser")
                                : _vm.$t(
                                    "components.remotePersonCall.unMuteUser"
                                  )
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.state.ownUUID === _vm.ownerUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-3",
                                      attrs: { color: "white", icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.transferModerator(_vm.uuid)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "users-crown"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2754735369
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.remotePersonCall.transferModerator"
                            )
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mx-3",
                                attrs: { icon: "", color: "white" },
                                on: {
                                  click: function($event) {
                                    return _vm.setShowModalSenMsg(_vm.uuid)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "paper-plane"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.userProfile.sendAMessage"))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mx-3",
                                attrs: { icon: "", color: "white" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.sendMail(_vm.uuid)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "24px" },
                                attrs: { icon: ["fal", "envelope-square"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.userListItem.sendEMail")))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.state.ownUUID === _vm.ownerUUID
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      avatar: "",
                                      overlap: "",
                                      color: "primary",
                                      "offset-y": 15,
                                      "offset-x": 23,
                                      value: _vm.isOnRequest
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function() {
                                            return [
                                              _c("font-awesome-icon", {
                                                style: {
                                                  fontSize: "19px",
                                                  padding: 1
                                                },
                                                attrs: {
                                                  icon: ["fal", "exclamation"]
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mx-3",
                                            class: { blink: _vm.isOnRequest },
                                            attrs: { icon: "", color: "white" },
                                            on: {
                                              click: function($event) {
                                                _vm.setFullSizeVideo(
                                                  _vm.remote,
                                                  _vm.uuid
                                                )
                                                _vm.removeFromObj(_vm.uuid)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "24px" },
                                            attrs: { icon: ["fal", "comment"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4011946796
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.callsContent.giveWord"))
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeCandidates &&
              (_vm.activeCandidates.remote.candidateType === "relay" ||
                _vm.activeCandidates.local.candidateType === "relay")
                ? _c(
                    "span",
                    {
                      class:
                        _vm.activeCandidates &&
                        (_vm.activeCandidates.remote.candidateType ===
                          "relay" ||
                          _vm.activeCandidates.local.candidateType === "relay")
                          ? "darkGreen"
                          : "lightGreen"
                    },
                    [_vm._v("Relay")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "callerName" },
            [
              _vm.ownerUUID === _vm.uuid
                ? _c(
                    "span",
                    { staticStyle: { float: "left", "margin-left": "9px" } },
                    [_vm._v("Moderator")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uuid
                ? _c(
                    "span",
                    { staticClass: "text-truncate callerNameTruncate" },
                    [
                      _vm.showButtons
                        ? _c("font-awesome-icon", {
                            style: { cursor: "pointer", fontSize: "16px" },
                            attrs: {
                              color: "white",
                              icon: ["far", "chevron-down"]
                            },
                            on: {
                              click: function($event) {
                                _vm.showButtons = !_vm.showButtons
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showButtons
                        ? _c("font-awesome-icon", {
                            style: { cursor: "pointer", fontSize: "16px" },
                            attrs: {
                              color: "white",
                              icon: ["far", "chevron-up"]
                            },
                            on: {
                              click: function($event) {
                                _vm.showButtons = !_vm.showButtons
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getDataForHeader(_vm.uuid)) +
                          "\n        "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "span",
                            _vm._g(
                              {
                                staticClass: "italicFont",
                                staticStyle: {
                                  float: "right",
                                  "margin-right": "9px"
                                }
                              },
                              on
                            ),
                            [_vm._v(" P2P")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.remotePersonCall.p2p")))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.ownerUUID !== _vm.uuid &&
              !_vm.isWaitingRoomUser(_vm.uuid) && !_vm.isVisitor(_vm.uuid)
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticStyle: {
                                        float: "left",
                                        "margin-left": "9px"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "font-awesome-icon",
                                      _vm._g(
                                        {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "user-crown"] }
                                        },
                                        on
                                      )
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        477651809
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.remotePersonCall.user"))
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ownerUUID !== _vm.uuid &&
              (_vm.isWaitingRoomUser(_vm.uuid) || _vm.isVisitor(_vm.uuid))
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticStyle: {
                                        float: "left",
                                        "margin-left": "9px"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "font-awesome-icon",
                                      _vm._g(
                                        {
                                          style: { fontSize: "20px" },
                                          attrs: {
                                            icon: ["fal", "user-friends"]
                                          }
                                        },
                                        on
                                      )
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1591339111
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.remotePersonCall.guest"))
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "wave" } }, [
      _c("div", { staticClass: "dot", attrs: { color: "primary" } }),
      _vm._v(" "),
      _c("div", { staticClass: "dot", attrs: { color: "primary" } }),
      _vm._v(" "),
      _c("div", { staticClass: "dot", attrs: { color: "primary" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }