var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "650",
        attach: _vm.getAttach,
        "hide-overlay": _vm.getAttach ? true : false
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.manageConference"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-list",
                { staticClass: "manageConfList" },
                _vm._l(_vm.conferenceAttendees, function(user) {
                  return !_vm.isOnlyOneCallUser(user.uuid) &&
                    _vm.state.group[user.uuid]
                    ? _c(
                        "v-list-item",
                        { key: user.uuid, attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "d-flex" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(user.name) +
                                      "\n              "
                                  ),
                                  _vm._v(" "),
                                  user.uuid !== _vm.state.ownUUID
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex dbtnsAttendees ml-auto"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                disabled:
                                                  _vm.checkUserInCall(
                                                    user.uuid
                                                  ) ||
                                                  !_vm.isAvailable(user.uuid)
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.addUserToCall(
                                                    user.uuid
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                icon: "",
                                                                disabled:
                                                                  _vm.checkUserInCall(
                                                                    user.uuid
                                                                  ) ||
                                                                  !_vm.isAvailable(
                                                                    user.uuid
                                                                  )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  $event.preventDefault()
                                                                  return _vm.addUserToCall(
                                                                    user.uuid
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                staticClass:
                                                                  "inIcon",
                                                                class: {
                                                                  greenButton:
                                                                    !_vm.checkUserInCall(
                                                                      user.uuid
                                                                    ) &&
                                                                    _vm.isAvailable(
                                                                      user.uuid
                                                                    )
                                                                },
                                                                style: {
                                                                  fontSize:
                                                                    "20px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "door-open"
                                                                  ]
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.hasPrivilege(user.uuid)
                                                      ? _vm.$t(
                                                          "generics.addUser"
                                                        )
                                                      : _vm.$t(
                                                          "generics.addGuest"
                                                        )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                disabled: !_vm.checkUserInCall(
                                                  user.uuid
                                                )
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                disabled: !_vm.checkUserInCall(
                                                                  user.uuid
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  $event.preventDefault()
                                                                  return _vm.hangUpUser(
                                                                    user.uuid
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "outIcon",
                                                                  class: {
                                                                    greenButton: _vm.checkUserInCall(
                                                                      user.uuid
                                                                    )
                                                                  },
                                                                  style: {
                                                                    fontSize:
                                                                      "20px"
                                                                  },
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "door-closed"
                                                                    ]
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.hasPrivilege(user.uuid)
                                                      ? _vm.$t(
                                                          "generics.removeUser"
                                                        )
                                                      : _vm.$t(
                                                          "generics.deleteGuest"
                                                        )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  disabled: _vm.availableUserToCall.length == 0
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.callAll()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.conferences.callAll"))
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.conferences.callAllTT")))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }