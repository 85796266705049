var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c("HeaderModal", {
        attrs: {
          titleModal: _vm.$t("generics.info"),
          closeModalFunction: _vm.closeModal
        }
      }),
      _vm._v(" "),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "d-flex justify-center" },
          [
            _c(
              "v-row",
              [
                _c("v-col", [
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("components.twoFAInfoModal.text1")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("components.twoFAInfoModal.text2")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("components.twoFAInfoModal.sms")))
                    ]),
                    _vm._v(
                      ": " +
                        _vm._s(_vm.$t("components.twoFAInfoModal.smsText")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("components.twoFAInfoModal.auth")))
                    ]),
                    _vm._v(
                      ": " +
                        _vm._s(_vm.$t("components.twoFAInfoModal.authText")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("components.twoFAInfoModal.pin")))
                    ]),
                    _vm._v(
                      ": " +
                        _vm._s(_vm.$t("components.twoFAInfoModal.pinText")) +
                        "\n          "
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("FooterModal", {
        attrs: { closeModalFunction: _vm.closeModal, showFooter: true }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }