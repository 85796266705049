<template>
    <div>
    <v-dialog :value="visible" persistent >
        <v-card>
            <v-form ref="form" v-model="valid">
            <v-card-title class="headline">
                <v-img class="mr-2" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
                {{ $t('components.manageOrganisation.headline') }}
            </v-card-title>
            <v-card-text>
                <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>
                <v-container fluid>
                    <div class="org-row section">
                        <v-row align="center">
                            <v-col cols="12" sm="4">
                                <div class="d-flex label">
                                    <span>{{ $t('components.manageOrganisation.section.name') }}</span>
                                    <div>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="addStructure('section')">
                                                    <font-awesome-icon :icon="['fal', 'plus']" />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.section.add') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon :disabled="!selectedSection || !isSelectMode">
                                                   <DeleteBasket indexData='section' :delFunction="removeStructure" extraClasses="" fontSize="17" prevent= false />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.section.delete') }}</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <v-autocomplete
                                    ref="section"
                                    v-if="current.section.mode === 'select'"
                                    v-model="selectedSection"
                                    @change="fieldChanged('section')"
                                    :items="sections"
                                    item-text="section"
                                    return-object
                                    :disabled="current.team.mode === 'move'"
                                    @keyup.enter="event => renameStructure('section', event)"
                                    @blur="event => renameStructure('section', event)"
                                    :auto-select-first="false"
                                    hide-no-data
                                    />
                                <!-- <discardable-input
                                    :rules="[v => {
                                        if (!v) return 'Bereich darf nicht leer sein'
                                        if (current.section.mode !== 'select') return false
                                        return true
                                    }]"
                                    v-if="current.section.mode === 'new' || current.section.mode === 'edit'"
                                    :value="selectedSection.section"
                                    @discard="discardChange('section')"
                                    @accept="value => acceptChange('section', value)"
                                    :placeholder="$t('components.manageOrganisation.section.new')" /> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div>{{ $t('components.manageOrganisation.supervisor') }}</div>
                                <v-autocomplete
                                    ref="sectionSupervisor"
                                    @keyup.enter="event => focusNext('sectionSupervisor', 'sectionAssistants')"
                                    required
                                    :rules="[v => selectedSection.section == null || !!v || false]"
                                    v-model="selectedSection.supervisor"
                                    :items="[...users].sort(user => (selectedSection.supervisor || []).includes(user.uuid) ? -1 : 0)"
                                    item-text="name"
                                    item-value="uuid"
                                    :disabled="selectedSection.section == null"
                                    auto-select-first
                                    multiple
                                    />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div>{{ $t('components.manageOrganisation.assistants') }}</div>
                                <v-autocomplete
                                    ref="sectionAssistants"
                                    v-model="sectionSupervisorAssistants"
                                    @keyup.enter="focusNext('sectionAssistants', 'department')"
                                    :items="[...users].sort(user => (sectionSupervisorAssistants || []).includes(user.uuid) ? -1 : 0)"
                                    item-text="name"
                                    item-value="uuid"
                                    :disabled="selectedSection.section == null || selectedSection.supervisor && selectedSection.supervisor.length > 1"
                                    multiple
                                    clearable
                                    auto-select-first />
                            </v-col>
                        </v-row>
                    </div>
                    <div class="org-row department">
                        <v-row align="center">
                            <v-col cols="12" sm="4">
                                <div class="d-flex label">
                                    <span>{{ $t('components.manageOrganisation.department.name') }}</span>
                                    <div>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="moveStructure('department')" :disabled="!selectedDepartment.department || !isSelectMode">
                                                    <font-awesome-icon :icon="['fal', 'exchange']" />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.department.move') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="addStructure('department')">
                                                    <font-awesome-icon :icon="['fal', 'plus']" />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.department.add') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon :disabled="!selectedDepartment || !isSelectMode">
                                                  <DeleteBasket indexData='department' :delFunction="removeStructure" extraClasses="" fontSize="17" prevent= false />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.department.delete') }}</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <v-autocomplete
                                    ref="department"
                                    v-if="current.department.mode === 'select' || current.department.mode === 'move'"
                                    v-model="selectedDepartment"
                                    :disabled="selectedSection.section == null"
                                    @change="fieldChanged('department')"
                                    @keyup.enter="event => renameStructure('department', event)"
                                    @blur="event => renameStructure('department', event)"
                                    :items="departments"
                                    item-text="department"
                                    item-value="department"
                                    return-object
                                    auto-select-first
                                    hide-no-data />
                                <!-- <discardable-input
                                    :rules="[v => {
                                        if (!v) return 'Abteilung darf nicht leer sein'
                                        if (current.department.mode !== 'select') return false
                                        return true
                                    }]"
                                    v-if="current.department.mode === 'edit' || current.department.mode === 'new'"
                                    :value="selectedDepartment.department"
                                    @discard="discardChange('department')"
                                    @accept="value => acceptChange('department', value)"
                                    :placeholder="$t('components.manageOrganisation.department.new')" /> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div>{{ $t('components.manageOrganisation.supervisor') }}</div>
                                <v-autocomplete
                                    ref="departmentSupervisor"
                                    @keyup.enter="event => focusNext('departmentSupervisor', 'departmentAssistants')"
                                    required
                                    :rules="[v => selectedDepartment.department == null || !!v || false]"
                                    v-model="selectedDepartment.supervisor"
                                    :items="[...users].sort(user => (selectedDepartment.supervisor || []).includes(user.uuid) ? -1 : 0)"
                                    item-text="name"
                                    item-value="uuid"
                                    :disabled="selectedDepartment.department == null"
                                    auto-select-first
                                    multiple />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div>{{ $t('components.manageOrganisation.assistants') }}</div>
                                <v-autocomplete
                                    ref="departmentAssistants"
                                    v-model="departmentSupervisorAssistants"
                                    :items="[...users].sort(user => (departmentSupervisorAssistants || []).includes(user.uuid) ? -1 : 0)"
                                    @keyup.enter="event => focusNext('departmentAssistants', 'team')"
                                    item-text="name"
                                    item-value="uuid"
                                    :disabled="selectedDepartment.department == null || selectedDepartment.supervisor && selectedDepartment.supervisor.length > 1"
                                    multiple
                                    auto-select-first />
                            </v-col>
                        </v-row>
                    </div>
                    <div class="org-row team">
                        <v-row align="center">
                            <v-col cols="12" sm="4">
                                <div class="d-flex label">
                                    <span>{{ $t('components.manageOrganisation.team.name') }}</span>
                                    <div>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="moveStructure('team')" :disabled="selectedTeam.team == null || !isSelectMode">
                                                    <font-awesome-icon :icon="['fal', 'exchange']" />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.team.move') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon @click="addStructure('team')">
                                                    <font-awesome-icon :icon="['fal', 'plus']" />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.team.add') }}</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon :disabled="!selectedTeam || !isSelectMode">
                                                    <DeleteBasket indexData='team' :delFunction="removeStructure" extraClasses="" fontSize="17" prevent= false />
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('components.manageOrganisation.team.delete') }}</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                                <v-autocomplete
                                    ref="team"
                                    v-if="current.team.mode === 'select' || current.team.mode === 'move'"
                                    :disabled="selectedSection.section == null"
                                    v-model="selectedTeam"
                                    @keyup.enter="event => renameStructure('team', event)"
                                    @blur="event => renameStructure('team', event)"
                                    :items="teams"
                                    item-text="team"
                                    return-object
                                    auto-select-first />
                                <!-- <discardable-input
                                    :rules="teamRules"
                                    v-if="current.team.mode === 'edit' || current.team.mode === 'new'"
                                    :value="selectedTeam.team"
                                    @discard="discardChange('team')"
                                    @accept="value => acceptChange('team', value)"
                                    :placeholder="$t('components.manageOrganisation.team.new')" /> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div>{{ $t('components.manageOrganisation.supervisor') }}</div>
                                <v-autocomplete
                                    ref="teamSupervisor"
                                    required
                                    @keyup.enter="event => focusNext('teamSupervisor', 'users')"
                                    :rules="[v => selectedTeam.team == null || !!v || false]"
                                    v-model="selectedTeam.supervisor"
                                    :items="[...users].sort(user => (selectedTeam.supervisor || []).includes(user.uuid) ? -1 : 0)"
                                    item-text="name"
                                    item-value="uuid"
                                    :disabled="selectedTeam.team == null"
                                    auto-select-first
                                    multiple
                                    hide-no-data />
                            </v-col>
                            <v-col cols="12" sm="4"></v-col>
                        </v-row>
                    </div>
                    <v-row align="center">
                        <v-col cols="12" sm="12">
                            <div>{{ $t('components.manageOrganisation.user') }}</div>
                            <v-autocomplete
                                ref="users"
                                v-model="selectedTeam.teamMembers"
                                :disabled="selectedTeam.team == null"
                                @change="$refs.users.lazySearch = ''"
                                :items="[...users].sort(user => (selectedTeam.teamMembers || []).includes(user.uuid) ? -1 : 0)"
                                item-text="name"
                                item-value="uuid"
                                multiple
                                clearable
                                auto-select-first />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
                <v-row class="btns mx-0" flex>
                    <v-col cols="8" class="px-0 py-0" align="right">
                    <v-btn ref="save" color="primary" :disabled="!valid" @click="save" :class="saving == true ? 'savingData' : ''">{{ $t('components.manageOrganisation.save') }}</v-btn>
                    <v-btn color="primary" :disabled="!valid" @click="saveAndClose" :class="saving == true ? 'savingData' : ''">{{ $t('components.manageOrganisation.saveAndClose') }}</v-btn>
                    <v-btn color="primary" @click="close">{{ $t('generics.cancel') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <v-spacer></v-spacer>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog :value="showDiscardChangesDialog" max-width="450px">
        <v-card>
            <v-card-title class="headline">{{ $t('components.manageOrganisation.discardChangesHeadline') }}</v-card-title>
            <v-card-text>
                <p>{{ $t('components.manageOrganisation.discardChangesQuestion') }}</p>
            </v-card-text>
            <v-card color="red" class="py-2 pl-4 mt-4 pr-2 footerModal">
                <v-row class="px-2 btns mx-0" flex>
                    <v-col cols="12" class="px-0 py-0" align="right">
                    <v-btn :disabled="!valid" @click="$emit('update:visible', false); saveAndClose()">{{ $t('components.manageOrganisation.save') }}</v-btn>
                    <v-btn @click="$emit('update:visible', false)">{{ $t('components.manageOrganisation.discard') }}</v-btn>
                    <v-btn @click="showDiscardChangesDialog = false">{{ $t('generics.cancel') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
    </v-dialog>
    <v-dialog :value="structureToDeleteType != null" max-width="400px">
        <v-card>
            <v-card-title class="headline">
                <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
                {{ $t(`components.manageOrganisation.${structureToDeleteType}.delete`) }}
            </v-card-title>
            <v-card-text>
                <p>Soll '{{ structureToDelete != null ? structureToDelete[structureToDeleteType] : "" }}' inklusive aller Unterstrukturen wirklich gelöscht werden?</p>
                <span>Bitte bestätigen Sie die Löschung durch Eingabe des Namens:</span>
                <div style="position: relative; width: 100%; padding-top: 5px;">
                    <v-text-field ref="repeatStructureName" style="position: absolute; width: 100%" v-model="repeatStructureName" @keyup.enter="tryDeletion" />
                    <div style="font-size: 16px; padding-top: 5px; color: #cccccc">{{structureToDelete != null ? structureToDelete[structureToDeleteType] : ''}}</div>
                </div>
            </v-card-text>
            <v-card color="red" class="py-2 pl-4 mt-4 pr-2 footerModal">
                <v-row class="px-2 btns mx-0" flex>
                    <v-col cols="8" class="px-0 py-0" align="right">
                    <v-btn @click="deteleStructureAndSub" :disabled="isDeleteDisabled">Löschen</v-btn>
                    <v-btn @click="structureToDeleteType = null">{{ $t('components.manageOrganisation.abort') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import { entries, cloneDeep, sortBy, isEqual, includes, flatMap } from "lodash-es"
import { importUsersParsed} from "../../lib/wsMsg";
import DeleteBasket from "../ui/deleteBasket.vue";
import DiscardableInput from "./DiscardableInput.vue";

export default {
    props: ["visible"],
    components: {
        DiscardableInput,
        DeleteBasket,
    },
    mounted() {
        this.organisation = cloneDeep(this.state.namespaceSettings.organisation)
        this.$nextTick(() => {
            this.$nextTick(() => {
                this.$refs.section.focus();
            })
        })
    },
    watch: {
        structureToDelete: {
            handler(value) {
                if (value != null) {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$refs.repeatStructureName.focus()
                        })
                    })
                }
            }
        }
    },
    data() {
        return {
            valid: false,
            error: null,
            structureToDeleteType: null,
            current: {
                section: {
                    mode: "select"
                },
                department: {
                    mode: "select"
                },
                team: {
                    mode: "select"
                }
            },
            show: true,
            state: store.state,
            organisation: [],
            selectedSection: {},
            selectedDepartment: {},
            selectedTeam: {},
            saving: false,
            repeatStructureName: "",
            showDiscardChangesDialog: false
        }
    },
    computed: {
        allTeams() {
            return this.organisation.map(org => org.team).filter(Boolean)
        },
        teamRules() {
            return [
                v => includes(this.allTeams, v) ? `Team "${v}" existiert bereits` : true,
                v => !v ? "Team darf nicht leer sein" : true,
            ]
        },
        isDeleteDisabled() {
            return this.structureToDelete != null && this.structureToDelete[this.structureToDeleteType] != this.repeatStructureName
        },
        isSelectMode() {
            return this.current.section.mode == "select" && this.current.department.mode == "select" && this.current.team.mode == "select"
        },
        structure() {
            return this.selectedTeam.team ? this.selectedTeam : this.selectedDepartment.department ? this.selectedDepartment : this.selectedSection.section ? this.selectedSection : ""
        },
        structureToDelete() {
            switch(this.structureToDeleteType) {
                case "section":
                    return this.selectedSection;
                case "department":
                    return this.selectedDepartment;
                case "team":
                    return this.selectedTeam;
            } 
        },
        users() {
            return sortBy(entries(this.state.group).map(([uuid, value]) => ({
                uuid,
                permissions: value.permissions,
                ...value.user
            })), ["name"]).filter(user => !user.guest && !user.visitor && user.permissions != 10)
        },
        sections() {
            return this.organisation.filter(it => it.sectionRights === 1)
        },
        departments() {
            const allDepartments = this.organisation.filter(it => it.departmentRights === 1) 
            if (this.selectedSection.section) {
                return allDepartments.filter(it => it.section === this.selectedSection.section)
            } else {
                return allDepartments
            }
        },
        teams() {
            let teams = this.organisation.filter(it => it.departmentRights !== 1 && it.sectionRights !== 1)
            if (this.selectedSection.section) {
                teams = teams.filter(it => it.section === this.selectedSection.section)
            }
            if (this.selectedDepartment.department) {
                teams = teams.filter(it => it.department === this.selectedDepartment.department)
            } else {
                teams = teams.filter(it => it.department == null)
            }
            return teams
        },
        sectionSupervisorAssistants: {
            get() {
                return flatMap(this.selectedSection.supervisor?.map(uuid => this.state.group[uuid] ?? []), group => group.user.assistants)
            },
            set(assistants) {
                const group = this.state.group[this.selectedSection.supervisor[0]]
                if (group && group.user) {
                    group.user.assistants = assistants
                }
            }
        },
        departmentSupervisorAssistants: {
            get() {
                return flatMap(this.selectedDepartment.supervisor?.map(uuid => this.state.group[uuid] ?? []), group => group.user.assistants)
            },
            set(assistants) {
                const group = this.state.group[this.selectedDepartment.supervisor[0]]
                if (group && group.user) {
                    group.user.assistants = assistants
                } 
            }
        },
    },
    methods: {
        async save() {
            this.saving = true
            await this.$refs.form.validate()
            if (this.valid) {
                this.organisation = this.organisation.filter(it => {
                    return (it.sectionRights == 1 && it.section) ||
                    (it.departmentRights == 1 && it.department) ||
                    (it.sectionRights != 1 && it.departmentRights != 1 && it.team)
                })

                // split organisation node when it has section and department rights
                this.organisation = this.organisation.flatMap(it => {
                    if (it.sectionRights == 1 && it.departmentRights == 1) {
                        return [
                            {
                                section: it.section,
                                sectionRights: 1,
                                supervisor: it.supervisor,
                            },
                            {
                                department: it.department,
                                departmentRights: 1,
                                supervisor: it.supervisor,
                                section: it.section,
                            }
                        ]
                    } else {
                        return it
                    }
                })
                this.state.namespaceSettings.organisation = this.organisation
                const data = { user: this.users, organisation: this.organisation }
                try {
                    await importUsersParsed(data, { overwrite: false, update: true }); // Skip user deletion for extra safety
                    this.current.section.mode = "select";
                    this.current.department.mode = "select";
                    this.current.team.mode = "select";
                    const audio = new Audio("/media/notificationSound.mp3");
                    await audio.play()
                } catch(error) {
                    this.error = error
                    console.error(error)
                }
            }
            this.saving = false
        },
        async saveAndClose() {
            await this.save()
            this.close()
        },
        close() {
            if (!isEqual(JSON.stringify(this.organisation), JSON.stringify(this.state.namespaceSettings.organisation))) {
                this.showDiscardChangesDialog = true
            } else {
                this.$emit('update:visible', false)
            }
        },
        // TODO: deprecated
        acceptChange(type, value) {
            switch(type) {
                case "section":
                    this.organisation
                        .filter(structure => structure.section === this.selectedSection.section)
                        .forEach(structure => {
                            structure.section = value
                        })
                    break;
                case "department":
                    this.organisation
                        .filter(structure => structure.section === this.selectedSection.section)
                        .filter(structure => structure.department === this.selectedDepartment.department)
                        .forEach(structure => {
                            structure.department = value
                        })
                    break;
                case "team":
                    this.organisation
                        .filter(structure => structure === this.selectedTeam)
                        .forEach(structure => {
                            structure.team = value
                        })
                    break;
                default:
                    throw new Error("invalid type " + type)
            }
            this.current[type].mode = "select"
        },
        discardChange(type) {
            this.organisation = this.organisation.filter(org => org[type] !== "")
            this.current[type].mode = 'select'
        },
        renameStructure(type, event) {
            const value = event.srcElement.value.trim()
            switch(type) {
                case "section":
                    this.organisation
                        .filter(structure => structure.section === this.selectedSection.section)
                        .forEach(structure => {
                            structure.section = value
                        })
                    break;
                case "department":
                    this.organisation
                        .filter(structure => structure.section === this.selectedSection.section)
                        .filter(structure => structure.department === this.selectedDepartment.department)
                        .forEach(structure => {
                            structure.department = value
                        })
                    break;
                case "team":
                    this.organisation
                        .filter(structure => structure === this.selectedTeam)
                        .forEach(structure => {
                            structure.team = value
                        })
                    break;
                default:
                    throw new Error("invalid type " + type)
            }
            this.focusNext(type, `${type}Supervisor`)
        },
        addStructure(type) {
            const newStructure = { [type]: "" }
            
            if (type === "section") {
                newStructure.sectionRights = 1
                this.selectedSection = newStructure
            } else if (type === "department") {
                newStructure.departmentRights = 1
                newStructure.section = this.selectedSection.section
                this.selectedDepartment = newStructure
            } else if (type === "team") {
                newStructure.section = this.selectedSection.section
                newStructure.department = this.selectedDepartment.department
                this.selectedTeam = newStructure
            } else {
                throw new Error("invalid type " + type)
            }
            // console.log('new structure', newStructure, 'selectedSection', this.selectedSection, 'selectedDepartment', this.selectedDepartment, 'selectedTeam', this.selectedTeam)

            this.organisation.push(newStructure)
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$refs[type].focus()
                })
            })
        },
        removeStructure(type) {
            this.structureToDeleteType = type
        },
        moveStructure(type) {
            switch(type) {
                case "department":
                    this.current.department.mode = "move"
                    this.$refs.section.focus();
                    this.$refs.section.activateMenu();
                    break
                case "team":
                    this.current.team.mode = "move"
                    this.$refs.department.focus();
                    this.$refs.department.activateMenu();
                    break
            }
        },
        tryDeletion() {
            if (!this.isDeleteDisabled) this.deteleStructureAndSub()
        },
        deteleStructureAndSub() {
            this.organisation = this.organisation.filter(org => org[this.structureToDeleteType] !== this.repeatStructureName)
            switch(this.structureToDeleteType) {
                case "section":
                    this.selectedSection = {}
                    this.selectedDepartment = {}
                    this.selectedTeam = {}
                    break;
                case "department":
                    this.selectedDepartment = {}
                    this.selectedTeam = {}
                    break;
                case "team":
                    this.selectedTeam = {}
                    break;
                default:
                    throw new Error("invalid type " + this.structureToDeleteType)
            }
            this.structureToDeleteType = null
            this.repeatStructureName = ""
        },
        stringToArray(commaSeparatedString) {
            return commaSeparatedString ? commaSeparatedString.split(",") : []
        },
        arrayToString(array) {
            return array.join(",")
        },
        focusNext(previous, next) {
            if (previous) this.$refs[previous].blur()
            this.$nextTick(() => {
                this.$refs[next].focus()
            })
        },
        fieldChanged(field) {
            switch(field) {
                case "section":
                    if(this.current.department.mode === "move") {
                        this.organisation.filter(org => org.department === this.selectedDepartment.department).forEach(org => {
                            org.section = this.selectedSection.section
                        })
                    } else {
                        this.selectedDepartment = {}
                        this.selectedTeam = {}
                    }
                    break
                case "department":
                    if(this.current.team.mode === "move") {
                        this.selectedTeam.department = this.selectedDepartment.department
                    } else {
                        this.selectedTeam = {}
                    }
                    break
            }
        }
    }
};
</script>

<style scoped lang="scss">
:deep() .v-input {
    padding-top: 0;
    margin-top: 0;
}

:deep() .v-list-item {
    min-height: initial;
}

:deep() .v-list-item__content {
    padding: 0;
}

.v-card__text {
    padding-bottom: 0 !important;
}

.row > * {
    padding: 0 8px;
} 

.org-row {
    display: flex;
    align-items: center;

    .label {
        align-items: center;
        justify-content: space-between;
        column-gap: 4px;
    }

    .v-btn--icon {
        width: 24px;
        height: 24px;
    }

    :deep() .v-select__selections {
        flex-wrap: initial;
    }
}

.savingData {
  animation: blink 1s linear infinite;
  pointer-events: none;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>