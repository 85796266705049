var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v(_vm._s(_vm.getTimeRange))]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.getNameModerator))]),
    _vm._v(" "),
    _c("div", [
      _c("span", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("components.remotePersonCall.attendees")) +
            ":\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("\n      " + _vm._s(_vm.getAllUsers()) + "\n    ")])
    ]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.entry.title || ""))]),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.entry.details))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }