var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClose,
          expression: "onClose"
        }
      ],
      staticStyle: { height: "100%" }
    },
    [
      _c("CallLateralPanel", {
        attrs: {
          isConferenceCall: _vm.isConferenceCall,
          waitingRoomFor: _vm.waitingRoomFor,
          showLateralPanel: _vm.showLateralPanel,
          dataType: _vm.dataType,
          handlerShowLateralPanel: _vm.handlerShowLateralPanel
        }
      }),
      _vm._v(" "),
      _vm.maximizeScreen
        ? _c(
            "div",
            { staticClass: "d-flex flex-column w55 sidebarCall" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-auto mt-2 mb-2",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.goToDefaultPage()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "color-white",
                                  style: { fontSize: "16px" },
                                  attrs: { icon: ["fal", "home"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    360445966
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("literals.Home")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  ref: "buttonMessages",
                                  staticClass: "mx-auto mt-2 mb-2",
                                  class: {
                                    darkGreyIcon:
                                      _vm.showLateralPanel &&
                                      _vm.dataType === "messages"
                                  },
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showLateralPanel &&
                                      _vm.dataType == "messages"
                                        ? _vm.handlerShowLateralPanel(
                                            false,
                                            null
                                          )
                                        : _vm.handlerShowLateralPanel(
                                            true,
                                            "messages"
                                          )
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "color-white",
                                  style: { fontSize: "16px" },
                                  attrs: { icon: ["fal", "paper-plane"] }
                                }),
                                _vm._v(" "),
                                _vm.totalMessagesNotification > 0
                                  ? _c("v-badge", {
                                      staticClass: "waitingRoomBadge",
                                      attrs: {
                                        right: "",
                                        color: "error",
                                        content: _vm.totalMessagesNotification,
                                        value: _vm.totalMessagesNotification,
                                        bordered: "",
                                        overlap: "",
                                        "offset-x": "7",
                                        "offset-y": "7"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2047250246
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.drawerContentCard.messages"))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-auto mt-2 mb-2",
                                  class: {
                                    darkGreyIcon:
                                      _vm.showLateralPanel &&
                                      _vm.dataType === "favorites"
                                  },
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showLateralPanel &&
                                      _vm.dataType == "favorites"
                                        ? _vm.handlerShowLateralPanel(
                                            false,
                                            null
                                          )
                                        : _vm.handlerShowLateralPanel(
                                            true,
                                            "favorites"
                                          )
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "primary--text-sidepanel" },
                                  [_vm._v(" mdi-star-outline")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1243453945
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.sidebar.favoritesMiniTT")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  ref: "buttonUsers",
                                  staticClass: "mx-auto mt-2 mb-2",
                                  class: {
                                    darkGreyIcon:
                                      _vm.showLateralPanel &&
                                      _vm.dataType === "users"
                                  },
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoPage("/users")
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "color-white",
                                  style: { fontSize: "16px" },
                                  attrs: { icon: ["fal", "user-plus"] }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3595289714
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.sidebar.userMiniTT")))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.getAmIModerator && _vm.hasPrivilege(_vm.ownUUID)
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-auto mt-2 mb-2",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openModalSettings()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "color-white",
                                        style: { fontSize: "20px" },
                                        attrs: { icon: ["fal", "bolt"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1374545339
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.callsContent.addParticipants")
                            )
                          )
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _vm.showWaitingRoom && _vm.hasPrivilege(_vm.ownUUID)
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-auto mt-2 mb-2",
                                      class: {
                                        darkGreyIcon:
                                          _vm.showLateralPanel &&
                                          _vm.dataType === "myWR"
                                      },
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handlerOpenVisitor()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-img", {
                                      staticStyle: { "margin-left": "3px" },
                                      attrs: {
                                        contain: "",
                                        "max-height": "28x",
                                        "max-width": "25px",
                                        src: "img/icons/guest-plus-white.svg",
                                        "lazy-src":
                                          "img/icons/guest-plus-white.svg",
                                        width: "16px",
                                        height: "16px"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.getconnecetedWaitingrooms > 0
                                      ? _c("v-badge", {
                                          staticClass: "waitingRoomBadge",
                                          attrs: {
                                            right: "",
                                            color: "error",
                                            content:
                                              _vm.getconnecetedWaitingrooms,
                                            value:
                                              _vm.getconnecetedWaitingrooms,
                                            bordered: "",
                                            overlap: "",
                                            "offset-x": "7",
                                            "offset-y": "7"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    111636491
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.sidebar.waitingRoomMiniTT"))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showWaitingRoom && _vm.assistantsUser
                ? _vm._l(_vm.assistantsUser, function(uuid) {
                    return _c(
                      "v-tooltip",
                      {
                        key: uuid,
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-auto mt-2 mb-2",
                                        class: {
                                          darkGreyIcon:
                                            _vm.showLateralPanel &&
                                            _vm.dataType === "wrFor"
                                        },
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handlerOpenVisitor()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-img", {
                                        staticStyle: { "margin-left": "3px" },
                                        attrs: {
                                          contain: "",
                                          "max-height": "28x",
                                          "max-width": "25px",
                                          src: "img/icons/guest-plus-white.svg",
                                          "lazy-src":
                                            "img/icons/guest-plus-white.svg",
                                          width: "16px",
                                          height: "16px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.sidebarCall.waitingRoomFor", [
                                _vm.getTitelForUuid(uuid),
                                _vm.getNameForUuid(uuid)
                              ])
                            ) + "\n        "
                          )
                        ])
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPrivilege(_vm.ownUUID)
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-auto mt-2 mb-2",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(
                                              "/newconference"
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "color-white",
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fal", "users"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3758198462
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("components.groups.konferenzen"))
                          )
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showOrganigram && _vm.hasPrivilege(_vm.ownUUID)
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-auto mt-2 mb-2",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage("/organisation")
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "color-white",
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fal", "sitemap"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1945344987
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("generics.organisation")))
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.state.namespaceSettings.showCoffeeBreak &&
              _vm.hasPrivilege(_vm.ownUUID)
                ? [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-auto mt-2 mb-2",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage("/coffee-break")
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "color-white",
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fal", "mug"] }
                                      }),
                                      _vm._v(" "),
                                      _vm.getConnectedCoffeeBreak > 0
                                        ? _c("v-badge", {
                                            staticClass: "waitingRoomBadge",
                                            attrs: {
                                              right: "",
                                              color: "error",
                                              content:
                                                _vm.getConnectedCoffeeBreak,
                                              value:
                                                _vm.getConnectedCoffeeBreak,
                                              bordered: "",
                                              overlap: "",
                                              "offset-x": "7",
                                              "offset-y": "7"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1103496108
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("status.Coffee break")))
                        ])
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mx-auto mt-2 mb-2",
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.gotoPage("/new-room")
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "color-white",
                                    style: { fontSize: "16px" },
                                    attrs: { icon: ["fal", "comment"] }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1387584035
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("components.sambaRooms.callRooms")))
                    ])
                  ]
                )
              ],
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-auto mt-2 mb-2",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openBugModal()
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(
                                  "font-awesome-icon",
                                  _vm._g(
                                    {
                                      staticClass: "color-white",
                                      style: { fontSize: "16px" },
                                      attrs: { icon: ["fal", "headset"] }
                                    },
                                    on
                                  )
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1216861056
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.support")))])
                ]
              ),
              _vm._v(" "),
              _vm.state.namespaceSettings.showGPT4BOT
                ? _c(
                    "div",
                    { staticClass: "askmeContainerCall" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mx-auto mt-2 mb-2 w100",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openGpt4bot()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "font-awesome-icon",
                                          _vm._g(
                                            {
                                              staticClass: "color-white",
                                              style: { fontSize: "16px" },
                                              attrs: {
                                                icon: ["fal", "comments"]
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3444319897
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.sidebar.askYourQuestion")
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("AddParticipantsCall", {
                attrs: {
                  closeModal: _vm.closeModalSettings,
                  showModalSettings: _vm.showModalSettings,
                  instaLink: _vm.instaLink,
                  handlerOpenVisitor: _vm.handlerOpenVisitor,
                  openUsers: _vm.openUsers
                }
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }