<template>
  <section>
    <draggable v-if="!isGuest" v-model="modifiedNotes">
      <transition-group name="card">
        <v-col
          draggable="true"
          style="height:300px;"
          :md="4"
          :sm="4"
          class="noteDiv px-1 col-12"
          v-for="(oneNote, i) in modifiedNotes"
          :key="'divnote-'+i"
        >
          <div :id="'note-'+i" >
            <v-card class="mx-auto" outlined flat>
              <UserProfle
                v-if="showUserProfile"
                :showUserProfile="showUserProfile"
                :toggleUserProfile="toggleUserProfile"
              />
              <v-list-item three-line v-bind:class="[oneNote.noteColor]">
                <v-list-item-content style="height:250px;" :class="isDark ? 'noteListItemBlack' :'noteListItem'">
                  <label
                    @click="setUserInModal(oneNote.author)"
                    :class=" isDark ? 'footerDate' : 'footerDateBlack'"
                    style="font-size: 14px;"
                    class="d-flex justify-space-between"
                  >

                  <span>  {{ getDateFormatted(oneNote.date, true) + ' ' + $t('literals.by') + ' '+ (((state.group[oneNote.author] || {}).user || {}).titel && state.group[oneNote.author].user.titel !== 'unknown' ? state.group[oneNote.author].user.titel : '') +' '+ oneNote.authorName || ''}}</span>
                  <span>{{getNoteViews(oneNote.id)}} Views</span>
                  </label>
                 <label v-if="(getNoteRData(oneNote) === 1)" style="font-size: 14px;;font-weight:600;">{{$t('components.singleNote.todepartment')}} {{oneNote.targetDepartments[0]}}&nbsp;{{state.namespaceSettings.companyInfo.virtualOfficePrefix}}</label>
                 <label v-else-if="(getNoteRData(oneNote) === 2)" style="font-size: 14px;;font-weight:600;">{{$t('components.singleNote.tosection')}} {{oneNote.targetSections[0]}}&nbsp;{{state.namespaceSettings.companyInfo.virtualOfficePrefix}}</label>
                 <label v-else-if="(getNoteRData(oneNote) === 3)" style="font-size: 14px;;font-weight:600;">{{$t('components.singleNote.toteam')}} {{oneNote.targetTeams[0]}}&nbsp;{{state.namespaceSettings.companyInfo.virtualOfficePrefix}}</label>
                 <label v-else-if="(getNoteRData(oneNote) === 4)" style="font-size: 14px;;font-weight:600;color:black!important">{{$t('components.singleNote.toallusers')}}&nbsp;{{state.namespaceSettings.companyInfo.virtualOfficePrefix}}</label>

        
                  <v-list-item-title v-bind:class="{'my-2': editId !== i}" class="noBottom">
                    <v-text-field :rules="rules" v-model="oneNote.title" v-if="edit && editId == i"></v-text-field>
                    <label
                      class="labelBold"
                      :class="{isDone: oneNote.completed}"
                      v-else
                    >{{oneNote.title}}</label>
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle>{{oneNote.date}}</v-list-item-subtitle> -->
                  <v-list-item-subtitle v-bind:class="{'my-2': editId !== i,'blackText': isDark}" class="noTop">
                    <v-textarea
                      v-if="edit && editId == i"
                      name="input-7-1"
                      no-resize
                      v-model="oneNote.text"
                    ></v-textarea>
                    <p
                      v-else
                      :class="{expand: idToExpand == i, isDone: oneNote.completed}"
                      v-html="modifiedText(i)"
                    ></p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions class="primary noteFooter">
                <v-layout justify-start>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        color="white"
                        v-if="!edit && amIAdmin"
                        @click="handleEditNote(i, oneNote)"
                      >
                        <font-awesome-icon :icon="['fal', 'pen']" :style="{ fontSize: '20px' }" />
                      </v-btn>
                    </template>
                    <span>{{$t('generics.edit')}}</span>
                  </v-tooltip>

                   <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" style="margin-left:20px;" icon color="white"  v-if="amIAdmin">
                         <DeleteBasket :indexData="[$event, oneNote.id, i]" :delFunction="showModalDelete" extraClasses="" fontSize="20" prevent= false />
                      </v-btn>
                    </template>
                    <span>{{$t('generics.remove')}}</span>
                  </v-tooltip>
                  <v-btn icon color="white" @click="toggleTransition(i)" v-if="amIAdmin && 1 === 2">
                    <font-awesome-icon :icon="['fal', 'palette']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                  <v-btn
                    icon
                    color="white"
                    v-if="edit && editId == i"
                    @click="updateNote(i, oneNote)"
                  >
                    <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                 
                </v-layout>
                <v-layout justify-end>
                   <label v-if="oneNote.activeUntil !== undefined && oneNote.activeUntil !== ''" style="margin-right: 42px;line-height: 2.5em;color: white;">{{$t('components.modalNote.postingUntil')}} {{getDateFormatted(oneNote.activeUntil, false)}} </label>
                      <v-btn
                        justify="end"
                        icon
                        color="white"
                        v-if="noteIsLong(i)"
                        @click="openModalNote(oneNote,false)"
                      >
                        <font-awesome-icon :icon="['fal', 'expand-alt']" :style="{ fontSize:20 }" />
                     </v-btn>
                </v-layout>
              </v-card-actions>

              <!-- <div class="colors" :class="{openDivs: currentID == i}">
                <div
                  @click="changeColor(i, 'blue-note')"
                  class="circle blue-note"
                  :class="{selected: oneNote.color == 'blue-note'}"
                ></div>
                <div
                  @click="changeColor(i, 'yellow-note')"
                  class="circle yellow-note"
                  :class="{selected: oneNote.color == 'yellow-note'}"
                ></div>
                <div
                  @click="changeColor(i, 'red-note')"
                  class="circle red-note"
                  :class="{selected: oneNote.color == 'red-note'}"
                ></div>
                <div
                  @click="changeColor(i, 'purple-note')"
                  class="circle purple-note"
                  :class="{selected: oneNote.color == 'purple-note'}"
                ></div>
                <div
                  @click="changeColor(i, 'white-note')"
                  class="circle whiteCircle-note"
                  :class="{selected: oneNote.color == 'white-note'}"
                ></div>
              </div>-->
            </v-card>
          </div>
        </v-col>
      </transition-group>
    </draggable>

    <!-- Modal remove Note -->

    <v-dialog v-model="showModalDeleteNote" persistent max-width="460"  @keydown.esc="closeModal">
      <v-card>
        <v-card-title class="headline">
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{ $t('components.singleNote.deleteNote') }}
        </v-card-title>
        <v-card-text>Are you sure you want to remove this note? You can not undo this action.</v-card-text>
        <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" flex>
            <v-col cols="8" class="px-0 py-0" align="right">
              <v-btn color="primary" @click="closeModal">{{ $t('generics.cancel') }}</v-btn>
              <v-btn color="primary" @click="deleteNote()">{{ $t('generics.delete') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <!-- End modal remove note -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
import moment from "../../../../sharedsrc/moment";
import store from "../../../store";
import { setNamespaceSetting } from "../../../lib/wsMsg";
import UserProfle from "../../../components/modal/userProfile.vue";
import DeleteBasket from "../../../components/ui/deleteBasket.vue";
import {
 unreadNotesToMe,
 cachedNotes,
 dispatchReadedNoteEvent,
 dispatchDeleteNoteEvent
} from "../../../effector/notes";

export default {
  components: {
    draggable,
    UserProfle,
    DeleteBasket,
    
  },
  props: ["allNotes"],
  data() {
    return {
      state: store.state,
      rules: [
        value => !!value || this.$t('generics.required'),
        value => (value || "").length <= 30 || "Max 20 characters"
      ],
      showModalDeleteNote: false,
      showUserProfile: false,
      noteDate: new Date().toISOString(),
      // toLocaleDateString([], {
      //   day: "2-digit",
      //   month: "2-digit",
      //   year: "2-digit",
      //   hour: "2-digit",
      //   minute: "2-digit"
      // }),
      moment: moment,
      // modifiedNotes: this.allNotes,
      // Temporary IDs
      currentID: -2,
      editId: -1,
      idToCopy: -1,

      // Transitions
      opened: false,
      safeWait: false,

      // Confirm Delete
      confrim: "",

      // Edit Mode
      edit: false,

      // Expand
      idToExpand: -1,
      expanded: false,
      noteToDelete: undefined,
      ownUUID: store.state.ownUUID,
      allowedUsers: [],
      unreadNotesCounter: 0,

      userList: store.getProcessedUserList(),
      processing: false
    };
  },
   watch: {
    //  unreadNotesToMe:{
    //     handler: function (unreadNotes) {
    //        Object.keys(unreadNotes).forEach(noteUUID => {
    //          dispatchReadedNoteEvent(noteUUID)
    //       });
    //  }
    //  }
  //  modifiedNotes: {
  //     handler: function (notes) {
  //       notes.forEach(note => {
  //         if(note.id && this.unreadNotesToMe[note.id]){
  //           // fire event 
  //           this.processing = true;
  //           if(!this.processing){
  //             dispatchReadedNoteEvent(note.id)
  //           }
  //         }
  //       });
  //     },
  //     deep: true,
  //     immediate: true,
  //   }
  },
  effector: {
    unreadNotesToMe: unreadNotesToMe,
    cachedNotes: cachedNotes,
  },
  mounted(){
    const unreadNotesList = Object.keys(this.unreadNotes)
    if(unreadNotesList.length>0){
      unreadNotesList.forEach(noteUUID => {
        dispatchReadedNoteEvent(noteUUID)
      });
    }
  },
  computed: {
    unreadNotes(){
      return this.unreadNotesToMe
    },
    isDark(){
      return store.state.persisted.isDark;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    modifiedNotes() {
      let everyNote = this.allNotes;
      let filteredNotes = [];
      let noteUsers = [];
      const currentDate = new Date();
      filteredNotes = everyNote.filter(note =>{
        return (!note.activeUntil || new Date(note.activeUntil) > currentDate) 
      });

      filteredNotes = filteredNotes.filter(note => {
        const castToArray = (elem) => {
          if (typeof elem === 'undefined') return [];
          if (Array.isArray(elem)) return elem;
          return [elem];
        };
        return this.amIAdmin || this.noteIsOpen(note) || note.author === this.ownUUID 
          || (castToArray(note.targetUsers)).includes(this.ownUUID)
          || (castToArray(note.targetSections)).filter((section) => {
            return ((this.userList[this.ownUUID] || {}).supervisorOfSections || []).includes(section)
              || ((this.userList[this.ownUUID] || {}).teamSections || []).includes(section);
          }).length
          || (castToArray(note.targetDepartments)).filter((department) => {
            return ((this.userList[this.ownUUID] || {}).supervisorOfDepartments || []).includes(department)
              ||  ((this.userList[this.ownUUID] || {}).teamDepartments || []).includes(department);
          }).length
          || (castToArray(note.targetTeams)).filter((team) => {
            return ((this.userList[this.ownUUID] || {}).supervisorOfTeams || []).includes(team)
              || ((this.userList[this.ownUUID] || {}).memberOfTeams || []).includes(team);
          }).length;
      });
      return filteredNotes;
    }
  },
  methods: {
    getNoteViews(noteId){
      let views = 0
      if(this.cachedNotes && this.cachedNotes[noteId] && this.cachedNotes[noteId].users){
        for (const user in this.cachedNotes[noteId].users) {
          if (Object.hasOwnProperty.call(this.cachedNotes[noteId].users, user)) {
            const element = this.cachedNotes[noteId].users[user];
            if(element.read) views+=1;
          }
        }
      }
      return views
  
    },
    getDateFormatted(till, showmins = false){
      if (showmins){
         return this.moment(till).format("DD.MM.YY H:mm") + ' '+ this.$t("generics.hour");
      } else {
         return this.moment(till).format("DD.MM.YY");
      }
     
    },
    getNoteRData(note){
      let mssg = '';
      if(note){
       if((note.targetDepartments || []).length > 0){
         mssg = 1; // $t('components.singleNote.todepartment') 
       } else if((note.targetSections  || []).length  > 0){
           mssg = 2; // $t('components.singleNote.tosection') 
       } else if((note.targetTeams || []).length  > 0){
         mssg = 3; // $t('components.singleNote.toteam')  
       } else {
         mssg = 4; // $t('components.singleNote.toallusers') 
       }
      }
      return mssg;
    },
    noteIsOpen(note){
       return (
         (note.targetTeams || []).length === 0  &&  
         (note.targetDepartments || []).length === 0  &&
         (note.targetSections || []).length === 0)
    },
    setUserInModal(userid) {
      let person = store.state.group[userid];
      person.uuid = userid;
      store.state.ownModalUser = (person.uuid === this.state.ownUUID);
      store.state.userOnModal = { ...person.user };
      store.state.userOnModal.connected = person.connected;
      store.state.userOnModal.uuid = person.uuid;
      store.state.userOnModal.userTS = person.userTS;

      this.toggleUserProfile();
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    todoFetch() {
      return store.state.namespaceSettings.notes || [];
    },
    todoSave(notes) {
      setNamespaceSetting("notes", notes);
    },
    modifiedText(id) {
      // Detect Links
      const detectLinks = /((https?:\/\/)(\S+))/g;
      const detectLinksWWW = /(^|[\s])(((www\.))(\S+))/g;
      if (
        this.modifiedNotes[id].text.match(detectLinks) ||
        this.modifiedNotes[id].text.match(detectLinksWWW)
      ) {
        return this.$sanitize(
          this.modifiedNotes[id].text
            .replace(
              detectLinks,
              '<a rel="noopener noreferrer" href="$1" target="_black">$1</a>'
            )
            .replace(
              detectLinksWWW,
              '$1<a rel="noopener noreferrer" href="https://$2" target="_black">$2</a>'
            )
        );
      } else {
        return this.$sanitize(this.modifiedNotes[id].text);
      }
    },
    noteIsLong(id) {
      const text = this.modifiedNotes[id].text;
      // var match = /\r|\n/.exec(text);
      if (text.length > 50) {
        //|| match
        return true;
      } else {
        return false;
      }
    },
    copyLink(id) {
      const copiedNote = this.modifiedNotes[id];

      let theNote = JSON.stringify(copiedNote);
      theNote = encodeURIComponent(theNote);

      const theLink =
        location.href.replace("index.html", "").replace(location.search, "") +
        "share.html?note=" +
        theNote;
      this.idToCopy = id;

      setTimeout(() => {
        this.idToCopy = -1;
      }, 1200);

      function copyToClipboard(text) {
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("value", text);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      }

      //alert(theLink);
      copyToClipboard(theLink);
    },
    // Toggle The Effect
    toggleTransition(id) {
      if (id >= 0) {
        this.currentID = id;
      } else if (id == -1) {
        this.currentID = -1;
      }

      if (this.opened == false) {
        this.opened = true;
      } else {
        this.currentID = -2;
        this.opened = false;
      }
    },
    // Note Processes
    showModalDelete(evt, noteId, index) {
      if(!evt.target.classList.contains('redClass') && !this.safeWait){
        evt.target.classList.add('redClass');
        this.safeWait = true;
        let root = this;
         setTimeout(() => {root.safeWait = false}, 1000);
         setTimeout(() => {root.safeWait = false; evt.target.classList.remove('redClass'); }, 5000);
      } else if(evt.target.classList.contains('redClass') && !this.safeWait){
        evt.target.classList.remove('redClass');
         this.noteToDelete = index;
         this.deleteNote(noteId);
      }
    },
    deleteNote(noteId) {
      this.modifiedNotes.splice(this.noteToDelete, 1);
      this.todoSave(this.modifiedNotes);
      // remove from effector
      dispatchDeleteNoteEvent(noteId)
      // noteId
      this.closeModal();
    },
    closeModal() {
      this.noteToDelete = undefined;
      this.showModalDeleteNote = false;
    },
    changeColor(id, color) {
      this.modifiedNotes[id].color = color;
      this.todoSave(this.modifiedNotes);
      this.currentID = -2;
      this.opened = false;
    },
    handleEditNote(i, note) {
      this.openModalNote(note, false, true);
    },
    editNote(i) {
      this.edit = true;
      this.editId = i;
    },
    updateNote(id, note) {
      note.date = this.noteDate;
      if (note.text.length > 106) {
        note.long = true;
      } else {
        note.long = false;
      }
      this.modifiedNotes[id] = note;
      this.todoSave(this.modifiedNotes);
      this.editId = -1;
      this.edit = false;
    },
    expandNote(id) {
      if (this.expanded == false) {
        this.idToExpand = id;
        this.expanded = true;
      } else {
        this.expanded = false;
        this.idToExpand = -1;
      }
    },
    openModalNote(note, inserting = false, editnote = false) {
      store.setShowNote(true, note, inserting, editnote);
    },
    finishTask(id) {
      if (this.modifiedNotes[id].completed == false) {
        this.modifiedNotes[id].completed = true;
      } else {
        this.modifiedNotes[id].completed = false;
      }
      this.todoSave(this.modifiedNotes);
    }
  }
};
</script>
<style scoped>
.noteFooter {
  height: 36px !important;
}
.redClass{
  font-size:26px !important;
  font-weight: 800;
  color:red;
}
.noteListItem{
  height: 250px;
  align-items: flex-start;
}
.noteListItemBlack{
   height: 250px;
  align-items: flex-start;
  color: black !important;
}
.blackText{
  color: black !important;
}
.noBottom{
  margin-bottom: 0px !important;
}
.noTop{
  margin-top: 0px !important;
  font-size: 15px;
}
.footerDate {
  /* color: white; */
  /* line-height: 2.8em; */
  text-align: right;
  cursor: pointer;
}
.footerDateBlack{
  color: black !important;
}
.labelBold {
  font-weight: bold !important;
  font-size: 15px !important;
}
.positionRigth {
  float: right;
}

.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.blue-note {
  background-color: lightblue !important;
}
.lime-note {
 background-color: limegreen !important;
}
.pink-note {
 background-color: pink !important;
}
.white-note,
.white {
  background-color: unset !important;
}
.whiteCircle-note {
  background-color: #fff !important;
  border: 1px solid #ccc;
}
.theme--dark .white-note,
.theme--dark .white,
.theme--dark .single-note:not(.add-note) .white-note .v-list-item__title,
.theme--dark .single-note:not(.add-note) .white-note .v-list-item__subtitle,
.theme--dark .white-note .single-note:not(.add-note) .v-list-item__title {
  color: white !important;
}
.single-note:not(.add-note) .v-list-item__subtitle,
.single-note:not(.add-note) .v-list-item__subtitle {
  color: rgba(0, 0, 0, 0.6) !important;
}

.single-note:not(.add-note) .v-list-item__title {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
