var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "width100 mt-4" }, [
    _vm.entry
      ? _c(
          "div",
          { staticClass: "width100" },
          [
            _c("GeneralView", { attrs: { entry: _vm.entry } }),
            _vm._v(" "),
            _vm.entry.users && _vm.entry.users.length > 0
              ? _c(
                  "div",
                  { staticClass: "px-2" },
                  [
                    _c("div", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("components.eventEntryForm.participants")
                          ) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      _vm._l(_vm.entry.users, function(userUUID) {
                        return _c(
                          "v-col",
                          {
                            key: userUUID,
                            staticClass: "px-1 py-1",
                            attrs: { cols: "3" }
                          },
                          [
                            userUUID !== _vm.entry.creatorUUID
                              ? _c("UserDisplay", {
                                  attrs: {
                                    userUUID: userUUID,
                                    userHasRejected: _vm.userHasRejectedEvent(
                                      userUUID
                                    )
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isRsvp
                              ? _c("RsvpView", {
                                  attrs: {
                                    userUUID: userUUID,
                                    entry: _vm.entry
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _c("div", [_vm._v("No Entry")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }