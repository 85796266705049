var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      on: {
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.endCall.apply(null, arguments)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.endCall.apply(null, arguments)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.closeModalFunction.apply(null, arguments)
          }
        ]
      },
      model: {
        value: _vm.showFinishCallModal,
        callback: function($$v) {
          _vm.showFinishCallModal = $$v
        },
        expression: "showFinishCallModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c(
              "HeaderModal",
              {
                attrs: {
                  titleModal: _vm.$t("generics.info"),
                  closeModalFunction: _vm.closeModalFunction
                }
              },
              [_c("v-spacer")],
              1
            ),
            _vm._v(" "),
            _c("v-card-text", { staticClass: "py-4 fontSize16" }, [
              _c("div", { staticClass: "d-flex justify-center" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.finishCallSentence) + "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: {
                  closeModalFunction: _vm.closeModalFunction,
                  hideDefaultButtons: true
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "ml-3 mr-2 primary--text buttonIconPrimaryFooter",
                    attrs: { "min-width": "45px", color: "primary" },
                    on: { click: _vm.endCall }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("components.conferences.yes")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "ml-3 primary--text mr-2 buttonIconPrimaryFooter",
                    attrs: { "min-width": "45px", color: "primary" },
                    on: { click: _vm.closeModalFunction }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("generics.no")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }