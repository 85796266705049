<template>
  <div>
    <div
      :class="`px-0 py-0 contentGridWrapper`"
      v-on:scroll.native="handleScroll"
    >
        <v-row class="mx-auto">
          <v-col class="pb-1 px-2" cols="2">
            <v-card
            v-if="workersLoungeRoom"
            class="cardWidth"
            style="background-color: #eaeaea"
            :style="{ border: '2px solid rgb(121, 85, 72)', backgroundColor: '#eaeaea' }"
            @click="goToWorkersLounge"
          >
            <!-- <v-tooltip location="top"> -->
            <!-- <template> -->
              <v-tooltip location="top" :disabled="!getTotalUsers(workersLoungeRoom)">
                <template v-slot:activator="{ props }">
                  <div class="contentSambaRomm" v-bind="props" :class="workersLoungeRoom?.roomImage && workersLoungeRoom?.roomImage === 'img/icon.png' ? 'imageSambaRoom': ''">
                    <div class="contentSambaRomm">
                       <!-- <SambaRoomOverlay :room="room" /> -->
                       <div class="centered-text">
                        <p class="text-h6 font-weight-bold px-4">{{$t("components.workersLounge.joinWorkersLounge")}}</p>
                       </div>
                       <div class="gradientBg">
                        <v-card-subtitle
                          style="color: #fff !important; padding-bottom: 6px;padding-top: 6px;"
                          class="titleCard colorWhite px-2 text-truncate"
                        >
                        <!-- No actions as this is a static room for everybody, no editable -->
                          <RoomCardActions
                            :room="workersLoungeRoom"
                            :hideOptions="true"
                          />
                        </v-card-subtitle>
                      </div> 
                    </div>
                  </div>
                </template>
                <div>
                  <span>
                    <font-awesome-icon
                      :icon="['fal', 'video']"
                    />
                    {{ getUsersNameInRoom(workersLoungeRoom) }}
                  </span>
                </div>
              </v-tooltip>
            <!-- </template> -->
            <!-- <ConferenceInfoTooltip
                :conference="conference"
              ></ConferenceInfoTooltip> -->
            <!-- </v-tooltip> -->
            </v-card>
          </v-col>
          <!-- <v-col
            v-for="(element, index) in paginatedData"
            :key="index"
            class="pb-1 px-2"
            cols="2"
          >
             START USER CARD
            <v-card
              flat
              class="cardWidth wr"
              @click="handleClicks($event, element, `flipCard-${index}`)"
              :style="getBorderByStatusFull(element)"
              v-if="element.uuid"
            >
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <v-img
                      :src="getAvatarByType(element.uuid)"
                      class="text-white align-end customImageFit"
                      height="180px"
                    >
                      <div class="gradientBg">
                        <v-card-subtitle
                          style="color: #fff !important; opacity: 1"
                          class="
                            titleCard
                            colorWhite
                            pt-1
                            pb-1
                            px-2
                            customFontSize
                            text-truncate
                          "
                        >
                          {{ titelPerson(element) }}
                          {{ namePerson(element, index) }}</v-card-subtitle
                        >
                        <ButtonsFrontCard
                          :person="element"
                          :uuid="element.uuid"
                          sectionName="members"
                          :flipCard="flipCard"
                          :index="index"
                          typeData="users"
                        />
                      </div>
                    </v-img>
                  </div>
                </template>
                <UserInfoTooltip :person="element"></UserInfoTooltip>
              </v-tooltip>
            </v-card>
            END USER CARD
          </v-col> -->
        </v-row>
      <v-row class="paginationPosition">
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              :model-value="page" @update:model-value="page = $event"
              :length="getTotalPages"
              circle
              v-if="getTotalPages > 1"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../../sharedsrc/moment";
import store, { EventBus } from "../../../store";
import Flipper from "vue-flipper";
import UserInfoTooltip from "../userInfoTooltip.vue";
import { sendFileTo } from "../../../lib/rtcConn";
import DefaultAvatarGrid from "../../image/defaultAvatarGrid.vue";
import UserProfle from "../../modal/userProfile.vue";
import SendInvitationModal from "../../modal/sendInvitationModal.vue";
import { bridgeCallDoStaticRoom } from "../../../lib/wsMsg";
import { v4 as uuidv4 } from "uuid";
import { totalUsersInRoom, usersNamesInRoom, joinSambaRoom } from "../../../utils/staticRoom";
import {
  callPerson,
  disableCallBtn,
  wsCallStartBridgeCall,
  // personIsInBridgeCall,
  directBridgeCall,
  userInCallText,
  amInABridgeCall
} from "../../../utils/calls";
import {
  getAvatarByType,
  getBorderByStatusFull,
  checkListSort,
  uid
} from "../../../utils/basicFunctions";
import ButtonsFrontCard from "../buttonsFrontCard.vue";
import ButtonsCardWrapper from "../buttonsCardWrapper.vue";
// import { markAsCallWaitingRoomUserEvent, markAsCallWaitingRoomForUserEvent } from "../../../effector/users/waitingInfo";
import { isMobile } from "../../../lib/mobileUtil";
import RoomCardActions from "../roomCardActions.vue";
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  components: {
    Flipper,
    DefaultAvatarGrid,
    UserProfle,
    UserInfoTooltip,
    SendInvitationModal,
    ButtonsFrontCard,
    ButtonsCardWrapper,
    RoomCardActions,
  },
  props: ["typeData", "removeGeneralWrapper", "event"],
  data() {
    const effector = {
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      dataRooms: [],
      workersLoungeRoom: undefined,
      workersLoungeRoomStaticName: 'Workers Lounge',
      state: store.state,
      clicks: 0,
      timer: null,
      deleteFavorite: {
        show: false,
        person: undefined,
      },
      changeUserFunction: {
        show: false,
        person: undefined,
      },
      ownUUID: store.state.ownUUID,
      showUserProfile: false,
      showInvitationModal: false,
      sliceIndex: 48,
      obConferences: [],
      activeGroupId: null,
      page: 1,
      usersPerPage: 17,
      moment,
      selectedConference: "",
      isMobile: isMobile(),
      // Effector
      ...effector,
      oldActivity: "",
    };
  },
  beforeMount() {
    this._beforeMountPromise = bridgeCallDoStaticRoom('list')
      .then(data => {
        if (this._destroyed) return;
        this.dataRooms = data;
        this.getOrCreateWorkersLoungeRoom(data);
      });
  },
  // beforeUnmount() {
  //   console.log('ICEICE before unmount trying to revert state');
  //   this.revertToOldActivity();
  // },
  methods: {
    getTotalUsers(room){
      return totalUsersInRoom(room)
    },
    async getOrCreateWorkersLoungeRoom(data) {
      // Room already exists
      const existingRoom = data.filter( room => room.roomName === this.workersLoungeRoomStaticName); // Check by name
      if (existingRoom.length > 0){
        this.workersLoungeRoom = existingRoom[0];
      } else {
        // Room does not exist
        const obj = {
          roomId: uuidv4(),
          roomName: this.workersLoungeRoomStaticName,
          roomDescription: this.$t('components.workersLounge.workersLoungeRoomDescription'),
          roomImage: "img/icon.png",
        };
        const elementIndex = this.dataRooms.findIndex(
          (e) => e.roomId === obj.roomId
        );
        if (elementIndex === -1) {
          const { _id, roomId, roomGuid } = await bridgeCallDoStaticRoom('add', obj); // Create new room
          Object.assign(obj, { _id, roomId, roomGuid });
          this.dataRooms.push(obj);
          this.workersLoungeRoom = obj;
        } else {
          this.getOrCreateWorkersLoungeRoom(); // Just if id is duplicated (almost impossible)
        }
      }
    },
    getUserActivity(person) {
      if (!person.connected && person.user.activity !== "Holidays") {
        return this.$t("status.Offline");
      } else {
        const key = `status.${person.user.activity}`;
        const translation = this.$t(key) + ' ' + this.getCallText(person) || key;
        return key === translation ? `${person.user.activity} ` + this.getCallText(person) : translation;
      }
    },
    getUsersNameInRoom(room){
      return usersNamesInRoom(room) || this.$t('components.workersLounge.emptyRoom');
    },
    getCallText(person) {
      return userInCallText(person) ? userInCallText(person) : ''; // #455 Tooltip showing 'false' fix
    },
    directBridgeCall(uuid) {
      directBridgeCall(uuid, this.$router);
    },
    selectConference(conference) {
      if (this.selectedConference == "") {
        this.selectedConference = conference.confId;
      } else {
        this.selectedConference = "";
      }
    },
    goToInviteGuest() {
      if (this.isAssistsWaitinRoom) {
        this.setCurrentContentVisile(
          `invitationform/${this.isAssistsWaitinRoom}`,
          true,
          this.$router
        );
      } else {
        this.setCurrentContentVisile("invitationform", true, this.$router);
      }
    },
    gotoInviteGuestList() {
      if (this.isAssistsWaitinRoom) {
        this.setCurrentContentVisile(
          `invitationform/${this.isAssistsWaitinRoom}`,
          true,
          this.$router
        );
      } else {
        this.setCurrentContentVisile("invitationform", true, this.$router);
      }
      setTimeout(() => {
        EventBus.$emit("openTabInviteGuestList");
      }, 200);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    getUserInfo(uuid) {
      if (this.state.group[uuid]) {
        let result = this.state.group[uuid];
        result.uuid = uuid;
        result.user.uuid = uuid;
        return result;
      } else {
        return false;
      }
    },
    getFinalPersons(persons) {
      // function to sort the userlist
      const personSort = persons.sort((a, b) => {
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return (
          (a.user || {}).presentFrom ||
          a.name ||
          a.uuid ||
          ""
        ).localeCompare((b.user || {}).presentFrom || b.name || b.uuid || "");
      });
      // do foreach to find user conference and replace for conference data
      let actualConference = null;
      personSort.forEach((person, index) => {
        const isConferencePerson =
          person.user.visitorData.conferenceUUID &&
          person.user.visitorData.conferenceUUID.length > 0
            ? true
            : false;
        const currentConference = person.user.visitorData.currentConference;
        if (actualConference !== currentConference) {
          actualConference = currentConference;
          if (isConferencePerson) {
            this.obConferences[currentConference].waitingRoomPosition =
              index + 1;
            personSort[index] = this.obConferences[currentConference];
          }
        }
      });
      return personSort;
    },
    callConference(conferenceID) {
      let confGroup = undefined;
      if (this.amIanAssistant) {
        confGroup = this.state.group[this.getUrlUserUuid].user.userGroups.find(
          (e) => e.id == conferenceID
        );
      } else {
        confGroup = this.state.user.userGroups.find(
          (e) => e.id == conferenceID
        );
      }
      if (confGroup.durationMin) {
        const duration = Math.floor(confGroup.durationMin * 60 * 1000);
        this.setdurationMeeting(duration);
      }
      if (confGroup.members) {
        const usersToCall = [];
        confGroup.members.forEach(async (user) => {
          if (user.uuid !== this.state.ownUUID) {
            const isConnected =
              (store.state.group[user.uuid] || {}).connected || false;
            if (!this.amInACall) {
              if (
                isConnected &&
                (((store.state.group[user.uuid] || {}).user || {}).activity ||
                  false) !== "Holidays"
              ) {
                if (!this.userIsInCall(store.state.group[user.uuid])) {
                  usersToCall.push(user.uuid);
                }
              }
            } else {
              if (
                isConnected &&
                (((store.state.group[user.uuid] || {}).user || {}).activity ||
                  false) !== "Holidays"
              ) {
                if (!this.userIsInCall(store.state.group[user.uuid])) {
                  usersToCall.push(user.uuid);
                }
              }
            }
          }
        });
        const id = uid();
        const info = {
          callUUID: id,
          calling: usersToCall,
          isNormalMode: true,
          initiator:
            this.checkInitiator === ""
              ? this.state.ownUUID
              : this.checkInitiator,
          callStartTs: Date.now(),
          callDurationMs:
            Math.floor(confGroup.durationMin * 60 * 1000) || 60000 * 20,
          infiniteCall: false,
        };
        usersToCall.forEach((user, idx) => {
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode: false,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs:
              Math.floor(confGroup.durationMin * 60 * 1000) || 60000 * 20,
            infiniteCall: false,
          };
          wsCallStartBridgeCall(
            user,
            this.state.ownUUID,
            infoStartBridgeCall,
            idx > 0
          );
        });
        store.addRemoteBridgeStream(id, info);
        store.setCurrentContentVisile("", false, this.$router);
      }
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    userIsAdmin(uuid) {
      return store.getUserIsAdmin(uuid);
    },
    sendFileTo(uuid) {
      return sendFileTo(uuid);
    },
    flipCard(reference) {
      this.$refs[reference][0].flipped = !this.$refs[reference][0].flipped;
    },
    handleClicks(event, person, reference) {
      // const isWaitingFor = this.$route.params.uuid
      if (person.uuid === this.ownUUID) return;
      // this.clicks++;
      // if (this.clicks === 1) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
        //   this.clicks = 0;
          if (person.uuid !== this.ownUUID) {
            if (!this.disableCallBtn(person)) {
              this.callUser(person.uuid);
            }
          } else {
            this.setUserInModal();
          }
        }, 700);
      // } else {
      //   clearTimeout(this.timer);
      //   this.clicks = 0;
      //   if (!this.disableCallBtn(person)) {
      //     if (this.inBridgeCall) {
      //       this.callUser(person.uuid);
      //     } else {
      //       if (!this.userIsInCall(person)) {
      //         if (personIsInBridgeCall(person.uuid)) {
      //           this.callUser(person.uuid);
      //         } else {
      //           this.directBridgeCall(person.uuid);
      //         }
      //       }
      //     }
      //     if (isWaitingFor) {
      //       markAsCallWaitingRoomForUserEvent(person.uuid);
      //     }
      //     else {
      //       markAsCallWaitingRoomUserEvent(person.uuid);
      //     }
      //   }
      // }
    },
    setdurationMeeting(duration = null) {
      return store.setdurationMeeting(duration || 300000);
    },
    setUserInModal() {
      store.state.ownModalUser = true;
      store.state.userOnModal = {
        ...store.state.user,
        connected: store.state.wsOpen,
        uuid: store.state.ownUUID,
      };
      this.toggleUserProfile();
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
    userIsInCall(person) {
      if (
        (person.connected &&
          person.user &&
          person.user.rtcCallStatus &&
          person.user.rtcCallStatus.length > 5) ||
        person.user.inBridgeCall ||
        false
      ) {
        return true;
      }
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    namePerson(person, index) {
      if (
        person.uuid &&
        store.state.group[person.uuid] &&
        store.state.group[person.uuid].beaconCallInvite
      ) {
        const userName = person.user?.name; // || `${this.$t("components.userSettings.user")} - ${position}`; #397
        return `${userName}`;
      }
      if (person && person.user?.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      return store.getTitelForUuid(person.uuid);
    },
    goToWorkersLounge() {
      // save old Activity before Coffee Break
      // this.oldActivity = this.state.user.activity;
      store.setActivityUser("Coffee break");
      this.joinWorkersLoungeRoom();
    },
    joinWorkersLoungeRoom() {
      if(this.amInABridgeCall){
        return;
      }
      return joinSambaRoom(this.workersLoungeRoom, this.$route);
    },
  },
  computed: {
    amInABridgeCall() {
      return amInABridgeCall();
    },
    isAssistsWaitinRoom() {
      return this.$route.params.uuid;
    },
    showSidebar() {
      return this.state.persisted.showSidebar;
    },
    paginatedData() {
      const start = (this.page - 1) * this.usersPerPage,
        end = start + this.usersPerPage;
      return this.slicedPersons.slice(start, end);
    },
    getTotalPages() {
      const totalUsers = Object.keys(this.slicedPersons).length;
      return Math.ceil(totalUsers / this.usersPerPage);
    },
    getUrlUserUuid() {
      if (this.amIanAssistant) return this.$route.params.uuid;
    },
    amIanAssistant() {
      if (this.$route.params.uuid) {
        return true;
      } else {
        return false;
      }
    },
    checkInitiator() {
      const callUUID = this.getCallUUID;
      if (callUUID !== "") {
        return this.state.remoteBridgeStreams[callUUID].initiator;
      } else {
        return "";
      }
    },
    getCallUUID() {
      let remoteStreamsKey;
      if (Object.keys(this.state.remoteBridgeStreams).length > 0) {
        remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      } else {
        remoteStreamsKey = "";
      }
      return remoteStreamsKey;
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    userList() {
      return store.getProcessedUserList();
    },
    slicedPersons() {
      return this.sortedPersons
        .filter((element) => (element.uuid || (!element.uuid && element.isConference)))
        .slice(0, this.sliceIndex);
    },
    sortedPersons() {
      let persons = [];
      for (let uuid in store.state.group) {
          const person = uuid
            && hasOwn.call(store.state.group, uuid)
            && store.state.group[uuid];
          if (!person || !person.user || person.user.hidedByAdmin) continue;
          if (person.connected && person.user.presentFrom) {
            if (person.user.activity === "Coffee break") {
              persons.push({ ...person, uuid });
            }
          }
      }
      return checkListSort(persons);
    }
  },
};
</script>
<style lang="scss">
.customImageFit{
  .v-img__img--contain{
    object-fit: cover !important;
  }
}
.imageSambaRoom{
  .v-image__image{
    padding: 23px 33px 33px 33px;
    background-origin: content-box;
  }
}
</style>
<style scoped lang="scss">
// SambaRoomGrid imported styles
.centered-text {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 148px; /* Take up full height of the parent container */
  text-align: center; /* Center text when it wraps */
  width: 100%;
  max-height: 100%;
  overflow: hidden;

}

.centered-text p {
  margin: 0; /* Remove default margin of <p> if any */
  white-space: normal; /* Allow line breaks */
  max-width: 100%;
  color: var(--v-primary-600) !important;
  caret-color: var(--v-primary-600) !important;
  line-height: 25px;
  max-height: 100%;
  overflow: hidden;
}
.contentSambaRomm{
  height: 178px;
  min-height: 178px;
  max-height: 178px;
  width: 100%;
}
.containerImgRoom {
  position: relative;
  max-width: 60%;
}
.h100 {
  height: 100%;
}
.borderRadius-4 {
  border-radius: 4px;
}
.v-theme--light.v-btn.list {
  color: rgb(117, 117, 117);
}
.list {
  text-transform: none;
  font-weight: 300;
  padding-left: 5px !important;
  letter-spacing: 0;
  font-size: 16px !important;
}
.heightScroll {
  min-height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow: auto;
}
.cursorPointer {
  cursor: pointer;
}
.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}
.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.v-theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  position: relative;
  border-bottom-left-radius: 3px; /* Replace with the desired radius */
  border-bottom-right-radius: 3px; /* Replace with the desired radius */
}
.colorWhite {
  color: white !important;
  opacity: 1!important;
}
.custom6cols {
  width: 16%;
  flex-basis: 16%;
}
.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}
@media only screen and (max-width: 1024px) {
  .custom6cols {
    width: 100%;
    flex-basis: 100%;
  }
}
/* Laptops y desktops pequeños */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  /* Estilos aquí */
  .custom6cols {
    width: 30%;
    flex-basis: 30%;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}
.v-card__subtitle {
  color: black !important;
}
.v-card__title {
  font-size: 15px !important;
}
// SambaRoomGrid imported styles END
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.iconCardsContainer {
  position: absolute;
  top: 0;
  right: 0;
  background: unset;
  height: 28px;
}
.conferenceCard {
  height: 100%;
  background-color: #f5f5f5 !important;
}
.titleConferenceCard {
  font-size: 18px;
  background-color: white !important;
}
.firstCard {
  border: 2px solid white !important;
  max-width: 235px !important;
  min-width: 220px!important
}
.cursorPointer {
  cursor: pointer;
}
.paginationPosition {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}
.buttonAdd {
  font-size: 49px;
  color: var(--v-primary-base);
}
.divAddBox {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.divAdd {
  text-align: center;
  width: 100%;
}
.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.v-theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  position: relative;
}
.contentGridWrapper {
  height: calc(100vh - 195px);
  overflow: auto;
}
.custom6cols {
  width: 16%;
  flex-basis: 16%;
}
.cardWidth.wr {
  max-width: 235px !important;
  box-shadow: none !important;
}
@media (max-width: 765px) {
  .custom6cols {
    width: 50% !important;
    min-width: 50% !important;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
.footerCard {
  background: white;
  padding: 0 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.v-card__subtitle {
  color: black !important;
}
.v-card__title {
  font-size: 15px !important;
}
</style>
