var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: this.state.showAddTeamModal,
            callback: function($$v) {
              _vm.$set(this.state, "showAddTeamModal", $$v)
            },
            expression: "this.state.showAddTeamModal"
          }
        },
        [
          _c("v-card", [
            _c(
              "div",
              [
                _c(
                  "v-card-title",
                  { staticClass: "headline" },
                  [
                    _c("v-img", {
                      staticClass: "mr-1",
                      attrs: {
                        src: "assets/icon.png",
                        "max-height": "30",
                        "max-width": "30",
                        contain: ""
                      }
                    }),
                    _vm._v("\n            Add new team\n          ")
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-row", {
                  staticClass: "w-100 mx-0 my-0",
                  attrs: { flex: "" }
                }),
                _vm._v(" "),
                _c(
                  "v-form",
                  {
                    ref: "form",
                    staticStyle: { width: "100%", margin: "0" },
                    attrs: { "lazy-validation": "" },
                    model: {
                      value: _vm.valid,
                      callback: function($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid"
                    }
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          bottom: "",
                          origin: "center center",
                          transition: "scale-transition",
                          "close-on-click": _vm.closeOnClick,
                          "close-on-content-click": false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-checkbox",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "mencheckbox departments cbNoteHead",
                                      attrs: {
                                        label: _vm.$t(
                                          "components.addTeamModal.chooseTeamDepartment"
                                        ),
                                        readonly: "readonly"
                                      },
                                      model: {
                                        value: _vm.departmentsChecked,
                                        callback: function($$v) {
                                          _vm.departmentsChecked = $$v
                                        },
                                        expression: "departmentsChecked"
                                      }
                                    },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.isDepartment,
                          callback: function($$v) {
                            _vm.isDepartment = $$v
                          },
                          expression: "isDepartment"
                        }
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-list",
                          {
                            staticStyle: { "padding-bottom": "0px !important" }
                          },
                          [
                            _c("v-list-item", [
                              _c(
                                "div",
                                { staticStyle: { margin: "8px" } },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "py-0" },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: {
                                          "font-weight": "600",
                                          "font-size": "19px"
                                        },
                                        attrs: {
                                          label: _vm.$t(
                                            "generics.searchDepartment"
                                          )
                                        },
                                        model: {
                                          value: _vm.selectionSearchTerm,
                                          callback: function($$v) {
                                            _vm.selectionSearchTerm = $$v
                                          },
                                          expression: "selectionSearchTerm"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "confined" },
                              _vm._l(_vm.departmentData, function(item) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: item,
                                    staticClass: "mencheckbox",
                                    class: {
                                      selactive: item === _vm.activeItem
                                    },
                                    attrs: { "item-text": "name" }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticClass:
                                            "mencheckbox listOrgItem",
                                          domProps: {
                                            textContent: _vm._s(item)
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setSelectedArea(
                                                "department",
                                                item
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.selectedDepartment,
                                            callback: function($$v) {
                                              _vm.selectedDepartment = $$v
                                            },
                                            expression: "selectedDepartment"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item", { staticClass: "select-box" })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticStyle: { width: "100%", margin: "0" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12 px-2 py-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                autofocus: "",
                                disabled: !_vm.departmentsChecked,
                                label: _vm.$t(
                                  "components.addTeamModal.teamName"
                                ),
                                required: "",
                                counter: "",
                                maxlength: "20",
                                rules: _vm.requiredRule
                              },
                              model: {
                                value: _vm.newTeamName,
                                callback: function($$v) {
                                  _vm.newTeamName = $$v
                                },
                                expression: "newTeamName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card",
                  {
                    staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                    attrs: { color: "primary" }
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "px-2 btns w-100 mx-0 my-0" },
                      [
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              disabled:
                                                !_vm.valid ||
                                                !_vm.departmentsChecked,
                                              icon: "",
                                              color: "white"
                                            },
                                            on: { click: _vm.handleNewTeam }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "check"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.accept")))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { icon: "", color: "white" },
                                            on: { click: _vm.closeModal }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "times"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.close")))
                              ])
                            ]
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }