var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pb-0", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            [
              _c(
                "v-row",
                { staticClass: "pt-0 px-3 Width100" },
                [
                  _vm.isBeaconInvite ||
                  (!_vm.hasPrivilege(_vm.ownUUID) && !_vm.hasVisitorNameOnStart)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.inviteVisitor.typeYourName"
                              ),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              maxlength: 120
                            },
                            model: {
                              value: _vm.visitorName,
                              callback: function($$v) {
                                _vm.visitorName = $$v
                              },
                              expression: "visitorName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(3, function(n) {
                    return _c(
                      "v-col",
                      {
                        key: n,
                        staticClass: "col-12",
                        attrs: {
                          lg: _vm.cols[n - 1],
                          md: _vm.cols[n - 1],
                          sm: _vm.cols[n - 1],
                          xl: _vm.cols[n - 1],
                          xs: "12"
                        }
                      },
                      [
                        _c("v-card", { attrs: { flat: "" } }, [
                          n == 1
                            ? _c("div", { staticClass: "video-container" }, [
                                _c("video", {
                                  ref: "video",
                                  refInFor: true,
                                  attrs: {
                                    id: "video",
                                    playsinline: "",
                                    autoplay: ""
                                  },
                                  domProps: { srcObject: _vm.videoSourceObject }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          n == 2
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0 px-0 cameraSelection"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            disables: !_vm.showUpdateAdvise
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-col",
                                                      _vm._g(
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-select", {
                                                          ref: "videoSource",
                                                          refInFor: true,
                                                          staticClass: "pt-1",
                                                          attrs: {
                                                            "hide-details": "",
                                                            items:
                                                              _vm.optionsVideoSource,
                                                            label: _vm.$t(
                                                              "generics.camera"
                                                            ),
                                                            outlined: "",
                                                            dense: "",
                                                            id: "videoSource"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.handleChange(
                                                                "video",
                                                                _vm.videoOptionSelected
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.videoOptionSelected,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.videoOptionSelected = $$v
                                                            },
                                                            expression:
                                                              "videoOptionSelected"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.camAndMic.updateAdvise"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          n == 3
                            ? _c(
                                "div",
                                { attrs: { cols: "12 px-0 py-0" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-0 my-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "12 pt-2 px-0 py-0" }
                                        },
                                        [
                                          _c("v-select", {
                                            ref: "audioSource",
                                            refInFor: true,
                                            staticClass: "selAudio pt-1",
                                            attrs: {
                                              items: _vm.optionsAudioSource,
                                              label: _vm.$t(
                                                "components.callsContent.mic"
                                              ),
                                              outlined: "",
                                              dense: "",
                                              id: "audioSource"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  "audioInput",
                                                  _vm.audioOptionSelected
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.audioOptionSelected,
                                              callback: function($$v) {
                                                _vm.audioOptionSelected = $$v
                                              },
                                              expression: "audioOptionSelected"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("canvas", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.expandedContent &&
                                                  _vm.hasPrivilege(_vm.ownUUID),
                                                expression:
                                                  "expandedContent && hasPrivilege(ownUUID)"
                                              }
                                            ],
                                            ref: "canvasAudio",
                                            refInFor: true,
                                            attrs: { id: "canvasAudio" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.expandedContent &&
                                                _vm.hasPrivilege(_vm.ownUUID),
                                              expression:
                                                "expandedContent && hasPrivilege(ownUUID)"
                                            }
                                          ],
                                          attrs: { cols: "12 px-0 py-0" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            {
                                              staticClass:
                                                "pl-0 mb-1 font-weight-light setupSubHeader"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.camAndMic.ringerVolume"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-slider", {
                                            staticClass: "micSlider",
                                            attrs: { "thumb-label": "always" },
                                            model: {
                                              value: _vm.volumeCallToneModel,
                                              callback: function($$v) {
                                                _vm.volumeCallToneModel = $$v
                                              },
                                              expression: "volumeCallToneModel"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12 px-0 py-0" } },
                                        [
                                          _c("v-select", {
                                            ref: "audioOutput",
                                            refInFor: true,
                                            attrs: {
                                              items: _vm.optionsAudioOutput,
                                              label: _vm.$t(
                                                "components.callsContent.loudspeaker"
                                              ),
                                              "hide-details": "",
                                              outlined: "",
                                              dense: "",
                                              id: "audioOutput"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  "audioOutput",
                                                  _vm.audioOutputOptionSelected
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.audioOutputOptionSelected,
                                              callback: function($$v) {
                                                _vm.audioOutputOptionSelected = $$v
                                              },
                                              expression:
                                                "audioOutputOptionSelected"
                                            }
                                          }),
                                          _vm._v(" "),
                                          !!_vm.audioOutputError
                                            ? _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    type: "warning",
                                                    dismissible: false
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    Output: " +
                                                      _vm._s(
                                                        _vm.audioOutputError
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "12 px-0 py-0 pt-4" }
                                        },
                                        [
                                          _c("v-select", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.hasPrivilege(
                                                  _vm.ownUUID
                                                ),
                                                expression:
                                                  "hasPrivilege(ownUUID)"
                                              }
                                            ],
                                            ref: "ringingOutput",
                                            refInFor: true,
                                            staticClass: "pt-3",
                                            attrs: {
                                              items: _vm.optionsAudioOutput,
                                              label: _vm.$t(
                                                "components.camAndMic.ringingOutput"
                                              ),
                                              "hide-details": "",
                                              outlined: "",
                                              dense: "",
                                              id: "ringingOutput"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  "ringingOutput",
                                                  _vm.ringingOutputOptionSelected
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.ringingOutputOptionSelected,
                                              callback: function($$v) {
                                                _vm.ringingOutputOptionSelected = $$v
                                              },
                                              expression:
                                                "ringingOutputOptionSelected"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.expandedContent = !_vm.expandedContent
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "generics.aditionalSettings"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                          !_vm.expandedContent
                                            ? _c("font-awesome-icon", {
                                                staticClass: "ml-2",
                                                style: { fontSize: "16px" },
                                                attrs: {
                                                  icon: ["fal", "chevron-down"],
                                                  color: "primary"
                                                }
                                              })
                                            : _c("font-awesome-icon", {
                                                staticClass: "ml-2",
                                                style: { fontSize: "16px" },
                                                attrs: {
                                                  icon: ["fal", "chevron-up"],
                                                  color: "primary"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _vm.expandedContent
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.callsContent.videoBackground"
                                                    )
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gridVideoBackground mt-2 pb-2"
                                                },
                                                _vm._l(
                                                  _vm.dataVideoBackground,
                                                  function(background, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        class:
                                                          _vm.videoBackground &&
                                                          JSON.stringify(
                                                            _vm.videoBackground
                                                          ) ===
                                                            JSON.stringify(
                                                              background
                                                            )
                                                            ? "imageBackgroundSelected"
                                                            : "imageBackground",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectVideoBackground(
                                                              background
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        background.image
                                                          ? _c("img", {
                                                              attrs: {
                                                                src:
                                                                  background.url
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        background.icon
                                                          ? _c("img", {
                                                              staticClass:
                                                                "imgIcon",
                                                              attrs: {
                                                                src:
                                                                  background.icon
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.mediaDevicesError
        ? _c("v-alert", { attrs: { type: "error", dismissible: true } }, [
            _vm._v(_vm._s(_vm.mediaDevicesError))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "FooterModal",
        { attrs: { showCancel: true, closeModalFunction: _vm.closeModal } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.disableSave,
                depressed: "",
                color: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.saveMediaSetup()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("generics.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }