var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      !_vm.isGuest
        ? _c(
            "v-list-group",
            {
              attrs: {
                "active-class": "listColor",
                value:
                  _vm.state.searchTerm.length > 0
                    ? false
                    : null || _vm.isActive,
                appendIcon: _vm.sortedInvites.length == 0 ? null : "$expand"
              },
              on: {
                click: function($event) {
                  return _vm.setList("invites")
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", "max-width": "300" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-list-item-icon",
                                        {
                                          class:
                                            "" +
                                            (_vm.getRoute === "/invites"
                                              ? "primary--text"
                                              : ""),
                                          staticStyle: {
                                            "min-height": "0px !important"
                                          },
                                          on: {
                                            click: _vm.handlerClickInvitesIcon
                                          }
                                        },
                                        [
                                          _vm.currentPage("/invites")
                                            ? _c("font-awesome-icon", {
                                                staticClass: "primary--text",
                                                attrs: {
                                                  icon: ["fas", "user-friends"]
                                                }
                                              })
                                            : _c("font-awesome-icon", {
                                                staticClass: "primary--text",
                                                attrs: {
                                                  icon: ["fal", "user-friends"]
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-title",
                                        _vm._g(
                                          {
                                            staticClass: "primary--text",
                                            class:
                                              "" +
                                              (_vm.currentPage("/invites")
                                                ? "font-weight-bold primary--text"
                                                : ""),
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.openGridInvites()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n            \n            " +
                                              _vm._s(
                                                _vm.$t("generics.userInvites")
                                              ) +
                                              "\n              "
                                          ),
                                          _c(
                                            "span",
                                            [
                                              _vm.sortedInvites.length > 0
                                                ? [
                                                    _vm._v(
                                                      "\n                  ("
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "textColor"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.sortedInvites
                                                              .length
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      ")\n                "
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1096091558
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.userInvites")))
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1099799641
              )
            },
            [
              _vm._v(" "),
              _c("VisitorList", {
                attrs: { visitors: _vm.sortedInvites, section: "My Invites" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }