var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InviteFormContainer" },
    [
      _c(
        "v-card",
        { class: (!_vm.showTimeline ? "pt-2" : "pt-6") + " pl-10 pr-10" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "tabInvite pb-5",
              attrs: { centered: "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tab", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("components.inviteVisitor.inviteVofficeUser")
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-tab", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.inviteGuestPanel.inviteList")) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          label: _vm.$t(
                                            "components.inviteGuestPanel.title"
                                          )
                                        },
                                        model: {
                                          value: _vm.title,
                                          callback: function($$v) {
                                            _vm.title = $$v
                                          },
                                          expression: "title"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "8" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "components.inviteFormUser.username"
                                          ),
                                          required: "",
                                          outlined: "",
                                          rules: _vm.userNameRules,
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "components.inviteGuestPanel.eMail"
                                          ),
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          rules: _vm.emailRules
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.checkEmailPreSend()
                                          },
                                          keyup: _vm.changeEmail
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 positionRelative",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("vue-tel-input", {
                                        staticClass:
                                          "focusStyle form-control text-center input-sm inputPhone2FA",
                                        class: {
                                          borderError: !_vm.phoneInputValid
                                        },
                                        attrs: {
                                          defaultCountry: "DE",
                                          enabledCountryCode: "",
                                          name: "number",
                                          id: "numDisplay",
                                          value: "",
                                          placeholder: _vm.$t(
                                            "components.inviteGuestPanel.telOptional"
                                          ),
                                          autocomplete: "off"
                                        },
                                        on: { onInput: _vm.onTelInput },
                                        model: {
                                          value: _vm.telNumberModel,
                                          callback: function($$v) {
                                            _vm.telNumberModel = $$v
                                          },
                                          expression: "telNumberModel"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "customLabelTlf",
                                          class: _vm.isDarkMode
                                            ? "bgBlack"
                                            : "bgWhite"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.inviteGuestPanel.telOptional"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: _vm.$t(
                                    "components.inviteGuestPanel.position"
                                  )
                                },
                                model: {
                                  value: _vm.position,
                                  callback: function($$v) {
                                    _vm.position = $$v
                                  },
                                  expression: "position"
                                }
                              }),
                              _vm._v(" "),
                              _vm.teams.length > 0
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      label: _vm.$t(
                                        "components.inviteGuestPanel.teams"
                                      ),
                                      items: _vm.teams,
                                      "item-value": "team",
                                      "item-text": "team",
                                      multiple: "",
                                      clearable: "",
                                      outlined: "",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.selectedTeams,
                                      callback: function($$v) {
                                        _vm.selectedTeams = $$v
                                      },
                                      expression: "selectedTeams"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _vm.amIAdmin
                                            ? _c(
                                                "v-switch",
                                                _vm._g(
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      disabled: _vm.userSupport
                                                    },
                                                    model: {
                                                      value: _vm.createAdmin,
                                                      callback: function($$v) {
                                                        _vm.createAdmin = $$v
                                                      },
                                                      expression: "createAdmin"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          slot: "label"
                                                        },
                                                        slot: "label"
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.inviteGuestPanel.adminRights"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteGuestPanel.adminRightsText"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "max-width": "250" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _vm.amIAdmin
                                            ? _c(
                                                "v-switch",
                                                _vm._g(
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      disabled: _vm.createAdmin
                                                    },
                                                    model: {
                                                      value: _vm.userSupport,
                                                      callback: function($$v) {
                                                        _vm.userSupport = $$v
                                                      },
                                                      expression: "userSupport"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          slot: "label"
                                                        },
                                                        slot: "label"
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.inviteGuestPanel.inviteUserSupport"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteGuestPanel.inviteSupportText"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticStyle: { position: "relative" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-checkbox",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "mt-0 mb-4",
                                                        model: {
                                                          value: _vm.pinActive,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.pinActive = $$v
                                                          },
                                                          expression:
                                                            "pinActive"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              slot: "label"
                                                            },
                                                            slot: "label"
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.2faModal.PINInviteUser"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.2faModal.btnEnablePINText"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _vm.pinActive
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "body-2 noteStyle pinAdvice"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.pinUserSenden"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pinActive
                                        ? _c("v-text-field", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              autocomplete: "off",
                                              label: "PIN",
                                              outlined: "",
                                              dense: "",
                                              maxlength: 6
                                            },
                                            on: {
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                              }
                                            },
                                            model: {
                                              value: _vm.pinCode,
                                              callback: function($$v) {
                                                _vm.pinCode = $$v
                                              },
                                              expression: "pinCode"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-8",
                              attrs: { cols: "7" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c("v-textarea", {
                                staticClass: "inviteTextAreaClass",
                                attrs: {
                                  spellcheck: "false",
                                  outlined: "",
                                  "no-resize": true,
                                  name: "noteinput",
                                  "auto-grow": "",
                                  rows: "1",
                                  label: _vm.$t(
                                    "components.inviteVisitor.invitationTextHeadline"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  }
                                },
                                model: {
                                  value: _vm.customMessage,
                                  callback: function($$v) {
                                    _vm.customMessage = $$v
                                  },
                                  expression: "customMessage"
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticStyle: { display: "none" } }, [
                                _vm._v("Value: " + _vm._s(_vm.customMessage))
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "buttonsTextarea py-4 px-0",
                                  attrs: { align: "right" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "", "max-width": "240" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "mr-3 mb-2",
                                                    attrs: {
                                                      align: "right",
                                                      dense: "",
                                                      color: "primary",
                                                      outlined: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.resetCustomMessage()
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass: "mr-3",
                                                    style: { fontSize: "18px" },
                                                    attrs: {
                                                      icon: ["fal", "undo"]
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.inviteGuestPanel.resetCustomInviteText"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteGuestPanel.resetUserInviteTextTT"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      align: "right",
                                                      color: "primary",
                                                      outlined: "",
                                                      dense: "",
                                                      disabled: _vm.isTextEmpty
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.saveCustomMessage()
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass: "mr-3",
                                                    style: { fontSize: "18px" },
                                                    attrs: {
                                                      icon: ["fal", "save"]
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.inviteGuestPanel.saveText"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteGuestPanel.saveText"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errMssg
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mx-0 py-2",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { align: "left" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteGuestPanel.errAlreadyUser"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-auto footerRow" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-0 pt-2", attrs: { cols: "12" } },
                            [_c("v-divider")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0",
                              attrs: { cols: "12", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "230",
                                    disabled: _vm.isSendDisabled,
                                    color: "primary",
                                    loading: _vm.sendingInvitation
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendInvitation(false)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteGuestPanel.sendInvitation"
                                      )
                                    ) + "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3 mr-2",
                                  staticStyle: {
                                    "border-radius": "4px",
                                    "min-width": "45px"
                                  },
                                  attrs: {
                                    icon: "",
                                    tile: "",
                                    outlined: "",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeInvitationUserForm()
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "times"] }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-tab-item", [
                _vm.state.persisted.invitationsSent.length > 0
                  ? _c(
                      "div",
                      { staticClass: "visitorListTable" },
                      _vm._l(_vm.getInvitationList, function(
                        invitation,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("div", { staticClass: "d-flex Table" }, [
                              _c("div", { staticClass: "ListAvatar" }, [
                                _c(
                                  "div",
                                  { staticClass: "AvatarImage" },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src:
                                          _vm.getAvatarForUuid(
                                            invitation.uuid
                                          ) ||
                                          "img/default_profile_picture.png",
                                        "lazy-src":
                                          "img/default_profile_picture.png"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListMessage" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1 mx-2",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.setShowModalSenMsg(
                                                            invitation.uuid,
                                                            "message"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: [
                                                          "fal",
                                                          "paper-plane"
                                                        ]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.groups.sendMessage"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ListName pl-2 mb-2" }, [
                                _c("p", { staticClass: "mt-2 mb-2" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.getTitelForUuid(invitation.uuid)
                                      ) +
                                      "\n                  " +
                                      _vm._s(invitation.user.name) +
                                      "\n                "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListEmail pl-2 mb-2" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "mt-2" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(invitation.user.email) +
                                          "\n                  "
                                      ),
                                      invitation.uuid
                                        ? _c("font-awesome-icon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.hasUuidBeenOnPage(
                                                  invitation.uuid
                                                ),
                                                expression:
                                                  "hasUuidBeenOnPage(invitation.uuid)"
                                              }
                                            ],
                                            staticClass: "ml-2",
                                            attrs: {
                                              icon: ["fal", "check"],
                                              color: "primary"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "listDate" }, [
                                _c("p", { staticClass: "mt-2" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.formatDateToShow(
                                          invitation.user.created
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListFunktion pl-2 mb-2" },
                                [
                                  _c("p", { staticClass: "mt-2" }, [
                                    _vm._v(_vm._s(invitation.user.position))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListTeam pl-2 mb-2 pr-2" },
                                [
                                  _c("p", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTeamList(invitation.user.teams)
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListResend" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.SendLinkToUser(
                                                            invitation.uuid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "redo"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteVisitor.resendInvLink"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListCopy" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.copyUserLink(
                                                            $event,
                                                            invitation.uuid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "copy"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteGuestPanel.copyLink"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListDelete" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("DeleteBasket", {
                                                      attrs: {
                                                        indexData:
                                                          invitation.uuid,
                                                        delFunction:
                                                          _vm.deleteVisitor,
                                                        extraClasses: "",
                                                        fontSize: "20"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("generics.delete"))
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-divider", { staticClass: "mt-2 mb-2" })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      [
        _vm.showModalDelete
          ? [
              _c("RemoveVisitorModal", {
                attrs: {
                  showModal: _vm.showModalDelete,
                  closeModal: _vm.closeRemoveUserModal,
                  deleteVisitor: _vm.deleteVisitor
                }
              })
            ]
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }