var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "550" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.state.showAddToGroupModal,
            callback: function($$v) {
              _vm.$set(_vm.state, "showAddToGroupModal", $$v)
            },
            expression: "state.showAddToGroupModal"
          }
        },
        [
          _c("v-card", [
            _c(
              "div",
              [
                _c("HeaderModal", {
                  attrs: {
                    titleModal: _vm.titleModal,
                    closeModalFunction: _vm.closeModal
                  }
                }),
                _vm._v(" "),
                _c("v-row", {
                  staticClass: "w-100 mx-0 my-0",
                  attrs: { flex: "" }
                }),
                _vm._v(" "),
                _c(
                  "v-form",
                  {
                    ref: "form",
                    staticStyle: { width: "100%", margin: "0" },
                    attrs: { "lazy-validation": "" },
                    model: {
                      value: _vm.valid,
                      callback: function($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      { staticStyle: { width: "100%", margin: "0" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12 px-2 py-2" },
                          [_c("GroupList")],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "FooterModal",
                  { attrs: { closeModalFunction: _vm.closeModal } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled:
                            (_vm.state.groupSelected || undefined) === undefined
                        },
                        on: { click: _vm.handleNewGroupMember }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("generics.save")) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }