<template>
  <v-app id="sandbox">
    <v-app-bar :clipped-left="primaryDrawer.clipped" app>
      
    </v-app-bar>
    <v-content>
      <v-row flex class="mx-0 rowDownload text-center">
          <v-col cols="12" class="px-0 py-0 text-center contentDownload">
          <v-card-title class="fontSize30 font-weight-bold justify-center">
            <v-img
              class="mr-2"
              :max-height="45"
              :max-width="45"
              src="assets/icon.png"
              contain
            ></v-img>
            <span class="primary--text">vOffice </span>
          </v-card-title>
          <v-card-text>
           {{$t('components.downloadApp.downloadAppText')}}
          </v-card-text>
           <v-btn class="app-btn blu flex vert" target="_blank" href="https://apps.apple.com/de/app/voffice-app/id1523298495" v-if="mobileOperatingSystem === 'iOS' ">
            <font-awesome-icon :icon="['fab', 'apple']" :style="{ fontSize: '28px', marginRight: 7 }" />
            <p class="mb-0">{{$t('components.downloadApp.availableIn')}} <br/> <span class="big-txt">App Store</span></p>
          </v-btn>

           <v-btn class="app-btn blu flex vert" target="_blank" href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.voffice.vofficepro" v-if="mobileOperatingSystem === 'Android'">
              <font-awesome-icon :icon="['fab', 'google-play']" :style="{ fontSize: '28px', marginRight: 7 }" />
              <p class="mb-0">{{$t('components.downloadApp.getItOn')}} <br/> <span class="big-txt">Google Play</span></p>
            </v-btn>

        </v-col>
        </v-row>
    </v-content>
  </v-app>
</template>

<script>
import store from "../../store";
import { isMobile, getMobileOperatingSystem } from "../../lib/mobileUtil"
export default {
  data() {
    return {
      isLarge: this.$vuetify.breakpoint.lgAndUp,
      isSmall: this.$vuetify.breakpoint.mdOnly,
      isXSmall: this.$vuetify.breakpoint.smAndDown,
      drawers: ["Default (no property)", "Permanent", "Temporary"],
      primaryDrawer: {
        model: null,
        type: "default (no property)",
        clipped: false,
        floating: false,
        mini: false,
      },
      state: store.state,
      isMobile: isMobile(),
      mobileOperatingSystem: getMobileOperatingSystem(),
    };
  },
  methods: {
 
  },
  computed: {

  }
};
</script>

<style scoped lang="scss">

 .app-btn {
    width: 45%;
  max-width: 180px;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  text-decoration: none;
  // font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;

  .big-txt {
   font-size: 14px;
   text-transform: capitalize;
  }
 }

.blu {
  background-color: #101010 !important;
  transition: background-color 0.25s linear !important;
  &:hover {
  background-color: #454545 !important;
  }
}

.rowDownload{
  width: 100%;
  display: table;
  height: calc(100% - 70px);
  & .contentDownload{
    display: table-cell;
    vertical-align: middle;
  }

}
.customForm {
  margin-top: 7%;
}
.fontSize17 {
  font-size: 17px !important;
}
.fontSize22 {
  font-size: 22px !important;
}
.fontSize21 {
  font-size: 21px !important;
}
.fontSize30 {
  font-size: 30px !important;
}
.title {
  color: #2a3133;
}
.requestButton {
  background-color: #2a3133 !important;
  border-color: #2a3133 !important;
}
.linkApp {
  text-decoration: unset;
  color: unset !important;
}
</style>