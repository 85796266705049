<template>
  <div :class="showTimeLine ? 'transcriptionContainerTimeline' : 'transcriptionContainer'"  class="transcription" v-if="showUi">
    <ul class="ulContainer">
      <li v-for="(message, index) in sortedTranscriptions" :key="index">
        <div> {{ getTitelForUuid(message.user) }}
          {{ getNameForUuid(message.user) }}</div>
        <div  v-html="checkMessage(message.text)"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import {
  bridgeCallSendChatMessage,
} from "../../../lib/wsMsg";
import { callChatStore } from "../../../effector/callChat";
// import {onMessage} from "../../../lib/wsMsg"
import SpeechService from "../../../utils/transcript/speechService";
import SpeechRecorder from "../../../utils/transcript/speechRecorder";
import { sendToOpenAIWhisper } from "../../../utils/transcript/openAiService";
import { checkForUrls, checkForEmojis, parseJSON } from "../../../utils/basicFunctions";
// import axios from "axios";

export default {
  props: ["showUi","showTimeLine",'audioDisabled'],
  data() {
    const effector = {
      chatMessages: callChatStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      isRecording: false,
      transcriptions: [],
      userName: "User X", // Puedes obtener el nombre del usuario dinámicamente
      speechService: null,
      speechRecorder: null,
      translatedMessages: [],
      useWhisper: !("webkitSpeechRecognition" in window), // Detecta si el navegador soporta webkitSpeechRecognition
      // Effector
      ...effector,
    };
  },
  watch: {
    "audioDisabled": {
      handler: async function (value, oldValue) {
        if(!this.useWhisper){
          if( this.speechRecorder){
            if(value){
              this.speechRecorder.stop()
            }else{
              this.speechRecorder.start()
            }
          }
        }else {
          if(this.speechService){
            if(value){
              this.speechService.stop()
            }else{
              this.speechService.start()
            }
          }
        }
      }
    },
    chatMessages: {
      handler: async function (newMessages, oldMessages) {
        const bridgeCallInfo = this.state.user.bridgeCallInfo || {};
        const vofficeTranscriptEnabled = bridgeCallInfo.vofficeTranscript && this.state.user.language; // Transcription language in the frontend
        if(vofficeTranscriptEnabled){
          // Iterate over the new messages to check which ones need translation
          const newTranscriptions = newMessages.filter(msg => 
            JSON.parse(msg.text).type === "send_transcription" &&
            !this.translatedMessages.find(x => x.id === msg.id)
          );
          for (const message of newTranscriptions) {
            const parsedMessage = JSON.parse(message.text);
  
            // Only handle "send_transcription" messages
            if (parsedMessage.type === "send_transcription") {
              const text = parsedMessage.text;
              

              // If not already translated, proceed with translation
              if (!this.translatedMessages.find(x => x.id === message.id) ) {
                const localeLang = this.$locale.current();  // Get the current locale language
                const langForRecognition = (localeLang === 'de' || localeLang === 'es') 
                                          ? `${localeLang}-${localeLang.toUpperCase()}` 
                                          : 'en-US';  // Choose translation language
  
                // Call the translation API
                const translatedText = await sendToOpenAIWhisper(text, langForRecognition);  // Translate using Whisper API
                // If translation is successful, push the translated message to translatedMessages
                if (translatedText) {
                  const objToSend = {
                    type: parsedMessage.type,
                    user: parsedMessage.user,
                    text: translatedText,
                    timestamp: parsedMessage.timestamp,
                    id: message.id
                  };
                  // Push the translated message to translatedMessages
                  this.translatedMessages.push(objToSend)
                  const bridgeCallInfo = this.state.user.bridgeCallInfo || {};
                  const { roomId, callUUID, initiator } = bridgeCallInfo;
                  // Llamar a la API para guardar en MongoDB
                    fetch("/api/transcriptions", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        callId: callUUID, // Reemplazar con el ID real de la llamada
                        roomId,
                        callInitiator: initiator || '',
                        message: {
                          userName: `${this.getTitelForUuid(parsedMessage.user)} ${this.getNameForUuid(parsedMessage.user)}`,
                          user: parsedMessage.user,
                          text,
                          timestamp: parsedMessage.timestamp,
                          id: message.id
                        },
                      })
                    });
                }
              }
            }
          }

        }
      },
      deep: true, // Watch nested changes (important if messages are updated deeply)
    },
  },

  mounted() {
    this.useWhisper = !("webkitSpeechRecognition" in window)
    const bridgeCallInfo = this.state.user.bridgeCallInfo || {};
    const vofficeTranscriptEnabled = bridgeCallInfo.vofficeTranscript && this.state.user.language; // Transcription language in the frontend
    if(vofficeTranscriptEnabled && !this.showUi) {
      setTimeout(() => {
        this.startRecording();
      }, 2000);
    }
  },
  unmounted(){
    if ( !this.showUi ){
      this.stopRecording()
    }
  },
  methods: {
    // Function to handle the transcription
    async handleText(text) {
      const objtToSend = {
          type: "send_transcription",  // Type of message
          user: this.ownUUID,  // User UUID
          text,  // Original text without translation
          timestamp: Date.now(),  // Current timestamp
        };
        if (text) bridgeCallSendChatMessage(JSON.stringify(objtToSend));  // Send the original text if present
    },


    // Iniciar grabación o reconocimiento de voz
    startRecording() {
      const localeLang = this.$locale.current();
      const langForRecognition = (localeLang == 'de' || localeLang == 'es') ? `${localeLang}-${localeLang.toUpperCase()}` : 'en-US';
      if (this.useWhisper) {
        if (!this.speechRecorder) {
          this.speechRecorder = new SpeechRecorder(async (audioBlob) => {
            const text = await sendToOpenAIWhisper(audioBlob, langForRecognition);
            if(text === 'Could not transcribe'){
              return
            }
            this.handleText(text);
          });
        }
        this.speechRecorder.init();
      } else {
        if (!this.speechService) {
          this.speechService = new SpeechService(langForRecognition, this.handleText);
        }
        this.speechService.start();
      }
      this.isRecording = true;
    },

    // Detener grabación o reconocimiento de voz
    stopRecording() {
      if (this.useWhisper) {
        if ( this.speechRecorder ){
          this.speechRecorder.destroy()
        }
      } else {
        if ( this.speechService ){
          this.speechService.destroy();
        }
      }
      this.isRecording = false;
    },
    getTypeMessage(message) {
      const { type: messageType } = parseJSON(message.text) || {};
      return messageType;
    },
    checkMessage(message) {
      const finalMessageWithUrl = this.checkForUrls(message);
      const finalMessageWithEmoji = this.checkForEmojis(finalMessageWithUrl);
      return this.$sanitize(finalMessageWithEmoji);
    },
    checkForUrls(message) {
      return checkForUrls(message, false);
    },
    checkForEmojis(message) {
      return checkForEmojis(message);
    },
    getNameForUuid(userUUID) {
      if (!userUUID || !store.state.group[userUUID]) return this.$t("generics.anonymous");
      const userName = store.getNameForUuid(userUUID) || userUUID;
      return userUUID === userName ? this.$t("generics.anonymous") : userName;
    },
    getTitelForUuid(userUUID) {
      if (!userUUID || !store.state.group[userUUID] || store.state.group[userUUID].directCallUUID) return "";
      return store.getTitelForUuid(userUUID);
    },
  },
  computed: {
    sortedTranscriptions() {
      return [...this.translatedMessages]
        .filter(message => {
          return message.type === "send_transcription"; // Filtra solo los que tienen el tipo "send_transcription"
        })
        .sort((a, b) => {
          const aTimestamp = a.timestamp; // Obtiene el timestamp del mensaje a
          const bTimestamp = b.timestamp; // Obtiene el timestamp del mensaje b
          return aTimestamp - bTimestamp; // Ordena según el timestamp
        });
    }
  },
};
</script>

<style scoped>
.ulContainer{
  height: 100%;
  overflow: auto;
}
.transcriptionContainer {
  padding: 20px;
  max-width: 500px;
  margin: auto;
  height: calc(100vh - 70px);
}

.transcriptionContainerTimeline{
  padding: 20px;
  max-width: 500px;
  margin: auto;
  height: calc(100vh - 90px);
}

button {
  padding: 10px;
  margin-bottom: 10px;
}
</style>