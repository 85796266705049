var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        { attrs: { value: _vm.visible, persistent: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("v-img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("components.manageOrganisation.headline")
                          ) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.error
                        ? _c(
                            "v-alert",
                            { attrs: { type: "error", dismissible: "" } },
                            [_vm._v(_vm._s(_vm.error))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "org-row section" },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex label" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.manageOrganisation.section.name"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addStructure(
                                                                      "section"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "plus"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.section.add"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    !_vm.selectedSection ||
                                                                    !_vm.isSelectMode
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "DeleteBasket",
                                                                {
                                                                  attrs: {
                                                                    indexData:
                                                                      "section",
                                                                    delFunction:
                                                                      _vm.removeStructure,
                                                                    extraClasses:
                                                                      "",
                                                                    fontSize:
                                                                      "17",
                                                                    prevent:
                                                                      "false"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.section.delete"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.current.section.mode === "select"
                                        ? _c("v-autocomplete", {
                                            ref: "section",
                                            attrs: {
                                              items: _vm.sections,
                                              "item-text": "section",
                                              "return-object": "",
                                              disabled:
                                                _vm.current.team.mode ===
                                                "move",
                                              "auto-select-first": false,
                                              "hide-no-data": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.fieldChanged(
                                                  "section"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.renameStructure(
                                                    "section",
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              },
                                              blur: function(event) {
                                                return _vm.renameStructure(
                                                  "section",
                                                  event
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedSection,
                                              callback: function($$v) {
                                                _vm.selectedSection = $$v
                                              },
                                              expression: "selectedSection"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.manageOrganisation.supervisor"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-autocomplete", {
                                        ref: "sectionSupervisor",
                                        attrs: {
                                          required: "",
                                          rules: [
                                            function(v) {
                                              return (
                                                _vm.selectedSection.section ==
                                                  null ||
                                                !!v ||
                                                false
                                              )
                                            }
                                          ],
                                          items: []
                                            .concat(_vm.users)
                                            .sort(function(user) {
                                              return (
                                                _vm.selectedSection
                                                  .supervisor || []
                                              ).includes(user.uuid)
                                                ? -1
                                                : 0
                                            }),
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          disabled:
                                            _vm.selectedSection.section == null,
                                          "auto-select-first": "",
                                          multiple: ""
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return function(event) {
                                              return _vm.focusNext(
                                                "sectionSupervisor",
                                                "sectionAssistants"
                                              )
                                            }.apply(null, arguments)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedSection.supervisor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedSection,
                                              "supervisor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedSection.supervisor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.manageOrganisation.assistants"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-autocomplete", {
                                        ref: "sectionAssistants",
                                        attrs: {
                                          items: []
                                            .concat(_vm.users)
                                            .sort(function(user) {
                                              return (
                                                _vm.sectionSupervisorAssistants ||
                                                []
                                              ).includes(user.uuid)
                                                ? -1
                                                : 0
                                            }),
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          disabled:
                                            _vm.selectedSection.section ==
                                              null ||
                                            (_vm.selectedSection.supervisor &&
                                              _vm.selectedSection.supervisor
                                                .length > 1),
                                          multiple: "",
                                          clearable: "",
                                          "auto-select-first": ""
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.focusNext(
                                              "sectionAssistants",
                                              "department"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.sectionSupervisorAssistants,
                                          callback: function($$v) {
                                            _vm.sectionSupervisorAssistants = $$v
                                          },
                                          expression:
                                            "sectionSupervisorAssistants"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "org-row department" },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex label" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.manageOrganisation.department.name"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    !_vm
                                                                      .selectedDepartment
                                                                      .department ||
                                                                    !_vm.isSelectMode
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveStructure(
                                                                      "department"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "exchange"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.department.move"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addStructure(
                                                                      "department"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "plus"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.department.add"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    !_vm.selectedDepartment ||
                                                                    !_vm.isSelectMode
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "DeleteBasket",
                                                                {
                                                                  attrs: {
                                                                    indexData:
                                                                      "department",
                                                                    delFunction:
                                                                      _vm.removeStructure,
                                                                    extraClasses:
                                                                      "",
                                                                    fontSize:
                                                                      "17",
                                                                    prevent:
                                                                      "false"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.department.delete"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.current.department.mode ===
                                        "select" ||
                                      _vm.current.department.mode === "move"
                                        ? _c("v-autocomplete", {
                                            ref: "department",
                                            attrs: {
                                              disabled:
                                                _vm.selectedSection.section ==
                                                null,
                                              items: _vm.departments,
                                              "item-text": "department",
                                              "item-value": "department",
                                              "return-object": "",
                                              "auto-select-first": "",
                                              "hide-no-data": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.fieldChanged(
                                                  "department"
                                                )
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.renameStructure(
                                                    "department",
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              },
                                              blur: function(event) {
                                                return _vm.renameStructure(
                                                  "department",
                                                  event
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedDepartment,
                                              callback: function($$v) {
                                                _vm.selectedDepartment = $$v
                                              },
                                              expression: "selectedDepartment"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.manageOrganisation.supervisor"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-autocomplete", {
                                        ref: "departmentSupervisor",
                                        attrs: {
                                          required: "",
                                          rules: [
                                            function(v) {
                                              return (
                                                _vm.selectedDepartment
                                                  .department == null ||
                                                !!v ||
                                                false
                                              )
                                            }
                                          ],
                                          items: []
                                            .concat(_vm.users)
                                            .sort(function(user) {
                                              return (
                                                _vm.selectedDepartment
                                                  .supervisor || []
                                              ).includes(user.uuid)
                                                ? -1
                                                : 0
                                            }),
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          disabled:
                                            _vm.selectedDepartment.department ==
                                            null,
                                          "auto-select-first": "",
                                          multiple: ""
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return function(event) {
                                              return _vm.focusNext(
                                                "departmentSupervisor",
                                                "departmentAssistants"
                                              )
                                            }.apply(null, arguments)
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.selectedDepartment.supervisor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedDepartment,
                                              "supervisor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedDepartment.supervisor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.manageOrganisation.assistants"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-autocomplete", {
                                        ref: "departmentAssistants",
                                        attrs: {
                                          items: []
                                            .concat(_vm.users)
                                            .sort(function(user) {
                                              return (
                                                _vm.departmentSupervisorAssistants ||
                                                []
                                              ).includes(user.uuid)
                                                ? -1
                                                : 0
                                            }),
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          disabled:
                                            _vm.selectedDepartment.department ==
                                              null ||
                                            (_vm.selectedDepartment
                                              .supervisor &&
                                              _vm.selectedDepartment.supervisor
                                                .length > 1),
                                          multiple: "",
                                          "auto-select-first": ""
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return function(event) {
                                              return _vm.focusNext(
                                                "departmentAssistants",
                                                "team"
                                              )
                                            }.apply(null, arguments)
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.departmentSupervisorAssistants,
                                          callback: function($$v) {
                                            _vm.departmentSupervisorAssistants = $$v
                                          },
                                          expression:
                                            "departmentSupervisorAssistants"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "org-row team" },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex label" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.manageOrganisation.team.name"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm
                                                                      .selectedTeam
                                                                      .team ==
                                                                      null ||
                                                                    !_vm.isSelectMode
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveStructure(
                                                                      "team"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "exchange"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.team.move"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addStructure(
                                                                      "team"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "plus"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.team.add"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    !_vm.selectedTeam ||
                                                                    !_vm.isSelectMode
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "DeleteBasket",
                                                                {
                                                                  attrs: {
                                                                    indexData:
                                                                      "team",
                                                                    delFunction:
                                                                      _vm.removeStructure,
                                                                    extraClasses:
                                                                      "",
                                                                    fontSize:
                                                                      "17",
                                                                    prevent:
                                                                      "false"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ])
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.manageOrganisation.team.delete"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.current.team.mode === "select" ||
                                      _vm.current.team.mode === "move"
                                        ? _c("v-autocomplete", {
                                            ref: "team",
                                            attrs: {
                                              disabled:
                                                _vm.selectedSection.section ==
                                                null,
                                              items: _vm.teams,
                                              "item-text": "team",
                                              "return-object": "",
                                              "auto-select-first": ""
                                            },
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.renameStructure(
                                                    "team",
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              },
                                              blur: function(event) {
                                                return _vm.renameStructure(
                                                  "team",
                                                  event
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedTeam,
                                              callback: function($$v) {
                                                _vm.selectedTeam = $$v
                                              },
                                              expression: "selectedTeam"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.manageOrganisation.supervisor"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-autocomplete", {
                                        ref: "teamSupervisor",
                                        attrs: {
                                          required: "",
                                          rules: [
                                            function(v) {
                                              return (
                                                _vm.selectedTeam.team == null ||
                                                !!v ||
                                                false
                                              )
                                            }
                                          ],
                                          items: []
                                            .concat(_vm.users)
                                            .sort(function(user) {
                                              return (
                                                _vm.selectedTeam.supervisor ||
                                                []
                                              ).includes(user.uuid)
                                                ? -1
                                                : 0
                                            }),
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          disabled:
                                            _vm.selectedTeam.team == null,
                                          "auto-select-first": "",
                                          multiple: "",
                                          "hide-no-data": ""
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return function(event) {
                                              return _vm.focusNext(
                                                "teamSupervisor",
                                                "users"
                                              )
                                            }.apply(null, arguments)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedTeam.supervisor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedTeam,
                                              "supervisor",
                                              $$v
                                            )
                                          },
                                          expression: "selectedTeam.supervisor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", {
                                    attrs: { cols: "12", sm: "4" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.manageOrganisation.user"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("v-autocomplete", {
                                    ref: "users",
                                    attrs: {
                                      disabled: _vm.selectedTeam.team == null,
                                      items: []
                                        .concat(_vm.users)
                                        .sort(function(user) {
                                          return (
                                            _vm.selectedTeam.teamMembers || []
                                          ).includes(user.uuid)
                                            ? -1
                                            : 0
                                        }),
                                      "item-text": "name",
                                      "item-value": "uuid",
                                      multiple: "",
                                      clearable: "",
                                      "auto-select-first": ""
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.$refs.users.lazySearch = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.selectedTeam.teamMembers,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.selectedTeam,
                                          "teamMembers",
                                          $$v
                                        )
                                      },
                                      expression: "selectedTeam.teamMembers"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                      attrs: { color: "primary" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "btns mx-0", attrs: { flex: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 py-0",
                              attrs: { cols: "8", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  ref: "save",
                                  class: _vm.saving == true ? "savingData" : "",
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.valid
                                  },
                                  on: { click: _vm.save }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.manageOrganisation.save"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  class: _vm.saving == true ? "savingData" : "",
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.valid
                                  },
                                  on: { click: _vm.saveAndClose }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.manageOrganisation.saveAndClose"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.close }
                                },
                                [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.showDiscardChangesDialog, "max-width": "450px" }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.manageOrganisation.discardChangesHeadline"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.manageOrganisation.discardChangesQuestion"
                      )
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "red" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "12", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { disabled: !_vm.valid },
                              on: {
                                click: function($event) {
                                  _vm.$emit("update:visible", false)
                                  _vm.saveAndClose()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.manageOrganisation.save")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("update:visible", false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.manageOrganisation.discard"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showDiscardChangesDialog = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("generics.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.structureToDeleteType != null,
            "max-width": "400px"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline" },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "assets/icon.png",
                      "max-height": "30",
                      "max-width": "30",
                      contain: ""
                    }
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t(
                          "components.manageOrganisation." +
                            _vm.structureToDeleteType +
                            ".delete"
                        )
                      ) +
                      "\n        "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Soll '" +
                      _vm._s(
                        _vm.structureToDelete != null
                          ? _vm.structureToDelete[_vm.structureToDeleteType]
                          : ""
                      ) +
                      "' inklusive aller Unterstrukturen wirklich gelöscht werden?"
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "Bitte bestätigen Sie die Löschung durch Eingabe des Namens:"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "relative",
                      width: "100%",
                      "padding-top": "5px"
                    }
                  },
                  [
                    _c("v-text-field", {
                      ref: "repeatStructureName",
                      staticStyle: { position: "absolute", width: "100%" },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.tryDeletion.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.repeatStructureName,
                        callback: function($$v) {
                          _vm.repeatStructureName = $$v
                        },
                        expression: "repeatStructureName"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "padding-top": "5px",
                          color: "#cccccc"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.structureToDelete != null
                              ? _vm.structureToDelete[_vm.structureToDeleteType]
                              : ""
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                  attrs: { color: "red" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "8", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.isDeleteDisabled },
                              on: { click: _vm.deteleStructureAndSub }
                            },
                            [_vm._v("Löschen")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  _vm.structureToDeleteType = null
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.manageOrganisation.abort")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }