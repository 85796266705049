var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.sidebar && !_vm.mini
        ? _c(
            "v-list",
            {
              attrs: { "max-width": "320" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.openGpt4bot()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "primary--text-sidepanel",
                        style: { fontSize: "20px" },
                        attrs: { icon: ["fal", "comments"] }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-list-item-title",
                                  _vm._g(
                                    { staticClass: "primary--text-sidepanel" },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.sidebar.askYourQuestion"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        466861923
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("components.sidebar.askYourQuestion"))
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.openGpt4bot()
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "primary--text-sidepanel",
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "comments"] }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1847354379
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.sidebar.askYourQuestion")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }