var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "d-none": _vm.hideVideoContainer,
        maximizeScreen: true,
        maximizeScreenMobile: _vm.isMobile
      },
      staticStyle: {}
    },
    [
      _c(
        "div",
        {
          staticClass: "sidebarCallWrapper",
          class: {
            sidebarCallWrapper: !_vm.showTimeLine,
            sidebarCallWrapperTimeline: _vm.showTimeLine
          }
        },
        [
          _vm.maximizeScreen && _vm.hasPrivilege(this.ownUUID)
            ? _c("SidebarCall", {
                attrs: {
                  maximizeScreen: _vm.maximizeScreen,
                  isConferenceCall: _vm.isConferenceCall,
                  getAmIModerator: true,
                  showAskAiUser: _vm.showAskAiUser
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w100 progressLinearBridge" },
        [
          _vm.showTimeLine
            ? _c("ProgressLinearBridge", {
                attrs: {
                  amIModerator: true,
                  updateTimeForUser: _vm.updateTimeForUser,
                  setCallDuration: _vm.setCallDuration,
                  hangUpBridgeCall: _vm.hangUpBridgeCall,
                  redirectToStartView: _vm.redirectToStartView,
                  isConferenceCall: _vm.isConferenceCall,
                  rejectCall: _vm.rejectCall
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.amICalling.length
        ? _c(
            "div",
            { staticClass: "callingToaster" },
            _vm._l(_vm.amICalling, function(participant) {
              return _c(
                "v-alert",
                { key: participant, attrs: { prominent: "", color: "white" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "waveCallingBridge" },
                          _vm._l(3, function(index) {
                            return _c("div", { key: index, staticClass: "dot" })
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ml-2", style: "color: black;" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.remotePersonCall.calling")
                              ) +
                                " " +
                                _vm._s(_vm.getNameForUuid(participant))
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "buttonsCall",
                              attrs: { icon: "", color: "black" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.hangUpCalling(participant)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "faPhoneRotate",
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "phone"] }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            heightNoTimeline: !_vm.showTimeLine,
            heightTimeline: _vm.showTimeLine,
            callContainerNoSidebar: !_vm.hasPrivilege(_vm.ownUUID),
            callContainerSidebar: _vm.hasPrivilege(_vm.ownUUID)
          }
        },
        [
          _c(
            "div",
            {
              ref: "video",
              style:
                "height: 100%; width: " +
                (!_vm.hasPrivilege(_vm.ownUUID) ? "100%" : "99%") +
                "; -ms-user-select: none; -moz-user-select: none; -webkit-user-select: none; user-select: none;",
              attrs: { id: "video" }
            },
            [
              !_vm.isMobile && _vm.frameMuted !== undefined
                ? _c(
                    "div",
                    { class: _vm.mutedBtnClass, attrs: { id: "muteCall" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { color: "black", top: "", "nudge-top": "25" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "custom-btn",
                                          attrs: {
                                            large: "",
                                            icon: "",
                                            color: "white"
                                          },
                                          on: {
                                            click: _vm.toggleMuteAudioFunction
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        !_vm.frameMuted
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                icon: ["far", "volume-up"]
                                              }
                                            })
                                          : _c("font-awesome-icon", {
                                              style: {
                                                fontSize: "20px",
                                                color: "red"
                                              },
                                              attrs: {
                                                icon: ["far", "volume-slash"]
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1077208835
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                !_vm.frameMuted
                                  ? _vm.$t(
                                      "components.callsContent.turOffSound"
                                    )
                                  : _vm.$t(
                                      "components.callsContent.turnOnSound"
                                    )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobile && _vm.showLeaveSession
                ? _c(
                    "div",
                    {
                      style: "position: absolute; bottom: 28px; right: 180px;",
                      attrs: { id: "leaveSession" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", color: "black", "nudge-top": "25" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "custom-btn",
                                          style: "width: 40px; height: 40px;",
                                          attrs: {
                                            large: "",
                                            icon: "",
                                            color: "white"
                                          },
                                          on: { click: _vm.toggleLeaveSession }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: {
                                            fontSize: "23px",
                                            color: "red",
                                            transform: "rotate(225deg)"
                                          },
                                          attrs: { icon: ["fas", "phone"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3388780034
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.hangUp")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobile && _vm.showAskAiUser
                ? _c(
                    "div",
                    {
                      style: "position: absolute; bottom: 28px; right: 240px;"
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", color: "black", "nudge-top": "25" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "custom-btn",
                                          style: "width: 40px; height: 40px;",
                                          attrs: {
                                            large: "",
                                            icon: "",
                                            color: "white"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openConversationAi()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm.aiChatConversationOpen
                                          ? _c("img", {
                                              staticClass: "customIcons",
                                              style:
                                                "width: 26px; height: 26px;",
                                              attrs: {
                                                src: "/img/ai_chat_blue.svg"
                                              }
                                            })
                                          : _c("img", {
                                              staticClass: "customIcons",
                                              style:
                                                "width: 26px; height: 26px;",
                                              attrs: { src: "/img/ai_chat.svg" }
                                            })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            252275608
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.callsContent.openConversation",
                                  [this.showAskAiUser]
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobile && _vm.showAskAiUser
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeAiMenuClick,
                          expression: "closeAiMenuClick"
                        }
                      ],
                      style: "position: absolute; bottom: 28px; right: 300px;",
                      attrs: { id: "askAiUser" }
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-click": true,
                            "close-on-content-click": true,
                            "min-width": "150",
                            "offset-x": "",
                            "position-x": _vm.xAiMenu,
                            "position-y": _vm.yAiMenu,
                            absolute: ""
                          },
                          model: {
                            value: _vm.showAiMenu,
                            callback: function($$v) {
                              _vm.showAiMenu = $$v
                            },
                            expression: "showAiMenu"
                          }
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "pointer" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { on: { click: _vm.stopTalkingAi } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.callsContent.stopTalking"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", color: "black", "nudge-top": "25" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "custom-btn",
                                          style: "width: 40px; height: 40px;",
                                          attrs: {
                                            large: "",
                                            icon: "",
                                            color: "white",
                                            loading: _vm.processingAiAudio
                                          },
                                          on: {
                                            click: _vm.toggleAskAiUser,
                                            contextmenu: function($event) {
                                              $event.preventDefault()
                                              return _vm.showAiMenuClick($event)
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm.aiUserRecording
                                          ? _c("img", {
                                              staticClass: "customIcons",
                                              style:
                                                "width: 26px; height: 26px;",
                                              attrs: {
                                                src: "/img/ai_icon_red.svg"
                                              }
                                            })
                                          : _c("img", {
                                              staticClass: "customIcons",
                                              style:
                                                "width: 26px; height: 26px;",
                                              attrs: { src: "/img/ai_icon.svg" }
                                            })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1391527669
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.sidebar.askTo", [
                                  this.showAskAiUser
                                ])
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.showFinishCallModal
        ? _c("FinishCallModal", {
            attrs: {
              showFinishCallModal: _vm.showFinishCallModal,
              closeModal: _vm.showCloseFinishCallModal,
              rejectCall: _vm.rejectCall
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }