var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("canvas", { ref: "canvas", staticClass: "streamCanvas" }),
    _vm._v(" "),
    _c("video", {
      ref: "video",
      staticClass: "streamVideo",
      class: { videoAway: _vm.state.localStreams.display },
      attrs: { autoplay: "", playsinline: "", muted: "" },
      domProps: { srcObject: _vm.stream, muted: true }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }