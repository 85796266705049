var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "two_sandbox" } },
    [
      _c(
        "v-app-bar",
        { attrs: { "clipped-left": _vm.primaryDrawer.clipped, app: "" } },
        [
          _vm.primaryDrawer.type !== "permanent"
            ? _c("v-app-bar-nav-icon")
            : _vm._e(),
          _vm._v(" "),
          _c("v-img", {
            staticClass: "mx-2",
            attrs: {
              src: "assets/icon.png",
              "max-height": "40",
              "max-width": "40",
              contain: ""
            }
          }),
          _vm._v(" "),
          _c("v-toolbar-title", { staticClass: "d-none d-md-flex" }, [
            _vm._v("vOffice")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "570" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-row",
                {
                  staticClass: "w-100 mx-0 my-0 justify-space-between",
                  attrs: { flex: "" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0 py-0", attrs: { align: "left" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: "assets/icon.png",
                              "max-height": "30",
                              "max-width": "30",
                              contain: ""
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium pl-4 spanModalTitle"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.2fa.twoFactorAuthenticationTitle"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pt-8 pb-2" }, [
                _vm.enabled === "sms" || _vm.smsRecovery
                  ? _c("div", { staticClass: "d-flex mb-4 divInput" }, [
                      _vm.requestSmsDone
                        ? _c(
                            "span",
                            { staticClass: "px-2 align-center d-flex" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.2fa.sentSMSMsg"))
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showCodeInput
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-end divInput" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autocomplete: !_vm.rememberPin
                              ? "new-password"
                              : null,
                            label: _vm.tfaCodeLabel,
                            placeholder: " ",
                            autofocus: "",
                            outlined: "",
                            dense: "",
                            required: "",
                            "hide-details": "",
                            "append-icon":
                              "" +
                              (_vm.showPassword ? "mdi-eye-off" : "mdi-eye"),
                            type: "" + (_vm.showPassword ? "text" : "password")
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              _vm.tfaCode && _vm.requestAccess()
                            },
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                            },
                            "click:append": function($event) {
                              return _vm.showPass()
                            }
                          },
                          model: {
                            value: _vm.tfaCode,
                            callback: function($$v) {
                              _vm.tfaCode = $$v
                            },
                            expression: "tfaCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.enabled !== "sms" && _vm.smsRecovery && _vm.requestSmsDone
                  ? _c("div", { staticClass: "d-flex mb-4 divInput" }, [
                      _c("span", { staticClass: "px-2 align-center d-flex" }, [
                        _vm._v(_vm._s(_vm.$t("components.2fa.pinValidUntil")))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.enabled !== "sms" && !_vm.requestSmsDone
                  ? _c(
                      "div",
                      { staticClass: "pt-0 pb-6 rememberPinStyle" },
                      [
                        _vm.enabled === "pin"
                          ? _c("v-checkbox", {
                              attrs: {
                                label: _vm.$t("components.2fa.rememberPin")
                              },
                              model: {
                                value: _vm.rememberPin,
                                callback: function($$v) {
                                  _vm.rememberPin = $$v
                                },
                                expression: "rememberPin"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.smsRecovery
                          ? _c(
                              "a",
                              {
                                staticClass: "link mb-3",
                                on: { click: _vm.handleForgotPin }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.2faSms.forgotPinQuestion"
                                    )
                                  ) + "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-12 px-2 py-0",
                      attrs: { align: "left" }
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showResult,
                              expression: "showResult"
                            }
                          ],
                          staticClass: "px-0",
                          staticStyle: { color: "red" }
                        },
                        [_vm._v(_vm._s(_vm.message))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass:
                    "py-2 pl-4 mt-0 pr-2 footerModal footersBackground"
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 btns mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "6", align: "left" }
                        },
                        [
                          _vm.enabled === "sms"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.requestingSms,
                                    disabled: _vm.requestSmsDone
                                  },
                                  on: { click: _vm.handleForgotPin }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("components.2faSms.sendSMS"))
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0",
                          attrs: { cols: "6", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled:
                                  !_vm.rememberPin &&
                                  (!_vm.showCodeInput || !_vm.tfaCode),
                                loading: _vm.requestingAccess
                              },
                              on: { click: _vm.requestAccess }
                            },
                            [_vm._v(_vm._s(_vm.$t("components.2fa.login")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          ),
          _vm._v(" "),
          _vm.showSendSMSModal
            ? _c("TwoFactorSendSmsModal", {
                attrs: {
                  toggleShowSendSMS: _vm.toggleShowSendSMS,
                  showSendSMSModal: _vm.showSendSMSModal,
                  requestSms: _vm.requestSms
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }