var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-0" },
    [
      _c(
        "v-col",
        {
          staticClass: "py-0 px-0",
          attrs: { cols: _vm.selectedUser ? 8 : 12 }
        },
        [
          _c("UserManagementTable", {
            attrs: {
              formIsChanged: _vm.formIsChanged,
              changesInForm: _vm.changesInForm,
              users: _vm.users,
              selectedUser: _vm.selectedUser,
              setUserInfo: _vm.setUserInfo,
              addRecord: _vm.addRecord
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedUser
        ? _c(
            "v-col",
            { staticClass: "profileWrapper py-0 px-0", attrs: { cols: 4 } },
            [
              _c("UserManagementDetail", {
                attrs: {
                  formIsChanged: _vm.formIsChanged,
                  changesInForm: _vm.changesInForm,
                  selectedUser: _vm.selectedUser,
                  closeDetails: _vm.closeDetails,
                  hideSaveButtons: _vm.hideSaveButtons
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }